import React from 'react'
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_STORE, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';

const UserActivity = ({item}) => (
    <Button>{item.fullName + " NICE"}</Button>
)

class TerminalDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_TERMINAL, listener)
            } else {
                Api.getItem(OBJECT_TYPE_TERMINAL, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_TERMINAL,
                    itemResult: this.state.itemResult,
                    pagePath: "/terminals/",
                    history: this.props.history,
                })}
            </div>
        )
    }

}

export default withRouter(TerminalDetailPage);