import { withLoadablePage, withLoadablePageWithParams } from "../../base/Page";
import Api from "../../session/Api";

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Delivery32,
    Building32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    User32,
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    CheckmarkOutline16,
    CheckmarkFilled16,
    ErrorFilled16,
    Enterprise32,
    Document32,
    RowDelete16,
    ChartBullet32,
    Money16,
    GraphicalDataFlow32,
    Education32,
} from '@carbon/icons-react'
import { ButtonSet, Checkbox, ComboBox, ContentSwitcher, DatePicker, DatePickerInput, NumberInput, RadioTile, Switch, Tag, TextArea, TextInput, TileGroup } from "carbon-components-react";
import { getObjectTypeName, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STUDENT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from "../../constants/ObjectTypes";
import { hasCapabilitySupport } from "../../app/Capabilities";
import React, { useEffect, useMemo, useState } from "react";
import Util, { absAmt, big } from "../../util/Util";
import useStore from "../../hooks/useStore";
import { makeObservable } from "../../util/makeObservable";
import { SERVICE_SALE_AMOUNT_MODE_FREE_FORM, SERVICE_SALE_AMOUNT_MODE_ITEM_BASED } from "../../domain/service-sale";
import { TaxInput } from "../stock-flow/TaxInput";

import Big from 'big.js';
import Button from "../../components/Button";
import UIUtil from "../../util/UIUtil";
import { useHistory } from "react-router-dom";
import CustomComboBox from "../../components/CustomComboBox";
import { LEDGER_TYPE_EXPENSE, LEDGER_TYPE_INCOME } from "../../constants/Constants";
import ProductServiceTable from "../../views/editable-tables/ProductServiceTable";
import { DISCOUNT_TYPE } from "../../app/Config";
import { ComboBoxField, ObjectComboBoxField } from "../../components/ComboBoxField";
import { ShipmentListSelector } from "../../components/list-selector";

function useCurrencyCode(store) {
    const [currency] = useStore(store, 'currency')
    return currency?.code ?? "AED"
}


const Root = ({ children }) => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
        <div style={{ width: '75vw' }}>
            {children}
        </div>
    </div>
)

const Section = ({ children, icon, title, options, extraTopMargin }) => (
    // <div style={{ marginTop: extraTopMargin ? '6rem' : '5rem', borderTop: 'solid', paddingTop: '1rem', borderWidth: 1, borderColor: '#00000060' }}>
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem', }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: 1 }}>{title}</p>
            {options}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginTop: '0.5rem', textAlign: 'center' }}>{title}</h4>
        <p style={{ marginTop: '0rem', textAlign: 'center', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

const Title = ({ store, endpoints }) => {
    const [currency, setCurrency] = useStore(store, 'currency')
    const [shipment, setShipment] = useStore(store, 'shipment')
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ flex: 1 }}>
                <h1>Service Sale</h1>
                <p style={{ fontSize: 18 }}>Creating new</p>
            </div>
            {hasCapabilitySupport("shipping") &&
                <div style={{ width: 300, marginRight: '1rem' }}>
                    <label className="bx--label" style={{}}>Shipment</label>
                    <ShipmentListSelector value={shipment} setValue={setShipment} />
                </div>}
            {!hasCapabilitySupport("lms") &&
                <ObjectComboBoxField title={"Currency"} options={endpoints.currencies} value={currency} setValue={setCurrency} placeholder={"AED"} />}
        </div>
    )
}

function getPayerTypeList(type, endpoints) {
    switch (type) {
        case OBJECT_TYPE_CUSTOMER:
            return endpoints.customers;
        case OBJECT_TYPE_VENUE:
            return endpoints.venues;
        case OBJECT_TYPE_STUDENT:
            return endpoints.students;
        default:
            return undefined;
    }
}

const SelectPayee = ({ store, endpoints }) => {
    const [type, setType] = useStore(store, 'payerType')
    const [id, setId] = useStore(store, 'payerId')
    const [pickerKey, setPickerKey] = useState(Util.newTempId())

    const [isPredefinedClass, setIsPredefinedClass] = useStore(store, 'predefinedClass');
    const [classCount, setClassCount] = useStore(store, 'predefinedClassTotalCount');

    const onTypeChange = value => {
        setType(value)
        setId(0)
        setPickerKey(Util.newTempId())
    }

    const onPickerChange = e => {
        setId(e.selectedItem !== null ? e.selectedItem.id : 0)
        if (e.selectedItem === null) {
            setPickerKey(Util.newTempId())
        }
    }

    const partyTypeList = getPayerTypeList(type, endpoints)

    return (
        <Section icon={Number_132} title="Select Customer">
            <TileGroup className="horizontal-tile-radio centered-content-tile" valueSelected={type} onChange={onTypeChange}>
                <RadioTile value={OBJECT_TYPE_CUSTOMER}>
                    <RadioItem icon={AirlinePassengerCare32} title="Customer" desc="Select customer" />
                </RadioTile>
                {hasCapabilitySupport("lms") &&
                    <RadioTile value={OBJECT_TYPE_STUDENT}>
                        <RadioItem icon={Education32} title="Student" desc="Select student" />
                    </RadioTile>}
                {hasCapabilitySupport("thirdPartyPos") &&
                    <RadioTile value={OBJECT_TYPE_VENUE}>
                        <RadioItem icon={Building32} title="Venue" desc="Select venue" />
                    </RadioTile>}

                {/* <RadioTile value={OBJECT_TYPE_SUPPLIER}>
                    <RadioItem icon={Delivery32} title="Supplier" desc="Select supplier " />
                </RadioTile>
                <RadioTile value={OBJECT_TYPE_VENDOR}>
                    <RadioItem icon={Enterprise32} title="Vendor" desc="Select vendor" />
                </RadioTile>
                {hasCapabilitySupport("thirdPartyPos") &&
                    <RadioTile value={OBJECT_TYPE_VENUE}>
                        <RadioItem icon={Building32} title="Venue" desc="Select venue " />
                    </RadioTile>} */}
            </TileGroup>

            {partyTypeList !== undefined && <>
                <div style={{ height: '1rem' }} />
                <ComboBox
                    key={pickerKey}
                    titleText={getObjectTypeName(type)}
                    items={partyTypeList}
                    itemToString={item => item !== null ? item.value : ""}
                    selectedItem={partyTypeList.filter(item => item.id == id)[0]}
                    onChange={onPickerChange}
                />
            </>}

            {hasCapabilitySupport("lms") && type == OBJECT_TYPE_STUDENT && (<>
                <div style={{ height: '1rem' }} />
                <label className="bx--label">Invoice type</label>
                <ContentSwitcher selectedIndex={isPredefinedClass ? 1 : 0} size="lg" onChange={e => setIsPredefinedClass(e.index === 1)}>
                    <Switch name="misc-invoice" text="Misc Invoice" />
                    <Switch name="class-invoice" text="Class Invoice" />
                </ContentSwitcher>

                {isPredefinedClass && <>
                    <div style={{ height: '1rem' }} />
                    <label className="bx--label">No of classes (sessions)</label>

                    <NumberInput hideSteppers min={0} invalidText="Invalid numeric value" value={classCount} onChange={e => setClassCount(e.target.value < 0 ? 0 : e.target.value)} />
                </>}
            </>)}
        </Section>
    )
}

const calcAmount = (subtotal, discount, tax) => {
    if (DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT") {
        return big(subtotal).add(big(tax)).minus(big(discount));
    } else {
        return big(subtotal).minus(big(discount)).add(big(tax));
    }
}


const Items = ({ store, itemIncludeTax }) => {
    const currencyCode = useCurrencyCode(store)
    const [_, setSubtotal] = useStore(store, "subtotal");
    const [items, setItems] = useStore(store, "items")


    useEffect(() => {
        if (itemIncludeTax) {
            setSubtotal(items
                .slice(0, items.length - 1)
                .map(item => big(item.qty).times(big(item.amount)).div(big(1.05)).round(2))
                .reduce((t, c) => t.add(c), big(0)))
        } else {
            setSubtotal(items
                .slice(0, items.length - 1)
                .map(item => big(item.qty).times(big(item.amount)))
                .reduce((t, c) => t.add(c), big(0)))
        }
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    return (
        <Section icon={Number_232} title="Items">
            {itemIncludeTax ? (
                <label className="bx--label" style={{ marginBottom: '0.5rem', fontSize: 12 }}>Item amounts include tax</label>
            ) : (
                <label className="bx--label" style={{ marginBottom: '0.5rem', fontSize: 12 }}>Item amounts should NOT include tax</label>
            )}

            <ProductServiceTable editable serviceOnly items={items} setItems={setItems} currency={currencyCode} />

            <div style={{ marginBottom: '1rem' }} />
        </Section>
    )
}

const Amount = ({ store, itemIncludeTax }) => {
    const currencyCode = useCurrencyCode(store)
    const [subtotal] = useStore(store, "subtotal");
    const [discount, setDiscount] = useStore(store, "discount")
    const [tax, setTax] = useStore(store, "tax");

    //const total = big(tax).add(subtotal)
    const total = calcAmount(subtotal, discount, tax);
    return (
        <Section icon={Number_332} title="Amount">
            <div style={{ marginBottom: '1rem' }}>
                <div style={{ marginBottom: '1rem' }}>
                    <label className="bx--label">{'Subtotal (without tax)'}</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <p>{currencyCode}</p>
                        <TextInput
                            disabled
                            size="lg" hideSteppers min={0}
                            value={subtotal} />
                    </div>
                </div>

                {(DISCOUNT_TYPE === "NORMAL" || DISCOUNT_TYPE === "TAX_INCLUDED") && <div style={{ marginBottom: '1rem' }}>
                    <label className="bx--label">{'Discount'}</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <p>{currencyCode}</p>
                        <TextInput
                            size="lg" hideSteppers min={0}
                            value={discount} onChange={e => setDiscount(e.target.value)} />
                    </div>
                </div>}

                <TaxInput
                    currency={currencyCode}
                    amount={DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT" ? big(subtotal) : big(subtotal).minus(big(discount))}
                    taxValue={tax} onTaxValueChange={setTax} hideModeSwitcher={itemIncludeTax} />

                {DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT" && <div style={{ marginBottom: '1rem' }}>
                    <label className="bx--label">{'Discount'}</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <p>{currencyCode}</p>
                        <TextInput
                            size="lg" hideSteppers min={0}
                            value={discount} onChange={e => setDiscount(e.target.value)} />
                    </div>
                </div>}

                <div style={{ height: '1rem' }} />
                <h5>Total Amount</h5>
                <h2>{currencyCode} {total.toFixed(2)}</h2>
            </div>
        </Section>
    )
}


const RevenueAccountRecord = ({ onRemove, item, setItem, last, accountTreeItems }) => {
    const [selectedAccount, setSelectedAccount] = useState(undefined)
    useEffect(() => {
        const newItem = { ...item, }
        if (selectedAccount) {
            newItem.accountLedgerId = selectedAccount.id
        } else {
            delete newItem.accountLedgerId
        }
        setItem(newItem)
        // setItem({ ...item, accountLedgerId: selectedAccount ? selectedAccount.id : undefined })
    }, [selectedAccount])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', }}>
            <div style={{ flex: 3, }}>
                {/* <TextInput value={item.description} onChange={e => setItem({ ...item, description: e.target.value })} placeholder="Input..." /> */}
                <div style={{ height: 40 }}>
                    <CustomComboBox
                        items={accountTreeItems}
                        selectedItem={selectedAccount}
                        onSelectedItemUpdate={item => setSelectedAccount(item)}
                    // selectedItem={this.state.otherPartyIdObject}
                    // onSelectedItemUpdate={item => this.setState({ otherPartyIdValue: item !== undefined ? item.id : 0, otherPartyIdObject: item })}
                    />
                </div>
            </div>
            <div style={{ flex: 2, }}>
                <TextInput value={item.amount} onChange={e => setItem({ ...item, amount: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 4, }}>
                <TextInput value={item.narration} onChange={e => setItem({ ...item, narration: e.target.value })} placeholder="Input..." />
            </div>
            <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                size="sm" disabled={last}
                style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />
        </div>
    )
}

const RevenueAccountsTable = ({ store, endpoints }) => {
    const [total, setTotal] = useStore(store, "revenueAccountsTotal");
    const [items, setItems] = useStore(store, "revenueAccounts")

    const updateItem = item => {
        const newItems = [...items]
        const index = newItems.findIndex(i => i.tempId === item.tempId);
        if (index >= 0) {
            newItems[index] = item;
            setItems(newItems)
        }
    }

    const removeItem = item => setItems(items.filter(i => i.tempId !== item.tempId))

    useEffect(() => {
        const lastItem = items[items.length - 1];
        if (Object.keys(lastItem).length > 1) {
            setItems([...items, { tempId: Util.newTempId() }])
        }

        setTotal(items
            .slice(0, items.length - 1)
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)))
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    const accountTreeItems = endpoints.accountTree.filter(item => item.id == LEDGER_TYPE_INCOME)[0].items

    return (
        <div>
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                    <div style={{ width: 40, height: 0, }} />
                </div>
                {items.map((item, i) =>
                    <RevenueAccountRecord key={item.tempId} accountTreeItems={accountTreeItems} item={item} setItem={updateItem} last={i === items.length - 1} onRemove={() => removeItem(item)} />)}
            </div>

        </div>
    )
}

const RevenueAccounts = ({ store, endpoints }) => {
    const currencyCode = useCurrencyCode(store);
    const [subtotal] = useStore(store, "subtotal");
    const [tax] = useStore(store, "tax");
    const [discount] = useStore(store, "discount");
    const [total, setTotal] = useStore(store, "revenueAccountsTotal");

    const revenueAmount = useMemo(() => {
        if (DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT") {
            return big(subtotal);
        } else {
            return big(subtotal).minus(big(discount))
        }
    }, [subtotal, tax, discount])
    const totalAboveSubtotal = useMemo(() => big(total).gt(revenueAmount), [total, revenueAmount])

    return (
        <Section icon={Number_432} title="Specify Revenue Accounts (optional)" options={<Tag renderIcon={Money16} type="purple">Revenue Amount: {currencyCode} {revenueAmount.toFixed(2)}</Tag>}>
            {totalAboveSubtotal ? (
                <label style={{ color: 'red' }} className="bx--label">Account amounts total is greater than revenue amount!</label>
            ) : (
                <label className="bx--label">Any amount not accounted for will be recorded under sales revenue</label>
            )}
            <RevenueAccountsTable store={store} endpoints={endpoints} />
        </Section>
    )
}

const AdditionalInformation = ({ store }) => {
    const [info, setInfo] = useStore(store, 'info')
    return (
        <Section icon={Number_532} title="Additional Information (optional)">
            <TextArea placeholder="Note here..." value={info} onChange={e => setInfo(e.target.value)} />
        </Section>
    )
}


const ServiceSaleRecordInfo = ({ store }) => {
    const [refNo, setRefNo] = useStore(store, 'refNo')
    const [saleDate, setSaleDate] = useStore(store, 'saleDate')
    return (
        <Section icon={Number_232} title="Sale Record Info (optional)">
            <div style={{ marginBottom: '1rem' }}>
                <TextInput labelText="Ref no (optional)" value={refNo} onChange={e => setRefNo(e.target.value)} placeholder="Input..." />
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <DatePicker datePickerType="single"
                    dateFormat="d/m/Y"
                    value={Util.isNumberExist(saleDate) ? saleDate : undefined}
                    onChange={e => {
                        if (e.length > 0) {
                            setSaleDate(e[0].getTime())
                        } else {
                            setSaleDate(0)
                        }
                    }}
                >
                    <DatePickerInput
                        // placeholder="mm/dd/yyyy"
                        placeholder="dd/mm/yyyy"
                        labelText={"Date (optional)"}
                    />
                </DatePicker>
            </div>
        </Section>
    )
}

function initState(observable) {
    observable.set("payerType", OBJECT_TYPE_CUSTOMER)
    observable.set("payerId", 0)
    observable.set("subtotal", 0)
    observable.set("discount", 0)
    observable.set("tax", 0)
    observable.set("items", [{ tempId: Util.newTempId() }])
    observable.set("revenueAccounts", [{ tempId: Util.newTempId() }])
    observable.set("revenueAccountsTotal", 0)
    observable.set("info", "")
    observable.set("predefinedClass", false)
    observable.set("predefinedClassCourseId", 0)
    observable.set("predefinedClassTotalCount", 0)
    observable.set("saleDate", 0)
    observable.set("refNo", "")
    observable.set("sendInvoiceEmail", false)
}

function validate(store) {
    const {
        subtotal, discount, tax, revenueAccountsTotal, payerId, items,
        predefinedClass, predefinedClassCourseId, predefinedClassTotalCount
    } = store.toObject();

    const amount = calcAmount(subtotal, discount, tax);
    if (big(amount).lt(big(0))) {
        return "Total amount has to be a non negative number";
    }

    if (DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT") {
        if (big(revenueAccountsTotal).gt(big(subtotal))) {
            return "Account amounts total is greater than revenue amount (amount - tax)!"
        }
    } else {
        if (big(revenueAccountsTotal).gt(big(subtotal).minus(big(discount)))) {
            return "Account amounts total is greater than revenue amount (amount - tax)!"
        }
    }

    if (payerId <= 0) {
        return "Customer not selected";
    }

    if (predefinedClass) {
        // if (predefinedClassCourseId <= 0) {
        //     return "Course not selected";
        // }

        if (predefinedClassTotalCount <= 0) {
            return "Total class amount must be a positive number";
        }
    }

    // console.log(itemsMapper(items))
    // return "Wor";

    return undefined;
}

function toServiceSale(store) {
    const state = store.toObject();

    const itemsMapper = items => items
        .slice(0, items.length - 1)
        .map(item => ({
            description: item.item.name,
            itemId: item.item.selectedItem?.id,
            itemType: item.item.selectedItem?.type,
            qty: big(item.qty).toFixed(2),
            unitAmount: big(item.amount).toFixed(2),
            saveCustomService: item.saveCustomService,
        }))

    return {
        payerType: state.payerType,
        payerId: state.payerId,
        subtotal: big(state.subtotal).toFixed(2),
        discount: big(state.discount).toFixed(2),
        tax: big(state.tax).toFixed(2),
        items: itemsMapper(state.items),
        accountDivisions: state.revenueAccounts.slice(0, state.revenueAccounts.length - 1),
        info: state.info,
        predefinedClass: state.predefinedClass,
        predefinedClassCourseId: state.predefinedClassCourseId,
        predefinedClassTotalCount: state.predefinedClassTotalCount,

        saleDate: state.saleDate,
        refNo: state.refNo,

        sendInvoiceEmail: state.sendInvoiceEmail,
        usingCurrency: state.currency,
        secondaryShipmentId: state.shipment?.id
    }
}

const Confirm = ({ store, onReset }) => {
    const [sendInvoiceEmail, setSendInvoiceEmail] = useStore(store, 'sendInvoiceEmail');

    const [creating, setCreating] = useState(false);
    const history = useHistory();

    const createServiceSale = () => {
        const err = validate(store);
        if (err) {
            UIUtil.showError(err);
            return;
        }

        setCreating(true);
        Api.createServiceSale(toServiceSale(store), response => {
            setCreating(false)

            if (response.status === true) {
                history.replace("/service-sale/" + response.payload)
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    return (
        <Section icon={Number_632} title="Confirm" extraTopMargin>
            <div onClick={() => setSendInvoiceEmail(p => !p)}>
                <Checkbox checked={sendInvoiceEmail} labelText="Send invoice email" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <ButtonSet style={{ width: 392 }}>
                    <Button onClick={onReset} disabled={creating} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                    <Button onClick={() => createServiceSale()} loading={creating} renderIcon={GraphicalDataFlow32}>Create Service Sale</Button>
                </ButtonSet>
            </div>
        </Section>
    )
}

const View = ({ payload: endpoints }) => {
    const itemIncludeTax = endpoints.includeTaxAmountInServiceSaleItem;
    const [rootKey, setRootKey] = useState(() => Util.newTempId());

    const store = useMemo(() => {
        const observable = makeObservable();
        initState(observable);
        return observable;
    }, [])

    const onReset = () => {
        initState(store);
        setRootKey(Util.newTempId())
    }

    return (
        <Root key={rootKey}>
            <Title store={store} endpoints={endpoints} />
            <SelectPayee store={store} endpoints={endpoints} />
            <ServiceSaleRecordInfo store={store} />
            <Items store={store} itemIncludeTax={itemIncludeTax} />
            <Amount store={store} itemIncludeTax={itemIncludeTax} />
            <RevenueAccounts store={store} endpoints={endpoints} />
            <AdditionalInformation store={store} />
            <Confirm store={store} onReset={onReset} />
        </Root>
    )
}

export default withLoadablePage(Api.getStockFlowEndpointsList.bind(Api), View);