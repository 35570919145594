import { printPosReceipt } from "../../../pdfs/pos-receipt/PosReceiptPdf";
import ThermalPrinterDriver from "../ThermalPrinterDriver";

class BrowserPDFThermalPrinterDriver extends ThermalPrinterDriver {

    async connect(params) { }

    async disconnect() { }

    async printReceipt(posSessionSnapshot) {
        printPosReceipt(posSessionSnapshot)
    }

    hasCashDrawerAccess() {
        return false;
    }

    isConnectionBased() {
        return false;
    }

    canPrintSalesSummary() {
        return false;
    }

    canPrintRestaurantDocs() {
        return false;
    }

    getParams() {
        return [];
    }

}

export default BrowserPDFThermalPrinterDriver;