import Api from "../session/Api";
import UIUtil from "../util/UIUtil";


export async function selectCourier() {
    const stopLoading = await UIUtil.pageLoader();

    try {
        const [success, couriers] = await Api.try((api, listener) => api.getSalesOrderAvailableCouriers(listener), false, true);
        if (!success) {
            return;
        }

        const courier = await UIUtil.listPrompt("Select courier", couriers);
        if (!courier) {
            return;
        }

        return courier.id;
    } finally {
        stopLoading();
    }
}