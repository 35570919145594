import Page from "../../../../../base/Page";
import Portal from "../../../../../util/Portal";
import PosDialogs from "../../../PosDialogs";
import PosState from "../../../state/PosState";
import { LiveCart } from "../../../state/live-cart";
import RestCartContent from "../../RestCartContent";
import RestCustomerInfo from "../../RestCustomerInfo";
import RestPaymentWidget from "../../RestPaymentWidget";
import { RestProducts } from "../../RestProducts";
import { ArrowLeft16 } from '@carbon/icons-react'


class Pos extends Page {

    state = {

    }

    posState = PosState.cartSession(() => this.state, this.setState.bind(this), this.props.cart.cartId, {
        onClose: () => this.props.onClose()
    });

    onPageStart() {
        this.posState.loadSession(false);
    }

    componentWillUnmount() {
        this.posState.cancelSubscriptions();
    }

    getLayout() {
        return (
            <div className="pos" style={{ display: 'flex', height: '100%', width: '100%', padding: '0rem', userSelect: 'none', WebkitUserSelect: 'none', }} >
                {this.posState.getSession() && <LiveCart state={this.posState} />}

                <div style={{ height: '100%', flex: 1.25, display: 'flex', flexDirection: 'column', background: '#f4f4f4' }}>
                    {/* <RestCartContent state={this.posState} noList /> */}
                    <div style={{ flex: 1 }}>
                        <RestProducts state={this.posState} />
                    </div>
                </div>
                <div style={{ height: '100%', flex: 1, display: 'flex', flexDirection: 'column', }}>

                    <div style={{ position: 'relative', flex: 2, minHeight: 0, }}>
                        {!(this.posState?.shouldShowTableSelect?.()) &&
                            <div style={{ width: '100%', position: 'absolute', left: 0, top: '-3rem', bottom: 0, zIndex: 8001, background: '#ffffff', borderLeft: '0px solid #F1F1F1', boxShadow: '0px 8px 17px 2px rgba(0,0,0,0.14) , 0px 3px 14px 2px rgba(0,0,0,0.12) , 0px 5px 5px -3px rgba(0,0,0,0.2) ' }}>
                                <RestCartContent noHeader state={this.posState}>
                                    <div style={{ minHeight: 64, background: '#1c1c1c', color: 'white', borderTop: '0px solid #00000020', borderBottom: '1px solid #ffffff10', }}>
                                        <RestCustomerInfo state={this.posState} responsive />
                                    </div>
                                    <RestPaymentWidget state={this.posState} />
                                </RestCartContent>
                            </div>}
                    </div>
                </div>


                <PosDialogs tab={"pos"} posState={this.posState} />
            </div>
        )
    }

    isPageLoading() {
        return this.posState.isLoadingPos() || !this.posState.getSession()
    }

}


export function EditCartView({ cart, onClose }) {
    return (
        <div style={{ height: '100%', width: '100%', }}>
            <div style={{ background: 'white', color: 'black', height: '3rem', width: '100%', borderBottom: '1px solid #00000020', paddingInline: '1rem', display: 'flex', alignItems: 'center' }}>
                <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <ArrowLeft16 />
                </button>
                <h4 style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>Cart</h4>
                <div style={{ flex: 1 }} />
                <p>Table {cart.tableNo}</p>
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 3rem - 2px)', display: 'flex' }}>
                <Pos cart={cart} onClose={onClose} />
            </div>
        </div>
    )
}