import { useParams } from "react-router-dom";
import { OBJECT_TYPE_ACTIVITY_BLUEPRINT } from "../../../../constants/ObjectTypes";
import { FormControls, FormLayout } from "../../../../templates/form/FormLayout";
import { FormSection, useForm } from "../../../../templates/form/form";
import { ButtonSet, OverflowMenu, OverflowMenuItem, TextInput } from "carbon-components-react";
import { createParamsFields, createParamsFieldsNew, DATA_TYPES, getStatusesDataTypes, makeParamsField } from "../../../../domain/activity/activity-blueprint";
import Util from "../../../../util/Util";
import { useDraggable } from "../../../../hooks/useDraggable";
import { AddFilled32, CheckmarkFilled16 } from '@carbon/icons-react'
import { FormEditor } from "../../../../views/custom-form-editor/FormEditor";
import { useEffect, useMemo, useState } from "react";
import MyStateDraftsDialog from "../../../../views/state-drafts/MyStateDraftsDialog";
import { STATE_TYPE_ACTIVITY_BLUEPRINT, STATE_TYPE_STOCK_FLOW_CREATOR } from "../../../../constants/Constants";
import { useDraft } from "../../../../templates/draft/draft";
import { createFormLoader, fixIdForApi, useFormApi } from "../../../../templates/form/form-api";
import { LogicNodeEditorDialog } from "../../../../views/logic-node-editor/LogicNodeEditorDialog";
import { AutomationListEditor } from "../../components/automation-list";
import { NotificationListEditor } from "../../components/notification-list";
import { withLoadablePageWithParams } from "../../../../base/Page";
import Api from "../../../../session/Api";
import ErrorPage from "../../../../components/page-messages/ErrorPage";
import { CirclePicker } from "react-color";
import { hasCapabilitySupport } from "../../../../app/Capabilities";

const ColorField = ({ value, setValue }) => (
    <>
        {/* <label className="bx--label">Highlight Color</label> */}
        <div style={{ display: 'flex' }}>
            <div style={{ borderRadius: 5, padding: '1rem', background: '#1c1c1c' }}>
                <CirclePicker
                    color={value}
                    onChangeComplete={e => setValue(e.hex)}
                />
            </div>
        </div>
    </>
)

const InputFields = ({ value: items, setValue: setItems }) => (
    <FormEditor itemHeaderTitle="Field" items={items} setItems={setItems} dateTypes={DATA_TYPES} />
)

const StatusInputFields = ({ value: items, setValue: setItems }) => (
    <FormEditor headerColor="#003552" itemHeaderTitle="Field" items={items} setItems={setItems} dateTypes={DATA_TYPES} />
)

const SupportedStatuses = ({ value: items, setValue: setItems, statusDataTypes }) => (
    <FormEditor unqiueOnly headerColor={item => item.color} itemHeaderTitle="Status" items={items} setItems={setItems} dateTypes={statusDataTypes} />
)

const Notifications = ({ value, setValue }) => (
    <NotificationListEditor defaultItems={value} onUpdate={setValue} />
)

const AutomationRules = ({ value, setValue, getConfigOptions }) => (
    <AutomationListEditor defaultItems={value} onUpdate={setValue}
        getConfigOptions={getConfigOptions}
    />
)

const SupportedStatusesField = ({ CustomField, statusDataTypes }) => {
    const SupportedStatusesView = useMemo(() => (props => <SupportedStatuses {...props} statusDataTypes={statusDataTypes} />), [statusDataTypes])
    return (
        <CustomField title="Supported Statuses" fieldKey="supportedStatuses" defValue={[]}>
            {SupportedStatusesView}
        </CustomField>
    )
}

const Form = ({ isCreating, item, objectType, objectId, statuses }) => {
    const statusDataTypes = useMemo(() => getStatusesDataTypes(StatusInputFields, statuses), [statuses])

    const { TextField, SwitchField, CustomField, CheckBoxField, ...form } = useForm();
    const draft = useDraft(STATE_TYPE_ACTIVITY_BLUEPRINT, data => {
        if (data) {
            createParamsFields(data, statusDataTypes)
        }
        form.setData(data ? data : undefined)
    }, form.getFormData);
    const formApi = useFormApi(objectType, objectId)

    useEffect(() => {
        if (item) {
            form.setData(prepareApiDataForUse(item, statusDataTypes))
        }
    }, [item])

    const getConfigOptions = () => {
        const data = form.getFormData();
        return {
            statuses: statuses,
            fields: (data.inputFields ?? []).concat((data.supportedStatuses ?? []).flatMap((item) => item?.params?.inputFields ?? []))
        }
    }

    return (
        <FormLayout objectType={objectType} objectId={objectId} objectTitle={item?.name} options={isCreating ? draft.draftManagement : null}>
            <div style={{ padding: '1rem' }}>
                <FormSection title="Blueprint Info">
                    <TextField title="Name" fieldKey="name" />
                    <CheckBoxField title="Auto-select" fieldKey="autoSelect" />
                    {hasCapabilitySupport("lms") &&
                        <CheckBoxField title="Show for student" fieldKey="showForStudent" />}
                    {hasCapabilitySupport("lms") &&
                        <CheckBoxField title="Require payment package in courses" fieldKey="courseActivityRequirePaymentPackage" />}
                    <CustomField title="Highlight Color" fieldKey="color" defValue="">
                        {ColorField}
                    </CustomField>
                </FormSection>
                <FormSection title="Dynamic Forms">
                    <CustomField title="Activity Creation Fields" fieldKey="inputFields" defValue={[]}>
                        {InputFields}
                    </CustomField>
                    <SupportedStatusesField CustomField={CustomField} statusDataTypes={statusDataTypes} />
                </FormSection>
                <FormSection title="Notification">
                    <CustomField title="Notifications" fieldKey="notifications" defValue={[]}>
                        {Notifications}
                    </CustomField>
                </FormSection>
                {/* <FormSection title="Automation">
                    <CustomField title="Automation Rules" fieldKey="automationRules" defValue={[]} childrenProps={{ getConfigOptions }}>
                        {AutomationRules}
                    </CustomField>
                </FormSection> */}

                <FormControls
                    objectType={objectType}
                    onDeleteBtn={() => deleteItem(formApi, item)}
                    deleting={formApi.deleting}

                    onSave={() => saveItem(formApi, form.getFormData())}
                    saving={formApi.saving}
                    isCreating={formApi.isCreating}
                    onSaveDraft={() => draft.setShowSaveDraft(true)}
                    selectedDraft={draft.selectedDraft}
                />

                {draft.draftDialogs}

                {/* <pre><code>{JSON.stringify(form.getFormData(), null, 2)}</code></pre> */}
            </div>
        </FormLayout>
    )
}


// const statuses = [{ id: 2, name: "Open", color: '#990000' }, { id: 23, name: "Closed" }, { id: 332, name: "Requires Arabic" }]

function prepareApiDataForUse(item, statusDataTypes) {
    const data = createParamsFieldsNew(item, statusDataTypes)
    if (data.automationRules) {
        data.automationRules = data.automationRules.map(rule => ({ ...rule, logic: Util.isStringExists(rule.logic) ? JSON.parse(rule.logic) : {} }))
    }
    return data;
}

function saveItem(formApi, formData) {
    const data = fixIdForApi(formData, data => {
        if (data.automationRules) {
            data.automationRules = data.automationRules.map(rule => ({ ...rule, logic: JSON.stringify(rule.logic) }))
        }
    });

    formApi.save(listener => Api.saveActivityBlueprint(data, listener));
}

function deleteItem(formApi, item) {
    formApi.deleteItem(listener => Api.deleteActivityBlueprint(item.id, listener))
}

function Page({ payload: { statuses, activityBlueprint: item } }) {
    const { itemId } = useParams();
    const isCreating = itemId === "new";

    if (!isCreating && !item) {
        return <ErrorPage error404 />
    }

    return (
        <Form isCreating={isCreating} objectType={OBJECT_TYPE_ACTIVITY_BLUEPRINT} objectId={isCreating ? 0 : parseInt(itemId)} statuses={statuses} item={item} />
    )
}

export const ActivityBlueprintDetailPage =
    withLoadablePageWithParams(createFormLoader(Api.getActivityBlueprint), Page);