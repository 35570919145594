import { Tag, TextArea, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import {
    Time16, Chat16, Alarm16, Hourglass16, Attachment16, Edit16, CheckmarkFilled16, CloseFilled16
} from '@carbon/icons-react'
import ProfilePic from "../../components/ProfilePic";
import { ProfilePicGroup } from "../../components/ProfilePicGroup";

function useFadeInAnim(scrollInto) {
    const [fadeInAnim, setFadeInAnim] = useState(false);
    useEffect(() => {
        if (scrollInto) {
            setFadeInAnim(true);
        }
    }, [scrollInto])
    return fadeInAnim;
}

export const CardTimeView = () => {
    const inTime = false;
    const timePassed = true;
    const successTimeOver = false;

    if (!inTime && !timePassed) {
        return (
            <div style={{
                width: '100%', height: 30, background: '#ffffff20', paddingBottom: '0.15rem', border: '1px solid #ffffff20', borderInline: 0,
                display: 'flex', alignItems: 'center', paddingInline: '1rem'
            }}>
                <Time16 />
                <p style={{ marginLeft: '0.5rem', fontSize: 12, opacity: 0.65, marginTop: 2 }}>16th Set</p>
            </div>
        )
    } else if (inTime) {
        return (
            <div style={{
                width: '100%', height: 30, background: '#00009920', paddingBottom: '0.15rem', border: '1px solid #ffffff20', borderInline: 0,
                display: 'flex', alignItems: 'center', paddingInline: '1rem'
            }}>
                <Alarm16 />
                <p style={{ marginLeft: '0.5rem', fontSize: 12, opacity: 0.65, marginTop: 2, flex: 1 }}><strong>Deadline:</strong> 17 Nov</p>
                <p style={{ fontSize: 12, opacity: 0.65, marginTop: 2 }}>07:32</p>
            </div>
        )
    } else {
        return (
            <div style={{
                width: '100%', height: 30, background: successTimeOver ? '#00990020' : '#99000020', paddingBottom: '0.15rem', border: '1px solid #ffffff20', borderInline: 0,
                display: 'flex', alignItems: 'center', paddingInline: '1rem'
            }}>
                <Hourglass16 />
                <p style={{ marginLeft: '0.5rem', fontSize: 12, opacity: 0.65, marginTop: 2 }}>Time over</p>
            </div>
        )
    }
}

export const CardView = ({ scrollInto, item, onContentUpdate, onCommentBtn, onNoteBtn }) => {
    const contentValue = item?.content ?? '';
    const noteCount = 0;
    const commentCount = 0;

    const [edit, setEdit] = useState(false);
    const [editValue, setEditValue] = useState()
    const fadeInAnim = useFadeInAnim(scrollInto);

    useEffect(() => {
        if (edit) {
            setEditValue(contentValue);
        }
    }, [edit])
    useEffect(() => {
        setEdit(edit => edit ? false : edit)
    }, [contentValue])

    return (
        <div data-no-dnd={edit ? "true" : undefined} className="card-view"
            style={{
                width: '100%', borderRadius: 5,
                boxShadow: '0px 25px 50px -12px rgba(0,0,0,0.25)',
                // background: haveBackground ? "#000000" : undefined,
                // color: 'white',
                animation: fadeInAnim ? 'board-view-item-fade-in-anim 250ms' : ''
            }}>
            {/* <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem' }}>
                <h6 style={{ flex: 1 }}>Title here {idd}</h6>
                <Tag size="sm" type="cyan">Type</Tag>
            </div> */}
            {/* <CardTimeView /> */}

            <div style={{ marginBottom: '0rem', padding: '0.5rem' }}>
                {/* <p>{item.id}</p> */}
                {edit ? (
                    <div className="auto-grow-text-area-wrap" data-replicated-value={editValue}>
                        <textarea data-no-dnd="true" value={editValue} rows={1} onChange={e => setEditValue(e.target.value)} />
                    </div>
                ) : (
                    <p style={{ fontSize: 14, whiteSpace: 'pre-line' }}>{contentValue}</p>
                )}
            </div>
            <div style={{ padding: '0.5rem', gap: '0.5rem', display: 'flex', alignItems: 'flex-end' }}>
                {/* <div data-no-dnd="true" style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', cursor: 'pointer' }} onClick={onNoteBtn}>
                    <Attachment16 />
                    <span>{noteCount}</span>
                </div>
                <div data-no-dnd="true" style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', cursor: 'pointer' }} onClick={onCommentBtn}>
                    <Chat16 />
                    <span>{commentCount}</span>
                </div> */}
                <div style={{ flex: 1 }} />
                {edit ? (<>
                    <div data-no-dnd="true" style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', cursor: 'pointer', color: '#990000', background: 'white', borderRadius: '50%', animation: 'board-view-column-fade-in-anim 250ms' }} onClick={() => setEdit(false)}>
                        <CloseFilled16 />
                    </div>
                    <div data-no-dnd="true" style={{ opacity: editValue === contentValue ? 0.4 : 1, display: 'flex', gap: '0.15rem', alignItems: 'center', cursor: editValue === contentValue ? undefined : 'pointer', color: 'green', background: 'white', borderRadius: '50%', animation: 'board-view-column-fade-in-anim 250ms' }} onClick={() => onContentUpdate(editValue)}>
                        <CheckmarkFilled16 />
                    </div>
                </>) : (
                    <div data-no-dnd="true" style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', cursor: 'pointer' }} onClick={() => setEdit(true)}>
                        <Edit16 />
                    </div>
                )}
                {/* <ProfilePicGroup size={32} srcs={['', '', '', '', '', '', '', '',]} /> */}
            </div>
        </div>
    )
}

export const NoItem = () => (
    <div className="no-item-card" style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ fontSize: 12, opacity: 0.65 }}>No items exist</p>
    </div>
)