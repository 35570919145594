import Button from "../../../components/Button";
import { Edit16 } from '@carbon/icons-react'
import { Modal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { useState } from "react";


export function EditAddressButton({ salesOrderId, address, setAddress }) {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button onClick={() => setVisible(true)} size="sm" style={{ borderRadius: 50 }} renderIcon={Edit16}>Edit Address</Button>

        <FormDialog open={visible} onClose={() => setVisible(false)} label="Address" title="Update" item={address} setItem={setAddress}
            saveApi={(data, listener) => Api.updateSalesOrderAddress(salesOrderId, data, listener)}>
            {({ TextField, SwitchField, }) => (<>
                <TextField title="First Name" fieldKey="firstName" />
                <TextField title="Last Name" fieldKey="lastName" />
                <TextField title="Phone Number" fieldKey="phoneNumber" />
                <TextField title="Email Address" fieldKey="emailAddress" />
                <TextField title="Area" fieldKey="area" />
                <TextField title="Address Line #1" fieldKey="addressLineOne" />
                <TextField title="Address Line #2" fieldKey="addressLineTwo" />
                <SwitchField title="Type" fieldKey="type" options={[{ id: 0, title: "Personal" }, { id: 1, title: "Business" }]} />
                <TextField title="Unit No" fieldKey="unitNo" />
                <TextField title="Zip Code" fieldKey="zipCode" />
                <TextField title="City" fieldKey="city" />
                <TextField title="State/Province/Emirate" fieldKey="state" />
                <TextField title="Country" fieldKey="country" />
            </>)}
        </FormDialog>
    </>)
}