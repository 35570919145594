import { useMemo } from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from 'array-move';



export function makeDraggable(record, handler) {
    const SortItem = SortableElement(record);
    const SortContainer = SortableContainer(({ children }) => <div>{children}</div>);
    const SortHandler = SortableHandle(handler);

    return {
        SortContainer: props => <SortContainer helperClass={props.inModal ? 'sortableHelper' : undefined} useDragHandle {...props} />,
        SortItem: props => <SortItem SortHandler={SortHandler} {...props} />,
        SortHandler,
        createOnSortEnd: (items, setItems) => ({ oldIndex, newIndex }) => setItems(arrayMove(items, oldIndex, newIndex))
    }
}

export function useDraggable(record, handler) {
    return useMemo(() => makeDraggable(record, handler), []);
}