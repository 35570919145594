
class HistoryManager {

    actions = []
    historyIndex = 0;

    recordAction({run, reverse}) {
        if (this.canRedo()) {
            this.actions.splice(this.historyIndex);
        }
        this.actions.push({ run, reverse });
        this.historyIndex++;

        if (this.actions.length == 25) {
            this.actions.splice(0, 1);
            this.historyIndex--;
        }
    }

    redo() {
        if (!this.canRedo()) {
            return;
        }

        this.actions[this.historyIndex].run();
        this.historyIndex++;
    }

    undo() {
        if (!this.canUndo()) {
            return;
        }

        this.historyIndex--;
        this.actions[this.historyIndex].reverse();
    }

    canRedo() {
        return this.historyIndex < this.actions.length;
    }

    canUndo() {
        return this.historyIndex > 0;
    }

}

export default HistoryManager;