import { Tag } from 'carbon-components-react'
import React from 'react'

export default ({ type }) => {
    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

    return (
        <Tag
        style={{color: isLightColor(type.color ?? "#000000") ? "black" : "white", 
        background: type.color ?? "#000000", transition: '250ms',}}>{type.name}</Tag>
    )
}