import { TextInput } from "carbon-components-react"
import { makeListInput } from "../../../../../components/list-input"
import { UploadField } from "../../../../../components/upload/upload-field"
import { ComboBoxField } from "../../../../../components/ComboBoxField"
import { OBJECT_TYPE_VENDOR } from "../../../../../constants/ObjectTypes"
import { Close16 } from '@carbon/icons-react'

export const Bills = makeListInput(({ item, setItemValue, updateItem, onRemove, SortHandler, vendors }) => (
    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <SortHandler />
            <div style={{ flex: 1 }} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>

        <ComboBoxField title="Vendor" options={Array.isArray(vendors) ? vendors : []} value={item.otherPartyId} setValue={value => {
            updateItem({
                otherPartyId: value,
                otherPartyType: OBJECT_TYPE_VENDOR,
            })
        }} />
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Bill No" value={item.billNo} onChange={e => setItemValue('billNo', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Amount" value={item.amount} onChange={e => setItemValue('amount', e.target.value)} />
            </div>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Invoiceable Amount" value={item.invoiceableAmount} onChange={e => setItemValue('invoiceableAmount', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Invoice Description" value={item.invoicableAmountDescription} onChange={e => setItemValue('invoicableAmountDescription', e.target.value)} />
            </div>
        </div>
        <UploadField value={item.document} setValue={value => setItemValue("document", value)} label="Document" />
    </div>
), () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
))
