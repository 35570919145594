import { Launch16, TagEdit24, RowDelete16, CutOut16, Gift16, SubtractAlt16 } from '@carbon/icons-react'
import { ButtonSet } from 'carbon-components-react'
import React, { useRef, useState, useEffect } from 'react'
import Button from '../../../components/Button'
import { POS_SESSION_DISCOUNT_TYPE_COUPON, POS_SESSION_DISCOUNT_TYPE_FREE_FORM, POS_SESSION_DISCOUNT_TYPE_LOYALTY } from '../../../constants/Constants'
import Util, { big } from '../../../util/Util'
import Modal from 'react-modal';
import useSize from '../../../util/useSize'
import UIUtil from '../../../util/UIUtil'
import ReactTooltip from 'react-tooltip';

const HEIGHT_MIN = 180;
const HEIGHT_MID = 276;

const OPTIMAL_WIDTH = 550;
const OPTIMAL_HEIGHT = 387;

const DiscountItem = ({ state, discount }) => {
    const [deleting, setDeleting] = useState(false);


    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            setDeleting(true);
            const listener = success => {
                if (!success) {
                    setDeleting(false)
                }
            }

            // if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
            //     state.setApplyLoyaltyPoints(false, listener);
            // } else {
            //     state.setApplyCoupon(discount.couponCode, false, listener)
            // }
            state.removeDiscount(discount.id, listener)
            ReactTooltip.hide()
        })
    }


    let title;
    if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
        title = "Loyalty Points";
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_COUPON) {
        title = "Coupon" + (Util.isStringExists(discount.couponCode) ? " (" + discount.couponCode + ")" : "");
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_FREE_FORM) {
        title = "Free-form Discount"
    }

    let desc;
    if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
        desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_COUPON) {
        if (discount.subtractingAmount !== undefined && discount.subtractingAmount !== null) {
            desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
        } else if (discount.maxSubtractingPercentageAmount && parseFloat(parseFloat(discount.maxSubtractingPercentageAmount).toFixed(2)) > 0) {
            desc = "Subtracting " + discount.subtractingPercentage + "% (max: " + discount.maxSubtractingPercentageAmount + ")";
        } else {
            desc = "Subtracting " + discount.subtractingPercentage + "%";
        }
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_FREE_FORM) {
        if (discount.subtractingAmount !== undefined && discount.subtractingAmount !== null) {
            desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
        } else {
            desc = "Subtracting " + discount.subtractingPercentage + "%";
        }
    }


    const active = state.getRawSubtotal() >= discount.minimumAmountRequired;
    if (!active) {
        desc = `Not applicable. Minimum order amount not reached (${state.getRawSubtotal().toFixed(2)} < ${discount.minimumAmountRequired.toFixed(2)})`
    }


    return (
        <div style={{ width: '100%', padding: '0.5rem', display: 'flex', background: 'white', color: active ? 'green' : 'rgba(0,0,0,0.50)' }}>
            <div style={{ flex: 1 }}>
                <h5 style={{ fontSize: 14 }}>{title}</h5>
                <p style={{ fontSize: 12 }}>{desc}</p>
            </div>
            <Button style={{ height: 48 }} onClick={onDeleteBtn} loading={deleting} hasIconOnly data-tip="Remove" renderIcon={RowDelete16} className="bx--tooltip--hidden" kind="danger--tertiary" />
        </div>
    )
}

const Content = ({ state, size, mini, showDiscountOnly, hideLoyalty }) => {
    return (<>
        {size.height >= HEIGHT_MID && <div style={{ display: 'flex', alignItems: 'center', marginBottom: ' 1rem' }}>
            <TagEdit24 style={{ marginRight: 5 }} />
            {/* <h4>Coupons/Points</h4> */}
            <h4>Discounts</h4>
        </div>}

        {!mini ? (<>
            {/* <p style={{fontSize: 12}}>Applied coupons/points:</p> */}
            <p style={{ fontSize: 12 }}>Applied discounts:</p>
            <div style={{ flex: 1, gap: '0.25rem', display: 'flex', flexDirection: 'column', overflow: 'auto', paddingBottom: '0.25rem', paddingTop: '0.25rem' }}>
                {state.getSession().discounts.length > 0 ? (
                    state.getSession().discounts.map(discount => <DiscountItem key={discount.id + "-discount"} state={state} discount={discount} />)
                ) :
                    // <p style={{opacity: 0.45, fontSize: 12}}>No coupons/points applied</p>
                    <p style={{ opacity: 0.45, fontSize: 12 }}>No discounts applied</p>
                }
            </div>

            {size.width >= 310 ? <ButtonSet>
                {!showDiscountOnly && <>
                    <Button onClick={() => state.setShowCouponSelectDialog(true)} loading={state.isLoadingAddingCoupon()} style={{ flex: 1 }} kind="secondary" renderIcon={CutOut16}>Apply Coupon</Button>
                    {!hideLoyalty && state.getLoyaltyPointDiscount() === undefined && (
                        <Button onClick={() => state.setApplyLoyaltyPoints(true)} loading={state.isLoadingAddingDiscount()} style={{ flex: 1 }} renderIcon={Gift16} disabled={!state.hasLoyaltyCard()}>Apply Loyalty Points</Button>
                    )}
                </>}
                <Button onClick={() => state.setShowFreeFormDiscountDialog(true)} loading={state.isShowFreeFormDiscountDialog()} style={{ flex: 1 }} kind="secondary" renderIcon={SubtractAlt16}>Apply Discount</Button>
            </ButtonSet> : <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                {!showDiscountOnly && <>
                    <Button data-tip="Apply Coupon" onClick={() => state.setShowCouponSelectDialog(true)} loading={state.isLoadingAddingCoupon()} kind="secondary" renderIcon={CutOut16} hasIconOnly />
                    {!hideLoyalty && state.getLoyaltyPointDiscount() === undefined && (
                        <Button data-tip="Apply Loyalty Points" onClick={() => state.setApplyLoyaltyPoints(true)} loading={state.isLoadingAddingDiscount()} renderIcon={Gift16} disabled={!state.hasLoyaltyCard()} hasIconOnly />
                    )}
                </>}
                <Button data-tip="Apply Discount" onClick={() => state.setShowFreeFormDiscountDialog(true)} loading={state.isShowFreeFormDiscountDialog()} kind="secondary" renderIcon={SubtractAlt16} hasIconOnly />
            </div>}
        </>) : <>
            <p style={{ fontSize: 12, marginBottom: '0.25rem' }}>
                {/* {state.getSession().discounts.length > 0 ? `Coupons/Points applied (${state.getSession().discounts.length})` : 'No coupons/points applied'} */}
                {state.getSession().discounts.length > 0 ? `Discounts applied (${state.getSession().discounts.length})` : 'No discounts applied'}
            </p>

            {/* <Button onClick={mini} style={{width: '100%'}} kind="secondary" renderIcon={Launch16}>Coupons/Points</Button>    */}
            <Button onClick={mini} style={{ width: '100%' }} kind="secondary" renderIcon={Launch16}>Discounts</Button>
        </>}


    </>)
}

export default ({ state, showDiscountOnly, hideLoyalty }) => {
    const targetRef = useRef();
    const size = useSize(targetRef)
    const content = <Content state={state} size={size} showDiscountOnly={showDiscountOnly} hideLoyalty={hideLoyalty} />

    const [contentOpen, setContentOpen] = useState(false)

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [size])

    return (
        <div className="dashboard-card" ref={targetRef} style={{ width: '100%', height: '100%', background: '#f4f4f4', padding: '1rem', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            {size.height > HEIGHT_MIN ? (
                content
            ) : (<>
                {React.cloneElement(content, { mini: () => setContentOpen(true) })}

                <Modal
                    isOpen={contentOpen}
                    onRequestClose={() => setContentOpen(false)}
                    style={{
                        overlay: { zIndex: 8999, background: 'rgba(0,0,0,0.75)' },
                        content: {
                            height: OPTIMAL_HEIGHT, width: OPTIMAL_WIDTH, background: 'rgb(244, 244, 244)', border: 'none',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex', flexDirection: 'column', borderRadius: 0
                        }
                    }}
                >
                    {React.cloneElement(content, { size: { height: 10000, width: 10000 } })}
                </Modal>
            </>)}
        </div>
    )
}