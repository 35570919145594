import { ComposedModal, Link, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import ProfilePic from "../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_COMMENT } from "../../constants/ObjectTypes";
import Api from "../../session/Api";
import Portal from "../../util/Portal";
import Util from "../../util/Util";
import { useComment } from "./comment-controller"
import { CommentView } from "./comment-view";
import { hasCapabilitySupport } from "../../app/Capabilities";

const ReplyBtn = ({ comment }) => {
    const [visible, setVisible] = useState(false);
    return (<>
        <Link onClick={() => setVisible(true)} size="sm" style={{ cursor: 'pointer' }}>Reply</Link>

        {visible && <Portal>
            <ComposedModal className="no-outline-modal" size="md" open onClose={() => setVisible(false)}>
                <ModalHeader label="Commenting" title="Replying" />
                <ModalBody style={{ paddingRight: '1rem' }} hasScrollingContent>
                    <div style={{ background: '#1c1c1c', padding: '0.5rem', borderRadius: 15 }}>
                        <CommentItem readonly noMargin commentItem={comment} />
                    </div>
                    <div style={{ marginTop: '3rem', width: '100%', height: 250 }}>
                        <CommentView itemId={comment.id} itemType={OBJECT_TYPE_COMMENT} />
                    </div>
                </ModalBody>
                <ModalFooter style={{ justifyContent: 'flex-end' }}>
                    <Button kind="secondary" onClick={() => setVisible(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </Portal>}
    </>)
}

export function CommentItem({ noMargin, readonly, commentItem, sendComment, lastItem }) {
    const ref = useRef();
    const { sending, failed, comment, redacting, redactComment } = useComment(commentItem, sendComment);

    useEffect(() => {
        if (sending) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
    }, [lastItem, sending])

    return (
        <div ref={ref} style={{
            display: 'flex', alignItems: 'flex-start', gap: '0.5rem', margin: noMargin ? 0 : '1rem', opacity: failed || comment.redacted ? 0.65 : 1,
            //animation: sendComment !== undefined ? 'opacity-fade-in-lg-anim 250ms' : '' 
            animation: sendComment !== undefined ? 'comment-fade-in-anim 250ms' : ''
        }}>
            <ProfilePic size={32} style={{ marginTop: '1rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, comment.userId)} />
            <div style={{ flex: 1, borderRadius: 15, padding: '1rem', background: failed ? '#99000020' : 'white', boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.1) , 0px 1px 2px 0px rgba(0,0,0,0.06) ' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem' }}>
                    <h6 style={{ fontSize: 12 }}>{comment.sender}</h6>
                    {failed ? (
                        <p style={{ flex: 1, textAlign: 'end', fontSize: 14, fontWeight: 'bold', color: 'red', opacity: 0.65 }}>Failed to send</p>
                    ) : (
                        <p style={{ flex: 1, textAlign: 'end', fontSize: 12, opacity: 0.65 }}>{Util.getDate(comment.dateCreated)}</p>
                    )}
                </div>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <p style={{ fontSize: 14, whiteSpace: 'pre-wrap', flex: 1 }}>{comment.content}</p>
                    {!comment.redacted && !failed && !sending && !readonly &&
                        <div style={{ display: 'flex', gap: '0.5rem', paddingTop: '0.25rem', justifyContent: 'flex-end' }}>
                            {comment.parentType != OBJECT_TYPE_COMMENT && !hasCapabilitySupport("cargo") &&
                                <ReplyBtn comment={comment} />}
                            {comment.senderIsSelf &&
                                <Link onClick={redactComment} size="sm" style={{ cursor: 'pointer', color: '#990000', opacity: redacting ? 0.65 : 1, pointerEvents: redacting ? 'none' : undefined }}>Delete</Link>}
                        </div>}
                </div>
            </div>
        </div>
    )
}


// if (failed) {
//     return <p style={{ color: 'red' }}>failed</p>
// }
// if (sending) {
//     return <p>sending...</p>
// }

// return (
//     <div>
//         <h6 style={{ opacity: comment.redacted ? 0.25 : 1 }}>{JSON.stringify(comment)}</h6>
//         <a style={{ pointerEvents: redacting ? 'none' : undefined }} onClick={redactComment}>redact</a>
//     </div>
// )