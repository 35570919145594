import { InlineLoading, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput, Select, SelectItem } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import Api from '../../session/Api';
import Util from '../../util/Util';
import { WarningFilled32, Screen20, Printer20 } from '@carbon/icons-react'
import UIUtil from '../../util/UIUtil';
import { getTerminal, getThermalPrinterDriverId, getThermalPrinterParams, saveTerminal, saveThermalPrinterDriverId, saveThermalPrinterParams } from '../../session/SessionManager';
import { ACCOUNT_TYPE_ADMINISTRATION } from '../../constants/Constants';
import { getThermalPrinterDriverParams, THERMAL_PRINTER_DRIVERS } from '../../integrations/thermal-printer/ThermalPrinterDriverList';
import Button from '../../components/Button';

export default ({open, onClose, onAuth, title}) => {
    const [loading, setLoading] = useState(false);
    const [clearanceCode, setClearanceCode] = useState("")

    useEffect(() => {
        setClearanceCode("")
    }, [open])

    const onAuthorizeBtn = () => {
        setLoading(true);
        Api.securityClearanceValidate(clearanceCode, ACCOUNT_TYPE_ADMINISTRATION, response => {            
            if (response.status === true) {
                UIUtil.showSuccess("Clearance code authenticated")
                onClose();
                onAuth();
            } else {
                UIUtil.showError("Clearance code could not be used for authorization");
            }
            setLoading(false)
        })
    }

 
    return (
        <ComposedModal open={open} onClose={onClose} size="sm">
            <ModalHeader label="Security Clearance" title={title} />
            <ModalBody style={{paddingRight: '1rem'}}>
                <div style={{}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3rem', marginTop: '1rem'}}>
                        <WarningFilled32 />
                        <h2 style={{fontSize: 16, marginLeft: '1rem',}}>Security clearance required!</h2>
                    </div>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{width: '75%', height: 64, overflow: 'hidden'}}>
                            <TextInput.PasswordInput 
                            value={clearanceCode}
                            onChange={e => setClearanceCode(e.target.value)}
                            style={{width: '100%',}}
                            data-modal-primary-focus
                            labelText="Clearance Code"
                            placeholder="Input to continue"
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter 
            // primaryButtonDisabled={loading}
            // onRequestSubmit={onAuthorizeBtn}
            // primaryButtonText={(loading ? "Authorizing..." : "Authorize")} secondaryButtonText="Cancel"
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button 
                disabled={loading}
                onClick={onAuthorizeBtn}>
                    {(loading ? "Authorizing..." : "Authorize")}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}