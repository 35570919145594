import React, { useEffect, useState } from 'react'
import Util from '../util/Util';

export default (props) => {
    const [dateText, setDateText] = useState(Util.getCurrentTimeAndDay());

    useEffect(() => {
        const clockInterval = setInterval(() => {
            const newDateText = Util.getCurrentTimeAndDay();
            if (newDateText != dateText) {
                setDateText(newDateText);
            }
        }, 1000);
        return () => clearInterval(clockInterval);
    }, [])

    return props.children(dateText)
}