import { DataTableSkeleton } from "carbon-components-react";
import Page from "../../base/Page";
import DeliverablesListView from "./DeliverablesListView";

class DeliverablesPage extends Page {

    render() {
        return (
            <div className="main-content">
                <DeliverablesListView />
            </div>
        )
    }

}

export default DeliverablesPage;