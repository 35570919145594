


import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../constants/Constants";
import { CheckmarkFilled16, ErrorFilled16, WarningFilled16, IncompleteWarning16, Money16, Misuse16, Error16, Search16, Time16, Renew16, DeliveryParcel16, Delivery16, WatsonHealthStackedScrolling_116 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react"


export const PROPERTY_DEAL_STATUS = {
    UN_POSTED: 0,
    POSTED: 1
}

export function getPropertyDealStatusName(status) {
    switch (status) {
        case PROPERTY_DEAL_STATUS.UN_POSTED:
            return "Unposted";
        case PROPERTY_DEAL_STATUS.POSTED:
            return "Posted";
    }
}

export function getPropertyDealStatusDetails(status) {
    switch (status) {
        case PROPERTY_DEAL_STATUS.UN_POSTED:
            return { type: "high-contrast", text: getPropertyDealStatusName(status), icon: IncompleteWarning16 };
        case PROPERTY_DEAL_STATUS.POSTED:
            return { type: "green", text: getPropertyDealStatusName(status), icon: CheckmarkFilled16 };
    }
}

export const PropertyDealStatusIndicator = ({ status }) => {
    let { icon, text, type } = getPropertyDealStatusDetails(status);
    return (
        <div>
            <Tag size="md" renderIcon={icon} type={type}>
                {text}
            </Tag>
        </div>
    )
}