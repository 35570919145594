import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR, ACCOUNT_TYPE_WAREHOUSE_KEEPER } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24 } from '@carbon/icons-react'
import { PackingListForm } from "./form"
import { CreatePackingListForm } from "./create-form"
import { InvoiceSelectField } from "../../../components/invoice-select-field"
import { TextBox } from "../../components/basic-filter/text-box"

export const PackingList = newListDetail({
    title: "Packing List",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: CreatePackingListForm, updateForm: PackingListForm,

    defaultValues: {
        items: []
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_WAREHOUSE_KEEPER;
    },

    noTitle: true,
    fullScreenSideViewUpdateForm: true,
    skipInlinePadding: true,
    openDetailOnCreate: true,
})

function Filter({ endpoint, state }) {
    return (<>
        {/* <div className="list-mode-divider-quick-filter-bar" /> */}

        <div style={{ flex: 1 }}>
            <TextBox state={state} label="Carton No" property="cartonNo" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} label="Invoice No" property="invoiceNo" fullWidth />
        </div>
    </>)
}
