import { useEffect } from "react"


export function useLockScroll(lock) {
    useEffect(() => {
        if (lock) {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByTagName('body')[0].style.overflow = 'visible'
        }
        return () => document.getElementsByTagName('body')[0].style.overflow = 'visible';
    }, [lock])
}

export function useForceLockScroll(enabled) {
    useEffect(() => {
        if (enabled) {
            document.body.classList.add("forced-blocked-body");
        } else {
            document.body.classList.remove("forced-blocked-body");
        }
        return () => document.body.classList.remove("forced-blocked-body");
    }, [enabled]);
}

export const LockScroll = () => {
    useLockScroll(true);
    return <div />
}
