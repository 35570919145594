import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_ACTIVITY_MEMBER_TAG, OBJECT_TYPE_ACTIVITY_MEMBER_TAG_ASSIGNATION, OBJECT_TYPE_ACTIVITY_STATUS } from '../../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../../base/Page';
import ItemTemplate from '../../../../templates/ItemTemplate';
import TableTemplate from '../../../../templates/TableTemplate';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../../constants/Constants';
import UIUtil from '../../../../util/UIUtil';
import ItemCreatorDialog from '../../../../templates/ItemCreatorDialog';

const OBJECT_TYPE = OBJECT_TYPE_ACTIVITY_MEMBER_TAG;


const MembersList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Assigned Members",
                subTitle: "Staff users assigned with this tag",
                objectType: OBJECT_TYPE_ACTIVITY_MEMBER_TAG_ASSIGNATION,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_ACTIVITY_MEMBER_TAG_ASSIGNATION}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                parentId={item.id}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}

const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                    {
                        title: 'Members',
                        component: MembersList
                    }
                ]
            })}
        </div>
    )
})

export const ActivityMemberTagsDetailPage = Page;
