import { ComboBox, ContentSwitcher, InlineLoading, Switch, Tag, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';

const TIME_UNIT_INDEFINITE = 3;
const TIME_UNITS = ['Days', 'Weeks', 'Months', "Remaining"]

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved, itemIndex, options }) => {
    const [timeUnit, setItemUnit] = useMultiInputState(input, 'timeUnit', onInputUpdate)
    const [time, setTime] = useMultiInputState(input, 'time', onInputUpdate);
    const [commissionRate, setCommissionRate] = useMultiInputState(input, 'commissionRate', onInputUpdate);

    const getTitle = () => {
        if (!nonExistent) {
            if (timeUnit == TIME_UNIT_INDEFINITE) {
                return `${TIME_UNITS[timeUnit ?? 0]} - ${commissionRate ?? 0}%`
            } else {
                return `${itemIndex == 0 ? 'First' : 'Next'} ${time ?? 0} ${TIME_UNITS[timeUnit ?? 0]} - ${commissionRate ?? 0}%`
            }
        } else {
            return 'New term';
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '0.5rem', marginBottom: '1rem', marginTop: '1rem', opacity: nonExistent ? 0.6 : 1 }}>
                <div style={{ height: 1, width: 50, background: '#00000040' }} />
                <Tag style={{ margin: 0 }} type="high-contrast">{getTitle()}</Tag>
                <div style={{ height: 1, flex: 1, background: '#00000040' }} />
            </div>
            <div style={{ display: 'flex', width: '100%', gap: 5, }}>
                <ComboBox style={{ width: 200 }} size="lg" titleText="Period Type" items={TIME_UNITS} selectedItem={TIME_UNITS[timeUnit]} onChange={e => setItemUnit(e.selectedItem ? TIME_UNITS.indexOf(e.selectedItem) : 0)} />
                {timeUnit != TIME_UNIT_INDEFINITE &&
                    <TextInput size="lg" labelText={TIME_UNITS[timeUnit ?? 0]} style={{ width: '100%' }} value={time} onChange={e => setTime(e.target.value)} />}
                <div style={{ flex: 1 }}>
                    <TextInput size="lg" labelText={'Commission Rate'} style={{ width: '100%' }} value={commissionRate} onChange={e => setCommissionRate(e.target.value)} />
                </div>

                <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                    style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
            </div>
        </div>
    )
}

export const MovieContractEditor = ({ defaultItems, onUpdate }) => {
    return (
        <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
    )
}