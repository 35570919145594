import { useState } from "react";
import { useHistory } from "react-router-dom";
import { withLoadablePage } from "../../../base/Page";
import Button from "../../../components/Button";
import PaginatedDataTable from "../../../components/data-table/PaginatedDataTable";
import Api from "../../../session/Api";
import { CreateFlowDialog } from "../views/create-flow-dialog";
import {
    Add16
} from '@carbon/icons-react'
import Util from "../../../util/Util";
import { StatusIndicator } from "../../../domain/lead";
import { LeadTag, LeadTagView } from "../lead-flow/widgets/flow-info";
import { isMobileScreen } from "../../../App";

const MOBILE_HEADERS = [
    {
        id: "id",
        name: "Lead",
        render: item => (<div style={{ paddingBlock: '0.5rem' }}>
            {Util.getVoucherNumber(item.id)}
            <br />
            <LeadTag name={item.sourceName} color={item.sourceColor} />
        </div>)
    },
    {
        id: "fullName",
        name: "Name",
        render: item => item.fullName
    },
    {
        id: "status",
        name: "Status",
        render: item => <StatusIndicator skipText status={item.status} />
    },
]

const HEADERS = [
    {
        id: "id",
        name: "Lead ID",
        render: item => Util.getVoucherNumber(item.id)
    },
    {
        id: "source",
        name: "Source",
        render: item => <LeadTag name={item.sourceName} color={item.sourceColor} />
    },
    {
        id: "fullName",
        name: "Full Name",
        render: item => item.fullName
    },
    {
        id: "status",
        name: "Status",
        render: item => <StatusIndicator status={item.status} />
    },
    {
        id: "tags",
        name: "Tags",
        render: item => (
            <div style={{ maxWidth: 250 }}>
                <LeadTagView lead={item} />
            </div>
        )
    },
]



const CreateButton = ({ sources, users, userTags, customers }) => {
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    return (<>
        <Button renderIcon={Add16} onClick={() => setVisible(true)}>New Lead</Button>

        <CreateFlowDialog
            onCreated={id => history.push("/lead/" + id)}
            open={visible}
            sources={sources}
            users={users}
            userTags={userTags}
            customers={customers}
            onClose={() => setVisible(false)} />
    </>)
}

const View = ({ payload: { sources, users, customers } }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            <div className="tooltip-supported-toolbar">
                {/* <h1>Leads</h1> */}

                <PaginatedDataTable
                    loader={(page, _, listener) => Api.getMyLeads(listener)}
                    loadResponseHandler={response => ({ loadedItems: response.payload, loadedHasMore: false })}

                    title="My Leads"
                    description={"All leads created by you or assigned to you"}
                    columns={isMobileScreen ? MOBILE_HEADERS : HEADERS}
                    onClick={({ id }) => history.push('/lead/' + id)}

                >
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ flex: 1 }} />
                        <CreateButton sources={sources} users={users} customers={customers} userTags={[]} />
                    </div>
                </PaginatedDataTable>

                <div style={{ height: 1000 }} />
            </div>
        </div>
    )
}
// title="My Leads"
//     description={"All leads created by you or assigned to you"}
export const MyLeadFlowList = withLoadablePage(Api.getLeadFlowCreationEndpoints.bind(Api), View);