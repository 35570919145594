import { isRomario } from "../../../app/app-util";
import Util from "../../../util/Util";

//export const SALES_WORK_STATUSES = isRomario() ? [
export const ORDER_SENT = "Order Sent"
export const ORDER_DELIVERED = "Order Delivered";
export const FEEDBACK = "Feedback";
export const SALES_WORK_STATUSES = [
    "No Status",
    "Order Placed",
    "Order Packed",
    "Payment Pending",
    ORDER_SENT,
    "Order in Transit",
    ORDER_DELIVERED,
    // FEEDBACK,
]

export function statusEq(a, b) {
    return statusName(a) === statusName(b);
}

export function actualStatus(a) {
    return statusName(a) !== SALES_WORK_STATUSES[0]
}

export function statusName(a) {
    if (!Util.isStringExists(a)) {
        a = SALES_WORK_STATUSES[0];
    }

    return a;
}

export function statusManager(work) {
    return (status) => {
        const eq = check => statusEq(status, check)
        return {
            done: work.statusReport.status.find($ => eq($.name)),
            current: eq(work.state),
        }
    }
}