import { globalStore } from "../App";
import useStore from "../hooks/useStore";

export function renderNode(nodeFn) {
    const onClose = () => globalStore.set('imperativeRender', undefined);
    globalStore.set('imperativeRender', nodeFn(onClose))
}

export function ImperativeRender() {
    const [node] = useStore(globalStore, 'imperativeRender')

    return node ?? null;
}