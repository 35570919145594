import Button from "../../components/Button";
import { CheckmarkFilled16, ErrorFilled16, Money16, Misuse16, Error16, Search16, Time16, Renew16, DeliveryParcel16, Delivery16, WatsonHealthStackedScrolling_116 } from '@carbon/icons-react'
import { STOCK_REQUEST_STATUS_PENDING_APPROVAL } from "../../constants/Constants";
import Api from "../../session/Api";
import FinalizeStockRequestDialog from "./FinalizeStockRequestDialog";
import { useEffect, useState } from "react";
import UIUtil from "../../util/UIUtil";

const RejectBtn = ({ stockRequest, onUpdateStockRequest, disabled, onLoadingChanged }) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        onLoadingChanged(loading)
    }, [loading])
    const onRevokeBtn = () => {
        UIUtil.confirmMsg('This action is irreversible', () => {
            setLoading(true);
            Api.rejectStockRequest(stockRequest.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onUpdateStockRequest(response.payload);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        })
    }
    return <Button disabled={disabled} loading={loading} onClick={onRevokeBtn} renderIcon={Misuse16} kind="danger">Reject</Button>;
}

const PendingApproval = ({ stockRequest, onUpdateStockRequest }) => {
    const [visible, setVisible] = useState(false);
    const [disabled, setDisabled] = useState(false);

    return (
        <>

            <RejectBtn stockRequest={stockRequest} onUpdateStockRequest={onUpdateStockRequest} disabled={false} onLoadingChanged={setDisabled} />
            <Button disabled={disabled} renderIcon={CheckmarkFilled16} onClick={() => setVisible(true)}>Approve Request</Button>

            <FinalizeStockRequestDialog
                stockRequestId={stockRequest.id}
                open={visible} onClose={() => setVisible(false)}
                onUpdateStockRequest={onUpdateStockRequest} />
        </>
    )
}


export default ({ stockRequest, onUpdateStockRequest }) => {
    let Button;
    switch (stockRequest.status) {
        case STOCK_REQUEST_STATUS_PENDING_APPROVAL:
            Button = PendingApproval;
            break;
    }

    if (!Button) {
        return null;
    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start', paddingTop: '1rem', justifyContent: 'center', paddingRight: '1rem', gap: '0.15rem' }}>
                <Button stockRequest={stockRequest} onUpdateStockRequest={onUpdateStockRequest} />
            </div>
        </>
    )
}