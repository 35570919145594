import React from 'react'

import { Undo16, Redo16, ZoomFit16, ResearchHintonPlot16, Development16 } from '@carbon/icons-react'
import { Slider } from 'carbon-components-react'
import { ButtonPopover } from '../../../components/button-popover'

const Button = ({ disabled, icon, onClick }) => (
    <div style={{ ...(disabled ? { pointerEvents: 'none', opacity: 0.65 } : {}) }} className="label-designer-toolbar-button" onClick={onClick}>
        {React.createElement(icon)}
    </div>
)

export default ({ engine }) => (
    <div className="label-designer-toolbar">
        <Button icon={Undo16} disabled={!engine.get('canUndo')} onClick={() => engine.undoHistory()} />
        <Button icon={Redo16} disabled={!engine.get('canRedo')} onClick={() => engine.redoHistory()} />
        <div style={{ flex: 1 }} />
        <p style={{ fontSize: 12, opacity: 0.65 }}>{engine.getTemplate().name}</p>
        <div style={{ flex: 1 }} />


        <ButtonPopover clearBottomRightRadius customAlign="end" customPos={["top"]} popover={
            <div style={{ paddingInline: '1rem', paddingBottom: '1rem', paddingTop: '0.5rem' }}>
                <Slider min={5} max={125} formatLabel={value => value + '%'} value={engine.getZoom()} onChange={e => engine.setZoom(e.value)} />
            </div>
        }>
            <Button icon={ZoomFit16} />
        </ButtonPopover>
        <Button icon={Development16} onClick={() => engine.toggleState('showCanvasGrid')} />
        <Button icon={ResearchHintonPlot16} onClick={() => engine.toggleState('showBackgroundGrid')} />
    </div>
)