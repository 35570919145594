import { useEffect, useRef, useState } from "react"
import useSize from "../../util/useSize"

import {
    Add16,
} from '@carbon/icons-react'
import { Popover, ArrowContainer } from "react-tiny-popover";
import { Icon } from "./components/icon";
import { ADVANCED_NOTES_TYPE } from ".";
import { typeName } from "./components/type-name";
import { InlineLoading } from "carbon-components-react";
import Util from "../../util/Util";


const ButtonPopover = ({ parentRef, children, popover, invalidateOpen }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [invalidateOpen])

    return (
        <Popover
            isOpen={open}
            //positions={['bottom', 'left', 'right', 'top']}
            parentElement={parentRef?.current}
            // padding={10}
            containerStyle={{ zIndex: 1000000 }}
            align="start"
            onClickOutside={e => {
                // if (!classExistsInSomeParent(e.target, "table-list-filter-save-dialog")) {
                setOpen(false)
                // }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container to-do-board-button-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="to-do-board-button-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        {popover}
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                {children}
            </a>
        </Popover>
    )
}

const NewTypeBtn = ({ type, onNewItem }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        //setLoading(true)
        onNewItem(type)
    }
    return (
        <div onClick={onClick} className="advanced-notes-items-new-type really_centered-progress-bar" style={{ pointerEvents: loading ? 'none' : undefined, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
            <div style={{ borderRadius: 50, width: '3rem', height: '3rem', background: '#F3F3F3', border: '1px solid #00000020', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {loading ? <InlineLoading /> : <Icon type={type} />}
            </div>
            <p style={{ fontSize: 12, opacity: 0.65 }}>{typeName(type)}</p>
        </div>
    )
}

const NewPopover = ({ onNewItem }) => {
    return (
        <div style={{ borderRadius: 15, overflow: 'hidden', padding: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <NewTypeBtn onNewItem={onNewItem} type={ADVANCED_NOTES_TYPE.FOLDER} />
            <NewTypeBtn onNewItem={onNewItem} type={ADVANCED_NOTES_TYPE.PDF} />
            <NewTypeBtn onNewItem={onNewItem} type={ADVANCED_NOTES_TYPE.IMAGE} />
            <NewTypeBtn onNewItem={onNewItem} type={ADVANCED_NOTES_TYPE.VIDEO} />
            <NewTypeBtn onNewItem={onNewItem} type={ADVANCED_NOTES_TYPE.FILE} />
            <NewTypeBtn onNewItem={onNewItem} type={ADVANCED_NOTES_TYPE.NOTE} />
        </div>
    )
}

export function NewItemBtn({ expanded, setExpanded, onNewItem }) {
    const ref = useRef();
    const [invalidateOpen, setInvalidateOpen] = useState(() => Util.newTempId());
    return (
        <ButtonPopover parentRef={ref} invalidateOpen={invalidateOpen} popover={<NewPopover onNewItem={type => {
            setInvalidateOpen(Util.newTempId())
            onNewItem(type);
        }} />}>
            <div ref={ref} className="advanced-notes-items-tree-root" style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <div style={{ width: '3rem' }} />
                <div onClick={() => setExpanded?.(!expanded)} className="advanced-notes-items-tree advanced-notes-items-tree-new-btn" style={{ opacity: 0.65, overflow: 'hidden', width: '100%', height: '1.5rem', borderRadius: 5, background: '#F3F3F3', border: '1px solid #00000020', gap: '1rem', display: 'flex', alignItems: 'center', paddingRight: '1rem', }}>
                    <div className="advanced-notes-items-thumb-icon-tree" style={{ width: '1.5rem', height: '1.5rem', minWidth: '1.5rem', padding: expanded ? 0 : '0.25rem', transition: '250ms', background: 'none', border: 'none' }}>
                        <div style={{ height: '100%', width: '100%', background: '#0353e9', color: 'white', transition: 'background 250ms', borderRadius: expanded ? 0 : 5, borderRight: expanded ? '1px solid #00000020' : undefined, border: expanded ? undefined : '1px solid #00000020', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Add16 />
                        </div>
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <p style={{ userSelect: 'none', fontSize: 12, fontStyle: 'italic', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>new item</p>
                    </div>
                </div>
            </div>
        </ButtonPopover>
    )
}