import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { ACCOUNT_TYPE_SALES, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_CUSTOMER_GROUP, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import { getAccountRole } from '../../session/SessionManager';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import PriceOverrideList from '../../views/price-override/PriceOverrideList';


const PriceOverride = ({item}) => {
    return (
        <div style={{marginTop: '1rem'}}>
            <PriceOverrideList parent={item} />
        </div>
    )
}

const CustomersList = ({item}) => {
    const history = useHistory();
    return (
        <div style={{marginTop: '1rem'}}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'groupId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Customers", 
                subTitle: "Group customers", 
                objectType: OBJECT_TYPE_CUSTOMER, 
                pagePath: "/customers/", 
                history: history
            })}
        </div>
    )
}


class CustomerGroupDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_CUSTOMER_GROUP, listener)
            } else {
                Api.getItem(OBJECT_TYPE_CUSTOMER_GROUP, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_CUSTOMER_GROUP,
                    itemResult: this.state.itemResult,
                    pagePath: "/customer-groups/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Customers",
                            component: CustomersList
                        },
                        ...(getAccountRole() != ACCOUNT_TYPE_SALES ? [{
                            title: "Price Override",
                            component: PriceOverride
                        }] : [])
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(CustomerGroupDetailPage);