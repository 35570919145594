import { TextArea, TextInput } from "carbon-components-react"
import { Close16, CheckmarkFilled16, ErrorFilled16, Reset16, Number_432, ArrowRight16, Incomplete16 } from '@carbon/icons-react'
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import useEmblaCarousel from 'embla-carousel-react'
import { useRefSize } from "../../../../../util/useSize"
import { getStatusIcon, getStatusOp } from "../.."
import Util from "../../../../../util/Util"

const Layout = ({ index, children }) => {
    const startIndex = useRef(index)
    const [emblaRef, emblaApi] = useEmblaCarousel({ draggable: false, startIndex: startIndex.current })
    const [rootRef, rootSize] = useRefSize()

    useEffect(() => { emblaApi?.reInit() }, [emblaApi, rootSize.width])

    useEffect(() => { emblaApi?.scrollTo(index) }, [emblaApi, index])

    return (
        <div ref={rootRef} style={{ width: '100%', height: '100%', }}>
            <div className='align-slider-2'>
                <div className="embla">
                    <div className="embla__viewport" ref={emblaRef}>
                        <div className="embla__container">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CardLayout = ({ icon, title, statusOps, children, noPadding, hiddenOverflow, selected, showOnlyIfSelected, status }) => {
    const show = showOnlyIfSelected ? selected : true;
    return (
        <div style={{ width: '100%', height: '100%', padding: '1rem', overflow: 'auto' }}>
            <div style={{ width: '100%', background: 'white', borderRadius: 15, overflow: hiddenOverflow ? 'hidden' : 'visible', border: '1px solid #00000020', boxShadow: '0px 25px 50px -12px rgba(0,0,0,0.25) ' }}>
                <div style={{ width: '100%', height: '3rem', borderBottom: '1px solid #00000040', display: 'flex', alignItems: 'center', paddingInline: '1rem' }}>
                    {/* <Status icon={Number_432} selected done name="" /> */}
                    <div style={{ background: '#1c1c1c', color: 'white', height: '1.5rem', paddingLeft: '0.5rem', paddingRight: '1rem', border: '1px solid #ffffff20', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ transition: '150ms', marginRight: '0.5rem', height: 32, width: 32, borderRadius: 5, background: '#00520f', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {React.createElement(icon)}
                        </div>
                        {/* <p style={{ fontSize: 14, opacity: selected ? 1 : 0.65 }}>{'32'}</p> */}
                    </div>

                    <h4 style={{ marginLeft: '1rem', fontWeight: 'bold' }}>{title}</h4>
                    {status.header && <status.header />}
                    <div style={{ flex: 1 }} />

                    {statusOps?.done ? (<>
                        <p style={{ fontSize: 12, opacity: 0.65, color: '#00520f' }}>Status set on {Util.getDate(statusOps.statusDate)}</p>
                        <CheckmarkFilled16 style={{ marginLeft: '0.25rem', color: '#00520f' }} />
                    </>) : statusOps?.enabled && (<>
                        <p style={{ fontSize: 12, opacity: 0.65, }}>Current status</p>
                        <Incomplete16 style={{ marginLeft: '0.25rem' }} />
                    </>)}
                </div>
                <div style={{ padding: noPadding ? 0 : '1rem' }}>
                    {show && children}
                </div>
                {!noPadding && <div style={{ width: '100%', height: '3rem', borderTop: '0px solid #00000020', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.25rem', paddingInline: '1rem' }}>
                </div>}
            </div>
        </div>
    )
}

const Card = ({ fnbEvent, form, flow, status, statusOps, selected }) => {
    return (
        <CardLayout icon={getStatusIcon(flow, status, true)} title={status.title} statusOps={statusOps} selected={selected} status={status} {...status.parentLayoutProps}>
            <status.card fnbEvent={fnbEvent} form={form} />
        </CardLayout>
    )
}

export function Content({ fnbEvent, form, flow, selectedStatus }) {
    const layoutIndex = useMemo(() => Math.max(flow.findIndex(status => status.id === selectedStatus?.id), 0), [flow, selectedStatus])
    const statusOp = useCallback(status => getStatusOp(fnbEvent, flow, selectedStatus, status), [fnbEvent, flow, selectedStatus]);
    return (
        <Layout index={layoutIndex}>
            {flow.map((status, statusIndex) => (
                <div key={status.id} className="embla__slide">
                    <Card fnbEvent={fnbEvent} form={form} flow={flow} status={status} statusOps={statusOp(status)} selected={layoutIndex === statusIndex} />
                </div>
            ))}
        </Layout>
    )
}