import "../styles.scss";

import { useCallback, useMemo, useState } from "react";
import { withLoadablePage } from "../../base/Page"
import Api from "../../session/Api"
import { Toolbar } from "../components/toolbar";
import { SideBar, SideBarAdd, SideBarGroup, SideBarItem } from "../components/side-bar";
import { CodeEditor } from "../components/code-editor";
import { Preview } from "../components/preview";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import { useForceLockScroll } from "../../hooks/useLockScroll";
import { Resources } from "../components/resources";
import { Fragments } from "../components/fragments";
import { Link } from "carbon-components-react";


function TemplatesTab({ definitions, showSidebar, selected, setSelected, sideView, createNewTemplate, onTemplateDeleted, onTemplateUpdate }) {
    const [previewKey, setPreviewKey] = useState(() => Util.newTempId())
    const selectedDef = useMemo(() => definitions.find(def => def.templates.some(defTempl => defTempl?.id === selected?.id)), [definitions, selected])

    const onSaved = response => {
        setPreviewKey(Util.newTempId())
        onTemplateUpdate({
            ...selected,
            name: response.name,
            orderNo: response.orderNo,
            active: response.active,
        })
    }

    const onDeleteBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            title: "Delete Template",
            message: "Are you sure you want to delete this template?"
        })

        if (!confirmed) {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success] = await Api.try((api, listener) => api.deleteMarkupTemplate(selected.id, listener));
            if (!success) {
                return;
            }

            onTemplateDeleted?.(selected);
            setSelected(undefined);
        } finally {
            stopLoading();
        }
    }

    const onToggleActiveBtn = async () => {
        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.toggleStandardMarkupTemplateActive(selectedDef.name, listener));
            if (!success) {
                return;
            }

            onTemplateUpdate({
                ...selected,
                active: response,
            })
        } finally {
            stopLoading();
        }
    }

    return (
        <div className="robo-ide-root">
            <SideBar visible={showSidebar}>
                {definitions.map(definition => (
                    <SideBarGroup key={definition.name} title={definition.title}>
                        {definition.templates.map(template => (
                            <SideBarItem
                                key={template.path}
                                title={template.name}
                                selected={selected?.path === template.path}
                                disabled={!template.active}
                                onClick={() => setSelected(template)}
                            />
                        ))}

                        <SideBarAdd key={definition.name + 'add'} onClick={() => createNewTemplate(definition)} />
                    </SideBarGroup>
                ))}
            </SideBar>

            <CodeEditor
                title={selectedDef?.title ? `${selectedDef.title} > ` : ''}
                lang="html"
                selected={selected}
                onSaved={onSaved}
                selectedDefinition={selectedDef}
                toolbarTopRight={<>
                    {selected && <>
                        {!selected.readonly &&
                            <Link style={{ cursor: 'pointer', color: 'red', marginRight: '0.25rem' }} onClick={onDeleteBtn}>Delete</Link>}

                        {selected.readonly && selected.active &&
                            <Link style={{ cursor: 'pointer', color: 'red', marginRight: '0.25rem' }} onClick={onToggleActiveBtn}>Disable</Link>}
                        {selected.readonly && !selected.active &&
                            <Link style={{ cursor: 'pointer', marginRight: '0.25rem' }} onClick={onToggleActiveBtn}>Enable</Link>}
                    </>}
                </>} />

            {sideView === "preview" &&
                <Preview key={previewKey} definition={selectedDef} selected={selected} />}
        </div>
    )
}


function ResourcesTab({ }) {
    return (
        <div className="robo-ide-resources-tab">
            <Resources />
        </div>
    )
}


function FragmentsTab({ showSidebar, }) {
    return (
        <div className="robo-ide-fragments-tab">
            <Fragments showSidebar={showSidebar} />
        </div>
    )
}


function ConstantsTab({ }) {
    const selected = useMemo(() => ({
        name: "Constants",
        path: "constants",
    }), [])

    return (
        <div className="robo-ide-constants-tab">
            <CodeEditor lang="json" selected={selected} />
        </div>
    )
}

function Tab({ index, ...props }) {
    switch (index) {
        case 0:
            return <TemplatesTab {...props} />
        case 1:
            return <ResourcesTab {...props} />
        case 2:
            return <FragmentsTab {...props} />
        case 3:
            return <ConstantsTab {...props} />
        default:
            return null;
    }
}

function View({ payload }) {
    const [tabIndex, setTabIndex] = useState(0);
    const [sideView, setSideView] = useState("");

    const [definitions, setDefinitions] = useState(payload.definitions);
    // const [fragments, setFragments] = useState(payload.fragments);

    const [showSidebar, setShowSidebar] = useState(true);
    const [selected, setSelected] = useState(undefined);

    const createNewTemplate = useCallback(async definition => {
        if (window._roboCodeEditorUnsavedChanges) {
            const confirmed = await UIUtil.confirmPrompt({
                title: "Unsaved Changes",
                message: "You have unsaved changes. Are you sure you want to create a new template? All unsaved changes will be lost.",
                dangerPositive: true,
                positiveAction: "Discard Changes"
            })
            if (!confirmed) {
                return;
            }
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.createMarkupTemplate(definition.name, listener));
            if (!success) {
                return;
            }

            const newAddition = {
                id: response.id,
                name: response.name,
                path: response.src,
                orderNo: 0,
            }

            setDefinitions(prevDefs => prevDefs.map(prev => prev.name === definition.name ? {
                ...prev,
                templates: [
                    ...prev.templates,
                    newAddition
                ].sort((a, b) => a.orderNo - b.orderNo)
            } : prev))
            setSelected(newAddition);
        } finally {
            stopLoading();
        }
    }, []);

    const updateTemplateName = useCallback((definition, template, newName) => setDefinitions(prevDefs => prevDefs.map(prev => prev.name === definition.name ? {
        ...prev,
        templates: prev.templates.map(templ => templ.path === template.path ? {
            ...templ, name: newName
        } : templ)
    } : definition)), []);

    const onTemplateDeleted = template => {
        setSelected(undefined);
        setDefinitions(prevDefs => prevDefs.map(prev => ({
            ...prev,
            templates: prev.templates.filter(templ => templ.path !== template.path)
        })))
    }

    const onTemplateUpdate = template => {
        setDefinitions(prevDefs => prevDefs.map(prev => ({
            ...prev,
            templates: prev.templates.map(templ => templ.path === template.path ? template : templ).sort((a, b) => a.orderNo - b.orderNo)
        })))
        setSelected(template);
    }


    useForceLockScroll(true)


    const onTabIndexChange = async newIndex => {
        if (window._roboCodeEditorUnsavedChanges) {
            const confirmed = await UIUtil.confirmPrompt({
                title: "Unsaved Changes",
                message: "You have unsaved changes. Are you sure you want to switch tabs? All unsaved changes will be lost.",
                dangerPositive: true,
                positiveAction: "Discard Changes"
            })
            if (!confirmed) {
                return;
            }
        }

        setTabIndex(newIndex);
    }

    const onSelectedChanged = async templ => {
        if (window._roboCodeEditorUnsavedChanges) {
            const confirmed = await UIUtil.confirmPrompt({
                title: "Unsaved Changes",
                message: "You have unsaved changes. Are you sure you want to switch templates? All unsaved changes will be lost.",
                dangerPositive: true,
                positiveAction: "Discard Changes"
            })
            if (!confirmed) {
                return;
            }
        }

        setSelected(templ);
    }

    return (
        <div style={{ background: '#1c1c1c' }}>
            <Toolbar
                tabIndex={tabIndex} setTabIndex={onTabIndexChange}
                sideView={sideView} setSideView={setSideView}
                onSidebarBtn={() => setShowSidebar(p => !p)} />

            <Tab index={tabIndex} {...{
                definitions,
                showSidebar, sideView,
                selected, setSelected: onSelectedChanged,
                createNewTemplate, updateTemplateName,
                onTemplateDeleted, onTemplateUpdate
            }} />
        </div>
    )
}

export const MarkupTemplatesPage = withLoadablePage(Api.getMarkupTemplates.bind(Api), View);