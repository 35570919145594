import React from 'react'
import Page from '../../base/Page';
import { API_RESPONSE_FAILED_EVENT_404 } from '../../constants/Constants';
import { getImportableObjectTypeIcon, getImportableObjectTypeLink, getImportableObjectTypeTitle, isImportableObjectTypeExists } from './ImportableObjectTypes';
import { ButtonSet, ComposedModal, FileUploaderDropContainer, FileUploaderItem, Link as DLink, ModalBody, ModalFooter } from 'carbon-components-react';

import { 
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    DocumentImport16,
    Template16,
    Warning16,
    CheckmarkFilled32,
    ErrorFilled16,
    List16,
    DocumentImport32,
    Download16,
    DocumentDownload16
} from '@carbon/icons-react'
import Button from '../../components/Button';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { withRouter } from 'react-router-dom';

const Section = ({children, icon, title, extraTopMargin, hasLink, linkText, onLinkClick}) => (
    <div style={{marginTop: extraTopMargin ? '6rem' : '3rem'}}>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
            {React.createElement(icon)}
            <p>{title}</p>


            {/* {hasLink && <Link style={{marginLeft: '1rem', fontSize: 12, cursor: 'pointer'}} onClick={onLinkClick}>{linkText}</Link>} */}
        </div>
        {children}
    </div>
)


class ImportDataTypePage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            selectedDataFile: undefined,
            importing: false,

            successResponse: {},
            showSuccessDialog: false,
        }
    }

    getObjectType() {
        return this.getPathParams().objectType;
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (isImportableObjectTypeExists(this.getObjectType())) {
                listener({
                    status: true,
                })
            } else {
                listener({
                    status: false,
                    payload: API_RESPONSE_FAILED_EVENT_404
                })
            }
        }, () => {})
    }

    onImportBtn() {
        this.setState({importing: true})
        Api.importData(this.getObjectType(), this.state.selectedDataFile, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState({ importing: false, selectedDataFile: undefined, successResponse: response.payload, showSuccessDialog: true })
                // this.props.history.replace(getImportableObjectTypeLink(this.getObjectType()))
            } else {
                UIUtil.showError(response.message);
                this.setState({importing: false})
            }
        })
    }

    getLayout() {
        const Icon = getImportableObjectTypeIcon(this.getObjectType());
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem'}}>
                <div style={{width: '75vw'}} className="menu-tile">
                    <DLink onClick={() => this.props.history.goBack()} style={{marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center'}}><ArrowLeft16 style={{marginRight: '0.25rem'}} /> Back</DLink>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '3rem'}}>
                        <Icon style={{width: 64, height: 64, marginRight: '0.5rem'}} />
                        <div>
                            <h1>{getImportableObjectTypeTitle(this.getObjectType())}</h1>
                            <p style={{fontSize: 18}}>Import data</p>
                        </div>
                    </div>

                    
                    <Section icon={Number_132} title="Get Template">
                        <div>
                            {/* <p style={{fontSize: 14, opacity: 1, marginBottom: '1rem'}}>Two sheets are included in the template. The main template sheet and the instruction sheet describing the data model to be inputted into the template.</p> */}
                            <a download href={Api.downloadImportDataTemplate(this.getObjectType())} style={{textDecoration: 'none'}}>
                                <Button kind="secondary" renderIcon={Template16} style={{width: undefined}}>Download Template</Button>
                            </a>
                            <p className="bx--label" style={{display: 'block', marginTop: '0.25rem'}}>.xlsx file format</p>
                        </div>
                    </Section>

                    <Section icon={Number_232} title="Upload data" extraTopMargin>
                        {this.state.selectedDataFile !== undefined ? (
                            <FileUploaderItem name={this.state.selectedDataFile.name} status="edit" onDelete={() => this.setState({selectedDataFile: undefined})} />
                        ) : (
                            <FileUploaderDropContainer accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]} multiple={false} labelText="Drag and drop the input data file here or click to upload (.xlsx only)" onAddFiles={(e, { addedFiles }) => {
                                if (addedFiles.length > 0) {
                                    this.setState({selectedDataFile: addedFiles[0]})
                                }
                            }} />
                        )}

                        <p style={{fontSize: 14, opacity: 1, marginTop: '1rem'}}><Warning16 /> Always use the latest template file.</p>
                    </Section>

                    <Section icon={Number_332} title="Confirm and import" extraTopMargin>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={this.onImportBtn.bind(this)} disabled={this.state.selectedDataFile === undefined} loading={this.state.importing} renderIcon={DocumentImport16}>Import Data</Button>
                        </div>
                    </Section>
                </div>

                <ComposedModal open={this.state.showSuccessDialog} onClose={() => this.setState({showSuccessDialog: false})}>
                    <ModalBody style={{paddingRight: '1rem', paddingTop: '1rem'}}>
                        <div style={{background: 'rgb(244, 244, 244)', width: '100%', overflow: 'hidden'}}>
                            {/* <ModalHeader label="Cash" title="Are you sure?" /> */}
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '3rem', marginBottom: '1.5rem'}}>
                                <DocumentImport32 style={{ width: 128, height: 128}} />
                                <h2 style={{}}>Import Results</h2>
                                <p style={{marginBottom: '1rem', fontSize: 14}}>{this.state.successResponse.totalRows + ''} total row{this.state.successResponse.totalRows != 1 ? 's' : ''}</p>

                                <p style={{color: 'green'}}>{this.state.successResponse.totalSuccess + ''} imported successfully</p>
                                {this.state.successResponse.totalFail > 0 &&
                                <p style={{color: 'red'}}>{this.state.successResponse.totalFail + ''} failed</p>}

                                <a download="result.xlsx" href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${this.state.successResponse.reportData}`} style={{textDecoration: 'none'}}>
                                <Button kind="secondary" style={{marginTop: '1.5rem'}} renderIcon={DocumentDownload16}>Download Results</Button>
                                </a>
                            </div>
                            {/* <div style={{height: 15}} /> */}
                            
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonSet style={{width: '100%'}}>

                            <Button kind="secondary" renderIcon={ErrorFilled16} 
                            onClick={() => this.setState({showSuccessDialog: false})}>
                                Close
                            </Button>
                            <Button 
                            renderIcon={List16}
                            onClick={() => this.props.history.push(getImportableObjectTypeLink(this.getObjectType()))}>
                                View List
                            </Button>

                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>
            </div>
        )
    }

}

export default withRouter(ImportDataTypePage);