import React, { useState, useEffect } from 'react'

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    User32,
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    CheckmarkOutline16,
    CheckmarkFilled16,
    ErrorFilled16
} from '@carbon/icons-react'
import { TileGroup, RadioTile, TextArea, ComboBox, ButtonSet, Switch, ContentSwitcher, NumberInput, ComposedModal, ModalHeader, ModalBody, ModalFooter, InlineLoading } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Util from '../../util/Util';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { INVOICE_DOCUMENT_STATUS_NOT_PAID } from '../../domain/invoice-document';
import { BILL_STATUS_NOT_PAID, BILL_STATUS_UNPAID_OR_PARTIALLY_PAID } from '../../domain/bill';

// const SelectStockFlowItem = ({ stockFlow, stockFlowType, onSelect, onRemove, selected, readOnly, invoiceMode, billMode }) => {
//     let link = invoiceMode ? '/invoice-documents/' + stockFlow.id : "/stock-flow/" + stockFlow.id
//     let date = invoiceMode ? Util.getDate(stockFlow.dateCreated) : Util.getDate(stockFlow.initiationDate);
//     if (invoiceMode) {
//         stockFlowType = "Invoice Document"
//     }

//     if (billMode) {
//         link = '/bill/' + stockFlow.id;
//         stockFlowType = "Bill"
//     }

//     return (
//         <div style={{
//             transition: '250ms', height: undefined, overflow: 'hidden',
//             background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
//         }}>
//             <div style={{ height: undefined, display: 'flex', flex: 1, alignItems: 'flex-start', padding: '15px' }}>
//                 <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
//                     <Link to={link} target="_blank">
//                         <p style={{ fontSize: 14 }}>{stockFlowType} (voucher no: {Util.getVoucherNumber(stockFlow.id)})</p>
//                     </Link>
//                     <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>{date}</p>

//                     <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '0.25rem' }}>
//                         <h2>AED {stockFlow.amountPaid.toFixed(2)}</h2>
//                         <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}>(Amount Paid Already)</p>

//                         {/* {stockFlow.amount !== null && */}
//                         {/* // <p style={{marginLeft: '0.25rem', paddingBottom: '0.25rem'}}> / AED {stockFlow.amount.toFixed(2)} (Total {stockFlowType} Amount)</p>} */}
//                     </div>

//                     <p style={{ paddingBottom: '0.25rem' }}>AED {stockFlow.amount.toFixed(2)} (Total {stockFlowType} Amount)</p>

//                     <p style={{ color: 'red', fontSize: 14, }}>AED {(stockFlow.amount - stockFlow.amountPaid).toFixed(2)} left</p>
//                 </div>
//                 {/* <p style={{fontSize: 12, opacity: 0.65, marginRight: '1rem'}}>{Util.getDate(stockFlow.initiationDate)}</p> */}
//                 {!readOnly &&
//                     <Button onClick={selected ? onRemove : onSelect} kind={selected ? undefined : "tertiary"} renderIcon={selected ? CheckmarkFilled16 : CheckmarkOutline16} style={{ width: 195 }}>{selected ? 'Unselect' : 'Select'}</Button>}
//             </div>
//         </div>
//     )
// }


const SelectItem = ({ item, onSelect, onRemove, selected, readOnly, againstOption }) => {
    let link = againstOption.getItemLink(item);
    let date = Util.getDate(againstOption.getItemDate(item))
    let name = againstOption.name;

    return (
        <div style={{
            transition: '250ms', height: undefined, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: undefined, display: 'flex', flex: 1, alignItems: 'flex-start', padding: '15px' }}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <Link to={link} target="_blank">
                        <p style={{ fontSize: 14 }}>{name} (voucher no: {Util.getVoucherNumber(item.id)})</p>
                    </Link>
                    <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>{date}</p>

                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '0.25rem' }}>
                        <h2>AED {item.amountPaid.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}>(Amount Paid Already)</p>
                    </div>

                    <p style={{ paddingBottom: '0.25rem' }}>AED {item.amount.toFixed(2)} (Total {name} Amount)</p>

                    <p style={{ color: 'red', fontSize: 14, }}>AED {(item.amount - item.amountPaid).toFixed(2)} left</p>
                </div>
                {!readOnly &&
                    <Button onClick={selected ? onRemove : onSelect} kind={selected ? undefined : "tertiary"} renderIcon={selected ? CheckmarkFilled16 : CheckmarkOutline16} style={{ width: 195 }}>{selected ? 'Unselect' : 'Select'}</Button>}
            </div>
        </div>
    )
}

export default ({
    //againstMode, billMode, invoiceMode, 
    againstOption,
    againstParentId, onClose, onConfirm, defaultSelection, readOnly
}) => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    const [selectAgainstSelections, setSelectAgainstSelections] = useState([])

    useEffect(() => {
        const listener = response => {
            if (response.status === true) {
                setLoading(false);
                setItems(response.payload);

                const selection = defaultSelection.filter(selection => response.payload
                    .find(o => selection.targetAgainstOption.getSelectionId(selection) == o.id) !== undefined
                ).map(selection => response.payload
                    .find(o => selection.targetAgainstOption.getSelectionId(selection) == o.id));
                setSelectAgainstSelections(selection)

                // setSelectAgainstSelections(defaultSelection.filter(selection => {
                //     for (const o of response.payload) {
                //         if (selection.targetAgainstOption.getSelectionId(selection) == o.id) {
                //             return true;
                //         }
                //     }
                //     return false;
                // }).map(selection => {
                //     for (const o of response.payload) {
                //         if (selection.targetAgainstOption.getSelectionId(selection) == o.id) {
                //             return o;
                //         }
                //     }
                // }))
            } else {
                UIUtil.showError(response.message);
            }
        }

        againstOption.api(againstParentId, listener);

        // if (invoiceMode) {
        //     Api.getInvoiceDocuments(againstParentId, INVOICE_DOCUMENT_STATUS_NOT_PAID, listener)
        // } else if (billMode) {
        //     Api.getBills(againstParentId, BILL_STATUS_UNPAID_OR_PARTIALLY_PAID, listener)
        // } else {
        //     if (againstMode?.api) {
        //         againstMode.api(againstParentId, listener);
        //     }
        // }

        // if (isAgainstSale) {
        //     Api.getUnpaidSales(againstParentId, listener);
        // } else {
        //     Api.getUnpaidPurchases(againstParentId, listener);
        // }
    }, [])

    // let label = "Against " + againstMode.name;
    // let title = "Select " + againstMode.name;


    // if (readOnly) {
    //     label = "Unpaid " + againstMode.name;
    //     title = againstMode.name;
    // }

    // if (billMode) {
    //     let label = "Against " + 'Bill';
    //     let title = "Select " + 'Bill';


    //     if (readOnly) {
    //         label = "Unpaid " + 'Bill';
    //         title = 'Bill';
    //     }

    // }

    let label;
    let title;
    if (readOnly) {
        label = "Unpaid " + againstOption.name;
        title = againstOption.name;
    } else {
        label = "Against " + againstOption.name;
        title = "Select " + againstOption.name;
    }

    return (
        <ComposedModal size={"md"} open onClose={onClose}>
            <ModalHeader label={label} title={title} />
            <ModalBody style={{ paddingRight: '1rem' }}>
                {loading && <InlineLoading />}
                {!loading && items.length == 0 && <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>}

                {!loading && items.length && (
                    <div onClick={() => {
                        setSelectAgainstSelections(selectAgainstSelections => [
                            ...selectAgainstSelections.filter(o => !items.includes(o)),
                            ...items
                        ]);
                    }} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                        <Button kind="ghost" size="sm" style={{ borderRadius: 25 }}>Select all</Button>
                    </div>
                )}
                {!loading && items.map(item =>
                    <SelectItem
                        key={item.id}
                        //invoiceMode={invoiceMode} billMode={billMode} stockFlow={item} stockFlowType={againstMode.name}
                        item={item} againstOption={againstOption}
                        readOnly={readOnly}
                        selected={selectAgainstSelections.includes(item)}
                        onRemove={() => setSelectAgainstSelections(selectAgainstSelections => selectAgainstSelections.filter(o => o !== item))}
                        onSelect={() => setSelectAgainstSelections(selectAgainstSelections => [...selectAgainstSelections, item])}
                    />)}

            </ModalBody>
            {!readOnly && <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonSet style={{ width: 392 }}>
                    <Button kind="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button disabled={selectAgainstSelections.length == 0} onClick={() => {
                        //onConfirm(selectAgainstSelections, invoiceMode, billMode)
                        onConfirm(selectAgainstSelections, againstOption)
                    }}>
                        Confirm
                    </Button>
                </ButtonSet>
            </div>}
        </ComposedModal>
    )
}