import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_DELIVERY_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { Archive32 } from '@carbon/icons-react'

export const CRVSummary = {
    filter: Filter,


    onAction: (action, data, history) => {
        switch (action) {
            case "createCrv":
                history.push("/crv-creator", { staffId: data.staffId })
                return;
        }
    },

    isAvailable: () => {
        // if (!isV2()) {
        //     return false;
        // }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_DELIVERY_MANAGER;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter />
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Name" fieldKey={"nameField"} options={endpoint.staff.map(item => item.value)} state={state} property="name" />
        </div>
    </>)
}
