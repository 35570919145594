import { Link } from "react-router-dom";
import { withLoadablePagePropsAndProps } from "../../../../base/Page";
import Api from "../../../../session/Api";
import { Certificate32, ArrowRight16 } from '@carbon/icons-react'
import Button from "../../../../components/Button";
import { InvoiceItemsTable } from "../components/invoice-items-table";
import { MinimalTable } from "../../../../components/minimal-table";
import { useMemo } from "react";
import { V2SaleInvoiceBtn } from "../../../../pdfs/invoice-document/InvoiceDocumentPdf";
import Util from "../../../../util/Util";
import NoteView from "../../../../views/notes/NoteView";
import { OBJECT_TYPE_V2_SALE } from "../../../../constants/ObjectTypes";

const ValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16, whiteSpace: 'pre-line' }}>{Util.isStringExists(children) ? children : '-'}</p>
    </div>
)

const Group = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

const COLUMNS = [
    {
        name: "item",
        title: "Item",
        flex: 2,
        renderCell: item => !item.aggregate ? item.itemName : undefined
    },
    {
        name: "class",
        title: "Class",
        flex: 2,
        renderCell: item => !item.aggregate ? item?.tags?.map($ => $.name).join(", ") : undefined
    },
    {
        name: "memo",
        title: "Memo",
        flex: 2,
        renderCell: item => !item.aggregate ? item.memo : undefined
    },
    {

        name: "price",
        title: "Price",
        flex: 1,
        renderCell: item => !item.aggregate ? item.rate : undefined
    },
    {
        name: "qty",
        title: "Qty",
        flex: 1,
        renderCell: item => !item.aggregate ? item.qty : undefined
    },
    {
        name: "gross",
        title: "Gross",
        flex: 2,
        renderCell: item => !item.aggregate ? item.gross : undefined
    },
    {
        name: "tax",
        title: "Tax",
        flex: 2,
        renderCell: item => !item.aggregate ? item.taxName : undefined
    },
    {
        name: "total",
        title: "Total",
        flex: 2,
        renderCell: item => !item.aggregate ? item.total : item.aggregate.total
    },
]

function View({ payload: item }) {
    const items = useMemo(() => {
        const list = item.items.filter($ => !$.hidden);
        return [
            ...list.map($ => ({
                ...$,
                rate: $.rate * (item.creditNote ? -1 : 1),
                gross: $.gross * (item.creditNote ? -1 : 1),
                tax: $.tax * (item.creditNote ? -1 : 1),
                total: $.total * (item.creditNote ? -1 : 1),
            })),
            {
                id: "total", aggregate: {
                    total: item.amount * (item.creditNote ? -1 : 1),
                }
            }
        ];
    }, [item])
    return (
        <div style={{ paddingInline: '3rem', paddingBlock: '6rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem', gap: '0.25rem' }}>
                <div>
                    <Certificate32 style={{ width: 64, height: 64 }} />
                    <h3 style={{ fontWeight: 'bold', marginTop: '1rem', color: '#0f62fe' }}>{item.creditNote ? 'Credit Note' : 'Sale'}</h3>
                    <h4 style={{ marginBottom: '1rem' }}>Voucher: {item.voucherNo}</h4>

                    <V2SaleInvoiceBtn creditNote={item.creditNote} saleId={item.id} mini />
                </div>
                <div style={{ flex: 1 }} />


                {item.transactions.map(transaction => (
                    <Link to={"/transaction/" + transaction.transactionId}>
                        <Button size="sm" renderIcon={ArrowRight16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Receipt #{transaction.voucherNo}</Button>
                    </Link>
                ))}
                <Link to={"/journal-entry/" + item.journalEntryId}>
                    <Button size="sm" renderIcon={ArrowRight16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Journal Entry</Button>
                </Link>
            </div>

            <Group title="Attachments">
                <NoteView objectId={item.id} objectType={OBJECT_TYPE_V2_SALE} />
            </Group>

            <Group title="Details">
                <div style={{ display: 'flex' }}>
                    <ValueField style={{ flex: 1 }} title="Creation date">{Util.getDateOnly(item.dateCreated)}</ValueField>
                    <ValueField style={{ flex: 1 }} title={item.creditNote ? "Credit Note date" : "Invoice date"}>{Util.getDateOnly(item.invoiceDate)}</ValueField>
                    <ValueField style={{ flex: 1 }} title="Due date">{Util.getDateOnly(item.dueDate)}</ValueField>
                </div>
                <div style={{ display: 'flex' }}>
                    <ValueField style={{ flex: 1 }} title="Name">{item.invoiceToName}</ValueField>
                    <ValueField style={{ flex: 1 }} title="TRN">{item.invoiceToTrn}</ValueField>
                    <ValueField style={{ flex: 1 }} title="Address">{item.invoiceToAddress}</ValueField>
                </div>
                {!item.creditNote && <div style={{ display: 'flex' }}>
                    <ValueField style={{ flex: 1 }} title="Order no">{item.orderNo}</ValueField>
                    <ValueField style={{ flex: 1 }} title="Delivered by">{item.deliveredBy}</ValueField>
                    <div style={{ flex: 1 }} />
                </div>}
                <div style={{ display: 'flex' }}>
                    <ValueField style={{ flex: 1 }} title={"Memo"}>{item.memo}</ValueField>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 1 }} />
                </div>
            </Group>

            {/* <InvoiceItemsTable sellables={[]} items={item.items} setItems={() => {}} /> */}
            <MinimalTable
                large items={items}
                columns={COLUMNS}
            />
        </div>
    )
}


export const SalesPage = withLoadablePagePropsAndProps(props => listener => Api.getV2Sale(props.itemId, listener), View);