import React from 'react'

import Button from '../../components/Button';


import Api from '../../session/Api';
import { Edit16 } from '@carbon/icons-react'
import { ComposedModal, ModalBody, ModalFooter, ModalHeader, NumberInput } from 'carbon-components-react';
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util';

export function formatBalanceAmount(amount, negPrefix, posPrefix) {
    let balance = amount ? amount : 0;
    let neg = balance < 0;

    let prefix = ""
    if (neg && negPrefix) {
        prefix = negPrefix
    }
    if (!neg && posPrefix) {
        prefix = posPrefix;
    }

    return prefix + "AED " + Util.formatMoneyWithNeg(balance)
}

export class BalanceView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: props.item,

            showOpeningBalanceDialog: false,
            setOpeningBalanceValue: 0,
            loadingSettingBalance: false
        }
    }

    renderBalanceItem() {
        const item = this.state.item;

        if (!item.hasJournalEntries) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1rem' }}>
                    <Button onClick={() => this.setState({ showOpeningBalanceDialog: true, setOpeningBalanceValue: 0, loadingSettingBalance: false })} renderIcon={Edit16}>Set Opening Balance</Button>
                </div>
            )
        } else {
            //REPEATED ABOVE!
            let balance = item.balance ? item.balance : 0;
            let neg = balance < 0;

            let prefix = ""
            if (neg && this.props.negPrefix) {
                prefix = this.props.negPrefix
            }
            if (!neg && this.props.posPrefix) {
                prefix = this.props.posPrefix;
            }

            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-center', justifyContent: 'center', paddingRight: '1rem', color: neg ? 'red' : 'green' }}>
                    <p>Balance</p>
                    <h4>{prefix}AED {Util.formatMoneyWithNeg(balance)}</h4>
                </div>
            )
        }
    }

    setOpeningBalance() {
        this.setState({ loadingSettingBalance: true })
        const listener = response => {
            this.setState({ loadingSettingBalance: false })
            if (response.status === true) {
                this.setState(prevState => ({
                    showOpeningBalanceDialog: false,
                    item: {
                        ...prevState.item,
                        balance: prevState.setOpeningBalanceValue,
                        hasJournalEntries: true
                    }
                }))
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
        };

        if (this.props.subsidiaryType) {
            Api.setSubsidiaryOpeningBalance(this.state.item.id, this.props.subsidiaryType, this.state.setOpeningBalanceValue, listener);
        } else {
            Api.setOpeningBalance(this.state.item.id, this.state.setOpeningBalanceValue, listener)
        }
    }

    render() {
        return (
            <>
                {this.renderBalanceItem()}
                <ComposedModal key="opening-balance-dialog" size="sm" open={this.state.showOpeningBalanceDialog} onClose={() => this.setState({ showOpeningBalanceDialog: false })}>
                    <ModalHeader label="Account Ledger" title="Setting Opening Balance" />
                    <ModalBody>
                        <NumberInput
                            invalidText="Invalid number"
                            data-modal-primary-focus
                            value={this.state.setOpeningBalanceValue}
                            onChange={(e, { value }) => this.setState({ setOpeningBalanceValue: value })}
                            hideSteppers
                            label="Opening Balance"
                        />
                    </ModalBody>
                    <ModalFooter
                    // onRequestSubmit={() => this.startSession()}
                    // primaryButtonText="Start" secondaryButtonText="Cancel"
                    >

                        <Button kind="secondary" onClick={() => this.setState({ showOpeningBalanceDialog: false })}>
                            Cancel
                        </Button>
                        <Button
                            loading={this.state.loadingSettingBalance}
                            disabled={this.state.setOpeningBalanceValue == 0}
                            onClick={() => this.setOpeningBalance()}>
                            Set
                        </Button>

                    </ModalFooter>
                </ComposedModal>
            </>
        )
    }


}