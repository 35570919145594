import { Checkbox, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from "carbon-components-react";
import { useEffect, useState, useMemo } from "react";
import Button from "../../components/Button";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../constants/Constants";
import Api from "../../session/Api";
import { getAccountRole } from "../../session/SessionManager";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";

function useCanPublishDesign(open) {
    return useMemo(() => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }, [open])
}


export function NewReportDesignDialog({ reportName, designState, open, onClose, reportTitle, onCreated }) {
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState("")
    const [showForAll, setShowForAll] = useState(false);
    const [offeringToShowForAll, setOfferingToShowForAll] = useState(false);

    const onCreateBtn = () => {
        setLoading(true)
        Api.saveReportDesign(reportName, label, showForAll, offeringToShowForAll, designState, response => {
            if (response.status === true) {
                onCreated(response.payload)
                onClose()
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (open) {
            setLabel("")
            setShowForAll(false)
            setOfferingToShowForAll(false)
        }
    }, [open])

    const canPublish = useCanPublishDesign(open)

    return (
        <ComposedModal size="sm" open={open} onClose={onClose}>
            <ModalHeader label={reportTitle} title="New Design" />
            <ModalBody>
                <TextInput
                    data-modal-primary-focus
                    labelText="Title"
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                />

                {canPublish && <div style={{ marginTop: '1rem' }} onClick={() => setShowForAll(p => !p)}>
                    <Checkbox labelText="Public" checked={showForAll} />
                </div>}
                {!canPublish && <div style={{ marginTop: '1rem' }} onClick={() => setOfferingToShowForAll(p => !p)}>
                    <Checkbox labelText="Request to publicize" checked={offeringToShowForAll} />
                </div>}
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={loading || !Util.isStringExists(label)}
                    onClick={onCreateBtn}>
                    {loading ? "Loading..." : "Create"}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}