import { InlineLoading, Tag } from "carbon-components-react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";

function showShowtimeSelector() {

}

const Item = ({ movie }) => {
    const [cli, setCli] = useState(false);
    return (
        <div onClick={() => {
            setCli(true)
        }} style={{ width: cli ? undefined : 150, flex: cli ? 1 : undefined, height: 250, background: 'red' }}>
        </div>
    )
}

const LoadingMessage = () => {
    return (
        <div className='really_centered-progress-bar' style={{ width: '100%', height: 'calc(100vh - 10rem - 35px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <InlineLoading />
        </div>
    )
}

function onShowtime(onGuestBtn) {
    onGuestBtn();
    return;

    UIUtil.showOverlay2(onClose => (
        <div style={{ borderRadius: 25, width: 300, height: 300, background: '#1c1c1c', color: 'white' }}>
            <h1>Continue as guest?</h1>
            <Button onClick={() => {
                onGuestBtn();
                onClose();
            }}>yes</Button>
        </div>
    ))
}

export function ShowtimeMovieList({ cinemaId, movieId, day }) {
    const history = useHistory();

    const [schedules, setSchedules] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        setSchedules(null)
        // Api.getShowtimeSchedules(cinemaId, day, response => {
        //     if (response.status === true) {
        //         setSchedules(response.payload)
        //         setLoading(false);
        //     } else {
        //         UIUtil.showError(response.message);
        //     }
        // })

        Api.demo_getMovieSchedules(movieId, day, response => {
            if (response.status === true) {
                setSchedules(response.payload)
                setLoading(false);
            } else {
                UIUtil.showError(response.message);
            }
        })
    }, [movieId, day])

    if (loading) {
        return <LoadingMessage />
    }

    const movie = schedules.movie;

    return (
        <div style={{ padding: '1rem', width: '100%' }}>
            {movie.cinemas.map(cinema => (
                <div key={cinema.id} style={{ width: '100%', marginBottom: '3rem', background: '#1c1c1c', borderRadius: 10, border: '1px solid #00000090', color: 'white', padding: '1rem' }}>
                    <h4>{cinema.name}</h4>
                    <div style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
                        {cinema.theaterTypes.map(theaterType => (
                            <div key={theaterType.id} style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}>
                                <h6>{theaterType.name}</h6>
                                <div>
                                    {theaterType.schedules.map(showtime => (
                                        <Tag type="purple" onClick={() => onShowtime(() => {
                                            history.push("/cinema-web/" + movieId + '/' + showtime.id);
                                        })} key={showtime.id}>{showtime.value} {showtime.threeD && <strong>3D</strong>}</Tag>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

        </div>
    )
}