import { ContentSwitcher, NumberInput, Switch, TextInput } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'

const Input = ({ changeListener, setValue, width, setWidth, setHeight, height }) => {
    const [unit, setUnit] = useState("px");

    // const widthCm = (width / DPI) * 2.54

    return (
        <div>
            {/* <div style={{ marginBottom: '1rem' }}>
                <label className="bx--label">unit</label>
                <ContentSwitcher selectedIndex={unit === "px" ? 0 : 1} size="sm" onChange={e => setUnit(e.name)}>
                    <Switch name="px" text="px" />
                    <Switch name="cm" text="cm" />
                </ContentSwitcher>
            </div> */}

            {unit === "px" && <>
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end' }}>
                    <TextInput {...changeListener} labelText="width (px)" value={width} onChange={e => setValue(e.target.value, setWidth)} />
                    <div style={{ height: 40, width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p>x</p>
                    </div>
                    <TextInput {...changeListener} labelText="height (px)" value={height} onChange={e => setValue(e.target.value, setHeight)} />
                </div>
            </>}

            {/* {unit === "cm" && <>
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end' }}>
                    <TextInput {...changeListener} labelText="width (cm)" value={width} onChange={e => setValue(e.target.value, setWidth)} />
                    <div style={{ height: 40, width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p>x</p>
                    </div>
                    <TextInput {...changeListener} labelText="height (cm)" value={height} onChange={e => setValue(e.target.value, setHeight)} />
                    <TextInput {...changeListener} labelText="height (cm)" value={height} onChange={e => setValue(e.target.value, setHeight)} />
                </div>
            </>} */}
        </div>
    )
}

export default ({ style, size, onSizeChange }) => {
    const [isFocus, setIsFocus] = useState(false);

    const [width, setWidth] = useState(size.width);
    const [height, setHeight] = useState(size.height);

    useEffect(() => {
        if (isFocus) {
            return;
        }

        if (size.width != width) {
            setWidth(size.width);
        }
        if (size.height != height) {
            setHeight(size.height);
        }
    }, [size]);

    const setValue = (value, setter) => {
        value = value.replace("-", "");

        if (!isNaN(value)) {
            if (value.trim() == "") {
                setter(0);
            } else {
                setter(parseInt(value))
            }
        }
    }

    const updateSize = () => {
        if (size.width != width || size.height != height) {
            onSizeChange({ width, height })
        }
    }

    const changeListener = {
        onFocus: () => setIsFocus(true),
        onBlur: () => {
            setIsFocus(false);
            updateSize();
        },
        onKeyPress: e => {
            if (e.key == "Enter") {
                updateSize();
            }
        }
    }

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', ...style }}>
            <Input {...{ changeListener, setValue, updateSize, width, setWidth, height, setHeight }} />
        </div>
    )

    // return (
    //     <div style={{width: '100%', display: 'flex', alignItems: 'flex-end', ...style}}>
    //         <TextInput {...changeListener} labelText="width" value={width} onChange={e => setValue(e.target.value, setWidth)} />
    //         <div style={{height: 40, width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    //             <p>x</p>
    //         </div>
    //         <TextInput {...changeListener} labelText="height" value={height} onChange={e => setValue(e.target.value, setHeight)} />
    //     </div>
    // )
}