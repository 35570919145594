import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import DeliverableItemPage from "../../../pages/sales-order/DeliverableItemPage";
import { getAccountRole } from "../../../session/SessionManager"
import { WirelessCheckout32 } from '@carbon/icons-react'

export const RestOrderList = {
    filter: Filter,
    icon: WirelessCheckout32,

    isListMode: true,
    isManageMode: true,

    sideViews: {
        updateForm: {
            Component: (props) => {
                return (
                    <DeliverableItemPage orderIdOverride={props.itemId} />
                )
            }
        }
    },

    isAvailable: () => {
        if (true) {
            return false;
        }

        const role = getAccountRole();
        return hasCapabilitySupport("restaurant") && (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR);
    }
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
