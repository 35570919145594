import { Checkbox, Tag, TextArea, TextInput } from "carbon-components-react";
import { ComboBoxField } from "../../../../../components/ComboBoxField";
import { makeListInput } from "../../../../../components/list-input";
import { Close16 } from '@carbon/icons-react'
import Util from "../../../../../util/Util";
import Api from "../../../../../session/Api";
import UIUtil from "../../../../../util/UIUtil";
import { CheckmarkFilled32, Hourglass16, CheckmarkFilled16 } from '@carbon/icons-react'
import DatePicker from "../../../../../components/DatePicker";
import DateTimePicker from "../../../../../components/DateTimePicker";
import { StatelessDateTimePicker } from "../../../../../components/DateTimePicker";



export const PackageLoadingUnloadingListEditor = makeListInput(({ item: itemPackage, setItemValue, isUnloading }) => {
    if (!Util.isNumberExist(itemPackage.id)) {
        return <div />;
    }
    return (<>

        <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            <h6 style={{ marginBottom: '0.5rem' }}>Package: {itemPackage.id}</h6>
            <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{isUnloading ? 'Unloading' : 'Loading'} Date</label>
            <StatelessDateTimePicker date={itemPackage.loadOrUnloadDate} setDate={date => setItemValue('loadOrUnloadDate', date)} />
            <div style={{ marginBottom: '1rem' }} />

            {itemPackage.portLoadingConfirmed ? <>
                <Tag type="green" renderIcon={CheckmarkFilled16}>Confirmed by {itemPackage.portLoadingConfirmedName}</Tag>
                <label className="bx--label">{Util.getDate(itemPackage.portLoadingConfirmedDate)}</label>
            </> : <>
                <Tag renderIcon={Hourglass16}>Pending confirmation</Tag>
            </>}

            <div style={{ width: '100%', marginBottom: '1rem', paddingLeft: '6rem' }}>
                {itemPackage?.items.map(itemPackageItem => (<>
                    <div style={{ marginTop: '1rem' }}>
                        <h6 style={{ marginBottom: '0.25rem' }}>Item: {itemPackageItem.id}</h6>

                        {itemPackageItem.portLoadingConfirmed ? <>
                            <Tag type="green" renderIcon={CheckmarkFilled16}>Confirmed by {itemPackageItem.portLoadingConfirmedName}</Tag>
                            <label className="bx--label">{Util.getDate(itemPackageItem.portLoadingConfirmedDate)}</label>
                        </> : <>
                            <Tag renderIcon={Hourglass16}>Pending confirmation</Tag>
                        </>}
                    </div>
                </>))}
            </div>
        </div>

        {/* {item.confirmed && <TextArea value={item.confirmNote} placeholder="Confirm note..." onChange={e => setItemValue("confirmNote", e.target.value)} />} */}




    </>)
}, () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
), true)