import ProfilePic from "../../../../components/ProfilePic"
import { OBJECT_TYPE_ACCOUNT } from "../../../../constants/ObjectTypes"
import Api from "../../../../session/Api"
import { getAccount } from "../../../../session/SessionManager"
import { ErrorFilled16, WirelessCheckout16 } from '@carbon/icons-react'
import Button from "../../../../components/Button"
import useThirdPartyPos from "../../pos/useThirdPartyPos"
import useStore from "../../../../hooks/useStore"
import ConfirmOrderDialog from "../dialogs/ConfirmOrderDialog"
import { useEffect, useState } from "react"

export default ({ sessionStore }) => {
    const [cartItems] = useStore(sessionStore, "currentCartItems")
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)

    useEffect(() => {
        if (cartItems.length === 0) {
            setConfirmDialogVisible(false)
        }
    }, [cartItems])
    return (
        <div className="dashboard-card t-pos-card" style={{ width: '100%', background: '#f4f4f4', padding: '0.5rem', display: 'flex', alignItems: 'center', borderRadius: 10 }}>
            <div style={{ display: 'flex', marginTop: '0rem', alignItems: 'center', width: '100%' }}>
                <label style={{ margin: 0, marginLeft: '0.5rem' }} className="bx--label">Checkout {cartItems.length} item{cartItems.length !== 1 ? 's' : ''}</label>

                <div style={{ flex: 1 }} />

                <Button
                    disabled={cartItems.length === 0}
                    onClick={() => setConfirmDialogVisible(true)} className="t-pos-btn" renderIcon={WirelessCheckout16}>Create Order</Button>
            </div>

            {confirmDialogVisible &&
                <ConfirmOrderDialog
                    sessionStore={sessionStore}
                    visible={confirmDialogVisible} onClose={() => setConfirmDialogVisible(false)} />}
        </div>
    )
}