import { useRef, useState } from "react"
import useSize from "../../util/useSize"

import {
    DocumentBlank16, ArrowRight16, Box16, Edit16, Reset16, Download16
} from '@carbon/icons-react'
import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, Tag } from "carbon-components-react";
import Util from "../../util/Util";
import { Icon } from "./components/icon";
import { ADVANCED_NOTES_TYPE } from ".";
import { typeName } from "./components/type-name";
import Api from "../../session/Api";
import Button from "../../components/Button";

const ArchiveRestoreBtn = ({ item, archive, onArchiveItem, onRestoreItem }) => {
    const [loading, setLoading] = useState(false);
    return (
        <button onClick={e => {
            e.stopPropagation();
            setLoading(true);
            const onDone = () => setLoading(false);
            if (archive) {
                onArchiveItem(item, onDone);
            } else {
                onRestoreItem(item, onDone);
            }
        }} style={{ pointerEvents: loading ? 'none' : undefined }} className={`lead-flow-icon-button ${loading ? '' : 'lead-flow-icon-button-secondary'} really_centered-progress-bar`} data-tip={archive ? "Archive" : "Restore"} data-effect="solid" data-place="left">
            {loading ? <InlineLoading /> : archive ? <Box16 /> : <Reset16 />}
        </button>
    )
}

export function TreeItem({ readonly, archive, item, title, type, isSection, itemCount, expanded, setExpanded, SortHandler, onEditItem, onArchiveItem, onRestoreItem }) {
    const [viewOpen, setViewOpen] = useState(false);
    const onClick = () => {
        if (type == ADVANCED_NOTES_TYPE.FOLDER) {
            setExpanded?.(!expanded);
        } else {
            setViewOpen(true);
        }
    }
    return (
        <div className="advanced-notes-items-tree-root" style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {SortHandler && !readonly && !expanded && <SortHandler />}
            {/* {SortHandler && expanded && <div style={{ width: '1.5rem' }} />} */}
            <div onClick={onClick} className="advanced-notes-items-tree" style={{ overflow: 'hidden', width: '100%', height: '3rem', borderRadius: 5, background: '#F3F3F3', border: '1px solid #00000020', gap: '1rem', display: 'flex', alignItems: 'center', paddingRight: '1rem', }}>
                <div className="advanced-notes-items-thumb-icon-tree" style={{ width: '3rem', height: '3rem', minWidth: '3rem', padding: expanded ? 0 : '0.25rem', transition: '250ms', background: 'none', border: 'none' }}>
                    <div style={{ height: '100%', width: '100%', background: expanded ? '#1c1c1c' : 'white', color: expanded ? 'white' : 'black', transition: 'background 250ms', borderRadius: expanded ? 0 : 5, borderRight: expanded ? '1px solid #00000020' : undefined, border: expanded ? undefined : '1px solid #00000020', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Icon treeMode type={type} style={{ transition: '250ms', transform: expanded ? 'rotate(90deg)' : undefined }} />
                    </div>
                </div>
                <div style={{ flex: 1, minWidth: 0 }}>
                    <p style={{ userSelect: 'none', fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</p>
                    {!isSection && Util.isStringExists(item.description) && <p style={{ userSelect: 'none', fontSize: 12, opacity: 0.65, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</p>}
                </div>
                {/* <div style={{ flex: 1 }} /> */}

                {isSection ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
                        <Tag style={{ userSelect: 'none', }} type={expanded ? undefined : "blue"} size="sm">{itemCount} item{itemCount != 1 ? 's' : ''}</Tag>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
                        <p style={{ fontSize: 12, opacity: 0.65, userSelect: 'none', }}>{typeName(type)}</p>
                    </div>
                )}

                {!readonly && <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <ArchiveRestoreBtn archive item={item} onArchiveItem={onArchiveItem} onRestoreItem={onRestoreItem} />
                    <button onClick={e => {
                        e.stopPropagation();
                        onEditItem(item)
                        //}} className='lead-flow-icon-button lead-flow-icon-button-primary' data-tip="Edit" data-effect="solid" data-place="left">
                    }} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                        <Edit16 />
                    </button>
                </div>}
                {archive && onRestoreItem && <ArchiveRestoreBtn item={item} onArchiveItem={onArchiveItem} onRestoreItem={onRestoreItem} />}
            </div>


            {viewOpen &&
                <ComposedModal key="view-note-dialog" size="sm" open={viewOpen} onClose={() => setViewOpen(false)}>
                    <ModalHeader label="Viewing" title={typeName(type)} />
                    <ModalBody>
                        <h2>{item.title}</h2>

                        {/* <div style={{height: '1rem'}} /> */}

                        <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all', overflow: 'hidden' }}>{item.description}</p>

                        {Util.isStringExists(item.attachmentName) &&
                            <a download href={Api.downloadAdvancedNoteAttachment(item.id)} style={{ textDecoration: 'none' }}><Button style={{ marginTop: '1rem' }} renderIcon={Download16}>{item.attachmentName}</Button></a>}
                    </ModalBody>
                    <ModalFooter>
                        <Button kind="secondary" onClick={() => setViewOpen(false)}>
                            Done
                        </Button>
                    </ModalFooter>
                </ComposedModal>}
        </div>
    )
}