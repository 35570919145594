import { useState } from "react"
import { useFormField } from "../../../../../templates/form/form"
import Util from "../../../../../util/Util"
import { ComboBox } from "carbon-components-react"
import { useEffect } from "react"
import { ChartRelationship24, ArrowRight16 } from '@carbon/icons-react'

const Record = ({ item }) => (
    <div style={{ width: '100%', paddingInline: '1rem', paddingBlock: '0.5rem', pointerEvents: 'none' }}>
        <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
            <ChartRelationship24 />
            <div>
                <h4 style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>{item.value}</h4>
            </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginTop: '0.15rem' }}>
            <p>{item.origin}</p>
            <ArrowRight16 />
            <p>{item.destination}</p>
        </div>
    </div>
)

export function ShipmentMasterSelector({ config, store, options }) {
    const [value, setValue] = useFormField(config, store, "shipmentMasterId", null)
    const [key, setKey] = useState(() => Util.newTempId())

    useEffect(() => {
        if (!value) {
            setKey(Util.newTempId())
        }
    }, [value])

    return (
        <div className="any-height-combo" style={{ marginBottom: '1rem' }}>
            <ComboBox
                key={key}
                titleText={"Shipment Master"}
                items={options}
                itemToString={item => item !== null ? (item.value + ' (' + item.origin + ' - ' + item.destination + ')') : ""}
                itemToElement={item => <Record key={item.id} item={item} />}
                selectedItem={options.find(option => option.id === value)}
                onChange={e => {
                    setValue(e.selectedItem ? e.selectedItem.id : null)
                }} />
        </div>
    )
}