

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

import Logo from '../../../images/SquareLogo.png';
import ReceiptLogo from '../../../images/ReceiptLogo.png'

import QRCode from "react-qr-code"
import Util from "../../../util/Util"
import { paymentMethodTextWithArabic, paymentMethodText } from "../../../pages/transaction/TransactionEditor"
import { THERMAL_RECEIPT_LOGO, VAT } from '../../../app/Config';
import { POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';


const Line = () => <br />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>

const Text = ({ bold, italic, size, center, right, underline, children }) => <p style={{
    fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
    textDecoration: underline ? "underline" : undefined,
    fontStyle: italic ? "italic" : undefined,
    whiteSpace: 'pre-wrap'
}}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={20}>{children}</Text>
const Italic = ({ children }) => <Text italic size={20}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={20}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={20}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={20}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={20}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={20}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={20}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={20}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={20}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={20}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>



export const PosCartPrint = ({ request }) => {
    const couponCodes = request.discounts.filter($ => $.type === POS_SESSION_DISCOUNT_TYPE_COUPON).map($ => $.couponCode);
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            {/* <Line />
            <Line />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={THERMAL_RECEIPT_LOGO.squareLogo ? Logo : ReceiptLogo} style={{ width: THERMAL_RECEIPT_LOGO.width, height: THERMAL_RECEIPT_LOGO.height, objectPosition: 'center', objectFit: 'contain', }} />
            </div> */}

            {/* <CenterNormal>{request.customerName}</CenterNormal>
            <CenterNormal>{request.terminalName}</CenterNormal>
            <CenterNormal>{request.salespersonName}</CenterNormal> */}

            <Line />
            <Line />

            <BoldCenterNormal>{request.__company_name}</BoldCenterNormal>
            <CenterNormal>{request.receiptInfo.usrsys_address}</CenterNormal>
            <CenterNormal>Tel: {request.receiptInfo.usrsys_phone}</CenterNormal>
            <CenterNormal>TRN: {request.receiptInfo.usrsys_trn}</CenterNormal>

            <Line />
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Normal>POS: {request.terminalName}</Normal>
                <div style={{ flex: 1 }} />
                <RightNormal>{request.salespersonName}</RightNormal>
            </div>
            <Line />

            <CenterNormal>Sales Cart</CenterNormal>
            {/* <CenterBold>فاتورة ضريبيه</CenterBold> */}

            <Line />
            <Line />
            <Line />

            <div style={{ marginLeft: 25 }}>
                <Bold>{Util.isStringExists(request.customerName) ? request.customerName : "Guest"},</Bold>
            </div>

            <Divider />
            <Normal>{"#   " + weightText("Description", 20, true) + " " + weightText("Qty", 8, false) + " " + weightText("Amount", 8, false)}</Normal>
            <Divider />

            {request.currentCartItems.map((item, index) => (<>
                {Util.isNumberExist(item.itemDiscountedTotal) ? (<>

                    <Normal>
                        {weightText(index + 1, 3, true) +
                            " " +
                            weightText(item.ref, 20, true) +
                            " " +
                            weightText(item.quantityValue + "x", 8, false) +
                            " "}
                        <span style={{ textDecoration: 'line-through', }}>{weightText(item.totalAmount.price.toFixed(2).replaceAll("0", "O"), 8, false)}</span>
                    </Normal>

                    {item.name.length > 20 ? (<>
                        <Normal>{"    " + item.name.substring(0, 20) + "      " + weightText(item.itemDiscountedTotal.toFixed(2).replaceAll("0", "O"), 8, false)}</Normal>
                        <Normal>{"    " + weightText(item.name.substring(20), 20, true)}</Normal>
                    </>) : (
                        <Normal>{"    " + weightText(item.name, 20, true) + "      " + weightText(item.itemDiscountedTotal.toFixed(2).replaceAll("0", "O"), 8, false)}</Normal>
                    )}

                </>) : (<>


                    <Normal>
                        {weightText(index + 1, 3, true) +
                            " " +
                            weightText(item.ref, 20, true) +
                            " " +
                            weightText(item.quantityValue + "x", 8, false) +
                            " " +
                            weightText(item.totalAmount.price.toFixed(2).replaceAll("0", "O"), 8, false)}
                    </Normal>

                    {item.name.length > 20 ? (<>
                        <Normal>{"    " + item.name.substring(0, 20)}</Normal>
                        <Normal>{"    " + weightText(item.name.substring(20), 20, true)}</Normal>
                    </>) : (
                        <Normal>{"    " + weightText(item.name, 20, true)}</Normal>
                    )}

                </>)}



            </>))}

            <Line />
            <Line />
            <Divider />
            <div style={{ display: "flex", alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Normal>Total Items: {request.currentCartItems.length}</Normal>
                </div>
            </div>
            <Divider />
            <Line />
            <Line />


            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>Subtotal</Bold>
                <div style={{ flex: 1 }}>
                    <RightNormal>AED {request.posTotals.subtotal.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            {request.posTotals.discount !== undefined && request.posTotals.discount !== null && request.posTotals.discount > 0 && (<>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Bold>Discount</Bold>
                    <div style={{ flex: 1 }}>
                        <RightNormal>AED {request.posTotals.discount.toFixed(2).replaceAll("0", "O")}</RightNormal>
                    </div>
                </div>
                {couponCodes.map($ => (<>
                    <div style={{ marginLeft: 25 }}>
                        <Italic>Redeemed Code: {$}</Italic>
                    </div>
                </>))}
                <Line />
            </>)}

            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>VAT {`${VAT.PERC}%`}</Bold>
                <div style={{ flex: 1 }}>
                    <RightNormal>AED {request.posTotals.tax.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DoubleBold>Grand Total</DoubleBold>
                <div style={{ flex: 1 }}>
                </div>
            </div>
            <DoubleRightNormal>AED {request.posTotals.total.toFixed(2).replaceAll("0", "O")}</DoubleRightNormal>
            <Line />
            {Util.isNumberExist(request.cashbackAmount) && <>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ flex: 1 }} />
                    <Bold>Change:</Bold>
                    <div style={{ marginLeft: 15 }}>
                        {/* <ArabicNormal>ضريبة</ArabicNormal> */}
                        <RightNormal>AED {request.cashbackAmount.toFixed(2).replaceAll("0", "O")}</RightNormal>
                    </div>
                </div>
                <Line />
            </>}


            <Line />
            <Line />

            {request.posTotals.totalSavings > 0 && <>
                <CenterNormal>You have saved AED {request.posTotals.totalSavings}!</CenterNormal>
                <CenterBold>AED {request.posTotals.totalSavings} لقد وفرت</CenterBold>
            </>}

            <Line />
            <Line />
            <Line />
            <Line />


            <CenterNormal>This is NOT a paid invoice/receipt!</CenterNormal>
            {/* <CenterBold>شكرا للتسوق لدينا</CenterBold> */}
            <CenterNormal>{Util.getFullDate(new Date().getTime())}</CenterNormal>

            <Line />
            <Line />
            <Line />
            <Line />
            <Underline>Terms & Conditions</Underline>
            <Normal>{request.receiptInfo.usrsys_terms_and_conditions}</Normal>

            <Line />
            <Line />
            <Line />

            <CenterNormal>{request.currentCartId}</CenterNormal>

            {/* <Line />
            <Line />
            <Line />
            <Line />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <QRCode size={200} value={request.lastStockFlowRecordId} />
            </div> */}

            <Line />
            <Line />
            <Line />
            <Line />
        </div>
    )

}

function weightText(text, weight, leftAligned) {
    if (text == null) {
        text = "";
    }

    if (!text || !text.substring) {
        text = text + "";
    }

    if (text.length > weight) {
        text = text.substring(0, weight - 3) + "...";
    } else {
        const textLength = text.length;
        for (let i = 0; i < (weight - textLength); i++) {
            if (leftAligned) {
                text += ' ';
            } else {
                text = " " + text;
            }
        }
    }
    return text;
}