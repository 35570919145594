import { Close16 } from "@carbon/icons-react"
import TransactionCreatorPage from "./TransactionCreatorPage"
import { useLockScroll } from "../../hooks/useLockScroll"
import { withLoadablePagePropsAndProps } from "../../base/Page"
import Api from "../../session/Api"

function View({ payload: item, onClose }) {
    useLockScroll(true)
    return (
        <div style={{ paddingInline: '0rem', paddingBlock: '6rem' }}>
            <div style={{ display: "flex", alignItems: 'flex-start', marginBottom: '3rem', paddingInline: '3rem' }}>
                <div style={{ flex: 1 }}>
                    <h4>Transaction</h4>
                    <h6>Editing</h6>
                </div>

                <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>

            <div style={{ paddingInline: '1.5rem' }}>
                <TransactionCreatorPage editing={item} />
            </div>
        </div>
    )
}

export const EditTransaction = withLoadablePagePropsAndProps(props => listener => Api.getTransactionItem(props.itemId, listener), View)