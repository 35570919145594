

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

import Logo from '../../../images/other/rivas/logo.png';
import AbsharLogo from '../../../images/other/abshar/thermal-logo.png';
import FoodBazaarLogo from '../../../images/other/food-bazaar/thermal-logo.png';

import GenericLogo from '../../../images/SquareLogo.png';
import GenericReceiptLogo from '../../../images/ReceiptLogo.png'


import QRCode from "react-qr-code"
import Util, { big } from "../../../util/Util"
import { paymentMethodTextWithArabic } from "../../../pages/transaction/TransactionEditor"
import { COMPANY_NAME, THERMAL_RECEIPT_LOGO, VAT } from '../../../app/Config';
import { PAYMENT_METHOD_PROXY_OUTSTANDING, POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';
import { REST_ORDER_TYPE, getRestOrderDate, getRestOrderNote, restOrderTypeName, restOrderTypeNameWithDate } from '../../../domain/rest-sales-order';
import { addressToString, getAddressCustomerName } from '../../../pages/sales-order/base/sales-order';
import { getAccount } from '../../../session/SessionManager';
import { isAbshar, isAnyAbshar, isFoodBazaar, isRivas } from '../../../app/app-util';
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { Fragment, useMemo } from 'react';


const Line = () => <br />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>
const FullDivider = () => <div style={{ width: '100%', height: 4, background: 'black' }} />

const Text = ({ bold, italic, size, center, right, underline, children }) => <p style={{
    fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
    textDecoration: underline ? "underline" : undefined,
    fontStyle: italic ? "italic" : undefined,
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap'
}}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={25}>{children}</Text>
const Italic = ({ children }) => <Text italic size={25}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={25}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={25}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={25}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={25}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={25}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={25}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={25}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={25}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={25}>{children}</Text>
const MdBold = ({ children }) => <Text bold size={25}>{children}</Text>
const LgBoldRight = ({ children }) => <Text right bold size={30}>{children}</Text>
const LgNormal = ({ children }) => <Text size={30}>{children}</Text>
const LgBold = ({ children }) => <Text bold size={30}>{children}</Text>
const MdNormal = ({ children }) => <Text size={25}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>
const CenterDoubleBold = ({ children }) => <Text center bold size={40}>{children}</Text>
const CenterMdBold = ({ children }) => <Text center bold size={25}>{children}</Text>
const CenterLgBold = ({ children }) => <Text center bold size={30}>{children}</Text>


function subtitle(order) {
    if (order.type === REST_ORDER_TYPE.DINE_IN) {
        return 'Table ' + order.tableName
    } else if (order.type === REST_ORDER_TYPE.TAKE_OUT) {
        if (Util.isStringExists(order.memo)) {
            return order.memo
        } else {
            return 'Order'
        }
    } else if (order.type === REST_ORDER_TYPE.DELIVERY || order.type === REST_ORDER_TYPE.PICK_UP) {
        if (order.shippingAddress && (Util.isStringExists(order.shippingAddress.firstName) || Util.isStringExists(order.shippingAddress.lastName))) {
            const fullName = [order.shippingAddress.firstName, order.shippingAddress.lastName].filter(Util.isStringExists).join(" ");
            return fullName;
        } else {
            return "Order";
        }
    } else {
        return "Order";
    }
}

function getTotalQuantity(cartItems) {
    return cartItems.map(item => item.continuousStockType ? 1 : item.quantityValue).reduce((t, v) => t.plus(big(v)), big(0)).toNumber();
}


function OrderPreferencesView({ order }) {
    const preferences = order.preferences;
    const data = useMemo(() => JSON.parse(preferences ?? "[]"), [preferences])
    const isNote = $ => $.id === "id" || $.id === "note" || $.id === "Additional Notes";
    const shouldPrint = $ => !isNote($);;

    const items = data?.filter?.(shouldPrint) ?? [];

    if (!items.length) {
        return null;
    }

    return <>
        <Line />
        <Line />
        <div style={{ border: '4px solid black', width: '100%' }}>
            {items.map((item, index) => <Fragment key={item.id}>
                {index > 0 ? <Line /> : null}
                <Text underline size={20}>{item.label}</Text>
                <Text size={25}>{typeof item.value === "boolean" ? (item.value ? 'Yes' : 'No') : item.value}</Text>
            </Fragment>)}
        </div>
    </>
}


export const BillPrintRivas = ({ order, request }) => {
    let orderNote = getRestOrderNote(order);
    const orderDate = getRestOrderDate(order);

    const couponCodes = request.discounts.filter($ => $.type === POS_SESSION_DISCOUNT_TYPE_COUPON).map($ => $.couponCode);


    // console.log(request.currentCartItems)
    const hasKotAlready = request.currentCartItems?.some($ => Util.isStringExists($.restKotNo))
    const cartItems = request.currentCartItems?.filter($ => {
        const skip = hasCapabilitySupport("restaurant") && hasKotAlready && !Util.isStringExists($.restKotNo);
        return !$.flagRemovedItemRecord && !skip;
    }).map($ => $.flagDeliveryFee ? ({ ...$, name: "Delivery Fee" }) : $);
    // summary.setProducts(sessionInfo.getCurrentCartItems().stream().filter(item -> {
    //     boolean skip = Capabilities.RESTAURANT.hasCapability() && sessionInfo.getPosSessionType() == PosSession.SESSION_TYPE_SALES_ORDER && hasKotAlready && !Util.isStringExist(item.getRestKotNo());
    //     return !item.isFlagDeliveryFee() && !item.isFlagRemovedItemRecord() && !skip;
    // }).map(OrderSummary.Product::fromCartItem).collect(Collectors.toList()));
    // const cartItems = request.currentCartItems?.filter($ => !$.flagRemovedItemRecord).map($ => $.flagDeliveryFee ? ({ ...$, name: "Delivery Fee" }) : $);


    let paymentMethods = [...(request.paymentMethods ?? order.paymentMethods ?? [])];
    if (request.paymentMethods?.length === 0 && order.paymentMethods?.length > 0) {
        paymentMethods = [...order.paymentMethods]
    }
    if (order.unpaidAmount > 0 && paymentMethods.length > 0) {
        paymentMethods = [...paymentMethods, {
            methodType: PAYMENT_METHOD_PROXY_OUTSTANDING,
            amount: order.unpaidAmount
        }]
    }


    // order.shippingAddress.phoneNumber = "0509462048";

    // order.type = REST_ORDER_TYPE.DINE_IN;
    // order.tableName = "4";
    // cartItems.forEach($ => $.recipeCustomizationSummary = "this is the est  fewiofewfioewjf \n NEW LINE \n NE fewijfewifjewij")
    // orderNote = "This is the ordernote!!"
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            {/* <Line /> */}
            {isRivas() && <>
                <Line />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={Logo} style={{ width: 268, height: 108, objectPosition: 'center', objectFit: 'contain', }} />
                </div>
            </>}
            {isAbshar() && <>
                <Line />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={AbsharLogo} style={{ width: 480, height: 173, objectPosition: 'center', objectFit: 'contain', }} />
                </div>
            </>}
            {isFoodBazaar() && <>
                <Line />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={FoodBazaarLogo} style={{ width: 466, height: 116, objectPosition: 'center', objectFit: 'contain', }} />
                </div>
            </>}
            {hasCapabilitySupport("extendedPosCheckout") && <>
                <Line />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={THERMAL_RECEIPT_LOGO.squareLogo ? GenericLogo : GenericReceiptLogo} style={{ width: THERMAL_RECEIPT_LOGO.width, height: THERMAL_RECEIPT_LOGO.height, objectPosition: 'center', objectFit: 'contain', }} />
                </div>
            </>}


            <Line />
            <Line />
            <BoldCenterNormal>{request.__company_name}</BoldCenterNormal>
            <CenterNormal>{request.receiptInfo.usrsys_address}</CenterNormal>
            <CenterNormal>Tel: {request.receiptInfo.usrsys_phone}</CenterNormal>

            <Line />
            <Line />
            <FullDivider />
            <Text center size={25}>TAX INVOICE {request.receiptInfo.usrsys_trn}</Text>
            <FullDivider />

            <Line />
            <Text underline center size={30}>{restOrderTypeName(order.type)}</Text>
            <Line />
            <Line />
            {order.type === REST_ORDER_TYPE.DINE_IN &&
                <Text center size={40}>{subtitle(order)}</Text>}
            <Text center size={30}>Order# {order.orderNo}</Text>
            {Util.isStringExists(order.kotNo) && <Text center size={30}>KOT# {order.kotNo}</Text>}

            {hasCapabilitySupport("extendedPosCheckout") && <>
                {(Util.isStringExists(order.assignedDriverName) || Util.isStringExists(order.actualDeliveryDoneBy)) && <>
                    <Text center size={25}>{order.actualDeliveryDoneBy || order.assignedDriverName}</Text>
                </>}

                {Util.isStringExists(order.awbNo) && <>
                    <Text center size={25}>AWB: {order.awbNo}</Text>
                </>}
            </>}

            <Line />

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 15 }}>
                <Text underline size={25}>Staff:</Text>
                <Text size={25}>{getAccount().fullName}</Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }}>
                    <Text size={25}>{Util.formatDate(Util.isNumberExist(orderDate) ? orderDate : request.__current_date, "DD-MMM-YYYY")}</Text>
                </div>
                <div style={{ flex: 1 }}>
                    <Text right size={25}>{Util.formatDate(Util.isNumberExist(orderDate) ? orderDate : request.__current_date, "hh:mm A")}</Text>
                </div>
            </div>
            <Line />

            <div style={{ marginLeft: 25 }}>
                <Text size={25}>{(Util.isStringExists(request.customerName) && request.customerName !== 'Guest') ? request.customerName.trim() : getAddressCustomerName(order.shippingAddress)},</Text>
                {/* <Text size={25}>{getAddressCustomerName(order.shippingAddress)},</Text> */}
                <div style={{ marginLeft: 25 }}>
                    {Util.isStringExists(order?.shippingAddress?.readablePhoneNumber) && <Text size={25}>{order.shippingAddress.readablePhoneNumber}</Text>}
                    {order?.shippingAddress && <Text size={25}>{addressToString(order.shippingAddress, [order.deliveryLocationName])}</Text>}
                </div>
            </div>
            <Line />

            <FullDivider />
            <div style={{ display: 'flex', alignItems: 'center', }}>
                <div style={{ flex: 1 }}>
                    <Text size={25}>Qty</Text>
                </div>
                <div style={{ flex: 1 }}>
                    <Text size={25} center>Item</Text>
                </div>
                <div style={{ flex: 1 }}>
                    <Text size={25} right>Price</Text>
                </div>
            </div>
            <FullDivider />

            <Line />
            {cartItems.map(item => (<>
                <div style={{ display: 'flex', alignItems: 'flex-start', paddingInline: 0 }}>
                    <div style={{ width: 65 }}>
                        <Text size={25}>{item.quantityValue}</Text>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Text size={25}>{item.ref}</Text>
                        <Text size={25}>{item.name}</Text>
                        {Util.isStringExists(item.recipeCustomizationSummary) && <>
                            <div style={{ marginLeft: 0, borderTop: '1px dotted black' }}>
                                <Text size={20}>{item.recipeCustomizationSummary}</Text>
                            </div>
                            <Line />
                        </>}
                    </div>
                    <div style={{ width: 115 }}>
                        <Text size={25} right>{item.totalAmount.price.toFixed(2).replaceAll("0", "O")}</Text>
                    </div>
                </div>
            </>))}
            <Line />
            <FullDivider />
            <Line />
            <div style={{ display: "flex", alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Normal>Total Items: {cartItems.length}</Normal>
                </div>
                <div style={{ flex: 1 }}>
                    <ArabicBold>{cartItems.length} :مجموع الاغراض</ArabicBold>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Normal>Total Quantity: {getTotalQuantity(cartItems)}</Normal>
                </div>
                <div style={{ flex: 1 }}>
                    <ArabicBold>{getTotalQuantity(cartItems)} :مجموع</ArabicBold>
                </div>
            </div>
            <Line />
            <FullDivider />

            <OrderPreferencesView order={order} />

            {Util.isStringExists(orderNote) && <>
                <Line />
                <Line />
                <div style={{ display: 'flex', justifyContent: 'space-between', border: '4px solid black' }}>
                    <div style={{ flex: 2 }}>
                        <Text underline size={25}>Note</Text>
                        <Text size={35}>{orderNote}</Text>
                    </div>
                </div>
            </>}

            <Line />
            <Line />
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>Subtotal</Bold>
                <div style={{ flex: 1 }}>
                    <ArabicBold>المبلغ اجمالي</ArabicBold>
                    <RightNormal>AED {request.posTotals.subtotal.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            {request.posTotals.discount !== undefined && request.posTotals.discount !== null && request.posTotals.discount > 0 && (<>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Bold>Discount</Bold>
                    <div style={{ flex: 1 }}>
                        <ArabicBold>تخفيض</ArabicBold>
                        <RightNormal>AED {request.posTotals.discount.toFixed(2).replaceAll("0", "O")}</RightNormal>
                    </div>
                </div>
                {couponCodes.map($ => (<>
                    <div style={{ marginLeft: 25 }}>
                        <Italic>Redeemed Code: {$}</Italic>
                    </div>
                </>))}
                <Line />
            </>)}

            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>VAT {`${VAT.PERC}%`}</Bold>
                <div style={{ flex: 1 }}>
                    <ArabicBold>ضريبة</ArabicBold>
                    <RightNormal>AED {request.posTotals.tax.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DoubleBold>Grand Total</DoubleBold>
                <div style={{ flex: 1 }}>
                    <ArabicBold>المجموع الاجمالي</ArabicBold>
                </div>
            </div>
            <DoubleRightNormal>AED {request.posTotals.total.toFixed(2).replaceAll("0", "O")}</DoubleRightNormal>

            <Line />
            <Line />

            {request.posTotals.totalSavings > 0 && <>
                <CenterNormal>You have saved AED {request.posTotals.totalSavings}!</CenterNormal>
                <CenterBold>AED {request.posTotals.totalSavings} لقد وفرت</CenterBold>
            </>}

            {paymentMethods?.length > 0 && <>
                <Line />
                <Line />
                <Line />
                <Line />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Underline>Payment</Underline>
                    <div style={{ flex: 1 }}>
                        <ArabicBold>الدفع</ArabicBold>
                    </div>
                </div>
                {paymentMethods.map(item => (
                    <Normal>
                        {
                            weightText(paymentMethodTextWithArabic(item.methodType), 28, true) +
                            " " +
                            weightText(item.amount.toFixed ? item.amount.toFixed(2) : parseFloat(item.amount).toFixed(2), 13, false)
                        }
                    </Normal>
                ))}
            </>}

            <Line />

            {Util.isStringExists(request.receiptInfo.usrsys_receipt_bottom_text) && <>
                <CenterNormal>{request.receiptInfo.usrsys_receipt_bottom_text}</CenterNormal>
                <Line />
            </>}

            {/* <Line /> */}
            {/* 
            <CenterNormal>Thank you for dining with us!</CenterNormal>
            <CenterBold>شكرا لك على تناول الطعام معنا</CenterBold>
            <CenterNormal>{Util.getFullDate(new Date().getTime())}</CenterNormal>


            {Util.isStringExists(request.receiptInfo.usrsys_terms_and_conditions) && <>
                <Line />
                <Line />
                <Line />
                <Line />

                <Underline>Terms & Conditions</Underline>
                <Normal>{request.receiptInfo.usrsys_terms_and_conditions}</Normal>
                <Line />
                <ArabicBold>{request.receiptInfo.__ar_usrsys_terms_and_conditions}</ArabicBold>
            </>}

            <Line />
            <Line />
            <Line />
            <Line />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <QRCode size={200} value={"https://rivas.ae"} />
            </div>

            <Line />
            <Line />
            <Line />
            <Line /> */}
        </div>
    )

}

function weightText(text, weight, leftAligned) {
    if (text == null) {
        text = "";
    }

    if (!text || !text.substring) {
        text = text + "";
    }

    if (text.length > weight) {
        text = text.substring(0, weight - 3) + "...";
    } else {
        const textLength = text.length;
        for (let i = 0; i < (weight - textLength); i++) {
            if (leftAligned) {
                text += ' ';
            } else {
                text = " " + text;
            }
        }
    }
    return text;
}