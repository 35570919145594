import { isMobileScreen } from "../../../../App";
import { BoardViewPage } from "../../../board-view/board-view-page";
import { CalendarPage } from "../../../calendar/calendar-page";


export function ToDoBoard({ leadId }) {
    return (
        <div style={{ width: '100%', height: isMobileScreen ? 'calc(100% - 3rem - 40px)' : 'calc(100% - 4rem)' }}>
            <BoardViewPage leadId={leadId} />
        </div>
    )
}