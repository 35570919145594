import { Checkbox, InlineLoading, Tag, TextInput } from "carbon-components-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DynamicTable } from "../../../../components/dynamic-table"
import { getObjectTypeName, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_PACKAGE } from "../../../../constants/ObjectTypes"

import {
    Product16, Box16, ServiceDesk16, Application16, WatsonHealthTextAnnotationToggle16
} from '@carbon/icons-react'
import Util, { absAmt, big } from "../../../../util/Util"
import InfiniteComboBox from "../../../../components/InfiniteComboBox"
import ProfilePic from "../../../../components/ProfilePic"
import Api from "../../../../session/Api"
import UIUtil from "../../../../util/UIUtil"

const COLUMNS = [
    {
        title: "Item",
        flex: 5
    },
    // {
    //     title: "Type",
    //     flex: 4
    // },
    {
        title: "Amount",
        flex: 2
    },
    {
        title: "Qty",
        flex: 1
    },
    {
        title: "Total",
        flex: 2
    }
]

const ItemView = ({ item }) => {
    return (
        <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020' }}>
            {/* <ProfilePic notProfile size={24} /> */}
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
            {item.type === "PRODUCT" && <p style={{ fontSize: 12, opacity: 0.65 }}>Product</p>}
            {item.type === "EXPENSE" && <p style={{ fontSize: 12, opacity: 0.65 }}>Expense</p>}
            {item.type === "ASSET" && <p style={{ fontSize: 12, opacity: 0.65 }}>Asset</p>}
            {/* <TypeTag type={item.type} size="sm" /> */}
        </div>
    )
}
ItemView.noPadding = true;

const ItemSelector = ({ billables, item, setItem, light }) => {
    const textValue = item?.name;
    const setTextValue = name => {
        const itemToSelect = billables.find(item => item.name === textValue);
        if (itemToSelect) {
            setItem({ ...item, name, selectedItem: itemToSelect })
        } else {
            setItem({ ...item, name, selectedItem: undefined })
        }
    }
    const selectedItem = item?.selectedItem;
    const setSelectedItem = selectedItem => {
        setItem({ ...item, name: selectedItem ? selectedItem.name : textValue, selectedItem })
    }

    const onClear = () => setItem({ ...item, name: "", selectedItem: undefined })

    return <InfiniteComboBox light={light} ItemView={ItemView} itemToString={item => item.name} items={billables}
        {...({ textValue, setTextValue, selectedItem, setSelectedItem, onClear })} />
}

const Record = ({ billables, item: recordItem, setItem: setRecordItem, useValue }) => {
    const [item, setItem] = useState({ name: "", selectedItem: undefined });
    const [amount, setAmount] = useState(undefined);
    const [qty, setQty] = useState(undefined);
    const [saveCustomService, setSaveCustomService] = useState(false)

    const type = (item?.selectedItem?.type) ?? -1;
    const total = useMemo(() => big(qty).times(big(amount)).toFixed(2), [amount, qty])
    const dummyItem = recordItem.item === undefined;

    const firstTimeRef = useRef(true)

    useEffect(() => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        }

        setRecordItem({ ...recordItem, item, amount, qty, saveCustomService })
    }, [item, amount, qty, saveCustomService])

    useEffect(() => {
        if (!dummyItem) {
            setSaveCustomService(false)
            if (type !== "PRODUCT") {
                setQty(1);
            }
        }
    }, [item])

    const onSetItem = newItem => {
        if (item.selectedItem?.type !== newItem.selectedItem?.type || item.selectedItem?.id !== newItem.selectedItem?.id) {
            setItem(newItem);
            setAmount(newItem.selectedItem?.amount ?? "")
        } else {
            setItem(newItem)
        }
        if (big(qty).lte(big(0))) {
            setQty(1)
        }
    }

    return [
        <div style={{ height: 40, width: '100%' }}>
            <ItemSelector light billables={billables} item={item} setItem={onSetItem} />
        </div>,
        <TextInput light value={amount} onChange={e => setAmount(absAmt(e.target.value))} placeholder="Input..." />,
        type === "PRODUCT" ? (
            <TextInput light value={qty} onChange={e => setQty(absAmt(e.target.value))} placeholder="Input..." />
        ) : (
            <TextInput value={""} light placeholder="N/A" style={{ pointerEvents: 'none' }} />
        ),
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '1rem' }} />
            <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold' }}>AED {total}</p>
        </div>
    ]
}


export const BillItemsTable = ({ billables, editable, items, setItems }) => {
    return (
        <div style={{ width: '100%' }}>
            <DynamicTable
                whiteBg noRadius noBorder lightBorder
                items={items} setItems={setItems}
                editable={editable} entryView={Record}
                columns={COLUMNS} entryViewProps={{
                    billables
                }} />
        </div>
    )
}