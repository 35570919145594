import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_COURSE, OBJECT_TYPE_COURSE_ENROLMENT, OBJECT_TYPE_LEAD_SOURCE } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import { CalendarPage } from '../../calendar/calendar-page';
import TableTemplate from '../../../templates/TableTemplate';
import { COURSE_ENROLMENT_CUSTOM_LIST } from '../../../constants/Constants';
import Util from '../../../util/Util';
import { BoardViewPage } from '../../board-view/board-view-page';
import { MyStudentsList } from '../components/my-students';
import ItemCreatorDialog from '../../../templates/ItemCreatorDialog';
import UIUtil from '../../../util/UIUtil';

const OBJECT_TYPE = OBJECT_TYPE_COURSE;

const Dummy = () => {
    return <div />
}

const Calendar = ({ item }) => {

    return (
        <div style={{ width: '100vw', minHeight: 'calc(100vh - 10rem)', height: 'calc(100vh - 10rem)' }}>
            <CalendarPage courseId={item.id} autoSelectBlueprintId={item.autoSelectBlueprintId} />
        </div>
    )
}


const ToDoBoard = ({ item }) => {
    return (
        <div style={{ width: '100vw', minHeight: 'calc(100vh - 10rem)', height: 'calc(100vh - 10rem)' }}>
            <BoardViewPage courseId={item.id} />
        </div>
    )
}


const Students = ({ item }) => {
    return (
        <div>
            <MyStudentsList courseId={item.id} />
        </div>
    )
}



const Enrolments = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                ref: tableRef,
                title: "Course Enrolments",
                subTitle: "All enrolments in this course",
                objectType: OBJECT_TYPE_COURSE_ENROLMENT,
                pagePath: "/course-enrolments/",
                history: history,
                paginated: false,

                embedded: true,
                customNewButtonListener: () => setShowCreateDialog(true),

                getCustomListRequest: {
                    requestType: COURSE_ENROLMENT_CUSTOM_LIST.BY_COURSE_ID,
                    params: {
                        courseId: item.id
                    }
                }
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                objectType={OBJECT_TYPE_COURSE_ENROLMENT}
                preventDeletePhraseModal
                returnItemOnSave
                skipProperties={["courseId"]}
                open={showCreateDialog} onClose={e => setShowCreateDialog(false)}
                onPreSave={update => update.courseId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    tableRef.current.saveItem(savedItem)
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}

const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();

    const [tab, setTab] = useState(0);
    const [key, setKey] = useState(() => Util.newTempId())
    // const ItemCalendar = useCallback(({ item }) => <Calendar item={item} />, [key])

    return (
        ItemTemplate.renderTemplate({
            objectType: OBJECT_TYPE,
            itemResult: itemResult,
            noPadding: true,
            pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
            loadAdvancedNotes: true,
            history: history,
            onTabChanged: index => {
                // console.log(e)
                setTab(index);
                // setKey(Util.newTempId())
            },
            customTabs: [
                {
                    title: "Enrolments",
                    component: tab === 1 ? Enrolments : Dummy
                },
                {
                    //index: 2
                    title: "Scheduling",
                    component: tab === 2 ? Calendar : Dummy
                    //component: tab === 2 ? ItemCalendar : Dummy
                },
                {
                    //index: 3
                    title: "To Do Board",
                    component: tab === 3 ? ToDoBoard : Dummy
                },
                {
                    title: "Students",
                    component: tab === 4 ? Students : Dummy
                }
            ]
        })
    )
    // return (
    //     <div className="main-content">
    //         {ItemTemplate.renderTemplate({
    //             objectType: OBJECT_TYPE,
    //             itemResult: itemResult,
    //             pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
    //             history: history,
    //             customTabs: [
    //             ]
    //         })}
    //     </div>
    // )
})

export const CoursesDetailPage = Page;
