import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { PlaneSea32, ArrowsVertical16, Download16, Upload16, ChartRelationship32, ListBoxes } from '@carbon/icons-react'
import { ComposedModal, Loading } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { useStringValueFilter } from "../../engine/useValueFilter";
import UIUtil from "../../../util/UIUtil";
import { useEffect } from "react";
import { printShipmentDoc } from "../../../pages/shipping/shipment/item-page/pdf";
import { createInvoice } from "../../../pages/shipping/shipment/item-page/pdf/invoice";

export const ShipmentList = {
    filter: Filter,
    icon: PlaneSea32,

    customPath: "/shipment-job",
    newDialog: NewDialog,

    itemToOpenLink: item => `/shipment-job/${item.id}`,
    isListMode: true,
    openInSameTab: true,

    onAction: async (action, data, history) => {
        switch (action) {
            case "invoice":
                try {
                    const { item: shipment } = await Api.asyncGetShipment(data.id);
                    await printShipmentDoc(shipment, createInvoice(shipment), {
                        title: shipment.status === "Done" ? "TAX INVOICE" : "PROFORMA INVOICE"
                    });
                } finally {
                    // onClose()
                }
                return;
        }
    },

    isAvailable: () => {
        const role = getAccountRole();
        return hasCapabilitySupport("shipping") && (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR);
    }
}

function NewDialog({ visible, onClose, onSuccess, endpoint }) {
    return (
        <FormDialog
            setItem={item => onSuccess(item)} label="Shipment" title="Create New"
            saveApi={(data, listener) => Api.createShipment(data, listener)}


            open={visible} onClose={onClose} item={{
                dirType: 1
            }} payload={endpoint}>
            {({ ComboBoxField, SwitchField, IfTargetGroup }) => (<>
                <SwitchField title="Direction" fieldKey="dirType" options={[{ id: 0, title: "Import" }, { id: 1, title: "Export" }]} />

                <IfTargetGroup targetKey="dirType" checkTargetIf={value => value === 1}>
                    {/* <SwitchField fieldKey="loadingMode" title="Loading Mode" options={[{ id: 0, title: "LCL" }, { id: 1, title: "FCL" }]} /> */}
                    <ComboBoxField fieldKey="loadingModeId" title="Loading Mode" options={endpoint.loadingModes} />
                </IfTargetGroup>

            </>)}
        </FormDialog>
    )
}

const DirTypeFilter = ({ state }) => {
    const [value, setValue] = useStringValueFilter(state, "type", { property: "type", operator: "EQ" })
    return (
        <div style={{ paddingInline: '0.5rem' }}>
            <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                <button data-active={!value} onClick={() => setValue(null)} className='report-engine-menu-button-light'><ArrowsVertical16 /></button>
                <button data-active={value === "Import"} onClick={() => setValue("Import")} className='report-engine-menu-button-light'><Download16 /></button>
                <button data-active={value === "Export"} onClick={() => setValue("Export")} className='report-engine-menu-button-light'><Upload16 /></button>
            </div>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="dateCreated" />

        <div className="list-mode-divider-quick-filter-bar" />

        <DirTypeFilter state={state} />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="shipmentListStatusField" options={["Created", "Inspecting", "Customs", "Loading", "Finalized"]} state={state} property="status" />
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <TextBox state={state} label="Shipment No" property="shipmentNo" fullWidth />
        </div>
    </>)
}
