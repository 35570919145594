
import React from 'react';
import { Page, Text, View, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';

import { Receipt16, } from '@carbon/icons-react'
import Invoice from './pdf/Invoice';
import Util from '../../util/Util';

const PDF = ({stockFlow}) => (
    <Invoice invoice={{
        destinationName: stockFlow.destinationName,
        sourceName: stockFlow.sourceName,

        voucherNo: Util.getVoucherNumber(stockFlow.id),
        invoice_no: stockFlow.stockFlowRecordId,
        trans_date: Util.getDateOnly(stockFlow.initiationDate),
        due_date: Util.getDateOnly(stockFlow.initiationDate),
        stockFlow: stockFlow,
        items: stockFlow.items.map(item => ({
            sno: item.id,
            desc: item.itemName,
            qty: item.quantityValue,
            rate: item.price,
        })),
      }} />
);


export const printStockTransfer = async (stockFlow) => {
    const blob = await pdf(<PDF stockFlow={stockFlow} />).toBlob();
    print(URL.createObjectURL(blob));
}

export const StockTransferDownload = ({stockFlow}) => {
    const [instance, updateInstance] = usePDF({ document: <PDF stockFlow={stockFlow} /> });

    return (
        <a href={instance.url} target="_blank" style={{width: '100%', textDecoration: 'none'}}>
            <Button style={{width: '100%'}} loading={instance.loading} disabled={instance.error} renderIcon={Receipt16}>Stock Transfer Voucher</Button>
        </a>
    )
}