import { ShoppingCart20, FlowData20, Printer20, AddAlt20, SubtractAlt20 } from '@carbon/icons-react'
import React, { useEffect } from 'react'
import { PrintSearchDialog } from '../dialogs/print-search-dialog';
import { useState } from 'react';
import { ChangeCashDialog } from '../dialogs/change-cash-dialog';
import Portal from '../../../util/Portal';
import { hasCapabilitySupport } from '../../../app/Capabilities';

const Button = ({ onClick, icon, title, loading }) => (
    <div onClick={onClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '0.5rem', pointerEvents: loading ? 'none' : undefined, opacity: loading ? 0.65 : 1 }}>
        <div className="manage-pos-dialog-button">
            {React.createElement(icon)}
        </div>
        <h6 style={{ textAlign: 'center', fontSize: 12 }}>{title}</h6>
    </div>
)

const useDialogVisible = (setHidden) => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setHidden?.(visible)
    }, [visible])
    return [visible, setVisible];
}


const SaleHistoryBtn = ({ state, setHidden }) => {
    const [visible, setVisible] = useDialogVisible(setHidden)
    const onBtnClick = () => {
        setVisible(true);
    }
    const onPrintClick = ({ print }) => {
        state.mainApp.thermalPrinter.printReceipt(JSON.parse(print))
    }
    return (<>
        <Button onClick={onBtnClick} icon={ShoppingCart20} title="Sales History" />
        <PrintSearchDialog visible={visible} onClose={() => setVisible(false)} onPrintSelected={onPrintClick} />
    </>)
}

const PrintSalesSummaryBtn = ({ state }) => {
    const [loading, setLoading] = useState(false);
    const onPrintBtn = () => {
        setLoading(true)
        state.printSalesSummary(() => setLoading(false))
    }
    return (
        <Button loading={loading} onClick={onPrintBtn} icon={Printer20} title="Summary" />
    )
}


const AddCashBtn = ({ state, setHidden }) => {
    const [visible, setVisible] = useDialogVisible(setHidden)
    const onBtnClick = () => {
        setVisible(true);
    }
    return (<>
        <Button onClick={onBtnClick} icon={AddAlt20} title="Add Cash" />
        <Portal>
            <ChangeCashDialog key={"add-cash-dialog"} state={state} direction={"IN"} open={visible} onClose={() => setVisible(false)} />
        </Portal>
    </>)
}


const TakeCashBtn = ({ state, setHidden }) => {
    const [visible, setVisible] = useDialogVisible(setHidden)
    const onBtnClick = () => {
        setVisible(true);
    }
    return (<>
        <Button onClick={onBtnClick} icon={SubtractAlt20} title="Take Cash" />
        <Portal>
            <ChangeCashDialog key={"take-cash-dialog"} state={state} direction={"OUT"} open={visible} onClose={() => setVisible(false)} />
        </Portal>
    </>)
}


export function ActionBar({ state, setHidden }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: '1rem', gap: '1rem' }}>
            {hasCapabilitySupport("restaurant") || hasCapabilitySupport("extendedPosCheckout") && <>
                <AddCashBtn state={state} setHidden={setHidden} />
                <TakeCashBtn state={state} setHidden={setHidden} />
            </>}

            {/* <div /> */}
            <SaleHistoryBtn state={state} setHidden={setHidden} />
            {/* <Button icon={FlowData20} title="Cash Flow" /> */}

            {/* <div /> */}
            <PrintSalesSummaryBtn state={state} />
        </div>
    )
}