import React from 'react'
import Page from '../../base/Page';
import Button from '../../components/Button';

import { ArrowLeft32 } from '@carbon/icons-react'
import { withRouter } from 'react-router-dom';
import StockFlowCreatorPage from '../stock-flow/StockFlowCreatorPage';

class CashierSalesReturnPage extends Page {

    getLayout() {
        return (
            <div style={{width: '100%'}}>
                <div className="theme-bg" style={{width: '100%', height: 48, display: 'flex', alignItems: 'center', position: 'sticky', top: 48, zIndex: 10}}>
                    <Button onClick={() => this.props.history.goBack()} hasIconOnly renderIcon={ArrowLeft32} style={{width: 48, height: 48}} />
                    <h4 style={{margin: 0, marginLeft: '1rem', color: 'white'}}>Sales Return</h4>
                </div>

                <StockFlowCreatorPage componentMode posSalesReturnMode />
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(CashierSalesReturnPage);