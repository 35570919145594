
import React from 'react';
import { Page, Text, View, Document, StyleSheet, pdf, usePDF, PDFViewer } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';

import { Receipt16, DocumentPdf16 } from '@carbon/icons-react'
import Invoice from './pdf/Invoice';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';

const PDF = ({ posSessionSnapshot }) => (
    <Invoice invoice={{
        id: "5df3180a09ea16dc4b95f910",
        invoice_no: posSessionSnapshot.lastStockFlowRecordId,
        balance: "$2,283.74",
        company: "MANTRIX",
        email: "susanafuentes@mantrix.com",
        phone: "+1 (872) 588-3809",
        address: "Address",
        trans_date: Util.getDateOnly(new Date().getTime()),
        due_date: Util.getDateOnly(new Date().getTime()),
        posSession: posSessionSnapshot,
        items: posSessionSnapshot.currentCartItems.map(item => ({
            sno: item.id,
            desc: item.name,
            qty: item.quantityValue,
            rate: item.amount.price,
        })),
    }} />
);


export const printPosReceipt = async (posSessionSnapshot) => {
    const blob = await pdf(<PDF posSessionSnapshot={posSessionSnapshot} />).toBlob();
    print(URL.createObjectURL(blob));
}

export const PosReceiptDownload = ({ posSessionSnapshot }) => {
    const [instance, updateInstance] = usePDF({ document: <PDF posSessionSnapshot={posSessionSnapshot} /> });

    return (
        <a href={instance.url} target="_blank" style={{ width: '100%', textDecoration: 'none' }}>
            <Button style={{ width: '100%' }} loading={instance.loading} disabled={instance.error} renderIcon={Receipt16}>Get Receipt</Button>
        </a>
    )
}

export const FailedTaskPosReceiptDownload = ({ posSessionSnapshot }) => {
    return (
        <Button className="bx--tooltip--hidden" onClick={() => {
            // UIUtil.showPDF("Sales Receipt", <PDF posSessionSnapshot={posSessionSnapshot} />);
            UIUtil.showPDF("Sales Invoice", <PDF posSessionSnapshot={posSessionSnapshot} />);
            // UIUtil.showOverlay(
            //     <PDFViewer>
            //         <PDF posSessionSnapshot={posSessionSnapshot} />
            //     </PDFViewer>
            // )
        }} kind={"secondary"} renderIcon={DocumentPdf16} hasIconOnly />
    )

    // const [instance, updateInstance] = usePDF({ document: <PDF posSessionSnapshot={posSessionSnapshot} /> });

    // return (
    //     <a href={instance.url} target="_blank" style={{textDecoration: 'none'}}>
    //         <Button className="bx--tooltip--hidden" loading={instance.loading} disabled={instance.error} kind={"secondary"} renderIcon={DocumentPdf16} hasIconOnly />
    //     </a>
    // )
}