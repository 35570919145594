import { Accordion, AccordionItem } from "carbon-components-react";
import { useEffect, useState } from "react";
import { StudentPresenceView, StudentPresenceViewForTutor } from ".";
import ProfilePic from "../../../../components/ProfilePic";
import { OBJECT_TYPE_STUDENT } from "../../../../constants/ObjectTypes";
import Api from "../../../../session/Api";
import { ActualStudentAttendance } from "./actual-student-attendance";
import { EntranceScanStatus } from "./entrance-scan-status";

//new Date().getTime() > item.endDate

export const TutorPresenceController = ({ activityId, presence, setPresences }) => {
    return (<>
        <StudentPresenceViewForTutor activityId={activityId} presence={presence.studentPresence} readonly />
        <EntranceScanStatus attendanceRecord={presence.attendanceRecord} />
        <ActualStudentAttendance studentPresence={presence.studentPresence} attendanceRecord={presence.attendanceRecord} activityId={activityId} studentId={presence.studentId}
            //classDate={item.startDate} 
            classDate={presence.classDate}
            presenceConfirmation={presence.studentPresenceConfirmation} setPresences={setPresences} />
    </>)
}

export function ActivityPresenceReport({ activityId, item }) {
    const [presences, setPresences] = useState(undefined);

    useEffect(() => {
        Api.getAllStudentPresences(activityId, response => {
            if (response.status === true) {
                setPresences(response.payload);
            }
        })
    }, [activityId])
    if (presences === undefined) {
        return null;
    }

    return (
        <div>
            <div style={{ padding: '1rem' }}>
                <p style={{ fontWeight: 'bold', color: 'black', }}>Presence Confirmation</p>
            </div>
            {presences.length > 0 ? (
                <Accordion className={`center-accordion-header no-padding-accordion`} style={{ marginBottom: '1rem' }}>
                    {presences.map((presence) =>
                        <AccordionItem key={presence.studentId} title={<div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                            <ProfilePic size={20} src={Api.getThumbnail(OBJECT_TYPE_STUDENT, presence.studentId)} />
                            {presence.studentName}
                        </div>}>
                            <TutorPresenceController activityId={activityId} presence={presence} setPresences={setPresences} />
                        </AccordionItem>)}
                </Accordion>
            ) : (
                <div style={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No attendance</p>
                </div>
            )}
        </div>
    )
}