import { ClickableTile } from 'carbon-components-react';
import React, { useState } from 'react'

import {
    Pin16, PinFilled16,
} from '@carbon/icons-react'
import useHover from '../../hooks/useHover';
import Util from '../../util/Util';
import { Link } from 'react-router-dom';
import GridListTransition from '../grid-list-transition/GridListTransition';
import GridListTransitionBtn from '../grid-list-transition/GridListTransitionBtn';

import { useBooleanLocalStorage } from '../../hooks/useLocalStorage'
import UIUtil from '../../util/UIUtil';

const PinButton = ({ pinned, onPinStatusUpdate, grid }) => {
    const isPinned = pinned;
    const onClick = e => {
        e.stopPropagation();
        e.preventDefault();
        onPinStatusUpdate(!isPinned)
    }
    return (
        <div onClick={onClick} className={`pin-button ${isPinned ? 'pin-button-pinned' : ''}`} style={{
            marginRight: 5,
            ...(grid ? {
                position: 'absolute',
                top: '0.50rem',
                right: '0.25rem',
            } : {}),
        }}>
            {/* {isPinned + '-'} */}
            {isPinned ? <PinFilled16 /> : <Pin16 />}
        </div>
    )
}


const MenuItem = ({ icon, title, link, desc, grid, opacity, color, pinned, onPinStatusUpdate, miniMode }) => {
    const hover = useHover();

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
            <ClickableTile className={`menu-btn ${color} ${grid ? 'menu-btn-grid' : ''}`} {...hover.init()}
            // style={{overflow: 'hidden', width: '100%', height: '100%', display: 'flex', alignItems: 'center', //opacity: opacity
            // // ...(color ? {
            // //     color: color
            // // } : {})
            // }}
            >
                {React.createElement(icon, {
                    style: {
                        width: grid ? 22 : 18,
                        height: grid ? 22 : 18,
                    }
                })}
                <h4 style={{ marginLeft: '1rem', fontSize: 16, flex: 1 }}>{title}</h4>

                {(!miniMode && (hover.isHover || pinned)) && <PinButton pinned={pinned} onPinStatusUpdate={onPinStatusUpdate} grid={grid} />}
            </ClickableTile>
        </div>
    )
}

export default ({ title, subMenu, index, menus, color, onClose, isPinned, onPinStatusUpdate, miniMode }) => {
    //const [grid, setGrid] = useState(false)
    const [grid, setGrid] = useBooleanLocalStorage(title + "--menu-grid-view")

    if ((menus?.length ?? 0) === 0) {
        return null;
    }

    return (<>
        {Util.isStringExists(subMenu.title) ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: index > 0 ? '0.25rem' : undefined, marginBottom: index > 0 ? '0.25rem' : undefined }}>
                <p style={{ fontSize: 12, opacity: 0.65, paddingLeft: '1rem', marginBottom: '0rem', flex: 1, }}>{subMenu.title}</p>
                <GridListTransitionBtn grid={grid} onClick={() => setGrid(prev => !prev)} />
            </div>
        ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', width: '100%', }}>
                <GridListTransitionBtn grid={grid} onClick={() => setGrid(prev => !prev)} />
            </div>
        )}
        {/* {Util.isStringExists(subMenu.title) ?
        <p style={{fontSize: 12, opacity: 0.65, paddingLeft: '1rem', marginBottom: '0rem', marginTop: index > 0 ? '1rem' : undefined}}>{subMenu.title}</p> : 
        <div style={{marginBottom: '0rem'}} />} */}




        <GridListTransition mode={grid ? 1 : 0}>
            {menus.map(menu => (
                <Link to={menu.link} //onClick={menu.link !== undefined && (() => onClose())} 
                    onClick={menu.link !== undefined ? (() => onClose()) : (() => {
                        UIUtil.showInfo("Permission not given")
                        onClose();
                    })}
                    style={{ textDecoration: 'none' }}>
                    <MenuItem
                        link={menu.link}
                        grid={grid} miniMode={miniMode}
                        pinned={isPinned(menu)}
                        onPinStatusUpdate={pinned => onPinStatusUpdate(pinned, menu)}
                        color={menu.color ? menu.color : color}
                        key={menu.title} icon={menu.icon} title={menu.title} desc={menu.desc} opacity={Util.isStringExists(menu.link) ? 1 : 0.4} />
                </Link>
            ))}
        </GridListTransition>
        {/* {menus.map(menu => (
            <Link to={menu.link} onClick={menu.link !== undefined && (() => onClose())} style={{textDecoration: 'none'}}>
                <MenuItem
                link={menu.link}
                //onPinStatusUpdate={(pinned) => this.onPinStatusUpdate(pinned, menu)}
                color={color} 
                key={menu.title} icon={menu.icon} title={menu.title} desc={menu.desc} opacity={Util.isStringExists(menu.link) ? 1 : 0.4} />
            </Link>
        ))} */}
    </>)
}