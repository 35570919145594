
import {
    Analytics16,
    ShoppingCart32,
    ShoppingBag32,
    Wallet32,
    TagGroup32,
    ErrorFilled32
} from '@carbon/icons-react'
import { Widget } from '.';
import Util from '../../util/Util';
import { isMobileScreen } from '../../App';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
const Card = ({ title, value, Icon, color }) => {
    const lastValue = useRef(value);
    const [valueKey, setValueKey] = useState(Util.newTempId());
    const [valueHighlight, setValueHighlight] = useState(undefined);

    useEffect(() => {
        if (lastValue.current !== undefined && lastValue.current != value) {
            setValueKey(Util.newTempId())
            setValueHighlight('quantity-increase-anim');
        }
        lastValue.current = value;
    }, [value])

    //const color = "#009900";
    // const color = "#009900";
    return (
        <div className="rb-card" style={{ width: '100%', overflow: 'hidden', paddingLeft: '1rem', paddingTop: '1rem', border: `1px solid ${color}60` }}>
            <div className={"rb-card-color"} style={{ background: color }} />
            <div className={"rb-card-content"} style={{ background: color + "15" }}>
                <div className={"rb-card-icon"} style={{ width: 64 }}>
                    <Icon style={{ width: 48, height: 48, color: 'white' }} />
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <h4 style={{ fontSize: 25, color, marginBlock: '0.25rem' }}>{title}</h4>

                        <p key={valueKey} style={{ animation: valueHighlight ? valueHighlight + ' 1000ms' : '', fontSize: 27, fontWeight: 'bold' }}>{value}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Analytics({ data }) {
    const analytics = data.analytics;
    return (
        <Widget icon={Analytics16} title={"Analytics"} style={{ width: isMobileScreen ? '100%' : 300, flex: 1 }}>
            <div style={{
                display: 'flex', gap: '1rem', justifyContent: 'center', alignItems: 'center', width: '100%',
                flexDirection: 'column'
            }}>
                <Card color={"#009900"} Icon={ShoppingCart32} title={analytics.saleCount + " Sales"} value={'AED ' + Util.formatMoney(analytics.saleAmount)} />
                <Card color={"#005999"} Icon={ShoppingBag32} title={analytics.orderCount + " Orders"} value={'AED ' + Util.formatMoney(analytics.orderAmount)} />
                <Card color={"#994a00"} Icon={Wallet32} title={analytics.unpaidCount + " Open Bills"} value={'AED ' + Util.formatMoney(analytics.unpaidAmount)} />
                <Card color={"#690099"} Icon={TagGroup32} title={analytics.discountCount + " Discounts"} value={'AED ' + Util.formatMoney(analytics.discountAmount)} />
                <Card color={"#990000"} Icon={ErrorFilled32} title={analytics.revokedCount + " Revoked"} value={'AED ' + Util.formatMoney(analytics.revokedAmount)} />
                {/* <Card title={"KOT Avg Time"} />
                <Card title={"Delivery Avg Time"} />
                <Card title={"Table Turnover Rate"} />
                <Card title={"Order Revoke Rate"} /> */}
            </div>
        </Widget>
    )
}