import { Checkbox, InlineLoading, Tag } from "carbon-components-react";
import { Header } from "./my-students";
import Api from "../../../session/Api";
import { useEffect, useMemo, useRef, useState } from "react";
import UIUtil from "../../../util/UIUtil";
import { MinimalTable } from "../../../components/minimal-table";
import Util from "../../../util/Util";
import { STUDENT_PRESENCE } from "../../../constants/Constants";
import { ButtonPopover } from "../../../components/button-popover";
import {
    OverflowMenuVertical16
} from '@carbon/icons-react'
import { TutorPresenceController } from "../activity-ext/student-presence/activity-presence-report";
import { isMobileScreen } from "../../../App";

const Action = ({ item, onClose }) => {
    const ref = useRef();
    return (
        <div ref={ref} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonPopover parentRef={ref} customPos={['left', 'bottom',]} popover={
                <div style={{ padding: '1rem' }}>
                    <TutorPresenceController activityId={item.id} presence={item.attendanceReportStudentPresenceForTutor} setPresences={presences => {
                        UIUtil.showSuccess();
                        onClose();
                    }} />
                </div>
            }>
                <button style={{ width: 30, height: 30 }} className='lead-flow-icon-button lead-flow-icon-button-primary' data-tip="New Item" data-effect="solid" data-place="bottom">
                    <OverflowMenuVertical16 />
                </button>
            </ButtonPopover>
        </div>
    )
}


export function StudentAttendanceReportForTutor({ courseId, studentId, onClose }) {
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        Api.getUpcomingStudentAttendanceReport(courseId, studentId, response => {
            if (response.status === true) {
                setReport(response.payload);
            } else {
                UIUtil.showError(response.message);
                onClose();
            }
            setLoading(false);
        })
    }, [courseId, studentId])

    const totals = useMemo(() => {
        let totalAttending = 0;
        let totalAbsent = 0;
        for (const item of report) {
            if (item?.attendanceReportStudentPresenceForTutor?.studentPresence?.state === STUDENT_PRESENCE.PRESENT) {
                totalAttending++;
            } else if (item?.attendanceReportStudentPresenceForTutor?.studentPresence?.state === STUDENT_PRESENCE.ABSENT) {
                totalAbsent++;
            }
        }
        return { totalAttending, totalAbsent }
    }, [report])

    if (loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    return (
        <div style={{ overflow: 'auto', padding: '1rem', }}>
            <div style={{ width: !isMobileScreen ? '100%' : '300%', position: 'relative' }}>
                <MinimalTable large columns={[
                    {
                        name: "day",
                        title: "Day",
                        flex: 2,
                        renderCell: item => item.id ? Util.getDay(item.startDate) : ''
                    },
                    {
                        name: "date",
                        title: "Date",
                        flex: 2,
                        renderCell: item => item.id ? Util.getDateOnly(item.startDate) : ''
                    },
                    {
                        name: "time",
                        title: "Time",
                        flex: 2,
                        renderCell: item => item.id ? Util.getTime(item.startDate) : <strong>Total</strong>
                    },
                    {
                        name: "attending",
                        title: "Attending",
                        flex: 2,
                        renderCell: item => !item.id ? totals.totalAttending : <div style={{ pointerEvents: 'none' }}><Checkbox checked={item?.attendanceReportStudentPresenceForTutor?.studentPresence?.state === STUDENT_PRESENCE.PRESENT} /></div>
                    },
                    {
                        name: "absent",
                        title: "Absent",
                        flex: 2,
                        renderCell: item => !item.id ? totals.totalAbsent : <div style={{ pointerEvents: 'none' }}><Checkbox checked={item?.attendanceReportStudentPresenceForTutor?.studentPresence?.state === STUDENT_PRESENCE.ABSENT} /></div>
                    },
                    {
                        name: "status",
                        title: "Attendance Status",
                        flex: 2,
                        renderCell: item => item.id ? item?.attendanceReportStudentPresenceForTutor?.studentPresence ? "Confirmed" : <span style={{ fontSize: 12, opacity: 0.65 }}>not confirmed by student</span> : ''
                    },
                    {
                        name: "action",
                        title: "",
                        flex: 1,
                        renderCell: item => item.id ? <Action item={item} onClose={onClose} /> : ''
                    }
                ]} items={[
                    ...report, {}
                ]} />

            </div>
            <div style={{ height: '18rem' }} />
        </div>
    )
}