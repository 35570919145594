import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SUPERVISOR, ACCOUNT_TYPE_TUTOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { NotificationFilled32, ArrowsVertical16, Download16, Upload16, ChartRelationship32, ListBoxes } from '@carbon/icons-react'
import { ComposedModal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { useStringValueFilter } from "../../engine/useValueFilter";

export const MyLmsNotificationList = {
    filter: Filter,
    icon: NotificationFilled32,

    newLink: "/lms-notifications-publish",

    itemToOpenLink: item => `/lms-notifications/${item.id}`,
    isListMode: true,

    isAvailable: () => {
        const role = getAccountRole();
        return hasCapabilitySupport("lms") && (role == ACCOUNT_TYPE_TUTOR);
    }
}



function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="date" />
        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ width: 75, overflow: 'hidden' }}>
            <TextBox state={state} placeholder="ID" property="id" fullWidth />
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Message" property="message" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Email" property="toEmails" fullWidth />
        </div>

    </>)
}
