import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SHIPMENT_DIR_TYPE } from "../.."
import Api from "../../../../../session/Api"
import Util from "../../../../../util/Util"
import { UserAssignation } from "../../../../activities/components/user-assignation"
import Button from "../../../../../components/Button"
import { Close16, ArrowRight16, Activity16, Locked16 } from '@carbon/icons-react'
import UIUtil from "../../../../../util/UIUtil"
import useApi from "../../../../../hooks/useApi"
import { getAccount } from "../../../../../session/SessionManager"
import { Tag } from "carbon-components-react"

const ValueEntry = ({ label, value }) => (<>
    <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: 1, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: 2, outline: 'none', textAlign: 'end' }}>{value}</p>
    </div>
</>)

const Assignations = ({ value, setValue, tags, allUsers }) => (
    <UserAssignation
        title="Assignations" value={value ?? []} onValueChanged={setValue}
        tags={tags} allUsers={allUsers}
    />
)

function LockStatus({ shipment, globalState }) {
    const [lockStatus, setLockStatus] = globalState.use("lockStatus", {
        locked: shipment.locked,
        date: shipment.lockedDate,
        name: shipment.lockedByFullName
    })
    const [doingLock, doLock] = useApi(listener => Api.setShipmentLocked(shipment.id, true, listener), () => setLockStatus({
        locked: true, date: new Date().getTime(), name: getAccount().fullName
    }))
    const [clearingLock, clearLock] = useApi(listener => Api.setShipmentLocked(shipment.id, false, listener), () => setLockStatus({
        locked: false
    }))

    return (<>
        <ValueEntry label="Invoice State" value={
            lockStatus.locked
                ? <Tag type="high-contrast" renderIcon={Locked16}>Locked</Tag>
                : <Tag type="magenta" renderIcon={Activity16}>Live</Tag>
        } />

        <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            {lockStatus.locked ? (
                <p style={{ fontSize: 12, opacity: 0.65, marginBottom: '0.25rem' }}>Invoice locked by {lockStatus.name} on {Util.getDate(lockStatus.date)}</p>
            ) : (
                null
            )}

            {(lockStatus.locked) ? (
                <Button onClick={() => UIUtil.confirm(clearLock)} loading={clearingLock} kind="danger-ghost" renderIcon={Close16} size="sm" style={{ borderRadius: 25 }}>Clear lock</Button>
            ) : (
                <Button onClick={() => UIUtil.confirm(doLock)} loading={doingLock} renderIcon={Locked16} size="sm" style={{ borderRadius: 25 }}>Lock invoice</Button>
            )}
        </div>
    </>)
}

export function Detail({ shipment, form, globalState }) {
    const [{ users, userTags }, setActivityUsers] = useState({ users: [], userTags: [] })
    useEffect(() => {
        let cancelled = false;
        Api.getActivityUsers(response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                // console.log(response.payload)
                setActivityUsers(response.payload)
            }
        })
        return () => cancelled = true;
    }, [])

    return (
        <div style={{ width: '100%' }}>

            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Details</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <ValueEntry label="Shipment No" value={shipment.shipmentNo} />
                    <ValueEntry label="Type" value={shipment.dirType === SHIPMENT_DIR_TYPE.IMPORT ? 'Import' : 'Export'} />
                    <ValueEntry label="Created By" value={<Link to={'/staff-users/' + shipment.userId}>{shipment.createdByFullName}</Link>} />
                    <ValueEntry label="Created On" value={Util.getDate(shipment.createDate)} />
                    <ValueEntry label="Last Update On" value={Util.getDate(shipment.updateDate)} />
                    <LockStatus shipment={shipment} globalState={globalState} />
                </div>
            </div>

            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Bills Against</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    {shipment.billsAgainst.length === 0 && <div>
                        <div style={{ minWidth: 300, height: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>
                                No bills against
                            </p>
                        </div>
                    </div>}

                    {shipment.billsAgainst.map(bill => <ValueEntry key={bill.billId}
                        label={<Link to={'/bill/' + bill.billId}>{'Bill ' + Util.getVoucherNumber(bill.billId)}</Link>}
                        value={'AED ' + Util.formatMoney(bill.against.againstAmount)} />)}
                </div>
            </div>


            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Assignations</h4>
            <div style={{ paddingInline: '1rem', marginBottom: '3rem', width: '100%' }}>
                <form.CustomField fieldKey="assignations" childrenProps={{ tags: userTags, allUsers: users }}>
                    {Assignations}
                </form.CustomField>
            </div>

            <div style={{ height: 300 }} />
        </div>
    )
}