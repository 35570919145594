import Button from "../../../components/Button";
import { Filter16, Filter20 } from '@carbon/icons-react'
import useHover from "../../../hooks/useHover";
import { useStringValueFilter } from "../../engine/useValueFilter";
import { useMemo } from "react";
import { getReport } from "../../reports/reports";
import Util from "../../../util/Util";

export function PayloadBar({ reportName, showDarkMode, payload }) {
    const report = useMemo(() => getReport(reportName), [reportName])
    if (report.hasPayloadBar) {
        return (
            <div
                className="report-engine-quick-filter-bar" style={{
                    width: '100%', paddingInline: '3rem', gap: '0.25rem',
                    display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingBlock: '0.5rem',
                    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) inset, 0px 4px 6px -2px rgba(0,0,0,0.05) inset',

                    background: showDarkMode ? '#1c1c1c' : '#f1f1f1', color: showDarkMode ? 'white' : 'black',
                    //borderTop: showDarkMode ? '1px solid #ffffff10' : undefined,
                    borderBottom: showDarkMode ? '1px solid #FFFFFF10' : '1px solid #00000020'
                }}
            >
                <report.payload payload={payload} />
            </div>
        );
    } else {
        return null;
    }
}