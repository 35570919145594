import { useEffect, useMemo, useState } from "react";
import CustomComboBox from "../../../components/CustomComboBox";
import { useNumberValueFilter, useStringValueFilter } from "../../engine/useValueFilter";


export function LocationFilter({ accountTree, state }) {
    const [selectedAccount, setSelectedAccount] = state.use("productMergeLocationId", undefined)
    const [value, setValue] = useNumberValueFilter(state, "productMergeLocationId", { property: "productMergeLocationId", operator: "EQ", skipAutoFilter: true })

    useEffect(() => {
        setValue(selectedAccount?.id ?? 0)
    }, [selectedAccount])

    return (
        <CustomComboBox rounded dark placeholder="Select Location" items={accountTree} selectedItem={selectedAccount} onSelectedItemUpdate={item => setSelectedAccount(item)} />
    )
}
