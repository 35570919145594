import React, { useState } from 'react'

import { 
    ArrowLeft16,
    OverflowMenuVertical16,
    ShoppingCart16,
    ShoppingCart24,
    Minimize16
} from '@carbon/icons-react'
import Button from '../../../components/Button'
import CartList from '../components/CartList'

const View = ({mainSessionStore}) => {
    return (
        <CartList sessionStore={mainSessionStore} hasActions />
    )
}

export default ({mainSessionStore}) => {
    const [collapsed, setCollapsed] = useState(false)

    if (collapsed) {
        return (
            <div onClick={() => setCollapsed(false)} className="sales-cart-collapsed-panel" style={{width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <div style={{display: 'flex', minWidth: 200, alignItems: 'center', justifyContent: 'center', transform: 'rotate(270deg)',}}>
                    <ShoppingCart16 style={{marginRight: '0.25rem', marginLeft: '0.5rem'}} />
                    <p style={{fontSize: 14, opacity: 0.65}}>Cart Items</p>
                </div>
            </div>
        )
    }

    return (
        <div className="dashboard-card" style={{padding: '0rem', flex: 1}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <ShoppingCart24 style={{marginRight: '0.25rem', marginLeft: '0.5rem'}} />
                <h4 style={{flex: 1}}>Cart Items</h4>

                <Button kind="ghost" tooltipPosition="bottom" iconDescription="Collapse" hasIconOnly renderIcon={Minimize16} onClick={() => setCollapsed(true)} />
            </div>
            <View mainSessionStore={mainSessionStore} />
        </div>
    )
}