import { useEffect, useState } from "react"
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import { useListFilter } from "../../engine/useValueFilter";

const createStyle = style => (baseStyles) => ({
    ...baseStyles,
    ...style
})

const DEF_VALUE = []

export function MultiSelector({ fieldKey, placeholder, state, property, options }) {
    const [filters, setFilters] = useListFilter(state, property)
    const [value, setValue] = state.use(fieldKey, DEF_VALUE)

    useEffect(() => {
        setFilters(value.map(item => ({ property, operator: "CONTAINS", value: item.label })))
    }, [value, property])

    return (
        <Select
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: '100%',
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 5,
                    background: '#ffffff10',
                    border: '1px solid #00000099',
                    color: 'white',
                    minHeight: 30,
                    height: '100%',
                    padding: 0
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white',
                    height: '100%',
                    margin: 0
                }),
                multiValue: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: 'white'
                }),
                multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: '#ffffffBB'
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                menu: createStyle({
                    background: '#1c1c1c'
                }),
                option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
                    ...baseStyles,
                    backgroundColor: isFocused ? '#ffffff20' : isSelected ? '#ffffff40 !important' : 'transparent'
                })
            }}
            isMulti options={options.map(opt => ({ value: opt.id, label: opt.value }))} />
    )
}


export function MultiSelectorListMode({ fieldKey, placeholder, state, property, options }) {
    const [filters, setFilters] = useListFilter(state, property)
    const [value, setValue] = state.use(fieldKey, DEF_VALUE)

    useEffect(() => {
        setFilters(value.map(item => ({ property, operator: "CONTAINS", value: item.label })))
    }, [value, property])

    return (
        <Select
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: '100%',
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 5,
                    background: '#ffffff10',
                    border: '0px solid #00000099',
                    outline: 'none',
                    color: 'black',
                    minHeight: 30,
                    height: '100%',
                    padding: 0
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'black',
                    height: '100%',
                    margin: 0
                }),
                // multiValue: (baseStyles) => ({
                //     ...baseStyles,
                //     background: '#000000',
                //     color: 'black'
                // }),
                // multiValueLabel: (baseStyles) => ({
                //     ...baseStyles,
                //     background: '#000000',
                //     color: '#ffffffBB'
                // }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                // menu: createStyle({
                //     background: '#ffffff',
                //     color: 'black'
                // }),
                // option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
                //     ...baseStyles,
                //     backgroundColor: isFocused ? '#ffffff20' : isSelected ? '#ffffff40 !important' : 'transparent'
                // })
            }}
            isMulti options={options.map(opt => ({ value: opt.id, label: opt.value }))} />
    )
}