import { ComboBox, Tag, TextInput } from "carbon-components-react"
import { ProductListSelector, StockReqItemOpts } from "../../../components/list-selector"
import { TableInput } from "../../../components/table-input"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24, Checkmark16 } from '@carbon/icons-react'
import Util from "../../../util/Util"
import useStore from "../../../hooks/useStore"
import Button from "../../../components/Button"
import { useState } from "react"
import UIUtil from "../../../util/UIUtil"
import Api from "../../../session/Api"
import { InvRequestPage } from "../../../pages/inv-requests/inv-request-page"
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter"
import { OBJECT_INV_REQUEST } from "../../../constants/ObjectTypes"
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view"
/*
<ComboBox
                        size="md"
                        style={{ background: 'black', color: 'white', width: 150 }}
                        titleText="UOM"
                        value={!selectedUOM ? getDefaultUOM().symbol : selectedUOM.symbol}
                        onChange={item => {
                            setSelectedUOM(item.selectedItem)
                            onStockValueChanged('_selected_uom', item.selectedItem)
                        }}
                        itemToString={item => item ? item.symbol : ""}
                        items={measurementType.unitOfMeasurements}
                    />
*/

const PRODUCT_COLS = [
    {
        key: "item", title: "Item", flex: 3, Component: ({ value, setMultiProp }) => {
            const setItem = item => {
                setMultiProp({
                    item,
                    itemId: item?.itemId ?? 0,
                    itemType: item?.itemType,
                    uomId: 0,
                })
            }
            return (
                <div style={{ height: 40, width: '100%' }}>
                    <StockReqItemOpts value={value} setValue={setItem} />
                </div>
            )
        }
    },
    {
        key: "requestedQty", title: "Requesting Qty", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "uomId", title: "UOM", flex: 1, Component: ({ useEntry, value, setValue }) => {
            const [cKey, setCKey] = useState(() => Util.newTempId());
            const [item] = useEntry('item');
            const measurementType = item?.measurementType ?? {};

            const uom = measurementType.unitOfMeasurements?.find($ => $.id === value) || {
                symbol: measurementType.defaultUomSymbol || "x", name: measurementType.defaultUomName || "x"
            };

            if (!item) {
                return null;
            }

            return (
                <div style={{ width: '100%' }}>
                    <ComboBox
                        key={cKey}
                        placeholder="UOM"
                        selectedItem={uom}
                        onChange={item => {
                            setValue(item?.selectedItem?.id ?? 0)
                            setCKey(Util.newTempId())
                        }}
                        itemToString={item => item ? item.symbol : ""}
                        items={measurementType.unitOfMeasurements ?? []}
                    />
                </div>
            )
        }
    },
    {
        key: "requestingNote", title: "Requesting Note", flex: 2, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    }
]

export const InvRequests = newListDetail({
    title: "Stock Request",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: NewForm, updateForm: UpdateForm,

    rawPanelFunc: item => item?.status && item.status !== 'DRAFT',

    openDetailOnCreate: true,
    clearStateOnSave: true,

    defaultValues: {
        items: [],
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },

    skipInlinePadding: true,

    renderOptions: (props) => <DraftOptions {...props} />,
})

function ItemListField({ value, setValue }) {
    return <TableInput minimal columns={PRODUCT_COLS} value={value} setValue={setValue} />;
}

function DraftStatusCard({ store }) {
    const [dateCreated] = useStore(store, 'dateCreated');
    const [lastUpdated] = useStore(store, 'lastUpdated');
    const [requestedByName] = useStore(store, 'requestedByName');
    const [requestingLocationName] = useStore(store, 'requestingLocationName');

    return (
        <div style={{
            borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
            display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
        }}>
            <div>
                <h6>Status</h6>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', paddingLeft: '0.25rem' }}>
                    <div style={{ width: 6, height: 6, background: '#ab48e0', borderRadius: 50, boxShadow: '0 0 50px 15px #ab48e0CC' }} />
                    <span>Draft</span>
                </div>
            </div>
            <div>
                <h6>Created on</h6>
                {Util.getDate(dateCreated)}
            </div>
            <div>
                <h6>Created by</h6>
                {requestedByName}
            </div>
            <div>
                <h6>For Location</h6>
                {requestingLocationName}
            </div>
            <div>
                <h6>Last updated</h6>
                {Util.getDate(lastUpdated)}
            </div>
        </div>
    )
}

function NewForm({ endpoint, form }) {
    return (
        <div>
            <div style={{ paddingInline: '3rem', marginBottom: '1rem' }}>
                <label className="bx--label" style={{ opacity: 0.65, marginBottom: 0 }}>
                    Requesting Store
                </label>
                <p>{endpoint.userStoreName}</p>
            </div>

            <FormSection title={"Items"} titleStyle={{ paddingInline: '3rem' }}>
                <form.CustomField fieldKey="items">
                    {ItemListField}
                </form.CustomField>
            </FormSection>

            <div style={{ paddingInline: '3rem' }}>
                <form.TextAreaField fieldKey="requestingNote" title="Request Memo (optional)" />
            </div>
        </div>
    )
}

function UpdateForm({ endpoint, form, data }) {
    if (data.status !== 'DRAFT') {
        return <InvRequestPage data={data} />
    }

    return (
        <div>
            <div style={{ paddingInline: '3rem', marginBottom: '3rem', marginTop: '-2rem' }}>
                <form.ExtField>
                    {DraftStatusCard}
                </form.ExtField>
            </div>

            <FormSection title={"Items"} titleStyle={{ paddingInline: '3rem' }}>
                <form.CustomField fieldKey="items">
                    {ItemListField}
                </form.CustomField>
            </FormSection>

            <div style={{ paddingInline: '3rem' }}>
                <form.TextAreaField fieldKey="requestingNote" title="Request Memo (optional)" />
            </div>

            <div style={{ paddingInline: '3rem' }}>
                <div style={{ marginTop: '3rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                    <AdvancedNotesView title="Notes/Docs" objectId={data.id} objectType={OBJECT_INV_REQUEST} />
                </div>
            </div>
        </div>
    )
}

function DraftOptions({ item, onUpdate }) {
    const [loading, setLoading] = useState(false);
    const onSendRequest = async () => {
        setLoading(true);
        try {
            const [success, response] = await Api.try((api, listener) => api.makeInvRequestPending(item.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response)
        } finally {
            setLoading(false);
        }
    }

    if (item.status !== 'DRAFT') {
        return <></>;
    }

    return (<>
        <Button onClick={() => UIUtil.confirm(onSendRequest)} loading={loading} size="sm" style={{ borderRadius: 25, marginLeft: '0.25rem' }} renderIcon={Checkmark16}>
            Send Request
        </Button>
    </>)
}


const STATUSES = [
    'Draft',
    'Pending',
    'Approved',
    'Rejected',
    'Sent',
    'Received',
    'Cancelled',
]


const HIDE_DRAFTS_FILTER = { operator: "AND", negate: true, filters: [{ property: "status", operator: "EQ", value: 'Draft' }] }
const SHOW_ACTIVE_ONLY_FILTER = {
    operator: "OR", filters: [
        { property: "status", operator: "EQ", value: 'Pending' },
        { property: "status", operator: "EQ", value: 'Approved' },
        { property: "status", operator: "EQ", value: 'Sent' },
    ]
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="dateCreated" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="invreqstatus" options={STATUSES} state={state} property="status" />
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <CheckboxFilter state={state} filterId="hideDraftsCheck" label="Hide drafts" filter={HIDE_DRAFTS_FILTER} />

        <CheckboxFilter state={state} filterId="activeOnlyCheck" label="Active only" filter={SHOW_ACTIVE_ONLY_FILTER} />
    </>)
}
