import { Link } from "react-router-dom";
import { getOrderCustomerName } from "../../../domain/rest-sales-order";
import Util from "../../../util/Util";
import { addressToString } from "../base/sales-order";
import { TypeTag } from "../../pos/restaurant/components/type-tag";
import { Tag } from "carbon-components-react";


export function RestOrderTopOverview({ order }) {
    return (<div>

        <TypeTag type={order.type} />
        <div style={{ height: 5 }} />

        {order.fromOnlineStore && <>
            <Tag type="blue" size='sm'>online store</Tag>
            <div style={{ height: 5 }} />
        </>}

        <h6>Order No</h6>
        <span>{order.orderNo}</span>
        <div style={{ height: 5 }} />

        {Util.isStringExists(order.kotNo) && <>
            <h6>KOT No</h6>
            <span>{order.kotNo}</span>
            <div style={{ height: 5 }} />
        </>}


        {Util.isStringExists(order.tableName) && <>
            <h6>Table</h6>
            <span>{order.tableName}</span>
            <div style={{ height: 5 }} />
        </>}

        <h6>Customer</h6>
        {order.customerId > 0 ? (
            <Link to={"/customers/" + order.customerId}>
                <p>{order.customerName ?? "Unknown"}</p>
            </Link>
        ) : (
            <p>{getOrderCustomerName(order)}</p>
        )}

        {Util.isStringExists(order.shippingAddress?.phoneNumber) && <>
            <div style={{ height: 5 }} />
            <h6>Phone number</h6>
            <p>{order.shippingAddress.phoneNumber}</p>
        </>}

        {Util.isStringExists(order.deliveryLocationName) && <>
            <div style={{ height: 5 }} />
            <h6>Delivery Area</h6>
            <p>{order.deliveryLocationName}</p>
        </>}

        {order.shippingAddress && <>
            <div style={{ height: 5 }} />
            <h6>Address</h6>
            <p>{addressToString(order.shippingAddress)}</p>
        </>}

    </div>)
}