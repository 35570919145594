export const API_RESPONSE_FAILED_EVENT_404 = 0;
export const API_RESPONSE_EMAIL_NOT_FOUND = 1;

export const BUNDLE_SHOULD_NOT_CONTAIN_GROUP = "bundle_should_not_contain_product_group";

export const API_RESPONSE_MESSAGE_EVENT_FINAL_PAGE = "pagination_final_page";

export const TABLE_HEADER_TYPE_TEXT = 0;
export const TABLE_HEADER_TYPE_DATE = 1;
export const TABLE_HEADER_TYPE_DISABLED_STATUS = 2;
export const TABLE_HEADER_TYPE_THUMBNAIL = 3;
export const TABLE_HEADER_TYPE_PROFILE_PIC = 4;
export const TABLE_HEADER_TYPE_TABLE_LIST_ITEM = 5;
export const TABLE_HEADER_TYPE_EMAIL_ADDRESS = 6;
export const TABLE_HEADER_TYPE_PHONE_NUMBER = 7;
export const TABLE_HEADER_TYPE_MONEY = 8;
export const TABLE_HEADER_TYPE_STOCK = 9;
export const TABLE_HEADER_TYPE_CUSTOM = 10;
export const TABLE_HEADER_TYPE_FULL_DATE = 11;
export const TABLE_HEADER_TYPE_COLOR = 12;
export const TABLE_HEADER_TYPE_BALANCE = 13;

export const FORM_FIELD_TYPE_TEXT = 0;
export const FORM_FIELD_TYPE_DATE = 1;
export const FORM_FIELD_TYPE_DISABLED_STATUS = 2;
export const FORM_FIELD_TYPE_TEXT_AREA = 3;
export const FORM_FIELD_TYPE_ATTRIBUTES = 4;
export const FORM_FIELD_TYPE_TABLE_LIST = 5;
export const FORM_FIELD_TYPE_BARCODES = 6;
export const FORM_FIELD_TYPE_PHONE_NUMBER = 7;
export const FORM_FIELD_TYPE_EMAIL_ADDRESS = 8;
export const FORM_FIELD_TYPE_RADIO = 9;
export const FORM_FIELD_TYPE_DATE_RANGE = 10;
export const FORM_FIELD_TYPE_MONEY = 11;
export const FORM_FIELD_TYPE_NUMBER_DECIMAL = 12;
export const FORM_FIELD_TYPE_BOOLEAN = 13;
export const FORM_FIELD_TYPE_TYPE_PRODUCT = 14;
export const FORM_FIELD_TYPE_TYPE_PERCENTAGE = 15;
export const FORM_FIELD_TYPE_NUMBER = 16;
export const FORM_FIELD_TYPE_CUSTOM = 17;
export const FORM_FIELD_TYPE_PRICE_WITH_TAX = 18;
export const FORM_FIELD_TYPE_LOCATION = 19;
export const FORM_FIELD_TYPE_COLOR = 20;
export const FORM_FIELD_TYPE_MEDIA = 21;
export const FORM_FIELD_TYPE_SERVICE = 22;
export const FORM_FIELD_TYPE_KV_STORE = 23;
export const FORM_FIELD_TYPE_MEDIA_VIDEO = 24;

export const ATTRIBUTE_TYPE_TEXT = 0;
export const ATTRIBUTE_TYPE_NUMBER = 1;

export const TABLE_RELATION_TYPE_ONE_TO_MANY = 0;
export const TABLE_RELATION_TYPE_MANY_TO_MANY = 1;

export const DISCOUNT_CUSTOM_LIST_PRODUCTS = 0;
export const DISCOUNT_CUSTOM_LIST_PRODUCT_BUNDLES = 1;

export const PRODUCT_BUNDLE_CUSTOM_LIST_PARENT = 0;
export const SERVICE_PACKAGE_CUSTOM_LIST_PARENT = 0;

export const POS_BARCODE_SCAN_TYPE_PRODUCT = 0;
export const POS_BARCODE_SCAN_TYPE_CUSTOMER = 1;
export const POS_BARCODE_SCAN_TYPE_COUPON = 2;

export const STOCK_FLOW_INITIATION_TYPE_BACKEND = 0;
export const STOCK_FLOW_INITIATION_TYPE_POS = 1;
export const STOCK_FLOW_INITIATION_TYPE_SALES_CART = 2;

export const SOURCE_TYPE_SUPPLIER = 0;
export const SOURCE_TYPE_WAREHOUSE = 1;
export const SOURCE_TYPE_STORE = 2;
export const SOURCE_TYPE_STOCK_CREATION = 3;
export const SOURCE_TYPE_CUSTOMER = 4;
export const SOURCE_TYPE_MOBILE_UNIT = 5;
export const SOURCE_TYPE_VENUE = 6;

export const DESTINATION_TYPE_SUPPLIER = 0;
export const DESTINATION_TYPE_CUSTOMER = 1;
export const DESTINATION_TYPE_WAREHOUSE = 2;
export const DESTINATION_TYPE_STORE = 3;
export const DESTINATION_TYPE_STOCK_TERMINATION = 4;
export const DESTINATION_TYPE_MOBILE_UNIT = 5;
export const DESTINATION_TYPE_VENUE = 6;


export const PAYMENT_METHOD_PROXY_DISCOUNT = -1;
export const PAYMENT_METHOD_CASH = 0;
export const PAYMENT_METHOD_CREDIT = 1;
export const PAYMENT_METHOD_DEBIT = 2;
export const PAYMENT_METHOD_CHEQUE = 3;
export const PAYMENT_METHOD_BANK = 4;
export const PAYMENT_METHOD_OTHER = 5;
export const PAYMENT_METHOD_COUPON = 6;
export const PAYMENT_METHOD_ONLINE_GATEWAY = 7;
export const PAYMENT_METHOD_TALABAT = 8;
export const PAYMENT_METHOD_ZOMATO = 9;
export const PAYMENT_METHOD_DELIVEROO = 10;
export const PAYMENT_METHOD_PROXY_ADVANCE = 11;
export const PAYMENT_METHOD_PROXY_OUTSTANDING = 12;
export const PAYMENT_METHOD_INSTASHOP = 13;
export const PAYMENT_METHOD_FRESH_BAZAAR = 14;
export const PAYMENT_METHOD_COMPANY_EXPENSE = 15;
export const PAYMENT_METHOD_ART_BAZAAR = 16;
export const PAYMENT_METHOD_CAREEM = 17;
export const PAYMENT_METHOD_NOON = 18;
export const PAYMENT_METHOD_COURIER = 19;

export const TRANSACTION_MODE = {
    METHOD: 0,
    JOURNAL: 1
}

export const TRANSACTION_DIRECTION_TYPE_INWARD = 0;
export const TRANSACTION_DIRECTION_TYPE_OUTWARD = 1;

export const TRANSACTION_PARTY_TYPE_SUPPLIER = 0;
export const TRANSACTION_PARTY_TYPE_CUSTOMER = 1;
export const TRANSACTION_PARTY_TYPE_OTHER = 2;
export const TRANSACTION_PARTY_TYPE_STAFF = 3;
export const TRANSACTION_PARTY_TYPE_VENDOR = 4;
export const TRANSACTION_PARTY_TYPE_STORE = 5;
export const TRANSACTION_PARTY_TYPE_VENUE = 6;
export const TRANSACTION_PARTY_TYPE_STUDENT = 7;
export const TRANSACTION_PARTY_TYPE_TUTOR = 8;
export const TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR = 9;
export const TRANSACTION_PARTY_TYPE_V2_CUSTOMER = 10;
export const TRANSACTION_PARTY_TYPE_V2_VENDOR = 11;
export const TRANSACTION_PARTY_TYPE_V2_EMPLOYEE = 12;

export const MONITORING_EVENT_TYPE_VALUE_CHANGE = 0;
export const MONITORING_EVENT_TYPE_STATE_CHANGE = 1;

export const POS_SESSION_DISCOUNT_TYPE_LOYALTY = 0;
export const POS_SESSION_DISCOUNT_TYPE_COUPON = 1;
export const POS_SESSION_DISCOUNT_TYPE_FREE_FORM = 2;


export const JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT = 0;
export const JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT = 1;


export const ACCOUNTING_STATEMENT_BALANCE_SHEET = 0;
export const ACCOUNTING_STATEMENT_INCOME_SHEET = 1;
export const ACCOUNTING_STATEMENT_TRIAL_BALANCE = 2;


export const LEDGER_TYPE_ASSET = 0;
export const LEDGER_TYPE_LIABILITY = 1;
export const LEDGER_TYPE_EQUITY = 2;
export const LEDGER_TYPE_INCOME = 3;
export const LEDGER_TYPE_EXPENSE = 4;

export const ACCOUNT_TYPE_ADMINISTRATION = 0;
export const ACCOUNT_TYPE_SUPERVISOR = 1;
export const ACCOUNT_TYPE_SALES = 2;
export const ACCOUNT_TYPE_CASHIER = 3;
export const ACCOUNT_TYPE_ACCOUNTANT = 4;
export const ACCOUNT_TYPE_STORE_MASTER = 5;
export const ACCOUNT_TYPE_REAL_ESTATE_AGENT = 6;
export const ACCOUNT_TYPE_STUDENT = 7;
export const ACCOUNT_TYPE_TUTOR = 8;
export const ACCOUNT_TYPE_DELIVERY_MANAGER = 9;
export const ACCOUNT_TYPE_VENUE_MANAGER = 10;
export const ACCOUNT_TYPE_CMS_MANAGER = 11;
export const ACCOUNT_TYPE_GUARDIAN = 12;
export const ACCOUNT_TYPE_PRODUCT_MANAGER = 13;
export const ACCOUNT_TYPE_SHIPPING_PORT_STAFF = 14;
export const ACCOUNT_TYPE_LEARNING_MANAGER = 15;
export const ACCOUNT_TYPE_TYPE_WAITER = 16;
export const ACCOUNT_TYPE_TYPE_KITCHEN = 17;
export const ACCOUNT_TYPE_WAREHOUSE_KEEPER = 18;
export const ACCOUNT_TYPE_SALES_ADMIN = 19;
export const ACCOUNT_TYPE_REST_DELIVERY_MANAGER = 20;
export const ACCOUNT_TYPE_SECOND_DISPLAY = 21;


export const CHEQUE_STATUS_WAITING = -1;
export const CHEQUE_STATUS_PENDING = 0;
export const CHEQUE_STATUS_BOUNCED = 1;
export const CHEQUE_TATUS_DEPOSITED = 2;

export const CHEQUE_TYPE_RECEIVABLE = 0;
export const CHEQUE_TYPE_PAYABLE = 1;

export const CHEQUE_OTHER_PARTY_TYPE_SUPPLIER = 0;
export const CHEQUE_OTHER_PARTY_TYPE_CUSTOMER = 1;
export const CHEQUE_OTHER_PARTY_TYPE_STAFF = 2;
export const CHEQUE_OTHER_PARTY_TYPE_OTHER = 3;
export const CHEQUE_OTHER_PARTY_TYPE_VENDOR = 4;


export const STATE_TYPE_STOCK_FLOW_CREATOR = 0;
export const STATE_TYPE_ACTIVITY_BLUEPRINT = 1;
export const STATE_TYPE_ACTIVITY = 2;
export const STATE_TYPE_JOURNAL_ENTRY = 3;
export const STATE_TYPE_TEMP_DATA = 4;
export const STATE_TYPE_LMS_NOTIFICATION = 5;
export const STATE_TYPE_RECIPE_MANAGER = 6;
export const STATE_TYPE_FOOD_STOCK = 7;

export const CUSTOMER_STATUS_LEAD = 0;
export const CUSTOMER_STATUS_PROSPECT = 1;
export const CUSTOMER_STATUS_CUSTOMER = 2;

export const CUSTOMER_TYPE_INDIVIDUAL = 0;
export const CUSTOMER_TYPE_COMPANY = 1;

export const SALES_CART_WORK_STATUS_ONGOING = 0;
export const SALES_CART_WORK_STATUS_QUOTATION = 1;
export const SALES_CART_WORK_STATUS_SALES_ORDER = 2;
export const SALES_CART_WORK_STATUS_SALES = 3;
export const SALES_CART_WORK_STATUS_EXPIRED = 4;


export const POS_SESSION_TYPE_TERMINAL = 0;
export const POS_SESSION_TYPE_POCKET_POS = 1;
export const POS_SESSION_TYPE_MOBILE_UNIT = 2;
export const POS_SESSION_TYPE_SALES_CART_WORK = 3;
export const POS_SESSION_TYPE_SALES_CART = 4;
export const POS_SESSION_TYPE_ONLINE_STORE = 5;
export const SESSION_TYPE_THIRD_PARTY = 6;

export const LOCATION_EVENT_FLAG_NONE = 0;
export const LOCATION_EVENT_FLAG_STAGNANT = 1;
export const LOCATION_EVENT_FLAG_LOCATION_OFF = 2;
export const LOCATION_EVENT_FLAG_LOCATION_ON = 3;
export const LOCATION_EVENT_FLAG_SALES = 4;
export const LOCATION_EVENT_FLAG_AMOUNT_COLLECTED = 5;

export const STOCK_TYPE_DISCRETE = 0;
export const STOCK_TYPE_CONTINUOUS = 1;


export const PRODUCT_GROUP_DIVISION_TYPE_NO_DIVISION = 0;
export const PRODUCT_GROUP_DIVISION_TYPE_DIVISION_BY_ATTRIBUTES = 1;


export const STORE_TYPE_BRANCH = 0;
export const STORE_TYPE_THIRD_PARTY = 1;


export const STOCK_REQUEST_STATUS_PENDING_APPROVAL = 0;
export const STOCK_REQUEST_STATUS_APPROVED = 1;
export const STOCK_REQUEST_STATUS_REJECTED = 2;
export const STOCK_REQUEST_STATUS_REVOKED_BY_SENDER = 3;



export const REAL_ESTATE_PROJECT_CUSTOM_LIST = {
    BY_TYPE_ID: 0,
    BY_AREA_ID: 1,
    BY_DEVELOPER_ID: 2,

    MINE_AS_AGENT: 3
}
export const REAL_ESTATE_PROPERTY_CUSTOM_LIST = {
    BY_TYPE_ID: 0,
    BY_OWNER_ID: 1,
    BY_TENANT_ID: 2,
    BY_PROJECT_ID: 3,

    MINE_AS_AGENT: 4
}
export const REAL_ESTATE_AREA_CUSTOM_LIST = {
    BY_CITY_ID: 0
}

export const COURSE_ENROLMENT_CUSTOM_LIST = {
    BY_STUDENT_ID: 0,
    BY_COURSE_ID: 1,
}

export const STUDENT_PRESENCE = {
    PRESENT: 0,
    ABSENT: 1
}

export const PAID_STATUS = {
    PAID: "PAID",
    NOT_PAID: "NOT_PAID",
    PARTIAL: "PARTIAL"
}


export const GMT_TIMEZONE_OFFSET = -14400000;