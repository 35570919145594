
import { withLoadablePage } from "../../../../base/Page";
import Api from "../../../../session/Api";

import {
    GroupPresentation32,
    AddAlt16, ArrowRight16, EventSchedule16,
    GroupPresentation24
} from '@carbon/icons-react'
import { getAccountRole } from "../../../../session/SessionManager";
import { ACCOUNT_TYPE_STUDENT } from "../../../../constants/Constants";
import Button from "../../../../components/Button";
import ImageView from "../../../../components/ImageView";
import { OBJECT_TYPE_COURSE } from "../../../../constants/ObjectTypes";

import './my-courses.scss'
import { Link, useHistory } from "react-router-dom";
import { Tag } from "carbon-components-react";
import { useMemo } from "react";
import { isMobileScreen } from "../../../../App";

export function formatUpcomingClasses(count) {
    if (count <= 0) {
        return "No upcoming classes";
    } else if (count == 1) {
        return "1 class upcoming";
    } else {
        return count + " classes upcoming";
    }
}

const CourseItem = ({ course }) => {
    const history = useHistory();
    return (
        <div onClick={() => history.push('/my-courses/' + course.id)} className="my-course-card" style={{ borderRadius: 15, overflow: 'hidden', height: 250, display: 'flex', flexDirection: 'column', border: '1px solid black' }}>
            <ImageView src={Api.getThumbnail(OBJECT_TYPE_COURSE, course.id)} style={{ flex: 1, width: '100%' }} />
            <div style={{ color: 'white', background: 'black', padding: '1rem', display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <h4>{course.name}</h4>
                </div>
                <Tag size="sm" style={{ marginRight: '0.5rem' }} type={course.assignedActivitiesCount === 0 ? "gray" : "green"} renderIcon={EventSchedule16}>{formatUpcomingClasses(course.assignedActivitiesCount)}</Tag>
                <ArrowRight16 />
            </div>
        </div>
    )
}

function View({ payload: courses }) {
    const hasCourses = courses.length > 0;
    const totalUpcomingClasses = useMemo(() => courses.map(course => course.assignedActivitiesCount ?? 0).reduce((t, c) => t + c, 0), [courses])

    if (isMobileScreen) {
        return (
            <div>
                <div style={{ background: "#f4f4f4", color: 'black', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000020', paddingInline: '1rem', width: '100%', height: "3rem", gap: '0.5rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 35, height: 35 }}>
                        <GroupPresentation24 />
                    </div>
                    <h4 style={{ fontWeight: 'bold' }}>My Courses</h4>
                </div>
                <div style={{ background: 'white', borderBottom: '1px solid #00000020', width: '100%', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
                    <Tag style={{}} type={totalUpcomingClasses === 0 ? "high-contrast" : "green"} renderIcon={EventSchedule16}>{formatUpcomingClasses(totalUpcomingClasses)}</Tag>
                </div>

                {hasCourses ? (
                    <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(1, minmax(0, 1fr))', gap: '1rem', paddingInline: '1rem', paddingBlock: '1rem', }}>
                        {courses.map(course => <CourseItem course={course} />)}
                    </div>
                ) : (
                    <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>No courses</p>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div style={{}}>
            <div style={{ background: '#f4f4f4', paddingInline: '6rem', paddingTop: '3rem', paddingBottom: hasCourses ? '6rem' : '3rem', width: '100%', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <GroupPresentation32 style={{ width: 48, height: 48 }} />
                <div style={{ flex: 1 }}>
                    <h2>My Courses</h2>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>All of my courses</p>
                </div>
                <div>
                    <Tag style={{}} type={totalUpcomingClasses === 0 ? "high-contrast" : "green"} renderIcon={EventSchedule16}>{formatUpcomingClasses(totalUpcomingClasses) + ' from all courses'}</Tag>
                </div>
                {/* {getAccountRole() == ACCOUNT_TYPE_STUDENT &&
                    <Button renderIcon={AddAlt16}>Request Enrolment</Button>} */}
            </div>
            {hasCourses ? (
                <div style={{ width: '100%', display: 'grid', marginTop: '-4.5rem', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', paddingInline: '3rem', paddingBlock: '1rem', minHeight: 'calc(100% - 4rem)' }}>
                    {courses.map(course => <CourseItem course={course} />)}
                </div>
            ) : (
                <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No courses</p>
                </div>
            )}
        </div>
    )
}

export const MyCoursesPage = withLoadablePage(listener => Api.getMyCourses(listener), View);