import { useEffect, useState } from "react";

function importSeatTypesFromApi(seats) {
    return Object.fromEntries(seats.map(seat => [`${seat.seatRow},${seat.seatCol}`, { enabled: true, type: seat.seatType, unavailable: seat.unavailable }]));
}


export function useGrid(design, onRawUpdate, defaultState) {
    const [rowCount, setRowCount] = useState(design.rowCount);
    const [colCount, setColCount] = useState(design.colCount);
    const [selections, setSelections] = useState(defaultState ?? {});
    const [seatTypes, setSeatTypes] = useState(() => importSeatTypesFromApi(design.seats));

    const funcs = {
        rows: () => {
            let i = 0;
            return [...Array(rowCount).keys()].map(row => ({
                row: row,
                value: hasAnyEnabled.inRow(row) ? ((i++) + 10).toString(36).toUpperCase() : '-'
            }))
        },
        cols: () => {
            let i = 0;
            return [...Array(colCount).keys()].map(col => ({
                col: col,
                value: hasAnyEnabled.inCol(col) ? ((i++) + 1) : '-'
            }))
        },
    }

    useEffect(() => {
        if (onRawUpdate) {
            onRawUpdate({ rowCount, colCount, selections, seatTypes }, funcs)
        }
    }, [onRawUpdate, rowCount, colCount, selections, seatTypes])

    useEffect(() => {
        setSelections(prev => {
            let newSelections = {}
            for (const selection in prev) {
                const [row, col] = selection.split(',');
                if (row < rowCount && col < colCount) {
                    newSelections[selection] = prev[selection];
                }
            }
            return newSelections;
        })

        setSeatTypes(prev => {
            let newItems = {}
            for (const item in prev) {
                const [row, col] = item.split(',');
                if (row < rowCount && col < colCount) {
                    newItems[item] = prev[item];
                }
            }
            return newItems;
        })
    }, [rowCount, colCount])

    const hasAnyEnabled = {
        inRow: (row) => Object.entries(seatTypes).filter(entry => entry[0].split(',')[0] == row && entry[1]?.enabled === true).length > 0,
        inCol: (col) => Object.entries(seatTypes).filter(entry => entry[0].split(',')[1] == col && entry[1]?.enabled === true).length > 0,
    }

    return {
        ...funcs,

        grid: () => [...Array(rowCount).keys()].map(() => [...Array(colCount).keys()]),

        removeRow: () => setRowCount(p => p - 1),
        newRow: () => setRowCount(p => p + 1),

        removeCol: () => setColCount(p => p - 1),
        newCol: () => setColCount(p => p + 1),

        isSelected: (row, col) => selections[row + ',' + col] === true,
        toggleSelection: (row, col) => setSelections(prev => ({ ...prev, [row + ',' + col]: !prev[row + ',' + col] })),

        hasAnySelections: () => Object.values(selections).find(selection => selection === true),
        clearSelections: () => setSelections({}),

        isEnabled: (row, col) => seatTypes[row + ',' + col]?.enabled === true,
        getSeatType: (row, col) => seatTypes[row + ',' + col]?.type,
        isSeatUnavailable: (row, col) => seatTypes[row + ',' + col]?.unavailable,

        seatTypes,
        inSelection: {
            hasAnyEnabled: () => Object.entries(selections).filter(entry => entry[1] === true).find(entry => Object.entries(seatTypes).find(seatTypeEntry => entry[0] === seatTypeEntry[0])?.[1]?.enabled === true),
            getSeatTypes: () => Object.entries(selections).filter(entry => entry[1] === true).map(entry => Object.entries(seatTypes).find(seatTypeEntry => entry[0] === seatTypeEntry[0])?.[1]?.type),

            set: (setter) => setSeatTypes(seatTypes => {
                const selectionKeys = Object.entries(selections).filter(entry => entry[1] === true).map(entry => entry[0]);
                let newSeatTypes = { ...seatTypes }

                for (const selectionEntryKey of selectionKeys) {
                    if (newSeatTypes[selectionEntryKey] === undefined) {
                        newSeatTypes[selectionEntryKey] = {}
                    }

                    newSeatTypes[selectionEntryKey] = setter(newSeatTypes[selectionEntryKey])
                }

                return newSeatTypes;
            })
        },

        rowCount, colCount,

        raw: { rowCount, colCount, selections, seatTypes }
    }
}