import Button from "../../../../components/Button";
import { CheckmarkFilled16, Password16 } from '@carbon/icons-react'
import { useState } from "react";
import { FormDialog } from "../../../../templates/form/form-dialog";
import Api from "../../../../session/Api";
import { FormSection, useFormField } from "../../../../templates/form/form";
import useStore from "../../../../hooks/useStore";
import { TextInput } from "carbon-components-react";

const PasswordField = ({ store }) => {
    const [value, setValue] = useFormField(undefined, store, "password", "")
    const [confirmPassword, setConfirmPassword] = useFormField(undefined, store, "confirmPassword", "")
    return (<>
        <TextInput labelText="Password" value={value} onChange={e => setValue(e.target.value)} />
        <div style={{ height: '1rem' }} />
        <TextInput labelText="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
        <div style={{ height: '1rem' }} />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Button kind="tertiary" size="sm" style={{ borderRadius: 15 }} renderIcon={Password16} onClick={() => {
                const pass = Math.random().toString(36).substring(2, 8);
                setValue(pass);
                setConfirmPassword(pass)
            }}>Generate random password</Button>
        </div>
    </>)
}

const Dialog = ({ leadId, onUpdate, open, onClose, store }) => {
    const [profile, setProfile] = useStore(store, "profile");
    const emailAddress = (profile.emailAddresses ?? [])[0] ?? "";
    const phoneNumber = (profile.phoneNumbers ?? [])[0] ?? "";
    return (
        <FormDialog successMsg="Student registration completed!" open={open} onClose={onClose} label="Finalizing Lead" title="Registering Student" item={{
            enrolmentAction: 0,
            email: emailAddress,
            password: "",
            confirmPassword: "",
            yearLevel: "",
            school: "",
            phoneNumber: phoneNumber,
            sendPasswordEmail: false,
        }}
            setItem={item => {
                onClose()
                onUpdate(item)
            }} saveApi={(data, listener) => Api.finalizeLeadStudent(leadId, data, listener)}>
            {({ TextField, CheckBoxField, SwitchField, ExtField }) => (<>
                <SwitchField title="Auto Enrolment Action" fieldKey="enrolmentAction" options={[{ id: 0, title: "Enroll In Prospect Courses" }, { id: 1, title: "No Enrolments" }]} />

                <FormSection title="Student Credentials">
                    <TextField title="Email Address" fieldKey="email" />
                    <ExtField>
                        {PasswordField}
                    </ExtField>
                    <CheckBoxField title="Send credential info email to student" fieldKey="sendPasswordEmail" />
                    {/* <TextField title="Password" fieldKey="password" /> */}
                </FormSection>

                <FormSection title="Student Details">
                    {/* <TextField title="Date of Birth" fieldKey="dateOfBirth" /> */}
                    <TextField title="Year Level" fieldKey="yearLevel" />
                    <TextField title="School" fieldKey="school" />
                    <TextField title="Phone Number" fieldKey="phoneNumber" />
                </FormSection>
            </>)}
        </FormDialog>
    )
}


export function LmsFinalizeButton({ leadId, store, onUpdate }) {
    const [open, setOpen] = useState(false);
    return (<>
        <Button onClick={() => setOpen(true)} kind="primary" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={CheckmarkFilled16}>Finalize</Button>

        <Dialog store={store} leadId={leadId} onUpdate={onUpdate} open={open} onClose={() => setOpen(false)} />
    </>)
}