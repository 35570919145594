import { useState } from "react"

import { Screen32, ErrorFilled16, ArrowRight16 } from '@carbon/icons-react'
import Button from "../../../../components/Button";
import useApi from "../../../../hooks/useApi";
import Api from "../../../../session/Api";
import UIUtil from "../../../../util/UIUtil";
import OperatorView from "./OperatorView";
import CartView from "./CartView";
import ProductView from "./ProductView";
import CreateOrderView from "./CreateOrderView";
// import Button from "../../../components/Button";

export const NoSession = ({ onStarted }) => {
    const [loading, startSession] = useApi(Api.startThirdPartyPos, onStarted)

    return (
        <div style={{ width: '100%', height: '100%', paddingLeft: '15rem', paddingRight: '15rem', paddingTop: '3rem', paddingBottom: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div className="dashboard-card" style={{ background: '#f4f4f4', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '3rem' }}>
                <Screen32 style={{ transform: 'scale(2)', marginLeft: 10 }} />
                <h2 style={{ marginTop: '2rem' }}>Not active</h2>
                <p>Start work session</p>

                <Button
                    loading={loading}
                    onClick={startSession}
                    style={{ marginTop: '6rem' }} renderIcon={ArrowRight16}>Start</Button>
            </div>

            {/* {this.renderDialogs()} */}
        </div>
    )
}

export const HasSession = ({ sessionStore, allowNegativeStock, onStopped }) => {
    const [stopSessionLoading, stopSession] = useApi(Api.stopThirdPartyPos, onStopped)
    return (
        <div className="third-party-pos" style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%', padding: '0.5rem', gap: '0.5rem', }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', gap: '0.5rem' }}>
                <div style={{ flex: 1 }}>
                    <ProductView sessionStore={sessionStore} allowNegativeStock={allowNegativeStock} />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <OperatorView stopSessionLoading={stopSessionLoading} stopSession={stopSession} />
                    <div style={{ flex: 1, minHeight: 0, }}>
                        <CartView sessionStore={sessionStore} />
                    </div>
                    <CreateOrderView sessionStore={sessionStore} />
                </div>
            </div>
        </div>
    )
}