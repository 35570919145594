import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import Util from '../../../../../../util/Util';
import Logo from '../../../../../../images/doc-logo.png'
import { PDFTable } from '../../../../../../pdfs/invoice-document/pdf/Invoice';
import Api from '../../../../../../session/Api';
import { stringifyBlueprintInputFields } from '../../../../../activities/components/blueprint-input-fields-read';
import picardlogo from '../../../../../../images/other/picard/picardlogo.png';
import { isPicard } from '../../../../../../app/app-util';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }
});


const COLS = [
    {
        id: "no",
        name: "No",
        flex: 1,
        alignment: "center",
        render: row => <Text>{row.no}</Text>
    },
    {
        id: "type",
        name: "Type",
        flex: 2,
        alignment: "center",
        render: row => <Text>{row.type}</Text>
    },
    {
        id: "description",
        name: "Description",
        flex: 4,
        alignment: "flex-start",
        render: row => <Text style={{ paddingLeft: 5 }}>{row.description}</Text>
    },
    {
        id: "qty",
        name: "Qty",
        flex: 1,
        alignment: "center",
        render: row => <Text>{row.qty}</Text>
    },
    // {
    //     id: "yard",
    //     name: "Yard",
    //     flex: 2,
    //     alignment: "center",
    //     render: row => <Text>{row.yard}</Text>
    // },
    {
        id: "remarks",
        name: "Remarks",
        flex: 3,
        alignment: "flex-start",
        render: row => <Text style={{ paddingLeft: 5 }}>{row.remarks}</Text>
    },
]


function PDF({ shipment, payload: master }) {
    const vesselName = (() => {
        return master?.transportationLegs.map(leg => leg.vesselName).join(" -> ");
    })();
    const pod = (() => {
        const lastLeg = master?.transportationLegs[master?.transportationLegs.length - 1];
        return lastLeg?.arrivalPortName ?? "-"
    })();
    const arrivalDate = (() => {
        const lastLeg = master?.transportationLegs[master?.transportationLegs.length - 1];
        return Util.getDateOnly(Util.isNumberExist(lastLeg?.actualEndDate) ? lastLeg?.actualEndDate : lastLeg?.scheduledEndDate)
    })();

    const items = shipment.packages.flatMap(e => e.items);

    return (
        <Document>
            <Page size="A4" orientation='landscape' style={styles.page}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    {isPicard() ? <>
                        <Image src={picardlogo} style={{ width: 100, objectFit: 'contain', objectPosition: 'center', }} />
                    </> : <>
                        <Image src={Logo} style={{ width: 75, height: 75 }} />
                    </>}
                    <View style={{ flex: 1, paddingLeft: 25 }}>
                        <Text style={{ fontSize: 18 }}>LOADING LIST</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <View style={{ flex: 1 }}>
                                <Text>VESSEL: {vesselName}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>POD: {pod}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>ARRIVAL DATE: {arrivalDate}</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                            <View>
                                <Text>Customer: {shipment?.invoiceCustomerIdName}</Text>
                            </View>
                        </View>
                    </View>

                </View>
                <View style={{ width: '100%', height: 2, marginTop: 15, marginBottom: 25, backgroundColor: '#68adf7' }} />

                <PDFTable normalPaddingTop rowBorder cols={COLS} rows={items.map((item, index) => ({
                    no: index + 1,
                    type: item.itemTypeName,
                    description: stringifyBlueprintInputFields(item.formFieldValues),
                    qty: item.quantity,
                    remarks: item.remark,
                }))} />

            </Page>
        </Document>
    )
}

export function createLoadingList(shipment) {
    return {
        PDF,
        canGenerate: true,
        getPayload: Util.isNumberExist(shipment.shipmentMasterId) ? (shipment, listener) => {
            Api.getShipmentMaster(shipment.shipmentMasterId, response => {
                if (response.status === true) {
                    listener(true, response.payload.item)
                } else {
                    listener(false, response.message);
                }
            })
        } : undefined
    }
}