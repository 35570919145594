import { withLoadablePagePropsAndProps } from "../../base/Page"
import { addressToString } from "../../pages/sales-order/base/sales-order"
import Api from "../../session/Api"
import { Crossroads24, Edit16, TrashCan16, Add32, CheckmarkFilled20 } from '@carbon/icons-react'
import Util from "../../util/Util"
import Button from "../../components/Button"
import { FormDialog } from "../../templates/form/form-dialog"
import { useEffect, useState } from "react"
import UIUtil from "../../util/UIUtil"
import Portal from "../../util/Portal"
import { hasCapabilitySupport } from "../../app/Capabilities"

const AddressDialog = ({ open, onClose, address, onSuccess, deliveryLocations }) => {
    return (
        <FormDialog
            formConfig={{ replaceComboBoxWithDataList: true }}
            open={open} onClose={onClose} creating={!Util.isNumberExist(address?.id)} item={address} setItem={onSuccess} title={"Address"} label={"Customer"} saveApi={(data, listener) => {
                Api.saveCustomerAddress(data, listener)
            }}>
            {({ TextField, ComboBoxField, TextAreaField }) => (<>
                <TextField fieldKey="name" title="Address Title" />
                {hasCapabilitySupport("restaurant") ? (
                    <ComboBoxField fieldKey="deliveryLocationId" title="Delivery Area" options={deliveryLocations} />
                ) : (
                    <TextField fieldKey="area" title="Area" />
                )}
                <TextField fieldKey="buildingName" title="Building Name" />
                <TextAreaField fieldKey="addressLineOne" title="Address" />
            </>)}
        </FormDialog>
    )
}

const Card = ({ selected, onClick, address, onEditBtn, onDeleteSuccess }) => {
    const [deleting, setDeleting] = useState(false);
    const onDeleteBtn = () => {
        setDeleting(true);
        Api.deleteCustomerAddress(address.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onDeleteSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setDeleting(false)
        })
    }
    return (
        <div onClick={onClick} style={{
            position: 'relative', padding: '1rem', borderRadius: 7, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
            background: selected ? '#E5EDFF' : 'white',
            border: selected ? "2px solid #0f62fe" : '1px solid #00000020',
            cursor: onClick ? 'pointer' : undefined
        }}>
            <Crossroads24 />
            <h4 style={{ fontWeight: "bold" }}>{Util.isStringExists(address.name) ? address.name : "Untitled Address"}</h4>
            <p style={{}}>{addressToString(address)}</p>

            <div style={{ marginTop: '1rem', display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button onClick={() => UIUtil.confirm(onDeleteBtn)} loading={deleting} kind={"danger-ghost"} renderIcon={TrashCan16} style={{ borderRadius: 5 }}>Delete</Button>
                <Button onClick={onEditBtn} renderIcon={Edit16} style={{ borderRadius: 5 }}>Edit</Button>
            </div>

            {selected && <CheckmarkFilled20 style={{ color: '#0f62fe', position: 'absolute', top: '1rem', right: '1rem' }} />}
        </div>
    )
}

const NewCard = ({ onClick }) => {
    return (
        <div onClick={onClick} style={{
            background: 'white', padding: '1rem', borderRadius: 7, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', border: '1px solid #00000020',
            display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.65, cursor: 'pointer'
        }}>
            <Add32 />
            <h4>New</h4>
        </div>
    )
}

const View = ({ customerId, payload, setShowingDialog, value, setValue }) => {
    const [addressList, setAddressList] = useState(payload.addresses)
    const [selectedAddress, setSelectedAddress] = useState(undefined);

    const onNewBtn = () => {
        setSelectedAddress({ customerId, save: true, })
    }

    const onAddressDeleted = address => {
        setAddressList(prev => prev.filter($ => $.id !== address.id));
    }

    const onSelectedAddressUpdated = update => {
        const address = { ...update, customerId }
        if (Util.isNumberExist(selectedAddress?.id)) {
            setAddressList(prev => prev.map($ => $.id === address.id ? address : $));
        } else {
            setAddressList(prev => [...prev, address]);
        }
        setSelectedAddress(undefined)
        setValue?.(address)
    }

    useEffect(() => {
        setShowingDialog?.(selectedAddress !== undefined)
    }, [selectedAddress])

    return (
        <div>
            {/* <div style={{ padding: '1rem', borderRadius: 7, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', border: '1px solid #00000020', background: '#1c1c1c', marginBottom: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button onClick={onNewBtn} renderIcon={Add16} style={{ borderRadius: 5 }}>New Address</Button>
            </div> */}
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', marginBottom: '3rem' }}>
                {addressList.map(address => (
                    <Card
                        key={address.id}
                        address={address}
                        selected={address?.id === value?.id}
                        onClick={setValue ? (() => setValue(address)) : undefined}
                        onEditBtn={() => setSelectedAddress(address)}
                        onDeleteSuccess={() => onAddressDeleted(address)}
                    />
                ))}
                <NewCard onClick={onNewBtn} />
            </div>

            {selectedAddress !== undefined && (
                <Portal>
                    <div className="high-z-modal">
                        <AddressDialog open onClose={() => setSelectedAddress(undefined)} address={selectedAddress} onSuccess={onSelectedAddressUpdated} deliveryLocations={payload.deliveryLocations} />
                    </div>
                </Portal>
            )}
        </div>
    )
}

export const NoDataCustomerAddress = View;
export const CustomerAddress = withLoadablePagePropsAndProps(props => listener => Api.getCustomerAddresses(props.customerId, listener), View);