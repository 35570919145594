import { HOURS } from "./hours";

/*
[
                    {
                        id: 50,
                        label: "Mirdif City Center",
                        resources: [
                            {
                                id: 98,
                                label: "IMAX",
                                values: [
                                    {
                                        id: 32,
                                        title: "Avatar",
                                        start: new Date().getTime() - 60000,
                                        end: new Date().getTime() + 60000,
                                    },
                                    {
                                        id: 777,
                                        title: "Aquaman",
                                        start: 1682967600000,
                                        end: 1682971200000,
                                    }
                                ]
                            },
                            {
                                id: 12,
                                label: "Standard",
                                values: [
                                    {
                                        id: 323,
                                        title: "Avatar",
                                        start: new Date().getTime() - (60000 * 4),
                                        end: new Date().getTime() + (60000 * 4),
                                    },
                                    {
                                        id: 84,
                                        title: "Garfield",
                                        start: new Date().getTime() - ((2 * 24 * 60 * 60 * 1000)) - ((1800 * 1000) * 2),
                                        //end: (new Date().getTime() - (2 * 24 * 60 * 60 * 1000)) + 60000
                                        end: 1682794810001 + ((1800 * 1000) * 2),
                                    },
                                    {
                                        id: 844,
                                        title: "Spiderman No Way Home",
                                        start: 1682794850001 - ((1800 * 1000) * 2),
                                        //end: (new Date().getTime() - (2 * 24 * 60 * 60 * 1000)) + 60000
                                        end: 1682798400001 + ((1800 * 1000) * 2),
                                    }
                                ]
                            }
                        ]
                    }
                ]
*/

const daysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Get the number of days in the month
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // Create an array to hold the Date objects for each day in the month
    const daysArray = [];

    // Loop through each day in the month and create a Date object for it
    for (let i = 1; i <= daysInMonth; i++) {
        const day = new Date(year, month, i);
        daysArray.push(day);
    }

    return daysArray;
}

const formatDay = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    let numberSuffix;

    switch (dayOfMonth % 10) {
        case 1:
            numberSuffix = 'st';
            break;
        case 2:
            numberSuffix = 'nd';
            break;
        case 3:
            numberSuffix = 'rd';
            break;
        default:
            numberSuffix = 'th';
    }

    if (dayOfMonth >= 11 && dayOfMonth <= 13) {
        numberSuffix = 'th';
    }

    return `${dayOfWeek} ${dayOfMonth}${numberSuffix}`;
}

const getMonths = (min, max) => {
    const date1 = new Date(min);
    const date2 = new Date(max);

    const months = [];
    while (date1 < date2 || date1.getMonth() === date2.getMonth()) {
        // Add the current month to the array
        months.push({
            value: date1.getTime(),
            name: date1.toLocaleString("en-US", { month: 'long' }),
            days: daysInMonth(date1).map(dayDate => ({
                value: dayDate.getTime(),
                name: formatDay(dayDate)
            }))
        });



        // Increment to the next month
        date1.setMonth(date1.getMonth() + 1);
    }

    return months;
}

const getHoursInDay = (date) => {
    date.setHours(0, 0, 0, 0);
    const timestamps = [];

    for (let i = 0; i < 24; i++) {
        date.setHours(i);
        timestamps.push(date.getTime());
    }

    return timestamps;
}


const mergeDuplicateValuesInCells = (cells) => {
    const idMap = new Map();

    const array = cells.flatMap($ => $.values);
    array.forEach((val, index) => {
        const id = val.id;
        if (idMap.has(id)) {
            const firstIndex = idMap.get(id);
            array[firstIndex].numDeleted = (array[firstIndex].numDeleted || 0) + 1;
            val.deleted = true;
        } else {
            idMap.set(id, index);
        }
    });

    cells.forEach(obj => {
        obj.values = obj.values.filter(val => !val.deleted);
    });
}

const trimCells = (records) => {
    const firstNonEmptyIndex = records.findIndex($ => $.values.length);
    records.splice(0, firstNonEmptyIndex - 2)

    let lastNonEmptyIndex = -1;
    for (let i = 0; i < records.length; i++) {
        if (records[i].values.length > 0) {
            lastNonEmptyIndex = i;
        }
    }

    records.splice(lastNonEmptyIndex + (2 + 24), records.length)
}

const trimMonths = (months, cells) => {
    const firstMonthStartDate = months[0].days[0].value;
    const lastMonthStartDate = months[months.length - 1].days[months[months.length - 1].days.length - 1].value + (23 * 3600000);
    const firstCellStartDate = cells[0].startDate;
    const lastCellStartDate = cells[cells.length - 1].startDate;

    if (firstMonthStartDate != firstCellStartDate) {
        for (const month of months) {
            let deleteCount = month.days.length;
            for (const day of month.days) {
                const [dayStart, dayEnd] = [day.value, day.value + (23 * 3600000)]

                if (firstCellStartDate >= dayStart && firstCellStartDate < dayEnd) {
                    //Delete every day before this 
                    deleteCount = month.days.indexOf(day);

                    day.skipHours = new Date(firstCellStartDate).getHours();
                }
            }

            month.days.splice(0, deleteCount)
            break;
        }

        months = months.filter(month => month.days.length)
    }

    if (lastMonthStartDate != lastCellStartDate) {
        for (const month of months.slice().reverse()) {
            let deleteIndex = 0;
            for (const day of month.days.slice().reverse()) {
                const [dayStart, dayEnd] = [day.value, day.value + (23 * 3600000)]

                if (lastCellStartDate >= dayStart && lastCellStartDate < dayEnd) {
                    //Delete every day after this 
                    deleteIndex = month.days.indexOf(day) + 1;
                    day.maxHours = new Date(cells[cells.length - 1].endDate).getHours();
                }
            }

            month.days.splice(deleteIndex, month.days.length)
            break;
        }

        months = months.filter(month => month.days.length)
    }

    return months;
}






const parseMonths = (min, max) => {
    const months = getMonths(min, max);

    // const dayToRemoveIndex = months[0].days.findIndex(day => day.value >= min) - 1
    // months[0].days.splice(0, months[0].days.slice(0, dayToRemoveIndex).length)

    return months;
}

const parseCells = (months, data) => {
    const allValues = data.flatMap($ => $.resources).flatMap($ => $.values.map(val => ({ ...val, resourceId: $.id })));

    const cells = months.flatMap(month => month.days).flatMap(day => getHoursInDay(new Date(day.value))).flatMap((startDate, index) => {
        const endDate = startDate + 3600000;
        return {
            cellId: index + 1,
            startDate, endDate,
            values: allValues.filter($ => $.start < endDate && $.end >= startDate).map(val => ({ ...val }))
        }
    })

    mergeDuplicateValuesInCells(cells);
    trimCells(cells);

    return cells;
}

export function parseData(data) {
    const dates = data.flatMap($ => $.resources).flatMap($ => $.values).flatMap($ => [$.start, $.end]).sort();
    let [min, max] = [dates[0], dates[dates.length - 1]]

    if (!dates.length) {
        min = new Date().getTime()
        max = new Date().getTime()
    }

    let months = parseMonths(min, max);
    const cells = parseCells(months, data);
    months = trimMonths(months, cells);

    return {
        months, cells
    }
}
