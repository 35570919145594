import { InlineLoading, TextInput, Toggle } from "carbon-components-react";
import { MosaicGrid } from "../../../../components/mosaic-grid";
import { OrderCard } from "../components/order-card";
import { TypeTag } from "../components/type-tag";
import { REST_ORDER_TYPE, getOrderCustomerName } from "../../../../domain/rest-sales-order";
import { OrderTabs } from "../order-tabs";
import { ClassFilter } from "../components/class-filter";
import Util from "../../../../util/Util";
import { useState, useMemo } from "react";
import Button from "../../../../components/Button";
import { ErrorFilled16 } from '@carbon/icons-react'
import { hasCapabilitySupport } from "../../../../app/Capabilities";

export function Kitchen({ state, orders, removeOrder, updateOrder, mainApp }) {
    const [orderNo, setOrderNo] = useState("")
    const [kotNo, setKotNo] = useState("")
    const [customer, setCustomer] = useState("")
    const [phone, setPhone] = useState("")
    const [table, setTable] = useState("")

    const filteredOrders = useMemo(() => {
        if (!orders) {
            return []
        }

        if (Util.isStringExists(customer) || Util.isStringExists(phone) || Util.isStringExists(table) || Util.isStringExists(orderNo) || Util.isStringExists(kotNo)) {
            return orders.filter(order => {
                const predicates = []
                if (Util.isStringExists(table)) {
                    predicates.push(
                        order.tableName?.toLowerCase?.() === table.toLowerCase().replace("table", "").trim()
                    )
                }
                if (Util.isStringExists(phone)) {
                    // predicates.push(
                    //     order?.shippingAddress?.phoneNumber?.toLowerCase?.().includes?.(phone.trim())
                    // )
                    const orderNumber = order?.shippingAddress?.phoneNumber;
                    if (orderNumber) {
                        const parseNum = number => {
                            number = number.trim().toLowerCase().replace("+", "").replace(" ", "").replace("-", "").replace("(", "").replace(")", "").replace("971", "");
                            if (number.startsWith("0")) {
                                number = number.substring(1)
                            }
                            return number;
                        }

                        if (Util.isStringExists(order?.shippingAddress?.readablePhoneNumber)) {
                            predicates.push(parseNum(orderNumber).includes(parseNum(phone)) || parseNum(order?.shippingAddress?.readablePhoneNumber).includes(parseNum(phone)));
                        } else {
                            predicates.push(parseNum(orderNumber).includes(parseNum(phone)));
                        }
                    } else {
                        predicates.push(false);
                    }
                }
                if (Util.isStringExists(customer)) {
                    predicates.push(
                        getOrderCustomerName(order)?.toLowerCase?.().includes?.(customer.trim())
                    )
                }
                if (Util.isStringExists(orderNo)) {
                    predicates.push(order?.orderNo?.toLowerCase?.().includes?.(orderNo.trim()))
                }
                if (Util.isStringExists(kotNo)) {
                    predicates.push(order?.kotNo?.toLowerCase?.().includes?.(kotNo.trim()))
                }

                return predicates.filter(p => p).length === predicates.length;
            });
        } else {
            return orders;
        }
    }, [orderNo, kotNo, customer, phone, table, orders])

    if (state.isLoading()) {
        return (
            <div className="centered-progress-bar" style={{ width: '100%', height: '100%', background: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    return (
        <div style={{
            position: 'relative',
            width: '100%', height: '100%', background: 'white', overflow: 'auto',
        }}>
            <div style={{ position: 'sticky', top: 0, minHeight: '3rem', paddingBlock: '0.5rem', zIndex: 50, background: '#f4f4f4', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem', flexWrap: 'wrap', borderBottom: '1px solid #00000020' }}>
                <div style={{ marginRight: '3rem' }}>
                    <OrderTabs kitchen orderState={state} tab={state.orderTab} setTab={tab => state.orderTab = tab} />
                </div>

                {!state.isWaiter && <>
                    <TypeTag type={REST_ORDER_TYPE.DINE_IN} onClick={() => state.toggleTypeSkipped(REST_ORDER_TYPE.DINE_IN)} opacity={state.isTypeSkipped(REST_ORDER_TYPE.DINE_IN) ? 0.65 : 1} />
                    <TypeTag type={REST_ORDER_TYPE.TAKE_OUT} onClick={() => state.toggleTypeSkipped(REST_ORDER_TYPE.TAKE_OUT)} opacity={state.isTypeSkipped(REST_ORDER_TYPE.TAKE_OUT) ? 0.65 : 1} />
                    <TypeTag type={REST_ORDER_TYPE.DELIVERY} onClick={() => state.toggleTypeSkipped(REST_ORDER_TYPE.DELIVERY)} opacity={state.isTypeSkipped(REST_ORDER_TYPE.DELIVERY) ? 0.65 : 1} />
                    <TypeTag type={REST_ORDER_TYPE.PICK_UP} onClick={() => state.toggleTypeSkipped(REST_ORDER_TYPE.PICK_UP)} opacity={state.isTypeSkipped(REST_ORDER_TYPE.PICK_UP) ? 0.65 : 1} />

                    {hasCapabilitySupport("fnbEvent") && <>
                        <TypeTag type={REST_ORDER_TYPE.CATERING} onClick={() => state.toggleTypeSkipped(REST_ORDER_TYPE.CATERING)} opacity={state.isTypeSkipped(REST_ORDER_TYPE.CATERING) ? 0.65 : 1} />
                        <TypeTag type={REST_ORDER_TYPE.BANQUET} onClick={() => state.toggleTypeSkipped(REST_ORDER_TYPE.BANQUET)} opacity={state.isTypeSkipped(REST_ORDER_TYPE.BANQUET) ? 0.65 : 1} />
                    </>}
                </>}
                <div style={{ flex: 1 }} />



                {/* <div style={{ width: 125, minWidth: 125 }} className='no-padding-toggle-control'>
                    <Toggle labelA='No auto print' labelB='Auto print KOT' checked={state.isAutoKotPrint} onChange={e => state.isAutoKotPrint = e.target.checked} />
                </div>
                <div style={{ flex: 1 }} /> */}
                <div style={{ height: 36 }}>
                    <ClassFilter placeholder={"Filter class"} value={state.filterClasses} setValue={value => state.filterClasses = value} options={state.classifications} />
                </div>
                <div style={{ flex: 1, flexBasis: '100%' }} />

                <div style={{ flex: 1 }} />


                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', width: 120 }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Order#:</label>
                    <TextInput light size="sm" placeholder="No filter" value={orderNo} onChange={e => setOrderNo(e.target.value)} />
                </div>
                <div style={{ width: '0.25rem' }} />
                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', width: 100 }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">KOT#:</label>
                    <TextInput light size="sm" placeholder="No filter" value={kotNo} onChange={e => setKotNo(e.target.value)} />
                </div>
                <div style={{ width: '0.25rem' }} />
                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Customer: </label>
                    <TextInput light size="sm" placeholder="No filter" value={customer} onChange={e => setCustomer(e.target.value)} />
                </div>
                <div style={{ width: '0.25rem' }} />
                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', width: 170 }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Phone: </label>
                    <TextInput light size="sm" placeholder="No filter" value={phone} onChange={e => setPhone(e.target.value)} />
                </div>
                <div style={{ width: '0.25rem' }} />
                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', width: 100 }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Table: </label>
                    <TextInput light size="sm" placeholder="No filter" value={table} onChange={e => setTable(e.target.value)} />
                </div>
                <Button disabled={!Util.isStringExists(orderNo) && !Util.isStringExists(kotNo) && !Util.isStringExists(customer) && !Util.isStringExists(phone) && !Util.isStringExists(table)}
                    kind="danger" size="sm" renderIcon={ErrorFilled16} onClick={() => {
                        setOrderNo("")
                        setKotNo("")
                        setCustomer("")
                        setPhone("")
                        setTable("")
                    }}></Button>
            </div>

            <div style={{ width: '100%', height: 'calc(100% - 3rem)', }}>
                {filteredOrders.length === 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>
                </div>}
                {/* <div style={{
                    display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '1rem', padding: '1rem', gridAutoRows: 'min-content'
                }}>
                    {filteredOrders.map(order => <OrderCard key={order.id} orderState={state} kot order={order} removeOrder={removeOrder} updateOrder={updateOrder} mainApp={mainApp} filterClasses={state.filterClasses} />)}
                </div> */}
                <MosaicGrid col={3}>
                    {filteredOrders.map(order => <OrderCard key={order.id} orderState={state} kot order={order} removeOrder={removeOrder} updateOrder={updateOrder} mainApp={mainApp} filterClasses={state.filterClasses} />)}
                </MosaicGrid>
            </div>
        </div>
    )
}