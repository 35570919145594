import { OBJECT_TYPE_BILL, OBJECT_TYPE_STOCK_FLOW } from "../../../../../constants/ObjectTypes"
import Util from "../../../../../util/Util"
import { BillPreview } from "../bill-preview"


export function getAttachments(rowData) {
    if (rowData) {
        return Object.values(rowData)
            .filter((value) => typeof value === "string" && value.startsWith("@@attach"))
            .map(value => value.split(","))
            .map(value => ({
                title: value[1],
                itemType: parseInt(value[2]),
                itemId: parseInt(value[3])
            }))
            .filter(item => Util.isNumberExist(item.itemId))
            .filter(item => item.itemType === OBJECT_TYPE_BILL)
    } else {
        return []
    }
}

export function renderAttachmentView(attachment) {
    switch (attachment.itemType) {
        case OBJECT_TYPE_BILL:
            return <BillPreview itemId={attachment.itemId} />
        default:
            return null;
    }
}