import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Util from '../../../util/Util';

const borderColor = '#ff9b87'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#ffb6a8',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    a: {
        width: '20%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    // b: {
    //     width: '15%',
    //     borderRightColor: borderColor,
    //     borderRightWidth: 1,
    //     textAlign: 'left',
    //     paddingLeft: 8,
    // },
    c: {
        //width: '25%',
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingLeft: 8,
    },
    d: {
        width: '20%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    e: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const TableRows = ({ items }) => {
    const rows = items.map(item =>
        <View style={styles.row}>
            <Text style={styles.a}>{Util.getVoucherNumber(item.id)}</Text>
            {/* <Text style={styles.b}>{'b'}</Text> */}
            <Text style={styles.c}>{Util.getDateOnly(item.initiationDate)}</Text>
            <Text style={styles.d}>{item.amount.toFixed(2)}</Text>
            <Text style={styles.e}>{(item.amount - item.amountPaid).toFixed(2)}</Text>
        </View>
    )
    return <>{rows}</>
};

export default TableRows