import { useMemo, useState } from "react"
import { MinimalTable } from "../../../components/minimal-table"
import Util from "../../../util/Util"
import Button from "../../../components/Button"
import { Certificate32, ArrowRight16, Edit16, TrashCan16 } from '@carbon/icons-react'

import { Link } from "react-router-dom";
import { OBJECT_TYPE_V2_BILL } from "../../../constants/ObjectTypes"
import NoteView from "../../../views/notes/NoteView"
import { EditBillDialog } from "./edit-bill"
import { SidePanel } from "../../../templates/draft/components/side-panel"
import { V2BillForm } from "."
import UIUtil from "../../../util/UIUtil"

const ValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{Util.isStringExists(children) ? children : '-'}</p>
    </div>
)

const Group = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

const COLUMNS = [
    {
        name: "item",
        title: "Item",
        flex: 2,
        renderCell: item => !item.aggregate ? item.itemName : undefined
    },
    {
        name: "class",
        title: "Class",
        flex: 2,
        renderCell: item => !item.aggregate ? item?.tags?.map($ => $.name).join(", ") : undefined
    },
    {
        name: "memo",
        title: "Memo",
        flex: 2,
        renderCell: item => !item.aggregate ? item.memo : undefined
    },
    {
        name: "rate",
        title: "Cost",
        flex: 1,
        renderCell: item => !item.aggregate ? item.rate : undefined
    },
    {
        name: "qty",
        title: "Qty",
        flex: 1,
        renderCell: item => !item.aggregate ? item.qty : undefined
    },
    {
        name: "amount",
        title: "Amount",
        flex: 2,
        renderCell: item => !item.aggregate ? item.gross : undefined
    },
    {
        name: "tax",
        title: "Tax",
        flex: 2,
        renderCell: item => !item.aggregate ? item.tax : undefined
    },
    {
        name: "total",
        title: "Total",
        flex: 2,
        renderCell: item => !item.aggregate ? item.total : item.aggregate.total
    },
]

export function DetailView({ data: initialData, deleteLoading, deleteItem, canDelete, ...props }) {
    const [data, setData] = useState(initialData)
    const [edit, setEdit] = useState(false);

    const items = useMemo(() => {
        const list = data.items.filter($ => !$.hidden);
        return [
            ...list,
            {
                id: "total", aggregate: {
                    total: data.amount
                }
            }
        ];
    }, [data])

    const onDeleteBtn = () => {
        UIUtil.confirmMsg('This action is irreversible', deleteItem)
    }

    return (
        <div style={{ paddingInline: '3rem', marginTop: '-1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '1rem' }}>
                <div style={{ flex: '1' }} />
                {data.transactions.map(transaction => (
                    <Link to={"/transaction/" + transaction.transactionId}>
                        <Button size="sm" renderIcon={ArrowRight16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Payment #{transaction.voucherNo}</Button>
                    </Link>
                ))}
                <Link to={"/journal-entry/" + data.journalEntryId}>
                    <Button size="sm" renderIcon={ArrowRight16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Journal Entry</Button>
                </Link>
                {/* <Button onClick={() => setEdit(true)} className="green-button" size="sm" renderIcon={Edit16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Edit</Button> */}
                <Button onClick={onDeleteBtn} kind="danger" size="sm" renderIcon={TrashCan16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Delete</Button>
            </div>

            <Group title="Attachments">
                <NoteView objectId={data.id} objectType={OBJECT_TYPE_V2_BILL} />
            </Group>

            <Group title="Bill Document">
                <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                    <ValueField title="Voucher No" style={{ flex: 1 }}>
                        {data.voucherNo}
                    </ValueField>
                    <ValueField title="Bill Date" style={{ flex: 1 }}>
                        {Util.getDateOnly(data.billDate)}
                    </ValueField>
                    <ValueField title="Due Date" style={{ flex: 1 }}>
                        {Util.getDateOnly(data.dueDate)}
                    </ValueField>
                </div>
                <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                    <ValueField title="Name" style={{ flex: 1 }}>
                        {data.billFromName}
                    </ValueField>
                    <ValueField title="TRN" style={{ flex: 1 }}>
                        {data.billFromTrn}
                    </ValueField>
                    <ValueField title="Address" style={{ flex: 1 }}>
                        {data.billFromAddress}
                    </ValueField>
                </div>
                <ValueField title="Memo">
                    {data.memo}
                </ValueField>
            </Group>

            <MinimalTable
                large items={items}
                columns={COLUMNS}
            />

            <EditBillDialog item={data} setItem={setData} open={edit} onClose={() => setEdit(false)} payload={props.endpoint} />
            {/* {edit && <SidePanel backBtn onClose={() => setEdit(false)} md2>
                <V2BillForm {...props} />
            </SidePanel>} */}
        </div>
    )
}