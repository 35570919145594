import { ContentSwitcher, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';
import Button from './Button';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [key, setKey] = useMultiInputState(input, 'recordKey', onInputUpdate);
    const [value, setValue] = useMultiInputState(input, 'recordValue', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <TextInput size="lg" labelText="Title" value={key} onChange={e => setKey(e.target.value)} />
            <TextInput size="lg" labelText="Value" value={value} onChange={e => setValue(e.target.value)} />

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const KVStoreEditor = ({ highZ, defaultItems, onUpdate }) => (
    <MultipleInputEditor highZ={highZ} defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
)

export const AwareKVStoreEditor = ({ highZ, defaultItems, onUpdate }) => (
    <MultipleInputEditor highZ={highZ} defaultInputs={[]} inputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
)