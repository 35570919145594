// Balcony
// Restaurant
// Supermarket
// Near Mall
// Near Park
// Near public transportation
// Shared GYM
// Shared Pool
// Security
// Concierge Service
// A / C
// Buil -in Wardrobe
// Parking
// High Floor
// City View 
// Sea View



export const REAL_ESTATE_AMENITIES = [
    {
        type: 0,
        name: "Custom"
    },
    {
        type: 1,
        name: "Balcony"
    },
    {
        type: 2,
        name: "Restaurant"
    },
    {
        type: 3,
        name: "Supermarket"
    },
    {
        type: 4,
        name: "Near Mall"
    },
    {
        type: 5,
        name: "Near Park"
    },
    {
        type: 6,
        name: "Near public transportation"
    },
    {
        type: 7,
        name: "Shared GYM"
    },
    {
        type: 8,
        name: "Shared Pool"
    },
    {
        type: 9,
        name: "Security"
    },
    {
        type: 10,
        name: "Concierge Service"
    },
    {
        type: 11,
        name: "A/C"
    },
    {
        type: 12,
        name: "Build-in Wardrobe"
    },
    {
        type: 13,
        name: "Parking"
    },
    {
        type: 14,
        name: "High Floor"
    },
    {
        type: 15,
        name: "City View "
    },
    {
        type: 16,
        name: "Sea View"
    }
]