import React from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_FNB_EVENT_SERVICE } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';

const OBJECT_TYPE = OBJECT_TYPE_FNB_EVENT_SERVICE;

const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                ]
            })}
        </div>
    )
})

export const FnbEventServiceDetailPage = Page;
