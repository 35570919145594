
import "@glideapps/glide-data-grid/dist/index.css";

import {
    DataEditor,
    GridCell,
    GridCellKind,
    GridColumn,
    Item,
    CompactSelection,
    GridColumnIcon
} from "@glideapps/glide-data-grid";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import chunk from 'lodash/chunk'
import range from 'lodash/range'
import { TaskQueue } from "../../../util/TaskQueue";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import Util, { sleep } from "../../../util/Util";
import UserDetailPage from "../../../pages/user/UserDetailPage";
import { useHistory } from "react-router-dom";
import { SideView } from "./side-view";
import useSize, { useRefSize } from "../../../util/useSize";
import { GRID_DARK_THEME, MANAGE_LIST_THEME } from "./themes";
import { useLayer } from "react-laag";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { ArrowsVertical16, ViewOffFilled16, PinFilled16 } from '@carbon/icons-react'
import { useExtraCells } from "@glideapps/glide-data-grid-cells";
import { getReport } from "../../reports/reports";
import { OBJECT_TYPE_PRODUCT } from "../../../constants/ObjectTypes";
import { TreeNodeCell } from "./cells/tree-node-cell";
import { LinkCell } from "./cells/link-cell";
import Portal from "../../../util/Portal";
import { SidePanel } from "../../../templates/draft/components/side-panel";

const HEADER_ICONS = {
    up: p => `<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="${p.bgColor}" fill-rule="evenodd" clip-rule="evenodd" d="M 13.734 5.806 L 10.102 2.21 C 9.958 2.068 9.728 2.073 9.584 2.21 L 5.952 5.806 C 5.809 5.948 5.809 6.178 5.952 6.319 C 6.096 6.462 6.328 6.462 6.471 6.319 L 9.477 3.344 L 9.477 17.25 C 9.477 17.45 9.641 17.613 9.843 17.613 C 10.046 17.613 10.21 17.45 10.21 17.25 L 10.21 3.344 L 13.216 6.319 C 13.359 6.461 13.591 6.461 13.734 6.319 C 13.877 6.177 13.877 5.948 13.734 5.806 Z" />
    </svg>`,
    down: p => `<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="${p.bgColor}" transform="matrix(-1, 0, 0, -1, 19.686001, 19.953367)" fill-rule="evenodd" clip-rule="evenodd" d="M 13.734 5.806 L 10.102 2.21 C 9.958 2.068 9.728 2.073 9.584 2.21 L 5.952 5.806 C 5.809 5.948 5.809 6.178 5.952 6.319 C 6.096 6.462 6.328 6.462 6.471 6.319 L 9.477 3.344 L 9.477 17.25 C 9.477 17.45 9.641 17.613 9.843 17.613 C 10.046 17.613 10.21 17.45 10.21 17.25 L 10.21 3.344 L 13.216 6.319 C 13.359 6.461 13.591 6.461 13.734 6.319 C 13.877 6.177 13.877 5.948 13.734 5.806 Z" />
    </svg>`,
};


function useAsyncData(slowMode, reloadRequest, pageSize, maxConcurrency, getRowData, toCell, gridRef) {
    pageSize = Math.max(pageSize, 1);
    const loadingRef = React.useRef(CompactSelection.empty());
    const dataRef = React.useRef([]);

    const [visiblePages, setVisiblePages] = React.useState({ x: 0, y: 0, width: 0, height: 0 });
    const visiblePagesRef = React.useRef(visiblePages);
    visiblePagesRef.current = visiblePages;

    const onVisibleRegionChanged = React.useCallback(r => {
        setVisiblePages(cv => {
            if (r.x === cv.x && r.y === cv.y && r.width === cv.width && r.height === cv.height) return cv;
            return r;
        });
    }, []);

    useEffect(() => {
        loadingRef.current = CompactSelection.empty();
        dataRef.current = []
    }, [reloadRequest])

    const getCellContent = React.useCallback(
        cell => {
            const [col, row] = cell;
            const rowData = dataRef.current[row];
            if (rowData !== undefined) {
                return toCell(rowData, col);
            }
            return {
                kind: GridCellKind.Loading,
                allowOverlay: false,
            };
        },
        [toCell]
    );

    const loadPage = React.useCallback(
        async (page) => {
            loadingRef.current = loadingRef.current.add(page);
            const startIndex = page * pageSize;
            const d = await getRowData([startIndex, (page + 1) * pageSize, page]);
            if (d === null) {
                loadingRef.current = loadingRef.current.remove(page);
                return;
            }

            const vr = visiblePagesRef.current;

            const damageList = [];
            const data = dataRef.current;
            for (const [i, element] of d.entries()) {
                data[i + startIndex] = element;
                for (let col = vr.x; col <= vr.x + vr.width; col++) {
                    damageList.push({
                        cell: [col, i + startIndex],
                    });
                }
            }
            gridRef.current?.updateCells(damageList);
        },
        [getRowData, gridRef, pageSize]
    );

    const getCellsForSelection = React.useCallback((r) => {
        return async () => {
            const firstPage = Math.max(0, Math.floor(r.y / pageSize));
            const lastPage = Math.floor((r.y + r.height) / pageSize);

            for (const pageChunk of chunk(
                range(firstPage, lastPage + 1).filter(i => !loadingRef.current.hasIndex(i)),
                maxConcurrency
            )) {
                await Promise.allSettled(pageChunk.map(loadPage));
            }

            const result = [];

            for (let y = r.y; y < r.y + r.height; y++) {
                const row = [];
                for (let x = r.x; x < r.x + r.width; x++) {
                    row.push(getCellContent([x, y]));
                }
                result.push(row);
            }

            return result;
        };
    },
        [getCellContent, loadPage, maxConcurrency, pageSize]
    );

    React.useEffect(() => {
        const r = visiblePages;
        const firstPage = Math.max(0, Math.floor((r.y - pageSize / 2) / pageSize));
        const lastPage = Math.floor((r.y + r.height + pageSize / 2) / pageSize);
        for (const page of range(firstPage, lastPage + 1)) {
            if (loadingRef.current.hasIndex(page)) continue;
            void loadPage(page);
        }
    }, [loadPage, pageSize, visiblePages]);

    // const firstTimeRef = useRef(true)
    // useEffect(() => {
    //     if (firstTimeRef.current) {
    //         firstTimeRef.current = false;
    //         return;
    //     }
    //     dataRef.current = []
    //     loadingRef.current = CompactSelection.empty()
    // }, [reloadRequest])

    return {
        tableProps: {
            getCellContent,
            onVisibleRegionChanged,
            getCellsForSelection,
        },
        funcs: {
            getRowData: row => dataRef.current[row],
            getRowCount: () => dataRef.current.length,
        }
    };
}

function typeToCellKind(type, actionType) {
    if (actionType) {
        return GridCellKind.Custom;
    }

    switch (type) {
        case "TEXT": case "MONEY": case "DATE": case "DATE_TIME": return GridCellKind.Text;
        case "NUMBER": return GridCellKind.Number;
        case "LIST": return GridCellKind.Bubble;
        case "IMAGE": return GridCellKind.Image;
        case "TREE_NODE": case "LINK": return GridCellKind.Custom;
        default: return GridCellKind.Text;
    }
}

//duplicated
function formatTypeValue(type, value) {
    switch (type) {
        case "TEXT": return (value ?? '') + '';
        case "DATE": return Util.getDateOnly(value);
        case "DATE_TIME": return Util.getFullDate(value);
        case "MONEY": return (value !== null && value !== undefined) ? (
            value?.startsWith?.('@NaNOvr: ') ? value.substring(9) : 'AED ' + Util.formatMoney(value)
        ) : '';
        case "NUMBER": return (value !== null && value !== undefined) ? Util.formatAmount(value) : '';
        // case "NUMBER": return (value !== null && value !== undefined) ? value.toFixed(2) : '';
        case "LIST": return value ?? [];
        case "IMAGE": return [Api.getThumbnail(OBJECT_TYPE_PRODUCT, value)];
        default: return (value ?? '') + '';
    }
}

function useLoadData(reloadRequest, reportName, columns, reportRequest, slowMode, actions, preloadedAllData, loadTreeOnly, noMoreItemsCallback) {
    const [preloadedDataReload, setPreloadedDataReload] = useState(() => Util.newTempId())

    const history = useHistory();
    const ref = React.useRef(null);
    const taskQueue = useMemo(() => new TaskQueue(slowMode ? 1 : 5), [])
    const report = useMemo(() => getReport(reportName), [reportName])

    const totalCount = useRef(0);


    useEffect(() => {
        setPreloadedDataReload(Util.newTempId())
    }, [preloadedAllData])

    const getRowData = React.useCallback(async (r) => {
        if (preloadedAllData) {
            return preloadedAllData;
        } else if (loadTreeOnly) {
            return null;
        }

        const taskResult = await taskQueue.runTask(async () => {
            const page = r[2]

            if (page > 0 && slowMode) {
                await sleep(150);
            }

            return await new Promise((resolve, reject) => {
                // console.log("CALLED twice", { page })
                return (
                    Api.getReportResult(reportName, {
                        page: page,
                        pageSize: report.isTreeSructure ? 50000 : 50,
                        ...reportRequest
                    }, response => {

                        if (response.status === true) {
                            resolve(response.payload)

                            // if (!response.payload?.hasMore) {
                            //     try {
                            //         noMoreItemsCallback(asyncData.funcs.getRowCount());
                            //     } catch (e) {
                            //         console.error(e);
                            //     }
                            // }
                        } else {
                            UIUtil.showError()
                            reject(response.message)
                        }
                    })
                )
            })
        })

        return taskResult.success ? taskResult.result.data : null;
        //return taskResult.result.data.map(item => columns.map(col => item[col.id] + ''))
    }, [preloadedAllData]);

    const toCell = React.useCallback(
        (rowData, colIndex) => {
            const col = columns[colIndex];
            if (!col || actions.isRowIndexHidden?.(rowData?.rowIndex)) {
                return { kind: GridCellKind.Text, data: '', allowOverlay: false, displayData: '', }
            }

            if (rowData.depthCount > 0 && col.type !== "TREE_NODE" && !actions.isRowCollapsed(rowData)) {
                return { kind: GridCellKind.Text, data: '', allowOverlay: false, displayData: '', }
            }

            if (col.selectionModeCheckbox) {
                return {
                    kind: GridCellKind.Boolean,
                    data: col.isRowSelected(rowData.id),
                    allowOverlay: false,
                    style: "normal",
                    rowId: rowData.id,
                }
            }

            if (col.button) {
                return {
                    kind: GridCellKind.Custom,
                    cursor: "pointer",
                    allowOverlay: true,
                    readonly: true,
                    data: {
                        kind: "button-cell",
                        backgroundColor: ["transparent", "#0f62fe"],
                        color: ["#0f62fe", "accentFg"],
                        //borderColor: "#6572ffa0",
                        borderColor: "#6572ff00",
                        borderRadius: 5,
                        title: "→",
                        onClick: () => {
                            if (report.openItemInSideView) {
                                actions.openItemInSideView(rowData.id)
                            } else if (report.openInSameTab) {
                                history.push(report.itemToOpenLink(rowData))
                            } else {
                                window.open(report.itemToOpenLink(rowData), "_blank")
                            }
                        },
                    },
                    themeOverride: {
                        baseFontStyle: "700 12px",
                        cellVerticalPadding: 7,
                        cellHorizontalPadding: 7
                    },
                }
            }

            const hasAction = col.actionType === "BUTTON" || col.actionType === "LINK";
            const value = rowData[col.id];

            const mapColToData = () => {
                if (col.type === "TREE_NODE") {
                    return {
                        kind: "tree-node-cell",
                        value: value ?? '',
                        hasChildren: rowData.depthCount > 0,
                        childrenCount: rowData.depthCount,
                        allowOverlay: false,
                        depth: rowData.depth,

                        collapsed: actions.isRowCollapsed(rowData),
                        toggleCollapse: () => actions.toggleCollapse(rowData)
                    }
                } if (col.actionType === "BUTTON") {
                    return {
                        kind: "button-cell",
                        // backgroundColor: ["transparent", "#0f62fe"],
                        // color: ["#0f62fe", "accentFg"],
                        // borderColor: "#6572ff00",

                        backgroundColor: ["#0f62fe", "#0353e9"],
                        color: ["white", "white"],
                        borderColor: "transparent",

                        borderRadius: 5,
                        title: formatTypeValue(col.type, value),
                        onClick: () => report?.onAction?.(col.id, rowData, history),
                    }
                } if (col.actionType === "LINK") {
                    return {
                        kind: "link-cell",
                        value: formatTypeValue(col.type, value),
                        onClick: () => report?.onAction?.(col.id, rowData, history),
                    }
                } else {
                    return col.type === "LIST" ? (value?.split("|") ?? []) : formatTypeValue(col.type, value)
                }
            }

            // if (col.type === "TREE_NODE") {
            //     return {
            //         kind: GridCellKind.Text,
            //         data: value ?? '',
            //         displayData: value ?? '',
            //         contentAlign: "left",
            //         readonly: true,
            //         cursor: 'pointer',

            //     }
            // }

            return {
                kind: typeToCellKind(col.type, col.actionType),
                data: mapColToData(),
                cursor: (col.type === "TREE_NODE" || hasAction) ? 'pointer' : 'default',
                allowOverlay: col.type !== "TREE_NODE" && !hasAction,
                displayData: (col.type === "LIST" || col.type === "IMAGE") ? undefined : (formatTypeValue(col.type, value)),
                contentAlign: col.type === "IMAGE" ? "center" : (col.type === "MONEY" || col.type === "NUMBER") ? "right" : "left",
                readonly: true,

                themeOverride: (col.type === "BUTTON") ? {
                    baseFontStyle: "700 12px",
                    cellVerticalPadding: 4,
                    cellHorizontalPadding: 4
                } : undefined,
            }
            // if (value) {
            //     return {
            //         kind: typeToCellKind(col.type),
            //         data: col.type === "LIST" ? value.split("|") : formatTypeValue(col.type, value),
            //         allowOverlay: true,
            //         displayData: col.type === "LIST" ? undefined : formatTypeValue(col.type, value),
            //         contentAlign: (col.type === "MONEY" || col.type === "NUMBER") ? "right" : "left",
            //         readonly: true,
            //     }
            // } else {
            //     return { kind: GridCellKind.Text, data: '', allowOverlay: false, displayData: '', }
            // }
        },
        [columns, actions]
    )

    const asyncData = useAsyncData(
        slowMode,
        // reloadRequest,
        report.loadTreeOnly ? preloadedDataReload : undefined,
        report.isTreeSructure ? 50000 : 50,
        (slowMode || report.isTreeSructure) ? 1 : 5,
        getRowData,
        toCell,
        ref
    );

    const afterCollapsedData = useMemo(() => {
        if (!preloadedAllData) {
            return []
        }
        // const collapsedRows = actions.collapsedRows ?? [];
        // const collapsedRanges = collapsedRows.map($ => [$.rowIndex, $.rowIndex + $.depthCount])
        return preloadedAllData.filter(rowData => !actions.isRowIndexHidden?.(rowData?.rowIndex))
    }, [preloadedAllData, actions.collapsedRows])

    if (preloadedAllData) {
        return [ref, {
            getCellContent: ([col, row]) => {
                const rowData = afterCollapsedData[row];
                if (rowData !== undefined) {
                    return toCell(rowData, col);
                }
                return {
                    kind: GridCellKind.Loading,
                    allowOverlay: false,
                };
            },
        }, {
                getRowData: row => preloadedAllData[row],
            }];
    } else {
        return [ref, asyncData.tableProps, asyncData.funcs];
    }
}

const ColumnMenu = ({ layerProps, onToggleSort, onHideColumn, onClose }) => {
    const ref = useClickOutside(onClose)
    return (
        <div
            {...layerProps}
            className="report-engine-column-menu"
            style={{
                ...layerProps.style,
                transformOrigin: 'top left',
                animation: 'report-engine-sort-menu-fade-in 250ms',
                boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)',

                margin: '0.25rem',

                width: 175,
                borderRadius: 10,
                overflow: 'hidden',
                backgroundColor: "white",
                border: "1px solid #00000020",
            }}>
            <ul ref={ref}>
                <li onClick={() => {
                    onToggleSort();
                    onClose();
                }}><span style={{ flex: 1 }}>Toggle Sort</span> <ArrowsVertical16 /></li>
                {/* <li onClick={() => {
                    // onToggleSort();
                    onClose();
                }}><span style={{ flex: 1 }}>Pin to Left</span> <PinFilled16 /></li> */}
                <li onClick={() => {
                    onHideColumn();
                    onClose();
                }} style={{ color: '#990000' }}><span style={{ flex: 1 }}>Hide Column</span> <ViewOffFilled16 /></li>
            </ul>
        </div>
    )
}

const useColumnMenu = ({ onToggleSort, onHideColumn }, columns) => {
    const [showMenu, setShowMenu] = React.useState();
    const onHeaderMenuClick = React.useCallback((col, bounds) => setShowMenu(prev => prev?.col === col ? undefined : { col, bounds }), []);

    const { renderLayer, layerProps } = useLayer({
        isOpen: showMenu !== undefined,
        trigger: {
            getBounds: () => ({
                bottom: (showMenu?.bounds.y ?? 0) + (showMenu?.bounds.height ?? 0),
                height: showMenu?.bounds.height ?? 0,
                left: showMenu?.bounds.x ?? 0,
                right: (showMenu?.bounds.x ?? 0) + (showMenu?.bounds.width ?? 0),
                top: showMenu?.bounds.y ?? 0,
                width: showMenu?.bounds.width ?? 0,
            }),
        },
        placement: "bottom-start",
        auto: true,
        possiblePlacements: ["bottom-start", "bottom-end"],
    });

    // const SortMenu = useCallback(() => , [renderLayer, layerProps])
    return {
        showMenu,
        onHeaderMenuClick,
        menuView: (<>
            {showMenu !== undefined ?
                renderLayer(
                    columns[showMenu.col]?.type === "TREE_NODE" ? (
                        <div {...layerProps} />
                    ) : (
                        <ColumnMenu key={showMenu.col}
                            layerProps={layerProps} onClose={() => setShowMenu(undefined)}
                            onToggleSort={() => onToggleSort(showMenu.col)}
                            onHideColumn={() => onHideColumn(showMenu.col)}
                        />
                    )) : null}
        </>)
    }
}

const useMappedColumns = (columns, isListMode, selectionMode, aggs, isTreeStructure, isManageMode) => {
    const trailingRowOptions = col => {
        if (!isTreeStructure && aggs?.[col.id]) {
            return {
                hint: "SUM: " + Util.formatMoney(aggs?.[col.id]),
                addIcon: GridColumnIcon.HeaderMath,
                disabled: false,
            }
        } else {
            return {
                disabled: true
            }
        }
    }
    return useMemo(() => {
        if (isListMode && !selectionMode && !isManageMode) {
            return [
                ...columns.map(col => ({
                    ...col,
                    grow: true,
                    trailingRowOptions: trailingRowOptions(col)
                })),
                {
                    button: true,
                    id: "action-btn",
                    title: "",
                    width: 50,
                    trailingRowOptions: {
                        disabled: true
                    }
                },
            ]
        } else if (isManageMode) {
            return [
                ...columns.map(col => ({
                    ...col,
                    grow: true,
                    trailingRowOptions: trailingRowOptions(col)
                })),

            ]
        } else if (selectionMode) {
            return [
                {
                    id: "check-box",
                    kind: GridCellKind.Boolean,
                    width: 50,
                    title: "",
                    selectionModeCheckbox: true,
                    isRowSelected: rowId => selectionMode.isSelected(rowId),
                    trailingRowOptions: {
                        disabled: true
                    }
                },
                ...columns.map(col => ({
                    ...col,
                    grow: true,
                    trailingRowOptions: trailingRowOptions(col)
                })),
            ]
        } else {
            return columns.map(col => ({
                ...col,
                trailingRowOptions: trailingRowOptions(col)
            }));
        }
    }, [columns, isListMode, aggs, isTreeStructure])
}

const useHandleRows = (isTreeStructure, totalRowCount) => {
    const [collapsedRows, setCollapsedRows] = useState([]);

    // useEffect(() => {
    //     // window.scrollBy(50, 0)
    // }, [collapsedRows])

    return useMemo(() => {
        if (isTreeStructure) {
            // const totalRowCollapsed = collapsedRows.map($ => $.depthCount).reduce((t, v) => t + v, 0)
            //const totalCollapsed = collapsedRows.map($ => $.depthCount).reduce((t, v) => t + v, 0);

            const isRowIndexHidden = index => {
                const collapsedRanges = collapsedRows.map($ => [$.rowIndex, $.rowIndex + $.depthCount])
                return collapsedRanges.find(([start, end]) => index > start && index <= end) !== undefined
            }

            const newCount = Array(totalRowCount).fill(undefined).filter((_, index) => !isRowIndexHidden(index)).length;

            return {
                rowCount: newCount,
                collapsedRows,

                isRowIndexHidden,


                isRowCollapsed: (row) => collapsedRows.find($ => $.rowIndex === row.rowIndex) !== undefined,
                toggleCollapse: (row) => setCollapsedRows(prev => {
                    if (prev.find($ => $.rowIndex === row.rowIndex)) {
                        return prev.filter($ => $.rowIndex !== row.rowIndex)
                    } else {
                        return [...prev, row]
                    }
                })
            }
        } else {
            return {
                rowCount: totalRowCount
            }
        }
    }, [isTreeStructure, totalRowCount, collapsedRows])
}

const useHighlightRow = (cols) => {
    const [hoverRow, setHoverRow] = useState(undefined);

    const onItemHovered = useCallback((args) => {
        const [_, row] = args.location;
        setHoverRow(args.kind !== "cell" ? undefined : row);
    }, []);

    const highlightedRegions = useMemo(() => {
        if (hoverRow === undefined) return undefined;

        return [
            {
                color: "#e3e3e3",
                range: {
                    x: -1,
                    y: hoverRow,
                    width: cols.length + 1,
                    height: 1,
                },
                style: "no-outline",
            },
        ];
    }, [cols.length, hoverRow]);

    return { onItemHovered, highlightedRegions }
}


export const ReportTable = ({
    slowMode, reloadRequest, reportName, reportRequest, allColumns, columns, rowCount: totalRowCount,
    showDarkMode, onColumnClick, onColumnMoved, onColumnResize, setColumnVisibility, isListMode, isManageMode, selectionMode, isTreeStructure,
    endpoint, state, aggs, preloadedAllData,
}) => {
    const history = useHistory();
    const implicitOpenItemId = history?.location?.state?.__open_item_id;
    const [showItemId, setShowItemId] = useState(() => state?.get("openNewPanelInitiallyItemId") ?? implicitOpenItemId);

    const { rowCount, isRowCollapsed, toggleCollapse, isRowIndexHidden, collapsedRows } = useHandleRows(isTreeStructure, totalRowCount);
    const [actualCount, setActualCount] = useState(null);

    const report = useMemo(() => getReport(reportName), [reportName])

    const mappedColumns = useMappedColumns(columns, isListMode, selectionMode, aggs, isTreeStructure, isManageMode);

    const [ref, args, funcs] = useLoadData(reloadRequest, reportName, mappedColumns, reportRequest, slowMode, {
        isRowCollapsed, toggleCollapse, isRowIndexHidden, collapsedRows,
        openItemInSideView: id => setShowItemId(id)
    }, preloadedAllData, report.loadTreeOnly, c => {
        // noMoreItemsCallback
        setActualCount(c)
    });
    const [selection, setSelection] = useState();
    const noSelection = useMemo(() => ({
        current: undefined,
        rows: CompactSelection.empty(),
        columns: CompactSelection.empty(),
    }), [])
    const [rootRef, rootSize] = useRefSize();

    const selectedRows = useMemo(() => selection?.rows.toArray() ?? [], [selection])

    const columnMenu = useColumnMenu({
        onHideColumn: (col) => setColumnVisibility(columns[col]),
        onToggleSort: (col) => onColumnClick(columns[col])
    }, columns);

    const rowHighlighter = useHighlightRow(mappedColumns ?? columns);

    const cellProps = useExtraCells();

    const [meltFreeze, setMeltFreeze] = useState(false);
    useEffect(() => {
        setMeltFreeze(true)
        const timeout = setTimeout(() => {
            setMeltFreeze(false);
        }, 50)
        return () => clearTimeout(timeout);
    }, [reportRequest, preloadedAllData])


    const onItemUpdate = () => {

    }

    const onItemDelete = () => {

    }
    return (
        <div ref={rootRef} style={{ width: '100%', height: '100%' }}>
            <DataEditor
                // key={preloadedAllData}
                ref={ref} {...args} headerIcons={HEADER_ICONS}
                width="100%" height="100%" rowMarkers={(selectionMode || isManageMode) ? "none" : "both"}
                smoothScrollX
                // onHeaderMenuClick={sort.onHeaderMenuClick}
                // onHeaderMenuClick={(col) => onColumnClick(columns[col])}

                //{...cellProps}
                customRenderers={[
                    ...cellProps.customRenderers,
                    TreeNodeCell, LinkCell
                ]}

                onCellEdited={selectionMode ? (([colIndex, rowIndex], val) => {
                    if (mappedColumns[colIndex]?.selectionModeCheckbox && val.rowId) {
                        if (val.data) {
                            selectionMode.select(val.rowId)
                        } else {
                            selectionMode.unselect(val.rowId)
                        }
                    }
                }) : undefined}

                onHeaderMenuClick={columnMenu.onHeaderMenuClick}
                onHeaderClicked={(col) => {
                    if (!mappedColumns[col]?.button && !mappedColumns[col]?.selectionModeCheckbox && mappedColumns[col]?.type !== "TREE_NODE") {
                        onColumnClick(columns[col])
                    }
                }}
                onColumnMoved={(from, to) => {
                    if (!mappedColumns[from]?.button && !mappedColumns[to]?.button) {
                        if (!mappedColumns[from]?.selectionModeCheckbox && !mappedColumns[to]?.buttselectionModeCheckboxon) {
                            if (columns[from] && columns[to]) {
                                onColumnMoved(columns[from], columns[to])
                            }
                        }
                    }
                }}
                onColumnResize={onColumnResize}




                columns={mappedColumns} rows={actualCount ?? rowCount}
                freezeColumns={meltFreeze ? 0 : (isTreeStructure ? 1 : report?.freezeCol)}
                theme={showDarkMode ? GRID_DARK_THEME : isManageMode ? MANAGE_LIST_THEME : undefined}


                gridSelection={isManageMode ? noSelection : selection}
                onGridSelectionChange={value => {
                    setSelection(value)
                }}

                {...(selectedRows.length > 0 && selectedRows.length < 10 ? {
                    rightElementProps: { sticky: true, fill: false },
                    rightElement: (
                        <SideView
                            width={
                                report?.xlSideView
                                    ? (rootSize ? rootSize.width / 1.25 : (0.80 * window.innerWidth))
                                    : (rootSize ? rootSize.width / 3 : 300)
                            }
                            rows={selectedRows} getRowData={funcs.getRowData}
                            allColumns={allColumns} reportName={reportName}
                            darkMode={showDarkMode}
                        />
                    )
                } : {})}

                {...(isListMode ? {
                    getRowThemeOverride: row => {
                        if (row % 2 === 1) {
                            return {
                                bgCell: '#f7f7f7',
                            }
                        }

                        return undefined;
                    },
                    //rowHeight: 45,
                    rowHeight: report.rowHeight ?? 45,
                    smoothScrollY: true
                } : {
                    rowHeight: index => {
                        if (isTreeStructure) {
                            //return isRowIndexHidden(index) ? 0 : 34;
                            const hasImage = columns?.find($ => $.type === "IMAGE") !== undefined;
                            return hasImage ? 50 : 34;
                        } else {
                            const hasImage = columns?.find($ => $.type === "IMAGE") !== undefined;
                            return hasImage ? 50 : 34;
                        }
                    }
                })}

                {...(isManageMode ? {
                    highlightRegions: rowHighlighter.highlightedRegions,
                    onItemHovered: rowHighlighter.onItemHovered,
                    onCellClicked: ([col, row]) => {
                        const rowData = funcs.getRowData(row);
                        if (rowData) {
                            setShowItemId(rowData.id)
                        }
                    }
                } : {})}

                trailingRowOptions={isTreeStructure ? {} : {
                    tint: true,
                    sticky: true,
                }}
                onRowAppended={() => { }}
            />

            {columnMenu.menuView}

            {showItemId && <Portal>
                <SidePanel md2={!report.sideViews.fullScreenUpdateForm} lg={report.sideViews.fullScreenUpdateForm} backBtn onClose={() => setShowItemId(undefined)}>
                    <report.sideViews.updateForm.Component {...{ reportName, onItemUpdate, onItemDelete, endpoint, itemId: showItemId, onClose: () => setShowItemId(undefined) }} />
                </SidePanel>
            </Portal>}
        </div>
    )
};

