import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util, { isV2 } from "../../../util/Util";
import { getReportLink } from "../../base/report-page";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange, EndDateOnly, toEpochUsingDate } from "../../components/basic-filter/date-range";
import { startOfDay, addDays } from 'date-fns';
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"

export const openAccountLedger = (from, history, accountId, accountName, start, end, skipBalanceForward) => {
    const dateFilters = [];
    if (Util.isNumberExist(start)) {
        dateFilters.push(
            { "property": "date", "operator": "GTE", "id": "date-start", "value": start },
        )
    } else {
        dateFilters.push(
            { "property": "date", "operator": "GTE", "id": "date-start", "value": startOfDay(new Date()).getTime() },
        )
    }
    if (Util.isNumberExist(end)) {
        dateFilters.push(
            { "property": "date", "operator": "LT", "id": "date-end", "value": end }
        )
    } else {
        dateFilters.push(
            { "property": "date", "operator": "LT", "id": "date-end", "value": addDays(startOfDay(new Date()), 1).getTime() }
        )
    }

    if (accountId === -9999 || accountId === '-9999' || accountId === "netincome") {
        const state = {
            filters: dateFilters.map($ => ({ ...$, skipAutoFilter: true })),
        }
        history.push(getReportLink("ProfitLoss", state))
    } else {
        const state = {
            filters: [
                { "property": "ledgerId", "operator": "EQ", "id": "ledgerId", "value": accountId, skipAutoFilter: true },
                ...(skipBalanceForward ? [{ "id": "skipBalanceForward", "value": true, skipAutoFilter: true }] : []),
                ...dateFilters
            ],
            __fromReport: {
                link: getReportLink(from, { filters: dateFilters.map($ => ({ ...$, skipAutoFilter: true })) })
            },
            __ledgerFilterOverride: {
                accountId, accountName,
            }
        }
        history.push(getReportLink("V2LedgerReport", state));
    }
}

export const BalanceSheet = {
    filter: Filter,
    isTreeSructure: true,
    loadTreeOnly: true,

    customPath: !isV2() ? "/balance-sheet" : undefined,

    onAction: (action, data, history) => {
        switch (action) {
            case "balance":
                //openAccountLedger("BalanceSheet", history, data.accountId, data.recordGroup?.replace(" Total", ""), data._startDate, data._endDate)
                openAccountLedger("BalanceSheet", history, data.acc_id, data.route?.replace(" Total", ""), data._startDate, data._endDate)
                return;
        }
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const SHOW_ZERO_BALANCE_FILTER = {
    skipAutoFilter: true,
    value: true
}

function Filter({ endpoint, state }) {
    return (<>
        {/* remember above for skipauot */}
        <EndDateOnly state={state} property="date" skipAutoFilter />
        <CheckboxFilter state={state} filterId="showZeroBalance" label="Show 0 balance" filter={SHOW_ZERO_BALANCE_FILTER} />

        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%' }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
