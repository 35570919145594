import { Tag } from "carbon-components-react";
import { OBJECT_TYPE_ACTIVITY, OBJECT_TYPE_COURSE, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_LMS_GROUP, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR } from "../../../constants/ObjectTypes";
import { useMemo } from "react";
import { isMobileScreen } from "../../../App";

const TAGS = {
    [OBJECT_TYPE_LMS_GROUP]: ["name", "email", "phone-number"],
    [OBJECT_TYPE_COURSE]: ["student-name", "course-name", "enrolment-date", "upcoming-class-count"],
    [OBJECT_TYPE_ACTIVITY]: ["student-name", "course-name", "package-type", "session-type", "date", "duration", "tutor-name"],
    [OBJECT_TYPE_TUTOR]: ["name", "email", "phone-number", "tutoring-courses"],
    [OBJECT_TYPE_STUDENT]: ["name", "email", "phone-number", "date-of-birth", "year-level", "school", "outstanding-balance"],
    [OBJECT_TYPE_GUARDIAN]: ["name", "email", "phone-number", "mobile-number", "relationship"], //if you add outstanding balance here, add it to group as well
}

export function EmailTags({ type, onTagClicked }) {
    const tags = useMemo(() => TAGS[type] ?? [], [type]);

    if (!tags.length) {
        return <p style={{ fontSize: 14, opacity: 0.65 }}>No tags available</p>
    }

    return (<>
        <p style={{ fontSize: 12, paddingInline: '1rem' }}>{isMobileScreen ? 'tap' : 'click'} to add</p>
        {tags.map(tag => <Tag key={tag} type="high-contrast" onClick={() => onTagClicked(tag)}>{tag}</Tag>)}
    </>)
}