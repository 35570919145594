import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util, { isV2 } from "../../../util/Util"
import { getReportLink } from "../../base/report-page"
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { DateRange, EndDateOnly } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select"

export const SupplierAgingSummary = {
    filter: Filter,
    freezeCol: 1,

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        {/* <EndDateOnly state={state} property="date" />
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Customer" key="customerField" fieldKey="customerField" options={endpoint.customers.map(item => item.value)} state={state} property="customerName" />
        </div>
        <div style={{ height: 30, marginTop: '0rem', flex: 2 }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div> */}
    </>)
}
