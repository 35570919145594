import { PlaneSea32, Menu16, Code16, Fork16, DocumentAttachment16, Template16, Categories16, Folders16, Corner16, Archive16 } from '@carbon/icons-react'
import { Tab, Tabs, Tag } from 'carbon-components-react';
import { useHistory } from 'react-router-dom';
import { Menu } from './menu';

// import { Menu } from './menu';

function MenuButton({ label, active, onClick, fullWidth, children }) {
    return <button style={{ width: fullWidth ? 30 : 100, paddingInline: fullWidth ? 0 : undefined }} onClick={onClick} data-active={active} className='shipment-item-menu-button'>{children}</button>
}


export function Toolbar({ tabIndex, setTabIndex, sideView, setSideView, onSidebarBtn }) {

    return (
        <div className='lead-flow-tabs hidden-tab-content' style={{ paddingInline: '1rem', height: '3rem', width: '100%', display: 'flex', alignItems: 'center', background: '#212121', color: 'white', borderBottom: '1px solid #00000060' }}>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                {(tabIndex === 0 || tabIndex === 2) && <button onClick={onSidebarBtn} className='lead-flow-icon-button lead-flow-icon-button-light'>
                    <Menu16 />
                </button>}

                <Tag renderIcon={Code16} style={{ marginRight: "1rem", marginLeft: '0.25rem' }} type="high-contrast">
                    Robotic IDE
                </Tag>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                    <MenuButton active={tabIndex === 0} onClick={() => setTabIndex(0)}><Template16 /> Templates</MenuButton>
                    <MenuButton active={tabIndex === 1} onClick={() => setTabIndex(1)}><Folders16 /> Resources</MenuButton>
                    <MenuButton active={tabIndex === 2} onClick={() => setTabIndex(2)}><Corner16 /> Fragments</MenuButton>
                    <MenuButton active={tabIndex === 3} onClick={() => setTabIndex(3)}><Archive16 /> Constants</MenuButton>
                </div>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {tabIndex === 0 && <Menu sideView={sideView} setSideView={setSideView} />}
            </div>
        </div>
    )
}