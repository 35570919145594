

import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY, ONLINE_STORE_URL } from "../app/Config";
import Util from "../util/Util";

const FRAME_TIMEOUT = 5000;

function funcInvokeEvent(func, payload) {
    const callId = Util.newTempId();
    return {
        type: "func-invoke",
        auth: ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY,
        callId,
        func,
        payload,
    };
}

function funcResultEvent(callId, payload) {
    return {
        type: "func-result",
        auth: ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY,
        callId,
        payload,
    };
}

export function useFrameConnection(
    impl
) {
    const frameRef = useRef();
    const listeners = useRef(new Map());

    const proxy = useMemo(
        () =>
            new Proxy(
                {},
                {
                    get(target, p, receiver) {
                        return (payload) => {
                            return new Promise((resolve, reject) => {
                                const timeout = setTimeout(() => {
                                    reject("Timeout error");
                                }, FRAME_TIMEOUT);
                                const event = funcInvokeEvent(
                                    p,
                                    payload
                                );
                                listeners.current.set(
                                    event.callId,
                                    (result) => {
                                        resolve(result);
                                        clearTimeout(timeout);
                                    }
                                );
                                frameRef.current.contentWindow.postMessage(
                                    JSON.stringify(event),
                                    ONLINE_STORE_URL
                                );
                            });
                        };
                    },
                }
            ),
        []
    );

    useEffect(() => {
        const onMessage = async (reqString) => {
            const req = JSON.parse(reqString);
            switch (req?.type) {
                case "func-result":
                    listeners.current.get(req.callId)?.(req.payload);
                    listeners.current.delete(req.callId);
                    break;
                case "func-invoke":
                    if (impl[req.func]) {
                        const result = await impl[req.func]?.(req.payload);
                        frameRef.current.contentWindow.postMessage(
                            JSON.stringify(funcResultEvent(req.callId, result)),
                            ONLINE_STORE_URL
                        );
                    }
                    break;
            }
        };

        const listener = (e) => {
            if (e.origin === ONLINE_STORE_URL) {
                onMessage(e.data);
            }
        };
        window.addEventListener("message", listener);
        return () => window.removeEventListener("message", listener);
    }, [frameRef, impl]);

    useLayoutEffect(() => {
        const listener = () => proxy.connect();
        frameRef.current?.addEventListener("load", listener)
        return () => frameRef.current?.removeEventListener("load", listener)
    }, [frameRef, proxy])

    return [frameRef, proxy];
}
