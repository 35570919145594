import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { PlaneSea32, ArrowsVertical16, DataShare32, Download16, Upload16, ChartRelationship32, ListBoxes } from '@carbon/icons-react'
import { ComposedModal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { useStringValueFilter } from "../../engine/useValueFilter";
import { FundTransferFormFields } from "../../../pages/fund-transfer/fund-transfer-fields";

export const FundTransferList = {
    filter: Filter,
    icon: DataShare32,

    customPath: "/fund-transfer",
    newDialog: NewDialog,

    itemToOpenLink: item => `/fund-transfer/${item.id}`,
    isListMode: true,

    isAvailable: () => {
        const role = getAccountRole();
        return (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT);
    }
}

function NewDialog({ visible, onClose, onSuccess, endpoint }) {
    return (
        <FormDialog
            setItem={item => onSuccess(item)} label="Fund Transfer" title="Create New"
            saveApi={(data, listener) => Api.createFundTransfer(data, listener)}
            open={visible} onClose={onClose} item={{
                date: 0,
                amount: "",
                fromCashAccountId: 0,
                toCashAccountId: 0,
            }} payload={endpoint}>
            {FundTransferFormFields}
        </FormDialog>
    )
}


function Filter({ endpoint, state }) {
    return (<>
        <div style={{ flex: 1 }}>
            <TextBox state={state} label="Voucher No" property="id" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="From" property="fromAcc" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="To" property="toAcc" fullWidth />
        </div>
        <DateRange2 state={state} property="date" />
    </>)
}
