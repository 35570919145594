import { useEffect, useMemo, useState } from "react";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import usePosSession from "../state/usePosSession";
import useStore from "../../../hooks/useStore";
import { ButtonSet, ComboBox, ComposedModal, Link, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from "carbon-components-react";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import Button from "../../../components/Button";
import { Save16, ErrorFilled16 } from '@carbon/icons-react'
import Util from "../../../util/Util";
import DatePicker from "../../../components/DatePicker";


const DetailField = ({ title, value }) => (<>
    <label className="bx--label">{title}</label>
    <p style={{ marginTop: '-0.75rem', whiteSpace: 'pre-wrap' }}>{value}</p>
    <div style={{ height: '0.25remrem' }} />
</>)



export function InventoryReadyDate({ sessionStore, editable }) {
    const session = usePosSession(sessionStore)
    const [date] = useStore(sessionStore, "inventoryReadyDate")

    if (!editable) {
        return (
            <div>
                <DetailField title={"Inventory Ready Date"} value={Util.isNumberExist(date) ? Util.getDateOnly(date) : '-'} />
            </div>
        )
    }

    return (
        <TextInput light labelText="Inventory Ready Date" type={"date"} value={Util.isNumberExist(date) ? Util.formatDate(date, "YYYY-MM-DD") : ''} onChange={e => session.setInventoryReadyDate(new Date(e.target.value).getTime())} />
        // <DatePicker key={date} defaultDate={date} onDateChanged={update => {
        //     console.log({ date, update })
        //     session.setInventoryReadyDate(update)
        // }} />
    )
}