import React from 'react';
import { withRouter } from 'react-router-dom';
import { OBJECT_TYPE_REAL_ESTATE_PROPERTY } from '../../../constants/ObjectTypes';
import Page from '../../../base/Page';
import TableTemplate from '../../../templates/TableTemplate';
import { getAccountRole } from '../../../session/SessionManager';
import { ACCOUNT_TYPE_REAL_ESTATE_AGENT, REAL_ESTATE_PROPERTY_CUSTOM_LIST } from '../../../constants/Constants';

class PropertyListPage extends Page {

    isAgent = getAccountRole() == ACCOUNT_TYPE_REAL_ESTATE_AGENT;

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Properties",
                    subTitle: this.isAgent ? "My properties" : "All properties",
                    objectType: OBJECT_TYPE_REAL_ESTATE_PROPERTY,
                    pagePath: "/real-estate-property/",
                    history: this.props.history,
                    paginated: false,

                    hideCreateButton: this.isAgent,

                    getCustomListRequest: this.isAgent ? {
                        requestType: REAL_ESTATE_PROPERTY_CUSTOM_LIST.MINE_AS_AGENT,
                    } : undefined
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(PropertyListPage);