
import {
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    BuildingInsights_132,
    ChartBullet32, Document32, Migrate32,

    Certificate32,
    GraphicalDataFlow32,
    DocumentSentiment32,
} from '@carbon/icons-react'
import Api from '../session/Api';
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_SUPPLIER, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_VENUE, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR, TRANSACTION_PARTY_TYPE_STAFF, TRANSACTION_PARTY_TYPE_STORE, TRANSACTION_PARTY_TYPE_STUDENT, TRANSACTION_PARTY_TYPE_SUPPLIER, TRANSACTION_PARTY_TYPE_TUTOR, TRANSACTION_PARTY_TYPE_V2_CUSTOMER, TRANSACTION_PARTY_TYPE_V2_VENDOR, TRANSACTION_PARTY_TYPE_VENDOR, TRANSACTION_PARTY_TYPE_VENUE } from '../constants/Constants';
import { BILL_STATUS_UNPAID_OR_PARTIALLY_PAID } from './bill';
import { PAYOUT_STATUS_UNPAID_OR_PARTIALLY_PAID } from './payout'
import { SERVICE_SALE_STATUS_UNPAID_OR_PARTIALLY_PAID } from './service-sale';
import { COMMISSION_STATUS_UNPAID_OR_PARTIALLY_PAID } from './commission'
import { SALE_STATUS_UNPAID_OR_PARTIALLY_PAID } from './sale';
import { INVOICE_DOCUMENT_STATUS_NOT_PAID } from './invoice-document';
import Util, { isV2 } from '../util/Util';
import { PURCHASE_STATUS_UNPAID_OR_PARTIALLY_PAID } from './purchase';
import { listDetailItemId } from '../reporting-engine/base/report-page';

const createAgainstMode = (id, name, selectionIdParam, icon, api, getItemLink, getItemDate) => ({
    id, icon, name, selectionIdParam, getItemLink, getItemDate,
    api: api.bind(Api),
    getSelectionId: selection => selection[selectionIdParam],
})

const getStockFlowName = (againstItem) => {
    const isQuotation = () => {
        if (againstItem.invoiceDocument || againstItem.bill) {
            return false;
        }
        return againstItem.stockFlow.quotation && (againstItem.stockFlow.transactionIds == null || againstItem.stockFlow.transactionIds.length == 0);
    }

    const isSourceExternal = () => {
        if (againstItem.invoiceDocument || againstItem.bill) {
            return false;
        }
        return againstItem.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER || againstItem.stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION || againstItem.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER;
    }

    const isDestinationExternal = () => {
        if (againstItem.invoiceDocument || againstItem.bill) {
            return false;
        }
        return againstItem.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER || againstItem.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER || againstItem.stockFlow.destinationType == DESTINATION_TYPE_STOCK_TERMINATION;
    }

    if (againstItem.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !isDestinationExternal()) {
        return "Stock Flow (purchase)"
    } else if (!isSourceExternal() && againstItem.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
        if (againstItem.stockFlow.sourceType == SOURCE_TYPE_VENUE) {
            return "Stock Flow (venue sales)";
        } else {
            return isQuotation() ? "Quotation" : "Stock Flow (sales)";
        }
    } else if (!isSourceExternal() && againstItem.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
        return "Purchase Return"
    } else if (againstItem.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !isDestinationExternal()) {
        return "Sales Return";
    } else if (!isSourceExternal() && !isDestinationExternal()) {
        return "Stock Transfer";
    }
}

export function getOptionFromItem(againstItem) {
    if (againstItem.stockFlow) {
        return {
            id: againstItem.stockFlow.id,
            name: getStockFlowName(againstItem),
            link: "/stock-flow/" + againstItem.stockFlow.id,
            date: Util.getDate(againstItem.stockFlow.initiationDate),
            amount: againstItem.stockFlow.amount,
        }
    } else if (againstItem.bill) {
        return {
            id: againstItem.bill.id,
            name: "Bill",
            link: '/bill/' + againstItem.bill.id,
            date: Util.getDate(againstItem.bill.initiationDate),
            amount: againstItem.bill.amount,
        }
    } else if (againstItem.payout) {
        return {
            id: againstItem.payout.id,
            name: "Payout",
            link: '/payout/' + againstItem.payout.id,
            date: Util.getDate(againstItem.payout.initiationDate),
            amount: againstItem.payout.amount,
        }
    } else if (againstItem.invoiceDocument) {
        return {
            id: againstItem.invoiceDocument.id,
            name: "Invoice Document",
            link: '/invoice-documents/' + againstItem.invoiceDocument.id,
            date: Util.getDate(againstItem.invoiceDocument.dateCreated),
            amount: againstItem.invoiceDocument.amount,
        }
    } else if (againstItem.purchase) {
        return {
            id: againstItem.purchase.id,
            name: "Purchase",
            link: '/purchase/' + againstItem.purchase.id,
            date: Util.getDate(againstItem.purchase.initiationDate),
            amount: againstItem.purchase.amount,
        }
    } else if (againstItem.sale) {
        return {
            id: againstItem.sale.id,
            name: "Sale",
            link: '/sale/' + againstItem.sale.id,
            date: Util.getDate(againstItem.sale.initiationDate),
            amount: againstItem.sale.amount,
        }
    } else if (againstItem.serviceSale) {
        return {
            id: againstItem.serviceSale.id,
            name: "Service Sale",
            link: '/service-sale/' + againstItem.serviceSale.id,
            date: Util.getDate(againstItem.serviceSale.initiationDate),
            amount: againstItem.serviceSale.amount,
        }
    } else if (againstItem.commission) {
        return {
            id: againstItem.commission.id,
            name: "Commission",
            link: '/commission/' + againstItem.commission.id,
            date: Util.getDate(againstItem.commission.initiationDate),
            amount: againstItem.commission.amount,
        }
    } else if (againstItem.v2Bill) {
        return {
            id: againstItem.v2Bill.id,
            name: "Bill",
            link: listDetailItemId("V2BillList", againstItem.v2Bill.id),
            date: Util.getDateOnly(againstItem.v2Bill.billDate),
            amount: againstItem.v2Bill.amount,
        }
    } else if (againstItem.v2Sale) {
        return {
            id: againstItem.v2Sale.id,
            name: "Sale",
            link: listDetailItemId("V2SalesList", againstItem.v2Sale.id),
            date: Util.getDateOnly(againstItem.v2Sale.invoiceDate),
            amount: againstItem.v2Sale.amount,
        }
    }
}

export function createAgainstOptions(isInward, otherPartyType) {
    if (isV2()) {
        switch (parseInt(otherPartyType)) {
            case TRANSACTION_PARTY_TYPE_V2_VENDOR:
                if (!isInward) {
                    return [
                        createAgainstMode(
                            'v2-bill',
                            "Bill",
                            "v2BillId",
                            PurchaseIcon,
                            Api.getV2UnpaidBills,
                            item => listDetailItemId("V2BillList", item.id),
                            item => item.billDate
                        ),
                    ];
                }
                break;
            case TRANSACTION_PARTY_TYPE_V2_CUSTOMER:
                if (isInward) {
                    return [
                        createAgainstMode(
                            'v2-sale',
                            "Sale",
                            "v2SaleId",
                            Certificate32,
                            Api.getV2UnpaidSales,
                            item => listDetailItemId("V2SalesList", item.id),
                            item => item.invoiceDate
                        ),
                    ];
                }
                break;
        }

        return []
    }

    switch (parseInt(otherPartyType)) {
        case TRANSACTION_PARTY_TYPE_SUPPLIER:
            if (!isInward) {
                return [
                    createAgainstMode(
                        'purchase',
                        "Purchase",
                        "purchaseId",
                        Migrate32,
                        (againstParentId, listener) => Api.getPurchases(againstParentId, PURCHASE_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/purchase/" + item.id,
                        item => item.initiationDate
                    ),
                    createAgainstMode(
                        'stock-flow-purchase',
                        "Stock Flow (purchase)",
                        "stockFlowId",
                        PurchaseIcon,
                        Api.getUnpaidPurchases,
                        item => "/stock-flow/" + item.id,
                        item => item.initiationDate
                    ),
                    createAgainstMode(
                        'bill',
                        "Bill",
                        "billId",
                        ChartBullet32,
                        (againstParentId, listener) => Api.getBills(againstParentId, BILL_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/bill/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            }
            break;
        case TRANSACTION_PARTY_TYPE_CUSTOMER:
            if (isInward) {
                return [
                    createAgainstMode(
                        'sale',
                        "Sale",
                        "saleId",
                        Certificate32,
                        (againstParentId, listener) => Api.getSales(againstParentId, SALE_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/sale/" + item.id,
                        item => item.initiationDate
                    ),
                    createAgainstMode(
                        'service-sale',
                        "Service Sale",
                        "serviceSaleId",
                        GraphicalDataFlow32,
                        (againstParentId, listener) => Api.getServiceSales(againstParentId, SERVICE_SALE_STATUS_UNPAID_OR_PARTIALLY_PAID, false, listener),
                        item => "/service-sale/" + item.id,
                        item => item.initiationDate
                    ),
                    createAgainstMode(
                        'stock-flow-sale',
                        "Stock Flow (sales)",
                        "stockFlowId",
                        SaleIcon,
                        Api.getUnpaidSales,
                        item => "/stock-flow/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            }
            break;
        case TRANSACTION_PARTY_TYPE_STAFF:
            if (!isInward) {
                return [
                    createAgainstMode(
                        'commission',
                        "Commission",
                        "commissionId",
                        Migrate32,
                        (againstParentId, listener) => Api.getCommissions(againstParentId, COMMISSION_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/commission/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            }
            break;
        case TRANSACTION_PARTY_TYPE_VENDOR:
            if (!isInward) {
                return [
                    createAgainstMode(
                        'bill',
                        "Bill",
                        "billId",
                        ChartBullet32,
                        (againstParentId, listener) => Api.getBills(againstParentId, BILL_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/bill/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            }
            break;
        case TRANSACTION_PARTY_TYPE_STORE:
            break;
        case TRANSACTION_PARTY_TYPE_VENUE:
            if (isInward) {
                return [
                    createAgainstMode(
                        'invoice-document',
                        "Invoice Document",
                        "invoiceDocumentId",
                        Document32,
                        (againstParentId, listener) => Api.getInvoiceDocuments(againstParentId, INVOICE_DOCUMENT_STATUS_NOT_PAID, listener),
                        item => "/invoice-documents/" + item.id,
                        item => item.dateCreated
                    ),
                    createAgainstMode(
                        'stock-flow-sale',
                        "Stock Flow (venue sales)",
                        "stockFlowId",
                        BuildingInsights_132,
                        Api.getUnpaidVenueSales,
                        item => "/stock-flow/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            } else {
                return [
                    createAgainstMode(
                        'bill',
                        "Bill",
                        "billId",
                        ChartBullet32,
                        (againstParentId, listener) => Api.getBills(againstParentId, BILL_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/bill/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            }
            break;
        case TRANSACTION_PARTY_TYPE_STUDENT:
            if (isInward) {
                return [
                    // createAgainstMode(
                    //     'sale',
                    //     "Sale",
                    //     "saleId",
                    //     Certificate32,
                    //     (againstParentId, listener) => Api.getSales(againstParentId, SALE_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                    //     item => "/sale/" + item.id,
                    //     item => item.initiationDate
                    // ),
                    createAgainstMode(
                        'service-sale',
                        "Service Sale",
                        "serviceSaleId",
                        GraphicalDataFlow32,
                        (againstParentId, listener) => Api.getServiceSales(againstParentId, SERVICE_SALE_STATUS_UNPAID_OR_PARTIALLY_PAID, false, listener),
                        item => "/service-sale/" + item.id,
                        item => item.initiationDate
                    ),
                    // createAgainstMode(
                    //     'stock-flow-sale',
                    //     "Stock Flow (sales)",
                    //     "stockFlowId",
                    //     SaleIcon,
                    //     Api.getUnpaidSales,
                    //     item => "/stock-flow/" + item.id,
                    //     item => item.initiationDate
                    // ),
                ];
            }
            break;
        case TRANSACTION_PARTY_TYPE_TUTOR:
            if (!isInward) {
                return [
                    createAgainstMode(
                        'payout',
                        "Payout",
                        "payoutId",
                        DocumentSentiment32,
                        (againstParentId, listener) => Api.getPayouts(againstParentId, PAYOUT_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/payout/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            }
            break;
        case TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR:
            if (!isInward) {
                return [
                    createAgainstMode(
                        'payout',
                        "Payout",
                        "payoutId",
                        DocumentSentiment32,
                        (againstParentId, listener) => Api.getPayouts(againstParentId, PAYOUT_STATUS_UNPAID_OR_PARTIALLY_PAID, listener),
                        item => "/payout/" + item.id,
                        item => item.initiationDate
                    ),
                ];
            }
            break;
    }
    return []
}