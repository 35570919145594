import { useHistory } from "react-router-dom"
import { withLoadablePageWithParams } from "../../base/Page"
import Api from "../../session/Api"
import { Save16, Delete16, FlowStream32, Time16, Error16, ErrorFilled16, CheckmarkFilled16, Currency16, Information16, QrCode32, ArrowLeft16 } from '@carbon/icons-react'
import { Link, Tab, Tabs, Tile } from "carbon-components-react";
import NoteView from "../../views/notes/NoteView";
import StockRequestActionButtons from "./StockRequestActionButtons";
import { useMemo, useState } from "react";
import { StockRequestStatusIndicator } from "../../domain/stock-request";
import Util from "../../util/Util";
import SystemLogsListView from "../system-logs/SystemLogsListView";
import { OBJECT_TYPE_STOCK_REQUEST } from "../../constants/ObjectTypes";
import CartList from "../store-master/components/CartList";
import { Link as LK } from 'react-router-dom'
import { getAccountRole } from "../../session/SessionManager";
import { ACCOUNT_TYPE_VENUE_MANAGER } from "../../constants/Constants";

const Header = ({ value, first }) => <p style={{ fontWeight: 'bold', color: 'black', marginTop: first ? 0 : '1rem' }}>{value}</p>

const FormField = ({ title, value }) => (
    <div style={{ marginBottom: '0.25rem' }}>
        <label className="bx--label" style={{ marginBottom: 0 }}>{title}</label>
        <p>{value}</p>
    </div>
)

const Items = ({ stockRequest }) => {
    const controller = useMemo(() => {
        const items = stockRequest.items.map(item => item.cartItemAlias);
        return {
            useClear: () => [false, () => { }],
            useItems: () => items,
            useUpdateCart: () => [() => { }, () => { }],
            useThirdPartyPos: () => { },
            useComplimentary: () => undefined
        }
    }, [stockRequest])
    const role = useMemo(() => getAccountRole(), []);
    return <CartList linkProduct={role != ACCOUNT_TYPE_VENUE_MANAGER} readonly customTitle="Selection" controller={controller} />
}

const Details = ({ stockRequest }) => {

    return (
        <div style={{ padding: '1rem' }}>
            <Header value={"Stock Request Details"} first />
            <FormField title={"Date Created"} value={Util.getDate(stockRequest.dateCreated)} />
            <FormField title={"Request By"} value={stockRequest.destinationName} />
            <FormField title={"Request By User"} value={stockRequest.requestByFullName} />
            {Util.isNumberExist(stockRequest.createdStockFlowId) &&
                <FormField title={"Stock Transfer No"} value={<LK to={"/stock-flow/" + stockRequest.createdStockFlowId}>{Util.getVoucherNumber(stockRequest.createdStockFlowId)}</LK>} />}

            <Header value={"Items"} />
            <div className="third-party-pos" style={{ padding: 5, marginTop: 10 }}>
                <Items stockRequest={stockRequest} />
            </div>
        </div>
    )
}


const View = ({ payload }) => {
    const [stockRequest, setStockRequest] = useState(payload);
    const history = useHistory()

    return (
        <div className="clear-tab-content" style={{ padding: '2rem' }}>
            <Link onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</Link>
            <Tile style={{ padding: 0, display: 'flex' }} >
                <div style={{ padding: '1rem', flex: 1 }}>
                    <h4 className="bx--data-table-header__title">Stock Request</h4>
                    <p style={{ marginBottom: '1rem' }} className="bx--data-table-header__description">ID: {Util.getVoucherNumber(stockRequest.id)}</p>

                    <StockRequestStatusIndicator status={stockRequest.status} />
                </div>
                <StockRequestActionButtons stockRequest={stockRequest} onUpdateStockRequest={setStockRequest} />
            </Tile>

            <Tabs type="container" style={{ background: '#e0e0e0' }}>
                <Tab id="details" label="Details">
                    <Details stockRequest={stockRequest} />
                </Tab>
                <Tab id="notes" label="Notes/Docs">
                    <div style={{ marginTop: '1rem' }}>
                        <NoteView objectType={OBJECT_TYPE_STOCK_REQUEST} objectId={stockRequest.id} />
                    </div>
                </Tab>
                <Tab id="logs" label="System Logs">
                    <div style={{ marginTop: '1rem' }}>
                        <SystemLogsListView relatedObjectId={stockRequest.id} />
                    </div>
                </Tab>
            </Tabs>


            <div style={{ height: '4rem' }} />
        </div>
    )
}

export default withLoadablePageWithParams(params => listener => Api.getStockRequest(params.itemId, listener), View)

