import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#ff9b87'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#ffb6a8',
        backgroundColor: '#ffb6a8',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'left',
        // fontStyle: 'bold',
        fontSize: 8,
        flexGrow: 1,
    },
    a: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 5,
    },
    b: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 5,
    },
    c: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 5,
    },
    d: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 5,
    },
    e: {
        width: '15%',
        paddingLeft: 5,
    },
    f: {
        width: '15%',
        paddingLeft: 5,
    },
});

export const TableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.a}>Date</Text>
        <Text style={styles.b}>Voucher No</Text>
        <Text style={styles.c}>Note</Text>
        <Text style={styles.d}>Debit</Text>
        <Text style={styles.e}>Credit</Text>
        <Text style={styles.e}>Closing Balance</Text>
    </View>
);
