import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_COUPON, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

class CouponListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Coupons",
                    subTitle: "All coupons",
                    objectType: OBJECT_TYPE_COUPON,
                    pagePath: "/coupons/",
                    history: this.props.history,
                    paginated: true,
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(CouponListPage);