import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Api from "../../../../session/Api";
import { Warning16 } from '@carbon/icons-react';
import { Tag } from "carbon-components-react";


export function InvoiceUnpaidIndicator() {
    const history = useHistory();
    const [count, setCount] = useState(0)

    useEffect(() => {
        Api.doIHaveAnyUnpaidInvoices(response => {
            if (response.status === true) {
                setCount(parseInt(response.payload));
            }
        })
    }, [])

    if (count && count > 0) {
        return <Tag onClick={() => history.push('/invoices')} type='red' renderIcon={Warning16}>{count} unpaid invoice{count != 1 ? 's' : ''} due</Tag>
    } else {
        return null;
    }
}