import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_TARGET } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import { createParamsFieldsNew } from '../../../domain/activity/activity-blueprint';
import TableTemplate from '../../../templates/TableTemplate';
import { TABLE_RELATION_TYPE_MANY_TO_MANY } from '../../../constants/Constants';

const OBJECT_TYPE = OBJECT_TYPE_PRODUCT_TARGET;

const ProductsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE,

                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Products",
                subTitle: "Products that exist in this target",
                objectType: OBJECT_TYPE_PRODUCT,
                paginated: true,
                pagePath: "/products/",
                history: history
            })}
        </div>
    )
}

const BundlesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE,

                    childType: OBJECT_TYPE_PRODUCT_BUNDLE,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Bundles",
                subTitle: "Bundles that exist in this target",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                pagePath: "/product-bundles/",
                history: history
            })}
        </div>
    )
}

const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    // const itemResult = useMemo(() => createParamsFieldsNew(payload, []), [payload])
    // console.log(itemResult)
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                shouldFixForApi: true,
                customTabs: [
                    {
                        title: "Products",
                        component: ProductsList
                    },
                    // {
                    //     title: "Bundles",
                    //     component: BundlesList
                    // }
                ]
            })}
        </div>
    )
})

export const ProductTargetDetailPage = Page;
