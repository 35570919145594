import Util from "../util/Util";

const STOP_ASK_FLAG = "dnafn-perm"

export function getPermissionIfNeeded() {
    if (!('Notification' in window)) {
        return;
    }

    if (Notification.permission === "granted") {
        return;
    }

    const cannotAsk = localStorage.getItem(STOP_ASK_FLAG) !== null;
    if (cannotAsk) {
        return;
    }

    const onDone = permission => {
        if (permission === "denied") {
            localStorage.setItem(STOP_ASK_FLAG, "true");
        }
    }

    if (Util.isDebug()) {
        Notification.requestPermission(onDone);
    } else {
        Notification.requestPermission().then(onDone);
    }
}

