import { InlineLoading } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { LOCATION_EVENT_FLAG_AMOUNT_COLLECTED, LOCATION_EVENT_FLAG_LOCATION_OFF, LOCATION_EVENT_FLAG_LOCATION_ON, LOCATION_EVENT_FLAG_SALES, LOCATION_EVENT_FLAG_STAGNANT } from '../../../constants/Constants';
import Api from '../../../session/Api';
import Util from '../../../util/Util';
//getRawLocationEvents

const Item = ({ event }) => {
    let title = 'Location update'
    let color = 'black'
    switch (event.flag) {
        case LOCATION_EVENT_FLAG_SALES:
            title = "Made a sale"
            color = "#00551a"
            break;
        case LOCATION_EVENT_FLAG_LOCATION_OFF:
            title = "Unreachable"
            color = "red"
            break;
        case LOCATION_EVENT_FLAG_LOCATION_ON:
            title = "Back Online"
            color = "rgb(19,57,59)"
            break;
        case LOCATION_EVENT_FLAG_STAGNANT:
            title = "Was Stagnant Here (> 5m)"
            color = "#e75d00"
            break;
        case LOCATION_EVENT_FLAG_AMOUNT_COLLECTED:
            title = "Amount collected";
            color = "#997d00"
            break;
    }

    return (<>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '0.5rem' }}>
            <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem', flex: 1 }}>
                <p>
                    [{Util.getDate(event.date)}] <span style={{ color }}>{title}</span>
                </p>
                <a target="_blank" href={`https://maps.google.com/?q=${event.lat},${event.lng}&z=20`}>
                    {event.address}
                </a>
            </div>
            {event.flag == LOCATION_EVENT_FLAG_SALES &&
                <Link target="_blank" to={"/stock-flow/" + event.salesStockFlowId} style={{ color: '#00551a' }}>
                    <p style={{ fontSize: 12, color: '#00551a' }}>Sales voucher no: {Util.getVoucherNumber(event.salesStockFlowId)}</p>
                </Link>}
            {event.flag == LOCATION_EVENT_FLAG_AMOUNT_COLLECTED &&
                <Link target="_blank" to={"/transaction/" + event.transactionId} style={{ color: '#997d00' }}>
                    <p style={{ fontSize: 12, color: '#997d00' }}>Transaction voucher no: {Util.getVoucherNumber(event.transactionId)}</p>
                </Link>}
        </div>
        <div style={{ height: 1, width: '100%', background: 'black', opacity: 0.25, marginTop: '0.5rem', marginBottom: '0.5rem' }} />
    </>)
}

export default ({ sessionId }) => {
    const [loading, setLoading] = useState(true)
    const [inError, setInError] = useState(false)
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true)
        setInError(false)
        setItems([])
        Api.getRawLocationEvents(sessionId, response => {
            if (response.status === true) {
                setItems(response.payload.reverse())
            } else {
                setInError(true)
            }
            setLoading(false)
        })
    }, [sessionId])

    if (!loading) {
        if (items.length > 0) {
            return (<>
                {items.map(item => <Item key={item.id} event={item} />)}
            </>)
        } else {
            return (
                <div className="centered-progress-bar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 45 }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No events exist</p>
                </div>
            )
        }
    } else if (inError) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 45 }}>
                <p style={{ fontSize: 12, opacity: 1, color: 'red' }}>An error has occurred</p>
            </div>
        )
    } else {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 45 }}>
                <InlineLoading />
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.15rem' }}>Loading...</p>
            </div>
        )
    }
}