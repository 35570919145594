

import {
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
    Checkmark16,

    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Number_832,
    Number_932,
    Checkmark32,

} from '@carbon/icons-react'

const ICONS = [
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
]

const ICONS_LX = [
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Number_832,
    Number_932,
]

export const getStatusIndexById = (flow, statusId) => {
    return flow.findIndex(item => item.id === statusId);
}

export const getPreviousStatusById = (flow, statusId) => {
    return flow[flow.findIndex(item => item.id === statusId) - 1];
}

export const getNextStatusById = (flow, statusId) => {
    return flow[flow.findIndex(item => item.id === statusId) + 1];
}

export const getFnbEventStatus = (flow, fnbEvent) => {
    return flow[Math.max(flow.findIndex(status => status.id === fnbEvent?.status), 0)]
}

export const getStatusIcon = (flow, status, lx) => {
    const index = flow.findIndex(item => item.id === status.id);
    if (index < flow.length - 1) {
        return lx ? ICONS_LX[index % ICONS.length] : ICONS[index % ICONS.length]
    } else {
        return lx ? Checkmark32 : Checkmark16;
    }
};

export const getStatusOp = (fnbEvent, flow, selectedStatus, status) => {
    if (status) {
        const fnbEventStatusIndex = flow.findIndex(item => item.id === fnbEvent.status);
        const statusIndex = flow.findIndex(item => item.id === status.id);

        return {
            done: statusIndex < fnbEventStatusIndex,
            enabled: statusIndex <= fnbEventStatusIndex,
            selected: status.id === selectedStatus?.id,

            statusDate: fnbEvent.statusUpdates.find(elem => elem.status === status.id)?.date
        }
    } else {
        return { done: false, active: false }
    }
};


export const FNB_EVENT_TYPE = {
    CATERING: 'CATERING',
    BANQUET: 'BANQUET'
}