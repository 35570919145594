import Button from "../../../components/Button";
import { CertificateCheck16 } from '@carbon/icons-react'
import { Modal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { useState } from "react";


export function SetSerialNoButton({ salesOrderId, salesOrder, setSalesOrder }) {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button className="green-button" onClick={() => setVisible(true)} size="sm" style={{ borderRadius: 50 }} renderIcon={CertificateCheck16}>Set Serial No</Button>

        <FormDialog open={visible} onClose={() => setVisible(false)} label="Product Serial Numbers" title="Update" item={salesOrder?.productsRequiringSerialNumbersValues} setItem={setSalesOrder}
            saveApi={(data, listener) => Api.updateSalesOrderSerialNumbers(salesOrderId, data, listener)}>
            {({ TextField, }) => (<>
                {salesOrder.productsRequiringSerialNumbers.map(product => (
                    <TextField key={product.id} title={product.value + " (" + product.id + ")"} fieldKey={product.id} placeholder="Serial Number" />
                ))}
            </>)}
        </FormDialog>
    </>)
}