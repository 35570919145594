import React from 'react';
import { withRouter } from 'react-router-dom';
import { OBJECT_TYPE_REAL_ESTATE_PROPERTY_TYPE } from '../../../constants/ObjectTypes';
import Page from '../../../base/Page';
import TableTemplate from '../../../templates/TableTemplate';

class PropertyTypeListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Property types",
                    subTitle: "All property types",
                    objectType: OBJECT_TYPE_REAL_ESTATE_PROPERTY_TYPE,
                    pagePath: "/real-estate-property-type/",
                    history: this.props.history,
                    paginated: false
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(PropertyTypeListPage);