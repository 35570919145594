
import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'

const PDF = ({labelType, products, barcodes, qrcodes, options}) => {
    let width = 250;
    let height = labelType == "Barcode only" ? ((options.displayPrice || options.displaySku) ? 105 : 85) : 250;

    let nameHeight = 10;
    let nameMarginTop = labelType == "Barcode only" ? 5 : 10;

    let priceHeight = options.displayPrice ? 10 : 0;
    let priceMarginTop = options.displayPrice ? 0 : 0;
    
    let skuHeight = options.displaySku ? 10 : 0;
    let skuMarginTop = options.displaySku ? 0 : 0;
    
    let qrCodeHeight = 200;
    let barcodeHeight = 65;

    let nonCodeSegment = height - nameHeight - nameMarginTop - priceHeight - priceMarginTop - skuHeight - skuMarginTop;

    if (labelType == "QR Code only") {
        qrCodeHeight = nonCodeSegment - 10;
    } else if (labelType == "Barcode only") {
        barcodeHeight = nonCodeSegment - 5;
    } else {
        qrCodeHeight = nonCodeSegment - barcodeHeight - 10;
    }

    return (
        <Document>
            {products.map(product => {
                let elements = [];
                
                if (!product.quantity) {
                    product.quantity = 1;
                }
                for (let i = 0; i < product.quantity; i++) {
                    elements.push(
                        <Page key={product.id + '-' + i} size={[width, height]}>
                            <Text style={{fontSize: 8, fontWeight: 'bold', width: '100%', height: nameHeight, marginTop: nameMarginTop, textAlign: 'center'}}>{product.name}</Text>
                            {labelType != "QR Code only" && <Image src={barcodes.get(product.id)} style={{width: '100%', height: barcodeHeight, objectFit: 'contain', objectPosition: 'center'}} />}
                            {labelType != "Barcode only" && <Image src={qrcodes.get(product.id)} style={{width: '100%', height: qrCodeHeight, objectFit: 'contain', objectPosition: 'center'}} />}
        
                            {options.displaySku && <Text style={{fontSize: 6, width: '100%', height: skuHeight, marginTop: skuMarginTop, textAlign: 'center'}}>{product.sku}</Text>}
                            {options.displayPrice && <Text style={{fontSize: 6, width: '100%', height: priceHeight, marginTop: priceMarginTop, textAlign: 'center'}}>{product.currency} {product.price} (tax included)</Text>}
                        </Page>
                    );
                }

                return elements;
            })}
        </Document>
    );
}

export const printProductLabels = async (labelType, options, products) => {
    const barcodes = new Map();
    const qrcodes = new Map();
    for (const product of products) {
        let canvas;
        // For QR Code
        
        if (labelType == "QR Code only" || labelType == "Barcode and QR Code") {
            canvas = document.createElement('canvas');
            QRCode.toCanvas(canvas, window.location.origin.toString() + "/product-preview/" + product.id);
            const qr = canvas.toDataURL();
            qrcodes.set(product.id, qr);
        }

        // For Barcode
        if (labelType == "Barcode only" || labelType == "Barcode and QR Code") {
            canvas = document.createElement('canvas');
            JsBarcode(canvas, product.forceBarcode ? product.forceBarcode.value : product.barcode, {
                format: product.forceBarcode ? product.forceBarcode.type : product.barcodeFormat
            });
            const barcode = canvas.toDataURL();
            barcodes.set(product.id, barcode);
        }
    }

    const blob = await pdf(<PDF labelType={labelType} products={products} barcodes={barcodes} qrcodes={qrcodes} options={options} />).toBlob();
    print(URL.createObjectURL(blob));
}