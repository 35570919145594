import { hasCapabilitySupport } from "../../../app/Capabilities"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR, DESTINATION_TYPE_STORE, DESTINATION_TYPE_WAREHOUSE, SOURCE_TYPE_STORE, SOURCE_TYPE_WAREHOUSE } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import ProductField from "../../../views/product/ProductField"
import { newListDetail } from "../../base/list-detail"
import { Industry32, Industry24, ArrowRight24, Warning16 } from '@carbon/icons-react'
import { BundleField } from "./bundle-field"
import { MinimalTable } from "../../../components/minimal-table"
import { StockUsage } from "./stock-usage"
import Util from "../../../util/Util"
import { selectionsToGroupToProductMap } from "./func"
import { ProductionViewPage } from "./view-page"

export const Productions = newListDetail({
    title: "Production",
    icon: Industry32,
    mdIcon: Industry24,
    saveLabel: "Produce",
    deleteLabel: "Revert/Delete Production",

    filter: Filter, newForm: Form, updateForm: ReadonlyForm,

    defaultValues: {
        date: new Date().getTime()
    },

    isAvailable: () => {
        if (!hasCapabilitySupport("production")) {
            return;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },

    finalFormMapper: data => ({
        ...data,
        groupToProductMap: selectionsToGroupToProductMap(data.bundle, data.groupSelections),
    }),

    onPreSave({ bundle: item, groupSelections: selections = [], quantity }) {
        const hasBundleSelected = !!item;

        const hasOptionsSelected = (() => {
            if (!item) {
                return false;
            }

            const selection = (groupId) => selections.find(selection => selection.groupId === groupId) ?? { groupId }
            const values = item.groups.map(group => selection(group.id))
            return values.filter(item => {
                //check if group has NOT been selected

                if (item.optionRecords) {
                    const totalPercentage = item.optionRecords.filter(selection => Util.isNumberExist(selection.optionId))
                        .map(selection => selection.percentage ?? 100)
                        .reduce((t, c) => t + c, 0)
                    const isHunderedPercent = totalPercentage === 100;
                    return !isHunderedPercent;
                } else {
                    return !Util.isNumberExist(item.optionId);
                }
            }).length === 0
        })();

        if (!hasBundleSelected) {
            return "Please select a bundle"
        }

        if (!hasOptionsSelected) {
            return "Please select all bundle options"
        }

        if (!quantity) {
            return "Please specify the quantity"
        }
    }
})

function ReadonlyForm({ endpoint, form }) {
    const data = form.getFormData();
    return <ProductionViewPage production={data} />
}

function Form({ endpoint, form }) {
    form.useChangeListener('sourceId', sourceId => {
        const sourceValue = endpoint.locations.find(location => location.id === sourceId)?.value;
        if (sourceValue) {
            if (sourceValue.startsWith("Store: ")) {
                form.setField("sourceType", SOURCE_TYPE_STORE);
            } else if (sourceValue.startsWith("Warehouse: ")) {
                form.setField("sourceType", SOURCE_TYPE_WAREHOUSE);
            }
        } else {
            form.setField("sourceType", 0)
        }
    })

    form.useChangeListener('destinationId', destinationId => {
        const destinationValue = endpoint.locations.find(location => location.id === destinationId)?.value;
        if (destinationValue) {
            if (destinationValue.startsWith("Store: ")) {
                form.setField("destinationType", DESTINATION_TYPE_STORE);
            } else if (destinationValue.startsWith("Warehouse: ")) {
                form.setField("destinationType", DESTINATION_TYPE_WAREHOUSE);
            }
        } else {
            form.setField("destinationType", 0)
        }
    })

    return (
        <div>
            <FormSection title="Inventory Flow">

                <form.DateField fieldKey="date" title="Date" />
                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '3rem', }}>
                    <div style={{ flex: 1 }}>
                        <form.ComboBoxField fieldKey="sourceId" title="Source" placeholder="Inventory Location" options={endpoint.locations} />
                    </div>
                    <div style={{ height: 40, display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                        <ArrowRight24 />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.ComboBoxField fieldKey="destinationId" title="Destination" placeholder="Inventory Location" options={endpoint.locations} />
                    </div>
                </div>
            </FormSection>

            <FormSection title="Production Item">
                <form.ExtField childrenProps={{}}>
                    {BundleField}
                </form.ExtField>
            </FormSection>

            <FormSection title="Stock Usage">
                {/* <pre><code>{JSON.stringify(form.getFormData(), null, 2)}</code></pre> */}
                <div style={{
                    borderRadius: 7, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', background: '#fff8ef', marginBottom: '1rem',
                    padding: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', color: 'orangered'
                }}>
                    <Warning16 />
                    <p style={{ fontSize: 14 }}>Please note that the following items are <strong>used/deducted/terminated</strong> from the 'Source' inventory during production.</p>
                </div>
                <form.ExtField>
                    {StockUsage}
                </form.ExtField>
            </FormSection>

            <FormSection title="Additional Information">
                <form.TextAreaField fieldKey="memo" title="Memo" placeholder="(Optional info)" />
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
