import React from 'react'
import { Line } from 'react-chartjs-2';

export default ({report}) => (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Line data={{
                labels: report.hours,
                // labels: ['09:00', '10:00', '11:00', '12:00', '13:00'],
                datasets: [
                    
                    // {
                    //     label: 'Sales',
                    //     data: report.hoursSales,
                    //     // label: 'John Smith',
                    //     // data: [4, 5, 7, 12, 15],
                    //     fill: true,
                    //     backgroundColor: 'rgba(54, 162, 235, 0.4)',
                    //     borderColor: 'rgba(54, 162, 235, 1)',
                    // },
                    {
                        label: 'Sales',
                        data: report.hoursSales,
                        // label: 'Alex Wales',
                        // data: [12, 23, 15, 7, 25],
                        fill: true,
                        backgroundColor: 'rgb(255, 99, 132, 0.4)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                    }
                ],
            }} options={{
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            callback: function(value, index, values) {
                                return 'AED ' + value;
                                // return value;
                            }
                        }
                    },
                },
                elements: {
                    line: {
                        tension: 0.4
                    }
                },
                // plugins: {
                //     tooltip: {

                //     }
                // }
            }} />
    </div>
)