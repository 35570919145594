import React from 'react'

export default () => (value, setter) => {
    if (!value) {
        value = "";
    }
    value = value.replace("-", "");

    if (!isNaN(value)) {
        if (value.trim() == "") {
            setter(0);
        } else {
            setter(parseInt(value))
        }
    }
}