import React from 'react'
import Page from '../../base/Page';
import CRVListView from './CRVListView';


class CRVPage extends Page {

    render() {
        return (
            <div className="main-content">
                <CRVListView />
            </div>
        )
    }

}

export default CRVPage;