import { ComboBox, ComposedModal, ContentSwitcher, DataTableSkeleton, InlineLoading, ModalBody, ModalFooter, ModalHeader, Switch } from "carbon-components-react";
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import Button from "../../components/Button"
import Table from "../../components/data-table/Table";
import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../../constants/Constants";
import { getInvoiceDocumentStatusDetails, InvoiceDocumentStatusIndicator, INVOICE_DOCUMENT_STATUS_NOT_PAID, INVOICE_DOCUMENT_STATUS_PAID, INVOICE_DOCUMENT_STATUS_REVOKED } from "../../domain/invoice-document";
import { getStockRequestStatusDetails, StockRequestStatusIndicator } from "../../domain/stock-request";
import useStore from "../../hooks/useStore";
import Api from "../../session/Api";
import { makeObservable } from "../../util/makeObservable";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import { Document16, DocumentAdd16 } from '@carbon/icons-react'
import { getObjectTypeName, OBJECT_TYPE_VENUE } from "../../constants/ObjectTypes";
import useApi from "../../hooks/useApi";
import Portal from "../../util/Portal";
import { DateRangePicker } from "react-date-range";
import { subDays } from "date-fns";
import DatePicker from "../../components/DatePicker";



const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)

const StatusTab = ({ selectedStatus, onSelect, status }) => {
    if (status == -1) {
        return <TabItem key={status + '-tab-item-stock-request'} title={"All"} icon={Document16} selected={selectedStatus == status} onClick={() => onSelect(status)} />
    }
    let { icon, text } = getInvoiceDocumentStatusDetails(status);
    return <TabItem key={status + '-tab-item-stock-request'} icon={icon} title={text} selected={selectedStatus == status} onClick={() => onSelect(status)} />
}

const Tabs = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    return (
        <>
            <StatusTab status={-1} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={INVOICE_DOCUMENT_STATUS_NOT_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={INVOICE_DOCUMENT_STATUS_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={INVOICE_DOCUMENT_STATUS_REVOKED} selectedStatus={statusFilter} onSelect={setStatusFilter} />

            <div style={{ flex: 1 }} />
        </>
    )
}

const COLUMNS = [
    {
        id: "id",
        name: "ID",
        render: item => Util.getVoucherNumber(item.id)
    },
    {
        id: "payerName",
        name: "Payer",
        render: item => item.payerName
    },
    {
        id: "dateCreated",
        name: "Request Date",
        render: item => Util.getDate(item.dateCreated)
    },
    {
        id: "amount",
        name: "Amount",
        render: item => item.amount.toFixed(2)
    },
    {
        id: "amountPaid",
        name: "Amount Paid",
        render: item => item.amountPaid.toFixed(2)
    },
    {
        id: "status",
        name: "Status",
        render: item => <InvoiceDocumentStatusIndicator key={item.id + "-table-status-indicator-stock-request"} status={item.status} />
    }
]

const IntervalPicker = ({ hasDate, setHasDate, startDate, setStartDate, endDate, setEndDate }) => {

    return (
        <>
            <ContentSwitcher selectedIndex={hasDate ? 1 : 0} onChange={e => setHasDate(e.index == 0 ? false : true)}>
                <Switch text="Select all" />
                <Switch text="Date interval" />
            </ContentSwitcher>

            {hasDate && <div style={{ marginTop: '1rem', display: 'flex', gap: '0.5rem' }}>
                <div style={{ flex: 1 }}>
                    <h6 style={{ marginBottom: '0.25rem' }}>From Date</h6>
                    <DatePicker defaultDate={startDate} onDateChanged={setStartDate} />
                </div>
                <div style={{ flex: 1 }}>
                    <h6 style={{ marginBottom: '0.25rem' }}>To Date</h6>
                    <DatePicker defaultDate={endDate} onDateChanged={setEndDate} />
                </div>
            </div>}
        </>
    )
}

const NewBtn = () => {
    const history = useHistory();

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [endpoints, setEndpoints] = useState();

    const [payerType, setPayerType] = useState(OBJECT_TYPE_VENUE)
    const [payerId, setPayerId] = useState(0)
    const [payerIdPickerKey, setPayerIdPickerKey] = useState(Util.newTempId())

    const [hasDate, setHasDate] = useState(false);
    const [startDate, setStartDate] = useState(() => new Date().getTime())
    const [endDate, setEndDate] = useState(() => new Date().getTime())

    useEffect(() => {
        setPayerId(0)
        setPayerIdPickerKey(Util.newTempId())
    }, [payerType])

    useEffect(() => {
        let cancelled = false;
        if (visible) {
            setPayerId(0)
            setPayerIdPickerKey(Util.newTempId())
            setHasDate(false)

            setLoading(true);
            Api.getInvoiceDocumentEndpoints(response => {
                if (cancelled) {
                    return;
                }

                setLoading(false)
                if (response.status === true) {
                    setEndpoints(response.payload)
                } else {
                    UIUtil.showError(response.message)
                }
            })
        }
        return () => cancelled = true;
    }, [visible])

    const [generating, generate] = useApi(listener => Api.generateInvoiceDocument({
        venueId: payerId,
        hasDate, startDate, endDate
    }, listener), payload =>
        history.push("/invoice-documents/" + payload.documentId))

    const getPayerName = () => getObjectTypeName(payerType);

    const getPayerList = () => {
        if (!endpoints) {
            return []
        }

        switch (payerType) {
            case OBJECT_TYPE_VENUE:
                return endpoints.venues ?? []
        }
    }


    return (
        <>
            <Button onClick={() => setVisible(true)} renderIcon={DocumentAdd16}>Generate New Document</Button>

            <Portal>
                <ComposedModal open={visible} onClose={() => setVisible(false)} className="modal-that-supports-combo-box " >
                    {/* full-width-modal */}
                    <ModalHeader label="Generating" title="Invoice Document" />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        {loading ? (
                            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45 }}>
                                <InlineLoading style={{ width: 'unset' }} />
                                <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
                            </div>
                        ) : (
                            <div>
                                <label className="bx--label" style={{}}>Invoice document will combine all of the payer's unpaid invoices</label>
                                <ComboBox
                                    key={payerIdPickerKey}
                                    light
                                    // titleText={getPayerName()}
                                    titleText="Payer Vendor"
                                    items={getPayerList()}
                                    itemToString={item => item !== null ? item.value : ""}
                                    selectedItem={getPayerList().filter(item => item.id == payerId)[0]}
                                    onChange={e => {
                                        setPayerId(e.selectedItem !== null ? e.selectedItem.id : 0)
                                        setPayerIdPickerKey(Util.newTempId())
                                    }}
                                />
                                <div style={{ height: '1rem' }} />
                                <IntervalPicker {...({ hasDate, setHasDate, startDate, setStartDate, endDate, setEndDate, })} />
                                <div style={{ height: '1rem' }} />
                            </div>
                        )}

                    </ModalBody>
                    <ModalFooter>
                        <Button kind="secondary" onClick={() => setVisible(false)} disabled={generating}>
                            Close
                        </Button>
                        <Button
                            loading={generating}
                            disabled={payerId <= 0}
                            onClick={generate}
                        >
                            Generate
                        </Button>

                    </ModalFooter>
                </ComposedModal>
            </Portal>
        </>
    )
}

const List = ({ tabs, status }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setLoading(true)
        Api.getInvoiceDocuments(0, status, response => {
            if (response.status === true) {
                setRows(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [])

    return (
        <Table
            hasFooter
            title="Invoice Documents" description="Generated invoice documents"
            rows={rows} columns={COLUMNS} onClick={id => history.push("/invoice-documents/" + id)}
            rightContent={<NewBtn />}>
            {tabs}
            {loading && <InlineLoading style={{ marginLeft: '1rem' }} />}
        </Table>
    )
}

const Content = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    const tabs = <Tabs store={store} />;
    return (
        <List
            key={'table-list-invoice-documents-' + statusFilter}
            tabs={tabs}
            status={statusFilter}
        />
    )
}

export default ({ }) => {
    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("statusFilter", -1)
        return observable;
    }, [])


    return (
        <div className="main-content">
            <Content store={store} />
        </div>
    )
}