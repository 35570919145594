import React from 'react'
import { ComposedModal, InlineLoading, Loading, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput } from 'carbon-components-react';
import Button from '../components/Button';
import { getObjectTypeName } from '../constants/ObjectTypes';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';
import ItemTemplate from './ItemTemplate';

class ItemCreatorView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            itemResult: undefined
        }
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.setState({loading: true})
        Api.getItemCreator(this.props.objectType, response => {
            this.setState({loading: false, itemResult: response.status === true ? response.payload : undefined})
            if (response.status === false) {
                UIUtil.showError();
                this.props.onClose();
            }
        })
    }

    render() {
        return (
            this.state.loading ? (
                <div style={{width: '100%', height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loading withOverlay={false} />
                </div>
            ) : (
                ItemTemplate.renderTemplate({
                    objectType: this.props.objectType,
                    itemResult: this.state.itemResult,
                    modalMode: true,
                    hideGroupTitles: true,
                    skipSaveSuccesMessage: true,
                    filterProperties: this.props.filterProperties,
                    fullSizedModal: this.props.fullSized,
                    history: {
                        goBack: createdItem => createdItem !== undefined ? this.props.onCreatedItem(createdItem) : this.props.onClose()
                    }
                })
            )
        )
    }

}

export default ItemCreatorView;