import '../dashboard/dashboard-page.scss'
import './dashboard-real-estate-page.scss'
import React, { useState } from 'react'
import Page from '../../base/Page';
import { getAccount } from '../../session/SessionManager';

import {
    Dashboard32,
    Archive32,
    ShoppingCart32,
    Currency32,
    AirlinePassengerCare32,
    StarFilled32,
    IncreaseLevel16,
    ArrowRight16,
    SubtractAlt16
} from '@carbon/icons-react'
import Button from '../../components/Button';
import TodayAtAGlanceView from './views/TodayAtAGlanceView';
import WeeksProfitView from './views/WeeksProfitView';
import PaymentMethodDivision from './views/PaymentMethodDivision';
import UpcomingChequesView from './views/UpcomingChequesView';
import LowStockAlertsView from './views/LowStockAlertsView';
import UnpaidStockFlowsView from './views/UnpaidStockFlowsView';
import TopCustomersView from './views/TopCustomersView';
import ProductPerformanceView from './views/ProductPerformanceView';
import StaffStats from './views/StaffStats';
import { Link } from 'react-router-dom';
import Api from '../../session/Api';
import { isStockEntityFifoEngine } from '../../stock-system/stock-engine';
import { TopOverview } from './top-overview';
import { ProfitGraph } from './profit-graph';
import { Projects } from './projects';
import LMSWeeksProfitView from './views/LMSWeeksProfitView';
import { TopOverviewLms } from './top-overview-lms';

const Padding = () => <div style={{ height: '1rem' }} />

class LMSDashboardPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            dashboard: undefined
        }
    }

    onPageStart() {
        window.scrollTo(0, 0);
        this.callPageApi(listener => {
            //Api.getDashboardRealEstate(listener)
            if (this.props.defaultDashboard) {
                listener({ status: true, payload: this.props.defaultDashboard })
            }
        }, payload => ({
            dashboard: payload
        }))
    }

    getLayout() {
        const dashboard = this.state.dashboard;
        return (
            <div style={{ width: '100%', padding: '1rem' }}>
                {!this.props.skipHeader && <>
                    <div style={{ display: "flex", alignItems: 'center', width: '100%' }}>
                        <div style={{ display: "flex", alignItems: 'center', borderRadius: 15, paddingInline: '0rem', paddingBlock: '0.5rem', background: 'white', }}>
                            <Dashboard32 style={{ marginRight: '0.5rem' }} />
                            <div style={{ display: "flex", alignItems: 'flex-end', }}>
                                <h2 style={{ fontWeight: 'bold' }}>Welcome to Robotic</h2>
                                <h4 style={{ marginLeft: '0.5rem', flex: 1 }}>{getAccount().fullName}</h4>
                            </div>
                        </div>
                        <div style={{ flex: 1 }} />
                        <Link to="/system-logs" style={{ textDecoration: 'none' }}>
                            <Button size="sm" style={{ borderRadius: 50 }} renderIcon={Archive32}>View System Logs</Button>
                        </Link>
                    </div>
                    <Padding />
                </>}

                <TopOverviewLms dashboard={dashboard} />

                <Padding />
                <Padding />
                <Padding />
                <Padding />
                {/* 

                <Projects dashboard={dashboard} />
                <Padding />
                <Padding />
                <Padding />
                <Padding /> */}


                {/* <ProfitGraph dashboard={dashboard} /> */}
                <div style={{ padding: '1rem' }}>
                    <h3 style={{ fontWeight: 'bold' }}>Profit Performance</h3>
                    <div className="square-stat-0" style={{ flex: 1, borderRadius: 25, width: '100%', padding: '1rem' }}>
                        <LMSWeeksProfitView dashboard={dashboard} />
                    </div>
                </div>


                <Padding />
                <Padding />
                <Padding />
                <Padding />


                <div style={{ padding: '1rem' }}>
                    <h3 style={{ fontWeight: 'bold' }}>Financials</h3>
                    <Padding />
                    <div style={{ width: '100%', display: 'flex', gap: '3rem' }}>
                        <div style={{ flex: 1 }}>
                            <UpcomingChequesView dashboard={dashboard} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <UnpaidStockFlowsView dashboard={dashboard} />
                        </div>
                    </div>
                </div>
                <Padding />
            </div>
        )
    }

    isPageLoadable() {
        return true;
    }

}

export default LMSDashboardPage;