import React, { useMemo, useRef, useState } from 'react'
import useSize from '../../util/useSize'
import './GridListTransition.scss'

const MODE_LIST = 0;
const MODE_GRID = 1;
const GRID_COLUMN_COUNT = 3;
const LIST_ITEM_HEIGHT = 45

const calcItemDim = (mode, rootWidth) => (mode == MODE_LIST ? {
    itemWidth: rootWidth,
    itemHeight: LIST_ITEM_HEIGHT
} : {
    itemWidth: rootWidth / GRID_COLUMN_COUNT,
    itemHeight: rootWidth / GRID_COLUMN_COUNT,
})
const calcRootHeight = (mode, itemHeight, itemCount) => (mode == MODE_LIST ? (
    itemHeight * itemCount
) : (
    itemHeight * Math.ceil(itemCount / GRID_COLUMN_COUNT)
))
const useDim = (rootRef, mode, itemCount) => {
    const { width: rootWidth } = useSize(rootRef)
    const { itemWidth, itemHeight } = useMemo(() => calcItemDim(mode, rootWidth), [mode, rootWidth])
    const rootHeight = useMemo(() => calcRootHeight(mode, itemHeight, itemCount), [mode, itemHeight, itemCount])

    return { rootWidth, rootHeight, itemWidth, itemHeight };
}

const useItemPos = (itemWidth, itemHeight, mode, index) => {
    if (mode == MODE_LIST) {
        return { x: 0, y: itemHeight * index }
    } else {
        const row = Math.floor(index / GRID_COLUMN_COUNT)
        const column = index % GRID_COLUMN_COUNT;
        return { 
            x: column * itemWidth,
            y: row * itemHeight
        }
    }
}

const ItemWrapper = ({itemWidth, itemHeight, mode, index, ...props}) => {
    const { x, y } = useItemPos(itemWidth, itemHeight, mode, index)
    return (
        <div className="grid-list-item" style={{...props.style, transform: `translate(${x}px,${y}px)`}}>
            {props.children}
        </div>
    )
}

export default ({mode, ...props}) => {
    const itemCount = props.children.length;
    const rootRef = useRef()
    //const [mode, setMode] = useState(MODE_LIST)


    const { rootHeight, itemWidth, itemHeight } = useDim(rootRef, mode, itemCount)

    return (
        <div ref={rootRef} className="grid-list-root" style={{height: rootHeight}}>
            {props.children.map((item, index) => React.createElement(ItemWrapper, {
                key: index + "-grid-list-item",
                style: { width: itemWidth, height: itemHeight },
                itemWidth, itemHeight, index, mode
            }, item))}
        </div>
    )
}