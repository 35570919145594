import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import { NotificationFilled32, ArrowLeft16 } from '@carbon/icons-react'
import Util from "../../../util/Util"
import { Tag, Link as DLink } from "carbon-components-react"
import { useHistory } from "react-router-dom"
import { getReportLink } from "../../../reporting-engine/base/report-page"
import { getAccountRole } from "../../../session/SessionManager"
import { ACCOUNT_TYPE_TUTOR } from "../../../constants/Constants"
import { isMobileScreen } from "../../../App"


function View({ payload: notification }) {
    const history = useHistory();
    return (
        <div>
            <div style={{ paddingInline: isMobileScreen ? '1rem' : '6rem', paddingTop: isMobileScreen ? '1rem' : '3rem' }}>
                <DLink onClick={() => {
                    if (getAccountRole() == ACCOUNT_TYPE_TUTOR) {
                        history.push(getReportLink("MyLmsNotificationList"))
                    } else {
                        history.push(getReportLink("LmsNotificationList"))
                    }
                }} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem' }}>
                    <div style={isMobileScreen ? { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' } : {}}>
                        <NotificationFilled32 style={{ width: 64, height: 64 }} />
                        <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>Notification</h3>
                        <h4>{notification.id}</h4>
                    </div>
                    <div style={{ flex: 1 }} />
                </div>


                <div style={{ borderRadius: 10, overflow: 'hidden', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', background: 'white', border: '1px solid #00000020', width: '100%' }}>

                    <div style={{ height: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', borderBottom: '1px solid #00000020' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>To</p>
                        <div style={{ flex: 1 }} />
                        <p>{notification.toTarget}</p>
                    </div>

                    <div style={{ height: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', background: '#00000010', borderBottom: '1px solid #00000020' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Date</p>
                        <div style={{ flex: 1 }} />
                        <p>{Util.getFullDate(notification.dateSent)}</p>
                    </div>
                    <div style={{ minHeight: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', background: '#00000000', flexWrap: 'wrap', borderBottom: '1px solid #00000020' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Email Addresses</p>
                        <div style={{ flex: 1 }} />

                        {notification.resolvedEmailAddresses.map(email => <Tag key={email} type="blue">{email}</Tag>)}
                    </div>
                    <div style={{ height: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', background: '#00000010', borderBottom: '1px solid #00000020' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Message:</p>
                        <div style={{ flex: 1 }} />
                        {/* <p>{Util.getFullDate(notification.dateSent)}</p> */}
                    </div>
                    <div style={{ padding: '1rem', width: '100%' }}>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{notification.message}</p>
                    </div>
                </div>


                <div style={{ height: '3rem' }} />

            </div>
        </div>
    )
}

export const LmsNotificationDetail = withLoadablePageWithParams(params => listener => Api.getLmsNotificationRecord(params.id, listener), View)
