import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_DELIVERY_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { Archive32 } from '@carbon/icons-react'

export const CRVDetail = {
    filter: Filter,
    freezeCol: 2,
    isAvailable: () => {
        // if (!isV2()) {
        //     return false;
        // }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_DELIVERY_MANAGER;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter={false} />
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Name" fieldKey={"nameField"}
                options={endpoint.staff.map(item => item.value)} state={state} property="source"
                filterMapper={filter => [{
                    operator: "OR",
                    filters: [
                        filter,
                        { property: "source", operator: "EQ", value: "CRV" },
                    ]
                }]}
            />
        </div>
    </>)
}
