import { InlineLoading, Tag } from "carbon-components-react"
import { useForceLockScroll } from "../../../hooks/useLockScroll"
import useSize, { useRefSize } from "../../../util/useSize"
import './seat-designer.scss'
import { MapBoundary32, Add16, Subtract16, ErrorOutline16, CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { useEffect, useState } from "react"
import { useGrid } from "./useGrid"
import { SeatItem } from "./seat-item"
import ReactTooltip from "react-tooltip"
import { Inspector } from "./inspector"
import Api from "../../../session/Api"
import UIUtil from "../../../util/UIUtil"
import { useSeatDesigner } from "./useSeatDesigner"


function Toolbar({ saving, onSaveBtn }) {
    return (
        <div className='' style={{ paddingInline: '1rem', height: '4rem', width: '100%', display: 'flex', alignItems: 'center', gap: '0.5rem', background: '#0f0f0f', color: 'white', borderBottom: '1px solid #ffffff20' }}>
            <MapBoundary32 style={{}} />
            <div style={{ flex: 1 }}>
                <h4>Designer</h4>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Theater seats</p>
            </div>
            {/* {hasAnySelections && <button onClick={onClearSelectionBtn} data-tip="Unselect All" data-effect="solid" className='lead-flow-icon-button lead-flow-icon-button-light'>
                <ErrorOutline16 />
            </button>} */}
            <button disabled={saving} onClick={onSaveBtn} data-tip="Save" data-effect="solid" className='lead-flow-icon-button lead-flow-icon-button-primary really_centered-progress-bar'>
                {saving ? <InlineLoading /> : <CheckmarkFilled16 />}
            </button>
        </div >
    )
}

const SeatTypeTag = ({ color, name }) => {
    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)
    return (
        <Tag
            size="sm"
            style={{
                color: isLightColor(color ?? "#000000") ? "black" : "white",
                background: color ?? "#000000",
            }}>{name}</Tag>
    )
}


const View = ({ defaultState, itemId, endpoints, onGridRawUpdate }) => {
    const [canvasRef, canvasSize] = useRefSize()
    const grid = useGrid(endpoints.design, onGridRawUpdate, defaultState);
    const seatDesigner = useSeatDesigner(itemId, grid);

    return (
        <div style={{
            //width: '100%', height: '100%', 
            position: 'relative',
            minWidth: 0,
            display: 'flex',
            //justifyContent: 'center', 
            alignItems: 'center',
            background: '#1c1c1c', borderRadius: 15,
            border: '1px solid #00000090', paddingBlock: '4rem', paddingInline: '3rem',
        }}>
            <div style={{ position: 'absolute', left: '1rem', top: '1rem', color: 'white' }}>
                <h4>Select Seats</h4>
            </div>

            {grid.hasAnySelections() &&
                <div style={{ paddingBlock: '0.5rem', paddingInline: '1rem' }} className="seat-designer-clear-selection-button" onClick={grid.clearSelections}>
                    <ErrorOutline16 />
                    <p style={{ fontSize: 12 }}>Deselect All</p>
                </div>}

            <div className="seat-designer-legend-bar" style={{ opacity: 1 }}>
                {endpoints.seatTypes.map(type => <SeatTypeTag key={type.id} color={type.color ?? '#b8730b'} name={type.name} />)}
            </div>

            {/* <div style={{ minWidth: 3000, height: 50, background: 'red' }} /> */}

            <div style={{ zIndex: 2, marginLeft: 'auto' }}>
                <div style={{ color: 'white', width: 25, gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: canvasSize.height - 55, marginTop: 55 - 25, marginRight: '0.5rem', background: 'black', borderRadius: 50, border: '1px solid #ffffff40', zIndex: 2 }}>
                    <div style={{ width: 25, height: '0.75rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    </div>
                    {grid.rows().map(({ row, value }) => (
                        <div key={row + '-row'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 40, height: 40, width: 40 }}>
                            <span style={{ fontSize: 12 }}>{value}</span>
                        </div>
                    ))}
                    <div style={{ width: 25, height: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    </div>
                </div>
            </div>
            <div style={{ marginRight: 'auto' }}>
                <div ref={canvasRef} style={{ position: 'relative' }} className="seat-designer-canvas">
                    <div className="pos-cinema-screen" />
                    <div style={{ height: 55, width: '100%', display: 'flex', paddingTop: '0.75rem', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Tag size="sm" style={{ zIndex: 1 }} type="high-contrast">screen</Tag>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', height: 1, background: '#ffffff20' }} />
                    </div>
                    <div style={{ width: '100%', padding: '1rem', color: 'white', display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {grid.grid().map((row, rowIndex) => (
                            <div key={rowIndex + '-row-seats'} style={{ width: '100%', height: 40, display: 'flex', gap: 2 }}>
                                {row.map((_, colIndex) => (
                                    <SeatItem key={rowIndex + '-' + colIndex + '-seat'} row={rowIndex} col={colIndex} grid={grid} />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ position: 'relative', color: 'white', height: 25, gap: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: canvasSize.width, marginTop: '0.5rem', background: 'black', borderRadius: 50, border: '1px solid #ffffff40', zIndex: 4 }}>
                    <div style={{ height: 25, width: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    </div>
                    {grid.cols().map(({ col, value }) => (
                        <div key={col + '-col'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 40, height: 40, width: 40 }}>
                            <span style={{ fontSize: 10 }}>{value}</span>
                        </div>
                    ))}
                    <div style={{ height: 25, width: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function SeatSelector({ defaultState, itemId, onGridRawUpdate }) {
    const [loading, setLoading] = useState(true);
    const [endpoints, setEndpoints] = useState(undefined)

    useEffect(() => {
        setLoading(true);
        setEndpoints(undefined);

        Api.getCinemaPosSeatPlan(itemId, response => {
            if (response.status === true) {
                setEndpoints(response.payload)
                setLoading(false);
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [itemId])

    if (loading) {
        return (
            <div className='really_centered-progress-bar' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading />
            </div>
        )
    } else {
        return <View defaultState={defaultState} itemId={itemId} endpoints={endpoints} onGridRawUpdate={onGridRawUpdate} />
    }
}