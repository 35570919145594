import Button from "../../../components/Button";
import Util from "../../../util/Util";
import { ErrorFilled16 } from '@carbon/icons-react'
import UIUtil from "../../../util/UIUtil";
import { useState } from "react";
import Api from "../../../session/Api";


export function RecurringStatusView({ item, onFree }) {
    const [loading, setLoading] = useState(false);

    const onFreeBtn = () => {
        setLoading(true);
        Api.freeActivityFromCommonId(item.id, response => {
            if (response.status === true) {
                onFree(response.payload);
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    if (!Util.isStringExists(item?.commonSharedId)) {
        return null;
    }
    return (
        <div style={{ padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem', gap: '0.5rem' }}>
                <label style={{ flex: 1, color: 'black', fontWeight: 'bold', margin: 0 }} className="bx--label">Recurring</label>
            </div>
            <div style={{ background: '#1c1c1c', borderRadius: 10, display: 'flex', alignItems: 'center', paddingInline: '0.5rem', paddingBlock: '0.5rem', minHeight: 60 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginRight: '1rem', flex: 1, color: 'white' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Free this event so you can change its schedule without effecting other occurrences</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                    <Button loading={loading} onClick={() => UIUtil.confirm(onFreeBtn)} kind="secondary" renderIcon={ErrorFilled16} style={{ borderRadius: 10, marginRight: '0.25rem', height: 'calc(100% - 1rem)' }} size="sm">Free Event</Button>
                </div>
            </div>
        </div>
    )
}