import React, { useEffect, useState } from 'react'

import { confirmAlert } from 'react-confirm-alert'; // Import
import { ButtonSet, ModalBody, ModalHeader, TextInput } from "carbon-components-react";
import Button from "../components/Button";
import { InlineLoading } from 'carbon-components-react';
import Api from '../session/Api';
import { API_RESPONSE_EMAIL_NOT_FOUND } from '../constants/Constants';
import UIUtil from '../util/UIUtil';

const UI = ({onClose, stockFlowId, customerId}) => {
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");

    useEffect(() => {
        setLoading(true)
        const listener = response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onClose();
            } else {
                if (response.payload == API_RESPONSE_EMAIL_NOT_FOUND) {
                    setLoading(false);
                } else {
                    UIUtil.showError(response.message)
                    onClose();
                }
            }
        };
        
        if (stockFlowId) {
            Api.sendReceiptEmail(stockFlowId, undefined, listener);
        } else if (customerId) {
            Api.sendCustomerCardEmail(customerId, undefined, listener);
        } else {
            UIUtil.showError();
            onClose();
        }
    }, [stockFlowId])

    const send = () => {
        setLoading(true)
        const listener = response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onClose();
            } else {
                if (response.payload == API_RESPONSE_EMAIL_NOT_FOUND) {
                    setLoading(false);
                } else {
                    UIUtil.showError(response.message)
                    onClose();
                }
            }
        };
        
        if (stockFlowId) {
            Api.sendReceiptEmail(stockFlowId, email, listener);
        } else if (customerId) {
            Api.sendCustomerCardEmail(customerId, email, listener);
        } else {
            UIUtil.showError();
            onClose();
        }
    }

    if (loading) {
        return <InlineLoading />
    } else {
        return (
            <div style={{background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden'}}>
                <ModalHeader label="Email" title="Specify Email" />
                <ModalBody style={{paddingRight: '1rem'}}>
                    <TextInput
                    light
                    style={{width: '100%'}}
                    data-modal-primary-focus
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    labelText="Email"
                    placeholder="Input email"
                    />
                </ModalBody>
                <ButtonSet>
                    <Button kind="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        // onConfirm();
                        send();
                        // onClose()
                    }}>
                        Confirm
                    </Button>
                </ButtonSet>
            </div>
        )
    }
}

class EmailSender {

    sendSalesReceipt(stockFlowId) {
        confirmAlert({
            customUI: ({onClose}) => <UI onClose={onClose} stockFlowId={stockFlowId} />,
            overlayClassName: "pos-dialog-overlay",
        })
    }

    sendCustomerCard(customerId) {
        confirmAlert({
            customUI: ({onClose}) => <UI onClose={onClose} customerId={customerId} />,
            overlayClassName: "pos-dialog-overlay",
        })
    }

}

export default EmailSender;