
import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'

const PDF = ({qr}) => {
    return (
        <Document>
            <Page size={[250, 250]}>
                {/* <Text style={{fontSize: 8, fontWeight: 'bold', width: '100%', height: 10, marginTop: 10, textAlign: 'center'}}>{product.name}</Text> */}
                <Image src={qr} style={{width: '100%', height: 250 - 10 - 10, objectFit: 'contain', objectPosition: 'center'}} />
            </Page>
        </Document>
    )
}

export const printClearanceCodeQrCode = async (clearanceCode) => {
    

    const canvas = document.createElement('canvas');
    QRCode.toCanvas(canvas, clearanceCode);
    const qr = canvas.toDataURL();

    const blob = await pdf(<PDF qr={qr} />).toBlob();
    print(URL.createObjectURL(blob));
}