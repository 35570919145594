import { Accordion, AccordionItem, Tag } from "carbon-components-react";
import { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import ProfilePic from "../../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../../constants/ObjectTypes";
import Api from "../../../../session/Api";
import UIUtil from "../../../../util/UIUtil";
import { ActivityStatusDetailsContent } from "../../dialogs/activity-status-details";
import { ActivityStatusTag, statusSummary, statusSummaryUserId } from "./status-view";
import { Copy16 } from '@carbon/icons-react'
import { SetActivityStatusDialog } from "../../dialogs/set-activity-status";

const statusToItem = status => ({ statusId: status.statusId, statusColor: status.statusColor, statusName: status.statusName, latestStatusRecord: status })

const Title = ({ status, current, smallWidth }) => {
    const item = statusToItem(status)

    if (smallWidth) {
        return (
            <div style={{}}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%' }}>
                    <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, statusSummaryUserId(item))} />
                    <ActivityStatusTag item={item} />
                    {/* <div style={{ flex: 1 }} /> */}
                    <Tag style={{ opacity: current ? 1 : 0, }} type="high-contrast" size="sm">Current</Tag>
                </div>
                <p style={{ fontSize: 12, opacity: 0.65, marginTop: '0.5rem' }}>{statusSummary(item)}</p>
            </div>
        )
    } else {
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flex: 1 }}>
                    <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, statusSummaryUserId(item))} />
                    <ActivityStatusTag item={item} />
                </div>
                <p style={{ fontSize: 14, marginLeft: '2rem', flex: 4 }}>{statusSummary(item)}</p>
                <Tag style={{ opacity: current ? 1 : 0, marginRight: '1rem' }} type="high-contrast" size="sm">Current</Tag>

            </div>
        )
    }
}

const Content = ({ status, activity }) => {
    const [replicate, setReplicate] = useState(false)

    const item = { ...statusToItem(status), id: activity.id };
    return (<>
        <ActivityStatusDetailsContent item={item} skipBanner hasNoDetailsMsg />
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '1rem' }}>
            <Button onClick={() => setReplicate(true)} style={{ borderRadius: 10 }} renderIcon={Copy16} size="sm" kind="secondary">Replicate</Button>
        </div>

        {replicate &&
            <SetActivityStatusDialog item={item} open replicateRequest onClose={() => setReplicate(false)} />}
    </>)
}

export function StatusHistory({ item, smallWidth }) {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        Api.getActivityStatusHistory(item.id, response => {
            if (response.status === true) {
                setRecords(response.payload)
            } else {
                UIUtil.showError("Could not load activity history");
            }
        })
    }, [])

    return (
        <Accordion className={`center-accordion-header ${smallWidth ? 'no-padding-accordion' : ''}`}>
            {records.map((record, index) =>
                <AccordionItem key={record.id} title={<Title status={record} current={index === 0} smallWidth={smallWidth} />}>
                    <Content status={record} activity={item} />
                </AccordionItem>)}
        </Accordion>
    )
}