import { Education16, Event16, GroupPresentation16, UserSpeaker16, Calendar16, Collaborate16, OverflowMenuHorizontal16 } from '@carbon/icons-react'
import { OBJECT_TYPE_ACTIVITY, OBJECT_TYPE_COURSE, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_LMS_GROUP, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR } from '../../../constants/ObjectTypes'
import { isMobileScreen } from '../../../App'

function MenuButton({ label, active, onClick, fullWidth, children }) {
    return <button style={{ width: fullWidth ? 30 : 100, paddingInline: fullWidth ? 0 : undefined }} onClick={onClick} data-active={active} className='report-engine-menu-button-light'>{children}</button>
}


export function TargetTypes({ tabIndex, setTabIndex }) {
    return (
        <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem', flexWrap: 'wrap' }}>
            <MenuButton active={tabIndex === OBJECT_TYPE_LMS_GROUP} onClick={() => setTabIndex(OBJECT_TYPE_LMS_GROUP)}><Event16 style={{ marginRight: 2 }} /> {isMobileScreen ? "Grp" : "Group"}</MenuButton>
            <MenuButton active={tabIndex === OBJECT_TYPE_COURSE} onClick={() => setTabIndex(OBJECT_TYPE_COURSE)}><GroupPresentation16 style={{ marginRight: 2 }} /> Course</MenuButton>
            <MenuButton active={tabIndex === OBJECT_TYPE_ACTIVITY} onClick={() => setTabIndex(OBJECT_TYPE_ACTIVITY)}><Calendar16 style={{ marginRight: 2 }} /> Class</MenuButton>
            <MenuButton active={tabIndex === OBJECT_TYPE_TUTOR} onClick={() => setTabIndex(OBJECT_TYPE_TUTOR)}><UserSpeaker16 style={{ marginRight: 2 }} /> Tutor</MenuButton>
            <MenuButton active={tabIndex === OBJECT_TYPE_STUDENT} onClick={() => setTabIndex(OBJECT_TYPE_STUDENT)}><Education16 style={{ marginRight: 2 }} /> {isMobileScreen ? "Stu" : 'Student'}</MenuButton>
            <MenuButton active={tabIndex === OBJECT_TYPE_GUARDIAN} onClick={() => setTabIndex(OBJECT_TYPE_GUARDIAN)}><Collaborate16 style={{ marginRight: 2 }} /> Guardian</MenuButton>

            <MenuButton active={tabIndex === -1} onClick={() => setTabIndex(-1)}><OverflowMenuHorizontal16 style={{ marginRight: 2 }} /> Mix</MenuButton>
        </div>
    )
}