import { QrCode16, Receipt16, Archive16, Information16, ShoppingCart16, ChartRelationship16, ServiceDesk16, Analytics16, Cube16 } from '@carbon/icons-react'
import { useCallback, useMemo, useState } from 'react';
import Util from '../../../../../util/Util';

function useHint() {
    const [message, setMessage] = useState("");
    return {
        message: Util.isStringExists(message) ? message : null,
        init: (message) => ({
            onMouseEnter: () => setMessage(message),
            onMouseLeave: () => setMessage("")
        })
    }
}

function Button({ hint, label, active, onClick, children }) {
    return <button {...hint.init(label)} style={{ width: 30 }} onClick={onClick} data-active={active} className='shipment-item-menu-button'>{children}</button>
}

export function Menu({ fnbEvent, sideView, setSideView }) {
    const hint = useHint();
    const MenuButton = useCallback(props => <Button {...props} hint={hint} />, [])

    return (<>
        {hint.message && <p key={hint.message + 'shipment-toolbar-menu'} style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', animation: '250ms shipment-item-menu-hint-fade-in' }}>
            {hint.message}
        </p>}
        <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
            <MenuButton label="Event" active={sideView === "detail"} onClick={() => setSideView(sideView !== "detail" ? "detail" : undefined)}><Information16 /></MenuButton>
            {/* <MenuButton label="Documents" active={sideView === "documents"} onClick={() => setSideView(sideView !== "documents" ? "documents" : undefined)}><Archive16 /></MenuButton> */}
            {fnbEvent.orderId > 0 &&
                <MenuButton label="Sales Order" active={sideView === "salesOrder"} onClick={() => setSideView(sideView !== "salesOrder" ? "salesOrder" : undefined)}><ShoppingCart16 /></MenuButton>}

            {/* {shipment.dirType === SHIPMENT_DIR_TYPE.EXPORT &&
                <MenuButton label="Master" active={sideView === "master"} onClick={() => setSideView(sideView !== "master" ? "master" : undefined)}><ChartRelationship16 /></MenuButton>}
            
            <MenuButton label="Profit/Loss" active={sideView === "profit"} onClick={() => setSideView(sideView !== "profit" ? "profit" : undefined)}><Analytics16 /></MenuButton>
            <MenuButton label="Services/Charges" active={sideView === "services"} onClick={() => setSideView(sideView !== "services" ? "services" : undefined)}><ServiceDesk16 /></MenuButton>
            <MenuButton label="Invoicing" active={sideView === "invoice"} onClick={() => setSideView(sideView !== "invoice" ? "invoice" : undefined)}><Receipt16 /></MenuButton>
            
            <MenuButton label="QR Code" active={sideView === "qrcode"} onClick={() => setSideView(sideView !== "qrcode" ? "qrcode" : undefined)}><QrCode16 /></MenuButton> */}
        </div>
    </>)
}