
import React from 'react';
import { Page, Text, View, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';

import { Receipt16, } from '@carbon/icons-react'
import Invoice from './pdf/Invoice';
import Util from '../../util/Util';

const PDF = ({journalEntry}) => (
    <Invoice journalEntry={journalEntry} />
);


export const printJournalVoucher = async (journalEntry) => {
    const blob = await pdf(<PDF journalEntry={journalEntry} />).toBlob();
    print(URL.createObjectURL(blob));
}

export const JournalVoucherDownload = ({journalEntry}) => {
    const [instance, updateInstance] = usePDF({ document: <PDF journalEntry={journalEntry} /> });

    return (
        <a href={instance.url} target="_blank" style={{width: '100%', textDecoration: 'none'}}>
            <Button style={{width: '100%'}} loading={instance.loading} disabled={instance.error} renderIcon={Receipt16}>Journal Voucher</Button>
        </a>
    )
}