
import {
    Document32, Notebook16, ArrowLeft16, Report32, Run32, Calendar32, DocumentPdf32, ErrorFilled16, Currency32,
    Currency16, ArrowRight16, DocumentSentiment32, Catalog32, Money32, List32, TreeView32, RequestQuote16, Notebook32,
    Migrate16, Activity16, Theater32, DocumentSentiment24
} from '@carbon/icons-react'
import { ButtonSet, Link as DLink, Tag } from "carbon-components-react"
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { withLoadablePageWithParams } from '../../base/Page';
import Button from '../../components/Button';
import ImageView from '../../components/ImageView';
import ProfilePic from '../../components/ProfilePic';
import { ACCOUNT_TYPE_TUTOR } from '../../constants/Constants';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_MOVIE, OBJECT_TYPE_PAYOUT } from '../../constants/ObjectTypes';
import { PayoutStatusIndicator, PAYOUT_STATUS_NOT_PAID } from '../../domain/payout';

import useApi from '../../hooks/useApi';
import Api from '../../session/Api';
import { getAccount, getAccountRole } from '../../session/SessionManager';
import UIUtil from '../../util/UIUtil';
import Util, { big } from '../../util/Util';
import NoteView from '../../views/notes/NoteView';
import TransactionEditor from '../transaction/TransactionEditor';
import { isMobileScreen } from '../../App';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const getAmountStatus = payout => {
    const amount = payout.amount;
    const amountPaid = payout.amountPaid;

    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Fully Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;

        status += " (AED " + amountLeft.toFixed(2) + " left)";
    }

    return { status, statusColor }
}

const Section = ({ id, children, icon, title, subtitle, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const TransactionItem = ({ payout, transaction }) => {
    const [expanded, setExpanded] = useState(false);

    const paymentMethodHeight = 80 + convertRemToPixels(0.5);

    let dividedAmount = 0;
    if (transaction.againstItems && transaction.againstItems.length !== undefined) {
        for (const item of transaction.againstItems) {
            if (item.payoutId == payout.id) {
                dividedAmount = item.dividedAmount;
                break;
            }
        }
    }

    return (
        <div style={{
            transition: '250ms', height: expanded ? 75 + (115 + (paymentMethodHeight * transaction.paymentMethods.length - 1)) : 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>Transaction (voucher no: {Util.getVoucherNumber(transaction.id)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>AED {dividedAmount.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / AED {transaction.amount.toFixed(2)} (Total Transaction Amount)</p>
                    </div>



                    {/* <h2>AED {transaction.amount.toFixed(2)}</h2> */}
                </div>
                <p style={{ fontSize: 12, opacity: 0.65 }}>View details</p>
                <ArrowRight16 style={{ opacity: 0.65, transition: '250ms', marginLeft: '0.25rem', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
            </div>
            <div style={{ height: 115 + (paymentMethodHeight * transaction.paymentMethods.length - 1), paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, overflow: 'auto' }} >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link target="_blank" to={"/transaction/" + transaction.id} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Open Transaction</Button>
                    </Link>
                </div>

                <TransactionEditor
                    hideTotal
                    readOnly showColorForIncome
                    defaultPaymentMethods={transaction.paymentMethods}
                    currency={"AED"}
                    //incoming={!isSourceExternal}
                    incoming={false}
                    totalAmount={transaction.amount} />
            </div>
        </div>
    )
}



const Title = ({ payout }) => {
    const history = useHistory();

    const [loading, revoke] = useApi(listener => Api.revokePayout(payout.id, listener), () => window.location.reload());

    if (isMobileScreen) {
        return (
            <div style={{ background: "#f4f4f4", color: 'black', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000020', paddingInline: '1rem', width: 'calc(100% + 10vw)', marginLeft: '-5vw', height: "3rem", gap: '0.5rem' }}>
                <button onClick={() => history.goBack()} className='lead-flow-icon-button' style={{ marginRight: '1rem' }}>
                    <ArrowLeft16 />
                </button>
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 35, height: 35 }}>
                    
                </div> */}
                <DocumentSentiment24 />
                <h4 style={{ fontWeight: 'bold', flex: 1, marginLeft: '-0.25rem' }}>Payout</h4>
                <PayoutStatusIndicator status={payout.status} />
                {payout.live && <Tag size="sm" type="magenta" renderIcon={Activity16}>Live</Tag>}
            </div>
        )
    }

    return (<>
        <DLink onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <DocumentSentiment32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <h1 style={{ flex: 1 }}>Payout</h1>

            {getAccountRole() != ACCOUNT_TYPE_TUTOR && <>
                {payout.status == PAYOUT_STATUS_NOT_PAID ? (
                    <ButtonSet style={{ width: '392px', gap: 2 }}>
                        <Button loading={loading} onClick={() => UIUtil.confirm(revoke)} kind="danger" renderIcon={ErrorFilled16} style={{ width: 195 }}>
                            Revoke
                        </Button>
                        <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                    </ButtonSet>
                ) : (
                    <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                )}
            </>}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <PayoutStatusIndicator status={payout.status} />
            {payout.live && <Tag size="sm" type="magenta" renderIcon={Activity16}>Live</Tag>}
        </div>
    </>)
}

const Amount = ({ payout }) => {
    const amount = payout.amount;
    const amountPaid = payout.amountPaid;
    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;
    }

    return (<>
        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
            <p>
                AED {amount.toFixed(2)}
            </p>
        </div>

        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
            <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                AED {amountPaid.toFixed(2)}
            </p>
        </div>
    </>)
}

const PrintBtn = ({ payout }) => {
    let btn;
    if (Util.isNumberExist(payout.purchaseId)) {
        btn = (
            <Link to={"/purchase/" + payout.purchaseId} style={{ textDecoration: 'none' }}>
                <Button kind="secondary" style={{ width: '100%' }} renderIcon={Migrate16}>Purchase Voucher</Button>
            </Link>
        )
    } else {
        //btn = <PurchaseVoucherDownload stockFlow={this.state.stockFlow} />
        btn = null;
    }

    if (btn) {
        return (
            <div style={{ marginBottom: '2rem' }}>
                {btn}
            </div>
        )
    } else {
        return null;
    }
}

const Info = ({ payout }) => (
    <Section icon={Report32} title="Document Info">
        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
            <p style={{}}>{Util.getVoucherNumber(payout.id)}</p>
        </div>

        {getAccountRole() != ACCOUNT_TYPE_TUTOR && (
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Payee'}</label>
                <Link target="_blank" to={getObjectTypeUrl(payout.payeeType) + '/' + payout.payeeId}><p style={{}}>{payout.payeeName}</p></Link>
            </div>)}

        <PrintBtn payout={payout} />

        <Amount payout={payout} />

        {/* <Link target="_blank" to={"/journal-entry/" + payout.journalEntryId} style={{ textDecoration: 'none', }}>
            <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: 195 }}>Associated Journal Entry</Button>
        </Link> */}
    </Section>

)

const Initiation = ({ payout }) => (
    <Section icon={Run32} title="Initiation">
        <label style={{}} className="bx--label">Initiator</label>
        <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={Util.isNumberExist(payout.initiatorId) ? getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + payout.initiatorId : ''}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, payout.initiatorId)} />
                <p>{payout.initiatorFullName}</p>
            </div>
        </Link>

        <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar32 style={{ marginRight: '0.5rem' }} />
            <p>{Util.getDate(payout.initiationDate)}</p>
        </div>
    </Section>
)

const Transactions = ({ payout }) => (
    <Section icon={Currency32} title="Transactions" //subtitle={'Total Amount Paid: AED ' + parseFloat(stockFlow.amountPaid).toFixed(2)}
        subtitle={getAmountStatus(payout).status} subtitleColor={getAmountStatus(payout).statusColor}
    >
        {payout.transactions.map(transaction => <TransactionItem key={transaction.id} payout={payout} transaction={transaction} />)}
    </Section>
)

const AmountBreakdown = ({ payout }) => (
    <Section icon={Money32} title="Amount Breakdown">
        {Util.isNumberExist(payout.subtotal) && <>
            <p style={{ fontSize: 14 }}>Subtotal (+)</p>
            <h2>AED {payout.subtotal.toFixed(2)}</h2>
        </>}

        {Util.isNumberExist(payout.tax) && <>
            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Tax (+)</p>
            <h2>AED {payout.tax.toFixed(2)}</h2>
        </>}

        <p style={{ marginTop: '1rem' }}>Total Amount</p>
        <h1 style={{ color: 'green' }}>AED {payout.amount.toFixed(2)}</h1>
    </Section>
)


const ItemRecord = ({ item, }) => {
    // const total = useMemo(() => big(item.qty).times(big(item.unitAmount)).toFixed(2), [item])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', height: 40 }}>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.description}</p>
            </div>
            {/* <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>AED {item.unitAmount}</p>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.qty}x</p>
            </div> */}
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>AED {item.amount}</p>
            </div>
        </div>
    )
}

const ItemsTable = ({ payout }) => {
    const items = payout.items;
    return (
        <Section icon={List32} title="List">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Description</h6>
                    </div>
                    {/* <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Unit Amount</h6>
                    </div>
                    <div style={{ flex: 1, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Qty</h6>
                    </div> */}
                    <div style={{ flex: 1, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <ItemRecord key={item.id} item={item} />)}
            </div>
        </Section>
    )
}


const AccountRecord = ({ item, }) => {
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', height: 40 }}>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.accountLedgerName}</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>AED {item.amount}</p>
            </div>
            <div style={{ flex: 4, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.narration}</p>
            </div>
        </div>
    )
}

const AccountsTable = ({ payout }) => {
    const items = payout.accountDivisions;
    return (
        <Section icon={List32} title="Expense Accounts">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <AccountRecord key={item.id} item={item} />)}
            </div>
        </Section>
    )
}

const MovieInfo = ({ payout }) => {
    const openTicketQrCode = ticket => {
        UIUtil.showQrCode('TI-ID-' + btoa((ticket.id).toString()))
    }
    return (
        <Section icon={Theater32} title="Movie Info">
            <div style={{ width: '100%', background: '#f4f4f4', borderRadius: 10, overflow: 'hidden', border: '1px solid #00000020', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', marginBottom: '1rem', display: 'flex', padding: '1rem' }}>
                <div style={{ width: 150, minWidth: 150, height: 225, borderRadius: 10, overflow: 'hidden', border: '1px solid #00000040' }}>
                    <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, payout.cinemaMovieId)} style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ marginLeft: '3rem', flex: 1 }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem', }}>
                        <h3 style={{ flex: 1 }}>{payout.ticketSaleMovieName}</h3>
                        <Link to={'/movies/' + payout.cinemaMovieId}>
                            <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                                <ArrowRight16 />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </Section>
    )
}


const View = ({ payload: payout }) => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: isMobileScreen ? '0rem' : '6rem', paddingBottom: '6rem' }}>
            <div style={{ width: isMobileScreen ? '90vw' : '75vw' }}>
                <Title payout={payout} />
                {Util.isNumberExist(payout.cinemaMovieId) && <MovieInfo payout={payout} />}
                <Info payout={payout} />
                {getAccountRole() != ACCOUNT_TYPE_TUTOR && Util.isNumberExist(payout.journalEntryId) && <Section icon={TreeView32} title="Accounting">
                    <Link target="_blank" to={"/journal-entry/" + payout.journalEntryId} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: undefined }}>Associated Journal Entry</Button>
                    </Link>
                </Section>}

                {payout.items !== null && payout.items.length > 0 &&
                    <ItemsTable payout={payout} />}


                {getAccountRole() != ACCOUNT_TYPE_TUTOR && payout.transactions !== null && payout.transactions.length > 0 &&
                    <Transactions payout={payout} />}

                <AmountBreakdown payout={payout} />
                {getAccountRole() != ACCOUNT_TYPE_TUTOR && <Initiation payout={payout} />}
                {Util.isStringExists(payout.info) && <Section icon={Catalog32} title="Additional Information">
                    <p style={{ whiteSpace: 'pre-line' }}>{payout.info}</p>
                </Section>}

                {getAccountRole() != ACCOUNT_TYPE_TUTOR && <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                    <NoteView objectId={payout.id} objectType={OBJECT_TYPE_PAYOUT} />
                </Section>}
            </div>
        </div>
    )
}

export default withLoadablePageWithParams(params => listener => Api.getPayout(params.payoutId, listener), View)

