import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { Restaurant32, ArrowsVertical16, Download16, Upload16, ChartRelationship32, ListBoxes } from '@carbon/icons-react'
import { ComposedModal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { useStringValueFilter } from "../../engine/useValueFilter";
import { IngredientFormDialog } from "../../../pages/restaurant/ingredients/fields";
import { FoodFormDialog } from "../../../pages/restaurant/foods/fields";
import { FoodDetailComponent, FoodDetailPage } from "../../../pages/restaurant/foods/detail";

export const FoodList = {
    filter: Filter,
    icon: Restaurant32,

    newDialog: NewDialog,

    //itemToOpenLink: item => `/food/${item.id}`,
    isListMode: true,
    // rowHeight: 75,

    openItemInSideView: true,
    sideViews: {
        updateForm: {
            Component: (props => (
                // <p>{props.itemId} - -{JSON.stringify(props)}</p>
                <FoodDetailComponent foodId={props.itemId} />
            ))
        }
    },

    isAvailable: () => {
        const role = getAccountRole();
        return hasCapabilitySupport("restaurant") && (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_PRODUCT_MANAGER);
    }
}


function NewDialog({ visible, onClose, onSuccess, endpoint }) {
    return (
        <FoodFormDialog title="Create New" {...{ visible, onClose, onSuccess, endpoint }} />
    )
}


function Filter({ endpoint, state }) {
    return (<>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Description" property="name" fullWidth />
        </div>


        <div className="list-mode-divider-quick-filter-bar" />
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Department" fieldKey="productListDepartmentField" options={endpoint.departments.map(item => item.value)} state={state} property="department" />
        </div>
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Category" fieldKey="productListCategoryField" options={endpoint.categories.map(item => item.value)} state={state} property="category" />
        </div>
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Subcategory" fieldKey="productListSubcategoryField" options={endpoint.subcategories.map(item => item.value)} state={state} property="subcategory" />
        </div>

    </>)
}
