
import { useState } from "react";
import { SelectWindow16, Close16 } from '@carbon/icons-react'
import useStore from "../../../hooks/useStore";
import { ProducibleBundlesSearchDialog } from "../../../pages/pos/dialogs/any-search-dialog";
import Button from "../../../components/Button";
import Util from "../../../util/Util";
import ProductField from "../../../views/product/ProductField";
import { OBJECT_TYPE_PRODUCT_BUNDLE } from "../../../constants/ObjectTypes";
import { DESTINATION_TYPE_STORE, SOURCE_TYPE_STORE } from "../../../constants/Constants";
import ProfilePic from "../../../components/ProfilePic";
import Api from "../../../session/Api";
import { BundleGroupSelectionsDialog } from "../../../pages/store-master/dialogs/bundle-group-selections-dialog";
import { ProductionBundleGroupSelections } from "../../../pages/store-master/dialogs/production-bundle-group-selections";


export function BundleField({ store }) {
    const [bundleId, setBundleId] = useStore(store, 'bundleId');
    const [quantity, setQuantity] = useStore(store, 'quantity');
    const [groupSelections, setGroupSelections] = useStore(store, 'groupSelections');
    const [bundle, setBundle] = useStore(store, 'bundle');

    const [visible, setVisible] = useState(false);

    const selected = Util.isNumberExist(bundleId)



    return <>
        <div style={{
            transition: '250ms',
            display: 'flex', alignItems: 'center', gap: '0.5rem',
            borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
            ...selected ? {
                background: "#1c1c1c",
                color: 'white'
            } : {}
        }}>
            {selected && <div style={{ minWidth: 48 }}>
                <ProfilePic size={48} notProfile src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, bundleId)} />
            </div>}

            <div style={{ flex: 1 }}>
                {selected ? (<>
                    <h6 style={{ color: '#0f62fe' }}>Selected Bundle</h6>
                    <p style={{}}>{bundle.name}</p>
                </>) : (<>
                    <h5>Bundle</h5>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>no bundle selected</p>
                </>)}
            </div>
            {!selected && <Button onClick={() => setVisible(true)} renderIcon={SelectWindow16} size="md" style={{ borderRadius: 5, }}>
                Select Bundle
            </Button>}
            {selected && <>
                <ProductField
                    productId={bundleId}
                    productType={OBJECT_TYPE_PRODUCT_BUNDLE}
                    allowBundlesWithGroups
                    quantityFieldOnly
                    defaultProductValues={{
                        itemId: bundleId, itemType: OBJECT_TYPE_PRODUCT_BUNDLE,
                        quantityValue: quantity
                    }}
                    stockItemMode={{
                        // Make it a transfer so it only shows quantity
                        destinationType: DESTINATION_TYPE_STORE, sourceType: SOURCE_TYPE_STORE, hideLabelBtn: false,
                        stockFlow: { destinationType: DESTINATION_TYPE_STORE, sourceType: SOURCE_TYPE_STORE }
                    }}
                    onProductChange={() => { }}
                    onStockValueChanged={(key, value) => {
                        if (key === 'quantityValue') {
                            setQuantity(value);
                        }
                    }}
                />

                <Button onClick={() => {
                    setGroupSelections([]);
                    setQuantity(1)
                    setBundleId(0);
                    setBundle(null);
                }} renderIcon={Close16} kind="danger" iconDescription="Clear" size="md" hasIconOnly style={{ borderRadius: 5, alignSelf: 'flex-end' }} />
            </>}

            <ProducibleBundlesSearchDialog visible={visible} onClose={() => setVisible(false)} onItemSelected={bundle => {
                setGroupSelections([]);
                setQuantity(1)
                setBundle(bundle)
                setBundleId(bundle.id);
            }} />
        </div>


        {bundle &&
            <ProductionBundleGroupSelections
                item={bundle}
                value={groupSelections}
                setValue={setGroupSelections}
            />}

    </>
}