import { Widget } from ".";
import { Archive16, ArrowRight16 } from "@carbon/icons-react"
import { MinimalTable } from "../../components/minimal-table";
import ProfilePic from "../../components/ProfilePic";
import Api from "../../session/Api";
import { OBJECT_TYPE_ACCOUNT } from "../../constants/ObjectTypes";
import { Link } from "react-router-dom";
import Util from "../../util/Util";
import Button from "../../components/Button";
import { ContentSwitcher, Switch } from "carbon-components-react";
import { useState } from "react";
import { useNumberLocalStorage } from "../../hooks/useLocalStorage";

const COLUMNS = [
    {
        name: "user",
        title: "User",
        flex: 2,
        renderCell: item => (<>
            <div style={{ minWidth: 32 }}>
                <ProfilePic src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.userId)} size={32} />
            </div>
            <Link to={"/staff-users/" + item.userId} target="_blank">
                <h5>{item.userFullName}</h5>
            </Link>
        </>)
    },
    {
        name: "title",
        title: '',
        flex: 1,
        renderCell: item => <p style={{ fontWeight: 'bold' }}>{item.title}</p>
    }
]

const CONTENT = item => {
    return (
        <div style={{ paddingInline: '1rem', paddingBottom: '1rem' }}>
            <p style={{ fontSize: 16 }}>{item.message}</p>
            <p style={{ fontSize: 14, opacity: 0.65 }}>{Util.getDate(item.date)}</p>
        </div>
    )
}

const FILTER_MAPPING = [
    undefined,
    'Updated Order Status',
    'KOT Modified'
]

export function UserActions({ data }) {
    const { actions } = data.userActions;
    const [filterIndex, setFilterIndex] = useNumberLocalStorage('rest-dashboard-actions-filter', 0);
    return (
        <Widget icon={Archive16} title={"Latest User Actions"} style={{ flex: 1, maxHeight: 650, overflow: 'auto' }}>
            <div style={{ display: 'flex', marginBottom: '1rem', gap: '1rem', }}>
                <ContentSwitcher selectedIndex={filterIndex} onChange={e => setFilterIndex(e.index)} size="md">
                    <Switch name={"All"} text={"All"} />
                    <Switch name={"Order"} text={"Order Actions"} />
                    <Switch name={"KOT"} text={"KOT Actions"} />
                </ContentSwitcher>
            </div>

            <MinimalTable
                animate
                items={actions.filter($ => {
                    const filter = FILTER_MAPPING[filterIndex]
                    if (filter) {
                        return $.title === filter;
                    } else {
                        return true;
                    }
                })}
                columns={COLUMNS}
                itemChildren={CONTENT}
                itemPadding="1rem"
            />


            <div style={{ height: '1rem' }} />
            <Link to={"/system-logs"} target="_blank">
                <Button renderIcon={ArrowRight16} style={{ borderRadius: 7, width: '100%', maxWidth: 'unset' }}>System Logs</Button>
            </Link>
        </Widget>
    )
}