import './timeline.scss'
import { useMemo, useState } from "react";
import { useRefSize } from "../../util/useSize";
import { parseData } from "./engine";
import { HOURS } from "./hours";
import Util from "../../util/Util";
import { CaretRight16, Ticket16 } from '@carbon/icons-react'
import useColorGenerator from './useColorGenerator';
import { Tag } from 'carbon-components-react';


const CELL_HEIGHT = 24;
const CELL_WIDTH = 75;
const CELL_VALUE_HEIGHT = 50;

const MonthHeader = ({ month, children }) => (
    <div>
        <div style={{ display: 'flex', width: '100%', paddingInline: '1rem', borderBottom: '1px solid #00000020', borderRight: '1px solid #00000020' }}>
            <p style={{ position: 'sticky', left: '50%', right: '50%', height: CELL_HEIGHT, textAlign: 'center', }}>{month}</p>
        </div>
        <div style={{ display: 'flex' }}>

            {children}
        </div>
    </div>
)

const DayHeader = ({ day, children }) => (
    <div style={{}}>
        <div style={{ display: 'flex', width: '100%', paddingInline: '1rem', borderBottom: '1px solid #00000020', borderRight: '1px solid #00000020' }}>
            <p style={{ position: 'sticky', left: '50%', right: '50%', height: CELL_HEIGHT, textAlign: 'center', }}>{day}</p>
        </div>
        <div style={{ display: 'flex', }}>
            {children}
        </div>
    </div >
)

const HourHeader = ({ hour, index }) => (
    <p style={{ background: index % 2 == 1 ? "#00000010" : "white", height: CELL_HEIGHT, textAlign: 'center', width: CELL_WIDTH, borderBottom: '1px solid #00000020', borderRight: '1px solid #00000020' }}>{hour}</p>
)





const Root = ({ children }) => (
    <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: 15, border: '1px solid #00000010', boxShadow: '0px 20px 25px -5px rgba(0,0,0,0.1) , 0px 10px 10px -5px rgba(0,0,0,0.04) ', overflow: 'hidden' }}>
        <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            {children}
        </div>
    </div>
)

const Header = ({ title, parsedValue }) => {
    const [headerRef, headerSize] = useRefSize();
    return (
        <div style={{ display: 'inline-block', position: 'sticky', top: 0, zIndex: 5, background: 'white' }}>
            <div style={{ position: 'relative', display: 'flex', }}>
                <div style={{ position: 'sticky', left: 0, minWidth: 250, height: headerSize.height, borderBottom: '1px solid #00000020', borderRight: '1px solid #00000020', background: 'white', zIndex: 4 }}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <h6>{title}</h6>
                    </div>
                </div>

                <div ref={headerRef} style={{ position: 'relative', display: 'flex', }}>
                    {parsedValue.months.map((month) => (
                        <MonthHeader key={'month' + month.value} month={month.name}>
                            {month.days.map((day) => (
                                <DayHeader key={'day' + day.value} day={day.name}>
                                    {HOURS.map((hour, index) =>
                                        (index >= (day.skipHours ?? 0) && index < (day.maxHours ?? 24)) ? (
                                            <HourHeader key={'hour' + day.value + hour} hour={hour} index={index} />
                                        ) : (
                                            null
                                        ))}
                                </DayHeader>
                            ))}
                        </MonthHeader>
                    ))}
                </div>
            </div>
        </div>
    )
}

const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

const Cell = ({ cell, val, getIdColor }) => {
    const oneHour = 3600000;
    const maxDuration = oneHour * ((val.numDeleted ?? 0) + 1);
    const maxWidth = (CELL_WIDTH * ((val.numDeleted ?? 0) + 1));

    const actualDuration = val.end - val.start;
    const actualWidth = (actualDuration / maxDuration) * maxWidth;

    const initialPadding = val.start - cell.startDate;
    const left = ((initialPadding / maxDuration) * (maxWidth));


    const bg = getIdColor(val.title)
    return (
        <div
            style={{
                left: left + 5,
                width: actualWidth - 10,
                position: 'absolute', borderRadius: 4, paddingInline: '0.5rem', display: 'flex', alignItems: 'center', height: '80%', top: '50%', transform: 'translateY(-50%)', zIndex: 2,
                boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',

                background: bg,
                color: isLightColor(bg) ? "black" : "white",
            }}>

            <span style={{ flex: 1 }}>{val.title}</span>
            {val?.attachments?.showShowtimeTicketStat &&
                <Tag
                    style={{ position: 'absolute', top: '-25%', height: 18, right: 0 }}
                    type='magenta' size="sm" renderIcon={Ticket16}>{val.attachments.soldCount}/{val.attachments.totalCount}</Tag>}
        </div>
    )
}

const Content = ({ data, parsedValue, getIdColor }) => (<>
    {data.map(item => (
        <div key={item.id} style={{ display: 'inline-block', borderBottom: '1px solid #00000020' }}>
            <div style={{ position: 'relative', display: 'flex' }}>
                <div style={{ position: 'sticky', left: 0, minWidth: 250, height: CELL_VALUE_HEIGHT, borderRight: '1px solid #00000020', background: 'white', zIndex: 4 }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: '100%', paddingLeft: '3rem', paddingRight: '1rem', }}>
                        <p style={{ fontSize: 14 }}>{item.label}</p>
                    </div>
                </div>

                {parsedValue.cells.map((cell) => (
                    <div key={cell.cellId} style={{ position: 'relative', height: CELL_VALUE_HEIGHT, minWidth: CELL_WIDTH, borderRight: '1px solid #00000020' }}>
                        {cell.values
                            .filter(val => val.resourceId == item.id)
                            .map(val => <Cell key={val.id} cell={cell} val={val} getIdColor={getIdColor} />)}
                    </div>
                ))}
            </div>
        </div>
    ))}
</>)

const Group = ({ group, parsedValue, getIdColor }) => {
    const [expanded, setExpanded] = useState(true)
    return (<>
        <div style={{ display: 'inline-block', borderBottom: '1px solid #00000020' }}>
            <div style={{ position: 'relative', display: 'flex' }}>
                <div onClick={() => setExpanded(p => !p)} style={{ cursor: 'pointer', position: 'sticky', left: 0, minWidth: 250, height: CELL_VALUE_HEIGHT, background: 'white', borderRight: '1px solid #00000020', zIndex: 4 }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: '100%', paddingInline: '1rem', }}>
                        <CaretRight16 style={{ transition: '250ms', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                        <h6>{group.label}</h6>
                    </div>
                </div>

                {/* {parsedValue.cells.map((cell) => (
                    <div key={cell.cellId} style={{ position: 'relative', height: CELL_VALUE_HEIGHT, minWidth: CELL_WIDTH, background: '#00000010' }} />
                ))} */}
                <div style={{ height: CELL_VALUE_HEIGHT, minWidth: CELL_WIDTH * parsedValue.cells.length, background: '#00000005' }} />
            </div>
        </div>


        {expanded &&
            <div style={{ animation: 'timeline-calendar-group-animate 250ms' }}>
                <Content data={group.resources} parsedValue={parsedValue} getIdColor={getIdColor} />
            </div>}
    </>)
}

const GroupList = ({ data, parsedValue, getIdColor }) => (<>
    {data.map(item => <Group key={item.id} group={item} parsedValue={parsedValue} getIdColor={getIdColor} />)}
</>)

export function Timeline({ headerTitle, data }) {
    const parsedValue = useMemo(() => parseData(data), [data])
    const { getIdColor } = useColorGenerator();
    return (
        <Root>
            <Header title={headerTitle} parsedValue={parsedValue} />
            <GroupList data={data} parsedValue={parsedValue} getIdColor={getIdColor} />

            {/* <pre><code>{JSON.stringify(parsedValue, null, 2)}</code></pre> */}
        </Root>
    )
}



