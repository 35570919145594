import React, { useEffect, useRef, useState } from 'react'

import MapPicker from 'react-google-map-picker'
import { GOOGLE_MAPS_API_KEY } from '../app/Config';
import Util from '../util/Util';
import Button from './Button';

import { Reset16 } from '@carbon/icons-react'

const DEF_LOC = { lat: 25.20112613289534, lng: 55.28151582031251 };
const DEF_ZOOM = 8;

export default ({label, location, onLocationChange}) => {
    const [key, setKey] = useState(Util.newTempId())

    const [zoom, setZoom] = useState(8)
    const [defaultLocation, setDefaultLocation] = useState(location ? { lat: parseFloat(location.split(",")[0]), lng: parseFloat(location.split(",")[1]) } : DEF_LOC)

    const defLoc = useRef(location);

    useEffect(() => {
        setDefaultLocation({ ...defaultLocation })
    }, [key])

    const onResetBtn = () => {
        setKey(Util.newTempId())
        onLocationChange(defLoc.current)
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{flex: 1, marginBottom: 0}} className="bx--label">{label}</label>
                <Button renderIcon={Reset16} onClick={onResetBtn} kind="ghost">Reset</Button>
            </div>

            <MapPicker 
            // key={key}
            defaultLocation={defaultLocation}
            zoom={zoom}
            mapTypeId="roadmap"
            style={{ width: '100%', height: 300 }}
            onChangeLocation={(lat, lng) => onLocationChange(`${lat},${lng}`)} 
            onChangeZoom={setZoom}
            apiKey={GOOGLE_MAPS_API_KEY} />
        </div>
    )
}