import { Certificate16, Hourglass16, ErrorFilled16, CheckmarkFilled16, Policy16 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react"

export const LEAD_STATUS_ACTIVE = 0;
export const LEAD_STATUS_ON_HOLD = 1;
export const LEAD_STATUS_CANCELLED = 2;
export const LEAD_STATUS_FINALIZED = 3;
export const LEAD_STATUS_DEAL_DONE = 4;

export const LEAD_FEELING_PENDING = 0;
export const LEAD_FEELING_NEUTRAL = 1;
export const LEAD_FEELING_SAD = 2;
export const LEAD_FEELING_HAPPY = 3;

export const LEAD_FEELINGS = [
    { id: LEAD_FEELING_PENDING, title: "Don't Know" },
    { id: LEAD_FEELING_NEUTRAL, title: "Neutral" },
    { id: LEAD_FEELING_SAD, title: "Sad" },
    { id: LEAD_FEELING_HAPPY, title: "Happy" },
]

export function getStatusName(status) {
    return getStatusDetails(status)?.text ?? "Unknown"
}

export function getStatusDetails(status) {
    switch (status) {
        case LEAD_STATUS_ACTIVE:
            return { type: "blue", text: "Active", icon: Certificate16 };
        case LEAD_STATUS_ON_HOLD:
            return { type: "", text: "On Hold", icon: Hourglass16 };
        case LEAD_STATUS_CANCELLED:
            return { type: "red", text: "Cancelled", icon: ErrorFilled16 };
        case LEAD_STATUS_FINALIZED:
            //return { type: "purple", text: "Finalized", icon: CheckmarkFilled16 };
            return { type: "green", text: "Finalized", icon: CheckmarkFilled16 };
        case LEAD_STATUS_DEAL_DONE:
            return { type: "green", text: "Deal Done", icon: Policy16 };
    }
}

export const StatusIndicator = ({ status, skipText }) => {
    let { icon, text, type } = getStatusDetails(status);
    return (
        <div>
            <Tag size="md" renderIcon={icon} type={type}>
                {!skipText && text}
            </Tag>
        </div>
    )
}