import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react';


import { ChevronDown16, CaretRight16, Close16, InventoryManagement20 } from '@carbon/icons-react'
import { Button, TextInput } from 'carbon-components-react';
import Util from '../util/Util';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const ItemList = ({ items, paddingLeft, onExpandedUpdate, onSelect, defExtended }) => items.map(item => (
    React.createElement(item.group ? GroupMenuItem : MenuItem, {
        key: item.id + "-" + item.name,
        item, paddingLeft, onExpandedUpdate, onSelect, defExtended
    })
));

const GroupMenuItem = ({ item, paddingLeft, onExpandedUpdate, onSelect, defExtended }) => {
    const [expanded, setExpanded] = useState(defExtended ? true : false);
    const [childExpand, setChildExpand] = useState(0);

    useEffect(() => {
        if (expanded) {
            onExpandedUpdate(item.items.length);
        } else {
            onExpandedUpdate(0);
        }
    }, [expanded])

    return (
        <div style={{ width: '100%' }}>
            <div onClick={() => setExpanded(prev => !prev)} className="bx--list-box__menu-item" style={{ display: 'flex', alignItems: 'center', padding: '1rem', height: 45, paddingLeft: paddingLeft }}>
                <CaretRight16 style={{ marginRight: '0.25rem', transition: '250ms', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                {/* <InventoryManagement20 style={{marginRight: '0.25rem'}} /> */}
                <span style={{ fontWeight: 'bold' }}>{item.name}</span>
            </div>
            <div style={{
                //transition: '250ms', height: expanded ? 45 * (item.items.length + childExpand) : 0,
                height: expanded ? undefined : 0,

                // height: 500,
                overflow: 'hidden'
            }}>
                <ItemList items={item.items} paddingLeft={paddingLeft + convertRemToPixels(1)} onExpandedUpdate={count => setChildExpand(count)} onSelect={onSelect} defExtended={defExtended} />
            </div>
        </div>
    )
}

const MenuItem = ({ item, paddingLeft, onSelect }) => {
    const [expanded, setExpanded] = useState(true);

    if ((item?.items?.length ?? 0) > 0) {
        return (
            <div style={{ width: '100%' }}>
                <div onClick={() => setExpanded(prev => !prev)} className="bx--list-box__menu-item" style={{
                    display: 'flex', alignItems: 'center', padding: '1rem', height: undefined, paddingLeft: paddingLeft, //pointerEvents: 'none'
                }}>
                    <CaretRight16 style={{ marginRight: '0.25rem', transition: '250ms', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                    <span style={{ fontWeight: 'bold' }}>{item.name}</span>
                    <span style={{ paddingInline: '0.5rem' }}>-</span>

                    <a //href="#" 
                        className="bx--list-box__menu-item" style={{ display: 'flex', alignItems: 'center', padding: 0 }} onClick={onSelect !== undefined ? (() => onSelect(item)) : undefined}>
                        Select Account
                    </a>
                </div>
                <div style={{ height: expanded ? undefined : 0, overflow: 'auto' }}>
                    <ItemList items={item.items} paddingLeft={paddingLeft + convertRemToPixels(3)} onExpandedUpdate={count => { }} onSelect={onSelect} />
                </div>
            </div>
        )
    } else {
        return (
            <div className="bx--list-box__menu-item" style={{ display: 'flex', alignItems: 'center', padding: '1rem', height: 45, paddingLeft: paddingLeft }} onClick={onSelect !== undefined ? (() => onSelect(item)) : undefined}>
                {item.name}
            </div>
        )
    }
}

function flatten(xs) {
    return xs.reduce((acc, x) => {
        acc = acc.concat(x);
        if (x.items) {
            acc = acc.concat(flatten(x.items));
            x.items = [];
        }
        return acc;
    }, []);
}

export default ({ items, selectedItem, rounded, dark, white, onSelectedItemUpdate, placeholder, readOnly, defExtended, preventClear }) => {
    const [searchValue, setSearchValue] = useState("");
    const [expanded, setExpanded] = useState(false);

    const wrapperRef = useRef();

    useEffect(() => {
        const onClickHandle = e => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                setExpanded(false)
            }
        }

        window.addEventListener('mousedown', onClickHandle);
        return () => window.removeEventListener('mousedown', onClickHandle);
    }, [])

    useEffect(() => {
        setSearchValue(selectedItem ? selectedItem.name : "")
    }, [selectedItem])

    const onSearchValueChange = (value) => {
        if (!selectedItem) {
            setSearchValue(value);
        }
    }

    const itemsList = useMemo(() => {
        if (Util.isStringExists(searchValue) && !selectedItem) {
            return flatten(JSON.parse(JSON.stringify(items))).filter(item => !item.group && item.name.toLowerCase().includes(searchValue.toLowerCase()))
        } else {
            return items;
        }
    }, [searchValue])

    return (
        <div ref={wrapperRef} className={"" + (dark ? 'dark-menu-item' : '')} style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div onClick={() => {
                if (!readOnly) {
                    //setExpanded(prev => !prev)
                    setExpanded(true)
                }
            }} className={"bx--text-input"} style={{
                position: 'relative', height: '100%', height: '100%', background: white ? 'white' : dark ? '#ffffff10' : '#f4f4f4', display: 'flex', alignItems: 'center', cursor: !readOnly ? 'pointer' : undefined, borderRadius: rounded ? 5 : 0,
                border: dark ? '1px solid #00000099' : undefined,
                color: dark ? 'white' : undefined
            }}>
                {/* {selectedItem !== undefined ? (
                    <p style={{ fontSize: 14, flex: 1 }}>{selectedItem.name}</p>
                ) : <p style={{ fontSize: 14, opacity: 0.65, flex: 1 }}>Select...</p>} */}

                {/* {selectedItem !== undefined ? (
                    <input style={{ all: 'unset', fontSize: 14, flex: 1, }} value={selectedItem.name}></input>
                ) : <input style={{ all: 'unset', fontSize: 14, opacity: 0.65, flex: 1, }} placeholder="Select..."></input>} */}

                <input style={{ all: 'unset', fontSize: 14, flex: 1, }} value={searchValue} placeholder={placeholder ?? "Search..."} onChange={e => onSearchValueChange(e.target.value)}></input>

                {selectedItem !== undefined && !preventClear && !readOnly && (
                    <div onClick={() => onSelectedItemUpdate(undefined)} style={{ height: '100%', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '250ms', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                        <Close16 />
                    </div>
                )}

                {!readOnly && <div
                    style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '250ms', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                    <ChevronDown16 />
                </div>}
            </div>

            {expanded && <div className="bx--list-box__menu" style={{
                width: '100%', height: 250, animation: 'custom-combo-box-drop-down 250ms',
                marginTop: rounded ? '0.25rem' : undefined, borderRadius: rounded ? 5 : undefined,
                background: dark ? 'black' : undefined,
                color: dark ? 'white' : undefined
            }}>
                <ItemList
                    defExtended={defExtended}
                    //items={items} 
                    items={itemsList}
                    paddingLeft={convertRemToPixels(1)} onExpandedUpdate={() => { }} onSelect={item => {
                        onSelectedItemUpdate(item);
                        setExpanded(false)
                    }} />
            </div>}
        </div>
    )
}