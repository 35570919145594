import React from 'react'

import { Document20, BringToFront32, SendToBack32, TrashCan32, ShapeIntersect32, ShapeExclude32 } from '@carbon/icons-react'
import { getComponentInspector, getComponentTypeIcon, getComponentTypeName } from '../engine/ComponentTypes'
import { Button } from 'carbon-components-react'

const Header = ({engine}) => (
    <div style={{padding: '0.5rem', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06)'}}>
        <div style={{display: 'flex', alignItems: 'center', }}>
            {React.createElement(getComponentTypeIcon(engine.get('selectedComponent').type))}
            <div style={{marginLeft: '0.25rem'}}>
                <p style={{fontSize: 14, height: 20,}}>{getComponentTypeName(engine.get('selectedComponent').type)}</p>
            </div>
        </div>
        {engine.get('selectedComponent').type && <div style={{width: '100%'}}>
            <div style={{marginTop: '0.5rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Button hasIconOnly iconDescription="Bring to Front" renderIcon={BringToFront32} kind="ghost" onClick={() => engine.canvasManager.bringToFront()} />
                <Button hasIconOnly iconDescription="Bring Forward" renderIcon={ShapeIntersect32} kind="ghost" onClick={() => engine.canvasManager.bringForward()} />
                <Button hasIconOnly iconDescription="Send Backward" renderIcon={ShapeExclude32} kind="ghost" onClick={() => engine.canvasManager.sendBackward()} />
                <Button hasIconOnly iconDescription="Send to Back" renderIcon={SendToBack32} kind="ghost" onClick={() => engine.canvasManager.sendToBack()} />
                <Button hasIconOnly iconDescription="Delete" renderIcon={TrashCan32} kind="danger--ghost" tooltipAlignment="end" onClick={() => engine.canvasManager.deleteSelection()} />
            </div>
        </div>}
    </div>
)

export default ({engine}) => (
    <div className="level-designer-inspector-root">
        <Header engine={engine} />
        <div className="level-designer-inspector-content">
            {getComponentInspector(engine, engine.get('selectedComponent'))}
        </div>
    </div>
)