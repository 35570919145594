import Page from "../../base/Page";
import Api from "../../session/Api";
import { isStockEntityFifoEngine } from "../../stock-system/stock-engine";
import DashboardPage from "../dashboard/DashboardPage";
import LMSDashboardPage from "./LMSDashboardPage";
import { Header } from "./retail-lms/header";


class RetailLMSDashboardPage extends Page {

    state = {
        tab: 0,

        lmsDashboard: undefined,
        retailDashboard: undefined
    }

    onPageStart() {
        window.scrollTo(0, 0);
        this.callPageApi(listener => Api.getRetailLmsDashboard(listener), payload => ({
            ...payload
        }))
    }

    getLayout() {
        return (
            <div className="rounded-dashboard-card" style={{ width: '100%', height: '100%', position: 'relative' }}>
                <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                    <Header tabIndex={this.state.tab} setTabIndex={tab => this.setState({ tab })} />
                    {this.state.tab === 0 && <LMSDashboardPage skipHeader defaultDashboard={this.state.lmsDashboard} />}
                    {this.state.tab === 1 && <DashboardPage skipHeader defaultDashboard={this.state.retailDashboard} />}
                </div>
            </div>
        )
        // return (
        //     <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        //         <Header tabIndex={this.state.tab} setTabIndex={tab => this.setState({ tab })} />
        //         <div style={{ flex: 1, width: '100%', minHeight: 0, overflow: 'auto' }}>
        //             {this.state.tab === 1 && <DashboardPage skipHeader defaultDashboard={this.state.retailDashboard} />}
        //         </div>
        //     </div>
        // )
    }

    isPageLoadable() {
        return true;
    }

}

export default RetailLMSDashboardPage;