import React, { useEffect, useState } from 'react'

import {
    ArrowLeft16,
    OverflowMenuVertical16,
    Product24,
    Product16,
    Minimize16
} from '@carbon/icons-react'
import Button from '../../../components/Button'
import ProductViewer from '../../pos/widgets/ProductViewer'
import useStore from '../../../hooks/useStore'
import usePosSession from '../state/usePosSession'

const View = ({ mainSessionStore }) => {
    const session = usePosSession(mainSessionStore)

    const addProduct = id => {
        session.updateCartItem(id, 1, false)
    }

    return (
        <div style={{ height: 'calc(100vh - 9rem)', maxHeight: 'calc(100vh - 9rem)' }}>
            {/* <Button onClick={() => addProduct(37)}>Nike Shoes</Button>
            <Button onClick={() => addProduct(38)}>NoName</Button>
            <Button onClick={() => addProduct(7579)}>InventoryTest</Button> */}

            <ProductViewer hideTitle
                //skipStockInfo
                overrideStoreId={0}
                state={{ ...session, getState: () => ({}) }} />
        </div>
    )
}


export const ProductViewLowerHeight = ({ mainSessionStore }) => {
    const session = usePosSession(mainSessionStore)

    const addProduct = id => {
        session.updateCartItem(id, 1, false)
    }

    return (
        <div style={{ height: 400, maxHeight: 400 }}>
            {/* <Button onClick={() => addProduct(37)}>Nike Shoes</Button>
            <Button onClick={() => addProduct(38)}>NoName</Button>
            <Button onClick={() => addProduct(7579)}>InventoryTest</Button> */}

            <ProductViewer hideTitle
                //skipStockInfo
                stickyTitle
                overrideStoreId={0}
                state={{ ...session, getState: () => ({}) }} />
        </div>
    )
}


export default ({ mainSessionStore, collapseRequest, hideCollapseBtn }) => {
    const [collapsed, setCollapsed] = useState(false)

    useEffect(() => {
        setCollapsed(collapseRequest)
    }, [collapseRequest])

    if (collapsed) {
        return (
            <div onClick={() => setCollapsed(false)} className="sales-cart-collapsed-panel" style={{ width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <div style={{ display: 'flex', minWidth: 200, alignItems: 'center', justifyContent: 'center', transform: 'rotate(270deg)', }}>
                    <Product16 style={{ marginRight: '0.25rem', marginLeft: '0.5rem' }} />
                    <p style={{ fontSize: 14, opacity: 0.65 }}>Products</p>
                </div>
            </div>
        )
    }

    return (
        <div className="dashboard-card" style={{ padding: '0rem', flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Product24 style={{ marginRight: '0.25rem', marginLeft: '0.5rem' }} />
                <h4 style={{ flex: 1 }}>Products</h4>

                {!hideCollapseBtn && <Button kind="ghost" tooltipPosition="bottom" iconDescription="Collapse" hasIconOnly renderIcon={Minimize16} onClick={() => setCollapsed(true)} />}
            </div>
            <View mainSessionStore={mainSessionStore} />
        </div>
    )
}