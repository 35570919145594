import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util, { isV2 } from "../../../util/Util";
import { getReportLink } from "../../base/report-page";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider";
import { LocationFilter } from "../stock-by-location/location-filter";
import { ProductFilter } from "../stock-by-location/product-filter";



const salesConsumptionDetail = (productId, start, end) => {
    const filters = [
        //{ "id": "customerName", "operator": "OR", "filters": [{ "property": "customerName", "operator": "EQ", "value": customer }] }
    ];
    if (Util.isNumberExist(start)) {
        filters.push(
            { "property": "date", "operator": "GTE", "id": "date-start", "value": start },
        )
    }
    if (Util.isNumberExist(end)) {
        filters.push(
            { "property": "date", "operator": "LT", "id": "date-end", "value": end }
        )
    }

    return { filters, }
}

export const ConsumptionReport = {
    filter: Filter,
    isTreeSructure: true,

    onAction: (action, data, history) => {
        switch (action) {
            case "sale_value":
                history.push(getReportLink("SalesConsumptionDetail", salesConsumptionDetail(
                    0, data._startDate, data._endDate
                )))
                break;
        }
    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter />
        <Divider />
        {/* <div style={{ minHeight: 30, flex: 1 }}>
            <LocationFilter state={state} options={endpoint.locations} sk />
        </div> */}
        <div style={{ minHeight: 30, flex: 1 }}>
            <ProductFilter state={state} options={endpoint.products} filterProperty="item_id" skipAutoFilter={false} />
        </div>
    </>)
}
