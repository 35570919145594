import { ComboBox, ContentSwitcher, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';
import Button from './Button';
import { REAL_ESTATE_AMENITIES } from '../domain/real-estate';

const TYPE_CUSTOM = 0;

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [type, setType] = useMultiInputState(input, 'type', onInputUpdate);
    const [name, setName] = useMultiInputState(input, 'name', onInputUpdate);

    const onSetType = type => {
        if (type !== TYPE_CUSTOM) {
            setName("");
        }
        setType(type)
    }

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div style={{ flex: 1 }}>
                <ComboBox style={{ width: '100%' }} size='lg' titleText="Type" items={REAL_ESTATE_AMENITIES} itemToString={item => item?.name ?? ""}
                    selectedItem={REAL_ESTATE_AMENITIES.find(item => item.type === type)}
                    onChange={e => onSetType(e.selectedItem ? e.selectedItem.type : undefined)} />
            </div>
            {type === TYPE_CUSTOM &&
                <TextInput size="lg" labelText="Name" value={name} onChange={e => setName(e.target.value)} />}

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const RealEstateAmenityEditor = ({ defaultItems, onUpdate }) => (
    <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
)