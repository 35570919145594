import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom";
import Util, { flattenItems } from "../../util/Util";
import { Link as LinkButton } from 'carbon-components-react';
import UIUtil from "../../util/UIUtil";
import { BalanceTagDivisionDialog } from "./balance-tags/BalanceTagDivsionDialog";
import { getReportLink } from "../../reporting-engine/base/report-page";
import { DATE_RANGE_UTIL } from "../../reporting-engine/components/basic-filter/date-range";

const pl = index => `${1 + ((index + 1) * 1)}rem`;


function Group({ item: group, itemRender, index, skipZero, leftAligned, rightAligned, startDate, endDate }) {
    // const total = useMemo(() => flattenItems(group.items)
    //     .filter(item => item.balance !== null && item.balance !== undefined)
    //     .map(item => item.balance)
    //     .reduce((t, c) => t + c, 0), [group])

    // console.log("AGALA GROUP", group.groupTotal === total)
    const total = group.groupTotal;

    if (skipZero && total === 0) {
        return null;
    }

    let title;
    if (group.accountIsParent) {
        //title = <Link target="_blank" to={"/general-ledger-report/" + group.id} style={{ paddingLeft: pl(index) }}><h6 style={{ paddingLeft: pl(index), marginTop: '0.5rem' }}>{group.name}</h6></Link>
        const link = getReportLink('LedgerReport', {
            selectedAccountLedger: group,
            filters: [
                { id: "ledgerId", property: "ledgerId", operator: "EQ", value: group.id },
                ...(Util.isNumberExist(startDate) ? [
                    { id: 'date-start', property: "date", operator: "GTE", value: startDate }] : []),
                ...(Util.isNumberExist(endDate) ? [
                    { id: 'date-end', property: "date", operator: "LT", value: endDate }] : [])
            ]
        })
        title = <Link target="_blank" to={link} style={{ paddingLeft: pl(index) }}><h6 style={{ paddingLeft: pl(index), marginTop: '0.5rem' }}>{group.name}</h6></Link>
        //title = <Link target="_blank" to={"/general-ledger-report/" + group.id} style={{ paddingLeft: pl(index) }}><h6 style={{ paddingLeft: pl(index), marginTop: '0.5rem' }}>{group.name}</h6></Link>
    } else {
        title = <h6 style={{ paddingLeft: pl(index), marginTop: '0.5rem' }}>{group.name}</h6>
    }

    return (
        <div>
            {/* <h6 style={{ paddingLeft: pl(index), marginTop: '0.5rem' }}>{group.name}</h6> */}
            {title}

            {group.items.map(item => <AccountTreeItem startDate={startDate} endDate={endDate} item={item} index={index + 1} itemRender={itemRender} hideZeroBalance={skipZero} leftAligned={leftAligned} rightAligned={rightAligned} />)}
            <div style={{ display: 'flex', alignItems: 'center', borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: '#00000040', marginTop: '0.25rem' }}>


                {leftAligned ? (<>
                    <div style={{ flex: 2 }}>
                        <h6 style={{ paddingLeft: pl(index), fontSize: 14, opacity: 1.65, fontWeight: 'lighter', }}>Total {group.name}</h6>
                    </div>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <p style={{}}>AED {Util.formatMoney(total)}</p>
                    </div>
                    <div style={{ flex: 1 }} />
                </>) : (
                    rightAligned ? (<>
                        <div style={{ flex: 2, }}>
                            <h6 style={{ paddingLeft: pl(index), fontSize: 14, opacity: 1.65, fontWeight: 'lighter', }}>Total {group.name}</h6>
                        </div>
                        <div style={{ flex: 1 }} />
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <p style={{}}>AED {Util.formatMoney(total)}</p>
                        </div>
                    </>) : (<>
                        <div style={{ flex: 1, }}>
                            <h6 style={{ paddingLeft: pl(index), fontSize: 14, opacity: 1.65, fontWeight: 'lighter', }}>Total {group.name}</h6>
                        </div>
                        <p style={{}}>AED {Util.formatMoney(total)}</p>
                    </>)
                )}
            </div>
        </div>
    )
}

function BalanceLabel({ item }) {
    const [showDialog, setShowDialog] = useState(false);
    const hasTags = item.balanceTags?.length > 0;

    useEffect(() => {
        if (!hasTags) {
            setShowDialog(false);
        }
    }, [hasTags])

    if (hasTags) {
        const showTagDivison = () => {
            //UIUtil.showOverlay2(onClose => <BalanceTagDivisionDialog visible onClose={onClose} />)
            setShowDialog(true);
        }
        return (<>
            {/* <LinkButton onClick={showTagDivison} style={{ cursor: 'pointer' }}><p>AED {item.balance.toFixed(2)}</p></LinkButton> */}
            <LinkButton onClick={showTagDivison} style={{ cursor: 'pointer' }}><p>AED {Util.formatMoney(item.balance)}</p></LinkButton>
            {showDialog && <BalanceTagDivisionDialog total={item.balance} balanceTags={item.balanceTags} visible onClose={() => setShowDialog(false)} />}
        </>)
    } else {
        return <p style={{}}>AED {Util.formatMoney(item.balance)}</p>;
        //return <p style={{}}>AED {item.balance.toFixed(2)}</p>;
    }
}

function Item({ item, index, skipZero, leftAligned, rightAligned, startDate, endDate }) {
    if (skipZero && item.balance.toFixed(2) == "0.00") {
        return null;
    }

    let title;
    if (item.aliasId || !Util.isNumberExist(item.id)) {
        title = <span style={{ paddingLeft: pl(index) }}>{item.name}</span>
    } else {
        const link = getReportLink('LedgerReport', {
            selectedAccountLedger: item,
            filters: [
                { id: "ledgerId", property: "ledgerId", operator: "EQ", value: item.id },
                ...(Util.isNumberExist(startDate) ? [
                    { id: 'date-start', property: "date", operator: "GTE", value: startDate }] : []),
                ...(Util.isNumberExist(endDate) ? [
                    { id: 'date-end', property: "date", operator: "LT", value: endDate }] : [])
            ]
        })
        title = <Link target="_blank" to={link} style={{ paddingLeft: pl(index) }}>{item.name}</Link>
        //title = <Link target="_blank" to={"/general-ledger-report/" + item.id} style={{ paddingLeft: pl(index) }}>{item.name}</Link>
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            {leftAligned ? (<>
                <div style={{ flex: 2, }}>
                    {/* <Link target="_blank" to={"/general-ledger-report/" + item.id} style={{ paddingLeft: pl(index) }}>{item.name}</Link> */}
                    {title}
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {/* <p style={{}}>AED {item.balance.toFixed(2)}</p> */}
                    <BalanceLabel item={item} />
                </div>
                <div style={{ flex: 1 }} />
            </>) : (
                rightAligned ? (<>
                    <div style={{ flex: 2, }}>
                        {/* <Link target="_blank" to={"/general-ledger-report/" + item.id} style={{ paddingLeft: pl(index) }}>{item.name}</Link> */}
                        {title}
                    </div>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {/* <p style={{}}>AED {item.balance.toFixed(2)}</p> */}
                        <BalanceLabel item={item} />
                    </div>
                </>) : (<>
                    <div style={{ flex: 1, }}>
                        {/* <Link target="_blank" to={"/general-ledger-report/" + item.id} style={{ paddingLeft: pl(index) }}>{item.name}</Link> */}
                        {title}
                    </div>
                    {/* <p style={{}}>AED {item.balance.toFixed(2)}</p> */}
                    <BalanceLabel item={item} />
                </>)
            )}
        </div>
    )
}

export function AccountTreeItem({ item, startDate, endDate, index, itemRender, hideZeroBalance, leftAligned, rightAligned }) {
    if (item.items) {
        item.items = item.items.sort((a, b) => (a.group ? 1 : -1) - (b.group ? 1 : -1));
    }
    return React.createElement(item.group ? Group : (itemRender ?? Item), {
        item, itemRender,
        index: index ?? 0,
        skipZero: hideZeroBalance,
        leftAligned, rightAligned, startDate, endDate
    })
}
