import { Accordion, AccordionItem, Checkbox } from "carbon-components-react"
import { makeSettingComponent } from "./Activities"
import { withLoadablePage } from "../../../base/Page"
import Api from "../../../session/Api"
import { useState } from "react"
import { StatelessDatePicker } from "../../../components/DateTimePicker"
import Button from "../../../components/Button"
import { CheckmarkFilled16 } from "@carbon/icons-react"
import UIUtil from "../../../util/UIUtil"

const ShouldCloseBooks = makeSettingComponent('should_close_books')

const CloseBooks = withLoadablePage(Api.getCloseBooksDetail.bind(Api), ({ payload }) => {
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(payload);

    const [date, setDate] = [detail.date, date => setDetail({ ...detail, date })]
    const [allowClearanceCode, setAllowClearanceCode] = [detail.allowWithClearanceCode, allowWithClearanceCode => setDetail({ ...detail, allowWithClearanceCode })]

    const onSaveBtn = () => {
        setLoading(true);
        Api.setCloseBooksDetail(detail, response => {
            setLoading(false);
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)

            }
        })
    }

    return (
        <div>
            <ShouldCloseBooks activeChildren={<>
                <div style={{height: '1rem'}} />
                <h5>Closing Books</h5>
                <label className="bx--label">Date</label>
                <StatelessDatePicker date={date} setDate={setDate} />
                <div style={{height: '1rem'}} />
                <div onClick={() => setAllowClearanceCode(!allowClearanceCode)}>
                    <Checkbox checked={allowClearanceCode} labelText="Allow with admin clearance code?" />
                </div>
                <div style={{height: '1rem'}} />
                <Button onClick={onSaveBtn} loading={loading} renderIcon={CheckmarkFilled16} size="sm" style={{borderRadius: 25}}>Save</Button>
            </>} />
        </div>
    )
})


export default ({ }) => {
    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="Close your books?">
                <CloseBooks />
            </AccordionItem>
        </Accordion>
    )
}