import { ArrowRight16, Stamp16, Close16 } from '@carbon/icons-react'
import { InlineLoading, Tag } from 'carbon-components-react'
import { Link } from 'react-router-dom'
import Api from '../../../../../session/Api'
import Util from '../../../../../util/Util'
import print from 'print-js';
import { useMemo, useState } from 'react'
import { pdf } from '@react-pdf/renderer'

import UIUtil from '../../../../../util/UIUtil'
import Button from '../../../../../components/Button'
import { getAccount } from '../../../../../session/SessionManager'
import useApi from '../../../../../hooks/useApi'
import { createFunctionSheet } from '../pdf/function-sheet'
import { createInvoice } from '../pdf/invoice'
import { createSelectedMenu } from '../pdf/selected-menu'

const List = ({ label, children }) => (<>
    <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{label}</h4>
    <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
        <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            {children}
        </div>
    </div>
</>)

const ValueEntry = ({ label, hasValue, loading, onClick }) => (<>
    <div onClick={hasValue ? onClick : undefined} className={hasValue ? 'shipment-side-view-entry-record' : undefined} style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
        {loading ?
            <InlineLoading style={{ width: 'unset' }} /> :
            hasValue ?
                <ArrowRight16 style={{ opacity: 0.65 }} /> :
                <p style={{ opacity: 0.65, fontSize: 12 }}>not available</p>}
    </div>
</>)


const SystemGeneratedDocument = ({ changesMade, label, fnbEvent, documentCreateFn, pdfProps = {} }) => {
    const { canGenerate, getPayload, PDF, overrideFnbEvent } = useMemo(() => documentCreateFn(fnbEvent), [fnbEvent, documentCreateFn])
    const [loading, setLoading] = useState(false);
    const onPrintBtn = async () => {
        if (changesMade) {
            UIUtil.showInfo("Please save changes to generate PDF")
            return;
        }

        //repeated
        const getFnbEventForPdf = async () => {
            if (overrideFnbEvent) {
                return await overrideFnbEvent(fnbEvent);
            } else {
                return fnbEvent;
            }
        }

        if (getPayload) {
            setLoading(true);
            getPayload(fnbEvent, async (success, data) => {
                try {
                    if (success) {
                        const fnbEventForPdf = await getFnbEventForPdf(fnbEvent);
                        if (!fnbEventForPdf) {
                            return;
                        }

                        const blob = await pdf(<PDF fnbEvent={fnbEventForPdf} payload={data} {...pdfProps} />).toBlob();
                        print(URL.createObjectURL(blob));
                    } else {
                        UIUtil.showError(data);
                    }
                } finally {
                    setLoading(false);
                }
            })
        } else {
            setLoading(true);
            try {
                const fnbEventForPdf = await getFnbEventForPdf(fnbEvent);
                if (!fnbEventForPdf) {
                    return;
                }

                const blob = await pdf(<PDF fnbEvent={fnbEventForPdf} {...pdfProps} />).toBlob();
                print(URL.createObjectURL(blob));
            } finally {
                setLoading(false);
            }
        }
    }
    return <ValueEntry label={label} hasValue={canGenerate} loading={loading} onClick={onPrintBtn} />
}

export function Documents({ fnbEvent, changesMade }) {

    return (
        <div style={{ width: '100%', paddingBlock: '1.6rem', paddingInline: '0rem' }}>

            <h2 style={{
                fontWeight: 'bold', paddingInline: '2rem', paddingBottom: '1rem', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
                marginBottom: '1rem'
            }}>
                Documents
            </h2>

            <List label="Order">
                <SystemGeneratedDocument changesMade={changesMade} fnbEvent={fnbEvent} label="Event Invoice" documentCreateFn={createInvoice} />
                <SystemGeneratedDocument changesMade={changesMade} fnbEvent={fnbEvent} label="Function Sheet" documentCreateFn={createFunctionSheet} />
            </List>

            <List label="Menu">
                <SystemGeneratedDocument changesMade={changesMade} fnbEvent={fnbEvent} label="Selected Menu" documentCreateFn={createSelectedMenu} />
            </List>
        </div>
    )
}