import toast from "react-hot-toast"
import Util from "./Util";

import { confirmAlert } from 'react-confirm-alert'; // Import
import { ButtonSet, ComboBox, ComposedModal, Loading, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from "carbon-components-react";
import { Printer16, CloseFilled16, CheckmarkFilled16, ErrorFilled16, Close16, Warning16 } from '@carbon/icons-react'
import Button from "../components/Button";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import print from 'print-js';
import QRCode from "react-qr-code";
import { printClearanceCodeQrCode } from '../pdfs/security-clearance-card/SecurityClearanceCodePdf'
import { useState } from "react";
import { StatelessDate, StatelessDateRange } from "../reporting-engine/components/basic-filter/date-range";

function InputDialog({ onCancel, onConfirm, negativeAction, positiveAction, title, info, message, label, initialValue, password, textArea, placeholder }) {
    const [value, setValue] = useState(initialValue ?? "")
    const Input = password ? TextInput.PasswordInput : TextInput;
    return (
        <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                <button onClick={onCancel} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>


            {info && <>
                <div style={{ display: 'flex', alignItems: 'center', background: '#ff450020', color: '#ff4500', borderRadius: 7, padding: 5 }}>
                    <Warning16 style={{ marginRight: '0.15rem', }} />
                    <h6>Warning</h6>
                </div>
                <p style={{ marginTop: '0.5rem', fontSize: 14, opacity: 1, color: '#ff4500', whiteSpace: 'pre-line' }}>{info}</p>
            </>}
            {message && <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>{message}</p>}

            <div style={{ marginTop: '0.5rem' }} />
            {textArea ? (
                <TextArea
                    data-modal-primary-focus
                    autoFocus
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    labelText={label}
                    placeholder={placeholder || "Input..."} />
            ) : (
                <Input
                    data-modal-primary-focus
                    autoFocus
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    labelText={label}
                    placeholder={placeholder || "Input..."} />
            )}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', marginTop: '1rem', }}>
                <Button onClick={onCancel} renderIcon={ErrorFilled16} kind="secondary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{negativeAction}</Button>
                <Button onClick={() => onConfirm(value)} renderIcon={CheckmarkFilled16} kind="primary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{positiveAction}</Button>
            </div>
        </div >
    )
}

function InputDialogQuantity({ onCancel, onConfirm, negativeAction, positiveAction, title, info, message, label, initialValue, password, textArea, placeholder, measurementType }) {
    const [value, setValue] = useState(initialValue ?? "")
    const [uomId, setUomId] = useState(0);
    const [cKey, setCKey] = useState(() => Util.newTempId());

    const uom = measurementType?.unitOfMeasurements?.find($ => $.id === uomId) || {
        symbol: measurementType?.defaultUomSymbol || "x", name: measurementType?.defaultUomName || "x"
    };

    const Input = password ? TextInput.PasswordInput : TextInput;
    return (
        <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                <button onClick={onCancel} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>


            {info && <>
                <div style={{ display: 'flex', alignItems: 'center', background: '#ff450020', color: '#ff4500', borderRadius: 7, padding: 5 }}>
                    <Warning16 style={{ marginRight: '0.15rem', }} />
                    <h6>Warning</h6>
                </div>
                <p style={{ marginTop: '0.5rem', fontSize: 14, opacity: 1, color: '#ff4500', whiteSpace: 'pre-line' }}>{info}</p>
            </>}
            {message && <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>{message}</p>}

            <div style={{ marginTop: '0.5rem' }} />
            <div style={{ display: 'flex' }}>
                <Input
                    data-modal-primary-focus
                    autoFocus
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    labelText={label}
                    placeholder={placeholder || "Input..."} />
                <ComboBox
                    key={cKey}
                    placeholder="UOM"
                    selectedItem={uom}
                    titleText="UOM"
                    onChange={item => {
                        setUomId(item?.selectedItem?.id ?? 0)
                        setCKey(Util.newTempId())
                    }}
                    itemToString={item => item ? item.symbol : ""}
                    items={measurementType?.unitOfMeasurements ?? []}
                />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', marginTop: '1rem', }}>
                <Button onClick={onCancel} renderIcon={ErrorFilled16} kind="secondary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{negativeAction}</Button>
                <Button onClick={() => onConfirm({ value, uomId })} renderIcon={CheckmarkFilled16} kind="primary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{positiveAction}</Button>
            </div>
        </div >
    )
}


function DateInputDialog({ onCancel, onConfirm, negativeAction, positiveAction, title, message, label, initialValue }) {
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(0)
    return (
        <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                <button onClick={onCancel} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>

            {message && <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>{message}</p>}

            <div style={{ marginTop: '0.5rem' }} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <StatelessDateRange label="Select" {...{ start, end, setStart, setEnd }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', marginTop: '1rem', }}>
                <Button onClick={onCancel} renderIcon={ErrorFilled16} kind="secondary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{negativeAction}</Button>
                <Button disabled={!start || !end} onClick={() => onConfirm({ start, end })} renderIcon={CheckmarkFilled16} kind="primary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{positiveAction}</Button>
            </div>
        </div>
    )
}

function SingleDateInputDialog({ onCancel, onConfirm, negativeAction, positiveAction, title, message, label, initialValue, initialTimestamp }) {
    const [start, setStart] = useState(initialTimestamp || 0)
    return (
        <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                <button onClick={onCancel} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>

            {message && <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>{message}</p>}

            <div style={{ marginTop: '0.5rem' }} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <StatelessDate label="Select" {...{ start, setStart }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', marginTop: '1rem', }}>
                <Button onClick={onCancel} renderIcon={ErrorFilled16} kind="secondary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{negativeAction}</Button>
                <Button disabled={!start} onClick={() => onConfirm(start)} renderIcon={CheckmarkFilled16} kind="primary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{positiveAction}</Button>
            </div>
        </div>
    )
}

class UIUtil {

    // showDialog(title) {
    //     confirmAlert({
    //         customUI: ({ onClose }) => (
    //             <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
    //                 <ModalHeader label={title} />
    //                 <p style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{msg}</p>
    //                 <div style={{ height: 15 }} />
    //                 <ButtonSet style={{ justifyContent: 'flex-end' }}>
    //                     <Button kind="secondary" onClick={onClose}>
    //                         OK
    //                     </Button>
    //                 </ButtonSet>
    //             </div>
    //         ),
    //         overlayClassName: "pos-dialog-overlay",
    //     });
    // }

    showForcedOverlay(ui) {
        confirmAlert({
            customUI: ({ onClose }) => (
                ui(onClose)
            ),
            closeOnClickOutside: false,
            closeOnEscape: false,
            overlayClassName: "pos-dialog-overlay",
        });
    }

    openDialog(ui) {
        confirmAlert({
            customUI: ui,
            overlayClassName: "pos-dialog-overlay",
        });
    }

    confirm(onConfirm) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                    <ModalHeader label="Confirm" title="Are you sure?" />
                    <div style={{ height: 15 }} />
                    <ButtonSet>
                        <Button kind="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            onConfirm();
                            onClose()
                        }}>
                            Confirm
                        </Button>
                    </ButtonSet>
                </div>
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }

    confirmMsg(msg, onConfirm) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                    <ModalHeader label="Confirm" title="Are you sure?" />
                    {/* <div style={{ height: 7 }} /> */}
                    <p style={{ fontSize: 14, opacity: 0.65, marginTop: '-1rem', padding: '1rem' }}>{msg}</p>
                    <ButtonSet>
                        <Button kind="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            onConfirm();
                            onClose()
                        }}>
                            Confirm
                        </Button>
                    </ButtonSet>
                </div>
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }


    confirmWithMsg(msg, onConfirm) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                    <ModalHeader label="Confirm" title={msg} />
                    {/* <p>{msg}</p> */}
                    <div style={{ height: 15 }} />
                    <ButtonSet>
                        <Button kind="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            onConfirm();
                            onClose()
                        }}>
                            Confirm
                        </Button>
                    </ButtonSet>
                </div>
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }

    confirmWithMsgAndOps(msg, { confirmLabel }, onConfirm) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                    <ModalHeader label="Confirm" title={"Are you sure?"} />
                    <p style={{ paddingInline: '1rem' }}>{msg}</p>
                    <div style={{ height: 15 }} />
                    <ButtonSet>
                        <Button kind="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            onConfirm();
                            onClose()
                        }}>
                            {confirmLabel ?? 'Confirm'}
                        </Button>
                    </ButtonSet>
                </div>
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }

    reverseConfirmWithMsg(msg, onReject) {
        confirmAlert({
            onClickOutside: onReject,
            onKeypressEscape: onReject,
            customUI: ({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                    <ModalHeader label="Confirm" title={msg} />
                    {/* <p>{msg}</p> */}
                    <div style={{ height: 15 }} />
                    <ButtonSet>
                        <Button kind="secondary" onClick={() => {
                            onReject();
                            onClose();
                        }}>
                            Cancel
                        </Button>
                        <Button onClick={onClose}>
                            Confirm
                        </Button>
                    </ButtonSet>
                </div>
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }

    showMsg(msg) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                    <ModalHeader label="Info" />
                    <p style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{msg}</p>
                    <div style={{ height: 15 }} />
                    <ButtonSet style={{ justifyContent: 'flex-end' }}>
                        <Button kind="secondary" onClick={onClose}>
                            OK
                        </Button>
                    </ButtonSet>
                </div>
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }

    showQrCode(value, options = { label: "QR Code", title: "QR Code Viewer" }) {
        this.showOverlay2(onClose => (
            <ComposedModal size="sm" open onClose={onClose}>
                <ModalHeader label={options.label} title={options.title} />
                <ModalBody style={{ paddingRight: '1rem' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <QRCode value={value} />
                    </div>
                </ModalBody>
                <ModalFooter
                // primaryButtonText="Print"
                // secondaryButtonText="Close"
                // onRequestSubmit={() => {
                //     printClearanceCodeQrCode(this.getDataSource().itemResult.item.qrCode)
                // }}
                // onRequestClose={() => {
                //     this.setState({openQrCodeViewer: false})
                // }}
                >
                    <Button kind="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => printClearanceCodeQrCode(value)}>
                        Print
                    </Button>
                </ModalFooter>
            </ComposedModal>
        ))
    }

    showPDF(title, document) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 784, overflow: 'hidden' }}>
                    {/* <ModalHeader label="PDF Viewer" title={title} /> */}
                    <PDFViewer style={{ width: '100%', height: '75vh' }}>
                        {document}
                    </PDFViewer>
                    {/* <div style={{width: '100%', height: 1, backgroundColor: '#1c1c1c'}} /> */}
                    <ButtonSet style={{ justifyContent: 'flex-end' }}>
                        <Button kind="secondary" onClick={onClose} renderIcon={CloseFilled16}>
                            Close
                        </Button>
                        <Button onClick={() => {
                            onClose()
                            pdf(document).toBlob()
                                .then(blob => print(URL.createObjectURL(blob)))
                                .catch(() => UIUtil.showError())
                        }} renderIcon={Printer16}>
                            Print
                        </Button>
                    </ButtonSet>
                </div>
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }

    showOverlay(ui) {
        confirmAlert({
            customUI: ({ onClose }) => (
                ui
            ),
            overlayClassName: "pos-dialog-overlay",
        });
    }

    showOverlay2(ui, onClickOutside) {
        confirmAlert({
            customUI: ({ onClose }) => (
                ui(onClose)
            ),
            overlayClassName: "pos-dialog-overlay",
            onClickOutside
        });
    }

    showOverlay3(ui) {
        confirmAlert({
            customUI: ({ onClose }) => (
                ui(onClose)
            ),
            overlayClassName: "",
        });
    }

    async pageLoader() {
        return new Promise((resolve) => {
            this.showOverlay2(onClose => {
                resolve(onClose)
                return <Loading />
            })
        })
    }


    showSuccess(message, duration) {
        toast.success(Util.isStringExists(message) ? message : "Success!", {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration
        });
    }

    showError(message) {
        toast.error(Util.isStringExists(message) ? message : "An error occurred. Please try again later.", {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        });
    }

    showInfo(message) {
        toast(message, {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                zIndex: 10000
            },
        });
    }

    showLoading(promise) {
        return toast.promise(promise, {
            loading: "Loading",
            success: "Success!",
            error: "Failed"
        }, {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        })
    }


    async listPrompt(title, options) {
        return new Promise((resolve, reject) => (
            this.showOverlay2(onClose => (
                <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                        <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                        <button onClick={() => {
                            onClose();
                            resolve(null);
                        }} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                            <Close16 />
                        </button>
                    </div>

                    {/* <div style={{ marginTop: '0.5rem' }}>
                        <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                    </div> */}

                    <div style={{ maxHeight: 350, minHeight: 150, overflow: 'auto', paddingTop: '0.5rem' }}>
                        {options.map(option => (
                            <div key={option.id} onClick={() => {
                                resolve(option);
                                onClose();
                            }} className="recipe-manager-ingredient-select-item" style={{ minHeight: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                                <p style={{ fontSize: 14, }}>{option.value}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ), () => resolve(null))
        ))
    }

    async confirmPrompt({
        title = "Confirm",
        message,
        messageNode,
        positiveAction = "Confirm",
        negativeAction = "Cancel",
        dangerPositive,
        info
    }) {
        return new Promise((resolve, reject) => (
            this.showOverlay2(onClose => (
                <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                        <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                        <button onClick={() => {
                            onClose();
                            resolve(false);
                        }} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                            <Close16 />
                        </button>
                    </div>



                    {messageNode ? messageNode : <p style={{ marginTop: '0.5rem' }}>{message}</p>}

                    {info && <>
                        <div style={{ display: 'flex', alignItems: 'center', background: '#ff450020', color: '#ff4500', borderRadius: 7, padding: 5 }}>
                            <Warning16 style={{ marginRight: '0.15rem', }} />
                            <h6>Warning</h6>
                        </div>
                        <p style={{ marginTop: '0.5rem', fontSize: 14, opacity: 1, color: '#ff4500', whiteSpace: 'pre-line' }}>{info}</p>
                    </>}

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', marginTop: '1rem', }}>
                        <Button onClick={() => {
                            onClose();
                            resolve(false);
                        }} renderIcon={ErrorFilled16} kind="secondary" size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{negativeAction}</Button>
                        <Button onClick={() => {
                            onClose();
                            resolve(true);
                        }} renderIcon={CheckmarkFilled16} kind={dangerPositive ? "danger" : "primary"} size="lg" style={{ flex: 1, borderRadius: 10, maxWidth: 'unset' }}>{positiveAction}</Button>
                    </div>
                </div>
            ), () => resolve(false))
        ))
    }

    promptUI(ui) {
        return new Promise((resolve, reject) => (
            this.showOverlay2(onClose => (
                ui(selected => {
                    onClose();
                    resolve(selected);
                })
            ), () => resolve(false))
        ))
    }

    inputPrompt({
        label,
        initialValue = "",
        title = "Confirm",
        info = undefined,
        message = undefined,
        positiveAction = "Confirm",
        negativeAction = "Cancel",
        password = false,
        textArea = false,
        placeholder
    }) {
        return new Promise((resolve, reject) => (
            this.showOverlay2(onClose => (
                <InputDialog {...{ label, title, info, message, positiveAction, negativeAction, initialValue, password, textArea, placeholder }} onCancel={() => {
                    onClose();
                    resolve(undefined)
                }} onConfirm={value => {
                    onClose();
                    resolve(value)
                }} />
            ), () => resolve(false))
        ))
    }

    inputPromptQuantity({
        label,
        initialValue = "",
        title = "Confirm",
        info = undefined,
        message = undefined,
        positiveAction = "Confirm",
        negativeAction = "Cancel",
        password = false,
        textArea = false,
        placeholder,
        measurementType
    }) {
        return new Promise((resolve, reject) => (
            this.showOverlay2(onClose => (
                <InputDialogQuantity {...{ label, title, info, message, positiveAction, negativeAction, initialValue, password, textArea, placeholder, measurementType }} onCancel={() => {
                    onClose();
                    resolve(undefined)
                }} onConfirm={value => {
                    onClose();
                    resolve(value)
                }} />
            ), () => resolve(false))
        ))
    }

    dateInputPrompt({
        label,
        initialValue = "",
        title = "Confirm",
        message = undefined,
        positiveAction = "Confirm",
        negativeAction = "Cancel",
    }) {
        return new Promise((resolve, reject) => (
            this.showOverlay2(onClose => (
                <DateInputDialog {...{ label, title, message, positiveAction, negativeAction, initialValue }} onCancel={() => {
                    onClose();
                    resolve(undefined)
                }} onConfirm={value => {
                    onClose();
                    resolve(value)
                }} />
            ), () => resolve(false))
        ))
    }

    singleDateInputPrompt({
        label,
        initialValue = "",
        title = "Confirm",
        message = undefined,
        positiveAction = "Confirm",
        negativeAction = "Cancel",
        initialTimestamp = 0,
    }) {
        return new Promise((resolve, reject) => (
            this.showOverlay2(onClose => (
                <SingleDateInputDialog {...{ label, title, message, positiveAction, negativeAction, initialValue, initialTimestamp }} onCancel={() => {
                    onClose();
                    resolve(undefined)
                }} onConfirm={value => {
                    onClose();
                    resolve(value)
                }} />
            ), () => resolve(false))
        ))
    }


    promptClearanceCode(msg, info) {
        return this.inputPrompt({
            label: "Clearance Code",
            title: "Authorization",
            message: msg,
            info,
            password: true
        })
    }

}

export default new UIUtil();