
import './report-layout.scss'
import React, { useState } from 'react'
import { useRefSize } from '../../../util/useSize'
import { QuickFilterBar } from './quick-filter-bar'
import { FormulaValues } from './formula-values'
import { Header } from './header'
import { Footer } from './footer'
import { NewReportDesignDialog } from '../../dialogs/new-report-design-dialog'
import useStore from '../../../hooks/useStore'
import { RenameReportDesignDialog } from '../../dialogs/rename-report-design-dialog'
import Button from '../../../components/Button'
// import { NewReportDesignDialog } from '../../dialogs/new-report-design-dialog'



export function ReportLayout({ info, setInfo, store, state, originalState, setState, children, isListMode, isManageMode, embeddedMode, componentMode, selectionMode, viewBased }) {
    const [headerRef, headerSize] = useRefSize()
    const [showCreate, setShowCreate] = useState(false);
    const [showRename, setShowRename] = useState(false);

    const [selectedId, setSelectedId] = useStore(store, "selectedSavedReportId")
    const onNewDesign = (design) => {
        setInfo(prev => ({ ...prev, savedReports: [...prev.savedReports, design] }))
        setSelectedId(design.id)
    }
    const onRenamedDesign = (design) => {
        setInfo(prev => ({ ...prev, savedReports: prev.savedReports.map(item => item.id === design.id ? design : item) }))
    }
    return (
        <div style={{ height: '100%' }}>
            <div ref={headerRef} style={{ width: '100%', }}>
                {!embeddedMode && <Header isManageMode={isManageMode} info={info} setInfo={setInfo} store={store}
                    state={state} originalState={originalState} setState={setState}
                    onCreateBtn={() => setShowCreate(true)} onRenameBtn={() => setShowRename(true)}
                    isListMode={isListMode} />}
                {embeddedMode && !componentMode && <div style={{ width: '100%', paddingTop: '0rem', borderBottomLeftRadius: 15, borderBottomRightRadius: 15, gap: '0.5rem', paddingBottom: 'calc(5rem + 37px)', paddingInline: '6rem', background: '#f1f1f1', borderBottom: '0px solid #ffffff20', display: 'flex', alignItems: 'center', color: 'black', }} />}


            </div>
            <div style={{ width: '100%', height: `calc(100% - ${headerSize.height}px)` }}>
                {children}
            </div>
            {/* <Footer report={report} /> */}

            <NewReportDesignDialog reportName={info.name} reportTitle={info.title} designState={state}
                open={showCreate} onClose={() => setShowCreate(false)} onCreated={onNewDesign} />

            <RenameReportDesignDialog reportTitle={info.title}
                design={showRename ? info?.savedReports?.find(item => item.id === selectedId) : undefined}
                open={showRename} onClose={() => setShowRename(false)} onRenamed={onRenamedDesign} />
        </div>
    )
}