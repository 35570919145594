import React, { createRef, forwardRef, useImperativeHandle } from 'react'
import Page from '../../base/Page';

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    RequestQuote32,
    Save16
} from '@carbon/icons-react'
import { TileGroup, RadioTile, TextArea, ComboBox, ButtonSet, Switch, ContentSwitcher, NumberInput } from 'carbon-components-react';
import { JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT, JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT, STATE_TYPE_JOURNAL_ENTRY, TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_DIRECTION_TYPE_OUTWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_OTHER, TRANSACTION_PARTY_TYPE_SUPPLIER } from '../../constants/Constants';
import Button from '../../components/Button';
import Util from '../../util/Util';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { withRouter } from 'react-router-dom';
import JournalEntryEditor from './JournalEntryEditor';
import { useDraft } from '../../templates/draft/draft';
import { SidePanel } from '../../templates/draft/components/side-panel';
import { EditJournalEntry } from './edit-journal-entry';

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>
        </div>
        {children}
    </div>
)

const DraftBtn = forwardRef(({ setData, getData }, ref) => {
    const draft = useDraft(STATE_TYPE_JOURNAL_ENTRY, setData, getData)

    useImperativeHandle(ref, () => draft, [draft])

    return (<>
        {draft.draftManagement}
        {draft.draftDialogs}
    </>)
})

class JournalEntriesCreatorPage extends Page {


    constructor(props) {
        super(props);

        this.editMode = props.editing

        this.state = {
            ...this.state,

            amountsValue: this.editMode ? this.editMode.amounts : [],
            infoValue: this.editMode ? this.editMode.info : "",

            journalEditorKey: Util.newTempId(),

            accountTree: [],
            endpointsList: undefined,

            creatingJournalEntry: false
        }

        this.draftBtnRef = createRef();
    }

    resetToDefaults() {
        this.setState({
            amountsValue: this.editMode ? this.editMode.amounts : [],
            infoValue: this.editMode ? this.editMode.info : "",

            journalEditorKey: Util.newTempId(),
        })
    }

    createJournalEntry() {
        let entry = {
            date: this.state.amountsValue[0].date !== undefined ? this.state.amountsValue[0].date : new Date().getTime(),
            // REPEATED IN transaction creator page
            amounts: this.state.amountsValue.map(amount => ({
                accountLedgerId: amount.account.id,
                subsidiaryLedgerId: amount.subsidiaryAccount ? amount.subsidiaryAccount.id : 0,
                subsidiaryLedgerType: amount.subsidiaryAccount ? amount.subsidiaryLedgerType : -1,
                amount: amount.amount,
                type: amount.type,
                narration: amount.narration,
                //amountTags: (amount.amountTags ?? []).map(tag => ({ name: tag.label }))
                amountTags: amount.amountTags
            })),
            info: this.state.infoValue
        }

        this.setState({ creatingJournalEntry: true });
        const listener = response => {
            this.setState({ creatingJournalEntry: false });
            if (response.status === true) {
                UIUtil.showSuccess();
                this.props.history.replace("/journal-entry/" + response.payload + "?temp=" + Math.random())
            } else {
                UIUtil.showError(response.message)
            }
        };
        if (this.editMode) {
            Api.updateJournalEntry({ ...entry, id: this.editMode.id }, listener)
        } else {
            Api.createJournalEntry(entry, listener)
        }
    }

    onPageStart() {
        //this.callPageApi(listener => Api.getAccountTree(false, listener), payload => {
        this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => {
            const accountTree = payload.accountTree;
            for (const group of accountTree) {
                //group.items = group.items.filter(item => item.name != "Inventory" && item.name != "Cash and cash equivalents");
                group.items = group.items.filter(item => item.name != "Inventory");
            }

            return { endpointsList: payload, accountTree }
        })
    }

    getTotalAmount(type) {
        let total = 0;
        for (const amount of this.state.amountsValue) {
            if (amount.type == type) {
                total += parseFloat(amount.amount);
            }
        }
        //return total;
        return parseFloat(total.toFixed(2));
    }

    canCreate() {
        if (this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT) <= 0) {
            return false;
        }

        if (this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT) != this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT)) {
            return false;
        }

        for (const amount of this.state.amountsValue) {
            if (amount.account == undefined) {
                return false;
            }

            if (amount.subsidiaryLedgerType !== null && amount.subsidiaryLedgerType !== undefined && amount.subsidiaryLedgerType != -1 && !amount.subsidiaryAccount) {
                return false;
            }
        }

        return true;
    }

    renderEntryEditor() {
        return (
            <JournalEntryEditor
                key={this.state.journalEditorKey}
                defaultEntries={this.state.amountsValue}
                onEntriesUpdate={entries => this.setState({ amountsValue: entries })}
                totalDebit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT)}
                totalCredit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT)}
                accountTree={this.state.accountTree}
                endpointsList={this.state.endpointsList} />
        )
    }

    renderInformation() {
        return (
            <TextArea placeholder="Note here..." value={this.state.infoValue} onChange={e => this.setState({ infoValue: e.target.value })} />
        )
    }

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: this.editMode ? 0 : '6rem', paddingBottom: this.editMode ? 0 : '6rem' }}>
                {/* <div style={{ width: '75vw' }}> */}
                <div style={{ width: '97vw' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        {!this.editMode && <div style={{ flex: 1 }}>
                            <h1>Journal Entry</h1>
                            <p style={{ fontSize: 18 }}>Recording</p>
                        </div>}
                        <DraftBtn
                            ref={this.draftBtnRef}
                            setData={data => {
                                const defs = {
                                    amountsValue: this.editMode ? this.editMode.amounts : [],
                                    infoValue: this.editMode ? this.editMode.info : "",
                                }
                                this.setState({
                                    amountsValue: data?.amountsValue ?? defs.amountsValue,
                                    infoValue: data?.infoValue ?? defs.infoValue,
                                    journalEditorKey: Util.newTempId(),
                                })
                            }}
                            getData={() => {
                                // let amountsValue = [];
                                // for (let i = 0; i < 500; i++) {
                                //     amountsValue.push([{ "id": "70a9a7ea-41e0-486e-978b-d174b9bbf006" + i, "account": { "id": 37403, "name": "HSBC", "group": false, "items": [], "parentId": 37401, "parentName": null, "type": 0, "balance": null, "groupTotal": null, "accountMode": 0, "aliasId": false, "accountIsParent": false, "balanceTags": null }, "narration": "This is the right one", "amountTags": [{ "label": "Test Class", "name": "Test Class" }], "type": 1, "amount": "25", "subsidiaryAccount": { "id": 76, "value": "Test supplier" }, "subsidiaryLedgerType": 9 }])
                                // }
                                // return { amountsValue, infoValue: "" }
                                return ({ amountsValue: this.state.amountsValue, infoValue: this.state.infoValue })
                            }} />
                    </div>

                    <Section icon={Number_132} title="Entry">
                        {this.renderEntryEditor()}
                    </Section>

                    {/* <Section icon={Number_232} title="Additional Information (optional)"> */}
                    <Section icon={Number_232} title="Description (optional)">
                        {this.renderInformation()}
                    </Section>


                    <Section icon={Number_332} title="Confirm" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            {!this.editMode && <Button onClick={() => this.resetToDefaults()} disabled={this.state.creatingJournalEntry} kind="ghost" renderIcon={Reset16}>Reset to Defaults</Button>}
                            <ButtonSet style={{ width: 392 }}>
                                <Button kind="secondary" onClick={() => this.draftBtnRef.current.setShowSaveDraft(true)} renderIcon={Save16}>{false ? 'Update Draft' : 'Save Draft'}</Button>
                                <Button onClick={() => this.createJournalEntry()} disabled={!this.canCreate()} loading={this.state.creatingJournalEntry} renderIcon={RequestQuote32}>
                                    {this.editMode ? "Save Edits" : "Create Journal Entry"}
                                </Button>
                            </ButtonSet>

                            {/* <ButtonSet style={{ width: 392 }}>
                                <Button onClick={() => this.resetToDefaults()} disabled={this.state.creatingJournalEntry} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                                <Button onClick={() => this.createJournalEntry()} disabled={!this.canCreate()} loading={this.state.creatingJournalEntry} renderIcon={RequestQuote32}>Create Journal Entry</Button>
                            </ButtonSet> */}
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

}

export default withRouter(JournalEntriesCreatorPage);