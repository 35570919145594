import { Checkbox, ComposedModal, ModalBody, ModalFooter, ModalHeader, Tag, TextInput } from "carbon-components-react";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";

function ColView({ actualColumns, first, showGroup, col, setColumnVisibility }) {
    const visible = useMemo(() => actualColumns.find(column => column.id === col.id) !== undefined, [actualColumns, col])
    const onClick = () => setColumnVisibility(col, !visible)
    return (
        <div>
            {showGroup && Util.isStringExists(col.group) &&
                // <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem', marginTop: '1rem' }}>{col.group}</p>}
                <Tag size="sm" type="high-contrast" style={{ marginTop: first ? 0 : '1rem' }}>{col.group}</Tag>}
            <div style={{ paddingLeft: 0 }} onClick={onClick}>
                <Checkbox labelText={col.title} checked={visible} />
            </div>
        </div>
    )
}

export function NewReportDesignDialog({ open, onClose, allColumns, actualColumns, setColumnVisibility }) {
    return (
        <ComposedModal size="sm" open={open} onClose={onClose}>
            <ModalHeader label="All Columns" title="Column Visibility" />
            <ModalBody hasScrollingContent>
                {/* {JSON.stringify(actualColumns)} */}
                {allColumns.filter($ => $.type !== "TREE_NODE").map((column, i) =>
                    <ColView key={column.id} actualColumns={actualColumns} setColumnVisibility={setColumnVisibility}
                        first={i === 0} showGroup={i > 0 ? allColumns[i - 1].group !== column.group : true} col={column} />)}
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Done
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}