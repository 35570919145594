import { ClickableTile, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { RowDelete32, ArrowRight16, } from '@carbon/icons-react'
import Util from '../../util/Util';

const DraftItem = ({ draft, onDeleted, onClick }) => {
    const [deleting, setDeleting] = useState(false);

    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            setDeleting(true);
            Api.deleteStateDraft(draft.id, response => {
                setDeleting(false)
                if (response.status === true) {
                    onDeleted();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        })
    }

    return (
        <div style={{ marginBottom: '0.5rem', display: 'flex' }}>
            <ClickableTile light disabled={deleting} handleClick={onClick} style={{ flex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <h4 style={{ fontSize: 14 }}>{draft.title}</h4>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Last modified: {Util.getDate(draft.lastModifiedDate)}</p>
                    </div>

                    <div style={{ width: 10 }} />
                    <ArrowRight16 />
                </div>
            </ClickableTile>
            <Button loading={deleting} onClick={onDeleteBtn} kind="danger--tertiary" className="bx--tooltip--hidden" renderIcon={RowDelete32} hasIconOnly data-tip="Delete Cart" />
        </div>
    )
}

export default ({ open, onClose, stateType, onLoadDraftRequest }) => {
    const [loading, setLoading] = useState(true);
    const [drafts, setDrafts] = useState([]);

    useEffect(() => {
        if (open) {
            setLoading(true);
            Api.getMyStateDrafts(stateType, response => {
                setLoading(false)
                if (response.status === true) {
                    setDrafts(response.payload);
                } else {
                    UIUtil.showError();
                    onClose();
                }
            })
        }
    }, [open])

    const removeDraft = index => {
        let newDrafts = [...drafts];
        newDrafts.splice(index, 1);
        setDrafts(newDrafts);
    }

    return (
        <ComposedModal open={open} onClose={onClose} size="sm">
            <ModalHeader label="Drafts" title="Load from draft" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                {loading ? <InlineLoading /> :
                    drafts.length == 0 ? <p style={{ fontSize: 12, opacity: 0.65 }}>No drafts saved</p> : (
                        drafts.map((draft, index) => <DraftItem key={"draft-" + draft.id} draft={draft} onDeleted={() => removeDraft(index)} onClick={() => onLoadDraftRequest(draft)} />)
                    )}
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}