import "./dashboard-rest.scss"
import { withLoadablePage, withLoadablePagePropsAndProps } from "../../base/Page"
import Api from "../../session/Api"
import { Restaurant32, TagGroup24, Analytics16, Restaurant16, Purchase16 } from '@carbon/icons-react'
import ImageView from "../../components/ImageView"
import React, { useRef, useState } from "react"
import { MosaicGrid } from "../../components/mosaic-grid"
import { Pie } from "react-chartjs-2"
import { Analytics } from "./analytics"
import { PaymentMethods } from "./payment-methods"
import { TopItems } from "./top-items"
import { OrderTypes } from "./order-types"
import { Overview } from "./overview"
import { TopCustomers } from "./top-customers"
import { NewCustomers } from "./new-customers"
import { PopularAreas } from "./popular-areas"
import { PopularTimes } from "./popular-times"
import { CouponsApplied } from "./coupons-applied"
import { ItemQtyRevenue } from "./item-qty-revenue"
import Util from "../../util/Util"
import { useNonArrayLocalStorage, useObjectLocalStorage } from "../../hooks/useLocalStorage"
import { isMobileScreen } from "../../App"
import { ComboBoxField } from "../../components/ComboBoxField"
import { ContentSwitcher, Switch } from "carbon-components-react"
import { RawStatelessDateRange, StatelessDateRange } from "../../reporting-engine/components/basic-filter/date-range"
import { InactiveCustomers } from "./inactive-customers"
import { UserActions } from "./user-actions"
import { EVENT_DASHBOARD_RELOAD, EVENT_SALES_ORDER_UPDATE, useSubscribe } from "../../session/SocketSession"
import { CarbonDateTimeRange } from "../../reporting-engine/components/basic-filter/date-time-range"
import { addDays, startOfDay } from "date-fns"

export const Widget = ({ icon, title, children, style = {} }) => (
    <div style={{
        display: 'inline-block',
        //border: '1px solid #A4243B',
        border: '1px solid #00000010',
        borderRadius: 15, borderTopLeftRadius: 7,
        overflow: 'hidden',
        // boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06)',
        background: "#fafafa",
        ...style
    }}>
        <div style={{
            paddingLeft: '1rem', paddingRight: '3rem', paddingBlock: '0.25rem', borderBottomRightRadius: 15, background: '#A4243B', color: 'white', display: 'inline-flex',
            alignItems: 'center', gap: '0.25rem', position: 'sticky', top: 0, zIndex: 4
        }}>
            {React.createElement(icon)}
            <h4 style={{ fontWeight: 'bold', fontSize: 14 }}>{title}</h4>
        </div>
        <div style={{ padding: '1rem', }}>
            {children}
        </div>
    </div>

)

export const ImageCard = ({ badge }) => (
    <div style={{ background: '#A4243B', borderRadius: 12, border: '1px solid #A4243B', overflow: 'hidden', }}>
        <div style={{ position: 'relative' }}>
            <ImageView style={{ height: 96, borderBottomRightRadius: 12, borderBottomLeftRadius: 12, }} />
            <div style={{
                position: 'absolute', top: 7, right: 7, borderRadius: 5, background: '#A4243B', color: 'white', paddingBlock: 5,
                display: 'flex', justifyContent: 'center', alignItems: 'center', width: 28
            }}>
                <span style={{ fontSize: 14, fontWeight: 'bold' }}>{badge}</span>
            </div>

            <div style={{
                position: 'absolute', bottom: 7, left: 7, borderRadius: 5, background: '#A4243B20', color: '#A4243B', border: '1px solid #A4243B', color: 'white', paddingBlock: 5,
                display: 'flex', justifyContent: 'center', alignItems: 'center', paddingInline: 10
            }}>
                <span style={{ fontSize: 14, fontWeight: 'bold', color: '#A4243B' }}>2,332x</span>
            </div>
        </div>
        <div style={{ paddingBlock: 5, paddingInline: 10 }}>
            <p style={{ fontSize: 14, color: 'white', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Joojeh Kebab fewuh weihfweiufwefwe uowefuewfhew ouifhew</p>
        </div>
    </div>
)



const Content = withLoadablePagePropsAndProps(props => listener => Api.getDashboardRestaurant(props.request, listener), ({ request, payload }) => {
    const [data, setData] = useState(() => ({
        ...payload,
        newCustomers: {
            ...payload.newCustomers,
            customers: payload.newCustomers.customers.map($ => ({ ...$, initial: true }))
        },
        userActions: {
            ...payload.userActions,
            actions: payload.userActions.actions.map($ => ({ ...$, initial: true }))
        }
    }));
    const loading = useRef(false);
    useSubscribe(EVENT_DASHBOARD_RELOAD, () => {
        if (loading.current) {
            return;
        }
        loading.current = true;
        Api.getDashboardRestaurant(request, response => {
            if (response.status === true) {
                setData(response.payload);
            }
            loading.current = false;
        })
    })

    if (isMobileScreen) {
        return (
            <div className="content">
                <Overview data={data} />
                <div style={{ height: '1rem' }} />
                <Analytics data={data} />
                <div style={{ height: '1rem' }} />
                <NewCustomers data={data} />
                <div style={{ height: '1rem' }} />
                <InactiveCustomers data={data} />
                <div style={{ height: '1rem' }} />
                <UserActions data={data} />
            </div>
        )
    }

    return (
        <div className="content">
            <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Analytics data={data} />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Overview data={data} />
                    <NewCustomers data={data} />
                    {/* <CouponsApplied data={data} /> */}
                </div>
            </div>
            <div style={{ height: '1rem' }} />
            <div style={{ display: 'flex', gap: '1rem' }}>
                <InactiveCustomers data={data} />
                <UserActions data={data} />
            </div>

            {/* <div style={{ height: '1rem' }} />
            <div style={{ display: 'flex', gap: '1rem' }}>
                <PopularAreas data={data} />
                <OrderTypes data={data} />
            </div>
            <div style={{ height: '1rem' }} />
            <div style={{ display: 'flex', gap: '1rem' }}>
                <PaymentMethods data={data} />
                <PopularTimes data={data} />
            </div>
            <div style={{ height: '1rem' }} />
            <div style={{ display: 'flex', gap: '1rem' }}>
                <TopCustomers data={data} />
                <TopItems data={data} />
            </div>
            <div style={{ height: '1rem' }} />
            <ItemQtyRevenue data={data} /> */}
        </div>
    )
})

const DEFAULT_REQQUEST = {
    key: Util.newTempId(),
    range: "TODAY",
    storeId: 0,
    startDate: 0,
    endDate: 0
}

const View = ({ payload: endpoints }) => {
    const [request, setRequest] = useNonArrayLocalStorage('rest-dashboard-request', DEFAULT_REQQUEST);
    const updateReq = update => setRequest(req => ({ ...req, ...update, key: Util.newTempId() }))
    const [range, setRange] = [request.range, range => updateReq({
        range,
        startDate: range === "CUSTOM" ? new Date().getTime() : 0,
        endDate: range === "CUSTOM" ? new Date().getTime() : 0,
    })];
    const [storeId, setStoreId] = [request.storeId, storeId => updateReq({ storeId })];
    const [startDate, setStartDate] = [request.startDate, startDate => updateReq({ startDate })];
    const [endDate, setEndDate] = [request.endDate, endDate => updateReq({ endDate })];


    return (
        <div className="dashboard-rest">
            <div className="header" style={{ paddingInline: isMobileScreen ? '0.5rem' : '3rem', paddingBlock: isMobileScreen ? '0.5rem' : '1rem' }}>
                {!isMobileScreen && <>
                    <Restaurant16 />
                    <h4 style={{ flex: 1, }}>Dashboard</h4>
                </>}

                <ContentSwitcher style={{ width: isMobileScreen ? '100%' : 500 }} selectedIndex={
                    range === "TODAY" ? 0 :
                        range === "WEEK" ? 1 :
                            range === "MONTH" ? 2 :
                                range === "CUSTOM" ? 3 : 0
                } onChange={e => setRange(e.name)}>
                    <Switch name={"TODAY"} text="Today" />
                    <Switch name={"WEEK"} text="Week" />
                    <Switch name={"MONTH"} text="Month" />
                    <Switch name={"CUSTOM"} text="Range" />
                </ContentSwitcher>
            </div>
            <div className={isMobileScreen ? "mobile-subheader" : "subheader"} style={{ paddingInline: isMobileScreen ? '0.5rem' : '3rem', flexWrap: 'wrap' }}>
                {!isMobileScreen && <p style={{ fontSize: 14, opacity: 0.65, flex: 1 }}>
                    Filter
                </p>}
                {range === "CUSTOM" && <>
                    <RawStatelessDateRange light label="Sales" start={startDate} setStart={setStartDate} end={endDate} setEnd={setEndDate} />
                    {/* <CarbonDateTimeRange label="report" start={startDate} setStart={setStartDate} end={endDate} setEnd={setEndDate} /> */}
                </>}
                <ComboBoxField light value={storeId} setValue={setStoreId} options={endpoints.stores} title={"Store"} placeholder={"All stores"} />
            </div>
            <Content key={request.key} request={request} />
        </div>
    )
}
//29


export const RestDashboard = withLoadablePage(Api.getDashboardRestaurantEndpoints.bind(Api), View);
