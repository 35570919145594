import React, { useState } from 'react'

export default () => {
    const [hover, setHover] = useState(false);
    return { 
        isHover: hover, 
        init: () => ({ 
            onMouseEnter: () => setHover(true),
            onMouseLeave: () => setHover(false)
        }) 
    }
}