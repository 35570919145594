import { useMemo } from "react";
import { withLoadablePageAndProps, withLoadablePagePropsAndProps } from "../../base/Page";
import { OBJECT_TYPE_COURSE, OBJECT_TYPE_LEAD, OBJECT_TYPE_SHIPMENT } from "../../constants/ObjectTypes";
import { useForceLockScroll } from "../../hooks/useLockScroll";
import Api from "../../session/Api";
import { getAccount } from "../../session/SessionManager";
import { BoardViewContent } from "./board-view-content";


export function Page({ apiver2, bvParentId, bvParentType, shipmentId, courseId, leadId, payload: { boardViews, allUsers } }) {
    const [parentId, parentType] = useMemo(() => {
        if (apiver2) {
            return [bvParentId, bvParentType]
        }

        const parentId = shipmentId ?? (courseId ?? (leadId ?? 0));
        const parentType = shipmentId ? OBJECT_TYPE_SHIPMENT : (courseId ? OBJECT_TYPE_COURSE : (leadId ? OBJECT_TYPE_LEAD : -1));
        return [parentId, parentType]
    }, [apiver2, bvParentId, bvParentType, shipmentId, courseId, leadId])

    useForceLockScroll(true)
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <BoardViewContent {...({ parentId, parentType, initialBoardViews: boardViews, allUsers })} />
        </div>
    )
}

export const BoardViewPage = withLoadablePagePropsAndProps(props => listener => {
    if (props.apiver2) {
        Api.getMyBoardViews(props.bvParentId, props.bvParentType, listener)
        return;
    }

    const parentId = props.shipmentId ?? (props.courseId ?? (props.leadId ?? 0));
    const parentType = props.shipmentId ? OBJECT_TYPE_SHIPMENT : (props.courseId ? OBJECT_TYPE_COURSE : (props.leadId ? OBJECT_TYPE_LEAD : -1));
    Api.getMyBoardViews(parentId, parentType, listener);
}, Page);

// export function Page({ courseId, leadId, payload }) {
//     const recordId = useMemo(() => courseId ?? (leadId ?? getAccount().id), [leadId, courseId])

//     useForceLockScroll(true)
//     return (
//         <div style={{ width: '100%', height: '100%' }}>
//             <BoardViewContent recordId={recordId} initalData={payload ? JSON.parse(payload) : {}} />
//         </div>
//     )
// }

// export const BoardViewPage = withLoadablePagePropsAndProps(props => listener => Api.getBoardView(props.courseId ?? (props.leadId ?? getAccount().id), listener), Page);