import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CONTACT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL, OBJECT_TYPE_VENDOR } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import { BalanceView } from '../../views/accounting/BalanceView';
import AllSubsidiaryReportView from '../accounting-reports/AllSubsidiaryReportView';
import StockFlowListView from '../stock-flow/StockFlowListView';
import TransactionListView from '../transaction/TransactionListView';

const ContactsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'vendorId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Contacts",
                subTitle: "Vendor contacts",
                objectType: OBJECT_TYPE_CONTACT,
                pagePath: "/contacts/",
                history: history
            })}
        </div>
    )
}


const SubsidiaryReport = ({ item }) => {
    return <AllSubsidiaryReportView itemId={item.id} itemType={OBJECT_TYPE_VENDOR} />
}

const TransactionsView = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <TransactionListView partyList={{ id: item.id, type: OBJECT_TYPE_VENDOR }} />
        </div>
    )
}

class VendorDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_VENDOR, listener)
            } else {
                Api.getItem(OBJECT_TYPE_VENDOR, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_VENDOR,
                    itemResult: this.state.itemResult,
                    pagePath: "/vendors/",
                    history: this.props.history,
                    hasCustomBtn: <>
                        <div style={{ width: '1rem' }} />
                        <BalanceView item={this.state.itemResult.item} subsidiaryType={OBJECT_TYPE_VENDOR} />
                    </>,
                    customTabs: [
                        {
                            title: "Contacts",
                            component: ContactsList
                        },
                        {
                            title: "Ledger Report",
                            component: SubsidiaryReport
                        },
                        {
                            title: "Transactions",
                            component: TransactionsView
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(VendorDetailPage);