import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import TransactionCreatorPage from "../../../pages/transaction/TransactionCreatorPage"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24 } from '@carbon/icons-react'

export const CourierReceipt = newListDetail({
    title: "Courier Receipt",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: NewForm, updateForm: Form,

    isRawPanel: true,

    defaultValues: {

    },



    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

CourierReceipt.openItemInSideView = false;
CourierReceipt.itemToOpenLink = data => '/transaction/' + data.id;

function NewForm({ endpoint }) {
    return <TransactionCreatorPage courier courierList={endpoint.couriers} accountsPayableId={endpoint.accountsPayableId} />
}

function Form({ endpoint, form }) {
    return (
        <div>
            <form.TextField fieldKey="name" title="Name" />
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
