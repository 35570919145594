import { FormGroup, Link, Tab, Tabs, Tag } from "carbon-components-react";
import Button from "../../components/Button";
import ImageView from "../../components/ImageView";
import Api from "../../session/Api";
import { FormSection } from "../../templates/form/form";
import Util from "../../util/Util";
import { Cube32, Checkmark16, Close16, CircleDash16, Compare16, Undo16, IncreaseLevel16, Launch20, Add20, ArrowRight16 } from '@carbon/icons-react'
import { useState } from "react";
import UIUtil from "../../util/UIUtil";
import { renderNode } from "../../components/imperative-render";
import { InvReqAssignSf, InvRequestItemsDialog } from "../pos/dialogs/any-search-dialog";
import { openPdf } from "../../markup-template-engine";
import { background } from "@carbon/themes";
import ProfilePic from "../../components/ProfilePic";
import { OBJECT_INV_REQUEST, OBJECT_TYPE_ACCOUNT } from "../../constants/ObjectTypes";
import { Link as LK } from "react-router-dom"
import { DESTINATION_TYPE_STORE, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER } from "../../constants/Constants";
import { MinimalTable } from "../../components/minimal-table";
import { AdvancedNotesView } from "../../views/advanced-notes/advanced-notes-view";

const ItemStatus = ({ color, text }) => <>
    <div style={{
        borderRadius: 5, background: `${color}20`, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingInline: '0.5rem', height: 32,
        border: `1px solid ${color}60`, marginInline: '1rem'
    }}>
        <h6 style={{ color: color, fontWeight: 'normal', fontSize: 14 }}>{text}</h6>
    </div>
</>

const StatusIndicator = ({ status }) => {
    const { text, color } = (() => {
        switch (status) {
            case "PENDING": return {
                text: "Pending",
                color: "#ffcc00"
            }

            case "APPROVED": return {
                text: "Approved",
                color: "#0f62fe"
            }

            case "SENT": return {
                text: "Sent",
                color: "#ff5a00"
            }

            case "RECEIVED": return {
                text: "Received",
                color: "#00a300"
            }

            case "CANCELLED": return {
                text: "Cancelled",
                color: "#1c1c1c"
            }

            case "REJECTED": return {
                text: "Rejected",
                color: "#990000"
            }

            case "REVOKED": return {
                text: "Revoked",
                color: "#990000"
            }

            default: return {
                text: "Unknown",
                color: "#1c1c1c"
            }
        }
    })();

    return <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', paddingLeft: '0.25rem' }}>
            <div style={{ width: 6, height: 6, background: color, borderRadius: 50, boxShadow: `0 0 50px 15px ${color}CC` }} />
            <span>{text}</span>
        </div>
    </>
};

function PendingStatusAction({ data, onUpdate }) {
    const [loading, setLoading] = useState('');

    const onCancelBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to cancel this Stock Request?",
        })
        if (!confirmed) {
            return;
        }

        const note = await UIUtil.inputPrompt({
            title: "Cancel Reason",
            message: `Enter the reason for canceling the request.`,
            label: "Reason",
            textArea: true,
        })
        if (!note) {
            UIUtil.showInfo("Reason is required!");
            return;
        }

        setLoading('cancel');
        try {
            const [success, response] = await Api.try((api, listener) => api.makeInvRequestCancelled(data.id, note, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoading('');
        }
    }

    const onRejectBtn = async () => {
        setLoading('reject');
        try {
            const [success, response] = await Api.try((api, listener) => api.makeInvRequestRejected(data.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoading('');
        }
    }

    const onApproveBtn = async () => {
        setLoading('approve');
        try {
            const [success, response] = await Api.try((api, listener) => api.makeInvRequestApproved(data.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoading('');
        }
    }

    if (data.requesting) {
        return <>
            <Button loading={loading === 'cancel'} disabled={!!loading} onClick={onCancelBtn}
                style={{ borderRadius: 50 }} renderIcon={Close16} size="sm" iconDescription="Cancel" kind="danger">
                Cancel Request
            </Button>
        </>;
    }

    return <>
        <Button loading={loading === 'reject'} disabled={!!loading} onClick={() => UIUtil.confirmMsg('This action is irreversible', onRejectBtn)}
            style={{ borderRadius: 50 }} renderIcon={Close16} size="sm" iconDescription="Reject" kind="danger">
            Reject
        </Button>
        <Button loading={loading === 'approve'} disabled={!!loading} onClick={() => UIUtil.confirmMsg('This action is irreversible', onApproveBtn)}
            style={{ borderRadius: 50 }} renderIcon={Checkmark16} size="sm">
            Approve
        </Button>
    </>;
}

function ApprovedStatusAction({ data, onUpdate }) {
    const [loading, setLoading] = useState('');

    const onRevokeBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to revoke this Stock Request?",
        })
        if (!confirmed) {
            return;
        }

        const note = await UIUtil.inputPrompt({
            title: "Revoke Reason",
            message: `Enter the reason for revoking the request.`,
            label: "Reason",
            textArea: true,
        })
        if (!note) {
            UIUtil.showInfo("Reason is required!");
            return;
        }

        setLoading('revoke');
        try {
            const [success, response] = await Api.try((api, listener) => api.revokeInvRequest(data.id, note, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoading('');
        }
    }

    const onSendBtn = async () => {
        setLoading('send');
        try {
            const [success, response] = await Api.try((api, listener) => api.makeInvRequestSent(data.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoading('');
        }
    }

    if (data.requesting) {
        return <>
            <Tag type="green" style={{ borderRadius: 5 }}>Awaiting Arrival</Tag>
        </>;
    }

    return <>
        <Button loading={loading === 'reject'} disabled={!!loading} onClick={onRevokeBtn}
            style={{ borderRadius: 50 }} renderIcon={Close16} size="sm" iconDescription="Reject" kind="danger--ghost">
            Revoke
        </Button>
        <Button loading={loading === 'send'} disabled={!!loading} onClick={() => UIUtil.confirmMsg('This action is irreversible', onSendBtn)}
            style={{ borderRadius: 50 }} renderIcon={Checkmark16} size="sm" className="cst-btn-green">
            Send Off
        </Button>
    </>
}

function SentStatusAction({ data, onUpdate }) {
    const [loading, setLoading] = useState('');

    const onRevokeBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to revoke this Stock Request?",
        })
        if (!confirmed) {
            return;
        }

        const note = await UIUtil.inputPrompt({
            title: "Revoke Reason",
            message: `Enter the reason for revoking the request.`,
            label: "Reason",
            textArea: true,
        })
        if (!note) {
            UIUtil.showInfo("Reason is required!");
            return;
        }

        setLoading('revoke');
        try {
            const [success, response] = await Api.try((api, listener) => api.revokeInvRequest(data.id, note, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoading('');
        }
    }

    const onReceiveBtn = async () => {
        setLoading('receive');
        try {
            const [success, response] = await Api.try((api, listener) => api.makeInvRequestReceived(data.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoading('');
        }
    }

    return <>
        {data.responding && <Button loading={loading === 'reject'} disabled={!!loading} onClick={onRevokeBtn}
            style={{ borderRadius: 50 }} renderIcon={Close16} size="sm" iconDescription="Reject" kind="danger--ghost">
            Revoke
        </Button>}

        {data.requesting && <Button loading={loading === 'receive'} disabled={!!loading} onClick={() => UIUtil.confirmMsg('This action is irreversible', onReceiveBtn)}
            style={{ borderRadius: 50 }} renderIcon={Checkmark16} size="sm" className="cst-btn-green">
            Received?
        </Button>}
    </>
}

function StatusActions({ data, onUpdate }) {
    switch (data.status) {
        case "PENDING": return <PendingStatusAction data={data} onUpdate={onUpdate} />
        case "APPROVED": return <ApprovedStatusAction data={data} onUpdate={onUpdate} />
        case "SENT": return <SentStatusAction data={data} onUpdate={onUpdate} />
    }

    return <></>
}

const InfoCard = ({ data, onUpdate }) => {
    const { dateCreated, lastUpdated, requestedByName, requestingLocationName } = data;

    const onRevokeBtn = async () => {

    }

    return <>
        <div style={{
            borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
            display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
        }}>
            <div>
                <h6>Status</h6>
                <StatusIndicator status={data.status} />
            </div>
            <div>
                <h6>Requested by</h6>
                {requestedByName}
            </div>
            <div>
                <h6>For Location</h6>
                {requestingLocationName}
            </div>
            <div>
                <h6>Document</h6>
                <Link style={{ cursor: 'pointer', lineHeight: 'unset' }} onClick={() => openPdf('StockRequest', data.id)}>Open PDF</Link>
            </div>
            {/* {data.responding && data.status !== 'REVOKED' && data.status !== "RECEIVED" && <>
                <div>
                    <h6>Termination</h6>
                    <Link style={{ cursor: 'pointer', lineHeight: 'unset', color: "#990000" }} onClick={onRevokeBtn}>Revoke?</Link>
                </div>
            </>} */}
        </div>
    </>;
}

function Item({ data, item, onUpdate }) {
    const [loadingAction, setLoadingAction] = useState('');


    const hasActions = data.status === "APPROVED" && data.responding;
    const replaced = item.status === "REPLACED";
    const actionSubmitted = item.status !== 'PENDING';

    const measurementType = item?.item?.measurementType ?? {};

    const uom = measurementType.unitOfMeasurements?.find($ => $.id === item?.uomId) || {
        symbol: measurementType.defaultUomSymbol || "x", name: measurementType.defaultUomName || "x"
    };

    const replaceUom = item?.replacedByItem?.measurementType?.unitOfMeasurements?.find($ => $.id === item?.replacedByUomId) || {
        symbol: item?.replacedByItem?.measurementType?.defaultUomSymbol || "x", name: item?.replacedByItem?.measurementType?.defaultUomName || "x"
    };

    const onRevertBtn = async () => {
        setLoadingAction('revert');
        try {
            const [success, response] = await Api.try((api, listener) => api.revertInvRequestItem(data.id, item.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoadingAction('');
        }
    }

    const onRejectBtn = async () => {
        setLoadingAction('reject');
        try {
            const [success, response] = await Api.try((api, listener) => api.rejectInvRequestItem(data.id, item.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoadingAction('');
        }
    }

    const onReplaceBtn = async () => {
        const replacing = await new Promise((resolve) => {
            renderNode(onClose => (
                <InvRequestItemsDialog visible onClose={onClose} onItemSelected={selected => {
                    resolve(selected)
                }} />
            ))
        })
        if (!replacing) {
            return;
        }

        let qtyRes = await UIUtil.inputPromptQuantity({
            title: "Quantity",
            message: `Enter the quantity of the replacing item.`,
            label: "Quantity to approve",
            measurementType: replacing.measurementType
        })
        if (!qtyRes) {
            return;
        }

        let { value: qty, uomId } = qtyRes;
        if (!qty) {
            return;
        }

        qty = qty.trim().replaceAll(",", "");

        setLoadingAction('replace');
        try {
            const [success, response] = await Api.try((api, listener) => api.replaceInvRequestItem(data.id, item.id, replacing.itemId, replacing.itemType, qty, uomId, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoadingAction('');
        }
    }

    const onPartialBtn = async () => {
        let qty = await UIUtil.inputPrompt({
            title: "Partial Quantity Approval",
            message: `Enter the quantity to approve. Must be less than the requested quantity (${item.requestedQty} ${uom.symbol}).`,
            label: "Quantity to approve",
        })
        if (!qty) {
            return;
        }

        qty = qty.trim().replaceAll(",", "");

        setLoadingAction('partial');
        try {
            const [success, response] = await Api.try((api, listener) => api.partialInvRequestItem(data.id, item.id, qty, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoadingAction('');
        }
    }

    const onAdditionalBtn = async () => {
        let qty = await UIUtil.inputPrompt({
            title: "Additional Quantity Approval",
            message: `Enter the quantity to approve. Must be more than the requested quantity (${item.requestedQty} ${uom.symbol}).`,
            label: "Quantity to approve",
        })
        if (!qty) {
            return;
        }

        qty = qty.trim().replaceAll(",", "");

        setLoadingAction('additional');
        try {
            const [success, response] = await Api.try((api, listener) => api.additionalInvRequestItem(data.id, item.id, qty, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoadingAction('');
        }
    }

    const onApproveBtn = async () => {
        setLoadingAction('approve');
        try {
            const [success, response] = await Api.try((api, listener) => api.approveInvRequestItem(data.id, item.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            setLoadingAction('');
        }
    }

    return <>
        <div style={{
            background: "#f4f4f4", borderRadius: 7, padding: '0.25rem', width: '100%', marginBottom: replaced ? 0 : '0.5rem', display: 'flex', gap: '0.5rem',
            border: '1px solid #00000010', borderBottomRightRadius: replaced ? 0 : 7
        }}>
            <ImageView src={Api.getThumbnail(item.itemType, item.itemId)} style={{ height: 40, width: 40, borderRadius: 5, overflow: 'hidden', opacity: replaced ? 0.65 : 1 }} />
            <div style={{ flex: 1 }}>
                <h6 style={{ fontWeight: 'bold', textDecoration: replaced ? 'line-through' : null, color: replaced ? '#990000' : '' }}>{item.item?.name}</h6>
                <p style={{ fontSize: 14, wordBreak: 'break-word' }}>
                    {item.requestingNote ? item.requestingNote : <span style={{ fontSize: 12, opacity: 0.65 }}>No note</span>}
                </p>
            </div>
            <div style={{ display: 'flex', height: 40, alignItems: 'center', marginRight: hasActions ? '0.5rem' : 0, gap: '0.25rem' }}>
                <Tag type="cool-gray" style={{ borderRadius: 5 }}>
                    Requested: <strong>{item.requestedQty} {uom.symbol}</strong>
                </Tag>
                {item.status === "PARTIAL" && <Tag type="high-contrast" style={{ borderRadius: 5 }}>
                    Responded: <strong>{item.respondedQty || 0} {uom.symbol}</strong>
                </Tag>}
                {item.status === "ADDITIONAL" && <Tag type="green" style={{ borderRadius: 5 }}>
                    Responded: <strong>{item.respondedQty || 0} {uom.symbol}</strong>
                </Tag>}

                {(data.status !== 'CANCELLED' && data.status !== 'REJECTED' && data.status !== 'REVOKED') && <>
                    {item.status === "PENDING" && <ItemStatus color="#ff5a00" text="Pending" />}
                    {item.status === "REJECTED" && <ItemStatus color="#990000" text="Rejected" />}
                    {item.status === "PARTIAL" && <ItemStatus color="#812add" text="Partial" />}
                    {item.status === "ADDITIONAL" && <ItemStatus color="#00a300" text="Additional" />}
                    {item.status === "PROVIDED" && <ItemStatus color="#0f62fe" text="Approved Exactly" />}
                </>}


                {hasActions && <>
                    {actionSubmitted ? <>
                        <Button
                            loading={loadingAction === 'revert'} disabled={!!loadingAction} onClick={onRevertBtn}
                            renderIcon={Undo16} style={{ borderRadius: 5, width: "calc(((32px + 0.25rem) * 4) - 0.25rem)" }} size="sm" kind="secondary">
                            Revert
                        </Button>
                    </> : <>
                        <Button
                            loading={loadingAction === 'reject'} disabled={!!loadingAction} onClick={onRejectBtn}
                            style={{ borderRadius: 5 }} renderIcon={Close16} size="sm" hasIconOnly iconDescription="Reject" kind="danger" />
                        <Button
                            loading={loadingAction === 'replace'} disabled={!!loadingAction} onClick={onReplaceBtn}
                            style={{ borderRadius: 5 }} renderIcon={Compare16} size="sm" hasIconOnly iconDescription="Replace" kind="secondary" className="cst-btn-orange" />
                        <Button
                            loading={loadingAction === 'partial'} disabled={!!loadingAction} onClick={onPartialBtn}
                            style={{ borderRadius: 5 }} renderIcon={CircleDash16} size="sm" hasIconOnly iconDescription="Partial" kind="secondary" className="cst-btn-purple" />
                        <Button
                            loading={loadingAction === 'additional'} disabled={!!loadingAction} onClick={onAdditionalBtn}
                            style={{ borderRadius: 5 }} renderIcon={IncreaseLevel16} size="sm" hasIconOnly iconDescription="Additional" kind="secondary" className="cst-btn-bright-green" />
                        <Button
                            loading={loadingAction === 'approve'} disabled={!!loadingAction} onClick={onApproveBtn}
                            style={{ borderRadius: 5 }} renderIcon={Checkmark16} size="sm" hasIconOnly iconDescription="Approve Exactly" />
                    </>}
                </>}
            </div>
        </div>

        {replaced && <div style={{
            background: "#1c1c1c", borderRadius: 7, padding: '0.25rem', width: 'calc(100% - 2rem)', marginBottom: '0.5rem', display: 'flex', gap: '0.5rem',
            border: '1px solid #00000010', marginLeft: '2rem', paddingRight: '0.5rem', borderTopLeftRadius: 0, borderTopRightRadius: 0, color: 'white', alignItems: 'center'
        }}>
            <ImageView src={Api.getThumbnail(item.replacedByItemType, item.replacedByItemId)} style={{ height: 40, width: 40, borderRadius: 5, overflow: 'hidden' }} />
            <h6 style={{ fontWeight: 'bold', flex: 1 }}>{item.replacedByItemName}</h6>
            <Tag type="high-contrast" style={{ borderRadius: 5 }}>
                <strong>{item.respondedQty || 0} {replaceUom?.symbol || 'x'}</strong>
            </Tag>
        </div>}
    </>;
}

function ItemList({ data, onUpdate }) {
    return (
        <div>
            {data.items.map(item => <Item key={item.id} data={data} item={item} onUpdate={onUpdate} />)}
        </div>
    )
}

function HistoryEntry({ userId, status, user, date, note, hasNote = true }) {
    if (!date) {
        return null;
    }

    return (
        <div style={{
            display: 'flex', gap: '0.5rem', padding: '0.5rem',
            borderBottom: '1px solid #00000020'
        }}>
            <ProfilePic style={{ minWidth: 40, borderRadius: 5 }} size={40} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, userId)} />
            <div>
                <h6>{status} {user ? ` by ${user}` : ''}</h6>
                <p style={{ fontSize: 12, opacity: 0.65, marginBottom: '0.25rem' }}>On {Util.getFullDate(date)}</p>

                {hasNote && <p style={{ fontSize: 14 }}>
                    {note ? note : <span style={{ fontStyle: 'italic', fontSize: 12 }}>No note provided</span>}
                </p>}
            </div>
        </div>
    )
}

function StockFlowEntry({ entry, data, onUpdate, editable }) {

    const onRemoveBtn = async e => {
        e.stopPropagation();
        e.preventDefault();

        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to remove this from the Stock Request?",
        })
        if (!confirmed) {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.removeStockFlowFromInvRequest(data.id, entry.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            stopLoading();
        }
    }

    return (
        <div onClick={() => window.open(entry.url, "_blank")} className="invreq-hover-box" style={{
            padding: '1rem', borderRadius: 7, border: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingRight: '1.5rem',
            background: "#f4f4f4"
        }}>
            <div style={{ flex: 1 }}>
                <h6>
                    {entry.type} <span style={{ fontWeight: 'normal' }}>#{entry.voucherNo}</span>
                </h6>

                {editable && <Link onClick={onRemoveBtn}
                    style={{ cursor: 'pointer', fontSize: 12, color: '#990000' }}>Remove?</Link>}
            </div>
            <Launch20 />
        </div>
    )
}

function NewStockFlowAssign({ data, onUpdate }) {
    const [visible, setVisible] = useState(false);

    const onAdd = async stockFlow => {
        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.addStockFlowToInvRequest(data.id, stockFlow.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            stopLoading();
        }
    }

    if (data.status === "CANCELLED" || data.status === "REJECTED" || data.status === "REVOKED") {
        return null;
    }

    return <>
        <div onClick={() => setVisible(true)} className="invreq-hover-box" style={{
            padding: '1rem', borderRadius: 7, border: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingRight: '1.5rem',
            background: "white"
        }}>
            <div style={{ flex: 1 }}>
                <h6 style={{ color: '#0f62fe' }}>Assign Stock Flow</h6>
                <p style={{ fontSize: 14, opacity: 0.65 }}>Stock Transfer / Purchase</p>
            </div>
            <Add20 />
        </div>

        <InvReqAssignSf visible={visible} onClose={() => setVisible(false)} onItemSelected={onAdd} />
    </>
}

function CreateStockTransferBtn({ data }) {
    const hasPendingItems = data.items?.some(item => item.status === "PENDING");

    const link = {
        pathname: "/stock-flow-creator",
        defaultFlow: {
            sourceTypeValue: SOURCE_TYPE_STORE,
            destinationTypeValue: DESTINATION_TYPE_STORE,
            destinationIdValue: data.requestingLocationId,

            stockRequestsValue: [{
                id: data.id,
                friendlyStatus: data.friendlyStatus,
                requestedOn: data.requestedOn,
                requestingLocationName: data.requestingLocationName,
            }],

            itemsValue: data.items?.map(item => ({
                itemType: item.replacedByItemType || item.itemType,
                itemId: item.replacedByItemId || item.itemId,
                quantityValue: item.respondedQty,

                _trs_force_uom_id: item.replacedByItemId ? item.replacedByUomId : item.uomId,
            })).filter(item => !!item.quantityValue && parseFloat(item.quantityValue) > 0)
        }
    }

    const onBtn = e => {
        if (hasPendingItems) {
            e.stopPropagation();
            e.preventDefault();

            UIUtil.showInfo("No pending item must remain in request to proceed");
        }
    }

    return <>
        <LK to={link}>
            <Button style={{ borderRadius: 5, opacity: hasPendingItems ? 0.65 : 1 }} size="sm" renderIcon={ArrowRight16} onClick={onBtn} kind={hasPendingItems ? "secondary" : "primary"}>
                Create Stock Transfer
            </Button>
        </LK>
    </>
}

function CreatePurchaseBtn({ data }) {
    const hasPendingItems = data.items?.some(item => item.status === "PENDING");

    const link = {
        pathname: "/stock-flow-creator",
        defaultFlow: {
            sourceTypeValue: SOURCE_TYPE_SUPPLIER,
            destinationTypeValue: DESTINATION_TYPE_STORE,
            destinationIdValue: data.requestingLocationId,

            stockRequestsValue: [{
                id: data.id,
                friendlyStatus: data.friendlyStatus,
                requestedOn: data.requestedOn,
                requestingLocationName: data.requestingLocationName,
            }],

            itemsValue: data.items?.map(item => ({
                itemType: item.replacedByItemType || item.itemType,
                itemId: item.replacedByItemId || item.itemId,
                quantityValue: item.respondedQty,

                _trs_force_uom_id: item.replacedByItemId ? item.replacedByUomId : item.uomId,
            })).filter(item => !!item.quantityValue && parseFloat(item.quantityValue) > 0)
        }
    }

    const onBtn = e => {
        if (hasPendingItems) {
            e.stopPropagation();
            e.preventDefault();

            UIUtil.showInfo("No pending item must remain in request to proceed");
        }
    }

    return <>
        <LK to={link}>
            <Button style={{ borderRadius: 5, opacity: hasPendingItems ? 0.65 : 1 }} size="sm" renderIcon={ArrowRight16} onClick={onBtn} kind={hasPendingItems ? "secondary" : "primary"}>
                Create Purchase
            </Button>
        </LK>
    </>
}

const LOG_COLUMNS = [
    {
        name: "user",
        title: "User",
        flex: 2,
        renderCell: item => <>
            <div style={{ minWidth: 32 }}>
                <ProfilePic src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.userId)} size={32} />
            </div>
            <Link to={"/staff-users/" + item.userId} target="_blank">
                <h5>{item.userFullName}</h5>
            </Link>
        </>
    },
    {
        name: "title",
        title: 'Title',
        flex: 3,
        renderCell: item => <p style={{ fontWeight: 'bold', fontSize: 12 }}>{item.title}</p>
    },
    {
        name: "body",
        title: 'Description',
        flex: 4,
        renderCell: item => <p style={{ fontSize: 12 }}>{item.body}</p>
    },
    {
        name: "date",
        title: 'Date',
        flex: 2,
        renderCell: item => <p style={{ fontSize: 12 }}>{Util.getFullDate(item.timestamp)}</p>
    },
    {
        name: "ip",
        title: 'IP',
        flex: 1,
        renderCell: item => <p style={{ fontSize: 12 }}>{item.ip}</p>
    }
]

export function InvRequestPage({ data: payload }) {
    const [data, setData] = useState(payload);

    const isFinalStatus = data.status === 'RECEIVED' || data.status === 'CANCELLED' || data.status === 'REJECTED' || data.status === 'REVOKED';

    const onUpdate = newData => setData(newData);

    const onApproveAllBtn = async () => {
        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.approveAllInvRequestItems(data.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            stopLoading();
        }
    }

    const onEditRespondingNote = async () => {
        const note = await UIUtil.inputPrompt({
            title: "Responding Note",
            textArea: true,
            initialValue: data.respondingNote || "",
        })
        if (typeof note !== "string") {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.updateInvRequestRespondingNote(data.id, note, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            stopLoading();
        }
    }

    const onEditReceivingNote = async () => {
        const note = await UIUtil.inputPrompt({
            title: "Receiving Note",
            textArea: true,
            initialValue: data.receivingNote || "",
        })
        if (typeof note !== "string") {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.updateInvRequestReceivingNote(data.id, note, listener));
            if (!success) {
                return;
            }

            onUpdate(response);
        } finally {
            stopLoading();
        }
    }

    return <div style={{ paddingInline: '3rem', paddingBlock: '6rem' }}>
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem', }}>
            <Cube32 />
            <div style={{ flex: 1 }}>
                <h4 style={{ fontWeight: 'bold' }}>Stock Request</h4>
                <p>ID: {data.id}</p>
            </div>
            <StatusActions data={data} onUpdate={onUpdate} />
        </div>

        <InfoCard data={data} onUpdate={onUpdate} />
        <div style={{ height: '1rem' }} />

        {data.responding && !isFinalStatus && <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            <CreateStockTransferBtn data={data} />
            <CreatePurchaseBtn data={data} />
        </div>}

        <div style={{ height: '2rem' }} />

        <FormGroup legendText={<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <p style={{ fontWeight: 'bold', color: 'black', flex: 1 }}>
                Items
            </p>
            {data.responding && data.status === "APPROVED" && <Button onClick={onApproveAllBtn} style={{ borderRadius: 5, background: "#1c1c1c10" }} size="sm" kind="ghost">
                Approve All Pending
            </Button>}
        </div>} hasMargin={false}>
            <ItemList data={data} onUpdate={onUpdate} />
        </FormGroup>
        <div style={{ height: '1rem' }} />

        {data.responding && <FormSection title="Assignations">
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '0.5rem' }}>
                {data.assignations?.map(assignation => <StockFlowEntry key={assignation.id} entry={assignation} data={data} onUpdate={onUpdate} editable={!isFinalStatus} />)}
                {!isFinalStatus && <NewStockFlowAssign data={data} onUpdate={onUpdate} />}
            </div>
        </FormSection>}

        {data.responding && (data.status === 'PENDING' || data.status === 'APPROVED') && <>
            <FormGroup legendText={<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <p style={{ fontWeight: 'bold', color: 'black', }}>
                    Responding Note
                </p>
                <Link style={{ cursor: 'pointer' }} onClick={onEditRespondingNote}>
                    Update Note
                </Link>
            </div>} hasMargin={false}>
                <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                    {data.respondingNote ? data.respondingNote : <span style={{ fontSize: 12, opacity: 0.65 }}>No note</span>}
                </p>
            </FormGroup>
        </>}

        {data.requesting && data.status === 'SENT' && <>
            <FormGroup legendText={<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <p style={{ fontWeight: 'bold', color: 'black', }}>
                    Receiving Note
                </p>
                <Link style={{ cursor: 'pointer' }} onClick={onEditReceivingNote}>
                    Update Note
                </Link>
            </div>} hasMargin={false}>
                <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                    {data.receivingNote ? data.receivingNote : <span style={{ fontSize: 12, opacity: 0.65 }}>No note</span>}
                </p>
            </FormGroup>
        </>}


        <div style={{ height: '3rem' }} />

        <Tabs>
            <Tab label="Timeline">
                <div style={{ width: 500 }}>
                    <HistoryEntry status={"Revoked"} userId={data.revokedBy} user={data.revokedByName} date={data.revokedOn} note={data.revokeNote} />

                    <HistoryEntry status={"Received"} userId={data.receivedBy} user={data.receivedByName} date={data.receivedOn} note={data.receivingNote} />

                    <HistoryEntry status={"Sent"} userId={data.sentBy} user={data.sentByName} date={data.sentOn} note={data.respondingNote} />

                    <HistoryEntry status={"Approved"} userId={data.approvedBy} user={data.approvedByName} date={data.approvedOn} hasNote={false} />

                    <HistoryEntry status={"Rejected"} userId={data.rejectedBy} user={data.rejectedByName} date={data.rejectedOn} note={data.respondingNote} />

                    <HistoryEntry status={"Cancelled"} userId={data.cancelledBy} user={data.cancelledByName} date={data.cancelledOn} note={data.cancelNote} />

                    <HistoryEntry status={"Requested"} userId={data.requestedBy} user={data.requestedByName} date={data.requestedOn} note={data.requestingNote} />
                </div>
            </Tab>
            <Tab label="Notes/Docs">
                <div style={{ overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                    <AdvancedNotesView title="" objectId={data.id} objectType={OBJECT_INV_REQUEST} />
                </div>

                <div style={{ height: '3rem' }} />
            </Tab>
            {data.responding && <Tab label="Logs">
                <MinimalTable columns={LOG_COLUMNS} items={data.domainLogs} />
            </Tab>}
        </Tabs>


        {/* <FormSection title="Requesting Note">
            <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                {data.requestingNote ? data.requestingNote : <span style={{ fontSize: 12, opacity: 0.65 }}>No note</span>}
            </p>
        </FormSection>
        <FormGroup legendText={<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <p style={{ fontWeight: 'bold', color: 'black', }}>
                Responding Note
            </p>
            {hasRespondingNote && <Link style={{ cursor: 'pointer' }}>Update Note</Link>}
        </div>} hasMargin={false}>
            <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                {data.respondingNote ? data.respondingNote : <span style={{ fontSize: 12, opacity: 0.65 }}>No note</span>}
            </p>
        </FormGroup>
        <div style={{ height: '1rem' }} />


        <FormGroup legendText={<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <p style={{ fontWeight: 'bold', color: 'black', }}>
                Receiving Note
            </p>
            {hasReceivingNote && <Link style={{ cursor: 'pointer' }}>Update Note</Link>}
        </div>} hasMargin={false}>
            <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                {data.receivingNote ? data.receivingNote : <span style={{ fontSize: 12, opacity: 0.65 }}>No note</span>}
            </p>
        </FormGroup>
        <div style={{ height: '3rem' }} /> */}


    </div>
}