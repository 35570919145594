import React, { useState } from 'react'
import { Pie } from 'react-chartjs-2'

import {
    DataConnected20, Diagram20, List20, ArrowLeft16, ArrowRight16, DotMark16
} from '@carbon/icons-react'
import StockFlowListView from '../../stock-flow/StockFlowListView'
import { Tab, Tabs } from 'carbon-components-react'
import PaginatedDataTable from '../../../components/data-table/PaginatedDataTable'
import { useHistory } from 'react-router-dom'
import Api from '../../../session/Api'
import Util from '../../../util/Util'

// export const getRandomMarkerColor = id => colors[id] ?? (colors[id] = "#" + Math.floor(Math.random()*16777215).toString(16))

const ValueField = ({ title, value, color }) => (
    <div style={{ color: color, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <p style={{ opacity: 0.75 }}>{title}</p>
        <h3>{value}</h3>
    </div>
)

const RangeCard = ({ range, selected, onClick }) => {
    return (
        <div onClick={onClick} className="dashboard-card" style={{ flex: 1, borderRadius: 5, padding: '1rem', display: 'flex', flexDirection: 'column', 
        boxShadow: selected ? '0px 0px 16px 0px rgba(15,99,254,0.75)' : undefined, transition: '250ms' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <DataConnected20 />
                <h4>Time Range</h4>
            </div>
            <h3>{range.start} - {range.end}</h3>
            <div style={{ width: '100%', flex: 1, display: 'grid', marginTop: '3rem', marginBottom: '3rem', alignItems: 'center', gap: '1rem', gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))' }}>
                <ValueField title="Product Count" value={range.productCount} />
                <ValueField title="Sales Count" value={range.salesCount} />
                <ValueField title="Total Revenue" value={range.totalRevenue} color="#0f62fe" />
                <ValueField title="Total COGS" value={range.totalCOGS} color="red" />
                <ValueField title="Total Profit" value={range.totalProfit} color="green" />
                <ValueField title="Gross Profit Margin" value={range.grossProfitMargin + '%'} color="#AF69EF" />
            </div>
        </div>
    )
}

const ChartsCard = ({ ranges }) => {
    return (
        <div className="dashboard-card" style={{ flex: 1, borderRadius: 5, padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <Diagram20 />
                <h4>Charts</h4>
            </div>

            <h3>Revenue</h3>
            <Pie data={{
                labels: ranges.map(range => `Range (${range.start} - ${range.end})`),
                datasets: [
                    {
                        //label: '# of Sales',
                        data: ranges.map(range => range.totalRevenue),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }} />

            <h3 style={{ marginTop: '1rem' }}>Profit</h3>
            <Pie data={{
                labels: ranges.map(range => `Range (${range.start} - ${range.end})`),
                datasets: [
                    {
                        //label: '# of Sales',
                        data: ranges.map(range => range.totalProfit),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }} />
        </div>
    )
}


const SalesList = ({ range }) => {
    return (
        <div style={{ width: '100%',}}>
            <div style={{ width: '100%' }}>
                <PaginatedDataTable
                hideToolbar
                reloadRequest={range.start + ':sales-list:' + range.end}
                loader={(page, sortRequest, listener) => Api.getTimePerformanceReportSales(
                    [{ start: range.start, end: range.end }], page, listener
                )}
                loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}
                columns={[
                    {
                        id: 'id',
                        name: "Voucher No",
                        render: item => Util.getVoucherNumber(item.id)
                    },
                    {
                        id: 'customer',
                        name: "Customer",
                        render: item => item.destinationName
                    },
                    {
                        id: 'date',
                        name: "Date",
                        render: item => Util.getDate(item.initiationDate)
                    }
                ]}
                onClick={row => window.open('/stock-flow/' + row.id, "_blank")} 
                />
            </div>
        </div>   
    )
}

const ProductsList = ({ range }) => {
    return (
        <div style={{ width: '100%',}}>
            <div style={{ width: '100%' }}>
                <PaginatedDataTable
                hideToolbar
                reloadRequest={range.start + ':sales-list:' + range.end}
                loader={(page, sortRequest, listener) => Api.getTimePerformanceReportProducts(
                    [{ start: range.start, end: range.end }], page, listener
                )}
                loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}
                columns={[
                    {
                        id: 'id',
                        name: "Ref No",
                        render: item => item.sku ?? '-'
                    },
                    {
                        id: 'description',
                        name: "Description",
                        render: item => item.name ?? '-'
                    },
                    {
                        id: 'brand',
                        name: "Brand",
                        render: item => item.brandName ?? '-'
                    }
                ]}
                onClick={row => window.open('/products/' + row.id, "_blank")} 
                />
            </div>
        </div>   
    )
}



const ListView = ({ range, selectedIndex, setSelectedIndex, rangeCount }) => {
    return (
        <div className="stripped-down-tab-content dashboard-card" style={{ flex: 1, borderRadius: 5, marginTop: '0.5rem', padding: '1rem', paddingBottom: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <List20 />
                <h4 style={{flex: 1}}>Selected Range: {range.start} - {range.end}</h4>

                <ArrowLeft16 style={{cursor: selectedIndex > 0 ? 'pointer' : undefined, opacity: selectedIndex > 0 ? 1 : 0.65}} 
                onClick={selectedIndex > 0 ? (() => setSelectedIndex(i => i - 1)) : (() => {})} />
                <DotMark16 />
                <ArrowRight16 style={{cursor: selectedIndex < (rangeCount - 1) ? 'pointer' : undefined, opacity: selectedIndex < (rangeCount - 1) ? 1 : 0.65}} 
                onClick={selectedIndex < (rangeCount - 1) ? (() => setSelectedIndex(i => i + 1)) : (() => {})} />
            </div>
            <p style={{opacity: 0.65, fontSize: 12, marginBottom: '0.5rem'}}>Click on a range card to select it</p>
            <Tabs>
                <Tab id="tab-1" label="Sales">
                    <div style={{height: '0.5rem'}} />
                    <SalesList range={range} />
                </Tab>
                <Tab id="tab-1" label="Products">
                    <div style={{height: '0.5rem'}} />
                    <ProductsList range={range} />
                </Tab>
            </Tabs>
        </div>
    )
}

export default ({ report }) => {
    const [selectedRangeIndex, setSelectedRangeIndex] = useState(0);
    return (
        <div>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-start' }}>
                <div style={{ flex: 2, minWidth: 0 }}>
                    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                        {report.ranges.map((range, i) => <RangeCard range={range} onClick={() => setSelectedRangeIndex(i)} selected={i == selectedRangeIndex} />)}
                    </div>
                    <ListView range={report.ranges[selectedRangeIndex]} selectedIndex={selectedRangeIndex} setSelectedIndex={setSelectedRangeIndex} rangeCount={report.ranges.length} />
                </div>
                <ChartsCard ranges={report.ranges} />
            </div>
        </div>
    )
}