import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Util from '../../../util/Util';

function addNewlineAfter29Characters(inputString) {
    const maxLength = 29;
    let result = '';

    for (let i = 0; i < inputString.length; i += maxLength) {
        const chunk = inputString.slice(i, i + maxLength);
        result += chunk + '\n';
    }

    return result;
}


const borderColor = '#00148C'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#00148C',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        minHeight: 24,
        fontStyle: 'bold',
        fontSize: 8,

        // paddingTop: 6,
        // paddingBottom: 6
    },
    a: {
        width: '15%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 5,

        height: '100%',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    b: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingLeft: 5,

        height: '100%',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    c: {
        //width: '25%',
        width: '25%',
        minWidth: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingLeft: 5,

        height: '100%',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    d: {
        width: '15%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 5,

        height: '100%',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    e: {
        width: '15%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 5,

        height: '100%',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    f: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 5,

        height: '100%',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
});

const acctVal = value => {
    //return value < 0 ? '(' + Math.abs(value).toFixed(2) + ')' : value.toFixed(2);
    return Util.formatMoneyWithNeg(value);
}

const TableRows = ({ items }) => {
    const rows = items.map(item =>
        <View wrap={false} style={styles.row}>
            <Text style={styles.a}>{Util.getDateOnly(item.date)}</Text>
            {/* <Text style={styles.b}>{item.id > 0 ? Util.getVoucherNumber(item.id) : ' '}</Text> */}
            <View style={styles.b}>
                <Text style={{ width: '100%' }}>{item.id > 0 ? item.voucherNo : ' '}</Text>
            </View>
            <Text style={styles.c}>{Util.limitParagraphWidth(item.note, 27)}</Text>
            <Text style={styles.d}>{(item.value.debit && item.value.debit != 0) ? acctVal(item.value.debit) : ' '}</Text>
            <Text style={styles.e}>{(item.value.credit && item.value.credit != 0) ? acctVal(item.value.credit) : ' '}</Text>
            <Text style={styles.f}>{(acctVal(item.closingBalance))}</Text>
        </View>
    )
    return <>{rows}</>
};

export default TableRows