import { useEffect, useMemo, useState } from "react";
import { getFnbEventStatus, getNextStatusById, getPreviousStatusById, getStatusIndexById } from "..";
import { createFlow } from "./flow";
import { AdvancedNotesView } from "../../../../views/advanced-notes/advanced-notes-view";
import { OBJECT_TYPE_FNB_EVENT } from "../../../../constants/ObjectTypes";
import { BoardViewPage } from "../../../board-view/board-view-page";
import Api from "../../../../session/Api";
import { fixIdForApi } from "../../../../templates/form/form-api";
import { useForm } from "../../../../templates/form/form";
import UIUtil from "../../../../util/UIUtil";
import { isEqual } from "lodash";
import { useForceLockScroll } from "../../../../hooks/useLockScroll";
import { withLoadablePageWithParams } from "../../../../base/Page";
import { Toolbar } from "./views/toolbar";
import { StatusBar } from "./views/status-bar";
import { Footer } from "./views/footer";
import { Content } from "./views/content";
import { SideView } from "./side-views";
import Util, { big } from "../../../../util/Util";

function validateFnbEvent(event) {
    console.log({ mp: event.noOfGuests })
    if (!(Number.isInteger(parseFloat(event.noOfGuests)))) {
        return "Number of guests must be a positive whole number";
    }
}

const useGlobalState = () => {
    const [state, setState] = useState({});
    return ({
        get: key => state[key],
        use: (key, defValue) => {
            return [state[key] ?? defValue, (arg) => {
                if (typeof arg === "function") {
                    setState(prevState => ({ ...prevState, [key]: arg(prevState[key] ?? defValue) }))
                } else {
                    setState(prevState => ({ ...prevState, [key]: arg }))
                }
            }]
        }
    })
}

const useFnbEventUpdater = (form, fnbEvent, setFnbEvent, flow) => {
    const [savingChanges, setSavingChanges] = useState(false)
    const [revertingStatus, setRevertingStatus] = useState(false);
    const [nextingStatus, setNextingStatus] = useState(false);

    const canRevert = flow && getStatusIndexById(flow, fnbEvent?.status) > 0;
    const canNext = flow && getStatusIndexById(flow, fnbEvent?.status) < (flow.length - 1)

    const validate = event => {
        const err = validateFnbEvent(event)
        if (err) {
            UIUtil.showError(err);
            return false;
        } else {
            return true;
        }
    }

    return {
        canRevert, canNext,


        onResetToDefaultBtn: () => form.setData(),

        onSaveChangesBtn: () => {
            const newFnbEvent = form.getFormData();
            if (!validate(newFnbEvent)) {
                return;
            }

            if (Util.isStringExists(newFnbEvent.orderStartTimeStr)) {
                newFnbEvent.orderStartTime = Util.timeToMilliseconds(newFnbEvent.orderStartTimeStr)
            } else {
                newFnbEvent.orderStartTime = 0;
            }

            setSavingChanges(true)
            Api.updateFnbEvent(fixIdForApi(newFnbEvent), response => {
                setSavingChanges(false);

                if (response.status === true) {
                    setFnbEvent(response.payload);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        },

        onRevertStatusBtn: () => {
            const fnbEvent = form.getFormData();
            const newFnbEvent = {
                ...fnbEvent,
                status: getPreviousStatusById(flow, fnbEvent.status).id,
                duringPortInspection: false
            };

            setRevertingStatus(true);
            Api.updateFnbEvent(fixIdForApi(newFnbEvent), response => {
                setRevertingStatus(false);

                if (response.status === true) {
                    setFnbEvent(response.payload);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        },

        onNextStatusBtn: () => {
            const fnbEvent = form.getFormData();
            const newFnbEvent = {
                ...fnbEvent,
                status: getNextStatusById(flow, fnbEvent.status).id,
                duringPortInspection: false
            };

            setNextingStatus(true);
            Api.updateFnbEvent(fixIdForApi(newFnbEvent), response => {
                setNextingStatus(false);

                if (response.status === true) {
                    setFnbEvent(response.payload);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        },



        savingChanges, revertingStatus, nextingStatus
    }
}


function View({ payload: { item, endpoint } }) {
    const [fnbEvent, setFnbEvent] = useState(item)
    const flow = useMemo(() => createFlow(endpoint), endpoint)


    const [sideView, setSideView] = useState(undefined)
    const [tab, setTab] = useState(0)
    const [selectedStatus, setSelectedStatus] = useState(() => getFnbEventStatus(flow, fnbEvent));



    const [changesMade, setChangesMade] = useState(false);
    const form = useForm(store => store.setObject(fnbEvent), {
        // replaceComboBoxWithDataList: true,
        skipFieldEffect: true
    });
    useEffect(() => form.store.subscribeGlobal(() => {
        setChangesMade(!isEqual(fnbEvent, form.getFormData()))
    }), [fnbEvent])


    const fnbEventUpdater = useFnbEventUpdater(form, fnbEvent, setFnbEvent, flow)
    useEffect(() => {
        setSelectedStatus(getFnbEventStatus(flow, fnbEvent))
        form.store.clear()
        form.store.setObject(fnbEvent)
    }, [flow, fnbEvent])

    useForceLockScroll(true)

    const globalState = useGlobalState();


    let view = null;
    switch (tab) {
        case 0: //Overview
            view = (<>
                <StatusBar fnbEvent={fnbEvent} flow={flow} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
                <div style={{ height: 'calc(100% - 2rem - 3rem)', display: 'flex' }}>
                    <div style={{ height: '100%', flex: 1, minWidth: 0 }}>
                        <div style={{ height: 'calc(100% - 3rem)', background: '#f4f4f4', overflow: 'auto' }}>
                            <Content fnbEvent={fnbEvent} form={form} flow={flow} selectedStatus={selectedStatus} />
                        </div>
                        <div style={{ height: '3rem', width: '100%', paddingInline: '1rem', gap: '0.25rem', background: 'white', borderTop: '1px solid #00000020', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Footer {...{ changesMade, ...fnbEventUpdater }} />
                        </div>
                    </div>

                    <SideView globalState={globalState} changesMade={changesMade} endpoint={endpoint} fnbEvent={fnbEvent} form={form} sideView={sideView} setSideView={setSideView} />
                </div>
            </>)
            break;

        case 1:
            view = (
                <div style={{ width: '100%', height: 'calc(100% - 3rem)' }}>
                    <BoardViewPage apiver2 bvParentType={OBJECT_TYPE_FNB_EVENT} bvParentId={fnbEvent.id} />
                </div>
            )
            break

        case 2:
            view = (
                <div style={{ width: '100%', height: 'calc(100% - 3rem)' }}>
                    <AdvancedNotesView title="Notes/Attachments" objectId={fnbEvent.id} objectType={OBJECT_TYPE_FNB_EVENT} />
                </div>
            )
            break;
    }


    return (
        <div style={{ height: '100%' }}>
            <Toolbar fnbEvent={fnbEvent} tabIndex={tab} setTabIndex={setTab} sideView={sideView} setSideView={setSideView} />
            {view}
        </div>
    )
}


export const FnbEventItemPage = withLoadablePageWithParams(params => listener => Api.getFnbEventAndLock(params.eventId, listener), View)