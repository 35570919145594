import { Tag } from "carbon-components-react";


export function ShipmentPortStatusTag({ status, duringPortInspection }) {
    if (status === "Created" && duringPortInspection === false) {
        return <Tag type='green' style={{ marginTop: '0.5rem' }}>Awaiting Inspection</Tag>
    } else if (status === "Created" && duringPortInspection === true) {
        return <Tag type='magenta' style={{ marginTop: '0.5rem' }}>Inspecting</Tag>
    } else if (status === "Loading") {
        return <Tag type='blue' style={{ marginTop: '0.5rem' }}>Loading</Tag>
    } else if (status === "Unloading") {
        return <Tag type='cyan' style={{ marginTop: '0.5rem' }}>Unloading</Tag>
    } else {
        return null;
    }
}