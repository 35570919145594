import { useState } from "react";


export function useNavigator() {
    const [page, setPage] = useState(0);
    return {
        page,
        openFolder: () => setPage(1),
        back: () => setPage(0),
    }
}