import Theme from "../Theme";

class GreenTheme extends Theme {

    getName() {
        return "green";
    }

    getColor() {
        return "#4A5240";
    }

    getClassName() {
        return "theme-green"
    }

}

export default GreenTheme;