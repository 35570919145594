
import {
    Milestone32, Add16
} from '@carbon/icons-react'
import { InlineLoading } from 'carbon-components-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageView from '../../../../components/ImageView'
import { OBJECT_TYPE_REAL_ESTATE_PROPERTY } from '../../../../constants/ObjectTypes'
import Api from '../../../../session/Api'
import UIUtil from '../../../../util/UIUtil'
import Util from '../../../../util/Util'
import { ProspectPropertyDialog } from '../dialogs/prospect-property-dialog'

function useLoadItems(leadId) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(() => {
        let cancelled = false;
        setLoading(true);
        Api.getProspectProperties(leadId, response => {
            if (cancelled) {
                return;
            }

            setLoading(false)
            if (response.status === true) {
                setItems(response.payload)
            } else {
                UIUtil.showError('Failed loading prospect properties ' + (response.message ?? ''));
            }
        })
        return () => cancelled = true;
    }, [leadId])
    return [loading, items, setItems];
}


const List = ({ children }) => (
    // <div style={{ overflow: 'auto', height: 300, }}>
    <div style={{ overflow: 'auto', height: 300, }}>
        <div className="bx--structured-list-th" style={{
            display: 'flex', paddingLeft: '0rem', borderBottom: '1px solid #e0e0e0', background: 'black', color: 'white',
            position: 'sticky', top: 0, zIndex: 1000,
            height: 'unset', gap: '0.5rem',
            padding: '0.5rem .25rem 1rem', paddingLeft: '0rem',
            paddingLeft: 0, paddingBottom: '0.5rem', paddingTop: 0,
            paddingInline: '1rem',
        }}>
            <div style={{ flex: 2 }}>
                Name
            </div>
            <div style={{ flex: 3 }}>
                Note
            </div>
            <div style={{ flex: 1 }}>
                Date Added
            </div>
        </div>

        {children}
    </div>
)

const Item = ({ item, onClick }) => (
    <div
        onClick={onClick}
        className='lead-flow-table-item'
        style={{
            display: 'flex',
            fontSize: '.875rem', borderBottom: '1px solid #e0e0e0', padding: '0.5rem .25rem 1rem', paddingLeft: '0rem',
            gap: '0.5rem', alignItems: 'flex-start',
            paddingInline: '1rem'
        }}
    >
        <div style={{ flex: 2 }}>
            <div style={{ display: 'flex', }}>
                <ImageView style={{ width: 50, minWidth: 50, height: 50, borderRadius: 5 }} src={Api.getThumbnail(item.propertyId, OBJECT_TYPE_REAL_ESTATE_PROPERTY)} />
                <div style={{ marginLeft: '0.25rem' }}>
                    <Link target="_blank" to={'/real-estate-property/' + item.propertyId}>
                        <p style={{ fontSize: 14 }}>{item.propertyName}</p>
                    </Link>
                    <p style={{ fontSize: 12, opacity: 1 }}>{item.projectName}</p>
                </div>
            </div>

        </div>
        <div style={{ flex: 3 }}>
            <p style={{ fontSize: 12, whiteSpace: 'pre-line' }}>{item.note}</p>
        </div>
        <div style={{ flex: 1 }}>
            <p style={{ fontSize: 12 }}>{Util.getDate(item.dateAdded)}</p>
        </div>
    </div>
)

const AddBtn = ({ leadId, addItem, data }) => {
    const [visible, setVisible] = useState(false);
    return (<>
        <button onClick={() => setVisible(true)} className='lead-flow-icon-button'>
            <Add16 />
        </button>
        <ProspectPropertyDialog creating item={{ leadId, propertyId: 0, note: "" }} setItem={addItem} payload={data} open={visible} onClose={() => setVisible(false)} />
    </>)
}


export function ProspectProperties({ leadId, data }) {
    const [loading, items, setItems] = useLoadItems(leadId)
    const [selectedItem, setSelectedItem] = useState(undefined)

    const addItem = item => setItems(items => [item, ...items])
    const updateItem = update => setItems(items => items.map(item => item.id === update.id ? update : item))

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', padding: '1rem' }}>
                <Milestone32 style={{ width: 32, height: 32 }} />
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>Prospect Properties</h4>
                <AddBtn leadId={leadId} data={data} addItem={addItem} />
            </div>


            <List>
                {!loading ? (
                    items.length > 0 ? (
                        items.map(item => <Item key={item.id} item={item} onClick={() => setSelectedItem(item)} />)
                    ) : (
                        <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>No item exists</p>
                        </div>
                    )
                ) : (
                    <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                        <InlineLoading style={{ width: 'unset' }} />
                    </div>
                )}
            </List>

            <ProspectPropertyDialog item={selectedItem ?? { leadId }} setItem={updateItem} payload={data} open={selectedItem !== undefined} onClose={() => setSelectedItem(undefined)} />
        </div>
    )
}