import { TextInput, ComboBox } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import { ATTRIBUTE_TYPE_NUMBER, ATTRIBUTE_TYPE_TEXT } from '../constants/Constants';
import Button from './Button';

import { RowDelete32, Draggable16 } from '@carbon/icons-react'
import Util from '../util/Util';
import MultipleInputEditor from './MultipleInputEditor';

const getAttributeTypeText = type => {
    switch (type) {
        case "A" + ATTRIBUTE_TYPE_TEXT:
            return "Text";
        case "A" + ATTRIBUTE_TYPE_NUMBER:
            return "Number";
    }
}

const AttributeRecord = ({input, nonExistent, onInputUpdate, onInputRemoved}) => {
    const [title, setTitle] = useState(input.title);
    const [type, setType] = useState(input.type);

    return (
        <div style={{display: 'flex', width: '100%'}}>
            <TextInput 
            labelText="Label" value={title} onChange={e => {
                onInputUpdate('title', e.target.value);
                setTitle(e.target.value);
            }} />
            <div style={{width: 5}} />

            <ComboBox 
            titleText="Type"
            style={{width: 262}}
            items={["A" + ATTRIBUTE_TYPE_TEXT, "A" + ATTRIBUTE_TYPE_NUMBER]}
            itemToString={getAttributeTypeText}
            value={getAttributeTypeText("A" + type)}
            selectedItem={"A" + type}
            onChange={item => {
                switch (item.selectedItem) {
                    case "A" + ATTRIBUTE_TYPE_TEXT:
                        onInputUpdate('type', ATTRIBUTE_TYPE_TEXT)
                        setType(ATTRIBUTE_TYPE_TEXT)
                        break;
                    case "A" + ATTRIBUTE_TYPE_NUMBER:
                        onInputUpdate('type', ATTRIBUTE_TYPE_NUMBER)
                        setType(ATTRIBUTE_TYPE_NUMBER)
                        break;
                }
            }}
            />

            <div style={{width: 5}} />

            <Button kind="danger" hasIconOnly renderIcon={RowDelete32} size="md" 
            iconDescription="Remove"
            style={{height: 40, alignSelf: 'flex-end'}} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export default function AttributeEditor(props) {
    return <MultipleInputEditor defaultInputs={props.defaultAttributes} onInputsUpdated={props.onAttributesUpdated} 
    ItemComponent={AttributeRecord} />
}