import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import Util, { big } from '../../../../../../util/Util';
import Logo from '../../../../../../images/doc-logo.png'
import { PDFTable } from '../../../../../../pdfs/invoice-document/pdf/Invoice';
import Api from '../../../../../../session/Api';
import { stringifyBlueprintInputFields } from '../../../../../activities/components/blueprint-input-fields-read';
import { getBLNo } from '../../../../../../domain/shipment';
import picardlogo from '../../../../../../images/other/picard/picardlogo.png';
import { isPicard } from '../../../../../../app/app-util';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 40,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

// no: index + 1,
// bl: blNo,
// consignor: shipment.consignorIdName,
// consignee: shipment.consigneeIdName,
// description: descOfGoods,
// weight: Util.formatAmount(totalWeight),
// qty: totalQty + ' unit' + (totalQty != 1 ? 's' : ''),
function TableContent({ rows }) {
    return rows.map(item => (
        <View style={{ width: '100%', flexDirection: 'row', borderBottom: '0.5px solid black' }}>
            <View style={{ flex: 3, flexDirection: 'row', borderRight: '0.5px solid black', }}>
                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 0.25 }}>
                    <Text style={{ lineHeight: 0, textAlign: 'center' }}>{item.no}</Text>
                </View>
                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 0.75 }}>
                    <Text style={{ lineHeight: 0, textAlign: 'center' }}>{item.bl}</Text>
                </View>
                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <Text style={{ lineHeight: 0, textAlign: 'center' }}>{item.consignor ?? ' '}</Text>
                </View>
            </View >
            <View style={{ flex: 6, flexDirection: 'row' }}>
                <View style={{ flex: 4, flexDirection: 'row', borderRight: '0.5px solid black', paddingHorizontal: 5 }}>
                    <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 2 }}>
                        <Text style={{ lineHeight: 0, textAlign: 'center' }}>{item.consignee ?? ' '}</Text>
                    </View>
                    <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'flex-start', flex: 3 }}>
                        <Text style={{ lineHeight: 0, textAlign: 'left', padding: 5 }}>{item.description}</Text>
                    </View>
                </View>
                <View style={{ flex: 2, flexDirection: 'row' }}>
                    <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 1 }}>
                        <Text style={{ lineHeight: 0, textAlign: 'center' }}>{item.weight}</Text>
                    </View>
                    <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                        <Text style={{ lineHeight: 0, textAlign: 'center' }}>{item.qty}</Text>
                    </View>
                </View>
            </View>
        </View>

    ))
}


function PDF({ payload: master }) {
    const vessel = (() => {
        return master.transportationLegs.map(leg => leg.vesselName).join(" -> ");
    })();
    const voyageNo = (() => {
        return master.transportationLegs.map(leg => leg.voyageNo).join(" -> ");
    })();
    const pol = (() => {
        const firstLeg = master.transportationLegs[0];
        return firstLeg?.departurePortName ?? "-"
    })();
    const pod = (() => {
        const lastLeg = master.transportationLegs[master.transportationLegs.length - 1];
        return lastLeg?.arrivalPortName ?? "-"
    })();
    const arrivalDate = (() => {
        const lastLeg = master.transportationLegs[master.transportationLegs.length - 1];
        return Util.formatDate(Util.isNumberExist(lastLeg?.actualEndDate) ? lastLeg?.actualEndDate : lastLeg?.scheduledEndDate, "DD.MM.YYYY")
    })();
    return (
        <Document>
            <Page size="A4" orientation='landscape' style={styles.page}>
                <View style={{ border: '1px solid black', flex: 1 }}>
                    <View fixed style={{ flexDirection: 'row', borderBottom: '1px solid black', width: '100%' }}>
                        <View style={{ flex: 3, borderRight: '0.5px solid black', }}>
                            <View style={{ padding: 10 }}>
                                {Util.isStringExists(vessel) && <Text style={{ fontFamily: 'Helvetica-Bold', marginBottom: 10, fontSize: 14 }}>{vessel}</Text>}
                                <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}>VOYAGE NO.: {voyageNo}</Text>
                            </View>
                        </View>
                        <View style={{ flex: 6 }}>
                            <View style={{ flexDirection: 'row', borderBottom: '0.5px solid black', }}>
                                <View style={{ flex: 4, borderRight: '0.5px solid black', flexDirection: 'row', justifyContent: isPicard() ? 'center' : 'space-around', padding: 5, }}>
                                    {isPicard() ? <>
                                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <Image src={picardlogo} style={{ width: 100, objectFit: 'contain', objectPosition: 'center', marginVertical: 10 }} />
                                            <Text style={{ fontSize: 8, textAlign: 'center' }}>
                                                FZS5 BB02, 1209 Street, Jebel Ali Free Zone [Jafza], Dubai, UAE, P.O. Box: 17728
                                            </Text>
                                        </View>
                                    </> : <>
                                        <View style={{ width: 5 }} />
                                        <Image src={Logo} style={{ width: 50, height: 50 }} />
                                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 16, textAlign: 'center' }}>EXCELLS SHIPPING LLC</Text>
                                            <Text style={{ fontSize: 10, textAlign: 'center' }}>NO. 343, CUSTOMS BUILDING, P.O. BOX 36067, DUBAI, UAE.</Text>
                                        </View>
                                    </>}
                                </View>
                                <View style={{ flex: 2, alignItems: 'center', justifyContent: 'center', }}>
                                    <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12 }}>MANIFEST NO: {master.id}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', }}>
                                <View style={{ flex: 4, alignItems: 'center', justifyContent: 'center', paddingVertical: 12, borderRight: '0.5px solid black', paddingHorizontal: 5 }}>
                                    <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 22, lineHeight: 0, margin: 0 }}>CARGO MANIFEST</Text>
                                </View>
                                <View style={{ flex: 2, }}>
                                    <View style={{ borderBottom: '0.5px solid black', padding: 5 }}>
                                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10, lineHeight: 0 }}>P.O.L : {pol}</Text>
                                    </View>
                                    <View style={{ borderBottom: '0.5px solid black', padding: 5 }}>
                                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10, lineHeight: 0 }}>P.O.D : {pod}</Text>
                                    </View>
                                    <View style={{ padding: 5 }}>
                                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 10, lineHeight: 0 }}>E.T.D : {arrivalDate}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View fixed style={{ width: '100%', flexDirection: 'row', borderBottom: '1px solid black', backgroundColor: '#D9D9D9' }}>
                        <View style={{ flex: 3, flexDirection: 'row', borderRight: '0.5px solid black' }}>
                            <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 0.25 }}>
                                <Text style={{ lineHeight: 0, fontFamily: 'Helvetica-Bold' }}>SN</Text>
                            </View>
                            <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 0.75 }}>
                                <Text style={{ lineHeight: 0, fontFamily: 'Helvetica-Bold' }}>BL</Text>
                            </View>
                            <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <Text style={{ lineHeight: 0, fontFamily: 'Helvetica-Bold' }}>SHIPPER</Text>
                            </View>
                        </View >
                        <View style={{ flex: 6, flexDirection: 'row' }}>
                            <View style={{ flex: 4, flexDirection: 'row', borderRight: '0.5px solid black', paddingHorizontal: 5 }}>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 2 }}>
                                    <Text style={{ lineHeight: 0, fontFamily: 'Helvetica-Bold' }}>CONSIGNEE</Text>
                                </View>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 3 }}>
                                    <Text style={{ lineHeight: 0, fontFamily: 'Helvetica-Bold' }}>DESCRIPTION OF GOODS</Text>
                                </View>
                            </View>
                            <View style={{ flex: 2, flexDirection: 'row' }}>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 1 }}>
                                    <Text style={{ lineHeight: 0, fontFamily: 'Helvetica-Bold' }}>WEIGHT (KGS)</Text>
                                </View>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                    <Text style={{ lineHeight: 0, fontFamily: 'Helvetica-Bold' }}>QTY</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View fixed style={{ width: '100%', flexDirection: 'row', borderBottom: '1px solid black' }}>
                        <View style={{ flex: 3, flexDirection: 'row', borderRight: '0.5px solid black' }}>
                            <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 0.25 }}>
                                <View />
                            </View>
                            <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 0.75 }}>
                                <View />
                            </View>
                            <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <View />
                            </View>
                        </View >
                        <View style={{ flex: 6, flexDirection: 'row' }}>
                            <View style={{ flex: 4, flexDirection: 'row', borderRight: '0.5px solid black', paddingHorizontal: 5 }}>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 2 }}>
                                    <View />
                                </View>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 3 }}>
                                    <View />
                                </View>
                            </View>
                            <View style={{ flex: 2, flexDirection: 'row' }}>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', borderRight: '0.5px solid black', flex: 1 }}>
                                    <View />
                                </View>
                                <View style={{ paddingVertical: 4, justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                    <View />
                                </View>
                            </View>
                        </View>
                    </View>

                    <TableContent rows={master.shipments.flatMap((shipment, shipmentIndex) => {
                        const allItems = shipment.packages.flatMap($ => $.items).map($ => ({
                            ...$, value: $.remark
                        }))


                        return allItems.map((item, itemIndex) => {
                            const items = [item]

                            const blNo = getBLNo({ ...shipment, _blNoSuffix: allItems.length > 1 ? itemIndex + 1 : undefined })

                            const totalWeight = items
                                .map($ => $.weight)
                                .filter(val => Util.isNumberExist(val))
                                .map(val => big(val))
                                .reduce((t, c) => t.add(c), big(0))
                                .round(2).toNumber()

                            const totalQty = items
                                .map($ => $.quantity)
                                .filter(val => Util.isNumberExist(val))
                                .map(val => big(val))
                                .reduce((t, c) => t.add(c), big(0))
                                .round(2).toNumber()

                            const descOfGoods = items
                                .map(item => item.remark)
                                .join("\n\n")

                            const showDisplayQty = Util.isStringExists(item.displayQuantity)

                            return {
                                no: master.shipments.slice(0, shipmentIndex).flatMap(shipment => shipment.packages.flatMap(pkg => pkg.items)).length + itemIndex + 1,
                                bl: blNo,
                                // consignor: shipment.consignorIdName,
                                // consignee: shipment.consigneeIdName,
                                consignor: shipment.customer?.fullName,
                                consignee: shipment.consignee?.name,
                                description: descOfGoods,
                                weight: Util.formatAmount(totalWeight),
                                qty: showDisplayQty ? item.displayQuantity : (totalQty + ' UNIT' + (totalQty != 1 ? 'S' : '')),
                            }
                        })
                    })} />

                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}
export function createBriefManifest(shipment) {
    return {
        PDF,
        canGenerate: Util.isNumberExist(shipment.shipmentMasterId),
        getPayload: (shipment, listener) => {
            Api.getShipmentMaster(shipment.shipmentMasterId, response => {
                if (response.status === true) {
                    listener(true, response.payload.item)
                } else {
                    listener(false, response.message);
                }
            })
        }
    }
}


/*

```js
const blNo = (() => {

return getBLNo(shipment)

})();

  
  

const totalWeight = shipment.packages

.flatMap($ => $.items)

.map($ => $.weight)

.filter(val => Util.isNumberExist(val))

.map(val => big(val))

.reduce((t, c) => t.add(c), big(0))

.round(2).toNumber()

  

const totalQty = shipment.packages

.flatMap($ => $.items)

.map($ => $.quantity)

.filter(val => Util.isNumberExist(val))

.map(val => big(val))

.reduce((t, c) => t.add(c), big(0))

.round(2).toNumber()

  

const descOfGoods = shipment.packages

.flatMap($ => $.items)

//.map(item => stringifyBlueprintInputFields(item.formFieldValues))

.map(item => item.remark)

.join("\n\n")

  

//const showDisplayQty = item.items.l
```
*/