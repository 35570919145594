import { ComboBox, TextInput } from "carbon-components-react";
import { ListInput, makeListInput } from "../../../components/list-input";
import { UploadField } from "../../../components/upload/upload-field";
import { ComboBoxField } from "../../../components/ComboBoxField";
import { OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from "../../../constants/ObjectTypes";
import { FormSection } from "../../../templates/form/form";
import { Close16 } from '@carbon/icons-react'
import { StatelessDateTimePicker } from "../../../components/DateTimePicker";

const TransportationLegs = makeListInput(({ item, setItemValue, updateItem, onRemove, SortHandler, ports }) => (
    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <SortHandler />
            <div style={{ flex: 1 }} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <ComboBoxField title="Port of Departure" options={Array.isArray(ports) ? ports : []} value={item.departurePort} setValue={value => setItemValue('departurePort', value)} />
            </div>
            <div style={{ flex: 1 }}>
                <ComboBoxField title="Port of Arrival" options={Array.isArray(ports) ? ports : []} value={item.arrivalPort} setValue={value => setItemValue('arrivalPort', value)} />
            </div>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Vessel Name" value={item.vesselName} onChange={e => setItemValue('vesselName', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Voyage No" value={item.voyageNo} onChange={e => setItemValue('voyageNo', e.target.value)} />
            </div>
            {/* <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Yard" value={item.yardName} onChange={e => setItemValue('yardName', e.target.value)} />
            </div> */}
        </div>
        <StatelessDateTimePicker style={{ background: '#f4f4f4' }} labelText="Scheduled Start Date" date={item.scheduledStartDate} setDate={value => setItemValue("scheduledStartDate", value)} />
        <div style={{ marginTop: '1rem' }} />
        <StatelessDateTimePicker style={{ background: '#f4f4f4' }} labelText="Scheduled End Date" date={item.scheduledEndDate} setDate={value => setItemValue("scheduledEndDate", value)} />
        {/* <UploadField value={item.document} setValue={value => setItemValue("document", value)} label="Document" /> */}
    </div>
), () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
))
const Bills = makeListInput(({ item, setItemValue, updateItem, onRemove, SortHandler, vendors }) => (
    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <SortHandler />
            <div style={{ flex: 1 }} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>

        <ComboBoxField title="Vendor" options={Array.isArray(vendors) ? vendors : []} value={item.otherPartyId} setValue={value => {
            updateItem({
                otherPartyId: value,
                otherPartyType: OBJECT_TYPE_VENDOR,
            })
        }} />
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Bill No" value={item.billNo} onChange={e => setItemValue('billNo', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Amount" value={item.amount} onChange={e => setItemValue('amount', e.target.value)} />
            </div>
        </div>
        <UploadField value={item.document} setValue={value => setItemValue("document", value)} label="Document" />
    </div>
), () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
))


export const ShippingMasterFormFields = ({
    TextField, ComboBoxField, CustomField, SwitchField
}, endpoint) => (<>
    <ComboBoxField fieldKey="originPort" title="Origin Port" options={endpoint.ports} />
    <ComboBoxField fieldKey="destinationPort" title="Destination Port" options={endpoint.ports} />

    <FormSection title="Transportation Legs">
        <CustomField fieldKey="transportationLegs" childrenProps={{ itemProps: { ports: endpoint.ports } }}>
            {TransportationLegs}
        </CustomField>
    </FormSection>

    {/* <FormSection title="Bills">
        <CustomField fieldKey="bills" childrenProps={{ itemProps: { vendors: endpoint.vendors } }}>
            {Bills}
        </CustomField>
    </FormSection> */}
</>)