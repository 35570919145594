import React, { useState } from 'react'

import { Camera16 } from '@carbon/icons-react'
import UploadWrapper from './UploadWrapper';
import ImageView from './ImageView';

export default ({style, src, profilePic, customTitle, onChange, skipChange, objectFit}) => {
    const [hover, setHover] = useState(false)
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [selectedImageURL, setSelectedImageURL] = useState(undefined);
    
    return (
        <UploadWrapper accept="image/gif,image/jpeg,image/png" onChange={e => {
            const file = e.target.files[0];

            setHover(false);
            if (!skipChange) {
                setSelectedImage(file)
                setSelectedImageURL(URL.createObjectURL(file))
            }
            
            onChange(file)
        }} onClick={e => {
            if (selectedImage !== undefined) {
                e.preventDefault();
                URL.revokeObjectURL(selectedImage);

                setHover(false);
                setSelectedImage(undefined);
                setSelectedImageURL(undefined);

                onChange(undefined)
            }
        }}>
            <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            style={{...style, position: 'relative', overflow: 'hidden', cursor: 'pointer'}}>
                <ImageView profilePlaceholder={profilePic} style={{width: '100%', height: '100%', objectFit: objectFit ? objectFit : 'cover', objectPosition: 'center'}} 
                imageViewStyle={{objectFit: objectFit ? objectFit : 'cover'}}
                src={selectedImageURL !== undefined ? selectedImageURL : src} 
                />
        
                <div style={{position: 'absolute', transition: '250ms', width: '100%', height: hover ? '100%' : 30, left: 0, bottom: 0, background: '#000000DD', 
                color: 'white', display: 'flex', alignItems: 'center', padding: '1rem'}}>
                    <p style={{transition: '250ms', fontSize: hover ? 16 : 12,}}>{hover ? (selectedImage == undefined ? 'Select Image' : 'Clear Image') : (customTitle ? customTitle : (profilePic ? 'Profile Pic' : 'Thumbnail'))}</p>
                    {/* <div>
                        {selectedImage === undefined && hover && 
                        <p style={{fontSize: 12}}>jpeg/png</p>}
                    </div> */}
                    <div style={{flex: 1}} />
                    <Camera16 style={{transition: '250ms', width: hover ? 32 : 16, height: hover ? 32 : 16}} />
                </div>
            </div>
        </UploadWrapper>
    )
}