import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { EndDateOnly } from "../../components/basic-filter/date-range";
import { Divider } from "../../components/basic-filter/divider";
import { LocationFilter } from "./location-filter";

export const StockByLocation = {
    filter: Filter,
    isTreeSructure: true,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <EndDateOnly state={state} property="date" />
        <Divider />
        <div style={{ minHeight: 30, flex: 1 }}>
            <LocationFilter state={state} options={endpoint.locations} />
        </div>
    </>)
}
