import { TextInput } from 'carbon-components-react';
import React from 'react'
import Button from './Button';
import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor'

import { 
    RowDelete16
} from '@carbon/icons-react'
import NumberInput from './NumberInput';
import TimePicker from './TimePicker';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [start, setStart] = useMultiInputState(input, 'start', onInputUpdate);
    const [end, setEnd] = useMultiInputState(input, 'end', onInputUpdate);

    return (
        <div style={{display: 'flex', width: '100%', gap: '0.5rem', marginBottom: '0.5rem'}}>
            <TimePicker size="lg" labelText="Range Start" value={start} onChange={value => setStart(value)} />
            <TimePicker size="lg" labelText="Range End" value={end} onChange={value => setEnd(value)} />
            
            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
            style={{height: 48, alignSelf: 'flex-end'}} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}


export default function TimeRangeListEditor({defaultItems, onUpdate}) {
    return <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} 
    ItemComponent={Record} />
}