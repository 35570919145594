import { TextInput } from "carbon-components-react"
import { useEffect } from "react";
import { hasCapabilitySupport } from "../../app/Capabilities";
import { getObjectTypeName, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CHEQUE, OBJECT_TYPE_CONTACT, OBJECT_TYPE_COURSE, OBJECT_TYPE_COURSE_ENROLMENT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_CUSTOMER_GROUP, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_REAL_ESTATE_DEVELOPER, OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL, OBJECT_TYPE_REAL_ESTATE_PROJECT, OBJECT_TYPE_REAL_ESTATE_PROPERTY, OBJECT_TYPE_SHIPMENT_CONSIGNEE, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR, OBJECT_TYPE_VENDOR } from "../../constants/ObjectTypes";
import { useFirstTime } from "../../hooks/useFirstTime";
import { OptionListEditor } from "../../pages/activities/components/option-list";
import { useForm } from "../../templates/form/form"

export function makeParamsField(view) {
    return ({ item, setItem }) => {
        const form = useForm();
        useEffect(() => form.store.subscribeGlobal(() => {
            //setItem({ ...item, params: form.getFormData() })
            setItem(item => ({ ...item, params: form.getFormData() }))
        }), [setItem])
        useFirstTime(() => {
            form.setData(item.params)
        }, [item])
        return (
            <div>
                {/* {JSON.stringify(item)} */}
                {view && view(form)}
            </div>
        )
    }
}

function makeParamsFieldWithLabel(view) {
    return ({ item, setItem }) => {
        const form = useForm();
        useEffect(() => form.store.subscribeGlobal(() => {
            // setItem({ ...item, params: form.getFormData() })
            setItem(item => ({ ...item, params: form.getFormData() }))
        }), [setItem])
        useFirstTime(() => {
            form.setData(item.params)
        }, [item])
        return (
            <div>
                <form.TextField title="Field Label" fieldKey="fieldLabel" />
                {view && view(form)}
            </div>
        )
    }
}

export function createParamsFieldsNew(items, statusDataTypes) {
    //const newItem = { ...items }
    const newItem = JSON.parse(JSON.stringify(items))
    createParamsFields(newItem, statusDataTypes);
    return newItem;
}

export function createParamsFields(items, statusDataTypes) {
    if (items?.hasParamField) {
        let dataTypeItem = DATA_TYPES.find(item => item.type === items.type);
        if (!dataTypeItem) {
            dataTypeItem = statusDataTypes.find(item => item.type === items.type);
        }

        items.ParamsField = dataTypeItem?.ParamsField;

        for (const key in items) {
            createParamsFields(items[key], statusDataTypes);
        }
    } else if (Array.isArray(items)) {
        items.forEach(item => createParamsFields(item, statusDataTypes))
    } else if (items && typeof items === "object") {
        for (const key in items) {
            createParamsFields(items[key], statusDataTypes);
        }
    }
}

export function getStatusesDataTypes(StatusInputFields, statuses) {
    return statuses.map(status => ({
        //type: status.id + '-status',
        type: status.id,
        title: status.name,
        rawItem: status,
        hasParamField: true,
        ParamsField: makeParamsField(form => (<>
            <form.CustomField title="Fields when setting status" fieldKey="inputFields" defValue={[]}>
                {StatusInputFields}
            </form.CustomField>
        </>))
    }))
}

function createItemTypes(...types) {
    const mapper = type => ({
        id: type,
        title: getObjectTypeName(type)
    })

    return types
        .filter(record => typeof record === "number" || record.enabled)
        .flatMap(record => {
            if (typeof record === "number") {
                return [mapper(record)]
            } else {
                return record.types.map(mapper);
            }
        })
}

const ITEM_TYPES = createItemTypes(
    OBJECT_TYPE_ACCOUNT,
    OBJECT_TYPE_CUSTOMER,
    OBJECT_TYPE_CUSTOMER_GROUP,
    OBJECT_TYPE_CONTACT,
    OBJECT_TYPE_VENDOR,

    {
        enabled: hasCapabilitySupport("lms"),
        prefix: "",
        types: [
            OBJECT_TYPE_STUDENT,
            OBJECT_TYPE_TUTOR,
            OBJECT_TYPE_COURSE,
            OBJECT_TYPE_GUARDIAN,
            OBJECT_TYPE_COURSE_ENROLMENT,
        ],
    },

    {
        enabled: hasCapabilitySupport("realEstate"),
        prefix: "Real Estate",
        types: [
            OBJECT_TYPE_REAL_ESTATE_PROJECT,
            OBJECT_TYPE_REAL_ESTATE_PROPERTY,
            OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL,
            OBJECT_TYPE_REAL_ESTATE_DEVELOPER,
        ],
    },

    {
        enabled: hasCapabilitySupport("shipping"),
        prefix: "Shipment",
        types: [
            OBJECT_TYPE_SHIPMENT_CONSIGNEE,
        ],
    },

    OBJECT_TYPE_CHEQUE,
)

export const DATE_TIME_TYPE = {
    dateAndTime: 0,
    dateOnly: 1,
    timeOnly: 2
}

export const DATA_TYPES = [
    {
        type: "text",
        title: "Text",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(),
        dataType: 'string'
    },
    {
        type: "file",
        title: "File",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(),
        dataType: 'string'
    },
    {
        type: "text-area",
        title: "Multiline Text",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(),
        dataType: 'string'
    },
    {
        type: "checkbox",
        title: "Yes/No Checkbox",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(),
        dataType: 'boolean'
    },
    {
        type: "date-time",
        title: "Date-Time",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(form => (<>
            <form.SwitchField title="Type" fieldKey="dateTimeType" options={[
                {
                    id: DATE_TIME_TYPE.dateAndTime,
                    title: 'Date and Time',
                },
                {
                    id: DATE_TIME_TYPE.dateOnly,
                    title: 'Date only',
                },
                {
                    id: DATE_TIME_TYPE.timeOnly,
                    title: 'Time only',
                }
            ]} />
        </>)),
        dataType: 'date'
    },
    {
        type: "list",
        title: "List",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(form => (<>
            <form.CustomField title="Options" fieldKey="optionSelectorOptions" defValue={[]}>
                {OptionListEditor}
            </form.CustomField>
        </>)),
        dataType: 'string'
    },
    {
        type: "options",
        title: "Option Selector",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(form => (<>
            <form.CustomField title="Options" fieldKey="optionSelectorOptions" defValue={[]}>
                {OptionListEditor}
            </form.CustomField>
        </>)),
        dataType: 'string'
    },
    {
        type: "object-item",
        title: "Item",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(form => (<>
            <form.ComboBoxField title="Type" fieldKey="objectItemType" options={ITEM_TYPES} />
        </>)),
        dataType: 'item'
    },
    {
        type: "country",
        title: "Country",
        hasParamField: true,
        ParamsField: makeParamsFieldWithLabel(),
        dataType: 'string'
    },
]


// export const DATA_TYPES_FOR_SHIPMENT = [
//     ...DATA_TYPES,
//     {
//         type: "country",
//         title: "Country",
//         hasParamField: true,
//         ParamsField: makeParamsFieldWithLabel(),
//         dataType: 'string'
//     },
// ]