
import { TextScale20, ImageCopy20, Barcode20, QrCode20, RulerAlt20, StopFilledAlt20, CircleFilled20, Api20, Document20 } from '@carbon/icons-react'
import BarcodeInspector from '../components/inspector-content/BarcodeInspector';
import CircleInspector from '../components/inspector-content/CircleInspector';
import DocumentInspector from '../components/inspector-content/DocumentInspector';
import ImageInspector from '../components/inspector-content/ImageInspector';
import LineInspector from '../components/inspector-content/LineInspector';
import MultiSelectInspector from '../components/inspector-content/MultiSelectInspector';
import QrCodeInspector from '../components/inspector-content/QrCodeInspector';
import SquareInspector from '../components/inspector-content/SquareInspector';
import TextInspector from '../components/inspector-content/TextInspector';

export const ComponentTypes = {
    TEXT: 'text',
    QR_CODE: 'qr-code',
    BARCODE: 'barcode',
    IMAGE: 'image',
    LINE: 'line',
    SQUARE: 'square',
    CIRCLE: 'circle',

    MULTI_SELECTION: 'multi-selection'
}

export const getComponentTypeName = type => {
    switch (type) {
        case ComponentTypes.TEXT:
            return "Text";
        case ComponentTypes.QR_CODE:
            return "QR Code";
        case ComponentTypes.BARCODE:
            return "Barcode";
        case ComponentTypes.IMAGE:
            return "Image";
        case ComponentTypes.LINE:
            return "Line";
        case ComponentTypes.SQUARE:
            return "Square";
        case ComponentTypes.CIRCLE:
            return "Circle";
        case ComponentTypes.MULTI_SELECTION:
            return "Multi-selection";
        default:
            return "Document";
    }
}

export const getComponentTypeIcon = type => {
    switch (type) {
        case ComponentTypes.TEXT:
            return TextScale20;
        case ComponentTypes.QR_CODE:
            return QrCode20;
            case ComponentTypes.BARCODE:
            return Barcode20;
        case ComponentTypes.IMAGE:
            return ImageCopy20;
        case ComponentTypes.LINE:
            return RulerAlt20;
        case ComponentTypes.SQUARE:
            return StopFilledAlt20;
        case ComponentTypes.CIRCLE:
            return CircleFilled20;
        case ComponentTypes.MULTI_SELECTION:
            return Api20;
        default:
            return Document20;
    }
}

export const getComponentInspector = (engine, selectedComponent) => {
    const props = { engine, componentId: selectedComponent.id, key: `component-inspector-${selectedComponent.id}` }
    switch (selectedComponent.type) {
        case ComponentTypes.TEXT:
            return <TextInspector {...props} />
        case ComponentTypes.QR_CODE:
            return <QrCodeInspector {...props} />
        case ComponentTypes.BARCODE:
            return <BarcodeInspector {...props} />
        case ComponentTypes.IMAGE:
            return <ImageInspector {...props} />
        case ComponentTypes.LINE:
            return <LineInspector {...props} />
        case ComponentTypes.SQUARE:
            return <SquareInspector {...props} />
        case ComponentTypes.CIRCLE:
            return <CircleInspector {...props} />
        case ComponentTypes.MULTI_SELECTION:
            return <MultiSelectInspector {...props} />
        default:
            return <DocumentInspector {...props} />
    }
}