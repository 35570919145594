import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { getTemplate } from "../templates/Template";
import CanvasManager from "./CanvasManger";
import HistoryManager from "./HistoryManager";

const TEMPLATE_DESIGNER_VERSION = 1;

export const createTemplateDesignerEngineState = (template) => ({
    //template details
    titleValue: template.title,
    noteValue: template.note,
    templateTypeDefaultValue: template.templateTypeDefault,
    canvasWidth: template.canvasWidth,
    canvasHeight: template.canvasHeight,
    template: getTemplate(template.type),
    engineVersion: template.engineVersion,

    templateId: template.id,

    //editor state
    savedTemplate: template,
    savedTemplateData: template.data,
    showBackgroundGrid: true,
    showCanvasGrid: false,
    canUndo: false,
    canRedo: false,
    selectedComponent: {},


    zoom: 100
})

class LabelDesignerState {

    /**
     * @type {HistoryManager}
     */
    historyManager;

    /**
     * @type {CanvasManager}
     */
    canvasManager;

    constructor(getState, setState) {
        this.getState = getState;
        this.setState = setState;
        this.historyManager = new HistoryManager();
    }

    createTemplateObject() {
        return {
            id: this.getState().templateId,
            title: this.getState().titleValue,
            note: this.getState().noteValue,
            templateTypeDefault: this.getState().templateTypeDefaultValue,
            canvasWidth: this.getState().canvasWidth,
            canvasHeight: this.getState().canvasHeight,
            engineVersion: this.getState().engineVersion,
        }
    }

    save(onDone) {
        const data = JSON.stringify(this.canvasManager.getJSON());
        Api.saveTemplate({
            id: this.getState().templateId,
            title: this.getState().titleValue,
            note: this.getState().noteValue,
            templateTypeDefault: this.getState().templateTypeDefaultValue,
            canvasWidth: this.getState().canvasWidth,
            canvasHeight: this.getState().canvasHeight,
            engineVersion: this.getState().engineVersion,
            data: data,
        }, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState({ savedTemplateData: data })
            } else {
                UIUtil.showError(response.message);
            }
            onDone();
        })
    }

    changesMade() {
        if (this.getState().titleValue != this.getState().savedTemplate.title ||
            this.getState().noteValue != this.getState().savedTemplate.note ||
            this.getState().canvasWidth != this.getState().savedTemplate.canvasWidth ||
            this.getState().canvasHeight != this.getState().savedTemplate.canvasHeight ||
            this.getState().templateTypeDefaultValue != this.getState().savedTemplate.templateTypeDefault) {
            return true;
        }


        if (this.canvasManager) {
            if (this.canvasManager.canvas.getObjects().length == 0 && !Util.isStringExists(this.getState().savedTemplateData)) {
                return false;
            }
            return (JSON.stringify(this.canvasManager.getJSON()) != this.getState().savedTemplateData);
        } else {
            return false;
        }
    }

    onZoom(zoom) {
        if (this.canvasManager) {
            if (zoom) {
                this.canvasManager.onZoom(zoom);
            }
        }
    }

    onResize(size) {
        if (this.canvasManager) {
            if (size) {
                this.canvasManager.onResize(size);
            } else {
                this.canvasManager.onResize({ width: this.getState().canvasWidth, height: this.getState().canvasHeight })
            }
        }
    }

    createCanvasManager(canvas) {
        if (this.canvasManager) {
            return;
        }
        this.canvasManager = new CanvasManager(this, canvas, { width: this.getState().canvasWidth, height: this.getState().canvasHeight });
    }

    getCanvasComponent(id) {
        return this.canvasManager.getObject(id);
    }

    setCanvasComponentParams(id, values) {
        this.canvasManager.setObjectParams(id, values);
    }

    toggleState(property) {
        this.setState(prevState => ({ [property]: !prevState[property] }));
    }

    get(property) {
        return this.getState()[property];
    }

    set(property, value) {
        this.setState({ [property]: value });
    }

    getTemplate() {
        return this.getState().template;
    }

    addComponent(type, size) {
        // alert("Added component: " + type);
        const id = this.canvasManager.addComponent(null, type, size);
        this.recordHistoryAction({ run: () => this.canvasManager.addComponent(id, type, size), reverse: () => this.canvasManager.removeComponent(id) });
    }

    recordHistoryAction({ run, reverse }) {
        this.historyManager.recordAction({ run, reverse })
        this.updateHistoryState();
    }

    redoHistory() {
        this.historyManager.redo();
        this.updateHistoryState();
    }

    undoHistory() {
        this.historyManager.undo();
        this.updateHistoryState();
    }

    updateHistoryState() {
        this.setState({ canUndo: this.historyManager.canUndo(), canRedo: this.historyManager.canRedo() })
        // console.log(this.historyManager.actions, this.historyManager.historyIndex)
    }

    setZoom(value) {
        this.set("zoom", value);
        this.onZoom(value)
    }

    getZoom() {
        return this.get("zoom");
    }

}

export default LabelDesignerState;