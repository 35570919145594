import { Checkbox } from "carbon-components-react";
import { useBooleanValueFilter, useStringValueFilter } from "../../engine/useValueFilter";


export function CheckboxFilter({ label, state, filterId, filter }) {
    const [value, setValue] = useBooleanValueFilter(state, filterId, filter)
    return (
        <div className="white-carbon-check-box" onClick={() => setValue(!value)}>
            <Checkbox checked={value} labelText={label} />
        </div>
    )
}

