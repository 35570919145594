import { InformationFilled20, InformationFilled32 } from "@carbon/icons-react"
import ReactTooltip from "react-tooltip"

export function Info16({ info }) {
    return (<>
        <div className="rb-info-tag" style={{ display: 'inline', marginInline: '0.15rem' }}>
            <InformationFilled20 data-tip={info} data-effect="solid" />
            <ReactTooltip />
        </div>
    </>)
}

export function Info32({ info }) {
    return (<>
        <div className="rb-info-tag">
            <InformationFilled32 data-tip={info} data-effect="solid" />
            <ReactTooltip />
        </div>
    </>)
}