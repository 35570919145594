import React, { createRef } from 'react'
import './happiness-slider.scss';

const emojis = {
    "🤬": { max: 1 },
    "😡": { max: 10 },
    "😢": { max: 20 },
    "😭": { max: 30 },
    "☹️": { max: 40 },
    "🙁": { max: 50 },
    "😐": { max: 60 },
    "🙂": { max: 70 },
    "😊": { max: 80 },
    "😄": { max: 90 },
    "😆": { max: 100 },
    "😁": { min: 100 }
}

class HappinessSlider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            val: 50,
            started: false
        }

        this.emojis = createRef();
        this.form = createRef();
        this.mout = createRef();
    }

    componentDidMount() {
        //this.onUpdateVal()
    }

    onUpdateVal() {
        this.setState({ started: true })

        let newval = +this.emojis.current.value;

        if (this.val !== newval) {
            this.setState({ val: newval })
            //this.form.current.style.setProperty("--val", (this.val = newval));

            for (let k in emojis)
                if (
                    (!emojis[k].min || emojis[k].min <= newval) &&
                    (!emojis[k].max || emojis[k].max > newval)
                ) {
                    this.mout.current.textContent = k;
                    break;
                }
        }
    }

    render() {
        if (this.props.displayNone) {
            return null;
        }

        return (
            <div className="happiness-slider">
                <form ref={this.form} style={{ "--val": this.state.val }}>
                    {/* {this.state.started ? <label for="mood">Happiness</label> : <label>How was your experience?</label>} */}
                    <input onChange={this.onUpdateVal.bind(this)}
                        onMouseUp={() => this.props.onSubmitted(this.state.val)}
                        onTouchEnd={() => this.props.onSubmitted(this.state.val)}
                        ref={this.emojis} type="range" id="mood" value={this.state.val} />
                    <output ref={this.mout} for="mood"></output>
                </form>
            </div>
        )
    }

}

export default HappinessSlider;




export class ReadOnlyHappinessSlider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            val: props.value,
            started: true
        }

        this.emojis = createRef();
        this.form = createRef();
        this.mout = createRef();
    }

    componentDidMount() {
        //this.onUpdateVal()
        this.onUpdateVal(this.props.value)
    }

    onUpdateVal() {
        this.setState({ started: true })

        let newval = +this.emojis.current.value;

        if (this.val !== newval) {
            this.setState({ val: newval })
            //this.form.current.style.setProperty("--val", (this.val = newval));

            for (let k in emojis)
                if (
                    (!emojis[k].min || emojis[k].min <= newval) &&
                    (!emojis[k].max || emojis[k].max > newval)
                ) {
                    this.mout.current.textContent = k;
                    break;
                }
        }
    }

    render() {
        if (this.props.displayNone) {
            return null;
        }

        return (
            <div className="happiness-slider" style={{ pointerEvents: 'none' }}>
                <form ref={this.form} style={{ "--val": this.state.val }}>
                    {/* {this.state.started ? <label for="mood">Happiness</label> : <label>How was your experience?</label>} */}
                    <input onChange={this.onUpdateVal.bind(this)}
                        // onMouseUp={() => this.props.onSubmitted(this.state.val)}
                        // onTouchEnd={() => this.props.onSubmitted(this.state.val)}
                        ref={this.emojis} type="range" id="mood" value={this.state.val} />
                    <output ref={this.mout} for="mood"></output>
                </form>
            </div>
        )
    }

}