import React, { useEffect, useState } from 'react'

import { subDays, addDays } from 'date-fns';
import { DateRangePicker, Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


export default ({ranges, onChange, ...props}) => {
    const [rangesState, setRanges] = useState(ranges)
    useEffect(() => {
        onChange({ selection: rangesState[0] })
    }, [rangesState])
    return (
        <DateRangePicker
            {...props}

            //onChange={item => setState([item.selection])}
            onChange={item => setRanges([item.selection])}
            ranges={rangesState}
            
            />
    )
}