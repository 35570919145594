import Util from "../../../../../util/Util";
import { Invoice } from "./invoice";

import { Close16 } from '@carbon/icons-react'
import { Detail } from "./detail";
import { Documents } from "./documents";
import { QrCode } from "./qr-code";
import { Services } from "./services";
import { Master } from "./master";
import { Profit } from "./profit";

export function getSideViewTitle(sideView) {
    switch (sideView) {
        case "profit":
            return "Profit/Loss";
        case "invoice":
            return "Invoicing";
        case "detail":
            return "Shipment";
        case "documents":
            return "Documents";
        case "qrcode":
            return "QR Code";
        case "services":
            return "Services";
        case "master":
            return "Master";
    }

    return ""
}

export function renderSideView(sideView, shipment, form, endpoint, changesMade, globalState) {
    switch (sideView) {
        case "profit":
            return <Profit shipment={shipment} />
        case "invoice":
            return <Invoice shipment={shipment} form={form} endpoint={endpoint} changesMade={changesMade} globalState={globalState} />
        case "services":
            return <Services shipment={shipment} form={form} endpoint={endpoint} />
        case "detail":
            return <Detail shipment={shipment} form={form} globalState={globalState} />
        case "documents":
            return <Documents shipment={shipment} form={form} globalState={globalState} />
        case "qrcode":
            return <QrCode shipment={shipment} form={form} />
        case "master":
            return <Master shipment={shipment} form={form} endpoint={endpoint} />
    }

    return null;
}

export function SideView({ globalState, endpoint, shipment, form, sideView, setSideView, changesMade }) {
    if (!Util.isStringExists(sideView)) {
        return null;
    }
    return (
        <div style={{ height: '100%', overflow: 'hidden', width: 450, borderLeft: '1px solid #00000020', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', animation: '250ms shipment-item-right-panel-fade-in' }}>
            <div style={{ height: '100%', width: 450, overflow: 'auto' }}>
                <div style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
                    <h2 style={{ fontWeight: 'bold', flex: 1 }}>{getSideViewTitle(sideView)}</h2>
                    <button onClick={() => setSideView(undefined)} className='lead-flow-icon-button'><Close16 /></button>
                </div>
                {renderSideView(sideView, shipment, form, endpoint, changesMade, globalState)}
            </div>
        </div>
    )
}