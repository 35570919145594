import Button from "../../../../components/Button"
import ProfilePic from "../../../../components/ProfilePic"
import { OBJECT_TYPE_ACCOUNT } from "../../../../constants/ObjectTypes"
import Api from "../../../../session/Api"
import { Cut16 } from '@carbon/icons-react'
import { useMemo, useState } from "react"
import UIUtil from "../../../../util/UIUtil"
import { useHistory } from "react-router-dom"
import { getAccount } from "../../../../session/SessionManager"
import { ProfilePicGroup } from "../../../../components/ProfilePicGroup"
import { BlueprintInputFieldsReadView } from "../../components/blueprint-input-fields-read"
import Util from "../../../../util/Util"
import { StudentPresence } from "../../../lms/activity-ext/student-presence"

const Banner = ({ item, activitiesAllowUnassignSelf, overrideUnassignSuccess }) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onUnassignMe = () => {
        UIUtil.confirm(() => {
            setLoading(true);
            Api.activityUnassignMyself(item.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess();
                    if (overrideUnassignSuccess) {
                        overrideUnassignSuccess();
                    } else {
                        history.goBack();
                    }
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        })
    }

    return (
        <div style={{ borderRadius: 10, background: '#0f62fe', paddingTop: '0.15rem', paddingBottom: '0.15rem', display: 'flex', alignItems: 'center', minHeight: 50, paddingInline: '1rem', gap: '0.5rem' }}>
            <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.userId)}
            //style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }} 
            />
            <p style={{ flex: 1, fontSize: 12, fontWeight: 'bold', color: 'white' }}>Assigned to you by <span style={{ fontWeight: 'lighter' }}>{item.creatorFullName}</span></p>
            {activitiesAllowUnassignSelf &&
                <Button onClick={onUnassignMe} loading={loading} size="sm" kind="secondary" renderIcon={Cut16} style={{ borderRadius: 10 }}>
                    Unassign Me
                </Button>}
        </div>
    )
}

const ValueField = ({ title, value }) => (
    <div style={{ marginBottom: '1rem' }}>
        <label style={{ color: '#0f62fe', fontWeight: 'bold', marginBottom: 0 }} className="bx--label">{title}</label>
        <p style={{ fontSize: 14 }}>{value}</p>
    </div>
)

const AnyField = ({ title, children }) => (
    <div style={{ marginBottom: '1rem' }}>
        <label style={{ color: '#0f62fe', fontWeight: 'bold' }} className="bx--label">{title}</label>
        {children}
    </div>
)

const Values = ({ item }) => {
    const userId = useMemo(() => getAccount().id, []);

    const hasDate = item.startDate != -1;
    const hasLead = item.leadId > 0;
    const hasOtherMembers = item.assignations && item.assignations.filter(assign => assign.userId != userId).length > 0;

    if (!hasDate && !hasLead && !hasOtherMembers) {
        return null;
    }

    return (
        <div style={{ paddingInline: '1rem', marginTop: -10, paddingTop: 'calc(1rem + 10px)', background: '#0f62fe40', borderStyle: 'solid', borderWidth: 1, borderColor: '#0f62fe', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
            {hasDate && <ValueField title="Schedule" value={new Date(item.startDate).toLocaleString("en-US") + ' - ' + new Date(item.endDate).toLocaleString("en-US")} />}
            {hasLead && <ValueField title="Lead" value={item.leadFullName} />}
            {hasOtherMembers && <AnyField title="Other members">
                <ProfilePicGroup reverse size={32} srcs={item.assignations.map(assign => Api.getThumbnail(OBJECT_TYPE_ACCOUNT, assign.userId))} />
            </AnyField>}
        </div>
    )
}

export function AssignedView({ item, isStudent, activitiesAllowUnassignSelf, overrideUnassignSuccess, onUpdatePresence }) {
    return (<>
        {!isStudent && <>
            <Banner {...({ item, activitiesAllowUnassignSelf, overrideUnassignSuccess })} />
            <Values item={item} />
            <div style={{ marginTop: '1rem' }} />
        </>}
        {isStudent && Util.isNumberExist(item.courseId) && <>
            <StudentPresence activityId={item.id} readonly={new Date().getTime() > item.endDate} onUpdatePresence={onUpdatePresence} />
            <div style={{ marginTop: '1rem' }} />
        </>}

        <BlueprintInputFieldsReadView formFieldValues={item.formFieldValues} />
    </>)
}