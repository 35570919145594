import { withLoadablePage, withLoadablePageAndProps } from "../../base/Page";
import { useForceLockScroll } from "../../hooks/useLockScroll";
import Api from "../../session/Api";
import { CalendarView } from "./calendar-view";


function Page({ payload: activityDetailsPayload, focusLeadId, courseId, autoSelectBlueprintId, loadAdvancedNotesAtFirstReq }) {
    useForceLockScroll(true)
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <CalendarView loadAdvancedNotesAtFirstReq={loadAdvancedNotesAtFirstReq} activityDetailsPayload={activityDetailsPayload} focusLeadId={focusLeadId} courseId={courseId} autoSelectBlueprintId={autoSelectBlueprintId} />
        </div>
    )
}

export const CalendarPage = withLoadablePageAndProps(listener => Api.getActivity(0, listener), Page);