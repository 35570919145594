import useStore from "../../hooks/useStore"
import { useRefSize } from "../../util/useSize"
import { FormulaValues } from "../components/report-layout/formula-values"
import { QuickFilterBar } from "../components/report-layout/quick-filter-bar"
import { Report } from "./report"

export function ReportView({ info, store, state, setState, isListMode, ...props }) {
    const [savedReportId] = useStore(store, 'selectedSavedReportId')

    const [showGroupNames] = useStore(store, 'showGroupNames');
    const [showFooter] = useStore(store, 'showFooter');
    const [showDarkMode] = useStore(store, 'showDarkMode');

    const [showQuickFilter] = useStore(store, 'showQuickFilter');
    const [showFormulaValues] = useStore(store, 'showFormulaValues');
    const [showPayloadBar] = useStore(store, 'showPayloadBar');

    const [refreshReportContentRequest] = useStore(store, 'refreshReportContentRequest')

    const [showColumns, setShowColumns] = useStore(store, 'showColumns');
    const [showAdvancedFilter, setShowAdvancedFilter] = useStore(store, 'showAdvancedFilter');

    return <Report reportName={info.name} reportEndpoint={info.endpoint} end {...{
        savedReportId, showFooter, showDarkMode, showQuickFilter, showFormulaValues, showPayloadBar,

        showColumns, setShowColumns,
        showAdvancedFilter, setShowAdvancedFilter,

        refreshReportContentRequest,
        state, setState, isListMode,


        showGroupNames: isListMode ? false : showGroupNames,
        ...props
    }} />
}