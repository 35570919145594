
import { CenterSquare16 } from '@carbon/icons-react'
import { Tag } from 'carbon-components-react';
import Util from '../../../../util/Util';

export function EntranceScanStatus({ attendanceRecord }) {
    if (attendanceRecord) {
        // if (attendanceRecord.allowedToProceed) {

        // }
        return (
            <div style={{ marginTop: '0.25rem', borderRadius: 15, border: '1px solid #00000040', background: 'white', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center' }}>
                <CenterSquare16 />
                <div style={{ marginLeft: '0.5rem', flex: 1 }}>
                    <p style={{ fontSize: 14, opacity: 0.65, fontStyle: 'italic', fontWeight: 'bold', }}>Entrance Scan</p>
                    <p style={{ fontSize: 12, marginTop: -2, opacity: 0.65 }}>{Util.getTime(attendanceRecord.attendanceDate)}</p>
                </div>

                {attendanceRecord.allowedToProceed ?
                    <Tag size="sm" type="green">allowed to proceed</Tag> :
                    <Tag size="sm" type="red">denied entrance</Tag>}
            </div>
        )
    } else {
        return (
            <div style={{ marginTop: '0.25rem', borderRadius: 15, border: '1px solid #00000040', background: 'white', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center' }}>
                <CenterSquare16 />
                <p style={{ fontSize: 12, marginLeft: '0.25rem', opacity: 0.65, fontStyle: 'italic', fontWeight: 'bold', flex: 1 }}>Entrance Scan</p>

                <Tag size="sm" type="high-contrast">no scan</Tag>

            </div>
        )
    }
}