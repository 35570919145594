

import {
    ErrorFilled16, CheckmarkFilled16, Add16, Subtract16
} from '@carbon/icons-react'

import { ButtonSet, ComposedModal, InlineLoading, NumberInput, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import useThirdPartyPos from '../../pos/useThirdPartyPos';
import CartView from '../views/CartView';

export default ({ controller, itemId, qty, groupToProductMap, visible, onClose }) => {
    const { loading, updateCartItem, } = controller.useThirdPartyPos()

    const [value, setValue] = useState(0);
    useEffect(() => {
        if (visible) {
            setValue(qty)
        }
    }, [visible])

    return (
        <ComposedModal
            key="set-item-qty-dialog"
            open={visible}

            onClose={onClose}
            size="sm">
            <ModalHeader label="Cart Item" title="Set Quantity" />
            <ModalBody style={{ paddingRight: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
                <Button disabled={value <= 0}
                    onClick={() => setValue(val => val - 1)}
                    kind="danger" hasIconOnly renderIcon={Subtract16} className="bx--tooltip--hidden" data-tip="Subtract" />
                <div>
                    <NumberInput
                        invalidText="Invalid number"
                        data-modal-primary-focus
                        size="xl"
                        value={value}
                        onChange={(e, { value }) => setValue(value)}
                        hideSteppers
                        hideLabel
                    />
                </div>
                <Button hasIconOnly size="lg" data-tip="Add"
                    onClick={() => setValue(val => val + 1)}
                    renderIcon={Add16} className="bx--tooltip--hidden" />
            </ModalBody>
            <ModalFooter>
                <ButtonSet style={{ width: '100%' }}>
                    <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16} disabled={loading}>
                        Cancel
                    </Button>
                    <Button renderIcon={CheckmarkFilled16} loading={loading}
                        disabled={value == qty || (isNaN(value) || value === "")}
                        onClick={() => {
                            //SAME CODE ABOVE FOR OTHER STOCK TYPE
                            const diff = value - qty;
                            let removing = diff < 0;

                            updateCartItem(itemId, Math.abs(diff), undefined, removing, onClose, groupToProductMap);
                        }}>
                        Set
                    </Button>
                </ButtonSet>
            </ModalFooter>
        </ComposedModal>
    )
}