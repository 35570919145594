import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceNo2Container: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        //width: 60
        marginRight: 5,
    }

});


const InvoiceNo = ({ invoice }) => (
    <Fragment>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Voucher Number:</Text>
            <Text style={styles.invoiceDate}>{invoice.voucherNo}</Text>
        </View >
        <View style={styles.invoiceNo2Container}>
            {/* <Text style={styles.label}>Sales Receipt ID:</Text> */}
            <Text style={styles.label}>Sales Invoice ID:</Text>
            <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date:</Text>
            <Text >{invoice.trans_date}</Text>
        </View >
    </Fragment>
);

export default InvoiceNo