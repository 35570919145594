import { MultiSelect } from "./multi-select";

const OPTIONS = [
    "Monthly"
]

export function DateComparison({ state }) {
    return (
        <div style={{ height: 30, width: 125 }}>
            <MultiSelect skipAutoFilter isMulti={false} placeholder="Compare" key="dateComparison" fieldKey={"dateComparison"} options={OPTIONS} state={state} property="dateComparison" />
        </div>
    )
}