import React from 'react'
import Page from '../../base/Page';
import Button from '../../components/Button';
import Api from '../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
} from '@carbon/icons-react'
import { DatePicker, DatePickerInput, Link } from 'carbon-components-react';
import TransactionListView from '../transaction/TransactionListView';
import Util, { DAY_MS, handleEndDate } from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import SalesSummaryView from '../../views/reporting/SalesSummaryView';

import { subDays, addDays } from 'date-fns';
//import { DateRangePicker } from 'react-date-range';
import DateRangePicker from '../../components/date/DateRangePicker'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { StatelessDateRange } from '../../reporting-engine/components/basic-filter/date-range';

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)


class SalesSummaryReport extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingReport: false,
            generatedReport: false,

            report: undefined,

            // startDateValue: subDays(new Date(), 1).getTime(),
            // endDateValue: new Date().getTime(),
            startDateValue: 0,
            endDateValue: 0,

            suppliers: [],
            customers: [],
            warehouses: [],
            stores: [],
        }
    }

    clearReport() {
        this.setState({
            generatingReport: false,
            generatedReport: false,

            startDateValue: 0,
            endDateValue: 0,
        })
    }

    canGenerateReport() {
        return Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);
    }

    generateReportBtn() {
        this.setState({ generatingReport: true })
        //Api.getSalesSummaryReport(this.state.startDateValue, handleEndDate(this.state.endDateValue), this.props.posMode === true, response => {
        Api.getSalesSummaryReport(this.state.startDateValue, this.state.endDateValue, this.props.posMode === true, response => {
            if (response.status === true) {
                // console.log(response.payload)
                this.setState({ generatingReport: false, report: response.payload, generatedReport: true, })
                UIUtil.showSuccess();
            } else {
                this.setState({ generatingReport: false, generatedReport: false })
                UIUtil.showError(response.message);
            }
        })
    }

    onPageStart() {
        this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => ({
            suppliers: payload.suppliers,
            customers: payload.customers,
            warehouses: payload.warehouses,
            stores: payload.stores,
        }))
    }

    renderInput() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>{this.props.posMode && 'POS '}Sales Summary</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>


                    <Section icon={Number_132} title="Select Range">
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <StatelessDateRange label="Sales"
                                start={this.state.startDateValue} end={this.state.endDateValue}
                                setStart={startDateValue => this.setState({ startDateValue })}
                                setEnd={endDateValue => this.setState({ endDateValue })}
                            />
                        </div>
                        {/* <DateRangePicker
                            //onChange={item => setState([item.selection])}
                            onChange={item => this.setState({ startDateValue: item.selection.startDate.getTime(), endDateValue: item.selection.endDate.getTime() })}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[{
                                startDate: new Date(this.state.startDateValue),
                                endDate: new Date(this.state.endDateValue),
                                key: 'selection'
                            }]}
                            direction="horizontal"
                        /> */}
                    </Section>

                    <Section icon={Number_232} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.generateReportBtn.bind(this)} disabled={!this.canGenerateReport()} loading={this.state.generatingReport} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    renderReport() {
        return (
            <div className="main-content">
                <div onClick={this.clearReport.bind(this)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                    <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another report</Link>
                </div>
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2, display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <h4 style={{}}>{this.props.posMode && 'POS '}Sales Summary</h4>

                        <div style={{ display: 'flex', alignItems: 'center', opacity: 0.65 }}>
                            <Calendar16 style={{ marginRight: '0.25rem' }} /> <p>{Util.getDateOnly(this.state.startDateValue)}</p>

                            <p style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>-</p>

                            <Calendar16 style={{ marginRight: '0.25rem' }} /> <p>{Util.getDateOnly(this.state.endDateValue - 1)}</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', color: 'green' }}>
                        <p>Total Sales (+)</p>
                        <h4>AED {this.state.report.totalSales.toFixed(2)}</h4>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', color: 'red' }}>
                        <p>Total Returns (-)</p>
                        <h4>AED {this.state.report.totalSalesReturn.toFixed(2)}</h4>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', color: 'green' }}>
                        <p>Net Total</p>
                        <h2 style={{ fontWeight: 'bold' }}>AED {this.state.report.totalNet.toFixed(2)}</h2>
                    </div>
                </div>
                <SalesSummaryView report={this.state.report} posMode={this.props.posMode} />
            </div>
        )
    }

    getLayout() {
        return this.state.generatedReport ? this.renderReport() : this.renderInput()
    }

}

export default SalesSummaryReport;