import { useEffect, useState } from "react";
import ImageView from "../../../components/ImageView";
import { OBJECT_TYPE_MOVIE } from "../../../constants/ObjectTypes";
import Api from "../../../session/Api";
import { Tag } from "carbon-components-react";

import { Theater24, Add16, AddAlt16, ArrowLeft16, ArrowRight16 } from '@carbon/icons-react'
import Util from '../../../util/Util';
import { getTerminal } from "../../../session/SessionManager";
import UIUtil from "../../../util/UIUtil";


function dayName(day) {
    if (day === 0) {
        return 'Today'
    } else if (day === 1) {
        return 'Tomorrow'
    } else {
        const date = new Date();
        date.setDate(date.getDate() + day);
        return Util.getDateOnlyWithoutYear(date);
    }
}

export function ShowtimeMovieListDayPicker({ day, setDay }) {
    return (
        <div style={{ width: '100%', height: 35, marginBottom: '1rem', color: 'white', background: '#1c1c1c', border: 'solid 1px #00000090', display: 'flex', borderRadius: 10, alignItems: 'center', justifyContent: 'center', gap: '3rem' }}>
            <button disabled={day === 0} className='cinema-pos-day-picker-show-time-scheduler-button' onClick={() => setDay(p => p - 1)}>
                <ArrowLeft16 />
            </button>
            <p style={{ fontSize: 12, opacity: 0.65, width: 125, textAlign: 'center' }}>{dayName(day)}</p>
            <button className='cinema-pos-day-picker-show-time-scheduler-button' onClick={() => setDay(p => p + 1)}>
                <ArrowRight16 />
            </button>
        </div>
    )
}

const useSchedule = (movieId, day) => {
    const [schedule, setSchedule] = useState();
    useEffect(() => {
        let cancelled = false;

        setSchedule(undefined)
        Api.getCinemaPosMovieSchedules(getTerminal().id, movieId, day, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setSchedule(response.payload);
            }
        })

        return () => cancelled = true;
    }, [day])
    return schedule;
}

const List = ({ movieId, day, onShowtimeClick }) => {
    const schedule = useSchedule(movieId, day);

    const onShowtimeClickBtn = (showtime) => {
        const time = new Date().getTime();
        console.log({ startdate: showtime.startDate, time })
        if (time > showtime.startDate) {
            const timeAlreadyStarted = Math.round((time - showtime.startDate) / 60000);
            UIUtil.confirmMsg("The movie has already started for " + timeAlreadyStarted + " minutes", () => onShowtimeClick(showtime))
        } else {
            onShowtimeClick(showtime)
        }
    }

    return (<>
        {schedule?.movie.cinemas.map(cinema => (
            <div key={cinema.id} style={{ width: '100%', marginBottom: '1rem', background: '#1c1c1c', borderRadius: 10, border: '1px solid #00000090', color: 'white', padding: '1rem' }}>
                {Util.isStringExists(cinema.name) && <h4>{cinema.name}</h4>}
                <div style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
                    {cinema.theaterTypes.map(theaterType => (
                        <div key={theaterType.id} style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}>
                            <h6 style={{ marginBottom: '0.25rem' }}>{theaterType.name}</h6>
                            <div>
                                {theaterType.schedules.map(showtime => (
                                    <Tag
                                        style={{
                                            paddingBlock: '1rem',
                                            paddingInline: '1rem',
                                            marginRight: '1rem'
                                        }}
                                        type="blue"
                                        onClick={() => onShowtimeClickBtn(showtime)}
                                        key={showtime.id}>{showtime.value} {showtime.threeD && <strong>3D</strong>}</Tag>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </>)
}

export function ShowtimeList({ overview, movieId, onShowtimeClick }) {
    const movieName = overview?.[0];
    const [day, setDay] = useState(0);
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div style={{ position: 'relative', padding: '1rem', color: 'white', background: '#0f0f0f', flex: 2, height: '100%', borderRight: '1px solid #ffffff10' }}>
                <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, movieId)} style={{ width: '100%', height: '100%', borderRadius: 15, border: '1px solid #ffffff10' }} />
                {movieName && <div style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', padding: '1rem', borderTop: '1px solid #ffffff20', background: '#00000080', backdropFilter: 'blur(16px) saturate(200%)', }}>
                    <h4 style={{ fontSize: 22, marginBottom: '0.25rem' }}>{movieName}</h4>
                </div>}
            </div>
            <div style={{ flex: 5, paddingBlock: '1rem', paddingInline: '3rem', color: 'white', height: '100%', overflow: 'auto' }}>
                <h2 style={{ marginBottom: '3rem', marginTop: '1rem' }}>Showtimes</h2>
                <ShowtimeMovieListDayPicker day={day} setDay={setDay} />
                <List movieId={movieId} onShowtimeClick={onShowtimeClick} day={day} />
                <div style={{ height: '1rem' }} />
            </div>
        </div>

        // <div style={{ paddingBlock: '3rem', display: 'flex', paddingInline: '6rem', gap: '1rem', flexWrap: 'wrap' }}>

        //     {/* <ShowtimeMovieListDayPicker day={day} setDay={setDay} />
        //     <ShowtimeMovieList cinemaId={selectedCinema?.id ?? 0} movieId={movieId} day={day} /> */}
        // </div>
    )
}