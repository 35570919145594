import Theme from "../Theme";

class RedTheme extends Theme {

    getName() {
        return "red";
    }

    getColor() {
        return "#500000";
    }

    getClassName() {
        return "theme-red"
    }

}

export default RedTheme;