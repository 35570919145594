
import { withLoadablePage, withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import { NotificationFilled32, ArrowLeft16 } from '@carbon/icons-react'
import Util from "../../../util/Util"
import { Tag, Link as DLink } from "carbon-components-react"
import { useHistory } from "react-router-dom"
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { STATE_TYPE_LMS_NOTIFICATION } from "../../../constants/Constants"
import { useDraft } from "../../../templates/draft/draft"
import { FormControls } from "../../../templates/form/FormLayout"
import { OBJECT_TYPE_ACTIVITY, OBJECT_TYPE_COURSE, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_LMS_GROUP, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR } from "../../../constants/ObjectTypes"
import { TargetTypes } from "./target-type"
import { OtherEmailSelect, TargetSelect } from "./target-select"
import { TargetEmails } from "./target-emails"
import UIUtil from "../../../util/UIUtil"
import { isMobileScreen } from "../../../App"
import { useAdvancedDraft } from "../../../templates/draft/advanced-draft"
import { EmailTags } from "./email-tags"


const DraftBtn = forwardRef(({ setData, getData }, ref) => {
    const draft = useAdvancedDraft(STATE_TYPE_LMS_NOTIFICATION, setData, getData, true)

    useImperativeHandle(ref, () => draft, [draft])

    return (<>
        {draft.draftManagement}
        {draft.draftDialogs}
    </>)
})


function useMakeDraft({
    type, setType,
    to, setTo,
    otherEmail, setOtherEmail,
    skippedEmails, setSkippedEmails,
    message, setMessage,
}) {
    const ref = useRef();

    const setData = data => {
        setType(data?.type ?? OBJECT_TYPE_LMS_GROUP)
        setTo(data?.to ?? [])
        setOtherEmail(data?.otherEmail ?? [])
        setSkippedEmails(data?.skippedEmails ?? [])
        setMessage(data?.message ?? "");
    }
    const getData = () => ({
        type, to, otherEmail, skippedEmails, message
    })
    return {
        draftBtn: <DraftBtn ref={ref} setData={setData} getData={getData} />,
        draft: ref.current
    }
}

function View({ payload: endpoints }) {
    const [type, setType] = useState(OBJECT_TYPE_LMS_GROUP);
    const [to, setTo] = useState([]);
    const [otherEmail, setOtherEmail] = useState([]);

    const [skippedEmails, setSkippedEmails] = useState([]);

    const [message, setMessage] = useState("")
    const [saving, setSaving] = useState(false);

    const history = useHistory();
    const { draftBtn, draft } = useMakeDraft({
        type, setType,
        to, setTo,
        otherEmail, setOtherEmail,
        skippedEmails, setSkippedEmails,
        message, setMessage
    });

    const toIds = useMemo(() => to.map(item => item.value), [to])
    const toEmails = useMemo(() => otherEmail.map(item => item.value), [otherEmail]);
    const toOptions = useMemo(() => {
        switch (type) {
            case OBJECT_TYPE_LMS_GROUP:
                return endpoints.groups;
            case OBJECT_TYPE_COURSE:
                return endpoints.courses;
            case OBJECT_TYPE_ACTIVITY:
                return endpoints.sessions;
            case OBJECT_TYPE_STUDENT:
                return endpoints.students;
            case OBJECT_TYPE_GUARDIAN:
                return endpoints.guardians;
            case OBJECT_TYPE_TUTOR:
                return endpoints.tutors;
            default:
                return []
        }
    }, [type, endpoints])

    const onTypeChanged = type => {
        setType(type);
        setTo([]);
        setOtherEmail([]);
        setSkippedEmails([]);
    }

    const onSaveBtn = () => {
        setSaving(true);
        Api.publishLmsNotification({
            toType: type, toIds: toIds, toEmails: toEmails,
            message, skippedEmails
        }, response => {
            if (response.status === true) {
                history.replace("/lms-notifications/" + response.payload.id)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setSaving(false);
        })
    }

    const messageTextArea = useRef();
    return (
        <div>
            <div style={{ paddingInline: isMobileScreen ? '1rem' : '6rem', paddingTop: isMobileScreen ? '1rem' : '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem' }}>
                    <div style={isMobileScreen ? { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' } : {}}>
                        <NotificationFilled32 style={{ width: 64, height: 64 }} />
                        <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>Notification</h3>
                        {/* <h4>{notification.id}</h4> */}
                        <h4 style={{ color: '#0f62fe', marginBottom: isMobileScreen ? '1rem' : 0 }}>Publish</h4>

                        {isMobileScreen && draftBtn}
                    </div>
                    <div style={{ flex: 1 }} />
                    {!isMobileScreen && draftBtn}
                </div>


                {isMobileScreen ? (<>


                    <div style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', background: 'white', border: '1px solid #00000020', width: '100%', marginBottom: '1rem' }}>
                        <div style={{ minHeight: '3rem', paddingBlock: '0.5rem', paddingInline: '1rem', alignItems: 'center', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>To Type</p>
                            <TargetTypes tabIndex={type} setTabIndex={onTypeChanged} />
                        </div>

                        <div style={{ minHeight: '3rem', paddingBlock: '0.5rem', paddingInline: '1rem', alignItems: 'center', background: '#00000010', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>To Target</p>
                            <div style={{ minHeight: '1rem', minWidth: '100%' }}>
                                {type === -1 ? (
                                    <OtherEmailSelect placeholder="Input email..." options={endpoints.mixEmails} value={otherEmail} setValue={setOtherEmail} />
                                ) : (
                                    <TargetSelect placeholder="Select..." options={toOptions} value={to} setValue={setTo} />
                                )}
                            </div>
                        </div>
                        <div style={{ minHeight: '3rem', paddingBlock: '0.5rem', paddingInline: '1rem', alignItems: 'center', background: '#00000000', flexWrap: 'wrap', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Email Addresses</p>
                            <TargetEmails ids={toIds} emails={toEmails} type={type} setSkippedEmails={setSkippedEmails} skippedEmails={skippedEmails} />
                        </div>
                        <div style={{ minHeight: '3rem', paddingBlock: '0.5rem', display: 'flex', paddingInline: '1rem', flexWrap: 'wrap', alignItems: 'center', background: '#00000010', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Message:</p>
                            <div style={{ flex: 1 }} />
                            <EmailTags type={type} onTagClicked={tag => {
                                setMessage(prev => prev + "{" + tag + "}")
                                messageTextArea.current?.focus();
                            }} />
                        </div>
                        <div style={{ width: '100%', borderBottom: '1px solid #00000020', borderRadius: 10, overflow: 'hidden' }}>
                            <textarea value={message} onChange={e => setMessage(e.target.value)} style={{ padding: '1rem', border: 'none', outline: 'none', width: '100%' }} rows={12} placeholder="Input message..." />
                        </div>
                    </div>


                </>) : (<>

                    <div style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', background: 'white', border: '1px solid #00000020', width: '100%', marginBottom: '1rem' }}>
                        <div style={{ height: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>To Type</p>
                            <div style={{ flex: 1 }} />
                            <TargetTypes tabIndex={type} setTabIndex={onTypeChanged} />
                        </div>

                        <div style={{ minHeight: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', background: '#00000010', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>To Target</p>
                            <div style={{ flex: 1 }} />
                            <div style={{ minHeight: '1rem', minWidth: 400 }}>
                                {type === -1 ? (
                                    <OtherEmailSelect placeholder="Input email..." options={endpoints.mixEmails} value={otherEmail} setValue={setOtherEmail} />
                                ) : (
                                    <TargetSelect placeholder="Select..." options={toOptions} value={to} setValue={setTo} />
                                )}
                            </div>
                        </div>
                        <div style={{ minHeight: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', flexWrap: 'wrap', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Email Addresses</p>
                            <div style={{ flex: 1 }} />
                            <TargetEmails ids={toIds} emails={toEmails} type={type} setSkippedEmails={setSkippedEmails} skippedEmails={skippedEmails} />
                        </div>
                        <div style={{ height: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', background: '#00000010', borderBottom: '1px solid #00000020' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Message:</p>
                            <div style={{ flex: 1 }} />
                            {/* <p style={{ fontSize: 10, opacity: 0.65 }}>tags</p> */}
                            <EmailTags type={type} onTagClicked={tag => {
                                setMessage(prev => prev + "{" + tag + "}")
                                messageTextArea.current?.focus();
                            }} />
                        </div>
                        <div style={{ width: '100%', borderBottom: '1px solid #00000020', borderRadius: 10, overflow: 'hidden' }}>
                            <textarea ref={messageTextArea} value={message} onChange={e => setMessage(e.target.value)} style={{ padding: '1rem', border: 'none', outline: 'none', width: '100%' }} rows={4} placeholder="Input message..." />
                        </div>
                    </div>

                </>)}

                <FormControls
                    fullWidth isCreating rounded saveMessage={isMobileScreen ? "Send" : "Send Notification"}
                    saving={saving}
                    onSave={() => UIUtil.confirm(onSaveBtn)}
                    selectedDraft={draft?.selectedDraft}
                    advancedDraft
                    onSaveDraft={() => draft.setShowSaveDraft(true)}
                />


                <div style={{ height: '6rem' }} />

            </div>
        </div>
    )
}

export const LmsNotificationPublish = withLoadablePage(listener => Api.getLsmNotificationEndpoints(listener), View)


