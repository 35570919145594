import React, { useState, useRef, useEffect } from 'react'
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAPS_API_KEY } from '../../../app/Config';
import Button from '../../../components/Button';
import './MapMonitoring.scss'

import { Van16, LocationStarFilled20, AirlinePassengerCare16, LocationHazardFilled20, AirportLocation16 } from '@carbon/icons-react'
import { CustomerLocationMarker, dark_getRandomMarkerColor, getRandomMarkerColor, LocationOffMarker, LocationOnMarker, MainMarker, Marker, SecondaryMarker, StartLocationMarker } from './Markers';
import { LOCATION_EVENT_FLAG_LOCATION_OFF, LOCATION_EVENT_FLAG_LOCATION_ON, LOCATION_EVENT_FLAG_NONE, LOCATION_EVENT_FLAG_STAGNANT } from '../../../constants/Constants';
import MapMonitoringEntryView from './MapMonitoringEntryView';
import ReactTooltip from 'react-tooltip';
import RawEventsView from './RawEventsView';
import Util from '../../../util/Util';
import { useHistory } from 'react-router-dom';

const DEF_LOC = { lat: 25.20112613289534, lng: 55.28151582031251 };
const DEF_ZOOM = 10;

// const mobileUnits = [{
//     id: 8422,
//     title: "Fwe", 
//     events: [
//         { id: 1, flag: LOCATION_EVENT_FLAG_START, lat: 25.20112613289534, lng: 55.28151582031251 },
//         { id: 2, flag: LOCATION_EVENT_FLAG_LOCATION_OFF, lat: 25.30112613289534, lng: 55.38151582031251 },
//         { id: 3, flag: LOCATION_EVENT_FLAG_LOCATION_ON, lat: 25.40112613289534, lng: 55.48151582031251 },
//         { id: 4, flag: LOCATION_EVENT_FLAG_STAGNANT, lat: 25.50112613289534, lng: 55.58151582031251 },
//         { id: 5, flag: LOCATION_EVENT_FLAG_NONE, lat: 25.55112613289534, lng: 55.64151582031251 },
//         { id: 6, flag: LOCATION_EVENT_FLAG_NONE, lat: 25.60112613289534, lng: 55.68151582031251 },
//     ]
// }]


export default ({ sessions, onMonitorBtn, customerLocations, onRemove }) => {
    const [selected, setSelected] = useState(undefined)
    const [focus, setFocus] = useState(undefined)

    const [selectedDate, setSelectedDate] = useState()
    useEffect(() => {
        setSelectedDate(new Date().getTime())
    }, [selected])

    const mapApiRef = useRef()
    const mapLines = useRef([])

    const getSessionsForMap = () => {
        return selected ? [selected] : sessions
    }

    const drawPaths = () => {
        if (!mapApiRef.current) {
            return;
        }

        mapLines.current.forEach(line => line.setMap(null))
        mapLines.current.length = 0;

        const { map, maps } = mapApiRef.current;
        for (const unit of getSessionsForMap()) {
            const line = new maps.Polyline({
                path: unit.locationEvents.map(event => ({ lat: event.lat, lng: event.lng })),
                geodesic: true,
                strokeColor: dark_getRandomMarkerColor(unit.id),
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });
            line.setMap(map)
            mapLines.current.push(line);
        }
    }

    const handleApiLoaded = (map, maps) => {
        mapApiRef.current = { map, maps }
        drawPaths()
    };
    useEffect(() => {
        drawPaths()
    }, [getSessionsForMap()])

    useEffect(() => {
        if (selected) {
            for (const session of sessions) {
                if (session.id == selected.id && session !== selected) {
                    setSelected(session)
                }
            }
        }
    }, [selected, sessions])

    return (
        <div style={{ width: '100%', display: 'flex', height: 350 }}>
            <div style={{ height: '100%', flex: 4 }}>
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                    defaultCenter={DEF_LOC}
                    defaultZoom={DEF_ZOOM}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                >
                    {customerLocations.map(location => (
                        <CustomerLocationMarker key={location.id + '-cm'} color={"#FFFFFF"} lat={location.lat} lng={location.lng}
                            name={location.name} onClick={() => window.open("/customers/" + location.id, "_blank")}
                        />
                    ))}

                    {getSessionsForMap().map(unit => (
                        unit.locationEvents.map((event, index) => (
                            <Marker key={unit.id + '-m:e-' + event.id} color={getRandomMarkerColor(unit.id)} {...event}
                                isStart={index == 0}
                                isCurrent={index == unit.locationEvents.length - 1}
                                onClick={() => setSelected(unit)}
                                onHoverChange={hover => {
                                    if (hover) {
                                        setFocus(unit.id)
                                    } else if (focus == unit.id) {
                                        setFocus(undefined)
                                    }
                                }}
                            />
                        ))
                    ))}

                    <ReactTooltip />
                </GoogleMapReact>
            </div>
            <div style={{ width: '1rem' }} />
            <div style={{ height: '100%', flex: 2 }}>
                <div className="dashboard-card" style={{ width: '100%', height: '100%', padding: '0.5rem', overflow: 'auto', position: 'relative' }}>
                    {selected && <MapMonitoringEntryView key={selected.id + '-header'} item={selected} headerMode subtitle={'As of ' + Util.getDate(selectedDate)} onBackBtn={() => setSelected(undefined)} />}
                    {selected && <RawEventsView sessionId={selected.session.posSessionId} />}
                    {/* {selected && <pre><code>{JSON.stringify(selected, null, 2)}</code></pre>} */}

                    {!selected && sessions.map(item => <MapMonitoringEntryView
                        key={item.id} item={item} highlight={focus == item.id}
                        onRemove={onRemove}
                        onMapEventsBtn={() => setSelected(item)} onMonitorBtn={() => onMonitorBtn(item)} />)}

                    {sessions.length == 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>No mobile unit is currently active</p>
                    </div>}
                </div>
            </div>

            <ReactTooltip />
        </div>
    )
}