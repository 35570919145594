
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_STORE, OBJECT_TYPE_STUDENT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TRANSACTION, OBJECT_TYPE_TUTOR, OBJECT_TYPE_V2_CUSTOMER, OBJECT_TYPE_V2_EMPLOYEE, OBJECT_TYPE_V2_VENDOR, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from '../../constants/ObjectTypes';
import {
    DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_WAREHOUSE, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE,
    TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR, TRANSACTION_PARTY_TYPE_OTHER, TRANSACTION_PARTY_TYPE_STAFF, TRANSACTION_PARTY_TYPE_STORE, TRANSACTION_PARTY_TYPE_STUDENT, TRANSACTION_PARTY_TYPE_SUPPLIER, TRANSACTION_PARTY_TYPE_TUTOR, TRANSACTION_PARTY_TYPE_V2_CUSTOMER, TRANSACTION_PARTY_TYPE_V2_EMPLOYEE, TRANSACTION_PARTY_TYPE_V2_VENDOR, TRANSACTION_PARTY_TYPE_VENDOR, TRANSACTION_PARTY_TYPE_VENUE
} from '../../constants/Constants';


import {
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    BuildingInsights_132,
    ChartBullet32
} from '@carbon/icons-react'
import Api from '../../session/Api';

export const AGAINST_MODE = {
    sales: {
        icon: SaleIcon,
        name: "Sales",
        api: Api.getUnpaidSales.bind(Api)
    },
    purchase: {
        icon: PurchaseIcon,
        name: "Purchases",
        api: Api.getUnpaidPurchases.bind(Api),
        stockFlowAndBill: true
    },
    bills: {
        icon: ChartBullet32,
        name: "Bill",
        bills: true
    },
    venueSales: {
        icon: BuildingInsights_132,
        name: "Venue Sales",
        api: Api.getUnpaidVenueSales.bind(Api),
        supportInvoiceDocuments: true
    }
}

export function getPartyTypeName(otherPartyType) {
    switch (otherPartyType) {
        case TRANSACTION_PARTY_TYPE_SUPPLIER: case TRANSACTION_PARTY_TYPE_SUPPLIER + "":
            return "Supplier";
        case TRANSACTION_PARTY_TYPE_CUSTOMER:
            return "Customer";
        case TRANSACTION_PARTY_TYPE_STAFF:
            return "Staff";
        case TRANSACTION_PARTY_TYPE_VENDOR:
            return "Vendor";
        case TRANSACTION_PARTY_TYPE_STORE:
            return "Store";
        case TRANSACTION_PARTY_TYPE_VENUE:
            return "Venue";
        case TRANSACTION_PARTY_TYPE_STUDENT:
            return "Student";
        case TRANSACTION_PARTY_TYPE_TUTOR:
            return "Tutor";
        case TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR:
            return "Movie Distributor"

        case TRANSACTION_PARTY_TYPE_V2_CUSTOMER:
            return "Customer";
        case TRANSACTION_PARTY_TYPE_V2_VENDOR:
            return "Vendor";
        case TRANSACTION_PARTY_TYPE_V2_EMPLOYEE:
            return "Employee";
    }

    return undefined;
}

export function getPartyObjectType(otherPartyType) {
    let objectType;
    switch (otherPartyType) {
        case TRANSACTION_PARTY_TYPE_SUPPLIER:
            objectType = OBJECT_TYPE_SUPPLIER;
            break;
        case TRANSACTION_PARTY_TYPE_CUSTOMER:
            objectType = OBJECT_TYPE_CUSTOMER;
            break;
        case TRANSACTION_PARTY_TYPE_STAFF:
            objectType = OBJECT_TYPE_ACCOUNT;
            break;
        case TRANSACTION_PARTY_TYPE_VENDOR:
            objectType = OBJECT_TYPE_VENDOR;
            break;
        case TRANSACTION_PARTY_TYPE_STORE:
            objectType = OBJECT_TYPE_STORE;
            break;
        case TRANSACTION_PARTY_TYPE_VENUE:
            objectType = OBJECT_TYPE_VENUE;
            break;
        case TRANSACTION_PARTY_TYPE_STUDENT:
            objectType = OBJECT_TYPE_STUDENT;
            break;
        case TRANSACTION_PARTY_TYPE_TUTOR:
            objectType = OBJECT_TYPE_TUTOR;
            break;
        case TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR:
            objectType = OBJECT_TYPE_MOVIE_DISTRIBUTOR;
            break;

        case TRANSACTION_PARTY_TYPE_V2_CUSTOMER:
            objectType = OBJECT_TYPE_V2_CUSTOMER;
            break;
        case TRANSACTION_PARTY_TYPE_V2_VENDOR:
            objectType = OBJECT_TYPE_V2_VENDOR;
            break;
        case TRANSACTION_PARTY_TYPE_V2_EMPLOYEE:
            objectType = OBJECT_TYPE_V2_EMPLOYEE;
            break;
    }
    return objectType;
}