import { ContentSwitcher } from "carbon-components-react"
import CustomComboBox from "../../../components/CustomComboBox"
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util from "../../../util/Util"
import { AccountLedgerSelector, SubsidiaryLedgerSelector } from "../../components/basic-filter/account-ledger-selector"
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select"
import { ObjectTypeSelector } from "../../components/basic-filter/object-type-selector"
import { TextBox } from "../../components/basic-filter/text-box"

export const AccountingEntitiesReport = {
    filter: Filter,

    itemToOpenLink: item => `/journal-entry/${Util.getIdFromVoucherNumber(item.journalEntryVoucherNo)}`,

    hasPayloadBar: false,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
    }
}


const EntityTypes = [
    'Purchase (Stock Flow)',
    'Venue Sales (Stock Flow)',
    'Sales (Stock Flow)',
    'Stock Termination (Stock Flow)',
    'Stock Creation (Stock Flow)',
    'Transaction',
    'CRV',
    'Bill',
    'Purchase',
    'Service Sale',
    'Sales',
    'Payout',
    'Commission',
    'Journal Entry',
]


function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="entityDate" />
        <Divider />


        <div style={{ height: 30, marginTop: '0rem', width: 300 }}>
            <MultiSelect isMulti={false} placeholder="Select entity type..." key="accountingEntityTypes" options={EntityTypes} state={state} property="entityType" />
        </div>

        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%' }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
