import { InlineLoading } from "carbon-components-react";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import useSize from "../util/useSize";
import Util from "../util/Util";
// import InfiniteScroll = require("react-infinite-scroller");

export const InfiniteList = ({ loader, resetRequest, component, reverse, children }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    const loadingRef = useRef(true);
    const reqIdRef = useRef(Util.newTempId());
    const resetRequestFirstTime = useRef(true)

    useEffect(() => {
        if (page == -1) {
            setPage(0);
            return;
        }

        setLoading(true);
        loadingRef.current = true;
        const reqId = reqIdRef.current;

        const listener = response => {
            if (reqIdRef.current != reqId) {
                return;
            }

            if (response.status === true) {
                setItems(items => [...items, ...response.payload.items])
                setHasMore(response.payload.hasMore)
            } else {
                setError(true)
            }
            setLoading(false)
            loadingRef.current = false;

            if (response.status === true && response.payload.hasMore) {
                if (targetRef.current?.clientHeight >= (targetRef.current?.scrollHeight)) {
                    getNextItems()
                }
            }

            if (page == 0 && targetRef.current) {
                targetRef.current.scrollTop = targetRef.current.scrollHeight
            }
        };

        loader(page, listener)
    }, [page])

    const reset = () => {
        reqIdRef.current = Util.newTempId();
        setItems([])
        setPage(-1)
    }

    useEffect(() => {
        if (resetRequestFirstTime.current === true) {
            resetRequestFirstTime.current = false;
            return;
        }

        reset();
    }, [resetRequest])

    const getNextItems = () => {
        if (loadingRef.current === true) {
            return;
        }

        loadingRef.current = true;
        setPage(page + 1);
    }

    const targetRef = useRef();
    const size = useSize(targetRef)

    const noMoreMsg = (
        <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ fontSize: 12, opacity: 0.65 }}>
                {items.length > 0 ? 'No more items' : 'No items'}
            </p>
        </div>
    )

    return (
        <div ref={targetRef} style={{ width: '100%', height: '100%', flex: 1, overflow: 'auto' }}>
            {/* {reverse && noMoreMsg} */}
            {!error ? (
                <InfiniteScroll
                    loadMore={getNextItems}
                    hasMore={hasMore}
                    useWindow={false}
                    threshold={size.height - (0.80 * size.height)}
                    loader={
                        <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                            <InlineLoading style={{ width: 'unset' }} />
                        </div>
                    }
                    style={{ overflow: 'visible' }}>


                    {items.map(component)}
                    {children}


                </InfiniteScroll>
            ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>An error occurred. Please try again later.</p>
                </div>
            )}
            {!reverse && noMoreMsg}

        </div>
    )

}
