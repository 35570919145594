import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL, OBJECT_TYPE_REAL_ESTATE_PROPERTY } from '../../../constants/ObjectTypes';
import Page from '../../../base/Page';
import Api from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import TableTemplate from '../../../templates/TableTemplate';
import { REAL_ESTATE_PROPERTY_CUSTOM_LIST } from '../../../constants/Constants';

const OwnedProperties = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                embedded: true,
                hideCreateButton: true,
                objectType: OBJECT_TYPE_REAL_ESTATE_PROPERTY,
                pagePath: "/real-estate-property/",
                history: history,
                paginated: false,

                getCustomListRequest: {
                    requestType: REAL_ESTATE_PROPERTY_CUSTOM_LIST.BY_OWNER_ID,
                    params: {
                        ownerId: item.id,
                    }
                },
            })}
        </div>
    )
}


const TenantProperties = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                embedded: true,
                hideCreateButton: true,
                objectType: OBJECT_TYPE_REAL_ESTATE_PROPERTY,
                pagePath: "/real-estate-property/",
                history: history,
                paginated: false,

                getCustomListRequest: {
                    requestType: REAL_ESTATE_PROPERTY_CUSTOM_LIST.BY_TENANT_ID,
                    params: {
                        tenantId: item.id,
                    }
                },
            })}
        </div>
    )
}


class IndividualDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL, listener)
            } else {
                Api.getItem(OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL,
                    itemResult: this.state.itemResult,
                    pagePath: "/real-estate-individual/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Owner",
                            component: OwnedProperties
                        },
                        {
                            title: "Tenancy",
                            component: TenantProperties
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(IndividualDetailPage);