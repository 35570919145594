

export const TAX_TYPES = [
    { id: 0, value: "VAT 5%", perc: 5 },
    { id: 1, value: "ZR 0%", perc: 0 },
    { id: 2, value: "Exempt 0%", perc: 0 },
    { id: 3, value: "RC 0%", perc: 0 },
]

export const CUSTOMER_ENTITY_TYPE = {
    CUSTOMER: 0,
    VENUE: 1,
}