import { CenterSquare16, Add16, Subtract16 } from '@carbon/icons-react'
import { SeatIcon } from './seat-icon';

export const SeatItem = ({ grid, row, col }) => {
    const enabled = grid.isEnabled(row, col);

    if (enabled) {
        // return <SeatIcon />

        const seatType = grid.getSeatType(row, col);
        const background = seatType?.color ? `${seatType?.color}` : 'rgba(184, 115, 11, 0.27)'

        if (grid.isSeatUnavailable(row, col)) {
            return (
                // <div className="pos-seat-node-enabled" style={{ background, pointerEvents: 'none', opacity: 0.25 }}>
                // </div>
                <SeatIcon
                    color={background}
                    opacity={0.25}
                />
            )
        } else {
            return (
                <SeatIcon
                    onClick={() => grid.toggleSelection(row, col)}
                    selected={grid.isSelected(row, col)}
                    color={background}
                />
            )
            // return (
            //     <div className="seat-node-enabled" onClick={() => grid.toggleSelection(row, col)} style={{ background, border: grid.isSelected(row, col) ? '1px solid #ffffff' : undefined }}>
            //     </div>
            // )
        }
    } else {
        return (
            <div className="pos-seat-node">
                {/* <CenterSquare16 /> */}
            </div>
        )
    }
}
