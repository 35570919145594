
import { Checkbox, ComboBox, ComposedModal, ContentSwitcher, InlineLoading, ModalBody, ModalFooter, ModalHeader, Switch, TextInput } from 'carbon-components-react';
import React from 'react'
import Button from '../../components/Button';
import { printProductLabels } from '../../pdfs/product-labels/ProductLabelsPdf';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { getAndExportTemplate } from '../label-designer/export/TemplateExporter';
import { TEMPLATE_TYPE_BUNDLE_LABEL, TEMPLATE_TYPE_LABEL_GENERIC, TEMPLATE_TYPE_PRODUCT_LABEL } from '../label-designer/templates/Template';
import { getDefaultTemplate } from '../label-designer/util/TemplateDefaultFinder';

class LabelPrintViewDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,

            labelType: "Barcode only",
            displayPrice: false,
            displaySku: false,

            generatingLabel: false,
            
            templates: [],
            advanced: true,
            selectedTemplateId: undefined,

            printCount: 1
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        Api.getTemplates(response => {
            if (response.status === true) {
                const templates = response.payload.filter(item => item.type == (this.props.productBundle ? TEMPLATE_TYPE_BUNDLE_LABEL : TEMPLATE_TYPE_PRODUCT_LABEL) || item.type == TEMPLATE_TYPE_LABEL_GENERIC); 

                this.setState({ 
                    loading: false,
                    templates,
                    selectedTemplateId: getDefaultTemplate(templates, this.props.productBundle ? TEMPLATE_TYPE_BUNDLE_LABEL : TEMPLATE_TYPE_PRODUCT_LABEL, TEMPLATE_TYPE_LABEL_GENERIC).id
                })
            } else {
                UIUtil.showError(response.message);
                this.props.onClose();
            }
        })
    }

    printLabel() {
        const selectedProducts = [this.props.itemId];
        if (this.state.advanced) {
            this.setState({ generatingLabel: true })
            getAndExportTemplate(this.state.selectedTemplateId, selectedProducts.map(id => ({ 
                itemId: id, 
                _templateCopyCount: this.state.printCount,
                forceBarcode: this.props.forceBarcode ? this.props.forceBarcode : undefined
            })), { loadProducts: !this.props.productBundle, loadBundles: this.props.productBundle })
            .then(() => {
                UIUtil.showSuccess()
                this.setState({ generatingLabel: false })
            })
            .catch(() => {
                UIUtil.showError();
                this.setState({ generatingLabel: false })
            })
        } else {
            this.setState({ generatingLabel: true })
            const apiMethod = (this.props.productBundle ? Api.getBundleBarcodes : Api.getProductBarcodes).bind(Api);
            apiMethod(selectedProducts, response => {
                if (response.status === true) {
                    for (const product of response.payload) {
                        product.quantity = this.state.printCount;
                        if (this.props.forceBarcode) {
                            product.forceBarcode = this.props.forceBarcode
                        }
                    }

                    printProductLabels(this.state.labelType, {
                        displayPrice: this.state.displayPrice,
                        displaySku: this.state.displaySku
                    }, response.payload)
                    .then(() => {
                        this.setState({ generatingLabel: false })
                    })
                    .catch(() => {
                        UIUtil.showError();
                        this.setState({ generatingLabel: false })
                    })
                } else {
                    UIUtil.showError();
                    this.setState({ generatingLabel: false })
                }
            })
        }
    }

    renderContent() {
        return (
            <div>
                <ContentSwitcher style={{marginBottom: '1rem'}} 
                selectedIndex={this.state.advanced ? 1 : 0} onChange={({index}) => {
                    this.setState({ advanced: index == 1 })
                }}>
                    <Switch text="Basic" />
                    <Switch text="Advanced" />
                </ContentSwitcher>

                {this.state.advanced && this.state.templates.filter(item => this.state.selectedTemplateId == item.id)[0] ? (
                    <ComboBox
                    light
                    titleText="Template"
                    items={this.state.templates}
                    value={this.state.templates.filter(item => this.state.selectedTemplateId == item.id)[0].title}
                    //value={this.state.templates.filter(item => this.state.selectedTemplateId == item.id)[0].title}
                    itemToString={item => item ? item.title : ''}
                    onChange={e => this.setState({selectedTemplateId: e.selectedItem ? e.selectedItem.id : getDefaultTemplate(this.state.templates, TEMPLATE_TYPE_PRODUCT_LABEL, TEMPLATE_TYPE_LABEL_GENERIC).id})}
                    />
                ) : (<>
                    {!this.props.productBundle && <ComboBox 
                    light
                    titleText="Type"
                    items={["Barcode only", "QR Code only", "Barcode and QR Code"]}
                    value={this.state.labelType}
                    onChange={e => this.setState({labelType: e.selectedItem})}
                    />}

                    <div style={{marginTop: !this.props.productBundle ? '1rem' : 0, display: 'flex', gap: '1rem', alignItems: 'center', flexWrap: 'wrap'}}>
                        <div onClick={() => this.setState(prevState => ({displayPrice: !prevState.displayPrice}))}>
                            <Checkbox labelText="Display price" checked={this.state.displayPrice}  />
                        </div>
                        {!this.props.productBundle && <div onClick={() => this.setState(prevState => ({displaySku: !prevState.displaySku}))}>
                            <Checkbox labelText="Display reference number" checked={this.state.displaySku}  />
                        </div>}
                    </div>
                </>)}

                <div style={{height: '1rem'}} />
                <TextInput 
                value={this.state.printCount}
                onChange={e => {
                    if (!isNaN(e.target.value)) {
                        if (e.target.value.trim && e.target.value.trim() != "") {
                            this.setState({ printCount: e.target.value })
                        } else {
                            this.setState({ printCount: 0 })
                        }
                    }
                }}
                labelText="Print count" light style={{width: '100%'}} />
            </div>
        )
    }

    render() {
        return (
            <ComposedModal size="sm" open onClose={this.props.onClose} className="modal-that-supports-combo-box">
                <ModalHeader label="Label" title="Print Label" />
                <ModalBody //style={{paddingRight: '1rem'}}
                >
                    {this.state.loading ?
                    (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <InlineLoading style={{width: 'auto'}} />
                        </div>
                    ) : (
                        this.renderContent()
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button kind="secondary" onClick={this.props.onClose}>
                        Close
                    </Button>
                    <Button
                    loading={this.state.generatingLabel}
                    onClick={() => this.printLabel()}>
                        Print
                    </Button>
                </ModalFooter>
            </ComposedModal>
        )
    }

}

export default LabelPrintViewDialog;