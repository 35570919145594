import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#ffb6a8',
  },
});

const getTotalUnitTransferred = (stockFlow) => {
  let total = 0;
  for (const item of stockFlow.items) {
    total += item.quantityValue;
  }
  return total;
}

const InvoiceItemsTable = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow items={invoice.items} />
    {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} /> */}
    <InvoiceTableFooter title="Total Unit Transferred" value={getTotalUnitTransferred(invoice.stockFlow)} />
    {/* <InvoiceTableFooter title="Subtotal" value={invoice.stockFlow.quotationAmount.toFixed(2)} /> */}
    {/* <InvoiceTableFooter title="Grand Total" value={invoice.stockFlow.quotationAmount.toFixed(2)} /> */}
  </View>
);

export default InvoiceItemsTable