import { Tag } from "carbon-components-react"
import { INVOICE_BILL_TYPE, INVOICE_BILL_TYPES } from "../.."
import { UploadField } from "../../../../../components/upload/upload-field"
import { FormSection, FormSectionWithoutMargin } from "../../../../../templates/form/form"
import Util from "../../../../../util/Util"
import { ShipmentMasterBillsSelector } from "../components/shipment-master-bills-selector"
import { PackageInspectionListEditor } from "./package-inspection-list-editor"
import { LoadingModeFilteredPackageListEditor, PackageListEditor } from "./package-list-editor"
import { CheckmarkFilled32, Hourglass16, CheckmarkFilled16 } from '@carbon/icons-react'
import { Bills } from "./bills"
import { OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_SHIPMENT_CONSIGNEE, OBJECT_TYPE_SHIPMENT_DELIVERY_AGENT, OBJECT_TYPE_SHIPMENT_PORT } from "../../../../../constants/ObjectTypes"
import { ShipmentMasterSelector } from "../components/shipment-master-selector"
import { ExtendableComboBox, NonStateExtendableComboBox, SharedState } from "../../../../../components/extendable-combo-box"
import { PackageLoadingUnloadingListEditor } from "./package-loading-unloading"
import { hasCapabilitySupport } from "../../../../../app/Capabilities"


export const importFlow = (endpoint) => [
    {
        id: "Created",
        title: "Created",
        card: ({ form }) => (<>
            <FormSectionWithoutMargin title="Job Info">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    {/* <div style={{ flex: 1 }}>
                        <form.ComboBoxField fieldKey="consignorId" title="Consignor" options={endpoint.customers} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.ComboBoxField fieldKey="consigneeId" title="Consginee" options={endpoint.consignees} />
                    </div> */}
                    {/* <ExtendableComboBox setItemId={id => form.setField("consignorId", id)} allItems={endpoint.customers} objectType={OBJECT_TYPE_CUSTOMER} filterProperties={["fullName", "nationalId", "email", "phoneNumber", "fax", "address", "poBox", "website"]} itemToString={item => item.fullName}>
                        {list => <form.ComboBoxField fieldKey="consignorId" title="Consignor" options={list} />}
                    </ExtendableComboBox> */}
                    <div style={{ flex: 1 }}>
                        <SharedState>
                            {([addedItems, setAddedItems]) => (<>
                                <NonStateExtendableComboBox setItemId={id => form.setField("invoicedCustomerId", id)} allItems={endpoint.customers} objectType={OBJECT_TYPE_CUSTOMER} filterProperties={["fullName", "nationalId", "email", "phoneNumber", "fax", "address", "poBox", "website"]} itemToString={item => item.fullName}  {...{ addedItems, setAddedItems }}>
                                    {list => <form.ComboBoxField fieldKey="invoicedCustomerId" title="Customer" options={list} />}
                                </NonStateExtendableComboBox>

                                <NonStateExtendableComboBox setItemId={id => form.setField("consignorId", id)} allItems={endpoint.customers} objectType={OBJECT_TYPE_CUSTOMER} filterProperties={["fullName", "nationalId", "email", "phoneNumber", "fax", "address", "poBox", "website"]} itemToString={item => item.fullName}  {...{ addedItems, setAddedItems }}>
                                    {list => <form.ComboBoxField fieldKey="consignorId" title="Consignor" options={list} />}
                                </NonStateExtendableComboBox>
                            </>)}
                        </SharedState>
                    </div>
                    <div style={{ flex: 1 }}>
                        <ExtendableComboBox setItemId={id => form.setField("consigneeId", id)} allItems={endpoint.consignees} objectType={OBJECT_TYPE_SHIPMENT_CONSIGNEE} filterProperties={["name", "email", "nationalId", "phoneNumber", "faxNumber", "address", "poBox", "website"]} itemToString={item => item.name}>
                            {list => <form.ComboBoxField fieldKey="consigneeId" title="Consginee" options={list} />}
                        </ExtendableComboBox>
                    </div>
                </div>
            </FormSectionWithoutMargin>

            <FormSection title="Customer Docs">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    {/* <div style={{ flex: 1 }}>
                            <form.CustomField fieldKey="customerInvoice" childrenProps={{ label: "Customer Invoice" }}>
                                {UploadField}
                            </form.CustomField>
                        </div> */}
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="importCustomerBl" childrenProps={{ label: "Customer BL" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="importCommercialInvoice" childrenProps={{ label: "Commercial Invoice" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="customerPackingList" childrenProps={{ label: "Packing List" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="customerDeliveryNote" childrenProps={{ label: "Delivery Note" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                </div>
            </FormSection>

            <FormSection title="Packages">
                <form.CustomField fieldKey="packages" childrenProps={{ itemProps: { packageTypes: endpoint.packageTypes, itemTypes: endpoint.itemTypes, customers: endpoint.customers, consignees: endpoint.consignees, importFlow: true } }}>
                    {PackageListEditor}
                </form.CustomField>
            </FormSection>
        </>)
    },
    {
        id: "Customs",
        title: "Bill of Entry",
        card: ({ form }) => (<>
            <FormSection title="Import Bill of Entry">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="billOfEntryNo" title="Bill No" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.DateField fieldKey="billOfEntryDate" title="Bill Date" />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1rem', }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="billOfEntryAmount" title="Bill Amount" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="billOfEntryDocument" childrenProps={{ label: "Document" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                </div>
                <form.CheckBoxField title="Invoice amount to customer?" fieldKey="invoiceBillOfEntryAmount" />
            </FormSection>

            <FormSection title="Additional Information">
                <form.TextAreaField fieldKey="customsNote" title="Bill of Entry Note" />
            </FormSection>
        </>)
    },
    {
        id: "Unloading",
        title: "Customs/Unloading",
        card: ({ shipment, form }) => (<>
            <FormSection title="Customer Charges">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="portHandlingCharge" title="Port Handling Charge" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="originalDocsCharge" title="Original/Non Docs Charge" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="documentProcessingCharge" title="Document Processing Charge" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="loadingCharge" title="Loading (Crane/Forklift) Charge" />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="dutyDepositCharge" title="Duty Deposit Charge" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="gatePassCharge" title="Gate Pass Charge" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="inspectionCharge" title="Inspection Charge" />
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                </div>
            </FormSection>

            {/* <FormSection title="Shipment Specific Bills">
                <form.CustomField fieldKey="bills" childrenProps={{ itemProps: { vendors: endpoint.vendors } }}>
                    {Bills}
                </form.CustomField>
            </FormSection> */}


            <FormSection title="Packages">
                <form.CustomField fieldKey="packages" childrenProps={{ itemProps: { isUnloading: true } }}>
                    {PackageLoadingUnloadingListEditor}
                </form.CustomField>

                {/* {shipment.packages.map(itemPackage => (<>
                    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                        <h6 style={{ marginBottom: '0.25rem' }}>Package: {itemPackage.id}</h6>

                        {itemPackage.portLoadingConfirmed ? <>
                            <Tag type="green" renderIcon={CheckmarkFilled16}>Confirmed by {itemPackage.portLoadingConfirmedName}</Tag>
                            <label className="bx--label">{Util.getDate(itemPackage.portLoadingConfirmedDate)}</label>
                        </> : <>
                            <Tag renderIcon={Hourglass16}>Pending confirmation</Tag>
                        </>}

                        <div style={{ width: '100%', marginBottom: '1rem', paddingLeft: '6rem' }}>
                            {itemPackage?.items.map(itemPackageItem => (<>
                                <div style={{ marginTop: '1rem' }}>
                                    <h6 style={{ marginBottom: '0.25rem' }}>Item: {itemPackageItem.id}</h6>

                                    {itemPackageItem.portLoadingConfirmed ? <>
                                        <Tag type="green" renderIcon={CheckmarkFilled16}>Confirmed by {itemPackageItem.portLoadingConfirmedName}</Tag>
                                        <label className="bx--label">{Util.getDate(itemPackageItem.portLoadingConfirmedDate)}</label>
                                    </> : <>
                                        <Tag renderIcon={Hourglass16}>Pending confirmation</Tag>
                                    </>}
                                </div>
                            </>))}
                        </div>
                    </div>
                </>))} */}
            </FormSection>

            <FormSection title="Additional Information">
                <form.TextAreaField fieldKey="unloadingNote" title="Customs/Unloading Note" />
            </FormSection>
        </>)
    },
    {
        id: "Transportation",
        title: "Transportation",
        card: ({ form }) => (<>
            <FormSection title="Transportation Flow">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.ComboBoxField fieldKey="transporterCompanyId" title="Transporter Company" options={endpoint.vendors} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="transporterCompanyDocument" childrenProps={{ label: "Transportation Document" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.ComboBoxField fieldKey="originPort" title="Origin Port" options={endpoint.ports} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.SwitchField fieldKey="destinationType" title="Destination Type" options={[{ id: 0, title: "Port" }, { id: 1, title: "Other" }]} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.IfTargetGroup targetKey="destinationType" checkTargetIf={value => value === 0}>
                            <form.ComboBoxField fieldKey="destinationPort" title="Destination Port" options={endpoint.ports} />
                        </form.IfTargetGroup>
                        <form.IfTargetGroup targetKey="destinationType" checkTargetIf={value => value === 1}>
                            <form.TextField fieldKey="destinationName" title="Destination" />
                        </form.IfTargetGroup>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="transportationCharge" title="Transportation Bill" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="invoiceableTransportationCharge" title="Invoiceable Amount" />
                    </div>
                </div>
            </FormSection>

            <FormSection title="Additional Information">
                <form.TextAreaField fieldKey="transportationNote" title="Transportation Note" />
            </FormSection>
        </>)
    },
    {
        id: "Done",
        title: "Done",
        card: ({ form }) => (<>
            <div style={{ width: '100%', height: 400, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                <CheckmarkFilled32 />
                <h4 style={{ marginBottom: '1rem' }}>Finalized!</h4>

                <form.TextAreaField fieldKey="finalNotes" title="Final Notes" />
            </div>
        </>)
    },
]

export const exportFlow = (endpoint) => [
    {
        id: "Created",
        title: "Created",
        card: ({ form }) => (<>
            <FormSection title="Shipment Info">
                {/* <form.SwitchField fieldKey="loadingMode" title="Loading Mode" options={[{ id: 0, title: "LCL" }, { id: 1, title: "FCL" }]} /> */}
                <form.ComboBoxField fieldKey="loadingModeId" title="Loading Mode" options={endpoint.loadingModes} />
            </FormSection>

            <FormSection title="Job Info">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        {/* <form.ComboBoxField fieldKey="consignorId" title="Consignor" options={endpoint.customers} /> */}
                        <SharedState>
                            {([addedItems, setAddedItems]) => (<>
                                <NonStateExtendableComboBox setItemId={id => form.setField("invoicedCustomerId", id)} allItems={endpoint.customers} objectType={OBJECT_TYPE_CUSTOMER} filterProperties={["fullName", "nationalId", "email", "phoneNumber", "fax", "address", "poBox", "website"]} itemToString={item => item.fullName}  {...{ addedItems, setAddedItems }}>
                                    {list => <form.ComboBoxField fieldKey="invoicedCustomerId" title="Customer" options={list} />}
                                </NonStateExtendableComboBox>

                                <NonStateExtendableComboBox setItemId={id => form.setField("consignorId", id)} allItems={endpoint.customers} objectType={OBJECT_TYPE_CUSTOMER} filterProperties={["fullName", "nationalId", "email", "phoneNumber", "fax", "address", "poBox", "website"]} itemToString={item => item.fullName}  {...{ addedItems, setAddedItems }}>
                                    {list => <form.ComboBoxField fieldKey="consignorId" title="Consignor" options={list} />}
                                </NonStateExtendableComboBox>
                            </>)}
                        </SharedState>

                        <form.TextField fieldKey="consignorNote" title="Consignor Note" />


                        {/* <ExtendableComboBox setItemId={id => form.setField("consignorId", id)} allItems={endpoint.customers} objectType={OBJECT_TYPE_CUSTOMER} filterProperties={["fullName", "nationalId", "email", "phoneNumber", "fax", "address", "poBox", "website"]} itemToString={item => item.fullName}>
                            {list => (<>
                                <form.ComboBoxField fieldKey="invoicedCustomerId" title="Customer" options={list} />
                                <form.ComboBoxField fieldKey="consignorId" title="Consignor" options={list} />
                            </>)}
                        </ExtendableComboBox> */}
                    </div>
                    <div style={{ flex: 1 }}>
                        {/* <form.ComboBoxField fieldKey="consigneeId" title="Consginee" options={endpoint.consignees} /> */}
                        <ExtendableComboBox setItemId={id => form.setField("consigneeId", id)} allItems={endpoint.consignees} objectType={OBJECT_TYPE_SHIPMENT_CONSIGNEE} filterProperties={["name", "email", "nationalId", "phoneNumber", "faxNumber", "address", "poBox", "website"]} itemToString={item => item.name}>
                            {list => <form.ComboBoxField fieldKey="consigneeId" title="Consginee" options={list} />}
                        </ExtendableComboBox>
                        <form.TextField fieldKey="consigneeNote" title="Consginee Note" />
                    </div>

                </div>
            </FormSection>

            {hasCapabilitySupport("cargo") && <FormSection title="Inventory">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.DateField fieldKey="inventoryRemovalDate" title="Inventory Release Date" />
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                </div>
            </FormSection>}

            <FormSection title="Customer Docs">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="customerInvoice" childrenProps={{ label: "Customer Invoice" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="customerPackingList" childrenProps={{ label: "Packing List" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="customerDeliveryNote" childrenProps={{ label: "Delivery Note" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                </div>
            </FormSection>

            <FormSection title="Packages">
                <form.ExtField childrenProps={{ allPackageTypes: endpoint.packageTypes, itemTypes: endpoint.itemTypes, customers: endpoint.customers, consignees: endpoint.consignees, exportFlow: true }}>
                    {LoadingModeFilteredPackageListEditor}
                </form.ExtField>
            </FormSection>
        </>)
    },
    {
        id: "Inspecting",
        title: "Inspecting",
        card: ({ form }) => (<>
            <FormSection title="Confirm Package Inspections">
                <form.CustomField fieldKey="packages" childrenProps={{ itemProps: { packageTypes: endpoint.packageTypes } }}>
                    {PackageInspectionListEditor}
                </form.CustomField>
            </FormSection>

        </>)
    },
    {
        id: "Customs",
        title: "Bill of Entry",
        card: ({ form }) => (<>
            <FormSection title="Export Bill of Entry">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="billOfEntryNo" title="Bill No" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.DateField fieldKey="billOfEntryDate" title="Bill Date" />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1rem', }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="billOfEntryAmount" title="Bill Amount" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CustomField fieldKey="billOfEntryDocument" childrenProps={{ label: "Document" }}>
                            {UploadField}
                        </form.CustomField>
                    </div>
                </div>
                <form.CheckBoxField title="Invoice amount to customer?" fieldKey="invoiceBillOfEntryAmount" />
            </FormSection>

            <FormSection title="Additional Information">
                <form.TextAreaField fieldKey="customsNote" title="Bill of Entry Note" />
            </FormSection>
        </>)
    },
    {
        id: "Loading",
        title: "Customs/Loading",
        card: ({ shipment, form }) => (<>
            <FormSection title="Loading Shipment">
                <form.ExtField childrenProps={{ options: endpoint.shipmentMasters }}>
                    {ShipmentMasterSelector}
                </form.ExtField>

                {/* <form.IfTargetGroup targetKey="shipmentMasterId" checkTargetIf={value => Util.isNumberExist(value)}>
                    <form.CheckBoxField title="Invoice bills to customer?" fieldKey="invoiceShipmentMasterBill" />
                    <form.IfTargetGroup targetKey="invoiceShipmentMasterBill">

                        <form.TextField title="Invoiceable percentage" fieldKey="masterBillInvoicePercentage" />
                        <form.CheckBoxField title="Equally divide amount between all customers" fieldKey="masterBillInvoiceAmountShouldDivide" />

                        <form.SwitchField fieldKey="masterBillInvoicingType" options={INVOICE_BILL_TYPES} />

                        <form.IfTargetGroup targetKey="masterBillInvoicingType" checkTargetIf={value => value !== INVOICE_BILL_TYPE.ALL}>

                            <form.ExtField childrenProps={{ masters: endpoint.shipmentMasters }}>
                                {ShipmentMasterBillsSelector}
                            </form.ExtField>
                        </form.IfTargetGroup>
                    </form.IfTargetGroup>
                </form.IfTargetGroup> */}
            </FormSection>

            {/* <FormSection title="Shipment Specific Bills">
                <form.CustomField fieldKey="bills" childrenProps={{ itemProps: { vendors: endpoint.vendors } }}>
                    {Bills}
                </form.CustomField>
            </FormSection> */}


            <FormSection title="Packages">
                <form.CustomField fieldKey="packages" childrenProps={{ itemProps: { isUnloading: false } }}>
                    {PackageLoadingUnloadingListEditor}
                </form.CustomField>
                {/* {shipment.packages.map(itemPackage => (<>
                    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                        <h6 style={{ marginBottom: '0.25rem' }}>Package: {itemPackage.id}</h6>

                        {itemPackage.portLoadingConfirmed ? <>
                            <Tag type="green" renderIcon={CheckmarkFilled16}>Confirmed by {itemPackage.portLoadingConfirmedName}</Tag>
                            <label className="bx--label">{Util.getDate(itemPackage.portLoadingConfirmedDate)}</label>
                        </> : <>
                            <Tag renderIcon={Hourglass16}>Pending confirmation</Tag>
                        </>}

                        <div style={{ width: '100%', marginBottom: '1rem', paddingLeft: '6rem' }}>
                            {itemPackage?.items.map(itemPackageItem => (<>
                                <div style={{ marginTop: '1rem' }}>
                                    <h6 style={{ marginBottom: '0.25rem' }}>Item: {itemPackageItem.id}</h6>

                                    {itemPackageItem.portLoadingConfirmed ? <>
                                        <Tag type="green" renderIcon={CheckmarkFilled16}>Confirmed by {itemPackageItem.portLoadingConfirmedName}</Tag>
                                        <label className="bx--label">{Util.getDate(itemPackageItem.portLoadingConfirmedDate)}</label>
                                    </> : <>
                                        <Tag renderIcon={Hourglass16}>Pending confirmation</Tag>
                                    </>}
                                </div>
                            </>))}
                        </div>
                    </div>
                </>))} */}
            </FormSection>

            <FormSection title="Additional Information">
                <form.TextAreaField fieldKey="loadingNote" title="Customs/Loading Note" />
            </FormSection>
        </>)
    },
    {
        id: "Finalization",
        title: "Finalization",
        card: ({ form }) => (<>

            <FormSection title="Bill of Lading">
                {/* <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="billOfLadingNo" title="Bill of Lading No" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="noOfOrigBlSigned" title="No of Orig BL Signed" />
                    </div>
                </div> */}
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.ComboBoxField fieldKey="blStatus" title="Bill of Lading Status" options={[
                            { id: "NON-NEGOTIABLE", value: "NON-NEGOTIABLE" },
                            { id: "ORIGINAL", value: "ORIGINAL" },
                            { id: "SURRENDERED", value: "SURRENDERED" },
                        ]} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="placeOfBlIssue" title="Place of BL Issue" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.DateField fieldKey="dateOfBlIssue" title="Date of BL Issue" />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.CheckBoxField title="Freight And Charges" fieldKey="blFreightAndCharges" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CheckBoxField title="Rated As" fieldKey="blRatedAs" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CheckBoxField title="Rate" fieldKey="blRate" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CheckBoxField title="Per" fieldKey="blPer" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CheckBoxField title="Prepaid" fieldKey="blPrepaid" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.CheckBoxField title="Collect" fieldKey="blCollect" />
                    </div>
                </div>
            </FormSection>
            {/* 
            <FormSection title="Additional Shipment Info">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="pierOrPlaceOfReceipt" title="Pier or Place of Receipt" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="preCarriageBy" title="Pre Carriage By" />
                    </div>
                </div>
            </FormSection> */}

            <div style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <FormSection title={<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        Notify Party
                        <div style={{ marginBottom: '-1rem' }}>
                            <form.CheckBoxField title="Same as consignee?" fieldKey="notifyPartySameAsConsignee" />
                        </div>
                    </div>}>

                        <form.NotIfTargetGroup targetKey="notifyPartySameAsConsignee">
                            <form.TextField fieldKey="notifyPartyName" title="Name" />
                            <form.TextField fieldKey="notifyPartyAddress" title="Address" />
                            <form.TextField fieldKey="notifyPartyPoBox" title="P.O. Box" />
                            <form.TextField fieldKey="notifyPartyNationalId" title="National ID" />
                            <form.TextField fieldKey="notifyPartyTel" title="Tel" />
                            <form.TextField fieldKey="notifyPartyFax" title="Fax" />
                            <form.TextField fieldKey="notifyPartyEmail" title="Email" />
                            <form.TextField fieldKey="notifyPartyWebsite" title="Website" />
                            <form.TextField fieldKey="notifyPartyNote" title="Note" />
                        </form.NotIfTargetGroup>
                    </FormSection>
                </div>
                <div style={{ flex: 1 }}>
                    <FormSection title={<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        Delivery Agent
                    </div>}>
                        {/* <form.TextField fieldKey="deliveryAgentName" title="Name" />
                        <form.TextField fieldKey="deliveryAgentAddress" title="Address" />
                        <form.TextField fieldKey="deliveryAgentPoBox" title="P.O. Box" />
                        <form.TextField fieldKey="deliveryAgentNationalId" title="National ID" />
                        <form.TextField fieldKey="deliveryAgentTel" title="Tel" />
                        <form.TextField fieldKey="deliveryAgentFax" title="Fax" />
                        <form.TextField fieldKey="deliveryAgentEmail" title="Email" />
                        <form.TextField fieldKey="deliveryAgentWebsite" title="Website" /> */}
                        <ExtendableComboBox setItemId={id => form.setField("deliveryAgentId", id)} allItems={endpoint.deliveryAgents} objectType={OBJECT_TYPE_SHIPMENT_DELIVERY_AGENT} filterProperties={["name", "email", "nationalId", "phoneNumber", "faxNumber", "address", "poBox", "website"]} itemToString={item => item.name}>
                            {list => <form.ComboBoxField fieldKey="deliveryAgentId" title="Delivery Agent" options={list} />}
                        </ExtendableComboBox>
                        <form.TextField fieldKey="deliveryAgentNote" title="Note" />
                    </FormSection>
                </div>
            </div>

            <FormSection title="Delivery">
                <div style={{ display: 'flex', gap: '1rem' }}>
                    {/* <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="placeOfDelivery" title="Place of Delivery" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="typeOfMove" title="Type of Move" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="containerised" title="Containerised" />
                    </div> */}
                    <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="finalDestination" title="Final Destination" />
                    </div>
                </div>
            </FormSection>
        </>)
    },
    {
        id: "Done",
        title: "Done",
        card: ({ form }) => (<>
            <div style={{ width: '100%', height: 400, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                <CheckmarkFilled32 />
                <h4 style={{ marginBottom: '1rem' }}>Finalized!</h4>

                <form.TextAreaField fieldKey="finalNotes" title="Final Notes" />
            </div>
        </>)
    }
]