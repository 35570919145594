import React from "react"
import { withLoadablePage } from "../../base/Page"
import Api from "../../session/Api"

import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16,
    AirlinePassengerCare32,
    User32,
    Education32
} from '@carbon/icons-react'
import { getObjectTypeName, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STUDENT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_V2_CUSTOMER } from "../../constants/ObjectTypes"
import { ComboBox, RadioTile, TileGroup } from "carbon-components-react"
import Util, { isV2 } from "../../util/Util"
import Button from "../../components/Button"

import { subDays, addDays } from 'date-fns';
//import { DateRangePicker, Calendar } from 'react-date-range';
// import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePicker from "../../components/date/DateRangePicker"
import { printStatementOfAccount } from "../../pdfs/statements-of-account/StatementsOfAccountPdf"
import { hasCapabilitySupport } from "../../app/Capabilities"


const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        {/* <p style={{marginTop: '0.5rem', fontSize: 12, opacity: 0.65}}>
            {desc}
        </p>  */}
    </div>
)






class StatementOfAccountsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            selectedAccount: undefined,

            accountTypeValue: isV2() ? OBJECT_TYPE_V2_CUSTOMER : OBJECT_TYPE_CUSTOMER,
            accountIdValue: 0,
            accountPickerKey: Util.newTempId(),

            suppliers: props.payload.suppliers,
            customers: props.payload.customers,
            students: props.payload.students,
            staff: props.payload.staff,
            v2customers: props.payload.v2Customers,

            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),

            loading: false,
        }
    }

    canGenerateReport() {
        return this.state.accountIdValue > 0 && Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);
    }

    onGenerateBtn() {
        //this.props.history.push(`/subsidiary-ledger-report/${this.state.selectedAccount.id}/${this.state.accountIdValue}/${this.state.accountTypeValue}`)
        this.setState({ loading: true })
        printStatementOfAccount(
            this.state.accountIdValue, this.state.accountTypeValue,
            this.state.startDateValue, this.state.endDateValue,
            () => this.setState({ loading: false })
        )
    }

    getAccountTypeName() {
        return getObjectTypeName(parseInt(this.state.accountTypeValue));
    }

    getAccountList() {
        switch (this.state.accountTypeValue) {
            case OBJECT_TYPE_CUSTOMER:
                return this.state.customers;
            case OBJECT_TYPE_SUPPLIER:
                return this.state.suppliers;
            case OBJECT_TYPE_ACCOUNT + "":
                return this.state.staff;
            case OBJECT_TYPE_STUDENT:
                return this.state.students;
            case OBJECT_TYPE_V2_CUSTOMER:
                return this.state.v2customers;
        }
    }

    render() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>Statement of Account</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title="Period">
                        <DateRangePicker
                            //onChange={item => setState([item.selection])}
                            onChange={item => this.setState({ startDateValue: item.selection.startDate.getTime(), endDateValue: item.selection.endDate.getTime() })}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[{
                                startDate: new Date(this.state.startDateValue),
                                endDate: new Date(this.state.endDateValue),
                                key: 'selection'
                            }]}
                            direction="horizontal"
                        />
                    </Section>

                    <Section icon={Number_232} title="Subsidiary Account">
                        {!isV2() && <>
                            <TileGroup className="horizontal-tile-radio" valueSelected={this.state.accountTypeValue} onChange={value => this.setState({ accountTypeValue: value, accountIdValue: 0, accountPickerKey: Util.newTempId() })}>
                                <RadioTile value={OBJECT_TYPE_CUSTOMER}>
                                    <RadioItem icon={AirlinePassengerCare32} title="Customer" desc="accounts" />
                                </RadioTile>
                                <RadioTile value={OBJECT_TYPE_SUPPLIER}>
                                    <RadioItem icon={Delivery32} title="Supplier" desc="accounts" />
                                </RadioTile>
                                {hasCapabilitySupport("lms") &&
                                    <RadioTile value={OBJECT_TYPE_STUDENT}>
                                        <RadioItem icon={Education32} title="Student" desc="accounts" />
                                    </RadioTile>}
                            </TileGroup>

                            <div style={{ height: '1rem' }} />
                        </>}

                        <ComboBox
                            key={this.state.accountPickerKey}
                            titleText={this.getAccountTypeName()}
                            items={this.getAccountList()}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.getAccountList().filter(item => item.id == this.state.accountIdValue)[0]}
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ accountIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, accountPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ accountIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }} />
                    </Section>

                    <Section icon={Number_332} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button loading={this.state.loading} onClick={this.onGenerateBtn.bind(this)} disabled={!this.canGenerateReport()} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

}

export default withLoadablePage(Api.getStockFlowEndpointsList.bind(Api), StatementOfAccountsPage)