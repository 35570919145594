
import { AccordionItem, Accordion, Toggle, Tile, TileGroup, RadioTile, SelectableTile } from 'carbon-components-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_MOBILE_UNIT, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_VENUE, SOURCE_TYPE_WAREHOUSE, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants'
import { OBJECT_TYPE_CONTACT, OBJECT_TYPE_PACKAGE_UNIT } from '../../../constants/ObjectTypes'
import Api from '../../../session/Api'
import ItemCreatorDialog from '../../../templates/ItemCreatorDialog'
import TableTemplate from '../../../templates/TableTemplate'
import UIUtil from '../../../util/UIUtil'
import { Warning16 } from '@carbon/icons-react';
import { isSettingsEnabled } from '../SettingsViews'
import { hasCapabilitySupport } from '../../../app/Capabilities'

import {
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    Van32,
    CheckmarkFilled16,
    Building32,
    Scale32
} from '@carbon/icons-react'
import Button from '../../../components/Button'
import { isSqlAvgEngine, isStockEntityFifoEngine } from '../../../stock-system/stock-engine'
import { makeSettingComponent } from '..'

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

const NegativeStockView = ({ }) => {
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    const [originalNegativeAllowances, setOriginalNegativeAllowances] = useState([])
    const [negativeAllowances, setNegativeAllowances] = useState([])

    const [loadingUpdate, setLoadingUpdate] = useState(false)

    const onUpdate = () => {
        setLoadingUpdate(true)
        Api.updateNegativeStockSetting(negativeAllowances, response => {
            if (response.status === true) {
                setOriginalNegativeAllowances(negativeAllowances)
                UIUtil.showSuccess()
            } else {
                UIUtil.showError(response.message)
            }
            setLoadingUpdate(false)
        })
    }

    const isSourceSelected = sourceType => {
        for (const allowance of negativeAllowances) {
            if (allowance.sourceType == sourceType && allowance.enabled === true) {
                return true;
            }
        }
        return false;
    }
    const isOriginalSourceSelected = sourceType => {
        for (const allowance of originalNegativeAllowances) {
            if (allowance.sourceType == sourceType && allowance.enabled === true) {
                return true;
            }
        }
        return false;
    }

    const canSave = useMemo(() =>
        isSourceSelected(SOURCE_TYPE_WAREHOUSE) != isOriginalSourceSelected(SOURCE_TYPE_WAREHOUSE) ||
        isSourceSelected(SOURCE_TYPE_STORE) != isOriginalSourceSelected(SOURCE_TYPE_STORE) ||
        isSourceSelected(SOURCE_TYPE_VENUE) != isOriginalSourceSelected(SOURCE_TYPE_VENUE) ||
        isSourceSelected(SOURCE_TYPE_MOBILE_UNIT) != isOriginalSourceSelected(SOURCE_TYPE_MOBILE_UNIT)
        , [originalNegativeAllowances, negativeAllowances])


    const setSourceSelected = (sourceType, selected) => {
        console.log(isSourceSelected(sourceType) + ' ' + selected + ' ' + JSON.stringify(negativeAllowances))
        if (selected) {
            setNegativeAllowances(prev => [...prev, { sourceType, enabled: true }])
        } else {
            setNegativeAllowances(prev => [...prev.filter(item => item.sourceType != sourceType), { sourceType, enabled: false }])
            //setNegativeAllowances(prev => prev.filter(item => item.sourceType != sourceType))
        }
    }

    useEffect(() => {
        setLoading(true)
        Api.getNegativeStockSetting(response => {
            if (response.status === true) {
                setActive(response.payload.enabled)
                setNegativeAllowances(response.payload.negativeAllowances)
                setOriginalNegativeAllowances(response.payload.negativeAllowances)
                setLoading(false)
            } else {
                setInError(true)
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const onActiveToggle = enabled => {
        const currentActive = active;
        setActive(enabled)
        setLoading(true)
        Api.setSettingEnable('allow_negative_stock', enabled, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setActive(currentActive)
            }
            setLoading(false)
        })
    }

    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();
    const tableRef = useRef()


    return (
        <div>
            <Tile style={{ marginBottom: '1rem', width: '100%', height: 'unset' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Warning16 style={{ marginRight: '0.5rem' }} />
                    <h6>Important Note</h6>
                </div>
                <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                    {isStockEntityFifoEngine() && "If this feature is enabled, whenever a sale occurs and stock does not exist, the system will allow the sale to proceed. However, the sales journal entry will not be generated until the stock can be fulfilled (via creating a purchase stock flow after the sale). The FIFO method will be used to calculate the COGS of the sales after the purchase stock flow is created and the sales can be fulfilled with the new stock. Before the purchase occurs, the system will try to estimate the COGS of the sale. It first checks the average cost of the product. If an average cost does not exist, then the system will use the product's default cost."}
                    {isSqlAvgEngine() && "If this feature is enabled, whenever a sale occurs and stock does not exist, the system will allow the sale to proceed. However, the sales journal entry may not always have accurate COGS calculations. Purchase(s) must be created with a date before the sales to ensure proper calculations."}
                </p>
            </Tile>

            <label className="bx--label">Active</label>
            <Toggle
                checked={active}
                disabled={loading}
                size="md"
                labelA="Disabled"
                labelB="Enabled"
                onToggle={onActiveToggle}
            />

            <div style={{ height: '1rem' }} />

            {active && <>
                <div style={{ display: 'flex', gap: '0.25rem' }} className={"horizontal-tile-radio settings-tiles"} //valueSelected={this.state.sourceTypeValue} onChange={value => this.setSourceType(value)}
                >
                    <SelectableTile selected={isSourceSelected(SOURCE_TYPE_WAREHOUSE)} onClick={() => setSourceSelected(SOURCE_TYPE_WAREHOUSE, !isSourceSelected(SOURCE_TYPE_WAREHOUSE))}>
                        <RadioItem icon={DataVis_232} title="Warehouse" desc="Allow negative stock in warehouse" />
                    </SelectableTile>
                    <SelectableTile selected={isSourceSelected(SOURCE_TYPE_STORE)} onClick={() => setSourceSelected(SOURCE_TYPE_STORE, !isSourceSelected(SOURCE_TYPE_STORE))}>
                        <RadioItem icon={Store32} title="Store" desc="Allow negative stock in store" />
                    </SelectableTile>
                    {hasCapabilitySupport("mobileUnit") &&
                        <SelectableTile selected={isSourceSelected(SOURCE_TYPE_MOBILE_UNIT)} onClick={() => setSourceSelected(SOURCE_TYPE_MOBILE_UNIT, !isSourceSelected(SOURCE_TYPE_MOBILE_UNIT))}>
                            <RadioItem icon={Van32} title="Mobile unit" desc="Allow negative stock in mobile unit" />
                        </SelectableTile>}
                    {hasCapabilitySupport("thirdPartyPos") &&
                        <SelectableTile selected={isSourceSelected(SOURCE_TYPE_VENUE)} onClick={() => setSourceSelected(SOURCE_TYPE_VENUE, !isSourceSelected(SOURCE_TYPE_VENUE))}>
                            <RadioItem icon={Building32} title="Venue" desc="Allow negative stock in venue" />
                        </SelectableTile>}
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <Button loading={loadingUpdate} onClick={onUpdate} disabled={!canSave} renderIcon={CheckmarkFilled16}>Update</Button>
                </div>
            </>}

            {/* {active && <>
                {TableTemplate.renderTemplate({
                    ref: tableRef,
                    tableRelationMode: {
                        tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                        objectProperty: 'parentId',
                        parentId: 10103,
                        showNotIncluded: false,
                        showNewBtn: true
                    },
                    embedded: true,
                    // hideCreateButton: true,
                    title: "Package Units",
                    subTitle: "Product package units",
                    objectType: OBJECT_TYPE_PACKAGE_UNIT,
                    //pagePath: "/discounts/", 
                    history: history,

                    customNewButtonListener: () => setShowCreateDialog(true),
                    customRowButtonListener: (id) => setSelectedItem(id)
                })}

                <ItemCreatorDialog
                    fullSized
                    size="lg"
                    itemId={selectedItem}
                    objectType={OBJECT_TYPE_PACKAGE_UNIT}
                    preventDeletePhraseModal
                    returnItemOnSave
                    productFinderShowFakeTextFieldAtFirst
                    parentId={10103}
                    open={showCreateDialog || selectedItem} onClose={() => {
                        setShowCreateDialog(false)
                        setSelectedItem(undefined)
                    }}
                    onPreSave={update => update.parentId = 10103}
                    onCreatedItem={createdItem => {
                        UIUtil.showSuccess();
                        setShowCreateDialog(false)
                        tableRef.current.addItem(createdItem)
                        //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                    }}
                    onSave={savedItem => {
                        UIUtil.showSuccess();
                        setSelectedItem(undefined)
                        tableRef.current.saveItem(savedItem)
                        //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                    }}
                    onDelete={id => tableRef.current.deleteItem(id)}
                />
            </>} */}
        </div>
    )
}


const AllowSalesReturnWithoutOriginalSales = ({ }) => {
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        Api.isSettingEnabled('allow_sales_return_without_org_sales', response => {
            if (response.status === true) {
                setActive(response.payload)
                setLoading(false)
            } else {
                setInError(true)
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const onActiveToggle = enabled => {
        const currentActive = active;
        setActive(enabled)
        setLoading(true)
        Api.setSettingEnable('allow_sales_return_without_org_sales', enabled, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setActive(currentActive)
            }
            setLoading(false)
        })
    }

    return (
        <div>
            <Tile style={{ marginBottom: '1rem', width: '100%', height: 'unset' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Warning16 style={{ marginRight: '0.5rem' }} />
                    <h6>Important Note</h6>
                </div>
                <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                    If this feature is enabled, whenever a sales return occurs and the original sale is not specified, the system will allow the sales return to proceed.
                    However, since no sale is selected, the system will have to calculate the stock's inventory value and the preceding sale's inventory revenue value.
                    Such calculation is inherently not accurate, however, the system will try the most accurate estimates. It will first use the average cost and revenue
                    of the product. If the average values do not exist, then the system will use the original cost and price of the product.
                </p>
            </Tile>

            <label className="bx--label">Active</label>
            <Toggle
                checked={active}
                disabled={loading}
                size="md"
                labelA="Disabled"
                labelB="Enabled"
                onToggle={onActiveToggle}
            />
        </div>
    )
}

const DontCheckProductSupplier = ({ }) => {
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        Api.isSettingEnabled('do_not_check_product_supplier_in_stock_flow', response => {
            if (response.status === true) {
                setActive(response.payload)
                setLoading(false)
            } else {
                setInError(true)
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const onActiveToggle = enabled => {
        const currentActive = active;
        setActive(enabled)
        setLoading(true)
        Api.setSettingEnable('do_not_check_product_supplier_in_stock_flow', enabled, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setActive(currentActive)
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <label className="bx--label">Active</label>
            <Toggle
                checked={active}
                disabled={loading}
                size="md"
                labelA="Disabled"
                labelB="Enabled"
                onToggle={onActiveToggle}
            />
        </div>
    )
}

export function AllowGreaterAmountInSalesPaidTransaction() {
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        Api.isSettingEnabled('allow_greater_amount_in_sales_paid_transaction', response => {
            if (response.status === true) {
                setActive(response.payload)
                setLoading(false)
            } else {
                setInError(true)
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const onActiveToggle = enabled => {
        const currentActive = active;
        setActive(enabled)
        setLoading(true)
        Api.setSettingEnable('allow_greater_amount_in_sales_paid_transaction', enabled, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setActive(currentActive)
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <label className="bx--label">Active</label>
            <Toggle
                checked={active}
                disabled={loading}
                size="md"
                labelA="Disabled"
                labelB="Enabled"
                onToggle={onActiveToggle}
            />
        </div>
    )
}


const AllowTransactionsToBeDeleted = makeSettingComponent('allow_transaction_to_be_deleted')
const AllowStockFlowsToBeDeleted = makeSettingComponent('allow_stock_flow_to_be_deleted')


export default ({ settingsValues }) => {

    return (
        <Accordion className="no-padding-accordion">
            {isSqlAvgEngine() && <AccordionItem title="Allow Negative Stock">
                <NegativeStockView />
            </AccordionItem>}

            <AccordionItem title="Allow Sales Return Without Specifying Original Sale">
                <AllowSalesReturnWithoutOriginalSales />
            </AccordionItem>

            <AccordionItem title="Don't Check Product Supplier">
                <DontCheckProductSupplier />
            </AccordionItem>

            <AccordionItem title="Allow Greater Amount In Sale's Paid Transaction">
                <AllowGreaterAmountInSalesPaidTransaction />
            </AccordionItem>

            <AccordionItem title="Allow Transactions to be deleted">
                <AllowTransactionsToBeDeleted />
            </AccordionItem>

            <AccordionItem title="Allow Stock Flows to be deleted">
                <AllowStockFlowsToBeDeleted />
            </AccordionItem>
        </Accordion>
    )
}