import { Close16, LicenseDraft24, ChevronRight16 } from '@carbon/icons-react'
import Util from '../../../util/Util'
import ProfilePic from '../../../components/ProfilePic'
import { useState } from 'react'
import { InlineLoading, Tag } from 'carbon-components-react'
import { useEffect } from 'react'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import { OBJECT_TYPE_ACCOUNT } from '../../../constants/ObjectTypes'
import { isMobileScreen } from '../../../App'
import { getAccountRole } from '../../../session/SessionManager'
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from '../../../constants/Constants'

const TemplateCard = ({ item, onClick }) => {
    const accountRole = getAccountRole();
    const isAdmin = accountRole === ACCOUNT_TYPE_ADMINISTRATION || accountRole === ACCOUNT_TYPE_SUPERVISOR;
    return (
        <div onClick={onClick}>
            <div className='draft-advanced-template-card' style={{ borderRadius: 5, overflow: 'hidden', background: 'white', }}>
                <div style={{ padding: '1rem', paddingBottom: '0.5rem', border: '1px solid #00000010', borderBottom: '1px solid #0353e9' }}>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-start' }}>
                        <div style={{ marginTop: '0.25rem' }}>
                            <ProfilePic size={24} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.userId)} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <p>{item.title}</p>
                            {isAdmin && (
                                item.visibilityPublic ? (
                                    <Tag size="sm" type="blue">Public</Tag>
                                ) : (
                                    <Tag size="sm" type="high-contrast">Private</Tag>
                                )
                            )}
                            {/* <h6 style={{ fontSize: 12 }}>Alex Wales</h6> */}
                        </div>
                        <ChevronRight16 style={{ opacity: 0.65, width: 12, height: 12 }} />
                    </div>
                    <p style={{ fontSize: 12, opacity: 0.65, textAlign: 'right' }}>{Util.getDate(item.lastModifiedDate)}</p>
                </div>
                <div style={{ background: "#0f62fe", width: '100%', height: 7 }}>

                </div>
            </div>
        </div>
    )
}

export function TemplatesList({ stateType, onTemplateClick, onClose }) {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setTemplates([])

        Api.getAllStateDrafts(stateType, response => {
            setLoading(false)
            if (response.status === true) {
                setTemplates(response.payload);
            } else {
                UIUtil.showError("Failed to load templates");
                onClose();
            }
        })
    }, []);

    return (
        <div style={{ paddingBlock: '6rem', paddingInline: '3rem' }}>
            <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem' }}>
                <LicenseDraft24 />
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>Templates</h4>
                <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>

            {templates.length > 0 ? (
                <div style={{ display: 'grid', gridTemplateColumns: isMobileScreen ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)', gap: '1rem', }}>
                    {templates.map(template => <TemplateCard key={template.id} item={template} onClick={() => onTemplateClick(template)} />)}
                </div>
            ) : (
                <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {loading ? (
                        <InlineLoading style={{ width: 'unset' }} />
                    ) : (
                        <p style={{ fontSize: 12, opacity: 0.65 }}>No templates saved</p>
                    )}
                </div>
            )}
        </div>
    )
}