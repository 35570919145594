import { Line } from "react-chartjs-2";
import { Widget } from ".";
import { FlowData16 } from '@carbon/icons-react'
import { ContentSwitcher, Switch } from "carbon-components-react";
import { useState } from "react";
import Util from "../../util/Util";
import { useStrLocalStorage } from "../../hooks/useLocalStorage";

// Order count
// Order amount


export function Overview({ data }) {
    const trend = data.currentTrend;
    const [mode, setMode] = useStrLocalStorage("rest-dashboard-trend-mode", "Sale")
    const [by, setBy] = useStrLocalStorage("rest-dashboard-trend-by", "Count")
    const getData = key => trend[key + mode + by];
    return (
        <Widget icon={FlowData16} title={"Current Trend"} style={{ width: '100%', flex: 1 }}>
            <div style={{ display: 'flex', marginBottom: '1rem', gap: '1rem' }}>
                <ContentSwitcher selectedIndex={mode === "Sale" ? 0 : 1} onChange={e => setMode(e.name)} size="md">
                    <Switch name={"Sale"} text="Sales" />
                    <Switch name={"Order"} text="Orders" />
                </ContentSwitcher>
                <ContentSwitcher selectedIndex={by === "Count" ? 0 : 1} onChange={e => setBy(e.name)} size="md">
                    <Switch name={"Count"} text="By Count" />
                    <Switch name={"Amount"} text="By Amount" />
                </ContentSwitcher>
            </div>

            <Line data={{
                labels: trend.keys,
                datasets: [
                    {
                        label: 'POS Sales',
                        data: getData('pos'),
                        fill: true,
                        backgroundColor: 'rgb(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                    },
                    {
                        label: 'Online Sales',
                        data: getData('online'),
                        fill: true,
                        backgroundColor: '#ADD7F4',
                        borderColor: 'rgba(54, 162, 235, 1)',
                    },
                    {
                        label: 'Total',
                        data: getData('total'),
                        fill: true,
                        backgroundColor: '#A3D7A3',
                        borderColor: 'rgba(0, 162, 0, 1)',
                    }
                ],
            }} options={{
                scales: {
                    y: {
                        min: 0,
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                                if (by === "Amount") {
                                    return 'AED ' + Util.formatMoney(value);
                                } else {
                                    return value
                                }
                            }
                        }
                    },
                },
                elements: {
                    line: {
                        tension: 0.4
                    }
                },
            }} />
        </Widget>
    )
}