
import { Checkbox, ComposedModal, ModalBody, ModalFooter, ModalHeader, Tag, TextInput } from "carbon-components-react";
import Button from "../../../../components/Button";
import { useMemo } from "react";
import { getRestOrderDate, getRestOrderNote } from "../../../../domain/rest-sales-order";
import Util from "../../../../util/Util";

const ValueField = ({ title, value }) => (
    <div style={{ marginBottom: '0.75rem' }}>
        <label style={{ fontWeight: 'bold', marginBottom: 0, opacity: 1, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>{value}</p>
    </div>
)

export function OrderPreferencesView({ order }) {
    const preferences = order.preferences;
    const data = useMemo(() => JSON.parse(preferences ?? "[]"), [preferences])
    const isNote = $ => $.id === "id" || $.id === "note" || $.id === "Additional Notes";
    const orderNote = getRestOrderNote(order)
    return (
        <div>
            {data?.filter?.(entry => !isNote(entry)).map?.(entry => <ValueField key={entry.id} title={entry.label} value={typeof entry.value === "boolean" ? (entry.value ? 'Yes' : 'No') : entry.value} />)}
            {Util.isStringExists(orderNote) && <ValueField key={"order-note-field"} title={"Order Note"} value={orderNote} />}
        </div>
    )
}

export function OrderPreferencesDialog({ open, onClose, order }) {

    return (
        <ComposedModal size="sm" open={open} onClose={onClose}>
            <ModalHeader label="Order" title="Preferences" />
            <ModalBody hasScrollingContent>
                <OrderPreferencesView order={order} />
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Done
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}