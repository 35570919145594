import './welcome.scss'
import React from "react";
import ProfilePic from "../../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR } from "../../../../constants/ObjectTypes";
import Api from "../../../../session/Api";
import { getAccount } from "../../../../session/SessionManager";
import { GroupPresentation32, CalendarHeatMap32, UserAvatar32, SplitScreen32, User32, DocumentSentiment32, UserCertification32, Notification32 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { isMobileScreen } from '../../../../App';
import { getReportLink } from '../../../../reporting-engine/base/report-page';

const Button = ({ href, color, icon, label }) => {
    return (
        <Link to={href} style={{ textDecoration: 'none' }}>
            <div className="welcome-button" style={{ color: color, borderRadius: 15, cursor: 'pointer', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', background: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', padding: '1rem', width: 96, height: 96 }}>
                {React.createElement(icon)}
                <h6 style={{ textDecoration: 'none' }}>{label}</h6>
            </div>
        </Link>
    )
}

export function TutorWelcomePage() {
    const account = getAccount();

    if (isMobileScreen) {
        return (
            <div style={{ padding: '1rem', gap: '1rem', }}>
                <div style={{ background: '#f4f4f4', border: '1px solid #00000020', borderRadius: 15, overflow: 'hidden', display: 'flex' }}>
                    <div style={{ padding: '1rem', paddingBottom: '1rem', color: 'white', background: '#1c1c1c', flex: 1, display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <ProfilePic size={64} src={Api.getThumbnail(OBJECT_TYPE_TUTOR, account.tutorId)} />
                        <div style={{ flex: 1, marginTop: '1rem' }}>
                            <h6>Welcome</h6>
                            <h2>{account.fullName}</h2>
                        </div>
                    </div>
                    <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Button href="/my-courses" color="#b64900" label="Courses" icon={GroupPresentation32} />
                        <Button href="/calendar" color="#003624" label="Calendar" icon={CalendarHeatMap32} />
                        <Button href="/board-view" color="#0f62fe" label="To-Do" icon={SplitScreen32} />
                        <Button href="/payouts" color="#003624" label="Payouts" icon={DocumentSentiment32} />
                        <Button href="/my-leads" color="#756300" label="My Leads" icon={UserCertification32} />
                        <Button href={getReportLink("MyLmsNotificationList")} color="#6c464f" label="Notifications" icon={Notification32} />
                        <Button href="/profile" color="#990000" label="Profile" icon={UserAvatar32} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ width: '100%', height: '100%', paddingLeft: '18%', paddingRight: '18%', paddingTop: '3rem', paddingBottom: '3rem', gap: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ background: '#f4f4f4', border: '1px solid #00000020', borderRadius: 15, overflow: 'hidden' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', padding: '1rem', paddingBottom: '4rem', color: 'white', background: '#1c1c1c' }}>
                    <ProfilePic size={64} src={Api.getThumbnail(OBJECT_TYPE_TUTOR, account.tutorId)} />
                    <div style={{ flex: 1 }}>
                        <h6>Welcome</h6>
                        <h2>{account.fullName}</h2>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', marginTop: '-3rem', }}>
                    <Button href="/my-courses" color="#b64900" label="Courses" icon={GroupPresentation32} />
                    <Button href="/calendar" color="#003624" label="Calendar" icon={CalendarHeatMap32} />
                    <Button href="/board-view" color="#0f62fe" label="To-Do" icon={SplitScreen32} />
                    <Button href="/payouts" color="#003624" label="Payouts" icon={DocumentSentiment32} />
                    <Button href="/my-leads" color="#756300" label="My Leads" icon={UserCertification32} />
                    <Button href={getReportLink("MyLmsNotificationList")} color="#6c464f" label="Notifications" icon={Notification32} />
                    <Button href="/profile" color="#990000" label="Profile" icon={UserAvatar32} />
                </div>
            </div>
        </div>
    )
}