import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import Logo from "../../../images/doc-logo.png"
import { SHIPMENT_DIR_TYPE } from "../shipment"
import { ArrowRight16, DeliveryParcel16 } from '@carbon/icons-react'
import { useHistory } from "react-router-dom"
import { Tag } from "carbon-components-react"
import { useMemo } from "react"
import { stringifyBlueprintInputFields } from "../../activities/components/blueprint-input-fields-read"
import { isPicard } from "../../../app/app-util"
import picardlogo from '../../../images/other/picard/picardlogo.png'

const DetailValueEntry = ({ label, value, skipTextAlign }) => (<>
    <div style={{ minHeight: 45, paddingBlock: skipTextAlign ? 10 : undefined, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: 1, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: 2, outline: 'none', textAlign: !skipTextAlign ? 'end' : undefined, wordBreak: 'break-all', }}>{value}</p>
    </div>
</>)
function Detail({ item, packageId, shipment }) {
    return (
        <div style={{ width: '100%' }}>
            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Info</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '0rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <DetailValueEntry label="Type" value={item.itemTypeName} />
                    <DetailValueEntry label="Length" value={item.length} />
                    <DetailValueEntry label="Width" value={item.width} />
                    <DetailValueEntry label="Height" value={item.height} />
                    <DetailValueEntry label="Weight" value={item.weight} />
                    <DetailValueEntry label="Quantity" value={item.quantity} />

                    {item.formFieldValues && item.formFieldValues.length > 0 &&
                        <DetailValueEntry skipTextAlign label="Description" value={stringifyBlueprintInputFields(item.formFieldValues)} />}

                    <ButtonValueEntry label="Shipment" href={"/shipment-job-qr/" + shipment.id} />
                    <ButtonValueEntry label="Package" href={"/shipment-package-qr/" + packageId} />
                </div>
            </div>
        </div>
    )
}

const ButtonValueEntry = ({ label, href }) => {
    const history = useHistory();
    return (<>
        <div onClick={() => history.push(href)} style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
            <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
            <ArrowRight16 style={{ opacity: 0.65 }} />
        </div>
    </>)
}
function View({ payload: { item: shipment, endpoint } }) {
    const itemId = endpoint.fromItemId;
    const packageId = endpoint.fromPackageId;
    const item = useMemo(() => shipment.packages.flatMap($ => $.items).find(i => i.id === itemId), [shipment, itemId])
    const aPackage = useMemo(() => shipment.packages.find(i => i.id === packageId), [shipment, packageId])
    return (
        <div style={{ padding: '1rem' }}>
            {isPicard() ? <>
                <img src={picardlogo} style={{ width: '100%', height: 100, marginBlock: '1rem', objectFit: 'contain', objectPosition: 'center', }} />
            </> : <>
                <img src={Logo} style={{ width: '100%', height: 150, marginBlock: '1rem', objectFit: 'contain', objectPosition: 'center', }} />
            </>}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Tag renderIcon={DeliveryParcel16} type="blue">Shipment {shipment.shipmentNo}</Tag>
            </div>
            <h4 style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center', width: '100%' }}>Item</h4>
            <p style={{ fontSize: 16, opacity: 0.65, textAlign: 'center', width: '100%' }}>{itemId}</p>

            <div style={{ height: '1rem' }} />

            {aPackage && <Detail item={item} shipment={shipment} packageId={packageId} />}

            <div style={{ height: 300 }} />
        </div>
    )
}

export const ShipmentPackageItemQr = withLoadablePageWithParams(params => listener => Api.getPublicShipmentFromItem(params.itemId, listener), View)

