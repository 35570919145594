import ReactTooltip from "react-tooltip"
import "./icon-btns.scss"

export function IconBtns({ children }) {
    return (
        <div className="icon-btns">
            {children}
            <ReactTooltip />
        </div>
    )
}

IconBtns.Button = ({ Icon, tip, onClick }) => {
    return (
        <button data-tip={tip} data-effect="solid" onClick={onClick}>
            <Icon />
        </button>
    )
}