import { Checkbox, ContentSwitcher, Switch } from "carbon-components-react";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange, EndDateOnly } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_STORE, OBJECT_TYPE_VENUE, OBJECT_TYPE_WAREHOUSE } from "../../../constants/ObjectTypes";
import { LocationFilter } from "./location-filter";

export const StockValuationByLocReport = {
    filter: Filter,
    viewBased: true,

    //itemToOpenLink: item => `/products/${item.id}`,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const HIDE_ZERO_STOCK_FILTER = { property: "qty", operator: "GT", value: 0 }
const HIDE_STORES = { operator: "AND", negate: true, filters: [{ property: "locationType", operator: "EQ", value: OBJECT_TYPE_STORE }] }
const HIDE_WAREHOUSES = { operator: "AND", negate: true, filters: [{ property: "locationType", operator: "EQ", value: OBJECT_TYPE_WAREHOUSE }] }
const HIDE_VENUES = { operator: "AND", negate: true, filters: [{ property: "locationType", operator: "EQ", value: OBJECT_TYPE_VENUE }] }
const HIDE_MOBILE_UNIT = { operator: "AND", negate: true, filters: [{ property: "locationType", operator: "EQ", value: OBJECT_TYPE_MOBILE_UNIT }] }


function Filter({ endpoint, state }) {
    return (<>
        <EndDateOnly state={state} property="date" skipAutoFilter />
        <CheckboxFilter state={state} filterId="stockReportHideZeroStock" label="Hide with 0 stock" filter={HIDE_ZERO_STOCK_FILTER} />
        <Divider />

        <CheckboxFilter state={state} filterId="hideStores" label="Hide stores" filter={HIDE_STORES} />
        <div style={{ width: '0.5rem' }} />
        <CheckboxFilter state={state} filterId="hideWarehouses" label="Hide warehouses" filter={HIDE_WAREHOUSES} />
        <div style={{ width: '0.5rem' }} />
        <CheckboxFilter state={state} filterId="hideVenues" label="Hide venues" filter={HIDE_VENUES} />
        <div style={{ width: '0.5rem' }} />
        <CheckboxFilter state={state} filterId="hideMobileUnits" label="Hide mobile units" filter={HIDE_MOBILE_UNIT} />

        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <LocationFilter state={state} accountTree={endpoint.locations} />
        </div>


        {/* <div style={{ flex: 1 }} />

        <div style={{ width: 250 }}>
            <ContentSwitcher selectedIndex={0} size="sm" onChange={e => { }}>
                <Switch name="BASIC" text="All Locations" />
                <Switch name="ADVANCED" text="Filter Locations" />
            </ContentSwitcher>
        </div> */}
    </>)
}
