import Api from "../../session/Api";


export function Preview({ definition, selected }) {
    if (!selected) {
        return null;
    }

    return (
        <div style={{ width: '40vw', minWidth: '40vw' }}>
            <iframe
                key={selected.path}
                // onError={e => alert(e)}
                style={{ width: '100%', height: '100%' }}
                src={Api.getTemplateEnginePathPreview(definition.name, selected.path)}
                type="application/pdf"
            />
        </div>
    )
}