import Theme from "../Theme";

class BlueTheme extends Theme {

    getName() {
        return "blue";
    }

    getColor() {
        return "#17255A";
    }

    getClassName() {
        return "theme-blue"
    }

}

export default BlueTheme;