import { Printer16, Xls16 } from '@carbon/icons-react'
import { useState } from 'react'
import { STATE_TYPE_TEMP_DATA } from '../../../constants/Constants';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import Util from '../../../util/Util';

export function ExportToExcelBtn({ reportName, columns, request }) {
    const [loading, setLoading] = useState(false)
    const onClick = () => {
        if (loading) {
            return;
        }

        setLoading(true)
        Api.saveStateDraft({
            stateType: STATE_TYPE_TEMP_DATA,
            title: "",
            state: JSON.stringify({
                ...request,
                columns
            })
        }, response => {
            if (response.status === true) {
                Api.downloadReportEngineExportExcel(reportName, response.payload.id, downloadRes => {
                    if (downloadRes.status === true) {
                        const blob = downloadRes.payload;

                        const a = document.createElement("a");
                        a.href = URL.createObjectURL(blob);
                        a.download = reportName + ".xlsx";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        UIUtil.showSuccess("Report Generated. Downloading Excel file.");

                    } else {
                        UIUtil.showError();
                    }
                    setLoading(false)
                })

                // const a = document.createElement("a");
                // a.href = Api.reportEngineExportExcel(reportName, response.payload.id);
                // a.download = true;
                // document.body.appendChild(a);
                // a.click();
                // document.body.removeChild(a);
                // UIUtil.showSuccess("Report Generated. Downloading Excel file.");
            } else {
                UIUtil.showError();
                setLoading(false);
            }
        })
    }
    return (
        <button onClick={onClick} style={{ opacity: loading ? 0.65 : 1 }} className='report-engine-bottom-bar-button report-engine-bottom-bar-button-green'>
            {loading ? 'Generating...' : "Export to Excel"} <Xls16 />
        </button>
    )
}