import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';
import { SidePanel } from '../../templates/draft/components/side-panel';
import ProductDetailPage from './ProductDetailPage';
import { useForceLockScroll, useLockScroll } from '../../hooks/useLockScroll';

export const LockScroll = () => {
    useLockScroll(true);
    return <div />
}

class ProductListPage extends Page {

    state = {
        selectedProductId: undefined,
    }

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Products",
                    subTitle: "All products",
                    objectType: OBJECT_TYPE_PRODUCT,
                    pagePath: "/products/",
                    history: this.props.history,
                    paginated: true,

                    customRowButtonListener: selectedProductId => this.setState({ selectedProductId })
                })}

                {this.state.selectedProductId && <SidePanel md2 onClose={() => this.setState({ selectedProductId: undefined })}>
                    <LockScroll />
                    <ProductDetailPage {...this.props} pagePathParamsOverride={{ itemId: this.state.selectedProductId }} overrideBacktToListBtn={() => this.setState({ selectedProductId: undefined })} />
                </SidePanel>}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(ProductListPage);