import Page from "../../base/Page";
import Button from "../../components/Button";
import Api from "../../session/Api";
import CartWorkstationView from "./cart-page/CartWorkstationView";
import CartItemsView from "./cart-page/CartItemsView";
import ProductView from "./cart-page/ProductView";

import {
    ArrowLeft20,
    OverflowMenuVertical16,
    Add16,
    ViewOffFilled16,
    ViewOff16,
    Edit16,
    Notebook16,
    ViewFilled16
} from '@carbon/icons-react'
import { useHistory } from "react-router-dom";
import { HeaderGlobalAction } from "carbon-components-react";
import { useEffect, useState } from "react";
import PosState from "../pos/state/PosState";
import { makeObservable } from "../../util/makeObservable";
import useStore from "../../hooks/useStore";
import WorkView from "./cart-page/WorkView";
import CreateWorkDialog from "./dialogs/CreateWorkDialog";
import ReactTooltip from "react-tooltip";
import { SALES_CART_WORK_STATUS_ONGOING } from "../../constants/Constants";
import CartTypeTag from './components/CartTypeTag'
import NoteViewDialog from "../../views/notes/NoteViewDialog";
import { OBJECT_TYPE_SALES_CART, OBJECT_TYPE_SALES_CART_TYPE } from "../../constants/ObjectTypes";
import NoteViewDialog2 from "../../views/notes/NoteViewDialog2";
import UIUtil from "../../util/UIUtil";
import EditSalesCartDialog from "./dialogs/EditSalesCartDialog";

const GhostButton = (props) => {
    const [hover, setHover] = useState(false);
    return <Button onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        kind="ghost" style={!hover ? { color: 'white', fill: 'white' } : {}} {...props}>{props.children}</Button>;
}

const Header = ({ salesCart, mainSessionStore, onCreateWorkBtn, onNoteBtn, onEditBtn, showActions }) => {
    const [loading, setLoading] = useStore(mainSessionStore, "loading")
    const [settingVisibility, setSettingVisibility] = useState(false)
    const history = useHistory()

    const toggleVisibility = () => {
        setSettingVisibility(true)
        const toggle = !salesCart.hidden;
        Api.setSalesCartVisibility(salesCart.id, toggle, response => {
            if (response.status === true) {
                salesCart.hidden = toggle;
            } else {
                UIUtil.showError(response.message)
            }
            setSettingVisibility(false)
        })
    }

    const visible = !salesCart.hidden;

    return (
        // <div className="theme-bg" style={{width: '100%', height: 48, display: 'flex', alignItems: 'center', position: 'relative'}}>
        <div className="label-designer-header theme-bg">
            <GhostButton iconDescription="Back" renderIcon={ArrowLeft20} onClick={() => history.goBack()} />
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', paddingLeft: '0.5rem', paddingRight: '0.5rem', }}>
                <h4 style={{ margin: 0, color: 'white', marginRight: '0.5rem' }}>{salesCart.name}</h4>
                {salesCart.type && <CartTypeTag type={salesCart.type} />}
            </div>

            {showActions && <>

                <div className="transparent-button">
                    <Button onClick={onNoteBtn} tooltipPosition="bottom"
                        hasIconOnly iconDescription="Notes/Docs" renderIcon={Notebook16} style={{ width: 48, height: 48, }} />
                </div>

                <div className="transparent-button">
                    <Button onClick={toggleVisibility} tooltipPosition="bottom" loading={settingVisibility}
                        hasIconOnly iconDescription={visible ? 'Visible' : 'Hidden'} renderIcon={visible ? ViewFilled16 : ViewOff16}
                        style={{ width: 48, height: 48, }} />
                </div>

                <div className="transparent-button">
                    <Button onClick={onEditBtn} tooltipPosition="bottom"
                        hasIconOnly iconDescription="Edit" renderIcon={Edit16} style={{ width: 48, height: 48, }} />
                </div>

                <Button onClick={onCreateWorkBtn} loading={loading}
                    style={{ height: '100%' }} kind="primary" renderIcon={Add16}>New Work</Button>
            </>}
        </div>
    )
}

class SalesCartPage extends Page {

    mainSessionStore = makeObservable()

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            salesCart: undefined,
            workList: [],
            customers: [],
            warehouses: [],
            stores: [],
            mobileUnits: [],
            venues: [],

            selectedWorkId: undefined,
            showCreateWorkDialog: false,
            showNoteDialog: false,
            showEditDialog: false,

            workstationTabSelectRequest: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getSalesCart(this.getPathParams().itemId, listener), salesCart => {
            this.mainSessionStore.setObject({
                ...salesCart.session,
                parentId: salesCart.id
            })
            return {
                salesCart,
                workList: salesCart.workList,
                customers: salesCart.customers,
                warehouses: salesCart.warehouses,
                mobileUnits: salesCart.mobileUnits,
                stores: salesCart.stores,
                venues: salesCart.venues,
            }
        })
    }

    onUpdateSalesCart(salesCart) {
        this.setState({ salesCart, showEditDialog: false })
    }

    onCreatedWork(work) {
        this.setState(prevState => ({ workList: [work, ...prevState.workList], showCreateWorkDialog: false }))
    }

    onUpdateWork(work) {
        if (work) {
            this.setState(prevState => ({
                selectedWorkId: undefined,
                workstationTabSelectRequest: work.status,
                workList: work.deleted ? prevState.workList.filter(item => item.id != work.id) : [work, ...prevState.workList.filter(item => item.id != work.id)]
            }))
        }
    }

    getLayout() {
        const { warehouses, mobileUnits, stores } = this.state;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', }}>
                <Header salesCart={this.state.salesCart} mainSessionStore={this.mainSessionStore}
                    showActions={!this.state.selectedWorkId}
                    onNoteBtn={() => this.setState({ showNoteDialog: true })}
                    onEditBtn={() => this.setState({ showEditDialog: true })}
                    onCreateWorkBtn={() => this.setState({ showCreateWorkDialog: true })} />
                <div style={{ display: 'flex', gap: '0.25rem', flex: 1, padding: '0.25rem' }}>
                    {this.state.selectedWorkId ?
                        <WorkView workId={this.state.selectedWorkId} onBack={this.onUpdateWork.bind(this)} {...{ warehouses, mobileUnits, stores }} /> :
                        <>
                            <ProductView mainSessionStore={this.mainSessionStore} />
                            <CartItemsView mainSessionStore={this.mainSessionStore} />
                            <CartWorkstationView
                                tabSelectRequest={this.state.workstationTabSelectRequest}
                                workList={this.state.workList} onWorkSelected={workId => this.setState({ selectedWorkId: workId })} />
                        </>
                    }
                </div>

                <CreateWorkDialog
                    onCreated={this.onCreatedWork.bind(this)}
                    customers={this.state.customers}
                    venues={this.state.venues}
                    salesCartId={this.state.salesCart.id}
                    open={this.state.showCreateWorkDialog} onClose={() => this.setState({ showCreateWorkDialog: false })} />

                <EditSalesCartDialog
                    salesCart={this.state.salesCart}
                    onEdited={this.onUpdateSalesCart.bind(this)}
                    open={this.state.showEditDialog} onClose={() => this.setState({ showEditDialog: false })} />

                <NoteViewDialog2 open={this.state.showNoteDialog} onClose={() => this.setState({ showNoteDialog: false })} objectType={OBJECT_TYPE_SALES_CART} objectId={this.state.salesCart.id} />
            </div>
        )
    }

}

export default SalesCartPage;