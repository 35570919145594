
import React, { useState } from 'react';
import {
    Activity20,
    Archive32,
    ShoppingCart32,
    Currency32,
    AirlinePassengerCare32,
    StarFilled32,
    IncreaseLevel16,
    ArrowRight16,
    SubtractAlt16
} from '@carbon/icons-react'
import Button from '../../../components/Button';
import DashboardTitle from '../components/DashboardTitle';
import CountUp from 'react-countup';
import { ContentSwitcher, Switch } from 'carbon-components-react';

const DashboardView = ({ id, icon, title, value, yesterdayValue, percChange, prefix, suffix, decrease, mode }) => {
    const [expanded, setExpanded] = useState(false);

    const getModeText = () => {
        if (mode == MODE_DAY) {
            return "Yesterday";
        } else if (mode == MODE_WEEK) {
            return "Last Week";
        } else {
            return "Last Month";
        }
    }

    return (
        <div style={{ flex: 1, position: 'relative' }}>
            <div className={`dashboard-view-${id}`} style={{ zIndex: 10000, width: '100%', height: 175, borderTopLeftRadius: 5, borderTopRightRadius: 5, padding: '1rem', color: 'white', display: 'flex', flexDirection: 'column' }}>
                {React.createElement(icon, {
                    style: {
                        width: 64, height: 64, fill: 'white'
                    }
                })}

                <div style={{ flex: 1 }}></div>
                <h3 style={{ alignSelf: 'flex-end' }}>{title}</h3>
                {/* <h1 ref={valueRef} style={{ fontWeight: 'bold', alignSelf: 'flex-end', }}>{value}</h1> */}
                <CountUp start={0} end={value} delay={0} duration={1.25} separator="," decimals={0} decimal="" prefix={prefix != undefined ? prefix : ""} suffix={suffix != undefined ? suffix : ""}>
                    {({ countUpRef }) => (
                        <h1 ref={countUpRef} style={{ fontWeight: 'bold', alignSelf: 'flex-end', }} />
                    )}
                </CountUp>
            </div>

            <div style={{ height: 45 }} />

            <div className={`dashboard-bottom-view-${id}`}
                onClick={() => setExpanded(prev => !prev)}
                style={{
                    height: expanded ? 220 : 45, width: '100%', borderBottomLeftRadius: 5, borderBottomRightRadius: 5,
                    paddingLeft: '1rem', paddingRight: '1rem', cursor: 'pointer', zIndex: 1000,
                    position: 'absolute', left: 0, bottom: expanded ? -175 : 0, overflow: 'hidden',
                    transition: '250ms',
                }}>
                <div style={{ display: 'flex', alignItems: 'center', height: 45 }}>
                    {decrease ? (
                        <SubtractAlt16 style={{ fill: 'white', marginRight: '0.5rem' }} />
                    ) : (
                        <IncreaseLevel16 style={{ fill: 'white', marginRight: '0.5rem' }} />
                    )}
                    <p style={{ color: 'white', fontSize: 14, flex: 1 }}>{percChange}% {decrease ? "decrease" : "increase"} from {getModeText().toLowerCase()}</p>
                    <ArrowRight16 style={{ fill: 'white', marginLeft: '0.5rem', transition: '250ms', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', height: 175 - 30, color: 'white' }}>
                    <h2 style={{ color: 'white' }}>{getModeText()}</h2>
                    <div style={{ flex: 1 }}></div>
                    <h3 style={{ alignSelf: 'flex-end' }}>{title}</h3>
                    <h2 style={{ fontWeight: 'bold', alignSelf: 'flex-end', }}>{yesterdayValue}</h2>
                </div>
            </div>
        </div>
    )
}

const MODE_DAY = 0;
const MODE_WEEK = 1;
const MODE_MONTH = 2;

export default ({dashboard}) => {
    const [mode, setMode] = useState(MODE_DAY);

    const getModeText = () => {
        if (mode == MODE_DAY) {
            return "Today";
        } else if (mode == MODE_WEEK) {
            return "This week";
        } else {
            return "This month (" + dashboard.monthName + ")";
        }
    }

    const getModeContent = () => {
        if (mode == MODE_DAY) {
            return (<>
                <DashboardView mode={mode} id={0} icon={ShoppingCart32} title="Total Sales" 
                value={dashboard.totalSalesValue} yesterdayValue={dashboard.totalSalesYesterdayValue} percChange={dashboard.totalSalesPercChange} decrease={dashboard.totalSalesDecrease} />
    
                <DashboardView mode={mode} id={1} icon={Currency32} title="Sales Revenue" prefix="AED " value={640000} 
                value={dashboard.salesRevenueValue} yesterdayValue={dashboard.salesRevenueYesterdayValue} percChange={dashboard.salesRevenuePercChange} decrease={dashboard.salesRevenueDecrease} />
    
                <DashboardView mode={mode} id={2} icon={AirlinePassengerCare32} title="New Customers" value={720} decrease 
                value={dashboard.newCustomersValue} yesterdayValue={dashboard.newCustomersYesterdayValue} percChange={dashboard.newCustomersPercChange} decrease={dashboard.newCustomersDecrease} />
    
                <DashboardView mode={mode} id={3} icon={StarFilled32} title="Average Rating" value={4} suffix="/5" 
                value={dashboard.averageRatingValue} yesterdayValue={dashboard.averageRatingYesterdayValue} percChange={dashboard.averageRatingPercChange} decrease={dashboard.averageRatingDecrease} />
            </>)
        } else if (mode == MODE_WEEK) {
            return (<>
                <DashboardView mode={mode} id={0} icon={ShoppingCart32} title="Total Sales" 
                value={dashboard.totalSalesValueWeek} yesterdayValue={dashboard.totalSalesYesterdayValueWeek} percChange={dashboard.totalSalesPercChangeWeek} decrease={dashboard.totalSalesDecreaseWeek} />
    
                <DashboardView mode={mode} id={1} icon={Currency32} title="Sales Revenue" prefix="AED " value={640000} 
                value={dashboard.salesRevenueValueWeek} yesterdayValue={dashboard.salesRevenueYesterdayValueWeek} percChange={dashboard.salesRevenuePercChangeWeek} decrease={dashboard.salesRevenueDecreaseWeek} />
    
                <DashboardView mode={mode} id={2} icon={AirlinePassengerCare32} title="New Customers" value={720} decrease 
                value={dashboard.newCustomersValueWeek} yesterdayValue={dashboard.newCustomersYesterdayValueWeek} percChange={dashboard.newCustomersPercChangeWeek} decrease={dashboard.newCustomersDecreaseWeek} />
    
                <DashboardView mode={mode} id={3} icon={StarFilled32} title="Average Rating" value={4} suffix="/5" 
                value={dashboard.averageRatingValueWeek} yesterdayValue={dashboard.averageRatingYesterdayValueWeek} percChange={dashboard.averageRatingPercChangeWeek} decrease={dashboard.averageRatingDecreaseWeek} />
            </>)
        } else {
            return (<>
                <DashboardView mode={mode} id={0} icon={ShoppingCart32} title="Total Sales" 
                value={dashboard.monthTotalSalesValue} yesterdayValue={dashboard.monthTotalSalesYesterdayValue} percChange={dashboard.monthTotalSalesPercChange} decrease={dashboard.monthTotalSalesDecrease} />
    
                <DashboardView mode={mode} id={1} icon={Currency32} title="Sales Revenue" prefix="AED " value={640000} 
                value={dashboard.monthSalesRevenueValue} yesterdayValue={dashboard.monthSalesRevenueYesterdayValue} percChange={dashboard.monthSalesRevenuePercChange} decrease={dashboard.monthSalesRevenueDecrease} />
    
                <DashboardView mode={mode} id={2} icon={AirlinePassengerCare32} title="New Customers" value={720} decrease 
                value={dashboard.monthNewCustomersValue} yesterdayValue={dashboard.monthNewCustomersYesterdayValue} percChange={dashboard.monthNewCustomersPercChange} decrease={dashboard.monthNewCustomersDecrease} />
    
                <DashboardView mode={mode} id={3} icon={StarFilled32} title="Average Rating" value={4} suffix="/5" 
                value={dashboard.monthAverageRatingValue} yesterdayValue={dashboard.monthAverageRatingYesterdayValue} percChange={dashboard.monthAverageRatingPercChange} decrease={dashboard.monthAverageRatingDecrease} />
            </>)
        }
    }

    return (
        <div style={{}}>
            {/* <h5>Today's Stats</h5> */}
            <div style={{display: 'flex', alignItems: 'center', height: 60}}>
                <div style={{flex: 1}}>
                    <DashboardTitle icon={Activity20} title="At a glance" noMargin />
                    <p style={{opacity: 0.85, fontSize: 12,}}>{getModeText()}</p>
                </div>
                <div style={{minWidth: 400}}>
                    <ContentSwitcher onChange={e => setMode(e.name)}>
                        <Switch name={MODE_DAY} text="Today" />
                        <Switch name={MODE_WEEK} text="This week" />
                        <Switch name={MODE_MONTH} text="This month" />
                    </ContentSwitcher>
                </div>
            </div>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
                {getModeContent()}
                {/* <DashboardView id={0} icon={ShoppingCart32} title="Total Sales" 
                value={dashboard.totalSalesValue} yesterdayValue={dashboard.totalSalesYesterdayValue} percChange={dashboard.totalSalesPercChange} decrease={dashboard.totalSalesDecrease} />
    
                <DashboardView id={1} icon={Currency32} title="Sales Revenue" prefix="AED " value={640000} 
                value={dashboard.salesRevenueValue} yesterdayValue={dashboard.salesRevenueYesterdayValue} percChange={dashboard.salesRevenuePercChange} decrease={dashboard.salesRevenueDecrease} />
    
                <DashboardView id={2} icon={AirlinePassengerCare32} title="New Customers" value={720} decrease 
                value={dashboard.newCustomersValue} yesterdayValue={dashboard.newCustomersYesterdayValue} percChange={dashboard.newCustomersPercChange} decrease={dashboard.newCustomersDecrease} />
    
                <DashboardView id={3} icon={StarFilled32} title="Average Rating" value={4} suffix="/5" 
                value={dashboard.averageRatingValue} yesterdayValue={dashboard.averageRatingYesterdayValue} percChange={dashboard.averageRatingPercChange} decrease={dashboard.averageRatingDecrease} /> */}
            </div>
        </div>
    )
}