import { useHistory } from "react-router-dom"
import { Add16, Error16, Save16, Reset16, Time16, ErrorFilled16, Delete16, Search16, CheckmarkFilled16, ArrowRight16, ArrowLeft16, Calendar16, QueryQueue16, List16 } from '@carbon/icons-react'
import { getObjectTypeName } from "../../constants/ObjectTypes";
import { ButtonSet, ComposedModal, Link, ModalBody, ModalFooter, ModalHeader, Tab, Tabs, TextInput, Tile } from "carbon-components-react";
import NoteView from "../../views/notes/NoteView";
import SystemLogsListView from "../../pages/system-logs/SystemLogsListView";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { CommentView } from "../../views/comment/comment-view";
import Portal from "../../util/Portal";

const DeleteConfirmDialog = ({ objectType, visible, onClose, onDelete, confirmByDefault }) => {
    const [confirmPhrase, setConfirmPhrase] = useState("");
    useEffect(() => {
        if (visible) {
            setConfirmPhrase(confirmByDefault ? "delete permanently" : "");
        }
    }, [visible])
    return (
        <ComposedModal open={visible} onClose={onClose}>
            <ModalHeader label="Confirm" title="Are you sure?" />
            <ModalBody>
                <p style={{ marginBottom: 5 }}>
                    Deleting this {objectType === undefined ? 'item' : getObjectTypeName(objectType).toLowerCase()} is irreversible and will be permanent!
                </p>
                {!confirmByDefault && <TextInput
                    data-modal-primary-focus
                    value={confirmPhrase}
                    onChange={e => setConfirmPhrase(e.target.value)}
                    onPaste={e => e.preventDefault()}
                    id="text-input-1"
                    labelText="To confirm please type out 'delete permanently'"
                    placeholder="Type out confirm phrase"
                />}
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    kind="danger" disabled={confirmPhrase != "delete permanently"}
                    onClick={onDelete}>
                    Delete
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}

export function FormControls({ dialogUsePortal, fullWidth, CustomDeleteDialog, isCreating, advancedDraft, saving, deleting, selectedDraft, onSaveDraft, onSave, onDeleteBtn, objectType, rounded, saveMessage = "Save" }) {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const DeleteDialog = CustomDeleteDialog !== null && CustomDeleteDialog !== undefined ? CustomDeleteDialog : DeleteConfirmDialog;

    const confirmDialog = (
        <DeleteDialog confirmByDefault={dialogUsePortal} objectType={objectType} visible={confirmDelete} onClose={() => setConfirmDelete(false)} onDelete={arg => {
            setConfirmDelete(false);
            onDeleteBtn(arg);
        }} />
    )

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: fullWidth ? '100%' : undefined, gap: rounded ? '0.5rem' : undefined }}>
            {isCreating ? (
                // <Button onClick={() => this.props.history.goBack()} kind="secondary" style={{ minWidth: 200 }} renderIcon={Error16} size="lg">Cancel</Button>
                <Button style={{ minWidth: !fullWidth ? 200 : undefined, flex: fullWidth ? 1 : undefined, borderRadius: rounded ? 50 : undefined }} size={rounded ? "sm" : "lg"} kind="secondary" onClick={onSaveDraft} disabled={saving} renderIcon={Save16}>
                    {advancedDraft ? (
                        selectedDraft ? 'Update Template' : 'Save Template'
                    ) : (
                        selectedDraft ? 'Update Draft' : 'Save Draft'
                    )}
                    {/* {selectedDraft ? 'Update Draft' : 'Save Draft'} */}
                </Button>
            ) : (
                <Button onClick={() => {
                    setConfirmDelete(true)
                }}
                    loading={deleting} disabled={saving} style={{ minWidth: !fullWidth ? 200 : undefined, flex: fullWidth ? 1 : undefined }} renderIcon={Delete16} kind="danger" size="lg">Delete</Button>
            )}

            <Button onClick={onSave} loading={saving} style={{ minWidth: !fullWidth ? 200 : undefined, flex: fullWidth ? 1 : undefined, borderRadius: rounded ? 50 : undefined }} renderIcon={CheckmarkFilled16} size={rounded ? "sm" : "lg"} disabled={deleting}>{saveMessage}</Button>

            {dialogUsePortal ? (
                <Portal>
                    <div style={{ zIndex: 10000000 }}>

                        {confirmDialog}
                    </div>
                </Portal>
            ) : confirmDialog}
        </div>
    )
}

export function FormLayout({
    objectType, objectId, objectTitle, children, options, hasComments,
    customTabs
}) {
    const [saving, setSaving] = useState(false);


    const history = useHistory()
    const isCreating = objectId == 0;
    return (
        <div className="clear-tab-content" style={{ padding: '2rem' }}>
            <Link onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</Link>
            <Tile style={{ padding: 0, display: 'flex' }} >
                <div style={{ padding: '1rem', flex: 1 }}>
                    <h4 className="bx--data-table-header__title">{isCreating ? 'New' : objectTitle}</h4>
                    <p className="bx--data-table-header__description">
                        {getObjectTypeName(objectType)}
                    </p>
                </div>
                <div style={{ padding: '1rem', display: 'flex' }}>
                    {options}
                </div>
                {/* {draftSupportType && <DraftManagement />} */}
                {/* <SalesOrderActionButtons salesOrder={this.state.salesOrder} onUpdateOrder={salesOrder => this.setState({ salesOrder })} /> */}
            </Tile>

            <Tabs type="container" style={{ background: '#e0e0e0', display: isCreating ? 'none' : undefined }}>
                <Tab id="details" label="Details">
                    {children}
                </Tab>
                {hasComments && <Tab id="comments" label="Comments">
                    <div style={{ padding: '1rem' }}>
                        {!isCreating && <CommentView itemType={objectType} itemId={objectId} />}
                    </div>
                </Tab>}
                {customTabs && !isCreating && customTabs.map(customTab => (
                    <Tab id={customTab.title} label={customTab.title}>
                        <div style={{ padding: '1rem' }}>
                            {customTab.content}
                        </div>
                    </Tab>
                ))}
                <Tab id="notes" label="Notes/Docs">
                    <div style={{ marginTop: '1rem' }}>
                        {!isCreating && <NoteView objectType={objectType} objectId={objectId} />}
                    </div>
                </Tab>
                <Tab id="system-logs" label="System Logs">
                    <div style={{ marginTop: '1rem' }}>
                        {!isCreating && <SystemLogsListView relatedObjectId={objectId} />}
                    </div>
                </Tab>
            </Tabs>


            <div style={{ height: '4rem' }} />
        </div>
    )
}