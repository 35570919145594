import { AccordionItem, Accordion, Toggle, Tile, TileGroup, RadioTile, SelectableTile } from 'carbon-components-react'
import { makeSettingComponent } from '..'



const IncludeTaxAmountInServiceSaleItem = makeSettingComponent('include_tax_amount_in_service_sale_item')

export default ({ settingsValues }) => {
    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="Include TAX amount in the service sale item price">
                <IncludeTaxAmountInServiceSaleItem />
            </AccordionItem>
        </Accordion>
    )
}