


import { ComboBox, ComposedModal, ModalBody, ModalFooter, ModalHeader, NumberInput, TextArea, TextInput } from 'carbon-components-react'
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import UIUtil from '../../../util/UIUtil';
import { Warning16, RotateCounterclockwiseAlt16 } from '@carbon/icons-react'
import Util from '../../../util/Util';
import Button from '../../../components/Button';
import Api from '../../../session/Api';


export function ChangeCashDialog({ state, direction, open, onClose }) {
    const [amount, setAmount] = useState("");
    const [memo, setMemo] = useState("");

    const [loading, setLoading] = useState(false);
    const onSubmitBtn = () => {
        setLoading(true);
        state.api.changePosCash({
            direction, amount, memo
        }, response => {
            if (response.status === true) {
                state.onUpdateSession(response.payload)
                onClose();
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        if (open) {
            setAmount("")
            setMemo("")
        }
    }, [open])

    return (
        <ComposedModal key="change-cash-dialog" size="md" open={open} onClose={onClose}>
            <ModalHeader label="Cash Flow" title={direction === "IN" ? "Add Cash" : "Take Cash"} />
            <ModalBody style={{ paddingRight: '1rem', }}>
                <TextInput
                    style={{ flex: 1 }}
                    data-modal-primary-focus
                    value={amount}
                    labelText="Amount"
                    onChange={(e) => setAmount(e.target.value)}

                />
                <div style={{ height: '1rem' }} />
                <TextArea labelText="Memo" rows={4} value={memo} onChange={e => setMemo(e.target.value)} />

            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    loading={loading}
                    onClick={onSubmitBtn}>
                    Submit
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}