import React, { useEffect, useRef, useState } from 'react'

export default ({style, disabled, title, color, onChange}) => {
    const [colorValue, setColorValue] = useState(color);
    const [focusedColor, setFocusedColor] = useState();

    const colorValueCache = useRef(colorValue);
    useEffect(() => {
        return () => {
            if (color != colorValueCache.current) {
                onChange(colorValueCache.current);
            }
        }
    }, [])

    const onBlur = () => {
        //onChange(e.target.value)
        if (focusedColor != colorValue) {
            onChange(colorValue);
            setFocusedColor(undefined);
        }
    }
    
    return (
        <div style={{display: 'flex', alignItems: 'center', ...style}}>
            <label className="bx--label" style={{flex: 1, margin: 0}}>{title}</label>
            <input onFocus={() => setFocusedColor(colorValue)} onBlur={onBlur} 
            disabled={disabled} type="color" value={colorValue} onChange={e => {
                setColorValue(e.target.value)
                colorValueCache.current = e.target.value;
            }} />
        </div>
    )
}