import { Checkbox, InlineLoading, Tag, TextInput } from "carbon-components-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DynamicTable } from "../../../components/dynamic-table"
import {
    Product16, Box16, ServiceDesk16, Application16, WatsonHealthTextAnnotationToggle16
} from '@carbon/icons-react'
import Util, { absAmt, big } from "../../../util/Util"
import InfiniteComboBox from "../../../components/InfiniteComboBox"
import { AmountTagSelectorNormalField } from "../../../pages/journal-entries/tag-selector"

const COLUMNS = [
    {
        title: "Item",
        flex: 5
    },
    {
        title: "Class",
        flex: 3
    },
    {
        title: "Memo",
        flex: 3
    },
    {
        title: "Qty",
        flex: 1
    },
]

const ItemView = ({ item }) => {
    return (
        <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020' }}>
            {/* <ProfilePic notProfile size={24} /> */}
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.value}</p>
        </div>
    )
}
ItemView.noPadding = true;

const ItemSelector = ({ products, item, setItem, light }) => {
    const textValue = item?.value;
    const setTextValue = value => {
        const itemToSelect = products.find(item => item.value === textValue);
        if (itemToSelect) {
            setItem({ ...item, value, selectedItem: itemToSelect })
        } else {
            setItem({ ...item, value, selectedItem: undefined })
        }
    }
    const selectedItem = item?.selectedItem;
    const setSelectedItem = selectedItem => {
        setItem({ ...item, value: selectedItem ? selectedItem.value : textValue, selectedItem })
    }

    const onClear = () => setItem({ ...item, value: "", selectedItem: undefined })

    return <InfiniteComboBox light={light} ItemView={ItemView} itemToString={item => item.value} items={products}
        {...({ textValue, setTextValue, selectedItem, setSelectedItem, onClear })} />
}

const Record = ({ products, predefinedClasses, item: recordItem, setItem: setRecordItem }) => {
    const [item, setItem] = useState({ value: "", selectedItem: undefined });

    const [qty, setQty] = useState(undefined);
    const [memo, setMemo] = useState(undefined);

    const [tags, setTags] = useState(item?.selectedItem?.tags ?? []);

    const dummyItem = recordItem.item === undefined;

    const firstTimeRef = useRef(true)

    useEffect(() => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        }

        setRecordItem({ ...recordItem, item, qty, memo, tags })
    }, [item, qty, memo, tags])

    useEffect(() => {
        if (!dummyItem) {
            // setSaveCustomService(false)
        }
    }, [item])

    const onSetItem = newItem => {
        setItem(newItem);
    }

    return [
        <div style={{ height: 40, width: '100%' }}>
            <ItemSelector products={products} item={item} setItem={onSetItem} />
        </div>,
        <div style={{ minHeight: 40, width: '100%', minWidth: 0, maxWidth: '100%', position: 'relative' }}>
            <AmountTagSelectorNormalField skipBottomBar options={predefinedClasses} value={tags} setValue={setTags} />
        </div>,
        <TextInput value={memo} onChange={e => setMemo(e.target.value)} placeholder="Input..." />,
        <TextInput value={qty} onChange={e => setQty(absAmt(e.target.value))} placeholder="Input..." />,
    ]
}


export const StockTransferItemsList = ({ products, predefinedClasses, editable, items, setItems }) => {
    return (
        <div style={{ width: '100%' }}>
            <DynamicTable
                //whiteBg noRadius noBorder 
                // lightBorder
                items={items} setItems={setItems}
                editable={editable} entryView={Record}
                columns={COLUMNS} entryViewProps={{
                    products, predefinedClasses
                }} />
        </div>
    )
}