import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CONTACT, OBJECT_TYPE_COUPON, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_MEASUREMENT_TYPE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL, OBJECT_TYPE_WAREHOUSE } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import StockFlowListView from '../stock-flow/StockFlowListView';


class MeasurementTypeDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_MEASUREMENT_TYPE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_MEASUREMENT_TYPE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_MEASUREMENT_TYPE,
                    itemResult: this.state.itemResult,
                    pagePath: "/measurement-types/",
                    history: this.props.history,
                    customTabs: []
                })}
            </div>
        )
    }

}

export default withRouter(MeasurementTypeDetailPage);