import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Util from '../../../util/Util';
import { paymentMethodText } from '../../../pages/transaction/TransactionEditor';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceNo2Container: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        //width: 60
        marginRight: 5,
    }

});


const InvoiceNo = ({ journalEntry }) => (
    <Fragment>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Voucher Number:</Text>
            <Text style={styles.invoiceDate}>{Util.getJournalEntryVoucherNumber(journalEntry.id, journalEntry.sequenceNo)}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date:</Text>
            <Text >{Util.getDateOnly(journalEntry.date)}</Text>
        </View >


    </Fragment>
);

export default InvoiceNo