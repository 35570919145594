import { Select, SelectItem } from "carbon-components-react";
import { useEffect, useState } from "react";
import Api from "../../../../session/Api";

export function PaymentPackageSelector({ value, setValue, courseId }) {
    const [items, setItems] = useState(undefined);

    useEffect(() => {
        Api.getCoursePaymentPackages(courseId, response => {
            if (response.status === true) {
                setItems(response.payload);
            }
        })
        return () => setItems(undefined);
    }, [courseId])
    if (items === undefined) {
        return null;
    }

    return (
        <div>
            {/* <label className="bx--label">Payment Package</label> */}
            <Select labelText="Payment Package" light value={value} onChange={e => setValue(e.target.value)}>
                <SelectItem value={-1} text="No Payment Event" />
                {items.map(option => <SelectItem key={option.id} value={option.id} text={option.value} />)}
            </Select>
        </div>
    )
}