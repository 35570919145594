import './welcome.scss'
import React, { useState } from "react";
import ProfilePic from "../../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_STUDENT } from "../../../../constants/ObjectTypes";
import Api from "../../../../session/Api";
import { getAccount, saveAccount } from "../../../../session/SessionManager";
import { GroupPresentation32, CalendarHeatMap32, Group32, Warning16, SplitScreen32, Account32, User32, Password32 } from '@carbon/icons-react';
import QRCode from 'react-qr-code';
import { Link, useHistory } from 'react-router-dom';
import { default as MainButton } from "../../../../components/Button"
import { FormDialog } from '../../../../templates/form/form-dialog';
import { isMobileScreen } from '../../../../App';
import UIUtil from '../../../../util/UIUtil';
import { Tag } from 'carbon-components-react';
import { InvoiceUnpaidIndicator } from './invoice-unpaid-indicator';

const Button = ({ href, color, icon, label }) => {
    return (
        <Link to={href} style={{ textDecoration: 'none', flex: 1, }}>
            {/* <div className="welcome-button" style={{ color: color, borderRadius: 15, cursor: 'pointer', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', background: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', padding: '1rem', width: 96, height: 96 }}> */}
            <div className="welcome-button" style={{ color: color, borderRadius: 15, cursor: 'pointer', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', background: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', padding: '1rem', height: 96 }}>
                {React.createElement(icon)}
                <h6 style={{ textDecoration: 'none' }}>{label}</h6>
            </div>
        </Link>
    )
}

const ChangePasswordButton = () => {
    const [visible, setVisible] = useState(false);
    return (<>
        <MainButton kind="tertiary" size="sm" style={{ borderRadius: 15 }} renderIcon={Password32} onClick={() => setVisible(true)}>Change Password</MainButton>

        <FormDialog customPositiveLabel="Update Password" successMsg="Password changed successfully!" open={visible} onClose={() => setVisible(false)} label="Updating" title="Password" item={{
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        }}
            setItem={item => {
                saveAccount(item)
                window.location.reload();
            }} saveApi={Api.changePassword.bind(Api)}>
            {({ TextField, CheckBoxField, SwitchField, ExtField }) => (<>
                <TextField title="Current Password" fieldKey="currentPassword" />
                <TextField title="New Password" fieldKey="newPassword" />
                <TextField title="Confirm New Password" fieldKey="confirmNewPassword" />
            </>)}
        </FormDialog>
    </>)
}

export function GuardianWelcomePage() {
    const account = getAccount();


    if (isMobileScreen) {
        return (
            <div style={{ padding: '1rem', gap: '1rem', }}>
                <div style={{ background: '#f4f4f4', border: '1px solid #00000020', borderRadius: 15, overflow: 'hidden', display: 'flex' }}>
                    <div style={{ padding: '1rem', paddingBottom: '1rem', color: 'white', background: '#1c1c1c', flex: 1, display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <ProfilePic size={64} src={Api.getThumbnail(OBJECT_TYPE_GUARDIAN, account.guardianId)} />
                        <div style={{ flex: 1, marginTop: '1rem' }}>
                            <h6>Welcome</h6>
                            <h2>{account.fullName}</h2>
                            <InvoiceUnpaidIndicator />
                        </div>
                    </div>
                    <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        {/* <Button href="/my-courses" color="#b64900" label="Courses" icon={GroupPresentation32} /> */}
                        {/* <Button href="/calendar" color="#003624" label="Calendar" icon={CalendarHeatMap32} /> */}
                        <Button href="/invoices" color="#756300" label="Invoices" icon={Account32} />
                        <Button href="/profile" color="#990000" label="Profile" icon={User32} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <ChangePasswordButton />
                </div>
            </div>
        )
    }

    return (
        <div style={{ width: '100%', height: '100%', paddingLeft: '25%', paddingRight: '25%', paddingTop: '3rem', paddingBottom: '3rem', gap: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ background: '#f4f4f4', border: '1px solid #00000020', borderRadius: 15, overflow: 'hidden' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', padding: '1rem', paddingBottom: '4rem', color: 'white', background: '#1c1c1c' }}>
                    <ProfilePic size={64} src={Api.getThumbnail(OBJECT_TYPE_GUARDIAN, account.guardianId)} />
                    <div style={{ flex: 1 }}>
                        <h6>Welcome</h6>
                        <h2>{account.fullName}</h2>
                        <InvoiceUnpaidIndicator />
                    </div>
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', marginTop: '-3rem', }}> */}
                <div style={{ display: 'flex', gap: '1rem', padding: '1rem', marginTop: '-3rem', width: '100%' }}>
                    {/* <Button href="/my-courses" color="#b64900" label="Courses" icon={GroupPresentation32} /> */}
                    {/* <Button href="/calendar" color="#003624" label="Calendar" icon={CalendarHeatMap32} /> */}
                    <Button href="/my-students" color="#b64900" label="My Students" icon={Group32} />
                    <Button href="/invoices" color="#756300" label="Invoices" icon={Account32} />
                    <Button href="/profile" color="#990000" label="Profile" icon={User32} />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ChangePasswordButton />
            </div>
        </div>
    )
}