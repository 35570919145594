import React, { useEffect, useState } from 'react'


export default (store, key) => {
    const [value, setValue] = useState(store.get(key))
    //useState(() => store.subscribe(key, setValue), [])
    useEffect(() => store.subscribe(key, setValue), [])
    return [value, (arg => {
        if (typeof arg == 'function') {
            //setValue(toString(arg(fromString(value))))
            store.set(key, arg(value))
        } else {
            // setValue(toString(arg))
            store.set(key, arg)
        }
    })]
}