import React from 'react'
import Page from '../../base/Page';
import Button from '../../components/Button';
import Api from '../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, Link, RadioTile, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar
} from 'carbon-components-react';
import TransactionListView from '../transaction/TransactionListView';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import StockFlowField from '../stock-flow/StockFlowField';
import { DESTINATION_TYPE_MOBILE_UNIT, DESTINATION_TYPE_STORE, DESTINATION_TYPE_VENUE, DESTINATION_TYPE_WAREHOUSE } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_VENUE, OBJECT_TYPE_WAREHOUSE } from '../../constants/ObjectTypes';
import ProductField from '../../views/product/ProductField';

import { subDays, addDays } from 'date-fns';
//import { DateRangePicker } from 'react-date-range';
import DateRangePicker from '../../components/date/DateRangePicker'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { withRouter } from 'react-router-dom'

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)


const HEADERS = [
    {
        header: "Name",
        key: "name"
    },
    {
        header: "Location",
        key: "objectType"
    },
    {
        header: "Stock",
        key: "stock"
    },
    {
        header: "Total Value",
        key: "totalStockValue",
    }
]


class ProductStockReportPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingReport: false,
            generatedReport: false,

            report: undefined,

            productIdValue: 0,
            productTypeValue: 0,
            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),

            suppliers: [],
            customers: [],
            warehouses: [],
            stores: [],
            venues: [],
            mobileUnits: [],

            locationPickerKey: Util.newTempId()
        }
    }

    clearReport() {
        if (this.props.loadDefaultProduct) {
            this.props.history.goBack()
        } else {
            this.setState({
                generatingReport: false,
                generatedReport: false,

                productIdValue: 0,
                startDateValue: subDays(new Date(), 1).getTime(),
                endDateValue: new Date().getTime(),
            })
        }
    }

    canGenerateReport() {
        //return Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);

        return Util.isNumberExist(this.state.productIdValue);
    }

    generateReportBtn() {
        this.setState({ generatingReport: true })
        Api.getProductStockReport(this.state.productIdValue, response => {
            if (response.status === true) {
                this.setState({ generatingReport: false, report: response.payload, generatedReport: true, })
                UIUtil.showSuccess();
            } else {
                this.setState({ generatingReport: false, generatedReport: false })
                UIUtil.showError(response.message);
            }
        })
    }

    onPageStart() {

        if (this.props.loadDefaultProduct) {
            this.callPageApi(listener => Api.getProductStockReport(this.getPathParams().loadDefaultProductId, listener), payload => ({
                report: payload,
                generatedReport: true,
            }))
        } else {
            this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => ({
                suppliers: payload.suppliers,
                customers: payload.customers,
                warehouses: payload.warehouses,
                stores: payload.stores,
                venues: payload.venues,
                mobileUnits: payload.mobileUnits
            }))
        }
    }

    getLocationList() {
        switch (this.state.locationTypeValue) {
            case DESTINATION_TYPE_WAREHOUSE:
                return this.state.warehouses;
            case DESTINATION_TYPE_STORE:
                return this.state.stores;
            case DESTINATION_TYPE_VENUE:
                return this.state.venues;
            case DESTINATION_TYPE_MOBILE_UNIT:
                return this.state.mobileUnits;
        }

        return undefined;
    }

    getLocationTypeName() {
        return "Product"
    }

    renderInput() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>Product Stock</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title="Product">
                        <ProductField productId={this.state.productIdValue}
                            productType={this.state.productTypeValue}
                            onProductChange={({ type, id }) => this.setState({
                                productIdValue: id,
                                productTypeValue: type,
                                //[stateValue(field.productTypeProperty)]: type,
                                //[stateValue(field.property)]: id
                            })} />
                    </Section>

                    {/* <Section icon={Number_232} title="Select Range" extraTopMargin>
                        <DateRangePicker
                        //onChange={item => setState([item.selection])}
                        onChange={item => this.setState({ startDateValue: item.selection.startDate.getTime(), endDateValue: item.selection.endDate.getTime() })}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={[{
                            startDate: new Date(this.state.startDateValue),
                            endDate: new Date(this.state.endDateValue),
                            key: 'selection'
                        }]}
                        direction="horizontal"
                        />

                        <DatePicker datePickerType={"range"}
                        // value={[this.state.startDateValue, this.state.endDateValue]}
                        onChange={e => {
                            if (e.length > 1) {
                                this.setState({
                                    startDateValue: e[0].getTime(),
                                    endDateValue: e[1].getTime()
                                })
                            } else if (e.length == 1) {
                                this.setState({
                                    startDateValue: e[0].getTime(),
                                })
                            } else {
                                this.setState({
                                    startDateValue: 0,
                                    endDateValue: 0
                                })
                            }
                        }}
                        >
                            <DatePickerInput
                                style={{flex: 1}}
                                placeholder="mm/dd/yyyy"
                                labelText={"Start date"}
                            />
                            <DatePickerInput
                                placeholder="mm/dd/yyyy"
                                labelText="End date"
                            />
                        </DatePicker>
                    </Section> */}

                    <Section icon={Number_232} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.generateReportBtn.bind(this)} disabled={!this.canGenerateReport()} loading={this.state.generatingReport} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        switch (header.key) {
            // case "name":
            //     return (
            //         <div style={{display: 'flex', alignItems: 'center'}}>
            //             <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, row.id)} />
            //             <p style={{marginLeft: '0.5rem'}}>{cell.value}</p>
            //         </div>
            //     )
            case "objectType":
                if (cell.value == OBJECT_TYPE_WAREHOUSE) {
                    return "Warehouse"
                } else if (cell.value == OBJECT_TYPE_STORE) {
                    return "Store"
                } else if (cell.value == OBJECT_TYPE_VENUE) {
                    return "Venue"
                } else if (cell.value == OBJECT_TYPE_MOBILE_UNIT) {
                    return "Mobile Unit"
                } else {
                    return "-";
                }

            case "totalStockValue":
                return <> AED {parseFloat(cell.value).toFixed(2)} </>

            case "stock":
                return <> <DeliveryParcel16 /> {row.stock} {row.stockSuffix} </>

            case "initiationDate":
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            default:
                return cell.value;
        }
    }

    renderReport() {
        return (
            <div className="main-content">
                <div onClick={this.clearReport.bind(this)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                    <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> {this.props.loadDefaultProduct ? 'Go back' : 'Generate another report'}</Link>
                </div>
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2 }}>
                    {this.state.report !== undefined && this.state.report.values !== undefined &&
                        <h4 style={{ color: 'green' }}>Total Stock: <DeliveryParcel24 /> {this.state.report.values.totalStock}</h4>}
                    {this.state.report !== undefined && this.state.report.values !== undefined &&
                        <h6 style={{ color: 'green' }}>Total Value: AED {this.state.report.values.totalValue.toFixed(2)}</h6>}

                    <div style={{ display: 'flex', alignItems: 'center', opacity: 0.65, marginTop: '0.25rem' }}>
                        <p>{this.getLocationTypeName()}</p>
                    </div>
                </div>
                {/* <TransactionListView report={this.state.report} hideToolbar /> */}

                <DataTable rows={this.state.report.items} headers={HEADERS} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => {
                                            if (this.getRow(row.id).objectType == OBJECT_TYPE_STORE) {
                                                this.props.history.push("/stores/" + row.id)
                                            } else if (this.getRow(row.id).objectType == OBJECT_TYPE_MOBILE_UNIT) {
                                                this.props.history.push("/mobile-units/" + row.id)
                                            } else if (this.getRow(row.id).objectType == OBJECT_TYPE_VENUE) {
                                                this.props.history.push("/venues/" + row.id)
                                            } else {
                                                this.props.history.push("/warehouses/" + row.id)
                                            }
                                        })} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }

    getLayout() {
        return this.state.generatedReport ? this.renderReport() : this.renderInput()
    }

}

export default withRouter(ProductStockReportPage);