import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { MultiSelector, MultiSelectorListMode } from "../../components/basic-filter/mutli-selector";
import { TextBox } from "../../components/basic-filter/text-box"
import { UserCertification32 } from '@carbon/icons-react'
import { CreateFlowDialog } from "../../../pages/leads/views/create-flow-dialog";

export const LeadsReport = {
    filter: Filter,

    //newLink: '/new-leads',
    itemToOpenLink: item => `/lead/${item.id}`,
    customPath: "/all-leads",
    newDialog: NewDialog,

    icon: UserCertification32,
    isListMode: true,

    isAvailable: () => {
        const role = getAccountRole();
        return (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("lead");
    }
}

const STATUS = [
    'Active',
    'On Hold',
    'Cancelled',
    'Finalized'
]

const FEELING = [
    'No Feeling',
    'Feeling Neutral',
    'Feeling Sad',
    'Feeling Happy'
]

function NewDialog({ endpoint, visible, onClose, onSuccess }) {
    const { sources, users, customers } = endpoint.creationEndpoints;
    return (
        <CreateFlowDialog
            onCreated={id => onSuccess({ id })}
            open={visible}

            sources={sources}
            users={users}
            userTags={[]}
            customers={customers}

            onClose={onClose} />
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <TextBox state={state} placeholder="Full Name" property="fullName" />
        <div className="list-mode-divider-quick-filter-bar" />
        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Source" fieldKey="leadsReportSource" options={endpoint.sources.map(item => item.value)} state={state} property="sourceName" />
        </div>
        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="leadsReportStatus" options={STATUS} state={state} property="status" />
        </div>
        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Feeling" fieldKey="leadsReportFeeling" options={FEELING} state={state} property="feeling" />
        </div>
        <div className="list-mode-divider-quick-filter-bar" />
        <div style={{ height: 30, marginTop: '0rem', flex: 2 }}>
            <MultiSelectorListMode fieldKey="leadsReportTags" placeholder="Select tags..." options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
