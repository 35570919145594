import React, { createRef, useEffect, useMemo, useState } from 'react'
import Button from '../../../components/Button';
import useStore from '../../../hooks/useStore'
import usePosSession from '../state/usePosSession'

import { ShoppingCart24, Pin16, Edit16, Add16, Subtract16, DeliveryParcel16, WarningAltFilled16, CheckmarkFilled16, Tag16, RowDelete16, ErrorFilled16, ShoppingCartPlus16, QueryQueue16, Barcode16 } from '@carbon/icons-react'
import ProfilePic from '../../../components/ProfilePic';
import Api from '../../../session/Api';
import Util from '../../../util/Util';
import UIUtil from '../../../util/UIUtil';
import useHover from '../../../hooks/useHover';
import ContinuousQuantityDialog from '../../pos/dialogs/ContinuousQuantityDialog';
import { ComposedModal, Link, ModalBody, ModalFooter, ModalHeader, NumberInput, Tag } from 'carbon-components-react';
import { SetCustomPriceDialog } from '../../pos/dialogs/set-custom-price-dialog';
import { getAccountRole } from '../../../session/SessionManager';
import { ACCOUNT_TYPE_WAREHOUSE_KEEPER } from '../../../constants/Constants';

const IconBtn = ({ icon, danger, positive, title, onClick }) => {
    const hover = useHover()
    return (
        <div onClick={onClick} data-tip={title} data-effect="solid" className={"work-list-item-shortcut-btn" + (danger ? "-danger" : positive ? "-positive" : "")} style={{ width: 45, height: 45, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
            {React.createElement(icon)}
            {/* <p style={{fontSize: 12, opacity: hover.isHover ? 0.65 : 0, transition: '250ms', paddingLeft: '0.25rem'}}>{title}</p> */}
        </div>
    )
}

const ItemActions = ({ onRemoveBtn, onSelectBtn }) => {
    return (<>
        <IconBtn icon={DeliveryParcel16} title="Set Quantity" onClick={onSelectBtn} />
        <IconBtn onClick={onRemoveBtn} icon={RowDelete16} danger title="Remove" />
    </>)
}


const getQuantityValue = item => {
    let quantity = item.quantityValue;
    let uomSymbol = 'x'
    if (item.continuousStockType) {
        if (Util.isNumberExist(item.displayUom)) {
            const uom = item.measurementType.unitOfMeasurements.filter(u => u.id == item.displayUom)[0]
            if (uom) {
                uomSymbol = ' ' + uom.symbol

                if (item.displayQuantityValue) {
                    quantity = item.displayQuantityValue;
                }
            }
        } else {
            uomSymbol = ' ' + item.measurementType.defaultUomSymbol
        }
    }

    return (<>
        {quantity}{uomSymbol}
    </>)
}

const CartItem = ({ allowCustomPricing, onEditPriceBtn, hasActions, removingItem, onClearBtn, onSelect, cartItem, scrollInto, warehouseKeeper, preloadedWork, onScrollDone }) => {
    const hover = useHover()

    const [key, setKey] = useState(Util.newTempId());
    const [highlight, setHighlight] = useState(false);

    const [quantityKey, setQuantityKey] = useState(Util.newTempId());
    const [quantityHighlight, setQuantityHighlight] = useState(undefined);

    const ref = createRef();


    const stockStatus = useMemo(() => {
        if (warehouseKeeper) {
            try {
                return preloadedWork.session.currentCartItems.find($ => $.id === cartItem.id)?.stockStatus
            } catch (e) { }
        }

        return null;
    }, [preloadedWork, warehouseKeeper, cartItem])

    useEffect(() => {
        if (scrollInto) {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            }
            onScrollDone();
            setKey(Util.newTempId())
            setHighlight(true);
        }
    }, [scrollInto])


    const [lastQuantity, setLastQuantity] = useState(undefined);
    useEffect(() => {
        if (lastQuantity !== undefined && lastQuantity != cartItem.quantityValue) {
            setQuantityKey(Util.newTempId())
            setQuantityHighlight(lastQuantity > cartItem.quantityValue ? 'quantity-decrease-anim' : 'quantity-increase-anim');
        }
        setLastQuantity(cartItem.quantityValue);
    }, [cartItem.quantityValue])

    let amount = cartItem.amount;
    if (cartItem.displayAmount) {
        amount = cartItem.displayAmount;
    }

    return (
        <div {...hover.init()}>
            <div key={key} ref={ref} style={{
                display: 'flex',
                animation: highlight ? 'scale-bounce-anim 750ms' : '',
                minHeight: cartItem.bundleProducts.length > 0 ? undefined : 65,
                fontSize: '.875rem', color: '#525252', borderBottom: '1px solid #e0e0e0', padding: '1rem .5rem 1.5rem', paddingLeft: '1rem'
            }}>
                {cartItem.bundleProducts.length > 0 ? (
                    <div style={{ flex: 3 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: -5 }}>
                            <ProfilePic size={30} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
                            {cartItem.name}
                        </div>
                        {cartItem.bundleProducts.map(bundleProduct => <p style={{ paddingLeft: '0.5rem', marginLeft: 30, opacity: 0.65, fontSize: 12 }}>
                            {bundleProduct.quantity + 'x ' + bundleProduct.name}
                        </p>)}
                    </div>
                ) : (
                    <div style={{ flex: 3, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <ProfilePic size={30} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
                        {cartItem.name}
                    </div>
                )}
                {!warehouseKeeper && <div style={{ flex: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 5 }}>

                    {(amount.price != amount.originalPrice && amount.originalPrice) ? (<>
                        <span style={{ textDecoration: 'line-through', color: 'red' }}>
                            <span style={{ color: 'black' }}>AED {amount !== null ? amount.originalPrice.toFixed(2) : (0).toFixed(2)}</span>
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tag16 style={{ marginRight: '0.25rem', fill: 'red' }} />
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}
                            </span>
                        </div>
                    </>) : (<>
                        <span style={{}}>AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}</span>
                    </>)}

                    {allowCustomPricing &&
                        <Link style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={onEditPriceBtn} renderIcon={Edit16}>Edit</Link>}
                </div>}
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: 5 }}>
                    <span key={quantityKey} style={{ animation: quantityHighlight ? quantityHighlight + ' 750ms' : '' }}>
                        {/* {cartItem.quantityValue}x */}
                        {getQuantityValue(cartItem)}
                    </span>
                </div>
                {!warehouseKeeper && <div style={{ flex: 2, display: 'flex', alignItems: 'center', gap: 5 }}>
                    AED {cartItem.totalAmount.price}
                </div>}

                {warehouseKeeper && <div style={{ flex: 2, }}>
                    {stockStatus ? (<>
                        {/* <div style={{ height: 15 }} /> */}
                        <div>
                            {stockStatus.needsMoreStock ? <Tag renderIcon={WarningAltFilled16} type="red">Not available</Tag> : <Tag renderIcon={CheckmarkFilled16} type="green">Inventory available</Tag>}
                        </div>
                        <div style={{ marginTop: '0.25rem' }}>
                            <p style={{ fontSize: 12, color: 'green', }}><strong>Available stock:</strong> +{stockStatus.stockAvailable}</p>
                            <p style={{ fontSize: 12, color: 'red', }}><strong>Required stock:</strong> -{stockStatus.stockRequired}</p>

                            {stockStatus.needsMoreStock &&
                                <p style={{ fontSize: 12, marginTop: '0.5rem' }}><strong>Stock Needed:</strong> {stockStatus.stockNeeded}</p>}
                        </div>
                        {/* {stockStatus.needsMoreStock && <p>{stockStatus.stockRequired}x</p>} */}
                    </>) : (<>
                    </>)}
                </div>}

                {/* {<div style={{flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button onClick={() => state.setShowQuantitySetDialog(cartItem)} className="bx--tooltip--hidden" style={{marginLeft: 5}} kind="tertiary" renderIcon={ShoppingCartPlus16} hasIconOnly data-tip="Set Quantity" />
                    <Button style={{height: 48, marginLeft: 5}} loading={removingItem === cartItem} onClick={() => UIUtil.confirm(() => onClearBtn(cartItem))} className="bx--tooltip--hidden" kind="danger--tertiary" renderIcon={RowDelete16} hasIconOnly data-tip="Remove Item" />
                </div>} */}
            </div>

            {hasActions && <div className="theme-bg" style={{
                width: '100%', height: hover.isHover ? 45 : 0, opacity: hover.isHover ? 1 : 0, paddingLeft: '1rem', paddingRight: '1rem', transition: '250ms', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', overflow: 'hidden',
                ...(removingItem === cartItem ? {
                    opacity: 0.65,
                    pointerEvents: 'none'
                } : {})
            }}>
                <label className="bx--label" style={{ color: 'white', margin: 0, flex: 1 }}>Actions</label>

                <ItemActions item={cartItem}
                    onSelectBtn={() => onSelect(cartItem)}
                    onRemoveBtn={() => UIUtil.confirm(() => onClearBtn(cartItem))} />
            </div>}
        </div>
    )
}


export default ({ sessionStore, hasActions, preventCustomPrice, preloadedWork }) => {
    const role = useMemo(() => getAccountRole(), [])
    const warehouseKeeper = role == ACCOUNT_TYPE_WAREHOUSE_KEEPER;

    const session = usePosSession(sessionStore)
    const [currentCartItems] = useStore(sessionStore, "currentCartItems")

    const [removingItem, setRemovingItem] = useState(undefined)
    const [selectedItem, setSelectedItem] = useState(undefined)
    const [newQuantityValue, setNewQuantityValue] = useState()

    const [repricingItem, setRepricingItem] = useState(undefined);

    useEffect(() => {
        if (selectedItem) {
            setNewQuantityValue(selectedItem.quantityValue)
        }
    }, [selectedItem])

    const onClearBtn = item => {
        setRemovingItem(item)
        session.updateCartItem(item.itemId, item.quantityValue, true, () => setRemovingItem(undefined))
    }

    return (
        <div>
            <div className="bx--structured-list-th dashboard-card" style={{ display: 'flex', paddingLeft: '1rem', borderBottom: '1px solid #e0e0e0', background: '#f4f4f4', position: 'sticky', top: 0, zIndex: 100 }}>
                {warehouseKeeper ? (<>
                    <div style={{ flex: 3 }}>
                        Item
                    </div>
                    <div style={{ flex: 1 }}>
                        Qty
                    </div>
                    <div style={{ flex: 2 }}>
                        Stock Analysis
                    </div>
                </>) : (<>
                    <div style={{ flex: 3 }}>
                        Item
                    </div>
                    <div style={{ flex: 2 }}>
                        Price
                    </div>
                    <div style={{ flex: 1 }}>
                        Qty
                    </div>
                    <div style={{ flex: 2 }}>
                        Total
                    </div>
                </>)}
            </div>

            {currentCartItems.map(cartItem => <CartItem key={cartItem.itemId + "-cart-item"} cartItem={cartItem} //state={state} 
                //scrollInto={cartItem === scrollIntoItem} onScrollDone={() => setScrollIntoItem(undefined)}
                allowCustomPricing={session.allowCustomPrice && !preventCustomPrice}
                removingItem={removingItem} onClearBtn={onClearBtn}
                onSelect={setSelectedItem}
                hasActions={hasActions}
                warehouseKeeper={warehouseKeeper}
                onEditPriceBtn={() => setRepricingItem(cartItem)}
                preloadedWork={preloadedWork}
            />
            )}

            {repricingItem && <SetCustomPriceDialog
                key={"set-custom-price-for-sales-cart"}
                cartItem={repricingItem}
                open onClose={() => setRepricingItem(false)}
                state={{
                    setCartItemCustomPrice(cartItemId, customPrice, clearanceCode, onDone, onRequireClearance) {
                        return session.setCartItemCustomPrice(cartItemId, customPrice, clearanceCode, onDone, onRequireClearance, () => setRepricingItem(false))
                    }
                }}
            />}

            <ContinuousQuantityDialog
                key="set-item-qty-dialog-continuous"
                cartItem={selectedItem}
                open={selectedItem && selectedItem.continuousStockType}
                onSet={({ quantityValue, displayUom }) => {
                    //posS.updateContinuousCartItem(selectedItem.itemId, quantityValue, displayUom);
                    session.updateContinuousCartItem(selectedItem.itemId, quantityValue, displayUom)
                    setSelectedItem(undefined)
                }}
                onClose={() => setSelectedItem(undefined)}
            />

            <ComposedModal key="set-item-qty-dialog" size="xs" open={selectedItem && !selectedItem.continuousStockType} onClose={() => setSelectedItem(undefined)}>
                <ModalHeader label="Cart Item" title="Set Quantity" />
                <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>

                    {selectedItem && selectedItem.continuousStockType ? (<>
                    </>) : (<>
                        <Button disabled={newQuantityValue <= 0}
                            onClick={() => setNewQuantityValue(newQuantityValue - 1)}
                            kind="danger" hasIconOnly renderIcon={Subtract16} className="bx--tooltip--hidden" data-tip="Subtract" />
                        <div>
                            <NumberInput
                                invalidText="Invalid number"
                                data-modal-primary-focus
                                size="xl"
                                value={newQuantityValue}
                                onChange={(e, { value }) => setNewQuantityValue(value)}
                                hideSteppers
                                hideLabel
                            />
                        </div>
                        <Button hasIconOnly size="lg" data-tip="Add"
                            onClick={() => setNewQuantityValue(newQuantityValue + 1)}
                            renderIcon={Add16} className="bx--tooltip--hidden" />
                    </>)}
                </ModalBody>
                <ModalFooter

                >
                    <Button kind="secondary" onClick={() => setSelectedItem(undefined)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={(selectedItem !== undefined && newQuantityValue == selectedItem.quantityValue) || (isNaN(newQuantityValue) || newQuantityValue === "")}
                        onClick={() => {
                            //SAME CODE ABOVE FOR OTHER STOCK TYPE
                            const diff = newQuantityValue - selectedItem.quantityValue;
                            let removing = diff < 0;

                            session.updateCartItem(selectedItem.itemId, Math.abs(diff), removing);
                            setSelectedItem(undefined)
                        }}>
                        Set
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </div>
    )
}