import { SidePanel } from "../../../../../templates/draft/components/side-panel"
import Portal from "../../../../../util/Portal"
import { ModifyOrderPos } from "../../../../pos/restaurant/components/modify-order-view"

const height = 'calc(100vh - 16rem - 2px)'

export function KotPos({ fnbEvent }) {

    return (
        <div style={{ height, width: '100%' }}>
            <Portal>
                <div style={{ position: 'fixed', left: 'calc(1rem + 1px)', top: 'calc(12rem + 1px)', width: 'calc(100vw - 2rem - 2px)', height, borderBottomLeftRadius: 15, borderBottomRightRadius: 15, overflow: 'hidden' }}>
                    <ModifyOrderPos fnbEvent={fnbEvent} orderId={fnbEvent.orderId} updateOrder={() => { }} />
                </div>
            </Portal>
        </div>
    )
}