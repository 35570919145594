import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { OBJECT_TYPE_ACCOUNT } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import { BalanceView } from '../../views/accounting/BalanceView';
import AllSubsidiaryReportView from '../accounting-reports/AllSubsidiaryReportView';
import { ReportPage } from '../../reporting-engine/base/report-page';
import { toBase64 } from '../../util/Util';

const SubsidiaryReport = ({ item }) => {
    //return <AllSubsidiaryReportView itemId={item.id} itemType={OBJECT_TYPE_ACCOUNT} />
    const query = useMemo(() => toBase64(JSON.stringify({
        selectedSubsidiaryAccountLedger: { id: item.id, name: item.fullName },
        filters: [
            { id: "subsidiaryId", property: "subsidiaryId", operator: "EQ", value: item.id },
        ]
    })), [item])
    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ height: '100vh', width: '100%' }}>
                <ReportPage reportName="LedgerReport" overrideQuery={query} componentMode />
            </div>
        </div>
    )
}

class UserDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_ACCOUNT, listener)
            } else {
                Api.getItem(OBJECT_TYPE_ACCOUNT, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_ACCOUNT,
                    itemResult: this.state.itemResult,
                    pagePath: "/staff-users/",
                    history: this.props.history,
                    hasCustomBtn: <>
                        <div style={{ width: '1rem' }} />
                        <BalanceView item={this.state.itemResult.item} subsidiaryType={OBJECT_TYPE_ACCOUNT + ""} />
                    </>,
                    customTabs: [
                        {
                            title: "Ledger Report",
                            component: SubsidiaryReport
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(UserDetailPage);