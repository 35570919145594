import { ViewFilled16 } from '@carbon/icons-react'
import { useCallback, useMemo, useState } from 'react';
import Util from '../../util/Util';


function useHint() {
    const [message, setMessage] = useState("");
    return {
        message: Util.isStringExists(message) ? message : null,
        init: (message) => ({
            onMouseEnter: () => setMessage(message),
            onMouseLeave: () => setMessage("")
        })
    }
}

function Button({ hint, label, active, onClick, children }) {
    return <button {...hint.init(label)} style={{ width: 30 }} onClick={onClick} data-active={active} className='shipment-item-menu-button'>{children}</button>
}

export function Menu({ sideView, setSideView }) {
    const hint = useHint();
    const MenuButton = useCallback(props => <Button {...props} hint={hint} />, [])

    return (<>
        {hint.message && <p key={hint.message + 'shipment-toolbar-menu'} style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', animation: '250ms shipment-item-menu-hint-fade-in' }}>
            {hint.message}
        </p>}
        <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
            <MenuButton label="Preview" active={sideView === "preview"} onClick={() => setSideView(sideView !== "preview" ? "preview" : undefined)}><ViewFilled16 /></MenuButton>

        </div>
    </>)
}