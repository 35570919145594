
import {
    Document32, Notebook16, ArrowLeft16, Report32, Run32, Calendar32, DocumentPdf32, ErrorFilled16, Currency32,
    Currency16, ArrowRight16, Notebook32
} from '@carbon/icons-react'
import { ButtonSet, Link as DLink, Tile } from "carbon-components-react"
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { withLoadablePageWithParams } from '../../base/Page';
import Button from '../../components/Button';
import ProfilePic from '../../components/ProfilePic';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_INVOICE_DOCUMENT } from '../../constants/ObjectTypes';
import { InvoiceDocumentStatusIndicator, INVOICE_DOCUMENT_STATUS_NOT_PAID } from '../../domain/invoice-document';
import useApi from '../../hooks/useApi';
import { InvoiceDocument } from '../../pdfs/invoice-document/InvoiceDocumentPdf';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util';
import NoteView from '../../views/notes/NoteView';
import TransactionEditor from '../transaction/TransactionEditor';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const getAmountStatus = invoice => {
    const amount = invoice.amount;
    const amountPaid = invoice.amountPaid;

    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Fully Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;

        status += " (AED " + amountLeft.toFixed(2) + " left)";
    }

    return { status, statusColor }
}

const Section = ({ id, children, icon, title, subtitle, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const TransactionItem = ({ invoice, transaction }) => {
    const [expanded, setExpanded] = useState(false);

    const paymentMethodHeight = 80 + convertRemToPixels(0.5);

    let dividedAmount = 0;
    if (transaction.againstItems && transaction.againstItems.length !== undefined) {
        for (const item of transaction.againstItems) {
            if (item.invoiceDocumentId == invoice.id) {
                dividedAmount = item.dividedAmount;
                break;
            }
        }
    }

    return (
        <div style={{
            transition: '250ms', height: expanded ? 75 + (115 + (paymentMethodHeight * transaction.paymentMethods.length - 1)) : 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>Transaction (voucher no: {Util.getVoucherNumber(transaction.id)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>AED {dividedAmount.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / AED {transaction.amount.toFixed(2)} (Total Transaction Amount)</p>
                    </div>



                    {/* <h2>AED {transaction.amount.toFixed(2)}</h2> */}
                </div>
                <p style={{ fontSize: 12, opacity: 0.65 }}>View details</p>
                <ArrowRight16 style={{ opacity: 0.65, transition: '250ms', marginLeft: '0.25rem', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
            </div>
            <div style={{ height: 115 + (paymentMethodHeight * transaction.paymentMethods.length - 1), paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, overflow: 'auto' }} >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link target="_blank" to={"/transaction/" + transaction.id} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Open Transaction</Button>
                    </Link>
                </div>

                <TransactionEditor
                    hideTotal
                    readOnly showColorForIncome
                    defaultPaymentMethods={transaction.paymentMethods}
                    currency={"AED"}
                    //incoming={!isSourceExternal}
                    incoming
                    totalAmount={transaction.amount} />
            </div>
        </div>
    )
}



const Title = ({ invoice }) => {
    const history = useHistory();

    const [loading, revoke] = useApi(listener => Api.revokeInvoiceDocument(invoice.id, listener), () => window.location.reload());
    return (<>
        <DLink onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Document32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <h1 style={{ flex: 1 }}>Invoice Document</h1>

            {invoice.status == INVOICE_DOCUMENT_STATUS_NOT_PAID ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Button loading={loading} onClick={() => UIUtil.confirm(revoke)} kind="danger" renderIcon={ErrorFilled16} style={{ width: 195 }}>
                        Revoke
                    </Button>
                    <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : (
                <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
            )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <InvoiceDocumentStatusIndicator status={invoice.status} />
        </div>
    </>)
}

const Amount = ({ invoice }) => {
    const amount = invoice.amount;
    const amountPaid = invoice.amountPaid;
    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;
    }

    return (<>
        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
            <p>
                AED {amount.toFixed(2)}
            </p>
        </div>

        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
            <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                AED {amountPaid.toFixed(2)}
            </p>
        </div>
    </>)
}

const Info = ({ invoice }) => (
    <Section icon={Report32} title="Document Info">
        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
            <p style={{}}>{Util.getVoucherNumber(invoice.id)}</p>
        </div>

        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Payer'}</label>
            <Link target="_blank" to={getObjectTypeUrl(invoice.payerType) + '/' + invoice.payerId}><p style={{}}>{invoice.payerName}</p></Link>
        </div>

        <Amount invoice={invoice} />
    </Section>

)

const Initiation = ({ invoice }) => (
    <Section icon={Run32} title="Initiation">
        <label style={{}} className="bx--label">Initiator</label>
        <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + invoice.createdByUserId}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, invoice.createdByUserId)} />
                <p>{invoice.createdByFullName}</p>
            </div>
        </Link>

        <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar32 style={{ marginRight: '0.5rem' }} />
            <p>{Util.getDate(invoice.dateCreated)}</p>
        </div>
    </Section>
)

const Transactions = ({ invoice }) => (
    <Section icon={Currency32} title="Transactions" //subtitle={'Total Amount Paid: AED ' + parseFloat(stockFlow.amountPaid).toFixed(2)}
        subtitle={getAmountStatus(invoice).status} subtitleColor={getAmountStatus(invoice).statusColor}
    >
        {invoice.transactions.map(transaction => <TransactionItem key={transaction.id} invoice={invoice} transaction={transaction} />)}
    </Section>
)

const DocumentPdf = ({ invoice }) => (<>
    <Section icon={DocumentPdf32} title="Document PDF" />
    <InvoiceDocument invoice={invoice.invoice} />
    <div style={{ height: '6rem' }} />
</>)

const View = ({ payload: invoice }) => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
            <div style={{ width: '75vw' }}>
                <Title invoice={invoice} />
                <Info invoice={invoice} />
                <Initiation invoice={invoice} />
                {invoice.transactions !== null && invoice.transactions.length > 0 &&
                    <Transactions invoice={invoice} />}
                <DocumentPdf invoice={invoice} />

                <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                    <NoteView objectId={invoice.id} objectType={OBJECT_TYPE_INVOICE_DOCUMENT} />
                    <div style={{ height: '6rem' }} />
                </Section>
            </div>
        </div>
    )
}

export default withLoadablePageWithParams(params => listener => Api.getInvoiceDocument(params.itemId, listener), View)

