import { MultiSelect } from "carbon-components-react";
import { ArrayInput } from "../../../../components/array-input";
import { AwareKVStoreEditor, KVStoreEditor } from "../../../../components/KVStoreEditor";
import { LEAD_FEELINGS, LEAD_FEELING_PENDING } from "../../../../domain/lead";
import Api from "../../../../session/Api";
import { FormSection } from "../../../../templates/form/form";
import { FormDialog } from "../../../../templates/form/form-dialog";
import { COUNTRY_LIST } from "../../../../util/country-list";
import { UserAssignation } from "../../../activities/components/user-assignation";
import { hasCapabilitySupport } from "../../../../app/Capabilities";

const Tags = ({ value, setValue, tags }) => {
    const items = (value ?? [])
        .map(item => tags.find(tag => item.tagId === tag.id))
        .filter(item => item !== undefined);
    return (
        <MultiSelect
            label="select tag"
            titleText="Lead Tags"
            light

            initialSelectedItems={items}
            selectedItems={items}
            onChange={e => setValue(e.selectedItems.map(item => ({ tagId: item.id })))}

            // compareItems={(a, b) => 0}
            // size="sm"
            hideLabel
            itemToString={i => i ? i.value : ""}
            items={tags}
        />
    )
}

const Assignations = ({ value, setValue, tags, allUsers }) => (
    <UserAssignation
        title="User Assignations" value={value ?? []} onValueChanged={setValue}
        tags={tags} allUsers={allUsers}
    />
)


function FormView(form, payload) {
    const { TextField, TextAreaField, SwitchField, ComboBoxField, CustomField, ExtField } = form;
    return (
        <div>
            {hasCapabilitySupport("cargo") && <>
                <ComboBoxField title="CRN" fieldKey="crnId" options={payload.crns} />
            </>}
            <ComboBoxField title="Source" fieldKey="sourceId" options={payload.leadSources.map(item => ({ id: item.id, title: item.value }))} />
            <SwitchField title="Feeling" fieldKey="feeling" options={LEAD_FEELINGS} />
            <CustomField fieldKey="tagAssignations" childrenProps={{ tags: payload.tags }}>
                {Tags}
            </CustomField>

            <FormSection title="User Assignations">
                <CustomField fieldKey="assignations" childrenProps={{ tags: payload.userTags, allUsers: payload.users }}>
                    {Assignations}
                </CustomField>
            </FormSection>

            {/* <pre><code>{JSON.stringify(form.getFormData(), null, 2)}</code></pre> */}
        </div>
    )
}

export function FlowInfoDialog({ leadId, info, setInfo, payload, open, onClose }) {

    return (
        <FormDialog open={open} onClose={onClose} label="Updating" title="Lead Flow Info"
            item={info} setItem={setInfo} payload={payload} preventCloseOnClickOutside
            saveApi={(data, listener) => Api.updateLeadFlowInfo(leadId, data, listener)}>
            {FormView}
        </FormDialog>
    )
}