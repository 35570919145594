import { useEffect } from "react";


export function useWarningOnClose(enabled) {
    //it wont work if page is loaded directly, this needs to be called after the first click
    useEffect(() => {
        if (enabled) {
            window.onbeforeunload = e => {
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            };
        }
        return () => window.onbeforeunload = undefined
    }, [enabled])
}