import { ClickableTile, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import ImageView from '../../components/ImageView'
import { OBJECT_TYPE_PRODUCT_BUNDLE } from '../../constants/ObjectTypes'
import Api from '../../session/Api'
import UIUtil from '../../util/UIUtil'
import { ErrorFilled16, ArrowRight16 } from '@carbon/icons-react'
import Portal from '../../util/Portal'

const Item = ({ item, onClick }) => (
    <div style={{ marginBottom: '0.5rem', display: 'flex' }}>
        <ClickableTile light handleClick={onClick} style={{ flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <ImageView src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, item.id)} style={{ height: 64, width: 64, marginRight: 10, objectFit: 'contain', objectPosition: 'center', backgroundColor: 'white' }} />
                <div style={{ flex: 1 }}>
                    <h4 style={{ fontSize: 14 }}>{item.name}</h4>
                    {item.productList.map(product => <p key={product.id} style={{ fontSize: 12, opacity: 0.65, }}>{product.value}</p>)}
                </div>

                {/* <p style={{fontSize: 12}}>{customerName}</p>
                <div style={{width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: 'black', opacity: 1}} />
                <p style={{fontSize: 12}}>{totalItems}</p>
                <div style={{width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: 'black', opacity: 1}} />
                <p style={{fontSize: 12, color: 'green'}}>{totalAmount}</p> */}

                <div style={{ width: 10 }} />
                <ArrowRight16 />
            </div>
        </ClickableTile>
    </div>
)

export default ({ open, onClose, productId, onReplace }) => {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])

    useEffect(() => {
        setLoading(true)
        setItems([])

        Api.getGroupsContainingProduct(productId, response => {
            setLoading(false)
            if (response.status === true) {
                setItems(response.payload)
            } else {
                UIUtil.showError(response.message)
                onClose();
            }
        })
    }, [productId])

    const content = (
        <div style={{ overflow: 'auto', maxHeight: '50vh' }}>
            {items.map(item => <Item key={item.id} item={item} onClick={() => {
                onClose();
                onReplace(item)
            }} />)}
        </div>
    )

    return (
        <Portal>
            <ComposedModal size="md" open={open} onClose={onClose}>
                <ModalHeader label="Replace Product" title="Product Groups" />
                <ModalBody style={{ paddingRight: '1rem' }}>
                    {loading ?
                        (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <InlineLoading style={{ width: 'auto' }} />
                            </div>
                        ) : (
                            content
                        )}
                </ModalBody>
                <ModalFooter style={{ justifyContent: 'flex-end' }}>
                    <Button kind="secondary" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </Portal>
    )
}