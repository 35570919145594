import { Archive16, Fork16, Dashboard24, Calendar16, DocumentAttachment16, SplitScreen16, Education16, Store16 } from '@carbon/icons-react'
import { Button, Tab, Tabs, Tag } from 'carbon-components-react';
import { Link, useHistory } from 'react-router-dom';
import { getAccount } from '../../../session/SessionManager';

function MenuButton({ label, active, onClick, fullWidth, children }) {
    return <button style={{ width: fullWidth ? 30 : 100, paddingInline: fullWidth ? 0 : undefined }} onClick={onClick} data-active={active} className='report-engine-menu-button-light'>{children}</button>
}


export function Header({ tabIndex, setTabIndex }) {
    const history = useHistory();
    return (
        <div className='lead-flow-tabs hidden-tab-content' style={{
            paddingInline: '1rem', height: '3rem', width: '100%', display: 'flex', alignItems: 'center', color: 'black', borderBottom: '1px solid #00000010',
            boxShadow: '0px 20px 25px -5px rgba(0,0,0,0.1) , 0px 10px 10px -5px rgba(0,0,0,0.04) ',
            background: '#F7F7F8AA', backdropFilter: 'blur(16px) saturate(200%)',
            position: 'sticky', top: 0, left: 0,
            zIndex: 8000 - 10
        }}>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Dashboard24 />
                <h4 style={{ fontSize: 18, marginLeft: '0.25rem', fontWeight: 'bold' }}>Dashboard</h4>
                <h4 style={{ fontSize: 12, marginLeft: '1rem', marginTop: 4, opacity: 0.65 }}>Welcome {getAccount().fullName}</h4>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}> */}
                <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                    <MenuButton active={tabIndex === 0} onClick={() => setTabIndex(0)}><Education16 style={{ marginRight: 2 }} /> LMS</MenuButton>
                    <MenuButton active={tabIndex === 1} onClick={() => setTabIndex(1)}><Store16 style={{ marginRight: 2 }} /> Retail</MenuButton>
                </div>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Link to="/system-logs" style={{ textDecoration: 'none' }}>
                    <Button kind="ghost" renderIcon={Archive16} size="sm" style={{ borderRadius: 8, textDecoration: 'none' }}>System Logs</Button>
                </Link>
            </div>
        </div>
    )
}