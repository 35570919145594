import { useEffect } from "react";
import { MainApp } from "../../../App";
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { isRivas } from "../../../app/app-util";
import { renderNode } from "../../../components/imperative-render";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_CASHIER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { LockScroll } from "../../../hooks/useLockScroll";
import DeliverableItemPage from "../../../pages/sales-order/DeliverableItemPage";
import StockFlowItemPage from "../../../pages/stock-flow/StockFlowItemPage";
import TransactionItemPage from "../../../pages/transaction/TransactionItemPage";
import { getAccountRole } from "../../../session/SessionManager"
import { SidePanel } from "../../../templates/draft/components/side-panel";
import UIUtil from "../../../util/UIUtil";
import { DateRange } from "../../components/basic-filter/date-range";
import { DateTimeRange } from "../../components/basic-filter/date-time-range";
import { Divider } from "../../components/basic-filter/divider";
import { MultiSelect } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box";
import { startOfDay, addDays } from 'date-fns';
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";

function openTx(txId) {
    renderNode(onClose => (
        <SidePanel md2 backBtn onClose={onClose}>
            <TransactionItemPage componentMode pagePathParamsOverride={{ transactionId: txId }} />
        </SidePanel>
    ))
}

export const PaymentMethodDetail = {
    filter: Filter,

    onAction: (action, data, history) => {
        switch (action) {
            case "voucherNo":
                openTx(data.id)
                break;

            case "against":
                if (data.againstLink && data.againstLink.split?.(':')?.length === 2) {
                    const [type, id] = data.againstLink.split(":")
                    switch (type) {
                        case "sales-order":
                            renderNode(onClose => (
                                <SidePanel md2 backBtn onClose={onClose}>
                                    <LockScroll />
                                    <DeliverableItemPage orderIdOverride={id} mainApp={MainApp.instance} />
                                </SidePanel>
                            ))
                            break;
                        case "stock-flow":
                            renderNode(onClose => (
                                <SidePanel md2 onClose={onClose}>
                                    <LockScroll />
                                    <StockFlowItemPage fullWidth pagePathParamsOverride={{ stockFlowId: id }} overrideBacktToListBtn={onClose} />
                                </SidePanel>
                            ))
                            break;
                        default:
                            openTx(data.id)
                            break;
                    }
                } else {
                    openTx(data.id)
                }
                break;
        }
    },

    isAvailable: () => {
        const role = getAccountRole();
        if (hasCapabilitySupport("restaurant")) {
            return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT || role == ACCOUNT_TYPE_CASHIER;
        } else {
            return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
        }
    },
}

const DATE_BY_OPTS = [
    "Receipt Date",
    "Out of Range Orders",
    "Sales/Order Date",
    "Order Date Only",
    "Sales Date Only",
]

const AGS_OPTS = [
    "Against Order Only",
    "Credit/Debits Only",
]

const ORDER_OPTS = [
    "Orders/sales in range",
    "Orders/sales out of range",
]



function Filter({ endpoint, state }) {
    const [filters, setFilters] = state.use("filters", [])
    useEffect(() => {
        if (!filters.length) {
            setFilters([
                { id: "date-start", property: "date", operator: "GTE", value: startOfDay(new Date()).getTime() },
                { id: "date-end", property: "date", operator: "LT", value: startOfDay(addDays(new Date(), 1)).getTime() },
            ])
        }
    }, [])
    return (<>
        <DateTimeRange state={state} property="date" skipAutoFilter />
        {hasCapabilitySupport("restaurant") &&
            <MultiSelect skipAutoFilter isMulti={false} placeholder="Receipt Date" fieldKey={"dateBy"} property="dateBy" options={DATE_BY_OPTS} state={state} />}
        {/* <CheckboxFilter state={state} filterId="txOutOfRange" label="Show out of range only?" filter={TX_OUT_OF_RANGE} /> */}

        <Divider />
        <div style={{ height: 30, width: 250 }}>
            <MultiSelect isMulti={false} placeholder="Store" fieldKey="storeName" options={endpoint.stores.map(item => item.value)} state={state} property="storeName" />
        </div>
        {hasCapabilitySupport("restaurant") && <>
            <div style={{ height: 30, width: 250 }}>
                <MultiSelect isMulti={false} skipAutoFilter placeholder="Against Filter" fieldKey="againstFilter" property="againstFilter" options={AGS_OPTS} state={state} />
            </div>
            <div style={{ height: 30, width: 250 }}>
                <MultiSelect isMulti={false} skipAutoFilter placeholder="Order Filter" fieldKey="orderFilter" property="orderFilter" options={ORDER_OPTS} state={state} />
            </div>
        </>}
    </>)
}
