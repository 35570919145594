import { Button, ComboBox, ComposedModal, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput } from 'carbon-components-react'
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'

import Util from '../../../util/Util'

export default ({open, onClose, onSet, cartItem}) => {
    const [quantityValue, setQuantityValue] = useState()
    const [selectedUOM, setSelectedUOM] = useState()

    const measurementType = cartItem ? cartItem.measurementType : undefined

    const uoms = useMemo(() => measurementType ? [{ 
        id: 0, symbol: measurementType.defaultUomSymbol, name: measurementType.defaultUomName }].concat(measurementType.unitOfMeasurements) : [{ id: 0, symbol: '', name: '' }], [measurementType])


    useEffect(() => {
        if (cartItem) {
            setQuantityValue(cartItem.displayQuantityValue)
            if (Util.isNumberExist(cartItem.displayUom)) {
                setSelectedUOM(measurementType.unitOfMeasurements.filter(u => u.id == cartItem.displayUom)[0])
            } else {
                setSelectedUOM(uoms[0])
            }
        }
    }, [cartItem])
    useEffect(() => {
        if (!selectedUOM) {
            setSelectedUOM(uoms[0])
        }
    }, [selectedUOM])

    const onSetBtn = () => {
        onSet({
            quantityValue,
            displayUom: selectedUOM ? selectedUOM.id : 0
        })
    }

    return (
        <ComposedModal className="modal-that-supports-combo-box" key="apply-coupon-dialog" size="md" open={open} onClose={onClose}>
            <ModalHeader label="Cart Item" title="Set Quantity" />
            <ModalBody style={{display: 'flex', gap: '0.5rem', paddingRight: '1rem', }}>
                <NumberInput
                style={{flex: 1}}
                label="Quantity"
                invalidText="Invalid number"
                data-modal-primary-focus
                size="xl"
                value={quantityValue}
                onChange={(e, { value }) => setQuantityValue(value)}
                hideSteppers
                //hideLabel
                />


                <div style={{flex: 1, marginTop: -3}}>
                    <ComboBox
                    titleText="UOM"
                    size="xl"
                    light
                    onChange={item => setSelectedUOM(item.selectedItem)}
                    value={!selectedUOM ? uoms[0].symbol : selectedUOM.symbol}
                    itemToString={item => item.symbol}
                    selectedItem={selectedUOM ? selectedUOM : uoms[0]}
                    items={uoms}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button 
                disabled={cartItem ? ((cartItem.displayQuantityValue == quantityValue && cartItem.displayUom == (selectedUOM ? selectedUOM.id : 0)) || isNaN(quantityValue) || quantityValue == '') : false}
                onClick={onSetBtn}>
                    Set
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}