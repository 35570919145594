
import { ComboBox, ContentSwitcher, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16, FlowConnection16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import { LogicNodeEditorDialog } from '../../../views/logic-node-editor/LogicNodeEditorDialog';
import Util from '../../../util/Util';
import TimePicker from '../../../components/TimePicker';


const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [optionLabel, setOptionLabel] = useMultiInputState(input, "optionLabel", onInputUpdate)

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div style={{ flex: 1 }}>
                <TextInput size="lg" labelText="Option" value={optionLabel} onChange={e => setOptionLabel(e.target.value)} />
            </div>

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const OptionListEditor = ({ value: defaultItems, setValue: onUpdate }) => {
    return (
        <div style={{ paddingInline: '0.5rem' }}>
            <MultipleInputEditor defaultInputs={[]} inputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
        </div>
    )
}