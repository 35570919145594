import { ComboBox, ContentSwitcher, InlineLoading, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';
import Button from './Button';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved, options }) => {
    const [courseId, setCourseId] = useMultiInputState(input, 'courseId', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <ComboBox style={{ width: 350 }}
                size='lg' titleText="Course"
                items={options} itemToString={item => item?.value ?? ""}
                selectedItem={options.find(item => item.id === courseId)}
                onChange={e => setCourseId(e.selectedItem?.id ?? 0)} />

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const TutoringCoursesEditor = ({ defaultItems, onUpdate }) => {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([])

    useEffect(() => {
        setLoading(true)
        Api.getTutoringCoursesOptions(response => {
            if (response.status === true) {
                setOptions(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError("Failed to load tutoring courses")
            }
        })
    }, [])

    if (loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    return (
        <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} options={options} />
    )
}