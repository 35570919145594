import React, { useEffect, useState } from 'react'
import Util from '../util/Util'

const useLocalStorage = (key, defValue) => {
    const currentValue = localStorage.getItem(key)
    const [value, setValue] = useState(Util.isStringExists(currentValue) ? currentValue : defValue)
    useEffect(() => {
        localStorage.setItem(key, value)
    }, [value])
    return [value, setValue]
}

export const useTypeLocalStorage = (key, toString, fromString, defValue) => {
    const [value, setValue] = useLocalStorage(key, toString(defValue));
    return [fromString(value), (arg => {
        if (typeof arg == 'function') {
            setValue(toString(arg(fromString(value))))
        } else {
            setValue(toString(arg))
        }
    })]
}

export const useBooleanLocalStorage = key => {
    return useTypeLocalStorage(key, v => v === true ? "true" : "false", s => s === "true", false);
}

export const useObjectLocalStorage = key => {
    return useTypeLocalStorage(key, v => JSON.stringify(v), s => JSON.parse(s), []);
}

export const useNonArrayLocalStorage = (key, def) => {
    return useTypeLocalStorage(key, v => JSON.stringify(v), s => JSON.parse(s), def);
}

export const useNumberLocalStorage = (key, def) => {
    return useTypeLocalStorage(key, v => v + "", s => parseFloat(s), def);
}

export const useStrLocalStorage = (key, def) => useLocalStorage(key, def);

export default key => useLocalStorage(key, "");