import React from 'react'
import Page from '../../../base/Page';
import CustomComboBox from '../../../components/CustomComboBox';
import Api from '../../../session/Api';

import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16,
    AirlinePassengerCare32,
    User32,
    Enterprise32,
    Building32,
    Education32,
    UserSpeaker32,
    BuildingInsights_332
} from '@carbon/icons-react'
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';
import { getObjectTypeName, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_STORE, OBJECT_TYPE_STUDENT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TUTOR, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from '../../../constants/ObjectTypes';
import { ComboBox, RadioTile, TileGroup } from 'carbon-components-react';
import Util, { flattenItems } from '../../../util/Util';
import { hasCapabilitySupport } from '../../../app/Capabilities';

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    // <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginTop: '0.5rem', textAlign: 'center' }}>{title}</h4>
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div> */}
        {/* <p style={{marginTop: '0.5rem', fontSize: 12, opacity: 0.65}}>
            {desc}
        </p>  */}
    </div>
)



class SubsidiaryLedgerReportPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            accountTree: [],
            selectedAccount: undefined,

            accountTypeValue: OBJECT_TYPE_CUSTOMER,
            accountIdValue: 0,
            accountPickerKey: Util.newTempId(),

            suppliers: [],
            customers: [],
            staff: [],
            vendors: [],
            stores: [],
            venues: [],
            students: [],
            tutors: [],
            movieDistributors: [],
        }
    }

    onPageStart() {

        this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => {
            // console.log(this.props.location.state.defaultAccountId)
            let account = undefined;
            if (this.props.location.state && this.props.location.state.defaultAccountId) {
                account = flattenItems(payload.accountTree)
                    .find(item => item.id == this.props.location.state.defaultAccountId)


                // mainLoop: for (const group of payload.accountTree) {
                //     for (const subGroup of group.items) {
                //         for (const item of subGroup.items) {
                //             if (item.id == this.props.location.state.defaultAccountId) {
                //                 account = item;
                //                 break mainLoop;
                //             }
                //         }
                //     }
                // }
            }

            return {
                accountTree: payload.accountTree,
                suppliers: payload.suppliers,
                customers: payload.customers,
                vendors: payload.vendors,
                stores: payload.stores,
                venues: payload.venues,
                staff: payload.staff,
                selectedAccount: account,
                students: payload.students,
                tutors: payload.tutors,
                movieDistributors: payload.movieDistributors
            }
        })
    }

    canGenerateReport() {
        return this.state.selectedAccount !== undefined && this.state.accountIdValue > 0;
    }

    onGenerateBtn() {
        this.props.history.push(`/subsidiary-ledger-report/${this.state.selectedAccount.id}/${this.state.accountIdValue}/${this.state.accountTypeValue}`)
    }

    getAccountTypeName() {
        return getObjectTypeName(parseInt(this.state.accountTypeValue));
    }

    getAccountList() {
        switch (this.state.accountTypeValue) {
            case OBJECT_TYPE_CUSTOMER:
                return this.state.customers;
            case OBJECT_TYPE_SUPPLIER:
                return this.state.suppliers;
            case OBJECT_TYPE_ACCOUNT + "":
                return this.state.staff;
            case OBJECT_TYPE_VENDOR:
                return this.state.vendors;
            case OBJECT_TYPE_STORE:
                return this.state.stores;
            case OBJECT_TYPE_VENUE:
                return this.state.venues;
            case OBJECT_TYPE_STUDENT:
                return this.state.students;
            case OBJECT_TYPE_TUTOR:
                return this.state.tutors;
            case OBJECT_TYPE_MOVIE_DISTRIBUTOR:
                return this.state.movieDistributors;
        }
    }

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>Subsidiary Ledger</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title="Account">
                        <div style={{ height: 40 }}>
                            <CustomComboBox items={this.state.accountTree} selectedItem={this.state.selectedAccount} onSelectedItemUpdate={item => this.setState({ selectedAccount: item })} />
                        </div>
                    </Section>

                    <Section icon={Number_232} title="Subsidiary Account">
                        <TileGroup className="horizontal-tile-radio centered-content-tile" valueSelected={this.state.accountTypeValue} onChange={value => this.setState({ accountTypeValue: value, accountIdValue: 0, accountPickerKey: Util.newTempId() })}>
                            <RadioTile value={OBJECT_TYPE_CUSTOMER}>
                                <RadioItem icon={AirlinePassengerCare32} title="Customer" desc="accounts" />
                            </RadioTile>
                            {hasCapabilitySupport("lms") &&
                                <RadioTile value={OBJECT_TYPE_STUDENT}>
                                    <RadioItem icon={Education32} title="Student" desc="accounts" />
                                </RadioTile>}
                            {hasCapabilitySupport("lms") &&
                                <RadioTile value={OBJECT_TYPE_TUTOR}>
                                    <RadioItem icon={UserSpeaker32} title="Tutor" desc="accounts" />
                                </RadioTile>}
                            {hasCapabilitySupport("cinema") &&
                                <RadioTile value={OBJECT_TYPE_MOVIE_DISTRIBUTOR}>
                                    <RadioItem icon={BuildingInsights_332} title="Movie Distributor" desc="accounts" />
                                </RadioTile>}
                            <RadioTile value={OBJECT_TYPE_SUPPLIER}>
                                <RadioItem icon={Delivery32} title="Supplier" desc="accounts" />
                            </RadioTile>
                            <RadioTile value={OBJECT_TYPE_VENDOR}>
                                <RadioItem icon={Enterprise32} title="Vendor" desc="accounts" />
                            </RadioTile>
                            <RadioTile value={OBJECT_TYPE_ACCOUNT + ""}>
                                <RadioItem icon={User32} title="Staff User" desc="accounts" />
                            </RadioTile>
                            {hasCapabilitySupport("thirdPartyPos") &&
                                <RadioTile value={OBJECT_TYPE_VENUE}>
                                    <RadioItem icon={Building32} title="Venues" desc="venues" />
                                </RadioTile>}
                            {/* <RadioTile value={OBJECT_TYPE_STORE}>
                                <RadioItem icon={Store32} title="Stores" desc="stores" />
                            </RadioTile> */}
                        </TileGroup>

                        <div style={{ height: '1rem' }} />
                        <ComboBox
                            key={this.state.accountPickerKey}
                            titleText={this.getAccountTypeName()}
                            items={this.getAccountList()}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.getAccountList().filter(item => item.id == this.state.accountIdValue)[0]}
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ accountIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, accountPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ accountIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }} />
                    </Section>

                    <Section icon={Number_332} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.onGenerateBtn.bind(this)} disabled={!this.canGenerateReport()} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

}

export default withRouter(SubsidiaryLedgerReportPage);