
import { FunctionMath20, Add16, ErrorFilled16, CheckmarkFilled16 } from '@carbon/icons-react'
import { ComboBox, ContentSwitcher, Switch, TextInput } from 'carbon-components-react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../../components/Button';
import { ButtonPopover } from '../../../components/button-popover';
import { CodeEditorInput } from '../../../components/code-editor/code-editor-input';
import Util from '../../../util/Util';
import { FormulaEditor } from '../formula/formula-editor';


const ValueTag = ({ availableProperties, formulaItem, label, value, darkMode, onRemove, onUpdate }) => {
    const [visible, setVisible] = useState(false);
    const [openInvalidate, setOpenInvalidate] = useState(() => Util.newTempId());

    const [title, setTitle] = useState(formulaItem?.name ?? "")
    const [formula, setFormula] = useState(formulaItem?.formula ?? "");
    const [mode, setMode] = useState(formulaItem?.mode ?? "BASIC");

    useEffect(() => {
        if (visible) {
            setTitle(formulaItem.name)
            setFormula(formulaItem.formula)
            setMode(formulaItem.mode)
        }
    }, [visible])
    useEffect(() => {
        setFormula("")
    }, [mode])



    const onCreateBtn = () => {
        onUpdate({ id: formulaItem.id, name: title, formula, mode })
        setOpenInvalidate(Util.newTempId())
    }

    const preventCreate = !Util.isStringExists(formula) || !Util.isStringExists(title);
    return (
        <ButtonPopover reversed invalidateOpen={openInvalidate} openListener={setVisible} popover={<div style={{ padding: '1rem', width: 600 }}>

            <TextInput size='sm' labelText="Name" style={{ borderRadius: 5 }} value={title} onChange={e => setTitle(e.target.value)} />

            <ContentSwitcher selectedIndex={mode == "BASIC" ? 0 : 1} size="sm" onChange={e => setMode(e.name)} style={{ marginTop: '0.5rem' }}>
                <Switch name="BASIC" text="Basic" />
                <Switch name="ADVANCED" text="Advanced" />
            </ContentSwitcher>

            {mode === "BASIC" ? (<>
                <FormulaEditor values={availableProperties} formula={formula} onFormulaChange={setFormula} />
            </>) : (<>
                <label className="bx--label" style={{ marginTop: '1rem' }}>Formula</label>
                <CodeEditorInput availableProperties={availableProperties} value={formula} onValueChange={setFormula} />
            </>)}



            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1rem', gap: '0.25rem' }}>
                <Button onClick={() => {
                    setOpenInvalidate(Util.newTempId())
                    onRemove(formulaItem);
                }} kind="danger" size="sm" style={{ borderRadius: 25 }} renderIcon={ErrorFilled16}>Remove</Button>
                <Button onClick={onCreateBtn} disabled={preventCreate} kind="primary" size="sm" style={{ borderRadius: 25 }} renderIcon={CheckmarkFilled16}>Update</Button>
            </div>
        </div>}>
            <div className='report-engine-value-tag' style={{ borderRadius: 50, overflow: 'hidden', height: 25, display: 'flex', border: darkMode ? '1px solid #ffffff20' : '1px solid #00000020', }}>
                <div style={{ background: darkMode ? '#f1f1f1' : '#1c1c1c', color: darkMode ? 'black' : 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingInline: '0.5rem' }}>
                    <p style={{ fontSize: 12, fontWeight: 'bold' }}>{label}</p>
                </div>
                <div style={{ background: darkMode ? 'black' : 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingInline: '1rem' }}>
                    <p style={{ fontSize: 14 }}>{value.inError ? 'Invalid' : value.loading ? 'Loading...' : Util.formatMoney(value.amount)}</p>
                </div>
            </div>
        </ButtonPopover>
    )
}


const NewBtn = ({ darkMode, availableProperties, onCreateNew }) => {
    const [visible, setVisible] = useState(false);
    const [openInvalidate, setOpenInvalidate] = useState(() => Util.newTempId());

    const [title, setTitle] = useState("")
    const [formula, setFormula] = useState("");
    const [mode, setMode] = useState("BASIC");

    useEffect(() => {
        if (visible) {
            setTitle("")
            setFormula("")
            setMode("BASIC")
        }
    }, [visible])
    useEffect(() => {
        setFormula("")
    }, [mode])


    const onCreateBtn = () => {
        onCreateNew({ id: Util.newTempId(), name: title, formula, mode })
        setOpenInvalidate(Util.newTempId())
    }
    const preventCreate = !Util.isStringExists(formula) || !Util.isStringExists(title);
    return (
        <ButtonPopover reversed clearTopLeftRadius invalidateOpen={openInvalidate} openListener={setVisible} popover={<div style={{ padding: '1rem', width: 600 }}>

            <TextInput size='sm' labelText="Name" style={{ borderRadius: 5 }} value={title} onChange={e => setTitle(e.target.value)} />

            <ContentSwitcher selectedIndex={mode == "BASIC" ? 0 : 1} size="sm" onChange={e => setMode(e.name)} style={{ marginTop: '0.5rem' }}>
                <Switch name="BASIC" text="Basic" />
                <Switch name="ADVANCED" text="Advanced" />
            </ContentSwitcher>

            {mode === "BASIC" ? (<>
                <FormulaEditor values={availableProperties} formula={formula} onFormulaChange={setFormula} />
            </>) : (<>
                <label className="bx--label" style={{ marginTop: '1rem' }}>Formula</label>
                <CodeEditorInput availableProperties={availableProperties} value={formula} onValueChange={setFormula} />
            </>)}



            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1rem', gap: '0.25rem' }}>
                <Button onClick={() => setOpenInvalidate(Util.newTempId())} kind="secondary" size="sm" style={{ borderRadius: 25 }} renderIcon={ErrorFilled16}>Cancel</Button>
                <Button onClick={onCreateBtn} disabled={preventCreate} kind="primary" size="sm" style={{ borderRadius: 25 }} renderIcon={CheckmarkFilled16}>Create</Button>
            </div>
        </div>}>
            <button className={'report-engine-value-plus-button ' + (darkMode ? 'report-engine-value-plus-button-for-dark-mode' : '')}><Add16 /></button>
        </ButtonPopover>
    )
}


export function FormulaValues({ state, showDarkMode, columns, values }) {
    const [formulas, setFormulas] = state.use("formulas", [])

    const availableProperties = useMemo(() => columns.filter(column => ['NUMBER', 'MONEY'].includes(column.type)).map(column => column.title), [columns])
    const getValue = id => {
        const amount = values?.find(value => value.id === id)?.amount;
        return {
            inError: amount === null,
            loading: amount === undefined,
            amount
        }
    };
    return (
        <div style={{
            background: showDarkMode ? '#212121' : '#F7F7F8', borderBottom: showDarkMode ? '1px solid #FFFFFF20' : '1px solid #00000020',
            color: showDarkMode ? 'white' : 'black',
            display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'stretch', paddingBlock: '0.5rem', paddingLeft: '0.5rem', gap: '0.5rem'
        }}>
            <div style={{ height: 25, width: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FunctionMath20 />
            </div>
            {formulas.map(formula =>
                <ValueTag key={formula.id} availableProperties={availableProperties} darkMode={showDarkMode} label={formula.name}
                    //value={Util.formatAmount(getValue(formula.id))} 
                    value={getValue(formula.id)}
                    formulaItem={formula}
                    onRemove={formula => setFormulas(prev => prev.filter(other => other.id !== formula.id))}
                    onUpdate={formula => setFormulas(prev => prev.map(other => other.id === formula.id ? formula : other))} />)}
            <NewBtn darkMode={showDarkMode} availableProperties={availableProperties} onCreateNew={formula => setFormulas(prev => [...prev, formula])} />
        </div>
    )
}