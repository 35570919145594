
export const nodeNameExistsInSomeParent = (item, nodename) => {
    try {
        const isNodeName = target => target?.nodeName?.toLowerCase() === nodename.toLowerCase();

        if (isNodeName(item)) {
            return true;
        }

        let element = item;
        do {
            const parent = element.parentNode;
            if (isNodeName(parent)) {
                return true;
            }
            element = parent;
        } while (element.parentNode);
    } catch (e) { }

    return false;
}

export const classExistsInSomeParent = (item, classname) => {
    try {
        if (item.classList.contains(classname)) {
            return true;
        }

        let element = item;
        do {
            const parent = element.parentNode;
            // console.log(parent.classList.contains)
            if (isExist(parent) && isExist(parent.classList) && isExist(parent.classList.contains) && parent.classList.contains(classname)) {
                return true;
            }
            element = parent;
        } while (element.parentNode);
    } catch (e) { }

    return false;
}

const isExist = item => item != undefined && item != null;

const getAllParents = item => {
    let parents = [];
    let element = item;
    do {
        const parent = element.parentNode;
        parents.push(parent);
        element = parent;
    } while (element.parentNode);
    return parents;
}