import React from "react"
import { Purchase16, Money16, ShoppingCartArrowUp16, AirlinePassengerCare16, ShoppingCartArrowDown16, ShoppingCart16, ErrorFilled16, Finance16, Wallet16, Time16 } from '@carbon/icons-react'
import Util from "../../../util/Util"
import { withLoadablePageAndProps, withLoadablePagePropsAndProps } from "../../../base/Page";
import Api from "../../../session/Api";

const Stats = ({ icon, title, value, valueColor, secondary }) => (
    <div>
        <div style={{ display: 'inline-flex', alignItems: 'center', borderRadius: 50, paddingInline: '0.75rem', paddingBlock: '0.15rem', background: secondary ? 'white' : '#1c1c1c', color: secondary ? 'black' : 'white', boxShadow: secondary ? '0px 1px 3px 0px rgba(0,0,0,0.1) , 0px 1px 2px 0px rgba(0,0,0,0.06) ' : undefined }}>
            {React.createElement(icon)}
            <p style={{ marginLeft: 5, fontSize: 14 }}>
                {title}
            </p>
        </div>

        <p style={{ fontSize: 22, margin: 0, color: valueColor }}>{value}</p>
    </div>
)


export function View({ state, payload: report }) {
    const session = state.getSession();
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', gap: '1rem' }}>



            <Stats icon={ShoppingCartArrowUp16} title={"Total Sales"} value={`AED ${Util.formatMoney(report.totalSales)}`} valueColor={"green"} />
            <Stats secondary icon={Money16} title={"Cash"} value={`AED ${Util.formatMoney(report.totalSalesByCash)}`} valueColor={"green"} />
            <Stats secondary icon={Purchase16} title={"Card"} value={`AED ${Util.formatMoney(report.totalSalesByNonCash)}`} valueColor={"green"} />
            {report.totalSalesByCustomerCredit != 0 ? (
                <Stats secondary icon={AirlinePassengerCare16} title={"Customer Credit"} value={`AED ${Util.formatMoney(report.totalSalesByCustomerCredit)}`} valueColor={"green"} />
            ) : (
                <div />
            )}


            <Stats icon={ShoppingCartArrowDown16} title={"Total Returns"} value={`AED ${Util.formatMoney(report.totalReturn)}`} valueColor={"red"} />
            <Stats secondary icon={Money16} title={"Cash Refund"} value={`AED ${Util.formatMoney(report.cashReturnAmount)}`} valueColor={"red"} />
            <Stats secondary icon={Purchase16} title={"Card Refund"} value={`AED ${Util.formatMoney(report.cardReturnAmount)}`} valueColor={"red"} />
            {report.otherReturnAmount != 0 ? (
                <Stats secondary icon={ErrorFilled16} title={"Other / No refund"} value={`AED ${Util.formatMoney(report.otherReturnAmount)}`} valueColor={"black"} />
            ) : (
                <div />
            )}

            <Stats icon={ShoppingCart16} title={"Net Sales"} value={`AED ${Util.formatMoney(report.netSales)}`} valueColor={"green"} />
            <Stats secondary icon={Money16} title={"Net Cash"} value={`AED ${Util.formatMoney(report.netSalesCash)}`} valueColor={"green"} />
            <Stats secondary icon={Purchase16} title={"Net Card"} value={`AED ${Util.formatMoney(report.netSalesCard)}`} valueColor={"green"} />
            {report.netSalesOther != 0 ? (
                <Stats secondary icon={AirlinePassengerCare16} title={"Net Other"} value={`AED ${Util.formatMoney(report.netSalesOther)}`} valueColor={"black"} />
            ) : (
                <div />
            )}


            <Stats icon={Wallet16} title={"Current Amount"} value={`AED ${Util.formatMoney(report.calculatedCashInDrawer)}`} valueColor={"black"} />
            <Stats secondary icon={Time16} title={"Starting Cash"} value={`AED ${Util.formatMoney(report.openingAmount)}`} valueColor={"black"} />
            <Stats secondary icon={Money16} title={"Total Cash Flow"} value={`AED ${Util.formatMoney(report.modifyingAmount)}`} valueColor={"black"} />
            <div />
        </div>
    )
}

export const PosStats = withLoadablePagePropsAndProps(props => listener => Api.getPosSalesSummaryReport(props.state.getSession().posSessionId, listener), View)