import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Util from '../../../util/Util';

const borderColor = '#00148C'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#00148C',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 8,
        // fontStyle: 'bold',
    },
    description: {
        width: '55%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 5,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
});


const acctVal = value => {
    //return value < 0 ? '(' + Math.abs(value).toFixed(2) + ')' : value.toFixed(2);
    return Util.formatMoneyWithNeg(value)
}

const TableFooter = ({ title, value }) => {
    const bothEmpty = value.debit == 0 && value.credit == 0;
    return (
        <View style={styles.row}>
            <Text style={styles.description}>{title}</Text>
            {bothEmpty ? (<>
                <Text style={styles.total}>{'0'}</Text>
                <Text style={styles.total}></Text></>
            ) : (<>
                <Text style={styles.total}>{(value.debit && value.debit != 0) ? 'Dr ' + acctVal(value.debit) : ' '}</Text>
                <Text style={styles.total}>{(value.credit && value.credit != 0) ? 'Cr ' + acctVal(value.credit) : ' '}</Text>
            </>)}

            <Text style={styles.total}></Text>
        </View>
    )
};

export default TableFooter