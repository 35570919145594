import { useMemo, useState } from "react";
import Button from "./Button";
import { Add16 } from '@carbon/icons-react'
import ItemCreatorDialog from "../templates/ItemCreatorDialog";
import UIUtil from "../util/UIUtil";
import Portal from "../util/Portal";



export function ExtendableComboBox({
    allItems,
    objectType,
    filterProperties,
    itemToString,
    setItemId,
    children,
}) {
    const [addedItems, setAddedItems] = useState([]);
    return <NonStateExtendableComboBox {...{
        allItems,
        objectType,
        filterProperties,
        itemToString,
        setItemId,
        children,
        addedItems,
        setAddedItems
    }} />
}

export function SharedState({ children }) {
    const addedItemsState = useState([]);
    return children(addedItemsState);
}



export function NonStateExtendableComboBox({
    allItems,
    objectType,
    filterProperties,
    itemToString,
    setItemId,
    children,
    addedItems, setAddedItems
}) {
    const [visible, setVisible] = useState(false)

    const items = useMemo(() => [...addedItems, ...allItems], [addedItems, allItems]);
    return (
        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
            <div style={{ flex: 1 }}>
                {children(items)}
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <Button renderIcon={Add16} size="md" hasIconOnly iconDescription="New" onClick={() => setVisible(true)} />
            </div>

            <Portal>
                <ItemCreatorDialog
                    hideThumbnail
                    filterProperties={filterProperties}
                    objectType={objectType}
                    open={visible} onClose={() => setVisible(false)}
                    onCreatedItem={createdItem => {
                        setAddedItems(prev => [...prev, { id: createdItem.id, value: itemToString(createdItem) }])
                        setItemId(createdItem.id);
                        UIUtil.showSuccess();
                        setVisible(false);
                    }} />
            </Portal>
        </div>
    )
}

export function NonStateExtendableComboBoxTwo({
    allItems,
    objectType,
    filterProperties,
    itemToString,
    setNewItem,
    children,
    addedItems, setAddedItems
}) {
    const [visible, setVisible] = useState(false)

    const items = useMemo(() => [...addedItems, ...allItems], [addedItems, allItems]);
    return (
        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
            <div style={{ flex: 1 }}>
                {children(items)}
            </div>
            <div>
                <Button style={{ height: 48 }} renderIcon={Add16} size="lg" className="bx--tooltip--hidden" hasIconOnly onClick={() => setVisible(true)} />
            </div>

            <Portal>
                <ItemCreatorDialog
                    hideThumbnail
                    filterProperties={filterProperties}
                    objectType={objectType}
                    open={visible} onClose={() => setVisible(false)}
                    onCreatedItem={createdItem => {
                        setAddedItems([...addedItems, { id: createdItem.id, value: itemToString(createdItem) }])
                        setNewItem(createdItem);
                        UIUtil.showSuccess();
                        setVisible(false);
                    }} />
            </Portal>
        </div>
    )
}