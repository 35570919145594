import { InlineLoading, Tab, Tabs } from 'carbon-components-react';
import React, { useEffect, useState } from 'react'
import { ErrorFilled16, Warning16 } from '@carbon/icons-react'
import Api from '../../session/Api';
import { OBJECT_TYPE_CUSTOMER } from '../../constants/ObjectTypes';
import SubsidiaryLedgerResultPage from './subsidiary-ledger-report/SubsidiaryLedgerResultPage';

export default ({ itemId, itemType }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [reports, setReports] = useState([])

    useEffect(() => {
        Api.getAllSubsidiaryLedgerReport(itemId, itemType, response => {
            setLoading(false)
            if (response.status === true) {
                setReports(response.payload)
            } else {
                setError(true)
            }
        })
    }, [])

    if (loading) {
        return <div className='centered-progress-bar' style={{ width: '100%', height: 75, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><InlineLoading /><p style={{ fontSize: 14, opacity: 0.65 }}>Loading...</p></div>
    }
    if (error) {
        return <div className='centered-progress-bar' style={{ width: '100%', height: 75, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ErrorFilled16 /><p style={{ fontSize: 14, opacity: 0.65, marginLeft: '0.25rem' }}>An error occurred</p></div>
    }
    if (reports.length == 0) {
        return <div className='centered-progress-bar' style={{ width: '100%', height: 75, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p style={{ fontSize: 14, opacity: 0.65, marginLeft: '0.25rem' }}>No records exist</p></div>
    }

    return (
        <div className="tab-allow-long-name" style={{ marginTop: '1rem' }}>
            <Tabs>
                {reports.map(report => (
                    <Tab key={report.accountName + '-tab'} label={report.accountName}>
                        <SubsidiaryLedgerResultPage report={report} hideSubsidiary />
                    </Tab>
                ))}
            </Tabs>
        </div>
    )
}