import { useState } from "react";
import { InvReqList } from "../pos/dialogs/any-search-dialog";
import { Add20, Launch20 } from "@carbon/icons-react";
import { listDetailItemId } from "../../reporting-engine/base/report-page";
import { Link } from "carbon-components-react";

function Entry({ editable, item, setValue }) {
    const onRemoveBtn = async e => {
        e.stopPropagation();
        e.preventDefault();

        setValue(prev => prev.filter(x => x.id !== item.id));
    }

    return (
        <div onClick={() => window.open(listDetailItemId("AllInvRequests", item.id), "_blank")} className="invreq-hover-box" style={{
            padding: '1rem', borderRadius: 7, border: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingRight: '1.5rem',
            background: "#f4f4f4"
        }}>
            <div style={{ flex: 1 }}>
                <h6>
                    Stock Request <span style={{ fontWeight: 'normal' }}>#{item.id}</span>
                </h6>

                {editable && <Link onClick={onRemoveBtn}
                    style={{ cursor: 'pointer', fontSize: 12, color: '#990000' }}>Remove?</Link>}
            </div>
            <Launch20 />
        </div>
    )
}

function NewBtn({ setValue }) {
    const [visible, setVisible] = useState(false);

    const onAdd = (item) => {
        setValue(prev => [...prev.filter(x => x.id !== item.id), {
            id: item.id,
            friendlyStatus: item.friendlyStatus,
            requestedOn: item.requestedOn,
            requestingLocationName: item.requestingLocationName,
        }]);
    }

    return (<>
        <div onClick={() => setVisible(true)} className="invreq-hover-box" style={{
            padding: '1rem', borderRadius: 7, border: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingRight: '1.5rem',
            background: "white"
        }}>
            <div style={{ flex: 1 }}>
                <h6 style={{ color: '#0f62fe' }}>Assign Stock Request</h6>
                <p style={{ fontSize: 14, opacity: 0.65 }}>Active Requests</p>
            </div>
            <Add20 />
        </div>

        <InvReqList visible={visible} onClose={() => setVisible(false)} onItemSelected={onAdd} />
    </>)
}

export function AssignedStockRequests({ editable, value, setValue, noLabel }) {
    return (
        <div>
            {!noLabel && <label className="bx--label" style={{ color: 'black', opacity: 0.65 }}>Stock Requests</label>}
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '0.5rem' }}>
                {value?.map(item => <Entry key={item.id} item={item} editable={editable} setValue={setValue} />)}
                {editable && <NewBtn setValue={setValue} />}
            </div>
        </div>
    );
}