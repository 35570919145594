import { ComboBox, ContentSwitcher, InlineLoading, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved, options }) => {
    const [name, setName] = useMultiInputState(input, 'name', onInputUpdate);
    const [description, setDescription] = useMultiInputState(input, 'description', onInputUpdate);
    const [seatColor, setSeatColor] = useMultiInputState(input, 'color', onInputUpdate);
    const [defaultTicketPrice, setDefaultTicketPrice] = useMultiInputState(input, 'defaultTicketPrice', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div style={{ flex: 1 }}>
                <TextInput labelText="Name" style={{ width: '100%' }} value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput labelText="Description" style={{ width: '100%' }} value={description} onChange={e => setDescription(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput type="color" labelText="Icon Color" style={{ width: '100%' }} value={seatColor} onChange={e => setSeatColor(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput labelText="Default Ticket Price" style={{ width: '100%' }} value={defaultTicketPrice} onChange={e => setDefaultTicketPrice(e.target.value)} />
            </div>

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const TheaterTypeSeatTypeListEditor = ({ defaultItems, onUpdate }) => {
    return (
        <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
    )
}