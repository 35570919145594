import { Checkbox, NumberInput, Slider, TextArea, TextInput } from 'carbon-components-react'
import React from 'react'
import DimensionsField from './base/DimensionsField'
import InspectorContentHeader from './base/InspectorContentHeader'

import useCanvasComponentInspectorField from '../../hooks/useCanvasComponentInspectorField'
import ColorField from './base/ColorField'
import useBooleanToggler from '../../hooks/useBooleanToggler'

export default ({engine, componentId}) => {
    const { changeListener, getter, setter, multiSetter } = useCanvasComponentInspectorField(engine, componentId, ['fill', 'rx', 'ry', 'stroke', 'strokeWidth', 'strokeDashArray']);
    const [isTransparent, toggleTransparent] = useBooleanToggler('fill', 'transparent', '#cecece', getter, setter);
    const isDashedStroke = getter('strokeDashArray') != null && getter('strokeDashArray').length > 0;
    const toggleDashedStroke = () => setter('strokeDashArray', isDashedStroke ? [] : [10, 5], true);

    return (
        <div>
            <InspectorContentHeader text="Background" />
            <ColorField disabled={isTransparent} title="Color" color={getter('fill')} onChange={color => setter('fill', color, true)} />
            <div onClick={() => toggleTransparent()}>
                <Checkbox labelText="Transparent" checked={isTransparent} />
            </div>
            {/* <div style={{height: '1rem'}} /> */}

            <InspectorContentHeader text="Border" hasTopMargin />
            <Slider onRelease={changeListener.onBlur} labelText="Corner Radius" min={0} max={50} hideTextInput value={getter('rx')} onChange={({ value }) => multiSetter({ rx: value, ry: value })} />
            <div style={{height: '1rem'}} />

            <Slider onRelease={changeListener.onBlur} labelText="Outline Thickness" min={0} max={50} hideTextInput value={getter('strokeWidth')} onChange={({ value }) => setter('strokeWidth', value)} />
            <div style={{height: '1rem'}} />
            
            <ColorField title="Outline Color" color={getter('stroke')} onChange={color => setter('stroke', color, true)} />
            <div onClick={() => toggleDashedStroke()}>
                <Checkbox labelText="Dashed Outline" checked={isDashedStroke} />
            </div>
            {/* <div style={{height: '1rem'}} /> */}

            {/* <InspectorContentHeader text="Dimensions" hasTopMargin />
            <DimensionsField size={{ width: getter('width'), height: getter('height') }} 
            onSizeChange={({width, height}) => multiSetter({ width, height })}/> */}
        </div>
    )
}