import { PlaneSea32, Menu16, QrCode16, Fork16, Restaurant16, ArrowLeft16, Calendar16, DocumentAttachment16, CheckmarkFilled16, SplitScreen16, DeliveryParcel32, DeliveryParcel16 } from '@carbon/icons-react'
import { Tab, Tabs, Tag } from 'carbon-components-react';
import { useHistory } from 'react-router-dom';

import { Menu } from './menu';
import QRCode from 'react-qr-code';

function MenuButton({ label, active, onClick, fullWidth, children }) {
    return <button style={{ width: fullWidth ? 30 : 100, paddingInline: fullWidth ? 0 : undefined }} onClick={onClick} data-active={active} className='shipment-item-menu-button'>{children}</button>
}


export function Toolbar({ fnbEvent, tabIndex, setTabIndex, sideView, setSideView }) {
    const history = useHistory();
    return (
        <div className='lead-flow-tabs hidden-tab-content' style={{ paddingInline: '1rem', height: '3rem', width: '100%', display: 'flex', alignItems: 'center', background: '#212121', color: 'white', borderBottom: '1px solid #00000060' }}>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <button onClick={() => history.goBack()} className='lead-flow-icon-button lead-flow-icon-button-light'>
                    <ArrowLeft16 />
                </button>

                <Tag renderIcon={Restaurant16} style={{ marginRight: "1rem", marginLeft: '0.25rem' }} type="high-contrast">
                    <strong>{fnbEvent.type === 'BANQUET' ? "Banquet" : "Catering"}</strong> Event
                </Tag>
                <div>
                    <h4 style={{ fontSize: 16, }}>{fnbEvent.eventNo}</h4>
                </div>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                    <MenuButton active={tabIndex === 0} onClick={() => setTabIndex(0)}><Fork16 /> Overview</MenuButton>
                    <MenuButton active={tabIndex === 1} onClick={() => setTabIndex(1)}><SplitScreen16 /> To-Do</MenuButton>
                    <MenuButton active={tabIndex === 2} onClick={() => setTabIndex(2)}><DocumentAttachment16 /> Attachments</MenuButton>
                </div>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {tabIndex === 0 && <Menu fnbEvent={fnbEvent} sideView={sideView} setSideView={setSideView} />}
            </div>
        </div>
    )
}