import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import { PosInfoView, PosInfoViewBtns } from "../view/PosInfoView";
import { useEffect, useState } from "react";
import Util from "../../../util/Util";


export function SessionInfoDialog({ posState }) {
    const [hidden, setHidden] = useState(false);
    const onClose = () => {
        posState.setShowPosInfoDialog(false);
        setHidden(false);
    }

    const visible = posState.isShowPosInfoDialog() && !hidden;

    const [viewKey, setViewKey] = useState(() => Util.newTempId())
    useEffect(() => {
        if (visible) {
            setViewKey(Util.newTempId())
        }
    }, [visible])
    return (
        <ComposedModal key="pos-session-info-dialog" size="lg" open={visible} onClose={onClose}>
            <ModalHeader label="Session" title="POS Management" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                <PosInfoView key={viewKey} posState={posState} setHidden={setHidden} />
            </ModalBody>
            <ModalFooter>
                <PosInfoViewBtns posState={posState} />
            </ModalFooter>
        </ComposedModal>
    )
}