

export class TaskQueue {

    maxConcurrent = 5;
    runningTasks = []

    constructor(maxConcurrent) {
        this.maxConcurrent = maxConcurrent;
    }

    async runTask(taskFunc) {
        if (this.runningTasks.length >= this.maxConcurrent) {
            await Promise.allSettled(this.runningTasks)
            return this.runTask(taskFunc);
        }

        const task = taskFunc();

        this.runningTasks.push(task)
        try {
            const result = await task;
            return { success: true, result }
        } catch (error) {
            return { success: false, error }
        } finally {
            this.runningTasks.splice(this.runningTasks.indexOf(task), 1);
        }
    }

}