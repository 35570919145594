import { useEffect, useState } from "react";
import Api from "../../../../session/Api";
import UIUtil from "../../../../util/UIUtil";
import { InlineLoading } from "carbon-components-react";
import { InvoiceItemsTable } from "./invoice-items-table";
import Util from "../../../../util/Util";
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../../../constants/ObjectTypes";


export function InvoiceableSalesOrders({
    customerId, selectionMode, startDate, endDate, items, setItems, againstOrderIds, setAgainstOrderIds,
    predefinedClasses, sellables,
    inventoryRequests, setInventoryRequests
}) {
    const [loading, setLoading] = useState(true);
    // const [sellables, setSellables] = useState([])
    //const [items, setItems] = useState([]);

    useEffect(() => {
        if (customerId) {
            setLoading(true);
            // setSellables([]);
            setItems([]);

            let cancelled = false;
            Api.getV2InvoiceableSalesOrderItems({
                customerId, startDate, endDate,
            }, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    // setSellables(response.payload.sellables);
                    setItems([
                        ...response.payload.sellables.filter($ => $.type === OBJECT_TYPE_PRODUCT_BUNDLE).map(item => ({
                            amount: item.amount,
                            qty: item.qty,
                            item: {
                                name: item.name,
                                selectedItem: item
                            },
                            tempId: Util.newTempId(),
                        })),
                        { tempId: Util.newTempId() }
                    ]);
                    setAgainstOrderIds(response.payload.orderIds)
                    setInventoryRequests(response.payload.sellables.filter($ => $.type === OBJECT_TYPE_PRODUCT).map(item => ({
                        amount: item.amount,
                        qty: item.qty,
                        fromServiceId: item.fromServiceId,
                        item: {
                            name: item.name,
                            selectedItem: item
                        },
                        tempId: Util.newTempId(),
                        hidden: true
                    })))
                } else {
                    UIUtil.showError(response.message);
                }
                setLoading(false)
            })

            return () => cancelled = true;
        } else {
            // setSellables([]);
            setItems([]);
            setLoading(false)
        }
    }, [customerId, startDate, endDate, selectionMode])


    return (
        <div style={{}}>
            {!loading ? (<>
                {items.length > 0 ? (
                    <div style={{}}>
                        <InvoiceItemsTable editable predefinedClasses={predefinedClasses} sellables={sellables} items={items} setItems={setItems} />
                    </div>
                ) : (
                    <div style={{ width: '100%', height: 75.44, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <p style={{ fontSize: 12, opacity: 0.65 }}>No order exists</p>
                    </div>
                )}
            </>) : (<>
                <div style={{ width: '100%', height: 75.44, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <InlineLoading style={{ width: 'unset' }} />
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
                </div>
            </>)}
        </div>
    )
}