import { ComposedModal, ContentSwitcher, ModalBody, ModalFooter, ModalHeader, Switch, TextArea } from "carbon-components-react";
// import ReactRRuleGenerator from "../../../lib/rrule-generator";
import RRuleGenerator from 'react-rrule-generator';
import Util from "../../../util/Util";

import { RRule } from 'rrule'
import Button from "../../../components/Button";
import { useEffect, useMemo, useState } from "react";
import { Edit16 } from '@carbon/icons-react'
import Portal from "../../../util/Portal";

const DEFAULT_RRULE = new RRule({
    freq: RRule.DAILY,
    count: 1,
    interval: 1
})

const Switcher = ({ hasRrule, setValue }) => {
    return (
        <ContentSwitcher selectedIndex={hasRrule ? 1 : 0} onChange={e => setValue(e.index === 0 ? undefined : DEFAULT_RRULE.toString())} style={{ width: '100%' }}>
            <Switch name="no-recurring" text="Single Event" />
            <Switch name="yes-recurring" text="Recurring event" />
        </ContentSwitcher>
    )
}

const Calendar = ({ value, onChange }) => {
    return (
        <input type="date" value={value} onChange={onChange} className="form-control" />
    )
}

export function RecurringView({ value, setValue, skipSwitcher, portalModal, setModalVisible }) {
    const hasRrule = Util.isStringExists(value);
    const rruleText = useMemo(() => Util.isStringExists(value) ? RRule.fromString(value).toText() : '', [value]);

    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (visible && !Util.isStringExists(value)) {
            setVisible(false);
        }

        setModalVisible?.(visible)
    }, [visible, value])

    useEffect(() => {
        if (skipSwitcher && !hasRrule) {
            setValue(DEFAULT_RRULE.toString())
        }
    }, [skipSwitcher, value])

    const modal = (
        <ComposedModal preventCloseOnClickOutside className="modal-that-supports-combo-box" key="set-activity-recurring-dialog" open={visible} onClose={() => setVisible(false)}>
            <ModalHeader label="Activity" title="Design Recurrence" />
            <ModalBody style={{ paddingRight: '1rem', outline: 'none' }} hasScrollingContent>
                {hasRrule && <RRuleGenerator
                    calendarComponent={Calendar}
                    config={{
                        end: ['After', 'On date']
                    }} value={value} onChange={(rrule) => setValue(rrule)} />}
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'flex-end' }}>
                <Button kind="secondary" onClick={() => setVisible(false)}>
                    Close
                </Button>
            </ModalFooter>
        </ComposedModal>
    )

    return (
        <div>
            {!skipSwitcher && <Switcher hasRrule={hasRrule} setValue={setValue} />}

            {hasRrule && <>
                <div style={{ marginTop: '0.5rem', borderRadius: 15, background: 'white', border: '1px solid #00000040', padding: '1rem' }}>
                    <p style={{ fontSize: 14 }}>{rruleText}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                    <Button kind="tertiary" size="sm" style={{ borderRadius: 15 }} renderIcon={Edit16} onClick={() => setVisible(true)}>Design Recurrence</Button>
                </div>
            </>}


            {portalModal ? (
                <Portal>
                    {modal}
                </Portal>
            ) : (
                modal
            )}
        </div>
    )
}