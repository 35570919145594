import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"

export const SupplierOutstandingReport = {
    filter: Filter,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
    },

    onAction: (action, data, history) => {
        switch (action) {
            case "name":
                window.open("/suppliers/" + data.supplierId, "_blank")
                return;
            case "statement":

                return;
        }
    },
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
