import { ComboBox, ContentSwitcher, Select, SelectItem, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import Util, { big } from '../../../util/Util';
// import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [amount, setAmount] = useMultiInputState(input, 'amount', onInputUpdate);
    const [memo, setMemo] = useMultiInputState(input, 'memo', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ width: '100%' }} size="lg" labelText="Profit Amount" value={amount}
                    onChange={e => setAmount(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ width: '100%' }} size="lg" labelText="Memo" value={memo} onChange={e => setMemo(e.target.value)} />
            </div>

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}


export const ProfitEditor = ({ highZ, defaultItems, onUpdate }) => (
    <MultipleInputEditor highZ={highZ} defaultInputs={[]} inputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
)