import { SOCKET_EVENT_LIVE_CART, useSubscribe } from "../../../session/SocketSession";
import { OrderState } from "../restaurant/engine/OrderState";


/**
 * 
 * @param {{ 
 *  state: OrderState, 
 * }} props 
 * @returns 
 */
export function LiveActiveCarts({ state }) {
    useSubscribe(SOCKET_EVENT_LIVE_CART("*"), ({ cartId }) => {
        state.reloadCarts();
        setTimeout(() => state.reloadCarts(), 1000)
    })
    return <div />
}