import { ArrowRight16, Stamp16, Close16 } from '@carbon/icons-react'
import { InlineLoading, Tag } from 'carbon-components-react'
import { Link } from 'react-router-dom'
import Api from '../../../../../session/Api'
import Util from '../../../../../util/Util'
import print from 'print-js';
import { useMemo, useState } from 'react'
import { pdf } from '@react-pdf/renderer'
import { createLoadingList } from '../pdf/loading-list'
import { createBillOfLading, createPerItemBillOfLading } from '../pdf/bill-of-lading'
import { createBriefManifest } from '../pdf/brief-manifest'
import { createDetailedManifest } from '../pdf/detailed-manifest'
import UIUtil from '../../../../../util/UIUtil'
import { SHIPMENT_DIR_TYPE } from '../..'
import Button from '../../../../../components/Button'
import { getAccount } from '../../../../../session/SessionManager'
import useApi from '../../../../../hooks/useApi'
import { createCurrencyInvoice, createInvoice } from '../pdf/invoice'
import { openPdf } from '../../../../../markup-template-engine'
import { hasCapabilitySupport } from '../../../../../app/Capabilities'

const List = ({ label, children }) => (<>
    <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{label}</h4>
    <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
        <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            {children}
        </div>
    </div>
</>)

const ValueEntry = ({ label, hasValue, loading, onClick }) => (<>
    <div onClick={hasValue ? onClick : undefined} className={hasValue ? 'shipment-side-view-entry-record' : undefined} style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
        {loading ?
            <InlineLoading style={{ width: 'unset' }} /> :
            hasValue ?
                <ArrowRight16 style={{ opacity: 0.65 }} /> :
                <p style={{ opacity: 0.65, fontSize: 12 }}>not available</p>}
    </div>
</>)


const SystemGeneratedDocument = ({ label, shipment, documentCreateFn, pdfProps = {} }) => {
    const { canGenerate, getPayload, PDF, overrideShipment } = useMemo(() => documentCreateFn(shipment), [shipment, documentCreateFn])
    const [loading, setLoading] = useState(false);
    const onPrintBtn = async () => {
        //repeated
        const getShipmentForPdf = async () => {
            if (overrideShipment) {
                return await overrideShipment(shipment);
            } else {
                return shipment;
            }
        }

        if (getPayload) {
            setLoading(true);
            getPayload(shipment, async (success, data) => {
                try {
                    if (success) {
                        const shipmentForPdf = await getShipmentForPdf(shipment);
                        if (!shipmentForPdf) {
                            return;
                        }

                        const blob = await pdf(<PDF shipment={shipmentForPdf} payload={data} {...pdfProps} />).toBlob();
                        print(URL.createObjectURL(blob));
                    } else {
                        UIUtil.showError(data);
                    }
                } finally {
                    setLoading(false);
                }
            })
        } else {
            setLoading(true);
            try {
                const shipmentForPdf = await getShipmentForPdf(shipment);
                if (!shipmentForPdf) {
                    return;
                }

                const blob = await pdf(<PDF shipment={shipmentForPdf} {...pdfProps} />).toBlob();
                print(URL.createObjectURL(blob));
            } finally {
                setLoading(false);
            }
        }
    }
    return <ValueEntry label={label} hasValue={canGenerate} loading={loading} onClick={onPrintBtn} />
}
export const SystemGeneratedDocumentWithPayload = SystemGeneratedDocument

const UserUploadedDocument = ({ label, fileId }) => {
    const hasDownload = Util.isStringExists(fileId);
    if (hasDownload) {
        return (
            <a download href={Api.downloadUploadFieldFile(fileId)}>
                <ValueEntry label={label} hasValue />
            </a>
        )
    } else {
        return <ValueEntry label={label} />
    }
}

export function Documents({ shipment, globalState }) {
    const [stampStatus, setStampStatus] = globalState.use("stampStatus", {
        stamped: shipment.stamped,
        date: shipment.stampedDate,
        name: shipment.stampedByFullName
    })
    const [doingStamp, doStamp] = useApi(listener => Api.setShipmentStamp(shipment.id, true, listener), () => setStampStatus({
        stamped: true, date: new Date().getTime(), name: getAccount().fullName
    }))
    const [clearingStamp, clearStamp] = useApi(listener => Api.setShipmentStamp(shipment.id, false, listener), () => setStampStatus({
        stamped: false
    }))

    if (shipment.dirType === SHIPMENT_DIR_TYPE.EXPORT) {
        return (
            <div style={{}}>
                {hasCapabilitySupport("cargo") && <List label="Cargo">
                    <ValueEntry label={"Cargo Delivery Order"} hasValue onClick={() => openPdf("CargoDeliveryOrderDoc", shipment.id)} />
                </List>}

                <List label="Shipment">
                    {shipment.status === "Done" ? (<>
                        <SystemGeneratedDocument shipment={shipment} label="Tax Invoice" documentCreateFn={createInvoice} pdfProps={{ title: "TAX INVOICE" }} />
                        <SystemGeneratedDocument shipment={shipment} label="Tax Invoice (Currency)" documentCreateFn={createCurrencyInvoice} pdfProps={{ title: "TAX INVOICE" }} />
                    </>) : (<>
                        <SystemGeneratedDocument shipment={shipment} label="Proforma Invoice" documentCreateFn={createInvoice} pdfProps={{ title: "PROFORMA INVOICE" }} />
                        <SystemGeneratedDocument shipment={shipment} label="Proforma Invoice (Currency)" documentCreateFn={createCurrencyInvoice} pdfProps={{ title: "PROFORMA INVOICE" }} />
                    </>)}

                    <SystemGeneratedDocument shipment={shipment} label="Loading List" documentCreateFn={createLoadingList} />
                    <SystemGeneratedDocument shipment={shipment} label={<>
                        <span style={{ marginRight: '1rem' }}>Bill of Lading</span>
                        {stampStatus.stamped ? (
                            <Tag size="sm" type="blue">stamped</Tag>
                        ) : (
                            <Tag size="sm">not stamped</Tag>
                        )}
                    </>} documentCreateFn={createBillOfLading} pdfProps={{ stamped: stampStatus.stamped }} />
                    <SystemGeneratedDocument shipment={shipment} label="Per Item BL" documentCreateFn={createPerItemBillOfLading} pdfProps={{ stamped: stampStatus.stamped }} />

                    <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        {stampStatus.stamped ? (
                            <p style={{ fontSize: 12, opacity: 0.65, marginBottom: '0.25rem' }}>Stamped by {stampStatus.name} on {Util.getDate(stampStatus.date)}</p>
                        ) : (
                            null
                        )}

                        {(stampStatus.stamped) ? (
                            <Button onClick={() => UIUtil.confirm(clearStamp)} loading={clearingStamp} kind="danger-ghost" renderIcon={Close16} size="sm" style={{ borderRadius: 25 }}>Clear stamp</Button>
                        ) : (
                            <Button onClick={() => UIUtil.confirm(doStamp)} loading={doingStamp} renderIcon={Stamp16} size="sm" style={{ borderRadius: 25 }}>Stamp bill of lading</Button>
                        )}
                    </div>
                </List>

                {shipment.otherInvoices.length > 0 && <List label="Other Invoices">
                    {shipment.otherInvoices.map(invoice => (<>
                        <SystemGeneratedDocument shipment={shipment} label={"Invoice #" + invoice.invoiceNo}
                            documentCreateFn={shipment => createInvoice(shipment, invoice.fromEntityId)} pdfProps={{ title: "TAX INVOICE" }} />
                    </>))}
                </List>}

                <List label="Master">
                    <SystemGeneratedDocument shipment={shipment} label="Cargo Manifest" documentCreateFn={createBriefManifest} />
                    <SystemGeneratedDocument shipment={shipment} label="Master Manifest" documentCreateFn={createDetailedManifest} />
                </List>


                <List label="Customs">
                    <UserUploadedDocument label="Bill of Entry" fileId={shipment.billOfEntryDocument} />
                </List>

                {shipment.masterBills.length > 0 &&
                    <List label="Master Bills">
                        {shipment.masterBills.map(bill => <UserUploadedDocument label={"Bill Invoice " + bill.billNo} fileId={bill.document} />)}
                    </List>}

                {shipment.bills.length > 0 &&
                    <List label="Shipment Bills">
                        {shipment.bills.map(bill => <UserUploadedDocument label={"Bill Invoice " + bill.billNo} fileId={bill.document} />)}
                    </List>}

                <List label="Customer Docs">
                    <UserUploadedDocument label="Customer Invoice" fileId={shipment.customerInvoice} />
                    <UserUploadedDocument label="Packing List" fileId={shipment.customerPackingList} />
                    <UserUploadedDocument label="Delivery Note" fileId={shipment.customerDeliveryNote} />
                </List>
            </div>
        )
    } else {
        return (
            <div style={{}}>
                <List label="Transportation">
                    <UserUploadedDocument label="Transporter Document" fileId={shipment.transporterCompanyDocument} />
                </List>

                <List label="Customs">
                    <UserUploadedDocument label="Bill of Entry" fileId={shipment.billOfEntryDocument} />
                </List>

                {shipment.bills.length > 0 &&
                    <List label="Shipment Bills">
                        {shipment.bills.map(bill => <UserUploadedDocument label={"Bill Invoice " + bill.billNo} fileId={bill.document} />)}
                    </List>}

                <List label="Customer Docs">
                    <UserUploadedDocument label="Customer BL" fileId={shipment.importCustomerBl} />
                    <UserUploadedDocument label="Commercial Invoice" fileId={shipment.importCommercialInvoice} />
                    <UserUploadedDocument label="Packing List" fileId={shipment.customerPackingList} />
                    <UserUploadedDocument label="Delivery Note" fileId={shipment.customerDeliveryNote} />
                </List>
            </div>
        )
    }
}