import './draft.scss'
import MyStateDraftsDialog from "../../views/state-drafts/MyStateDraftsDialog"
import { Close16, LicenseDraft16 } from '@carbon/icons-react'
import Button from "../../components/Button"
import { useEffect, useState } from "react";
import SaveStateDraftsDialog from "../../views/state-drafts/SaveStateDraftsDialog";
import { SidePanel } from "./components/side-panel";
import { TemplatesList } from './components/templates-list';


const DraftManagement = ({ selectedDraft, setShowDrafts, setSelectedDraft, rounded }) => (
    selectedDraft ? (
        <div style={{
            background: '#f4f4f4', borderRadius: 5, paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', paddingBottom: '1rem', minWidth: 250,
            display: 'flex',
        }}>
            <div style={{ flex: 1 }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Selected Template</p>
                <h4>{selectedDraft.title}</h4>
            </div>
            <Button onClick={() => setSelectedDraft(undefined)} kind="ghost" hasIconOnly renderIcon={Close16} iconDescription="Close template" />
        </div>
    ) : (
        <Button className="green-button" size="sm" style={{ borderRadius: 50 }} onClick={() => setShowDrafts(true)} renderIcon={LicenseDraft16} kind="secondary">Templates</Button>
    )
)

export function useAdvancedDraft(type, loadDraft, getState, rounded) {
    const [showDrafts, setShowDrafts] = useState(false);
    const [showSaveDraft, setShowSaveDraft] = useState(false);
    const [selectedDraft, setSelectedDraft] = useState(undefined)

    useEffect(() => {
        loadDraft(selectedDraft?.state ? JSON.parse(selectedDraft.state) : undefined)
    }, [selectedDraft])

    return {
        selectedDraft,
        setShowSaveDraft,
        setSelectedDraft,
        draftManagement: (
            <DraftManagement {...({ selectedDraft, setShowDrafts, setSelectedDraft, rounded })} />
        ),
        draftDialogs: (<>
            <SaveStateDraftsDialog open={showSaveDraft} onClose={() => setShowSaveDraft(false)}
                stateType={type} onSetState={setSelectedDraft} currentDraft={selectedDraft}
                getState={getState} showPublic
            />

            {/* <MyStateDraftsDialog open={showDrafts} onClose={() => setShowDrafts(false)}
                stateType={type} onLoadDraftRequest={draft => {
                    setSelectedDraft(draft)
                    setShowDrafts(false)
                }} /> */}

            {showDrafts && (
                <SidePanel onClose={() => setShowDrafts(false)}>
                    <TemplatesList stateType={type} onClose={() => setShowDrafts(false)} onTemplateClick={draft => {
                        setSelectedDraft(draft)
                        setShowDrafts(false)
                    }} />
                </SidePanel>
            )}
        </>)
    }
}