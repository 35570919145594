import { Page, Text, View, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';
import { Pdf } from './pdf';
import { Receipt16, } from '@carbon/icons-react'

export const ChequeDocumentBtn = ({ cheque }) => {
    const [instance, updateInstance] = usePDF({ document: <Pdf item={cheque} /> });

    return (
        <a href={instance.url} target="_blank" style={{ textDecoration: 'none' }}>
            <Button size="sm" style={{ borderRadius: 25 }} kind={"primary"} renderIcon={Receipt16}>Print Document</Button>
        </a>
    )
}