import { TextInput, ComboBox } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';

import { RowDelete32, Draggable16 } from '@carbon/icons-react'
import Button from '../../components/Button';
import MultipleInputEditor, { VirtualMultipleInputEditor } from '../../components/MultipleInputEditor';
import ProductField from '../../views/product/ProductField';

const StockItemRecord = ({ input, longList, nonExistent, onInputUpdate, onInputRemoved, stockItemMode, onProductLoaded }) => {
    const [itemId, setItemId] = useState(input.itemId);
    const [itemType, setItemType] = useState(input.itemType);

    useEffect(() => {
        setItemId(input.itemId)
    }, [input.itemId])
    useEffect(() => {
        setItemType(input.itemType)
    }, [input.itemType])

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: 1 }}>
                {/* <p>{itemType > 0 ? itemId : 0}</p> */}
                <ProductField
                    longList={longList}
                    productId={itemType > 0 ? itemId : 0} productType={itemId > 0 ? itemType : 0}
                    defaultProductValues={input}
                    onProductLoaded={onProductLoaded}

                    onInputRemoved={onInputRemoved} stockItemMode={stockItemMode}
                    onStockValueChanged={(key, value) => {
                        onInputUpdate(key, value);
                    }}
                    onProductChange={({ type, id }) => {
                        setItemId(id);
                        setItemType(type);
                        onInputUpdate('itemId', id);
                        onInputUpdate('itemType', type);
                    }} />
            </div>


            {/* <div style={{width: 5}} />
            
            <div style={{width: 5}} /> */}

            {/* <Button kind="danger" hasIconOnly renderIcon={RowDelete32} size="md" 
            iconDescription="Remove"
            style={{height: 40, alignSelf: 'flex-end'}} disabled={nonExistent} onClick={onInputRemoved} /> */}
        </div>
    )
}

export default function StockItemEditor(props) {
    // console.log("def", props.defaultItems)
    //return <MultipleInputEditor
    return <VirtualMultipleInputEditor
        ref={props.multipleInputEditorRef}
        defaultInputs={props.defaultItems} onInputsUpdated={props.onItemsUpdated}
        stockItemMode={props.stockItemMode}
        //ItemComponent={(itemProps) => <StockItemRecord {...itemProps}  key="few" />}
        hideDrag
        ItemComponent={StockItemRecord}

    />
}