import { useMemo } from "react";
import { ACCOUNT_TYPE_SALES } from "../../../constants/Constants";
import { Memo } from "./Memo";
import { NoTax } from "./NoTax";
import { Salesperson } from "./Salesperson";
import { getAccountRole } from "../../../session/SessionManager";
import { Discounts } from "./Discounts";
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { Courier } from "./Courier";
import { AWBNo } from "./AwbNo";


export function DeliveryDetails({ sessionStore, editable }) {
    const role = useMemo(() => getAccountRole(), [])
    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem', display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
            <Courier sessionStore={sessionStore} editable={editable} />
            <AWBNo sessionStore={sessionStore} editable={editable} />
        </div>
    )
}