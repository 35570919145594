import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { withLoadablePageWithParams } from '../../../../base/Page';
import { OBJECT_TYPE_COURSE } from '../../../../constants/ObjectTypes';
import Api from '../../../../session/Api';
import { CalendarPage } from '../../../calendar/calendar-page';
import {
    GroupPresentation32, GroupPresentation24, ArrowLeft16, Calendar16, SplitScreen16, Education16, Report16, RecentlyViewed16
} from '@carbon/icons-react'
import { Tab, Tabs } from 'carbon-components-react';
import NoteView from '../../../../views/notes/NoteView';
import { BoardViewPage } from '../../../board-view/board-view-page';
import { getAccount, getAccountRole } from '../../../../session/SessionManager';
import { ACCOUNT_TYPE_STUDENT, ACCOUNT_TYPE_TUTOR } from '../../../../constants/Constants';
import { MyStudentsList } from '../../components/my-students';
import { AdvancedNotesView } from '../../../../views/advanced-notes/advanced-notes-view';
import { isMobileScreen } from '../../../../App';
import { MyTutorsList } from '../../components/my-tutors';
import { HistoryReport } from '../../components/history-report';

function Toolbar({ title, tabIndex, setTabIndex, role }) {
    const history = useHistory();

    if (isMobileScreen) {
        return (<>
            <div className='lead-flow-tabs hidden-tab-content' style={{ paddingInline: '1rem', height: '3rem', width: '100%', display: 'flex', alignItems: 'center', background: '#212121', color: 'white', borderBottom: '1px solid #ffffff20' }}>
                <button onClick={() => history.goBack()} className='lead-flow-icon-button lead-flow-icon-button-light'>
                    <ArrowLeft16 />
                </button>

                <GroupPresentation24 style={{ marginLeft: '1rem', marginRight: '0.5rem' }} />
                <div style={{ flex: 1 }}>
                    <h4>{title}</h4>
                </div>

            </div>
            <div className='mobile-icon-white-tab hidden-tab-content' style={{
                background: '#1c1c1c', color: 'white', borderBottom: '1px solid black',
            }}>
                <Tabs selected={tabIndex} onSelectionChange={index => setTabIndex(index)}>
                    <Tab label={<Calendar16 />}></Tab>
                    <Tab label={<SplitScreen16 />}></Tab>
                    <Tab label={<Report16 />}></Tab>
                    {role == ACCOUNT_TYPE_TUTOR && <Tab label={<Education16 />}></Tab>}
                    {role == ACCOUNT_TYPE_STUDENT && <Tab label={<Education16 />}></Tab>}
                    <Tab label={<RecentlyViewed16 />}></Tab>
                </Tabs>
            </div>
        </>)
    }

    return (
        <div className='lead-flow-tabs hidden-tab-content' style={{ paddingInline: '1rem', height: '4rem', width: '100%', display: 'flex', alignItems: 'center', background: '#212121', color: 'white', borderBottom: '1px solid black' }}>
            <button onClick={() => history.goBack()} className='lead-flow-icon-button lead-flow-icon-button-light'>
                <ArrowLeft16 />
            </button>

            <GroupPresentation32 style={{ marginLeft: '1rem', marginRight: '0.5rem' }} />
            <div style={{ flex: 1 }}>
                <h4>{title}</h4>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Course</p>
            </div>
            <Tabs selected={tabIndex} onSelectionChange={index => setTabIndex(index)}>
                <Tab label="Calendar"></Tab>
                <Tab label="To-Do Board"></Tab>
                {/* <Tab label="Notes/Docs"></Tab> */}
                <Tab label="Course Content"></Tab>
                {role == ACCOUNT_TYPE_TUTOR && <Tab label="My Students"></Tab>}
                {role == ACCOUNT_TYPE_STUDENT && <Tab label="Tutors"></Tab>}
                <Tab label="History"></Tab>
            </Tabs>
        </div>
    )
}

const Calendar = ({ item }) => {
    if (isMobileScreen) {
        return (<>
            <div style={{ width: '100%', height: 'calc(100% - 3rem - 40px)' }}>
                <CalendarPage courseId={item.id} autoSelectBlueprintId={item.autoSelectBlueprintId}
                    loadAdvancedNotesAtFirstReq={{ title: "Course Content", objectType: OBJECT_TYPE_COURSE, objectId: item.id, readonly: true }} />
            </div>
            {/* <div style={{ height: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <p style={{ fontSize: 10, opacity: 0.65 }}>tap and hold to create/edit</p>
            </div> */}
        </>)
    } else {
        return (
            <div style={{ width: '100%', height: 'calc(100% - 4rem)' }}>
                <CalendarPage courseId={item.id} autoSelectBlueprintId={item.autoSelectBlueprintId}
                    loadAdvancedNotesAtFirstReq={{ title: "Course Content", objectType: OBJECT_TYPE_COURSE, objectId: item.id, readonly: true }} />
            </div>
        )
    }
}

const ToDoBoard = ({ item }) => {
    return (
        <div style={{ width: '100%', height: isMobileScreen ? 'calc(100% - 3rem - 40px)' : 'calc(100% - 4rem)' }}>
            <BoardViewPage courseId={item.id} />
        </div>
    )
}

const History = ({ item }) => {
    return (
        <div style={{ padding: '0rem' }}>
            <HistoryReport courseId={item.id} />
        </div>
    )
}

const MyStudents = ({ item }) => {
    return (
        <div style={{ padding: '0rem' }}>
            <MyStudentsList courseId={item.id} />
        </div>
    )
}

const MyTutors = ({ item }) => {
    return (
        <div style={{ padding: '0rem' }}>
            <MyTutorsList courseId={item.id} />
        </div>
    )
}

const Note = ({ item }) => {
    return (
        //<div style={{ padding: '1rem' }}>
        <div style={{ width: '100%', height: isMobileScreen ? 'calc(100% - 3rem - 40px)' : 'calc(100% - 4rem)' }}>
            {/* <NoteView objectType={OBJECT_TYPE_COURSE} objectId={item.id} readonly={getAccountRole() == ACCOUNT_TYPE_STUDENT} /> */}
            <AdvancedNotesView title="Course Content" objectType={OBJECT_TYPE_COURSE} objectId={item.id} readonly={getAccountRole() == ACCOUNT_TYPE_STUDENT} />
        </div>
    )
}

const View = ({ payload: { item: course } }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const role = getAccountRole();
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Toolbar title={course.name} tabIndex={tabIndex} setTabIndex={setTabIndex} role={role} />
            {tabIndex === 0 ? (
                <Calendar item={course} />
            ) : tabIndex === 1 ? (
                <ToDoBoard item={course} />
            ) : tabIndex === 2 ? (
                <Note item={course} />
            ) : tabIndex === 3 ? (
                role == ACCOUNT_TYPE_TUTOR ? (
                    <MyStudents item={course} />
                ) : role == ACCOUNT_TYPE_STUDENT ? (
                    <MyTutors item={course} />
                ) : null
            ) : tabIndex === 4 ? (
                <History item={course} />
            ) : null}
        </div>
    )
}

export const MyCourseDetailPage = withLoadablePageWithParams(({ itemId }) => listener => Api.getItem(OBJECT_TYPE_COURSE, itemId, listener), View);
