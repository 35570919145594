import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_MANY_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_LOYALTY_CARD, OBJECT_TYPE_LOYALTY_CARD_ISSUE, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';


class LoyaltyCardIssueDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_LOYALTY_CARD_ISSUE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_LOYALTY_CARD_ISSUE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    getChildrenRequest: {
                        parentId: this.state.pathParams.pId,
                    },
                    objectType: OBJECT_TYPE_LOYALTY_CARD_ISSUE,
                    itemResult: this.state.itemResult,
                    history: this.props.history,
                })}
            </div>
        )
    }

}

export default withRouter(LoyaltyCardIssueDetailPage);