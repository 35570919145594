import { Checkmark20, Collaborate20, ArrowRight20, CheckmarkFilled16, LicenseDraft16, Reset16, Close16 } from '@carbon/icons-react'
import { useMemo, useState } from 'react'
import Select from 'react-select'
import Button from '../../components/Button'
import Util from '../../util/Util'
import UIUtil from '../../util/UIUtil'
import Api from '../../session/Api'
import Portal from '../../util/Portal'
import { SidePanel } from '../../templates/draft/components/side-panel'
import { TemplateDesigner } from './template-designer'
import { Tag } from 'carbon-components-react'

const styles = {
    container: (baseStyles) => ({
        ...baseStyles,
        paddingRight: 1,
        background: 'transparent',
        height: '100%',
        width: '100%'
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        padding: '0 6px'
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 5,
        background: '#ffffff10',
        border: '0px solid #00000099',
        outline: 'none',
        color: 'black',
        minHeight: 30,
        height: '100%',
        padding: 0
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: 'black',
        height: '100%',
        margin: 0
    }),
    indicatorsContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
}


function TargetSelect({ value, setValue, placeholder, options }) {
    return (
        <Select
            value={value ? { value: value, label: value } : null}
            onChange={event => setValue(event.value)}
            placeholder={placeholder}
            styles={styles}
            options={options.map(opt => ({ value: opt, label: opt }))} />
    )
}



const Buttons = ({ event, changesMade, cancelBtn, createBtn, resetBtn, saveBtn, removeBtn }) => {
    if (Util.isNumberExist(event.id)) {
        if (changesMade) {
            return (<>
                {resetBtn}
                {saveBtn}
            </>)
        } else {
            return (<>
                {removeBtn}
            </>)
        }
        return null;
    } else {
        return (<>
            {cancelBtn}
            {createBtn}
        </>)
    }
}

export function EmailEvent({ hooks, event, updateEvent, removeEvent }) {
    const [whenHook, setWhenHook] = useState(event.whenHook);
    const [sendTo, setSendTo] = useState(event.sendTo);
    const [template, setTemplate] = useState(event.template)

    const [editTemplate, setEditTemplate] = useState(false);

    const [saving, setSaving] = useState(false);
    const onSaveBtn = () => {
        setSaving(true)
        Api.updateEmailEvent({
            id: Util.isNumberExist(event.id) ? event.id : 0,
            whenHook, sendTo, template
        }, response => {
            if (response.status === true) {
                updateEvent(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setSaving(false)
        })
    }

    const [deleting, setDeleting] = useState(false);
    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            setDeleting(true);
            Api.deleteEmailEvent(event.id, response => {
                if (response.status === true) {
                    removeEvent();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message)
                }
                setDeleting(false)
            })
        })
    }

    const onResetBtn = () => {
        setWhenHook(event.whenHook)
        setSendTo(event.sendTo)
    }


    const webHookOptions = useMemo(() => hooks.map($ => $.eventHook), hooks);
    const sendToOptions = useMemo(() => hooks.find($ => $.eventHook == whenHook)?.sendToOptions?.map($ => $.sendToOption) ?? [])
    const tags = useMemo(() => hooks.find($ => $.eventHook == whenHook)?.sendToOptions?.find($ => $.sendToOption == sendTo)?.tags ?? [])


    const canCreate = Util.isStringExists(whenHook) && Util.isStringExists(sendTo)
    const changesMade = event.whenHook !== whenHook || event.sendTo !== sendTo || event.template !== template;
    const templateEdited = event.template !== template;
    return (
        <div style={{ animation: !Util.isNumberExist(event.id) ? 'opacity-fade-in-md-anim 250ms' : undefined, width: '100%', marginBottom: '1rem', padding: '1rem', borderRadius: 10, border: '1px solid #00000010', background: 'white', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem', marginBottom: '1rem' }}>
                <div style={{ borderRadius: 10, background: '#00000000', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <h4 style={{ color: '#0f62fe', opacity: 0.85 }}>when</h4>
                    <Checkmark20 style={{ color: '#0f62fe', opacity: 0.85 }} />
                </div>
                <div style={{ borderRadius: 10, background: '#00000010', flex: 2, paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <TargetSelect setValue={value => {
                        if (whenHook == value) {
                            return;
                        }

                        setWhenHook(value)
                        setSendTo("")
                    }} value={whenHook} options={webHookOptions} />
                </div>
                <div style={{ borderRadius: 10, background: '#00000000', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <h4 style={{ color: '#0f62fe', opacity: 0.85 }}>send to</h4>
                    <ArrowRight20 style={{ color: '#0f62fe', opacity: 0.85 }} />
                </div>
                <div style={{ borderRadius: 10, background: '#00000010', flex: 1, paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <TargetSelect setValue={setSendTo} value={sendTo} options={sendToOptions} />
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <Button onClick={() => setEditTemplate(true)} renderIcon={LicenseDraft16} size="sm" kind="ghost" style={{ borderRadius: 25, color: '#00551a' }}>Edit Template</Button>
                {templateEdited && <Tag size="sm" type='high-contrast'>unsaved changes</Tag>}
                <div style={{ flex: 1 }} />
                <Buttons event={event} changesMade={changesMade}

                    cancelBtn={
                        <Button onClick={removeEvent} disabled={saving} renderIcon={Close16} size="sm" kind="secondary" style={{ borderRadius: 25 }}>Cancel</Button>
                    }
                    createBtn={
                        <Button disabled={!canCreate} loading={saving} onClick={onSaveBtn} renderIcon={CheckmarkFilled16} size="sm" style={{ borderRadius: 25 }}>Create</Button>
                    }

                    resetBtn={
                        <Button onClick={onResetBtn} renderIcon={Reset16} size="sm" kind="ghost" style={{ borderRadius: 25 }}>Reset to Default</Button>
                    }
                    saveBtn={
                        <Button onClick={onSaveBtn} loading={saving} renderIcon={CheckmarkFilled16} size="sm" style={{ borderRadius: 25 }}>Save</Button>
                    }

                    removeBtn={
                        <Button onClick={onDeleteBtn} loading={deleting} renderIcon={Close16} size="sm" kind="danger-ghost" style={{ borderRadius: 25 }}>Delete</Button>
                    }
                />
            </div>

            <Portal>
                {editTemplate && <SidePanel onClose={() => setEditTemplate(false)}>
                    <TemplateDesigner onClose={() => setEditTemplate(false)} value={template} setValue={setTemplate} tags={tags} changesMade={templateEdited} onResetBtn={() => setTemplate(event.template)} />
                </SidePanel>}
            </Portal>
        </div>
    )
}