import Theme from "../Theme";

class PinkTheme extends Theme {

    getName() {
        return "pink";
    }

    getColor() {
        return "#8f4e5d";
    }

    getClassName() {
        return "theme-pink"
    }

}

export default PinkTheme;