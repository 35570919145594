import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TEMPLATE } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

import { ErrorFilled16, AddFilled16, Document32, Product32, Box32, Tag32 } from '@carbon/icons-react'
import { ButtonSet, ComposedModal, ModalBody, ModalFooter, ModalHeader, RadioTile, TileGroup } from 'carbon-components-react';
import Button from '../../components/Button';
import { TEMPLATE_TYPE_BUNDLE_LABEL, TEMPLATE_TYPE_GENERIC, TEMPLATE_TYPE_LABEL_GENERIC, TEMPLATE_TYPE_PRODUCT_LABEL } from '../label-designer/templates/Template';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

const RadioItem = ({icon, title, desc}) => (
    <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {React.createElement(icon)}
            <h4 style={{marginLeft: '0.5rem'}}>{title}</h4>
        </div>
        <p style={{marginTop: '0.5rem', fontSize: 12, opacity: 0.65}}>
            {desc}
        </p> 
    </div>
)


class TemplatesListPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            showTypeSelector: false,
            selectedType: TEMPLATE_TYPE_PRODUCT_LABEL,
            creatingTemplate: false
        }
    }

    createTemplate() {
        this.setState({creatingTemplate: true})
        Api.createTemplate(this.state.selectedType, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.props.history.push(`/template-designer/${response.payload}`)
                this.setState({creatingTemplate: false, showTypeSelector: false})
            } else {
                UIUtil.showError();
                this.setState({creatingTemplate: false})
            }
        })
    }

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Templates", 
                    subTitle: "All templates", 
                    objectType: OBJECT_TYPE_TEMPLATE, 
                    pagePath: "/templates/", 
                    history: this.props.history,
                    customNewButtonListener: () => this.setState({showTypeSelector: true, selectedType: TEMPLATE_TYPE_PRODUCT_LABEL}),
                    customRowButtonListener: (id) => this.props.history.push(`/template-designer/${id}`)
                })}

                <ComposedModal
                key="save-state-dialog"
                open={this.state.showTypeSelector}
                onClose={() => this.setState({ showTypeSelector: false })}
                size="lg">
                    <ModalHeader label="Creating Template" title="Choose a type" />
                    <ModalBody style={{paddingRight: '1rem'}}>
                        <TileGroup className="horizontal-tile-radio" valueSelected={this.state.selectedType} onChange={value => this.setState({ selectedType: value })}>
                            <RadioTile light value={TEMPLATE_TYPE_GENERIC + ""}>
                                <RadioItem icon={Document32} title="Generic Template" desc="Generic template that covers everything such as flyers, posters, etc." />
                            </RadioTile>
                            <RadioTile light value={TEMPLATE_TYPE_PRODUCT_LABEL}>
                                <RadioItem icon={Product32} title="Product Label" desc="Supports dynamic content from product's fields" />
                            </RadioTile>
                            <RadioTile light value={TEMPLATE_TYPE_BUNDLE_LABEL}>
                                <RadioItem icon={Box32} title="Product Bundle Label" desc="Supports dynamic content from product bundle's fields" />
                            </RadioTile>
                            <RadioTile light value={TEMPLATE_TYPE_LABEL_GENERIC}>
                                <RadioItem icon={Tag32} title="Label (generic)" desc="Supports some dynamic content from product and product bundles" />
                            </RadioTile>
                        </TileGroup>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonSet style={{width: '100%'}}>
                            <Button kind="secondary" onClick={() => this.setState({showTypeSelector: false})} renderIcon={ErrorFilled16}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.createTemplate()} renderIcon={AddFilled16} loading={this.state.creatingTemplate}>
                                Create
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>
            </div>
        )
    }
    
    isPageLoadable() {
        return false;
    }

}

export default withRouter(TemplatesListPage);