
import './input.scss'
import { CloseFilled16, Search16 } from '@carbon/icons-react';
import { useEffect, useState } from 'react';
import Util from '../../util/Util';

function Input({ placeholder, value, onUpdateValue }) {
    const [hasValue, setHasValue] = useState(false);
    const [closeHover, setCloseHover] = useState(false);

    useEffect(() => {
        setHasValue(Util.isStringExists(value))
    }, [value])

    return (
        <div className='custom-input-parent'>
            <Search16 />
            <input placeholder={placeholder}
                value={value}
                onChange={e => {
                    if (onUpdateValue) {
                        onUpdateValue(e.target.value)
                    }
                }} />
            <CloseFilled16
                onMouseEnter={() => setCloseHover(true)} onMouseLeave={() => setCloseHover(false)}
                onClick={() => onUpdateValue('')}
                style={{
                    transform: hasValue ? 'scale(1)' : 'scale(0)', transition: '250ms',
                    opacity: closeHover ? 1 : 0.65,
                    cursor: 'pointer'
                }} />
        </div>
    )
}

export default Input;