import { withLoadablePage, withLoadablePageAndProps, withLoadablePageWithParams } from "../../base/Page";
import Api from "../../session/Api";

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Delivery32,
    Building32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    User32,
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    CheckmarkOutline16,
    CheckmarkFilled16,
    ErrorFilled16,
    Enterprise32,
    Document32,
    RowDelete16,
    ChartBullet32,
    Money16
} from '@carbon/icons-react'
import { ButtonSet, ComboBox, ContentSwitcher, DatePicker, DatePickerInput, NumberInput, RadioTile, Switch, Tag, TextArea, TextInput, TileGroup } from "carbon-components-react";
import { getObjectTypeName, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from "../../constants/ObjectTypes";
import { hasCapabilitySupport } from "../../app/Capabilities";
import React, { useEffect, useMemo, useState } from "react";
import Util, { absAmt, big, flattenItems } from "../../util/Util";
import useStore from "../../hooks/useStore";
import { makeObservable } from "../../util/makeObservable";
import { BILL_AMOUNT_MODE_FREE_FORM, BILL_AMOUNT_MODE_ITEM_BASED } from "../../domain/bill";
import { NoStateTaxInput, TAX_MODE, TaxInput } from "../stock-flow/TaxInput";

import Big from 'big.js';
import Button from "../../components/Button";
import UIUtil from "../../util/UIUtil";
import { useHistory } from "react-router-dom";
import CustomComboBox from "../../components/CustomComboBox";
import { LEDGER_TYPE_ASSET, LEDGER_TYPE_EXPENSE } from "../../constants/Constants";
import ProductServiceTable from "../../views/editable-tables/ProductServiceTable";
import Calendar from "../../components/date/Calendar";
import { SelectShipments } from "./bill-shipments";
import { fixIdForApi } from "../../templates/form/form-api";
import { AmountTagSelector } from "../journal-entries/tag-selector";


const Root = ({ children, noPadding }) => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: noPadding ? 0 : '6rem', paddingBottom: noPadding ? 0 : '6rem' }}>
        <div style={{ width: '95vw' }}>
            {children}
        </div>
    </div>
)

const Section = ({ children, icon, title, options, extraTopMargin, noMargin }) => (
    // <div style={{ marginTop: extraTopMargin ? '6rem' : '5rem', borderTop: 'solid', paddingTop: '1rem', borderWidth: 1, borderColor: '#00000060' }}>
    <div style={{ marginTop: noMargin ? 0 : extraTopMargin ? '6rem' : '3rem', }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: 1 }}>{title}</p>
            {options}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginTop: '0.5rem', textAlign: 'center' }}>{title}</h4>
        <p style={{ marginTop: '0rem', textAlign: 'center', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

const Title = () => (
    <div>
        <h1>Bill</h1>
        <p style={{ fontSize: 18 }}>Creating new</p>
    </div>
)

function getPayeeTypeList(type, endpoints) {
    switch (type) {
        case OBJECT_TYPE_SUPPLIER:
            return endpoints.suppliers;
        case OBJECT_TYPE_VENDOR:
            return endpoints.vendors;
        case OBJECT_TYPE_VENUE:
            return endpoints.venues;
        default:
            return undefined;
    }
}

const SelectPayee = ({ store, endpoints }) => {
    const isEditing = useIsEditing(store)
    const [type, setType] = useStore(store, 'payeeType')
    const [id, setId] = useStore(store, 'payeeId')
    const [pickerKey, setPickerKey] = useState(Util.newTempId())

    const onTypeChange = value => {
        setType(value)
        setId(0)
        setPickerKey(Util.newTempId())
    }

    const onPickerChange = e => {
        setId(e.selectedItem !== null ? e.selectedItem.id : 0)
        if (e.selectedItem === null) {
            setPickerKey(Util.newTempId())
        }
    }

    const partyTypeList = getPayeeTypeList(type, endpoints)

    return (
        <Section icon={Number_132} title="Select Payee" noMargin={isEditing}>
            <TileGroup className="horizontal-tile-radio centered-content-tile"
                valueSelected={type} onChange={onTypeChange}>
                <RadioTile value={OBJECT_TYPE_SUPPLIER}>
                    <RadioItem icon={Delivery32} title="Supplier" desc="Select supplier " />
                </RadioTile>
                <RadioTile value={OBJECT_TYPE_VENDOR}>
                    <RadioItem icon={Enterprise32} title="Vendor" desc="Select vendor" />
                </RadioTile>
                {hasCapabilitySupport("thirdPartyPos") &&
                    <RadioTile value={OBJECT_TYPE_VENUE}>
                        <RadioItem icon={Building32} title="Venue" desc="Select venue " />
                    </RadioTile>}
            </TileGroup>

            {partyTypeList !== undefined && <>
                <div style={{ height: '1rem' }} />
                <ComboBox
                    key={pickerKey}
                    titleText={getObjectTypeName(type)}
                    items={partyTypeList}
                    itemToString={item => item !== null ? item.value : ""}
                    selectedItem={partyTypeList.filter(item => item.id == id)[0]}
                    onChange={onPickerChange}
                />
            </>}
        </Section>
    )
}

const calcAmount = (subtotal, tax) => big(subtotal).add(big(tax));

const useAmount = (store) => {
    const [subtotal, setSubtotal] = useStore(store, "subtotal");
    const [tax, setTax] = useStore(store, "tax");

    return [calcAmount(subtotal, tax).toFixed(2), () => {
        setSubtotal(0)
        setTax(0)
    }];
}

const useIsEditing = (store) => {
    const [editMode, _] = useStore(store, "editMode")
    //return editMode !== null && editMode !== undefined;
    return editMode;
}

const FreeFormAmount = ({ store }) => {
    const [subtotal, setSubtotal] = useStore(store, "subtotal");
    const [tax, setTax] = useStore(store, "tax");
    const [taxMode, setTaxMode] = useStore(store, "taxMode");
    return (
        <div>
            <div style={{ marginBottom: '1rem' }}>
                <label className="bx--label">{'Amount (without tax)'}</label>
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                    <p>AED</p>
                    <TextInput
                        size="lg" hideSteppers min={0} invalidText="Invalid numeric value"
                        value={subtotal} onChange={e => setSubtotal(e.target.value < 0 ? 0 : e.target.value)} />
                </div>
            </div>

            <div style={{ marginBottom: '1rem' }}>
                <NoStateTaxInput
                    mode={taxMode} setMode={setTaxMode}
                    amount={subtotal}
                    taxValue={tax} onTaxValueChange={setTax} />
            </div>
        </div>
    )
}

const ItemRecord = ({ onRemove, item, setItem, last }) => {
    const total = useMemo(() => big(item.qty).times(big(item.unitAmount)).toFixed(2), [item])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', }}>
            <div style={{ flex: 2, }}>
                <TextInput value={item.description} onChange={e => setItem({ ...item, description: e.target.value })} placeholder="Input..." />
            </div>
            <div style={{ flex: 2, }}>
                <TextInput value={item.unitAmount} onChange={e => setItem({ ...item, unitAmount: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 1, }}>
                <TextInput value={item.qty} onChange={e => setItem({ ...item, qty: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold' }}>AED {total}</p>
            </div>
            <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                size="sm" disabled={last}
                style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />
        </div>
    )
}

const ItemsAmount = ({ store }) => {
    const [subtotal, setSubtotal] = useStore(store, "subtotal");
    const [tax, setTax] = useStore(store, "tax");
    const [taxMode, setTaxMode] = useStore(store, "taxMode");

    const [items, setItems] = useStore(store, "items")

    const updateItem = item => {
        const newItems = [...items]
        const index = newItems.findIndex(i => i.tempId === item.tempId);
        if (index >= 0) {
            newItems[index] = item;
            setItems(newItems)
        }
    }

    const removeItem = item => setItems(items.filter(i => i.tempId !== item.tempId))

    useEffect(() => {
        const lastItem = items[items.length - 1];
        if (Object.keys(lastItem).length > 1) {
            setItems([...items, { tempId: Util.newTempId() }])
        }

        setSubtotal(items
            .slice(0, items.length - 1)
            .map(item => big(item.qty).times(big(item.unitAmount)))
            .reduce((t, c) => t.add(c), big(0)))
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    return (
        <div>
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 2, paddingLeft: '1rem', }}>
                        <h6>Description</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '1rem', }}>
                        <h6>Unit Amount</h6>
                    </div>
                    <div style={{ flex: 1, paddingLeft: '1rem', }}>
                        <h6>Qty</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '1rem', }}>
                        <h6>Total</h6>
                    </div>
                    <div style={{ width: 40, height: 0, }} />
                </div>
                {items.map((item, i) =>
                    <ItemRecord key={item.tempId} item={item} setItem={updateItem} last={i === items.length - 1} onRemove={() => removeItem(item)} />)}
            </div>

            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <NoStateTaxInput
                    mode={taxMode} setMode={setTaxMode}
                    amount={subtotal}
                    taxValue={tax} onTaxValueChange={setTax} />
            </div>
        </div>
    )
}

const Amount = ({ store }) => {
    const [mode, setMode] = useStore(store, "amountMode")
    const [amount, clearAmount] = useAmount(store);

    // useEffect(() => {
    //     clearAmount()
    // }, [mode])
    const changeMode = mode => {
        setMode(mode)
        clearAmount()
    }
    return (
        <Section icon={Number_332} title="Set Amount">
            <label className="bx--label">Amount Mode</label>
            <ContentSwitcher selectedIndex={mode} onChange={e => changeMode(e.name)}>
                <Switch name={BILL_AMOUNT_MODE_FREE_FORM} text="Free-Form" />
                <Switch name={BILL_AMOUNT_MODE_ITEM_BASED} text="List Based" />
            </ContentSwitcher>

            <div style={{ height: '1rem' }} />
            {mode == BILL_AMOUNT_MODE_FREE_FORM ?
                <FreeFormAmount store={store} /> :
                <ItemsAmount store={store} />}

            <div style={{ height: '1rem' }} />
            <h5>Total Amount</h5>
            <h2>AED {amount}</h2>
        </Section>
    )
}

const ExpenseAccountRecord = ({ onRemove, item, setItem, last, accountTreeItems, endpoints }) => {
    const [selectedAccount, setSelectedAccount] = useState(() => {
        return flattenItems(accountTreeItems).find($ => $.id === item?.accountLedgerId)
    })
    useEffect(() => {
        const newItem = { ...item, }
        if (selectedAccount) {
            newItem.accountLedgerId = selectedAccount.id
        } else {
            delete newItem.accountLedgerId
        }
        setItem(newItem)
        // setItem({ ...item, accountLedgerId: selectedAccount ? selectedAccount.id : undefined })
    }, [selectedAccount])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', }}>
            <div style={{ flex: 3, }}>
                {/* <TextInput value={item.description} onChange={e => setItem({ ...item, description: e.target.value })} placeholder="Input..." /> */}
                <div style={{ height: 40 }}>
                    <CustomComboBox
                        items={accountTreeItems}
                        selectedItem={selectedAccount}
                        onSelectedItemUpdate={item => setSelectedAccount(item)}
                    // selectedItem={this.state.otherPartyIdObject}
                    // onSelectedItemUpdate={item => this.setState({ otherPartyIdValue: item !== undefined ? item.id : 0, otherPartyIdObject: item })}
                    />
                </div>
            </div>
            <div style={{ flex: 3, height: 40 }}>
                <AmountTagSelector options={endpoints.predefinedClasses} value={item.tags ?? []} setValue={tags => setItem({ ...item, tags: tags.map(tag => ({ ...tag, name: tag.label })) })} minHeight={40} />
            </div>
            <div style={{ flex: 2, }}>
                <TextInput value={item.amount} onChange={e => setItem({ ...item, amount: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 4, }}>
                <TextInput value={item.narration} onChange={e => setItem({ ...item, narration: e.target.value })} placeholder="Input..." />
            </div>
            <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                size="sm" disabled={last}
                style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />
        </div>
    )
}

const ExpenseAccountsTable = ({ store, endpoints }) => {
    const [total, setTotal] = useStore(store, "expenseAccountsTotal");
    const [items, setItems] = useStore(store, "expenseAccounts")

    const id = item => Util.isNumberExist(item.id) ? item.id : item.tempId;

    const updateItem = item => {
        const newItems = [...items]
        const index = newItems.findIndex(i => id(i) === id(item));
        if (index >= 0) {
            newItems[index] = item;
            setItems(newItems)
        }
    }

    const removeItem = item => setItems(items.filter(i => id(i) !== id(item)))

    useEffect(() => {
        const lastItem = items[items.length - 1];
        if (Object.keys(lastItem).length > 1) {
            setItems([...items, { tempId: Util.newTempId() }])
        }

        setTotal(items
            .slice(0, items.length - 1)
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)))
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    //const accountTreeItems = endpoints.accountTree.filter(item => item.id == LEDGER_TYPE_EXPENSE)[0].items
    const accountTreeItems = useMemo(() => {
        const accountTree = [...endpoints.accountTree];
        for (const group of accountTree) {
            group.items = group.items.filter(item => item.name != "Inventory");
        }
        return endpoints.accountTree.filter(item => item.id == LEDGER_TYPE_EXPENSE || item.id == LEDGER_TYPE_ASSET)
    }, [endpoints])

    return (
        <div>
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Class</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                    <div style={{ width: 40, height: 0, }} />
                </div>
                {items.map((item, i) =>
                    <ExpenseAccountRecord key={id(item)} endpoints={endpoints} accountTreeItems={accountTreeItems} item={item} setItem={updateItem} last={i === items.length - 1} onRemove={() => removeItem(item)} />)}
            </div>

        </div>
    )
}

const Shipments = ({ store, endpoints }) => {
    const [value, setValue] = useStore(store, 'againstShipments')
    return (
        <Section icon={Number_432} title={"Specify Shipments"}>
            <SelectShipments value={value} setValue={setValue} />
        </Section>
    )
}

const ExpenseAccounts = ({ store, endpoints }) => {
    const [subtotal] = useStore(store, "subtotal");
    const [total, setTotal] = useStore(store, "expenseAccountsTotal");

    const totalAboveSubtotal = useMemo(() => big(total).gt(big(subtotal)), [total, subtotal])

    return (
        <Section icon={hasCapabilitySupport("shipping") ? Number_532 : Number_432} title="Specify Accounts (optional)" options={<Tag renderIcon={Money16} type="purple">Bill Amount: AED {big(subtotal).toFixed(2)}</Tag>}>
            {totalAboveSubtotal ? (
                <label style={{ color: 'red' }} className="bx--label">Account amounts total is greater than bill amount!</label>
            ) : (
                <label className="bx--label">Any amount not accounted for will be recorded under misc expenses</label>
            )}
            <ExpenseAccountsTable store={store} endpoints={endpoints} />
        </Section>
    )
    // return (
    //     <Section icon={Number_432} title="Specify Expense Accounts (optional)" options={<Tag renderIcon={Money16} type="purple">Expensable Amount: AED {big(subtotal).toFixed(2)}</Tag>}>
    //         {totalAboveSubtotal ? (
    //             <label style={{ color: 'red' }} className="bx--label">Account amounts total is greater than expensable amount!</label>
    //         ) : (
    //             <label className="bx--label">Any amount not accounted for will be recorded under misc expenses</label>
    //         )}
    //         <ExpenseAccountsTable store={store} endpoints={endpoints} />
    //     </Section>
    // )
}

const AdditionalInformation = ({ store }) => {
    const [info, setInfo] = useStore(store, 'info')
    return (
        <Section icon={hasCapabilitySupport("shipping") ? Number_632 : Number_532} title="Additional Information (optional)">
            <TextArea placeholder="Note here..." value={info} onChange={e => setInfo(e.target.value)} />
        </Section>
    )
}


const BillRecordInfo = ({ store }) => {
    const [refNo, setRefNo] = useStore(store, 'refNo')
    const [billDate, setBillDate] = useStore(store, 'billDate')
    return (
        <Section icon={Number_232} title="Bill Record Info (optional)">
            <div style={{ marginBottom: '1rem' }}>
                <TextInput labelText="Ref no (optional)" value={refNo} onChange={e => setRefNo(e.target.value)} placeholder="Input..." />
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <DatePicker datePickerType="single"
                    dateFormat="d/m/Y"
                    value={Util.isNumberExist(billDate) ? billDate : undefined}
                    onChange={e => {
                        if (e.length > 0) {
                            setBillDate(e[0].getTime())
                        } else {
                            setBillDate(0)
                        }
                    }}
                >
                    <DatePickerInput
                        // placeholder="mm/dd/yyyy"
                        placeholder="dd/mm/yyyy"
                        labelText={"Date (optional)"}
                    />
                </DatePicker>
            </div>
        </Section>
    )
}




function initState(observable, editMode) {
    if (editMode) {
        // payeeType: state.payeeType,
        // payeeId: state.payeeId,
        // subtotal: big(state.subtotal).toFixed(2),
        // tax: big(state.tax).toFixed(2),
        // items: state.items.slice(0, state.items.length - 1),
        // accountDivisions: state.expenseAccounts.slice(0, state.expenseAccounts.length - 1),
        // info: state.info,
        // billDate: state.billDate,
        // refNo: state.refNo,
        // againstShipments: fixIdForApi(state.againstShipments),

        // alert(editMode.subtotal)
        observable.set("payeeType", editMode.payeeType)
        observable.set("payeeId", editMode.payeeId)
        observable.set("amountMode", editMode.items.length ? BILL_AMOUNT_MODE_ITEM_BASED : BILL_AMOUNT_MODE_FREE_FORM)
        observable.set("subtotal", editMode.subtotal)
        observable.set("tax", editMode.tax)
        observable.set("taxMode", big(editMode.tax).gt(big(0)) ? TAX_MODE.MODE_5 : TAX_MODE.MODE_0)
        observable.set("items", [...editMode.items.map($ => ({ ...$, tempId: Util.newTempId() })), { tempId: Util.newTempId() }])
        observable.set("expenseAccounts", [...editMode.accountDivisions, { tempId: Util.newTempId() }])
        observable.set("expenseAccountsTotal", editMode.items.slice(0, editMode.items.length - 1).map(item => big(item.amount)).reduce((t, c) => t.add(c), big(0)))
        observable.set("info", editMode.info)
        observable.set("billDate", editMode.billDate)
        observable.set("refNo", editMode.refNo)

        observable.set("againstShipments", editMode.againstShipments)

    } else {
        observable.set("payeeType", OBJECT_TYPE_SUPPLIER)
        observable.set("payeeId", 0)
        observable.set("amountMode", BILL_AMOUNT_MODE_FREE_FORM)
        observable.set("subtotal", 0)
        observable.set("tax", 0)
        observable.set("taxMode", TAX_MODE.MODE_5)
        observable.set("items", [{ tempId: Util.newTempId() }])
        observable.set("expenseAccounts", [{ tempId: Util.newTempId() }])
        observable.set("expenseAccountsTotal", 0)
        observable.set("info", "")
        observable.set("billDate", 0)
        observable.set("refNo", "")

        observable.set("againstShipments", [])
    }

    observable.set("editMode", editMode)
}

function validate(store) {
    const {
        subtotal, tax, expenseAccountsTotal, payeeId
    } = store.toObject();

    const amount = calcAmount(subtotal, tax);
    if (big(amount).lte(big(0))) {
        return "Total amount has to be a positive number";
    }

    if (big(expenseAccountsTotal).gt(big(subtotal))) {
        return "Account amounts total is greater than expensable amount (amount - tax)!"
    }

    if (payeeId <= 0) {
        return "Payee not selected";
    }

    return undefined;
}

function toBill(store) {
    const state = store.toObject();
    return {
        payeeType: state.payeeType,
        payeeId: state.payeeId,
        subtotal: big(state.subtotal).toFixed(2),
        tax: big(state.tax).toFixed(2),
        items: state.items.slice(0, state.items.length - 1),
        accountDivisions: state.expenseAccounts.slice(0, state.expenseAccounts.length - 1),
        info: state.info,
        billDate: state.billDate,
        refNo: state.refNo,
        againstShipments: fixIdForApi(state.againstShipments),
    }
}

const Confirm = ({ store }) => {
    const isEdit = useIsEditing(store)
    const [creating, setCreating] = useState(false);
    const history = useHistory();

    const createBill = () => {
        const err = validate(store);
        if (err) {
            UIUtil.showError(err);
            return;
        }

        setCreating(true);
        const listener = response => {
            setCreating(false)

            if (response.status === true) {
                UIUtil.showSuccess();
                history.replace("/bill/" + response.payload + "?temp=" + Math.random())
            } else {
                UIUtil.showError(response.message);
            }
        }
        if (isEdit) {
            Api.updateBill({ ...toBill(store), id: isEdit.id }, listener)
        } else {
            Api.createBill(toBill(store), listener)
        }
    }

    return (
        <Section icon={hasCapabilitySupport("shipping") ? Number_732 : Number_632} title="Confirm" extraTopMargin>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonSet style={{ width: 392 }}>
                    <Button onClick={() => initState(store, store.get("editMode"))} disabled={creating} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                    <Button onClick={() => createBill()} loading={creating} renderIcon={ChartBullet32}>
                        {isEdit ? "Save Edits" : "Create Bill"}
                    </Button>
                </ButtonSet>
            </div>
        </Section>
    )
}

const View = ({ payload: endpoints, editing }) => {
    const store = useMemo(() => {
        const observable = makeObservable();
        initState(observable, editing);
        return observable;
    }, [])

    return (
        <Root noPadding={editing}>
            {!editing && <Title />}
            <SelectPayee store={store} endpoints={endpoints} />
            <BillRecordInfo store={store} />
            <Amount store={store} />
            {hasCapabilitySupport("shipping") && <Shipments store={store} endpoints={endpoints} />}
            <ExpenseAccounts store={store} endpoints={endpoints} />
            <AdditionalInformation store={store} />
            <Confirm store={store} />
        </Root>
    )
}

export default withLoadablePageAndProps(Api.getStockFlowEndpointsList.bind(Api), View);