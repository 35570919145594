import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#ff9b87'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#ffb6a8',
        backgroundColor: '#ffb6a8',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    a: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    // b: {
    //     width: '15%',
    //     borderRightColor: borderColor,
    //     borderRightWidth: 1,
    // },
    c: {
        //width: '25%',
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    d: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    e: {
        width: '20%'
    },
});

export const TableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.a}>Invoice No</Text>
        {/* <Text style={styles.b}>Description</Text> */}
        <Text style={styles.c}>Date</Text>
        <Text style={styles.d}>Amount</Text>
        <Text style={styles.e}>O/S Amount</Text>
    </View>
);
