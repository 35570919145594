import ProfilePic from "../../../components/ProfilePic"
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR } from "../../../constants/ObjectTypes"
import Api from "../../../session/Api"
import Util from "../../../util/Util"
import {
    EventSchedule16
} from '@carbon/icons-react'
import { Tag } from "carbon-components-react"
import { useEffect, useState } from "react"
import { formatUpcomingClasses } from "../role-pages/my-courses"

import {
    ArrowRight16, ArrowLeft16
} from '@carbon/icons-react'
import Button from "../../../components/Button"
import { TutorAvailabilityReport } from "./tutor-availability-report"
import { isMobileScreen } from "../../../App"


export function Header({ children, onBackBtn, availability }) {
    return (
        <div style={{ background: '#f4f4f4', gap: '0.5rem', paddingInline: '1rem', height: '3rem', width: '100%', borderBottom: '1px solid #00000040', display: 'flex', alignItems: 'center' }}>
            {onBackBtn && <button style={{ animation: 'advanced-notes-button-fade-in 250ms' }} onClick={onBackBtn} className='lead-flow-icon-button'>
                <ArrowLeft16 />
            </button>}
            <h4 style={{ animation: 'advanced-notes-button-fade-in 350ms' }}>{children}</h4>
            <div style={{ flex: 1 }} />
            {/* <NewBtn /> */}
            {/* <button onClick={() => { }} className='lead-flow-icon-button lead-flow-icon-button-primary' data-tip="New Item" data-effect="solid" data-place="bottom">
                <AddFilled16 />
            </button> */}
            {/* <ReactTooltip /> */}
            {availability && <Tag size="sm" type="blue">Availability Report</Tag>}
        </div>
    )
}

const Item = ({ tutor, setSelectedTutor }) => {
    return (
        <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem', alignItems: 'center', background: '#f4f4f4', border: '1px solid #00000040', borderRadius: 15, overflow: 'hidden' }}>
            <div style={{ padding: '1rem', background: '#1c1c1c', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_TUTOR, tutor.id)} />
            </div>
            <div style={{ height: '100%', flex: 1 }}>
                <h4 style={{ fontSize: 16 }}>{tutor.name}</h4>
            </div>
            {/* <Tag style={{}} type={"blue"} renderIcon={EventSchedule16}>{tutor.availability}</Tag> */}
            <Button onClick={() => setSelectedTutor(tutor)} size="sm" style={{ marginLeft: '0.15rem', borderRadius: 50 }} renderIcon={ArrowRight16}>Availability</Button>
            <div style={{ width: isMobileScreen ? '0rem' : '1rem' }} />
        </div>
    )
}

const Message = ({ msg }) => (
    <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ fontSize: 12, opacity: 0.65 }}>{msg}</p>
    </div>
)

export function MyTutorsList({ courseId }) {
    const [loading, setLoading] = useState(true);
    const [tutors, setTutors] = useState([]);
    const [inError, setInError] = useState(false);

    const [selectedTutor, setSelectedTutor] = useState(undefined);

    useEffect(() => {
        let cancelled = false;

        setSelectedTutor(undefined);
        setLoading(true);
        Api.getMyTutors(courseId, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setTutors(response.payload)
            }
            setInError(response.status !== true)
            setLoading(false);
        })
        return () => cancelled = true;
    }, [courseId])

    if (loading) {
        return null;
    }

    if (selectedTutor) {
        return (
            <div>
                <Header availability onBackBtn={() => setSelectedTutor(undefined)}>{selectedTutor.name}</Header>
                <TutorAvailabilityReport courseId={courseId} tutorId={selectedTutor.id} onClose={() => setSelectedTutor(undefined)} />
            </div>
        )
    }

    return (
        <div>
            <Header>Tutors</Header>
            <div style={{ padding: '1rem' }}>
                {tutors.map(tutor => <Item key={tutor.id} tutor={tutor} setSelectedTutor={setSelectedTutor} />)}
                {tutors.length === 0 && <Message msg={inError ? "An error occurred. Please try again later." : "No tutors"} />}
            </div>
        </div>
    )
}