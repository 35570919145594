import { useEffect, useState } from "react"
import CreatableSelect from 'react-select/creatable';
import { useListFilter } from "../../engine/useValueFilter";

const createStyle = style => (baseStyles) => ({
    ...baseStyles,
    ...style
})

const DEF_VALUE = []

export function MultiFreetextSelector({ state, property, skipAutoFilter, placeholder }) {
    const [filters, setFilters] = useListFilter(state, property)
    const [value, setValue] = state.use(property + 'state', DEF_VALUE)

    useEffect(() => {
        setFilters(value.map(item => ({
            skipAutoFilter,
            operator: "OR",
            filters: [
                { property, skipAutoFilter, operator: "CONTAINS", value: item.label },
                ...(item?.children?.map(child => ({ property, skipAutoFilter, operator: "CONTAINS", value: child })) ?? [])
            ]
        })))
    }, [value, property])

    return (
        <CreatableSelect
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: 30,
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 5,
                    background: '#ffffff10',
                    border: '1px solid #00000099',
                    color: 'white',
                    minHeight: 30,
                    height: 30,
                    padding: 0
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white',
                    height: 30,
                    margin: 0
                }),
                multiValue: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: 'white'
                }),
                multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: '#ffffffBB'
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                menu: createStyle({
                    background: '#1c1c1c'
                }),
                option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
                    ...baseStyles,
                    backgroundColor: isFocused ? '#ffffff20' : isSelected ? '#ffffff40 !important' : 'transparent'
                })
            }}
            isMulti
            options={[]}

        />
    )
}