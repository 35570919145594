import { useEffect } from "react";


export const useSaveShortcut = (onSave, deps) => useEffect(() => {
    const listener = e => {
        if ((e.ctrlKey || e.metaKey) && e.key === 's') {
            onSave();
            e.preventDefault();
        }
    }

    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener);
}, deps)