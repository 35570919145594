
import { QueryQueue32, CheckmarkFilled16, Scan32, Checkmark16, Barcode16, Box32, Close16, Receipt16 } from '@carbon/icons-react'
import { FormSection, useForm } from '../../../templates/form/form'
import { TableInput } from '../../../components/table-input'
import { ProductListSelector } from '../../../components/list-selector'
import Button from '../../../components/Button'
import useApi from '../../../hooks/useApi'
import { useState } from 'react'
import { ComboBox, TextInput } from 'carbon-components-react'
import { InvoiceSearchDialog } from '../../../pages/pos/dialogs/any-search-dialog'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import { printPackingListPdf } from './pdf'
import Util, { big } from '../../../util/Util'
import { useBarcodeScanner } from '../../../hooks/useBarcodeScanner'

const COLUMNS = [
    {
        key: "product", title: "Product", flex: 3, Component: ({ value, setMultiProp }) => {
            const setProduct = product => {
                const barcode = product?.barcodes?.filter?.($ => !$.systemGenerated)?.[0]
                setMultiProp({
                    product,
                    productId: product?.id ?? 0,
                    articleNo: product?.articleNo ?? "",
                    size: product?.size ?? "",
                    color: product?.color ?? "",
                    barcode: barcode?.value ?? "00",
                    barcodeType: barcode?.type ?? "CODE128",
                    coo: product?.coo ?? "",
                })
            }
            return (
                <div style={{ height: 40 }}>
                    <ProductListSelector value={value} setValue={setProduct} />
                </div>
            )
        }
    },
    {
        key: "articleNo", title: "Article No", flex: 2, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "size", title: "Size", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "invoiceQty", title: "Invoice Qty", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => { }} />
        }
    },
    {
        key: "packedQty", title: "Packed Qty", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "balanceQty", title: "Balance Qty", flex: 1, Component: ({ value, setValue }) => {
            const val = big(value);
            const positive = val.gt(big(0))
            const negative = val.lt(big(0))
            const color = positive ? 'green' : negative ? 'red' : 'black'
            return <TextInput style={{ color, fontWeight: 'bold' }} value={value} onChange={e => { }} />
        }
    },
    {
        key: "barcode", title: "Barcode", flex: 2, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "barcodeType", title: "Barcode Type", flex: 2, Component: ({ value, setValue }) => {
            //return <TextInput placeholder='CODE128 or EAN13' value={value} onChange={e => setValue(e.target.value)} />
            return (
                <ComboBox
                    style={{ width: '100%', }}
                    items={["CODE128", "EAN13"]}
                    selectedItem={value}
                    onChange={item => setValue(item?.selectedItem)}
                />
            )
        }
    },
    {
        key: "coo", title: "COO", flex: 2, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    }
]

function ItemListField({ value, setValue }) {
    return <TableInput minimal columns={COLUMNS} value={value} setValue={setValue} />
}

const notificationAudio = new Audio("/positive.wav")

export function PackingListForm({ endpoint, form, save }) {
    const [scanning, setScanning] = useState(false);

    const [loadingGenerate, setLoadingGenerate] = useState(false);
    const onGenerateBtn = async () => {
        setLoadingGenerate(true)
        try {
            await printPackingListPdf(form.getFormData())
        } catch (e) {
            UIUtil.showError("Invalid barcode value")
        } finally {
            setLoadingGenerate(false)
        }
    }


    useBarcodeScanner(scanning, barcode => {
        const items = form.store.get("items") ?? []
        const item = items.find($ => $.barcode === barcode)
        if (item) {
            const update = items.map($ => $.id === item.id ? ({
                ...$, packedQty: big($.packedQty).add(big(1)).toNumber()
            }) : $)
            form.store.set("items", update)

            try {
                notificationAudio.pause();
                notificationAudio.currentTime = 0;
                notificationAudio.play().catch(() => { })
            } catch (e) { }
        }
    })

    form.useChangeListener('items', () => {
        const items = form.store.get("items") ?? []
        const updates = items.map($ => ({
            ...$,
            balanceQty: big($.packedQty).minus(big($.invoiceQty)).round(2).toNumber()
        }))
        const balanceChanged = updates.some(update => items.some($ => $.id === update.id && !big($.balanceQty).eq(big(update.balanceQty))));
        if (balanceChanged) {
            form.store.set("items", updates)
        }
    })

    return (
        <div style={{}}>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '1rem', marginTop: '-6rem', paddingInline: '3rem' }}>
                <div>
                    <QueryQueue32 style={{ width: 64, height: 64 }} />
                    <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>Packing List</h3>
                    {/* <h4>{notification.id}</h4> */}
                    <h4 style={{ color: '#0f62fe', fontSize: 16, marginTop: -3 }}>Generate Document</h4>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>All data can be changed temporarily for document</p>

                </div>
                <div style={{ flex: 1 }} />
            </div>


            <FormSection title={"Carton No"} titleStyle={{ paddingInline: '3rem' }}>
                <div style={{
                    marginInline: '3rem',
                    display: 'inline-flex', borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
                    alignItems: 'center', gap: '0.25rem'
                }}>
                    <Box32 />
                    <h3 style={{ fontWeight: 'bold', }}>{form.store.get('cartonNo')}</h3>
                </div>
            </FormSection>


            <div style={{ paddingInline: '3rem' }}>
                <FormSection title={"Document Info"}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey={"invoiceNo"} title="Invoice No" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey={"customerName"} title="Customer Name" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="location" title="Location" />
                        </div>
                        {/* <div style={{ flex: 1 }}>
                        <form.TextField fieldKey="cartonNo" title="Carton No" />
                    </div> */}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="size" title="Carton Size" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="weight" title="Carton Weight (KG)" help="Numeric value allowed only" />
                        </div>
                        <div style={{ flex: 1 }}>

                        </div>
                    </div>
                </FormSection>


            </div>

            <FormSection title={"Scanner"} titleStyle={{ paddingInline: '3rem' }}>
                {scanning ? (<>
                    <div style={{
                        background: '#1c1c1c', color: 'white', paddingBlock: '1rem',
                        paddingInline: '3rem',
                        // display: 'flex', gap: '1rem'
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                            <Scan32 />
                            <h4 style={{ fontWeight: 'bold' }}>Scanner Active</h4>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                            <Button onClick={() => setScanning(false)} size="sm" kind="danger" renderIcon={Close16} style={{ borderRadius: 5, marginTop: '1rem' }}>Stop Scanner</Button>
                            <Button className="green-button" onClick={() => {
                                setScanning(false)
                                save()
                            }} size="sm" kind="primary" renderIcon={Checkmark16} style={{ borderRadius: 5, marginTop: '1rem' }}>Stop and Save</Button>
                        </div>
                    </div>
                </>) : (<>
                    <Button onClick={() => setScanning(true)} renderIcon={Barcode16} style={{ borderRadius: 5, marginInline: '3rem' }}>Start Scanner</Button>
                </>)}


            </FormSection>
            <FormSection title={"Product List"} titleStyle={{ paddingInline: '3rem' }}>
                <form.CustomField fieldKey="items">
                    {ItemListField}
                </form.CustomField>
            </FormSection>

            <div style={{ paddingInline: '3rem' }}>
                <FormSection title={"Additional Info"}>
                    <form.TextAreaField fieldKey="note" title="Note (optional)" />
                </FormSection>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button onClick={onGenerateBtn} loading={loadingGenerate} renderIcon={CheckmarkFilled16} style={{ borderRadius: 5, }}>Generate Document</Button>
                </div>
            </div>

            <div style={{ height: '12rem' }} />

            {/* <InvoiceSearchDialog visible={loadInvoiceVisible} onClose={() => setLoadInvoiceVisible(false)} onItemSelected={loadInvoice} /> */}
        </div>
    )
}



/* <Button loading={loadingInvoice} onClick={() => setLoadInvoiceVisible(true)} kind="secondary" renderIcon={Receipt16} style={{ borderRadius: 5, marginBottom: '1rem' }}>Load from invoice</Button> */
/*
const [loadInvoiceVisible, setLoadInvoiceVisible] = useState(false);
    const [loadingInvoice, setLoadingInvoice] = useState(false);
    const loadInvoice = invoice => {
        Api.packingListLoadFromStockFlow(invoice.id, response => {
            if (response.status === true) {
                form.setData({
                    ...response.payload,
                    items: response.payload.items.map(item => ({
                        ...item,
                        id: Util.newTempId()
                    }))
                })
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoadingInvoice(false);
        })
        
    }
*/