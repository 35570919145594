import { InlineLoading, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput, Select, SelectItem } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import Api from '../../session/Api';
import Util from '../../util/Util';
import { WarningFilled32, Screen20, Printer20 } from '@carbon/icons-react'
import UIUtil from '../../util/UIUtil';
import { getTerminal, getThermalPrinterDriverId, getThermalPrinterParams, saveTerminal, saveThermalPrinterDriverId, saveThermalPrinterParams } from '../../session/SessionManager';
import { ACCOUNT_TYPE_ADMINISTRATION } from '../../constants/Constants';
import { getThermalPrinterDriverParams, THERMAL_PRINTER_DRIVERS } from '../../integrations/thermal-printer/ThermalPrinterDriverList';
import Button from '../../components/Button';

export default ({ open, onClose, onTerminalUpdate }) => {
    const [authorized, setAuthorized] = useState(false)
    const [loading, setLoading] = useState(false);
    const [loadingItems, setLoadingItems] = useState(false);

    const [terminal, setTerminal] = useState(getTerminal())
    const [stores, setStores] = useState(undefined);
    const [terminals, setTerminals] = useState(undefined);

    const [clearanceCode, setClearanceCode] = useState("")
    const [selectedStoreId, setSelectedStoreId] = useState(-1);
    const [selectedTerminalId, setSelectedTerminalId] = useState(-1);

    const [selectedPrinterId, setSelectedPrinterId] = useState(getThermalPrinterDriverId());
    const [selectedPrinterParamValue, setSelectedPrinterParamValue] = useState(getThermalPrinterParams());

    useEffect(() => {
        setAuthorized(false);
        setClearanceCode("")

        if (open) {
            setLoadingItems(true);

            setSelectedPrinterId(getThermalPrinterDriverId());
            setSelectedPrinterParamValue(getThermalPrinterParams());

            Api.getAllTerminals(response => {
                setLoadingItems(false);
                if (response.status === true) {
                    setStores(response.payload.stores)
                    setTerminals(response.payload.terminals)
                    if (terminal !== undefined && terminal !== null) {
                        setSelectedStoreId(terminal.storeId);
                        setSelectedTerminalId(terminal.id)
                    }
                } else {
                    UIUtil.showError(response.message);
                    onClose();
                }
            })
        }
    }, [open])

    const onAuthorizeBtn = () => {
        setLoading(true);
        Api.securityClearanceValidate(clearanceCode, ACCOUNT_TYPE_ADMINISTRATION, response => {
            if (response.status === true) {
                // UIUtil.showSuccess()
                setAuthorized(true);
            } else {
                UIUtil.showError("Clearance code could not be used for authorization");
            }
            setLoading(false)
        })
    }

    const changesMade = () => true;

    // const changesMade = () => {
    //     if (selectedPrinterId != getThermalPrinterDriverId()) {
    //         return
    //     }


    //     if (terminal !== undefined && terminal !== null) {
    //         return selectedTerminalId != terminal.id;
    //     } else if (selectedPrinterId !== undefined && selectedPrinterId !== null) {
    //         return selectedPrinterId != getThermalPrinterDriverId()
    //     } else {
    //         return true;
    //     }
    // }

    const onSetBtn = () => {
        let rawStore;
        let rawTerminal;

        for (const store of stores) {
            if (store.id == selectedStoreId) {
                rawStore = store;
                break;
            }
        }

        for (const terminal of terminals) {
            if (terminal.id == selectedTerminalId) {
                rawTerminal = terminal;
                break;
            }
        }

        const terminal = {
            terminalId: rawTerminal.value,
            store: rawStore.value,

            id: rawTerminal.id,
            storeId: rawStore.id,
        }
        saveTerminal(terminal);
        setTerminal(terminal);


        saveThermalPrinterDriverId(selectedPrinterId);
        saveThermalPrinterParams(selectedPrinterParamValue);

        onTerminalUpdate(terminal);

        UIUtil.showSuccess();
    }

    const content = authorized ? (<>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <Screen20 />
            <p style={{ marginLeft: '0.5rem', fontSize: 12 }}>
                {/* {terminal !== undefined && terminal !== null ? 
                terminal.terminalId + ' - ' + terminal.store : 'No terminal specified'} */}
                Terminal Config
            </p>
        </div>

        <Select
            style={{ width: '100%', marginBottom: '1rem' }}
            data-modal-primary-focus
            disabled={loading}
            value={selectedStoreId}
            onChange={e => {
                setSelectedStoreId(e.target.value);
                const storeTerminals = terminals.filter(terminal => terminal.storeId == e.target.value);
                if (storeTerminals.length > 0) {
                    setSelectedTerminalId(storeTerminals[0].id)
                }
            }}
            labelText="Store"
            placeholder="Select store"
        >
            <SelectItem value={-1} text={"Select store"} />
            {stores.map(store => <SelectItem value={store.id} text={store.value} />)}
        </Select>

        <Select
            style={{ width: '100%', marginBottom: '1rem' }}
            data-modal-primary-focus
            disabled={loading || !Util.isNumberExist(selectedStoreId)}
            value={selectedTerminalId}
            onChange={e => setSelectedTerminalId(e.target.value)}
            labelText="Terminal"
            placeholder="Select terminal"
        >
            {terminals.filter(terminal => terminal.storeId == selectedStoreId).map(terminal => <SelectItem value={terminal.id} text={terminal.value} />)}
        </Select>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '3rem', marginBottom: '1rem' }}>
            <Printer20 />
            <p style={{ marginLeft: '0.5rem', fontSize: 12 }}>
                POS Printer Config
            </p>
        </div>
        <Select
            style={{ width: '100%', marginBottom: '1rem' }}
            data-modal-primary-focus
            disabled={loading}
            value={selectedPrinterId}
            onChange={e => setSelectedPrinterId(e.target.value)}
            labelText="Receipt Printer Driver"
            placeholder="Select driver"
        >
            {THERMAL_PRINTER_DRIVERS.map(driverItem => <SelectItem value={driverItem.id} text={driverItem.name} />)}
        </Select>
        {getThermalPrinterDriverParams(selectedPrinterId).map(item => (
            item.node ? (
                <item.node
                    value={selectedPrinterParamValue[item.property]}
                    setValue={value => setSelectedPrinterParamValue(prevValue => ({ ...prevValue, [item.property]: value }))} />
            ) : (
                <TextInput
                    value={selectedPrinterParamValue[item.property]}
                    onChange={e => setSelectedPrinterParamValue(prevValue => ({ ...prevValue, [item.property]: e.target.value }))}
                    style={{ width: '100%', marginBottom: '1rem' }}
                    data-modal-primary-focus
                    labelText={item.name}
                    placeholder={item.name}
                />
            )
        ))}



        {loading &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <InlineLoading style={{ width: 'auto' }} />
            </div>}
    </>) : (<>
        <div style={{}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3rem', marginTop: '1rem' }}>
                <WarningFilled32 />
                <h2 style={{ fontSize: 16, marginLeft: '1rem', }}>Security clearance required!</h2>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '75%', height: 64, overflow: 'hidden' }}>
                    <TextInput.PasswordInput
                        value={clearanceCode}
                        onChange={e => setClearanceCode(e.target.value)}
                        style={{ width: '100%', }}
                        data-modal-primary-focus
                        labelText="Clearance Code"
                        placeholder="Input to continue"
                    />
                </div>
            </div>
        </div>
    </>)

    return (
        <ComposedModal open={open} onClose={onClose} size="sm">
            <ModalHeader label="Terminal" title="Setup Terminal" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                {loadingItems ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <InlineLoading style={{ width: 'auto' }} />
                    </div>
                ) : content}
            </ModalBody>
            <ModalFooter
            // primaryButtonDisabled={(authorized ? (!(changesMade() && Util.isNumberExist(selectedTerminalId) && Util.isNumberExist(selectedStoreId))) : !Util.isStringExists(clearanceCode)) || loading}
            // onRequestSubmit={authorized ? onSetBtn : onAuthorizeBtn}
            // primaryButtonText={authorized ? "Update" : (loading ? "Authorizing..." : "Authorize")} secondaryButtonText="Cancel"
            >

                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={(authorized ? (!(changesMade() && Util.isNumberExist(selectedTerminalId) && Util.isNumberExist(selectedStoreId))) : !Util.isStringExists(clearanceCode)) || loading}
                    onClick={authorized ? onSetBtn : onAuthorizeBtn}>
                    {authorized ? "Update" : (loading ? "Authorizing..." : "Authorize")}
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}