import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";
import { listDetailItemId } from "../../base/report-page";
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange } from "../../components/basic-filter/date-range";
import { Divider } from "../../components/basic-filter/divider";
import { MultiSelect } from "../../components/basic-filter/multi-select";


export const V2BillsReport = {
    filter: Filter,
    freezeCol: 1,

    onAction: (action, data, history) => {
        switch (action) {
            case "voucherNo":
                window.open(listDetailItemId("V2BillList", data.id), "_blank")
                break;
        }
    },

    customPath: "/bills-report",

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="billDate" />
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Vendor" fieldKey="vendorField" options={endpoint.vendors.map(item => item.value)} state={state} property="billVendor" />
        </div>
        <div style={{ height: 30, marginTop: '0rem', flex: 2 }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
