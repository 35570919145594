import { ContentSwitcher } from "carbon-components-react"
import CustomComboBox from "../../../components/CustomComboBox"
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util from "../../../util/Util"
import { AccountLedgerSelector, SubsidiaryLedgerSelector } from "../../components/basic-filter/account-ledger-selector"
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { ObjectTypeSelector } from "../../components/basic-filter/object-type-selector"
import { TextBox } from "../../components/basic-filter/text-box"
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter"
import { Link } from 'react-router-dom'
import { ArrowLeft16 } from '@carbon/icons-react'
import JournalEntriesItemPage from "../../../pages/journal-entries/JournalEntriesItemPage"

export const V2LedgerReport = {
    filter: Filter,
    customPath: "/detailed-ledger-report",
    isTreeSructure: true,
    loadTreeOnly: true,

    xlSideView: true,
    sideViewCustomTab: {
        name: "Journal Entry",
        component: JournalEntryPreview
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function JournalEntryPreview({ item }) {
    console.log(item.Jr)
    if (!Util.isNumberExist(item.jrId)) {
        return <p style={{ fontSize: 12, opacity: 0.65 }}>No journal entry selected</p>
    }

    return (
        <div>
            <h6>{item.entry_id}</h6>
            <JournalEntriesItemPage mini pagePathParamsOverride={{ journalEntryId: item.jrId }} />
        </div>
    )
}


const SHOW_ZERO_BALANCE_FILTER = {
    skipAutoFilter: true,
    value: true
}

function Filter({ endpoint, state }) {
    const [fromReport] = state.use("__fromReport", undefined);
    const [override] = state.use("__ledgerFilterOverride", undefined);

    return (<>
        {fromReport && <Link to={fromReport.link}><button className="lead-flow-icon-button lead-flow-icon-button-primary"><ArrowLeft16 /></button></Link>}
        <DateRange state={state} property="date" skipAutoFilter />
        <CheckboxFilter state={state} filterId="showZeroBalance" label="Show 0 balance" filter={SHOW_ZERO_BALANCE_FILTER} />
        <Divider />


        {override ? (
            <div className="filter-field" style={{ flex: 1 }}>
                <div style={{ height: 30, width: '100%', display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: 14 }}>{override.accountName}</p>
                </div>
            </div>
        ) : (
            <AccountLedgerSelector skipAutoFilter parentStyle={{ flex: 1 }} fieldStyle={{ width: '100%' }} accountTree={endpoint.accountTree} state={state} label="Account" property="ledgerId" />
        )}
        <SubsidiaryLedgerSelector parentStyle={{ flex: 1 }} fieldStyle={{ width: 'calc(100% + 0.40rem)' }} accountTree={endpoint.subsidiaryAccounts} state={state} label="Subsidiary" property="subsidiary_id" />


        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%' }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>

    </>)
}
