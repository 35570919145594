import WeeksProfitView from "./views/WeeksProfitView";


export function ProfitGraph({ dashboard }) {
    return (
        <div style={{ padding: '1rem' }}>
            <h3 style={{ fontWeight: 'bold' }}>Profit Performance</h3>
            <div className="square-stat-0" style={{ flex: 1, borderRadius: 25, width: '100%', padding: '1rem' }}>
                <WeeksProfitView dashboard={dashboard} />
            </div>
        </div>
    )
}