
import React from 'react'

const Item = ({item, columns}) => {
    return (
        <div style={{display: 'flex', 
        height: 65, fontSize: '.875rem', color: '#525252', borderBottom: '1px solid #e0e0e0', padding: '1rem .5rem 1.5rem', paddingLeft: '1rem'}}>
            {columns.map(column => (
                <div key={column.name + "-row-" + item.id} style={{flex: column.flex, display: 'flex', alignItems: 'center', gap: 5}}>
                    {column.renderCell(item)}
                </div>
            ))}
        </div>
    )
}

export default ({columns, items, minHeight, maxHeight, customBgColor}) => {
    return (
        <div className="dashboard-card" style={{overflowY: 'auto', overflowX: 'hidden', flex: 1, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
            <div className="bx--structured-list-th" 
            style={{display: 'flex', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5, paddingLeft: '1rem', borderBottom: '0px solid #e0e0e0', background: customBgColor ? customBgColor : '#1c1c1c', position: 'sticky', top: 0, zIndex: 999}}>
                {columns.map(column => (
                    <div key={column.name} style={{flex: column.flex}}>
                        {column.title}
                    </div>
                ))}
            </div>
            <div className="dashboard-card" style={{minHeight: minHeight ? minHeight : 300, maxHeight: maxHeight ? maxHeight : 300, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
                {items.length == 0 && <p style={{fontSize: 12, opacity: 0.65, marginTop: '1rem', marginLeft: '1rem'}}>No items</p>}
                {items.map(item => <Item key={item.id} item={item} columns={columns} />)}
            </div>
        </div>
    )
}