import {
    CalendarTools32, CalendarTools16, Theater24, Calendar16

} from '@carbon/icons-react'
import { ComboBox } from 'carbon-components-react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { withLoadablePage } from '../../../base/Page'
import Api from '../../../session/Api'
import Util from '../../../util/Util'
import { DayPicker } from './day-picker'
import { Scheduler } from './scheduler'
import { ShowtimeTimeline } from '../showtime-timeline'

function MenuButton({ label, active, onClick, fullWidth, children }) {
    return <button style={{ width: fullWidth ? 30 : 100, paddingInline: fullWidth ? 0 : undefined }} onClick={onClick} data-active={active} className='shipment-item-menu-button'>{children}</button>
}




function Toolbar({ cinemas, selectedCinema, setSelectedCinema, tabIndex, setTabIndex }) {
    const [key, setKey] = useState(() => Util.newTempId())
    useEffect(() => {
        setKey(Util.newTempId())
    }, [selectedCinema])
    return (

        <div className='' style={{ paddingInline: '1rem', height: '4rem', width: '100%', display: 'flex', alignItems: 'center', background: '#212121', color: 'white', borderBottom: '1px solid black' }}>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <CalendarTools32 style={{ marginRight: '0.5rem' }} />
                <div style={{}}>
                    <h4>Showtime scheduler</h4>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Movies</p>
                </div>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                    <MenuButton active={tabIndex === 0} onClick={() => setTabIndex(0)}><CalendarTools16 /> Scheduler</MenuButton>
                    <MenuButton active={tabIndex === 1} onClick={() => setTabIndex(1)}><Calendar16 /> Calendar</MenuButton>
                </div>
            </div>
            <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {tabIndex === 0 && (<>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Cinema:</p>
                    <div className="white-combo-box-arrow dark-text-input" style={{ marginLeft: 10 }}>
                        <ComboBox
                            key={key}
                            // size="lg"
                            style={{ background: 'black', color: 'white', width: 250 }}
                            placeholder="Select cinema"
                            // titleText="UOM"
                            value={cinemas.find(cinema => cinema.id == selectedCinema)?.value}
                            item={cinemas.find(cinema => cinema.id == selectedCinema)}
                            onChange={item => setSelectedCinema(item.selectedItem ? item.selectedItem.id : 0)}
                            itemToString={item => item ? item.value : ""}
                            items={cinemas}
                        />
                    </div>
                </>)}
            </div>
        </div>
    )
}

const NoSelectedMessage = () => {
    return (
        <div style={{ width: '100%', height: 'calc(100vh - 10rem)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '0.5rem', opacity: 0.65 }}>
            <Theater24 />
            <p style={{ fontSize: 12 }}>Please select a cinema</p>
        </div>
    )
}



function View({ payload: { cinemas } }) {
    const [tabIndex, setTabIndex] = useState(0);

    const [selectedCinema, setSelectedCinema] = useState(cinemas[0]?.id ?? 0)
    const [day, setDay] = useState(0);
    useEffect(() => { setDay(0) }, [selectedCinema])
    return (
        <div style={{ height: 'calc(100% - 4rem)' }}>
            <Toolbar {...{ cinemas, selectedCinema, setSelectedCinema, tabIndex, setTabIndex }} />
            {tabIndex === 0 && <>
                {Util.isNumberExist(selectedCinema) ?
                    <>
                        <DayPicker day={day} setDay={setDay} />
                        <Scheduler cinemaId={selectedCinema} day={day} />
                    </> :
                    <NoSelectedMessage />}
            </>}
            {tabIndex === 1 && <>
                <ShowtimeTimeline />
            </>}
        </div>
    )
}

export const ShowtimeScheduler = withLoadablePage(listener => Api.getShowtimeSchedulerEndpoints(listener), View);