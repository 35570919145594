import Theme from "../Theme";

class OrangeTheme extends Theme {

    getName() {
        return "orange";
    }

    getColor() {
        return "rgb(19,57,59)";
    }

    getClassName() {
        return "theme-orange"
    }

}

export default OrangeTheme;