import { ComboBox, ContentSwitcher, InlineLoading, Switch, TextInput } from 'carbon-components-react';
import { TableInput } from "../../../../../components/table-input"
import { FnbEventSellableListSelector } from "../../../../../components/list-selector"
import { getObjectTypeName } from "../../../../../constants/ObjectTypes"

const DEFAULTS = {
    chargeMode: "CUSTOM",
    itemId: 0,
    itemType: -1,
    name: ''
}

const COLUMNS = [
    {
        key: "chargeMode", tile: "Type", flex: 1, Component: ({ value, setMultiProp, useEntry }) => {
            const [_, setItem] = useEntry('item');
            const selectedIndex = value === "LIST" ? 1 : 0;

            const onModeChange = chargeMode => {
                setMultiProp({
                    chargeMode: chargeMode,
                    itemId: 0,
                    itemType: -1,
                    name: ''
                })
            }

            return (
                <div className='no-round-or-border-content-switcher' style={{ width: '100%' }}>
                    <ContentSwitcher selectedIndex={selectedIndex} size="md" onChange={e => onModeChange(e.name)}>
                        <Switch name="CUSTOM" text="Free Text" />
                        <Switch name="LIST" text="List" />
                    </ContentSwitcher>
                </div>
            )
        }
    },

    {
        key: "item", title: "Item", flex: 2, Component: ({ useEntry, setMultiProp }) => {
            const [chargeMode] = useEntry('chargeMode');

            const [name, setName] = useEntry('name')
            const [itemId, setItemId] = useEntry('itemId');
            const [itemType, setItemType] = useEntry('itemType');

            if (chargeMode === 'LIST') {
                const value = {
                    name, itemId, itemType
                }
                const setValue = value => {
                    setMultiProp({
                        name: value?.name ?? '',
                        itemId: value?.id ?? 0,
                        itemType: value?.type ?? -1,
                        rate: value?.amount ?? 0,
                    })
                }

                return <FnbEventSellableListSelector value={value} setValue={setValue} />
            } else {
                return <TextInput value={name} onChange={e => setName(e.target.value)} />
            }
        },
    },

    {
        key: "rate", title: "Rate (+ Tax)", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        },
    },

    {
        key: "qty", title: "Quantity", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        },
    }
]

export function SaleItemsTable({ value, setValue }) {
    return <TableInput columns={COLUMNS} value={value} setValue={setValue} defaultItemValue={DEFAULTS} />
}