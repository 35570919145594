import { ComboBox } from 'carbon-components-react'
import React from 'react'

const HOURS = (() => { let arr = []; for (let i = 0; i < 24; i++) { arr.push(i.toLocaleString('en-US', { minimumIntegerDigits: 2 })) } return arr; })()
const MINUTES = (() => { let arr = []; for (let i = 0; i < 60; i++) { arr.push(i.toLocaleString('en-US', { minimumIntegerDigits: 2 })) } return arr; })()

export default ({ labelText, value, onChange, light }) => {
    const valueData = (value ?? "").split(":");
    const hour = valueData[0]
    const minute = valueData[1]

    const onHourChanged = newHour => {
        onChange(`${newHour}:${minute ?? '00'}`)
    }

    const onMinuteChanged = newMinute => {
        onChange(`${hour ?? '00'}:${newMinute}`)
    }

    return (
        <div>
            {labelText && <label className="bx--label" style={{ marginBottom: 0, fontSize: 14 }}>{labelText}</label>}
            <div style={{ display: 'flex', gap: '0.15rem' }}>
                <ComboBox light={light} style={{ width: 150 }} size="lg" titleText="Hour" items={HOURS} selectedItem={hour} onChange={e => onHourChanged(e.selectedItem ? e.selectedItem : "00")} />
                <ComboBox light={light} style={{ width: 150 }} size="lg" titleText="Minute" items={MINUTES} selectedItem={minute} onChange={e => onMinuteChanged(e.selectedItem ? e.selectedItem : "00")} />
            </div>
        </div>
    )
}