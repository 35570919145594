
class Theme {

    getName() {
        throw new Error("Not implemented");
    }

    getColor() {
        throw new Error("Not implemented");
    }

    getClassName() {
        throw new Error("Not implemented");
    }

}

export default Theme;