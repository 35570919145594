import { ACCOUNT_TYPE_REST_DELIVERY_MANAGER, ACCOUNT_TYPE_TYPE_WAITER } from "../../../../constants/Constants";
import { REST_ORDER_TYPE } from "../../../../domain/rest-sales-order";
import Api from "../../../../session/Api";
import { getAccountRole, getTerminal } from "../../../../session/SessionManager";
import SocketSession, { EVENT_SALES_ORDER_UPDATE } from "../../../../session/SocketSession";
import UIUtil from "../../../../util/UIUtil";
import Util from "../../../../util/Util";
import {
    STATUS_FINALIZED,
    STATUS_PENDING, STATUS_PROCESSING, STATUS_REST_SERVING, STATUS_REST_PACKING, STATUS_REST_AWAITING_FINALIZE,
    STATUS_REST_PREPARING, STATUS_REVOKED
} from "../../../sales-order/base/sales-order";

export const DEF_ORDER_STATE = () => ({
    loadingOrderEngine: true,
    refreshKey: Util.newTempId(),
    orders: [],
    carts: [],
    classifications: [],
    skipTypes: [],
    orderTab: "current",

    isAutoKotPrint: new OrderState().loadState("isAutoKotPrint", false),
    filterClasses: new OrderState().loadState("filterClasses", []),
    deliveryLocations: [],

    orderCardStates: [],
    kotCardStates: [],
})


const ACTIVE_REST_ORDER_STATUSES = [
    STATUS_PENDING,
    STATUS_PROCESSING,
    STATUS_REST_PREPARING,
    STATUS_REST_SERVING,
    STATUS_REST_PACKING,
    STATUS_REST_AWAITING_FINALIZE
]

const SCHEDULE_THRESHOLD = 3_600_000 + 1_800_000; // one hour and 30 minutes

export function createScheduleFilter(orderTab) {
    const currentDate = new Date().getTime();
    return {
        predicate: (order) => {
            const orderDate = Util.isNumberExist(order.targetDeliveryDate) ? order.targetDeliveryDate : order.preferredDeliveryDate
            let datePredicate = true;
            switch (orderTab) {
                case "current":
                    datePredicate = (currentDate + SCHEDULE_THRESHOLD) >= orderDate;
                    break;
                case "future":
                    datePredicate = (currentDate + SCHEDULE_THRESHOLD) < orderDate;
                    break;
            }
            return datePredicate;
        }
    }
}

function kotHasContent(kot, filterClasses) {
    if (filterClasses?.length && kot?.itemGroups?.length) {
        const filterValues = filterClasses.map($ => $.label)
        const visible = kot.itemGroups.filter(group => filterValues.includes(group.name));
        return visible.length > 0
    } else {
        return kot?.itemGroups?.length > 0;
    }
}

export class OrderState {

    //notificationAudio = new Audio("notification.wav");
    newKotAudio = new Audio("notification-2.mp3")
    newOrderAudio = new Audio("notification.wav")
    newCartNotiAudio = new Audio("positive.wav");

    constructor(setState, getState, mainApp) {
        this.setState = setState;
        this.getState = getState;
        this.mainApp = mainApp;

        const role = getAccountRole();
        this.isWaiter = role === ACCOUNT_TYPE_TYPE_WAITER;
        this.isDeliveryManager = role === ACCOUNT_TYPE_REST_DELIVERY_MANAGER;
    }

    useOrderCardState(order, isKot, defState = {}) {
        const key = isKot ? "kotCardStates" : "orderCardStates";
        const state = this.getState()[key].filter($ => $.id === order.id).map($ => $.state)[0] ?? defState;
        const setState = update => {
            this.setState(prev => {
                const prevState = prev[key].filter($ => $.id === order.id).map($ => $.state)[0] ?? defState;
                const nextState = (() => {
                    if (typeof update === "function") {
                        return update(prevState)
                    } else {
                        return update;
                    }
                })()
                return {
                    [key]: [
                        ...prev[key].filter($ => $.id !== order.id),
                        { id: order.id, state: nextState }
                    ]
                }
            })
        }
        const useState = (field) => {
            const value = state[field];
            const setValue = arg => {
                if (typeof arg === "function") {
                    setState(prev => ({ ...prev, [field]: arg(prev[field]) }))
                } else {
                    setState(prev => ({ ...prev, [field]: arg }))
                }
            }
            return [value, setValue]
        }
        return { useState };
    }

    start() {
        SocketSession.registerListener(EVENT_SALES_ORDER_UPDATE, this.onOrderUpdateEvent)
        SocketSession.subscribe(EVENT_SALES_ORDER_UPDATE, subscription => {
            this.orderUpdateSubscription = subscription;
            this.loadOrders();
        })
        this.tickInterval = setInterval(this.onTick.bind(this), 1000 * 60)
    }

    stop() {
        SocketSession.unregisterListener(EVENT_SALES_ORDER_UPDATE, this.onOrderUpdateEvent);
        this.orderUpdateSubscription?.unsubscribe?.();
        if (this.tickInterval) {
            clearInterval(this.tickInterval)
        }
    }

    onTick() {
        // this.setState({ refreshKey: Util.newTempId() })
        this.setState(prev => ({ orders: prev.orders ? [...prev.orders] : [] }))
    }

    onOrderUpdateEvent = (order) => {
        if (order.sourceId !== this.storeId) {
            return;
        }

        const orderInList = (list) => list?.find($ => $.id === order.id) !== undefined;
        const kotCount = order => order?.kots?.length ?? -1;
        const isShouldPrintAllKot = prevOrder => {
            if (!prevOrder) {
                return false;
            }

            const isPackingOrServing = prevOrder.status === STATUS_REST_SERVING || prevOrder.status === STATUS_REST_PACKING
            return isPackingOrServing && kotCount(order) === kotCount(prevOrder)
        };

        const prevOrders = this.getAllOrders();
        const prevKotOrders = this.getAllOrdersForKitchen();


        const shouldPrintAllKot = isShouldPrintAllKot(prevOrders.find(prev => prev.id === order.id))
        const hasNewKot = kotCount(order) > kotCount(prevKotOrders.find(prev => prev.id === order.id));

        this.setState(prev => ({
            orders: (
                ACTIVE_REST_ORDER_STATUSES.includes(order?.status) ? (
                    [order, ...prev.orders.filter($ => $.id !== order.id)].sort((a, b) => a.lastStatusUpdateDate - b.lastStatusUpdateDate).reverse()
                ) : (
                    [...prev.orders.filter($ => $.id !== order.id)].sort((a, b) => a.lastStatusUpdateDate - b.lastStatusUpdateDate).reverse()
                )
            )
        }), () => {
            const newOrders = this.getAllOrders();
            const newKotOrders = this.getAllOrdersForKitchen();

            if (orderInList(newKotOrders) && hasNewKot) {
                //this.playSound();
                this.playNewKOTSound()
                if (shouldPrintAllKot) {
                    this.printAllKot(order)
                } else {
                    this.printLastKot(order)
                }
            }

            if (order?.type == REST_ORDER_TYPE.DELIVERY || order?.type == REST_ORDER_TYPE.PICK_UP) {
                if (!orderInList(prevOrders) && orderInList(newOrders)) {
                    // this.playSound()
                    this.playNewOrderSound()
                }
            }
        })
    }

    get storeId() {
        return getTerminal() ? getTerminal().storeId : -1
    }

    loadOrders() {
        Api.getRestOrders(this.storeId, response => {
            if (response.status === true) {
                this.setState({
                    orders: response.payload.orders,
                    carts: response.payload.carts,
                    classifications: response.payload.classifications,
                    deliveryLocations: response.payload.deliveryLocations,
                    loadingOrderEngine: false,
                })
            } else {
                UIUtil.showError("Failed to initialize Sales Order Engine.")
            }
        })
    }

    reloadCarts() {
        if (this.reloadingCarts) {
            return;
        }
        this.reloadingCarts = true;
        Api.getActiveCarts(this.storeId, response => {
            this.reloadingCarts = false;
            if (response.status === true) {
                const prevCarts = this.carts;
                this.carts = response.payload;
                const newCarts = this.carts;


                for (const cart of newCarts) {
                    const prevCart = prevCarts.find($ => $.cartId === cart.cartId);
                    if (!prevCart?.waiterCalled && cart.waiterCalled) {
                        this.playCartNotiSound();
                    }

                    if (!prevCart?.orderSubmitted && cart.orderSubmitted) {
                        this.playCartNotiSound();
                    }

                    if (!prevCart?.modifyOrderSubmitted && cart.modifyOrderSubmitted) {
                        this.playCartNotiSound();
                    }
                }
            }
        })
    }

    getAllOrders() {
        if (this.isWaiter && this.getState().orders) {
            return this.getState().orders.filter($ => $.type === REST_ORDER_TYPE.DINE_IN)
        } else {
            return this.getState().orders ?? [];
        }
    }

    getAllOrdersForKitchen() {
        return this.getAllOrders().filter($ => $.status === STATUS_REST_PREPARING);
    }

    getCurrentOrderCount() {
        const scheduleFilter = createScheduleFilter("current");
        return this.getAllOrders().filter(scheduleFilter.predicate).length;
    }

    getFutureOrderCount() {
        const scheduleFilter = createScheduleFilter("future");
        return this.getAllOrders().filter(scheduleFilter.predicate).length;
    }

    getCurrentOrderCountForKitchen() {
        const scheduleFilter = createScheduleFilter("current");
        return this.getAllOrdersForKitchen().filter(scheduleFilter.predicate).length;
    }

    getFutureOrderCountForKitchen() {
        const scheduleFilter = createScheduleFilter("future");
        return this.getAllOrdersForKitchen().filter(scheduleFilter.predicate).length;
    }

    getFilteredOrders() {
        const orders = this.getAllOrders()
        const skippedTypes = this.getState().skipTypes;

        const scheduleFilter = createScheduleFilter(this.orderTab);

        return orders.filter($ => {
            const typePredicate = !skippedTypes.includes($.type);
            const datePredicate = scheduleFilter.predicate($);
            return typePredicate && datePredicate
        })
    }

    getFilteredOrdersForKitchen() {
        return this.getFilteredOrders().filter($ => $.status === STATUS_REST_PREPARING);
    }

    getFilteredOrdersForDeliveryManager() {
        return this.getFilteredOrders().filter($ => $.type !== REST_ORDER_TYPE.DINE_IN);
    }

    getFilteredOrdersForDeliveryManagerKOT() {
        return this.getFilteredOrders().filter($ => $.status === STATUS_REST_PREPARING && $.type !== REST_ORDER_TYPE.DINE_IN);
    }

    onNewOrder(order) {
        this.setState(prev => ({
            orders: [order, ...prev.orders]
        }))
    }

    updateOrder(order) {
        this.setState(prev => ({
            orders: prev.orders.map($ => $.id === order.id ? order : $)
        }))
    }

    removeOrder(order) {
        this.setState(prev => ({
            orders: prev.orders.filter($ => $.id !== order.id)
        }))
    }

    isLoading() {
        return this.getState().loadingOrderEngine;
    }

    isTypeSkipped(type) {
        return this.getState().skipTypes.includes(type)
    }

    toggleTypeSkipped(type) {
        this.setState(prev => {
            if (prev.skipTypes.includes(type)) {
                return { skipTypes: prev.skipTypes.filter($ => $ !== type) }
            } else {
                return { skipTypes: [...prev.skipTypes, type] }
            }
        })
    }

    get carts() {
        return this.getState().carts ?? []
    }
    set carts(carts) {
        this.setState({ carts })
    }

    get refreshKey() {
        return this.getState().refreshKey;
    }

    get orderTab() {
        return this.getState().orderTab;
    }

    set orderTab(orderTab) {
        this.setState({ orderTab })
    }

    get deliveryLocations() {
        return this.getState().deliveryLocations;
    }

    get classifications() {
        return this.getState().classifications;
    }

    set classifications(classifications) {
        this.setState({ classifications })
    }

    get filterClasses() {
        return this.getState().filterClasses;
    }

    set filterClasses(filterClasses) {
        this.setState({ filterClasses })
        this.saveState("filterClasses", filterClasses)
    }

    get isAutoKotPrint() {
        return this.getState().isAutoKotPrint;
    }

    set isAutoKotPrint(isAutoKotPrint) {
        this.setState({ isAutoKotPrint });
        this.saveState("isAutoKotPrint", isAutoKotPrint);
    }

    loadState(key, def) {
        const load = () => {
            try {
                return JSON.parse(window.localStorage.getItem("robo-rest-erp-order-" + key))
            } catch (e) { }
            return undefined
        }

        return load() ?? def
    }

    saveState(key, value) {
        try {
            window.localStorage.setItem("robo-rest-erp-order-" + key, JSON.stringify(value))
        } catch (e) { }
    }

    playSound() {
        try {
            this.notificationAudio.pause();
            this.notificationAudio.currentTime = 0;
            this.notificationAudio.play().catch(() => { })
        } catch (e) { }
    }

    playNewOrderSound() {
        try {
            this.newOrderAudio.pause();
            this.newOrderAudio.currentTime = 0;
            this.newOrderAudio.play().catch(() => { })
        } catch (e) { }
    }

    playNewKOTSound() {
        try {
            this.newKotAudio.pause();
            this.newKotAudio.currentTime = 0;
            this.newKotAudio.play().catch(() => { })
        } catch (e) { }
    }

    playCartNotiSound() {
        try {
            this.newCartNotiAudio.pause();
            this.newCartNotiAudio.currentTime = 0;
            this.newCartNotiAudio.play().catch(() => { })
        } catch (e) { }
    }

    printLastKot(order) {
        // try {
        //     if (this.isAutoKotPrint && this.mainApp.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
        //         const lastKot = order.kots[order.kots.length - 1]
        //         if (kotHasContent(lastKot, this.filterClasses)) {
        //             this.mainApp.thermalPrinter.driver.printKot(order, lastKot, this.filterClasses, "Auto Print")
        //         }
        //     }
        // } catch (e) { }
    }

    printAllKot(order) {
        // try {
        //     if (this.isAutoKotPrint && this.mainApp.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
        //         this.mainApp.thermalPrinter.driver.printAllKots(order, this.filterClasses, "Back to KOT")
        //     }
        // } catch (e) { }
    }

}