import { useParams } from "react-router-dom"
import Button from "../components/Button";
import HTMLEditor from "./HTMLEditor"
import { Close16, Save16, Terminal32 } from '@carbon/icons-react'
import { getObjectTypeName, OBJECT_TYPE_BLOG_POST } from "../constants/ObjectTypes";
import { Loading } from "carbon-components-react";
import useApi from "../hooks/useApi";
import Api, { createApiWithLang } from "../session/Api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UIUtil from "../util/UIUtil";
import Util from "../util/Util";

const useReq = () => {
    const { targetId, targetType, lang } = useParams();
    return [parseInt(targetId), parseInt(targetType), lang]
}

export default () => {
    const editorRef = useRef();

    const [targetId, targetType, lang] = useReq();
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false);

    const [initialContentData, setInitialContentData] = useState();

    const api = useMemo(() => (!Util.isStringExists(lang) || lang === "EN") ? Api : createApiWithLang(lang), [lang])

    useEffect(() => {
        setLoading(true)
        api.getHtmlEditorContent(targetType, targetId, response => {
            if (response.status === true) {
                setInitialContentData(JSON.parse(response.payload.data));
                setLoading(false);
            } else {
                alert(response.message);
                window.close()
            }
        })
    }, [])

    const saveContentData = () => {
        const { data, html } = editorRef.current.export();

        setSaving(true)
        api.saveHtmlEditorContent(targetType, targetId, {
            data: JSON.stringify(data), html
        }, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setSaving(false)
        })
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ paddingLeft: '1rem', paddingRight: '0.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <Terminal32 />
                {/* <div style={{ flex: 1 }}> */}
                <h2>Editor</h2>
                <label style={{ alignSelf: 'flex-end' }} className="bx--label">{getObjectTypeName(targetType)} Content</label>
                {/* </div> */}
                <div style={{ flex: 1 }} />
                {!loading && <>
                    <Button disabled={saving} kind="danger--ghost" onClick={() => window.close()} renderIcon={Close16}>Close</Button>
                    <Button onClick={saveContentData} loading={saving} renderIcon={Save16}>Save</Button>
                </>}
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
                {loading ? (
                    <Loading />
                ) : (
                    <HTMLEditor ref={editorRef} contentData={initialContentData} newsletterMode={targetType == OBJECT_TYPE_BLOG_POST} />
                )}
            </div>
        </div>
    )
}