import { Button, Checkbox, ComboBox, ContentSwitcher, NumberInput, Slider, Switch, Tab, Tabs, TextArea, TextInput } from 'carbon-components-react'
import React from 'react'
import DimensionsField from './base/DimensionsField'
import InspectorContentHeader from './base/InspectorContentHeader'

import useCanvasComponentInspectorField from '../../hooks/useCanvasComponentInspectorField'
import ColorField from './base/ColorField'
import useNumberValueSetter from '../../hooks/useNumberValueSetter'
import useBooleanToggler from '../../hooks/useBooleanToggler'

import {
    TextBold16,
    TextItalic16,
    TextUnderline16,
    TextAlignLeft16,
    TextAlignCenter16,
    TextAlignRight16,
    TextAlignJustify16
} from '@carbon/icons-react'
import { getFontFamilies } from '../../util/FontUtil'

export default ({ engine, componentId }) => {
    const { changeListener, getter, setter, multiSetter } = useCanvasComponentInspectorField(engine, componentId, ['backgroundColor', 'fill', 'stroke', 'strokeWidth', 'fontSize', 'fontWeight', 'fontFamily', 'fontStyle', 'underline', 'textAlign', 'text', 'valueDynamic', 'dynamicValueContent']);
    const [isTransparent, toggleTransparent] = useBooleanToggler('backgroundColor', 'transparent', '#cecece', getter, setter);
    const [isBold, toggleBold] = useBooleanToggler('fontWeight', 'bold', 'normal', getter, setter);
    const [isItalic, toggleItalic] = useBooleanToggler('fontStyle', 'italic', 'normal', getter, setter);
    const [isUnderline, toggleUnderline] = useBooleanToggler('underline', true, false, getter, setter);
    const [isValueDynamic, toggleValueDynamic] = useBooleanToggler('valueDynamic', true, false, getter, setter);

    const numberValueSetter = useNumberValueSetter();

    return (
        <div>
            <InspectorContentHeader text="Content" />
            <div className="short-width-tabs">
                <Tabs selected={isValueDynamic ? 1 : 0} onSelectionChange={index => toggleValueDynamic()}>
                    <Tab id="static" label="Static">
                        <TextArea {...changeListener} labelText="Text" value={getter('text')} onChange={e => setter('text', e.target.value)} />
                    </Tab>
                    <Tab id="dynamic" label="Dynamic">
                        {engine.getTemplate().supportDynamicText ? (
                            <ComboBox titleText={engine.getTemplate().itemName}
                                items={engine.getTemplate().dynamicTextValues} itemToString={item => item.name}
                                selectedItem={getter('dynamicValueContent') ? getter('dynamicValueContent') : engine.getTemplate().dynamicTextValues[0]}
                                // selectedItem={!getter('dynamicValueContent') ? engine.getTemplate().dynamicTextValues[0] : engine.getTemplate().dynamicTextValues.filter(item => getter('dynamicValueContent').field == item.field)[0]}
                                onChange={e => setter('dynamicValueContent', e.selectedItem ? e.selectedItem : engine.getTemplate().dynamicTextValues[0], true)}
                            />
                        ) : (
                            <p style={{ fontSize: 14, opacity: 0.65, color: 'red' }}>Dynamic text is not supported in template type '{engine.getTemplate().name}'</p>
                        )}
                    </Tab>
                </Tabs>
            </div>

            <InspectorContentHeader text="Appearance" hasTopMargin />
            <ColorField title="Color" color={getter('fill')} onChange={color => setter('fill', color, true)} />
            <div style={{ height: '1rem' }} />
            <ComboBox titleText="Font Family" items={getFontFamilies()} itemToString={item => item.name}
                selectedItem={getFontFamilies().filter(fontFamily => getter('fontFamily') == fontFamily.value)[0]}
                itemToElement={(item) =>
                    item ? (
                        <span style={{ fontFamily: item.value }}>
                            {item.name}
                        </span>
                    ) : (
                        ''
                    )
                }
                onChange={e => setter('fontFamily', e.selectedItem ? e.selectedItem.value : 'sans-serif', true)} />

            <div style={{ height: '1rem' }} />
            <div style={{ display: 'flex' }}>
                <NumberInput {...changeListener} min={0} max={300} hideSteppers invalidText="Invalid Size" label="Font Size" value={getter('fontSize')} onChange={e => numberValueSetter(e.target.value, v => setter('fontSize', v))} />
            </div>
            <div style={{ height: '1rem' }} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                <Button hasIconOnly iconDescription="Align left" renderIcon={TextAlignLeft16} kind={getter('textAlign') == "left" ? "primary" : "ghost"} onClick={() => setter('textAlign', "left", true)} />
                <Button hasIconOnly iconDescription="Center text" renderIcon={TextAlignCenter16} kind={getter('textAlign') == "center" ? "primary" : "ghost"} onClick={() => setter('textAlign', "center", true)} />
                <Button hasIconOnly iconDescription="Align right" renderIcon={TextAlignRight16} kind={getter('textAlign') == "right" ? "primary" : "ghost"} onClick={() => setter('textAlign', "right", true)} />
                <Button hasIconOnly iconDescription="Justify" renderIcon={TextAlignJustify16} kind={getter('textAlign') == "justify" ? "primary" : "ghost"} onClick={() => setter('textAlign', "justify", true)} />
            </div>
            <div style={{ width: '100%', marginTop: '0.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                <Button hasIconOnly iconDescription="Bold" renderIcon={TextBold16} kind={isBold ? "primary" : "ghost"} onClick={() => toggleBold()} />
                <Button hasIconOnly iconDescription="Italic" renderIcon={TextItalic16} kind={isItalic ? "primary" : "ghost"} onClick={() => toggleItalic()} />
                <Button hasIconOnly iconDescription="Underline" renderIcon={TextUnderline16} kind={isUnderline ? "primary" : "ghost"} onClick={() => toggleUnderline()} />
            </div>

            <InspectorContentHeader text="Stroke" hasTopMargin />
            <Slider onRelease={changeListener.onBlur} labelText="Stroke Thickness" min={0} max={10} hideTextInput value={getter('strokeWidth')} onChange={({ value }) => setter('strokeWidth', value)} />
            <div style={{ height: '1rem' }} />
            <ColorField title="Stroke Color" color={getter('stroke')} onChange={color => setter('stroke', color, true)} />

            <InspectorContentHeader text="Background" hasTopMargin />
            <ColorField disabled={isTransparent} title="Color" color={getter('backgroundColor')} onChange={color => setter('backgroundColor', color, true)} />
            <div onClick={() => toggleTransparent()}>
                <Checkbox labelText="Transparent" checked={isTransparent} />
            </div>
        </div>
    )
}