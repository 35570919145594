import React, { useEffect, useState } from 'react';

import ProductField from './ProductField';
import MultipleInputEditor from '../../components/MultipleInputEditor';

const Record = ({input, nonExistent, onInputUpdate, onInputRemoved, templateEditorMode, productFinderShowFakeTextFieldAtFirst}) => {
    const [itemId, setItemId] = useState(input.itemId);
    const [itemType, setItemType] = useState(input.itemType);

    return (
        <div style={{display: 'flex', width: '100%'}}>
            <div style={{flex: 1}}>
                <ProductField productId={itemType > 0 ? itemId : 0} productType={itemId > 0 ? itemType : 0} 
                defaultProductValues={input}
                productFinderShowFakeTextFieldAtFirst={productFinderShowFakeTextFieldAtFirst}
                onInputRemoved={onInputRemoved} 
                templateEditorMode={templateEditorMode}
                onValueChanged={(key, value) => {
                    onInputUpdate(key, value);
                }}
                onProductChange={({ type, id }) => {
                    setItemId(id);
                    setItemType(type);
                    onInputUpdate('itemId', id);
                    onInputUpdate('itemType', type);
                }} />
            </div>


            {/* <div style={{width: 5}} />
            
            <div style={{width: 5}} /> */}

            {/* <Button kind="danger" hasIconOnly renderIcon={RowDelete32} size="md" 
            iconDescription="Remove"
            style={{height: 40, alignSelf: 'flex-end'}} disabled={nonExistent} onClick={onInputRemoved} /> */}
        </div>
    )
}

export default function ProductSelector(props) {
    return <MultipleInputEditor defaultInputs={props.defaultItems} onInputsUpdated={props.onItemsUpdated} 
    hideDrag templateEditorMode={props.templateEditorMode} productFinderShowFakeTextFieldAtFirst={props.productFinderShowFakeTextFieldAtFirst}
    ItemComponent={Record}

     />
}