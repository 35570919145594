import { Button } from 'carbon-components-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STORE } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';
import { AirlinePassengerCare16, Events16, Collaborate16, WatsonHealth3DPrintMesh16, Enterprise16, Person16 } from '@carbon/icons-react'
import { CUSTOMER_STATUS_CUSTOMER, CUSTOMER_STATUS_LEAD, CUSTOMER_STATUS_PROSPECT, CUSTOMER_TYPE_COMPANY, CUSTOMER_TYPE_INDIVIDUAL } from '../../constants/Constants';
import LocationPicker from '../../components/LocationPicker';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { CustomerSearchDialog } from '../pos/dialogs/customer-search-dialog';

const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)


class CustomerListPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            selectedStatus: -1,
            selectedType: -1,

            showSearchDialog: false,
        }
    }

    hasFilter = () => this.state.selectedStatus != -1 || this.state.selectedType != -1;

    filter = (item) => {
        const statusCondition = this.state.selectedStatus == -1 || item.status == this.state.selectedStatus;
        const typeCondition = this.state.selectedType == -1 || item.type == this.state.selectedType;
        return statusCondition && typeCondition;
    }

    renderFilterTab() {
        return (
            <>
                <div>
                    <label className="bx--label" style={{ paddingLeft: '1rem' }}>Type</label>
                    <div style={{ display: 'flex' }}>
                        <TabItem key={this.state.selectedType + '-all-tab'} icon={Events16} title="All" selected={this.state.selectedType == -1} onClick={() => this.setState({ selectedType: -1 })} />
                        <TabItem key={this.state.selectedType + '-individual-tab'} icon={Person16} title="Individual" selected={this.state.selectedType == CUSTOMER_TYPE_INDIVIDUAL} onClick={() => this.setState({ selectedType: CUSTOMER_TYPE_INDIVIDUAL })} />
                        <TabItem key={this.state.selectedType + '-company-tab'} icon={Enterprise16} title="Company" selected={this.state.selectedType == CUSTOMER_TYPE_COMPANY} onClick={() => this.setState({ selectedType: CUSTOMER_TYPE_COMPANY })} />

                    </div>
                </div>

                {/* <div style={{ paddingInline: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: 6, height: 6, borderRadius: '50%', background: 'black', opacity: 0.65 }} />
                </div>

                <div>
                    <label className="bx--label" style={{ paddingLeft: '1rem' }}>Status</label>
                    <div style={{ display: 'flex' }}>
                        <TabItem key={this.state.selectedStatus + '-all-tab'} icon={Events16} title="All" selected={this.state.selectedStatus == -1} onClick={() => this.setState({ selectedStatus: -1 })} />
                        <TabItem key={this.state.selectedStatus + '-leads-tab'} icon={WatsonHealth3DPrintMesh16} title="Leads" selected={this.state.selectedStatus == CUSTOMER_STATUS_LEAD} onClick={() => this.setState({ selectedStatus: CUSTOMER_STATUS_LEAD })} />
                        <TabItem key={this.state.selectedStatus + '-prospects-tab'} icon={Collaborate16} title="Prospects" selected={this.state.selectedStatus == CUSTOMER_STATUS_PROSPECT} onClick={() => this.setState({ selectedStatus: CUSTOMER_STATUS_PROSPECT })} />
                        <TabItem key={this.state.selectedStatus + '-customer-tab'} icon={AirlinePassengerCare16} title="Customers" selected={this.state.selectedStatus == CUSTOMER_STATUS_CUSTOMER} onClick={() => this.setState({ selectedStatus: CUSTOMER_STATUS_CUSTOMER })} />
                    </div>
                </div> */}

                <div style={{ flex: 1 }} />
            </>
        )
    }

    getLayout() {
        return (
            <div className="main-content tooltip-supported-toolbar height-change-supported-toolbar">
                {TableTemplate.renderTemplate({
                    title: "Customers",
                    subTitle: "All customers",
                    objectType: OBJECT_TYPE_CUSTOMER,
                    pagePath: "/customers/",
                    history: this.props.history,
                    // customToolbarContent: hasCapabilitySupport("customerEnhancement") && this.renderFilterTab(),
                    // filter: this.hasFilter() ? this.filter : undefined,
                    //paginated: hasCapabilitySupport("restaurant"),
                    paginated: true,
                    onSearchBtn: () => this.setState({ showSearchDialog: true })
                })}

                <CustomerSearchDialog onClose={() => this.setState({ showSearchDialog: false })} visible={this.state.showSearchDialog} onCustomerSelected={customer => this.props.history.push('/customers/' + customer.id)} />
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(CustomerListPage);