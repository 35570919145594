import { useCallback } from "react";
import Api from "../../../session/Api";
import { FormDialog } from "../../../templates/form/form-dialog";
import { BillItemsTable } from "./bill-items-table";

function FormView(form, payload) {
    const Items = useCallback(({ billables, predefinedClasses, locations, editable, value, setValue }) => (
        <BillItemsTable {...{ billables, predefinedClasses, locations }} editable={editable} items={value} setItems={setValue} />
    ), [])

    const { TextField, TextAreaField, SwitchField, ComboBoxField, CustomField, ExtField } = form;
    return (<div>
        <TextField fieldKey="billFromName" title="Name" />
        {/* {JSON.stringify(form.getFormData().items)} */}
        <CustomField fieldKey="items" childrenProps={{ billables: payload.billables, locations: payload.locations, predefinedClasses: payload.predefinedClasses, editable: true, }}>
            {Items}
        </CustomField>
    </div>)
}

export function EditBillDialog({ open, item, setItem, onClose, payload }) {
    return (
        <FormDialog open={open} onClose={onClose} label="Updating" title="Lead Flow Info"
            item={item} setItem={setItem} payload={payload} preventCloseOnClickOutside
            saveApi={(data, listener) => Api.listDetailSaveData("V2BillList", data, listener)}>
            {FormView}
        </FormDialog>
    )
}