import './lead-flow.scss'
import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import { Card } from "./components/card"
import { ContentGrid } from "./components/content-grid"
import { Toolbar } from "./components/toolbar"
import { LeadInfo } from "./widgets/lead-info"
import { Status } from './widgets/status'
import { FlowInfo } from './widgets/flow-info'
import { ProspectProperties } from './widgets/prospect-properties'
import { NoteDocs } from './widgets/note-docs'
import { ActivityReport } from './widgets/activity-report'
import { Comments } from './widgets/comments'
import { useMemo, useState } from 'react'
import { Overview } from './tabs/overview'
import { makeObservable } from '../../../util/makeObservable'
import { Calendar } from './tabs/calendar'
import { ToDoBoard } from './tabs/to-do-board'


const View = ({ payload: { lead, ...data } }) => {
    const [tabIndex, setTabIndex] = useState(0);

    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("customerId", lead.customerId)
        observable.set("profile", lead.leadProfile)
        observable.set("flowInfo", {
            feeling: lead.feeling,
            crnId: lead.crnId,
            sourceId: lead.sourceId,
            assignations: lead.assignations,
            tagAssignations: lead.tagAssignations,

            sourceName: lead.sourceName
        })
        observable.set("statusReport", {
            status: lead.status,
            lastStatusUpdate: lead.lastStatusUpdate,
            lastStatusUpdateUserId: lead.lastStatusUpdateUserId,
            lastStatusUpdateUserFullName: lead.lastStatusUpdateUserFullName,
            daysSinceLastUpdate: lead.daysSinceLastUpdate,
            createdStudentFullName: lead.createdStudentFullName,
            createdStudentId: lead.createdStudentId,

            createdPropertyDealStatus: lead.createdPropertyDealStatus,
            createdPropertyDealId: lead.createdPropertyDealId
        })
        return observable;
    }, [])

    const leadId = lead.id;

    return (
        <div style={{ height: '100%' }}>
            <Toolbar lead={lead} store={store} tabIndex={tabIndex} setTabIndex={setTabIndex} />
            {tabIndex === 0 ? (
                <Overview leadId={leadId} lead={lead} store={store} data={data} />
            ) : tabIndex === 1 ? (
                <Calendar leadId={leadId} />
            ) : tabIndex === 2 ? (
                <ToDoBoard leadId={leadId} />
            ) : null}
        </div>
    )
}

export const LeadFlowDetail = withLoadablePageWithParams(params => listener => Api.getLeadFlow(params.itemId, listener), View)