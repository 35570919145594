import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { Delivery32, Delivery24 } from '@carbon/icons-react'
import { TextBox } from "../../components/basic-filter/text-box"
import { FormSection } from "../../../templates/form/form"

export const VendorList = newListDetail({
    title: "Vendor",
    icon: Delivery32,
    mdIcon: Delivery24,

    filter: Filter, newForm: Form, updateForm: Form,

    defaultValues: {

    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Form({ endpoint, form }) {
    return (
        <div>
            <form.TextField fieldKey="name" title="Name" />
            <FormSection title={"Detail"}>
                <form.CheckBoxField fieldKey="active" title="Active" />
                <form.CheckBoxField fieldKey="mainTaxBillVendor" title="Default VAT Bill Vendor" />
                <form.TextField fieldKey="currency" title="Currency" />

                <form.TextField fieldKey="address" title="Address" />
                <form.TextField fieldKey="trn" title="Tax Registration Number" />
                <form.TextField fieldKey="mainPhone" title="Main Phone" />
                <form.TextField fieldKey="fax" title="Fax" />
                <form.TextField fieldKey="contactPersonFinance" title="Contact Person (Finance)" />
                <form.TextField fieldKey="otherContactPerson" title="Other Contact Person" />
                <form.TextField fieldKey="mainEmail" title="Main Email" />
                <form.TextField fieldKey="taxCode" title="Tax Code" />
                <form.TextField fieldKey="taxCountry" title="Tax Country" />
                <form.TextField fieldKey="accountNo" title="Account Number" />
                <form.TextField fieldKey="creditLimit" title="Credit Limit" />
                <form.TextField fieldKey="jobStatus" title="Job Status" />
                <form.TextField fieldKey="jobDescription" title="Job Description" />
                <form.DateField fieldKey="startDate" title="Start Date" />
                <form.DateField fieldKey="projectedEnd" title="Projected End Date" />
                <form.DateField fieldKey="endDate" title="End Date" />
                <form.TextField fieldKey="jobTitle" title="Job Title" />
                <form.TextField fieldKey="typeOfOrg" title="Type of Organization" />
                <form.TextField fieldKey="paymentTerms" title="Payment Terms" />
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <TextBox state={state} label="Name" property="name" fullWidth />
    </>)
}
