

import { Product24, Product16, Category16, Tag16, RotateClockwiseAltFilled16, Search16, Exit16 } from '@carbon/icons-react'
import { ComboBox, InlineLoading, Search } from 'carbon-components-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Button from '../../../components/Button'
import ProfilePic from '../../../components/ProfilePic'
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from '../../../constants/ObjectTypes'
import useStore from '../../../hooks/useStore'
import Api from '../../../session/Api'
import { makeObservable } from '../../../util/makeObservable'
import useSize from '../../../util/useSize'
import Util from '../../../util/Util'
import Header from '../third-party-pos/components/Header'


const SelectCategory = ({ store }) => {
    const [selectedCategoryId, setSelectedCategoryId] = useStore(store, "selectedCategoryId")

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [categories, setCategories] = useState([]);

    const loadCategories = () => {
        setLoading(true);
        Api.getAllCategories(response => {
            setLoading(false);
            setError(response.status !== true);
            if (response.status === true) {
                setCategories(response.payload)
            }
        })
    }

    useEffect(() => {
        loadCategories();
        return () => setSelectedCategoryId(0)
    }, [])

    if (error) {
        return (
            <p style={{ color: 'red' }}>An error occurred</p>
        )
    }

    return (
        <ComboBox
            light
            disabled={loading}
            size="lg"
            style={{ width: '100%' }}
            placeholder="Select Category"
            items={categories}
            selectedItem={categories.find(category => category.id === selectedCategoryId) ?? null}
            itemToString={category => category ? category.value : ""}
            onChange={e => setSelectedCategoryId(e.selectedItem ? e.selectedItem.id : 0)}
        />
    )
}

const SearchField = ({ store }) => {
    const [searchValue, setSearchValue] = useStore(store, "searchValue")
    const ref = useRef()

    useEffect(() => {
        ref?.current?.input?.focus()
        return () => setSearchValue("")
    }, [])

    return (
        <Search
            ref={ref}
            // itemRef={ref}
            light
            size="lg"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder="Search"
            style={{ height: 48, flex: 1 }}
        />
    )
}

const Top = ({ customTitle, store }) => {
    const [resetRequest, setRequest] = useStore(store, 'resetRequest')

    const [inSearch, setInSearch] = useState(false);
    const [inCategory, setInCategory] = useState(false);

    const inFilter = inSearch || inCategory;
    const clearFilter = () => {
        if (inSearch) {
            setInSearch(false)
        }
        if (inCategory) {
            setInCategory(false)
        }
    }

    return (<>
        <Header icon={Product24} title={customTitle ?? "Products"}>
            {inFilter ? (
                <Button key="exit-filter-btn" className="t-pos-btn t-pos-btn-icon" hasIconOnly kind={"primary"} tooltipPosition="bottom" tooltipAlignment="center"
                    iconDescription={"Exit Filter"} size="sm" renderIcon={Exit16} onClick={clearFilter} />
            ) : (<>
                <Button key="select-category-btn" className="t-pos-btn t-pos-btn-icon" hasIconOnly kind={"tertiary"} tooltipPosition="bottom" tooltipAlignment="center"
                    iconDescription={"Select Category"} size="sm" renderIcon={Category16} onClick={() => setInCategory(prev => !prev)} />

                <Button key="search-btn" className="t-pos-btn t-pos-btn-icon" hasIconOnly kind={"tertiary"} tooltipPosition="bottom" tooltipAlignment="center"
                    iconDescription={"Search"} size="sm" renderIcon={Search16} onClick={() => setInSearch(prev => !prev)} />

                <Button key="reload-btn" className="t-pos-btn t-pos-btn-icon" hasIconOnly tooltipPosition="bottom" tooltipAlignment="center"
                    iconDescription="Reload" size="sm" renderIcon={RotateClockwiseAltFilled16} onClick={() => setRequest(Util.newTempId())} />
            </>)}
        </Header>
        <div style={{ marginTop: '0.5rem', }}>
            <div style={{ width: 'calc(100% + 1rem)', marginLeft: '-0.5rem', transition: '150ms', opacity: inSearch ? 1 : 0, height: inSearch ? 60 : 0, background: '#0f62fe', overflow: inSearch ? 'visible' : 'hidden' }}>
                <div className='full-size-close-btn' style={{ width: '100%', minHeight: 60, display: 'flex', paddingInline: (60 - 48) / 2, alignItems: 'center', gap: 5 }}>
                    {inSearch && <SearchField store={store} />}
                </div>
            </div>

            <div style={{ width: 'calc(100% + 1rem)', marginLeft: '-0.5rem', transition: '150ms', opacity: inCategory ? 1 : 0, height: inCategory ? 60 : 0, background: '#0f62fe', overflow: inCategory ? "visible" : 'hidden' }}>
                <div style={{ width: '100%', minHeight: 60, display: 'flex', paddingInline: (60 - 48) / 2, alignItems: 'center', gap: 5 }}>
                    <div style={{ flex: 1, width: '100%' }}>
                        {inCategory && <SelectCategory store={store} />}
                    </div>
                </div>
            </div>
        </div>


    </>)
}


const ProductItem = ({ id, name, allowNoStockClick, hideStock, amount, lowStock, stock, noStock, bundle, onClick }) => (
    <div onClick={(noStock && !allowNoStockClick) ? (() => { }) : onClick}
        // onClick={onClick}
        style={{
            position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: (noStock && !allowNoStockClick) ? undefined : 'pointer'
        }}>
        <ProfilePic style={{ opacity: (noStock && !allowNoStockClick) ? 0.65 : 1, }} size={90} notProfile src={Api.getThumbnail(bundle ? OBJECT_TYPE_PRODUCT_BUNDLE : OBJECT_TYPE_PRODUCT, id)} />
        <p style={{
            //whiteSpace: 'nowrap', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis',
            textAlign: 'center',
            fontSize: 14, marginTop: 10, opacity: (noStock && !allowNoStockClick) ? 0.65 : 1, maxWidth: 90,
        }}>{name}</p>

        {/* {(amount.price != amount.originalPrice && amount.originalPrice) ? (<>
            <p style={{ fontSize: 12, opacity: noStock ? 0.65 : 1, textDecoration: 'line-through', color: 'red' }}>
                <span style={{ color: 'black' }}>AED {amount !== null ? amount.originalPrice.toFixed(2) : (0).toFixed(2)}</span>
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tag16 style={{ marginRight: '0.25rem', fill: 'red' }} />
                <p style={{ fontSize: 14, opacity: 0.65, opacity: noStock ? 0.65 : 1, color: 'red', fontWeight: 'bold' }}>
                    AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}
                </p>
            </div>
        </>) : (<>
            <p style={{ fontSize: 12, opacity: 0.65, opacity: noStock ? 0.65 : 1, }}>AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}</p>
        </>)} */}

        {!hideStock && <>

            {noStock && <p style={{ fontWeight: 'bold', color: 'red', fontSize: 14 }}>NO STOCK</p>}

            {!noStock && lowStock && <div style={{
                position: 'absolute', background: 'red', borderRadius: 25, color: 'white', zIndex: 1000,
                top: 0, right: -15,
                paddingTop: 5, paddingBottom: 5, paddingLeft: 12, paddingRight: 12
            }}>
                <p style={{ fontSize: 12 }}>Low stock <strong>{stock}</strong></p>
            </div>}

        </>}
    </div>
)

const List = ({ includeDummyCartItem, allowNoStockClick, hideStock, onClick, store }) => {
    const [selectedCategoryId, setSelectedCategoryId] = useStore(store, "selectedCategoryId")
    const [searchValue, setSearchValue] = useStore(store, "searchValue")

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    const loadingRef = useRef(false);
    const firstTimeRef = useRef(true);

    const reqIdRef = useRef(Util.newTempId());

    const [resetRequest, setRequest] = useStore(store, 'resetRequest')
    const resetRequestFirstTime = useRef(true)

    useEffect(() => {
        if (page == -1) {
            setPage(0);
            return;
        }

        setLoading(true);
        loadingRef.current = true;
        const reqId = reqIdRef.current;

        const listener = response => {
            if (reqIdRef.current != reqId) {
                return;
            }

            if (response.status === true) {
                setItems(items => [...items, ...response.payload.items])
                setHasMore(response.payload.hasMore)
            } else {
                setError(true)
            }
            setLoading(false)
            loadingRef.current = false;

            if (response.payload.hasMore) {
                if (targetRef.current?.clientHeight >= (targetRef.current?.scrollHeight)) {
                    getNextItems()
                }
                // getNextItems();
            }
        };



        if (searchValue && searchValue.trim && searchValue.trim() != "") {
            Api.getThirdPartyPosProducts({
                page, categoryId: 0,
                search: searchValue,
                includeDummyCartItem
            }, listener)
        } else {
            Api.getThirdPartyPosProducts({
                page, categoryId: selectedCategoryId,
                includeDummyCartItem
            }, listener)
        }

        // if (searchValue && searchValue.trim && searchValue.trim() != "") {
        //     Api.getPosProducts({
        //         page, categoryId: 0,
        //         storeId: 29,
        //         search: searchValue
        //     }, listener)
        // } else {
        //     Api.getPosProducts({
        //         page, categoryId: selectedCategoryId,
        //         storeId: 29,
        //     }, listener)
        // }

    }, [page])

    const reset = () => {
        reqIdRef.current = Util.newTempId();
        setItems([])
        setPage(-1)
    }

    useEffect(() => {
        if (firstTimeRef.current === true) {
            firstTimeRef.current = false;
            return;
        }

        reset()
    }, [selectedCategoryId, searchValue])

    useEffect(() => {
        if (resetRequestFirstTime.current === true) {
            resetRequestFirstTime.current = false;
            return;
        }

        reset();
    }, [resetRequest])

    const getNextItems = () => {
        if (loadingRef.current === true) {
            return;
        }

        loadingRef.current = true;
        setPage(page + 1);
    }

    const targetRef = useRef();
    const size = useSize(targetRef)

    return (
        <div ref={targetRef} style={{ width: 'calc(100% + 1rem)', marginLeft: '-0.5rem', flex: 1, overflow: 'auto' }}>
            {!error ? (
                <InfiniteScroll
                    loadMore={getNextItems}
                    hasMore={hasMore}
                    useWindow={false}
                    threshold={size.height - (0.80 * size.height)}
                    loader={
                        <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                            <InlineLoading style={{ width: 'unset' }} />
                        </div>
                    }
                    style={{ overflow: 'visible' }}>

                    <div style={{ display: 'flex', flexWrap: 'wrap', padding: '1rem', gap: "2rem", justifyContent: 'space-around', alignItems: 'flex-start' }}>

                        {items
                            .map(product =>
                                <ProductItem key={product.id + "-product-preview"}
                                    onClick={() => onClick(product)} allowNoStockClick={allowNoStockClick} hideStock={hideStock}
                                    lowStock={product.lowStock} stock={product.stock} noStock={product.bundle ? false : product.stock == 0}
                                    id={product.id} name={product.name} amount={product.amount} bundle={product.bundle} />)}
                    </div>


                </InfiniteScroll>
            ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>An error occurred. Please try again later.</p>
                </div>
            )}
        </div>
    )

}

export default ({ customTitle, allowNoStockClick, hideStock, includeDummyCartItem, onClick }) => {
    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("searchValue", "")
        observable.set("selectedCategoryId", 0)
        return observable;
    }, [])
    return (
        <div className="dashboard-card t-pos-card" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', paddingBottom: 0 }}>
            <Top customTitle={customTitle} store={store} />
            <List includeDummyCartItem={includeDummyCartItem} allowNoStockClick={allowNoStockClick} hideStock={hideStock} onClick={onClick} store={store} />
        </div>
    )
}