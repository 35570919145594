

export function ContentGrid({ children, left, right }) {
    return (
        <div style={{ width: '100%', display: 'flex', gap: '1rem', padding: '1rem', minHeight: 'calc(100% - 4rem)', background: '#E0E9F5' }}>
            <div style={{ flex: 2, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {left}
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {right}
            </div>
        </div>
    )
    // return (
    //     <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', padding: '1rem', minHeight: 'calc(100% - 4rem)', background: '#E0E9F5' }}>
    //         {children}
    //     </div>
    // )
}