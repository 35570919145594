import { ComboBox, ComposedModal, ContentSwitcher, ModalBody, ModalFooter, ModalHeader, Switch, TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { UserAssignation } from "../../activities/components/user-assignation";


export function CreateFlowDialog({ customers, sources, userTags, users, open, onClose, onCreated }) {
    const [fromCustomer, setFromCustomer] = useState(false)

    const [loading, setLoading] = useState(false);
    const [customerId, setCustomerId] = useState(0);
    const [fullName, setFullName] = useState("")
    const [sourceId, setSourceId] = useState(0)
    const [sourcePickerKey, setSourcePickerKey] = useState(Util.newTempId())

    const [selectedUsers, setSelectedUsers] = useState([]);

    const onCreate = () => {
        setLoading(true)

        Api.createLeadFlow(fromCustomer ? '' : fullName, sourceId, fromCustomer ? customerId : 0, (selectedUsers ?? []).map(item => item.userId), response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onCreated(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (open) {
            setFromCustomer(false);
            setCustomerId(0);
            setFullName("")
            setSourceId(0)
            setSourcePickerKey(Util.newTempId())
            setSelectedUsers([]);
        }
    }, [open])

    return (
        <ComposedModal //className="modal-that-supports-combo-box" 
            key="create-lead-flow-dialog" //size="sm" 
            open={open} onClose={onClose} preventCloseOnClickOutside>
            <ModalHeader label="Lead" title="New Lead" />
            <ModalBody style={{ paddingRight: '1rem', outline: 'none' }} hasScrollingContent>
                <p style={{ fontSize: 12, marginBottom: '1rem', opacity: 0.65 }}>Creating new lead</p>



                <ContentSwitcher selectedIndex={fromCustomer ? 1 : 0} onChange={e => setFromCustomer(e.index === 1)}>
                    <Switch name="New Flow" text="New Flow" />
                    <Switch name="From Customer" text="From Customer" />
                </ContentSwitcher>


                {fromCustomer ? (<>
                    <div style={{ height: '1rem' }} />
                    <ComboBox
                        light
                        // key={sourcePickerKey}
                        titleText={"Select customer"}
                        items={customers}
                        itemToString={item => item !== null ? item.value : ""}
                        selectedItem={customers.filter(item => item.id == customerId)[0]}
                        onChange={e => {
                            setCustomerId(e.selectedItem !== null ? e.selectedItem.id : 0)
                            // setSourcePickerKey(Util.newTempId())
                        }} />
                </>) : (<>
                    <div style={{ height: '1rem' }} />
                    <TextInput
                        data-modal-primary-focus
                        labelText="Lead Full Name"
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                    />
                </>)}


                <div style={{ height: '1rem' }} />
                <ComboBox
                    light
                    key={sourcePickerKey}
                    titleText={"Select source"}
                    items={sources}
                    itemToString={item => item !== null ? item.value : ""}
                    selectedItem={sources.filter(item => item.id == sourceId)[0]}
                    onChange={e => {
                        setSourceId(e.selectedItem !== null ? e.selectedItem.id : 0)
                        setSourcePickerKey(Util.newTempId())
                    }} />

                <div style={{ height: '1rem' }} />

                <label className="bx--label">User Assignations (optional)</label>
                <div style={{ padding: '1rem', background: 'white', borderRadius: 15, }}>
                    <UserAssignation
                        title="User Assignations" value={selectedUsers} onValueChanged={setSelectedUsers}
                        tags={userTags} allUsers={users}
                    />
                </div>
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    onClick={onCreate}>
                    {loading ? "Loading..." : "Create"}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}