import { useEffect, useMemo, useState } from "react";
import { TableInput } from "../../components/table-input";
import { ComboBox, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from "carbon-components-react";
import { ProductListSelector, ShipmentListSelector } from "../../components/list-selector";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import Button from "../../components/Button";
import { fixIdForApi } from "../../templates/form/form-api";
import { Link } from 'react-router-dom'

const COLUMNS = (light) => [
    {
        key: "shipment", title: "Shipment", flex: 3, Component: ({ value, setMultiProp, readonly }) => {
            const setShipment = shipment => {
                setMultiProp({
                    shipment,
                    shipmentId: shipment?.id ?? 0,
                    customerName: shipment?.invoiceCustomerIdName ?? '',
                })
            }
            if (readonly) {
                return (
                    <div style={{ height: 40, display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                        <Link to={"/shipment-job/" + value?.id}>
                            {value?.shipmentNo}
                        </Link>
                    </div>
                )
            } else {
                return (
                    <div style={{ height: 40, width: '100%' }}>
                        <ShipmentListSelector readonly={readonly} light={light} value={value} setValue={setShipment} />
                    </div>
                )
            }
        }
    },
    {
        key: "customerName", title: "Customer", flex: 2, Component: ({ value }) => {
            return <TextInput value={value} />
        }
    },
    {
        key: "againstAmount", title: "Against", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "chargeAmount", title: "Charge", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "chargeMessage", title: "Charge Message", flex: 4, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
]

export function SelectShipments({ value, setValue, readonly, light }) {
    const cols = useMemo(() => COLUMNS(light), [light])
    return (
        <TableInput readonly={readonly} minimal hideDummy={readonly} columns={cols} value={value} setValue={setValue} />
    )
}


export function ShipmentListDialog({ open, onClose, bill, onUpdateBill }) {
    const [value, setValue] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setValue(bill?.againstShipments ?? [])
        }
    }, [open])

    const onUpdateBtn = () => {
        setLoading(true)
        Api.updateBillShipments(bill.id, fixIdForApi(value), response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onUpdateBill(response.payload)
                onClose();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false)
        })
    }

    return (
        <ComposedModal size="lg" className="modal-that-supports-combo-box" open={open} onClose={onClose}>
            <ModalHeader label={"Update Bill"} title={"Shipments"} />
            <ModalBody style={{ paddingRight: '1rem', outline: 'none' }} hasScrollingContent>
                <SelectShipments value={value} setValue={setValue} light />
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    onClick={onUpdateBtn}
                >
                    {loading ? "Loading..." : "Save"}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}