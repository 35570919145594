import { TextInput, ComboBox } from 'carbon-components-react';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ATTRIBUTE_TYPE_NUMBER, ATTRIBUTE_TYPE_TEXT } from '../constants/Constants';
import Button from './Button';

import { RowDelete32, Draggable16, Download16, Upload16 } from '@carbon/icons-react'
import Util from '../util/Util';

import { isEqual } from 'lodash'

import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { OBJECT_TYPE_PRODUCT } from '../constants/ObjectTypes';
import { FixedSizeList, VariableSizeList } from 'react-window';

const SortableItem = sortableElement(props => <InputRecord {...props} />);
const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);

export const useMultiInputState = (item, property, onInputUpdate) => {
    const [value, setValue] = useState(item[property]);
    useEffect(() => {
        if (onInputUpdate && item[property] != value) {
            onInputUpdate(property, value);
        }
    }, [value])
    return [value, setValue]
}

const InputRecord = ({ highZ, itemIndex, input, inputUpdated, inputRemoved, nonExistent, creating, skipMargin, ItemComponent, longList, ...props }) => {
    const SortHandler = sortableHandle(() => (
        <div style={{
            position: 'absolute',
            //marginRight: 5, alignSelf: 'flex-end', 
            left: -20, bottom: 0,
            height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'move'
        }}>
            <Draggable16 />
        </div>
    ))

    const [visible, setVisible] = React.useState(false);
    useEffect(() => {
        setVisible(false);
        setTimeout(() => setVisible(true))
    }, [itemIndex]);

    const onInputUpdate = (property, update) => {
        input[property] = update;
        inputUpdated();
    }

    return (
        <div style={{
            position: 'relative', display: 'flex', marginBottom: !skipMargin ? 15 : 0, //transition: '250ms', opacity: !visible && creating ? 0 : 1, transform: visible ? undefined : creating ? 'translateY(-50px)' : 'translateY(50px)',
            zIndex: highZ ? 1000000000000 : undefined
        }}>
            {!nonExistent && !props.hideDrag && <SortHandler />}
            <ItemComponent {...props} longList={longList} itemIndex={itemIndex} input={input} visible={visible} creating={creating} nonExistent={nonExistent} onInputUpdate={onInputUpdate} onInputRemoved={inputRemoved} />
        </div>
    )
}

function MultipleInputEditor(props, ref) {
    const freezeLock = props.freezeLock;

    let inputInitialState = props.defaultInputs !== null ? [...props.defaultInputs, { id: Util.newTempId() }] : [{ id: Util.newTempId() }];
    //if (props.readOnly || freezeLock) {
    if (props.readOnly) {
        inputInitialState = props.defaultInputs !== null ? [...props.defaultInputs] : [];
    }

    const [inputs, setInputs] = React.useState(inputInitialState);
    const [creating, setCreating] = useState(false);

    useEffect(() => {
        if (props.inputs) {
            if (props.readOnly) {
                if (!isEqual(props.inputs, inputs)) {
                    setInputs([...props.inputs]);
                }
            } else {
                if (!isEqual(props.inputs, inputs.slice(0, inputs.length - 1))) {
                    setInputs([...props.inputs, { id: Util.newTempId() }]);
                }
            }
        }
    }, [props.inputs])

    const onInputUpdated = input => {
        if (inputs.indexOf(input) != (inputs.length - 1)) {
            props.onInputsUpdated(inputs.slice(0, inputs.length - 1));
            return;
        }

        setCreating(true);
        props.onInputsUpdated(inputs);
        const updatedInputs = freezeLock ? [...inputs] : [...inputs, { id: Util.newTempId() }];
        setInputs(updatedInputs);
    }

    const onInputRemoved = input => {
        setCreating(false);

        let updatedInputs = [...inputs];
        updatedInputs.splice(updatedInputs.indexOf(input), 1);
        setInputs(updatedInputs);

        props.onInputsUpdated(updatedInputs.slice(0, updatedInputs.length - 1));
    }

    const onOrderUpdate = ({ oldIndex, newIndex }) => {
        const movableInputs = inputs.slice(0, inputs.length - 1);
        const updatedInputs = [...arrayMove(movableInputs, oldIndex, newIndex), inputs[inputs.length - 1]];
        setInputs(updatedInputs);

        props.onInputsUpdated(updatedInputs.slice(0, updatedInputs.length - 1));
    }

    useImperativeHandle(ref, () => ({
        addItem: item => {
            const lastInput = inputs[inputs.length - 1]
            for (const property in item) {
                lastInput[property] = item[property]
            }
            onInputUpdated(lastInput)
        },
        addMultipleItems: items => {
            const newInputs = [...inputs.slice(0, inputs.length - 1), ...items];
            setCreating(true);
            props.onInputsUpdated(newInputs);
            setInputs([...newInputs, { id: Util.newTempId() }]);
        }
    }));

    return (
        <div>
            <SortableContainer
                helperClass={props.inModal ? 'sortableHelper' : undefined}
                onSortEnd={onOrderUpdate}
                useDragHandle>
                {inputs
                    .filter((input, index) => freezeLock ? (index === (inputs.length - 1) ? false : true) : true)
                    .map((input, index) => (
                        <SortableItem
                            {...props}
                            skipMargin={props.skipMargin}
                            key={input.id} index={index} itemIndex={index} creating={creating}
                            nonExistent={inputs.indexOf(input) == (inputs.length - 1)} input={input}
                            ItemComponent={props.ItemComponent}
                            inputUpdated={() => onInputUpdated(input)} inputRemoved={() => onInputRemoved(input)}
                            longList={inputs?.length > 25}
                        />
                    ))}
            </SortableContainer>
        </div>
    )
}

function VritualRow({ data, index, style }) {
    const {
        props, creating, inputs, list, onInputUpdated, onInputRemoved
    } = data;
    const input = list[index]
    return (
        <div key={input.id} style={{
            ...style,
            width: "99%",
        }}>
            <InputRecord
                {...props}
                skipMargin={props.skipMargin}
                index={index} itemIndex={index} creating={creating}
                nonExistent={inputs.indexOf(input) == (inputs.length - 1)} input={input}
                inputUpdated={() => onInputUpdated(input)} inputRemoved={() => onInputRemoved(input)}
                ItemComponent={props.ItemComponent}
                longList={inputs?.length > 25}
            />
        </div>
    )
}

function VirtualMultipleInputEditorFn(props, ref) {
    const freezeLock = props.freezeLock;

    let inputInitialState = props.defaultInputs !== null ? [...props.defaultInputs, { id: Util.newTempId() }] : [{ id: Util.newTempId() }];
    //if (props.readOnly || freezeLock) {
    if (props.readOnly) {
        inputInitialState = props.defaultInputs !== null ? [...props.defaultInputs] : [];
    }

    const [inputs, setInputs] = React.useState(inputInitialState);
    const [creating, setCreating] = useState(false);

    useEffect(() => {
        if (props.inputs) {
            if (props.readOnly) {
                if (!isEqual(props.inputs, inputs)) {
                    setInputs([...props.inputs]);
                }
            } else {
                if (!isEqual(props.inputs, inputs.slice(0, inputs.length - 1))) {
                    setInputs([...props.inputs, { id: Util.newTempId() }]);
                }
            }
        }
    }, [props.inputs])

    const onInputUpdated = input => {
        if (inputs.indexOf(input) != (inputs.length - 1)) {
            props.onInputsUpdated(inputs.slice(0, inputs.length - 1));
            return;
        }

        setCreating(true);
        props.onInputsUpdated(inputs);
        const updatedInputs = freezeLock ? [...inputs] : [...inputs, { id: Util.newTempId() }];
        setInputs(updatedInputs);
    }

    const onInputRemoved = input => {
        setCreating(false);

        let updatedInputs = [...inputs];
        updatedInputs.splice(updatedInputs.indexOf(input), 1);
        setInputs(updatedInputs);

        props.onInputsUpdated(updatedInputs.slice(0, updatedInputs.length - 1));
    }

    const onOrderUpdate = ({ oldIndex, newIndex }) => {
        const movableInputs = inputs.slice(0, inputs.length - 1);
        const updatedInputs = [...arrayMove(movableInputs, oldIndex, newIndex), inputs[inputs.length - 1]];
        setInputs(updatedInputs);

        props.onInputsUpdated(updatedInputs.slice(0, updatedInputs.length - 1));
    }

    useImperativeHandle(ref, () => ({
        addItem: item => {
            const lastInput = inputs[inputs.length - 1]
            for (const property in item) {
                lastInput[property] = item[property]
            }
            onInputUpdated(lastInput)
        },
        addMultipleItems: items => {
            const newInputs = [...inputs.slice(0, inputs.length - 1), ...items];
            setCreating(true);
            props.onInputsUpdated(newInputs);
            setInputs([...newInputs, { id: Util.newTempId() }]);
        }
    }));

    const list = useMemo(() => {
        return inputs.filter((input, index) => freezeLock ? (index === (inputs.length - 1) ? false : true) : true)
    }, [inputs, freezeLock])

    const listRef = useRef()

    return (
        <div style={{ padding: '1rem', paddingRight: 0, border: '1px solid #00000020', borderRadius: 10, background: "#f4f4f4", boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
            <Button
                renderIcon={Download16}
                style={{ borderRadius: 7, marginBottom: '1rem' }}
                kind="ghost"
                onClick={() => listRef.current?.scrollToItem?.(list.length - 1, "end")}>Scroll to bottom</Button>
            <VariableSizeList
                ref={listRef}
                itemSize={index => {
                    if (window.screen.width < 1100) {
                        return 140;
                    } else {
                        return 114;
                    }
                }}
                height={600}
                width={"100%"}

                itemData={{
                    props, creating, inputs, list, onInputUpdated, onInputRemoved
                }}
                itemCount={list.length}>
                {VritualRow}
            </VariableSizeList>
            <Button
                renderIcon={Upload16}
                style={{ borderRadius: 7, marginBottom: '1rem' }}
                kind="ghost"
                onClick={() => listRef.current?.scrollToItem?.(0, "start")}>Scroll to top</Button>
        </div>
    )
}

export default forwardRef(MultipleInputEditor)

export const VirtualMultipleInputEditor = forwardRef(VirtualMultipleInputEditorFn);