
import {
    Delivery16,
    Delivery32,
    VirtualColumnKey16,
    VirtualColumnKey32,
    Task32,
    Task16,
    Calculator32,
    Calculator16,
    ServiceDesk16,
    ServiceDesk32,
    FlowStream16,
    FlowStream32,
    ShoppingCart16,
    ShoppingCart32,
    Restaurant16,
    Restaurant32,
    Currency16,
    Currency32,
    Edit16,
    Edit32,
    Cube16,
    Cube32,
} from '@carbon/icons-react'
import { hasCapabilitySupport } from '../../app/Capabilities';
import { MENU_PACKAGE } from '../../app/Config';
import Activities from './views/Activities';
import DeliverySystem from './views/DeliverySystem';
import NonDefaultFeatures from './views/NonDefaultFeatures'
import Pos from './views/Pos';
import Services from './views/Services';
import StockFlows from './views/StockFlows';
import Restaurant from './views/Restaurant';
import Accounting from './views/Accounting';
import { isV2 } from '../../util/Util';
import Editing from './views/Editing';
import General from './views/General';


export const isSettingsEnabled = (settingsValues, key) => {
    const setting = settingsValues.filter(setting => setting.key = key)[0];
    return setting ? setting.enabled : false;
}

export const SETTINGS_VIEWS = (() => {
    if (MENU_PACKAGE === "crm2") {
        return [
            ...(hasCapabilitySupport("activities") ? [
                {
                    icon: Task16,
                    largeIcon: Task32,
                    title: "Activities",
                    subtitle: "Design activity workflow based on your company policy",
                    component: Activities
                },
            ] : []),
        ]
    } else {
        return [
            {
                icon: Cube16,
                largeIcon: Cube32,
                title: "General",
                subtitle: "General settings",
                component: General
            },
            {
                icon: Currency16,
                largeIcon: Currency32,
                title: "Accounting",
                subtitle: "Manage your books",
                component: Accounting
            },
            ...(hasCapabilitySupport("restaurant") ? [
                {
                    icon: Restaurant16,
                    largeIcon: Restaurant32,
                    title: "Restaurant",
                    subtitle: "Manage your restaurant management flow",
                    component: Restaurant
                }
            ] : []),
            {
                icon: FlowStream16,
                largeIcon: FlowStream32,
                title: "Stock Flows",
                subtitle: "Tailor stock flows based on your company policy",
                component: StockFlows
            },
            ...(hasCapabilitySupport("terminalPos") ? [
                {
                    icon: Calculator16,
                    largeIcon: Calculator32,
                    title: "POS",
                    subtitle: "Customize your POS session experience",
                    component: Pos
                },
            ] : [
                {
                    icon: ShoppingCart16,
                    largeIcon: ShoppingCart32,
                    title: "Cart",
                    subtitle: "Customize your sales session experience",
                    component: Pos
                },
            ]),
            ...(hasCapabilitySupport("services") ? [
                {
                    icon: ServiceDesk16,
                    largeIcon: ServiceDesk32,
                    title: "Services",
                    subtitle: "Tailor services based on your company policy",
                    component: Services
                }
            ] : []),
            ...(hasCapabilitySupport("activities") ? [
                {
                    icon: Task16,
                    largeIcon: Task32,
                    title: "Activities",
                    subtitle: "Design activity workflow based on your company policy",
                    component: Activities
                },
            ] : []),
            ...(hasCapabilitySupport("orderManagementSystem") ? [
                {
                    icon: Delivery16,
                    largeIcon: Delivery32,
                    title: "Delivery System",
                    subtitle: "Setup your own unique delivery workflow",
                    component: DeliverySystem
                },
            ] : []),
            {
                icon: VirtualColumnKey16,
                largeIcon: VirtualColumnKey32,
                title: "Non-default features",
                subtitle: "Enabling these features have some nuances associated with them",
                component: NonDefaultFeatures
            },
            ...(!isV2() ? [
                {
                    icon: Edit16,
                    largeIcon: Edit32,
                    title: "Editing",
                    subtitle: "Authorize editing access",
                    component: Editing
                },
            ] : []),
        ]
    }
})()