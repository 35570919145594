import { useMemo, useState } from "react";
import { MosaicGrid } from "../../../../components/mosaic-grid";
import { CartCard } from "../components/cart-card";
import { OrderState } from "../engine/OrderState";
import { TextInput } from "carbon-components-react";
import Button from "../../../../components/Button";
import Util from "../../../../util/Util";
import { ErrorFilled16 } from '@carbon/icons-react'
import { SOCKET_EVENT_LIVE_CART, useSubscribe } from "../../../../session/SocketSession";
import Api from "../../../../session/Api";

/**
 * 
 * @param {{ 
 *  state: OrderState, 
 *  carts: [] 
 * }} props 
 * @returns 
 */
export function ActiveCarts({ state, carts, mainApp }) {
    const [orderNo, setOrderNo] = useState("")
    const [waiterName, setWaiterName] = useState("")
    const [table, setTable] = useState("")

    const filteredCarts = useMemo(() => {
        if (!carts) {
            return []
        }

        if (Util.isStringExists(table) || Util.isStringExists(orderNo) || Util.isStringExists(waiterName)) {
            return carts.filter(cart => {
                const predicates = []
                if (Util.isStringExists(table)) {
                    predicates.push(
                        cart.tableName?.toLowerCase?.() === table.toLowerCase().replace("table", "").trim()
                    )
                }
                if (Util.isStringExists(orderNo)) {
                    predicates.push(cart?.orderNo?.toLowerCase?.().includes?.(orderNo.trim()))
                }
                if (Util.isStringExists(waiterName)) {
                    predicates.push(cart?.waiterName?.toLowerCase?.().includes?.(waiterName.trim()))
                }

                return predicates.filter(p => p).length === predicates.length;
            });
        } else {
            return carts;
        }
    }, [orderNo, waiterName, table, carts])

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', background: 'white', overflow: 'auto', }}>
            <div style={{ position: 'sticky', top: 0, minHeight: '3rem', paddingBlock: '0.5rem', zIndex: 50, background: '#f4f4f4', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem', flexWrap: 'wrap', borderBottom: '1px solid #00000020' }}>
                <div style={{ flex: 1 }} />

                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', width: 120 }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Order#: </label>
                    <TextInput light size="sm" placeholder="No filter" value={orderNo} onChange={e => setOrderNo(e.target.value)} />
                </div>
                <div style={{ width: '0.25rem' }} />
                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', width: 120 }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Waiter: </label>
                    <TextInput light size="sm" placeholder="No filter" value={waiterName} onChange={e => setWaiterName(e.target.value)} />
                </div>
                <div style={{ width: '0.25rem' }} />
                <div className="minimal-input" style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', width: 100 }}>
                    <label style={{ marginBottom: 0 }} className="bx--label">Table: </label>
                    <TextInput light size="sm" placeholder="No filter" value={table} onChange={e => setTable(e.target.value)} />
                </div>
                <Button disabled={!Util.isStringExists(orderNo) && !Util.isStringExists(waiterName) && !Util.isStringExists(table)}
                    kind="danger" size="sm" renderIcon={ErrorFilled16} onClick={() => {
                        setOrderNo("")
                        setWaiterName("")
                        setTable("")
                    }}></Button>
            </div>

            <div style={{ width: '100%', height: 'calc(100% - 3rem)', }}>
                {filteredCarts.length === 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>
                </div>}
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '1rem', padding: '1rem', gridAutoRows: 'min-content' }}>
                    {filteredCarts.map(cart => <CartCard key={cart.cartId} cart={cart} mainApp={mainApp} />)}
                </div>
                <div style={{ height: '12rem' }} />
            </div>
        </div >
    )
}