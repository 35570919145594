import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_COUPON, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import PriceOverrideList from '../../views/price-override/PriceOverrideList';


const PriceOverride = ({item}) => {
    return (
        <div style={{marginTop: '1rem'}}>
            <PriceOverrideList parent={item} />
        </div>
    )
}


const RestrictedStoresList = ({item}) => {
    const history = useHistory();
    return (
        <div style={{marginTop: '1rem'}}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,
                    
                    parentId: item.id,
                    parentType: OBJECT_TYPE_DISCOUNT,

                    childType: OBJECT_TYPE_STORE,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Stores", 
                subTitle: "Restricted stores", 
                objectType: OBJECT_TYPE_STORE, 
                pagePath: "/stores/", 
                history: history
            })}
        </div>
    )
}


class DiscountDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_DISCOUNT, listener)
            } else {
                Api.getItem(OBJECT_TYPE_DISCOUNT, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_DISCOUNT,
                    itemResult: this.state.itemResult,
                    pagePath: "/discounts/",
                    history: this.props.history,
                    customTabs: [
                        ...(this.state.itemResult.item.storeRestricted ? [
                            {
                                title: "Store Restrictions",
                                component: RestrictedStoresList
                            }
                        ] : []),
                        {
                            title: "Price Override",
                            component: PriceOverride
                        }
                    ],
                    onSave: update => this.setState(prevState => ({itemResult: {
                        ...prevState.itemResult,
                        item: {
                            ...prevState.itemResult.item,
                            ...update
                        }
                    }}))
                })}
            </div>
        )
    }

}

export default withRouter(DiscountDetailPage);