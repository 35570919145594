import { ComboBox, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, RadioTile, TileGroup } from "carbon-components-react";
import React, { useEffect, useState } from "react"
import { hasCapabilitySupport } from "../../../app/Capabilities";
import Button from "../../../components/Button";
import { DESTINATION_TYPE_MOBILE_UNIT, DESTINATION_TYPE_STORE, DESTINATION_TYPE_WAREHOUSE, SOURCE_TYPE_WAREHOUSE } from "../../../constants/Constants";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import Util, { isV2 } from "../../../util/Util";

import {
    DataVis_232,
    Store32,
    Van32,

    ErrorFilled16,
} from '@carbon/icons-react'

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)


export default ({ open, onClose, salesOrderId, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const [endpointsList, setEndpointsList] = useState();

    const [destinationType, setDestinationType] = useState(DESTINATION_TYPE_WAREHOUSE)
    const [destinationId, setDestinationId] = useState(0)
    const [destinationIdPickerKey, setDestinationIdPickerKey] = useState(Util.newTempId())

    useEffect(() => {
        setDestinationId(0)
        setDestinationIdPickerKey(Util.newTempId())
    }, [destinationType])

    useEffect(() => {
        if (isV2()) {
            return;
        }

        setLoading(true);
        Api.getStockFlowEndpointsList(response => {
            if (response.status === true) {
                setDestinationType(DESTINATION_TYPE_WAREHOUSE);

                setEndpointsList(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message);
                onClose();
            }
        })
    }, [open])

    const onBtn = () => {
        setLoading(true);
        Api.createSalesOrderRentalStockFlow(salesOrderId, destinationType, destinationId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onClose();
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    const getDestinationName = () => {
        switch (destinationType) {
            case DESTINATION_TYPE_WAREHOUSE:
                return "Warehouse";
            case DESTINATION_TYPE_STORE:
                return "Store";
            case DESTINATION_TYPE_MOBILE_UNIT:
                return "Mobile Unit"
        }
    }

    const getDestinationList = () => {
        if (!endpointsList) {
            return []
        }

        switch (destinationType) {
            case DESTINATION_TYPE_WAREHOUSE:
                return endpointsList.warehouses
            case DESTINATION_TYPE_STORE:
                return endpointsList.stores
            case DESTINATION_TYPE_MOBILE_UNIT:
                return endpointsList.mobileUnits
        }
    }

    return (
        <ComposedModal open={open} onClose={onClose} className="modal-that-supports-combo-box">
            <ModalHeader label="Updating Sales Order" title="Finalize Inspection" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                {isV2() ? (<>
                    <p>Are you sure and want to confirm the inspection?</p>
                </>) : (<>
                    {loading ? (
                        <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45 }}>
                            <InlineLoading style={{ width: 'unset' }} />
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
                        </div>

                    ) : (
                        <div>
                            <label className="bx--label" style={{}}>Stock destination</label>
                            <TileGroup className={"horizontal-tile-radio"} valueSelected={destinationType} onChange={value => setDestinationType(value)}>
                                <RadioTile light value={DESTINATION_TYPE_WAREHOUSE}>
                                    <RadioItem icon={DataVis_232} title="Warehouse" desc="Return stock to warehouse" />
                                </RadioTile>
                                <RadioTile light value={DESTINATION_TYPE_STORE}>
                                    <RadioItem icon={Store32} title="Store" desc="Return stock to store" />
                                </RadioTile>
                                {hasCapabilitySupport("mobileUnit") &&
                                    <RadioTile light value={DESTINATION_TYPE_MOBILE_UNIT}>
                                        <RadioItem icon={Van32} title="Mobile unit" desc="Return stock to mobile unit" />
                                    </RadioTile>}
                            </TileGroup>
                            <div style={{ height: '1rem' }} />

                            <ComboBox
                                key={destinationIdPickerKey}
                                light
                                titleText={getDestinationName()}
                                items={getDestinationList()}
                                itemToString={item => item !== null ? item.value : ""}
                                selectedItem={getDestinationList().filter(item => item.id == destinationId)[0]}
                                //initialSelectedItem={this.state[stateValue(field.property)]}
                                //onChange={e => this.setState({destinationIdValue: e.selectedItem !== null ? e.selectedItem.id : 0})} 
                                onChange={e => {
                                    setDestinationId(e.selectedItem !== null ? e.selectedItem.id : 0)
                                    setDestinationIdPickerKey(Util.newTempId())
                                }}
                            />
                            <div style={{ height: '1rem' }} />
                        </div>
                    )}
                </>)}

            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    loading={loading}
                    disabled={isV2() ? false : destinationId <= 0}
                    onClick={onBtn}
                >
                    Confirm
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}