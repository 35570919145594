import { Checkbox, NumberInput, Slider, TextArea, TextInput } from 'carbon-components-react'
import React from 'react'
import DimensionsField from './base/DimensionsField'
import InspectorContentHeader from './base/InspectorContentHeader'

import useCanvasComponentInspectorField from '../../hooks/useCanvasComponentInspectorField'
import ColorField from './base/ColorField'
import useBooleanToggler from '../../hooks/useBooleanToggler'

export default ({engine, componentId}) => {
    const { changeListener, getter, setter, multiSetter } = useCanvasComponentInspectorField(engine, componentId, ['fill', 'height']);

    return (
        <div>
            <InspectorContentHeader text="Appearance" />
            <Slider onRelease={changeListener.onBlur} labelText="Thickness" min={2} max={50} hideTextInput value={getter('height')} onChange={({ value }) => setter('height', value)} />
            <div style={{height: '1rem'}} />
            <ColorField title="Color" color={getter('fill')} onChange={color => setter('fill', color, true)} />
        </div>
    )
}