import { Checkbox, InlineLoading, Tag } from "carbon-components-react";
import { Header } from "./my-students";
import Api from "../../../session/Api";
import { useEffect, useMemo, useRef, useState } from "react";
import UIUtil from "../../../util/UIUtil";
import { MinimalTable } from "../../../components/minimal-table";
import Util from "../../../util/Util";
import { ACCOUNT_TYPE_STUDENT, STUDENT_PRESENCE } from "../../../constants/Constants";
import { ButtonPopover } from "../../../components/button-popover";
import {
    OverflowMenuVertical16
} from '@carbon/icons-react'
import { TutorPresenceController } from "../activity-ext/student-presence/activity-presence-report";
import { isMobileScreen } from "../../../App";
import { getAccount, getAccountRole } from "../../../session/SessionManager";



export function HistoryReport({ courseId }) {
    const role = getAccountRole();
    const isStudent = role == ACCOUNT_TYPE_STUDENT;

    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        Api.getMySessionHistoryInCourse(courseId, response => {
            if (response.status === true) {
                setReport(response.payload);
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }, [courseId])


    if (loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    return (
        <div>
            <Header>History</Header>
            <div style={{ overflowX: 'auto', padding: '1rem' }}>
                <div style={{ width: !isMobileScreen ? '100%' : '300%', position: 'relative' }}>
                    <MinimalTable large columns={[
                        {
                            name: "day",
                            title: "Day",
                            flex: 2,
                            renderCell: item => Util.getDay(item.startDate)
                        },
                        {
                            name: "date",
                            title: "Date",
                            flex: 2,
                            renderCell: item => Util.getDateOnly(item.startDate)
                        },
                        {
                            name: "time",
                            title: "Time",
                            flex: 2,
                            renderCell: item => Util.getTime(item.startDate)
                        },
                        {
                            name: "duration",
                            title: "Duration",
                            flex: 2,
                            renderCell: item => Util.formatDuration(item.endDate - item.startDate)
                        },
                        {
                            name: "coursePaymentPackageName",
                            title: "Package",
                            flex: 2,
                            renderCell: item => Util.isNumberExist(item.coursePaymentPackageId) ? item.coursePaymentPackageName : '-'
                        },
                        {
                            name: "blueprintName",
                            title: "Type",
                            flex: 2,
                            renderCell: item => item.blueprintName
                        },
                        ...(isStudent ? [
                            {
                                name: "attending",
                                title: "Attending",
                                flex: 2,
                                renderCell: item => <div style={{ pointerEvents: 'none' }}><Checkbox checked={item?.attendanceReportStudentPresenceForTutor?.studentPresence?.state === STUDENT_PRESENCE.PRESENT} /></div>
                            },
                            {
                                name: "absent",
                                title: "Absent",
                                flex: 2,
                                renderCell: item => <div style={{ pointerEvents: 'none' }}><Checkbox checked={item?.attendanceReportStudentPresenceForTutor?.studentPresence?.state === STUDENT_PRESENCE.ABSENT} /></div>
                            },
                        ] : [])
                    ]} items={report} />

                </div>
                <div style={{ height: '18rem' }} />
            </div>
        </div>)
}