import QRCode from "react-qr-code"
import Util from "../../../../util/Util"
import { useRefSize } from "../../../../util/useSize"
import { Box } from "../../../../components/Box"
import Button from "../../../../components/Button"
import { ShoppingCartPlus16, Printer16, ServiceDesk16, Share16, Checkmark16 } from "@carbon/icons-react"
import UIUtil from "../../../../util/UIUtil"
import { share } from "../../../monitoring/TerminalMonitoringView"
import { useEffect, useRef, useState } from "react"
import Portal from "../../../../util/Portal"
import { SidePanel } from "../../../../templates/draft/components/side-panel"
import { EditCartView } from "../views/edit-cart"
import { ModifyOrderView, ModifyOrderViewWithOrderId } from "./modify-order-view"
import { newHeadlessPosState } from "../../state/headless-pos-state"
import PosState from "../../state/PosState"

const cardProps = (cart) => {
    if (Util.isStringExists(cart.orderNo)) {
        return { bg: "#00990015", border: "#00990060" }
    } else {
        return { bg: "#00009915", border: "#00009960" }
    }
}

const Card = ({ bg, border, children }) => (
    <div style={{
        width: '100%', background: bg, borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', border: '1px solid #00000010', borderColor: border,
        overflow: 'hidden', height: 'fit-content', padding: '1rem'
    }}>
        {children}
    </div>
)

const QrCode = ({ cart }) => {
    const onEnlargeBtn = () => {
        UIUtil.showOverlay(
            <div style={{ padding: '1rem', background: 'white', borderRadius: 10 }}>
                <QRCode size={300} value={cart.href} />
            </div>
        )
    }
    return (
        <Box>
            <div style={{ width: '100%', height: '100%', padding: '1rem', background: 'white', border: '1px solid #00000020', borderRadius: 7 }}>
                <Box>
                    {size => <div onClick={onEnlargeBtn}>
                        <QRCode size={size} value={cart.href} />
                    </div>}
                </Box>
            </div>
        </Box>
    )
}

const Actions = ({ cart, mainApp }) => {
    const [printing, setPrinting] = useState(false)
    const [editVisible, setEditVisible] = useState(false);

    const onShareBtn = () => share(cart.href, () => alert("Copy link: " + cart.href))
    const onPrintBtn = async (e) => {
        if (printing) {
            return;
        }

        setPrinting(true);
        try {
            await mainApp.thermalPrinter.driver.printRestCartQr(cart)
            UIUtil.showSuccess();
        } catch (e) {
            UIUtil.showError()
        } finally {
            setPrinting(false)
        }
    }


    return (
        <div style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: '0.5rem' }}>
            <Button className="bx--tooltip--hidden" onClick={onPrintBtn} kind={"secondary"} style={{ borderRadius: 5 }} iconDescription="Print" hasIconOnly renderIcon={Printer16} />
            <Button className="bx--tooltip--hidden" onClick={onShareBtn} kind={"primary"} style={{ borderRadius: 5 }} iconDescription="Share" hasIconOnly renderIcon={Share16} />
            <Button className="bx--tooltip--hidden green-button" onClick={() => setEditVisible(true)} style={{ borderRadius: 5 }} iconDescription="Edit Cart" hasIconOnly renderIcon={ShoppingCartPlus16} />


            <Portal>
                {editVisible && <SidePanel lg onClose={() => setEditVisible(false)}>
                    {cart.orderId ? (
                        <ModifyOrderViewWithOrderId orderId={cart.orderId} orderNo={cart.orderNo} subtitle={"Table " + cart.tableName} onClose={() => setEditVisible(false)} />
                    ) : (
                        <EditCartView cart={cart} onClose={() => setEditVisible(false)} />
                    )}
                </SidePanel>}
            </Portal>
        </div>
    )
}

const WaiterCalledIndicator = ({ cart }) => {
    const [background, setBackground] = useState("red");
    const color = background === "yellow" ? "black" : 'white';
    const [hide, setHide] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setBackground(bg => bg === "red" ? "yellow" : "red");
        }, 500)
        return () => clearInterval(interval);
    }, [])

    const [loading, setLoading] = useState(false);
    const onClick = async () => {
        if (loading) {
            return;
        }
        setLoading(true);

        try {
            const state = newHeadlessPosState((getState, setState) => PosState.cartSession(getState, setState, cart.cartId, { onClose: () => { } }));
            await state.setCartDataWaiterCalled(false);
            UIUtil.showSuccess(true);
            setHide(true)
        } catch (e) {
            UIUtil.showError();
        } finally {
            setLoading(false);
        }
    }

    if (hide) {
        return <div />
    }

    return (
        <div onClick={onClick} style={{ background, color, marginBottom: '0.5rem', opacity: loading ? 0.65 : 1, cursor: 'pointer', padding: '0.5rem', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
            <ServiceDesk16 />
            <h5 style={{ textAlign: 'center' }}>Captain Called</h5>
        </div>
    )
}

const OrderSubmittedIndicator = ({ cart }) => {
    const [background, setBackground] = useState("red");
    const color = background === "yellow" ? "black" : 'white';

    useEffect(() => {
        const interval = setInterval(() => {
            setBackground(bg => bg === "red" ? "yellow" : "red");
        }, 500)
        return () => clearInterval(interval);
    }, [])

    return (
        <div style={{ background, color, marginBottom: '0.5rem', cursor: 'pointer', padding: '0.5rem', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
            <Checkmark16 />
            <h5 style={{ textAlign: 'center' }}>Order Submitted</h5>
        </div>
    )
}



/**
 * 
 * @param {{ 
 *  cart: {
 *      type: "POS" | "CUSTOMER" 
 *  }
 * }} props 
 */
export function CartCard({ cart, mainApp }) {
    return (
        <Card {...cardProps(cart)}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <p style={{ fontSize: 16, fontWeight: 'bold' }}>Table {cart.tableName}</p>
                <div style={{ flex: 1 }} />
                <p style={{ fontSize: 14, opacity: 0.65 }}>{cart.waiterName}</p>
            </div>
            {cart.waiterCalled &&
                <WaiterCalledIndicator cart={cart} />}
            {cart.orderSubmitted &&
                <OrderSubmittedIndicator cart={cart} />}
            {cart.orderNo ? <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#0f62fe', paddingBlock: '0.15rem' }}>
                <p style={{ color: 'white', fontSize: 14 }}>Order {cart.orderNo}</p>
            </div> : <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#1c1c1c', paddingBlock: '0.15rem' }}>
                <p style={{ color: 'white', fontSize: 14 }}>Ordering...</p>
            </div>}
            <QrCode cart={cart} />
            <Actions cart={cart} mainApp={mainApp} />
        </Card>
    )
}