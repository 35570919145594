import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE, OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD, OBJECT_TYPE_SALES_ORDER_STATE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER } from "../../../constants/ObjectTypes";
import TableTemplate from '../../../templates/TableTemplate';

class CustomPagesListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Online Store Custom Pages",
                    subTitle: "All online store custom pages",
                    objectType: OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE,
                    pagePath: "/online-store-custom-pages/",
                    history: this.props.history,
                    getCustomListRequest: {
                        params: {
                            requireAuth: false
                        }
                    }
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(CustomPagesListPage);