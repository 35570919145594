
import { ShoppingCart16, ShoppingCartPlus16, RowDelete16, Tag16, Gift16, QueryQueue24, ShoppingCartClear16 } from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../components/Button'
import ProfilePic from '../../../components/ProfilePic'
import { getObjectTypeUrl } from '../../../constants/ObjectTypes'
import useStore from '../../../hooks/useStore'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import Util from '../../../util/Util'
import { getQuantityValue } from '../../pos/widgets/CartContent'
import useThirdPartyPos from '../pos/useThirdPartyPos'
import Header from '../third-party-pos/components/Header'
import SetQuantityDialog from '../third-party-pos/dialogs/SetQuantityDialog'



class CartItem extends React.Component {

    shouldComponentUpdate(prevProps) {
        return this.props.cartItem != prevProps.cartItem || this.props.scrollInto != prevProps.scrollInto || this.props.removingItem != prevProps.removingItem
    }

    render() {
        return <CartItemF {...this.props} />
    }

}

const CartItemF = ({ readonly, controller, linkProduct, removingItem, hasToggleComplimentary, togglingComplimentaryItem, onToggleComplimentaryBtn, onClearBtn, cartItem, scrollInto, onScrollDone }) => {
    const [key, setKey] = useState(Util.newTempId());
    const [highlight, setHighlight] = useState(false);

    const [quantityKey, setQuantityKey] = useState(Util.newTempId());
    const [quantityHighlight, setQuantityHighlight] = useState(undefined);

    const [qtyVisible, setQtyVisible] = useState(false)

    const ref = useRef();

    useEffect(() => {
        if (scrollInto) {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            }
            onScrollDone();
            setKey(Util.newTempId())
            setHighlight(true);
        }
    }, [scrollInto])


    const [lastQuantity, setLastQuantity] = useState(undefined);
    useEffect(() => {
        if (lastQuantity !== undefined && lastQuantity != cartItem.quantityValue) {
            setQuantityKey(Util.newTempId())
            setQuantityHighlight(lastQuantity > cartItem.quantityValue ? 'quantity-decrease-anim' : 'quantity-increase-anim');
        }
        setLastQuantity(cartItem.quantityValue);
    }, [cartItem.quantityValue])

    const name = linkProduct ? (
        <Link target="_blank" to={getObjectTypeUrl(cartItem.itemType) + '/' + cartItem.itemId}>
            {cartItem.name}
        </Link>
    ) : cartItem.name

    return (
        <div key={key} ref={ref} style={{
            display: 'flex',
            animation: highlight ? 'scale-bounce-anim 750ms' : '',
            //height: cartItem.bundleProducts.length > 0 ? undefined : 65, 
            fontSize: '.875rem', color: '#525252', borderBottom: '1px solid #e0e0e0', padding: '0.5rem .25rem 1rem', paddingLeft: '0rem',
            gap: '0.5rem', alignItems: 'flex-start'
        }}>
            {(cartItem.bundleProducts?.length ?? 0) > 0 ? (
                <div style={{ flex: 3 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: -5 }}>
                        <ProfilePic style={{ borderRadius: 10, minWidth: 45 }} size={45} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />

                        {/* {cartItem.name} */}
                        {name}
                    </div>
                    {cartItem.bundleProducts.map(bundleProduct => <p style={{ paddingLeft: '1.5rem', marginLeft: 30, opacity: 0.65, fontSize: 12, whiteSpace: 'pre-wrap' }}>
                        {bundleProduct.quantity + 'x ' + bundleProduct.name}
                    </p>)}
                </div>
            ) : (
                <div style={{ flex: 3, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <ProfilePic style={{ borderRadius: 10, minWidth: 45 }} size={45} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
                    {/* {cartItem.name} */}
                    {name}
                </div>
            )}

            <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: 5 }}>
                <div style={{ height: 45 }} />
                <span key={quantityKey} style={{ animation: quantityHighlight ? quantityHighlight + ' 750ms' : '' }}>
                    {getQuantityValue(cartItem)}
                </span>
            </div>
            {!readonly && (
                <div>
                    <div style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button style={{ marginLeft: 5 }} className="t-pos-btn" size="lg" loading={removingItem === cartItem}
                            onClick={() => UIUtil.confirm(() => onClearBtn(cartItem))}
                            kind="danger--tertiary" renderIcon={RowDelete16} hasIconOnly iconDescription="Remove Item" />
                        <Button onClick={() => setQtyVisible(true)} style={{ marginLeft: 5 }} className="t-pos-btn" size="lg" kind="secondary" tooltipAlignment={hasToggleComplimentary ? "center" : "end"}
                            renderIcon={ShoppingCartPlus16} hasIconOnly iconDescription="Set Quantity" />

                        {hasToggleComplimentary && <Button loading={togglingComplimentaryItem === cartItem} onClick={() => UIUtil.confirm(() => onToggleComplimentaryBtn(cartItem))} style={{ marginLeft: 5 }} className="t-pos-btn" size="lg" kind="primary" tooltipAlignment="end"
                            renderIcon={Gift16} hasIconOnly iconDescription="Toggle Complimentary" />}
                    </div>
                    {cartItem.complimentary && <Tag renderIcon={Gift16} size="sm" type='green' style={{ marginTop: '0.5rem' }}>Complimentary</Tag>}
                </div>
            )}

            {!readonly && <>
                <SetQuantityDialog
                    controller={controller}
                    itemId={cartItem.itemId}
                    groupToProductMap={cartItem.groupToProductMap}
                    qty={cartItem.quantityValue}
                    visible={qtyVisible} onClose={() => setQtyVisible(false)} />
            </>}
        </div>
    )

}

const CartHeader = () => (<>
    {/* <div style={{ marginBottom: '-0.75rem' }} /> */}

</>)

const CartList = ({ controller, readonly, linkProduct }) => {
    //const [cartItems] = useStore(sessionStore, "currentCartItems")
    const setItemComplimentary = controller.useComplimentary();
    const [addToCart, removeFromCart] = controller.useUpdateCart();
    const cartItems = controller.useItems();

    const [removingItem, setRemovingItem] = useState(undefined);
    const [togglingComplimentaryItem, setTogglingComplimentaryItem] = useState(undefined);
    const [scrollIntoItem, setScrollIntoItem] = useState(undefined);
    const lastItemCount = useRef(undefined)

    useEffect(() => {
        if (lastItemCount.current && lastItemCount.current < cartItems.length) {
            setScrollIntoItem(cartItems[cartItems.length - 1])
        }
        lastItemCount.current = cartItems.length;
    }, [cartItems.length])

    const onRemoveItemBtn = (item) => {
        setRemovingItem(item);
        removeFromCart(item.itemId, item.quantityValue, () => setRemovingItem(undefined), item.groupToProductMap)
    }

    const onToggleComplimentaryBtn = (item) => {
        setTogglingComplimentaryItem(item);
        setItemComplimentary(item.id, !item.complimentary, () => setTogglingComplimentaryItem(undefined))
    }

    return (
        <div style={{ width: 'calc(100% + 1rem)', marginLeft: '-0.5rem', padding: '0.5rem', paddingTop: 0, flex: 1, overflowY: 'auto', overflowX: 'hidden', }}>
            <div className="bx--structured-list-th dashboard-card t-pos-card-bg" style={{
                display: 'flex', paddingLeft: '0rem', borderBottom: '1px solid #e0e0e0', background: '#f4f4f4',
                position: 'sticky', top: 0, zIndex: 1000,
                height: 'unset', gap: '0.5rem',
                padding: '0.5rem .25rem 1rem', paddingLeft: '0rem',
                paddingLeft: 0, paddingBottom: '0.5rem', paddingTop: 0,
            }}>
                <div style={{ flex: 3 }}>
                    Item
                </div>
                <div style={{ flex: 1 }}>
                    Quantity
                </div>
                {!readonly && <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>

                </div>}
            </div>

            {cartItems.map((cartItem) => (
                <CartItem key={cartItem.itemId + "-cart-item"} cartItem={cartItem} readonly={readonly}
                    scrollInto={cartItem === scrollIntoItem} onScrollDone={() => setScrollIntoItem(undefined)}
                    removingItem={removingItem} linkProduct={linkProduct}
                    togglingComplimentaryItem={togglingComplimentaryItem}
                    onToggleComplimentaryBtn={onToggleComplimentaryBtn}
                    onClearBtn={onRemoveItemBtn}
                    controller={controller}
                    hasToggleComplimentary={setItemComplimentary !== undefined}
                />
            ))}
        </div>
    )
}

const Top = ({ customTitle, controller }) => {
    const [loadingClearing, clearCart] = controller.useClear();
    const onClearCartBtn = () => {
        UIUtil.confirm(clearCart);
    }
    return (
        <Header icon={QueryQueue24} title={customTitle ?? "Cart"}>
            {/* danger--tertiary */}
            <Button className="t-pos-btn t-pos-btn-icon" hasIconOnly kind="danger" tooltipPosition="left" tooltipAlignment="end"
                iconDescription={`Clear ${customTitle ?? "Cart"}`} size="sm" renderIcon={ShoppingCartClear16} onClick={onClearCartBtn} loading={loadingClearing}
            />
        </Header>
    )
}

export default ({ controller, readonly, customTitle, linkProduct }) => {
    return (
        <div className={"dashboard-card t-pos-card t-pos-card-bg"} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', paddingBottom: 0, }}>
            {!readonly && <>
                <Top customTitle={customTitle} controller={controller} />
                <div style={{ marginTop: '0.5rem' }} />
            </>}

            <CartHeader />
            <CartList linkProduct={linkProduct} controller={controller} readonly={readonly} />
        </div>
    )
}