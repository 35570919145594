import { useState } from "react";
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { COMPANY_NAME } from "../../../app/Config";
import Button from "../../../components/Button";
import { printPosReceipt } from "../../../pdfs/pos-receipt/PosReceiptPdf";
import Api from "../../../session/Api";
import { getAccount } from "../../../session/SessionManager";
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { generateBitmap, openBase64ImageInNewTab } from "../../../util/useBitmap";
import ThermalPrinterDriver from "../ThermalPrinterDriver";
import { ArabicPosReceipt } from "../resource/arabic-pos-receipt";
import { BillPrint } from "../resource/bill-print";
import { CouponPrint } from "../resource/coupon-print";
import { KotPrint } from "../resource/kot-print";
import { AllKotPrint } from "../resource/all-kot-print";
import { Add16, Printer16, Close16 } from '@carbon/icons-react'
import { SalesSummaryPrint } from "../resource/sales-summary-print";
import { RestCartQrPrint } from "../resource/rest-cart-qr-print";
import { RestBusinessSummary } from "../../../pages/rest-business-summary";


const SelectPrinter = ({ title, value, setValue }) => {
    const printers = value ?? []
    const [loading, setLoading] = useState(false);
    const addPrinter = async () => {
        setLoading(true);
        try {
            const allPrinters = await Api.asyncGetAvailableMultiEscPrinters();
            const selectedPrinter = await UIUtil.listPrompt("Select Printer", allPrinters.map($ => ({ id: $.id, value: $.title, name: $.name })));
            if (!selectedPrinter) {
                return;
            }

            if (printers.filter($ => $.id === selectedPrinter.id).length) {
                UIUtil.showInfo("Printer already added");
                return;
            }

            setValue([...printers, selectedPrinter])
        } finally {
            setLoading(false);
        }
    }
    const removePrinter = printer => setValue(printers.filter($ => $.id !== printer.id));
    return (<div>
        <label className="bx--label">{title}</label>
        {printers.map(printer => (
            <div key={printer.id} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', background: 'white', marginBottom: '0.5rem', paddingInline: '1rem', paddingBlock: '0.5rem', borderRadius: 7, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <Printer16 />
                <p style={{ fontSize: 14, flex: 1 }}>{printer.value}</p>

                <button onClick={() => UIUtil.confirm(() => removePrinter(printer))} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button loading={loading} onClick={addPrinter} kind={"secondary"} renderIcon={Add16} size="sm" style={{ borderRadius: 25 }}>Add printer</Button>
        </div>
    </div>)
}
const SelectKotPrinter = ({ value, setValue }) => <SelectPrinter title={"KOT Printers"} value={value} setValue={setValue} />
const SelectBillPrinter = ({ value, setValue }) => <SelectPrinter title={"Bill Printers"} value={value} setValue={setValue} />
const SelectReceiptPrinter = ({ value, setValue }) => <SelectPrinter title={"Receipt Printers"} value={value} setValue={setValue} />
const SalesSummaryPrinter = ({ value, setValue }) => <SelectPrinter title={"Sales Summary Printers"} value={value} setValue={setValue} />

const PRINT_TYPE = {
    KOT: "kot",
    BILL: "bill",
    RECEIPT: "receipt",
    SALES_SUMMARY: 'salessummary'
}
const REST_PRINT_TYPE = {
    KOT: 'kot',
    ALL_KOT: 'all-kot'
}

class MultiEscServerDriver extends ThermalPrinterDriver {

    async connect(params) {
        this.params = params;
    }
    async disconnect() { }

    async printSalesSummary(report) {
        const doc = await generateBitmap(<SalesSummaryPrint request={{
            ...report,
            __current_date: Util.getFullDate(new Date().getTime()),
            __opening_date: Util.getFullDate(report.openingDate),
            __closing_date: Util.isNumberExist(report.closingDate) ? Util.getFullDate(report.closingDate) : null
        }} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(doc)
        }

        await this.printBitmap(PRINT_TYPE.SALES_SUMMARY, doc);
    }

    async printCoupons(posSessionSnapshot, coupons) {
        for (const coupon of coupons) {
            const doc = await generateBitmap(<CouponPrint request={posSessionSnapshot} coupon={coupon} />)
            if (Util.isDebug()) {
                openBase64ImageInNewTab(doc)
            }

            await this.printBitmap(PRINT_TYPE.RECEIPT, doc);
        }
    }

    async printKot(order, kot, filterClasses, bottomText) {
        const request = {
            order, kot, filterClasses, bottomText,
        }
        await this.printKotRequest(REST_PRINT_TYPE.KOT, request)

        // const receipt = await generateBitmap(<KotPrint request={{
        //     order, kot, filterClasses, bottomText,
        //     __company_name: COMPANY_NAME.toUpperCase(),
        //     __current_date: Util.getFullDate(new Date().getTime()),
        //     __printed_by: getAccount().fullName
        // }} />)
        // if (Util.isDebug()) {
        //     openBase64ImageInNewTab(receipt)
        // }

        // await this.printBitmap(PRINT_TYPE.KOT, receipt);
    }

    async printAllKots(order, filterClasses, bottomText) {
        const request = {
            order, filterClasses, bottomText,
        }
        await this.printKotRequest(REST_PRINT_TYPE.ALL_KOT, request)

        // const receipt = await generateBitmap(<AllKotPrint request={{
        //     order, filterClasses, bottomText,
        //     __company_name: COMPANY_NAME.toUpperCase(),
        //     __current_date: Util.getFullDate(new Date().getTime()),
        //     __printed_by: getAccount().fullName
        // }} />)
        // if (Util.isDebug()) {
        //     openBase64ImageInNewTab(receipt)
        // }

        // await this.printBitmap(PRINT_TYPE.KOT, receipt);
    }

    async printBill(order, paymentMethods) {
        const session = await Api.asyncGetSalesOrderPosSession(order.id);

        // const props = {
        //     order, request: {
        //         ...session,
        //         paymentMethods,
        //         __company_name: COMPANY_NAME.toUpperCase(),
        //         __current_date: Util.getFullDate(new Date().getTime()),
        //     }
        // }
        // console.log(JSON.stringify(props))

        const receipt = await generateBitmap(<BillPrint order={order} request={{
            ...session,
            paymentMethods,
            __company_name: COMPANY_NAME.toUpperCase(),
            __current_date: Util.getFullDate(new Date().getTime()),
        }} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(receipt)
        }

        await this.printBitmap(PRINT_TYPE.BILL, receipt)
    }

    async printReceipt(posSessionSnapshot) {
        const receipt = await generateBitmap(<ArabicPosReceipt request={{
            ...posSessionSnapshot,
            __company_name: COMPANY_NAME.toUpperCase(),
            __current_date: Util.getFullDate(new Date().getTime()),
            __voucher_no: Util.getVoucherNumber(posSessionSnapshot.lastStockFlowId)
        }} />)
        if (Util.isDebug()) {
            if (posSessionSnapshot._transient_param_coupons?.length) {
                await this.printCoupons(posSessionSnapshot, posSessionSnapshot._transient_param_coupons);
            }
        }
        await this.printBitmap(PRINT_TYPE.RECEIPT, receipt);

        if (posSessionSnapshot._transient_param_coupons?.length) {
            await this.printCoupons(posSessionSnapshot, posSessionSnapshot._transient_param_coupons);
        }
    }

    async printRestCartQr(cart) {
        const doc = await generateBitmap(<RestCartQrPrint cart={cart} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(doc)
        }
        await this.printBitmap(PRINT_TYPE.BILL, doc)
    }

    async printRestBusinessSummary(report) {
        const doc = await generateBitmap(<RestBusinessSummary type={"thermal"} report={report} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(doc)
        }
        await this.printBitmap(PRINT_TYPE.SALES_SUMMARY, doc);
    }

    async printKotRequest(kotPrintType, request) {
        const printer = await this.selectPrinter(PRINT_TYPE.KOT);
        if (!printer) {
            return;
        }

        await Api.asyncPrintRestKot(kotPrintType, printer.name, request)
    }

    async printBitmap(type, bitmap) {
        const printer = await this.selectPrinter(type);
        if (!printer) {
            return;
        }

        await Api.asyncMultiEscDocumentPrint(printer.name, bitmap);
    }

    async selectPrinter(type) {
        const getDefaultPrinters = () => {
            switch (type) {
                case PRINT_TYPE.KOT: return this.params.kotPrinters ?? [];
                case PRINT_TYPE.BILL: return this.params.billPrinters ?? [];
                case PRINT_TYPE.RECEIPT: return this.params.receiptPrinters ?? [];
                case PRINT_TYPE.SALES_SUMMARY: return this.params.salesSummaryPrinters ?? [];
            }

            return [];
        }
        const getPrinters = async () => {
            const defaultPrinters = getDefaultPrinters();
            if (defaultPrinters?.length) {
                return defaultPrinters;
            } else {
                return await Api.asyncGetAvailableMultiEscPrinters()
            }
        }
        const selectPrinter = async (printers) => {
            if (printers.length === 1) {
                return printers[0];
            } else {
                return await UIUtil.listPrompt("Select Printer", printers.map($ => ({ id: $.id, value: $.title ?? $.value, name: $.name })))
            }
        }

        const printers = await getPrinters();
        const selectedPrinter = await selectPrinter(printers);

        return selectedPrinter;
    }

    hasCashDrawerAccess() {
        return false;
    }

    isConnectionBased() {
        return false;
    }

    canPrintSalesSummary() {
        return true;
    }

    canPrintRestaurantDocs() {
        return true;
    }

    getParams() {
        return [
            {
                property: "kotPrinters",
                name: "KOT Printers",
                node: SelectKotPrinter
            },
            {
                property: "billPrinters",
                name: "Bill Printers",
                node: SelectBillPrinter
            },
            {
                property: "receiptPrinters",
                name: "Receipt Printers",
                node: SelectReceiptPrinter
            },
            {
                property: "salesSummaryPrinters",
                name: "Sales Summary Printers",
                node: SalesSummaryPrinter
            }
        ]
    }

}

export default MultiEscServerDriver;