import { UserSpeaker24, Add16, Wallet16, Scale16, Launch16, NotebookReference16, Locked16, Unlocked16, Renew16 } from '@carbon/icons-react'
import React, { useState } from 'react'
import ProfilePic from '../../../components/ProfilePic'
import Button from '../../../components/Button'
import { ComposedModal, ModalBody, ModalHeader } from 'carbon-components-react'
import { getAccount } from '../../../session/SessionManager'
import Api from '../../../session/Api'
import { OBJECT_TYPE_ACCOUNT } from '../../../constants/ObjectTypes'

export default ({state, light, hideLayoutOptions}) => {
    return (
        <div className="dashboard-card" style={{width: '100%', height: '100%', background: light ? 'white' : '#f4f4f4', padding: '1rem', display: 'flex', alignItems: 'center'}}>
            <div style={{display: 'flex', marginTop: '0rem', alignItems: 'center', width: '100%'}}>
                <ProfilePic size={45} style={{marginRight: '1rem'}} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, state.getSession().operatorId)} />
                <div>
                    <p style={{fontStyle: 'bold'}}>{state.getSession().operatorName}</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <p style={{fontSize: 12, opacity: 0.65}}>
                            POS Operator
                        </p>
                    </div>
                </div>
                <div style={{flex: 1}} />

                {!hideLayoutOptions && <>
                    {/* <Button 
                    style={{height: 40}}
                    kind="ghost"
                    onClick={() => state.restartPosLayout()}
                    data-tip="Restore Layout Default"
                    renderIcon={Renew16} hasIconOnly /> */}
                    {/* <div style={{width: 5}} /> */}

                    <Button 
                    style={{height: 40}}
                    kind={state.getState().posEditMode ? "secondary" : "ghost"}
                    onClick={() => state.togglePosEditMode()}
                    data-tip={state.getState().posEditMode ? "Stop Edit Mode" : "Start Edit Mode"}
                    //renderIcon={!state.getState().posEditMode ? Locked16 : Unlocked16} 
                    renderIcon={Scale16}
                    hasIconOnly />
                    {/* <div style={{width: 5}} /> */}
                </>}

                

                <Button 
                style={{height: 40}}
                loading={state.isLoadingPosAction()}
                onClick={() => state.setShowPosInfoDialog(true)}
                data-tip="Session Management"
                renderIcon={NotebookReference16} hasIconOnly />
            </div>
        </div>
    )
}