import { ButtonSet, ComboBox, ComposedModal, ModalBody, ModalFooter, ModalHeader, Select, SelectItem } from "carbon-components-react";
import {
    ErrorFilled16, CheckmarkFilled16, WarningFilled16, Tools16
} from '@carbon/icons-react'
import Button from "../../../components/Button";
import Api from "../../../session/Api";
import { OBJECT_TYPE_PRODUCT } from "../../../constants/ObjectTypes";
import ProfilePic from "../../../components/ProfilePic";
import { useEffect, useMemo, useState } from "react";
import Util from "../../../util/Util";


const GroupSelection = ({ options, selection, setSelection, hasBottomMargin, hasPercentage, hasDelete, onRemove }) => {
    const optionId = selection?.optionId ?? -1;
    const percentage = selection?.percentage ?? 100;


    useEffect(() => {
        setSelection(prev => ({ ...prev, percentage: undefined }))
    }, [optionId])

    const nonStandardPercentage = ![10, 20, 30, 40, 50, 60, 70, 80, 90, 100].includes(percentage)

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: hasBottomMargin ? '0.5rem' : 0 }}>
            <div style={{ flex: 1 }}>
                <Select style={{ background: '#f4f4f4' }} labelText="Option" value={optionId} onChange={e => setSelection(prev => ({ ...prev, optionId: parseInt(e.target.value) }))}>
                    <SelectItem value={-1} text="Select Option" />
                    {options.map(option => <SelectItem key={option.id} value={option.id} text={option.name} />)}
                </Select>
            </div>
            {hasPercentage &&
                <div style={{ width: 100 }}>
                    <Select style={{ background: '#f4f4f4' }} disabled={optionId === -1} labelText="Percentage" value={percentage} onChange={e => setSelection(prev => ({ ...prev, percentage: parseInt(e.target.value) }))}>
                        {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(interval => (
                            <SelectItem key={'interval-' + interval} value={interval} text={interval + '%'} />
                        ))}
                        {nonStandardPercentage && <SelectItem key={'interval-' + 'non-standard-value'} value={percentage} text={percentage.toFixed(2) + '%'} />}
                    </Select>
                </div>}
            {hasDelete &&
                <Button disabled={onRemove === undefined} onClick={onRemove} kind="danger" renderIcon={ErrorFilled16} hasIconOnly iconDescription="Remove selection" width={40} height={40} style={{ alignSelf: 'flex-end' }} />}
        </div>
    )
}

const MultiGroupSelection = ({ options, multiSelection, setMultiSelection }) => {
    //const [selections, setSelections] = useState(() => [...(multiSelection.options ?? []), { optionRecordId: Util.newTempId() }]);
    const selections = multiSelection.optionRecords ?? [];
    const setSelections = arg => setMultiSelection(prev => {
        const value = arg(prev.optionRecords ?? []);
        return { ...prev, optionRecords: value }
    });

    const selection = (optionRecordId) => selections.find(selection => selection.optionRecordId === optionRecordId) ?? { optionRecordId }
    const setSelection = optionRecordId => arg => {
        setSelections(prev => {
            const index = prev.findIndex(selection => selection.optionRecordId === optionRecordId);
            const value = arg(prev[index] ?? { optionRecordId })
            const newArr = [...prev];
            newArr[index] = value;
            return newArr;
        })
    }
    const removeSelection = optionRecordId => () => {
        setSelections(prev => prev.filter(selection => selection.optionRecordId !== optionRecordId))
    }

    useEffect(() => {
        const lastItem = selections[selections.length - 1];
        if (lastItem && lastItem.optionId !== undefined && lastItem.optionId !== null) {
            setSelections(prev => [...prev, { optionRecordId: Util.newTempId() }])
        }
    }, [selections])

    const totalPercentage = selections.filter(selection => Util.isNumberExist(selection.optionId))
        .map(selection => selection.percentage ?? 100)
        .reduce((t, c) => t + c, 0)
    const isHunderedPercent = totalPercentage === 100;

    const makeHunderedPercent = () => {
        //(3 / 100)* 100
        const totalItems = selections.filter(selection => Util.isNumberExist(selection.optionId)).length;
        const percentage = 100 / totalItems;
        setSelections(prev => prev.map(selection => Util.isNumberExist(selection.optionId) ? ({
            ...selection,
            percentage
        }) : selection))
    }

    return (
        <div style={{}}>
            {/* <pre><code>{JSON.stringify(selections, null, 2)}</code></pre> */}
            <div style={{ borderRadius: 5, background: '#1c1c1c', color: 'white', marginTop: '0.5rem', marginBottom: '1rem', paddingLeft: '1rem', paddingRight: '0.5rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>Total percentage</p>
                <WarningFilled16 style={{ color: isHunderedPercent ? 'green' : 'red' }} />
                <p style={{ color: isHunderedPercent ? 'green' : 'red' }}>{totalPercentage.toFixed(2) + ''}%</p>

                {!isHunderedPercent && <Button onClick={makeHunderedPercent} renderIcon={Tools16} size="sm" style={{ marginLeft: '0.5rem', borderRadius: 15 }}>Make 100%</Button>}
            </div>
            {selections.map((optionRecord, index) => (
                <GroupSelection key={selection.optionRecordId} onRemove={index === selections.length - 1 ? undefined : removeSelection(optionRecord.optionRecordId)}
                    options={options} selection={selection(optionRecord.optionRecordId)} setSelection={setSelection(optionRecord.optionRecordId)}
                    hasPercentage hasDelete hasBottomMargin={index !== (selections.length - 1)} />
            ))}
        </div>
    )
}

export function ProductionBundleGroupSelections({
    item, value: selections, setValue: setSelections
}) {
    // const [selections, setSelections] = useState([]);
    const selection = (groupId) => selections?.find(selection => selection.groupId === groupId) ?? { groupId }
    const setSelection = groupId => arg => {
        setSelections(prev => {
            const value = arg(prev?.find(selection => selection.groupId === groupId) ?? { groupId })
            return [
                ...prev ? prev.filter(selection => selection.groupId !== groupId) : [],
                value
            ]
        })
    }


    useEffect(() => {
        if (item) {
            setSelections(item.groups.filter(group => group.allowsDivision).map(group => ({ groupId: group.id, optionRecords: [{ optionRecordId: Util.newTempId() }] })))
        } else {
            setSelections([]);
        }
    }, [item])

    return <>
        {item.groups.map(group => (
            <div key={group.id} style={{ background: 'white', marginTop: '0.5rem', borderRadius: 10, padding: '1rem', borderWidth: 1, borderColor: '#00000020', borderStyle: 'solid', }}>
                <h6>{group.name}</h6>
                {group.allowsDivision ? (
                    <MultiGroupSelection options={group.options} multiSelection={selection(group.id)} setMultiSelection={setSelection(group.id)} />
                ) : (
                    <GroupSelection options={group.options} selection={selection(group.id)} setSelection={setSelection(group.id)} />
                )}
            </div>
        ))}
    </>
}