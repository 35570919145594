import { useEffect, useState } from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { classExistsInSomeParent } from "../pages/label-designer/util/ParentFinder";

export const ButtonPopover = ({ parentRef, children, popover, invalidateOpen, shouldBeInvisible, openListener, clearTopLeftRadius, clearBottomRightRadius, reversed, customPos, customAlign }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [invalidateOpen])

    useEffect(() => {
        if (openListener) {
            openListener(open);
        }
    }, [openListener, open])

    return (
        <Popover
            isOpen={open}
            //positions={['bottom', 'left', 'right', 'top']}
            positions={customPos ? customPos : ['right', 'top', 'bottom', 'left',]}
            // positions={['left']}
            // padding={10}
            parentElement={parentRef?.current}
            containerStyle={{ zIndex: 1000000 }}
            align={customAlign ? customAlign : "start"}
            onClickOutside={e => {
                if (!classExistsInSomeParent(e.target, "bx--modal")) {
                    setOpen(false)
                }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className={`popover-arrow-container ${reversed ? 'user-assignation-button-pop-over-root-reversed' : 'user-assignation-button-pop-over-root'}`}
                    arrowClassName='popover-arrow'
                    style={{ display: shouldBeInvisible ? 'none' : undefined }}
                >
                    <div
                        className="user-assignation-button-pop-over"
                        style={{ borderTopLeftRadius: clearTopLeftRadius ? 0 : undefined, borderBottomRightRadius: clearBottomRightRadius ? 0 : undefined }}
                    // onClick={() => setOpen(p => !p)}
                    >
                        {popover}
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                {children}
            </a>
        </Popover>
    )
}