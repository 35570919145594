import Util from "../../../util/Util";


export function selectionsToGroupToProductMap(item, selections) {
    return selections.map(selection => selection.optionRecords ? ({
        groupId: selection.groupId,
        divisionRecords: selection.optionRecords.filter(record => Util.isNumberExist(record.optionId)).map(record => ({
            //...record, 
            productId: record.optionId,
            productType: item.groups.find(group => group.id === selection.groupId).options.find(option => option.id === record.optionId).type,
            percentage: record.percentage ?? 100
        }))
    }) : ({
        groupId: selection.groupId,
        productId: selection.optionId,
        productType: item.groups.find(group => group.id === selection.groupId).options.find(option => option.id === selection.optionId).type,
    }))
}