
import { useEffect, useState } from "react"
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import { useListFilter } from "../../engine/useValueFilter";

const createStyle = style => (baseStyles) => ({
    ...baseStyles,
    ...style
})

const DEF_VALUE = []

export function ProductFilter({ state, options }) {
    const [filters, setFilters] = useListFilter(state, 'productMergeProductIds', true)
    const [value, setValue] = state.use('productMergeProductIds', DEF_VALUE)

    useEffect(() => {
        setFilters(value.map(item => ({ property: 'itemId', operator: "EQ", value: item.value })))
    }, [value])

    return (
        <Select
            value={value}
            onChange={setValue}
            placeholder="Products"
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    minHeight: 30,
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: '30px',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 5,
                    background: '#ffffff10',
                    border: '1px solid #00000099',
                    color: 'white',
                    minHeight: 30,
                    minHeight: 30,
                    padding: 0
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white',
                    minHeight: 30,
                    margin: 0
                }),
                multiValue: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: 'white'
                }),
                multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: '#ffffffBB'
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: '30px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                menu: createStyle({
                    background: '#1c1c1c'
                }),
                option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
                    ...baseStyles,
                    backgroundColor: isFocused ? '#ffffff20' : isSelected ? '#ffffff40 !important' : 'transparent'
                })
                // menuList: createStyle({
                //     background: 'red'
                // }),
                // menuPortal: createStyle({
                //     background: 'red'
                // })
                // multiValueRemove: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
                // dropdownIndicator: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
                // indicatorSeparator: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
            }}
            isMulti options={options.map(opt => ({ value: opt.id, label: opt.value }))} />
    )
}