import React from 'react';
import { withRouter } from 'react-router-dom';
import { OBJECT_TYPE_DESIGNATION, OBJECT_TYPE_LEAVE_TYPE, OBJECT_TYPE_PAY_HEAD } from '../../../constants/ObjectTypes';
import Page from '../../../base/Page';
import TableTemplate from '../../../templates/TableTemplate';

class PayHeadListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Pay Heads",
                    subTitle: "All pay heads",
                    objectType: OBJECT_TYPE_PAY_HEAD,
                    pagePath: "/pay-heads/",
                    history: this.props.history
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(PayHeadListPage);