import { Accordion, AccordionItem } from "carbon-components-react"
import { makeSettingComponent } from ".."


const RequireClearanceCodePostingStockAdjustment = makeSettingComponent('require_clearance_code_posting_stock_adjustment')
const RequireClearanceCodeDeletingStockAdjustment = makeSettingComponent('require_clearance_code_deleting_stock_adjustment')

export default ({ settingsValues }) => {
    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="Require clearance code when posting stock adjustment">
                <RequireClearanceCodePostingStockAdjustment />
            </AccordionItem>
            <AccordionItem title="Require clearance code when deleting draft stock adjustment">
                <RequireClearanceCodeDeletingStockAdjustment />
            </AccordionItem>
        </Accordion>
    )
}