import {
    Chat32
} from '@carbon/icons-react'
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_LEAD } from '../../../../constants/ObjectTypes'
import { CommentView } from '../../../../views/comment/comment-view'

export function Comments({ leadId }) {
    return (
        <div style={{ height: 600, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', padding: '1rem' }}>
                <Chat32 style={{ width: 32, height: 32 }} />
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>Comments</h4>
            </div>
            <div style={{ overflow: 'auto', height: '100%', }}>
                <CommentView itemId={leadId} itemType={OBJECT_TYPE_LEAD} noGapInSendBox />
            </div>
        </div>
    )
}