import React from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_MOVIE_DISTRIBUTOR } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import TransactionListView from '../../transaction/TransactionListView';
import AllSubsidiaryReportView from '../../accounting-reports/AllSubsidiaryReportView';

const OBJECT_TYPE = OBJECT_TYPE_MOVIE_DISTRIBUTOR;

const SubsidiaryReport = ({ item }) => {
    return <AllSubsidiaryReportView itemId={item.id} itemType={OBJECT_TYPE} />
}

const TransactionsView = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <TransactionListView partyList={{ id: item.id, type: OBJECT_TYPE }} />
        </div>
    )
}


const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                    {
                        title: "Ledger Report",
                        component: SubsidiaryReport
                    },
                    {
                        title: "Transactions",
                        component: TransactionsView
                    }
                ]
            })}
        </div>
    )
})

export const MovieDistributorDetailPage = Page;
