import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_SEATING_PLAN } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import { BoardViewPage } from '../../board-view/board-view-page';
import { SeatDesigner } from '../../../views/cinema/seat-designer';

const Dummy = () => <div />
const OBJECT_TYPE = OBJECT_TYPE_SEATING_PLAN;



const SeatPlanDesigner = ({ item }) => {
    return (
        <div style={{ width: '100vw', minHeight: 'calc(100vh - 10rem)', height: 'calc(100vh - 10rem)' }}>
            <SeatDesigner itemId={item.id} />
        </div>
    )
}


const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    const [tab, setTab] = useState(0);
    return (
        ItemTemplate.renderTemplate({
            objectType: OBJECT_TYPE,
            itemResult: itemResult,
            noPadding: true,
            pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
            history: history,
            onTabChanged: setTab,
            customTabs: [
                {
                    title: "Designer",
                    component: tab === 1 ? SeatPlanDesigner : Dummy
                },
            ]
        })
    )
})

export const SeatingPlanDetailPage = Page;
