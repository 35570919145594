import React, { useEffect, useRef } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useRefSize = () => {
    const target = useRef();
    const [size, setSize] = React.useState({ width: 0, height: 0 })

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return [target, size]
}

const useSize = (target) => {
    const [size, setSize] = React.useState({ width: 0, height: 0 })

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

export default (target) => {
    const [size, setSize] = React.useState({ width: 0, height: 0 })

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}


export const useDyamicRefSize = (enable) => {
    const target = useRef();
    const [size, setSize] = React.useState({ width: 0, height: 0 })

    React.useLayoutEffect(() => {
        if (enable) {
            setSize(target.current.getBoundingClientRect())
        }
    }, [target, enable])

    // Where the magic happens
    useResizeObserver(target, (entry) => {
        if (enable) {
            setSize(entry.contentRect)
        }
    })
    return [target, size]
}

export function SizeGetter({ target, onSizeChange }) {
    const size = useSize(target);
    useEffect(() => {
        onSizeChange(size)
    }, [size])
    return <div />
}
