import {
    Filter24
} from '@carbon/icons-react'

export function FilterView({ filter, setFilter }) {
    return (
        <div className="calendar-filter-pop-over">
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <Filter24 />
                <h4>Filtering</h4>
            </div>


        </div>
    )
}