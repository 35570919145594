import React from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_FNB_EVENT_MENU } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import { MenuDesigner } from '../components/menu-designer-c';
import { Printer16 } from '@carbon/icons-react'
import Button from '../../../components/Button';

const OBJECT_TYPE = OBJECT_TYPE_FNB_EVENT_MENU;


function Options({ item }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1rem', gap: '0.5rem' }}>
            {/* <Button renderIcon={Printer16}>Print Menu</Button> */}
        </div>
    )
}


const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();

    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                    {
                        title: 'Menu Designer',
                        component: MenuDesigner
                    }
                ],
                hasCustomBtn: (
                    <Options item={itemResult.item} />
                )
            })}
        </div>
    )
})

export const FnbEventMenuDetailPage = Page;
