import React from 'react';

export default function UploadWrapper(props) {
    const inputRef = React.createRef();
    return (
        <>
            <span>
                {React.cloneElement(props.children, { onClick: e => inputRef.current.click() })}
            </span>

            <input accept={props.accept} id={props.id} ref={inputRef} type="file" 
                style={{display: 'none'}} onClick={props.onClick} onChange={(e) => {props.onChange(e); e.target.value = "";}} />
        </>
    )
}