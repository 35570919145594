import { ArrowContainer, Popover } from "react-tiny-popover";
import {
    CaretDown16
} from '@carbon/icons-react'
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import { Tag } from "carbon-components-react";
import { UserAssignation } from "../../../pages/activities/components/user-assignation";
import { classExistsInSomeParent } from "../../../pages/label-designer/util/ParentFinder";

const ButtonPopover = ({ children, popover, invalidateOpen }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [invalidateOpen])

    return (
        <Popover
            isOpen={open}
            positions={['bottom', 'right']}
            containerStyle={{ zIndex: 1000000 }}
            align="end"
            onClickOutside={e => {
                if (!classExistsInSomeParent(e.target, "user-assignation-button-pop-over-root")) {
                    setOpen(false)
                }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container to-do-board-button-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="to-do-board-button-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        {popover}
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                {children}
            </a>
        </Popover>
    )
}

export function BoardViewAssignation({ allUsers, assignations, setAssignations, children }) {
    return (
        <ButtonPopover popover={
            <div style={{ width: 400, padding: '1rem', height: 300, overflow: 'auto' }}>
                <h5>Assigned Users</h5>
                <UserAssignation allUsers={allUsers} title="Assign Users" value={assignations} onValueChanged={setAssignations} />
            </div>
        }>
            {children}
        </ButtonPopover>
    )
}