import { ComboBox, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, RadioTile, TileGroup } from "carbon-components-react";
import React, { useEffect, useState } from "react"
import { hasCapabilitySupport } from "../../app/Capabilities";
import Button from "../../components/Button";
import { SOURCE_TYPE_MOBILE_UNIT, SOURCE_TYPE_STORE, SOURCE_TYPE_WAREHOUSE } from "../../constants/Constants";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";

import {
    DataVis_232,
    Store32,
    Van32,

    ErrorFilled16,
} from '@carbon/icons-react'

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)


export default ({ open, onClose, stockRequestId, onUpdateStockRequest }) => {
    const [loading, setLoading] = useState(false);
    const [endpointsList, setEndpointsList] = useState();

    const [sourceType, setSourceType] = useState(SOURCE_TYPE_WAREHOUSE)
    const [sourceId, setSourceId] = useState(0)
    const [sourceIdPickerKey, setSourceIdPickerKey] = useState(Util.newTempId())

    useEffect(() => {
        setSourceId(0)
        setSourceIdPickerKey(Util.newTempId())
    }, [sourceType])

    useEffect(() => {
        setLoading(true);
        Api.getStockFlowEndpointsList(response => {
            if (response.status === true) {
                setSourceType(SOURCE_TYPE_WAREHOUSE);

                setEndpointsList(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message);
                onClose();
            }
        })
    }, [open])

    const onBtn = () => {
        setLoading(true);
        Api.approveStockRequest(stockRequestId, sourceType, sourceId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onClose();
                onUpdateStockRequest(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    const getSourceName = () => {
        switch (sourceType) {
            case SOURCE_TYPE_WAREHOUSE:
                return "Warehouse";
            case SOURCE_TYPE_STORE:
                return "Store";
            case SOURCE_TYPE_MOBILE_UNIT:
                return "Mobile Unit"
        }
    }

    const getSourceList = () => {
        if (!endpointsList) {
            return []
        }

        switch (sourceType) {
            case SOURCE_TYPE_WAREHOUSE:
                return endpointsList.warehouses
            case SOURCE_TYPE_STORE:
                return endpointsList.stores
            case SOURCE_TYPE_MOBILE_UNIT:
                return endpointsList.mobileUnits
        }
    }

    return (
        <ComposedModal open={open} onClose={onClose} className="modal-that-supports-combo-box">
            <ModalHeader label="Stock Request Approval" title="Finalize Request" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                {loading ? (
                    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45 }}>
                        <InlineLoading style={{ width: 'unset' }} />
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
                    </div>

                ) : (
                    <div>
                        <label className="bx--label" style={{}}>Stock source</label>
                        <TileGroup className={"horizontal-tile-radio"} valueSelected={sourceType} onChange={value => setSourceType(value)}>
                            <RadioTile light value={SOURCE_TYPE_WAREHOUSE}>
                                <RadioItem icon={DataVis_232} title="Warehouse" desc="Send stock from warehouse" />
                            </RadioTile>
                            <RadioTile light value={SOURCE_TYPE_STORE}>
                                <RadioItem icon={Store32} title="Store" desc="Send stock from store" />
                            </RadioTile>
                            {hasCapabilitySupport("mobileUnit") &&
                                <RadioTile light value={SOURCE_TYPE_MOBILE_UNIT}>
                                    <RadioItem icon={Van32} title="Mobile unit" desc="Send stock from mobile unit" />
                                </RadioTile>}
                        </TileGroup>
                        <div style={{ height: '1rem' }} />

                        <ComboBox
                            key={sourceIdPickerKey}
                            light
                            titleText={getSourceName()}
                            items={getSourceList()}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={getSourceList().filter(item => item.id == sourceId)[0]}
                            //initialSelectedItem={this.state[stateValue(field.property)]}
                            //onChange={e => this.setState({sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0})} 
                            onChange={e => {
                                setSourceId(e.selectedItem !== null ? e.selectedItem.id : 0)
                                setSourceIdPickerKey(Util.newTempId())
                            }}
                        />
                        <div style={{ height: '1rem' }} />
                    </div>
                )}

            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    loading={loading}
                    disabled={sourceId <= 0}
                    onClick={onBtn}
                >
                    Confirm
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}