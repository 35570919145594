

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

// import Logo from '../../../images/SquareLogo.png';
import Logo from '../../../images/other/rivas/logo.png';
import ReceiptLogo from '../../../images/ReceiptLogo.png'

import QRCode from "react-qr-code"
import Util from "../../../util/Util"
import { paymentMethodTextWithArabic } from "../../../pages/transaction/TransactionEditor"
import { COMPANY_NAME, THERMAL_RECEIPT_LOGO } from '../../../app/Config';
import { POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';
import { isAbshar, isAnyAbshar, isRivas } from '../../../app/app-util';


const Line = () => <br />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>

const Text = ({ bold, italic, size, center, right, underline, children }) => <p style={{
    fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
    textDecoration: underline ? "underline" : undefined,
    fontStyle: italic ? "italic" : undefined,
    whiteSpace: 'pre-wrap'
}}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={20}>{children}</Text>
const Italic = ({ children }) => <Text italic size={20}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={20}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={20}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={20}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={20}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={20}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={20}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={20}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={20}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>
const LgCenter = ({ children }) => <Text center size={30}>{children}</Text>
const DoubleCenter = ({ children }) => <Text center size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={20}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>
const FullDivider = () => <div style={{ width: '100%', height: 4, background: 'black' }} />

export const RestCartQrPrint = ({ cart }) => {
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            <Line />
            <Line />
            {COMPANY_NAME === "Rivas" && <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={Logo} style={{ width: 268, height: 108, objectPosition: 'center', objectFit: 'contain', }} />
                </div>
            </>}

            <Line />
            <Line />
            <FullDivider />
            <Text center size={25}>TABLE {cart.tableName}</Text>
            <FullDivider />
            <Line />
            <Line />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <QRCode size={250} value={cart.href} />
            </div>
            <Line />

            <Line />
            <DoubleCenter>Scan to manage and pay your order!</DoubleCenter>

            <Line />
            <Line />

            {isRivas() && <>
                <Text center size={25}>© rivas.ae</Text>
            </>}
            {isAnyAbshar() && <>
                <Text center size={25}>© grandabshar.ae</Text>
            </>}
            <Text center size={25}>Printed on {Util.formatDate(new Date().getTime(), "DD.MMM hh:mm A")}</Text>

            <Text center size={20}>Powered by RoboticERP.com</Text>


            <Line />
            <Line />
        </div>
    )

}
