import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { DISCOUNT_CUSTOM_LIST_PRODUCTS, PRODUCT_BUNDLE_CUSTOM_LIST_PARENT, SERVICE_PACKAGE_CUSTOM_LIST_PARENT, STOCK_TYPE_DISCRETE, TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOM_PRICE, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_PACKAGE_UNIT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_ATTRIBUTES, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_GROUP, OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_PACKAGE } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import UIUtil from '../../util/UIUtil';
import StockFlowListView from '../stock-flow/StockFlowListView';
import TransactionListView from '../transaction/TransactionListView';
// import ProductAttributesView from './ProductAttributesView';




const ServicePackagesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                getCustomListRequest: {
                    requestType: SERVICE_PACKAGE_CUSTOM_LIST_PARENT,
                    params: {
                        parentId: item.id,
                    }
                },
                customToolbarContent: (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', flex: 1 }}>
                        <p>Packages containing this service</p>
                    </div>
                ),
                hideCreateButton: true,
                embedded: true,
                title: "Service Packages",
                subTitle: "Packages containing this service",
                objectType: OBJECT_TYPE_SERVICE_PACKAGE,
                pagePath: "/service-packages/",
                history: history
            })}
        </div>
    )
}



class ServiceDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_SERVICE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_SERVICE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_SERVICE,
                    itemResult: this.state.itemResult,
                    pagePath: "/services/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Packages",
                            component: ServicePackagesList
                        },
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(ServiceDetailPage);