import { ComboBox } from "carbon-components-react"
import Util from "../util/Util"
import { useEffect, useState } from "react"
import { useForm } from "../templates/form/form"

export const DataListField = ({ title, light, placeholder, help, options, value, setValue, skipMargin, height }) => {
    const { store, ...form } = useForm(store => {
        store.set('value', value)
    }, { light, replaceComboBoxWithDataList: true, })

    if (store.get('value') !== value) {
        store.set('value', value)
    }

    return <form.ComboBoxField _skipFormMargin={skipMargin} __data_list_height={height} fieldKey="value" title={title} placeholder={placeholder} help={help} options={options} onValueSet={setValue} />
}

export const ComboBoxField = ({ title, light, placeholder, help, options, value, setValue }) => {
    const [key, setKey] = useState(() => Util.newTempId())

    useEffect(() => {
        if (!value) {
            setKey(Util.newTempId())
        }
    }, [value])

    return (
        <ComboBox
            light={light}
            key={key}
            titleText={title}
            items={options}
            helperText={help}
            placeholder={placeholder}
            itemToString={item => item !== null ? item.value : ""}
            selectedItem={options.find(option => option.id === value)}
            onChange={e => setValue(e.selectedItem ? e.selectedItem.id : null)} />
    )
}


export const ObjectComboBoxField = ({ title, light, placeholder, help, options, value, setValue }) => {
    const [key, setKey] = useState(() => Util.newTempId())

    useEffect(() => {
        if (!value) {
            setKey(Util.newTempId())
        }
    }, [value])

    return (
        <ComboBox
            light={light}
            key={key}
            titleText={title}
            items={options}
            helperText={help}
            placeholder={placeholder}
            itemToString={item => item !== null ? item.value : ""}
            selectedItem={value}
            onChange={e => setValue(e.selectedItem)} />
    )
}
