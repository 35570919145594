import { TextArea, TextInput } from "carbon-components-react";
import { useCallback, useMemo, useState } from "react";
import Button from "../../components/Button";
import { InfiniteList } from "../../components/infinite-list";
import Api from "../../session/Api";
import { createSendComment, useLoader } from "./comment-controller";
import { CommentItem } from "./comment-item";
import { SendFilled16 } from '@carbon/icons-react';
import Util from "../../util/Util";
import { makeObservable } from "../../util/makeObservable";
import useStore from "../../hooks/useStore";

const SendBox = ({ itemType, itemId, store, noGapInSendBox }) => {
    const [_, setSentItems] = useStore(store, "sentItems");
    const [value, setValue] = useState("")

    const newComment = () => {
        setSentItems(items => [...items, createSendComment(itemType, itemId, value)])
        setValue("")
    }

    return (
        <div style={{ marginBottom: '0rem', display: 'flex', gap: noGapInSendBox ? 0 : '0.5rem' }}>
            <TextArea rows={1} size="lg" placeholder="Comment..." value={value} onChange={e => setValue(e.target.value)} />
            <Button onClick={newComment} size="md" disabled={!Util.isStringExists(value)} renderIcon={SendFilled16}>Comment</Button>
        </div>
    )
}

const SentItems = ({ store }) => {
    const [sentItems, setSentItems] = useStore(store, "sentItems");
    return (
        <div>
            {sentItems.map((item, index) => <CommentItem key={item.tempId} sendComment={item} lastItem={sentItems.length === index - 1} />)}
        </div>
    )
}

const Item = comment => <CommentItem key={comment.id} commentItem={comment} />;

export function CommentView({ itemType, itemId, noGapInSendBox }) {
    const loader = useLoader(itemType, itemId);
    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("sentItems", [])
        return observable;
    }, [itemType, itemId])
    return (
        <div className="dark-minimal-scroll-bar" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', }}>
            <div style={{ flex: 1, minHeight: 0 }}>
                <InfiniteList reverse loader={loader} component={Item}>
                    <SentItems store={store} />
                </InfiniteList>
            </div>
            <SendBox {...({ itemType, itemId, store, noGapInSendBox })} />
        </div>
    )
}