import { ContentSwitcher, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';
import Button from './Button';
import CodeEditorDialog from './code-editor/CodeEditorDialog';

const MetaTagRecord = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [name, setName] = useMultiInputState(input, 'name', onInputUpdate);
    const [content, setContent] = useMultiInputState(input, 'content', onInputUpdate);
    const [charset, setCharset] = useMultiInputState(input, 'charset', onInputUpdate);
    const [httpEquiv, setHttpEquiv] = useMultiInputState(input, 'httpEquiv', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <TextInput size="lg" labelText="name" value={name} onChange={e => setName(e.target.value)} />
            <TextInput size="lg" labelText="content" value={content} onChange={e => setContent(e.target.value)} />
            <TextInput size="lg" labelText="charset" value={charset} onChange={e => setCharset(e.target.value)} />
            <TextInput size="lg" labelText="http-equiv" value={httpEquiv} onChange={e => setHttpEquiv(e.target.value)} />

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const MetaTagEditor = ({ defaultItems, onUpdate }) => (
    <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={MetaTagRecord} />
)


const ScriptTagRecord = ({ input, onInputUpdate, nonExistent, onInputRemoved, openEditor }) => {
    const [mode, setMode] = useMultiInputState(input, "mode", onInputUpdate)
    const [src, setSrc] = useMultiInputState(input, 'src', onInputUpdate);
    const [script, setScript] = useMultiInputState(input, 'script', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div>
                <label className="bx--label">mode</label>
                <ContentSwitcher selectedIndex={mode == "SCRIPT" ? 1 : 0} size="lg" onChange={e => setMode(e.name)} style={{ alignSelf: 'flex-end', width: 200 }}>
                    <Switch name="SRC" text="Source" />
                    <Switch name="SCRIPT" text="Script" />
                </ContentSwitcher>
            </div>

            {mode === "SCRIPT" ? (
                <div style={{ height: 48, flex: 1, alignSelf: 'flex-end' }}>
                    <Button onClick={() => openEditor(script, setScript)}
                        renderIcon={Code16} style={{ width: '100%', maxWidth: 'unset', alignSelf: 'flex-end' }}>Edit Script</Button>
                </div>
            ) : (
                <TextInput size="lg" labelText="src" value={src} onChange={e => setSrc(e.target.value)} />
            )}
            {/* <TextInput size="lg" labelText="content" value={content} onChange={e => setContent(e.target.value)} />
            <TextInput size="lg" labelText="charset" value={charset} onChange={e => setCharset(e.target.value)} />
            <TextInput size="lg" labelText="http-equiv" value={httpEquiv} onChange={e => setHttpEquiv(e.target.value)} /> */}

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const ScriptTagEditor = ({ defaultItems, onUpdate }) => {
    const [editorVisible, setEditorVisible] = useState(false);
    const [editorValue, setEditorValue] = useState("");
    const valueSetter = useRef();

    const onValueChange = value => {
        if (valueSetter.current) {
            valueSetter.current(value);
        }
    }

    const openEditor = useCallback((value, setValue) => {
        valueSetter.current = setValue;
        setEditorValue(value)
        setEditorVisible(true)
    }, [])
    return (
        <>
            <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={ScriptTagRecord} openEditor={openEditor} />
            <CodeEditorDialog visible={editorVisible} onClose={() => setEditorVisible(false)} value={editorValue} onValueChange={onValueChange} />
        </>
    )
}