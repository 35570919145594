

export const RATING_G = 0;
export const RATING_PG = 1;
export const RATING_PG_13 = 2;
export const RATING_PG_15 = 3;
export const RATING_15_PLUS = 4;
export const RATING_18_PLUS = 5;
export const RATING_21_PLUS = 6;

export const movieRatingLabel = (value) => {
    switch (value) {
        case RATING_G:
            return "G";
        case RATING_PG:
            return "PG";
        case RATING_PG_13:
            return "PG13";
        case RATING_PG_15:
            return "PG15";
        case RATING_15_PLUS:
            return "15+";
        case RATING_18_PLUS:
            return "18+";
        case RATING_21_PLUS:
            return "21+";
    }

    return "";
}