
import {
    Money16
} from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'
import Util from '../util/Util'

export function CurrencyConvertedAmount({ currency, amount }) {
    if (!currency) {
        return Util.formatMoney(amount);
    }
    return <Tag type='green' size='sm'>{currency.code} {Util.formatMoney(amount)}</Tag>
}

export function CurrencyConvertionBanner({ currency }) {
    if (!currency) {
        return <div />
    }

    return (
        <div style={{
            borderRadius: 5, border: '1px solid #00990020', paddingBlock: '0.15rem', paddingBlock: '0.5rem', gap: '0.25rem', background: "darkgreen",
            display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
            color: 'white', marginBlock: '1rem'
        }}>
            <Money16 />
            <p style={{ fontSize: 14 }}>Converted Currency <strong>{currency.value}</strong></p>
        </div>
    )
}