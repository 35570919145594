import React from 'react'
import Page from '../../base/Page';
import LabelDesignerCanvas from './components/LabelDesignerCanvas';
import LabelDesignerHeader from './components/LabelDesignerHeader';
import LabelDesignerToolbar from './components/LabelDesignerToolbar';
import LevelDesignerComponents from './components/LevelDesignerComponents';
import LevelDesignerInspector from './components/LevelDesignerInspector';
import LabelDesignerState, { createTemplateDesignerEngineState } from './engine/LabelDesignerState';

import './LabelDesigner.scss';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { isMacOs } from 'react-device-detect';
import { TEMPLATE_TYPE_GENERIC, TEMPLATE_TYPE_PRODUCT_LABEL, TEMPLATE_TYPE_LABEL_GENERIC, TEMPLATE_TYPE_BUNDLE_LABEL } from './templates/Template';
import { exportTemplate } from './export/TemplateExporter';
import { Button, ButtonSet, ComposedModal, ModalBody, NumberInput, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react';

import { ErrorFilled16, Printer16 } from '@carbon/icons-react'

import UIUtil from '../../util/UIUtil'
import Util from '../../util/Util'
import ProductSelector from '../../views/product/ProductSelector';
import Api from '../../session/Api';

class LabelDesignerPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            showPrintDialog: false,
            printingItems: [],
            printingItemsSelectorKey: Util.newTempId(),
            printing: false
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getTemplate(this.getPathParams().templateId, listener), payload => {
            this.engine = new LabelDesignerState(() => this.state, this.setState.bind(this));
            return { ...createTemplateDesignerEngineState(payload) }
        })
    }

    componentDidMount() {
        this.onPageStart();
        document.addEventListener('keydown', this.keyDownListener);
        //it wont work if page is loaded directly, this needs to be called after the first click
        window.onbeforeunload = e => {
            if (this.engine && this.engine.changesMade()) {
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            } else {
                delete e['returnValue'];
            }
        };
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownListener);
        window.onbeforeunload = null;
    }

    renderDesigner() {
        return (
            <div style={{ display: 'flex', flex: 1, minHeight: 0 }}>
                <LevelDesignerComponents engine={this.engine} />
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', minWidth: 0 }}>
                    <LabelDesignerCanvas engine={this.engine} />
                    <LabelDesignerToolbar engine={this.engine} />
                </div>
                <LevelDesignerInspector engine={this.engine} />
            </div>
        )
    }

    keyDownListener = (event => {
        if (!this.engine) {
            return;
        }

        if (isMacOs ? event.metaKey : event.ctrlKey) {
            switch (event.key) {
                case 'z':
                    this.engine.undoHistory();
                    event.preventDefault();
                    break;
                case 'y':
                    this.engine.redoHistory();
                    event.preventDefault();
                    break;
            }
        }
    }).bind(this);

    onPrintBtn() {
        if (this.engine.getTemplate().type == TEMPLATE_TYPE_PRODUCT_LABEL || this.engine.getTemplate().type == TEMPLATE_TYPE_LABEL_GENERIC) {
            this.setState({ showPrintDialog: true, printingItemsSelectorKey: Util.newTempId(), printingItems: [] })
        } else {
            this.printTemplate([{ _templateCopyCount: 1 }]);
        }
    }

    printTemplate(items) {
        const loadOptions = {};
        if (this.engine.getTemplate().type == TEMPLATE_TYPE_PRODUCT_LABEL || this.engine.getTemplate().type == TEMPLATE_TYPE_LABEL_GENERIC) {
            loadOptions.loadProducts = true;
        } else if (this.engine.getTemplate().type == TEMPLATE_TYPE_BUNDLE_LABEL) {
            loadOptions.loadBundles = true;
        }

        this.setState({ printing: true })
        exportTemplate(
            this.engine.getState().canvasWidth, this.engine.getState().canvasHeight,
            this.engine.getTemplate().type, this.engine.canvasManager.getJSON(),
            items, loadOptions
        )
            .then(() => {
                this.setState({ printing: false })
                UIUtil.showSuccess();
            })
            .catch(e => {
                console.log(e)
                this.setState({ printing: false })
                UIUtil.showError();
            });
    }

    getLayout() {
        return (
            <div className="label-designer-root" style={{}}>
                {/* <button onClick={() => exportTemplate(this.engine.getState().canvasWidth, this.engine.getState().canvasHeight, TEMPLATE_TYPE_PRODUCT_LABEL, this.engine.canvasManager.getJSON())}>export</button> */}
                <LabelDesignerHeader engine={this.engine} loadingPrinting={this.state.printing} onPrintBtn={this.onPrintBtn.bind(this)} />
                <DndProvider backend={HTML5Backend}>{this.renderDesigner()}</DndProvider>

                <ComposedModal
                    key="save-state-dialog"
                    open={this.state.showPrintDialog}
                    onClose={() => this.setState({ showPrintDialog: false })}
                    size="lg">
                    <ModalHeader label="Template" title="Printing" />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        {(this.engine.getTemplate().type == TEMPLATE_TYPE_PRODUCT_LABEL || this.engine.getTemplate().type == TEMPLATE_TYPE_LABEL_GENERIC) &&
                            <ProductSelector
                                productFinderShowFakeTextFieldAtFirst
                                key={this.state.printingItemsSelectorKey} defaultItems={this.state.printingItems}
                                templateEditorMode onItemsUpdated={items => this.setState({ printingItems: items })}
                            />}
                    </ModalBody>
                    <ModalFooter>
                        <ButtonSet style={{ width: '100%' }}>
                            <Button kind="secondary" onClick={() => this.setState({ showPrintDialog: false })} renderIcon={ErrorFilled16}>
                                Cancel
                            </Button>
                            <Button disabled={this.state.printingItems.length == 0} onClick={() => this.printTemplate(this.state.printingItems)} renderIcon={Printer16} loading={this.state.printing}>
                                Print
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>
            </div>
        )
    }

    isPageLoadable() {
        return true;
    }

}

export default LabelDesignerPage;