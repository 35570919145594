import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";
import { TextBox } from "../../components/basic-filter/text-box";

export const SalesReportDetail = {
    filter: Filter,

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },
    freezeCol: 2
}

function Filter({ endpoint, state }) {
    return (<>
        <TextBox state={state} label="Voucher No" property="voucherNo" operator="EQ" />
    </>)
}
