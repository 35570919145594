import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    User32,
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    CheckmarkOutline16,
    CheckmarkFilled16,
    ErrorFilled16,
    Wallet16
} from '@carbon/icons-react'
import { TileGroup, RadioTile, TextArea, ComboBox, ButtonSet, Switch, ContentSwitcher, NumberInput, ComposedModal, ModalHeader, ModalBody, ModalFooter, InlineLoading, DatePickerInput, DatePicker } from 'carbon-components-react';
import Util, { flattenItems } from '../../util/Util';
import CRVTransactionSelector from './CRVTransactionSelector';
import Button from '../../components/Button';
import CustomComboBox from '../../components/CustomComboBox';
import { LEDGER_TYPE_ASSET } from '../../constants/Constants';
import UIUtil from '../../util/UIUtil';
import { OBJECT_TYPE_ACCOUNT } from '../../constants/ObjectTypes';
import { getCashGroup } from '../../domain/account-ledgers';
import { CRV_ACCOUNT_ID } from '../../app/Config';

const Section = ({ children, icon, title, options, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: 1 }}>{title}</p>
            {options}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)





class CRVCreatorPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            staffUsers: [],
            accountTree: [],

            dateValue: new Date().getTime(),
            selectedStaffUserIdValue: 0,
            staffUserIdPickerKey: Util.newTempId(),

            cashAccount: undefined,
            amountValue: 0,
            infoValue: "",

            transactionSelection: {
                totalToReturn: 0,
                distribution: []
            },

            loadingTransactions: false,
            creating: false,
        }
    }

    resetToDefaults() {
        let defCashAccount = flattenItems(this.state.accountTree).find(item => item.name == "Cash" && !item.group);
        // let defCashAccount;
        // mainLoop: for (const group of this.state.accountTree) {
        //     for (const subGroup of group.items) {
        //         for (const item of subGroup.items) {
        //             if (item.name == "Cash") {
        //                 defCashAccount = item;
        //                 break mainLoop;
        //             }
        //         }
        //     }
        // }

        this.setState({
            selectedStaffUserIdValue: 0,
            staffUserIdPickerKey: Util.newTempId(),
            cashAccount: defCashAccount,
            amountValue: 0,
            infoValue: "",
        })
    }

    canCreate() {
        if (!Util.isNumberExist(this.state.amountValue) ||
            !Util.isNumberExist(this.state.selectedStaffUserIdValue) ||
            !Util.isNumberExist(this.state.transactionSelection.totalToReturn)) {
            return false;
        }

        if (parseFloat(this.state.amountValue) > this.state.transactionSelection.totalToReturn) {
            return false;
        }

        return true;
    }

    createCRV() {
        if (!Util.isNumberExist(this.state.dateValue)) {
            UIUtil.showError("Date is required!")
            return;
        }

        //this.state.cashAccount.name
        this.setState({ creating: true })

        Api.createCrv({
            cashBorrowerId: this.state.selectedStaffUserIdValue,
            cashBorrowerType: OBJECT_TYPE_ACCOUNT,

            amountReturned: this.state.amountValue,
            info: this.state.infoValue,

            initiationDate: this.state.dateValue,

            againstItems: this.state.transactionSelection.distribution
                .filter(dist => dist.amountPaying && dist.amountPaying > 0)
                .map(dist => ({
                    transactionId: dist.id,
                    dividedAmount: dist.amountPaying,
                })),

            requestedCashAccountId: this.state.cashAccount?.id
        }, response => {
            this.setState({ creating: false });
            if (response.status === true) {
                UIUtil.showSuccess();
                this.props.history.replace("/crv/" + response.payload)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }

    onPageStart() {
        this.callPageApi(listener => Api.getCRVEndpointsList(listener), payload => {
            let defCashAccount = flattenItems(payload.accountTree).find(item => item.name == "Cash" && !item.group);
            // let defCashAccount;
            // mainLoop: for (const group of payload.accountTree) {
            //     for (const subGroup of group.items) {
            //         for (const item of subGroup.items) {
            //             if (item.name == "Cash") {
            //                 defCashAccount = item;
            //                 break mainLoop;
            //             }
            //         }
            //     }
            // }

            let staffUser;
            if (this.props.location.state && this.props.location.state.staffId) {
                staffUser = {
                    selectedStaffUserIdValue: this.props.location.state.staffId,
                    staffUserIdPickerKey: Util.newTempId(),
                }
            } else {
                staffUser = {}
            }

            return {
                staffUsers: payload.staff,
                accountTree: payload.accountTree,
                cashAccount: defCashAccount,

                ...staffUser
            }
        })
    }

    getCashAccounts() {
        return getCashGroup(this.state.accountTree[0]).items;
        //return this.state.accountTree[0].items.find(item => item.name == "Cash and cash equivalents").items
        // const accountTree = flattenItems(this.state.accountTree);
        // const cashGroup = accountTree.find(item => item.group && item.name == "Cash and cash equivalents");

        // // const accountTree = [...this.state.accountTree].filter(item => item.id == LEDGER_TYPE_ASSET);

        // // for (const group of accountTree) {
        // //     group.items = group.items.filter(item => item.name == "Cash and cash equivalents");
        // // }

        // return accountTree.filter(item => item.parentId == cashGroup.id);
    }

    renderAmount() {
        return (
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <p>AED</p>
                <NumberInput hideSteppers min={0} invalidText="Invalid numeric value" value={this.state.amountValue} onChange={e => {
                    // console.log(e.target.value)
                    this.setState({ amountValue: e.target.value < 0 ? 0 : e.target.value })
                }} />
            </div>
        )
    }

    renderTransactionsSelector() {
        return (<>
            {/* <pre><code>{JSON.stringify(this.state.transactionSelection, null, 2)}</code></pre> */}
            <CRVTransactionSelector
                amount={this.state.amountValue}
                staffUserId={this.state.selectedStaffUserIdValue}
                onLoadChange={loading => this.setState({ loadingTransactions: loading })}
                onSelectionUpdate={transactionSelection => this.setState({ transactionSelection })}
            />

        </>)
    }

    renderInformation() {
        return (
            <TextArea placeholder="Note here..." value={this.state.infoValue} onChange={e => this.setState({ infoValue: e.target.value })} />
        )
    }

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>Cash Receipt Voucher</h1>
                    <p style={{ fontSize: 18 }}>Initiating</p>

                    <div style={{ marginTop: '1rem' }}>
                        <DatePicker datePickerType="single"
                            dateFormat="d/m/Y"
                            value={this.state.dateValue}
                            onChange={e => {
                                if (e.length > 0) {
                                    this.setState({
                                        dateValue: e[0].getTime(),
                                    })
                                } else {
                                    this.setState({
                                        dateValue: undefined
                                    })
                                }
                            }}
                        >
                            <DatePickerInput
                                // placeholder="mm/dd/yyyy"
                                placeholder="dd/mm/yyyy"
                                labelText={"CRV Date"}
                            />
                        </DatePicker>
                    </div>

                    <Section icon={Number_132} title={"Select staff"}>
                        <ComboBox
                            disabled={this.state.loadingTransactions}
                            key={this.state.staffUserIdPickerKey}
                            titleText={"Staff User"}
                            placeholder="Select..."
                            items={this.state.staffUsers}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.state.staffUsers.filter(item => item.id == this.state.selectedStaffUserIdValue)[0]}
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ selectedStaffUserIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, staffUserIdPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ selectedStaffUserIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }} />
                    </Section>

                    <Section icon={Number_232} title="Set amount">
                        {this.renderAmount()}

                        {CRV_ACCOUNT_ID === 0 && <>
                            <div style={{ height: '1rem' }} />
                            <label className="bx--label">Cash Account</label>
                            <div style={{ height: 40, }}>
                                <CustomComboBox
                                    defExtended
                                    preventClear
                                    items={this.getCashAccounts()}
                                    selectedItem={this.state.cashAccount}
                                    onSelectedItemUpdate={item => this.setState({ cashAccount: item })}
                                />
                            </div>
                        </>}
                    </Section>

                    <Section icon={Number_332} title={"Select transactions"}>
                        {Util.isNumberExist(this.state.selectedStaffUserIdValue) ?
                            this.renderTransactionsSelector() : <p style={{ fontSize: 12, opacity: 0.65 }}>No staff user selected</p>}
                    </Section>

                    <Section icon={Number_432} title="Additional Information (optional)">
                        {this.renderInformation()}
                    </Section>

                    <Section icon={Number_532} title="Confirm" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonSet style={{ width: 392 }}>
                                <Button onClick={() => this.resetToDefaults()} disabled={this.state.creating} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                                <Button onClick={() => this.createCRV()} disabled={!this.canCreate()} loading={this.state.creating} renderIcon={Wallet16}>Create CRV</Button>
                            </ButtonSet>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

}

export default CRVCreatorPage;