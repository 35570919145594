import { useHistory } from "react-router-dom";
import { withLoadablePageWithParams } from "../../../base/Page";
import Api from "../../../session/Api";
import { ArrowLeft16, CheckmarkFilled16, CheckmarkFilled24, Close16, QrCode16, ArrowRight16, Receipt16, ErrorFilled16, Reset16 } from '@carbon/icons-react'
import { useEffect, useMemo, useState } from "react";
import { useShipmentUpdator } from "../shipment/item-page";
import { FormSection, useForm } from "../../../templates/form/form";
import Button from "../../../components/Button";
import isEqual from 'lodash.isequal';
import { LoadingModeFilteredPackageListEditor, PackageListEditor } from "../shipment/item-page/flow/package-list-editor";
import { ShipmentPortStatusTag } from "./shipment-port-status";
import { SHIPMENT_DIR_TYPE } from "../shipment";
import { importFlow, exportFlow } from "../shipment/item-page/flow";
import { PortStaffPackageInspectionListEditor } from "../shipment/item-page/flow/port-staff-package-inspection-list-editor";
import { PortStaffPackageLoadingListEditor } from "../shipment/item-page/flow/port-staff-package-loading-list-editor";
import UIUtil from "../../../util/UIUtil";
import { QrCode } from "../shipment/item-page/side-views/qr-code";
import { PortStaffPackageUnloadingListEditor } from "../shipment/item-page/flow/port-staff-package-unloading-list-editor";
import { Services } from "../shipment/item-page/side-views/services";


const CreationForm = ({ form, endpoint }) => (
    <div style={{ padding: '1rem' }}>
        <FormSection title="Creating Packages">
            <form.ExtField childrenProps={{ allPackageTypes: endpoint.packageTypes, itemTypes: endpoint.itemTypes, customers: endpoint.customers, consignees: endpoint.consignees }}>
                {LoadingModeFilteredPackageListEditor}
            </form.ExtField>
        </FormSection>
    </div>
)

const InspectingForm = ({ form, endpoint }) => (
    <div style={{ padding: '1rem' }}>
        <FormSection title="Inspecting Packages">
            <form.CustomField fieldKey="packages" childrenProps={{ itemProps: { packageTypes: endpoint.packageTypes, itemTypes: endpoint.itemTypes, } }}>
                {PortStaffPackageInspectionListEditor}
            </form.CustomField>
        </FormSection>
    </div>
)

const LoadingForm = ({ form, endpoint }) => (
    <div style={{ padding: '1rem' }}>
        <FormSection title="Loading Packages">
            <form.CustomField fieldKey="packages" childrenProps={{ itemProps: { packageTypes: endpoint.packageTypes, itemTypes: endpoint.itemTypes, } }}>
                {PortStaffPackageLoadingListEditor}
            </form.CustomField>
        </FormSection>
    </div>
)


const UnloadingForm = ({ form, endpoint }) => (
    <div style={{ padding: '1rem' }}>
        <FormSection title="Unloading Packages">
            <form.CustomField fieldKey="packages" childrenProps={{ itemProps: { packageTypes: endpoint.packageTypes, itemTypes: endpoint.itemTypes, } }}>
                {PortStaffPackageUnloadingListEditor}
            </form.CustomField>
        </FormSection>
    </div>
)


const AllDone = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3rem', flexDirection: 'column' }}>
        <CheckmarkFilled24 />
        <p style={{ fontSize: 12, opacity: 0.65, marginTop: '0.5rem' }}>You are all done!</p>
    </div>
)

function showQrCodes(shipment) {
    UIUtil.showOverlay2(onClose => (
        <div style={{ borderRadius: 15, background: 'white', width: '90vw', }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', padding: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Shipment</p>
                    <h4 style={{ fontWeight: 'bold' }}>QR Codes</h4>
                </div>
                <button onClick={onClose} className='lead-flow-icon-button'>
                    <Close16 />
                </button>
            </div>


            <div style={{ height: '75vh', overflow: 'auto' }}>
                <QrCode shipment={shipment} />
            </div>
        </div>
    ))
}

function View({ payload: { item, endpoint } }) {
    const [shipment, setShipment] = useState(item)
    const flow = useMemo(() => shipment?.dirType === SHIPMENT_DIR_TYPE.IMPORT ? importFlow(endpoint) : exportFlow(endpoint), [shipment?.dirType])

    const history = useHistory();

    const [changesMade, setChangesMade] = useState(false);
    const form = useForm(store => store.setObject(shipment), {
        skipFieldEffect: true
    });
    useEffect(() => form.store.subscribeGlobal(() => {
        setChangesMade(!isEqual(shipment, form.getFormData()))
    }), [shipment])
    const shipmentUpdator = useShipmentUpdator(form, shipment, setShipment, flow);
    useEffect(() => {
        form.store.clear()
        form.store.setObject(shipment)
    }, [shipment])

    const [showCharges, setShowCharges] = useState(false);

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ zIndex: 100, position: 'fixed', top: '3rem', left: 0, background: "#f4f4f4", color: 'black', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000020', paddingInline: '1rem', width: '100%', height: "3rem", gap: '0.5rem' }}>
                <button onClick={() => history.goBack()} className='lead-flow-icon-button'>
                    <ArrowLeft16 />
                </button>
                <div style={{ flex: 1 }}>
                    <h4 style={{ fontSize: 16, fontWeight: 'bold', flex: 1 }}>Shipment</h4>
                    <p style={{ fontSize: 12, opacity: 0.65, marginTop: -2 }}>{shipment.shipmentNo}</p>
                </div>

                <button onClick={() => showQrCodes(shipment)} className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                    <QrCode16 />
                </button>
                <button onClick={() => setShowCharges(p => !p)} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                    <Receipt16 />
                </button>
            </div>
            <div style={{ height: '3rem' }} />

            {(shipment.status === "Created" && shipment.duringPortInspection === false) ? <CreationForm form={form} endpoint={endpoint} /> :
                (shipment.status === "Created" && shipment.duringPortInspection === true) ? <InspectingForm form={form} endpoint={endpoint} /> :
                    (shipment.status === "Loading") ? <LoadingForm form={form} endpoint={endpoint} /> :
                        (shipment.status === "Unloading") ? <UnloadingForm form={form} endpoint={endpoint} /> :
                            <AllDone />}



            {<>
                <div style={{ position: 'fixed', bottom: 0, left: 0, paddingInline: '1rem', width: '100%', background: "white", borderTop: '1px solid #00000020', height: 32 + 15, display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
                    <Footer {...{ shipment, changesMade, ...shipmentUpdator }} />
                </div>
                <div style={{ height: 32 + 15 }} />
            </>}

            {showCharges && <div style={{ position: 'absolute', left: 0, top: '3rem', zIndex: 100, width: '100%', height: 'calc(100vh - 6rem)', background: '#00000040', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingBlock: '3rem', overflow: 'auto' }}>
                <div style={{ borderRadius: 15, background: 'white', width: '90vw', }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', padding: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Shipment</p>
                            <h4 style={{ fontWeight: 'bold' }}>Charges</h4>
                        </div>
                        <button onClick={() => setShowCharges(false)} className='lead-flow-icon-button'>
                            <Close16 />
                        </button>
                    </div>


                    <Services form={form} endpoint={endpoint} shipment={shipment} showMineOnly />
                </div>
            </div>}
        </div>
    )
}

function Footer({ shipment, canRevert, canNext, nextingStatus, changesMade, savingChanges, revertingStatus, onRevertStatusBtn, onPortStaffInspectBtn, onNextStatusBtn, onSaveChangesBtn, onResetToDefaultBtn }) {
    return (<>
        {changesMade ? (<>
            {!revertingStatus && !nextingStatus && <>
                {/* <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>Changes made</p> */}
                <Button onClick={onResetToDefaultBtn} disabled={savingChanges} kind="secondary" size="sm" style={{ borderRadius: 50, flex: 1 }} renderIcon={Reset16}>Reset</Button>
                <Button onClick={onSaveChangesBtn} loading={savingChanges} size="sm" style={{ borderRadius: 50, flex: 1 }} renderIcon={CheckmarkFilled16}>Save</Button>
            </>}
        </>) : (<>
            <ShipmentPortStatusTag status={shipment.status} duringPortInspection={shipment.duringPortInspection} />
            <div style={{ flex: 1 }} />
            {shipment.status === "Created" && shipment.duringPortInspection === false &&
                <Button onClick={onPortStaffInspectBtn} loading={nextingStatus} disabled={revertingStatus} className="green-button" size="sm" style={{ borderRadius: 50 }} renderIcon={ArrowRight16}>Inspect</Button>}

            {shipment.status === "Created" && shipment.duringPortInspection === true &&
                <Button onClick={onNextStatusBtn} loading={nextingStatus} disabled={revertingStatus} className="green-button" size="sm" style={{ borderRadius: 50 }} renderIcon={ArrowRight16}>Confirm</Button>}
        </>)}

    </>)
}

//export const PortShipmentItemPage = withLoadablePageWithParams(params => listener => Api.getPortShipment(params.shipmentId, listener), View)
export const PortShipmentItemPage = withLoadablePageWithParams(params => listener => Api.getShipmentAndLock(params.shipmentId, listener), View)