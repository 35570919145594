import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";

import { RRule } from 'rrule'

function syncEventDateWithActivity(event) {
    const activity = event.extendedProps.activity;

    const [startDate, endDate, allDay] = [activity.startDate, activity.endDate, activity.allDay]

    const skipEndDate = allDay && (endDate - startDate) <= 86399999;

    event.setDates(startDate, skipEndDate ? null : endDate, { allDay })
    event.setDates(startDate, skipEndDate ? null : endDate, { allDay })
}

async function updateEvent(event, info, onSuccess) {
    return new Promise((resolve) => {
        Api.updateActivityDate(event.extendedProps.activity.id, event.allDay ?? false, event.start.getTime(), event.end?.getTime() ?? 0, response => {
            if (response.status === true) {
                event.setExtendedProp("activity", response.payload);
                onSuccess(response.payload);
            } else {
                UIUtil.showError(response.message);
            }
            resolve();
        })
    })
}

class QueueNode {
    constructor(func) {
        this.isExecuting = false;
        this.func = func;
    }

    async exec(onDone) {
        this.isExecuting = true

        await this.func()
        onDone();

        this.isExecuting = false;
    }
}

export class Manager {

    constructor(calendarRef) {
        this.calendarRef = calendarRef;
        this.queues = new Map();
    }

    reloadCurrentView() {
        //UIUtil.showInfo("this is it: " + this.queues.size)
        this.calendarRef.current?.getApi().refetchEvents();
    }

    onUpdatePresence() {
        this.calendarRef.current?.getApi().refetchEvents();
    }

    getEventCalendarDate(event) {
        const calendarEvent = this.calendarRef.current?.getApi().getEventById(event?.id);
        return {
            start: calendarEvent?.start?.getTime(),
            end: calendarEvent?.end?.getTime() ?? 0,
            allDay: calendarEvent?.allDay ?? false
        }
    }

    setEventCalendarEndDate(event, endTime) {
        const calendarEvent = this.calendarRef.current?.getApi().getEventById(event?.id);
        calendarEvent?.setEnd(endTime)
    }

    getEditingEvent() {
        return this.calendarRef.current?.getApi().getEvents().find(item => item?.extendedProps?.activity === undefined);
    }

    getCurrentEvent(event) {
        return this.calendarRef.current?.getApi().getEventById(event?.id);
    }

    replaceCalendarEvent(currentEvent, newEvent, onNewEvent) {
        currentEvent.remove();
        const event = this.calendarRef.current.getApi().addEvent(newEvent, true);
        onNewEvent(event)
    }

    updateEvent(event) {
        const calendarEvent = this.calendarRef.current?.getApi().getEventById(event?.id);
        calendarEvent.setExtendedProp("activity", event.extendedProps.activity)
    }

    updateEventActivity(event, fn) {
        const calendarEvent = this.calendarRef.current?.getApi().getEventById(event?.id);
        calendarEvent.setExtendedProp("activity", fn(calendarEvent.extendedProps.activity))
    }

    updateSchedule(event, info) {
        const eventId = event.id;

        const queue = this.getQueue(eventId);
        queue.push(new QueueNode(async () => updateEvent(event, info, activity => {
            if (Util.isStringExists(activity?.commonSharedId)) {
                this.reloadCurrentView();
            }
        })));

        this.performNextEventUpdate(eventId)
    }

    async performNextEventUpdate(eventId) {
        const queue = this.getQueue(eventId);
        if (queue.length === 0) {
            const event = this.calendarRef.current?.getApi().getEventById(eventId);
            if (event) {
                syncEventDateWithActivity(event);
            }
            return;
        }

        const node = queue[0];
        if (node.isExecuting) {
            return;
        }

        await node.exec(() => queue.splice(0, 1));
        this.performNextEventUpdate(eventId)
    }

    getQueue(eventId) {
        const id = eventId;
        let queue = this.queues.get(id);
        if (!queue) {
            queue = [];
            this.queues.set(id, queue);
        }

        return queue;
    }

}