import React from 'react'
import DashboardTitle from '../components/DashboardTitle'

import { Bar } from 'react-chartjs-2';
import DashboardCard from '../components/DashboardCard';

import { Rocket20 } from '@carbon/icons-react'


export default ({dashboard}) => {
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <DashboardTitle icon={Rocket20} title={"Top 10 product sales this month (" + dashboard.monthName + ")"} />
            <DashboardCard>
                <Bar data={{
                    labels: dashboard.topProductNames,
                    datasets: [
                        {
                            data: dashboard.topProductSaleCounts,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }} options={{
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    }
                }} />
            </DashboardCard>
        </div>
    )
}