import { useEffect, useMemo, useState } from "react";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import usePosSession from "../state/usePosSession";
import useStore from "../../../hooks/useStore";
import { ButtonSet, ComboBox, ComposedModal, Link, ModalBody, ModalFooter, ModalHeader, TextArea } from "carbon-components-react";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import Button from "../../../components/Button";
import { Save16, ErrorFilled16 } from '@carbon/icons-react'
import Util from "../../../util/Util";


const DetailField = ({ title, value }) => (<>
    <label className="bx--label">{title}</label>
    <p style={{ marginTop: '-0.75rem', whiteSpace: 'pre-wrap' }}>{value}</p>
    <div style={{ height: '0.25remrem' }} />
</>)



export function InventoryStatus({ sessionStore, statuses, editable }) {
    const session = usePosSession(sessionStore)
    const [status] = useStore(sessionStore, "inventoryStatus")

    if (!editable) {
        return (
            <div>
                <DetailField title={"Inventory Status"} value={Util.isStringExists(status) ? status : '-'} />
            </div>
        )
    }

    return (
        <ComboBox
            disabled={session.loading}
            light
            titleText="Inventory Status"
            placeholder={"Select Status"}
            item={status}
            onChange={e => session.setInventoryStatus(e?.selectedItem ? e.selectedItem : '')}
            value={status}
            itemToString={item => item}
            items={statuses?.map($ => $.value) ?? []}
        />
    )
}