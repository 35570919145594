import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import Button from '../../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_ACCOUNT_LEDGER, OBJECT_TYPE_ACCOUNT_LEDGER_GROUP, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_LOYALTY_CARD_ISSUE, OBJECT_TYPE_STORE, OBJECT_TYPE_TERMINAL } from "../../../constants/ObjectTypes";
import Api from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import TableTemplate from '../../../templates/TableTemplate';

const AccountLedgersList = ({item}) => {
    const history = useHistory();
    return (
        <div style={{marginTop: '1rem'}}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'groupId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Account Ledgers", 
                subTitle: "Group account ledgers", 
                objectType: OBJECT_TYPE_ACCOUNT_LEDGER, 
                pagePath: "/account-ledgers/", 
                history: history
            })}
        </div>
    )
}


class AccountLedgerGroupsDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_ACCOUNT_LEDGER_GROUP, listener)
            } else {
                Api.getItem(OBJECT_TYPE_ACCOUNT_LEDGER_GROUP, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_ACCOUNT_LEDGER_GROUP,
                    itemResult: this.state.itemResult,
                    pagePath: "/account-ledger-groups/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Account Ledgers",
                            component: AccountLedgersList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(AccountLedgerGroupsDetailPage);