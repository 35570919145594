import { Toggle } from "carbon-components-react";
import { useEffect, useState } from "react";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";

export const makeSettingComponent = settingKey => () => {
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        Api.isSettingEnabled(settingKey, response => {
            if (response.status === true) {
                setActive(response.payload)
                setLoading(false)
            } else {
                setInError(true)
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const onActiveToggle = enabled => {
        const currentActive = active;
        setActive(enabled)
        setLoading(true)
        Api.setSettingEnable(settingKey, enabled, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setActive(currentActive)
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <label className="bx--label">Active</label>
            <Toggle
                checked={active}
                disabled={loading}
                size="md"
                labelA="Disabled"
                labelB="Enabled"
                onToggle={onActiveToggle}
            />
        </div>
    )
}
