import { useMemo } from 'react'
import Select from 'react-select'

const parseOption = opt => opt ? { value: opt.id, label: opt.value, id: opt.id } : null;

export function MultiSelector({ labelText, value, setValue, readonly, options, skipBottomBar, minimalInput, mdInput, style, light }) {
    // const value = useMemo(() => outValue?.map(id => options.find(opt => opt.id === id)).filter(opt => opt).map(parseOption) ?? [], [options, outValue])
    // const setValue = values => setOutValue(values?.map($ => $.value) ?? [])
    return (<>
        <div style={style}>
            <label className="bx--label" style={{}}>{labelText}</label>
            <Select
                value={value}
                onChange={setValue}
                isClearable={false}
                isDisabled={readonly}
                placeholder={readonly ? "No class" : "Select..."}
                styles={{
                    container: (baseStyles) => ({
                        ...baseStyles,
                        paddingRight: 1,
                        background: 'transparent',
                        height: '100%',
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: '2.5rem',
                        padding: '0 6px'
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: 1.29,
                        letterSpacing: '0.16px',
                        outline: '2px solid transparent',
                        outlineOffset: '-2px',
                        width: '100%',
                        minHeight: '2.5rem',
                        padding: minimalInput ? '0 5px' : mdInput ? '0 0.25rem' : '0 1rem',
                        border: 'none',
                        borderBottom: skipBottomBar ? 'none' : '1px solid #8d8d8d',
                        backgroundColor: light ? '#ffffff' : '#f4f4f4',
                        color: '#161616',
                        transition: 'background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9)',

                    }),
                    input: (baseStyles) => ({
                        ...baseStyles,
                        color: 'black',
                        height: '100%',
                        margin: 0
                    }),
                    indicatorsContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: '2.5rem',
                    }),
                    indicatorSeparator: state => ({
                        display: 'none',
                    }),

                }}
                isMulti options={options.map(parseOption)} />
        </div>
    </>)
}