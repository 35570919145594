import { useMemo } from "react";
import { TableInput } from "../../../components/table-input";
import { TextInput } from "carbon-components-react";
import { AmountTagSelectorNormalField } from "../../../pages/journal-entries/tag-selector";
import { ProductListSelector } from "../../../components/list-selector";


export function StockAdjustmentItemsList2({ value, setValue, products, predefinedClasses }) {
    const columns = useMemo(() => [
        {
            key: "item", title: "Item", flex: 5, Component: ({ value, setValue }) => {
                return (
                    <div style={{ height: 40, width: '100%' }}>
                        <ProductListSelector value={value} setValue={setValue} />
                    </div>
                )
            },
        },
        {
            key: "tags", title: "Class", flex: 3, Component: ({ value, setValue }) => {
                console.log({ classes: value })
                return (
                    <div style={{ width: '100%' }}>
                        <AmountTagSelectorNormalField skipBottomBar options={predefinedClasses} value={value} setValue={setValue} />
                    </div>
                )
            },
        },
        {
            key: "memo", title: "Memo", flex: 3, Component: ({ value, setValue }) => {
                return <TextInput value={value} onChange={e => setValue(e.target.value)} placeholder="Input..." />
            },
        },
        {
            key: "qty", title: "Qty +/-", flex: 2, Component: ({ value, setValue }) => {
                return <TextInput value={value} onChange={e => setValue(e.target.value)} placeholder="Input..." />
            },
        }
    ], [products, predefinedClasses])
    return (
        <TableInput
            columns={columns} value={value} setValue={setValue}
        />
    )
}