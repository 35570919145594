import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CONTACT, OBJECT_TYPE_COUPON, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_WAREHOUSE } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

class MobileUnitListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Mobile Units", 
                    subTitle: "All mobile units", 
                    objectType: OBJECT_TYPE_MOBILE_UNIT, 
                    pagePath: "/mobile-units/", 
                    history: this.props.history
                })}
            </div>
        )
    }
    
    isPageLoadable() {
        return false;
    }

}

export default withRouter(MobileUnitListPage);