import MyStateDraftsDialog from "../../views/state-drafts/MyStateDraftsDialog"
import { Close16, LicenseDraft16 } from '@carbon/icons-react'
import Button from "../../components/Button"
import { useEffect, useState } from "react";
import SaveStateDraftsDialog from "../../views/state-drafts/SaveStateDraftsDialog";

const DraftManagement = ({ selectedDraft, setShowDrafts, setSelectedDraft, rounded }) => (
    selectedDraft ? (
        <div style={{
            background: 'white', borderRadius: 5, paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', paddingBottom: '1rem', minWidth: 250,
            display: 'flex',
        }}>
            <div style={{ flex: 1 }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Selected Draft</p>
                <h4>{selectedDraft.title}</h4>
            </div>
            <Button onClick={() => setSelectedDraft(undefined)} kind="ghost" hasIconOnly renderIcon={Close16} iconDescription="Close draft" />
        </div>
    ) : (
        rounded ? (
            <Button size="sm" style={{ borderRadius: 50 }} onClick={() => setShowDrafts(true)} renderIcon={LicenseDraft16} kind="secondary">Load from draft</Button>
        ) : (
            <Button onClick={() => setShowDrafts(true)} renderIcon={LicenseDraft16} kind="secondary">Load from draft</Button>
        )
    )
)

export function useDraft(type, loadDraft, getState, rounded, opt = {}) {
    const [showDrafts, setShowDrafts] = useState(false);
    const [showSaveDraft, setShowSaveDraft] = useState(false);
    const [selectedDraft, setSelectedDraft] = useState(undefined)

    useEffect(() => {
        loadDraft(selectedDraft?.state ? JSON.parse(selectedDraft.state) : undefined)
    }, [selectedDraft])

    return {
        selectedDraft,
        setShowSaveDraft,
        setSelectedDraft,
        draftManagement: (
            <DraftManagement {...({ selectedDraft, setShowDrafts, setSelectedDraft, rounded })} />
        ),
        draftDialogs: (<>
            <SaveStateDraftsDialog open={showSaveDraft} onClose={() => setShowSaveDraft(false)}
                stateType={type} onSetState={setSelectedDraft} currentDraft={selectedDraft}
                getState={getState}
            />

            <MyStateDraftsDialog open={showDrafts} onClose={() => setShowDrafts(false)}
                stateType={type} onLoadDraftRequest={draft => {
                    setSelectedDraft(draft)
                    setShowDrafts(false)
                }} />
        </>)
    }
}