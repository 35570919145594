import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24 } from '@carbon/icons-react'
import { MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { DateRange2 } from "../../components/basic-filter/date-range"
import { InvRequestPage } from "../../../pages/inv-requests/inv-request-page"

export const ActiveInvRequests = newListDetail({
    title: "Stock Request",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    skipCreateBtn: true,
    rawPanelFunc: () => true,

    defaultValues: {

    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Form({ data }) {
    return <InvRequestPage data={data} />
}

const STATUSES = [
    'Pending',
    'Approved',
    'Sent',
]

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="requestedDate" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="invreqstatus" options={STATUSES} state={state} property="status" />
        </div>

    </>)
}
