import React from 'react'
import Page from '../../base/Page';

import { App32, Download20, Document16, Mobile16 } from '@carbon/icons-react'
import Api from '../../session/Api';
import Button from '../../components/Button';

const Section = ({children, icon, title, extraTopMargin, hasLink, linkText, onLinkClick}) => (
    <div style={{marginTop: extraTopMargin ? '6rem' : '3rem'}}>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
            {React.createElement(icon)}
            <p style={{marginLeft: '0.25rem'}}>{title}</p>


            {/* {hasLink && <Link style={{marginLeft: '1rem', fontSize: 12, cursor: 'pointer'}} onClick={onLinkClick}>{linkText}</Link>} */}
        </div>
        {children}
    </div>
)


class PocketPosAppPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            version: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getSalesMobileAppVersion(listener), version => ({ version }))
    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem'}}>
                <div style={{width: '75vw'}} className="menu-tile">
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '3rem'}}>
                        <App32 style={{width: 64, height: 64, marginRight: '0.5rem'}} />
                        <div>
                            <h1>Pocket POS App</h1>
                            <p style={{fontSize: 18}}>v{this.state.version}</p>
                        </div>
                    </div>

                    <Section icon={Download20} title="Get App">
                        <div>
                            {/* <p style={{fontSize: 14, opacity: 1, marginBottom: '1rem'}}>Two sheets are included in the template. The main template sheet and the instruction sheet describing the data model to be inputted into the template.</p> */}
                            <a target="_blank" download={"PocketPosAppV" + this.state.version} href="/PocketPosApp.apk" style={{textDecoration: 'none'}}>
                                <Button kind="secondary" renderIcon={Mobile16} style={{width: undefined}}>Download Android App</Button>
                            </a>
                            <p className="bx--label" style={{display: 'block', marginTop: '0.25rem'}}>.apk file format</p>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    isPageLoadable() {
        return true;
    }

}

export default PocketPosAppPage;