import React from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import Button from '../../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_BLOG_POST, OBJECT_TYPE_CONTACT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../../constants/ObjectTypes";
import Api, { createApiWithLang } from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import TableTemplate from '../../../templates/TableTemplate';
import AllSubsidiaryReportView from '../../accounting-reports/AllSubsidiaryReportView';
import StockFlowListView from '../../stock-flow/StockFlowListView';
import TransactionListView from '../../transaction/TransactionListView';
import { Launch16, ViewFilled16, Edit16, Terminal16 } from '@carbon/icons-react'
import HTMLEditor from '../../../html-editor/HTMLEditor';
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { TranslateBanner } from '../../../templates/translate/translate-banner';
import Util from '../../../util/Util';

class ContentEditor extends React.Component {
    shouldComponentUpdate(prevProps) {
        if (prevProps !== undefined && this.props !== undefined && prevProps.item !== undefined && this.props.item !== undefined && prevProps.item.id === this.props.item.id) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const item = this.props.item;
        return (
            <div style={{ marginTop: '1rem' }}>
                {/* <HTMLEditor /> */}
                <a target="_blank" href="/html-editor">
                    <button>open editor</button>
                </a>
            </div>
        )
    }
}


class SupplierDetailPage extends Page {

    api = Api;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined,
            lang: "EN"
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                this.api.getItemCreator(OBJECT_TYPE_BLOG_POST, listener)
            } else {
                this.api.getItem(OBJECT_TYPE_BLOG_POST, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    hasLang() {
        return !(!Util.isStringExists(this.state.lang) || this.state.lang === "EN");
    }

    setLang(lang) {
        this.setState({ lang }, () => {
            this.api = (!Util.isStringExists(lang) || lang === "EN") ? Api : createApiWithLang(lang);
            this.onPageStart();
        })
    }

    hasTranslation() {
        return hasCapabilitySupport("translationSupport") && this.state.itemResult.translationSupport;
    }

    renderOptions() {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1rem', gap: '0.5rem' }}>
                <div className="cash-button">
                    <Button renderIcon={ViewFilled16} style={{ pointerEvents: 'none' }}>{this.state.itemResult.item.viewCount}</Button>
                </div>
                <a target="_blank" style={{ textDecoration: 'none' }} href={this.api.redirectToOnlineStoreBlog(this.state.itemResult.item.id)}>
                    <Button kind="secondary" renderIcon={Launch16}>Open Blog Post</Button>
                </a>
                {this.hasLang() ? (
                    <Link target="_blank" style={{ textDecoration: 'none' }}
                        to={`/html-editor/${OBJECT_TYPE_BLOG_POST}/${this.getPathParams().itemId}/${this.state.lang}`}>
                        <Button renderIcon={Terminal16}>Open Editor</Button>
                    </Link>
                ) : (
                    <Link target="_blank" style={{ textDecoration: 'none' }}
                        to={`/html-editor/${OBJECT_TYPE_BLOG_POST}/${this.getPathParams().itemId}`}>
                        <Button renderIcon={Terminal16}>Open Editor</Button>
                    </Link>
                )}
            </div>
        )
    }

    getLayout() {
        return (<>
            {this.hasTranslation() && <TranslateBanner lang={this.state.lang} setLang={this.setLang.bind(this)} />}
            <div className="main-content">
                <div key={"blog-item-lang-" + this.state.lang}>
                    {ItemTemplate.renderTemplate({
                        objectType: OBJECT_TYPE_BLOG_POST,
                        itemResult: this.state.itemResult,
                        pagePath: "/blog-posts/",
                        history: this.props.history,

                        hasCustomBtn: this.renderOptions(),

                        lang: this.state.lang

                        // customTabs: [
                        //     {
                        //         title: "Content",
                        //         component: ContentEditor
                        //     },
                        // ]
                    })}
                </div>
            </div>
        </>)
    }

}

export default withRouter(SupplierDetailPage);