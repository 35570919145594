import { SOCKET_EVENT_LIVE_CART, useSubscribe } from "../../../session/SocketSession";
import PosState from "./PosState";


/**
 * 
 * @param {{ state: PosState }} props
 * @returns 
 */
export function LiveCart({ state }) {
    useSubscribe(SOCKET_EVENT_LIVE_CART(state.getSession().currentCartId), ({ cartId }) => {
        if (cartId === state.getSession().currentCartId) {
            state.reloadSessionOnce();
            setTimeout(() => state.reloadSessionOnce(), 1000)
        }
    })
    return <div />
}