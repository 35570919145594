import './showtime-scheduler.scss'
import Util from "../../../util/Util"
import { Theater24, Add16, AddAlt16, CloseFilled16, AddFilled16, CheckmarkFilled16, ErrorFilled16, ArrowLeft16, ArrowRight16 } from '@carbon/icons-react'
import { useRefSize } from "../../../util/useSize"
import ImageView from "../../../components/ImageView";
import Api from "../../../session/Api";
import { OBJECT_TYPE_MOVIE } from "../../../constants/ObjectTypes";
import { Checkbox, InlineLoading, Tag } from "carbon-components-react";
import Button from "../../../components/Button";
import { SelectionPopover } from "../../../components/selection-popover";
import { useEffect, useMemo, useRef, useState } from "react";
import UIUtil from "../../../util/UIUtil";
import { ButtonPopover } from "../../../components/button-popover";
import TimePicker from "../../../components/TimePicker";
import { Link } from "react-router-dom";
import { RecurringView } from '../../activities/components/recurring';
import { RRule, rrulestr } from 'rrule';

const ShowtimeTag = ({ schedule, onScheduleRemoved }) => {
    const [loading, setLoading] = useState(false);
    const onBtn = () => {
        setLoading(true);
        Api.deleteShowtimeSchedule(schedule.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onScheduleRemoved(schedule.id)
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }
    return <Tag disabled={loading} onClick={() => UIUtil.confirm(onBtn)} renderIcon={CloseFilled16} key={schedule.id} type="blue">{schedule.value} {schedule.threeD && <strong>3D</strong>}</Tag>
}

const Item = ({ cinemaId, item, schedules, setSchedules, day, reloadData }) => {
    const onScheduleRemoved = (hallId, scheduleId) => {
        setSchedules(schedules => ({
            ...schedules,
            movies: schedules.movies.map(movie => movie.id == item.id ? ({
                ...movie,
                halls: movie.halls.map(hall => hall.id == hallId ? ({
                    ...hall,
                    schedules: hall.schedules.filter(otherSchedule => scheduleId != otherSchedule.id)
                }) : hall)
            }) : movie)
        }))
    }
    return (
        <div style={{ width: '100%', background: '#f4f4f4', borderRadius: 10, overflow: 'hidden', border: '1px solid #00000020', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', marginBottom: '1rem', display: 'flex', padding: '1rem' }}>
            <div style={{ width: 150, minWidth: 150, height: 225, borderRadius: 10, overflow: 'hidden', border: '1px solid #00000040' }}>
                <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, item.id)} style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ marginLeft: '3rem', flex: 1 }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem', }}>
                    <h3 style={{ flex: 1 }}>{item.name}</h3>
                    <Link to={'/movies/' + item.id}>
                        <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                            <ArrowRight16 />
                        </button>
                    </Link>
                </div>

                {item.halls.map(hall => (
                    <div key={hall.id} style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                        {/* <p style={{ fontSize: 12, opacity: 0.65 }}>Hall</p> */}
                        <div style={{ display: 'inline-block', }}>
                            <div style={{ display: 'flex', minWidth: 100, borderRadius: 5, border: '1px solid #00000020', overflow: 'hidden' }}>
                                <div style={{ background: '#e3e3e3', paddingInline: '0.75rem', paddingBlock: '0.15rem', borderRight: '1px solid #00000020', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h6>Hall</h6>
                                </div>
                                <div style={{ paddingInline: '0.75rem', background: 'white', paddingBlock: '0.15rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                    <h4 style={{ fontSize: 16 }}>{hall.name}</h4>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: '0.5rem', marginTop: '0.25rem' }}>
                            {hall.schedules.map(schedule =>
                                <ShowtimeTag key={schedule.id} schedule={schedule} onScheduleRemoved={scheduleId => onScheduleRemoved(hall.id, scheduleId)} />
                            )}

                            {/* <span style={{ marginLeft: '1rem', }} /> */}
                            <div style={{ paddingLeft: '3rem', marginTop: '0.25rem', marginBottom: '0.5rem' }}>
                                {Util.isNumberExist(hall.schedules.length) &&
                                    <ContinueScheduleBtn itemHall={hall} itemMovie={item} setSchedules={setSchedules} hallSchedules={hall.schedules} />}

                                <NewScheduleBtn reloadData={reloadData} day={day} cinemaId={cinemaId} movieId={item.id} hallId={hall.id} itemHall={hall} itemMovie={item} schedules={schedules} setSchedules={setSchedules} />
                            </div>
                            {/* <NewRecurrenceBtn day={day} cinemaId={cinemaId} movieId={item.id} hallId={hall.id} itemHall={hall} itemMovie={item} schedules={schedules} setSchedules={setSchedules} /> */}
                        </div>
                    </div>
                ))}

                <ScheduleAnotherHallBtn {...{ item, schedules, setSchedules }} />
            </div>
        </div>
    )
}

const ContinueScheduleBtn = ({ itemMovie, itemHall, hallSchedules, setSchedules }) => {
    const [loading, setLoading] = useState(false);

    const onCreateBtn = () => {
        if (loading) {
            return;
        }

        setLoading(true);

        Api.continueShowtimeSchedule(hallSchedules[hallSchedules.length - 1].id, response => {
            if (response.status === true) {
                UIUtil.showSuccess();

                const newSchedule = response.payload
                setSchedules(schedules => ({
                    ...schedules,
                    movies: schedules.movies.map(movie => movie.id == itemMovie.id ? ({
                        ...movie,
                        halls: movie.halls.map(hall => hall.id == itemHall.id ? ({
                            ...hall,
                            schedules: [
                                ...hall.schedules,
                                newSchedule
                            ]
                        }) : hall)
                    }) : movie)
                }))
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    return (
        <Tag onClick={onCreateBtn} renderIcon={Add16} type="purple">next</Tag>
    )
}



const NewScheduleBtn = ({ day, cinemaId, movieId, hallId, itemMovie, itemHall, schedules, setSchedules, reloadData }) => {
    const [visible, setVisible] = useState(false);
    const [openInvalidate, setOpenInvalidate] = useState(() => Util.newTempId());
    const [newTimeValue, setNewTimeValue] = useState("");
    const [threeD, setThreeD] = useState(false);

    const [rule, setRule] = useState("");
    const [modalVisible, setModalVisible] = useState(false);


    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!visible) {
            setNewTimeValue("")
            setThreeD(false);
            setRule("");
        }
    }, [visible])

    const onCreateBtn = () => {
        if (Util.isStringExists(rule)) {
            setLoading(true)

            Api.showtimeScheduleTimeToDate(day, newTimeValue, response => {
                if (response.status === true) {
                    const startDate = response.payload;


                    const rrule = rrulestr(rule)
                    const newRule = new RRule({ ...rrule.origOptions, dtstart: new Date(startDate) })
                    const repeatDates = newRule.all((_, l) => l < 100);

                    const showtimesToCreate = repeatDates.map(date => ({
                        cinemaId, movieId, hallId, threeD,
                        startDate: date.getTime()
                    }))

                    Api.createBulkShowtimeSchedule(showtimesToCreate, response => {
                        if (response.status === true) {
                            UIUtil.showSuccess();
                            reloadData();
                        } else {
                            UIUtil.showError(response.message);
                        }
                        setLoading(false);
                    })
                } else {
                    UIUtil.showError(response.message);
                    setLoading(false);
                }
            })


        } else {
            setLoading(true);
            Api.createShowtimeSchedule({
                cinemaId, movieId, hallId,

                day, threeD,
                time: newTimeValue
            }, response => {
                if (response.status === true) {
                    UIUtil.showSuccess();
                    setOpenInvalidate(Util.newTempId());

                    const newSchedule = response.payload
                    setSchedules(schedules => ({
                        ...schedules,
                        movies: schedules.movies.map(movie => movie.id == itemMovie.id ? ({
                            ...movie,
                            halls: movie.halls.map(hall => hall.id == itemHall.id ? ({
                                ...hall,
                                schedules: [
                                    ...hall.schedules,
                                    newSchedule
                                ]
                            }) : hall)
                        }) : movie)
                    }))
                } else {
                    UIUtil.showError(response.message);
                }
                setLoading(false);
            })
        }
    }

    const ref = useRef();
    return (
        <ButtonPopover shouldBeInvisible={modalVisible} parentRef={ref} reversed invalidateOpen={openInvalidate} openListener={setVisible} popover={<div style={{ padding: '1rem', display: modalVisible ? 'none' : undefined }}>
            <TimePicker size="lg" labelText="Showtime" value={newTimeValue} onChange={value => setNewTimeValue(value)} />
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }} onClick={() => setThreeD(t => !t)}>
                <Checkbox labelText="Is 3D" checked={threeD} />
            </div>

            <RecurringView setValue={setRule} value={rule} portalModal setModalVisible={setModalVisible} />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '3rem', gap: '0.25rem' }}>
                <Button onClick={() => setOpenInvalidate(Util.newTempId())} kind="secondary" size="sm" style={{ borderRadius: 25 }} renderIcon={ErrorFilled16}>Cancel</Button>
                <Button onClick={onCreateBtn} disabled={newTimeValue.split(":").length != 2} kind="primary" size="sm" style={{ borderRadius: 25 }} renderIcon={CheckmarkFilled16}>Create</Button>
            </div>
        </div>}>
            <div ref={ref} style={{ display: 'inline-block' }}>
                <Tag onClick={() => { }} renderIcon={Add16} type="high-contrast">showtime</Tag>
            </div>
        </ButtonPopover>
    )
}


// const NewRecurrenceBtn = ({ day, cinemaId, movieId, hallId, itemMovie, itemHall, schedules, setSchedules }) => {
//     const [visible, setVisible] = useState(false);
//     const [openInvalidate, setOpenInvalidate] = useState(() => Util.newTempId());
//     const [newTimeValue, setNewTimeValue] = useState("");
//     const [threeD, setThreeD] = useState(false);
//     const [rule, setRule] = useState("");

//     const [modalVisible, setModalVisible] = useState(false);

//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//         if (!visible) {
//             setNewTimeValue("")
//             setThreeD(false);
//         }
//     }, [visible])

//     const onCreateBtn = () => {
//         setLoading(true);
//         Api.createShowtimeSchedule({
//             cinemaId, movieId, hallId,

//             day, threeD,
//             time: newTimeValue
//         }, response => {
//             if (response.status === true) {
//                 UIUtil.showSuccess();
//                 setOpenInvalidate(Util.newTempId());

//                 const newSchedule = response.payload
//                 setSchedules(schedules => ({
//                     ...schedules,
//                     movies: schedules.movies.map(movie => movie.id == itemMovie.id ? ({
//                         ...movie,
//                         halls: movie.halls.map(hall => hall.id == itemHall.id ? ({
//                             ...hall,
//                             schedules: [
//                                 ...hall.schedules,
//                                 newSchedule
//                             ]
//                         }) : hall)
//                     }) : movie)
//                 }))
//             } else {
//                 UIUtil.showError(response.message);
//             }
//             setLoading(false);
//         })
//     }

//     const ref = useRef();
//     return (
//         <ButtonPopover parentRef={ref} reversed invalidateOpen={openInvalidate} openListener={setVisible} shouldBeInvisible={modalVisible} popover={<div style={{ padding: '1rem', display: modalVisible ? 'none' : undefined }}>
//             <RecurringView setValue={setRule} value={rule} skipSwitcher portalModal setModalVisible={setModalVisible} />

//             <div style={{ marginTop: '1rem' }} onClick={() => setThreeD(t => !t)}>
//                 <Checkbox labelText="Is 3D" checked={threeD} />
//             </div>

//             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1rem', gap: '0.25rem' }}>
//                 <Button onClick={() => setOpenInvalidate(Util.newTempId())} kind="secondary" size="sm" style={{ borderRadius: 25 }} renderIcon={ErrorFilled16}>Cancel</Button>
//                 <Button onClick={onCreateBtn} disabled={newTimeValue.split(":").length != 2} kind="primary" size="sm" style={{ borderRadius: 25 }} renderIcon={CheckmarkFilled16}>Create</Button>
//             </div>
//         </div>}>
//             <div ref={ref} style={{ display: 'inline-block' }}>
//                 <Tag onClick={() => { }} renderIcon={Add16} type="cyan">recurrence</Tag>
//             </div>
//         </ButtonPopover>
//     )
// }


const ScheduleAnotherHallBtn = ({ item, schedules, setSchedules }) => {
    const [selected, setSelected] = useState([]);
    useEffect(() => {
        if (selected && selected.length > 0) {
            setSelected([])

            const newHall = selected[0].raw;
            setSchedules(schedules => ({
                ...schedules,
                movies: schedules.movies.map(movie => movie.id == item.id ? ({
                    ...movie,
                    halls: [
                        ...movie.halls,
                        {
                            id: newHall.id,
                            name: newHall.value,
                            schedules: []
                        }
                    ]
                }) : movie)
            }))
        }
    }, [selected])

    const halls = useMemo(() =>
        schedules.allHalls.filter(hall => item.halls.find(selectedHall => hall.id == selectedHall.id) === undefined), [schedules, item])

    return (
        <div style={{ width: '100%', display: 'flex', paddingTop: '3rem', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
            <SelectionPopover
                title="Schedule for new hall" closeOnChange
                selectedItems={selected} setSelectedItems={setSelected}
                objectType={-1} items={halls}>
                <Button kind="secondary" size="sm" style={{ borderRadius: 25 }} renderIcon={AddFilled16}>Schedule new hall</Button>
            </SelectionPopover>
        </div>
    )
}


const ScheduleAnotherMovieBtn = ({ schedules, setSchedules }) => {
    const [selected, setSelected] = useState([]);
    useEffect(() => {
        if (selected && selected.length > 0) {
            setSelected([])

            const newMovie = selected[0].raw;
            setSchedules(schedules => ({
                ...schedules,
                movies: [
                    ...schedules.movies,
                    {
                        id: newMovie.id,
                        name: newMovie.value,
                        halls: []
                    }
                ]
            }))
        }
    }, [selected])

    const movies = useMemo(() =>
        schedules.allMovies.filter(movie => schedules.movies.find(selectedMovie => movie.id == selectedMovie.id) === undefined), [schedules])

    return (
        <div style={{ width: '100%', display: 'flex', paddingBlock: '3rem', justifyContent: 'center', alignItems: 'center' }}>
            <SelectionPopover
                title="Schedule for new movie" closeOnChange
                selectedItems={selected} setSelectedItems={setSelected}
                objectType={OBJECT_TYPE_MOVIE} items={movies}>
                <Button size="sm" style={{ borderRadius: 25 }} renderIcon={AddFilled16}>Schedule new movie</Button>
            </SelectionPopover>
        </div>
    )
}

const LoadingMessage = () => {
    return (
        <div className='really_centered-progress-bar' style={{ width: '100%', height: 'calc(100vh - 10rem - 35px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <InlineLoading />
        </div>
    )
}

export function Scheduler({ cinemaId, day }) {
    const [schedules, setSchedules] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        setSchedules(null)
        Api.getShowtimeSchedules(cinemaId, day, response => {
            if (response.status === true) {
                setSchedules(response.payload)
                setLoading(false);
            } else {
                UIUtil.showError(response.message);
            }
        })
    }, [cinemaId, day])

    const reloadData = () => {
        setLoading(true);
        setSchedules(null)
        Api.getShowtimeSchedules(cinemaId, day, response => {
            if (response.status === true) {
                setSchedules(response.payload)
                setLoading(false);
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    if (loading) {
        return <LoadingMessage />
    }

    return (
        <div style={{ padding: '1rem', }}>
            {schedules.movies.map(item =>
                <Item reloadData={reloadData} key={item.id} cinemaId={cinemaId} item={item} schedules={schedules} setSchedules={setSchedules} day={day} />)}
            <ScheduleAnotherMovieBtn schedules={schedules} setSchedules={setSchedules} />

            <div style={{ height: 500 }} />
        </div>
    )
}