import { InlineLoading, Tag } from "carbon-components-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Bg from '../../images/_bg-test.jpeg'
import './board-view.scss'
import {
    Time16, Chat16, Alarm16, Hourglass16
} from '@carbon/icons-react'
import ProfilePic from "../../components/ProfilePic";
import { ProfilePicGroup } from "../../components/ProfilePicGroup";
import { CardView, NoItem } from "./CardView";
import UIUtil from "../../util/UIUtil";
import Button from "../../components/Button";
import update from 'immutability-helper'
import { arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Column, Columns, DragOption, Item, SortColumnView } from "./sort-column-view";
import { AddBtn, ColumnView, ContentView, DeleteBtn, ItemDialog, RootView, Toolbar } from "./board-view-ui";
import Portal from "../../util/Portal";
import Util from "../../util/Util";
import html2canvas from 'html2canvas'
import { usePrint } from "../../util/usePrint";
import { BoardPrintContent } from "./print-content";
import ReactTooltip from "react-tooltip";
import NoteView from "../notes/NoteView";
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_BOARD_VIEW_ITEM } from "../../constants/ObjectTypes";
import NoteViewDialog2 from "../notes/NoteViewDialog2";
import { CommentView } from "../comment/comment-view";

function useDragState(setGroups, setItems) {
    const setItemGroup = (itemId, group) => {
        setItems(items => {
            const item = items.find(item => item.id == itemId);
            if (item) {
                return [
                    { ...item, group },
                    ...items.filter(item => item.id != itemId)
                ]
            } else {
                return items;
            }
        })
    }
    const sortItem = (activeId, overId) => {
        setItems(items => {
            const oldIndex = items.findIndex(item => item.id == activeId);
            const newIndex = items.findIndex(item => item.id == overId);

            return arrayMove(items, oldIndex, newIndex);
        });
    }

    const sortGroup = (activeId, overId) => {
        setGroups(groups => {
            const oldIndex = groups.indexOf(activeId)
            const newIndex = groups.indexOf(overId)

            return arrayMove(groups, oldIndex, newIndex);
        });
    }

    const deleteItemOrGroup = (id, isItem) => {
        if (isItem) {
            //item
            setItems(items => items.filter(item => item.id != id))
            // setItems(items => items.map(item => ({ ...item, archived: item.id == id })))
        } else {
            //group
            setItems(items => items.filter(item => item.group != id))
            setGroups(groups => groups.filter(group => group != id))
        }
    }

    return { setItemGroup, sortItem, sortGroup, deleteItemOrGroup }
}

function useGroupItems(groups, items, setItems) {
    return useMemo(() => {
        const makeItems = group => {
            const groupItems = items.filter(item => item.group === group);
            const groupItemIds = groupItems.map(item => item.id);
            const setGroupItems = arg => {
                if (typeof arg === "function") {
                    setItems(val => [...val.filter(item => item.group !== group), ...arg(val.filter(item => item.group === group))])
                } else {
                    setItems(val => [...val.filter(item => item.group !== group), ...arg])
                }
            }
            return [groupItems, groupItemIds, setGroupItems]
        }
        return groups
            .reduce((acc, group) => ({ ...acc, [group]: makeItems(group) }), {})
    }, [groups, items, setItems])
}

function useAddGroup(setGroups) {
    const [scrollInto, setScrollInto] = useState(undefined);

    useEffect(() => {
        if (scrollInto) {
            setScrollInto(undefined);
        }
    }, [scrollInto])

    const addGroup = () => setGroups(groups => {
        let groupName = null;
        let i = 0;
        do {
            const nextName = "Untitled" + (i > 0 ? ' (' + (i + 1) + ')' : '');
            const taken = groups.find(group => group === nextName);
            if (!taken) {
                groupName = nextName;
            }
            i++;
        } while (groupName === null)

        setScrollInto(groupName);
        return [...groups, groupName]
    })

    return [scrollInto, addGroup];
}

function useAddItem(groupItems) {
    const [scrollInto, setScrollInto] = useState(undefined);

    useEffect(() => {
        if (scrollInto) {
            setScrollInto(undefined);
        }
    }, [scrollInto])

    const addItem = group => groupItems[group][2](items => {
        const newItem = {
            id: Util.newTempId(), group,
            content: "New Item"
        };
        setScrollInto(newItem)
        return [newItem, ...items]
    })

    return [scrollInto, addItem];
}

function useRenameGroup(groups, setGroups, setItems) {
    const rename = (oldName, newName) => {
        if (!Util.isStringExists(newName)) {
            return "Name is required!";
        }
        if (groups.find(group => group == newName) !== undefined) {
            return "Name already exists!";
        }

        setGroups(groups => groups.map(group => group === oldName ? newName : group))
        setItems(items => items.map(item => item.group === oldName ? ({ ...item, group: newName }) : item))

        return null;
    }
    return rename;
}

function useUpdateItem(setItems) {
    const updateItem = (id, update) => {
        setItems(items => items.map(item => item.id == id ? ({ ...item, ...update }) : item))
    }
    return updateItem;
}

const GroupView = ({ group, groupItems, scrollIntoGroup, scrollIntoItem, onAddBtn, renameGroup, setItemContent, onCommentBtn, onNoteBtn }) => {
    const [items, itemIds, setItems] = groupItems[group]

    return (
        <Column columnId={group} itemIds={itemIds}>
            {isOver => (
                <ColumnView isOver={isOver} group={group} scrollInto={scrollIntoGroup} onAddBtn={onAddBtn} renameGroup={renameGroup}>
                    {items.map(item => (
                        <Item key={item.id} itemId={item.id} columnId={group}>
                            <CardView item={item}
                                scrollInto={scrollIntoItem(item)}
                                onCommentBtn={() => onCommentBtn(item)}
                                onNoteBtn={() => onNoteBtn(item)}
                                onContentUpdate={content => setItemContent(item.id, content)} />
                        </Item>
                    ))}
                </ColumnView>
            )}
        </Column>
    )
}

export function BoardView({
    loading, boardViews, selectedId, selectId, dark, setDark, title, setTitle, groups, setGroups, items, setItems, allUsers,
    assignations, setAssignations, canAssign
}) {
    const [showItemNote, setShowItemNote] = useState(undefined)
    const [showItemComment, setShowItemComment] = useState(undefined)

    const groupItems = useGroupItems(groups, items, setItems);

    const dragState = useDragState(setGroups, setItems);
    const dragOverlay = active => (
        active?.data?.current?.isColumn === true ? (
            <ColumnView group={active?.id}>
                {groupItems[active?.id][0].map(item => (
                    <Item key={item.id} itemId={item.id} columnId={active?.id}>
                        <CardView item={item} />
                    </Item>
                ))}
            </ColumnView>
        ) : (
            <CardView item={items?.find(item => item.id == active.id)} haveBackground />
        )
    )

    const [scrollIntoGroup, addGroup] = useAddGroup(setGroups);
    const [scrollIntoItem, addItem] = useAddItem(groupItems)

    const updateItem = useUpdateItem(setItems);
    const setItemContent = (id, content) => updateItem(id, { content })
    const renameGroup = useRenameGroup(groups, setGroups, setItems);

    const { PrintContent, print } = usePrint();

    const onNoteBtn = item => setShowItemNote(item)
    const onCommentBtn = item => setShowItemComment(item)

    return (
        <SortColumnView dragState={dragState} columnIds={groups}>
            <RootView dark={dark}>
                <Toolbar title={title} dark={dark} setDark={setDark} onRenameBtn={setTitle} onPrintBtn={print}
                    {...({ boardViews, selectedId, selectId, allUsers, assignations, setAssignations, canAssign })} />

                <ContentView dark={dark}>
                    <Columns dragState={dragState} dragOverlay={dragOverlay}>
                        {groups.map(group => (
                            <GroupView key={group} group={group} groupItems={groupItems}
                                scrollIntoGroup={scrollIntoGroup === group}
                                scrollIntoItem={item => scrollIntoItem === item}
                                setItemContent={setItemContent}
                                onAddBtn={() => addItem(group)}
                                renameGroup={renameGroup}
                                onNoteBtn={onNoteBtn} onCommentBtn={onCommentBtn}
                            />
                        ))}
                    </Columns>
                    <AddBtn onClick={addGroup} />
                </ContentView>


                <DragOption dropId="delete-zone" onDropped={dragState.deleteItemOrGroup}>
                    {isOver => <DeleteBtn highlight={isOver} />}
                </DragOption>
            </RootView>

            {showItemNote &&
                <NoteViewDialog2 open={showItemNote !== undefined} onClose={() => setShowItemNote(undefined)} objectId={showItemNote.id} objectType={OBJECT_TYPE_BOARD_VIEW_ITEM}>
                    <div style={{ overflow: 'auto', maxHeight: 150, marginBottom: '1rem', }}>
                        <p style={{ whiteSpace: 'pre-line' }}>{showItemNote.content}</p>
                    </div>
                </NoteViewDialog2>}
            {showItemComment &&
                <ItemDialog label="Comments" title="All Comments" open={showItemComment !== undefined} onClose={() => setShowItemComment(undefined)} item={showItemComment}>
                    <CommentView itemId={showItemComment.id} itemType={OBJECT_TYPE_BOARD_VIEW_ITEM} />
                </ItemDialog>}

            <PrintContent>
                <BoardPrintContent title={title} groups={groups} items={items} />
            </PrintContent>

            <ReactTooltip />

            {loading && <div style={{ animation: 'actual-opacity-fade-in-anim 250ms', position: 'absolute', left: 0, top: 0, width: '100vw', height: '100vh', background: dark ? '#00000080' : '#ffffff80', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
                {/* <p style={{ color: 'white', color: dark ? 'white' : 'black', fontSize: 12, opacity: 0.65 }}>Loading...</p> */}
            </div>}
        </SortColumnView>
    )
}