import { useEffect } from "react";
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { isRivas } from "../../../app/app-util";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_CASHIER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { DateRange } from "../../components/basic-filter/date-range";
import { DateTimeRange } from "../../components/basic-filter/date-time-range";
import { Divider } from "../../components/basic-filter/divider";
import { MultiSelect } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box";
import { startOfDay, addDays } from 'date-fns';
import { getReportLink } from "../../base/report-page";
import Util from "../../../util/Util";
import UIUtil from "../../../util/UIUtil";

const detailLink = (data, voucherType) => {
    const state = {
        filters: [
            { "property": "date", "operator": "GTE", "id": "date-start", "value": data._startDate },
            { "property": "date", "operator": "LT", "id": "date-end", "value": data._endDate },
            { "property": "methodType", "operator": "EQ", "id": "methodType", "value": data.methodType },
            ...(voucherType ? [
                { "property": "voucherType", "operator": "EQ", "id": "voucherType", "value": voucherType },
            ] : []),
            ...(data._filter_storeName ? [
                { "id": "storeName", "operator": "OR", "filters": [{ "property": "storeName", "operator": "EQ", "value": data._filter_storeName }] }
            ] : []),

            ...(data._againstFilter ? [
                { "id": "againstFilter", "skipAutoFilter": true, "operator": "OR", "filters": [{ "property": "againstFilter", "skipAutoFilter": true, "operator": "EQ", "value": data._againstFilter }] }
            ] : []),
            ...(data._orderFilter ? [
                { "id": "orderFilter", "skipAutoFilter": true, "operator": "OR", "filters": [{ "property": "orderFilter", "skipAutoFilter": true, "operator": "EQ", "value": data._orderFilter }] }
            ] : []),
        ],

        ...(data._filter_storeName ? {
            storeName: { label: data._filter_storeName }
        } : {}),

        ...(data._againstFilter ? {
            againstFilter: { label: data._againstFilter }
        } : {}),
        ...(data._orderFilter ? {
            orderFilter: { label: data._orderFilter }
        } : {}),
    }

    // console.log({ filters, data })
    return getReportLink("PaymentMethodDetail", state);
}


export const PaymentMethodReport = {
    filter: Filter,

    isAvailable: () => {
        const role = getAccountRole();
        if (hasCapabilitySupport("restaurant")) {
            return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT || role == ACCOUNT_TYPE_CASHIER;
        } else {
            return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
        }
    },

    onAction: (action, data, history) => {
        // if (Util.isStringExists(data._againstFilter)) {
        //     UIUtil.showInfo("Against filter is not applicable in detail report. Please clear it to continue.")
        //     return;
        // }

        // if (Util.isStringExists(data._orderFilter)) {
        //     UIUtil.showInfo("Order filter is not applicable in detail report. Please clear it to continue.")
        //     return;
        // }

        switch (action) {
            case "receipt":
                window.open(detailLink(data, 'Receipt'), '_blank')
                break;
            case "payment":
                window.open(detailLink(data, 'Payment'), '_blank')
                break;
            case "diff":
                window.open(detailLink(data), '_blank')
                break;
        }
    }
}

const AGS_OPTS = [
    "Against Order Only",
    "Credit/Debits Only",
]

const ORDER_OPTS = [
    "Orders/sales in range",
    "Orders/sales out of range",
]


function Filter({ endpoint, state }) {
    const [filters, setFilters] = state.use("filters", [])
    useEffect(() => {
        if (!filters.length) {
            setFilters([
                { id: "date-start", property: "date", operator: "GTE", value: startOfDay(new Date()).getTime() },
                { id: "date-end", property: "date", operator: "LT", value: startOfDay(addDays(new Date(), 1)).getTime() },
            ])
        }
    }, [])
    return (<>
        <DateTimeRange state={state} property="date" />

        <Divider />
        <div style={{ height: 30, width: 250 }}>
            <MultiSelect isMulti={false} placeholder="Store" fieldKey="storeName" options={endpoint.stores.map(item => item.value)} state={state} property="storeName" />
        </div>
        {hasCapabilitySupport("restaurant") && <>
            <div style={{ height: 30, width: 250 }}>
                <MultiSelect isMulti={false} skipAutoFilter placeholder="Against Filter" fieldKey="againstFilter" property="againstFilter" options={AGS_OPTS} state={state} />
            </div>
            <div style={{ height: 30, width: 250 }}>
                <MultiSelect isMulti={false} skipAutoFilter placeholder="Order Filter" fieldKey="orderFilter" property="orderFilter" options={ORDER_OPTS} state={state} />
            </div>
        </>}
    </>)
}
