import Button from "../../../components/Button";
import { Tag16 } from '@carbon/icons-react'
import { Modal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { useState } from "react";


export function ApplyCouponButton({ salesOrderId, onUpdate }) {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button onClick={() => setVisible(true)} size="sm" style={{ borderRadius: 50 }} renderIcon={Tag16}>Apply Coupon</Button>

        <FormDialog customPositiveLabel="Apply" open={visible} onClose={() => setVisible(false)} label="Apply" title="Coupon" item={{ code: "" }} setItem={onUpdate}
            saveApi={(data, listener) => Api.applySalesOrderCoupon(salesOrderId, data.code, listener)}>
            {({ TextField, SwitchField, }) => (<>
                <TextField title="Coupon Code" fieldKey="code" />
            </>)}
        </FormDialog>
    </>)
}