import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { FNB_EVENT_TYPE, SHIPMENT_DIR_TYPE } from "../.."
import Api from "../../../../../session/Api"
import Util from "../../../../../util/Util"
import { UserAssignation } from "../../../../activities/components/user-assignation"
import Button from "../../../../../components/Button"
import { Close16, ArrowRight16, Activity16, Locked16 } from '@carbon/icons-react'
import UIUtil from "../../../../../util/UIUtil"
import useApi from "../../../../../hooks/useApi"
import { getAccount } from "../../../../../session/SessionManager"
import { Tag } from "carbon-components-react"

const ValueEntry = ({ label, value }) => (<>
    <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: 1, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: 2, outline: 'none', textAlign: 'end' }}>{value}</p>
    </div>
</>)

export function Detail({ fnbEvent, form, globalState }) {

    return (
        <div style={{ width: '100%', paddingBlock: '1.6rem', paddingInline: '0rem' }}>

            <h2 style={{ fontWeight: 'bold', paddingInline: '2rem', paddingBottom: '1rem', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                Details
            </h2>
            <div style={{ width: '100%', marginBottom: '3rem', marginTop: '1rem', paddingInline: '1rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <ValueEntry label="Event No" value={fnbEvent.eventNo} />
                    <ValueEntry label="Type" value={fnbEvent.type === FNB_EVENT_TYPE.BANQUET ? 'Banquet' : 'Catering'} />
                    <ValueEntry label="Created By" value={<Link to={'/staff-users/' + fnbEvent.userId}>{fnbEvent.createdByFullName}</Link>} />
                    <ValueEntry label="Created On" value={Util.getDate(fnbEvent.createDate)} />
                    <ValueEntry label="Last Update On" value={Util.getDate(fnbEvent.updateDate)} />
                </div>
            </div>

            <div style={{ height: 300 }} />
        </div>
    )
}