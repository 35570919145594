import Select from 'react-select'

const styles = {
    container: (baseStyles) => ({
        ...baseStyles,
        paddingRight: 1,
        background: 'transparent',
        height: '100%',
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        padding: '0 6px'
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 5,
        background: '#ffffff10',
        border: '0px solid #00000099',
        outline: 'none',
        color: 'black',
        minHeight: 30,
        height: '100%',
        padding: 0
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: 'black',
        height: '100%',
        margin: 0
    }),
    indicatorsContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
}

export function ClassFilter({ value, setValue, placeholder, options }) {
    return (
        <Select
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            styles={styles}
            isMulti options={options.map(opt => ({ value: opt.id, label: opt.value }))} />
    )
}