import { withLoadablePageWithParams } from "../../base/Page"
import Api from "../../session/Api"

import {
    Ticket32, Theater32, QrCode20
} from '@carbon/icons-react'
import { Tag } from "carbon-components-react"
import UIUtil from "../../util/UIUtil"

import AddToWallet from '../../images/add-to-apple-wallet.png'
import { Link } from "react-router-dom"


import { ArrowLeft32, Ticket24, Calendar24, Home16, CheckmarkFilled32, CheckmarkFilled16, Theater24, Receipt20 } from '@carbon/icons-react'
import Logo from '../../images/star-cinema.png'
import { big } from "../../util/Util";
import Button from "../../components/Button"


const View = ({ payload: serviceSale }) => {
    const openTicketQrCode = ticket => {
        UIUtil.showQrCode('TI-ID-' + btoa((ticket.id).toString()))
    }
    const openTicketPassQrCode = ticket => {
        UIUtil.showQrCode(window.location.origin + Api.getMovieTicketWalletPass(ticket.id), { label: "Apple Wallet", title: "Scan to add" })
    }

    return (
        <div style={{ minHeight: '100vh', background: '#0f0f0f' }}>
            <div style={{ width: '100%', height: '6rem', background: '#1b1c3c', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ffffff10' }}>
                <div style={{ width: '6rem' }}></div>
                <div style={{ flex: 1 }} />
                <Link to="/cinema-web">
                    <img src={Logo} style={{ height: 75 }} />
                </Link>
                <div style={{ flex: 1 }} />
                <div style={{ width: '6rem' }}></div>
            </div>
            <div style={{ width: '100%', height: '3rem', background: '#11122b', paddingInline: '6rem', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <Ticket24 />
                <h4 style={{ flex: 1 }}>Tickets</h4>
            </div>

            <div style={{ marginTop: '3rem', width: '100%', display: 'flex', color: 'white', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingInline: '6rem', gap: '1rem', }}>
                <CheckmarkFilled32 style={{ color: 'green', width: 128, height: 128 }} />
                <h2 style={{ marginTop: '1rem' }}>Thank you!</h2>
                <h4>Hope you'll enjoy your movie!</h4>

                <div style={{ marginTop: '3rem', marginBottom: '1rem', width: '75%' }}>
                    {serviceSale.ticketSaleTickets.map(ticket => (
                        <div key={ticket.id} style={{
                            marginBottom: '1rem', background: '#0f0f0f', border: '1px solid #ffffff20', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                            display: 'flex', alignItems: 'center'
                        }}>
                            <Ticket32 />
                            <div style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                                <p>Ticket</p>
                            </div>
                            <Tag type='blue'>{ticket.seatName}</Tag>

                            <div style={{ flex: 1, minWidth: '1rem' }} />
                            {window.navigator.platform === "iPhone" && <a download href={Api.getMovieTicketWalletPass(ticket.id)}>
                                <img style={{ height: 45 }} src={AddToWallet} />
                            </a>}
                            {window.navigator.platform !== "iPhone" &&
                                <img onClick={() => openTicketPassQrCode(ticket)} style={{ height: 45, cursor: 'pointer' }} src={AddToWallet} />}
                            <button style={{ marginLeft: '1rem' }} onClick={() => openTicketQrCode(ticket)} className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                <QrCode20 />
                            </button>
                        </div>
                    ))}
                </div>

                <Link to="/cinema-web">
                    <Button renderIcon={Home16} size="sm" style={{ borderRadius: 25 }}>Go Back Home</Button>
                </Link>
            </div>
            <div style={{ height: '3rem' }} />


            <div style={{ height: '3rem' }} />
        </div>
    )
}

export const CinemaDemoTicketSalePage = withLoadablePageWithParams(params => listener => Api.getServiceSale(params.serviceSaleId, listener), View)