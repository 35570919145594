import { useCallback } from "react"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { AmountTagSelectorNormalField } from "../../../pages/journal-entries/tag-selector"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection, FormSectionWithoutMargin } from "../../../templates/form/form"
import { newListDetail } from "../../base/list-detail"
import { Migrate32, Migrate24, CheckmarkFilled16 } from '@carbon/icons-react'
import { TAX_TYPES } from "../../../2v/modules/sales"
import { BillItemsTable } from "./bill-items-table"
import Util from "../../../util/Util"
import Button from "../../../components/Button"
import { FinanceSummaryBar } from "./financials"
import { FormMapper } from "./form-mapper"
import { DetailView } from "./detail-view"
import { DateRange2 } from "../../components/basic-filter/date-range"
import { MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { BillItemsTable2 } from "./bill-items-table-2"


export const V2BillList = newListDetail({
    title: "Bill",
    icon: Migrate32,
    mdIcon: Migrate24,

    filter: Filter, newForm: Form, updateForm: DetailView,
    canDelete: false, canSave: false, fullScreenSideView: true, skipInlinePadding: true,

    defaultValues: {
        billDate: new Date().getTime(),
        dueDate: new Date().getTime(),
        items: [{ tempId: Util.newTempId() }],
        // items: [],
        taxId: TAX_TYPES[0].id,
        tags: []
    },
    finalFormMapper: FormMapper,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})


export const V2BillForm = Form;
function Form({ endpoint, form, save, saveLoading, }) {
    const TagSelector = useCallback(({ predefinedClasses, value, setValue }) => (
        <AmountTagSelectorNormalField options={predefinedClasses} value={value} setValue={setValue} />
    ), [])
    const Items = useCallback(({ billables, predefinedClasses, locations, editable, value, setValue }) => (
        <BillItemsTable {...{ billables, predefinedClasses, locations }} editable={editable} items={value} setItems={setValue} />
        // <BillItemsTable2 {...{ billables, predefinedClasses, locations }} value={value} setValue={setValue} />
    ), [])


    return (
        <div>
            <div style={{ paddingInline: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'stretch', gap: '1rem', marginBottom: '3rem' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: '1rem', alignContent: 'space-between', flex: 2 }}>
                        <form.ComboBoxField _skipFormMargin fieldKey="vendorId" title="Vendor" options={endpoint.vendors} />
                        <form.TextField _skipFormMargin fieldKey="voucherNo" title="Voucher No" />

                        <form.DateField _skipFormMargin fieldKey="billDate" title="Bill Date" />
                        <form.DateField _skipFormMargin fieldKey="dueDate" title="Due Date" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <form.TextAreaField _skipFormMargin style={{ height: 124 }} fieldKey="memo" title="Memo (optional)" />
                    </div>
                </div>
                <FormSectionWithoutMargin title="Items">
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', columnGap: '1rem', marginBottom: '0rem' }}>
                        <form.ComboBoxField fieldKey="destinationId" title="Default Destination" options={endpoint.locations} />
                        <form.ComboBoxField fieldKey="taxId" title="Default Tax Code" options={TAX_TYPES} />
                        <div>
                            <form.CustomField fieldKey="tags" title="Class" childrenProps={{ predefinedClasses: endpoint.predefinedClasses }}>
                                {TagSelector}
                            </form.CustomField>
                        </div>
                    </div>
                </FormSectionWithoutMargin>
            </div>
            <div style={{ height: '3rem' }} />
            <form.CustomField fieldKey="items" childrenProps={{ billables: endpoint.billables, locations: endpoint.locations, predefinedClasses: endpoint.predefinedClasses, editable: true, }}>
                {Items}
            </form.CustomField>

            <form.ExtField _skipFormMargin childrenProps={{ saveLoading, save }}>
                {FinanceSummaryBar}
            </form.ExtField>

            <div style={{ height: '6rem' }} />
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="billDate" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Vendor" fieldKey="vendorField" options={endpoint.vendors.map($ => $.value)} state={state} property="vendors" operator="CONTAINS" />
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <TextBox state={state} label="Voucher No" property="voucherNo" fullWidth />
        </div>
    </>)
}
