import { ClickableTile } from 'carbon-components-react';
import React from 'react'
import { useState } from 'react';
import Page from '../../base/Page';

import { DocumentImport32, DocumentBlank32, ArrowRight20 } from '@carbon/icons-react'
import { IMPORTABLE_OBJECT_TYPES, IMPORTABLE_OBJECT_TYPES_PRODUCT_MANAGER } from './ImportableObjectTypes';

import { Link } from 'react-router-dom'
import GridListTransition from '../../components/grid-list-transition/GridListTransition';
import { getAccountRole } from '../../session/SessionManager';
import { ACCOUNT_TYPE_PRODUCT_MANAGER } from '../../constants/Constants';

const ObjectTypeItem = ({ icon, title, desc }) => {
    const [hover, setHover] = useState(false)

    return (
        <ClickableTile onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ overflow: 'hidden', display: 'flex', alignItems: 'center', width: '100%', height: 75, borderRadius: 5, marginBottom: '0.25rem' }}>
            {React.createElement(icon !== undefined ? icon : DocumentBlank32, {
                style: {
                    width: 32,
                    height: 32,
                    marginRight: '0.5rem'
                }
            })}
            <h3 style={{ flex: 1 }}>{title}</h3>

            <p style={{ transition: '250ms', fontSize: 14, opacity: hover ? 0.65 : 0, marginRight: '1rem' }}>Import data</p>
            <ArrowRight20 style={{ transition: '250ms', opacity: hover ? 1 : 0 }} />
        </ClickableTile>
    )
}


class ImportDataPage extends Page {

    accountRole = getAccountRole();

    render() {
        const types = this.accountRole == ACCOUNT_TYPE_PRODUCT_MANAGER ? IMPORTABLE_OBJECT_TYPES_PRODUCT_MANAGER : IMPORTABLE_OBJECT_TYPES;
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }} className="menu-tile">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3rem' }}>
                        <DocumentImport32 style={{ width: 64, height: 64, marginRight: '0.5rem' }} />
                        <div>
                            <h1>Import Data</h1>
                            <p style={{ fontSize: 18 }}>Import spreadsheet data into system</p>
                        </div>
                    </div>

                    <p style={{ opacity: 0.65, marginBottom: '1rem' }}>Select data type</p>

                    {types.map(dataType => (
                        <Link to={'/import-data/' + dataType.objectType} style={{ textDecoration: 'none' }}>
                            <ObjectTypeItem key={dataType.objectType} icon={dataType.icon} title={dataType.title} />
                        </Link>
                    ))}
                </div>
            </div>
        )
    }

}

export default ImportDataPage;