import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import Util from "../../../util/Util";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box"

export const PurchaseReport = {
    filter: Filter,

    // newLink: '/stock-flow-creator',
    itemToOpenLink: item => `/stock-flow/${Util.getIdFromVoucherNumber(item.voucherNo)}`,
    customPath: "/purchases-report",

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT || role == ACCOUNT_TYPE_PRODUCT_MANAGER;
    },
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="purchaseDate" />
        <Divider />

        <div style={{ height: 30, marginTop: '0rem', width: 300 }}>
            <MultiSelect isMulti={false} placeholder="Supplier" key="purchaseReportSourceName" options={endpoint.suppliers.map(item => item.value)} state={state} property="sourceName" />
        </div>

        {/* <TextBox state={state} label="Source" property="sourceName" />
        <TextBox state={state} label="Customer" property="customerName" />
        <TextBox state={state} label="Product" property="products" /> */}

        {/* <TextBox state={state} label="Amount Status" property="amountStatus" /> */}
    </>)
}
