import React from 'react'
import Util from '../../util/Util';
import { RadialChart } from 'react-vis';

import { ShoppingCartArrowUp24, SettingsCheck24, Purchase24, Category24, CategoryNewEach24, Product24, DeliveryParcel24 } from '@carbon/icons-react';

const Chart = ({items}) => (
    <RadialChart
    data={items.map(item => ({ label: item.name, angle: item.totalPercentage }))}
    showLabels
    margin={{bottom: 100}}
    width={300}
    height={300} />
)

const Group = ({report, icon, title, property, showAmount}) => (
    <div style={{marginBottom: '0.5rem',}}>
        <div style={{height: 15, background: '#f4f4f4'}} />
        <div style={{width: '100%', display: 'flex', paddingLeft: '1rem', paddingRight: '1rem', alignItems: 'center', height: 30, background: '#f4f4f4'}}>
            {React.createElement(icon)}
            <h4 style={{fontSize: 18, marginLeft: '0.5rem', fontWeight: 'bold', flex: 1}}>{title}</h4>
        </div>
        <div style={{height: 15, background: '#f4f4f4'}} />
        
        <div style={{ display: 'flex',}}>
            <div style={{flex: 1, padding: '1rem', marginRight: '1rem'}}>
                {report[property].map(item => (
                    <Item key={item.id + "-" + property} showAmount={showAmount} item={item} />
                ))}

                <div style={{width: '100%', display: 'flex', alignItems: 'center', marginTop: '1rem', gap: '1rem'}}>
                    <div style={{flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        
                    </div>
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        
                    </div>
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', borderTop: '1px solid #00000020', paddingTop: '0.5rem'}}>
                        {/* <p style={{fontSize: 18, fontWeight: 'bold'}}>{report[property + 'TotalAmount'] != null ? `AED ${report[property + 'TotalAmount'].toFixed(2)}` : report[property + 'TotalQuantity']}</p> */}
                        <p style={{fontSize: 18, fontWeight: 'bold'}}>{showAmount ? `AED ${report[property + 'TotalAmount'].toFixed(2)}` : report[property + 'TotalQuantity']}</p>
                    </div>
                </div>
            </div>
            <Chart items={report[property]} /> 

            
        </div>
    </div>
)

const Item = ({item, showAmount}) => (
    <div style={{width: '100%', display: 'flex', alignItems: 'center', marginBottom: '0.5rem', gap: '1rem'}}>
        <div style={{flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            <p>{item.name}</p>
        </div>
        <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <p>{item.totalPercentage}%</p>
        </div>
        <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            {/* <p>{item.amount != null ? `AED ${item.amount.toFixed(2)}` : item.quantity}</p> */}
            <p>{showAmount ? `AED ${item.amount.toFixed(2)}` : item.quantity}</p>
        </div>
    </div>
)

class SalesSummaryView extends React.Component {

    render() {
        const report = this.props.report;
        return (
            <div style={{width: '100%', marginTop: '1rem'}}>
                <Group report={report} icon={ShoppingCartArrowUp24} title="Sales by staff" property="salesByStaff" showAmount />
                <Group report={report} icon={Purchase24} title="Sales by payment method" property="salesByPaymentMethod" showAmount />

                {!this.props.posMode && <Group report={report} icon={SettingsCheck24} title="Sales by type" property="salesByType" showAmount />}

                <Group report={report} icon={Category24} title="Sales by product category" property="salesByItemCategory" showAmount />
                <Group report={report} icon={CategoryNewEach24} title="Quantities by product category" property="quantityByItemCategory" />

                <Group report={report} icon={Product24} title="Top 10 products by sales" property="salesByItem" showAmount />
                <Group report={report} icon={DeliveryParcel24} title="Top 10 products by quantities" property="quantityByItem" />
            </div>
        )
    }

}

export default SalesSummaryView;