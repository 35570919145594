import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import Util from "../../../util/Util";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { LocationFilter } from "./location-filter";
import { ProductFilter } from "./product-filter";

export const ProductStockReport = {
    customPath: "/product-stock-report",
    filter: Filter,
    payload: Payload,
    hasPayloadBar: true,
    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_PRODUCT_MANAGER;
        // return false;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter />
        <Divider />
        <div style={{ minHeight: 30, flex: 1 }}>
            <ProductFilter state={state} options={endpoint.products} />
        </div>
        <div style={{ height: 30, flex: 1 }}>
            <LocationFilter state={state} accountTree={endpoint.locations} />
        </div>
    </>)
}



function Payload({ payload }) {
    return (<>
        <p style={{ fontSize: 16, opacity: 1, marginRight: '0.25rem' }}>As of {Util.getStatementDate(payload.asOfDate)}</p>
        <div style={{ flex: 1 }} />
        <p style={{ fontSize: 12, opacity: 0.65, fontWeight: 'bold', marginRight: '0.25rem' }}>Closing Stock:</p>
        <p>{Util.formatAmount(payload.stock)}</p>
    </>)
}