import { ComboBox, TextInput } from "carbon-components-react"
import { TableInput } from "../../../components/table-input"
import { useMemo } from "react"

export const DeliveryCaptureList = ({ services, value, setValue }) => {
    const cols = useMemo(() => ([
        {
            key: "itemId", title: "Item", flex: 1, Component: ({ value, setValue }) => {
                return (
                    <div style={{ width: '100%' }}>
                        <ComboBox
                            placeholder="Select item" items={services}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={services.find($ => $.id === value)}
                            onChange={e => setValue(e.selectedItem?.id)} />
                    </div>
                )
            }
        },
    ]), [services])

    return (
        <TableInput
            value={value} setValue={setValue}
            columns={cols} />
    )
}