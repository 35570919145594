
import { ContentSwitcher, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16, FlowConnection16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import { LogicNodeEditorDialog } from '../../../views/logic-node-editor/LogicNodeEditorDialog';
import Util from '../../../util/Util';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved, openEditor }) => {
    const [name, setName] = useMultiInputState(input, "name", onInputUpdate)
    const [logicData, setLogicData] = useMultiInputState(input, 'logic', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <TextInput size="lg" labelText="Rule Name" value={name} onChange={e => setName(e.target.value)} />
            <div style={{ height: 48, flex: 1, alignSelf: 'flex-end' }}>
                <Button onClick={() => openEditor(name, logicData, setLogicData)}
                    renderIcon={FlowConnection16} style={{ width: '100%', maxWidth: 'unset', alignSelf: 'flex-end' }}>Edit Rule Logic</Button>
            </div>

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const AutomationListEditor = ({ defaultItems, onUpdate, getConfigOptions }) => {
    const [inputEditorKey, setInputEditorKey] = useState(() => Util.newTempId());

    const [editorName, setEditorName] = useState("");
    const [editorVisible, setEditorVisible] = useState(false);
    const [editorValue, setEditorValue] = useState({});
    const [editorConfig, setEditorConfig] = useState({ statuses: [], fields: [] })
    const valueSetter = useRef();

    const onValueChange = value => {
        if (valueSetter.current) {
            valueSetter.current(value);
        }
    }

    const openEditor = useCallback((name, value, setValue) => {
        valueSetter.current = setValue;

        setEditorConfig(getConfigOptions())
        setEditorName(name);
        setEditorValue(value)
        setEditorVisible(true)
    }, [])

    // useEffect(() => {
    //     setInputEditorKey(Util.newTempId())
    // }, [defaultItems])
    return (
        <>
            <MultipleInputEditor key={inputEditorKey} defaultInputs={[]} inputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} openEditor={openEditor} />
            <LogicNodeEditorDialog name={editorName} open={editorVisible} onClose={() => setEditorVisible(false)}
                statuses={editorConfig.statuses}
                fields={editorConfig.fields}
                data={editorValue}
                onSave={data => {
                    setEditorVisible(false);
                    onValueChange(data)
                }} />
        </>
    )
}