import { Add16 } from '@carbon/icons-react'
import { useState } from 'react';
import Button from "../../components/Button";
import UIUtil from '../../util/UIUtil';
import ItemCreatorDialog from '../ItemCreatorDialog';


export function NewItemDialogBtn({ objectType, onSuccess }) {
    const [visible, setVisible] = useState(false)
    return (<>
        <Button onClick={() => setVisible(true)} kind="tertiary" renderIcon={Add16}>Create New</Button>

        <div className="full-size-dialog">
            <ItemCreatorDialog
                fullSized preventDeletePhraseModal productFinderShowFakeTextFieldAtFirst
                objectType={objectType}
                open={visible} onClose={() => setVisible(false)}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setVisible(false)
                    onSuccess?.(createdItem)
                }} />
        </div>
    </>)
}