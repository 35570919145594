import { useEffect, useMemo, useState } from "react";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import usePosSession from "../state/usePosSession";
import useStore from "../../../hooks/useStore";
import { ComboBox } from "carbon-components-react";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";


export function Courier({ sessionStore, editable }) {
    const session = usePosSession(sessionStore)
    const [courierId] = useStore(sessionStore, "courierId")

    const [loading, setLoading] = useState(true);
    const [couriers, setCouriers] = useState([]);

    useEffect(() => {
        Api.getSalesOrderAvailableCouriers(response => {
            if (response.status === true) {
                setCouriers(response.payload);
                setLoading(false);
            } else {
                UIUtil.showError("Failed to load couriers list");
            }
        })
    }, [])

    const courier = useMemo(() => couriers.find($ => $.id === courierId), [couriers, courierId]);

    if (editable) {
        return (
            <ComboBox
                key={'courier-combo-' + courierId}
                light
                className="no-padding-menu"
                titleText="Courier"
                disabled={loading || session.loading}
                items={couriers}
                selectedItem={courier}
                itemToString={item => item !== null ? item.value : ""}
                itemToElement={item => (
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', pointerEvents: 'none' }}>
                        <span style={{ marginLeft: '0.5rem' }}>
                            {item.value}
                        </span>
                    </div>
                )}
                placeholder="Select"
                onChange={e => session.setCourier(e.selectedItem !== null ? e.selectedItem.id : 0)}
            />
        )
    } else if (courier) {
        const item = courier;
        return (
            <div>
                <label className="bx--label" style={{ marginBottom: 0 }}>Courier</label>
                <div style={{ display: 'flex', alignItems: 'center', height: 45, pointerEvents: 'none' }}>
                    <span style={{ marginLeft: '0.5rem' }}>
                        {item.value}
                    </span>
                </div>
            </div>
        )
    } else {
        return (<>
            <div>
                <label className="bx--label" style={{ marginBottom: 0 }}>Courier</label>
                <p style={{ width: 300 }}>{'No courier selected'}</p>
            </div>
        </>)
    }
}