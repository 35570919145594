import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { OBJECT_TYPE_DESIGNATION, OBJECT_TYPE_LEAVE, OBJECT_TYPE_LEAVE_TYPE, OBJECT_TYPE_PAY_HEAD, OBJECT_TYPE_SALARY_PACKAGE } from '../../../constants/ObjectTypes';
import Page from '../../../base/Page';
import Api from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';

class LeaveDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_LEAVE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_LEAVE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_LEAVE,
                    itemResult: this.state.itemResult,
                    pagePath: "/leaves/",
                    history: this.props.history,

                })}
            </div>
        )
    }

}

export default withRouter(LeaveDetailPage);