import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_ATTRIBUTES, OBJECT_TYPE_PRODUCT_BRAND, OBJECT_TYPE_PRODUCT_CATEGORY, OBJECT_TYPE_PRODUCT_DEPARTMENT } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import { ProductAttributeValuesView } from '../product/ProductAttributesView';


const ProductsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_PRODUCT_ATTRIBUTES,

                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Products",
                subTitle: "Products that have these product attributes",
                objectType: OBJECT_TYPE_PRODUCT,
                pagePath: "/products/",
                history: history,
                paginated: true,
            })}
        </div>
    )
}

// const AttributeValueSetter = ({ item }) => {
//     return <ProductAttributeValuesView productId={26229} />
// }

class ProductAttributesDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_PRODUCT_ATTRIBUTES, listener)
            } else {
                Api.getItem(OBJECT_TYPE_PRODUCT_ATTRIBUTES, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_PRODUCT_ATTRIBUTES,
                    itemResult: this.state.itemResult,
                    pagePath: "/product-attribute-packs/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Products",
                            component: ProductsList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(ProductAttributesDetailPage);