
import { Information16, ServiceDesk16, Delivery16, ShoppingBag16, DeliveryParcel16, Restaurant16, DeskAdjustable16 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react"
import { REST_ORDER_TYPE } from '../../../../domain/rest-sales-order';



export function getRestOrderDetails(type) {
    switch (type) {
        case REST_ORDER_TYPE.DINE_IN:
            return { tagType: "green", bg: "#00990015", border: "#00990060", text: "Dine-in", icon: ServiceDesk16 };
        case REST_ORDER_TYPE.TAKE_OUT:
            return { tagType: "blue", bg: "#00009915", border: "#00009960", text: "Take-out", icon: ShoppingBag16 };
        case REST_ORDER_TYPE.DELIVERY:
            return { tagType: "red", bg: "#99000015", border: "#99000060", text: "Delivery", icon: Delivery16 };
        case REST_ORDER_TYPE.PICK_UP:
            return { tagType: "purple", bg: "#da00ff20", border: "#da00ff60", text: "Pickup", icon: DeliveryParcel16 };
        case REST_ORDER_TYPE.CATERING:
            return { tagType: "magenta", bg: "#eb403420", border: "#eb403460", text: "Catering", icon: Restaurant16 }
        case REST_ORDER_TYPE.BANQUET:
            return { tagType: "teal", bg: "#00826220", border: "#00826260", text: "Banquet", icon: DeskAdjustable16 }
        default:
            return { tagType: "high-contrast", bg: "#f4f4f4", border: "#00000010", text: "Unknown", icon: Information16 };

    }
}

export const TypeTag = ({ type, onClick, opacity = 1, noPadding }) => {
    let { icon, text, tagType } = getRestOrderDetails(type);
    return (
        <Tag renderIcon={icon} type={tagType} onClick={onClick} style={{ opacity, margin: noPadding ? 0 : undefined }}>
            {text}
        </Tag>
    )
}