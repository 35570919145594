import { InProgress16, Alarm16, Roadmap16 } from '@carbon/icons-react'

function MenuButton({ label, active, onClick, fullWidth, children }) {
    return <button style={{ width: fullWidth ? 30 : 100, paddingInline: fullWidth ? 0 : undefined }} onClick={onClick} data-active={active} className='report-engine-menu-button-black'>{children}</button>
}

export function OrderTabs({ orderState, tab, setTab, kitchen }) {
    // const orderCount = orderState.getFilteredOrders().length;
    // const kitchenOrderCount = orderState.getFilteredOrdersForKitchen().length;
    const currentCount = kitchen ? orderState.getCurrentOrderCountForKitchen() : orderState.getCurrentOrderCount();
    const futureCount = kitchen ? orderState.getFutureOrderCountForKitchen() : orderState.getFutureOrderCount();
    return (
        <div style={{ flex: 1, height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
            {/* <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}> */}
            <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.5rem' }}>
                <div style={{ position: 'relative' }}>
                    <MenuButton active={tab === "current"} onClick={() => setTab("current")}><InProgress16 style={{ marginRight: 2 }} /> Current</MenuButton>
                    {currentCount > 0 && <div style={{ position: 'absolute', top: -5, right: -5, width: 18, height: 18, borderRadius: '50%', background: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none', zIndex: 10 }}>
                        <p style={{ fontSize: 12, color: 'white', }}>{currentCount}</p>
                    </div>}
                </div>
                <div style={{ position: 'relative' }}>
                    <MenuButton active={tab === "future"} onClick={() => setTab("future")}><Alarm16 style={{ marginRight: 2 }} /> Scheduled</MenuButton>
                    {futureCount > 0 && <div style={{ position: 'absolute', top: -5, right: -5, width: 18, height: 18, borderRadius: '50%', background: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none', zIndex: 10 }}>
                        <p style={{ fontSize: 12, color: 'white', }}>{futureCount}</p>
                    </div>}
                </div>
                <MenuButton active={tab === "all"} onClick={() => setTab("all")}><Roadmap16 style={{ marginRight: 2 }} /> All</MenuButton>
            </div>
        </div>
    )
}