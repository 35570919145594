import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";
import { getReportLink, listDetailItemId } from "../../base/report-page";
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange } from "../../components/basic-filter/date-range";
import { Divider } from "../../components/basic-filter/divider";
import { MultiSelect } from "../../components/basic-filter/multi-select";

export const V2SalesReport = {
    filter: Filter,

    onAction: (action, data, history) => {
        switch (action) {
            case "voucherNo":
                window.open(listDetailItemId("V2SalesList", data.id), "_blank")
                break;
            case "viewDetail":
                window.open(getReportLink("SalesReportDetail", { "filters": [{ "property": "voucherNo", "operator": "EQ", "id": "voucherNo", "value": data.voucherNo }] }))
                break;
        }
    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },

    customPath: "/sales-report",
    freezeCol: 1
}

const SHOW_DELIVERY_FILTER = { property: "createdFromOrder", operator: "EQ", value: "Yes" }

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="salesDate" padWorkingHours />
        <div style={{ paddingLeft: '1rem' }}>
            <CheckboxFilter state={state} filterId="showDeliveryOnly" label="Show delivery sales" filter={SHOW_DELIVERY_FILTER} />
        </div>
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Customer" key="customerField" fieldKey="customerField" options={endpoint.customers.map(item => item.value)} state={state} property="customers" operator="CONTAINS" />
        </div>
        <div style={{ height: 30, marginTop: '0rem', flex: 2 }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
