import { Draggable16, Close16, Add16, Edit16 } from '@carbon/icons-react'
import { ListDialog } from '../../../restaurant/foods/recipe-manager'
import { OBJECT_TYPE_PRODUCT_BUNDLE } from '../../../../constants/ObjectTypes'
import UIUtil from '../../../../util/UIUtil'
import Button from '../../../../components/Button'
import { Info16 } from '../../../../components/info'
import Util from '../../../../util/Util'
import { makeDraggable } from '../../../../hooks/useDraggable'
import ProfilePic from '../../../../components/ProfilePic'
import Api from '../../../../session/Api'
import { useMemo } from 'react'

const draggable = makeDraggable(Option, () => (
    <div style={{ cursor: 'grab', }}>
        <Draggable16 />
    </div>
));

function Option({
    SortHandler, option, foodItems, onRemoveBtn
}) {
    const name = useMemo(() => foodItems.find($ => $.id === option.foodItemId)?.value, [option, foodItems])
    return (
        <div style={{
            background: '#cecece', marginBottom: '2rem', borderRadius: 7,
            boxShadow: '3px 4px 0px 0px #1c1c1c',
            border: '1px solid #1c1c1c',
            display: 'flex', gap: '1rem',
            height: '3rem',
            alignItems: 'center', paddingInline: '1rem'
        }}>
            <SortHandler />
            <ProfilePic notProfile size={64} style={{ borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, option.foodItemId)} />
            <p style={{ flex: 1 }}>{name}</p>

            <button onClick={onRemoveBtn} className='lead-flow-icon-button' style={{ width: 32, height: 32 }}>
                <Close16 />
            </button>
        </div>
    )
}


export function Section({
    SortHandler, section, onRemoveBtn, foodItems,
    setSectionState
}) {

    const addFood = (id) => {
        setSectionState('options', options => [
            ...options, {
                id: Util.newTempId(),
                foodItemId: id,
            }
        ])
    }

    const removeOption = (option) => {
        setSectionState('options', prev => prev.filter(other => option.id !== other.id))
    }

    const onNewFoodBtn = () => UIUtil.showOverlay2(onClose => <ListDialog title="Foods" onItemClick={item => addFood(item.id)} itemType={OBJECT_TYPE_PRODUCT_BUNDLE} items={foodItems} onClose={onClose} />)

    return (
        <div>
            <div style={{ width: '100%', background: '#f4f4f4', borderRadius: 10, overflow: 'hidden', border: '1px solid #00000020', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '1rem', marginBottom: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '0.5rem' }}>
                    <SortHandler />
                    <div style={{ flex: 1, display: 'flex', padding: '0.5rem', background: 'white', border: '1px solid #00000020', alignItems: 'center', borderRadius: 7, gap: '0.25rem' }}>
                        <label className="bx--label" style={{ margin: 0 }}>Name</label>
                        <input value={section.name} onChange={e => setSectionState('name', e.target.value)} placeholder="Enter name..." style={{ border: 'none', outline: 'none', width: '100%' }} />
                    </div>
                    <div style={{ flex: 1, display: 'flex', padding: '0.5rem', background: 'white', border: '1px solid #00000020', alignItems: 'center', borderRadius: 7, gap: '0.25rem' }}>
                        <label className="bx--label" style={{ margin: 0, width: '105px' }}>Max Selection</label>
                        <input value={section.maxSelection} onChange={e => setSectionState('maxSelection', e.target.value)} placeholder="Enter max selection..." style={{ border: 'none', outline: 'none', width: '100%' }} />
                        <Info16 info={"The maximum number of options the customer can select in this section"} />
                    </div>
                    {/* <div style={{ flex: 1 }} /> */}

                    <button onClick={onRemoveBtn} className='lead-flow-icon-button' style={{ width: 32, height: 32 }}>
                        <Close16 />
                    </button>
                </div>


                <div style={{ paddingLeft: '3rem', width: '100%', paddingTop: '1rem' }}>
                    {/* <h6 style={{ marginBottom: '0.5rem' }}>Options</h6> */}

                    <draggable.SortContainer onSortEnd={draggable.createOnSortEnd(section.options, (arg) => setSectionState('options', arg))}>
                        {section.options.map((option, index) => <draggable.SortItem
                            key={option.id}
                            index={index}
                            option={option}
                            foodItems={foodItems}
                            onRemoveBtn={() => removeOption(option)}
                        />)}
                    </draggable.SortContainer>

                    <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                        <Button onClick={onNewFoodBtn} kind="secondary" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add food</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}