import "./sales-work-list.scss"
import { useEffect, useState } from "react";
import { CardView } from "./component/card-view";
import {
    WirelessCheckout32, FlowStream32, Reset16,


    Connect32, // ongoing
    ShoppingCatalog32, // quotation
    ShoppingCart32, // orders
    SubtractAlt32, //expired
} from '@carbon/icons-react'
import Button from "../../../components/Button";
import Util from "../../../util/Util";
import PaginatedDataTable from "../../../components/data-table/PaginatedDataTable";
import Api from "../../../session/Api";
import { SidePanel } from "../../../templates/draft/components/side-panel";
import { WorkStatusTag } from "./component/status-tag";
import WorkView from "../cart-page/WorkView";
import { useLockScroll } from "../../../hooks/useLockScroll";
import { withLoadablePage } from "../../../base/Page";
import { Tag } from "carbon-components-react";
import { actualStatus, statusName } from "../domain/statuses";
import { hasCapabilitySupport } from "../../../app/Capabilities";

const DEF_STAT = {
    ongoing: 0,
    quotation: 0,
    saleOrder: 0,
    expired: 0,
}

const COLS = [
    {
        id: "id",
        name: "ID",
        render: item => item.id
    },
    {
        id: "dateCreated",
        name: "Date Created",
        render: item => Util.getFullDate(item.dateCreated)
    },
    {
        id: "customer",
        name: "Customer",
        render: item => item.customerName
    },
    {
        id: "label",
        name: "Label",
        render: item => item.label
    },
    {
        id: "by",
        name: "By",
        render: item => item.byUserName
    },
    {
        id: "status",
        name: "Status",
        render: item => (<>
            <WorkStatusTag status={item.status} />
            {actualStatus(item.state) ? (
                <Tag type="blue">{statusName(item.state)}</Tag>
            ) : (
                <Tag>{statusName(item.state)}</Tag>
            )}
        </>)
    },
    {
        id: "invoiceVoucherNo",
        name: "Invoice No",
        render: item => Util.isNumberExist(item.stockFlowVoucherNo) ? item.stockFlowVoucherNo : "No invoice"
    },
]

const Content = ({ payload: { mobileUnits, warehouses, stores, inventoryStatuses } }) => {
    const [stat, setStat] = useState(DEF_STAT)
    const [visibleItemId, setVisibleItemId] = useState(undefined);

    useLockScroll(visibleItemId);

    useEffect(() => {
        Api.getSaleWorksListStat(0, response => {
            if (response.status === true) {
                setStat(response.payload);
            }
        })
    }, [])

    return (<>
        <div style={{ display: 'flex', marginBottom: '1rem', gap: '1rem' }}>
            <CardView index={0} icon={Connect32} title="Ongoing Works" value={stat.ongoing} />
            <CardView index={1} icon={ShoppingCatalog32} title="Active Quotations" value={stat.quotation} />
            <CardView index={2} icon={ShoppingCart32} title="Proforma Invoices" value={stat.saleOrder} />
            <CardView index={3} icon={SubtractAlt32} title="Expired Works" value={stat.expired} />
        </div>

        <PaginatedDataTable
            // title="Journal Entries"
            // description="All journal entries"
            columns={COLS}
            loader={(page, _, listener) => Api.getSaleWorksList(0, page, listener)}
            loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}
            //onClick={({ id }) => history.push('/journal-entry/' + id)}
            onClick={({ id }) => setVisibleItemId(id)}
        >

        </PaginatedDataTable>

        {visibleItemId && <SidePanel background="#f4f4f4" md2 onClose={() => setVisibleItemId(undefined)} >
            <WorkView onBack={() => setVisibleItemId(undefined)} workId={visibleItemId} skipProductView {...{ mobileUnits, warehouses, stores, inventoryStatuses }} />
        </SidePanel>}
    </>)
}

const View = ({ payload }) => {
    const [resetKey, setResetKey] = useState(() => Util.newTempId())
    return (
        <div style={{ width: '100%', padding: '1rem' }}>

            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', marginBottom: '1rem' }}>
                <FlowStream32 />
                <h3 style={{ fontWeight: 'bold', flex: 1, }}>Sales Works</h3>

                <Button onClick={() => setResetKey(Util.newTempId())} renderIcon={Reset16} kind="secondary" size="sm" style={{ borderRadius: 50 }}>Reload</Button>
            </div>
            <Content key={resetKey} payload={payload} />
        </div>
    )
}

export const AdminSalesWorks = withLoadablePage(Api.getSaleWorksListEndpoint.bind(Api), View);