import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT_CATEGORY } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

class ProductCategoryListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Categories", 
                    subTitle: "All categories", 
                    objectType: OBJECT_TYPE_PRODUCT_CATEGORY, 
                    pagePath: "/product-categories/", 
                    history: this.props.history
                })}
            </div>
        )
    }
    
    isPageLoadable() {
        return false;
    }

}

export default withRouter(ProductCategoryListPage);