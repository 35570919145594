
import React from 'react'
import { CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'


export default ({ active }) => (
    <Tag renderIcon={!active ? ErrorFilled16 : CheckmarkFilled16} style={{ color: !active ? 'red' : 'green' }}>
        {!active ? "Disabled" : "Active"}
    </Tag>
)

export const CustomActiveStatusView = ({ active, activeTxt, disabledTxt }) => (
    <Tag renderIcon={!active ? ErrorFilled16 : CheckmarkFilled16} style={{ color: !active ? 'red' : 'green' }}>
        {!active ? disabledTxt : activeTxt}
    </Tag>
)