import Portal from "../../util/Portal"
import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react';
import {
    ErrorFilled16, CheckmarkFilled16,
} from '@carbon/icons-react'
import Button from "../Button";

import Editor from "@monaco-editor/react";
import { useEffect, useRef } from "react";


export default ({ visible, onClose, value, onValueChange }) => {
    const editorRef = useRef();
    const onConfirmBtn = () => {
        onValueChange(editorRef.current.getValue())
        onClose()
    }

    useEffect(() => {
        if (visible && editorRef.current) {
            editorRef.current.setValue(value ?? "")
        }
    }, [visible])

    return (
        <Portal>
            <ComposedModal
                size="lg"
                key="code-editor-modal"
                className="modal-that-supports-combo-box"
                open={visible}
                onClose={onClose}
            >
                <ModalHeader label="Editing" title="Script Editor" />
                <ModalBody style={{ paddingRight: '1rem', height: '50vh' }}>
                    <Editor
                        onMount={(editor) => editorRef.current = editor}
                        height="100%"
                        defaultLanguage="javascript"
                        // defaultValue={value}
                        theme="vs-dark"
                    />
                </ModalBody>
                <ModalFooter>
                    <ButtonSet style={{ width: '100%' }}>
                        <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16} >
                            Cancel
                        </Button>
                        <Button renderIcon={CheckmarkFilled16} onClick={onConfirmBtn}
                        >
                            Confirm
                        </Button>
                    </ButtonSet>
                </ModalFooter>
            </ComposedModal>
        </Portal>
    )
}