import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { OBJECT_TYPE_REAL_ESTATE_PROJECT, OBJECT_TYPE_REAL_ESTATE_PROJECT_WEBSITE_ATTACHMENTS, OBJECT_TYPE_REAL_ESTATE_PROPERTY } from '../../../constants/ObjectTypes';
import Page from '../../../base/Page';
import Api, { createApiWithLang } from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import TableTemplate from '../../../templates/TableTemplate';
import { ACCOUNT_TYPE_REAL_ESTATE_AGENT, REAL_ESTATE_PROPERTY_CUSTOM_LIST, TABLE_RELATION_TYPE_MANY_TO_MANY } from '../../../constants/Constants';
import NoteView from '../../../views/notes/NoteView';
import { Tile } from 'carbon-components-react';
import {
    Attachment16
} from '@carbon/icons-react'
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { ItemMediaTable } from '../../../views/item-media/item-media-table';
import { getAccountRole } from '../../../session/SessionManager';
import Util from '../../../util/Util';
import { TranslateBanner } from '../../../templates/translate/translate-banner';

const WebsiteAttachments = ({ item }) => (
    <div style={{ marginTop: '1rem' }}>
        <Tile style={{ marginBottom: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Attachment16 style={{ marginRight: '0.5rem' }} />
                <h6>Website Attachments</h6>
            </div>
            <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                All items added here will be visible on the website
            </p>
        </Tile>
        <NoteView skipBody requiredUpload itemName="Attachment" itemNoteName="Description" itemsName="attachments" objectId={item.id} objectType={OBJECT_TYPE_REAL_ESTATE_PROJECT_WEBSITE_ATTACHMENTS} />
    </div>
)

const Properties = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                embedded: true,
                hideCreateButton: true,
                objectType: OBJECT_TYPE_REAL_ESTATE_PROPERTY,
                pagePath: "/real-estate-property/",
                history: history,
                paginated: false,

                getCustomListRequest: {
                    requestType: REAL_ESTATE_PROPERTY_CUSTOM_LIST.BY_PROJECT_ID,
                    params: {
                        projectId: item.id,
                    }
                },
            })}
        </div>
    )
}

// const RelatedProjectsList = ({ item }) => {
//     const history = useHistory();
//     return (
//         <div style={{ marginTop: '1rem' }}>
//             {TableTemplate.renderTemplate({
//                 tableRelationMode: {
//                     tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

//                     parentId: item.id,
//                     parentType: OBJECT_TYPE_REAL_ESTATE_PROJECT,

//                     childType: OBJECT_TYPE_REAL_ESTATE_PROJECT,
//                     manyToManyChildView: false,

//                     showNotIncluded: false
//                 },
//                 embedded: true,
//                 title: "Related Projects",
//                 subTitle: "Related Projects",
//                 objectType: OBJECT_TYPE_REAL_ESTATE_PROJECT,
//                 pagePath: "/real-estate-project/",
//                 history: history
//             })}
//         </div>
//     )
// }


class ProjectDetailPage extends Page {

    api = Api;
    isAgent = getAccountRole() == ACCOUNT_TYPE_REAL_ESTATE_AGENT;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined,
            lang: "EN"
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                this.api.getItemCreator(OBJECT_TYPE_REAL_ESTATE_PROJECT, listener)
            } else {
                this.api.getItem(OBJECT_TYPE_REAL_ESTATE_PROJECT, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    setLang(lang) {
        this.setState({ lang }, () => {
            this.api = (!Util.isStringExists(lang) || lang === "EN") ? Api : createApiWithLang(lang);
            this.onPageStart();
        })
    }

    hasTranslation() {
        return hasCapabilitySupport("translationSupport") && this.state.itemResult.translationSupport;
    }

    getLayout() {
        return (
            <>
                {this.hasTranslation() && <TranslateBanner lang={this.state.lang} setLang={this.setLang.bind(this)} />}
                <div className="main-content">
                    {ItemTemplate.renderTemplate({
                        lang: this.state.lang,
                        readOnly: this.isAgent,

                        objectType: OBJECT_TYPE_REAL_ESTATE_PROJECT,
                        itemResult: this.state.itemResult,
                        pagePath: "/real-estate-project/",
                        history: this.props.history,
                        customTabs: [
                            ...(hasCapabilitySupport("onlineStore") ? [
                                {
                                    title: 'Gallery',
                                    component: ItemMediaTable
                                },
                                {
                                    title: 'Attachments',
                                    component: WebsiteAttachments
                                }
                            ] : []),
                            {
                                title: "Properties",
                                component: Properties
                            },
                            // {
                            //     title: "Related Projects",
                            //     component: RelatedProjectsList
                            // }
                        ]
                    })}
                </div>
            </>
        )
    }

}

export default withRouter(ProjectDetailPage);