import {
    AddFilled20, Copy16, TrashCan16, TextFont32, Code16
} from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'
import ImageView from '../../components/ImageView'
import Api from '../../session/Api'
import Button from '../../components/Button'
import { copyToClipboard } from '../../pages/monitoring/TerminalMonitoringView'
import UIUtil from '../../util/UIUtil'
import { withLoadablePage } from '../../base/Page'
import { useState } from 'react'
import UploadWrapper from '../../components/UploadWrapper'

const ImageItem = ({ }) => {
    const name = 'mymainlogo'

    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            UIUtil.showError("Not authorized");
        })
    }

    const onCopyBtn = () => {
        const code = `robo.res("${name}")`
        copyToClipboard("${" + code + "}");
    }

    return (
        <div className='robo-res-item' style={{}}>
            <div style={{ width: '100%', height: 300 }}>
                <ImageView
                    style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                    imageViewStyle={{ objectFit: 'cover', objectPosition: 'center' }}
                    src={Api.getThumbnail()} />
            </div>

            <div style={{ padding: '1rem', display: 'flex', alignItems: 'center', width: '100%', gap: '0.5rem' }}>
                <div style={{ flex: 1 }}>
                    <p>{name}</p>
                    <Tag size='sm' type='blue'>Image</Tag>
                </div>

                <Button onClick={onDeleteBtn} hasIconOnly renderIcon={TrashCan16} kind="danger--ghost" iconDescription="Delete" style={{ borderRadius: 7 }} />
                <Button onClick={onCopyBtn} hasIconOnly renderIcon={Copy16} iconDescription="Copy Code" style={{ borderRadius: 7 }} />
            </div>
        </div>
    )
}


const FontItem = () => {
    const name = 'mymainlogo'

    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            UIUtil.showError("Not authorized");
        })
    }

    const onCopyBtn = () => {
        const code = `robo.res("${name}")`
        copyToClipboard("${" + code + "}");
    }

    return (
        <div className='robo-res-item' style={{}}>
            <div style={{ width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
                <TextFont32 />
            </div>

            <div style={{ padding: '1rem', display: 'flex', alignItems: 'center', width: '100%', gap: '0.5rem' }}>
                <div style={{ flex: 1 }}>
                    <p>fewfwefe</p>
                    <Tag size='sm' type='green'>Font</Tag>
                </div>

                <Button onClick={onDeleteBtn} hasIconOnly renderIcon={TrashCan16} kind="danger--ghost" iconDescription="Delete" style={{ borderRadius: 7 }} />
                <Button onClick={onCopyBtn} hasIconOnly renderIcon={Copy16} iconDescription="Copy Code" style={{ borderRadius: 7 }} />
            </div>
        </div>
    )
}


const Preview = ({ item }) => {
    if (item.type === 'FONT') {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
                <TextFont32 />
            </div>
        )
    }

    if (item.type === 'IMAGE') {
        return (
            <ImageView
                style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                imageViewStyle={{ objectFit: 'cover', objectPosition: 'center' }}
                src={Api.getCustomResourceImagePath(item.id)} />
        )
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
            <p style={{ fontSize: 12, opacity: 0.65 }}>no preview</p>
        </div>
    )
}

const TypeTag = ({ item }) => {
    if (item.type === 'FONT') {
        return <Tag size='sm' type='green'>Font</Tag>;
    }

    if (item.type === 'IMAGE') {
        return <Tag size='sm' type='blue'>Image</Tag>
    }

    return <Tag size='sm' type="high-contrast">Unknown Type</Tag>
}

const genCode = async item => {
    if (item.type === 'FONT') {
        const fontFamily = await UIUtil.inputPrompt({
            title: "Font Family",
            message: "Enter font family",
        })
        if (!fontFamily) {
            return '';
        }

        const style = await UIUtil.inputPrompt({
            title: "Font Style",
            message: "Enter font style",
            placeholder: "e.g. normal, italic",
        })
        if (!style) {
            return '';
        }

        const weight = await UIUtil.inputPrompt({
            title: "Font Weight",
            message: "Enter font weight",
            placeholder: "e.g. 400, 700",
        })
        if (!weight) {
            return '';
        }

        return `<@robo.ImportFont file="${item.name}" family="${fontFamily}" style="${style}" weight="${weight}" />`
    }

    if (item.type === 'IMAGE') {
        return `\${robo.image("${item.name}")}`;
    }

    return ''
}


const Item = ({ item, onDelete }) => {
    const onDeleteBtn = () => UIUtil.confirm(() => Api.deleteCustomResource(item.id, response => {
        if (response.status === true) {
            onDelete();
        } else {
            UIUtil.showError(response.message);
        }
    }))

    const onCopyBtn = async () => {
        const code = await genCode(item);
        if (code) {
            copyToClipboard(code);
        } else {
            UIUtil.showInfo("Code generation not available");
        }
    }

    return (
        <div className='robo-res-item' style={{}}>
            <div style={{ width: '100%', height: 300 }}>
                <Preview item={item} />
            </div>

            <div style={{ padding: '1rem', display: 'flex', alignItems: 'center', width: '100%', gap: '0.5rem' }}>
                <div style={{ flex: 1, minWidth: 0 }}>
                    <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', minWidth: 0 }}>{item.name}</p>
                    <TypeTag item={item} />
                </div>

                <Button onClick={onDeleteBtn} hasIconOnly renderIcon={TrashCan16} kind="danger--ghost" iconDescription="Delete" style={{ borderRadius: 7 }} />
                <Button onClick={onCopyBtn} hasIconOnly renderIcon={Code16} iconDescription="Generate Code" style={{ borderRadius: 7 }} />
            </div>
        </div>
    )
}


const CreateBtn = ({ onResourceCreated }) => {

    const onUpload = async file => {
        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.uploadCustomResource(file, listener));
            if (!success) {
                return;
            }

            onResourceCreated(response);
        } finally {
            stopLoading();
        }
    }

    return (
        <UploadWrapper
            //accept="image/gif,image/jpeg,image/png,image/webp,font/ttf,font/woff,font/woff2,application/x-font-woff,application/x-font-woff2"
            onChange={e => onUpload(e.target.files[0])}>
            <div className='robo-res-item create-res-btn' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 300, height: '100%' }}>
                <AddFilled20 />
            </div>
        </UploadWrapper>
    )
}




function View({ payload }) {
    const [resources, setResources] = useState(payload)

    const onResourceCreated = resource => {
        setResources(resources => [...resources, resource]);
    }

    const onDeleted = item =>
        setResources(resources => resources.filter(res => res.id !== item.id))

    return (
        <div style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', }}>
                {resources.map(item => <Item key={item.id} item={item} onDelete={() => onDeleted(item)} />)}

                <CreateBtn onResourceCreated={onResourceCreated} />
            </div>
        </div>
    )
}


export const Resources = withLoadablePage(Api.listCustomResources.bind(Api), View);
