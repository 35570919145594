import { ModalBody, ModalFooter, ModalHeader, TextInput, Tile } from 'carbon-components-react'
import React, { useState } from 'react'
import { Screen32, ArrowRight16, Login32 } from '@carbon/icons-react'
import Button from '../../components/Button'
import Api from '../../session/Api'
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util'
import { saveAccount } from '../../session/SessionManager'
import { isMobileScreen } from '../../App'

export default ({ onAuth }) => {
    const [emailAddressValue, setEmailAddressValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const [loggingIn, setLoggingIn] = useState(false);

    const onLoginBtn = () => {
        setLoggingIn(true);
        Api.auth(emailAddressValue, passwordValue, response => {
            if (response.status === true) {
                saveAccount(response.payload)
                onAuth();
            } else {
                UIUtil.showError(response.message);
            }
            setLoggingIn(false);
        })
    }

    if (isMobileScreen) {
        return (
            <div style={{ width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Tile style={{ width: '90%', padding: 0 }}>
                    <div style={{ width: '100%', padding: '1rem' }}>
                        <h1><Login32 /> Login</h1>
                        <br />
                        <br />

                        <TextInput disabled={loggingIn} light labelText="Email Address" value={emailAddressValue} onChange={e => setEmailAddressValue(e.target.value)} />
                        <br />
                        <TextInput.PasswordInput
                            onKeyPress={e => {
                                if (e.key == "Enter") {
                                    onLoginBtn();
                                }
                            }}
                            disabled={loggingIn} light labelText="Password" value={passwordValue} onChange={e => setPasswordValue(e.target.value)} />
                    </div>
                    <br />
                    <br />

                    <Button loading={loggingIn} onClick={onLoginBtn} kind="primary" size="lg" style={{ width: '50%', marginLeft: '50%' }}
                        disabled={!Util.isStringExists(emailAddressValue) || !Util.isStringExists(passwordValue)}
                        renderIcon={ArrowRight16}>Login</Button>
                    {/* <ModalFooter primaryButtonText="Login" secondaryButtons={null} /> */}
                </Tile>
            </div>
        )
    }

    return (
        <div style={{ width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Tile style={{ width: '30%', padding: 0 }}>
                <div style={{ width: '100%', padding: '1rem' }}>
                    <h1><Login32 /> Login</h1>
                    <br />
                    <br />

                    <TextInput disabled={loggingIn} light labelText="Email Address" value={emailAddressValue} onChange={e => setEmailAddressValue(e.target.value)} />
                    <br />
                    <TextInput.PasswordInput
                        onKeyPress={e => {
                            if (e.key == "Enter") {
                                onLoginBtn();
                            }
                        }}
                        disabled={loggingIn} light labelText="Password" value={passwordValue} onChange={e => setPasswordValue(e.target.value)} />
                </div>
                <br />
                <br />

                <Button loading={loggingIn} onClick={onLoginBtn} kind="primary" size="lg" style={{ width: '50%', marginLeft: '50%' }}
                    disabled={!Util.isStringExists(emailAddressValue) || !Util.isStringExists(passwordValue)}
                    renderIcon={ArrowRight16}>Login</Button>
                {/* <ModalFooter primaryButtonText="Login" secondaryButtons={null} /> */}
            </Tile>
        </div>
    )
}