import React, { useEffect, useState } from 'react'
import DashboardCard from '../components/DashboardCard'
import DashboardTable from '../components/DashboardTable'
import DashboardTitle from '../components/DashboardTitle'

import { Receipt20 } from '@carbon/icons-react'
import Util from '../../../util/Util'
import { InlineLoading, Link as L } from 'carbon-components-react'
import { Link, useHistory } from 'react-router-dom'
import { getObjectTypeUrl } from '../../../constants/ObjectTypes'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'

export default ({ dashboard }) => {
    const [loading, setLoading] = useState(true)
    const [unpaidInvoices, setUnpaidInvoices] = useState([])

    const history = useHistory();
    const onViewAllBtn = () => history.push('/stock-flows');

    useEffect(() => {
        setLoading(true)
        Api.getDashboardUnpaidInvoices(response => {
            if (response.status === true) {
                setUnpaidInvoices(response.payload.unpaidInvoices)
                setLoading(false)
            } else {
                UIUtil.showError("Could not load unpaid invoices")
            }
        })
    }, [])

    if (loading) {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <DashboardTitle icon={Receipt20} title={"Top unpaid invoices"} />
                <div className="dashboard-card centered-progress-bar" style={{ flex: 1, height: '100%', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InlineLoading />
                </div>
            </div>
        )
    }

    return (
        <div>
            <DashboardTitle icon={Receipt20} title={"Top unpaid invoices"} />
            {/* <DashboardTitle icon={Receipt20} title={"Latest unpaid invoices"} 
            link linkText="View all" linkOnClick={onViewAllBtn} /> */}
            <DashboardTable customBgColor="rgb(19,57,59)"
                //items={dashboard.unpaidInvoices} 
                items={unpaidInvoices}
                columns={[
                    {
                        name: "unpaid-stock-flows-tb-name",
                        title: "",
                        flex: 2,
                        renderCell: item => (
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '0.5rem' }}>
                                <Link style={{ textDecoration: 'none', }} target="_blank" to={getObjectTypeUrl(item.partyType) + "/" + item.partyId}><L style={{ fontSize: 16 }}>{item.partyName}</L></Link>
                                <label className="bx--label">{item.partyTypeName}</label>
                            </div>
                        )
                    },
                    {
                        name: "unpaid-stock-flows-tb-voucher",
                        title: "Voucher No",
                        flex: 1,
                        renderCell: item => <Link style={{ textDecoration: 'none' }} target="_blank" to={"/stock-flow/" + item.stockFlowId}><L>{Util.getVoucherNumber(item.stockFlowId)}</L></Link>
                    },
                    {
                        name: "unpaid-stock-flows-tb-type",
                        title: "Type",
                        flex: 1,
                        renderCell: item => item.stockFlowTypeName
                    }
                ]} />
        </div>
    )
}