import { getOrderStatusName, STATUS_AWAITING_DELIVERY, STATUS_AWAITING_PAYMENT_COLLECTION, STATUS_AWAITING_RENTAL_INSPECTION, STATUS_AWAITING_RENTAL_PICKUP, STATUS_DELIVERED, STATUS_FINALIZED, STATUS_OUT_FOR_DELIVERY, STATUS_OUT_FOR_PAYMENT_COLLECTION, STATUS_PENDING, STATUS_PICKED_UP, STATUS_PROCESSING, STATUS_PROCESSING_RENTAL, STATUS_RENTAL_OUT_FOR_PICKUP, STATUS_REST_AWAITING_FINALIZE, STATUS_REST_PACKING, STATUS_REST_PREPARING, STATUS_REST_SERVING, STATUS_REVOKED } from "../base/sales-order"
import { CheckmarkFilled16, ErrorFilled16, Money16, Search16, Time16, Hourglass16, Restaurant16, ServiceDesk16, Package16, Renew16, DeliveryParcel16, Delivery16, WatsonHealthStackedScrolling_116, Code16 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react"
import { hasCapabilitySupport } from "../../../app/Capabilities";


function getStatusDetails(status) {
    switch (status) {
        case STATUS_REVOKED:
            return { type: "red", text: "Revoked", icon: ErrorFilled16 };
        case STATUS_PENDING:
            return { type: "high-contrast", text: "Pending", icon: Time16 };
        case STATUS_PROCESSING:
            return { type: "cyan", text: hasCapabilitySupport("restaurant") ? "Awaiting Preparation" : "Processing", icon: Renew16 };
        case STATUS_AWAITING_DELIVERY:
            return { type: "blue", text: "Awaiting Delivery", icon: DeliveryParcel16 };
        case STATUS_OUT_FOR_DELIVERY:
            return { type: "purple", text: "Out For Delivery", icon: Delivery16 };
        case STATUS_DELIVERED:
            return { type: "teal", text: "Delivered", icon: WatsonHealthStackedScrolling_116 };
        case STATUS_PROCESSING_RENTAL:
            return { type: "blue", text: "Processing Rental", icon: Renew16 };
        case STATUS_AWAITING_RENTAL_PICKUP:
            return { type: "purple", text: "Awaiting Pickup", icon: DeliveryParcel16 };
        case STATUS_RENTAL_OUT_FOR_PICKUP:
            return { type: "teal", text: "Out For Pickup", icon: Delivery16 };
        case STATUS_PICKED_UP:
            return { type: "red", text: "Picked up", icon: WatsonHealthStackedScrolling_116 };

        case STATUS_AWAITING_RENTAL_INSPECTION:
            return { type: "magenta", text: "Awaiting Rental Inspection", icon: Search16 }

        case STATUS_FINALIZED:
            return { type: "green", text: "Finalized", icon: CheckmarkFilled16 };

        case STATUS_AWAITING_PAYMENT_COLLECTION:
            return { type: "purple", text: "Awaiting Payment Collection", icon: Money16 }
        case STATUS_OUT_FOR_PAYMENT_COLLECTION:
            return { type: "blue", text: "Out For Payment Collection", icon: Delivery16 }

        case STATUS_REST_PREPARING:
            return { type: "red", text: "Preparing", icon: Restaurant16 }
        case STATUS_REST_SERVING:
            return { type: "blue", text: "Serving", icon: ServiceDesk16 }
        case STATUS_REST_AWAITING_FINALIZE:
            return { type: "teal", text: "Awaiting Finalization", icon: Hourglass16 }
        case STATUS_REST_PACKING:
            return { type: "purple", text: "Packing", icon: Package16 }

        default:
            return { type: "gray", text: "Unknown", icon: Code16 }

    }
}

export default ({ status, state, assignedDriverName }) => {
    let { icon, text, type } = getStatusDetails(status);
    return (
        <div>
            <Tag size="md" renderIcon={icon} type={type}>
                {text}
            </Tag>
            {state && <>
                <Tag size="sm" type="outline">
                    {/* <h6>State:</h6> */}
                    <p style={{ color: state.color, fontSize: 12 }}>
                        <strong style={{ marginRight: '0.15rem' }}>State:</strong>
                        {state.name}
                    </p>
                </Tag>
            </>}
            {assignedDriverName &&
                <div style={{ marginTop: '0.5rem', paddingLeft: '0.5rem' }}>
                    <label className="bx--label" style={{ marginBottom: 0, fontSize: 12 }}>Current Assigned Driver</label>
                    <p style={{ fontSize: 14 }}>{assignedDriverName}</p>
                </div>}
        </div>
    )
}