
import { Theater24, Add16, AddAlt16, ArrowLeft16, ArrowRight16 } from '@carbon/icons-react'
import { useState } from 'react'
import Util from '../../../util/Util';

function dayName(day) {
    if (day === 0) {
        return 'Today'
    } else if (day === 1) {
        return 'Tomorrow'
    } else {
        const date = new Date();
        date.setDate(date.getDate() + day);
        return Util.getDateOnlyWithoutYear(date);
    }
}

export function DayPicker({ day, setDay }) {
    return (
        <div style={{ width: '100%', height: 35, color: 'white', background: '#0a0a0a', borderBottom: 'solid 1px black', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3rem' }}>
            <button disabled={day === 0} className='day-picker-show-time-scheduler-button' onClick={() => setDay(p => p - 1)}>
                <ArrowLeft16 />
            </button>
            <p style={{ fontSize: 12, opacity: 0.65, width: 125, textAlign: 'center' }}>{dayName(day)}</p>
            <button className='day-picker-show-time-scheduler-button' onClick={() => setDay(p => p + 1)}>
                <ArrowRight16 />
            </button>
        </div>
    )
}