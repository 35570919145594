import { useEffect, useMemo, useState } from "react";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import usePosSession from "../state/usePosSession";
import useStore from "../../../hooks/useStore";
import { ButtonSet, ComboBox, ComposedModal, ContentSwitcher, Link, ModalBody, ModalFooter, ModalHeader, Switch, TextArea } from "carbon-components-react";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import Button from "../../../components/Button";
import { Save16, ErrorFilled16 } from '@carbon/icons-react'
import Util from "../../../util/Util";


const DetailField = ({ title, value }) => (<>
    <label className="bx--label">{title}</label>
    <p style={{ marginTop: '-0.75rem', whiteSpace: 'pre-wrap' }}>{value}</p>
    <div style={{ height: '0.25remrem' }} />
</>)



export function InventoryAllocate({ stores, warehouses, mobileUnits, sessionStore, editable }) {
    const locations = useMemo(() => [{ id: 0, value: 'Do not allocate' }, ...stores, ...warehouses, ...mobileUnits], []);

    const session = usePosSession(sessionStore)
    const [locationId] = useStore(sessionStore, "inventoryAllocateLoc")
    const location = useMemo(() => locations.find(loc => loc.id === locationId), [locationId]);

    if (!editable) {
        return (
            <div>
                <DetailField title={"Inventory Allocation"} value={location.value} />
            </div>
        )
    }

    return (
        <ComboBox
            disabled={session.loading}
            light
            titleText={"Inventory Allocation"}
            placeholder={location.value}
            selectedItem={location}
            onChange={e => session.setInventoryAllocate(e.selectedItem?.id ?? 0)}
            itemToString={item => item?.value}
            items={locations}
        />
    )

    // return <>

    //     <div>
    //         <label className="bx--label" style={{ marginBottom: !editable ? 0 : undefined }}>Allocate Inventory?</label>
    //         {editable ? (
    //             <ContentSwitcher selectedIndex={yes ? 0 : 1} onChange={({ index }) => session.setInventoryAllocate(index === 0)} style={{ width: 300 }}>
    //                 <Switch text="Yes" />
    //                 <Switch text="No" />
    //             </ContentSwitcher>
    //         ) : (
    //             <p style={{ width: 300 }}>{yes ? 'Yes' : 'No'}</p>
    //         )}
    //     </div>

    // </>
}