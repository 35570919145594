import Util from "../../../../../util/Util";
import { Close16 } from '@carbon/icons-react'

import { Detail } from "./detail";
import { SidePanel } from "../../../../../templates/draft/components/side-panel";
import { LockScroll } from "../../../../../hooks/useLockScroll";
import DeliverableItemPage from "../../../../sales-order/DeliverableItemPage";
import { MainApp } from "../../../../../App";
import Portal from "../../../../../util/Portal";
import { Documents } from "./documents";

export function getSideViewTitle(sideView) {
    switch (sideView) {
        case "detail":
            return "Event";
    }

    return ""
}

export function renderSideView(sideView, fnbEvent, form, endpoint, changesMade, globalState, onClose) {
    switch (sideView) {
        case "detail":
            return (
                <Portal>
                    <SidePanel sm backBtn onClose={onClose}>
                        <Detail fnbEvent={fnbEvent} form={form} globalState={globalState} />
                    </SidePanel>
                </Portal>
            )
        case "documents":
            return (
                <Portal>
                    <SidePanel sm backBtn onClose={onClose}>
                        <Documents fnbEvent={fnbEvent} form={form} globalState={globalState} changesMade={changesMade} />
                    </SidePanel>
                </Portal>
            )

        case "salesOrder":
            return (
                <Portal>
                    <SidePanel md2 backBtn onClose={onClose}>
                        <DeliverableItemPage orderIdOverride={fnbEvent.orderId} mainApp={MainApp.instance} />
                    </SidePanel>
                </Portal>
            )
    }

    return null;
}

export function SideView({ globalState, endpoint, fnbEvent, form, sideView, setSideView, changesMade }) {
    if (!Util.isStringExists(sideView)) {
        return null;
    }
    return renderSideView(sideView, fnbEvent, form, endpoint, changesMade, globalState, () => setSideView(undefined));
    return (
        <div style={{
            position: 'absolute', right: 0, background: 'white', height: 'calc(100vh - 11rem)', zIndex: 1,
            // height: '100%', 
            overflow: 'hidden', width: 450,
            borderLeft: '1px solid #00000020', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)',
            // animation: '250ms shipment-item-right-panel-fade-in'
        }}>
            <div style={{ height: '100%', width: 450, overflow: 'auto' }}>
                {/* <div style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
                    <h2 style={{ fontWeight: 'bold', flex: 1 }}>{getSideViewTitle(sideView)}</h2>
                    <button onClick={() => setSideView(undefined)} className='lead-flow-icon-button'><Close16 /></button>
                </div> */}
                {renderSideView(sideView, fnbEvent, form, endpoint, changesMade, globalState, () => setSideView(undefined))}
            </div>
        </div>
    )
}