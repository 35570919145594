import { TextArea } from "carbon-components-react";
import { useEffect } from "react";
import { DATE_TIME_TYPE } from "../../../domain/activity/activity-blueprint";
import { useForm } from "../../../templates/form/form";

import { isEqual } from 'lodash'
import Util from "../../../util/Util";
import { UploadField } from "../../../components/upload/upload-field";
import { COUNTRIES_LIST } from "../../../resources/countries-list";

function createValue(value, inputFields) {
    return Object.entries(value).map(([inputFieldId, value]) => ({
        inputFieldId, value,
        type: inputFields.find(field => field.id == inputFieldId)?.type
    }))
}

function parseValue(value) {
    if (!value) {
        return undefined;
    }

    const getRecordValue = record => {
        if (!Util.isStringExists(record.value)) {
            return record.value;
        }

        switch (record.type) {
            case "object-item": case "options": case "list":
                return parseInt(record.value)
            case "date-time":
                if (record.dateTimeType !== DATE_TIME_TYPE.timeOnly) {
                    return parseInt(record.value);
                } else {
                    return record.value;
                }
            case "checkbox":
                return record.value === "true";
            default:
                return record.value;
        }
    }

    const v = value.reduce((acc, record) => ({ ...acc, [record.inputFieldId]: getRecordValue(record) }), {})
    return v;
}

const NoFieldsMessage = () => (
    <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ fontSize: 12, opacity: 0.65 }}>
            No field exists
        </p>
    </div>
)

export function BlueprintInputFieldsForm({ inputFields, value, onValueChanged, config }) {
    const { TextField, TextAreaField, CheckBoxField, SwitchField, ComboBoxField, CustomField, ExtField, DateField, DateTimeField, TimeField, ...form } = useForm(undefined, config);
    const getValue = () => createValue(form.getFormData(), inputFields)

    useEffect(() => form.store.subscribeGlobal(() => {
        onValueChanged(getValue())
    }), [onValueChanged])

    useEffect(() => {
        const currentFormData = getValue();
        if (!isEqual(currentFormData, value)) {
            form.setData(parseValue(value))
        }
    }, [form, value])

    if (inputFields.length === 0) {
        return <NoFieldsMessage />
    }

    return (
        <div>
            {inputFields.map(field => {
                switch (field.type) {
                    case "text":
                        return <TextField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} />
                    case "file":
                        return (
                            <div key={field.id} style={{ width: 300 }}>
                                <CustomField fieldKey={field.id} childrenProps={{ label: field.params.fieldLabel }}>
                                    {UploadField}
                                </CustomField>
                            </div>
                        )
                    case "country":
                        return <ComboBoxField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} options={COUNTRIES_LIST} />
                    case "text-area":
                        return <TextAreaField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} />
                    case "checkbox":
                        return <CheckBoxField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} />
                    case "date-time":
                        switch (field.params.dateTimeType) {
                            case DATE_TIME_TYPE.dateOnly:
                                return <DateField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} />
                            case DATE_TIME_TYPE.timeOnly:
                                return <TimeField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} />
                            case DATE_TIME_TYPE.dateAndTime: default:
                                return <DateTimeField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} />
                        }
                    case "options":
                        return <SwitchField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} options={field.params.optionSelectorOptions.map(option => ({ ...option, title: option.optionLabel }))} />
                    case "list":
                        return <ComboBoxField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} options={field.params.optionSelectorOptions.map(option => ({ ...option, title: option.optionLabel }))} />
                    case "object-item":
                        return <ComboBoxField key={field.id} title={field.params.fieldLabel} fieldKey={field.id} options={field.params.objectItemTypeList} />
                }

                return null;
            })}
        </div>
    )
}