
import Api from "../../../session/Api"
import { FormSection } from "../../../templates/form/form"
import { FormDialog } from "../../../templates/form/form-dialog"
import Util from "../../../util/Util"

export function FoodFormDialog({ visible, onClose, onSuccess, endpoint, title, item }) {
    console.log(item)
    return (
        <FormDialog
            setItem={item => onSuccess(item)} label="Food" title={title}
            formConfig={{ skipFieldEffect: true }}
            saveApi={(data, listener) => {
                if (Util.isNumberExist(item?.id)) {
                    Api.saveFood({ ...data, id: item.id }, listener)
                } else {
                    Api.saveFood(data, listener)
                }
            }}
            open={visible} onClose={onClose} item={{
                refNo: item?.refNo ?? "",
                name: item?.name ?? "",
                description: item?.description ?? "",
                price: item?.price ?? "",
                minQuantity: item?.minQuantity ?? "",
                departmentId: item?.departmentId ?? 0,
                categoryId: item?.categoryId ?? 0,
                subcategoryId: item?.subcategoryId ?? 0,
                classificationId: item?.classificationId ?? 0,
                restMenuSort: item?.restMenuSort ?? 0
            }} payload={endpoint}>
            {FoodFormFields}
        </FormDialog>
    )
}



export const FoodFormFields = ({
    TextField, IfTargetGroup, SwitchField, ComboBoxField, TextAreaField
}, endpoint) => (<>
    <TextField title="Ref no" fieldKey="refNo" />
    <TextField title="Name" fieldKey="name" />
    <TextAreaField title="Description" fieldKey="description" />
    <TextField title="Min stock" fieldKey="minQuantity" help="Estimated Daily Portions Stock Minimum" />
    {/* <TextField title="Price (+ Tax)" fieldKey="price" /> */}
    <FormSection title="Menu">
        <TextField title="Menu Sort" fieldKey="restMenuSort" />
    </FormSection>

    <FormSection title="Relations">
        <ComboBoxField title="Department" fieldKey="departmentId" options={endpoint.departments} />
        <ComboBoxField title="Category" fieldKey="categoryId" options={endpoint.categories} />
        <ComboBoxField title="Subcategory" fieldKey="subcategoryId" options={endpoint.subcategories} />
        <ComboBoxField title="Classification" fieldKey="classificationId" options={endpoint.classifications} />
    </FormSection>
</>)