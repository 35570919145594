import Button from "../../../components/Button";
import { Tag16 } from '@carbon/icons-react'
import { Modal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { useState } from "react";
import { big } from "../../../util/Util";

function onSave(salesOrderId, data, listener) {
    if (!big(data.amount).gt(big(0))) {
        listener({ status: false, message: "Amount must be a positive number!" });
        return;
    }

    Api.applySalesOrderFreeFormDiscount(salesOrderId, data, listener);
}

export function ApplyFreeFormDiscount({ salesOrderId, onUpdate }) {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button onClick={() => setVisible(true)} size="sm" style={{ borderRadius: 50 }} renderIcon={Tag16}>Apply Discount</Button>

        <FormDialog customPositiveLabel="Apply" open={visible} onClose={() => setVisible(false)} label="Apply" title="Discount" item={{ type: "AMOUNT", amount: "" }} setItem={onUpdate}
            saveApi={(data, listener) => onSave(salesOrderId, data, listener)}>
            {({ TextField, SwitchField, IfTargetGroup }) => (<>
                <SwitchField title="Type" fieldKey="type" options={[
                    {
                        id: 'AMOUNT',
                        title: 'Amount',
                    },
                    {
                        id: "PERCENTAGE",
                        title: 'Percentage',
                    },
                ]} />

                <IfTargetGroup targetKey={"type"} checkTargetIf={type => type === "AMOUNT"}>
                    <TextField key="amount" title="Discount Amount" fieldKey="amount" />
                </IfTargetGroup>

                <IfTargetGroup targetKey={"type"} checkTargetIf={type => type === "PERCENTAGE"}>
                    <TextField key="amount" title="Discount Percentage" fieldKey="amount" />
                </IfTargetGroup>
            </>)}
        </FormDialog>
    </>)
}