import { Accordion, AccordionItem, Checkbox, Tag } from "carbon-components-react";
import { useMemo } from "react";
import ProfilePic from "../../../../../components/ProfilePic";
import { ChevronRight16, AddFilled16, SubtractAlt16 } from '@carbon/icons-react'
import { OBJECT_TYPE_PRODUCT } from "../../../../../constants/ObjectTypes";
import Api from "../../../../../session/Api";
import Util, { big } from "../../../../../util/Util";
import RestCartContent from "../../RestCartContent";
import { useState } from "react";
import { KOT_ITEM_STATUS_AWAITING, KOT_ITEM_STATUS_DONE, KOT_ITEM_STATUS_STARTED } from "../../../../../domain/rest-sales-order";
import Button from "../../../../../components/Button";

const GenderIndicator = ({ item }) => {
    const gender = item.restGuestGender
    const isMale = gender === "M";
    const isFemale = gender === "F";

    if (isMale) {
        return <Tag type="cyan">👨 Male</Tag>
    } else if (isFemale) {
        return <Tag type="magenta">👩 Female</Tag>
    } else {
        return <div />;
    }
}

const CartItem = ({ item, isRemovingCartItem, setRemovingCartItem, getQty, setQty }) => {
    // let status;
    const status = useMemo(() => {
        if (item.flagRemovedItemRecord) {
            return null;
        }

        if (item.restKotStatus === KOT_ITEM_STATUS_AWAITING) {
            return {
                tag: null,
                color: "#1c1c1c"
            }
        } else if (item.restKotStatus === KOT_ITEM_STATUS_STARTED) {
            return {
                tag: <Tag size="sm" type="blue">Started</Tag>,
                color: "#0f62fe",
            }
        } else if (item.restKotStatus === KOT_ITEM_STATUS_DONE) {
            return {
                tag: <Tag size="sm" type="green">Done</Tag>,
                color: '#00551a'
            }
        }

        return null;
    }, [item])

    const qtyChanged = !big(item.quantityValue).eq(big(getQty()));
    const isRemoved = big(getQty()).lte(big(0))
    return (
        <div style={{ borderRadius: 5, overflow: 'hidden', background: 'white', marginBottom: '0.5rem' }}>
            <div style={{ paddingInline: '1rem', paddingBlock: '0.5rem', border: '1px solid #00000010', borderBottom: '1px solid #0353e9' }}>
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <ProfilePic notProfile size={24} src={Api.getThumbnail(item.itemType, item.itemId)} />
                    <div style={{ flex: 1 }}>
                        {item.flagRemovedItemRecord ? (<>
                            <p style={{ fontSize: 14, fontWeight: 'bold', textDecorationLine: 'line-through', textDecorationColor: 'red', textDecorationThickness: 2 }}>{item.name}</p>
                            <p style={{ fontSize: 12, textDecorationLine: 'line-through', textDecorationColor: 'red', textDecorationThickness: 2 }}>{item.quantityValue}x</p>
                        </>) : (<>
                            <p style={{ fontSize: 14, fontWeight: 'bold' }}>{item.name}</p>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <p style={{ fontSize: 12, }}>{item.quantityValue}x</p>
                                {qtyChanged && <>
                                    {isRemoved ? (
                                        <p style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>Item Removed!</p>
                                    ) : (
                                        <p style={{ fontSize: 14, fontWeight: 'bold', color: '#0f62fe' }}>Quantity Changed!</p>
                                    )}
                                </>}
                            </div>
                            {status.tag}
                            <GenderIndicator item={item} />
                        </>)}
                    </div>
                    {!item.flagRemovedItemRecord && <>
                        <Button disabled={isRemoved} kind="danger" renderIcon={SubtractAlt16} hasIconOnly style={{ borderRadius: 5 }} onClick={() => setQty(getQty() - 1)} />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f4f4f4', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', width: 48, height: 48, borderRadius: 5, border: '1px solid #00000020' }}>
                            <p>{getQty()}</p>
                        </div>
                        <Button className="green-button" renderIcon={AddFilled16} hasIconOnly style={{ borderRadius: 5 }} onClick={() => setQty(getQty() + 1)} />
                    </>}

                    {/* <ChevronRight16 style={{ opacity: 0.65, width: 12, height: 12 }} /> */}
                    {/* {status && <>
                        {!status.tag ? (
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setRemovingCartItem(!isRemovingCartItem())}>
                                <p style={{ fontSize: 12, opacity: 0.65, marginRight: '0.15rem' }}>Remove?</p>
                                <div>
                                    <Checkbox checked={isRemovingCartItem()} />
                                </div>
                            </div>
                        ) : status.tag}
                    </>} */}
                </div>
            </div>
            <div style={{ background: status?.color, width: '100%', height: 7 }} />
        </div>
    )
}


export function CartList({ state }) {
    const items = state.getCartItems();
    const kots = useMemo(() => [...new Set(items.map(item => item.restKotNo).filter($ => $))].map(kotNo => ({
        kotNo, items: items.filter(item => item.restKotNo === kotNo)
    })), [items])

    //const [removingCartItemIds, setRemovingCartItemIds] = useState([]);
    //const [removingCartItemIds, setRemovingCartItemIds] = [state.getState().removingCartItemIds, removingCartItemIds => state.setState({ removingCartItemIds })];
    const [changingCartItemQtyReqs, setChangingCartItemQtyReqs] = [state.getState().changingCartItemQtyReqs, changingCartItemQtyReqs => state.setState({ changingCartItemQtyReqs })];

    const cartItemChangeQuantity = cartItem => changingCartItemQtyReqs.find($ => $.id === cartItem.id)?.qty ?? cartItem.quantityValue;
    const setCartItemChangeQuantity = (cartItem, quantity) => {
        const changed = !big(cartItem.quantityValue).eq(big(quantity));
        if (changed) {
            setChangingCartItemQtyReqs([...changingCartItemQtyReqs.filter($ => $.id !== cartItem.id), { id: cartItem.id, qty: quantity }])
        } else {
            setChangingCartItemQtyReqs(changingCartItemQtyReqs.filter($ => $.id !== cartItem.id))
        }
    };

    return (
        <div className="complete-no-padding-accordion">
            <Accordion>
                {kots.map(kot => (
                    <AccordionItem key={kot.kotNo} title={"KOT #" + kot.kotNo}>
                        <div style={{ padding: '1rem' }}>
                            {kot.items.map(item =>
                                <CartItem
                                    key={item.id} item={item}
                                    getQty={() => cartItemChangeQuantity(item)}
                                    setQty={qty => setCartItemChangeQuantity(item, qty)}
                                />)}
                        </div>
                    </AccordionItem>
                ))}
                <AccordionItem title={<Tag type="green" size="sm" style={{ margin: 0 }}>New KOT</Tag>} open>
                    <div style={{ height: 2 }} />
                    <div>
                        <RestCartContent state={state} noHeader showNoKotOnly />
                    </div>
                </AccordionItem>
            </Accordion>
            {/* {JSON.stringify(kots)} */}
        </div>
    )
}