import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CONTACT, OBJECT_TYPE_COUPON, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_MOBILE_UNIT_DRIVER_ASSIGNATION, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL, OBJECT_TYPE_WAREHOUSE } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import UIUtil from '../../util/UIUtil';
import StockFlowListView from '../stock-flow/StockFlowListView';


const DriversList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Assigned Drivers",
                subTitle: "Drivers assigned to this mobile unit",
                objectType: OBJECT_TYPE_MOBILE_UNIT_DRIVER_ASSIGNATION,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_MOBILE_UNIT_DRIVER_ASSIGNATION}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                parentId={item.id}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}

const StocksView = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <StockFlowListView locationList={{ id: item.id, type: OBJECT_TYPE_MOBILE_UNIT }} />
        </div>
    )
}


class MobileUnitDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_MOBILE_UNIT, listener)
            } else {
                Api.getItem(OBJECT_TYPE_MOBILE_UNIT, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_MOBILE_UNIT,
                    itemResult: this.state.itemResult,
                    pagePath: "/mobile-units/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Drivers",
                            component: DriversList
                        },
                        {
                            title: "Stock",
                            component: StocksView
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(MobileUnitDetailPage);