import { Tag, TextInput } from "carbon-components-react";
import { useCallback, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import { SeatSelector } from "./seat-selector";

import { ArrowLeft32, Ticket24, ErrorFilled32, CheckmarkFilled16, Reset16, Receipt20 } from '@carbon/icons-react'
import Logo from '../../images/star-cinema.png'
import { big } from "../../util/Util";
import { VAT } from "../../app/Config";

const SeatSelect = ({ showtimeId, setSeats }) => {
    const onGridRawUpdate = useCallback(({ rowCount, colCount, selections, seatTypes }, funcs) => {
        setSeats(Object.entries(seatTypes).filter(entry => entry[1]?.enabled === true && selections[entry[0]] === true).map(entry => {
            const [row, col] = entry[0].split(",");

            return {
                row: row,
                col: col,
                posName:
                    (funcs.rows().find(item => item.row == row)?.value ?? row) +
                    (funcs.cols().find(item => item.col == col)?.value ?? col),
                seatType: entry[1].type
            }
        }))
    }, [])
    return <SeatSelector itemId={showtimeId} onGridRawUpdate={onGridRawUpdate} />
}

const PlaceOrderBtn = ({ showtimeId, seats }) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const onPurchaseBtn = () => {
        if (!window.confirm("Please confirm this demo ticket purchase")) {
            return;
        }

        setLoading(true);
        Api.demo_purchaseMovieTickets({
            showtimeId, seats: seats.map(seat => ({ row: seat.row, col: seat.col }))
        }, response => {
            if (response.status === true) {
                history.replace('/cinema-web-s/ticket-sale/' + response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    return <Button loading={loading} onClick={onPurchaseBtn} disabled={seats.length === 0} renderIcon={CheckmarkFilled16} style={{ borderRadius: 10, width: '100%', marginTop: '1rem' }}>Place Order</Button>
}

const FinalizeOrder = ({ showtimeId, seats }) => {
    const amts = useMemo(() => {
        const subtotal = seats.map(item => big(item.seatType?.defaultTicketPrice ?? 0)).reduce((t, c) => t.add(c), big(0)).round(2, 1)
        const tax = big(VAT.FRAC).times(subtotal).round(2, 1)
        const total = subtotal.plus(tax).round(2, 1)
        return { subtotal: subtotal.toFixed(2), tax: tax.toFixed(2), total: total.toFixed(2) }
    }, [seats])
    return (<>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ margin: 0, flex: 1 }} className="bx--label">Subtotal</label>
            <h4 style={{ fontSize: 14 }}>AED {amts.subtotal}</h4>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ margin: 0, flex: 1 }} className="bx--label">VAT {`${VAT.PERC}%`}</label>
            <h4 style={{ fontSize: 14 }}>AED {amts.tax}</h4>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <label style={{ margin: 0, flex: 1 }} className="bx--label">Total</label>
            <h4 style={{}}>AED {amts.total}</h4>
        </div>

        <PlaceOrderBtn showtimeId={showtimeId} seats={seats} />
    </>)
}

const SessionCountdown = () => {
    const onComplete = () => {
        UIUtil.showForcedOverlay(onClose => (
            <div style={{ borderRadius: 15, width: 400, height: 225, flexDirection: 'column', background: '#1c1c1c', color: 'white', border: '1px solid #ffffff20', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ErrorFilled32 style={{ color: '#990000' }} />
                <h4>Order session expired</h4>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Your 5 minutes is up</p>


                <Button renderIcon={Reset16} size="sm" style={{ marginTop: '1rem', borderRadius: 50 }} onClick={() => {
                    window.location.reload();
                }}>Reload</Button>
            </div>
        ))
    }

    return (
        <Countdown zeroPadDays={2} zeroPadTime={2} onComplete={onComplete}
            date={Date.now() + (1000 * 60 * 5)}
            // date={Date.now() + (1000 * 3)}
            renderer={({ hours, minutes, seconds, completed, formatted }) => {
                if (completed) {
                    // Render a completed state
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>TIME LEFT</p>
                            <p style={{ fontSize: 14 }}>{'00'}:{'00'}</p>
                        </div>
                    )
                } else {
                    // Render a countdown
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>TIME LEFT</p>
                            <p style={{ fontSize: 14 }}>{formatted.minutes}:{formatted.seconds}</p>
                        </div>
                    );
                }
            }} />
    )
}

function View() {
    const { showtimeId } = useParams();
    const [seats, setSeats] = useState([]);
    const history = useHistory();

    return (
        <div style={{ minHeight: '100vh', background: '#0f0f0f' }}>
            <div style={{ width: '100%', height: '6rem', background: '#1b1c3c', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ffffff10' }}>
                <div onClick={history.goBack} className="cinema-demo-header-button">
                    <ArrowLeft32 />
                </div>
                <div style={{ flex: 1 }} />
                <Link to="/cinema-web">
                    <img src={Logo} style={{ height: 75 }} />
                </Link>
                <div style={{ flex: 1 }} />
                <div style={{ width: '6rem' }}></div>
            </div>
            <div style={{ width: '100%', height: '3rem', background: '#11122b', paddingInline: '6rem', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <Ticket24 />
                <h4 style={{ flex: 1 }}>Ordering Tickets</h4>
                <SessionCountdown />
            </div>

            <div style={{ marginTop: '3rem', display: 'flex', paddingInline: '6rem', gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <SeatSelect showtimeId={showtimeId} setSeats={setSeats} />
                </div>
                <div style={{ width: 300, height: 500, background: '#1c1c1c', borderRadius: 15, border: '1px solid #00000090', color: 'white', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ padding: '1rem', borderBottom: '1px solid #00000090', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        <Receipt20 />
                        <h4>Order Summary</h4>
                    </div>
                    <div style={{ flex: 1, overflow: 'auto', }}>
                        {seats.map(seat => (
                            <div key={seat.row + ',' + seat.col + '-ticket'} style={{ width: '100%', paddingInline: '1rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000040', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                <Ticket24 />
                                <div style={{ flex: 1, }}>
                                    <p>{seat.seatType?.name ?? 'Seat'}</p>
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>{seat.posName}</p>
                                </div>
                                <Tag size="sm" type="blue"><strong>AED</strong> {seat.seatType?.defaultTicketPrice}</Tag>
                            </div>
                        ))}
                    </div>
                    <div style={{ padding: '1rem', borderTop: '1px solid #00000090' }}>
                        <FinalizeOrder showtimeId={showtimeId} seats={seats} />
                    </div>
                </div>
            </div>

            <div style={{ height: '3rem' }} />
        </div>
    )
}

export const CinemaDemoOrderPage = View;