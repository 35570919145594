
import { Checkbox, ComposedModal, ModalBody, ModalFooter, ModalHeader, Tag, TextInput } from "carbon-components-react";
import Button from "../../../../components/Button";
import { Frame } from "../../../../frame";
import { useFrameConnection } from "../../../../frame/useFrameConnection";
import { useEffect, useState } from "react";
import UIUtil from "../../../../util/UIUtil";
import { ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY } from "../../../../app/Config";




export function CustomizeFoodDialog({ open, onClose, onDone, itemId, numberItemId, defaultValues, loadingDone }) {
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(0);
    const [frameRef, connection] = useFrameConnection({
        updateHeight: ({ height }) => {
            setHeight(height);
        }
    });

    useEffect(() => {
        if (open && height) {
            connection.loadProduct({ itemId, numberItemId, defaultRecipeOptions: defaultValues, accessKey: ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY });
        }
    }, [open, height, itemId, numberItemId])

    const onDoneBtn = async () => {
        setLoading(true);
        try {
            const { itemId, recipeOptions } = await connection.getRecipeOptions({ accessKey: ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY });
            onDone(itemId, recipeOptions);
        } catch (e) {
            UIUtil.showError();
        } finally {
            setLoading(false);
        }
    }

    return (
        <ComposedModal size="sm" open={open} onClose={onClose}>
            <ModalHeader label="Food" title="Customize" />
            <ModalBody hasScrollingContent style={{ paddingRight: '1rem' }}>
                <div style={{ height: `calc(${height}px + 2rem)` }}>
                    <Frame ref={frameRef} path="/backend/customize" />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    loading={loading || loadingDone}
                    onClick={onDoneBtn}>
                    Done
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}