import {
    Notebook32, Add16
} from '@carbon/icons-react'
import { InlineLoading } from 'carbon-components-react';
import { useRef, useState } from 'react'
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_LEAD } from '../../../../constants/ObjectTypes'
import { AdvancedNotesView } from '../../../../views/advanced-notes/advanced-notes-view';
import NoteView from '../../../../views/notes/NoteView'

export function NoteDocs({ leadId }) {
    return (
        <div style={{ width: '100%', height: 600, overflow: 'auto' }}>
            <AdvancedNotesView title="Notes/Docs" objectId={leadId} objectType={OBJECT_TYPE_LEAD} />
        </div>
    )

    // const [adding, setAdding] = useState(false);
    // const noteRef = useRef();
    // const onAddBtn = () => {
    //     noteRef.current.onAddBtn();
    // }
    // return (
    //     <div>
    //         <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', padding: '1rem' }}>
    //             <Notebook32 style={{ width: 32, height: 32 }} />
    //             <h4 style={{ fontWeight: 'bold', flex: 1 }}>Notes/Docs</h4>
    //             {/* <button onClick={onAddBtn} className='lead-flow-icon-button' style={{ pointerEvents: adding ? 'none' : undefined }}>
    //                 {adding ? <InlineLoading style={{ width: 16, height: 16 }} /> :
    //                     <Add16 />}
    //             </button> */}
    //         </div>
    //         <div style={{ overflow: 'auto', height: 300, }}>
    //             <NoteView ref={noteRef} miniMode hideAddBtn objectType={OBJECT_TYPE_LEAD} objectId={leadId} onCreatingNoteChange={setAdding} />
    //         </div>
    //     </div>
    // )
}