import { InlineLoading, Tag } from "carbon-components-react";
import { TrashCan16, Reset16, CheckmarkFilled16, TextScale16, Settings16 } from '@carbon/icons-react'
import ReactTooltip from "react-tooltip";
import { useMemo, useState } from "react";
import useHover from "../../../hooks/useHover";
import useStore from "../../../hooks/useStore";
import Util from "../../../util/Util";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import isEqual from 'lodash.isequal';
import { getAccountRole } from "../../../session/SessionManager";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";

function useHint() {
    const [message, setMessage] = useState("");
    return {
        message: Util.isStringExists(message) ? message : null,
        init: (message) => ({
            onMouseEnter: () => setMessage(message),
            onMouseLeave: () => setMessage("")
        }),
        clear: () => setMessage("")
    }
}


function useCanPublishDesign() {
    return useMemo(() => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }, [])
}



export function ReportDesignControl({ info, store, state, originalState, setState, setInfo, onRenameBtn, onCreateBtn, isListMode }) {
    const hint = useHint();
    const [selectedId, setSelectedId] = useStore(store, "selectedSavedReportId")

    const resetToDefault = () => {
        setState(originalState)
        hint.clear();
    }

    const [loadingDelete, setLoadingDelete] = useState(false);
    const onDeleteDesign = () => UIUtil.confirm(() => {
        setLoadingDelete(true);
        Api.deleteReportDesign(selectedId, response => {
            if (response.status === true) {
                setInfo(prev => ({ ...prev, savedReports: prev.savedReports.filter(item => item.id != selectedId) }))
                setSelectedId(0)
            } else {
                UIUtil.showError(response.message);
            }
            setLoadingDelete(false)
        })
    })

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const onUpdateDesign = () => {
        setLoadingUpdate(true)
        Api.saveReportDesignState(selectedId, state, response => {
            if (response.status === true) {
                setState(state, { original: true })
                hint.clear();

                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoadingUpdate(false);
        })
    }

    const [loadingPublish, setLoadingPublish] = useState(false);
    const onPublishDesign = () => {
        setLoadingPublish(true)
        Api.renameReportDesign(selectedDesign.id, true, false, selectedDesign.value, response => {
            if (response.status === true) {
                setInfo(prev => ({
                    ...prev, savedReports: prev.savedReports.map(item => item.id == selectedDesign.id ? ({
                        ...selectedDesign, offeringToShowForAll: false, showForAll: true
                    }) : item)
                }))
                hint.clear();

                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setLoadingPublish(false)
        })
    }

    const changesMade = useMemo(() => {


        const prepareState = s => {
            const newS = { ...s }
            if (newS?.filters?.length === 0) {
                delete newS.filters;
            } else if (newS?.filters?.length > 0) {
                // console.log(newS.filters)
                newS.filters = [...newS.filters].sort((a, b) => a.property?.localeCompare(b.property));
            }
            if (newS?.sorts?.length === 0) {
                delete newS.sorts;
            } else if (newS?.sorts?.length > 0) {
                newS.sorts = [...newS.sorts].sort((a, b) => a.property.localeCompare(b.property));
            }
            if (newS?.formulas?.length === 0) {
                delete newS.formulas;
            } else if (newS?.formulas?.length > 0) {
                newS.formulas = [...newS.formulas].sort((a, b) => a.id.localeCompare(b.id));
            }
            if (newS?.hiddenColumns?.length === 0) {
                delete newS.hiddenColumns;
            } else if (newS?.hiddenColumns?.length > 0) {
                newS.hiddenColumns = [...newS.hiddenColumns].sort();
            }
            return newS;
        }
        const cm = !isEqual(prepareState(state), prepareState(originalState))
        // if (cm) {
        //     console.log({ state, originalState })
        // }
        return cm;
    }, [state, originalState])


    const selectedDesign = useMemo(() => Util.isNumberExist(selectedId) ? info.savedReports.find(report => report.id === selectedId) : undefined, [selectedId, info.savedReports])


    const designExists = Util.isNumberExist(selectedId);
    const canUpdate = !designExists || selectedDesign?.selfEntry === true;

    const canPublishDesign = useCanPublishDesign();

    return (<>
        <div style={{ maxHeight: 27, minHeight: 27, paddingLeft: '1rem', paddingRight: '0.5rem', gap: '0.5rem', background: isListMode ? '#ffffff' : '#0f0f0f', color: isListMode ? 'black' : 'white', borderBottomRightRadius: 5, borderTopRightRadius: 5, border: isListMode ? '1px solid #00000020' : '1px solid #000000', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '1rem' }} />

            {designExists && canUpdate && <button disabled={loadingDelete} onClick={onDeleteDesign} {...hint.init("Delete Design")} className='lead-flow-icon-button lead-flow-icon-button-danger really_centered-progress-bar' >
                {loadingDelete ? <InlineLoading /> : <TrashCan16 />}
            </button>}
            {changesMade && <button onClick={resetToDefault} disabled={false} {...hint.init("Reset to Default")} className='lead-flow-icon-button lead-flow-icon-button-secondary-2 really_centered-progress-bar' >
                {false ? <InlineLoading /> : <Reset16 />}
            </button>}
            {designExists && canUpdate && <button onClick={onRenameBtn} disabled={loadingUpdate} {...hint.init("Design Settings")} className='lead-flow-icon-button lead-flow-icon-button-secondary-2 really_centered-progress-bar' >
                {false ? <InlineLoading /> : <Settings16 />}
            </button>}
            {(changesMade && canUpdate) && <button onClick={() => {
                if (designExists) {
                    onUpdateDesign();
                } else {
                    onCreateBtn()
                }
            }} disabled={loadingUpdate} {...hint.init(designExists ? "Update Design" : "Save as Design")} className='lead-flow-icon-button lead-flow-icon-button-primary really_centered-progress-bar' >
                {loadingUpdate ? <InlineLoading /> : <CheckmarkFilled16 />}
            </button>}
            {canPublishDesign && selectedDesign?.offeringToShowForAll && <button onClick={onPublishDesign} disabled={loadingPublish} {...hint.init("Publish")} className='lead-flow-icon-button lead-flow-icon-button-success really_centered-progress-bar' >
                {loadingPublish ? <InlineLoading /> : <CheckmarkFilled16 />}
            </button>}
        </div>
        {selectedDesign?.showForAll && <Tag style={{ marginLeft: '1rem' }} size="sm" type="blue">Public</Tag>}
        {selectedDesign?.offeringToShowForAll && <Tag style={{ marginLeft: '1rem' }} size="sm" type="teal">Requesting To Publish</Tag>}
        {hint.message && <p key={hint.message + 'report-toolbar-menu'} style={{ fontSize: 12, opacity: 0.65, marginLeft: '1rem', animation: '250ms report-engine-menu-hint-fade-in' }}>
            {hint.message}
        </p>}
    </>)
}