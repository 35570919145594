import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

class SupplierListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Suppliers", 
                    subTitle: "All suppliers", 
                    objectType: OBJECT_TYPE_SUPPLIER, 
                    pagePath: "/suppliers/", 
                    history: this.props.history
                })}
            </div>
        )
    }
    
    isPageLoadable() {
        return false;
    }

}

export default withRouter(SupplierListPage);