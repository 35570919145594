

const DEF_VALUE = []

export function AdvancedFilterIndicator({ state, setShowAdvancedFilter }) {
    const [items, setItems] = state.use("advancedFilterItems", DEF_VALUE)

    if (items.length > 0) {
        return (
            <div style={{ height: 20, background: '#080808', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p onClick={() => setShowAdvancedFilter(true)} style={{ color: '#0f62fe', fontSize: 12, cursor: 'pointer' }}>Advanced Filter Active</p>
            </div>
        )
    } else {
        return null;
    }
}