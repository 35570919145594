import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";
import { getReportLink } from "../../base/report-page";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider";
import { LocationFilter } from "../stock-by-location/location-filter";
import { ProductFilter } from "../stock-by-location/product-filter";

export const CostingAudit = {
    filter: Filter,

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter />
        <Divider />
        <div style={{ minHeight: 30, flex: 1 }}>
            <ProductFilter state={state} options={endpoint.products} skipAutoFilter={false} />
        </div>
    </>)
}
