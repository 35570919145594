import { OverflowMenu, OverflowMenuItem } from "carbon-components-react"
import { makeDraggable, useDraggable } from "../../hooks/useDraggable"
import { AddFilled32 } from '@carbon/icons-react'
import Util from "../../util/Util"
import { useEffect, useMemo } from "react"
import UIUtil from "../../util/UIUtil"

const InputFieldItemHeader = () => (
    <div style={{ flex: 2, display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'white', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
)

const InputFieldItem = ({ headerColor, itemHeaderTitle, item, setItem, onRemove, SortHandler }) => {
    const getHeaderColor = () => {
        if (headerColor) {
            return typeof headerColor === "function" ? (headerColor(item.rawItem) ?? "#1c1c1c") : headerColor;
        } else {
            return '#1c1c1c'
        }
    }
    return (
        <div style={{
            width: '100%', background: 'white', marginBottom: '1rem', padding: '0rem', borderRadius: 5,
            boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06)',
            // overflow: 'hidden',
            border: 'solid', borderWidth: 1, borderColor: '#00000090'
        }}>
            {/* <div>
                {JSON.stringify(items)}
                <button onClick={() => setItem2(item)}>sync</button>
            </div> */}
            <div style={{ background: getHeaderColor(), width: '100%', height: '25px', color: 'white', display: 'flex', alignItems: 'center', paddingInline: '0.5rem' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}><strong>{itemHeaderTitle}: </strong>{item.title}</p>
                </div>
                <SortHandler />
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <p onClick={onRemove} style={{ fontSize: 12, color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>Remove Entry</p>
                </div>
            </div>
            <div style={{ padding: '1rem', paddingBottom: '0rem', paddingTop: '0.75rem' }}>
                {item.ParamsField && <item.ParamsField item={item} setItem={setItem} />}
            </div>
        </div>
    )
}

const NewInputFieldBtn = ({ dateTypes, createNew }) => {
    return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <OverflowMenu
                ariaLabel="add-field-menu"
                renderIcon={AddFilled32}
                size="lg"
            >

                {dateTypes.map(item => <OverflowMenuItem key={item.type} itemText={item.title} onClick={() => createNew(item.type)} />)}
            </OverflowMenu>
        </div>
    )
}

const draggable = makeDraggable(InputFieldItem, InputFieldItemHeader);

const InputFields = ({ unqiueOnly, headerColor, itemHeaderTitle, items, setItems, dateTypes }) => {
    // const draggable = useDraggable(InputFieldItem, InputFieldItemHeader);
    const createNew = type => {
        setItems(items => {
            if (!items) {
                items = []
            }

            if (unqiueOnly && items.find(item => item.type === type) !== undefined) {
                UIUtil.showInfo("Entry already exists")
                return items;
            }
            return [...items, { ...dateTypes.find(item => item.type == type), id: Util.newTempId(), params: {} }]
        })
    }
    const removeItem = item => setItems(items => items.filter(other => other.id !== item.id))
    const createSetItem = (item) => (arg, i) => setItems(items => {
        let update;
        if (typeof arg == 'function') {
            update = arg(item);
        } else {
            update = arg;
        }

        const newItems = [...items]
        newItems[newItems.findIndex(other => other.id === update.id)] = update;

        return newItems;
    })

    return (
        <div>
            {/* {JSON.stringify(items)} */}
            <draggable.SortContainer onSortEnd={draggable.createOnSortEnd(items, setItems)}>
                {items.map((item, index) => <draggable.SortItem
                    itemHeaderTitle={itemHeaderTitle} headerColor={headerColor}
                    key={item.id} index={index} item={item} setItem={createSetItem(item)} onRemove={() => removeItem(item)} />)}
            </draggable.SortContainer>
            <NewInputFieldBtn dateTypes={dateTypes} createNew={createNew} />
        </div>
    )
}

export const FormEditor = InputFields;