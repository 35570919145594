import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { TableHeader } from './TableHeader';
import TableFooter from './TableFooter';
import TableRows from './TableRows';

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 6,
        borderWidth: 1,
        borderColor: '#00148C',
    },
});

const Table = ({ report }) => (
    <View style={styles.tableContainer}>
        <TableHeader />
        <TableRows items={report.records} />

        <TableFooter title="Total" value={report.total} />
        <TableFooter title="Closing Balance" value={report.closingBalance} />
    </View>
);

export default Table