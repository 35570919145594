
import {
    RowDelete16
} from '@carbon/icons-react'
import { useEffect, useState } from 'react';
import Util from '../util/Util';
import Button from './Button';


const createUseValueHook = (item, setItem) => (param, defValue) => {
    // const [value, setValue] = useState(item[param] ?? defValue)
    // useEffect(() => {
    //     setItem()
    // }, [value])


    const value = item[param]
    return [value ?? defValue, newValue => {
        setItem({ ...item, [param]: newValue })
    }]
}

const Entry = ({ onRemove, item, setItem, last, editable, columns, entryViewProps, entryView, lightBorder }) => {
    const entryViewData = entryView({
        item, setItem, editable,
        useValue: createUseValueHook(item, setItem),
        ...entryViewProps
    });
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: lightBorder ? '#00000020' : 'black', }}>
            {columns.map((column, i) => (
                <div key={column.title + '-key-entry'} style={{ flex: column.flex, width: column.width, paddingLeft: '0rem', display: 'flex' }}>
                    {entryViewData[i]}
                </div>
            ))}
            {editable &&
                <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                    size="sm" disabled={last}
                    style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />}
        </div>
    )
}

export const DynamicTable = ({ items = [], minimalInput, setItems, editable, entryView, columns, entryViewProps, noRadius, noBorder, lightBorder, whiteBg }) => {
    const removeItem = item => setItems(items.filter(i => i.tempId !== item.tempId))

    const updateItem = item => {
        const newItems = [...items]
        const index = newItems.findIndex(i => i.tempId === item.tempId);
        if (index >= 0) {
            newItems[index] = item;
            setItems(newItems)
        }
    }

    useEffect(() => {
        if (editable) {
            const lastItem = items[items.length - 1];
            if (!lastItem || Object.keys(lastItem).length > 1) {
                setItems([...items, { tempId: Util.newTempId() }])
            }
        }
    }, [items, editable])

    return (
        <div>
            <div className={"no-input-border-2 " + (minimalInput ? "minimal-input " : "")} style={{ background: whiteBg ? '#ffffff' : '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: noRadius ? 0 : 5, borderWidth: noBorder ? 0 : 1, borderBottomWidth: 0, }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    {columns.map(column => (
                        <div key={column.title + '-key'} style={{ flex: column.flex, width: column.width, paddingLeft: '0rem', display: 'flex' }}>
                            <div style={{ width: minimalInput ? 5 : '1rem' }} />
                            <h6>{column.title}</h6>
                        </div>
                    ))}
                    {editable && <div style={{ width: 40, height: 0, }} />}
                </div>
                {items.map((item, i) =>
                    <Entry key={item.tempId}
                        item={item} setItem={updateItem}
                        last={i === items.length - 1} columns={columns}
                        editable={editable} entryView={entryView}
                        entryViewProps={entryViewProps}
                        lightBorder={lightBorder}
                        onRemove={() => removeItem(item)} />)}
            </div>
        </div>
    )
}