import { CenterSquare16, Add16, Subtract16 } from '@carbon/icons-react'

export const SeatItem = ({ grid, row, col }) => {
    const enabled = grid.isEnabled(row, col);

    if (enabled) {
        const seatType = grid.getSeatType(row, col);
        const background = seatType?.color ? `${seatType?.color}` : 'rgba(184, 115, 11, 0.27)'
        return (
            <div className="seat-node-enabled" onClick={() => grid.toggleSelection(row, col)} style={{ background, border: grid.isSelected(row, col) ? '1px solid #ffffff' : undefined }}>
            </div>
        )
    } else {
        return (
            <div className="seat-node" onClick={() => grid.toggleSelection(row, col)} style={{ opacity: grid.isSelected(row, col) ? 1 : undefined }}>
                <CenterSquare16 />
            </div>
        )
    }
}
