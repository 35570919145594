import { useCallback, useEffect, useMemo, useRef } from "react";
import useStore from "../../hooks/useStore";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import { classExistsInSomeParent, nodeNameExistsInSomeParent } from "../label-designer/util/ParentFinder";

export function useManageEventSelection(store, calendarRef) {
    const [selectedEvent, setSelectedEvent] = useStore(store, "selectedEvent");
    const lastSelectedEvent = useRef(selectedEvent);

    useEffect(() => {
        if (lastSelectedEvent.current && selectedEvent !== lastSelectedEvent.current) {
            const event = lastSelectedEvent.current;
            if (event.extendedProps?.activity === undefined) {
                event.remove();
            }
        }

        if (selectedEvent) {
            lastSelectedEvent.current = selectedEvent;
        }
    }, [selectedEvent])
    // useEffect(() => {
    //     if (selectedEvent) {
    //         selectedEvent.setProp('backgroundColor', '#000000')
    //         return () => {
    //             const event = calendarRef.current?.getApi().getEventById(selectedEvent.id);
    //             if (event) {
    //                 event.setProp('title', "OOps")
    //                 event.setProp('backgroundColor', undefined)
    //                 event.setProp('backgroundColor', undefined)
    //             }
    //         }
    //     }
    // }, [selectedEvent])

    useEffect(() => {
        const onClickHandle = e => {
            if (nodeNameExistsInSomeParent(e.target, "button")) {
                return;
            }
            if (classExistsInSomeParent(e.target, "activity-inspector")) {
                return;
            }
            if (classExistsInSomeParent(e.target, "fc-event")) {
                return;
            }
            if (classExistsInSomeParent(e.target, "react-tiny-popover-container")) {
                return;
            }
            if (classExistsInSomeParent(e.target, "bx--modal")) {
                return;
            }

            // console.log(e.target)
            setSelectedEvent(undefined);
        };
        window.addEventListener('mousedown', onClickHandle);
        return () => window.removeEventListener('mousedown', onClickHandle)
    }, [])
}

export function useHandleEventModificationAndSelection(store) {
    const unselectedEvent = useRef(null);

    const onStart = useCallback(() => {
        const selectedEvent = store.get("selectedEvent");
        if (selectedEvent) {
            unselectedEvent.current = selectedEvent;
        }
        store.set("selectedEvent", undefined)
    }, [])

    const onStop = useCallback(() => {
        if (unselectedEvent.current) {
            store.set("selectedEvent", unselectedEvent.current)
        }
        unselectedEvent.current = null;
    }, [])

    return {
        eventResizeStart: onStart,
        eventResizeStop: onStop,
        eventDragStart: onStart,
        eventDragStop: onStop,
    }
}

export function onSelection(info) {
    const event = info.view.calendar.addEvent({
        id: Util.newTempId(),
        // title: "New activity",
        start: info.start,
        end: info.end,
        allDay: info.allDay,
        editable: true
    })
    info.view.calendar.unselect();
    return event;
}

const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)
export function parseApiCalendarEvent(event) {
    return {
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),

        borderColor: event.backgroundColor,
        textColor: Util.isStringExists(event.backgroundColor) ? isLightColor(event.backgroundColor) ? 'black' : 'white' : undefined
    }
}

export function eventsLoader(leadId, courseId, info, success, failure) {
    Api.getCalendarEvents(info.start.getTime(), info.end.getTime(), leadId, courseId, response => {
        if (response.status === true) {
            success(response.payload.map(parseApiCalendarEvent))
        } else {
            UIUtil.showError("Failed to load more events")
            failure({ message: response.message ?? "An error occurred. Please try again later." })
        }
    })
}

export function useEventActivity(store, activityDetailsPayload) {
    const [selectedEvent, setSelectedEvent] = useStore(store, "selectedEvent");
    const payload = useMemo(() => ({
        ...activityDetailsPayload,
        activity: selectedEvent?.extendedProps?.activity
    }), [activityDetailsPayload, selectedEvent])
    const removeEvent = () => {
        selectedEvent.remove();
        setSelectedEvent(undefined)
    }
    return { selectedEvent, payload, removeEvent }
}