
import { Link } from 'carbon-components-react'
import React from 'react'

export const SectionView = ({children, icon, title, extraTopMargin, hasLink, hasButton, linkText, onLinkClick}) => (
    <div style={{marginTop: extraTopMargin ? '6rem' : '3rem'}}>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
            {React.createElement(icon)}
            <p style={{flex: hasButton ? 1 : undefined}}>{title}</p>


            {hasLink && <Link style={{marginLeft: '1rem', fontSize: 12, cursor: 'pointer'}} onClick={onLinkClick}>{linkText}</Link>}
        </div>
        {children}
    </div>
)

//export default ({ children })