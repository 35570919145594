import { useState } from "react";
import { withLoadablePage, withLoadablePageAndProps } from "../../base/Page";
import Api from "../../session/Api";
import { SideBar, SideBarAdd, SideBarItem } from "./side-bar";
import { CodeEditor } from "./code-editor";
import UIUtil from "../../util/UIUtil";
import Button from "../../components/Button";
import { Link } from "carbon-components-react";

const srcSaver = (fragment, src, listener) =>
    Api.saveCustomFragmentSrc(fragment.id, src, listener);

const srcLoader = (fragment, listener) =>
    Api.loadCustomFragmentSrc(fragment?.id, listener);


const View = ({ payload, showSidebar }) => {
    const [fragments, setFragments] = useState(payload);
    const [selectedFrag, setSelectedFrag] = useState(null)

    const createNewFragment = async () => {
        const name = await UIUtil.inputPrompt({
            title: "New Fragment",
            message: "Enter the name of the new fragment"
        })
        if (!name) {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.createCustomFragment(name, listener));
            if (!success) {
                return;
            }

            setFragments(fragments => [...fragments, response]);
        } finally {
            stopLoading();
        }
    }

    const onRenameBtn = async () => {
        const name = await UIUtil.inputPrompt({
            title: "Rename Fragment",
            message: "Enter the new name of the fragment",
            initialValue: selectedFrag.name
        })
        if (!name) {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.renameCustomFragment(selectedFrag.id, name, listener));
            if (!success) {
                return;
            }

            setFragments(fragments => fragments.map(frag => frag.id === selectedFrag.id ? response : frag));
            setSelectedFrag(response);
        } finally {
            stopLoading();
        }
    }

    const onDeleteBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            title: "Delete Fragment",
            message: "Are you sure you want to delete this fragment?"
        })
        if (!confirmed) {
            return;
        }


        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.deleteCustomFragment(selectedFrag.id, listener));
            if (!success) {
                return;
            }

            setFragments(fragments => fragments.filter(frag => frag.id !== selectedFrag.id));
            setSelectedFrag(null);
        } finally {
            stopLoading();
        }
    }

    return (
        <div className="robo-ide-root">
            <SideBar visible={showSidebar}>
                {fragments.map(fragment => (
                    <SideBarItem
                        key={fragment.id}
                        title={fragment.name}
                        selected={selectedFrag?.id === fragment.id}
                        onClick={() => setSelectedFrag(fragment)}
                    />
                ))}
                <SideBarAdd onClick={() => createNewFragment()} />
            </SideBar>

            <CodeEditor lang="html" selected={selectedFrag}
                srcLoader={srcLoader} srcSaver={srcSaver}
                toolbarTopRight={<>
                    <Link style={{ cursor: 'pointer', color: 'red', marginRight: '0.25rem' }} onClick={onDeleteBtn}>Delete</Link>

                    <Link style={{ cursor: 'pointer' }} onClick={onRenameBtn}>Rename</Link>
                </>}
            />
        </div>
    )
}


export const Fragments = withLoadablePageAndProps(Api.listCustomFragments.bind(Api), View);