import { Button, Checkbox, ComboBox, ContentSwitcher, NumberInput, Slider, Switch, Tab, Tabs, TextArea, TextInput } from 'carbon-components-react'
import React from 'react'
import DimensionsField from './base/DimensionsField'
import InspectorContentHeader from './base/InspectorContentHeader'

import useCanvasComponentInspectorField from '../../hooks/useCanvasComponentInspectorField'
import ColorField from './base/ColorField'
import useNumberValueSetter from '../../hooks/useNumberValueSetter'
import useBooleanToggler from '../../hooks/useBooleanToggler'

import { 
    Information16
} from '@carbon/icons-react'
import { getFontFamilies } from '../../util/FontUtil'
import { ALL_BARCODE_TYPES } from '../../../../constants/BarcodeConstants'

export default ({engine, componentId}) => {
    const { changeListener, getter, setter, multiSetter } = useCanvasComponentInspectorField(engine, componentId, ['barcodeValue', 'barcodeType', 'valueDynamic', 'fill', 'backgroundColor']);
    const [isValueDynamic, toggleValueDynamic] = useBooleanToggler('valueDynamic', true, false, getter, setter);

    return (
        <div>
            <InspectorContentHeader text="Content" />
            <div className="short-width-tabs">
                <Tabs selected={isValueDynamic ? 1 : 0} onSelectionChange={index => toggleValueDynamic()}>
                    <Tab id="static" label="Static">
                        <TextInput {...changeListener} labelText="Value" value={getter('barcodeValue')} onChange={e => setter('barcodeValue', e.target.value)} />
                        <div style={{height: '1rem'}} />
                        <ComboBox titleText="Type" items={ALL_BARCODE_TYPES}
                        selectedItem={getter('barcodeType')}
                        onChange={e => setter('barcodeType', e.selectedItem ? e.selectedItem : 'CODE128', true)} />
                    </Tab>
                    <Tab id="dynamic" label="Dynamic">
                        {engine.getTemplate().supportDynamicBarcode ? (
                            <p style={{fontSize: 14, opacity: 0.65}}>{engine.getTemplate().itemName}'s first barcode will be displayed</p>
                        ) : (
                            <p style={{fontSize: 14, opacity: 0.65, color: 'red'}}>Dynamic barcode is not supported in template type '{engine.getTemplate().name}'</p>
                        )}
                    </Tab>
                </Tabs>
            </div>

            <InspectorContentHeader text="Appearance" hasTopMargin />
            <ColorField title="Color" color={getter('fill')} onChange={color => setter('fill', color, true)} />
            <ColorField title="Background" color={getter('backgroundColor')} onChange={color => setter('backgroundColor', color, true)} />
            <div style={{height: '1rem'}} />
            <div style={{display: 'flex', alignItems: 'flex-start', marginBottom: '1rem', marginTop: '0.25rem'}}>
                <Information16 />
                <p style={{fontSize: 12, flex: 1, marginLeft: '0.25rem', opacity: 0.65}}>Ensure a high contract color-background combination</p>
            </div>
        </div>
    )
}