import { TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import Util from "../util/Util";
import { Warning16, Password16 } from '@carbon/icons-react'
import Button from "./Button";


export function PasswordField({ labelText, helperText, value, setValue }) {
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    useEffect(() => {
        if (Util.isStringExists(password)) {
            if (value !== password && password === confirm) {
                setValue(password);
            }

            if (password !== confirm && Util.isStringExists(value)) {
                setValue("")
            }
        } else {
            setValue("");
        }
    }, [value, password, confirm])

    return (<>
        <div style={{ marginBottom: '1rem' }}>
            <TextInput labelText="Password" helperText={helperText} value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <div style={{ marginBottom: '1rem' }}>
            <TextInput labelText="Confirm Password" value={confirm} onChange={e => setConfirm(e.target.value)} />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
            <Button size="sm" style={{ borderRadius: 15 }} renderIcon={Password16} onClick={() => {
                const pass = Math.random().toString(36).substring(2, 8);
                setPassword(pass);
                setConfirm(pass)
            }}>Generate password</Button>
        </div>


        {(Util.isStringExists(password)) && password !== confirm &&
            <p style={{ fontSize: 12, color: '#990000', marginBottom: '1rem', marginTop: '-0.5rem' }}><Warning16 style={{ marginRight: '0.15rem' }} /> Passwords do not match!</p>}
    </>)
}