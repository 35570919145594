import { useState } from "react";
import Button from "../../../../components/Button";
import { Tag16 } from '@carbon/icons-react'
import { CartList } from "../views/modify-order/cart-list";
import PosDialogs from "../../PosDialogs";
import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import PosState from "../../state/PosState";
import Page from "../../../../base/Page";
import CouponWidget from "../../widgets/CouponWidget";
import { SidePanel } from "../../../../templates/draft/components/side-panel";

class Pos extends Page {

    state = {

    }

    constructor(props) {
        super(props);

        this.posState = new PosState(() => this.state, this.setState.bind(this));
        this.posState.updateOrder = props.updateOrder;
    }

    onPageStart() {
        this.posState.loadSalesOrderSession(this.props.order.id);
    }

    componentWillUnmount() {
        this.posState.cancelSubscriptions();
    }

    getLayout() {
        return (
            <div className="pos" style={{ display: 'flex', height: '100%', width: '100%', padding: '0rem', paddingLeft: '1rem', background: '#f4f4f4', userSelect: 'none', WebkitUserSelect: 'none', }} >
                <CouponWidget state={this.posState} hideLoyalty />

                <PosDialogs posState={this.posState} />
            </div>
        )
    }

    isPageLoading() {
        return this.posState.isLoadingPos() || !this.posState.getSession()
    }

}

export function DiscountBtn({ order, updateOrder }) {
    const [visible, setVisible] = useState(false)
    return (
        <>
            <Button style={{ maxWidth: 'unset' }} onClick={() => setVisible(true)} renderIcon={Tag16} kind="secondary" size="md">Discounts</Button>

            {visible && <SidePanel onClose={() => setVisible(false)} backBtn={() => setVisible(false)}>
                <Pos order={order} updateOrder={updateOrder} />
            </SidePanel>}

            {/* {visible && (
                <ComposedModal key="order-discounts" size="lg" open={visible} onClose={() => setVisible(false)}>
                    <ModalHeader label="Order" title="Discounting" />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        <Pos order={order} />
                    </ModalBody>
                    <ModalFooter
                    //secondaryButtonText="Close"
                    >
                        <Button kind="secondary" onClick={() => setVisible(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </ComposedModal>

            )} */}
        </>
    )
}