import { InlineLoading } from "carbon-components-react";
import { useEffect, useState } from "react";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { MinimalTable } from "../../../components/minimal-table";
import Button from "../../../components/Button";
import { ArrowRight16, CheckmarkFilled16 } from '@carbon/icons-react'
import { isMobileScreen } from "../../../App";

const JoinBtn = ({ item }) => {
    const [loading, setLoading] = useState(false);
    const [joined, setJoined] = useState(false);

    const onJoinBtn = () => UIUtil.confirm(() => {
        setLoading(true);
        Api.studentJoinSession(item.id, response => {
            if (response.status === true) {
                setJoined(true)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }

            setLoading(false)
        })
    })

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.15rem', justifyContent: 'flex-end', width: '100%', minWidth: 102 }}>
            {joined ? (
                <>
                    <CheckmarkFilled16 style={{ color: 'green' }} />
                    <p style={{ color: 'green', fontSize: 12 }}>Joined!</p>
                </>
            ) : (
                <Button onClick={onJoinBtn} loading={loading} renderIcon={ArrowRight16} size="sm" style={{ borderRadius: 50 }}>Join</Button>
            )}
        </div>
    )
}

export function TutorAvailabilityReport({ courseId, tutorId, onClose }) {
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        Api.getTutorAvailabilityReport(courseId, tutorId, response => {
            if (response.status === true) {
                setReport(response.payload);
            } else {
                UIUtil.showError(response.message);
                onClose();
            }
            setLoading(false);
        })
    }, [courseId, tutorId])


    if (loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    return (
        <div style={{ overflowX: 'auto', padding: '1rem' }}>
            <div style={{ width: !isMobileScreen ? '100%' : '300%', position: 'relative' }}>
                <MinimalTable large columns={[
                    {
                        name: "day",
                        title: "Day",
                        flex: 2,
                        renderCell: item => Util.getDay(item.startDate)
                    },
                    {
                        name: "date",
                        title: "Date",
                        flex: 2,
                        renderCell: item => Util.getDateOnly(item.startDate)
                    },
                    {
                        name: "time",
                        title: "Time",
                        flex: 2,
                        renderCell: item => Util.getTime(item.startDate) + ' - ' + Util.getTime(item.endDate)
                    },
                    {
                        name: "type",
                        title: "Type",
                        flex: 4,
                        renderCell: item => item.blueprintName + ' - ' + item.coursePaymentPackageName
                    },
                    {
                        name: "price",
                        title: "Price",
                        flex: 2,
                        renderCell: item => 'AED ' + Util.formatMoney(item.coursePaymentPackagePrice) + ' ' + (item.coursePaymentPackageHourlyPrice ? "per hour" : "flat")
                    },
                    {
                        name: "availability",
                        title: "Availability",
                        flex: 2,
                        renderCell: item => item.students.length + '/' + item.capacity
                    },
                    {
                        name: "action",
                        title: "",
                        flex: 1,
                        renderCell: item => <JoinBtn key={item.id} item={item} />
                        //renderCell: item => <Action item={item} onClose={onClose} />
                    }
                ]} items={report} />
            </div>


            <div style={{ height: '18rem' }} />
        </div>
    )
}