import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24 } from '@carbon/icons-react'

export const InventoryLocationList = newListDetail({
    title: "Inventory Location",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    defaultValues: {

    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Form({ endpoint, form }) {
    return (
        <div>
            <form.TextField fieldKey="name" title="Name" />
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
