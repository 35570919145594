import { ComposedModal, FileUploaderDropContainer, FileUploaderItem, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from "carbon-components-react"
import Button from "../../../components/Button"
import { CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { useEffect, useState } from "react"
import { typeGetAttachmentType, typeHasAttachment, typeName } from "../components/type-name";
import Util from "../../../util/Util";
import { ADVANCED_NOTES_TYPE } from "..";
import { AdvancedNotesView } from "../advanced-notes-view";


export function ArchiveDialog({ visible, onClose }) {
    return (
        <ComposedModal className="no-outline-modal" key="archive-list-advanced-note-dialog" size="sm" open={visible} onClose={onClose}>
            <ModalHeader label="Archived Items" title="Archive" />
            <ModalBody hasScrollingContent>
                <div style={{ padding: '0.5rem', background: 'white', borderRadius: 5, border: '1px solid #00000020' }}>
                    {visible && <AdvancedNotesView readonly archive />}
                </div>
                <div style={{ height: '6rem' }} />
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                    Close
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}