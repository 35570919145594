import { TextInput } from 'carbon-components-react';
import React, { useEffect } from 'react'
import Button from '../../components/Button';
import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16 } from '@carbon/icons-react'

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [name, setName] = useMultiInputState(input, 'name', onInputUpdate);
    const [symbol, setSymbol] = useMultiInputState(input, 'symbol', onInputUpdate);

    const [conversionFactor, setConversionFactor] = useMultiInputState(input, 'conversionFactor', onInputUpdate);

    return (
        <div style={{display: 'flex', width: '100%', gap: 5}}>
            <TextInput size="lg" labelText="Name" value={name} onChange={e => setName(e.target.value)} />
            <TextInput size="lg" labelText="Symbol" value={symbol} onChange={e => setSymbol(e.target.value)} />

            <TextInput size="lg" labelText="Conversion Factor (to default UOM)" value={conversionFactor} onChange={e => setConversionFactor(e.target.value)} />
            
            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
            style={{height: 48, alignSelf: 'flex-end'}} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export default ({defaultItems, onUpdate}) => (
    <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
)