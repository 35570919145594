import { PlaneSea20, PlaneSea24, DeliveryParcel20, Add32, ChevronRight16, ErrorFilled16, CheckmarkFilled16, Close16 } from '@carbon/icons-react'
import './port-staff-shipping.scss';
import { Tag } from 'carbon-components-react';
import UIUtil from '../../../util/UIUtil';
import Button from '../../../components/Button';
import { useForm } from '../../../templates/form/form';
import { fixIdForApi } from '../../../templates/form/form-api';
import Api from '../../../session/Api';
import { useState } from 'react';
import { withLoadablePage } from '../../../base/Page';
import { useHistory } from 'react-router-dom';
import { ShipmentPortStatusTag } from './shipment-port-status';

const Shipment = ({ shipment, onClick }) => {
    return (
        <div onClick={onClick} className='port-staff-shipping-shipment-item' style={{ borderBottom: '1px solid #00000020', width: '100%', paddingInline: '1rem', }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <div style={{ flex: 1, paddingTop: '1rem', paddingBottom: '1rem' }}>
                    <div style={{ display: 'flex', alignItems: 'cetner', gap: '0.25rem' }}>
                        <DeliveryParcel20 />
                        <p style={{ fontSize: 16, lineHeight: 1.25 }}>{shipment.shipmentNo}</p>
                    </div>

                    <ShipmentPortStatusTag status={shipment.status} duringPortInspection={shipment.duringPortInspection} />
                    {/* <Tag type='blue' style={{ marginTop: '0.5rem' }}>Awaiting Inspection</Tag> */}
                    {/* <Tag type='magenta' style={{ marginTop: '0.5rem' }}>Awaiting Loading</Tag> */}
                </div>

                <ChevronRight16 />
            </div>
        </div>
    )
}

const CreateNewDialog = ({ loadingModes, onClose, onSuccess }) => {
    const form = useForm();

    const [loading, setLoading] = useState(false);
    const onSave = () => {
        setLoading(true);
        const data = fixIdForApi(form.getFormData());
        Api.createShipment(data, response => {
            if (response.status === true) {
                UIUtil.showSuccess();

                onSuccess?.(response.payload)
                onClose();
            } else {
                // UIUtil.showError();
                alert(response.message)
            }
            setLoading(false)
        })
    }

    return (
        <div style={{ borderRadius: 15, background: 'white', width: '90vw', padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ flex: 1 }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Creating</p>
                    <h4 style={{ fontWeight: 'bold' }}>Shipment</h4>
                </div>
                <button onClick={onClose} className='lead-flow-icon-button'>
                    <Close16 />
                </button>
            </div>


            <div style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
                <form.SwitchField title="Direction" fieldKey="dirType" options={[{ id: 0, title: "Import" }, { id: 1, title: "Export" }]} />
                <form.IfTargetGroup targetKey="dirType" checkTargetIf={value => value === 1}>
                    {/* <form.SwitchField fieldKey="loadingMode" title="Loading Mode" options={[{ id: 0, title: "LCL" }, { id: 1, title: "FCL" }]} /> */}
                    <form.ComboBoxField fieldKey="loadingModeId" title="Loading Mode" options={loadingModes} />
                </form.IfTargetGroup>

            </div>
            <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'flex-end' }}>
                <Button onClick={onClose} renderIcon={ErrorFilled16} size="sm" style={{ borderRadius: 25 }} kind="secondary">Cancel</Button>
                <Button loading={loading} onClick={onSave} renderIcon={CheckmarkFilled16} size="sm" style={{ borderRadius: 25 }} kind="primary">Create</Button>
            </div>
        </div>
    )
}

function onNewBtn(loadingModes, onSuccess) {
    UIUtil.showOverlay2(onClose => <CreateNewDialog loadingModes={loadingModes} onClose={onClose} onSuccess={onSuccess} />)
}

function View({ payload: { loadingModes, shipments } }) {
    const history = useHistory();

    const openShipment = shipment => history.push("/shipment/" + shipment.id);
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ zIndex: 100, position: 'fixed', top: '3rem', left: 0, background: "#f4f4f4", color: 'black', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000020', paddingInline: '1rem', width: '100%', height: "3rem", gap: '0.5rem' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 35, height: 35 }}>
                    <PlaneSea24 />
                </div>
                <h4 style={{ fontWeight: 'bold' }}>Shipments</h4>
            </div>
            <div style={{ height: "3rem" }} />

            {shipments.map(shipment => <Shipment key={shipment.id} shipment={shipment} onClick={() => openShipment(shipment)} />)}
            <div style={{ height: "12rem" }} />

            <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', background: "white", borderTop: '1px solid #00000020', height: 32 + 15, display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                <button onClick={() => onNewBtn(loadingModes, openShipment)} className='lead-flow-icon-button lead-flow-icon-button-primary' style={{ width: 60, height: 60, marginTop: -32 + 7 }}>
                    <Add32 />
                </button>
            </div>
        </div>
    )
}

export const StaffPortShippingPage = withLoadablePage(listener => Api.getPortShipments(listener), View);