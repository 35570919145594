import useApi from "../../../hooks/useApi"
import useStore from "../../../hooks/useStore"
import Api from "../../../session/Api"
import UIUtil from "../../../util/UIUtil"


export default (sessionStore) => {
    const [loading, setLoading] = useStore(sessionStore, "loading")
    const setSession = session => sessionStore.setObject(session)

    const updateCartItem = (itemId, quantity, displayUom, removing, onDone, groupToProductMaps) => {
        setLoading(true)
        Api.updateThirdPartyPosCartItem({
            itemId, quantity,
            continuous: displayUom !== undefined,
            displayUom, removing,
            groupToProductMaps
        }, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setItemComplimentary = (cartItemId, complimentary, onDone) => {
        setLoading(true)
        Api.setThirdPartyPosComplimentary(cartItemId, complimentary, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const [loadingClearing, clearCart] = useApi(Api.clearThirdPartyPosCart, setSession)
    const [loadingCheckout, checkout] = useApi(Api.checkoutThirdPartyPos, setSession)

    const addToCart = (itemId, quantity, displayUom, onDone, groupToProductMaps) => updateCartItem(itemId, quantity, displayUom, false, onDone, groupToProductMaps)
    const removeFromCart = (itemId, quantity, onDone, groupToProductMaps) => updateCartItem(itemId, quantity, undefined, true, onDone, groupToProductMaps)

    return {
        loading,
        updateCartItem,
        setItemComplimentary,
        addToCart,
        removeFromCart,

        loadingClearing, clearCart,
        loadingCheckout, checkout
    }
}