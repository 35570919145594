import { AccordionItem, Accordion, Toggle, Tile, TileGroup, RadioTile, SelectableTile, TextInput } from 'carbon-components-react'
import { makeSettingComponent } from './Activities'
import { withLoadablePage } from '../../../base/Page';
import Api from '../../../session/Api';
import { useState } from 'react';
import UIUtil from '../../../util/UIUtil';
import Button from '../../../components/Button';
import { CheckmarkFilled16 } from "@carbon/icons-react"
//import { makeSettingComponent } from '..'


const ShouldBlockOnlineStore = makeSettingComponent('block_online_store_ordering')

const BlockOnlineStoreOrdering = withLoadablePage(Api.getOnlineStoreBlockMessage.bind(Api), ({ payload }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(payload);

    const onSaveBtn = () => {
        setLoading(true);
        Api.updateOnlineStoreBlockMessage(message, response => {
            setLoading(false);
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
        })
    }

    return (
        <div>
            <ShouldBlockOnlineStore activeChildren={<>
                <div style={{ height: '1rem' }} />
                <TextInput
                    value={message} onChange={e => setMessage(e.target.value)}
                    labelText="Stop Message" placeholder='Thank you! Unfortunately, we cannot accept orders at this moment. Please try again later.' />
                <div style={{ height: '1rem' }} />
                <Button onClick={onSaveBtn} loading={loading} renderIcon={CheckmarkFilled16} size="sm" style={{ borderRadius: 25 }}>Save Message</Button>
            </>} />
        </div>
    )
})



const UseFoodStock = makeSettingComponent('restaurant_use_food_stock')

export default ({ settingsValues }) => {
    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="Use estimated daily portions stock system for menu food items">
                <UseFoodStock />
            </AccordionItem>
            <AccordionItem title="Stop accepting online store orders">
                <BlockOnlineStoreOrdering />
            </AccordionItem>
        </Accordion>
    )
}