import { Menu } from "./menu";
import { ReportDesignControl } from "./report-design-control";
import { Report24, Add16, VirtualColumn16, Tools16 } from '@carbon/icons-react'
import { SelectReport } from "../select-report/select-report";
import Button from "../../../components/Button";
import { NewBtn } from "../buttons/new-btn";
import { useMemo } from "react";
import { getReport } from "../../reports/reports";
import { ListModeMenu } from "./list-mode-menu";
import { isMobileScreen } from "../../../App";

export function Header({ info, setInfo, store, state, originalState, setState, onCreateBtn, onRenameBtn, isListMode, isManageMode }) {
    const report = useMemo(() => getReport(info.name), [info.name])
    if (isListMode) {
        if (isMobileScreen) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', padding: '1rem', gap: '0.5rem', borderBottom: '0px solid #ffffff20', }}>
                    <report.icon />
                    <h3 style={{ marginRight: '1rem', fontWeight: 'bold' }}>{info.title}</h3>
                </div>
            )
        }

        return (
            <div style={{ width: '100%', paddingTop: isManageMode ? '1rem' : '2rem', gap: '0.5rem', paddingBottom: isManageMode ? 'calc(4rem + 37px)' : 'calc(5rem + 37px)', paddingInline: isManageMode ? '3rem' : '6rem', background: '#f1f1f1', borderBottom: '0px solid #ffffff20', display: 'flex', alignItems: 'center', color: 'black', }}>
                <report.icon />
                <h3 style={{ marginRight: '1rem', fontWeight: 'bold' }}>{info.title}</h3>
                <SelectReport info={info} store={store} />
                <div style={{ marginLeft: 'calc(-10px + -0.5rem)', display: 'flex', alignItems: 'center' }}>
                    <ReportDesignControl info={info} setInfo={setInfo} store={store}
                        state={state} originalState={originalState} setState={setState}
                        onRenameBtn={onRenameBtn} onCreateBtn={onCreateBtn}
                        isListMode={isListMode} />
                </div>

                <div style={{ flex: 1 }} />
                <ListModeMenu reportName={info.name} store={store} />
            </div>
        )
    } else {
        return (
            <div style={{ width: '100%', paddingBlock: '0.5rem', paddingInline: '1rem', background: '#212121', borderBottom: '0px solid #ffffff20', display: 'flex', alignItems: 'center', color: 'white', }}>
                <Report24 />
                <h4 style={{ marginRight: '3rem', marginLeft: '0.15rem' }}>{info.title}</h4>
                <SelectReport info={info} store={store} />
                <div style={{ marginLeft: -10, display: 'flex', alignItems: 'center' }}>
                    <ReportDesignControl info={info} setInfo={setInfo} store={store}
                        state={state} originalState={originalState} setState={setState}
                        onRenameBtn={onRenameBtn} onCreateBtn={onCreateBtn} />
                </div>
                <div style={{ flex: 1 }} />
                <Menu reportName={info.name} store={store} />
                <NewBtn reportName={info.name} />
            </div>
        )
    }
}