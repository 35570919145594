import { hasCapabilitySupport } from "../../../app/Capabilities"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_LEARNING_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { Education32 } from '@carbon/icons-react'

export const OnlineLearnersList = {
    filter: Filter,
    icon: Education32,

    customPath: "/learners",
    // newDialog: NewDialog,

    itemToOpenLink: item => `/customers/${item.customerId}`,
    isListMode: true,

    isAvailable: () => {
        const role = getAccountRole();
        return hasCapabilitySupport("onlineLearning") && (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_LEARNING_MANAGER);
    }
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
