
import {
    ArrowRight16,
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
    Checkmark16,
    Error16
} from '@carbon/icons-react'
import React, { useCallback } from 'react'
import { getStatusIcon, getStatusOp } from '../..'

const ICONS = [
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
]


const Divider = ({ left, right }) => (
    <div style={{ position: 'relative' }}>
        <div style={{ background: left.selected ? '#0f62fe' : left.done ? '#00520f' : '#0f0f0f', transition: 'background 250ms', position: 'absolute', left: -1, top: 0, height: '100%', width: 'calc(100% + 1px)', clipPath: 'polygon(0 0, 25% 0, 50% 50%, 25% 100%, 0 100%, 0 50%)' }} />
        <div style={{ position: 'relative', zIndex: 2, height: '100%', width: 15, background: 'white', clipPath: 'polygon(0 0, 25% 0, 75% 51%, 25% 100%, 0 100%, 50% 50%)' }} />
        <div style={{ background: right.selected ? '#0f62fe' : right.done ? '#00520f' : '#0f0f0f', transition: 'background 250ms', position: 'absolute', right: -1, top: 0, height: '100%', width: 'calc(100% + 2px)', clipPath: 'polygon(25% 0, 100% 0, 100% 50%, 100% 100%, 25% 100%, 75% 50%)' }} />
    </div>
)


const Status = ({ icon, title, selected, done, enabled, onClick }) => (
    <div onClick={enabled ? onClick : undefined} style={{ cursor: enabled ? 'pointer' : undefined, height: '100%', flex: 1, background: selected ? "#0f62fe" : done ? '#00520f' : "#0f0f0f", transition: 'background 250ms', }}>
        <div style={{ width: '100%', height: '100%', opacity: !enabled && !done ? 0.45 : 1, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
            <div style={{ marginRight: '0.5rem', height: 18, width: 18, borderRadius: 50, background: 'white', color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {React.createElement(icon)}
            </div>
            <p style={{ fontSize: 12 }}>{title}</p>
        </div>
    </div>
)


export function StatusBar({ fnbEvent, flow, selectedStatus, setSelectedStatus }) {
    const statusOp = useCallback(status => getStatusOp(fnbEvent, flow, selectedStatus, status), [fnbEvent, flow, selectedStatus]);
    return (
        <div style={{ width: '100%', height: '2rem', background: '#1c1c1c', display: 'flex', color: 'white' }}>
            {flow.map((status, index) => {
                return (<>
                    {index > 0 &&
                        <Divider key={status.id + 'divider'} left={statusOp(flow[index - 1])} right={statusOp(status)} />}

                    <Status key={status.id + 'status'} {...statusOp(status)}
                        icon={getStatusIcon(flow, status)}
                        title={status.title}
                        onClick={() => setSelectedStatus(status)} />
                </>)
            })}
        </div>
    )
}





