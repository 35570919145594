import React from 'react'
import DashboardCard from '../components/DashboardCard'
import DashboardTable from '../components/DashboardTable'
import DashboardTitle from '../components/DashboardTitle'

import { Events20 } from '@carbon/icons-react'

import { OBJECT_TYPE_CUSTOMER } from '../../../constants/ObjectTypes'

import { Link as L } from 'carbon-components-react'
import { Link } from 'react-router-dom'
import Api from '../../../session/Api'
import ProfilePic from '../../../components/ProfilePic'
import Util from '../../../util/Util'



export default ({ dashboard }) => (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <DashboardTitle icon={Events20} title={"Top 10 customers this month (" + dashboard.monthName + ")"} />
        <div className="dashboard-card" style={{ flex: 1, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, }}>
            <DashboardTable customBgColor="#3083DC" maxHeight="100%" items={dashboard.topCustomers} columns={[
                {
                    name: "customer-tb-customer",
                    title: "Customer",
                    flex: 2,
                    renderCell: item => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ProfilePic style={{ marginRight: '0.5rem' }} size={34} src={Api.getThumbnail(OBJECT_TYPE_CUSTOMER, item.customerId)} />
                            {!Util.isNumberExist(item.customerId) ? (
                                <span>{item.customerName}</span>
                            ) : (
                                <Link style={{ textDecoration: 'none' }} target="_blank" to={"/customers/" + item.customerId}><L>{item.customerName}</L></Link>
                            )}
                        </div>
                    )
                },
                {
                    name: "customer-tb-total-amount",
                    title: "Amount Spent",
                    flex: 1,
                    renderCell: item => 'AED ' + item.amountSpent.toFixed(0)
                }
            ]} />
        </div>
    </div>
)