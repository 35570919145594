import React from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_LMS_GROUP, OBJECT_TYPE_STUDENT } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import { TABLE_RELATION_TYPE_MANY_TO_MANY } from '../../../constants/Constants';
import TableTemplate from '../../../templates/TableTemplate';

const OBJECT_TYPE = OBJECT_TYPE_LMS_GROUP;

const Students = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE,

                    childType: OBJECT_TYPE_STUDENT,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Students",
                subTitle: "Students in group",
                objectType: OBJECT_TYPE_STUDENT,
                pagePath: "/students/",
                history: history
            })}
        </div>
    )
}


const Guardians = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE,

                    childType: OBJECT_TYPE_GUARDIAN,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Guardians",
                subTitle: "Guardians in group",
                objectType: OBJECT_TYPE_GUARDIAN,
                pagePath: "/guardians/",
                history: history
            })}
        </div>
    )
}

const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                    {
                        title: "Students",
                        component: Students
                    },
                    {
                        title: "Guardians",
                        component: Guardians
                    }
                ]
            })}
        </div>
    )
})

export const LmsGroupDetailPage = Page;
