import React from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import Button from '../../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CONTACT, OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE, OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_SALES_ORDER_STATE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../../constants/ObjectTypes";
import Api from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import TableTemplate from '../../../templates/TableTemplate';
import AllSubsidiaryReportView from '../../accounting-reports/AllSubsidiaryReportView';
import StockFlowListView from '../../stock-flow/StockFlowListView';
import TransactionListView from '../../transaction/TransactionListView';
import { Launch16, Terminal16 } from '@carbon/icons-react'


class PrivateCustomPagesDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE,
                    itemResult: this.state.itemResult,
                    pagePath: "/private-online-store-custom-pages/",
                    customDetailSubtitle: "Private Online Store Custom Page",
                    history: this.props.history,
                    onPreSave: item => item.requireAuth = true,

                    hasCustomBtn: (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1rem', gap: '0.5rem' }}>
                        <a target="_blank" style={{ textDecoration: 'none' }} href={Api.redirectToOnlineStoreCustomPage(this.state.itemResult.item.id)}>
                            <Button renderIcon={Launch16} kind="secondary">Open Page</Button>
                        </a>
                        <Link target="_blank" style={{ textDecoration: 'none' }}
                            to={`/html-editor/${OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE}/${this.getPathParams().itemId}`}>
                            <Button renderIcon={Terminal16}>Open Editor</Button>
                        </Link>
                    </div>),
                })}
            </div>
        )
    }

}

export default withRouter(PrivateCustomPagesDetailPage);