import { getPermissionIfNeeded } from "./permission";



export function startNotificationSystem(socketSession) {
    getPermissionIfNeeded();

    // new Notification("Hellow", { body: "This is a message" })
}

export function stopNotificationSystem(socketSession) {

}

// toast(message, {
//     position: "top-right",
//     autoClose: 5000,
//     theme: "dark",
//     type: "success",
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     onOpen: () => {
//         try {
//             this.notificationAudio.pause();
//             this.notificationAudio.currentTime = 0;
//             this.notificationAudio.play().catch(() => { })
//         } catch (e) { }
//     }
// });