import { InlineLoading, Loading, TextInput } from "carbon-components-react";
import { useEffect, useRef, useState } from "react";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import useStore from "../../hooks/useStore";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import { useRefSize } from "../../util/useSize";
import Util from "../../util/Util";
import { Footer } from "../components/report-layout/footer";
import { FormulaValues } from "../components/report-layout/formula-values";
import { QuickFilterBar } from "../components/report-layout/quick-filter-bar";
import { ReportContent } from "./report-content";






const useReportBase = (reportName, savedReportId, setState) => {
    const [loading, setLoading] = useState(true);
    const [reportBase, setReportBase] = useState(undefined)
    const [usingSavedReportId, setUsingSavedReportId] = useState(undefined);

    // const { query } = useLocationQuery()
    // const consumedQuery = useRef(false)

    useEffect(() => {
        setLoading(true)
        setReportBase(undefined)
        setUsingSavedReportId(undefined)

        let cancelled = false;
        Api.getReportBase(reportName, savedReportId, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                const report = response.payload

                // if (query && !consumedQuery.current) {
                //     consumedQuery.current = true;
                // } else {
                //     setState(Util.isStringExists(report.state) ? JSON.parse(report.state) : {}, { report: true, original: true })
                // }

                setState(Util.isStringExists(report.state) ? JSON.parse(report.state) : {}, { report: true, original: true })
                setReportBase(report)
                setUsingSavedReportId(savedReportId)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })

        return () => cancelled = true;
    }, [reportName, savedReportId])

    return [loading, reportBase, usingSavedReportId]
}

export function Report({ reportName, reportEndpoint, savedReportId, refreshReportContentRequest, ...props }) {
    const [loading, report, usingSavedReportId] = useReportBase(reportName, savedReportId, props.setState);

    if (!loading && report && savedReportId === usingSavedReportId) {
        return <ReportContent key={"report-engine-content-view-" + savedReportId + '-refreshReportContentRequest'} {...props} endpoint={reportEndpoint} report={report} />
    } else if (!loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: "100%" }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Cannot load invalid report</p>
            </div>
        )
    } else {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: "100%" }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }
}