import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { Divider } from "../../components/basic-filter/divider";
import { ProductFilter } from "./product-filter";

export const SerialNoReport = {
    filter: Filter,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const SHOW_AVAILABLE_ONLY = { property: "status", operator: "EQ", value: 'Available' }

function Filter({ endpoint, state }) {
    return (<>
        <CheckboxFilter state={state} filterId="showActiveOnly" label="Available only" filter={SHOW_AVAILABLE_ONLY} />
        <Divider />
        <div style={{ minHeight: 30, flex: 1 }}>
            <ProductFilter state={state} options={[]} />
        </div>
    </>)
}
