import { useStringValueFilter } from "../../engine/useValueFilter";


export function TextBox({ label, state, id, property, fullWidth, placeholder, operator = "CONTAINS" }) {
    const [value, setValue] = useStringValueFilter(state, id ?? property, { property, operator })
    return (
        <div className={"filter-field " + (fullWidth ? 'report-engine-filter-field-full-width' : '')}>
            {label && <label className="bx--label">{label}: </label>}
            <input placeholder={placeholder ?? "No filter"} value={value} onChange={e => setValue(e.target.value)} />
        </div>
    )
}

