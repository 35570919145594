import React, { createRef } from 'react'
import { ComposedModal, InlineLoading, Loading, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput } from 'carbon-components-react';
import Button from '../components/Button';
import { getObjectTypeName } from '../constants/ObjectTypes';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';
import ItemTemplate from './ItemTemplate';

class ItemCreatorDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            itemResult: undefined
        }

        this.formRef = createRef();
    }

    componentDidMount() {
        if (this.props.open) {
            this.load();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.load();
        }

        if (this.formRef.current) {
            for (const prop in this.props) {
                if (prop.startsWith("valueUpdate") && this.props[prop] != prevProps[prop] && this.props[prop].value != prevProps[prop].value) {
                    this.formRef.current.setFieldValue(this.props[prop].property, this.props[prop].value)
                }
            }
        }
    }

    load() {
        this.setState({ loading: true })
        const listener = response => {
            this.setState({ loading: false, itemResult: response.status === true ? response.payload : undefined }, () => {
                try {
                    if (this.formRef.current) {
                        for (const prop in this.props) {
                            if (prop.startsWith("valueDefault")) {
                                this.formRef.current.setFieldValue(this.props[prop].property, this.props[prop].value)
                            }
                        }
                    }
                } catch (e) {

                }
            })
            if (response.status === false) {
                UIUtil.showError();
                this.props.onClose();
            }
        }

        if (this.props.itemId) {
            Api.getItem(this.props.objectType, this.props.itemId, listener)
        } else {
            Api.getItemCreator(this.props.objectType, listener)
        }
    }

    render() {
        const itemTemplate = !this.state.loading && (
            ItemTemplate.renderTemplate({
                ref: this.formRef,
                objectType: this.props.objectType,
                itemResult: this.state.itemResult,
                modalMode: true,
                hideGroupTitles: true,
                skipSaveSuccesMessage: true,
                filterProperties: this.props.filterProperties,
                skipProperties: this.props.skipProperties,
                fullSizedModal: this.props.fullSized,
                onFieldUpdate: this.props.onFieldUpdate,
                onPreSave: this.props.onPreSave,
                onSave: this.props.onSave,
                preventDeletePhraseModal: this.props.preventDeletePhraseModal,
                productFinderShowFakeTextFieldAtFirst: this.props.productFinderShowFakeTextFieldAtFirst,
                productFinderShowReplaceWithBundle: this.props.productFinderShowReplaceWithBundle,
                productFinderShowReplaceWithProductGroup: this.props.productFinderShowReplaceWithProductGroup,
                onDelete: this.props.onDelete,
                returnItemOnSave: this.props.returnItemOnSave,
                tryMiniFieldSize: this.props.tryMiniFieldSize,
                parentId: this.props.parentId,
                hideThumbnail: this.props.hideThumbnail,
                history: {
                    goBack: createdItem => createdItem !== undefined ? this.props.onCreatedItem(createdItem) : this.props.onClose()
                },

                noDelete: this.props.noDelete
            })
        )

        return (
            <ComposedModal open={this.props.open} onClose={this.props.onClose}
                key={"item-creator-" + this.props.objectType}
                className={this.props.className}
                size={this.props.size !== undefined ? this.props.size : "sm"}>
                <ModalHeader label={this.props.dialogLabel ?? "Creating"} title={this.props.dialogTitle ?? getObjectTypeName(this.props.objectType)} />
                {this.state.loading ? (
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        <div style={{ width: '100%', height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Loading withOverlay={false} />
                        </div>
                    </ModalBody>
                ) : (
                    this.props.useModalBody ? (
                        <ModalBody style={{ paddingRight: '1rem' }} hasScrollingContent>
                            {itemTemplate}
                        </ModalBody>
                    ) : this.props.useScrollable ? (
                        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                            {itemTemplate}
                        </div>
                    ) : (
                        itemTemplate
                    )
                )}
            </ComposedModal>
        )
    }

}

export default ItemCreatorDialog;