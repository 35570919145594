import { useMemo } from "react"
import { getCashGroup } from "../../domain/account-ledgers"
import CustomComboBox from "../../components/CustomComboBox"
import { flattenItems, isV2 } from "../../util/Util"


function CashAccount({ endpoint, value, setValue, label, cashOnly }) {
    const accounts = useMemo(() => {
        if (isV2() || cashOnly) {
            return getCashGroup(endpoint.accountTree[0]).items
        } else {
            return endpoint.accountTree;
        }
    }, [endpoint, cashOnly])
    const flattenedAccounts = useMemo(() => flattenItems(accounts), [accounts]);
    return (<>
        <label className="bx--label">{label}</label>
        <div style={{ height: 40, }}>
            <CustomComboBox
                white
                defExtended
                preventClear
                items={accounts}
                // selectedItem={value}
                // onSelectedItemUpdate={setValue}

                selectedItem={flattenedAccounts.find(item => item.id === value)}
                onSelectedItemUpdate={item => setValue(item?.id)}
            />
        </div>
    </>)
}


export const FundTransferFormFields = ({
    TextField, DateField, CustomField, SwitchField, TextAreaField
}, endpoint) => (<>
    <DateField title="Date" fieldKey="date" />
    <TextField title="Amount" fieldKey="amount" />

    <CustomField fieldKey="fromCashAccountId" childrenProps={{ endpoint, label: "From", cashOnly: true }}>
        {CashAccount}
    </CustomField>

    <CustomField fieldKey="toCashAccountId" childrenProps={{ endpoint, label: "To" }}>
        {CashAccount}
    </CustomField>

    <TextAreaField title="Memo" fieldKey="memo" />

</>)