import React from 'react'
import { useDrag } from 'react-dnd'


import { TextScale24, ImageCopy24, Barcode24, QrCode24, RulerAlt24, StopFilledAlt24, CircleFilled24 } from '@carbon/icons-react'
import { ComponentTypes } from '../engine/ComponentTypes'

const Button = ({engine, icon, label, type}) => {
    const [collected, drag, dragPreview] = useDrag(() => ({
        type,
        item: {  }
    }))

    const onClick = () => engine.addComponent(type);

    return (
        <div ref={drag} {...collected} className="label-designer-component-button theme-raw-bg" onClick={onClick}>
            {React.createElement(icon)}
            <p>{label}</p>
        </div>
    )
}

const Divider = () => <div style={{width: '80%', marginTop: 15, marginBottom: 15, height: 1, background: '#00000040'}} />

export default ({engine}) => (
    <div className="level-designer-component-root">
        <Button engine={engine} icon={TextScale24} label="Text" type={ComponentTypes.TEXT} />
        <Button engine={engine} icon={Barcode24} label="Barcode" type={ComponentTypes.BARCODE} />
        <Button engine={engine} icon={QrCode24} label="QR Code" type={ComponentTypes.QR_CODE} />
        <Button engine={engine} icon={ImageCopy24} label="Image" type={ComponentTypes.IMAGE} />

        <Divider />

        <Button engine={engine} icon={RulerAlt24} label="Line" type={ComponentTypes.LINE} />
        <Button engine={engine} icon={StopFilledAlt24} label="Square" type={ComponentTypes.SQUARE} />
        <Button engine={engine} icon={CircleFilled24} label="Circle" type={ComponentTypes.CIRCLE} />

    </div>
)