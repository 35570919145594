import React from 'react'
import DashboardTitle from '../components/DashboardTitle';

import { Pie } from 'react-chartjs-2';
import DashboardCard from '../components/DashboardCard';

import { Purchase20 } from '@carbon/icons-react'



export default ({dashboard}) => {
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <DashboardTitle icon={Purchase20} title="Payment methods used this week" />
            <DashboardCard>
                <Pie data={{
                    labels: ['Cash', 'Credit Card', 'Debit Card', 'Cheque', 'Bank', 'Other'],
                    datasets: [
                        {
                            label: '# of Sales',
                            data: dashboard.paymentMethodDivisionSales,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }} />
            </DashboardCard>
        </div>
    )
}