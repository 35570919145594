import { ComboBox, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import { useEffect, useState } from "react"
import Button from "../../../components/Button";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";




export default ({ label = "Set Driver", open, onClose, salesOrderId, onUpdateOrder, onButtonOverride }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        setLoading(true);
        Api.getSalesOrderAvailableDrivers(response => {
            if (response.status === true) {
                setSelectedItem(null)
                setItems([{ id: 0, value: "No driver" }, ...response.payload])
                setLoading(false)
            } else {
                UIUtil.showError(response.message);
                onClose();
            }
        })
    }, [open])

    const onBtn = () => {
        if (onButtonOverride) {
            onButtonOverride(selectedItem.id)
            return;
        }

        setLoading(true);
        Api.salesOrderSetDriver(salesOrderId, selectedItem.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onClose();
                // console.log(response.payload)
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ComposedModal open={open} onClose={onClose} className="modal-that-supports-combo-box">
            <ModalHeader label={label} title="Assigning Driver" />
            <ModalBody>
                {loading ? (
                    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45 }}>
                        <InlineLoading style={{ width: 'unset' }} />
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
                    </div>

                ) : (
                    <div>
                        <ComboBox
                            light
                            titleText="Select Driver"
                            items={items}
                            itemToString={item => item ? item.value : ""}
                            value={selectedItem ? items.filter(item => selectedItem.id == item.id)[0].value : ""}
                            onChange={e => setSelectedItem(e.selectedItem)}
                        />
                    </div>
                )}

            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    loading={loading}
                    disabled={selectedItem == null}
                    onClick={onBtn}
                >
                    Confirm
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}