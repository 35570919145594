import "./rest-business-summary.scss"
import { useState } from "react";
import { withLoadablePage, withLoadablePagePropsAndProps } from "../../base/Page";
import Api from "../../session/Api";
import Util from "../../util/Util";
import { RestBusinessSummary } from ".";
import { MainApp, isMobileScreen } from "../../App";
import { Dashboard16, Xls16, Printer16, Receipt16 } from '@carbon/icons-react'
import { usePrint } from "../../util/usePrint";
import Button from "../../components/Button";
import UIUtil from "../../util/UIUtil";
import { CarbonDateTimeRange } from "../../reporting-engine/components/basic-filter/date-time-range";
import { ComboBoxField } from "../../components/ComboBoxField";
import { startOfDay, addDays } from 'date-fns';

function ThermalExport({ report }) {
    const [loading, setLoading] = useState()
    const onBtn = async () => {
        if (MainApp.instance?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
            setLoading(true);
            try {
                await MainApp.instance.thermalPrinter.driver.printRestBusinessSummary(report)
                UIUtil.showSuccess();
            } catch (e) {
                UIUtil.showError()
            } finally {
                setLoading(false)
            }
        } else {
            UIUtil.showInfo("Printer not connected")
        }
    }

    return <Button loading={loading} onClick={onBtn} renderIcon={Receipt16} style={{ borderRadius: 5 }}>Thermal</Button>
}

function PDFExport({ report }) {
    const { PrintContent, print } = usePrint()
    const onBtn = () => {
        print()
    }
    return (<>
        <Button onClick={onBtn} kind={"secondary"} renderIcon={Printer16} style={{ borderRadius: 5 }}>PDF</Button>

        <PrintContent>
            <RestBusinessSummary type="ui" report={report} />
        </PrintContent>
    </>)
}

const Content = withLoadablePagePropsAndProps(props => listener => Api.getRestBusinessSummaryReport(props.request, listener), ({ request, payload }) => {


    return (<>
        <div className="content">
            <RestBusinessSummary type="ui" report={payload} />
        </div>
        <div className="footer">
            <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>Export Report</p>
            <PDFExport report={payload} />
            <ThermalExport report={payload} />
        </div>
    </>)
});


const DEFAULT_REQQUEST = {
    key: Util.newTempId(),
    startDate: startOfDay(new Date()).getTime(),
    endDate: startOfDay(addDays(new Date(), 1)).getTime(),
    storeId: 0,
}

function View({ payload }) {
    const [request, setRequest] = useState(DEFAULT_REQQUEST);
    const updateReq = update => setRequest(req => ({ ...req, ...update, key: Util.newTempId() }))

    const [startDate, setStartDate] = [request.startDate, startDate => updateReq({ startDate })];
    const [endDate, setEndDate] = [request.endDate, endDate => updateReq({ endDate })];
    const [storeId, setStoreId] = [request.storeId, storeId => updateReq({ storeId })];

    return (
        <div className="rest-business-summary-page">
            <div className="header" style={{ paddingInline: isMobileScreen ? '0.5rem' : '3rem', paddingBlock: isMobileScreen ? '0.5rem' : '1rem' }}>
                <ComboBoxField value={storeId} setValue={setStoreId} options={payload.stores} title={"Store"} placeholder={"All stores"} />
                <CarbonDateTimeRange label="Report" start={startDate} setStart={setStartDate} end={endDate} setEnd={setEndDate} />


                {/* <ContentSwitcher style={{ width: isMobileScreen ? '100%' : 500 }} selectedIndex={
                    range === "TODAY" ? 0 :
                        range === "WEEK" ? 1 :
                            range === "MONTH" ? 2 :
                                range === "CUSTOM" ? 3 : 0
                } onChange={e => setRange(e.name)}>
                    <Switch name={"TODAY"} text="Today" />
                    <Switch name={"WEEK"} text="Week" />
                    <Switch name={"MONTH"} text="Month" />
                    <Switch name={"CUSTOM"} text="Range" />
                </ContentSwitcher> */}
            </div>
            <Content key={request.key} request={request} />
        </div>
    )
}

export const RestBusinessSummaryPage = withLoadablePage(Api.getRestBusinessSummaryEndpoints.bind(Api), View);