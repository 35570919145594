
import {
    Pin16, PinFilled16,

    Dashboard32, User32, Product32, Screen32, Category32, Barcode32, Box32, Compare32, Rocket32,
    Delivery32, AirlinePassengerCare32, CutOut32, Headset32, Percentage32, Store32, DataVis_232,
    Calculator32, WatsonHealthTextAnnotationToggle32, Archive32, StringText32, Chip32, InventoryManagement32,
    FlowStream32, Cube32, DocumentImport32, Purchase32, Money32, Finance32, Account32, Receipt32, Events32,
    Gift32, TagGroup32, QrCode32, Close16, SubtractAlt32, Currency32, IncreaseLevel32, ShoppingCartArrowUp32,
    Api32, License32, RequestQuote32, Notebook32, Table32, ShoppingCartArrowDown32, InProgress32,
    DataFormat32, TreeView32, Catalog32, ListDropdown32, Course32, Grid32, DataShare32, Data_132,
    CaretRight16, ArrowDown32, ArrowUp32, PenFountain32, DeliveryParcel32, LicenseGlobal32,
    Network_132, CategoryNewEach32, PhraseSentiment32, Gradient32, App32, Book32, Document32,
    Wallet32, ReportData32, Van32, RulerAlt32, Types32, ShoppingCart32, Classification32, Settings32,
    Alarm32, Categories32, Boolean32, LocationCompany32, InsertPage32, Bullhorn32,
    Locked32, ViewFilled32, Blog32, Home32, TextLink32, LicenseDraft32, MediaLibrary32,
    UserData32, Enterprise32, Code32, RecentlyViewed32, Building32, ChartBullet32, BuildingInsights_132,
    AirlineManageGates32, Migrate32, ServiceDesk32, Application32, Certificate32, GraphicalDataFlow32,
    Wikis32, Map32, TwoPersonLift32, Industry32, Milestone32, UserCertification32, UserMultiple32,
    Task32, CalendarHeatMap32, SplitScreen32, ChoroplethMap32, Legend32, Roadmap32, EventsAlt32, UserProfile32,
    StarFilled32, Education32, UserSpeaker32, GroupPresentation32, Collaborate32, PassengerPlus32, ManageProtection32,
    DocumentSentiment32, DataClass32, ShoppingCartPlus32, CenterSquare32, MapBoundary32, FitToScreen32, Theater32, Video32,
    BuildingInsights_332, Translate32, CalendarTools32, Ticket32, Report32, Tag32, PlaneSea32, ChartRelationship32,
    Harbor32, ListBoxes32, UserAdmin32, VideoFilled32, NotificationFilled32, Email32, Restaurant32, ShoppingCatalog32, Wheat32,
    DeskAdjustable32, BringToFront32, Location32, QueryQueue32, TagEdit32, Printer32, ListNumbered32, Hashtag32
} from '@carbon/icons-react'
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { MENU_PACKAGE } from '../../../app/Config';
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_LEARNING_MANAGER, ACCOUNT_TYPE_REAL_ESTATE_AGENT, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_STORE_MASTER, ACCOUNT_TYPE_SUPERVISOR } from '../../../constants/Constants';
import { getObjectTypeUrl, OBJECT_TYPE_CINEMA, OBJECT_TYPE_MOVIE, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_MOVIE_GENRE, OBJECT_TYPE_MOVIE_LANGUAGE, OBJECT_TYPE_SEATING_PLAN, OBJECT_TYPE_THEATER_TYPE } from '../../../constants/ObjectTypes';
import { getReportLink } from '../../../reporting-engine/base/report-page';
import { getAccountRole } from '../../../session/SessionManager';
import { isSqlAvgEngine, isStockEntityFifoEngine } from '../../../stock-system/stock-engine';


export function restSupervisor() {
    const accountRole = getAccountRole();
    const hasSalesSystem = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_SALES) && hasCapabilitySupport("salesSystem")

    const hasOrderManagement = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("orderManagementSystem")
    const hasOnlineStore = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("onlineStore");

    const menu = [
        {
            title: "Portal",
            color: "black",
            subMenus: [
                {
                    menus: (
                        (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) ? [
                            ...(hasCapabilitySupport("restaurant") ? [{
                                icon: LocationCompany32,
                                title: "Order Management",
                                link: "/deliverables"
                            }] : []),
                            ...(hasCapabilitySupport("restaurant") ? [{
                                icon: Report32,
                                title: "Sales Order Report",
                                link: getReportLink('SalesOrderReport')
                            }] : []),
                            {
                                icon: Dashboard32,
                                title: "Dashboard",
                                desc: "Get an overview of the system",
                                link: "/"
                            },
                            {
                                icon: Dashboard32,
                                title: "Business Summary",
                                link: "/business-summary"
                            },
                            {
                                icon: ReportData32,
                                title: "Reporting Dashboard",
                                link: "/reporting-dashboard"
                            },
                            ...(!hasCapabilitySupport("shipping") ? [{
                                icon: Chip32,
                                title: "Monitoring",
                                link: "/monitoring"
                            }] : []),
                            ...((hasCapabilitySupport("terminalPos") || hasCapabilitySupport("thirdPartyPos")) ? [{
                                icon: Calculator32,
                                title: "POS",
                                desc: "Open POS terminal",
                                link: "/pos"
                            }] : []),
                        ] : (
                            hasCapabilitySupport("terminalPos") ? (
                                [
                                    {
                                        icon: Calculator32,
                                        title: "POS",
                                        desc: "Open POS terminal",
                                        link: "/"
                                    }
                                ]
                            ) : (
                                [
                                    {
                                        icon: Home32,
                                        title: "Home",
                                        link: "/"
                                    }
                                ]
                            )
                        )
                    ).concat(
                        ...((hasCapabilitySupport("activities")) ? [
                            {
                                icon: CalendarHeatMap32,
                                title: "Calendar/Appointments",
                                link: "/calendar"
                            },
                            {
                                icon: SplitScreen32,
                                title: "To-Do Boards",
                                link: "/board-view"
                            }
                        ] : []),
                    )
                }
            ]
        },
        //Task32, CalendarHeatMap32, SplitScreen32, ChoroplethMap32, Legend32, EventsAlt32, UserProfile32
        // ...((hasCapabilitySupport("activities")) ? [{
        //     title: "Activities",
        //     color: "green2",
        //     subMenus: [
        //         {
        //             menus: [
        //                 {
        //                     icon: Task32,
        //                     title: "Activities",
        //                     link: "/activities"
        //                 },
        //                 {
        //                     icon: CalendarHeatMap32,
        //                     title: "Calendar",
        //                     link: "/calendar"
        //                 },
        //                 {
        //                     icon: SplitScreen32,
        //                     title: "Board",
        //                     link: "/board-view"
        //                 },
        //             ]
        //         },
        //         ...((accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) ? [
        //             {
        //                 title: "Definitions",
        //                 menus: [
        //                     {
        //                         icon: ChoroplethMap32,
        //                         title: "Blueprints",
        //                         link: "/activity-blueprints"
        //                     },
        //                     {
        //                         icon: Legend32,
        //                         title: "Activity Statuses",
        //                         link: "/activity-statuses"
        //                     },
        //                     {
        //                         icon: UserProfile32,
        //                         title: "Member Tags",
        //                         link: "/activity-member-tags"
        //                     },
        //                     // {
        //                     //     icon: EventsAlt32,
        //                     //     title: "Teams",
        //                     //     link: "/activity-teams"
        //                     // },
        //                 ]
        //             }
        //         ] : [])
        //     ]
        // }] : []),
        ...((hasCapabilitySupport("restaurant")) ? [{
            title: "Restaurant Management",
            color: "green2",
            subMenus: [
                {
                    title: 'Operations',
                    menus: [
                        {
                            icon: ListNumbered32,
                            title: "Estimated Daily Portions",
                            link: "/estimated-daily-portions"
                        },
                    ]
                },
                {
                    title: "Management",
                    menus: [
                        {
                            icon: Restaurant32,
                            title: "Menu Management",
                            link: getReportLink("FoodList")
                        },
                        {
                            icon: DeskAdjustable32,
                            title: "Restaurant Components",
                            link: "/rest-components"
                        },
                        // {
                        //     icon: Book32,
                        //     title: "Menus",
                        //     link: "/real-estate-developer"
                        // },
                    ]
                },
                {
                    title: "Offerings",
                    menus: [
                        {
                            icon: Product32,
                            title: "Products",
                            link: "/products"
                        },
                        {
                            icon: Box32,
                            title: "Bundles",
                            link: "/product-bundles"
                        },
                        {
                            icon: Categories32,
                            title: "Product Groups",
                            link: "/product-groups"
                        },
                        // {
                        //     icon: ShoppingCatalog32,
                        //     title: "Meals",
                        //     link: "/real-estate-project"
                        // },
                        // {
                        //     icon: Restaurant32,
                        //     title: "Foods",
                        //     link: getReportLink("FoodList")
                        // },
                        {
                            icon: Wheat32,
                            title: "Ingredients",
                            link: getReportLink("IngredientList")
                        },
                    ]
                },
                {
                    title: "Utilities",
                    menus: [
                        {
                            icon: BringToFront32,
                            title: "Bulk Recipe Manager",
                            link: '/bulk-recipe-manager'
                        }
                    ]
                }
            ]
        }] : []),
        ...((hasCapabilitySupport("shipping")) ? [{
            title: "Shipping Management",
            color: "green",
            subMenus: [
                {
                    title: "Management",
                    menus: [
                        {
                            icon: ChartRelationship32,
                            title: "Shipment Masters",
                            link: getReportLink("ShipmentMasterList")
                        },
                        {
                            icon: PlaneSea32,
                            title: "Shipment Jobs",
                            link: getReportLink("ShipmentList")
                        }
                    ]
                },
                {
                    title: "Reporting",
                    menus: [
                        {
                            icon: LocationCompany32,
                            title: "Loading List Report",
                            link: getReportLink("LoadingListReport")
                        }
                    ]
                },
                {
                    title: "Connections",
                    menus: [
                        {
                            icon: Enterprise32,
                            title: "Vendors",
                            link: "/vendors"
                        },
                        {
                            icon: UserAdmin32,
                            title: "Consignees",
                            link: "/shipment-consignees"
                        },
                        {
                            icon: UserAdmin32,
                            title: "Delivery Agents",
                            link: "/shipment-delivery-agents"
                        },
                        {
                            icon: Harbor32,
                            title: "Ports",
                            link: "/shipment-ports"
                        },
                    ]
                },
                {
                    title: "Definitions",
                    menus: [
                        {
                            icon: ServiceDesk32,
                            title: "Services/Charges",
                            link: "/services"
                        },
                        {
                            icon: TwoPersonLift32,
                            title: "Loading Mode Types",
                            link: "/shipment-loading-mode-types"
                        },
                        {
                            icon: Grid32,
                            title: "Package Types",
                            link: "/shipment-package-types"
                        },
                        {
                            icon: ListBoxes32,
                            title: "Item Types",
                            link: "/shipment-item-types"
                        },
                        // {
                        //     icon: RulerAlt32,
                        //     title: "Measurement Types",
                        //     link: "/measurement-types"
                        // },
                    ]
                }
            ]
        }] : []),
        ...((hasCapabilitySupport("lms")) ? [
            {
                title: "Student Management",
                color: "green2",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: Education32,
                                title: "Students",
                                link: "/students"
                            },
                            {
                                icon: Collaborate32,
                                title: "Guardians",
                                link: "/guardians"
                            },
                            {
                                icon: Events32,
                                title: "Groups",
                                link: "/lms-groups"
                            },
                        ],
                    },
                    {
                        title: "Communications",
                        menus: [
                            {
                                icon: NotificationFilled32,
                                title: "Notifications",
                                link: getReportLink("LmsNotificationList")
                            }
                        ]
                    },
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: CenterSquare32,
                                title: "Student Entrance Activity",
                                link: "/student-entrance-activity"
                            }
                        ]
                    },
                ]
            },
            {
                title: "Learning Management",
                color: "pink",
                subMenus: [
                    {
                        title: "Courses",
                        menus: [
                            {
                                icon: GroupPresentation32,
                                title: "Courses",
                                link: "/courses"
                            },
                            // {
                            //     icon: PassengerPlus32,
                            //     title: "Enrolment Requests",
                            //     link: "/enrolment-requests"
                            // },
                        ]
                    },
                    {
                        title: "Faculty",
                        menus: [
                            {
                                icon: UserSpeaker32,
                                title: "Tutors",
                                link: "/tutors"
                            },
                            {
                                icon: DocumentSentiment32,
                                title: "Tutor Payout",
                                link: "/payouts"
                            }
                        ]
                    },
                ]
            }
        ] : []),
        ...((hasCapabilitySupport("lead")) ? [{
            title: "Leads Management",
            color: "orange",
            subMenus: [
                {
                    menus: [
                        {
                            icon: UserCertification32,
                            title: "My Leads",
                            link: "/my-leads"
                        },
                        {
                            icon: UserMultiple32,
                            title: "All Leads",
                            link: "/all-leads"
                        },
                    ]
                },
                {
                    title: "Definitions",
                    menus: [
                        {
                            icon: Types32,
                            title: "Lead Sources",
                            link: "/lead-sources"
                        },
                        {
                            icon: Classification32,
                            title: "Lead Tags",
                            link: "/lead-tags"
                        },
                    ]
                }
            ]
        },] : []),
        // {
        //     title: "Operation",
        //     color: "green",
        //     subMenus: [
        //         {
        //             //title: "Management",
        //             menus: [
        //                 {
        //                     icon: Migrate32,
        //                     title: "Purchase",
        //                     link: "/purchases"
        //                 },
        //                 {
        //                     icon: Certificate32,
        //                     title: "Sale",
        //                     link: "/sales"
        //                 },
        //             ]
        //         },
        //     ]
        // },
        ...((true) ? [{
            title: "Sales and Purchases",
            color: "orange",
            subMenus: [
                {
                    title: "Operation",
                    menus: [
                        //...((!hasCapabilitySupport("restaurant") && !hasCapabilitySupport("shipping")) ? [
                        ...((true) ? [
                            {
                                icon: Migrate32,
                                title: "Purchase",
                                link: "/purchases"
                            },
                            {
                                icon: Certificate32,
                                title: "Sale",
                                link: "/sales"
                            },
                        ] : []),
                        ...((hasCapabilitySupport("thirdPartyPos")) ? [{
                            icon: Document32,
                            title: "Tax Invoice Documents",
                            link: "/invoice-documents"
                        }] : []),
                    ]
                },
                ...((hasSalesSystem) ? [{
                    title: "Management",
                    menus: [
                        {
                            icon: ShoppingCart32,
                            title: "Carts",
                            link: "/sales-carts"
                        },
                        {
                            icon: Types32,
                            title: "Cart Types",
                            link: "/sales-cart-types"
                        },
                    ]
                }] : []),
                ...((hasSalesSystem) ? [{
                    title: "Sales Work",
                    menus: [
                        {
                            icon: FlowStream32,
                            title: "Sales Works",
                            link: "/sales-works"
                        },
                        {
                            icon: Types32,
                            title: "Sales Work Inventory Statuses",
                            link: "/sales-work-inventory-statuses"
                        },
                    ]
                }] : []),
                {
                    title: "Sales Reporting",
                    menus: [
                        ...(hasCapabilitySupport("thirdPartyPos") ? [{
                            icon: BuildingInsights_132,
                            title: "Venue Sales Report",
                            link: "/venue-sales-report"
                        }] : []),
                        {
                            icon: ShoppingCartArrowUp32,
                            title: "Sales Report",
                            desc: "Analyze sales",
                            //link: "/sales-report"
                            link: getReportLink("SalesReport")
                        },
                        {
                            icon: Delivery32,
                            title: "Sales by Supplier Report",
                            link: getReportLink("SalesBySupplier")
                        },
                        {
                            icon: Box32,
                            title: "Sales by Item Report",
                            link: getReportLink("SalesByItem")
                        },
                        {
                            icon: Data_132,
                            title: "Sales Summary",
                            link: "/sales-summary-report"
                        },
                    ]
                },
                ...(isStockEntityFifoEngine() ? [{
                    title: "Performance Reporting",
                    menus: [
                        {
                            icon: DataShare32,
                            title: "Product Performance",
                            link: "/product-performance-report"
                        },
                        {
                            icon: Compare32,
                            title: "Price Performance",
                            link: "/price-performance-report"
                        },
                        {
                            icon: Alarm32,
                            title: "Hourly Performance",
                            link: "/hourly-performance-report"
                        }
                    ]
                }] : [])
            ]
        }] : []),
        ...((accountRole == ACCOUNT_TYPE_SALES) ? [{
            title: "Sales",
            color: "orange",
            subMenus: [
                // {
                //     title: "Operation",
                //     menus: [
                //         {
                //             icon: Certificate32,
                //             title: "Sale",
                //             link: "/sales"
                //         },
                //     ]
                // },
                ...((hasSalesSystem) ? [{
                    title: "Management",
                    menus: [
                        {
                            icon: ShoppingCart32,
                            title: "Carts",
                            link: "/sales-carts"
                        },
                        {
                            icon: Types32,
                            title: "Cart Types",
                            link: "/sales-cart-types"
                        },
                    ]
                }] : []),
                // {
                //     title: "Sales Reporting",
                //     menus: [
                //         ...(hasCapabilitySupport("thirdPartyPos") ? [{
                //             icon: BuildingInsights_132,
                //             title: "Venue Sales Report",
                //             link: "/venue-sales-report"
                //         }] : []),
                //         {
                //             icon: ShoppingCartArrowUp32,
                //             title: "Sales Report",
                //             desc: "Analyze sales",
                //             //link: "/sales-report"
                //             link: getReportLink("SalesReport")
                //         },
                //         {
                //             icon: Data_132,
                //             title: "Sales Summary",
                //             link: "/sales-summary-report"
                //         },
                //     ]
                // },
                // ...(isStockEntityFifoEngine() ? [{
                //     title: "Performance Reporting",
                //     menus: [
                //         {
                //             icon: DataShare32,
                //             title: "Product Performance",
                //             link: "/product-performance-report"
                //         },
                //         {
                //             icon: Compare32,
                //             title: "Price Performance",
                //             link: "/price-performance-report"
                //         },
                //         {
                //             icon: Alarm32,
                //             title: "Hourly Performance",
                //             link: "/hourly-performance-report"
                //         }
                //     ]
                // }] : [])
            ]
        }] : []),
        ...((hasOrderManagement) ? [{
            title: "Order Management",
            color: "purple",
            subMenus: [
                {
                    title: "Management",
                    menus: [
                        ...(!hasCapabilitySupport("onlineStore") ? [{
                            icon: ShoppingCartPlus32,
                            title: "Ordering",
                            link: "/ordering"
                        }] : []),
                        // {
                        //     icon: LocationCompany32,
                        //     title: "Manage Orders",
                        //     link: getReportLink("RestOrderList")
                        // },
                        {
                            icon: LocationCompany32,
                            //title: "Deliverables/Rentals",
                            title: "Sale Orders",
                            link: "/deliverables"
                        },
                        {
                            icon: ArrowDown32,
                            title: "Receipt Voucher",
                            link: "/receipt-voucher-creator"
                        },
                        {
                            icon: Report32,
                            title: "Payment Method Summary",
                            link: getReportLink("PaymentMethodReport")
                        },
                        {
                            icon: Report32,
                            title: "Payment Method Detail",
                            link: getReportLink("PaymentMethodDetail")
                        },
                        ...(hasCapabilitySupport("CRV") ? [
                            {
                                icon: Wallet32,
                                title: "Cash Receipt Voucher",
                                link: "/cash-receipt-vouchers"
                            },
                            {
                                icon: ReportData32,
                                title: "CRV Report",
                                link: "/crv-report"
                            },
                            {
                                icon: ReportData32,
                                title: "CRV Detail",
                                link: "/crv-report-detail"
                            }
                        ] : []),
                    ]
                },
                {
                    title: "Definitions",
                    menus: [
                        {
                            icon: Boolean32,
                            title: "Order States",
                            link: "/sales-order-states"
                        },
                        //...(hasCapabilitySupport("restaurant") ? [
                        ...(true ? [
                            {
                                icon: Location32,
                                title: "Delivery Cities",
                                link: "/delivery-cities"
                            },
                            {
                                icon: Location32,
                                title: "Delivery Zones",
                                link: "/delivery-zones"
                            },
                            {
                                icon: Location32,
                                title: "Delivery Locations",
                                link: "/delivery-locations"
                            }
                        ] : []),
                    ]
                }
            ]
        }] : []),
        ...((hasCapabilitySupport("terminalPos")) ? [{
            title: "Point of Sale",
            color: "blue",
            subMenus: [
                {
                    title: "Reporting",
                    menus: [
                        {
                            icon: DataFormat32,
                            title: "POS Summary",
                            link: "/pos-sales-summary-report"
                        },
                        {
                            icon: Receipt32,
                            title: "POS Sales",
                            link: "/pos-sales-report"
                        },
                        {
                            icon: InProgress32,
                            title: "Work Periods",
                            link: "/work-period-report"
                        },
                        {
                            icon: Account32,
                            title: "Cash Closed",
                            link: "/cash-closed-report"
                        },
                        {
                            icon: Rocket32,
                            title: "POS Product Performance",
                            link: "/pos-product-performance-report"
                        },
                    ]
                }
            ]
        }] : []),
        {
            title: "Inventory",
            color: "yellow",
            subMenus: [
                ...(accountRole === ACCOUNT_TYPE_ADMINISTRATION || accountRole === ACCOUNT_TYPE_SUPERVISOR ? [{
                    title: "Requests",
                    menus: [
                        {
                            icon: Cube32,
                            title: "My Stock Requests",
                            link: getReportLink("InvRequests")
                        },
                        {
                            icon: Cube32,
                            title: "Active Stock Requests",
                            link: getReportLink("ActiveInvRequests")
                        },
                        {
                            icon: Cube32,
                            title: "All Stock Requests",
                            link: getReportLink("AllInvRequests")
                        },
                    ]
                }] : []),
                {
                    title: "Management",
                    menus: [
                        ...(accountRole === ACCOUNT_TYPE_ADMINISTRATION || accountRole === ACCOUNT_TYPE_SUPERVISOR ? [{
                            icon: Cube32,
                            title: "Purchase Orders",
                            link: getReportLink("V1PoList")
                        }] : []),
                        {
                            icon: FlowStream32,
                            title: "Stock Flow",
                            desc: "View and initiate stock flow",
                            link: "/stock-flows"
                        },
                        {
                            icon: Delivery32,
                            title: "Suppliers",
                            desc: "View, add and edit suppliers",
                            link: "/suppliers"
                        },
                        ...(hasCapabilitySupport("thirdPartyPos") ? [{
                            icon: DeliveryParcel32,
                            title: "Stock Requests",
                            link: "/stock-requests"
                        }] : []),
                    ]
                },
                {
                    title: "Quick Filters",
                    menus: [
                        {
                            icon: Delivery32,
                            title: "Stock Flow Purchases",
                            link: "/stock-flows-purchases"
                        },
                        {
                            icon: ShoppingCartPlus32,
                            title: "Stock Flow Sales",
                            link: "/stock-flows-sales"
                        },
                    ]
                },
                {
                    title: "Reporting",
                    menus: [
                        {
                            icon: InventoryManagement32,
                            title: "Stock Report",
                            desc: "Analyze stock",
                            // link: "/stock-report"
                            link: getReportLink("StockReport")
                        },
                        {
                            icon: Cube32,
                            title: "Last Stock Adjust Report",
                            link: getReportLink("LastStockAdjustReport")
                        },
                        {
                            icon: Grid32,
                            title: "Product Stocks",
                            // link: "/product-stock-report"
                            link: getReportLink("ProductStockReport")
                        },
                        {
                            icon: Hashtag32,
                            title: "Serial No Report",
                            link: getReportLink("SerialNoReport")
                        },
                        // {
                        //     icon: ShoppingCartArrowUp32,
                        //     title: "Sales Report",
                        //     desc: "Analyze sales",
                        //     link: "/sales-report"
                        // },
                        {
                            icon: ShoppingCartArrowDown32,
                            title: "Purchases Report",
                            link: "/purchases-report"
                        },
                        {
                            icon: Report32,
                            title: "Stock By Location",
                            link: getReportLink("StockQtyByLocReport")
                        },
                        {
                            icon: Report32,
                            title: "Stock Valuation By Location",
                            link: getReportLink("StockValuationByLocReport")
                        },
                        {
                            icon: Report32,
                            title: "Stock Valuation",
                            link: getReportLink("StockValuationReport")
                        },
                        // {
                        //     icon: Data_132,
                        //     title: "Sales Summary",
                        //     link: "/sales-summary-report"
                        // },
                        // {
                        //     icon: DataShare32,
                        //     title: "Product Performance",
                        //     link: "/product-performance-report"
                        // },
                    ]
                }
            ]
        },
        ...((hasCapabilitySupport("cinema")) ? [{
            title: "Cinema Operations",
            color: "yellow",
            subMenus: [
                {
                    title: "Theater",
                    menus: [
                        {
                            icon: Video32,
                            title: "Movies",
                            link: getObjectTypeUrl(OBJECT_TYPE_MOVIE)
                        },
                        {
                            icon: CalendarTools32,
                            title: "Showtime Scheduler",
                            link: '/showtime-scheduler'
                        },
                    ]
                },
                {
                    title: "Finances",
                    menus: [
                        {
                            icon: Ticket32,
                            title: "Ticket Sales",
                            link: "/ticket-sales"
                        },
                        {
                            icon: DocumentSentiment32,
                            title: "Distributor Payout",
                            link: "/payouts"
                        }
                    ]
                },
                {
                    title: "Monitoring",
                    menus: [
                        {
                            icon: CenterSquare32,
                            title: "Ticket Scans",
                            link: "/ticket-scans"
                        }
                    ]
                }
            ]
        }] : []),
        ...((hasCapabilitySupport("cinema")) ? [{
            title: "Cinema Management",
            color: "purple2",
            subMenus: [
                {
                    title: "Theater Definitions",
                    menus: [
                        {
                            icon: FitToScreen32,
                            title: "Theater Blueprints",
                            link: getObjectTypeUrl(OBJECT_TYPE_THEATER_TYPE)
                        },
                        {
                            icon: MapBoundary32,
                            title: "Theater Designs",
                            link: getObjectTypeUrl(OBJECT_TYPE_SEATING_PLAN)
                        },
                        {
                            icon: Theater32,
                            title: "Cinemas",
                            link: getObjectTypeUrl(OBJECT_TYPE_CINEMA)
                        },
                    ]
                },
                {
                    title: "Movie Management",
                    menus: [
                        {
                            icon: BuildingInsights_332,
                            title: "Distributors",
                            link: getObjectTypeUrl(OBJECT_TYPE_MOVIE_DISTRIBUTOR)
                        },
                        {
                            icon: Types32,
                            title: "Genres",
                            link: getObjectTypeUrl(OBJECT_TYPE_MOVIE_GENRE)
                        },
                        {
                            icon: Translate32,
                            title: "Languages",
                            link: getObjectTypeUrl(OBJECT_TYPE_MOVIE_LANGUAGE)
                        },
                    ]
                },
            ]
        }] : []),
        ...((hasCapabilitySupport("realEstate")) ? [{
            title: "Real Estate",
            color: "purple2",
            subMenus: [
                {
                    title: "Listing",
                    menus: [
                        {
                            icon: Industry32,
                            title: "Projects",
                            link: "/real-estate-project"
                        },
                        {
                            icon: Milestone32,
                            title: "Properties",
                            link: "/real-estate-property"
                        },
                    ]
                },
                ...(hasCapabilitySupport("onlineStore") ? [
                    {
                        title: "Website",
                        menus: [
                            ...(hasCapabilitySupport("realEstateFeaturedOffPlans") ? [{
                                icon: StarFilled32,
                                //title: "Featured Off Plans",
                                title: "Featured Projects",
                                //link: "/featured-off-plans"
                                link: "featured-projects",
                            }] : []),
                            ...(hasCapabilitySupport("realEstateFeaturedProperties") ? [{
                                icon: StarFilled32,
                                title: "Featured Properties",
                                link: "/featured-properties"
                            }] : []),
                        ]
                    }
                ] : []),
                {
                    title: "Connections",
                    menus: [
                        {
                            icon: Product32,
                            title: "Developers",
                            link: "/real-estate-developer"
                        },
                        {
                            icon: TwoPersonLift32,
                            title: "Owners/Tenants",
                            link: "/real-estate-individual"
                        },
                    ]
                },
                {
                    title: "Classification",
                    menus: [
                        {
                            icon: Wikis32,
                            title: "Cities",
                            link: "/real-estate-city"
                        },
                        {
                            icon: Map32,
                            title: "Areas",
                            link: "/real-estate-area"
                        },
                        {
                            icon: Types32,
                            title: "Project Types",
                            link: "/real-estate-project-type"
                        },
                        {
                            icon: Types32,
                            title: "Property Types",
                            link: "/real-estate-property-type"
                        },
                    ]
                }
            ]
        }] : []),
        ...((hasCapabilitySupport("restaurant")) ? [{
            title: "Offerings Administration",
            color: "light-blue",
            subMenus: [
                {
                    title: "Grouping",
                    menus: [
                        // {
                        //     icon: Categories32,
                        //     title: "Product Groups",
                        //     link: "/product-groups"
                        // },
                        {
                            icon: Types32,
                            title: "Product Targets",
                            link: "/product-targets"
                        },
                    ]
                },
                {
                    title: "Classification",
                    menus: [
                        {
                            icon: PhraseSentiment32,
                            title: "Brands",
                            desc: "View, add and edit product brands",
                            link: "/product-brands"
                        },
                        {
                            icon: Network_132,
                            title: "Departments",
                            desc: "View, add and edit departments",
                            link: "/product-departments"
                        },
                        {
                            icon: Category32,
                            title: "Categories",
                            desc: "View, add and edit categories",
                            link: "/product-categories"
                        },
                        {
                            icon: CategoryNewEach32,
                            title: "Subcategories",
                            desc: "View, add and edit subcategories",
                            link: "/product-subcategories"
                        },
                        {
                            icon: Classification32,
                            title: "Classifications",
                            link: "/product-classifications"
                        },
                    ],
                },
                {
                    title: "Utility",
                    menus: [
                        ...(hasCapabilitySupport("continuousStockType") && !hasCapabilitySupport("shipping") ? [{
                            icon: RulerAlt32,
                            title: "Measurement Types",
                            link: "/measurement-types"
                        }] : []),
                        {
                            icon: StringText32,
                            title: "Attribute Packs",
                            link: "/product-attribute-packs"
                        },
                    ]
                },
                {
                    title: "Labeling",
                    menus: [
                        {
                            icon: Barcode32,
                            title: "Product Labels",
                            desc: "Generate barcode and QR code labels",
                            link: "/product-labels"
                        },
                        {
                            icon: Barcode32,
                            title: "Bundle Labels",
                            desc: "Generate barcode and QR code labels",
                            link: "/bundle-labels"
                        },
                    ]
                },
                // {
                //     title: "Reporting",
                //     menus: [
                //         {
                //             icon: Rocket32,
                //             title: "Performance",
                //             desc: "Compare product performance"
                //         },
                //     ]
                // }
            ]
        }] : []),
        ...((!hasCapabilitySupport("restaurant")) ? [{
            title: "Products",
            color: "light-blue",
            subMenus: [
                {
                    title: "Management",
                    menus: [
                        {
                            icon: Product32,
                            title: "Products",
                            desc: "View, add and edit products",
                            link: "/products"
                        },
                        {
                            icon: Box32,
                            title: "Bundles",
                            desc: "View, add and edit product bundles",
                            link: "/product-bundles"
                        },
                        {
                            icon: Categories32,
                            title: "Product Groups",
                            link: "/product-groups"
                        },
                        {
                            icon: Types32,
                            title: "Product Targets",
                            link: "/product-targets"
                        },
                        ...(hasCapabilitySupport("continuousStockType") && !hasCapabilitySupport("shipping") ? [{
                            icon: RulerAlt32,
                            title: "Measurement Types",
                            link: "/measurement-types"
                        }] : []),
                        {
                            icon: StringText32,
                            title: "Attribute Packs",
                            link: "/product-attribute-packs"
                        },
                    ]
                },
                {
                    title: "Classification",
                    menus: [
                        {
                            icon: PhraseSentiment32,
                            title: "Brands",
                            desc: "View, add and edit product brands",
                            link: "/product-brands"
                        },
                        {
                            icon: Network_132,
                            title: "Departments",
                            desc: "View, add and edit departments",
                            link: "/product-departments"
                        },
                        {
                            icon: Category32,
                            title: "Categories",
                            desc: "View, add and edit categories",
                            link: "/product-categories"
                        },
                        {
                            icon: CategoryNewEach32,
                            title: "Subcategories",
                            desc: "View, add and edit subcategories",
                            link: "/product-subcategories"
                        },
                        {
                            icon: Classification32,
                            title: "Classifications",
                            link: "/product-classifications"
                        },
                    ],
                },
                {
                    title: "Labeling",
                    menus: [
                        {
                            icon: Barcode32,
                            title: "Product Labels",
                            desc: "Generate barcode and QR code labels",
                            link: "/product-labels"
                        },
                        {
                            icon: Barcode32,
                            title: "Bundle Labels",
                            desc: "Generate barcode and QR code labels",
                            link: "/bundle-labels"
                        },
                    ]
                },
                {
                    title: "Reporting",
                    menus: [
                        {
                            icon: Product32,
                            title: "Product Report",
                            link: getReportLink("ProductList")
                        },
                        {
                            icon: DeliveryParcel32,
                            title: "Low Stock Products",
                            link: "/low-stock-product-report"
                        },
                        // {
                        //     icon: Compare32,
                        //     title: "Product Merge Report",
                        //     link: getReportLink("ProductMergeReport")
                        // }
                    ]
                },
                {
                    title: "Utilities",
                    menus: [
                        {
                            icon: QueryQueue32,
                            title: "Packing List",
                            link: getReportLink("PackingList")
                        }
                    ]
                }
            ]
        }] : []),
        ...((hasCapabilitySupport("services")) ? [{
            title: "Services",
            color: "blue",
            subMenus: [
                {
                    title: "Sales",
                    menus: [
                        {
                            icon: GraphicalDataFlow32,
                            title: "Service Sales",
                            link: "/service-sales"
                        },
                    ]
                },
                {
                    title: "Management",
                    menus: [
                        ...(!hasCapabilitySupport("shipping") ? [{
                            icon: ServiceDesk32,
                            title: "Services",
                            link: "/services"
                        }] : []),
                        {
                            icon: Application32,
                            title: "Packages",
                            link: "/service-packages"
                        },
                    ]
                },
                {
                    title: "Classification",
                    menus: [
                        {
                            icon: Network_132,
                            title: "Departments",
                            link: "/service-departments"
                        },
                        {
                            icon: Category32,
                            title: "Categories",
                            link: "/service-categories"
                        },
                        {
                            icon: CategoryNewEach32,
                            title: "Subcategories",
                            link: "/service-subcategories"
                        },
                    ]
                },
            ]
        }] : []),
        ...((hasOnlineStore) ? [{
            title: "Online Store",
            color: "light-blue-2",
            subMenus: [
                // {
                //     title: "Management",
                //     menus: [
                //         {
                //             icon: LocationCompany32,
                //             title: "Deliverables/Rentals",
                //             link: "/404"
                //         }
                //     ]
                // },
                {
                    // title: "",
                    menus: [
                        {
                            icon: ManageProtection32,
                            title: "Visit Website As Admin",
                            link: "/website-admin-view"
                        },
                        {
                            icon: Home32,
                            title: "Advertisements",
                            link: "/slides"
                        },
                        {
                            icon: AirlineManageGates32,
                            title: "Customer Testimonies",
                            link: "/customer-testimonies"
                        },
                    ]
                },
                {
                    title: "CMS",
                    menus: [
                        // {
                        //     icon: TextLink32,
                        //     title: "Menus and Links",
                        //     // link: "/online-store-custom-pages"
                        // },
                        {
                            icon: LicenseDraft32,
                            title: "Dynamic Content",
                            link: "/dynamic-content"
                        },
                        {
                            icon: Code32,
                            title: "Page Heads",
                            link: "/page-heads",
                        }
                    ]
                },
                {
                    title: "Posting",
                    menus: [
                        {
                            icon: MediaLibrary32,
                            title: "Gallery Posts",
                            link: "/gallery-posts"
                        },
                        {
                            icon: Blog32,
                            title: "Blog Posts",
                            link: "/blog-posts"
                        },
                        {
                            icon: InsertPage32,
                            title: "Blog Categories",
                            link: "/blog-categories"
                        },
                    ]
                },
                {
                    title: "Custom Pages",
                    menus: [
                        {
                            icon: ViewFilled32,
                            title: "Public Pages",
                            link: "/online-store-custom-pages"
                        },
                        {
                            icon: Locked32,
                            title: "Private Pages",
                            link: "/private-online-store-custom-pages"
                        },
                    ]
                },
                {
                    title: "Definitions",
                    menus: [
                        {
                            icon: Purchase32,
                            title: "Payment Methods",
                            link: "/online-store-payment-methods"
                        },
                    ]
                }
            ]
        }] : []),
        {
            title: "CRM",
            color: "green",
            subMenus: [
                {
                    title: "Customers",
                    menus: [
                        {
                            icon: AirlinePassengerCare32,
                            title: "Customers",
                            desc: "View, add and edit customers",
                            link: "/customers"
                        },
                        {
                            icon: Events32,
                            title: "Groups",
                            desc: "View, add and edit customer groups",
                            link: "/customer-groups"
                        },
                    ]
                },
                ...((hasCapabilitySupport("thirdPartyPos")) ? [{
                    title: 'Relations',
                    menus: [
                        {
                            icon: Building32,
                            title: "Venues",
                            link: "/venues"
                        },
                    ]
                }] : []),
                {
                    title: 'Communications',
                    menus: [
                        {
                            icon: Headset32,
                            title: "Contacts",
                            desc: "View, add and edit contact informations",
                            link: "/contacts"
                        },
                        ...(hasCapabilitySupport("onlineStore") ? [{
                            icon: Bullhorn32,
                            title: "Newsletter",
                            link: "/newsletter-subscriptions"
                        }] : []),
                    ]
                },
                {
                    title: "Pricing",
                    menus: [

                        {
                            icon: Gift32,
                            title: "Loyalty",
                            desc: "View, add and edit loyalty cards",
                            link: "/loyalty-cards"
                        },
                        {
                            icon: TagEdit32,
                            title: "Coupon Promotion",
                            link: "/coupon-promotions"
                        },
                        {
                            icon: CutOut32,
                            title: "Coupons",
                            desc: "View, add and edit coupons",
                            link: "/coupons"
                        },
                        {
                            icon: TagGroup32,
                            title: "Discounts",
                            desc: "View, add and edit store discount periods",
                            link: "/discounts"
                        },
                    ]
                }
            ]
        },
        // {
        //     title: "Promotion Management",
        //     color: "orange",
        //     subMenus: [
        //         {
        //             title: "Pricing",
        //             menus: [

        //                 {
        //                     icon: Gift32,
        //                     title: "Loyalty",
        //                     desc: "View, add and edit loyalty cards",
        //                     link: "/loyalty-cards"
        //                 },
        //                 {
        //                     icon: CutOut32,
        //                     title: "Coupons",
        //                     desc: "View, add and edit coupons",
        //                     link: "/coupons"
        //                 },
        //                 {
        //                     icon: TagGroup32,
        //                     title: "Discounts",
        //                     desc: "View, add and edit store discount periods",
        //                     link: "/discounts"
        //                 },
        //             ]
        //         }
        //     ]
        // },

        /*
        
    public static final int OBJECT_TYPE_LEAVE_TYPE = 49;
    
    
    
    public static final int OBJECT_TYPE_LEAVE = 53;
        */
        ...((hasCapabilitySupport("activities")) ? [{
            title: "Business Administration",
            color: "green2",
            subMenus: [
                // {
                //     menus: [
                //         {
                //             icon: Task32,
                //             title: "Activities",
                //             link: "/activities"
                //         }
                //     ]
                // },
                ...((accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) ? [
                    {
                        title: "Definitions",
                        menus: [
                            {
                                icon: ChoroplethMap32,
                                title: "Blueprints",
                                link: "/activity-blueprints"
                            },
                            {
                                icon: Legend32,
                                title: "Activity Statuses",
                                link: "/activity-statuses"
                            },
                            {
                                icon: UserProfile32,
                                title: "Member Tags",
                                link: "/activity-member-tags"
                            },
                            // {
                            //     icon: EventsAlt32,
                            //     title: "Teams",
                            //     link: "/activity-teams"
                            // },
                        ]
                    }
                ] : [])
            ]
        },] : []),
        //...((hasCapabilitySupport("orderManagementSystem")) ? [{
        // ...(hasCapabilitySupport("hrm") ? [{
        //     title: "HRM",
        //     color: "hr-color",
        //     subMenus: [
        //         {
        //             title: 'Employment',
        //             menus: [
        //                 {
        //                     icon: User32,
        //                     title: "Employees",
        //                     link: "/employees"
        //                 },
        //                 {
        //                     icon: User32,
        //                     title: "Designations",
        //                     link: "/designations"
        //                 },
        //             ]
        //         },
        //         {
        //             title: "Payroll",
        //             menus: [
        //                 ...((hasCapabilitySupport("lms")) ? [{
        //                     icon: DocumentSentiment32,
        //                     title: "Tutor Payout",
        //                     link: "/payouts"
        //                 }] : []),
        //                 {
        //                     icon: User32,
        //                     title: "Pay Heads",
        //                     link: "/pay-heads"
        //                 },
        //                 {
        //                     icon: User32,
        //                     title: "Salary Package",
        //                     link: "/salary-packages"
        //                 },
        //             ]
        //         },
        //         {
        //             title: "Leaves",
        //             menus: [
        //                 {
        //                     icon: User32,
        //                     title: "Leaves",
        //                     link: "/leaves"
        //                 },
        //                 {
        //                     icon: User32,
        //                     title: "Leave Types",
        //                     link: "/leave-types"
        //                 },
        //             ]
        //         }
        //     ]

        // }] : []),
        // {
        //     title: "Company",
        //     color: "red",
        //     subMenus: [
        //         {
        //             menus: [
        //                 {
        //                     icon: User32,
        //                     title: "Staff Users",
        //                     desc: "View, add and edit staff users",
        //                     link: "/staff-users"
        //                 },
        //                 {
        //                     icon: Store32,
        //                     title: "Stores",
        //                     desc: "View, add and edit stores",
        //                     link: "/stores"
        //                 },
        //                 // ...(hasCapabilitySupport("restaurant") ? [{
        //                 //     icon: DeskAdjustable32,
        //                 //     title: "Restaurant Components",
        //                 //     link: "/rest-components"
        //                 // }] : []),
        //                 {
        //                     icon: DataVis_232,
        //                     title: "Warehouses",
        //                     desc: "View, add and edit warehouses",
        //                     link: "/warehouses"
        //                 },
        //                 ...(hasCapabilitySupport("mobileUnit") ? [{
        //                     icon: Van32,
        //                     title: "Mobile Units",
        //                     link: "/mobile-units"
        //                 }] : []),
        //                 ...(hasCapabilitySupport("terminalPos") ? [{
        //                     icon: Screen32,
        //                     title: "Terminals",
        //                     desc: "View, add and edit terminals",
        //                     link: "/terminals"
        //                 }] : []),
        //             ]
        //         },
        //     ]
        // },
        ...((hasCapabilitySupport("onlineLearning") && (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_LEARNING_MANAGER)) ? [{
            title: "Learning Management",
            color: "green2",
            subMenus: [
                {
                    title: 'Design',
                    menus: [
                        {
                            icon: GroupPresentation32,
                            title: "Courses",
                            link: "/lectures"
                        },
                        {
                            icon: VideoFilled32,
                            title: "Course Videos",
                            link: "/lecture-videos"
                        },
                        {
                            icon: ListBoxes32,
                            title: "Course Quizzes",
                            link: "/lecture-quizzes"
                        },
                    ]
                },
                {
                    title: 'Management',
                    menus: [
                        {
                            icon: Education32,
                            title: "Learners",
                            link: "/learners"
                        },
                    ]
                }
            ]
        }] : []),
        {
            title: "System",
            color: "white",
            subMenus: [
                {
                    menus: [
                        {
                            icon: Gradient32,
                            title: "Templates",
                            link: "/templates"
                        },
                        {
                            icon: LicenseGlobal32,
                            title: "Supported Currencies",
                            link: "/supported-currencies"
                        },
                        {
                            icon: WatsonHealthTextAnnotationToggle32,
                            title: "System Values",
                            desc: "Set system values",
                            link: "/system-values"
                        },
                        ...(hasCapabilitySupport("lms") ? [{
                            icon: Email32,
                            title: "Email Designer",
                            link: "/email-designer"
                        }] : []),
                        {
                            icon: Printer32,
                            title: "Printers",
                            link: "/printers"
                        },
                        ...(hasCapabilitySupport("restaurant") ? [{
                            icon: Printer32,
                            title: "Print Event Designer",
                            link: "/print-event-designer"
                        }] : []),
                        // {
                        //     icon: StringText32,
                        //     title: "Attributes",
                        //     desc: "Define custom system attributes"
                        // },
                        {
                            icon: DocumentImport32,
                            title: "Import",
                            desc: "Import spreadsheet data into system",
                            link: "/import-data"
                        },
                        {
                            icon: Archive32,
                            title: "Logs",
                            desc: "View system logs",
                            link: "/system-logs",
                        },
                        ...(hasCapabilitySupport("orderManagementSystem") ? [{
                            icon: App32,
                            title: "Delivery App",
                            link: "/delivery-app"
                        }] : []),
                        ...(hasCapabilitySupport("salesApp") ? [{
                            icon: App32,
                            title: "Sales App",
                            link: "/sales-app"
                        }] : []),
                        ...(hasCapabilitySupport("pocketPosApp") ? [{
                            icon: App32,
                            title: "Pocket POS App",
                            link: "/pocket-pos-app"
                        }] : []),
                        {
                            icon: Settings32,
                            title: "System Settings",
                            link: "/system-settings",
                        },
                    ]
                },
            ]
        },
    ];



    if (accountRole == ACCOUNT_TYPE_SUPERVISOR) {
        for (const menuGroup of menu) {
            for (const subMenu of menuGroup.subMenus) {
                subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/system-logs" && menuItem.link != "/system-settings" && menuItem.link != "/staff-users" && menuItem.link != "/supported-currencies" && menuItem.link != "/sales-app" && menuItem.link != "/delivery-app" && menuItem.link != "/pocket-pos-app" && menuItem.link != "/system-values");
            }
        }
    } else if (accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
        //menu = menu.filter(menu => (menu.title == "Accounting" || menu.title == "Finances" || menu.title == "Point of Sale"));
        menu = menu.filter(menu => (menu.title == "Accounting" || menu.title == "Finance Management" || menu.title == "Point of Sale" || menu.title == "Activities"));
    } else if (accountRole == ACCOUNT_TYPE_SALES) {
        if (hasCapabilitySupport("salesSystem")) {
            menu = menu.filter(menu => (menu.title == "Portal" || menu.title == "Products" || menu.title == "Relations" || menu.title == "Sales" || menu.title == "Activities"));

            // for (const menuGroup of menu) {
            //     if (menuGroup.title == "Sales") {
            //         menuGroup.subMenus = menuGroup.subMenus.filter(subMenu => subMenu.title == "Management")
            //     }
            // }
        } else {
            menu = menu.filter(menu => (menu.title == "Portal" || menu.title == "Products" || menu.title == "Relations" || menu.title == "Activities"));
        }

        for (const menuGroup of menu) {
            for (const subMenu of menuGroup.subMenus) {
                subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/monitoring");
            }

            if (menuGroup.title == "Relations") {
                for (const subMenu of menuGroup.subMenus) {
                    subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link == "/customers" || menuItem.link == "/customer-groups" || menuItem.link == "/contacts");
                }
            }
        }
    } else if (accountRole == ACCOUNT_TYPE_REAL_ESTATE_AGENT) {
        menu = menu.filter(menu => (menu.title == "Real Estate" || menu.title == "CRM" || menu.title == "Activities"));

        for (const menuGroup of menu) {
            if (menuGroup.title == "Activities") {
                continue;
            }

            for (const subMenu of menuGroup.subMenus) {
                subMenu.menus = subMenu.menus.filter(menuItem =>
                    menuItem.link == "/real-estate-project" ||
                    menuItem.link == "/real-estate-property" ||
                    menuItem.link == "/customers" ||
                    menuItem.link == "/contacts");
            }
        }
    }


    return menu;
}