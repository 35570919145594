import { User24, Add16, Search16, ErrorFilled16, Launch16, Collaborate24 } from '@carbon/icons-react'
import React, { useEffect, useState, useRef } from 'react'
import ProfilePic from '../../../components/ProfilePic'
import Button from '../../../components/Button'
import Util from '../../../util/Util'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import { ComboBox, TextInput } from 'carbon-components-react'
import useSize from '../../../util/useSize'
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { OBJECT_TYPE_ACCOUNT } from '../../../constants/ObjectTypes'

const HEIGHT_MIN = 80;
const HEIGHT_MID = 68;

const OPTIMAL_WIDTH = 452;
const OPTIMAL_HEIGHT = 180;

const Content = ({ size, state, loading, salespeople, hideTitle }) => {

    const getSelectedSalesperson = () => {
        const salespersonId = state.getSalespersonId();
        for (const salesperson of salespeople) {
            if (salesperson.id == salespersonId) {
                return salesperson;
            }
        }

        return undefined;
    }

    return (<>
        {/* {!hideTitle && size.height >= HEIGHT_MID && <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
            <Collaborate24 style={{marginRight: 5}} />
            <h4>Salesperson</h4>
        </div>} */}

        {size.height < (HEIGHT_MIN / 2) && <p style={{ fontSize: 12 }}>Salesperson</p>}

        {size.height >= (HEIGHT_MIN / 2) && <div style={{ display: 'flex', justifyContent: 'center', width: '100%', }}>
            <div style={{ flex: 1 }}>
                <ComboBox
                    key={'sales-person-combo-' + state.getSalespersonId()}
                    light
                    size="lg"
                    className="no-padding-menu"
                    disabled={loading || state.isSetSalespersonLoading()}
                    items={salespeople}
                    selectedItem={getSelectedSalesperson()}
                    itemToString={category => category !== null ? category.value : ""}
                    itemToElement={item => (
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%', pointerEvents: 'none' }}>
                            <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.id)} />
                            <span style={{ marginLeft: '0.5rem' }}>
                                {item.value}
                            </span>
                        </div>
                    )}
                    placeholder="Select Salesperson"
                    onChange={e => state.setSalespersonId(e.selectedItem !== null ? e.selectedItem.id : 0)}

                />
            </div>

            {getSelectedSalesperson() !== undefined && <Button onClick={() => {
                UIUtil.confirm(() => state.setSalespersonId(0))
            }} hasIconOnly data-tip="Clear" kind="danger" className="bx--tooltip--hidden" renderIcon={ErrorFilled16} style={{ height: 48 }} />}
        </div>}

        {/* {(mini && size.height < HEIGHT_MIN) && <Button onClick={mini} hasIconOnly data-tip="Open" className="bx--tooltip--hidden" renderIcon={Launch16} style={{height: 48}}>Select Salesperson</Button>} */}

        {/* {!state.readOnly && size.height >= HEIGHT_MIN && <> */}
        {/* <div style={{flex: 1}} /> */}

        {/* <TextInput style={{height: 48}} light labelText="Search Customer" placeholder="Email or number" />    */}
        {/* </>} */}
    </>)
}


export default ({ state, light, hideTitle, responsive }) => {
    const [loading, setLoading] = useState(true);
    const [salespeople, setSalespeople] = useState([]);

    useEffect(() => {
        Api.getAllSalespeople(response => {
            if (response.status === true) {
                setSalespeople(response.payload);
                setLoading(false);
            } else {
                UIUtil.showError("Failed to load salesperson list");
            }
        })
    }, [])


    const targetRef = useRef();
    const size = useSize(targetRef)
    const content = <Content {...({ size: responsive ? size : { height: 10000, width: 10000 }, state, light, hideTitle, loading, salespeople })} />
    const [contentOpen, setContentOpen] = useState(false)
    useEffect(() => {
        ReactTooltip.rebuild()
    }, [size])

    return (
        <div ref={targetRef} className="dashboard-card" style={{ width: '100%', height: '100%', background: light ? 'white' : '#f4f4f4', padding: '1rem', overflow: 'visible', display: 'flex', flexDirection: 'column' }}>
            {responsive ? (
                size.height > HEIGHT_MIN ? (
                    content
                ) : (<>
                    {React.cloneElement(content, { mini: () => setContentOpen(true) })}

                    <Modal
                        isOpen={contentOpen}
                        onRequestClose={() => setContentOpen(false)}
                        style={{
                            overlay: { zIndex: 8998, background: 'rgba(0,0,0,0.75)' },
                            content: {
                                height: OPTIMAL_HEIGHT, width: OPTIMAL_WIDTH, background: 'rgb(244, 244, 244)', border: 'none',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex', flexDirection: 'column', borderRadius: 0
                            }
                        }}
                    >
                        {React.cloneElement(content, { size: { height: 10000, width: 10000 } })}
                    </Modal>
                </>)
            ) : (
                content
            )}

        </div>
    )
}