import Button from "../../../../components/Button";
import { CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { useEffect, useState } from "react";
import Api from "../../../../session/Api";
import UIUtil from "../../../../util/UIUtil";
import { STUDENT_PRESENCE } from "../../../../constants/Constants";
import { SettingAbsentDialog } from "./setting-absent-dialog";
import Util from "../../../../util/Util";

const ConfirmButton = ({ activityId, setPresence }) => {
    const [loading, setLoading] = useState(false);
    const onConfirm = () => {
        setLoading(true);
        Api.createStudentPresence(activityId, STUDENT_PRESENCE.PRESENT, 0, response => {
            if (response.status === true) {
                setPresence(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }
    return (
        <Button onClick={onConfirm} loading={loading} size="sm" style={{ borderRadius: 50 }} renderIcon={CheckmarkFilled16}>
            Confirm
        </Button>
    )
}

const CancelButton = ({ activityId, setPresence, children }) => {
    const [loading, setLoading] = useState(false);
    const onBtn = () => {
        setLoading(true);
        Api.cancelMyStudentPresence(activityId, response => {
            if (response.status === true) {
                setPresence(null)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }
    return (
        <Button loading={loading} onClick={onBtn} size="sm" style={{ borderRadius: 50 }} kind="secondary" renderIcon={ErrorFilled16}>
            {children}
        </Button>
    )
}

const AbsentButton = ({ activityId, setPresence }) => {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <Button onClick={() => setVisible(true)} size="sm" style={{ borderRadius: 50 }} kind="danger" renderIcon={ErrorFilled16}>
                Absent
            </Button>

            <SettingAbsentDialog activityId={activityId} setPresence={setPresence} open={visible} onClose={() => setVisible(false)} />
        </>
    )
}

export const StudentPresenceViewForTutor = ({ activityId, presence }) => {
    if (presence) {
        if (presence.state === STUDENT_PRESENCE.PRESENT) {
            return (
                <div style={{ borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem' }}>
                    <div style={{ borderRadius: 15, padding: '0.5rem', background: 'green', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        <CheckmarkFilled16 />
                        <p style={{ fontSize: 14 }}>Student confirmed will be present</p>
                    </div>
                </div>
            )
        } else if (presence.state === STUDENT_PRESENCE.ABSENT) {
            return (
                <div style={{ borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem' }}>
                    <div style={{ borderRadius: 15, padding: '0.5rem', background: '#990000', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        <ErrorFilled16 />
                        <p style={{ fontSize: 14 }}>Student confirmed will be absent</p>
                    </div>
                    {presence.makeUpActivity &&
                        <div style={{ marginTop: '1rem', borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem', }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Make up schedule</p>
                            <h6>{Util.getFullDate(presence.makeUpActivity.startDate) + ' - ' + Util.getTime(presence.makeUpActivity.endDate)}</h6>
                        </div>}
                </div>
            )
        } else {
            return null;
        }
    } else {
        return (
            <div style={{ borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem' }}>
                <p>Attendance not confirmed by student</p>
            </div>
        )
    }
}

export const StudentPresenceView = ({ readonly, setPresence, activityId, presence }) => {
    if (presence) {
        if (presence.state === STUDENT_PRESENCE.PRESENT) {
            return (
                <div style={{ borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem' }}>
                    <div style={{ borderRadius: 15, padding: '0.5rem', background: 'green', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        <CheckmarkFilled16 />
                        <p style={{ fontSize: 14 }}>{readonly ? 'Attended' : 'Will be attending'}</p>
                    </div>
                    {!readonly && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1rem', gap: '0.5rem' }}>
                        <CancelButton {...({ activityId, setPresence })}>
                            Cancel Confirmation
                        </CancelButton>
                    </div>}
                </div>
            )
        } else if (presence.state === STUDENT_PRESENCE.ABSENT) {
            return (
                <div style={{ borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem' }}>
                    <div style={{ borderRadius: 15, padding: '0.5rem', background: '#990000', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        <ErrorFilled16 />
                        <p style={{ fontSize: 14 }}>{readonly ? 'Was absent' : 'Will be absent'}</p>
                    </div>
                    {presence.makeUpActivity &&
                        <div style={{ marginTop: '1rem', borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem', }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Make up schedule</p>
                            <h6>{Util.getFullDate(presence.makeUpActivity.startDate) + ' - ' + Util.getTime(presence.makeUpActivity.endDate)}</h6>
                        </div>}
                    {!readonly && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1rem', gap: '0.5rem' }}>
                        <CancelButton {...({ activityId, setPresence })}>
                            Cancel Confirmation
                        </CancelButton>
                    </div>}
                </div>
            )
        } else {
            return null;
        }
    } else {
        return (
            <div style={{ borderRadius: 15, border: '1px solid #00000040', background: 'white', padding: '1rem' }}>
                <p>{readonly ? 'Attendance not confirmed' : 'Please confirm attendance'}</p>
                {!readonly && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1rem', gap: '0.5rem' }}>
                    <AbsentButton {...({ activityId, setPresence })} />
                    <ConfirmButton {...({ activityId, setPresence })} />
                </div>}
            </div>
        )
    }
}

export function StudentPresence({ activityId, readonly, onUpdatePresence }) {
    const [presence, setPresence] = useState(undefined);

    useEffect(() => {
        Api.getMyStudentPresence(activityId, response => {
            if (response.status === true) {
                setPresence(response.payload);
            }
        })
    }, [activityId])

    const updatePresence = presence => {
        setPresence(presence);
        onUpdatePresence?.();
    }


    if (presence === undefined) {
        return null;
    }

    return <StudentPresenceView presence={presence} readonly={readonly} activityId={activityId} setPresence={updatePresence} />
}