
import { Accordion, AccordionItem, Tile } from 'carbon-components-react';
import React from 'react'
import { withRouter } from 'react-router';
import { OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_GROUP_PRICE_OVERRIDE, OBJECT_TYPE_LOYALTY_CARD_ISSUE, OBJECT_TYPE_PRICE_OVERRIDE } from '../../constants/ObjectTypes';
import TableTemplate from '../../templates/TableTemplate';
import { TagEdit16 } from '@carbon/icons-react';

import Button from '../../components/Button';

class PriceOverrideList extends React.Component {

    renderGroupPriceOverride() {
        return (
            <div>
                {TableTemplate.renderTemplate({
                    getChildrenRequest: {
                        parentId: this.props.parent.id,
                    },
                    embedded: true,
                    hideBottomBar: true,
                    title: "Group Price Overrides", 
                    subTitle: "", 
                    objectType: OBJECT_TYPE_GROUP_PRICE_OVERRIDE,
                    pagePath: "/group-price-overrides/", 
                    history: this.props.history
                })}
            </div>
        )
    }

    renderSinglePriceOverride() {
        return (
            <div>
                {TableTemplate.renderTemplate({
                    getChildrenRequest: {
                        parentId: this.props.parent.id,
                    },
                    embedded: true,
                    hideBottomBar: true,
                    title: "Price Overrides", 
                    subTitle: "", 
                    objectType: OBJECT_TYPE_PRICE_OVERRIDE,
                    pagePath: "/price-overrides/", 
                    history: this.props.history
                })}
            </div>
        )
    }

    render() {
        return (
            <div style={{marginTop: '1rem'}}>
                <Tile style={{marginBottom: '1rem'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <TagEdit16 style={{marginRight: '0.5rem'}} />
                        <h6>Price Modification System</h6>
                    </div>
                    <p style={{marginTop: '0.5rem', fontSize: 12}}>
                        Price override takes precedence in the order: single price override, category-specific group price override and then all products/bundles group price override (specifc-to-broad flow).
                        <br />
                        Override gets applied in the precedence order of: customer and then customer group.
                        <br />
                        <br />
                        Discount price overrides can be stacked. When stacked discounts have two single price set overrides on a single product, the cheapest will be used.
                        <br />
                        The customer and discount overrides will be compared and the lowest value will be used.
                    </p>
                </Tile>
                <Accordion className="full-width-accordion">
                    <AccordionItem title="Group Price Override" open>
                        {this.renderGroupPriceOverride()}
                    </AccordionItem>
                    <AccordionItem title="Single Price Override" open>
                        {this.renderSinglePriceOverride()}
                    </AccordionItem>
                </Accordion>
            </div>
        )
    }
    
}

export default withRouter(PriceOverrideList);