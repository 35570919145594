import { ComboBox } from "carbon-components-react";
import { TRANSLATE_LANG } from "../../app/Config";


export function TranslateBanner({ lang, setLang }) {
    return (
        <div className="white-combo-box-arrow" style={{ background: '#1c1c1c', width: '100%', paddingInline: '1rem', paddingBlock: '0.5rem', color: 'white', display: 'flex', alignItems: 'center' }}>
            <h4 style={{ flex: 1 }}>Translate</h4>
            <ComboBox size='lg' items={TRANSLATE_LANG}
                selectedItem={lang ?? "EN"} light
                itemToString={item => {
                    if (item === "EN") {
                        return "English";
                    } else if (item === "AR") {
                        return "Arabic";
                    } else if (item === "FA") {
                        return "Persian";
                    } else if (item === "RU") {
                        return "Russian"
                    } else {
                        return item;
                    }
                }}
                style={{ background: 'black', color: 'white', }}
                onChange={e => setLang(e.selectedItem ? e.selectedItem : undefined)} />
        </div>
    )
}