import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { OBJECT_TYPE_CUSTOMER } from "../../../constants/ObjectTypes";
import { getAccountRole } from "../../../session/SessionManager";
import ItemCreatorDialog from "../../../templates/ItemCreatorDialog";
import UIUtil from "../../../util/UIUtil";
import PosState from "./PosState";


export class HeadlessPosStateError extends Error {
    constructor(message) {
        this.message = message;
    }
}


export function newHeadlessPosState(factory = (getState, setState) => new PosState(getState, setState)) {
    const state = {

    }
    const getState = () => state;
    const setState = (arg, callback) => {
        const changes = typeof arg === "function" ? arg(state) : arg;
        Object.assign(state, changes);
        callback?.(state);
    }

    const posState = factory(getState, setState)

    return {
        load: () => new Promise((resolve, reject) => {
            posState.loadSession(false, success => success ? resolve() : reject(new HeadlessPosStateError("No POS Session Exists")))
        }),
        holdCart: () => new Promise((resolve, reject) => {
            posState.holdCart(success => success ? resolve() : reject(new HeadlessPosStateError("Failed to hold cart")))
        }),
        setCustomerId: (customerId) => new Promise((resolve, reject) => {
            posState.setCustomerId(customerId, success => success ? resolve() : reject(new HeadlessPosStateError("Failed to set customer")))
        }),
        setOrderType: (type) => new Promise((resolve, reject) => {
            posState.setOrderRestTypeHeadless(type, success => success ? resolve() : reject(new HeadlessPosStateError("Failed to set type")))
        }),
        setCartDataWaiterCalled: (value) => new Promise((resolve, reject) => {
            posState.setCartDataWaiterCalled(value, success => success ? resolve() : reject(new HeadlessPosStateError("Failed. Please refresh page.")));
        }),
        createRestCart: () => new Promise((resolve, reject) => {
            posState.createRestCart(success => success ? resolve() : reject(new HeadlessPosStateError("Failed to hold cart")))
        }),
        clearCart: () => new Promise((resolve, reject) => {
            posState.clearCart(success => success ? resolve() : reject(new HeadlessPosStateError("Failed to hold cart")))
        }),

        hasItems: () => posState.getAllCartItems()?.length > 0,
        isDineInMode: () => posState.isDineInMode(),


        newCustomer: (phoneNumber) => UIUtil.promptUI((onSelect) => (
            <ItemCreatorDialog
                hideThumbnail
                filterProperties={["fullName", "email", "phoneNumber",]}
                valueDefaultPhoneNumber={{ property: "phoneNumber", value: phoneNumber }}
                objectType={OBJECT_TYPE_CUSTOMER}
                open onClose={() => onSelect(undefined)}
                onCreatedItem={createdItem => onSelect(createdItem.id)} />
        )),

        posUrl: (() => {
            const role = getAccountRole();
            const hasPath = role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR;
            return hasPath ? "/pos" : "/";
        })()
    }
}