import { withLoadablePageWithParams } from "../../../base/Page";
import Api from "../../../session/Api";


import {
    Ticket32, Theater32, QrCode20, QrCode16
} from '@carbon/icons-react'
import { Tag } from "carbon-components-react"

import AddToWallet from '../../../images/add-to-apple-wallet.png'
import { Link } from "react-router-dom"


import { ArrowLeft32, Ticket24, Calendar24, Home16, CheckmarkFilled32, CheckmarkFilled16, Theater24, Receipt20 } from '@carbon/icons-react'
import Logo from '../../../images/star-cinema.png'
import { big } from "../../../util/Util";
import UIUtil from "../../../util/UIUtil";
import Button from "../../../components/Button";
import { OBJECT_TYPE_MOVIE } from "../../../constants/ObjectTypes";
import ImageView from "../../../components/ImageView";
import QRCode from "react-qr-code";

function ticketQrCodeValue(ticket) {
    return 'TI-ID-' + btoa((ticket.id).toString());
}

const View = ({ payload: serviceSale }) => {

    const openTicketQrCode = ticket => {
        UIUtil.showQrCode(ticketQrCodeValue(ticket))
    }
    const openTicketPassQrCode = ticket => {
        UIUtil.showQrCode(window.location.origin + Api.getMovieTicketWalletPass(ticket.id), { label: "Apple Wallet", title: "Scan to add" })
    }

    return (
        <div style={{ minHeight: '100vh', background: '#0f0f0f' }}>
            <div style={{ width: '100%', height: '6rem', background: '#1b1c3c', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ffffff10' }}>
                <div style={{ width: '6rem' }}></div>
                <div style={{ flex: 1 }} />
                <img src={Logo} style={{ height: 75 }} />
                <div style={{ flex: 1 }} />
                <div style={{ width: '6rem' }}></div>
            </div>
            <div style={{ width: '100%', height: '3rem', background: '#11122b', paddingInline: '1rem', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
                <Ticket24 />
                <h4>Tickets</h4>
            </div>

            <div style={{ marginTop: '3rem', width: '100%', display: 'flex', color: 'white', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingInline: '1rem', gap: '1rem', }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>Thank you!</h4>
                    <h6 style={{ fontWeight: 'normal', textAlign: 'center' }}>Hope you'll enjoy your movie!</h6>
                </div>

                <div style={{ display: 'flex', gap: '1rem', width: '100%', paddingInline: '1rem', marginTop: '1rem' }}>
                    <div>
                        <div style={{ width: 150, minWidth: 150, height: 225, borderRadius: 10, overflow: 'hidden', border: '1px solid #ffffff10' }}>
                            <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, serviceSale.ticketSaleMovieId)} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div style={{ marginTop: '1rem' }}>
                            <div style={{ marginBottom: '1rem' }}>
                                <label style={{ marginBottom: '-0.25rem', color: '#ffffffAA' }} className="bx--label">Hall</label>
                                <p style={{ fontSize: 22, fontWeight: 'bold' }}>{serviceSale.ticketSaleHallName}</p>
                            </div>

                            <div style={{ marginBottom: '1rem' }}>
                                <label style={{ marginBottom: '-0.25rem', color: '#ffffffAA' }} className="bx--label">Showtime</label>
                                <p style={{ fontSize: 22, fontWeight: 'bold' }}>{serviceSale.ticketSaleShowtime}</p>
                            </div>

                            <div style={{ marginBottom: '1rem' }}>
                                <label style={{ marginBottom: '-0.25rem', color: '#ffffffAA' }} className="bx--label">Cinema</label>
                                <p style={{ fontSize: 14 }}>{serviceSale.ticketSaleCinemaName}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ marginTop: '3rem', marginBottom: '1rem', width: '100%', paddingInline: '1rem' }}>
                    {serviceSale.ticketSaleTickets.map(ticket => (
                        <div key={ticket.id} style={{
                            marginBottom: '1rem', background: '#0f0f0f', border: '1px solid #ffffff20', width: '100%', padding: '1rem', borderRadius: 10, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Ticket32 />
                                <div style={{ marginRight: '0.5rem', marginLeft: '0.5rem', flex: 1 }}>
                                    <p>Ticket</p>
                                </div>
                                <Tag type='blue'>{ticket.seatName}</Tag>
                            </div>

                            <div style={{ width: '100%', height: 175, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5, background: 'white' }}>
                                <QRCode size={150} value={ticketQrCodeValue(ticket)} />
                            </div>
                            <Button onClick={() => UIUtil.showQrCode(ticketQrCodeValue(ticket))} renderIcon={QrCode16} style={{ borderRadius: 7, width: '100%', maxWidth: 'unset', marginTop: '0.5rem' }}>Open QR Code</Button>

                            {/* <div style={{ flex: 1, minWidth: '1rem' }} />
                            {window.navigator.platform === "iPhone" && <a download href={Api.getMovieTicketWalletPass(ticket.id)}>
                                <img style={{ height: 45 }} src={AddToWallet} />
                            </a>}
                            {window.navigator.platform !== "iPhone" &&
                                <img onClick={() => openTicketPassQrCode(ticket)} style={{ height: 45, cursor: 'pointer' }} src={AddToWallet} />}
                            <button style={{ marginLeft: '1rem', fontSize: 12 }} onClick={() => openTicketQrCode(ticket)} className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                <QrCode20 style={{ fontSize: 12 }} />
                            </button> */}
                        </div>
                    ))}
                </div>


            </div>
            <div style={{ height: '3rem' }} />
            <div style={{ height: '3rem' }} />
        </div>
    )
}
export const ClientTicketSale = withLoadablePageWithParams(params => listener => Api.getServiceSale(params.serviceSaleId, response => {
    if (response.status === true && response.payload.ticketSale) {
        listener(response)
    } else {
        listener({ status: false })
    }
}), View)