import { Tag } from "carbon-components-react";
import { SidePanel } from "../../../../templates/draft/components/side-panel";
import { RestTablesView } from "../views/tables/rest-tables-view";
import Portal from "../../../../util/Portal";
import Button from "../../../../components/Button";
import { ArrowRight16, Close16 } from '@carbon/icons-react'
import { getAccountRole } from "../../../../session/SessionManager";
import { ACCOUNT_TYPE_TYPE_WAITER } from "../../../../constants/Constants";


export function ChangeTableDialog({ onClose, setTableId }) {

    return (<>
        <SidePanel onClose={() => { }} lg belowSubheader>
            <div style={{ height: '100%', width: '100%', }}>
                <div style={{ background: '#1c1c1c', color: 'white', height: '3rem', width: '100%', borderBottom: '1px solid #ffffff10', paddingInline: '1rem', display: 'flex', alignItems: 'center' }}>
                    <h4 style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>Change Table</h4>
                    <div style={{ flex: 1 }} />
                    <Tag type="green">Available</Tag>
                    <Tag type="high-contrast">Occupied</Tag>
                    <Tag type="purple">Reserved</Tag>

                    <Button onClick={onClose} renderIcon={Close16} size="sm" style={{ borderRadius: 50, marginLeft: '3rem' }}>Close</Button>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 3rem)', display: 'flex' }}>
                    <RestTablesView title="TABLES" subtitle="Select" selectMode onSelect={table => {
                        setTableId(table.id)
                        onClose();
                    }} />
                </div>
            </div>
        </SidePanel>
    </>)
}

export function TableDialog({ state }) {
    const [mode, setMode] = state.useRestOrderType();

    const visible = false;
    const setVisible = () => { }
    return (<>
        {/* <Portal> */}
        {(state?.shouldShowTableSelect()) && <SidePanel onClose={() => { }} lg belowSubheader>
            <div style={{ height: '100%', width: '100%', }}>
                <div style={{ background: '#1c1c1c', color: 'white', height: '3rem', width: '100%', borderBottom: '1px solid #ffffff10', paddingInline: '1rem', display: 'flex', alignItems: 'center' }}>
                    <h4 style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>Select Table</h4>
                    <div style={{ flex: 1 }} />
                    <Tag type="green">Available</Tag>
                    <Tag type="high-contrast">Occupied</Tag>
                    <Tag type="purple">Reserved</Tag>

                    {getAccountRole() !== ACCOUNT_TYPE_TYPE_WAITER && <>
                        <Button loading={state.isSetRestOrderTypeLoading()} onClick={() => setMode("take-out")} renderIcon={ArrowRight16} size="sm" style={{ borderRadius: 50, marginLeft: '3rem' }}>Take-out</Button>
                        <Button loading={state.isSetRestOrderTypeLoading()} onClick={() => setMode("delivery")} renderIcon={ArrowRight16} size="sm" style={{ borderRadius: 50, marginLeft: '1rem' }}>Delivery</Button>
                    </>}
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 3rem)', display: 'flex' }}>
                    <RestTablesView rootBg="#330000" title="TABLES" subtitle="Select" selectMode onSelect={table => {
                        state.setTableId(table.id)
                        setVisible(false);
                    }} />
                </div>
            </div>
        </SidePanel>}
        {/* </Portal> */}
    </>)
}