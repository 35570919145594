import { withLoadablePage } from "../../../base/Page";
import { Timeline } from "../../../components/timeline/timeline";
import Api from "../../../session/Api";


const View = ({ payload }) => (
    <div style={{ height: '100%', padding: '3rem' }}>
        <Timeline headerTitle="Cinema/Hall" data={payload} />
    </div>
)

export const ShowtimeTimeline = withLoadablePage(listener => Api.getShowtimeTimeline(listener), View);


const ViewNoPadding = ({ payload }) => (
    <div style={{ height: '100%', }}>
        <Timeline headerTitle="Cinema/Hall" data={payload} />
    </div>
)
export const ShowtimeTimelineNoPadding = withLoadablePage(listener => Api.getShowtimeTimeline(listener), ViewNoPadding);