import { useEffect, useState } from "react"
import Select from 'react-select';
import Util from "../../../util/Util";
import { useListFilter } from "../../engine/useValueFilter";

const createStyle = style => (baseStyles) => ({
    ...baseStyles,
    ...style
})

const DEF_VALUE = null;

export function MultiSelect({ placeholder, fieldKey, state, property, options, skipAutoFilter, isMulti = true, filterMapper, operator = "EQ" }) {
    const [filters, setFilters] = useListFilter(state, property, skipAutoFilter)
    const [value, setValue] = state.use(fieldKey, DEF_VALUE)

    useEffect(() => {
        if (Util.isStringExists(value?.label)) {
            if (filterMapper) {
                setFilters(filterMapper({ property, skipAutoFilter, operator, value: value.label }))
            } else {
                setFilters([{ property, skipAutoFilter, operator, value: value.label }])
            }
        } else {
            setFilters([])
        }

        // if (Util.isStringExists(value?.label)) {
        //     setFilters([
        //         ...(filters ?? []),
        //         { property, operator: "EQ", value: value.label }
        //     ])
        // } else {
        //     //setFilters([])
        //     setFilters((filters ?? []).filter(item => item.property !== property))
        // }
    }, [value, property])

    return (
        <Select
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: 30,
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 5,
                    background: '#ffffff10',
                    border: '1px solid #00000099',
                    color: 'white',
                    minHeight: 30,
                    height: 30,
                    padding: 0
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white',
                    height: 30,
                    margin: 0
                }),
                multiValue: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: 'white'
                }),
                multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: '#ffffffBB'
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                menu: createStyle({
                    background: '#1c1c1c'
                }),
                option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
                    ...baseStyles,
                    backgroundColor: isFocused ? '#ffffff20' : isSelected ? 'transparent' : 'transparent'
                }),
                singleValue: createStyle({
                    color: 'white'
                })
                // menuList: createStyle({
                //     background: 'red'
                // }),
                // menuPortal: createStyle({
                //     background: 'red'
                // })
                // multiValueRemove: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
                // dropdownIndicator: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
                // indicatorSeparator: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
            }}
            isClearable
            isMulti={isMulti} options={options.map(opt => ({ label: opt }))} />
    )
}

export function MultiSelectListMode({ placeholder, fieldKey, state, property, options, isMulti = true, operator = "EQ" }) {
    const [filters, setFilters] = useListFilter(state, property)
    const [value, setValue] = state.use(fieldKey, DEF_VALUE)

    useEffect(() => {
        if (Util.isStringExists(value?.label)) {
            setFilters([{ property, operator, value: value.label }])
        } else {
            setFilters([])
        }
    }, [value, property])

    return (
        <Select
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: '100%',
                    outline: 'none'
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '100%',
                    padding: '0 6px',
                    outline: 'none'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 5,
                    background: '#ffffff10',
                    border: '0px solid #00000099',
                    outline: 'none',
                    color: 'black',
                    minHeight: 30,
                    height: '100%',
                    padding: 0,
                    boxShadow: 'none'
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'black',
                    outline: 'none',
                    height: '100%',
                    margin: 0,
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '100%',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),

                menu: createStyle({
                    background: '#ffffff'
                }),
                option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
                    ...baseStyles,
                    backgroundColor: isFocused ? '#00000020' : isSelected ? 'transparent' : 'transparent',
                    color: '#000000BB'
                }),
                singleValue: createStyle({
                    color: 'black'
                })
            }}
            isClearable
            isMulti={isMulti} options={options.map(opt => ({ label: opt }))} />
    )
}


export function MultiSelectWithId({ placeholder, fieldKey, state, property, options, skipAutoFilter, isMulti = true, filterMapper, operator = "EQ" }) {
    const [filters, setFilters] = useListFilter(state, property, skipAutoFilter)
    const [value, setValue] = state.use(fieldKey, DEF_VALUE)

    useEffect(() => {
        if (value?.value) {
            if (filterMapper) {
                setFilters(filterMapper({ property, skipAutoFilter, operator, value: value.value }))
            } else {
                setFilters([{ property, skipAutoFilter, operator, value: value.value }])
            }
        } else {
            setFilters([])
        }

        // if (Util.isStringExists(value?.label)) {
        //     setFilters([
        //         ...(filters ?? []),
        //         { property, operator: "EQ", value: value.label }
        //     ])
        // } else {
        //     //setFilters([])
        //     setFilters((filters ?? []).filter(item => item.property !== property))
        // }
    }, [value, property])

    return (
        <Select
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: 30,
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 5,
                    background: '#ffffff10',
                    border: '1px solid #00000099',
                    color: 'white',
                    minHeight: 30,
                    height: 30,
                    padding: 0
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white',
                    height: 30,
                    margin: 0
                }),
                multiValue: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: 'white'
                }),
                multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    background: '#000000',
                    color: '#ffffffBB'
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '30px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                menu: createStyle({
                    background: '#1c1c1c'
                }),
                option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
                    ...baseStyles,
                    backgroundColor: isFocused ? '#ffffff20' : isSelected ? 'transparent' : 'transparent'
                }),
                singleValue: createStyle({
                    color: 'white'
                })
                // menuList: createStyle({
                //     background: 'red'
                // }),
                // menuPortal: createStyle({
                //     background: 'red'
                // })
                // multiValueRemove: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
                // dropdownIndicator: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
                // indicatorSeparator: (baseStyles, state) => ({
                //     ...baseStyles,
                //     display: readonly ? 'none' : undefined
                // }),
            }}
            isClearable
            isMulti={isMulti} options={options.map(opt => ({ label: opt.value, value: opt.id }))} />
    )
}