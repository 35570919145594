import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { hasCapabilitySupport } from '../../app/Capabilities';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { STORE_TYPE_BRANCH, STORE_TYPE_THIRD_PARTY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_ALLOWED_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import UIUtil from '../../util/UIUtil';
import { BalanceView } from '../../views/accounting/BalanceView';
import AllSubsidiaryReportView from '../accounting-reports/AllSubsidiaryReportView';
import StockFlowListView from '../stock-flow/StockFlowListView';
import TransactionListView from '../transaction/TransactionListView';

const StaffUsersList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'storeId',
                    parentId: item.id,
                    showNotIncluded: false,
                    //nonEditable: item.type == STORE_TYPE_THIRD_PARTY
                    nonEditable: true
                },
                embedded: true,
                title: "Staff Users",
                subTitle: "Store staff users",
                objectType: OBJECT_TYPE_ACCOUNT,
                pagePath: "/staff-users/",
                history: history
            })}
        </div>
    )
}

const TerminalsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'storeId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Terminals",
                subTitle: "Store terminals",
                objectType: OBJECT_TYPE_TERMINAL,
                pagePath: "/terminals/",
                history: history
            })}
        </div>
    )
}

const StocksView = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <StockFlowListView locationList={{ id: item.id, type: OBJECT_TYPE_STORE }} />
        </div>
    )
}

const SubsidiaryReport = ({ item }) => {
    return <AllSubsidiaryReportView itemId={item.id} itemType={OBJECT_TYPE_STORE} />
}

const TransactionsView = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <TransactionListView partyList={{ id: item.id, type: OBJECT_TYPE_STORE }} />
        </div>
    )
}


const AllowedProductList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Allowed Products",
                subTitle: "Allowed Products",
                objectType: OBJECT_TYPE_ALLOWED_PRODUCT,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_ALLOWED_PRODUCT}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                productFinderShowReplaceWithBundle
                parentId={item.id}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}

class StoreDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_STORE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_STORE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_STORE,
                    itemResult: this.state.itemResult,
                    pagePath: "/stores/",
                    history: this.props.history,
                    // hasCustomBtn: <>
                    //     <div style={{ width: '1rem' }} />
                    //     <BalanceView item={this.state.itemResult.item} subsidiaryType={OBJECT_TYPE_STORE} />
                    // </>,
                    customTabs: [
                        {
                            title: "Staff Users",
                            component: StaffUsersList
                        },
                        // ...(this.state.itemResult.item.type == STORE_TYPE_THIRD_PARTY ? [
                        //     {
                        //         title: "Allowed Products",
                        //         component: AllowedProductList
                        //     }
                        // ] : []),
                        ...(hasCapabilitySupport("terminalPos") ? [
                            {
                                title: "Terminals",
                                component: TerminalsList
                            }
                        ] : []),
                        {
                            title: "Stock",
                            component: StocksView
                        },
                        // {
                        //     title: "Ledger Report",
                        //     component: SubsidiaryReport
                        // },
                        // {
                        //     title: "Transactions",
                        //     component: TransactionsView
                        // }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(StoreDetailPage);