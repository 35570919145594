import PaginatedDataTable from "../../../components/data-table/PaginatedDataTable"
import ProfilePic from "../../../components/ProfilePic"
import Api from "../../../session/Api"
import {
    Calendar16, Calendar32
} from '@carbon/icons-react'
import Util from "../../../util/Util"
import ActiveStatusView, { CustomActiveStatusView } from "../../../components/ActiveStatusView"
import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react"
import { useState } from "react"
import { getObjectTypeUrl, OBJECT_TYPE_STUDENT } from "../../../constants/ObjectTypes"
import Button from "../../../components/Button"
import { Link } from "react-router-dom"
import ImageView from "../../../components/ImageView"

const HEADERS = [
    {
        id: "attendeeId",
        name: "Student",
        render: item => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={34} src={Api.getThumbnail(item.attendeeType, item.attendeeId)} />
                <p style={{ marginLeft: '0.5rem' }}>{item.attendeeName}</p>
            </div>
        )
    },
    {
        id: "attendanceDate",
        name: "Attendance Date",
        render: item => <> <Calendar16 /> {Util.getFullDate(item.attendanceDate)} </>
    },
    {
        id: "allowedToProceed",
        name: "Allowed To Proceed",
        render: item => <CustomActiveStatusView active={item.allowedToProceed} activeTxt="Allowed" disabledTxt="Denied" />
    }
]

export function StudentEntranceActivityPage() {
    const [showSelected, setShowSelected] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null)
    return (
        <div className="main-content">
            <PaginatedDataTable
                title="Student Entrance Activity"
                description={"All student entrance QR scans"}
                columns={HEADERS}
                loader={(page, _, listener) => Api.getLmsAttendanceReport(page, listener)}
                loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}
                onClick={item => {
                    setSelectedRecord(item)
                    setShowSelected(true)
                }}
            >
            </PaginatedDataTable>

            <div style={{ height: 1000 }} />

            {selectedRecord && <ComposedModal open={showSelected} onClose={() => setShowSelected(false)} size="md">
                <ModalHeader label="Entrance Log" title="Student Record" />
                <ModalBody style={{ paddingRight: '1rem' }}>

                    <h6 style={{ marginBottom: '0.5rem' }}>Student</h6>
                    <Link target="_blank" style={{}} to={getObjectTypeUrl(selectedRecord.attendeeType) + "/" + selectedRecord.attendeeId}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ProfilePic size={32} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(selectedRecord.attendeeType, selectedRecord.attendeeId)} />
                            <p>{selectedRecord.attendeeName}</p>
                        </div>
                    </Link>

                    <h6 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>Date</h6>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Calendar32 style={{ marginRight: '0.5rem' }} />
                        <p>{Util.getFullDate(selectedRecord.attendanceDate)}</p>
                    </div>

                    <h6 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>Snapshot Proof</h6>
                    <ImageView style={{ width: '100%', height: 300 }} src={Api.getStudentAttendanceProof(selectedRecord.id)} />
                </ModalBody>
                <ModalFooter>
                    <Button kind="secondary" onClick={() => setShowSelected(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </ComposedModal>}
        </div>
    )
}