import { useEffect, useRef } from "react";


export function useFirstTime(fn, deps = []) {
    const firstTimeRef = useRef(true);
    useEffect(() => {
        if (firstTimeRef.current) {
            fn();
        }
        firstTimeRef.current = false;
        // return () => firstTimeRef.current = true;
    }, deps)
}