import { useMemo, useState } from "react"
import { withLoadablePage } from "../../../base/Page"
import ProfilePic from "../../../components/ProfilePic"
import { OBJECT_TYPE_PRODUCT_BUNDLE } from "../../../constants/ObjectTypes"
import Api from "../../../session/Api"
import { BringToFront32, TrashCan16, ArrowRight16, ArrowRight32 } from '@carbon/icons-react'
import { RecipeManager } from "../foods/recipe-manager"
import Portal from "../../../util/Portal"
import { SidePanel } from "../../../templates/draft/components/side-panel"
import { TextInput } from "carbon-components-react"
import Util from "../../../util/Util"

const FoodItem = ({ food }) => {
    const [showRecipeManager, setShowRecipeManager] = useState(false);
    return (<>
        <div key={food.id} onClick={() => setShowRecipeManager(true)} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '1rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
            <ProfilePic notProfile size={32} style={{ borderRadius: 7 }} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, food.id)} />
            <p style={{ fontSize: 14, flex: 1 }}>{food.value}</p>
            <ArrowRight16 />
        </div>

        <Portal>
            {showRecipeManager && <SidePanel onClose={() => { }}>
                <RecipeManager title={food.value} showFoodHeader foodId={food.id} onClose={() => setShowRecipeManager(false)} />
            </SidePanel>}
        </Portal>
    </>)
}

function View({ payload }) {
    const [search, setSearch] = useState("");
    const foods = useMemo(() => {
        if (Util.isStringExists(search)) {
            return payload.filter($ => $.value.toLowerCase().includes(search.toLowerCase()))
        } else {
            return payload;
        }
    }, [payload, search])
    return (
        <div>
            <div style={{ paddingInline: '6rem', paddingTop: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem', gap: '1rem' }}>
                    <BringToFront32 style={{ width: 64, height: 64 }} />
                    <div>
                        <h3 style={{ fontWeight: 'bold', }}>Bulk Recipe Manager</h3>
                        <h4 style={{ color: '#0f62fe' }}>Utility</h4>
                    </div>
                    <div style={{ flex: 1 }} />
                    <TextInput style={{ borderRadius: 50, borderBottom: 'none' }} size="sm" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                </div>

                {foods.map(food => <FoodItem key={food.id} food={food} />)}

                <div style={{ height: '3rem' }} />

            </div>
        </div>
    )
}

export const BulkRecipeManagerPage = withLoadablePage(Api.getAllFoods.bind(Api), View)