import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../../images/Logo.png'
import ReceiptLogo from '../../../images/ReceiptLogo.png'
import { THERMAL_RECEIPT_LOGO } from '../../../app/Config';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 178,
        height: 37,
        //marginLeft: 'auto',
        //marginRight: 'auto'
    }
});

const Invoice = ({ invoice }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* <Image style={styles.logo} src={logo} /> */}
            <Image style={styles.logo} src={THERMAL_RECEIPT_LOGO.squareLogo ? logo : ReceiptLogo} />
            <InvoiceTitle title={invoice.title} />
            <InvoiceNo invoice={invoice} />
            {/* <BillTo invoice={invoice}/> */}
            <InvoiceItemsTable invoice={invoice} />
            {/* <InvoiceThankYouMsg /> */}

            {invoice.showReceiverSign && <View style={{ flexDirection: 'row', marginTop: 25 }}>
                <Text style={{ marginRight: 5, fontSize: 12, }}>Received by:</Text>
                <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                <Text style={{ marginRight: 5, fontSize: 12 }}>Signature:</Text>
                <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                <Text style={{ marginRight: 5, fontSize: 12 }}>Date:</Text>
                <Text style={{ fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
            </View>}
        </Page>
    </Document>
);

export default Invoice