import { TextInput } from "carbon-components-react"
import { useEffect, useMemo, useState } from "react"
import Util from "../util/Util"
import { isEqual } from 'lodash'

export function ArrayInput({ label, placeholder, value, setValue }) {
    const [emailAddresses, setEmailAddresses] = useState(() => {
        return value ? value.map(item => ({ id: Util.newTempId(), address: item })) : []
    })
    const [emailAddressCursorId, setEmailAddressCursorId] = useState(Util.newTempId())

    useEffect(() => {
        setValue(emailAddresses.map(item => item.address))
    }, [emailAddresses])

    useEffect(() => {
        const parentValue = value;
        const stateValue = emailAddresses.map(item => item.address);

        if (!isEqual(parentValue, stateValue)) {
            setEmailAddresses(parentValue ? parentValue.map(item => ({ id: Util.newTempId(), address: item })) : [])
            setEmailAddressCursorId(Util.newTempId())
        }
    }, [value])

    return (
        <div>
            <label className="bx--label" style={{ marginBottom: '-0.75rem' }}>{label}</label>
            {emailAddresses.concat([{ id: emailAddressCursorId }]).map((emailAddress) => (<>
                <div style={{ height: '0.5rem' }} />
                <TextInput
                    key={emailAddress.id}
                    light
                    placeholder={emailAddress.id == emailAddressCursorId ? "+ " + placeholder : ""}
                    value={emailAddress.address}
                    onBlur={e => setEmailAddresses(emailAddresses => {
                        let addresses = [...emailAddresses]
                        if (!Util.isStringExists(e.target.value)) {
                            addresses = emailAddresses.filter(address => address.id != emailAddress.id)
                        }
                        return addresses;
                    })}
                    onChange={e => setEmailAddresses(emailAddresses => {
                        let addresses = [...emailAddresses]
                        let found = false;
                        for (let i = 0; i < addresses.length; i++) {
                            const address = addresses[i];
                            if (address.id == emailAddress.id) {
                                addresses[i] = {
                                    ...address,
                                    address: e.target.value
                                }
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            addresses.push({
                                id: emailAddress.id,
                                address: e.target.value
                            })
                            setEmailAddressCursorId(Util.newTempId())
                        }
                        return addresses;
                    })}
                />
            </>))}
        </div>
    )
}