import { Tag } from "carbon-components-react";
import ImageView from "../../../components/ImageView";
import Api from "../../../session/Api";
import { OBJECT_TYPE_MOVIE } from "../../../constants/ObjectTypes";
import { useEffect, useState } from "react";
import { movieRatingLabel } from "../../../domain/cinema";


const MovieCard = ({ movie, onClick }) => (
    <div onClick={onClick} style={{ position: 'relative', background: '#1c1c1c', borderRadius: 10, overflow: 'hidden', border: '1px solid #ffffff10', color: 'white', width: 250, height: 375 }}>
        <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, movie.id)} style={{ width: '100%', height: '100%', borderRadius: 10, }} />
        <div style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', padding: '1rem', borderTop: '1px solid #ffffff20', background: '#00000080', backdropFilter: 'blur(16px) saturate(200%)', }}>
            <div style={{ display: 'flex', gap: '0.25rem' }}>
                <h5 style={{ fontSize: 18, }}>{movie.displayName}</h5>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>{movieRatingLabel(movie.rating)}</p>
            </div>
            <p style={{ fontSize: 12, opacity: 0.85, marginBottom: '0.5rem' }}>Runtime: {movie.duration} minutes</p>

            {movie.nextShowtimeTime ? (
                movie.nextShowtimeTime.includes("3D") ? (
                    <Tag size="sm" type='blue'>next showtime: {movie.nextShowtimeTime.replace(" 3D", "")} <strong>3D</strong></Tag>
                ) : (
                    <Tag size="sm" type='blue'>next showtime: {movie.nextShowtimeTime}</Tag>
                )
            ) : (
                <Tag size="sm" type="high-contrast">no showtimes today</Tag>
            )}
            {/* <Tag size="sm" type='blue'>23:00</Tag>
            <Tag size="sm" type='blue'>23:00</Tag>
            <Tag size="sm" type='blue'>23:00</Tag>
            <Tag size="sm" type='blue'>23:00</Tag>
            <Tag size="sm" type='blue'>23:00</Tag>
            <Tag size="sm" type='blue'>23:00</Tag>
            <Tag size="sm" type='blue'>23:00</Tag> */}
        </div>
    </div>
)

const useMovies = () => {
    const [movies, setMovies] = useState([]);
    useEffect(() => {
        Api.getCinemaPosMovies(response => {
            if (response.status === true) {
                const rem = response.payload.length % 4 > 0 ? 4 - (response.payload.length % 4) : 0;
                setMovies([...response.payload, ...Array(rem).fill({})])
                //setMovies(response.payload);
            }
        })
    }, [])
    return movies;
}

export const MovieList = ({ onMovieClick }) => {
    const movies = useMovies();
    return (
        <div style={{ paddingBlock: '3rem', display: 'flex', justifyContent: 'space-around', paddingInline: '6rem', gap: '1rem', flexWrap: 'wrap' }}>
            {movies.map(movie => (
                movie.id ? (
                    <MovieCard key={movie.id} movie={movie} onClick={() => onMovieClick(movie)} />
                ) : (
                    <div style={{ width: 250 }} />
                )
            ))}
        </div>
    )
}