import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT, JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT } from '../../../constants/Constants';

const borderColor = '#ff9b87'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#ffb6a8',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '70%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({journalEntry}) => {
    const rows = journalEntry.amounts.map( item => 
        <View style={styles.row} key={item.id}>
            <Text style={styles.description}>{item.account.name}</Text>
            <Text style={styles.qty}>{item.type == JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT ? item.amount.toFixed(2) : '-'}</Text>
            <Text style={styles.rate}>{item.type == JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT ? item.amount.toFixed(2) : '-'}</Text>
            {/* <Text style={styles.amount}>{(item.qty * item.rate).toFixed(2)}</Text> */}
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow