import React, { useState } from 'react'
import NoteView from './NoteView'
import { ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput, ClickableTile } from 'carbon-components-react';
import Button from '../../components/Button';

export default ({objectType, objectId, open, onClose}) => {
    const [hideDialog, setHideDialog] = useState(false)

    const dialogVisibilityListener = visible => {
        setHideDialog(visible)
    }

    return (
        <ComposedModal open={!hideDialog && open} onClose={onClose} size="lg">
            <ModalHeader label="Notes/Docs" title="All notes" />
            <ModalBody style={{paddingRight: '1rem'}}>
    
                <NoteView dialogVisibilityListener={dialogVisibilityListener} 
                objectType={objectType} objectId={objectId} />
    
            </ModalBody>
            <ModalFooter 
            // onRequestClose={onClose}
            // secondaryButtonText="Close"
            >
                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}