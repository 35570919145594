import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import { VAT } from '../../../app/Config';

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#ffb6a8',
  },
});

const InvoiceItemsTable = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow items={invoice.items} />
    {/* <InvoiceTableBlankSpace //rowsCount={ tableRowsCount - invoice.items.length} 
        /> */}
    <InvoiceTableFooter title="Subtotal" value={invoice.posSession.posTotals.subtotal.toFixed(2)} />
    {invoice.posSession.posTotals.discount > 0 &&
      <InvoiceTableFooter title="Discount" value={invoice.posSession.posTotals.discount.toFixed(2)} />}
    <InvoiceTableFooter title={"VAT " + `${VAT.PERC}%`} value={invoice.posSession.posTotals.tax.toFixed(2)} />
    <InvoiceTableFooter title="Grand Total" value={invoice.posSession.posTotals.total.toFixed(2)} />
  </View>
);

export default InvoiceItemsTable