import Button from "../../../components/Button";
import { Filter16, Filter20 } from '@carbon/icons-react'
import useHover from "../../../hooks/useHover";
import { useStringValueFilter } from "../../engine/useValueFilter";
import { useMemo } from "react";
import { getReport } from "../../reports/reports";
import { AdvancedFilterIndicator } from "./advanced-filter-indicator";

export function QuickFilterBar({ isListMode, reportName, endpoint, state, showDarkMode, setShowAdvancedFilter }) {
    const report = useMemo(() => getReport(reportName), [reportName])

    if (!report.filter) {
        return (
            <>
                <AdvancedFilterIndicator state={state} setShowAdvancedFilter={setShowAdvancedFilter} />
            </>
        );
    }

    if (isListMode) {
        return (<div className="list-mode-report-engine-quick-filter-bar">
            <report.filter endpoint={endpoint} state={state} />
        </div>)
    }

    return (<>
        <AdvancedFilterIndicator state={state} setShowAdvancedFilter={setShowAdvancedFilter} />
        <div
            className="report-engine-quick-filter-bar" style={{
                background: '#1c1c1c', color: 'white', width: '100%', paddingLeft: '0.5rem', paddingRight: '0.5rem', gap: '0.25rem',
                display: 'flex', alignItems: 'center', paddingBlock: '0.5rem', borderTop: '1px solid #ffffff10', flexWrap: 'wrap',
                boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) inset, 0px 4px 6px -2px rgba(0,0,0,0.05) inset',

                borderBottom: showDarkMode ? '1px solid #FFFFFF10' : undefined
            }}
        >
            <div style={{ height: 30, width: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Filter20 />
            </div>

            <report.filter endpoint={endpoint} state={state} />


            {/* <p style={{ fontSize: 12, flex: 1 }}>Advanced Filtering is Active</p>
            <Button size="sm" kind="primary" style={{ borderRadius: 50, height: 25, minHeight: 'unset' }} renderIcon={Filter16}>Enable Quick Filter</Button> */}
        </div>

    </>)
}