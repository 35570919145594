import { useCallback, useEffect, useState } from "react";
import Api from "../../session/Api";
import { getAccount } from "../../session/SessionManager";
import Util from "../../util/Util";
import UIUtil from "../../util/UIUtil";

function loader(itemType, itemId, page, listener) {
    Api.getComments(itemType, itemId, page, listener)
}

export function useLoader(itemType, itemId) {
    const loaderFunc = useCallback((page, listener) => loader(itemType, itemId, page, listener), itemType, itemId)
    return loaderFunc;
}

export function useComment(commentItem, sendComment) {
    const [sending, setSending] = useState(commentItem === undefined && sendComment.id === undefined && sendComment.tempId !== undefined)
    const [comment, setComment] = useState(commentItem);
    const [failed, setFailed] = useState(false);
    const [redacting, setRedacting] = useState(false)

    useEffect(() => {
        if (sending) {
            let cancelled = false;

            const { parentType, parentId, content } = sendComment;
            Api.postComment(parentType, parentId, content, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    setComment(response.payload)
                    setSending(false)
                } else {
                    setFailed(true)
                    UIUtil.showError(response.message);
                }
            })

            return () => cancelled = true;
        }
    }, [sending])

    const redactComment = () => UIUtil.confirm(() => {
        setRedacting(true)
        Api.deleteComment(comment.id, response => {
            setRedacting(false)
            if (response.status === true) {
                setComment(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
        })
    })

    return {
        sending, failed,
        comment: sending ? sendComment : comment,
        redacting, redactComment,
    }
}

export function createSendComment(itemType, itemId, content) {
    const self = getAccount();
    return {
        id: undefined,
        tempId: Util.newTempId(),

        userId: self.id,
        senderIsSelf: true,

        parentId: itemId,
        parentType: itemType,

        content,

        dateCreated: new Date().getTime(),
        redacted: false,

        sender: self.fullName
    }
}