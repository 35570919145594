
import { CheckmarkFilled16, Reset16, Save16, Close16 } from '@carbon/icons-react'
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Util, { absAmt } from '../../../util/Util';
import { ComboBoxField } from '../../../components/ComboBoxField';
import { withLoadablePage, withLoadablePagePropsAndProps } from '../../../base/Page';
import Api from '../../../session/Api';
import { TableInput } from '../../../components/table-input';
import Button from '../../../components/Button';
import { TextInput } from 'carbon-components-react';
import isEqual from 'lodash.isequal';
import UIUtil from '../../../util/UIUtil';
import { useDraft } from '../../../templates/draft/draft';
import { STATE_TYPE_FOOD_STOCK } from '../../../constants/Constants';
import Portal from '../../../util/Portal';

const CellHeader = ({ flex, width, children }) => (
    <div style={{ flex, width, paddingLeft: '0rem', display: 'flex' }}>
        <div style={{ width: '1rem' }} />
        <h6>{children}</h6>
    </div>
)

const Row = ({ children }) => (
    <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: '#00000020', }}>
        {children}
    </div>
)

const CellValue = ({ flex, width, children }) => (
    <div style={{ flex, width, paddingLeft: '0rem', display: 'flex' }}>
        {children}
    </div>
)


const DraftBtn = forwardRef(({ setData, getData }, ref) => {
    const draft = useDraft(STATE_TYPE_FOOD_STOCK, setData, getData, true)

    useImperativeHandle(ref, () => draft, [draft])

    return (<>
        {draft.draftManagement}
        <Portal>
            {draft.draftDialogs}
        </Portal>
    </>)
})


function useMakeDraft({
    initialValue,
    value, setValue
}) {
    const ref = useRef();

    const setData = data => {
        setValue(data?.value ?? initialValue);
    }
    const getData = () => ({
        value
    })
    return {
        draftBtn: <DraftBtn ref={ref} setData={setData} getData={getData} />,
        draft: ref.current
    }
}




const ItemsListView = ({ storeId, menu, payload }) => {
    const [initialValue, setInitialValue] = useState(payload);
    const [value, setValue] = useState(payload);
    const changesMade = useMemo(() => !isEqual(initialValue, value), [initialValue, value])
    const onClearBtn = () => {
        setValue([])
    }
    const onResetBtn = () => {
        setValue(initialValue);
    }
    const { draftBtn, draft } = useMakeDraft({
        initialValue,
        value, setValue
    });

    const [saving, setSaving] = useState(false);
    const onSaveBtn = () => {
        for (const item of value) {
            if (item.quantity == '') {
                item.quantity = '0';
            }

            if (typeof item.quantity !== "number") {
                if (!Util.isANumber(item.quantity)) {
                    UIUtil.showError('Invalid number: ' + item.quantity)
                    return;
                }
            }
        }

        setSaving(true);
        Api.saveFoodStockValues(storeId, value, response => {
            if (response.status === true) {
                UIUtil.showSuccess(true);
                setInitialValue(response.payload)
                setValue(response.payload)
                draft?.setSelectedDraft(undefined)
            } else {
                UIUtil.showError(response.message);
            }
            setSaving(false);
        })
    }

    const getQty = foodId => value.find($ => $.foodId === foodId)?.quantity ?? 0
    const setQty = (foodId, quantity) => setValue(prev => [
        ...prev.filter($ => $.foodId !== foodId), {
            foodId, quantity
        }
    ])


    const table = {
        header: (<>
            <CellHeader flex={3}>
            </CellHeader>
            <CellHeader flex={1}>
                Quantity
            </CellHeader>
        </>),
        content: (<>
            {menu.map(item => (
                <Row key={item.id}>
                    <CellValue flex={3}>
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: `calc(1rem + ${item.depth * 12}px)`, minHeight: 40 }}>
                            {item.header ? (
                                <h6 style={{ fontWeight: 'normal', fontStyle: 'italic' }}>{item.value}</h6>
                            ) : (
                                <h6>{item.value}</h6>
                            )}
                        </div>
                    </CellValue>
                    <CellValue flex={1}>
                        {!item.header && (<>
                            <TextInput placeholder='0' value={getQty(item.id)} onChange={e => setQty(item.id, absAmt(e.target.value))} />
                        </>)}
                    </CellValue>
                </Row>
            ))}
        </>)
    }
    return (
        <div style={{ position: 'relative' }}>
            <div className={"no-input-border-2"} style={{ background: '#f4f4f4', width: '100%', }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    {table.header}
                </div>
                {table.content}
            </div>

            <div style={{ marginTop: '4rem' }}>
                <div style={{ position: 'fixed', bottom: '3rem', left: '3rem', minHeight: '4rem', width: 'calc(100% - 6rem)', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', background: '#ffffff80', backdropFilter: 'blur(4px) saturate(200%)', border: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '0.5rem', paddingInline: '4rem', paddingBlock: '1rem', borderRadius: 10, marginTop: '4rem', color: 'black' }}>
                    {draftBtn}
                    <Button renderIcon={Save16} kind="ghost" onClick={() => draft.setShowSaveDraft(true)} size="sm" style={{ borderRadius: 50 }}>{draft?.selectedDraft ? 'Update Draft' : 'Save Draft'}</Button>
                    <div style={{ flex: 1 }} />


                    <Button onClick={onClearBtn} kind="danger-tertiary" size={"sm"} renderIcon={Close16} style={{ borderRadius: 7 }}>Clear</Button>
                    <Button disabled={!changesMade} onClick={onResetBtn} kind="secondary" size={"sm"} renderIcon={Reset16} style={{ borderRadius: 7 }}>Reset Changes</Button>
                    <Button loading={saving} onClick={onSaveBtn} className="green-button" size={"sm"} renderIcon={CheckmarkFilled16} style={{ borderRadius: 7 }}>Save</Button>
                </div>
            </div>
            <div style={{ height: '6rem' }} />
        </div>
    )
};

const ItemsList = withLoadablePagePropsAndProps(props => listener => Api.getFoodStockValues(props.storeId, listener), ItemsListView)
const View = ({ payload: { stores, menu } }) => {
    const currentDay = useMemo(() => Util.getDateOnlyWithoutYear(new Date().getTime()), [])
    const [selectedStoreId, setSelectedStoreId] = useState(() => stores[0]?.id);
    return (
        <div>
            <div style={{ paddingInline: '6rem', paddingTop: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h3 style={{ fontWeight: 'bold', }}>Estimated Daily Portions</h3>
                        <h4 style={{ color: '#0f62fe' }}>As of {currentDay}</h4>
                    </div>
                    <ComboBoxField placeholder={'Select store'} value={selectedStoreId} setValue={setSelectedStoreId} options={stores} />
                </div>
                {Util.isNumberExist(selectedStoreId) ? (
                    <ItemsList key={selectedStoreId + 'store-items-list'} storeId={selectedStoreId} menu={menu} />
                ) : (
                    <p style={{ fontSize: 14, opacity: 0.65 }}>Please select store to continue</p>
                )}
            </div>
        </div>
    )
}
export const FoodStockValuePage = withLoadablePage(Api.getFoodStockEndpoint.bind(Api), View);