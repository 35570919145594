import { ArrowContainer, Popover } from "react-tiny-popover";
import {
    CaretDown16
} from '@carbon/icons-react'
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import { Tag } from "carbon-components-react";

const ButtonPopover = ({ children, popover, invalidateOpen }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [invalidateOpen])

    return (
        <Popover
            isOpen={open}
            positions={['bottom', 'right']}
            // padding={10}
            containerStyle={{ zIndex: 1000000 }}
            align="end"
            onClickOutside={e => {
                // if (!classExistsInSomeParent(e.target, "table-list-filter-save-dialog")) {
                setOpen(false)
                // }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container to-do-board-button-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="to-do-board-button-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        {popover}
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                {children}
            </a>
        </Popover>
    )
}

const SelectionItem = ({ selected, name, onClick }) => (
    <div onClick={onClick} data-selected={selected} className='to-do-board-selection-item'>
        <p style={{ fontSize: 14, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>{name}</p>
        {selected && <Tag type="green" size="sm">Selected</Tag>}
    </div>
)

const List = ({ boardViews, selectedId, selectId }) => {
    return (
        <div style={{ padding: '0rem', borderRadius: 15, overflow: 'hidden' }}>
            <p style={{ fontSize: 12, opacity: 0.65, padding: '1rem' }}>Select board</p>
            <div style={{ maxHeight: 250, overflowX: 'hidden', overflowY: 'auto', borderTopWidth: 1, borderTopColor: '#00000020', borderTopStyle: 'solid' }}>
                {boardViews.map(item => <SelectionItem key={item.id} name={item.value}
                    selected={item.id == selectedId} onClick={() => selectId(item.id)} />)}
            </div>
        </div>
    )
}

export function BoardViewList({ title, boardViews, selectedId, selectId }) {
    return (
        <ButtonPopover invalidateOpen={selectedId} popover={<List {...({ boardViews, selectedId, selectId })} />}>
            <Button renderIcon={CaretDown16} style={{ borderRadius: 10, width: 250, height: 'calc(100% - 1rem)' }} size="sm">{title}</Button>
        </ButtonPopover>
    )
}