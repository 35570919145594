import ProfilePic from "../../../../components/ProfilePic"
import { OBJECT_TYPE_ACCOUNT } from "../../../../constants/ObjectTypes"
import Api from "../../../../session/Api"
import { getAccount } from "../../../../session/SessionManager"
import { ErrorFilled16 } from '@carbon/icons-react'
import Button from "../../../../components/Button"
import UIUtil from "../../../../util/UIUtil"

export default ({ stopSessionLoading, stopSession }) => {
    return (
        <div className="dashboard-card t-pos-card" style={{ width: '100%', background: '#f4f4f4', padding: '0.5rem', display: 'flex', alignItems: 'center', borderRadius: 10 }}>
            <div style={{ display: 'flex', marginTop: '0rem', alignItems: 'center', width: '100%' }}>
                <ProfilePic size={45} style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, getAccount().id)} />
                <div>
                    <p style={{ fontStyle: 'bold' }}>{getAccount().fullName}</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>
                            Operator
                        </p>
                    </div>
                </div>
                <div style={{ flex: 1 }} />

                <Button
                    // style={{ height: 40, borderRadius: 10 }}
                    className="t-pos-btn"
                    loading={stopSessionLoading}
                    onClick={() => UIUtil.confirm(stopSession)}
                    data-tip="Stop Session"
                    kind="danger"
                    tooltipPosition="left" tooltipAlignment="end"
                    iconDescription="Stop Session"
                    renderIcon={ErrorFilled16}
                    hasIconOnly
                >
                    {/* Stop Session */}
                </Button>
            </div>
        </div>
    )
}