import { MultiSelect } from "carbon-components-react";
import { ArrayInput } from "../../../../components/array-input";
import { AwareKVStoreEditor, KVStoreEditor } from "../../../../components/KVStoreEditor";
import { LEAD_FEELINGS, LEAD_FEELING_PENDING } from "../../../../domain/lead";
import Api from "../../../../session/Api";
import { FormSection } from "../../../../templates/form/form";
import { FormDialog } from "../../../../templates/form/form-dialog";
import { COUNTRY_LIST } from "../../../../util/country-list";
import { UserAssignation } from "../../../activities/components/user-assignation";



function FormView(form, payload) {
    const { TextField, TextAreaField, SwitchField, ComboBoxField, CustomField, ExtField } = form;
    return (
        <div>
            <ComboBoxField title="Property" fieldKey="propertyId" options={payload.properties.map(item => ({ id: item.id, title: item.value }))} />
            <TextAreaField title="Note" fieldKey="note" />
        </div>
    )
}

export function ProspectPropertyDialog({ creating, item, setItem, payload, open, onClose }) {

    return (
        <FormDialog open={open} onClose={onClose} label={creating ? "Creating" : "Updating"} title="Prospect Property"
            item={item} setItem={setItem} payload={payload} preventCloseOnClickOutside
            saveApi={(data, listener) => Api.saveProspectProperty(data, listener)}>
            {FormView}
        </FormDialog>
    )
}