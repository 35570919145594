import { Tab, Tabs, Tag } from "carbon-components-react"
import { useMemo } from "react"
import { useEffect, useState } from "react"
import Button from "../../../components/Button";
import { FormSection } from "../../../templates/form/form"
import Util from "../../../util/Util";
import { getAttachments, renderAttachmentView } from "./previews/base";
import { BillPreview } from "./previews/bill-preview";
import { ArrowRight16 } from '@carbon/icons-react'
import { OpenBtn } from "../buttons/open-btn";
import { getReport } from "../../reports/reports";

//duplicated
function formatTypeValue(type, value) {
    if (!value) {
        return '-'
    }

    switch (type) {
        case "TEXT": return (value ?? '') + '';
        case "DATE": return Util.getDateOnly(value);
        case "DATE_TIME": return Util.getFullDate(value);
        case "MONEY": return 'AED ' + Util.formatMoney(value);
        case "NUMBER": return (value ?? 0).toFixed(2);
        case "LIST": return (value?.replace("|", ", ") ?? '');
        case "IMAGE": return "";
        default: return (value ?? '') + '';
    }
}


const Panel = ({ darkMode, width, children }) => (
    <div
        // hidden-tab-content
        className="report-table-right-panel full-width-tabs clear-tab-content full-perc-height-tab-content"
        style={{
            // position: 'relative',
            height: "100%",
            width: width,
            // overflow: 'auto',
            color: darkMode ? 'white' : "black",
            backgroundColor: darkMode ? "#00000070" : "#ffffff40",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(12px)",
            borderLeft: darkMode ? '1px solid #ffffff10' : '1px solid #00000020',
            display: 'flex',
            flexDirection: 'column'
        }}>
        {children}
    </div>
)

const Header = ({ children, skipMargin }) => <p style={{ fontWeight: 'bold', marginTop: skipMargin ? undefined : '1rem', }}>{children}</p>
const Value = ({ title, children }) => (<div style={{ marginBottom: '0.5rem' }}>
    <label style={{ marginBottom: 0 }} className="bx--label">{title}</label>
    <p style={{ whiteSpace: 'pre-wrap' }}>{children}</p>
</div>)

const ContentView = ({ darkMode, selectedRow, getRowData, reportName, allColumns }) => {
    const rowData = useMemo(() => getRowData(selectedRow), [selectedRow])
    const attachments = useMemo(() => getAttachments(rowData), [rowData])
    const report = useMemo(() => getReport(reportName), [reportName])
    const customTab = report.sideViewCustomTab;
    if (!rowData) {
        return;
    }

    // console.log('attachments', attachments)
    return (
        <Tabs>
            {/* <Tab label="System Log"> */}
            {customTab && <Tab label={customTab.name} style={{ background: darkMode ? 'white' : undefined, color: darkMode ? 'black' : undefined }}>
                <div style={{ height: 'calc(100% - 40px)', width: '100%', overflow: 'auto', padding: '1rem', background: 'white', color: 'black' }}>
                    <customTab.component item={rowData} darkMode={darkMode} />
                </div>
            </Tab>}
            <Tab label="Item" style={{ background: darkMode ? 'white' : undefined, color: darkMode ? 'black' : undefined }}>
                <div style={{ height: 'calc(100% - 40px)', width: '100%', overflow: 'auto', padding: '1rem' }}>
                    <ItemDetailView reportName={reportName} rowData={rowData} allColumns={allColumns} />
                </div>
            </Tab>
            {attachments.map(attachment => (
                <Tab key={attachment.itemId} label={attachment.title}>
                    <div style={{ height: 'calc(100% - 40px)', width: '100%', overflow: 'auto', }}>
                        {renderAttachmentView(attachment)}
                    </div>
                </Tab>
            ))}
            {/* <Tab label="Bill">
                <div style={{ height: 'calc(100% - 40px)', width: '100%', overflow: 'auto', }}>
                    <BillPreview billId={50700} />
                </div>
            </Tab> */}
        </Tabs>
    )
}


const ItemDetailView = ({ reportName, rowData, allColumns }) => {
    const groups = useMemo(() => [...new Set(allColumns.map(column => column.group))].map(group => ({
        group, columns: allColumns.filter(column => column.group == group)
    })), [allColumns])
    if (!rowData) {
        return;
    }

    return (<div style={{ width: '100%', }}>
        <OpenBtn reportName={reportName} item={rowData} />

        {groups.map(({ group, columns }, index) => (<>
            <Header skipMargin={index === 0}>{group}</Header>
            {columns.map(column => <Value title={column.title}>{formatTypeValue(column.type, rowData[column.id])}</Value>)}
        </>))}
    </div>)
}

export function SideView({ reportName, width, rows, getRowData, allColumns, darkMode }) {
    const [selectedRow, setSelectedRow] = useState(-1);
    useEffect(() => {
        if (!rows.includes(selectedRow)) {
            setSelectedRow(rows?.[0] ?? -1)
        }
    }, [rows, selectedRow])
    if (selectedRow == -1) {
        return null;
    }
    return (
        <Panel darkMode={darkMode} width={width}>
            <div style={{ width: '100%', padding: '0.25rem', background: darkMode ? 'black' : 'white', color: darkMode ? 'white' : 'black', borderBottom: '1px solid #00000015', position: 'sticky', top: 0 }}>
                {rows.map(row => <Tag
                    key={"grid-row-side-tab-" + row}
                    type={row == selectedRow ? "high-contrast" : undefined}
                    onClick={row != selectedRow ? (() => setSelectedRow(row)) : undefined}>
                    No {row + 1}
                </Tag>)}
            </div>
            <div style={{ flex: 1, minHeight: 0 }}>
                <ContentView key={"report-side-view-content-" + selectedRow}
                    reportName={reportName} darkMode={darkMode}
                    selectedRow={selectedRow} getRowData={getRowData} allColumns={allColumns} />
            </div>

            {/* <div style={{ width: '100%', padding: '0.25rem', background: '#f0f0f0', borderTop: '1px solid #00000010' }}>
                <Tag type="blue">System Log</Tag>
                <Tag type="magenta">Customer</Tag>
                <Tag type="red">Object</Tag>
            </div>
            <div style={{ padding: '0.25rem' }}>

            </div> */}
        </Panel>
    )
}