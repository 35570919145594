import { ComboBox, ComposedModal, DataTableSkeleton, InlineLoading, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import Button from "../../components/Button"
import Table from "../../components/data-table/Table";
import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../../constants/Constants";
import { getInvoiceDocumentStatusDetails, InvoiceDocumentStatusIndicator, INVOICE_DOCUMENT_STATUS_NOT_PAID, INVOICE_DOCUMENT_STATUS_PAID, INVOICE_DOCUMENT_STATUS_REVOKED } from "../../domain/invoice-document";
import { getStockRequestStatusDetails, StockRequestStatusIndicator } from "../../domain/stock-request";
import useStore from "../../hooks/useStore";
import Api from "../../session/Api";
import { makeObservable } from "../../util/makeObservable";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import { Document16, DocumentAdd16, Migrate16, Add16 } from '@carbon/icons-react'
import { getObjectTypeName, OBJECT_TYPE_VENUE } from "../../constants/ObjectTypes";
import useApi from "../../hooks/useApi";
import Portal from "../../util/Portal";
import { PurchaseStatusIndicator, PURCHASE_STATUS_NOT_PAID, PURCHASE_STATUS_PAID, PURCHASE_STATUS_PARTIALLY_PAID, PURCHASE_STATUS_REVOKED, getPurchaseStatusDetails } from "../../domain/purchase";



const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)

const StatusTab = ({ selectedStatus, onSelect, status }) => {
    if (status == -1) {
        return <TabItem key={status + '-tab-item-purchase'} title={"All"} icon={Migrate16} selected={selectedStatus == status} onClick={() => onSelect(status)} />
    }
    let { icon, text } = getPurchaseStatusDetails(status);
    return <TabItem key={status + '-tab-item-purchase'} icon={icon} title={text} selected={selectedStatus == status} onClick={() => onSelect(status)} />
}

const Tabs = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    return (
        <>
            <StatusTab status={-1} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={PURCHASE_STATUS_NOT_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={PURCHASE_STATUS_PARTIALLY_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={PURCHASE_STATUS_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            {/* <StatusTab status={PURCHASE_STATUS_REVOKED} selectedStatus={statusFilter} onSelect={setStatusFilter} /> */}

            <div style={{ flex: 1 }} />
        </>
    )
}


const COLUMNS = [
    {
        id: "id",
        name: "ID",
        render: item => Util.getVoucherNumber(item.id)
    },
    {
        id: "supplierName",
        name: "Supplier",
        render: item => item.supplierName
    },
    {
        id: "dateCreated",
        name: "Date Created",
        render: item => Util.getDate(item.initiationDate)
    },
    {
        id: "amount",
        name: "Amount",
        render: item => item.amount.toFixed(2)
    },
    {
        id: "amountPaid",
        name: "Amount Paid",
        render: item => item.amountPaid.toFixed(2)
    },
    {
        id: "status",
        name: "Status",
        render: item => <PurchaseStatusIndicator key={item.id + "-table-status-indicator-purchase"} status={item.status} />
    }
]
const NewBtn = () => {
    const history = useHistory()
    return <Button onClick={() => history.push("/purchase-creator")} renderIcon={Add16}>Create Purchase</Button>
}

const List = ({ tabs, status }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setLoading(true)
        Api.getPurchases(0, status, response => {
            if (response.status === true) {
                setRows(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [])

    return (
        <Table
            hasFooter
            title="Purchases" description="Manage purchases"
            rows={rows} columns={COLUMNS} onClick={id => history.push("/purchase/" + id)}
            rightContent={<NewBtn />}>
            {tabs}
            {loading && <InlineLoading style={{ marginLeft: '1rem' }} />}
        </Table>
    )
}

const Content = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    const tabs = <Tabs store={store} />;
    return (
        <List
            key={'table-list-purchases-documents-' + statusFilter}
            tabs={tabs}
            status={statusFilter}
        />
    )
}

export default ({ }) => {
    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("statusFilter", -1)
        return observable;
    }, [])


    return (
        <div className="main-content">
            <Content store={store} />
        </div>
    )
}