

import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_PRINTER } from "../../constants/ObjectTypes";
import ItemTemplate from '../../templates/ItemTemplate';
import { withLoadablePageWithParams } from '../../base/Page';


const OBJECT_TYPE = OBJECT_TYPE_PRINTER;


const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
            })}
        </div>
    )
})

export const PrinterDetailPage = Page;
