import { RestaurantFine24, ShoppingBag24, Delivery24 } from '@carbon/icons-react'

const styles = {
    selected: { borderRadius: 7, background: '#ffffff10', color: '#0f62fe', border: '1px solid #ffffff20' },
    notSelected: { cursor: 'pointer', background: 'transparent', border: '1px solid transparent' },
}


export function SetDineInTakeOut({ mode, setMode }) {
    return (
        <div style={{ width: '100%', display: 'flex', paddingBlock: '0.5rem', paddingInline: '0rem', color: 'white', }}>
            <div onClick={() => setMode("dine-in")} style={{ flex: 1, display: 'flex', height: 45, justifyContent: 'center', alignItems: 'center', transition: '250ms', gap: '0.25rem', ...(mode === "dine-in" ? styles.selected : styles.notSelected) }}>
                <RestaurantFine24 />
                <h4 style={{ fontWeight: 'bold' }}>Dine-in</h4>
            </div>
            <div onClick={() => setMode("take-out")} style={{ flex: 1, display: 'flex', height: 45, justifyContent: 'center', alignItems: 'center', transition: '250ms', gap: '0.25rem', ...(mode === "take-out" ? styles.selected : styles.notSelected) }}>
                <ShoppingBag24 />
                <h4 style={{ fontWeight: 'bold' }}>Take-out</h4>
            </div>
            <div onClick={() => setMode("delivery")} style={{ flex: 1, display: 'flex', height: 45, justifyContent: 'center', alignItems: 'center', transition: '250ms', gap: '0.25rem', ...(mode === "delivery" ? styles.selected : styles.notSelected) }}>
                <Delivery24 />
                <h4 style={{ fontWeight: 'bold' }}>Delivery</h4>
            </div>
        </div>
    )
}