import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_COURSE_ENROLMENT, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_STUDENT } from '../../../../constants/ObjectTypes';
import { withLoadablePage, withLoadablePageWithParams } from '../../../../base/Page';
import ItemTemplate from '../../../../templates/ItemTemplate';
import Api from '../../../../session/Api';
import { getAccount } from '../../../../session/SessionManager';

const OBJECT_TYPE = OBJECT_TYPE_GUARDIAN;

const Page = withLoadablePage(listener => Api.getItem(OBJECT_TYPE, getAccount().guardianId, listener), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                customDetailSubtitle: "My Profile",
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                hideTabs: true,
                hideBack: true,
                noDelete: true,
                customTabs: [
                ],
                filterProperties: ["firstName", "lastName", "email", "password", "mobileNumber", "phoneNumber"]
            })}
        </div>
    )
})

export const GuardianProfilePage = Page;
