import { ComboBox, TextInput } from "carbon-components-react";
import { useMemo } from "react"
import Util from "../../../util/Util";

const FUNCTIONS = ['Summation', 'Average', 'Max Value', 'Min Value', 'Standard Deviation', 'Variance']

function funcLabel(name) {
    switch (name) {
        case 'sum': return 'Summation';
        case 'avg': return 'Average';
        case 'max': return 'Max Value';
        case 'min': return 'Min Value';
        case 'std': return 'Standard Deviation';
        case 'variance': return 'Variance';
        default: return "Err";
    }
}

function funcName(label) {
    switch (label) {
        case 'Summation': return 'sum';
        case 'Average': return 'avg';
        case 'Max Value': return 'max';
        case 'Min Value': return 'min';
        case 'Standard Deviation': return 'std';
        case 'Variance': return 'variance';
        default: return "sum";
    }
}


export function FormulaEditor({ values, formula, onFormulaChange }) {
    const func = useMemo(() => Util.isStringExists(formula) ? funcLabel(formula.substring(formula.indexOf('.') + 1, formula.indexOf('('))) : null, [formula])
    const value = useMemo(() => Util.isStringExists(formula) && !formula.includes("null") ? formula.substring(formula.indexOf('(') + 1, formula.indexOf(')')) : null, [formula])

    const setFunc = func => {
        if (Util.isStringExists(func)) {
            onFormulaChange(`math.${funcName(func)}(${value ?? values[0]})`)
        } else {
            onFormulaChange("")
        }
    }

    const setValue = value => {
        if (Util.isStringExists(value)) {
            onFormulaChange(`math.${funcName(func)}(${value ?? values[0]})`)
        } else {
            onFormulaChange("")
        }
    }

    return (<>
        <div style={{ display: 'flex', gap: '0.15rem', marginTop: '1rem' }}>
            <div style={{ flex: 1 }}>
                <ComboBox style={{ width: '100%' }} size="sm" titleText="Function" items={FUNCTIONS} selectedItem={func} onChange={e => setFunc(e.selectedItem)} />
            </div>
            <div style={{ flex: 1 }}>
                <ComboBox style={{ width: '100%' }} size="sm" titleText="Value" items={values} selectedItem={value} onChange={e => setValue(e.selectedItem)} />
            </div>
        </div>
    </>)
}