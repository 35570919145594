import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD, OBJECT_TYPE_SALES_ORDER_STATE, OBJECT_TYPE_SLIDE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER } from "../../../constants/ObjectTypes";
import TableTemplate from '../../../templates/TableTemplate';

class SlidesListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Advertisements",
                    subTitle: "Advertisements",
                    objectType: OBJECT_TYPE_SLIDE,
                    pagePath: "/slides/",
                    history: this.props.history
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(SlidesListPage);