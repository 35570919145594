import Theme from "../Theme";

class OriginalTheme extends Theme {

    getName() {
        return "original";
    }

    getColor() {
        return "#000000";
    }

    getClassName() {
        return "theme-original"
    }

}

export default OriginalTheme;