import { useEffect, useState } from "react"
import { Tag16, RowDelete16 } from '@carbon/icons-react'
import { ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput, ClickableTile, ButtonSet, ContentSwitcher, Switch, ComboBox, Tooltip, TooltipIcon, Checkbox, TextArea } from 'carbon-components-react';
import Button from "../../../components/Button";
import { SidePanel } from "../../../templates/draft/components/side-panel";
import usePosSession from "../state/usePosSession";
import useStore from "../../../hooks/useStore";
import Util from "../../../util/Util";
import { POS_SESSION_DISCOUNT_TYPE_COUPON, POS_SESSION_DISCOUNT_TYPE_FREE_FORM, POS_SESSION_DISCOUNT_TYPE_LOYALTY } from "../../../constants/Constants";
import UIUtil from "../../../util/UIUtil";

function PosFreeFormDialog({ visible, onClose, onSubmit }) {
    const [percMode, setPercMode] = useState(false);
    const [value, setValue] = useState('');


    useEffect(() => {
        if (visible) {
            setValue('')
            setPercMode(false);
        }
    }, [visible])

    return (
        <ComposedModal key="apply-free-form-dialog" size="sm" open={visible} onClose={onClose}>
            <ModalHeader label="Discounting" title={"Apply Discount"} />
            <ModalBody>
                <ContentSwitcher style={{ marginBottom: '1rem' }}
                    selectedIndex={percMode ? 1 : 0} onChange={({ index }) => setPercMode(index == 1)}>
                    <Switch text="Amount" />
                    <Switch text="Percentage" />
                </ContentSwitcher>

                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '1rem' }}>
                    <TextInput
                        onKeyDown={e => {
                            if (e.key == "Tab") {
                                e.preventDefault();
                            }
                        }}

                        data-modal-primary-focus
                        labelText={percMode ? "Percentage" : "Amount"}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </div>

            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={!Util.isANumber(value)}
                    onClick={() => onSubmit(percMode, value)}>
                    Confirm
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}

const DiscountItem = ({ discount, onDelete, editable }) => {
    let title;
    if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
        title = "Loyalty Points";
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_COUPON) {
        title = "Coupon" + (Util.isStringExists(discount.couponCode) ? " (" + discount.couponCode + ")" : "");
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_FREE_FORM) {
        title = "Free-form Discount"
    }

    let desc;
    if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
        desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_COUPON) {
        if (discount.subtractingAmount !== undefined && discount.subtractingAmount !== null) {
            desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
        } else if (discount.maxSubtractingPercentageAmount && parseFloat(parseFloat(discount.maxSubtractingPercentageAmount).toFixed(2)) > 0) {
            desc = "Subtracting " + discount.subtractingPercentage + "% (max: " + discount.maxSubtractingPercentageAmount + ")";
        } else {
            desc = "Subtracting " + discount.subtractingPercentage + "%";
        }
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_FREE_FORM) {
        if (discount.subtractingAmount !== undefined && discount.subtractingAmount !== null) {
            desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
        } else {
            desc = "Subtracting " + discount.subtractingPercentage + "%";
        }
    }


    const [deleting, setDeleting] = useState(false);
    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            setDeleting(true);
            onDelete(discount, () => {
                setDeleting(false)
            })
        })
    }


    return (
        <div style={{
            width: '100%', padding: '0.5rem', display: 'flex', background: 'white', color: true ? 'green' : 'rgba(0,0,0,0.50)', border: '1px solid #00000010', marginBottom: '0.5rem',
            boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
            borderRadius: 5
        }}>
            <div style={{ flex: 1 }}>
                <h5 style={{ fontSize: 14 }}>{title}</h5>
                <p style={{ fontSize: 12 }}>{desc}</p>
            </div>
            {editable &&
                <Button style={{ height: 48 }} onClick={onDeleteBtn} loading={deleting} hasIconOnly data-tip="Remove" renderIcon={RowDelete16} className="bx--tooltip--hidden" kind="danger--tertiary" />}
        </div>
    )
}

export function Discounts({ sessionStore, editable }) {
    const session = usePosSession(sessionStore)
    const [visible, setVisible] = useState(false);
    const [discounts] = useStore(sessionStore, "discounts")

    const onSubmit = (percMode, value) => {
        setVisible(false)
        session.addDiscount(value, percMode, () => { })
    }

    const onDelete = (discount, onFail) => {
        // console.log(discount)
        session.removeDiscount(discount.id, onFail);
    }


    return <>

        <div className="bx--structured-list-th dashboard-card" style={{ display: 'flex', paddingLeft: '1rem', borderBottom: '1px solid #e0e0e0', background: '#f4f4f4', }}>
            <div style={{ flex: 3 }}>
                Discounts
            </div>
            {/* <div style={{ flex: 2 }}>
                Mode
            </div>
            <div style={{ flex: 1 }}>
                Amount
            </div> */}
        </div>

        <div style={{ padding: '1rem' }}>
            {discounts.map(discount => <DiscountItem key={discount.id} discount={discount} onDelete={onDelete} editable={editable} />)}
            {discounts.length === 0 && <p style={{ opacity: 0.65, fontSize: 12 }}>No discounts applied</p>}
        </div>

        {editable && <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button loading={session.loading} style={{ maxWidth: 'unset', marginRight: '1rem', }} onClick={() => setVisible(true)} renderIcon={Tag16} kind="secondary" size="md">
                    Add Discount
                </Button>
            </div>

            <PosFreeFormDialog visible={visible} onClose={() => setVisible(false)} onSubmit={onSubmit} />
        </>}

    </>
}