import { useState } from "react";
import { Edit16 } from '@carbon/icons-react'
import Button from "../../../../components/Button";
import UIUtil from "../../../../util/UIUtil";
import Api from "../../../../session/Api";


export function ModifyOrderNote({ order, updateOrder }) {
    const [loading, setLoading] = useState(false);

    const onBtn = async () => {
        setLoading(true);
        try {
            const note = await UIUtil.inputPrompt({
                title: "Modify Order Note",
                label: "Note",
                initialValue: order.restOrderNote,
                textArea: true,
                positiveAction: "Save",
            })
            if (note === null || note === undefined) {
                return;
            }

            if (!note) {
                const confirmed = await UIUtil.confirmPrompt({ message: "Note is blank, are you sure you want to save?" })
                if (!confirmed) {
                    return;
                }
            }

            const [success, response] = await Api.try((api, listener) => api.setSalesOrderNote(order.id, note, listener));
            if (!success) {
                return;
            }


            updateOrder(response);
        } finally {
            setLoading(false);
        }
    }

    return <>

        <Button loading={loading} style={{ maxWidth: 'unset' }} onClick={onBtn} renderIcon={Edit16} kind="secondary" size="md">Modify Note</Button>

    </>
}