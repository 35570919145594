import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";

export const AccountTree = {
    filter: Filter,
    isTreeSructure: true,

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
