
export const makeObservable = () => {
    let globalListeners = []
    let listeners = {}
    let values = {}

    const toObject = () => ({ ...values })

    const get = key => values[key]
    const set = (key, value) => {
        if (key === "students") {
            // console.log("S", value)
            // console.trace()
        }

        if (values[key] !== value) {
            values[key] = value;

            const keyListeners = listeners[key]
            if (keyListeners) {
                keyListeners.forEach(l => l(value))
            }

            globalListeners.forEach(l => l(value))
        }
    }

    const clear = () => {
        for (const key in values) {
            set(key, undefined)
        }
    }

    const setObject = (object) => {
        for (const property in object) {
            set(property, object[property])
        }
    }

    const unsubscribe = (key, listener) => {
        const keyListeners = listeners[key]
        if (keyListeners) {
            listeners[key] = keyListeners.filter(l => l !== listener);
        }
    }

    const subscribe = (key, listener) => {
        let keyListeners = listeners[key]
        if (!keyListeners) {
            keyListeners = []
            listeners[key] = keyListeners
        }

        keyListeners.push(listener);
        return () => unsubscribe(key, listener)
    }

    const unsubscribeGlobal = (listener) => {
        globalListeners = globalListeners.filter(l => l !== listener);
    }

    const subscribeGlobal = (listener) => {
        globalListeners.push(listener);
        return () => unsubscribeGlobal(listener)
    }

    return { get, set, subscribe, unsubscribe, setObject, toObject, clear, unsubscribeGlobal, subscribeGlobal }
}