import { useMemo, useState } from "react";
import Button from "../../../../components/Button";
import { Add16, Reset16, CheckmarkFilled16, Draggable16 } from '@carbon/icons-react'
import { withLoadablePageAndProps, withLoadablePagePropsAndProps } from "../../../../base/Page";
import Api from "../../../../session/Api";
import UIUtil from "../../../../util/UIUtil";
import Util, { big } from "../../../../util/Util";
import { makeDraggable } from "../../../../hooks/useDraggable";
import { Section } from "./section";
import { isEqual } from "lodash";
import { fixIdForApi } from "../../../../templates/form/form-api";

const Header = ({ onResetValuesBtn, changesMade, saveChanges }) => {
    const [saving, setSaving] = useState(false)
    const onSaveBtn = () => {
        setSaving(true);
        saveChanges(() => setSaving(false))
    }
    return (
        <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, width: '100%', paddingInline: '3rem', paddingTop: '5rem', paddingBottom: '2rem', color: 'white', background: '#1c1c1c' }}>
            <h2>Menu Designer</h2>
            <div style={{ flex: 1 }} />

            {changesMade && <>
                <Button onClick={onResetValuesBtn} renderIcon={Reset16} kind="secondary" size="sm" style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>Reset values</Button>
                <div style={{ width: 1 }} />
                <Button onClick={onSaveBtn} loading={saving} renderIcon={CheckmarkFilled16} size="sm" style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}>Save changes</Button>
            </>}
        </div>
    )
}

const Footer = ({ sections, onNewSectionBtn }) => (
    <div style={{ width: '100%', background: '#1c1c1c', color: 'white', borderRadius: 10, overflow: 'hidden', border: '1px solid #ffffff10', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0.5rem' }}>
        <p style={{ fontSize: 14, flex: 1, paddingInline: '1rem' }}>{sections.length + ' section' + (sections.length !== 1 ? 's' : '') + ' in total'}</p>
        <Button onClick={onNewSectionBtn} renderIcon={Add16} size="sm" style={{ borderRadius: 7 }}>New Section</Button>
    </div>
)

const draggable = makeDraggable(Section, () => (
    <div style={{ cursor: 'grab', }}>
        <Draggable16 />
    </div>
));

function View({ payload: { endpoint, ...payload }, item }) {
    const [originalSections, setOriginalSections] = useState(payload.item ?? []);
    const [sections, setSections] = useState(payload.item ?? []);

    const changesMade = useMemo(() => !isEqual(originalSections, sections), [originalSections, sections])

    const onNewSectionBtn = async () => {
        // const name = await UIUtil.inputPrompt({
        //     title: "New Section",
        //     label: "Section Name",
        //     positiveAction: "Create Section"
        // })
        // if (!name) {
        //     return;
        // }

        setSections(prev => [...prev, {
            id: Util.newTempId(),
            name: '',
            maxSelection: 1,
            options: []
        }])
    }

    const sectionProps = (section) => ({
        section,
        foodItems: endpoint.foodItems,

        onRemoveBtn: () => setSections(prev => prev.filter(other => section.id !== other.id)),

        setSectionState(prop, value) {
            setSections(prev => prev.map(other => other.id === section.id ? ({
                ...other, [prop]: typeof value === 'function' ? value(other[prop]) : value
            }) : other))
        }
    })

    // TODO :: Validations
    //  - No duplicate items in sections
    //  - Max selection must be a positive number >= 1
    //  - Names required in sections
    //
    //  Remember to fix id for api


    const onSaveBtn = (callback) => {
        if (!sections.find($ => Util.isStringExists($.name))) {
            UIUtil.showInfo("Name is required for all sections");
            callback();
            return;
        }

        if (!sections.every($ => Number.isInteger(parseFloat($.maxSelection)) && parseInt($.maxSelection) > 0)) {
            UIUtil.showInfo("Max selections must be a positive whole number ( > 0 )");
            callback();
            return;
        }

        Api.saveFnbMenuSections(item.id, fixIdForApi(sections), response => {
            if (response.status === true) {
                setOriginalSections(response.payload)
                setSections(response.payload)

                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            callback();
        })
    }

    return (
        <div>
            <Header changesMade={changesMade} onResetValuesBtn={() => setSections(originalSections)} saveChanges={onSaveBtn} />

            <draggable.SortContainer onSortEnd={draggable.createOnSortEnd(sections, setSections)}>
                {sections.map((section, index) => <draggable.SortItem key={section.id} index={index} {...sectionProps(section)} />)}
            </draggable.SortContainer>

            <Footer sections={sections} onNewSectionBtn={onNewSectionBtn} />
        </div>
    )
}

export const MenuDesigner = withLoadablePagePropsAndProps(props => listener => Api.getFnbMenuSections(props.item.id, true, listener), View)