import { Tag } from "carbon-components-react";
import Button from "../../../../components/Button";
import { ConditionPoint16, CheckmarkFilledWarning16 } from '@carbon/icons-react'
import Util from "../../../../util/Util";
import { SetActivityStatusDialog } from "../../dialogs/set-activity-status";
import { useEffect, useState } from "react";
import { ActivityStatusDetailsDialog } from "../../dialogs/activity-status-details";

const statusName = item => item.statusId > 0 ? item.statusName : "Open";
export const statusSummaryUserId = item => {
    if (item.statusId > 0 && item.latestStatusRecord) {
        return item.latestStatusRecord.userId;
    } else {
        return item.userId;
    }
}
export const statusSummary = item => {
    if (item.statusId > 0 && item.latestStatusRecord) {
        return `Status set on ${Util.getDate(item.latestStatusRecord.dateCreated)} by ${item.latestStatusRecord.userFullName}`
    } else {
        return `Activity created on ${Util.getDate(item.dateCreated)} by ${item.creatorFullName}`
    }
}

const StatusTag = ({ color, name, skipMarginLeft }) => {
    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

    return (
        <Tag
            style={{
                color: isLightColor(color ?? "#000000") ? "black" : "white",
                background: color ?? "#000000",
                minWidth: 75,
                maxWidth: 'unset',
                // marginLeft: !skipMarginLeft ? '-1.5rem' : undefined
            }}>{name}</Tag>
    )
}

const UpdateStatusBtn = ({ item, visible, setVisible, overrideUpdateSuccess, replicateRequest }) => {
    // const [visible, setVisible] = useState(false);
    return (<>
        <Button onClick={() => setVisible(true)} renderIcon={ConditionPoint16} style={{ borderRadius: 10, height: 'calc(100% - 1rem)' }} size="sm">Update Status</Button>
        <SetActivityStatusDialog overrideSuccess={overrideUpdateSuccess} item={item} open={visible} onClose={() => setVisible(false)} replicateRequest={replicateRequest} />
    </>)
}

const StatusDetailsBtn = ({ item, visible, setVisible, onReplicate }) => {
    // const [visible, setVisible] = useState(false);
    return (<>
        <Button onClick={() => setVisible(true)} kind="secondary" renderIcon={CheckmarkFilledWarning16} style={{ borderRadius: 10, marginRight: '0.25rem', height: 'calc(100% - 1rem)' }} size="sm">Status Details</Button>
        <ActivityStatusDetailsDialog item={item} open={visible} onClose={() => setVisible(false)} onReplicate={onReplicate} />
    </>)
}

const Buttons = ({ item, overrideUpdateSuccess }) => {
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);

    const [replicateRequest, setReplicateRequest] = useState(false);

    const onReplicate = () => {
        setDetailsVisible(false)
        setReplicateRequest(true)
        setUpdateVisible(true);
    }

    useEffect(() => {
        if (!updateVisible) {
            setReplicateRequest(false);
        }
    }, [updateVisible])

    return (<>
        {item.statusId > 0 &&
            <StatusDetailsBtn item={item} visible={detailsVisible} setVisible={setDetailsVisible} onReplicate={onReplicate} />}
        <UpdateStatusBtn overrideUpdateSuccess={overrideUpdateSuccess} item={item} visible={updateVisible} setVisible={setUpdateVisible} replicateRequest={replicateRequest} />
    </>)
}

export const ActivityStatusTag = ({ item }) => <StatusTag name={statusName(item)} color={item.statusColor} skipMarginLeft />;

export function StatusView({ item, overrideUpdateSuccess, readonly }) {
    return (<>
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem', gap: '0.5rem' }}>
                <label style={{ flex: 1, color: 'black', fontWeight: 'bold', margin: 0 }} className="bx--label">Status</label>
                <p style={{ opacity: 0.65, fontSize: 12 }}>{statusSummary(item)}</p>
            </div>
            <div style={{ background: '#E0E0E0', borderRadius: 10, display: 'flex', alignItems: 'center', paddingInline: '0.5rem', paddingBlock: '0.5rem', minHeight: 60 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginRight: '4.5rem' }}>
                    {/* <label style={{ color: 'black', margin: 0, fontWeight: 'bold' }} className="bx--label">Status</label> */}
                    {/* <p style={{ opacity: 0.65, fontSize: 12 }}>Set on 12:23 by Alex Wales</p> */}
                    <StatusTag name={statusName(item)} color={item.statusColor} />
                </div>
                <div style={{ flex: 1 }} />
                {!readonly && <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                    <Buttons item={item} overrideUpdateSuccess={overrideUpdateSuccess} />
                </div>}
            </div>
        </div>
    </>)
}