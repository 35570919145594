import React from "react";
import { DEF_ORDER_STATE, OrderState } from "../../pos/restaurant/engine/OrderState";
import { Kitchen } from "../../pos/restaurant/pages/kitchen";



export class KitchenPage extends React.Component {

    state = {
        ...DEF_ORDER_STATE()
    }
    orderState = new OrderState(this.setState.bind(this), () => this.state, this.props.mainApp);

    componentDidMount() {
        this.orderState.start();
    }

    componentWillUnmount() {
        this.orderState.stop();
    }

    render() {
        return (
            // repeated IN POS
            <div key={this.orderState.refreshKey} style={{ height: 'calc(100vh - 3rem)', width: '100vw' }}>
                <Kitchen
                    state={this.orderState} orders={this.orderState.getFilteredOrdersForKitchen()}
                    updateOrder={this.orderState.updateOrder.bind(this.orderState)}
                    removeOrder={this.orderState.removeOrder.bind(this.orderState)}
                    mainApp={this.props.mainApp} />
            </div>
        )
    }
}