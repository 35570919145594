import { useMemo } from "react";
import { SALES_CART_WORK_STATUS_ONGOING, SALES_CART_WORK_STATUS_QUOTATION, SALES_CART_WORK_STATUS_SALES_ORDER, SALES_CART_WORK_STATUS_EXPIRED, SALES_CART_WORK_STATUS_SALES, } from "../../../../constants/Constants";
import {
    ShoppingCart16,
    ShoppingCatalog16, //quotation
    ShoppingCartPlus16, //sales
    SubtractAlt16, //expired
    Connect16, //ongoing

    ShoppingCart32,
    ShoppingCatalog32, //quotation
    ShoppingCartPlus32, //sales
    SubtractAlt32, //expired
    Connect32, //ongoing
} from '@carbon/icons-react'
import { Tag } from "carbon-components-react";

const getWorkStatusInfo = status => {
    switch (status) {
        case SALES_CART_WORK_STATUS_SALES:
            return { icon: ShoppingCartPlus16, title: "Sales", color: '#00551a' }
        case SALES_CART_WORK_STATUS_QUOTATION:
            return { icon: ShoppingCatalog16, title: "Quotation", color: '#990000' }
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            // return { icon: ShoppingCart16, title: "Sales Order", color: '#997d00' }
            return { icon: ShoppingCart16, title: "Proforma Invoice", color: '#997d00' }
        case SALES_CART_WORK_STATUS_EXPIRED:
            //return { icon: SubtractAlt16, title: "Expired Quotation", color: '#000000' }
            return { icon: SubtractAlt16, title: "Expired", color: '#000000' }
        case SALES_CART_WORK_STATUS_ONGOING:
            return { icon: Connect16, title: "Ongoing Work", color: "#0f62fe" }
    }

    return null;
}

export const getWorkStatusInfoLg = status => {
    switch (status) {
        case SALES_CART_WORK_STATUS_SALES:
            return { icon: ShoppingCartPlus32, title: "Sales", color: '#00551a' }
        case SALES_CART_WORK_STATUS_QUOTATION:
            return { icon: ShoppingCatalog32, title: "Quotation", color: '#990000' }
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            // return { icon: ShoppingCart16, title: "Sales Order", color: '#997d00' }
            return { icon: ShoppingCart32, title: "Proforma Invoice", color: '#997d00' }
        case SALES_CART_WORK_STATUS_EXPIRED:
            //return { icon: SubtractAlt16, title: "Expired Quotation", color: '#000000' }
            return { icon: SubtractAlt32, title: "Expired", color: '#000000' }
        case SALES_CART_WORK_STATUS_ONGOING:
            return { icon: Connect32, title: "Ongoing Work", color: "#0f62fe" }
    }

    return null;
}

export function WorkStatusTag({ status }) {
    const info = useMemo(() => getWorkStatusInfo(status), [status])
    return info ? <Tag renderIcon={info.icon} type="high-contrast" style={{ background: info.color, }}>{info.title}</Tag> : <div />
}