import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { withLoadablePage, withLoadablePageWithParams } from '../../base/Page';
import Button from '../../components/Button';
import ImageView from '../../components/ImageView';
import { OBJECT_TYPE_MOVIE } from '../../constants/ObjectTypes';
import Api from '../../session/Api';
import './cinema-demo.scss'
import { ShowtimeMovieList } from './movie-showtime-list/showtime-movie-list';
import { ShowtimeMovieListDayPicker } from './movie-showtime-list/showtime-movie-list-day';


import { ArrowLeft32, Ticket24, Calendar24, CheckmarkFilled16, Theater24, Receipt20 } from '@carbon/icons-react'
import Logo from '../../images/star-cinema.png'
import { big } from "../../util/Util";



function View({ payload: { cinemas, movie } }) {
    const [selectedCinema, setSelectedCinema] = useState(cinemas[0])
    const [day, setDay] = useState(0);
    useEffect(() => { setDay(0) }, [selectedCinema])

    const history = useHistory();
    const { movieId } = useParams();

    return (
        <div style={{ minHeight: '100vh', background: '#0f0f0f' }}>
            <div style={{ width: '100%', height: '6rem', background: '#1b1c3c', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ffffff10' }}>
                <div onClick={history.goBack} className="cinema-demo-header-button">
                    <ArrowLeft32 />
                </div>
                <div style={{ flex: 1 }} />
                <Link to="/cinema-web">
                    <img src={Logo} style={{ height: 75 }} />
                </Link>
                <div style={{ flex: 1 }} />
                <div style={{ width: '6rem' }}></div>
            </div>
            <div style={{ width: '100%', height: '3rem', background: '#11122b', paddingInline: '6rem', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <Calendar24 />
                <h4 style={{ flex: 1 }}>Showtimes</h4>
            </div>

            <div style={{ marginTop: '3rem', display: 'flex', paddingInline: '6rem', gap: '1rem', flexWrap: 'wrap' }}>
                <ShowtimeMovieListDayPicker day={day} setDay={setDay} />
                <ShowtimeMovieList cinemaId={selectedCinema?.id ?? 0} movieId={movieId} day={day} />
            </div>

            <div style={{ height: '3rem' }} />
        </div>
    )

    // return (
    //     <div style={{ padding: '6rem' }}>
    //         <Button onClick={history.goBack}>back</Button>
    //         <h1>{movie.displayName}</h1>
    //         <ReactSelect
    //             defaultValue={cinemas[0]}
    //             isClearable={false}
    //             isSearchable
    //             name="Cinema"

    //             value={selectedCinema}
    //             onChange={setSelectedCinema}
    //             options={cinemas.map(item => ({ ...item, label: item.value }))}
    //         />

    //         <ShowtimeMovieListDayPicker day={day} setDay={setDay} />
    //         <ShowtimeMovieList cinemaId={selectedCinema?.id ?? 0} movieId={movieId} day={day} />
    //     </div>
    // )
}


export const CinemaDemoMoviePage = withLoadablePageWithParams(params => listener => Api.demo_getShowtimeEndpoints(params.movieId, listener), View);