import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
            //width: 150
            flex: 1
    },
    label: {
        width: 150
    }
    
  });


  const InvoiceNo = ({invoice}) => (
        <Fragment style={{marginTop: 36}}>
            {invoice.details && invoice.details.map(detail => (
                <View style={styles.invoiceNoContainer}>
                    <Text style={styles.label}>{detail.title}: </Text>
                    <Text style={styles.invoiceDate}>{detail.value}</Text>
                </View >
            ))}
            {/* <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Receipt ID:</Text>
                <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Date:</Text>
                <Text >{invoice.trans_date}</Text>
            </View > */}
        </Fragment>
  );
  
  export default InvoiceNo