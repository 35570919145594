
import {
    Archive16, ArrowLeft16
} from '@carbon/icons-react'
import ReactTooltip from 'react-tooltip'
import { NewBtn } from './components/new-btn'


export function Header({ children, onBackBtn, onArchiveBtn }) {
    return (
        <div style={{ background: '#f4f4f4', gap: '0.5rem', paddingInline: '1rem', height: '3rem', width: '100%', borderBottom: '1px solid #00000040', display: 'flex', alignItems: 'center' }}>
            {onBackBtn && <button style={{ animation: 'advanced-notes-button-fade-in 250ms' }} onClick={onBackBtn} className='lead-flow-icon-button'>
                <ArrowLeft16 />
            </button>}
            <h4 style={{ animation: 'advanced-notes-button-fade-in 350ms' }}>{children}</h4>
            <div style={{ flex: 1 }} />
            {onArchiveBtn && <button onClick={onArchiveBtn} className='lead-flow-icon-button' data-tip="Archive" data-effect="solid" data-place="bottom">
                <Archive16 />
            </button>}
            {/* <NewBtn /> */}
            {/* <button onClick={() => { }} className='lead-flow-icon-button lead-flow-icon-button-primary' data-tip="New Item" data-effect="solid" data-place="bottom">
                <AddFilled16 />
            </button> */}
            {/* <ReactTooltip /> */}
        </div>
    )
}