

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

import Logo from '../../../images/other/rivas/logo.png';

import QRCode from "react-qr-code"
import Util from "../../../util/Util"
import { paymentMethodTextWithArabic } from "../../../pages/transaction/TransactionEditor"
import { THERMAL_RECEIPT_LOGO, VAT } from '../../../app/Config';
import { POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';
import { REST_ORDER_TYPE, restOrderTypeName, restOrderTypeNameWithDate } from '../../../domain/rest-sales-order';
import { addressToString } from '../../../pages/sales-order/base/sales-order';
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { BillPrintRivas } from './bill-print-rivas';


const Line = () => <br />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>

const Text = ({ bold, italic, size, center, right, underline, children }) => <p style={{
    fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
    textDecoration: underline ? "underline" : undefined,
    fontStyle: italic ? "italic" : undefined,
    whiteSpace: 'pre-wrap'
}}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={20}>{children}</Text>
const Italic = ({ children }) => <Text italic size={20}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={20}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={20}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={20}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={20}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={20}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={20}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={20}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={20}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={20}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>


function subtitle(order) {
    if (order.type === REST_ORDER_TYPE.DINE_IN) {
        return 'Table ' + order.tableName
    } else if (order.type === REST_ORDER_TYPE.TAKE_OUT) {
        if (Util.isStringExists(order.memo)) {
            return order.memo
        } else {
            //return 'no memo'
            return ""
        }
    } else if (order.type === REST_ORDER_TYPE.DELIVERY || order.type === REST_ORDER_TYPE.PICK_UP) {
        if (order.shippingAddress && (Util.isStringExists(order.shippingAddress.firstName) || Util.isStringExists(order.shippingAddress.lastName))) {
            const fullName = [order.shippingAddress.firstName, order.shippingAddress.lastName].filter(Util.isStringExists).join(" ");
            return fullName;
        } else {
            //return "no name";
            return ""
        }
    } else {
        return null;
    }
}


export const BillPrint = ({ order, request }) => {
    return <BillPrintRivas order={order} request={request} />

    const couponCodes = request.discounts.filter($ => $.type === POS_SESSION_DISCOUNT_TYPE_COUPON).map($ => $.couponCode);
    const cartItems = request.currentCartItems?.filter($ => !$.flagRemovedItemRecord).map($ => $.flagDeliveryFee ? ({ ...$, name: "Delivery Fee" }) : $);

    // order.shippingAddress.phoneNumber = "0509462048";
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            <Line />
            <Line />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={Logo} style={{ width: 268, height: 108, objectPosition: 'center', objectFit: 'contain', }} />
            </div>

            <Line />
            <Line />

            <BoldCenterNormal>{request.__company_name}</BoldCenterNormal>
            <CenterNormal>{request.receiptInfo.usrsys_address}</CenterNormal>
            <CenterNormal>Tel: {request.receiptInfo.usrsys_phone}</CenterNormal>
            <CenterNormal>TRN: {request.receiptInfo.usrsys_trn}</CenterNormal>

            <Line />

            <CenterNormal>Tax Invoice</CenterNormal>
            <CenterBold>فاتورة ضريبيه</CenterBold>

            <Line />
            <Line />
            <Line />

            {(order.type == REST_ORDER_TYPE.DELIVERY || order.type == REST_ORDER_TYPE.PICK_UP) ? (
                <Bold>{restOrderTypeNameWithDate(order)}</Bold>
            ) : (
                <Bold>{weightText(restOrderTypeName(order.type), 8, true) + " " + weightText(subtitle(order), 33, false)}</Bold>
            )}

            <div style={{ marginLeft: 25 }}>
                <Bold>{Util.isStringExists(request.customerName) ? request.customerName : "Guest"},</Bold>
                {Util.isStringExists(order?.shippingAddress?.phoneNumber) && <Bold>{order.shippingAddress.phoneNumber}</Bold>}
                {order?.shippingAddress && <Bold>{addressToString(order.shippingAddress)}</Bold>}
            </div>

            <Divider />
            <Normal>{"#   " + weightText("Description", 24, true) + " " + weightText("Qty", 4, false) + " " + weightText("Amount", 8, false)}</Normal>
            <ArabicBold>{"    " + weightText("المبلغ", 8, true) + " " + weightText("العدد", 5, false) + " " + weightText("الوصف", 23, false)}</ArabicBold>
            <Divider />

            {cartItems.map((item, index) => (<>
                {/* {index > 0 && <>
                    <Line />
                    <Line />
                </>} */}


                <Normal>
                    {weightText(index + 1, 3, true) +
                        " " +
                        weightText(item.name.substring(0, 24), 24, true) +
                        " " +
                        weightText(item.quantityValue + "x", 4, false) +
                        " " +
                        weightText(item.totalAmount.price.toFixed(2).replaceAll("0", "O"), 8, false)}
                </Normal>

                {item.name.length > 24 ? (<>
                    <Normal>{"    " + weightText(item.name.substring(24), 24, true)}</Normal>
                </>) : (
                    // <Normal>{"    " + weightText(item.name, 24, true)}</Normal>
                    null
                )}
            </>))}

            <Line />
            <Line />
            <Divider />
            <div style={{ display: "flex", alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Normal>Total Items: {request.currentCartItems.length}</Normal>
                </div>
                <div style={{ flex: 1 }}>
                    <ArabicBold>{request.currentCartItems.length} :مجموع الاغراض</ArabicBold>
                </div>
            </div>
            <Divider />
            <Line />
            <Line />


            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>Subtotal</Bold>
                <div style={{ flex: 1 }}>
                    <ArabicBold>المبلغ اجمالي</ArabicBold>
                    <RightNormal>AED {request.posTotals.subtotal.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            {request.posTotals.discount !== undefined && request.posTotals.discount !== null && request.posTotals.discount > 0 && (<>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Bold>Discount</Bold>
                    <div style={{ flex: 1 }}>
                        <ArabicBold>تخفيض</ArabicBold>
                        <RightNormal>AED {request.posTotals.discount.toFixed(2).replaceAll("0", "O")}</RightNormal>
                    </div>
                </div>
                {couponCodes.map($ => (<>
                    <div style={{ marginLeft: 25 }}>
                        <Italic>Redeemed Code: {$}</Italic>
                    </div>
                </>))}
                <Line />
            </>)}

            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Bold>VAT {`${VAT.PERC}%`}</Bold>
                <div style={{ flex: 1 }}>
                    <ArabicBold>ضريبة</ArabicBold>
                    <RightNormal>AED {request.posTotals.tax.toFixed(2).replaceAll("0", "O")}</RightNormal>
                </div>
            </div>
            <Line />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DoubleBold>Grand Total</DoubleBold>
                <div style={{ flex: 1 }}>
                    <ArabicBold>المجموع الاجمالي</ArabicBold>
                </div>
            </div>
            <DoubleRightNormal>AED {request.posTotals.total.toFixed(2).replaceAll("0", "O")}</DoubleRightNormal>
            <Line />


            <Line />
            <Line />

            {request.posTotals.totalSavings > 0 && <>
                <CenterNormal>You have saved AED {request.posTotals.totalSavings}!</CenterNormal>
                <CenterBold>AED {request.posTotals.totalSavings} لقد وفرت</CenterBold>
            </>}

            {(request.paymentMethods?.length ?? 0) > 0 && <>
                <Line />
                <Line />
                <Line />
                <Line />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Underline>Payment</Underline>
                    <div style={{ flex: 1 }}>
                        <ArabicBold>الدفع</ArabicBold>
                    </div>
                </div>
                {request.paymentMethods.map(item => (
                    <Normal>
                        {
                            weightText(paymentMethodTextWithArabic(item.methodType), 28, true) +
                            " " +
                            weightText(item.amount.toFixed ? item.amount.toFixed(2) : parseFloat(item.amount).toFixed(2), 13, false)
                        }
                    </Normal>
                ))}
            </>}

            <Line />
            <Line />
            <Line />

            <CenterNormal>Thank you for dining with us!</CenterNormal>
            {/* {trans} */}
            <CenterBold>شكرا لك على تناول الطعام معنا</CenterBold>
            <CenterNormal>{Util.getFullDate(new Date().getTime())}</CenterNormal>


            {Util.isStringExists(request.receiptInfo.usrsys_terms_and_conditions) && <>
                <Line />
                <Line />
                <Line />
                <Line />

                <Underline>Terms & Conditions</Underline>
                <Normal>{request.receiptInfo.usrsys_terms_and_conditions}</Normal>
                <Line />
                <ArabicBold>{request.receiptInfo.__ar_usrsys_terms_and_conditions}</ArabicBold>
            </>}

            <Line />
            <Line />
            <Line />
            <Line />

            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <QRCode size={200} value={"https://rivas.ae"} />
            </div> */}

            <Line />
            <Line />
            <Line />
            <Line />
        </div>
    )

}

function weightText(text, weight, leftAligned) {
    if (text == null) {
        text = "";
    }

    if (!text || !text.substring) {
        text = text + "";
    }

    if (text.length > weight) {
        text = text.substring(0, weight - 3) + "...";
    } else {
        const textLength = text.length;
        for (let i = 0; i < (weight - textLength); i++) {
            if (leftAligned) {
                text += ' ';
            } else {
                text = " " + text;
            }
        }
    }
    return text;
}