import React, { useEffect, useRef, useState } from 'react'
import Page from '../../base/Page'

import { Screen32, Tablet32, Cafe16, Store32, Building32, Chip20, Location32, AirlinePassengerCare16, ShoppingBag20, Currency16, ErrorFilled16, CheckmarkFilled16 } from '@carbon/icons-react'
import { ClickableTile, ComposedModal, ContentSwitcher, InlineLoading, ModalBody, ModalFooter, ModalHeader, NumberInput, Switch, TextInput, Tile, } from 'carbon-components-react'
import ProfilePic from '../../components/ProfilePic'
import Api from '../../session/Api'
import SocketSession, { SOCKET_EVENT_MONITORING, SOCKET_EVENT_SALES_MONITORING } from '../../session/SocketSession'
import { OBJECT_TYPE_ACCOUNT } from '../../constants/ObjectTypes'
import Util from '../../util/Util'
import AdminMonitoringView from './AdminMonitoringView'
import { PosInfoView } from '../pos/view/PosInfoView'
import UIUtil from '../../util/UIUtil'

import Confetti from 'react-canvas-confetti';

import { toast } from 'react-toastify';
import TotalSalesChart from './charts/TotalSalesChart'
import SalesDivisionChart from './charts/SalesDivisionChart'
import Button from '../../components/Button'
import { hasCapabilitySupport } from '../../app/Capabilities'
import { POS_SESSION_TYPE_MOBILE_UNIT, POS_SESSION_TYPE_ONLINE_STORE, POS_SESSION_TYPE_POCKET_POS, POS_SESSION_TYPE_TERMINAL, SESSION_TYPE_THIRD_PARTY } from '../../constants/Constants'
import MapMonitoringView from './map/MapMonitoringView'
import { Link } from 'react-router-dom'

const TerminalMonitorPreview = ({ terminal, onCreateNotification, nonClickable, checkoutFlagRequest, breakStartFlagRequest, breakEndFlagRequest, cashOpenedFlagRequest, cashClosedFlagRequest, onRemove, onClick }) => {
    const [startConfettiFlag, setStartConfettiFlag] = useState(false);

    const [highlight, setHighlight] = useState(false);
    const [checkoutHighlight, setCheckoutHighlight] = useState(false);
    const [breakStartHighlight, setBreakStartHighlight] = useState(false);
    const [breakEndHighlight, setBreakEndHighlight] = useState(false);
    const [cashOpenedHighlight, setCashOpenedHighlight] = useState(false);
    const [cashClosedHighlight, setCashClosedHighlight] = useState(false);

    const [infoKey, setInfoKey] = useState(Util.newTempId());
    const [infoHighlight, setInfoHighlight] = useState(false);

    const customerName = Util.isStringExists(terminal.customerName) ? terminal.customerName : "Guest";
    const totalItems = terminal.totalItems + " items";
    const totalAmount = terminal.totals.currency + " " + terminal.totals.total.toFixed(2);
    const onBreak = terminal.onBreak;

    const currency = terminal.currency;
    const checkoutAmount = terminal.checkoutAmount ? terminal.checkoutAmount : 0;
    const lastBreakEndDate = terminal.lastBreakEndDate;
    const lastBreakStartDate = terminal.lastBreakStartDate;
    const startingAmount = terminal.startingAmount ? terminal.startingAmount : 0;
    const endingAmount = terminal.endingAmount ? terminal.endingAmount : 0;

    const onlineStoreAmount = (terminal?.totals?.total) ?? 0;

    const isOnlineStoreCart = terminal.type == POS_SESSION_TYPE_ONLINE_STORE;
    const hasCustomer = Util.isNumberExist(terminal.customerId);

    useEffect(() => {
        if (highlight) {
            setHighlight(undefined)
            setCheckoutHighlight(undefined)
        }
        setInfoKey(Util.newTempId())
        setInfoHighlight(true)
    }, [customerName, totalItems, totalAmount, onBreak])



    useEffect(() => {
        if (checkoutFlagRequest) {
            if (checkoutAmount >= 500) {
                onCreateNotification(`${customerName} spent AED ${checkoutAmount.toFixed(2)}!`)
                setStartConfettiFlag(true);
            }
            setHighlight(true);
            setCheckoutHighlight(true)
            setBreakStartHighlight(undefined)
            setBreakEndHighlight(undefined)
            setCashOpenedHighlight(undefined)
            setCashClosedHighlight(undefined)
            setTimeout(() => {
                setHighlight(undefined)
                setCheckoutHighlight(undefined)
                setStartConfettiFlag(undefined)
            }, checkoutAmount >= 500 ? 5000 : 2500)
        }

        if (breakStartFlagRequest) {
            setHighlight(true);
            setCheckoutHighlight(undefined)
            setBreakStartHighlight(true)
            setBreakEndHighlight(undefined)
            setCashOpenedHighlight(undefined)
            setCashClosedHighlight(undefined)
            setTimeout(() => {
                setHighlight(undefined)
                setBreakStartHighlight(undefined)
            }, 2500)
        }

        if (breakEndFlagRequest) {
            setHighlight(true);
            setCheckoutHighlight(undefined)
            setBreakStartHighlight(undefined)
            setBreakEndHighlight(true)
            setCashOpenedHighlight(undefined)
            setCashClosedHighlight(undefined)
            setTimeout(() => {
                setHighlight(undefined)
                setBreakEndHighlight(undefined)
            }, 2500)
        }

        if (cashOpenedFlagRequest) {
            setTimeout(() => {
                setHighlight(true);
                setCheckoutHighlight(undefined)
                setBreakStartHighlight(undefined)
                setBreakEndHighlight(undefined)
                setCashOpenedHighlight(true)
                setCashClosedHighlight(undefined)
                setTimeout(() => {
                    setHighlight(undefined)
                    setCashOpenedHighlight(undefined)
                }, 2500)
            }, 15)
        }

        if (cashClosedFlagRequest) {
            if (isOnlineStoreCart) {
                if (onlineStoreAmount >= 500) {
                    onCreateNotification(`${customerName} spent AED ${onlineStoreAmount.toFixed(2)}!`)
                    setStartConfettiFlag(true);
                }
                setHighlight(true);
                setCheckoutHighlight(true)
                setBreakStartHighlight(undefined)
                setBreakEndHighlight(undefined)
                setCashOpenedHighlight(undefined)
                setCashClosedHighlight(undefined)
                setTimeout(() => {
                    setHighlight(undefined)
                    setCheckoutHighlight(undefined)
                    setStartConfettiFlag(undefined)

                    onRemove()
                    //}, totalAmount >= 500 ? 5000 : 2500)
                }, 2500)
                // setTimeout(() => {
                //     onRemove()
                // }, 2500)
            } else {
                setHighlight(true);
                setCheckoutHighlight(undefined)
                setBreakStartHighlight(undefined)
                setBreakEndHighlight(undefined)
                setCashOpenedHighlight(undefined)
                setCashClosedHighlight(true)
                setTimeout(() => {
                    onRemove()
                }, 2500)
            }
        }
    }, [checkoutFlagRequest, breakStartFlagRequest, breakEndFlagRequest, cashOpenedFlagRequest, cashClosedFlagRequest])



    return (
        <Tile onClick={(e) => {

            onClick(e)
            // setStartConfettiFlag(true)
        }}
            // handleClick={() => {
            //     // alert("hi")
            //     setStartConfettiFlag(Util.newTempId())
            //     // confettiRef.current();
            // }}
            style={{
                cursor: 'pointer',
                width: 325, height: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                transition: '250ms', background: highlight ? (checkoutHighlight ? 'green' : 'black') : '#f4f4f4', transform: highlight ? 'scale(1.05)' : 'scale(1)',
                ...((nonClickable || onBreak) ? { opacity: highlight ? 1 : 0.75, pointerEvents: 'none' } : {})
            }}>

            <ProfilePic size={90} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, terminal.operatorId)} />
            <h4 style={{ marginTop: '0.5rem', color: highlight ? 'white' : 'inherit' }}>{terminal.operatorFullName}</h4>
            <p style={{ fontSize: 14, color: highlight ? 'white' : 'inherit' }}>{terminal.terminalName}</p>

            {(highlight && startConfettiFlag) ?
                <div style={{ width: '100%', padding: '0.5rem', marginTop: '1rem', backgroundColor: '#f4f4f4', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 14, }}>{customerName} spent</p>
                    <h4 style={{ fontWeight: 'bold', color: 'green', }}>AED {(isOnlineStoreCart ? onlineStoreAmount : checkoutAmount).toFixed(2)}!</h4>
                </div> : (<>
                    <div className="monitoring-preview-value" key={infoKey} style={{
                        display: 'flex',
                        animation: infoHighlight ? 'monitoring-preview-value-anim 750ms' : '',
                        alignItems: 'center', marginTop: '3rem'
                    }}>
                        {highlight ? (
                            checkoutHighlight ? (<>
                                <Currency16 style={{ color: 'white', fill: 'white' }} />
                                <h6 style={{ fontSize: 14, color: 'white', margin: 0, marginBottom: 2, marginLeft: '0.5rem' }}>Made a sale of</h6>
                                <p style={{ fontSize: 12, marginLeft: '0.25rem', opacity: 0.65, color: 'white' }}>{currency} {(isOnlineStoreCart ? onlineStoreAmount : checkoutAmount).toFixed(2)}</p>
                            </>) : (
                                breakStartHighlight ? (<>
                                    <Cafe16 style={{ color: 'white', fill: 'white' }} />
                                    <h6 style={{ fontSize: 14, color: 'white', margin: 0, marginBottom: 2, marginLeft: '0.5rem' }}>Started break</h6>
                                    <p style={{ fontSize: 12, marginLeft: '0.25rem', opacity: 0.65, color: 'white' }}>{
                                        lastBreakEndDate > 0 ? <> Last break was {Util.formatSecondsPretty((new Date().getTime() - lastBreakEndDate) / 1000)} ago </> :
                                            <>First break</>
                                    }</p>
                                </>) : (
                                    breakEndHighlight ? (<>
                                        <Cafe16 style={{ color: 'white', fill: 'white' }} />
                                        <h6 style={{ fontSize: 14, color: 'white', margin: 0, marginBottom: 2, marginLeft: '0.5rem' }}>Left break</h6>
                                        <p style={{ fontSize: 12, marginLeft: '0.25rem', opacity: 0.65, color: 'white' }}>Lasted for {Util.formatSecondsPretty((new Date().getTime() - lastBreakStartDate) / 1000)}</p>
                                    </>) : (
                                        cashClosedHighlight ? (<>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ErrorFilled16 style={{ color: 'white', fill: 'white' }} />
                                                    <h6 style={{ fontSize: 14, color: 'white', margin: 0, marginBottom: 2, marginLeft: '0.25rem' }}>
                                                        {terminal.type === POS_SESSION_TYPE_TERMINAL ? 'Cash closed' :
                                                            terminal.id == terminal.operatorId ? 'Logged out' :
                                                                'Session Ended'}
                                                        {/* {terminal.id == terminal.operatorId ? 'Logged out' : 'Cash closed'} */}
                                                    </h6>
                                                </div>
                                                {//terminal.id != terminal.operatorId &&
                                                    terminal.type === POS_SESSION_TYPE_TERMINAL &&
                                                    <p style={{ fontSize: 12, marginLeft: '0.5rem', opacity: 0.65, color: 'white', }}>Closing amount: {currency} {endingAmount.toFixed(2)}</p>}
                                            </div>
                                        </>) : (
                                            cashOpenedHighlight ? (<>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <CheckmarkFilled16 style={{ color: 'white', fill: 'white' }} />
                                                        <h6 style={{ fontSize: 14, color: 'white', margin: 0, marginBottom: 2, marginLeft: '0.25rem' }}>
                                                            {terminal.type === POS_SESSION_TYPE_TERMINAL ? 'Cash opened' :
                                                                terminal.id == terminal.operatorId ? 'Logged in' :
                                                                    isOnlineStoreCart ? 'Started Cart' :
                                                                        'Session Started'}
                                                            {/* {isOnlineStoreCart ? 'Started Cart' : terminal.id == terminal.operatorId ? 'Logged in' : 'Cash opened'} */}
                                                        </h6>
                                                    </div>
                                                    {//terminal.id != terminal.operatorId && !isOnlineStoreCart &&
                                                        terminal.type === POS_SESSION_TYPE_TERMINAL &&
                                                        <p style={{ fontSize: 12, marginLeft: '0.5rem', opacity: 0.65, color: 'white', }}>Starting amount: {currency} {startingAmount.toFixed(2)}</p>}
                                                </div>
                                            </>) : (
                                                null
                                            )
                                        )
                                    )
                                )
                            )
                        ) : (
                            onBreak ? <>
                                <Cafe16 /> <p style={{ marginLeft: 5, fontSize: 12, color: highlight ? 'white' : 'inherit' }}>On break from {Util.getDate(lastBreakStartDate)}</p>
                            </> : <>
                                <p style={{ fontSize: 12, color: highlight ? 'white' : 'inherit' }}>{customerName}</p>
                                <div style={{ width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: highlight ? 'white' : 'black', opacity: 1 }} />
                                <p style={{ fontSize: 12, color: highlight ? 'white' : 'inherit' }}>{totalItems}</p>
                                <div style={{ width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: highlight ? 'white' : 'black', opacity: 1 }} />
                                <p style={{ fontSize: terminal.totals.total >= 500 ? 16 : 12, fontWeight: terminal.totals.total >= 500 ? 'bold' : undefined, color: highlight ? 'white' : 'green' }}>{totalAmount}</p>
                            </>
                        )}
                    </div>

                    {!highlight && hasCustomer &&
                        <Link to={"/customers/" + terminal.customerId} target="_blank" style={{ textDecoration: 'none' }} onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopPropagation();

                            // e.preventDefault();
                            // e.nativeEvent.preventDefault();
                            // setStartConfettiFlag(true)
                        }}>
                            <Button kind="secondary" size="sm" style={{ marginTop: '1.5rem', }} renderIcon={AirlinePassengerCare16}>View Customer</Button>
                        </Link>}
                </>)}



            {<Confetti
                particleCount={100}
                origin={{ y: 0.75 }}
                // set the styles as for a usual react component
                style={{ width: '100%', height: '100%', position: 'fixed', pointerEvents: 'none' }}
                // set the class name as for a usual react component
                //className={'yourClassName'}
                // set the callback for getting instance. The callback will be called after initialization ReactCanvasConfetti component
                fire={startConfettiFlag}
            // fire
            />}
        </Tile>
    )
}

class MonitoringPage extends Page {

    notificationAudio = new Audio("notification.wav");
    usedSalesEvent = []

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            terminals: [],

            showViewingDialog: false,
            viewingTerminalId: undefined,

            posInfoSessionState: undefined,

            salesReport: {},

            customerLocations: [],
            idsThatUpdated: [],
        }
    }

    getViewingTerminal() {
        for (const terminal of this.state.terminals) {
            if (terminal.id == this.state.viewingTerminalId) {
                return terminal;
            }
        }
        return undefined;
    }

    componentDidMount() {
        super.componentDidMount();

        this.onMonitoringEventListener = ((event) => {
            {
                const terminalUpdate = {
                    ...event.terminalMonitoring,

                    checkoutFlag: event.checkoutFlag ? Util.newTempId() : undefined,
                    breakStartFlag: event.breakStartFlag ? Util.newTempId() : undefined,
                    breakEndFlag: event.breakEndFlag ? Util.newTempId() : undefined,
                    cashOpenedFlag: event.cashOpenedFlag ? Util.newTempId() : undefined,
                    cashClosedFlag: event.cashClosedFlag ? Util.newTempId() : undefined,
                };

                const terminals = this.state.terminals;
                let prevTerminal = undefined;
                let prevTerminalIndex = 0;

                for (let i = 0; i < terminals.length; i++) {
                    const terminal = terminals[i];
                    if (terminal.id == terminalUpdate.id) {
                        prevTerminal = terminal;
                        prevTerminalIndex = i;
                        break;
                    }
                }

                if (prevTerminal && prevTerminal.totals && prevTerminal.totals.total < 500 && terminalUpdate.totals.total >= 500) {
                    const customerName = Util.isStringExists(terminalUpdate.customerName) ? terminalUpdate.customerName : "Guest";
                    this.onCreateNotification(`${customerName} is spending AED ${terminalUpdate.totals.total.toFixed(2)}!`)
                }

                try {
                    this.setState(prevState => ({ idsThatUpdated: [...prevState.idsThatUpdated, terminalUpdate.id] }))
                } catch (e) { }
            }

            this.setState(prevState => {
                const terminalUpdate = {
                    ...event.terminalMonitoring,

                    checkoutFlag: event.checkoutFlag ? Util.newTempId() : undefined,
                    breakStartFlag: event.breakStartFlag ? Util.newTempId() : undefined,
                    breakEndFlag: event.breakEndFlag ? Util.newTempId() : undefined,
                    cashOpenedFlag: event.cashOpenedFlag ? Util.newTempId() : undefined,
                    cashClosedFlag: event.cashClosedFlag ? Util.newTempId() : undefined,
                };

                let terminals = [...prevState.terminals];
                let prevTerminal = undefined;
                let prevTerminalIndex = 0;

                for (let i = 0; i < terminals.length; i++) {
                    const terminal = terminals[i];
                    if (terminal.id == terminalUpdate.id) {
                        prevTerminal = terminal;
                        prevTerminalIndex = i;
                        break;
                    }
                }

                let removeViewingTerminal = false;
                if (this.state.viewingTerminalId == terminalUpdate.id) {
                    if (terminalUpdate.breakStartFlag || terminalUpdate.cashClosedFlag) {
                        removeViewingTerminal = true;
                    }
                }

                if (prevTerminal !== undefined) {
                    terminals[prevTerminalIndex] = terminalUpdate;
                } else {
                    terminals.unshift(terminalUpdate);
                }

                if (removeViewingTerminal) {
                    return { terminals, showViewingDialog: false, viewingTerminalId: undefined };
                } else {
                    return { terminals };
                }
            })
        }).bind(this);
        this.onSalesMonitoringEventListener = (({ stockFlowId, amount, hour, cashierName, salespersonName }) => {
            if (this.usedSalesEvent.includes(stockFlowId)) {
                return;
            }
            this.usedSalesEvent.push(stockFlowId);

            this.setState(({ salesReport }) => {
                const update = {
                    //CHECK SET STATE ON LOAD
                    totalSales: salesReport.totalSales + amount,
                    hours: [...salesReport.hours],
                    hoursSales: [...salesReport.hoursSales],
                    salespeople: [...salesReport.salespeople],
                    salespeopleSales: [...salesReport.salespeopleSales],
                    cashiers: [...salesReport.cashiers],
                    cashierSales: [...salesReport.cashierSales],
                }

                if (cashierName) {
                    if (salesReport.cashiers.includes(cashierName)) {
                        update.cashierSales[salesReport.cashiers.indexOf(cashierName)] += amount;
                    } else {
                        update.cashiers.push(cashierName);
                        update.cashierSales.push(amount);
                    }
                }

                if (salespersonName) {
                    if (salesReport.salespeople.includes(salespersonName)) {
                        update.salespeopleSales[salesReport.salespeople.indexOf(salespersonName)] += amount;
                    } else {
                        update.salespeople.push(salespersonName);
                        update.salespeopleSales.push(amount);
                    }
                }

                if (salesReport.hours.includes(hour)) {
                    update.hoursSales[salesReport.hours.indexOf(hour)] += amount;
                } else {
                    update.hours.push(hour);
                    update.hoursSales.push(amount);
                }

                return {
                    salesReport: update
                }
            })
        });

        SocketSession.registerListener(SOCKET_EVENT_MONITORING, this.onMonitoringEventListener);
        SocketSession.registerListener(SOCKET_EVENT_SALES_MONITORING, this.onSalesMonitoringEventListener);
    }

    onPageStart() {
        //getAllActiveMonitoring
        SocketSession.subscribe(SOCKET_EVENT_MONITORING, subscription => {
            this.socketSubscription = subscription;
            setTimeout(() => {
                SocketSession.subscribe(SOCKET_EVENT_SALES_MONITORING, salesSubscription => {
                    this.salesSubscription = salesSubscription;
                    this.callPageApi(listener => Api.getAllActiveMonitoring(listener), payload => ({
                        terminals: payload.terminals,
                        salesReport: {
                            //CHECK UPDATE IN THE SALES EVENT
                            totalSales: payload.totalSales,
                            hours: payload.hours,
                            hoursSales: payload.hoursSales,
                            salespeople: payload.salespeople,
                            salespeopleSales: payload.salespeopleSales,
                            cashiers: payload.cashiers,
                            cashierSales: payload.cashierSales,
                        },
                        customerLocations: payload.customerLocations
                    }))
                })
            })
        })
    }

    componentWillUnmount() {
        SocketSession.unregisterListener(SOCKET_EVENT_MONITORING, this.onMonitoringEventListener);
        SocketSession.unregisterListener(SOCKET_EVENT_SALES_MONITORING, this.onSalesMonitoringEventListener);

        if (this.socketSubscription !== undefined) {
            this.socketSubscription.unsubscribe();
            this.socketSubscription = undefined;
        }

        if (this.salesSubscription !== undefined) {
            this.salesSubscription.unsubscribe();
            this.salesSubscription = undefined;
        }
    }

    renderMessage(message) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>{message}</p>
            </div>
        )
    }

    onCreateNotification(message) {
        toast(message, {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onOpen: () => {
                try {
                    this.notificationAudio.pause();
                    this.notificationAudio.currentTime = 0;
                    this.notificationAudio.play().catch(() => { })
                } catch (e) { }
            }
        });
    }

    getTerminals() {
        //return this.state.terminals.filter(terminal => terminal.operatorId != terminal.id);
        return this.state.terminals.filter(terminal => terminal.type == POS_SESSION_TYPE_TERMINAL);
    }

    getThirdPartyStores() {
        return this.state.terminals.filter(terminal => terminal.type == SESSION_TYPE_THIRD_PARTY);
    }

    getMobileTerminals() {
        //return this.state.terminals.filter(terminal => terminal.operatorId == terminal.id);
        //return this.state.terminals.filter(terminal => terminal.operatorId == terminal.id);
        return this.state.terminals.filter(terminal => terminal.type == POS_SESSION_TYPE_POCKET_POS);
    }

    getOnlineStoreCarts() {
        //let idsToRemove = [139, 148, 206, 240, 389].filter(id => !this.state.idsThatUpdated.includes(id));
        let idsToRemove = [];

        return this.state.terminals.filter(terminal => terminal.type == POS_SESSION_TYPE_ONLINE_STORE && !idsToRemove.includes(terminal.id));
    }

    getMobileUnits() {
        return this.state.terminals.filter(terminal => terminal.type == POS_SESSION_TYPE_MOBILE_UNIT)
        // .concat([
        //     { id: 333333, locationEvents: [], operatorFullName: "Mohamm f jfwe jkfewjhfewjk fwkhjfew kjwhef",
        //     lastAddress: "Few ufihew", terminalName: "Fewfwefewfewfwefwe" }
        // ]);
    }

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '0rem', paddingBottom: '6rem' }}>
                {/* <div style={{width: '75vw'}}> */}
                <div style={{ width: '95vw' }}>
                    {/* <div style={{display: 'flex', alignItems: 'center', gap: '0.25rem'}}>
                        <Chip20 />
                        <h4>Monitoring</h4>
                    </div> */}

                    <div style={{ marginTop: false ? '6rem' : '1rem' }}>
                        {/* <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                            <ShoppingBag20 />
                            <h4 style={{color: 'black', fontSize: 16, marginLeft: '0.5rem'}}>Sales</h4>
                        </div> */}

                        <div style={{ width: '100%', display: 'flex', gap: '0.5rem' }}>
                            <div style={{ flex: 4, alignSelf: 'stretch' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', paddingRight: '1rem' }}>
                                    <Chip20 />
                                    <h4 style={{ flex: 1 }}>Monitoring</h4>

                                    <ShoppingBag20 />
                                    <h4 style={{ color: 'black', fontSize: 16, marginRight: '0.5rem' }}>Sales:</h4>

                                    <h2 key={'sales-' + this.state.salesReport.totalSales}
                                        style={{
                                            color: 'rgba(255, 99, 132, 1)', display: 'inline-block',
                                            animation: 'monitoring-sales-value-anim 750ms'
                                        }}>AED {this.state.salesReport.totalSales.toFixed(2)}</h2>
                                </div>

                                <TotalSalesChart report={this.state.salesReport} />
                            </div>
                            <div style={{ flex: 2, alignSelf: 'stretch', display: 'flex' }}>
                                <SalesDivisionChart report={this.state.salesReport} />
                            </div>
                        </div>
                    </div>


                    {hasCapabilitySupport("mobileUnit") && <div style={{ marginTop: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                            <Location32 />
                            <h4 style={{ color: 'black', fontSize: 16, marginLeft: '0.5rem' }}>Mobile Units</h4>
                        </div>

                        <MapMonitoringView
                            customerLocations={this.state.customerLocations}
                            onMonitorBtn={item => this.setState({ showViewingDialog: true, viewingTerminalId: item.id })}
                            sessions={this.getMobileUnits()}
                            onRemove={terminal => {
                                if (terminal.cashClosedFlag) {
                                    this.setState(prevState => ({ terminals: prevState.terminals.filter(item => item != terminal) }))
                                }
                            }}

                        />
                    </div>}


                    {hasCapabilitySupport("terminalPos") && <div style={{ marginTop: true ? '1rem' : '3rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                            <Screen32 />
                            <h4 style={{ color: 'black', fontSize: 16, marginLeft: '0.5rem' }}>Terminals</h4>
                        </div>

                        {this.getTerminals().length > 0 ? (
                            <div style={{ display: 'flex', alignSelf: 'center', gap: '1rem', flexWrap: 'wrap' }}>
                                {this.getTerminals().map(terminal =>
                                    <TerminalMonitorPreview key={terminal.id + "-terminal-monitor-preview"}
                                        onCreateNotification={this.onCreateNotification.bind(this)}
                                        nonClickable={(terminal.breakStartFlag || terminal.cashClosedFlag)}
                                        onClick={() => {
                                            //if (!(terminal.breakStartFlag || terminal.cashClosedFlag)) {
                                            this.setState({ showViewingDialog: true, viewingTerminalId: terminal.id })
                                            //}
                                        }}
                                        checkoutFlagRequest={terminal.checkoutFlag}
                                        breakStartFlagRequest={terminal.breakStartFlag}
                                        breakEndFlagRequest={terminal.breakEndFlag}
                                        cashOpenedFlagRequest={terminal.cashOpenedFlag}
                                        cashClosedFlagRequest={terminal.cashClosedFlag}
                                        onRemove={() => {
                                            if (terminal.cashClosedFlag) {
                                                this.setState(prevState => ({ terminals: prevState.terminals.filter(item => item != terminal) }))
                                            }
                                        }}
                                        terminal={terminal} />)}
                            </div>
                        ) : this.renderMessage("No terminal is currently active")}
                    </div>}

                    {hasCapabilitySupport("thirdPartyPos") && <div style={{ marginTop: true ? '1rem' : '3rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                            <Building32 />
                            <h4 style={{ color: 'black', fontSize: 16, marginLeft: '0.5rem' }}>Venues</h4>
                        </div>

                        {this.getThirdPartyStores().length > 0 ? (
                            <div style={{ display: 'flex', alignSelf: 'center', gap: '1rem', flexWrap: 'wrap' }}>
                                {this.getThirdPartyStores().map(terminal =>
                                    <TerminalMonitorPreview key={terminal.id + "-terminal-monitor-preview"}
                                        onCreateNotification={this.onCreateNotification.bind(this)}
                                        nonClickable={(terminal.breakStartFlag || terminal.cashClosedFlag)}
                                        onClick={() => {
                                            //if (!(terminal.breakStartFlag || terminal.cashClosedFlag)) {
                                            this.setState({ showViewingDialog: true, viewingTerminalId: terminal.id })
                                            //}
                                        }}
                                        checkoutFlagRequest={terminal.checkoutFlag}
                                        breakStartFlagRequest={terminal.breakStartFlag}
                                        breakEndFlagRequest={terminal.breakEndFlag}
                                        cashOpenedFlagRequest={terminal.cashOpenedFlag}
                                        cashClosedFlagRequest={terminal.cashClosedFlag}
                                        onRemove={() => {
                                            if (terminal.cashClosedFlag) {
                                                this.setState(prevState => ({ terminals: prevState.terminals.filter(item => item != terminal) }))
                                            }
                                        }}
                                        terminal={terminal} />)}
                            </div>
                        ) : this.renderMessage("No terminal is currently active")}
                    </div>}

                    {hasCapabilitySupport("pocketPosApp") && <div style={{ marginTop: true ? '1rem' : '3rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                            <Tablet32 />
                            <h4 style={{ color: 'black', fontSize: 16, marginLeft: '0.5rem' }}>Mobile</h4>
                        </div>

                        {this.getMobileTerminals().length > 0 ? (
                            <div style={{ display: 'flex', alignSelf: 'center', gap: '1rem', flexWrap: 'wrap' }}>
                                {this.getMobileTerminals().map(terminal =>
                                    <TerminalMonitorPreview key={terminal.id + "-terminal-monitor-preview"}
                                        onCreateNotification={this.onCreateNotification.bind(this)}
                                        nonClickable={(terminal.breakStartFlag || terminal.cashClosedFlag)}
                                        onClick={() => {
                                            //if (!(terminal.breakStartFlag || terminal.cashClosedFlag)) {
                                            this.setState({ showViewingDialog: true, viewingTerminalId: terminal.id })
                                            //}
                                        }}
                                        checkoutFlagRequest={terminal.checkoutFlag}
                                        breakStartFlagRequest={terminal.breakStartFlag}
                                        breakEndFlagRequest={terminal.breakEndFlag}
                                        cashOpenedFlagRequest={terminal.cashOpenedFlag}
                                        cashClosedFlagRequest={terminal.cashClosedFlag}
                                        onRemove={() => {
                                            if (terminal.cashClosedFlag) {
                                                this.setState(prevState => ({ terminals: prevState.terminals.filter(item => item != terminal) }))
                                            }
                                        }}
                                        terminal={terminal} />)}
                            </div>
                        ) : this.renderMessage("No mobile sales is currently active")}

                        {/* {false ? (
                            null
                        ) : this.renderMessage("No mobile sales is currently active")} */}
                    </div>}


                    {hasCapabilitySupport("onlineStore") && <div style={{ marginTop: true ? '1rem' : '3rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                            <Tablet32 />
                            <h4 style={{ color: 'black', fontSize: 16, marginLeft: '0.5rem' }}>Online Store Cart</h4>
                        </div>

                        {this.getOnlineStoreCarts().length > 0 ? (
                            <div style={{ display: 'flex', alignSelf: 'center', gap: '1rem', flexWrap: 'wrap' }}>
                                {this.getOnlineStoreCarts().map(terminal =>
                                    <TerminalMonitorPreview key={terminal.id + "-terminal-monitor-preview"}
                                        onCreateNotification={this.onCreateNotification.bind(this)}
                                        nonClickable={(terminal.breakStartFlag || terminal.cashClosedFlag)}
                                        onClick={() => {
                                            //if (!(terminal.breakStartFlag || terminal.cashClosedFlag)) {
                                            this.setState({ showViewingDialog: true, viewingTerminalId: terminal.id })
                                            //}
                                        }}
                                        checkoutFlagRequest={terminal.checkoutFlag}
                                        breakStartFlagRequest={terminal.breakStartFlag}
                                        breakEndFlagRequest={terminal.breakEndFlag}
                                        cashOpenedFlagRequest={terminal.cashOpenedFlag}
                                        cashClosedFlagRequest={terminal.cashClosedFlag}
                                        onRemove={() => {
                                            if (terminal.cashClosedFlag) {
                                                this.setState(prevState => ({ terminals: prevState.terminals.filter(item => item != terminal) }))
                                            }
                                        }}
                                        terminal={terminal} />)}
                            </div>
                        ) : this.renderMessage("No online store cart exists")}

                        {/* {false ? (
                            null
                        ) : this.renderMessage("No mobile sales is currently active")} */}
                    </div>}
                </div>

                <ComposedModal className="full-size-dialog" open={this.state.showViewingDialog} onClose={() => this.setState({ showViewingDialog: false })}>
                    <ModalHeader label={this.state.viewingTerminalId !== undefined && this.getViewingTerminal().terminalName} title="Monitoring" />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        {this.state.viewingTerminalId !== undefined &&
                            <AdminMonitoringView terminal={this.getViewingTerminal()}
                                onStateListener={state => this.setState({ posInfoSessionState: state })} />}
                    </ModalBody>
                    <ModalFooter
                    //secondaryButtonText="Close Monitoring"
                    >
                        <Button kind="secondary" onClick={() => this.setState({ showViewingDialog: false })}>
                            Close Monitoring
                        </Button>
                    </ModalFooter>
                </ComposedModal>

                {this.state.viewingTerminalId !== undefined && this.state.posInfoSessionState !== undefined &&
                    <ComposedModal key="pos-session-info-dialog" size="sm" open={this.state.posInfoSessionState.isShowPosInfoDialog()} onClose={() => this.state.posInfoSessionState.setShowPosInfoDialog(false)}>
                        <ModalHeader label="Session" title="POS Management" />
                        <ModalBody style={{ paddingRight: '1rem' }}>
                            <PosInfoView posState={this.state.posInfoSessionState} />
                        </ModalBody>
                    </ComposedModal>}
            </div>
        )
    }

}

export default MonitoringPage
// export default () => <MapMonitoringView />