import { Ticket16, ArrowRight16 } from '@carbon/icons-react'
import ProfilePic from "../../../components/ProfilePic";
import Button from '../../../components/Button';


export function CinemaTicketCartItem({ state }) {
    const cartItem = state.getTicketCartItem();

    const onOpenBtn = () => {
        if (state.readOnly) {
            return;
        }

        state.openTicketSelector(cartItem ? {
            showtimeId: cartItem.cinemaTicketShowtimeId,
            movieId: cartItem.cinemaTicketMovieId,
            seats: cartItem.cinemaTicketSeatsList
        } : undefined)
    }

    // if (state.readOnly) {
    //     return null;
    // }
    return (
        <div onClick={!state.readOnly ? onOpenBtn : undefined} className="payment-widget" style={{ borderRadius: 5, paddingBlock: '0.25rem', marginTop: state.readOnly ? '0.5rem' : 0, minHeight: 45, gap: '0.25rem', width: '100%', display: 'flex', color: 'white', alignItems: 'center', paddingInline: '1rem' }}>
            <Ticket16 />
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Ticket</p>


            {cartItem ? (
                <p style={{ fontSize: 14, textAlign: 'center', flex: 1 }}>
                    {cartItem.name}
                </p>
            ) : (
                <p style={{ fontSize: 12, opacity: 0.65, textAlign: 'center', flex: 1 }}>
                    no seats selected
                </p>
            )}


            {!state.readOnly && <button className='lead-flow-icon-button lead-flow-icon-button-light' style={{}} onClick={onOpenBtn}>
                <ArrowRight16 />
            </button>}
        </div>
    )
}