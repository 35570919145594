
import { Draggable16, Close16, Add16 } from '@carbon/icons-react'
import Button from '../../components/Button'
import { makeDraggable } from '../../hooks/useDraggable'
import { useState } from 'react'
import Util from '../../util/Util'
import { itemState } from '.'

export const QuestionEntryDrag = () => (
    <div style={{ cursor: 'grab', }}>
        <Draggable16 />
    </div>
)

const Checkbox = ({ checked, onClick, readonly }) => (
    <div onClick={readonly ? undefined : onClick} style={{ cursor: !readonly ? "pointer" : undefined, width: 20, height: 20, borderRadius: 40, border: '1px solid #00000020', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
        <div style={{ transition: '250ms', width: '100%', height: '100%', borderRadius: 40, background: checked ? 'green' : 'transparent' }} />
    </div>
)











const OptionDrag = () => (
    <div style={{ cursor: 'grab', }}>
        <Draggable16 />
    </div>
)


const OptionEntry = ({
    SortHandler, onRemoveBtn,
    correctState: [correct, setCorrect],
    optionState: [option, setOption],

    readonly,
}) => (
    <div style={{ marginBottom: '0.5rem', display: 'flex', gap: '0.25rem', alignItems: 'center', width: '100%' }}>
        {!readonly && <SortHandler />}

        <div style={{ display: "flex", border: '1px solid #00000020', borderRadius: 7, overflow: 'hidden', flex: 1 }}>
            <div style={{ background: '#00000020', width: 36, height: 36, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Checkbox readonly={readonly} checked={correct} onClick={() => setCorrect(c => !c)} />
            </div>
            <div style={{ display: 'flex', flex: 1, padding: '0.5rem', background: 'white', }}>
                <input value={option ?? ""} onChange={e => setOption(e.target.value)} placeholder="Input option..." style={{ border: 'none', outline: 'none', width: '100%' }} />
            </div>

            {!readonly && <div style={{ background: '#00000020', width: 36, height: 36, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button onClick={onRemoveBtn} className='lead-flow-icon-button' style={{ width: 24, height: 24, padding: 6 }}>
                    <Close16 />
                </button>
            </div>}
        </div>
    </div>
)

const draggable = makeDraggable(OptionEntry, OptionDrag);

const Options = ({ value, setValue, readonly }) => {
    // const [value, setValue] = useState([])
    const onNewOptionBtn = () => {
        setValue(prev => [...prev, {
            id: Util.newTempId(),
            optionText: "",
            correct: false
        }])
    }

    const itemProps = (item) => ({
        onRemoveBtn: () => setValue(prev => prev.filter(other => item.id !== other.id)),
        optionState: itemState(value, setValue, item, "optionText"),
        correctState: itemState(value, setValue, item, "correct", other => ({ ...other, correct: false })),

        readonly,
    })

    return (<>
        <draggable.SortContainer onSortEnd={draggable.createOnSortEnd(value, setValue)}>
            {value.map((item, index) => <draggable.SortItem key={item.id} index={index} {...itemProps(item)} />)}
        </draggable.SortContainer>

        {!readonly && <Button onClick={onNewOptionBtn} size="sm" style={{ borderRadius: 7, marginLeft: 36 }} renderIcon={Add16}>Add option</Button>}
    </>)
}

export function QuestionEntry({
    SortHandler, onRemoveBtn,
    questionState: [question, setQuestion],
    optionsState: [option, setOption],

    readonly,
}) {

    return (
        <div style={{ width: '100%', background: '#f4f4f4', borderRadius: 10, overflow: 'hidden', border: '1px solid #00000020', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '1rem', marginBottom: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', marginBottom: '0.5rem' }}>
                {!readonly && <SortHandler />}
                <p style={{ fontWeight: 'bold', fontSize: 14, }}>Question Entry</p>
                <div style={{ flex: 1 }} />

                {!readonly && <button onClick={onRemoveBtn} className='lead-flow-icon-button' style={{ width: 32, height: 32 }}>
                    <Close16 />
                </button>}
            </div>
            <div style={{ display: 'flex', padding: '0.5rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000020', borderRadius: 7 }}>
                <input value={question} onChange={e => setQuestion(e.target.value)} placeholder="Input question..." style={{ border: 'none', outline: 'none', width: '100%' }} />
            </div>

            <div style={{ paddingLeft: '3rem', width: '100%' }}>
                <h6 style={{ marginBottom: '0.5rem' }}>Options</h6>
                <Options value={option} setValue={setOption} readonly={readonly} />
            </div>
        </div>
    )
}