import { DataTableSkeleton, InlineLoading } from "carbon-components-react";
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import Button from "../../components/Button"
import Table from "../../components/data-table/Table";
import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../../constants/Constants";
import { getStockRequestStatusDetails, StockRequestStatusIndicator } from "../../domain/stock-request";
import useStore from "../../hooks/useStore";
import Api from "../../session/Api";
import { makeObservable } from "../../util/makeObservable";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";



const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)

const StatusTab = ({ selectedStatus, onSelect, status }) => {
    let { icon, text } = getStockRequestStatusDetails(status);
    return <TabItem key={status + '-tab-item-stock-request'} icon={icon} title={text} selected={selectedStatus == status} onClick={() => onSelect(status)} />
}

const Tabs = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    return (
        <>
            <StatusTab status={STOCK_REQUEST_STATUS_PENDING_APPROVAL} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={STOCK_REQUEST_STATUS_APPROVED} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={STOCK_REQUEST_STATUS_REJECTED} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={STOCK_REQUEST_STATUS_REVOKED_BY_SENDER} selectedStatus={statusFilter} onSelect={setStatusFilter} />

            <div style={{ flex: 1 }} />
        </>
    )
}

const COLUMNS = [
    {
        id: "id",
        name: "ID",
        render: item => Util.getVoucherNumber(item.id)
    },
    {
        id: "destinationName",
        name: "Request By",
        render: item => item.destinationName
    },
    {
        id: "requestByFullName",
        name: "Request By User",
        render: item => item.requestByFullName
    },
    {
        id: "dateCreated",
        name: "Request Date",
        render: item => Util.getDate(item.dateCreated)
    },
    {
        id: "totalQuantity",
        name: "Total Quantity",
        render: item => item.totalQuantity + 'x'
    },
    {
        id: "itemCount",
        name: "Item Count",
        render: item => item.itemCount
    },
    {
        id: "status",
        name: "Status",
        render: item => <StockRequestStatusIndicator key={item.id + "-table-status-indicator-stock-request"} status={item.status} />
    }
]

const List = ({ tabs, status }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setLoading(true)
        Api.getAllStockRequests(status, response => {
            if (response.status === true) {
                setRows(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [])

    // if (loading) {
    //     // return (
    //     //     <DataTableSkeleton />
    //     // )
    // }

    return (
        <Table
            hasFooter
            title="Stock Requests" description="Accept/reject stock requests"
            rows={rows} columns={COLUMNS} onClick={id => history.push("/stock-requests/" + id)}>
            {tabs}
            {loading && <InlineLoading style={{ marginLeft: '1rem' }} />}
        </Table>
    )
}

const Content = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    const tabs = <Tabs store={store} />;
    return (
        <List
            key={'table-list-stock-requests-' + statusFilter}
            tabs={tabs}
            status={statusFilter}
        />
    )
}

export default ({ }) => {
    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("statusFilter", STOCK_REQUEST_STATUS_PENDING_APPROVAL)
        return observable;
    }, [])


    return (
        <div className="main-content">
            <Content store={store} />
        </div>
    )
}