import { hasCapabilitySupport } from "../../../app/Capabilities";
import { renderNode } from "../../../components/imperative-render";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { LockScroll } from "../../../hooks/useLockScroll";
import StockFlowItemPage from "../../../pages/stock-flow/StockFlowItemPage";
import { getAccountRole } from "../../../session/SessionManager"
import { SidePanel } from "../../../templates/draft/components/side-panel";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange } from "../../components/basic-filter/date-range";
import { Divider } from "../../components/basic-filter/divider";
import { MultiFreetextSelector } from "../../components/basic-filter/multi-freetext-selector";
import { MultiSelectWithId } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box";

export const LastStockAdjustReport = {
    filter: Filter,

    isAvailable: () => {
        if (hasCapabilitySupport("cargo")) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },

    onAction: (action, data, history) => {
        switch (action) {
            case "adjustVoucher":
                if (data.sfId) {
                    renderNode(onClose => (
                        <SidePanel md2 onClose={onClose}>
                            <LockScroll />
                            <StockFlowItemPage fullWidth pagePathParamsOverride={{ stockFlowId: data.sfId }} overrideBacktToListBtn={onClose} />
                        </SidePanel>
                    ))
                }
                break;
        }
    }
}

const SHOW_UNADJUSTED_FILTER = { property: "lastAdjustDate", operator: "LTE", value: 0 };

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter />
        {/* <DateComparison state={state} /> */}
        <Divider />

        <div style={{ flex: 1 }}>
            <MultiSelectWithId isMulti={false} skipAutoFilter placeholder="Location" fieldKey="locationId" options={endpoint.locations} state={state} property="locationId" />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Name" property="name" fullWidth />
        </div>
        <div style={{ flex: 2, paddingLeft: '1rem' }}>
            <CheckboxFilter state={state} filterId="laststockadjustmentshowunadjustedonly" label="Show unadjusted only" filter={SHOW_UNADJUSTED_FILTER} />
        </div>

        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            <MultiFreetextSelector state={state} property="barcodes" placeholder="Barcodes..." />
        </div>
    </>)
}
