import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_CATEGORY, OBJECT_TYPE_SERVICE_DEPARTMENT, OBJECT_TYPE_SERVICE_PACKAGE, OBJECT_TYPE_SERVICE_SUBCATEGORY } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';

const ServicesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'subcategoryId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Services",
                subTitle: "Services in subcategory",
                objectType: OBJECT_TYPE_SERVICE,
                pagePath: "/services/",
                history: history
            })}
        </div>
    )
}

const PackagesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'subcategoryId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Packages",
                subTitle: "Packages in subcategory",
                objectType: OBJECT_TYPE_SERVICE_PACKAGE,
                pagePath: "/packages/",
                history: history
            })}
        </div>
    )
}

class ServiceSubcategoryDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_SERVICE_SUBCATEGORY, listener)
            } else {
                Api.getItem(OBJECT_TYPE_SERVICE_SUBCATEGORY, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_SERVICE_SUBCATEGORY,
                    itemResult: this.state.itemResult,
                    pagePath: "/service-subcategories/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Services",
                            component: ServicesList
                        },
                        {
                            title: "Packages",
                            component: PackagesList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(ServiceSubcategoryDetailPage);