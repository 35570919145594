import React, { useEffect, useState } from 'react'

import { subDays, addDays } from 'date-fns';
import { DateRangePicker, Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


export default ({date, onChange, ...props}) => {
    const [dateState, setDate] = useState(date)
    useEffect(() => {
        onChange(dateState)
    }, [dateState])
    return (
        <Calendar {...props} onChange={date => setDate(date)} date={dateState} />
    )
}