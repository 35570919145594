import {
    CustomCell,
    CustomRenderer,
    getMiddleCenterBias,
    GridCellKind,
    interpolateColors,
    drawTextCell,
} from "@glideapps/glide-data-grid";
import { roundedRect } from ".";
import Util from "../../../../util/Util";

function unpackColor(color, theme, hoverAmount) {
    if (typeof color === "string") {
        if (theme[color] !== undefined) return theme[color];
        return color;
    }

    let [normal, hover] = color;
    if (theme[normal] !== undefined) normal = theme[normal];
    if (theme[hover] !== undefined) hover = theme[hover];
    return interpolateColors(normal, hover, hoverAmount);
}


/**
 * @type {import("@glideapps/glide-data-grid").CustomRenderer}
 */
export const TreeNodeCell = {
    kind: GridCellKind.Custom,
    isMatch: (c) => c.data.kind === "tree-node-cell",
    onClick: ({ cell, posX, preventDefault }) => {
        if (!cell.data.hasChildren) {
            return;
        }

        // const { depth } = cell.data;

        // const depthOffset = (depth || 0) * 20;

        // if (posX < depthOffset || posX > depthOffset + 22) {
        //     return;
        // }

        preventDefault();

        // node.collapsed = !node.collapsed
        cell.data.collapsed = !cell.data.collapsed;
        cell.data.toggleCollapse();

        return cell;
        // return {
        //     ...cell,
        //     data: {
        //         ...cell.data,
        //         collapsed: !cell.data
        //     }
        // }
    },
    draw: (args, cell) => {
        const { ctx, rect, theme } = args;
        const x = Math.floor(rect.x + theme.cellHorizontalPadding + 1);
        const y = Math.floor(rect.y + theme.cellVerticalPadding + 1);
        const width = Math.ceil(rect.width - theme.cellHorizontalPadding * 2 - 1);
        const height = Math.ceil(rect.height - theme.cellVerticalPadding * 2 - 1);

        const { data } = cell;
        const { hasChildren, childrenCount, collapsed, depth, value } = data;

        const depthOffset = (depth || 0) * 20;

        ctx.save();


        ctx.fillStyle = theme.borderColor
        ctx.fillRect(rect.x, rect.y, rect.width, rect.height);

        if (hasChildren) {
            ctx.fillStyle = "none";
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineWidth = 1.5;
            ctx.strokeStyle = theme.textDark;
            ctx.beginPath();
            if (!collapsed) {
                ctx.moveTo(x + depthOffset + 15, y + height / 2 - 3);
                ctx.lineTo(x + depthOffset + 7, y + height / 2 - 3);
                ctx.lineTo(x + depthOffset + 11, y + height / 2 + 3);
            } else {
                ctx.moveTo(x + depthOffset + 8, y + height / 2 - 4);
                ctx.lineTo(x + depthOffset + 8, y + height / 2 + 4);
                ctx.lineTo(x + depthOffset + 14, y + height / 2);
            }
            ctx.closePath();
            ctx.stroke();
        }

        ctx.restore();

        const indent = depthOffset + 20;
        const indentRect = {
            ...rect,
            x: x + indent,
            width: width - indent,
        };

        // let mappedValue = value;
        // if (childrenCount && Util.isStringExists(mappedValue)) {
        //     mappedValue = `${mappedValue} (${childrenCount})`
        // }
        drawTextCell({ ...args, rect: indentRect, }, value, 'left');

        return true;
    },
    provideEditor: undefined,
}