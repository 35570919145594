import React from "react";



export function MosaicGrid({ children, col = 4, gap = "1rem", padding = "1rem" }) {
    if (children) {
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%', gap, padding }}>
                {Array(col).fill(0).map((_, colIndex) => (
                    <div key={"col-" + colIndex} style={{ flex: 1, display: 'flex', flexDirection: 'column', minWidth: 0, gap }}>
                        {children.filter((_, itemIndex) => (itemIndex + 0) % col === (colIndex + 0))}
                    </div>
                ))}
            </div>
        )
    } else {
        return children;
    }
}