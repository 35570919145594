import { ComboBox } from "carbon-components-react";
import { useEffect, useState } from "react"
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import {
    Number_132, Number_232, Number_332, Number_432,
    Number_532, Number_632, Number_732, Number_832, Number_932,
    Add32
} from '@carbon/icons-react'
import Util from "../../../util/Util";

const OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

const styles = {
    selected: { borderRadius: 7, background: '#ffffff10', color: '#0f62fe', border: '1px solid #ffffff20' },
    notSelected: { cursor: 'pointer', background: 'transparent', border: '1px solid transparent' },
}

const Item = ({
    icon, selected,
    onClick,
    label = "Guests",
}) => {
    return (
        <div onClick={onClick} style={{ flex: 1, display: 'flex', transition: '250ms', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', ...(selected ? styles.selected : styles.notSelected) }}>
            {icon}
            <p style={{ fontSize: 12, opacity: 0.65 }}>{label}</p>
        </div>
    )
}

export function SetNoOfPeople({ state }) {
    const [expanded, setExpanded] = useState(false); // 52 -> 120

    const loading = state.isSetNoOfPeopleDineInLoading();
    const no = state.getNoOfPeopleDineIn();
    const setNo = no => {
        state.setNoOfPeopleDineIn(no)
        if (no <= 9) {
            setExpanded(false);
        }
    };

    return (<>
        <div onClick={() => setExpanded(prev => !prev)} style={{ height: 25, width: '100%', background: "#ffffff20", borderRadius: 5, paddingInline: 5, display: 'flex', alignItems: 'center', color: 'white' }}>
            <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>No of guests (tap to expand)</p>
            <p style={{ fontSize: 14, fontWeight: 'bold' }}>{no ?? '-'}</p>
        </div>

        {expanded && <div style={{ width: '100%', color: 'white', paddingTop: 0, pointerEvents: loading ? 'none' : undefined, opacity: loading ? 0.6 : 1 }}>
            <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(5, minmax(0, 1fr))', gap: '1rem', }}>
                <Item selected={no === 1} onClick={() => setNo(1)} icon={<Number_132 />} label="Guest" />
                <Item selected={no === 2} onClick={() => setNo(2)} icon={<Number_232 />} />
                <Item selected={no === 3} onClick={() => setNo(3)} icon={<Number_332 />} />
                <Item selected={no === 4} onClick={() => setNo(4)} icon={<Number_432 />} />

                <Item selected={no === 5} onClick={() => setNo(5)} icon={<Number_532 />} />
                <Item selected={no === 6} onClick={() => setNo(6)} icon={<Number_632 />} />
                <Item selected={no === 7} onClick={() => setNo(7)} icon={<Number_732 />} />
                <Item selected={no === 8} onClick={() => setNo(8)} icon={<Number_832 />} />
                {no <= 9 && <Item selected={no === 9} onClick={() => setNo(9)} icon={<Number_932 />} />}

                {no > 9 && <Item selected icon={<h4 style={{ fontFamily: 'IBM Plex Mono' }}>{no}</h4>} />}
                <Item icon={<Add32 />} label="More" onClick={() => setNo(Util.isNumberExist(no) ? (Math.max(no + 1, 10)) : 1)} />
            </div>
        </div>}
    </>)


    // return (
    //     <ComboBox
    //         // key={state.getNoOfPeopleDineIn() + '-combobox-waiters' + state.isSetNoOfPeopleDineInLoading()}
    //         disabled={state.isSetNoOfPeopleDineInLoading()}

    //         style={{ background: 'black', color: 'white', width: '100%' }}
    //         placeholder={"No"}
    //         direction='top'
    //         item={state.getNoOfPeopleDineIn()}
    //         onChange={e => state.setNoOfPeopleDineIn(e.selectedItem ? e.selectedItem : 0)}
    //         value={state.getNoOfPeopleDineIn() + ' people'}
    //         itemToString={item => item + ' people'}
    //         items={OPTIONS}
    //     />
    // )
}