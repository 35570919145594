import { ArrowContainer, Popover } from "react-tiny-popover";
import {
    CaretDown16
} from '@carbon/icons-react'
import { useEffect, useMemo, useState } from "react";
import { Tag } from "carbon-components-react";
import Button from "../../../components/Button";
import useStore from "../../../hooks/useStore";
import Util from "../../../util/Util";

const ButtonPopover = ({ children, popover, invalidateOpen }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [invalidateOpen])

    return (
        <Popover
            isOpen={open}
            positions={['bottom', 'right']}
            // padding={10}
            containerStyle={{ zIndex: 1000000 }}
            align="end"
            onClickOutside={e => {
                // if (!classExistsInSomeParent(e.target, "table-list-filter-save-dialog")) {
                setOpen(false)
                // }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container to-do-board-button-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="to-do-board-button-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        {popover}
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                {children}
            </a>
        </Popover>
    )
}

const SelectionItem = ({ createdByYou, selected, name, onClick }) => (
    <div onClick={onClick} data-selected={selected} className='to-do-board-selection-item'>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
            <p style={{ fontSize: 14, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>{name}</p>
            {createdByYou && <p style={{ fontSize: 10, opacity: 0.65, flex: 1, marginTop: -5, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>Created by you</p>}
        </div>
        {selected && <Tag type="green" size="sm">Selected</Tag>}
    </div>
)

const Divider = ({ type, children }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem', paddingInline: '1rem', paddingBlock: '0.25rem' }}>
        <div style={{ flex: 1, height: 1, background: type === 'blue' ? '#D0E2FF' : type === "teal" ? '#9DF0F0' : '#39393940' }} />
        <Tag size="sm" type={type}>{children}</Tag>
        <div style={{ flex: 1, height: 1, background: type === 'blue' ? '#D0E2FF' : type === "teal" ? '#9DF0F0' : '#39393940' }} />
    </div>
)

const ReportList = ({ selectedId, setSelectedId, list, showCreatedByYou, hideNoReportMessage }) => {
    return (<>
        {list.map(item => <SelectionItem key={item.id} name={item.value} createdByYou={showCreatedByYou && item.selfEntry} selected={item.id == selectedId} onClick={() => setSelectedId(item.id)} />)}
        {!hideNoReportMessage && <NoReportMessage list={list} />}
    </>)
}

const NoReportMessage = ({ list }) => {
    if (list.length === 0) {
        return <div style={{ marginBottom: '0.5rem', marginTop: '0.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ fontSize: 12, opacity: 0.65 }}>No reports available</p></div>
    } else {
        return null;
    }
}

const List = ({ items, selectedId, setSelectedId }) => {
    const lists = useMemo(() => {
        const noDesign = [items[0]];
        const publicDesign = items.filter(item => item.showForAll === true);
        const offeringDesigns = items.filter(item => item.offeringToShowForAll === true)
        const myDesigns = items.filter(item => Util.isNumberExist(item.id) && item.showForAll === false && item.offeringToShowForAll === false)
        return { noDesign, publicDesign, offeringDesigns, myDesigns }
    }, [items])
    return (
        <div style={{ padding: '0rem', borderRadius: 15, overflow: 'hidden' }}>
            <p style={{ fontSize: 12, opacity: 0.65, padding: '1rem' }}>Select report</p>
            <div style={{ maxHeight: 250, overflowX: 'hidden', overflowY: 'auto', borderTopWidth: 1, borderTopColor: '#00000020', borderTopStyle: 'solid' }}>
                <ReportList selectedId={selectedId} setSelectedId={setSelectedId} list={lists.noDesign} hideNoReportMessage />

                <Divider type="blue">Public</Divider>
                <ReportList selectedId={selectedId} setSelectedId={setSelectedId} list={lists.publicDesign} showCreatedByYou />

                {lists.offeringDesigns.length > 0 && <>
                    <Divider type="teal">Requesting To Publish</Divider>
                    <ReportList selectedId={selectedId} setSelectedId={setSelectedId} list={lists.offeringDesigns} />
                </>}

                <Divider type="high-contrast">My Designs</Divider>
                <ReportList selectedId={selectedId} setSelectedId={setSelectedId} list={lists.myDesigns} />

                {/* {items.map(item => <SelectionItem key={item.id} name={item.value}
                    selected={item.id == selectedId} onClick={() => setSelectedId(item.id)} />)} */}
            </div>
        </div>
    )
}

export function SelectReport({ store, info }) {
    const [selectedId, setSelectedId] = useStore(store, "selectedSavedReportId")
    const item = useMemo(() => Util.isNumberExist(selectedId) ? info.savedReports.find(report => report.id === selectedId) : undefined, [selectedId, info.savedReports])
    return (<>
        <ButtonPopover invalidateOpen={selectedId} popover={
            <List items={info.savedReports} selectedId={selectedId} setSelectedId={setSelectedId} />
        }>
            {item ? (
                <Button kind="primary" renderIcon={CaretDown16} style={{ borderRadius: 10, width: 250, height: 'calc(100% - 1rem)' }} size="sm">{item.value}</Button>
            ) : (
                <Button kind="secondary" renderIcon={CaretDown16} style={{ borderRadius: 10, width: 250, height: 'calc(100% - 1rem)' }} size="sm">Load saved designs</Button>
            )}
        </ButtonPopover>
    </>)
}