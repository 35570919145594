import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_COUPON, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_CUSTOMER_GROUP, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import { QuizEditor } from '../../views/quiz-editor';


const CustomerRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_CUSTOMER,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Customers",
                subTitle: "Restricted customers",
                objectType: OBJECT_TYPE_CUSTOMER,
                pagePath: "/customers/",
                history: history
            })}
        </div>
    )
}

const CustomerGroupRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_CUSTOMER_GROUP,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Customer Groups",
                subTitle: "Restricted customer groups",
                objectType: OBJECT_TYPE_CUSTOMER_GROUP,
                pagePath: "/customer-groups/",
                history: history
            })}
        </div>
    )
}

const ProductRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Products",
                subTitle: "Restricted products",
                objectType: OBJECT_TYPE_PRODUCT,
                paginated: true,
                pagePath: "/products/",
                history: history
            })}
        </div>
    )
}

const BundleRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_PRODUCT_BUNDLE,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Bundles",
                subTitle: "Restricted bundles",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                pagePath: "/bundles/",
                history: history
            })}
        </div>
    )
}

class CouponDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_COUPON, listener)
            } else {
                Api.getItem(OBJECT_TYPE_COUPON, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_COUPON,
                    itemResult: this.state.itemResult,
                    pagePath: "/coupons/",
                    history: this.props.history,
                    customTabs: [
                        ...(this.state.itemResult.item.restricted ? [
                            {
                                title: "Customers",
                                component: CustomerRestrictedList
                            },
                            {
                                title: "Groups",
                                component: CustomerGroupRestrictedList
                            }
                        ] : []),
                        ...(this.state.itemResult.item.restrictedProducts ? [
                            {
                                title: "Products",
                                component: ProductRestrictedList
                            },
                            {
                                title: "Bundles",
                                component: BundleRestrictedList
                            }
                        ] : []),
                    ],
                    onSave: update => this.setState(prevState => ({
                        itemResult: {
                            ...prevState.itemResult,
                            item: {
                                ...prevState.itemResult.item,
                                ...update
                            }
                        }
                    }))
                })}
            </div>
        )
    }

}

export default withRouter(CouponDetailPage);