import { NewSales } from "../../../2v/modules/sales/pages/new-sales";
import { SalesPage } from "../../../2v/modules/sales/pages/sales-page";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { Certificate32, ArrowsVertical16, Download16, Upload16 } from '@carbon/icons-react'
import { DateRange2 } from "../../components/basic-filter/date-range";
import { MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box";
import { useStringValueFilter } from "../../engine/useValueFilter";

export const V2SalesList = {
    filter: Filter,
    openItemInSideView: true,
    sideViews: {
        fullScreen: true,
        newForm: {
            Component: Newform
        },
        updateForm: {
            Component: ItemPage,
        }
    },

    customPath: "/sales",
    icon: Certificate32,

    isListMode: true,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Newform({ form }) {
    return <NewSales />
}

function ItemPage({ itemId }) {
    return <SalesPage itemId={itemId} />
}

const TypeFilter = ({ state }) => {
    const [value, setValue] = useStringValueFilter(state, "type", { property: "type", operator: "EQ" })
    return (
        <div style={{ paddingInline: '0.5rem' }}>
            <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                <button data-active={!value} onClick={() => setValue(null)} className='report-engine-menu-button-light'><ArrowsVertical16 /></button>
                <button data-active={value === "Invoice"} onClick={() => setValue("Invoice")} className='report-engine-menu-button-light'><Download16 /></button>
                <button data-active={value === "Credit Note"} onClick={() => setValue("Credit Note")} className='report-engine-menu-button-light'><Upload16 /></button>
            </div>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="invoiceDate" padWorkingHours />

        <div className="list-mode-divider-quick-filter-bar" />

        <TypeFilter state={state} />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Customer" fieldKey="customerField" options={endpoint.customers.map($ => $.value)} state={state} property="customers" operator="CONTAINS" />
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <TextBox state={state} label="Voucher No" property="voucherNo" fullWidth />
        </div>
    </>)
}
