import './dashboard-page.scss'
import React, { useState } from 'react'
import Page from '../../base/Page';
import { getAccount } from '../../session/SessionManager';

import {
    Dashboard32,
    Archive32,
    ShoppingCart32,
    Currency32,
    AirlinePassengerCare32,
    StarFilled32,
    IncreaseLevel16,
    ArrowRight16,
    SubtractAlt16,
    Calendar20
} from '@carbon/icons-react'
import Button from '../../components/Button';
import TodayAtAGlanceView from './views/TodayAtAGlanceView';
import WeeksProfitView from './views/WeeksProfitView';
import PaymentMethodDivision from './views/PaymentMethodDivision';
import UpcomingChequesView from './views/UpcomingChequesView';
import LowStockAlertsView from './views/LowStockAlertsView';
import UnpaidStockFlowsView from './views/UnpaidStockFlowsView';
import TopCustomersView from './views/TopCustomersView';
import ProductPerformanceView from './views/ProductPerformanceView';
import StaffStats from './views/StaffStats';
import { Link } from 'react-router-dom';
import Api from '../../session/Api';
import { isStockEntityFifoEngine } from '../../stock-system/stock-engine';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { ShowtimeTimeline, ShowtimeTimelineNoPadding } from '../cinema/showtime-timeline';
import DashboardTitle from './components/DashboardTitle';

const Padding = () => <div style={{ height: '1rem' }} />

class DashboardPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            dashboard: undefined
        }
    }

    onPageStart() {
        window.scrollTo(0, 0);
        this.callPageApi(listener => {
            if (this.props.defaultDashboard) {
                listener({ status: true, payload: this.props.defaultDashboard })
            } else {
                if (isStockEntityFifoEngine()) {
                    Api.getDashboard2(listener)
                } else {
                    Api.getDashboard(listener)
                }
            }
        }, payload => ({
            dashboard: payload
        }))
    }

    getLayout() {
        const dashboard = this.state.dashboard;
        return (
            <div style={{ width: '100%', padding: '1rem' }}>
                {!this.props.skipHeader && <>
                    <div style={{ display: "flex", alignItems: 'center', width: '100%' }}>
                        <Dashboard32 style={{ marginRight: '0.5rem' }} />
                        <div style={{ display: "flex", alignItems: 'flex-end', flex: 1 }}>
                            <h2 style={{ fontWeight: 'bold' }}>Welcome</h2>
                            <h4 style={{ marginLeft: '0.5rem', flex: 1 }}>{getAccount().fullName}</h4>
                        </div>
                        <Link to="/system-logs" style={{ textDecoration: 'none' }}>
                            <Button renderIcon={Archive32}>View System Logs</Button>
                        </Link>
                    </div>
                    <Padding />
                </>}


                <TodayAtAGlanceView dashboard={dashboard} />

                <Padding />
                <Padding />
                <div style={{ width: '100%', display: 'flex', gap: '0.5rem' }}>
                    <div style={{ flex: 4, alignSelf: 'stretch' }}>
                        <WeeksProfitView dashboard={dashboard} />
                    </div>
                    <div style={{ flex: 2, alignSelf: 'stretch' }}>
                        <PaymentMethodDivision dashboard={dashboard} />
                    </div>
                </div>

                {hasCapabilitySupport("cinema") && <>
                    <Padding />
                    <Padding />

                    <DashboardTitle icon={Calendar20} title="Showtime Schedules" />
                    <div style={{ width: '100%', height: 400 }}>
                        <ShowtimeTimelineNoPadding />
                    </div>
                </>}

                <Padding />
                <Padding />
                <div style={{ width: '100%', display: 'flex', gap: '0.5rem' }}>
                    <div style={{ flex: 1 }}>
                        <UpcomingChequesView dashboard={dashboard} />
                    </div>
                    {/* <div style={{ flex: 1 }}>
                        <UnpaidStockFlowsView dashboard={dashboard} />
                    </div> */}
                    {/* <div style={{ flex: 1 }}>
                        <LowStockAlertsView dashboard={dashboard} />
                    </div> */}
                </div>

                <Padding />
                <Padding />
                <div style={{ width: '100%', display: 'flex', gap: '0.5rem' }}>
                    <div style={{ flex: 1, display: 'flex' }}>
                        <TopCustomersView dashboard={dashboard} />
                    </div>
                    <div style={{ flex: 2 }}>
                        <ProductPerformanceView dashboard={dashboard} />
                    </div>
                </div>

                {/* <Padding />
                <Padding />
                <StaffStats /> */}

            </div>
        )
    }

    isPageLoadable() {
        return true;
    }

}

export default DashboardPage;