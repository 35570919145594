import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { Calculation32, Calculation24, ArrowRight24, ArrowRight16, Xls16 } from '@carbon/icons-react'
import { StockAdjustmentItemsList } from "./item-list"
import Util, { flattenItems, isV2 } from "../../../util/Util"
import { DisplayFormGroup, DisplayFormValueField, FormSection } from "../../../templates/form/form"
import { useMemo } from "react"
import { MinimalTable } from "../../../components/minimal-table"
import Button from "../../../components/Button"
import { useState } from "react"
import { ImportDialog } from "./import-dialog"
import { StockAdjustmentItemsList2 } from "./item-list-2"
import CustomComboBox from "../../../components/CustomComboBox"
import { Link } from "react-router-dom"


export const StockAdjustmentList = newListDetail({
    title: "Stock Adjustment",
    icon: Calculation32,
    mdIcon: Calculation24,

    filter: Filter, newForm: Form, updateForm: ViewForm,
    fullScreenSideView: true,

    // canDelete: false,

    defaultValues: {
        date: new Date().getTime(),
        //items: [{ tempId: Util.newTempId() }]
        items: [],
    },
    finalFormMapper: data => ({
        ...data,
        //items: data.items.filter($ => $?.item?.selectedItem?.id).map($ => ({
        items: data.items.filter($ => $?.item?.id).map($ => ({
            itemId: $.item.id,
            qty: $.qty,
            memo: $.memo,
            tags: $.tags?.map($ => ({ ...$, name: $.label })) ?? []
        }))
    }),


    isAvailable: () => {

        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

const Items = ({ products, predefinedClasses, editable, value, setValue }) => {
    const [importVisible, setImportVisible] = useState(false);
    const onItemsImported = items => setValue(items);
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                <Button onClick={() => setImportVisible(true)} renderIcon={Xls16} size="sm" style={{ borderRadius: 7 }} className="green-button">Excel Import</Button>
            </div>
            {/* <StockAdjustmentItemsList products={products} predefinedClasses={predefinedClasses} editable={editable} items={value} setItems={setValue} /> */}
            <StockAdjustmentItemsList2 products={products} predefinedClasses={predefinedClasses} value={value} setValue={setValue} />

            <ImportDialog open={importVisible} onClose={() => setImportVisible(false)} onItemsImported={onItemsImported} />
        </div>
    )
}

function ViewForm({ endpoint, form }) {
    const data = useMemo(() => form.getFormData(), [form]);
    return (
        <>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '3rem' }}>
                <DisplayFormValueField title={"Date"}>{Util.getDateOnly(data.date)}</DisplayFormValueField>
                <div style={{ width: '3rem' }} />
                <DisplayFormValueField title={"Location"}>{data.toLocationName}</DisplayFormValueField>
                <div style={{ width: '3rem' }} />
                <DisplayFormValueField title={"Journal Entry"}>
                    <Link to={"/journal-entry/" + data.journalEntryId}>
                        <Button kind="secondary" size="sm" style={{ borderRadius: 7 }} renderIcon={ArrowRight16}>Open</Button>
                    </Link>
                </DisplayFormValueField>
            </div>

            <MinimalTable
                large items={data.items}
                columns={[
                    {
                        name: "item",
                        title: "Item",
                        flex: 5,
                        renderCell: item => item.itemName
                    },
                    {
                        name: "class",
                        title: "Class",
                        flex: 3,
                        renderCell: item => item?.tags?.map($ => $.name).join(", ")
                    },
                    {
                        name: "memo",
                        title: "Memo",
                        flex: 3,
                        renderCell: item => item.memo
                    },
                    {
                        name: "qty",
                        title: "Qty (+/-)",
                        flex: 2,
                        renderCell: item => item.qty
                    },
                ]}
            />
        </>
    )
}

function AccountField({ endpoint, value, setValue }) {
    const accounts = endpoint.accountTree
    const flattenedAccounts = useMemo(() => flattenItems(accounts), [accounts]);
    return (<>
        <label className="bx--label">Adjustment Account (optional)</label>
        <div style={{ height: 40, }}>
            <CustomComboBox
                // white
                defExtended
                // preventClear
                items={accounts}
                // selectedItem={value}
                // onSelectedItemUpdate={setValue}
                placeholder={"Default COGS Account"}

                selectedItem={flattenedAccounts.find(item => item.id === value)}
                onSelectedItemUpdate={item => setValue(item?.id)}
            />
        </div>
        {/* <label className="bx--label">Defaults to COGS account</label> */}
    </>)
}

function Form({ endpoint, form }) {
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', }}>
                <div style={{ flex: 1 }}>
                    <form.DateField fieldKey="date" title="Date" />
                </div>
                <div style={{ flex: 1 }}>
                    <form.ComboBoxField fieldKey="toLocationId" title="Location" options={endpoint.locations} />
                </div>
                <div style={{ flex: 1 }}>
                    <form.CustomField fieldKey="ledgerAccountId" childrenProps={{ endpoint }}>
                        {AccountField}
                    </form.CustomField>
                </div>

            </div>
            <FormSection title={"Items"}>
                <form.CustomField fieldKey="items" childrenProps={{ products: endpoint.products, predefinedClasses: endpoint.predefinedClasses, editable: true, }}>
                    {Items}
                </form.CustomField>
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
