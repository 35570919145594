import { Tab, Tabs } from "carbon-components-react"
import { Task16, Close16, Chat16, RecentlyViewed16, Notebook16 } from '@carbon/icons-react'
import React from "react"
import { isMobileScreen } from "../../../../App"

const Header = ({ title, onCloseBtn }) => (
    <div style={{ padding: '1rem', paddingBottom: '0.5rem' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ flex: 1 }}>
                <h4>{title}</h4>
            </div>
            {onCloseBtn && <button className="calendar-icon-button" onClick={onCloseBtn}>
                <Close16 />
            </button>}
        </div>
    </div>
)

const TabLabel = ({ label, icon }) => (
    <div style={{ width: '100%', height: '100%', flex: 1, display: 'flex', gap: '0.15rem', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {React.createElement(icon)}
        <label style={{ margin: 0, padding: 0, }} className="bx--label">{label}</label>
    </div>
)

export function CalendarLayout({ children, title, onCloseBtn, commentView, statusHistoryView, noteView }) {
    if (isMobileScreen) {
        return (
            <div className="full-width-tabs stripped-down-tab-content-full-height mobile-icon-black-tab" style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                {/* <div style={{ display: 'flex', alignItems: 'center', paddingBlock: '0.25rem', paddingInline: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <h4 style={{ fontSize: 14 }}>{title}</h4>
                    </div>
                    {onCloseBtn && <button className="calendar-icon-button" onClick={onCloseBtn}>
                        <Close16 />
                    </button>}
                </div> */}

                <Tabs>
                    <Tab label={<Task16 />} className="unfocused-tab">
                        <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                            {children}
                        </div>
                    </Tab>
                    <Tab label={<Chat16 />} className="unfocused-tab">
                        {commentView}
                    </Tab>
                    <Tab label={<RecentlyViewed16 />} className="unfocused-tab">
                        <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                            {statusHistoryView}
                        </div>
                    </Tab>
                    <Tab label={<Notebook16 />} className="unfocused-tab">
                        <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                            {noteView}
                        </div>
                    </Tab>
                    {onCloseBtn && <Tab label={<Close16 />} className="unfocused-tab" onClick={onCloseBtn} />}
                </Tabs>
            </div>
        )
    }

    return (
        <div className="full-width-tabs stripped-down-tab-content-full-height" style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
            <Header title={title} onCloseBtn={onCloseBtn} />

            <Tabs>
                <Tab label={<TabLabel label="Details" icon={Task16} />} className="unfocused-tab">
                    <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                        {children}
                    </div>
                </Tab>
                <Tab label={<TabLabel label="Comments" icon={Chat16} />} className="unfocused-tab">
                    {commentView}
                </Tab>
                <Tab label={<TabLabel label="History" icon={RecentlyViewed16} />} className="unfocused-tab">
                    <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                        {statusHistoryView}
                    </div>
                </Tab>
                <Tab label={<TabLabel label="Notes/Docs" icon={Notebook16} />} className="unfocused-tab">
                    <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                        {noteView}
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}