import { ComposedModal, FileUploaderDropContainer, FileUploaderItem, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import Button from "../../../components/Button";
import React, { useEffect, useState } from "react";
import Api from "../../../session/Api";
import {
    Template16,
    Number_132,
    Number_232,
    Number_332,
    Warning16
} from "@carbon/icons-react"
import UIUtil from "../../../util/UIUtil";
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_SUPPLIER, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_SUPPLIER } from "../../../constants/Constants";

const Section = ({ children, icon, title, extraTopMargin, hasLink, linkText, onLinkClick }) => (
    <div style={{ marginTop: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>


            {/* {hasLink && <Link style={{marginLeft: '1rem', fontSize: 12, cursor: 'pointer'}} onClick={onLinkClick}>{linkText}</Link>} */}
        </div>
        {children}
    </div>
)

export function ImportDialog({ open, onClose, onItemsImported }) {
    const [file, setFile] = useState(undefined)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setFile(undefined)
        }
    }, [open])

    const onImportBtn = () => {
        setLoading(true);
        Api.importStockAdjustment(file, response => {
            if (!open) {
                return;
            }

            if (response.status === true) {
                UIUtil.showSuccess();
                onItemsImported(response.payload)
                onClose()
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    return (
        <ComposedModal open={open} onClose={onClose}>
            <ModalHeader title="Import items" label="Excel Import Tool" />
            <ModalBody>
                <Section icon={Number_132} title="Get Template">
                    <div>
                        <a download href={Api.downloadStockAdjustmentImportTemplate()} style={{ textDecoration: 'none' }}>
                            <Button kind="secondary" renderIcon={Template16} style={{ width: undefined }}>Download Template</Button>
                        </a>
                        <p className="bx--label" style={{ display: 'block', marginTop: '0.25rem' }}>.xlsx file format</p>
                    </div>
                </Section>

                <Section icon={Number_232} title="Upload data" extraTopMargin>
                    {file !== undefined ? (
                        <FileUploaderItem name={file.name} status="edit" onDelete={() => setFile(undefined)} />
                    ) : (
                        <FileUploaderDropContainer accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]} multiple={false} labelText="Drag and drop the input data file here or click to upload (.xlsx only)" onAddFiles={(e, { addedFiles }) => {
                            if (addedFiles.length > 0) {
                                setFile(addedFiles[0])
                            }
                        }} />
                    )}

                    <p style={{ fontSize: 14, opacity: 1, marginTop: '1rem' }}><Warning16 /> Always use the latest template file.</p>
                </Section>
            </ModalBody>
            <ModalFooter>
                <Button kind={"secondary"} onClick={onClose}>Cancel</Button>
                <Button loading={loading} onClick={onImportBtn} disabled={file === undefined} kind="primary">Import Data</Button>
            </ModalFooter>
        </ComposedModal>
    )
}