


import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../constants/Constants";
import { CheckmarkFilled16, ErrorFilled16, WarningFilled16, IncompleteWarning16, Money16, Misuse16, Error16, Search16, Time16, Renew16, DeliveryParcel16, Delivery16, WatsonHealthStackedScrolling_116 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react"

export const SALE_AMOUNT_MODE_FREE_FORM = 0;
export const SALE_AMOUNT_MODE_ITEM_BASED = 1;

export const SALE_STATUS_NOT_PAID = 0;
export const SALE_STATUS_PARTIALLY_PAID = 1;
export const SALE_STATUS_PAID = 2;
export const SALE_STATUS_REVOKED = 3;
export const SALE_STATUS_UNPAID_OR_PARTIALLY_PAID = 4;

export function getSaleStatusName(status) {
    switch (status) {
        case SALE_STATUS_NOT_PAID:
            return "Not Paid";
        case SALE_STATUS_PARTIALLY_PAID:
            return "Partially Paid";
        case SALE_STATUS_PAID:
            return "Paid";
        case SALE_STATUS_REVOKED:
            return "Revoked";
    }
}

export function getSaleStatusDetails(status) {
    switch (status) {
        case SALE_STATUS_NOT_PAID:
            return { type: "high-contrast", text: "Not Paid", icon: WarningFilled16 };
        case SALE_STATUS_PARTIALLY_PAID:
            return { type: "purple", text: "Partially Paid", icon: IncompleteWarning16 };
        case SALE_STATUS_PAID:
            return { type: "green", text: "Paid", icon: CheckmarkFilled16 };
        case SALE_STATUS_REVOKED:
            return { type: "red", text: "Revoked", icon: ErrorFilled16 };
    }
}

export const SaleStatusIndicator = ({ status }) => {
    let { icon, text, type } = getSaleStatusDetails(status);
    return (
        <div>
            <Tag size="md" renderIcon={icon} type={type}>
                {text}
            </Tag>
        </div>
    )
}