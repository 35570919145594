import { AddFilled24, Edit24, Wheat24, Close16, ChevronRight16, Reset16, Save16, Draggable16, CheckmarkFilled16, ArrowRight16, TrashCan16, Add16 } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react';
import { useForm } from '../../templates/form/form';
import useApi from '../../hooks/useApi';
import Api from '../../session/Api';
import { fixIdForApi } from '../../templates/form/form-api';
import Button from '../../components/Button';
import { withLoadablePagePropsAndProps } from '../../base/Page';
import { isEqual } from 'lodash';
import UIUtil from '../../util/UIUtil';
import { useHistory } from 'react-router-dom';

export function ListDetailNewForm({ reportName, title, children, defaultValues, finalFormMapper, canSave, noTitle, onClose, onNewItem, skipInlinePadding, endpoint, saveLabel, onPreSave, isRawPanel }) {
    const form = useForm(store => store.setObject(defaultValues), {
        skipFieldEffect: true
    });

    const resetToDefault = () => {
        form.store.clear();
        form.store.setObject(defaultValues);
    }

    // const saving = 
    const [saveLoading, save] = useApi(listener => {
        const req = finalFormMapper(fixIdForApi(form.getFormData()), endpoint);
        if (onPreSave) {
            const errMsg = onPreSave(req);
            if (errMsg) {
                listener({ status: false, message: errMsg })
                return;
            }
        }

        return Api.listDetailSaveData(reportName, req, listener)
    }, onNewItem);

    if (isRawPanel) {
        return children({ form, save, saveLoading });
    }

    return (
        <div style={{ paddingInline: skipInlinePadding ? 0 : '3rem', paddingBlock: '6rem' }}>
            <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem', paddingInline: skipInlinePadding ? '3rem' : 0 }}>
                {noTitle ? (
                    <div style={{ flex: 1 }} />
                ) : (<>
                    <AddFilled24 />
                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                </>)}

                <Button onClick={resetToDefault} size="sm" style={{ borderRadius: 25, marginRight: '0.25rem' }} renderIcon={Reset16} kind="ghost">Reset to Default</Button>
                {canSave && <Button loading={saveLoading} onClick={save} size="sm" style={{ borderRadius: 25 }} renderIcon={CheckmarkFilled16} className="green-button">{saveLabel || 'Save'}</Button>}
            </div>
            {children({ form, save, saveLoading })}
        </div>
    )
}



export const ListDetailUpdateForm = withLoadablePagePropsAndProps(props => listener => Api.listDetailGetData(props.reportName, props.itemId, listener), ({ reportName, itemId, icon, title, children, payload, onItemUpdate, canDelete, onItemDelete, onClose, finalFormMapper, skipInlinePadding, endpoint, noTitle, deleteLabel, renderOptions, rawPanelFunc, closeOnSave, clearStateOnSave }) => {
    const history = useHistory();
    useEffect(() => {
        if (history?.location?.state?.__open_item_id) {
            history?.replace(history?.location?.pathname)
        }
    }, [history])

    const [item, setItem] = useState(payload);
    const form = useForm(store => store.setObject(item), {
        skipFieldEffect: true
    });


    const [changesMade, setChangesMade] = useState(false);
    const resetToDefault = () => {
        form.store.clear();
        form.store.setObject(item);
    }
    useEffect(() => form.store.subscribeGlobal(() => {
        setChangesMade(!isEqual(item, form.getFormData()))
    }), [item])

    const onUpdate = item => {
        onItemUpdate()
        setItem(item);
    }

    const onDelete = () => {
        onItemDelete()
        onClose()
    }

    // const saving = 
    const [loading, save] = useApi(listener => Api.listDetailSaveData(reportName, finalFormMapper(fixIdForApi(form.getFormData()), endpoint), listener), item => {
        onUpdate(item)
        if (closeOnSave) {
            onClose()
        }
        if (clearStateOnSave) {
            form.store.clear();
            form.store.setObject(item);
        }
    });
    const [deleteLoading, deleteItem] = useApi(listener => Api.listDetailDeleteData(reportName, itemId, listener), onDelete);

    const isAbleToDelete = typeof canDelete === 'function' ? canDelete(item) : canDelete;

    const content = children(form, item, onUpdate, { deleteLoading, deleteItem, canDelete: isAbleToDelete, save });

    if (rawPanelFunc?.(item)) {
        return content;
    }

    return (
        <div style={{ paddingInline: skipInlinePadding ? 0 : '3rem', paddingBlock: '6rem' }}>
            <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem', paddingInline: skipInlinePadding ? '3rem' : 0 }}>
                {noTitle ? (
                    <div style={{ flex: 1 }} />
                ) : (<>
                    {React.createElement(icon)}
                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                </>)}


                {changesMade ? (<>
                    <Button onClick={resetToDefault} size="sm" style={{ borderRadius: 25, marginRight: '0.25rem' }} renderIcon={Reset16} kind="ghost">Reset to Default</Button>
                    <Button loading={loading} onClick={save} size="sm" style={{ borderRadius: 25 }} renderIcon={CheckmarkFilled16} className="green-button">Save</Button>
                </>) : (<>
                    {isAbleToDelete &&
                        <Button loading={deleteLoading} onClick={() => UIUtil.confirmMsg('This action is irreversible', deleteItem)} size="sm" style={{ borderRadius: 25 }} renderIcon={TrashCan16} kind="danger">{deleteLabel || 'Delete'}</Button>}
                    {renderOptions && renderOptions({ item, onUpdate })}
                </>)}
            </div>
            {content}
        </div>
    )
})