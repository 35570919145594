import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getMenu } from './MenuContent'

import {
    CaretRight16
} from '@carbon/icons-react'
import Util from '../../util/Util'
import MenuSection from './MenuSection'
import { HAS_ROBOTIC_BRANDING, HAS_SMALL_ROBOTIC_BRANDING } from '../../app/Config'

import Logo from './../../images/LogoTransparent.png'
import RoboticLogo from './../../images/RL.png'
import useSize from '../../util/useSize'
import { useObjectLocalStorage } from '../../hooks/useLocalStorage'
import Input from '../input/Input'
import { getAccountRole } from '../../session/SessionManager'
import { ACCOUNT_TYPE_STORE_MASTER } from '../../constants/Constants'

const MenuGroup = ({ menuGroup, expanded, onToggleExpand, onClose, isPinned, onPinStatusUpdate, miniMode }) => {
    const titleRef = useRef();
    const contentRef = useRef()
    const titleSize = useSize(titleRef)
    const contentSize = useSize(contentRef)

    return (
        <div style={{ transition: '250ms', height: expanded ? contentSize.height + 44 : 44, overflow: 'hidden' }}>
            {/* //<p>{titleSize.height}</p> */}
            <div ref={titleRef} onClick={onToggleExpand}
                style={{ width: '100%', display: miniMode ? 'none' : 'flex', alignItems: 'center', cursor: 'pointer', paddingTop: '1rem', maxHeight: 44 }}>
                <CaretRight16 style={{ marginLeft: '1rem', opacity: 0.65, marginRight: '0.25rem', transition: '250ms', color: 'white', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />

                <h4
                    style={{ paddingLeft: '0.5rem', flex: 1 }}>{menuGroup.title}</h4>
            </div>

            <div ref={contentRef} style={{ overflow: 'hidden' }}>
                {menuGroup.subMenus.map((subMenu, index) => (<>
                    <MenuSection title={menuGroup.title} subMenu={subMenu} index={index} menus={subMenu.menus} color={menuGroup.color} onClose={onClose}
                        isPinned={isPinned} onPinStatusUpdate={onPinStatusUpdate} miniMode={miniMode} />
                </>))}
            </div>
        </div>
    )
}

const getPinnedMenu = (pinnedItems, menu) => {
    let menuItems = [];
    menu.forEach(menuGroup => menuGroup.subMenus.map(subMenu => menuItems.push(...subMenu.menus.map(menu => ({ ...menu, color: menuGroup.color })))))

    return pinnedItems
        .filter(item => menuItems.filter(menuItem => menuItem.link == item).length > 0)
        .map(item => menuItems.filter(menuItem => menuItem.link == item)[0])
}

const getSearchedMenu = (searchValue, menu) => {
    let menuItems = [];
    menu.forEach(menuGroup => menuGroup.subMenus.map(subMenu => menuItems.push(...subMenu.menus.map(menu => ({ ...menu, color: menuGroup.color })))))

    return menuItems.filter(item => (item.title ?? "").toLowerCase().includes(searchValue.toLowerCase()));
}

export default ({ onClose }) => {
    const [searchValue, setSearchValue] = useState("");

    const wrapperRef = useRef();

    const [expandedIndex, setExpandedIndex] = useState(0)
    const onToggleExpand = index => {
        if (expandedIndex == index) {
            setExpandedIndex(undefined)
        } else {
            setExpandedIndex(index)
        }
    }

    useEffect(() => {
        const onClickHandle = e => {
            if (e.target.id != "menu-btn" && wrapperRef.current && !wrapperRef.current.contains(e.target) && onClose !== undefined) {
                onClose();
            }
        };
        window.addEventListener('mousedown', onClickHandle);
        return () => window.removeEventListener('mousedown', onClickHandle)
    }, [])

    const [pinnedItems, setPinnedItems] = useObjectLocalStorage('pinned-menu')


    const menu = useMemo(() => getMenu(), [])
    const pinnedMenu = useMemo(() => getPinnedMenu(pinnedItems, menu), [pinnedItems])
    const searchMenu = useMemo(() => getSearchedMenu(searchValue, menu), [searchValue])

    const onPinStatusUpdate = (pinned, menu) => {
        if (pinned) {
            setPinnedItems(prev => [...prev, menu.link])
        } else {
            setPinnedItems(prev => prev.filter(item => item != menu.link))
        }
    }

    const miniMode = useMemo(() => getAccountRole() == ACCOUNT_TYPE_STORE_MASTER, []);

    return (
        <div ref={wrapperRef} style={{}} className="menu-tile-2">
            {!miniMode &&
                <Input placeholder="Search..." value={searchValue} onUpdateValue={setSearchValue} />}

            {searchValue ? (<>
                <MenuGroup
                    key={"search-group"}
                    isPinned={menu => pinnedItems.includes(menu.link)}
                    onPinStatusUpdate={onPinStatusUpdate}
                    menuGroup={{
                        title: "Search Result",
                        subMenus: [{
                            title: searchMenu.length == 0 ? "No menu item found" : "",
                            menus: searchMenu
                        }],
                    }} expanded onClose={onClose} onToggleExpand={() => setSearchValue('')} />
            </>) : (<>
                {!miniMode && <MenuGroup
                    key={"pinned"}
                    isPinned={menu => pinnedItems.includes(menu.link)}
                    onPinStatusUpdate={onPinStatusUpdate}
                    menuGroup={{
                        title: "Pinned",
                        subMenus: [{
                            title: pinnedMenu.length == 0 ? "Nothing is pinned yet" : "",
                            menus: pinnedMenu
                        }],
                    }} expanded={expandedIndex == -1} onClose={onClose} onToggleExpand={() => onToggleExpand(-1)} />}

                {getMenu().map((menuGroup, index) =>
                    <MenuGroup
                        key={menuGroup.title}
                        isPinned={menu => pinnedItems.includes(menu.link)}
                        onPinStatusUpdate={onPinStatusUpdate}
                        menuGroup={menuGroup} expanded={expandedIndex == index} onClose={onClose}
                        onToggleExpand={() => onToggleExpand(index)}
                        miniMode={miniMode} />)}
            </>)}


            {HAS_ROBOTIC_BRANDING &&
                <><div style={{ height: '1rem' }} />
                    <img src={Logo} style={{ objectFit: 'contain', objectPosition: 'center', height: '100%', cursor: 'pointer', width: 200, padding: '1rem' }} />
                    <div style={{ height: '1rem' }} /></>}

            {HAS_SMALL_ROBOTIC_BRANDING &&
                <><div style={{ height: '1rem' }} />
                    <img src={RoboticLogo} style={{ objectFit: 'contain', objectPosition: 'center', height: '100%', cursor: 'pointer', width: 200, padding: '1rem' }} />
                    <div style={{ height: '1rem' }} /></>}
        </div>
    )
}