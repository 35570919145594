
// import { ShoppingCart16, ShoppingCartPlus16, RowDelete16, Tag16, QueryQueue24, ShoppingCartClear16 } from '@carbon/icons-react'
// import React, { createRef, useEffect, useRef, useState } from 'react'
// import Button from '../../../../components/Button'
// import ProfilePic from '../../../../components/ProfilePic'
// import useStore from '../../../../hooks/useStore'
// import Api from '../../../../session/Api'
// import UIUtil from '../../../../util/UIUtil'
// import Util from '../../../../util/Util'
// import { getQuantityValue } from '../../../pos/widgets/CartContent'
// import useThirdPartyPos from '../../pos/useThirdPartyPos'
// import Header from '../components/Header'

import { useMemo } from "react"
import useStore from "../../../../hooks/useStore";
import CartList from "../../components/CartList"
import useThirdPartyPos from "../../pos/useThirdPartyPos";


// class CartItem extends React.Component {

//     shouldComponentUpdate(prevProps) {
//         return this.props.cartItem != prevProps.cartItem || this.props.scrollInto != prevProps.scrollInto || this.props.removingItem != prevProps.removingItem
//     }

//     render() {
//         return <CartItemF {...this.props} />
//     }

// }

// const CartItemF = ({ readonly, removingItem, onClearBtn, cartItem, scrollInto, onScrollDone }) => {
//     const [key, setKey] = useState(Util.newTempId());
//     const [highlight, setHighlight] = useState(false);

//     const [quantityKey, setQuantityKey] = useState(Util.newTempId());
//     const [quantityHighlight, setQuantityHighlight] = useState(undefined);

//     const ref = useRef();

//     useEffect(() => {
//         if (scrollInto) {
//             if (ref.current) {
//                 ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
//             }
//             onScrollDone();
//             setKey(Util.newTempId())
//             setHighlight(true);
//         }
//     }, [scrollInto])


//     const [lastQuantity, setLastQuantity] = useState(undefined);
//     useEffect(() => {
//         if (lastQuantity !== undefined && lastQuantity != cartItem.quantityValue) {
//             setQuantityKey(Util.newTempId())
//             setQuantityHighlight(lastQuantity > cartItem.quantityValue ? 'quantity-decrease-anim' : 'quantity-increase-anim');
//         }
//         setLastQuantity(cartItem.quantityValue);
//     }, [cartItem.quantityValue])

//     return (
//         <div key={key} ref={ref} style={{
//             display: 'flex',
//             animation: highlight ? 'scale-bounce-anim 750ms' : '',
//             //height: cartItem.bundleProducts.length > 0 ? undefined : 65, 
//             fontSize: '.875rem', color: '#525252', borderBottom: '1px solid #e0e0e0', padding: '0.5rem .25rem 1rem', paddingLeft: '0rem',
//             gap: '0.5rem', alignItems: 'flex-start'
//         }}>
//             {cartItem.bundleProducts.length > 0 ? (
//                 <div style={{ flex: 3 }}>
//                     <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: -5 }}>
//                         <ProfilePic style={{ borderRadius: 10, minWidth: 45 }} size={45} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
//                         {cartItem.name}
//                     </div>
//                     {cartItem.bundleProducts.map(bundleProduct => <p style={{ paddingLeft: '1.5rem', marginLeft: 30, opacity: 0.65, fontSize: 12 }}>
//                         {bundleProduct.quantity + 'x ' + bundleProduct.name}
//                     </p>)}
//                 </div>
//             ) : (
//                 <div style={{ flex: 3, display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
//                     <ProfilePic style={{ borderRadius: 10, minWidth: 45 }} size={45} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
//                     {cartItem.name}
//                 </div>
//             )}

//             <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: 5 }}>
//                 <div style={{ height: 45 }} />
//                 <span key={quantityKey} style={{ animation: quantityHighlight ? quantityHighlight + ' 750ms' : '' }}>
//                     {getQuantityValue(cartItem)}
//                 </span>
//             </div>
//             {!readonly && <div style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//                 <Button style={{ marginLeft: 5 }} className="t-pos-btn" size="lg" loading={removingItem === cartItem} onClick={() => UIUtil.confirm(() => onClearBtn(cartItem))}
//                     kind="danger--tertiary" renderIcon={RowDelete16} hasIconOnly iconDescription="Remove Item" />
//                 <Button onClick={() => { }} style={{ marginLeft: 5 }} className="t-pos-btn" size="lg" kind="secondary" tooltipAlignment="end"
//                     renderIcon={ShoppingCartPlus16} hasIconOnly iconDescription="Set Quantity" />
//             </div>}
//         </div>
//     )

// }

// const CartHeader = () => (<>
//     {/* <div style={{ marginBottom: '-0.75rem' }} /> */}

// </>)

// const CartList = ({ sessionStore, readonly }) => {
//     const [cartItems] = useStore(sessionStore, "currentCartItems")

//     const [removingItem, setRemovingItem] = useState(undefined);
//     const [scrollIntoItem, setScrollIntoItem] = useState(undefined);
//     const lastItemCount = useRef(undefined)

//     useEffect(() => {
//         if (lastItemCount.current && lastItemCount.current < cartItems.length) {
//             setScrollIntoItem(cartItems[cartItems.length - 1])
//         }
//         lastItemCount.current = cartItems.length;
//     }, [cartItems.length])

//     return (
//         <div style={{ width: 'calc(100% + 1rem)', marginLeft: '-0.5rem', padding: '0.5rem', paddingTop: 0, flex: 1, overflowY: 'auto', overflowX: 'hidden', }}>
//             <div className="bx--structured-list-th dashboard-card t-pos-card-bg" style={{
//                 display: 'flex', paddingLeft: '0rem', borderBottom: '1px solid #e0e0e0', background: '#f4f4f4',
//                 position: 'sticky', top: 0, zIndex: 1000,
//                 height: 'unset', gap: '0.5rem',
//                 padding: '0.5rem .25rem 1rem', paddingLeft: '0rem',
//                 paddingLeft: 0, paddingBottom: '0.5rem', paddingTop: 0,
//             }}>
//                 <div style={{ flex: 3 }}>
//                     Item
//                 </div>
//                 <div style={{ flex: 1 }}>
//                     Quantity
//                 </div>
//                 {!readonly && <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>

//                 </div>}
//             </div>

//             {cartItems.map((cartItem) => (
//                 <CartItem key={cartItem.itemId + "-cart-item"} cartItem={cartItem} readonly={readonly}
//                     scrollInto={cartItem === scrollIntoItem} onScrollDone={() => setScrollIntoItem(undefined)}
//                     removingItem={removingItem}
//                 //onClearBtn={onClearBtn} 
//                 />
//             ))}
//         </div>
//     )
// }

// const Top = ({ sessionStore }) => {
//     const { loadingClearing, clearCart } = useThirdPartyPos(sessionStore);
//     const onClearCartBtn = () => {
//         UIUtil.confirm(clearCart);
//     }
//     return (
//         <Header icon={QueryQueue24} title="Cart">
//             {/* danger--tertiary */}
//             <Button className="t-pos-btn t-pos-btn-icon" hasIconOnly data-tip="Stop Session" kind="danger" tooltipPosition="left" tooltipAlignment="end"
//                 iconDescription="Clear Cart" size="sm" renderIcon={ShoppingCartClear16} onClick={onClearCartBtn} loading={loadingClearing}
//             />
//         </Header>
//     )
// }

// export default ({ sessionStore, readonly }) => {
//     return (
//         <div className={"dashboard-card t-pos-card t-pos-card-bg"} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', paddingBottom: 0, }}>
//             {!readonly && <>
//                 <Top sessionStore={sessionStore} />
//                 <div style={{ marginTop: '0.5rem' }} />
//             </>}

//             <CartHeader />
//             <CartList sessionStore={sessionStore} readonly={readonly} />
//         </div>
//     )
// }

export default ({ sessionStore, readonly }) => {
    const controller = useMemo(() => ({
        useClear: () => {
            const { loadingClearing, clearCart } = useThirdPartyPos(sessionStore);
            return [loadingClearing, clearCart];
        },
        useItems: () => {
            const [cartItems] = useStore(sessionStore, "currentCartItems");
            return cartItems;
        },
        useUpdateCart: () => {
            const { addToCart, removeFromCart } = useThirdPartyPos(sessionStore);
            return [addToCart, removeFromCart]
        },
        useComplimentary: () => {
            const { setItemComplimentary } = useThirdPartyPos(sessionStore);
            return setItemComplimentary;
        },
        useThirdPartyPos: () => useThirdPartyPos(sessionStore)
    }), [sessionStore])
    return <CartList readonly={readonly} controller={controller} />
}