import {
    CustomCell,
    CustomRenderer,
    getMiddleCenterBias,
    GridCellKind,
    interpolateColors,
    drawTextCell,
    blend, measureTextCached,
} from "@glideapps/glide-data-grid";
import { roundedRect } from ".";
import Util from "../../../../util/Util";
import html2canvas from "html2canvas";
import { generateCanvas } from "../../../../util/useBitmap";
import { Tag } from "carbon-components-react";
import { Checkmark16 } from '@carbon/icons-react'

function prepTextCell(
    args,
    lastPrep,
    overrideColor
) {
    const { ctx, theme } = args;
    const result = lastPrep ?? {};

    const newFill = overrideColor ?? theme.textDark;
    if (newFill !== result.fillStyle) {
        ctx.fillStyle = newFill;
        result.fillStyle = newFill;
    }
    return result;
}


function unpackColor(color, theme, hoverAmount) {
    if (typeof color === "string") {
        if (theme[color] !== undefined) return theme[color];
        return color;
    }

    let [normal, hover] = color;
    if (theme[normal] !== undefined) normal = theme[normal];
    if (theme[hover] !== undefined) hover = theme[hover];
    return interpolateColors(normal, hover, hoverAmount);
}


/**
 * @type {import("@glideapps/glide-data-grid").CustomRenderer}
 */
export const LinkCell = {
    kind: GridCellKind.Custom,
    isMatch: (c) => c.data.kind === "link-cell",
    needsHover: true,
    onSelect: a => a.preventDefault(),
    onClick: a => {
        a.cell.data.onClick?.();
        return undefined;
    },
    drawPrep: prepTextCell,
    // useLabel: true,
    draw: a => {
        // const canvas = await generateCanvas(<Tag type="blue" renderIcon={Checkmark16} />)
        // a.ctx.drawImage(canvas, 0, 0);
        // return true;

        const { ctx, theme, hoverAmount } = a;

        drawTextCell({
            ...a, theme: {
                ...theme,
                textDark: interpolateColors('#0f62fe', '#00205c', hoverAmount)
            }
        }, a.cell.displayData, a.cell.contentAlign, a.cell.allowWrapping, a.hyperWrapping)
        return true;
    },
    measure: (ctx, cell, t) => {
        const lines = cell.displayData.split("\n").slice(0, cell.allowWrapping === true ? undefined : 1);
        return Math.max(...lines.map(l => ctx.measureText(l).width + 2 * t.cellHorizontalPadding));
    },
    // draw: (args, cell) => {
    //     const { ctx, rect, theme, hoverX = -100, highlighted } = args;
    //     const { links = [{
    //         title: cell.data.value
    //     }], underlineOffset = 5 } = cell.data;

    //     const xPad = theme.cellHorizontalPadding;

    //     let drawX = rect.x + xPad;

    //     const rectHoverX = rect.x + hoverX;

    //     const font = `${theme.baseFontStyle} ${theme.fontFamily}`;

    //     const middleCenterBias = getMiddleCenterBias(ctx, font);
    //     const drawY = rect.y + rect.height / 2 + middleCenterBias;

    //     for (const [index, l] of links.entries()) {
    //         const needsComma = index < links.length - 1;
    //         const metrics = measureTextCached(l.title, ctx, font);
    //         const commaMetrics = needsComma ? measureTextCached(l.title + ",", ctx, font) : metrics;

    //         const isHovered = rectHoverX > drawX && rectHoverX < drawX + metrics.width;

    //         if (isHovered) {
    //             ctx.moveTo(drawX, Math.floor(drawY + underlineOffset) + 0.5);
    //             ctx.lineTo(drawX + metrics.width, Math.floor(drawY + underlineOffset) + 0.5);

    //             // ctx.lineWidth = 1;
    //             ctx.strokeStyle = theme.textDark;
    //             ctx.stroke();

    //             ctx.fillStyle = highlighted ? blend(theme.accentLight, theme.bgCell) : theme.bgCell;
    //             ctx.fillText(needsComma ? l.title + "," : l.title, drawX - 1, drawY);
    //             ctx.fillText(needsComma ? l.title + "," : l.title, drawX + 1, drawY);

    //             ctx.fillText(needsComma ? l.title + "," : l.title, drawX - 2, drawY);
    //             ctx.fillText(needsComma ? l.title + "," : l.title, drawX + 2, drawY);
    //         }
    //         ctx.fillStyle = theme.textDark;
    //         ctx.fillText(needsComma ? l.title + "," : l.title, drawX, drawY);

    //         drawX += commaMetrics.width + 4;
    //     }

    //     return true;
    // },
    provideEditor: undefined,
};