import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'

import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'

import 'grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.css'
import 'grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.min.js'
import Api from "../session/Api";
import UIUtil from "../util/UIUtil";

function fixImageSrcs(html) {
    const dummySrc = Api.getImage(';');
    const prefix = dummySrc.substring(0, dummySrc.indexOf(';'))
    const getIdFromSrc = src => src.substring(prefix.length)

    let cacheInvalidator = new Date().getTime()
    const createSrc = id => `/api/general/image/${id}?i=${cacheInvalidator}`;


    const tmp = document.createElement('div');
    tmp.innerHTML = html;

    tmp.querySelectorAll('img').forEach(image => {
        const src = image.getAttribute('src');
        if (src) {
            const id = getIdFromSrc(src);
            image.setAttribute('src', createSrc(id))
        }
    })

    return tmp.innerHTML
}


function webpage() {
    return {
        plugins: ['gjs-preset-webpage'],
        pluginsOpts: {
            'gjs-preset-webpage': {
                modalTitleImport: 'Import template',
            }
        }
    }
}

function newsletter() {
    return {
        plugins: ['gjs-preset-newsletter'],
        pluginsOpts: {
            'gjs-preset-newsletter': {
                modalTitleImport: 'Import template',
            }
        },
    }
}

function HTMLEditor({ contentData, newsletterMode }, ref) {
    const editorRef = useRef(null)

    useEffect(() => {
        const editor = grapesjs.init({
            container: '#html-editor',
            height: '100%',
            width: '100%',
            ...(newsletterMode ? newsletter() : webpage()),
            storageManager: false,

            assetManager: {
                upload: false,
                multiUpload: false,
                showUrlInput: false,
                uploadFile: e => {
                    const files = (e.dataTransfer ? e.dataTransfer.files : e.target.files) ?? [];
                    const image = files[0]
                    if (image) {
                        Api.uploadImage(image, response => {
                            if (response.status === true) {
                                UIUtil.showSuccess();
                                editor.AssetManager.add(Api.getImage(response.payload))
                            } else {
                                UIUtil.showError(response.message);
                            }
                        })
                    }
                }
            }
        })
        editorRef.current = editor;


        editor.loadProjectData(contentData)
        return () => {
            editor.destroy();
            editorRef.current = null;
        };
    }, [])

    useImperativeHandle(ref, () => ({
        export: () => {
            if (editorRef.current) {
                let html;

                if (newsletterMode) {
                    html = editorRef.current.runCommand('gjs-get-inlined-html');
                } else {
                    const css = editorRef.current.getCss();
                    html = `<style>${css}</style>`
                    html += editorRef.current.getHtml();
                }

                html = html.replace('<body', '<div');
                html = html.replace('</body>', '</div>');

                html = fixImageSrcs(html);
                console.log(html)
                return {
                    data: editorRef.current.getProjectData(),
                    html: html
                }
            } else {
                return {
                    data: {},
                    html: ''
                }
            }
        }
    }));

    return (
        <div id="html-editor"></div>
    );
}

export default forwardRef(HTMLEditor);