import { useEffect, useRef, useState } from 'react';
import { ADVANCED_NOTES_TYPE } from '.';
import { makeDraggable } from '../../hooks/useDraggable';
import './advanced-notes.scss'
import { Header } from "./header";
import { useNavigator } from './hook/useNavigator';
import { TreeItem } from './tree-item';
import {
    Draggable16
} from '@carbon/icons-react'
import Util from '../../util/Util';
import arrayMove from 'array-move';
import UIUtil from '../../util/UIUtil';
import { NewItemBtn } from './new-item-btn';
import { typeName } from './components/type-name';
import { ItemCreateDialog } from './dialogs/item-create-dialog';
import { ItemEditDialog } from './dialogs/item-edit-dialog';
import { ArchiveDialog } from './dialogs/archive-dialog';
import { InlineLoading } from 'carbon-components-react';
import ReactTooltip from 'react-tooltip';
import Api from '../../session/Api';
import { OBJECT_TYPE_PRO_DESIGN_NODE } from '../../constants/ObjectTypes';
import { isEqual } from 'lodash'

function useItemsManager(parentId, parentType, items, setItems) {
    const ref = useRef(null);
    useEffect(() => {
        const prevItems = ref.current;
        if (prevItems) {
            const itemMapper = (item, index) => {
                if (item.items) {
                    return {
                        id: item.id,
                        orderNo: index,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return { id: item.id, orderNo: index, items: [] };
                }
            }

            const prevOrder = prevItems.map(itemMapper);
            const currOrder = items.map(itemMapper);

            if (!isEqual(prevOrder, currOrder)) {
                Api.reorderAdvancedNotes(parentType, parentId, currOrder, response => {
                    if (response.status !== true) {
                        UIUtil.showError("Failed to save, please refresh page");
                    }
                })
            }
        }
        ref.current = items;
    }, [items])

    const addNewItem = newItem => {
        if (newItem.parentId !== parentId) {
            const itemMapper = item => {
                if (item.id == newItem.parentId) {
                    return {
                        ...item,
                        items: [...item.items, newItem]
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(prevItems => [...prevItems, newItem])
        }
    }
    const updateItem = itemUpdate => {
        if (itemUpdate.parentId !== parentId) {
            const itemMapper = item => {
                if (item.id == itemUpdate.parentId) {
                    return {
                        ...item,
                        items: item.items.map(subItem => subItem.id === itemUpdate.id ? itemUpdate : subItem)
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(prevItems => prevItems.map(subItem => subItem.id === itemUpdate.id ? itemUpdate : subItem))
        }
    }
    const removeItem = itemToRemove => {
        if (itemToRemove.parentId !== parentId) {
            const itemMapper = item => {
                if (item.id == itemToRemove.parentId) {
                    return {
                        ...item,
                        items: item.items.filter(subItem => subItem.id === itemToRemove.id ? false : true)
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(prevItems => prevItems.filter(subItem => subItem.id === itemToRemove.id ? false : true))
        }
    }

    return { addNewItem, updateItem, removeItem }
}

const InputFieldItemHeader = () => (
    <div style={{ width: '1.5rem', height: '3rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'grab' }}>
        <Draggable16 />
    </div>
)

const Item = ({ item, ...props }) => {
    if (item.type == ADVANCED_NOTES_TYPE.FOLDER) {
        return <Section item={item} {...props} />
    } else {
        return <TreeItem item={item} title={item.title} type={item.type} {...props} />
    }
}
const draggable = makeDraggable(Item, InputFieldItemHeader);

const ItemList = ({ parentId, readonly, archive, items, setItems, onNewItem, onEditItem, onArchiveItem, onRestoreItem }) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (Util.isNumberExist(parentId)) {
            const itemMapper = item => {
                if (item.id == parentId) {
                    return {
                        ...item,
                        items: arrayMove(item.items, oldIndex, newIndex)
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(arrayMove(items, oldIndex, newIndex))
        }
    }

    return (<>
        <draggable.SortContainer onSortEnd={onSortEnd}>
            {items.map((item, index) => <draggable.SortItem key={item.id} index={index} readonly={readonly} archive={archive} item={item} setItems={setItems} onNewItem={onNewItem} onEditItem={onEditItem} onArchiveItem={onArchiveItem} onRestoreItem={Util.isNumberExist(parentId) ? undefined : onRestoreItem} />)}
        </draggable.SortContainer>

        {!readonly && <NewItemBtn onNewItem={type => onNewItem(parentId, type)} />}
    </>)
}

const Section = ({ readonly, archive, item, setItems, SortHandler, onNewItem, onEditItem, onArchiveItem, onRestoreItem }) => {
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [expanded])

    return (<>
        <TreeItem readonly={readonly} archive={archive} item={item} isSection itemCount={item.items.length} title={item.title} type={item.type} expanded={expanded} setExpanded={setExpanded} SortHandler={SortHandler} onEditItem={onEditItem} onArchiveItem={onArchiveItem} onRestoreItem={onRestoreItem} />
        {expanded && <div style={{ paddingLeft: '3rem', position: 'relative', animation: 'advanced-notes-section-fade-in 250ms' }}>
            <div style={{ position: 'absolute', left: '1.5rem', top: 0, height: 'calc(100% - 1rem)', width: 1, background: '#1c1c1c40', }} />

            <ItemList readonly={readonly} archive={archive} parentId={item.id} items={item.items} setItems={setItems} onNewItem={onNewItem} onEditItem={onEditItem} onArchiveItem={onArchiveItem} onRestoreItem={onRestoreItem} />
        </div>}
    </>)
}

const ContentView = ({ parentId, parentType, loading, readonly, archive, items, setItems, title, onArchiveBtn, onBackBtn }) => {
    const { addNewItem, updateItem, removeItem } = useItemsManager(parentId, parentType, items, setItems)

    const [archiveVisible, setArchiveVisible] = useState(false);

    const [newDialogVisible, setNewDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [dialogPayload, setDialogPayload] = useState({ type: ADVANCED_NOTES_TYPE.FOLDER });

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [items])

    const onNewItem = (itemParentId, type) => {
        setDialogPayload({
            parentId: Util.isNumberExist(itemParentId) ? itemParentId : parentId,
            parentType: Util.isNumberExist(itemParentId) ? OBJECT_TYPE_PRO_DESIGN_NODE : parentType,
            type
        })
        setNewDialogVisible(true);
    }

    const onEditItem = (item) => {
        setDialogPayload(item)
        setEditDialogVisible(true);
    }

    const onArchiveItem = (item, onDone) => {
        Api.archiveAdvancedNote(item.id, parentId, parentType, response => {
            if (response.status === true) {
                removeItem(item);
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            onDone();
        })
    }

    const onRestoreItem = (item, onDone) => {
        Api.restoreAdvancedNote(item.id, response => {
            if (response.status === true) {
                removeItem(item);
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            onDone();
        })
    }

    return (
        <div style={{ width: '100%', height: '100%', }}>
            <Header onBackBtn={onBackBtn} onArchiveBtn={onArchiveBtn}>{title}</Header>
            {!loading ? (<>
                <div style={{ animation: 'advanced-notes-content-fade-in 250ms', height: 'calc(100% - 3rem)', width: '100%', overflow: 'auto', padding: '1rem' }}>
                    <ItemList readonly={readonly} archive={archive} items={items} setItems={setItems} onNewItem={onNewItem} onEditItem={onEditItem} onArchiveItem={onArchiveItem} onRestoreItem={onRestoreItem} />
                </div>

                <ItemCreateDialog onNewItem={addNewItem}
                    parentId={dialogPayload?.parentId ?? parentId} parentType={dialogPayload?.parentType ?? parentType}
                    type={dialogPayload?.type ?? ADVANCED_NOTES_TYPE.FOLDER}
                    visible={newDialogVisible} onClose={() => setNewDialogVisible(false)} />
                <ItemEditDialog item={dialogPayload} visible={editDialogVisible} onClose={() => setEditDialogVisible(false)} onUpdateItem={updateItem} />

                <ArchiveDialog visible={archiveVisible} onClose={() => setArchiveVisible(false)} />
            </>) : (<>
                <div className='really_centered-progress-bar' style={{ width: '100%', height: 'calc(100% - 3rem)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <InlineLoading />
                </div>
            </>)}

            <ReactTooltip />
        </div>
    )
}


const MainContent = ({ title, readonly, objectId, objectType, onArchiveBtn }) => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true);
        Api.getAdvancedNotes(objectType, objectId, false, response => {
            if (response.status === true) {
                setItems(response.payload)
                setLoading(false);
            } else {
                UIUtil.showError("Cannot load");
            }
        })
    }, [])

    return <ContentView parentId={objectId} parentType={objectType} loading={loading} title={title} items={items} setItems={setItems} readonly={readonly} onArchiveBtn={!readonly ? onArchiveBtn : undefined} />
}

const ArchiveContent = ({ objectId, objectType, onBackBtn }) => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true);
        Api.getAdvancedNotes(objectType, objectId, true, response => {
            if (response.status === true) {
                setItems(response.payload)
                setLoading(false);
            } else {
                UIUtil.showError("Cannot load");
            }
        })
    }, [])

    return <ContentView parentId={objectId} parentType={objectType} loading={loading} title="Archive" onBackBtn={onBackBtn} readonly archive items={items} setItems={setItems} />
}

export function AdvancedNotesView({ title, readonly, objectType, objectId }) {
    const { page, openFolder, back } = useNavigator();

    return (
        <div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
            {page === 0 ?
                <MainContent title={title} readonly={readonly} objectId={objectId} objectType={objectType} onArchiveBtn={openFolder} /> :
                <ArchiveContent objectId={objectId} objectType={objectType} onBackBtn={back} />}
        </div>
    )
}