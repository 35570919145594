import { Star24, StarFilled24 } from '@carbon/icons-react';
import React from 'react';

const Star = ({filled, last, editable, onClick}) => {
    const Icon = filled ? StarFilled24 : Star24;
    return (
        <Icon className={editable && "rate-star"} 
        style={{marginRight: last ? 0 : 5, cursor: editable && 'pointer', color: filled ? "#f26622" : "#f26622"}} onClick={onClick} /> 
    )
}


const Rate = ({disabled, value, style, defaultValue, onChange}) => {
    const [rate, setRate] = React.useState(defaultValue !== undefined ? defaultValue : value);

    return (
        <div style={{display: 'flex', height: 31, ...style}}>
            {[1,2,3,4,5].map(star => 
            <Star editable={!disabled} filled={star <= rate} 
            onClick={() => {
                if (!disabled) {
                    setRate(star == rate ? 0 : star);
                    onChange(star == rate ? 0 : star)
                }
            }} />)}
        </div>
    )
}

export default Rate;