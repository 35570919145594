import { ComposedModal, ModalBody, ModalFooter, ModalHeader, Tab, Tabs } from "carbon-components-react";
import Button from "../../../components/Button";
import { MinimalTable } from "../../../components/minimal-table";
import { Pie } from 'react-chartjs-2';

const ListItem = ({ total, item }) => {
    const noTag = item.tagCount === 0;
    return (
        <div style={{ position: 'relative', background: 'white', border: '1px solid #00000040', borderRadius: 7, borderBottomLeftRadius: 0, overflow: 'hidden', marginBottom: '0.5rem' }}>
            <div style={{ height: 45 + 2, display: 'flex', alignItems: 'center', }}>
                <div style={{ flex: 3, paddingLeft: '1rem' }}>
                    <p style={{ fontSize: 14, fontStyle: noTag ? 'italic' : undefined, opacity: noTag ? 0.65 : undefined }}>{item.name}</p>
                </div>
                <div style={{ flex: 1, height: '100%', background: '#00000020', borderLeft: '1px solid #00000040', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h6>AED {item.amount.toFixed(2)}</h6>
                </div>
            </div>
            <div style={{ position: 'absolute', left: 0, bottom: 0, width: `${(item.amount / total) * 100}%`, height: 2, background: 'green' }} />
        </div>
    )
}

const TotalItem = ({ balance }) => {
    return (
        <div style={{ background: 'black', color: 'white', border: '1px solid #ffffff', borderRadius: 7, overflow: 'hidden', marginBottom: '0.5rem' }}>
            <div style={{ height: 45, display: 'flex', alignItems: 'center', }}>
                <div style={{ flex: 3, paddingLeft: '1rem' }}>
                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>Total</p>
                </div>
                <div style={{ flex: 1, height: '100%', background: '#ffffff20', borderLeft: '1px solid #ffffff40', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h6>AED {balance.toFixed(2)}</h6>
                </div>
            </div>
            <div style={{ width: "100%", height: 4, background: 'green' }} />
        </div>
    )
}



export function BalanceTagDivisionDialog({ total, balanceTags, visible, onClose }) {
    return (
        <ComposedModal open={visible} onClose={onClose}>
            <ModalHeader label="Balance Divison" title="Total by classes" />
            <ModalBody hasScrollingContent>
                <Tabs>
                    <Tab label="Table">
                        <MinimalTable items={[...balanceTags, { name: "Total", amount: total, isTotal: true }]} columns={[
                            {
                                name: "name",
                                title: "Name",
                                flex: 3,
                                renderCell: item => {
                                    if (item.tagCount === 0) {
                                        return <span style={{ fontStyle: 'italic', opacity: 0.65 }}>{item.name}</span>
                                    } else if (item.isTotal) {
                                        return <span style={{ fontWeight: 'bold', marginTop: '0.5rem', fontSize: 14 }}>{item.name}</span>
                                    } else {
                                        return item.name;
                                    }
                                }
                            },
                            {
                                name: "amount",
                                title: "Amount",
                                flex: 1,
                                renderCell: item => {
                                    if (item.isTotal) {
                                        return <span style={{ fontWeight: 'bold', marginTop: '0.5rem', fontSize: 14 }}>{'AED ' + item.amount}</span>;
                                    } else {
                                        return 'AED ' + item.amount;
                                    }
                                }
                            }
                        ]} />
                        <div style={{ height: 45 }} />
                    </Tab>
                    <Tab label="List">
                        {balanceTags.map(tag => <ListItem key={'list-item-' + tag.name} item={tag} total={total} />)}
                        <TotalItem balance={total} />
                        <div style={{ height: 45 }} />
                    </Tab>
                    <Tab label="Chart">
                        <div style={{ position: 'relative', height: 300, width: 450 }}>
                            <Pie data={{
                                labels: balanceTags.map(tag => tag.name),
                                datasets: [
                                    {
                                        label: 'Amount',
                                        data: balanceTags.map(tag => tag.amount),
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)',
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)',
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                            }} />
                        </div>
                        <div style={{ height: 150 }} />
                    </Tab>
                </Tabs>
            </ModalBody>
            <ModalFooter
            // onRequestSubmit={() => this.startSession()}
            // primaryButtonText="Start" secondaryButtonText="Cancel"
            >

                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}