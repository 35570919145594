import React from 'react'
import Page from '../../base/Page';
import JournalEntriesListView from './JournalEntriesListView';

class JournalEntriesPage extends Page {

    render() {
        return (
            <div className="main-content">
                <JournalEntriesListView />
            </div>
        )
    }

}

export default JournalEntriesPage;