import { ComboBox, ComposedModal, DataTableSkeleton, InlineLoading, ModalBody, ModalFooter, ModalHeader, Tag } from "carbon-components-react";
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button"
import Table from "../../../components/data-table/Table";
import { ACCOUNT_TYPE_GUARDIAN, ACCOUNT_TYPE_STUDENT, STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../../../constants/Constants";
import useStore from "../../../hooks/useStore";
import Api from "../../../session/Api";
import { makeObservable } from "../../../util/makeObservable";
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { Document16, DocumentAdd16, ChartBullet16, GraphicalDataFlow16, Activity16, Add16 } from '@carbon/icons-react'
import { ServiceSaleStatusIndicator, SERVICE_SALE_STATUS_NOT_PAID, SERVICE_SALE_STATUS_PAID, SERVICE_SALE_STATUS_PARTIALLY_PAID, SERVICE_SALE_STATUS_REVOKED, getServiceSaleStatusDetails } from "../../../domain/service-sale";
import { getAccountRole } from "../../../session/SessionManager";



const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)

const StatusTab = ({ selectedStatus, onSelect, status }) => {
    if (status == -1) {
        return <TabItem key={status + '-tab-item-stock-request'} title={"All"} icon={GraphicalDataFlow16} selected={selectedStatus == status} onClick={() => onSelect(status)} />
    }
    let { icon, text } = getServiceSaleStatusDetails(status);
    return <TabItem key={status + '-tab-item-stock-request'} icon={icon} title={text} selected={selectedStatus == status} onClick={() => onSelect(status)} />
}

const Tabs = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    return (
        <>
            <StatusTab status={-1} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={SERVICE_SALE_STATUS_NOT_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={SERVICE_SALE_STATUS_PARTIALLY_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={SERVICE_SALE_STATUS_PAID} selectedStatus={statusFilter} onSelect={setStatusFilter} />
            <StatusTab status={SERVICE_SALE_STATUS_REVOKED} selectedStatus={statusFilter} onSelect={setStatusFilter} />

            <div style={{ flex: 1 }} />
        </>
    )
}


const COLUMNS = [
    // {
    //     id: "id",
    //     name: "ID",
    //     render: item => Util.getVoucherNumber(item.id)
    // },
    {
        id: "payerId",
        name: "Customer",
        render: item => Util.isNumberExist(item.payerId) ? item.payerName : 'Guest'
    },
    // {
    //     id: "payeeType",
    //     name: "Payee Type",
    //     render: item => getObjectTypeName(item.payeeType)
    // },
    // {
    //     id: "saleDate",
    //     name: "Sale Date",
    //     render: item => Util.getDate(item.saleDate)
    // },
    {
        id: "dateCreated",
        name: "Date Created",
        render: item => (<>
            {Util.getDate(item.initiationDate)}
            {item.live && <Tag size="sm" type="magenta" renderIcon={Activity16}>Live</Tag>}
        </>)
    },
    // {
    //     id: "amount",
    //     name: "Amount",
    //     render: item => item.amount.toFixed(2)
    // },
    // {
    //     id: "amountPaid",
    //     name: "Amount Paid",
    //     render: item => item.amountPaid.toFixed(2)
    // },
    // {
    //     id: "status",
    //     name: "Status",
    //     render: item => <ServiceSaleStatusIndicator key={item.id + "-table-status-indicator-stock-request"} status={item.status} />
    // }
]
const NewBtn = () => {
    const history = useHistory()

    if (getAccountRole() == ACCOUNT_TYPE_STUDENT || getAccountRole() == ACCOUNT_TYPE_GUARDIAN) {
        return null;
    }
    return <Button onClick={() => history.push("/service-sale-creator")} renderIcon={Add16}>Create Service Sale</Button>
}

const List = ({ tabs, status }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setLoading(true)
        Api.getServiceSales(0, status, true, response => {
            if (response.status === true) {
                setRows(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const accountRole = getAccountRole();

    return (
        <Table
            hasFooter
            {...({
                title: "Movie Ticket Sales",
                description: "Manage movie ticket sales"
            })}
            rows={rows} columns={COLUMNS.filter(column => {
                if (accountRole == ACCOUNT_TYPE_STUDENT) {
                    return column.id !== "payerId";
                } else {
                    return true;
                }
            }).map(column => {
                if (accountRole == ACCOUNT_TYPE_GUARDIAN && column.id === "payerId") {
                    return { ...column, name: "Name" }
                } else {
                    return column;
                }
            })}
            onClick={id => {
                if (accountRole == ACCOUNT_TYPE_STUDENT || getAccountRole() == ACCOUNT_TYPE_GUARDIAN) {
                    history.push("/invoice/" + id)
                } else {
                    history.push("/service-sale/" + id)
                }
            }}
        //rightContent={<NewBtn />}
        >
            {tabs}
            {loading && <InlineLoading style={{ marginLeft: '1rem' }} />}
        </Table>
    )
}

const Content = ({ store }) => {
    const [statusFilter, setStatusFilter] = useStore(store, 'statusFilter');
    // const tabs = <Tabs store={store} />;
    return (
        <List
            key={'table-list-service-sales-documents-' + statusFilter}
            // tabs={tabs}
            status={statusFilter}
        />
    )
}

export default ({ }) => {
    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("statusFilter", -1)
        return observable;
    }, [])


    return (
        <div className="main-content">
            <Content store={store} />
        </div>
    )
}