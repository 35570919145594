import { isV2 } from "../util/Util";


export function getCashGroup(item) {
    if (isV2()) {
        return item;
    }

    if (item.items) {
        const cash = item.items.find(item => item.name == "Cash and cash equivalents" || item.name == "Cash On Hand & At Bank" || item.name.includes("Cash & Cash Equivalent"));
        if (cash) {
            return cash;
        } else {
            for (const otherItem of item.items) {
                const cash = getCashGroup(otherItem);
                if (cash) {
                    return cash;
                }
            }
        }
    } else {
        return []
    }
}

export function getReceivableGroup(item) {
    if (isV2()) {
        return item;
    }

    if (item.items) {
        const cash = item.items.find(item => item.name == "Receivables" || item.name.includes("Account Receivable"));
        if (cash) {
            return cash;
        } else {
            for (const otherItem of item.items) {
                const cash = getReceivableGroup(otherItem);
                if (cash) {
                    return cash;
                }
            }
        }
    } else {
        return []
    }
}

export function getPayableGroup(item) {
    if (isV2()) {
        return item;
    }

    if (item.items) {
        const cash = item.items.find(item => item.name == "Payables" || item.name.includes("Accounts Payables"));
        if (cash) {
            return cash;
        } else {
            for (const otherItem of item.items) {
                const cash = getPayableGroup(otherItem);
                if (cash) {
                    return cash;
                }
            }
        }
    } else {
        return []
    }
}