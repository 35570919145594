import { Checkbox, ComboBox, InlineLoading, Tag, TextInput } from "carbon-components-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DynamicTable } from "../../../components/dynamic-table"

import {
    Product16, Box16, ServiceDesk16, Application16, WatsonHealthTextAnnotationToggle16
} from '@carbon/icons-react'
import Util, { absAmt, big } from "../../../util/Util"
import InfiniteComboBox from "../../../components/InfiniteComboBox"
import ProfilePic from "../../../components/ProfilePic"
import Api from "../../../session/Api"
import UIUtil from "../../../util/UIUtil"
import { AmountTagSelectorNormalField } from "../../../pages/journal-entries/tag-selector"
import { TAX_TYPES } from "../../../2v/modules/sales"

const COLUMNS = [
    {
        title: "",
        width: '7px'
    },
    {
        title: "Item",
        flex: 4
    },
    {
        title: "Destination",
        flex: 4
    },
    {
        title: "Class",
        flex: 3
    },
    {
        title: "Memo",
        flex: 3
    },
    {
        title: "Amt",
        flex: 1
    },
    {
        title: "Qty",
        flex: 1
    },
    {
        title: "Total",
        flex: 2
    },
    {
        title: "Tax",
        flex: 2
    },
]

const ItemView = ({ item }) => {
    return (
        <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020' }}>
            {/* <ProfilePic notProfile size={24} /> */}
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
            {item.type === "PRODUCT" && <p style={{ fontSize: 12, opacity: 0.65 }}>Product</p>}
            {item.type === "EXPENSE" && <p style={{ fontSize: 12, opacity: 0.65 }}>Expense</p>}
            {item.type === "ASSET" && <p style={{ fontSize: 12, opacity: 0.65 }}>Asset</p>}
            {/* <TypeTag type={item.type} size="sm" /> */}
        </div>
    )
}
ItemView.noPadding = true;

const ItemSelector = ({ billables, item, setItem, light }) => {
    const textValue = item?.name;
    const setTextValue = name => {
        const itemToSelect = billables.find(item => item.name === textValue);
        if (itemToSelect) {
            setItem({ ...item, name, selectedItem: itemToSelect })
        } else {
            setItem({ ...item, name, selectedItem: undefined })
        }
    }
    const selectedItem = item?.selectedItem;
    const setSelectedItem = selectedItem => {
        setItem({ ...item, name: selectedItem ? selectedItem.name : textValue, selectedItem })
    }

    const onClear = () => setItem({ ...item, name: "", selectedItem: undefined })

    return <InfiniteComboBox light={light} ItemView={ItemView} itemToString={item => item.name} items={billables}
        {...({ textValue, setTextValue, selectedItem, setSelectedItem, onClear })} />
}

const Record = ({ billables, predefinedClasses, locations, item: recordItem, setItem: setRecordItem, useValue }) => {
    const [item, setItem] = useState({ name: "", selectedItem: undefined });
    const [amount, setAmount] = useState(undefined);
    const [qty, setQty] = useState(undefined);

    const [taxName, setTaxName] = useState(item?.selectedItem?.taxName);
    const [tags, setTags] = useState(item?.selectedItem?.tags ?? []);

    const [destinationId, setDestinationId] = useState(0);
    const [memo, setMemo] = useState("")

    const type = (item?.selectedItem?.type) ?? -1;
    const total = useMemo(() => big(qty).times(big(amount)).toFixed(2), [amount, qty])

    const firstTimeRef = useRef(true)
    useEffect(() => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        }

        setRecordItem({ ...recordItem, item, amount, qty, taxName, tags, destinationId, memo })
    }, [item, amount, qty, taxName, tags, destinationId, memo])

    useEffect(() => {
        if (!(recordItem.item === undefined)) {
            if (type !== "PRODUCT") {
                setQty(1);
            }
        }
    }, [item])

    const onSetItem = newItem => {
        if (item.selectedItem?.type !== newItem.selectedItem?.type || item.selectedItem?.id !== newItem.selectedItem?.id) {
            setItem(newItem);
            setAmount(newItem.selectedItem?.amount ?? "")
        } else {
            setItem(newItem)
        }
        if (big(qty).lte(big(0))) {
            setQty(1)
        }
    }

    return [
        <div />,
        <div style={{ height: 40, width: '100%' }}>
            <ItemSelector billables={billables} item={item} setItem={onSetItem} />
        </div>,
        <div className="no-input-border" style={{ height: 40, width: '100%', minWidth: 0 }}>
            <ComboBox
                placeholder="Dest"
                items={locations}
                itemToString={item => item !== null ? item.value : ""}
                selectedItem={locations.find($ => $.id === destinationId)}
                onChange={e => setDestinationId(e.selectedItem?.id)} />
        </div>,
        <div style={{ minHeight: 40, width: '100%', minWidth: 0, maxWidth: '100%', position: 'relative' }}>
            <AmountTagSelectorNormalField minimalInput skipBottomBar options={predefinedClasses} value={tags} setValue={setTags} />
        </div>,
        <TextInput value={memo} onChange={e => setMemo(e.target.value)} placeholder="Input..." />,
        <TextInput value={amount} onChange={e => setAmount(absAmt(e.target.value))} placeholder="Input..." />,
        type === "PRODUCT" ? (
            <TextInput value={qty} onChange={e => setQty(absAmt(e.target.value))} placeholder="Input..." />
        ) : (
            <TextInput value={""} placeholder="N/A" style={{ pointerEvents: 'none' }} />
        ),
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 5 }} />
            <p style={{ fontSize: 14, opacity: 0.65, fontWeight: 'bold' }}>AED {total}</p>
        </div>,
        <div className="no-input-border" style={{ height: 40, width: '100%', minWidth: 0 }}>
            <ComboBox
                placeholder="Code"
                items={TAX_TYPES}
                itemToString={item => item !== null ? item.value : ""}
                selectedItem={TAX_TYPES.find($ => $.value === taxName)}
                onChange={e => setTaxName(e.selectedItem?.value)} />
        </div>
    ]
}


export const BillItemsTable = ({ billables, locations, predefinedClasses, editable, items, setItems }) => {
    return (
        <div style={{ width: '100%' }}>
            <DynamicTable
                minimalInput
                noBorder noRadius lightBorder
                items={items} setItems={setItems}
                editable={editable} entryView={Record}
                columns={COLUMNS} entryViewProps={{
                    billables, predefinedClasses, locations
                }} />
        </div>
    )
}