import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';

import { 
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Save16,
    Reset16,
    FlowStream32,
    DirectionStraightRight32,
    TextLinkAnalysis32,
    Calendar32,
    Calculator32,
    Run32,
    DeliveryParcel32,
    Currency32,
    Notebook16,
    DeliveryParcel16,
    Money16,
    Archive32,
    TagEdit16,
    Box16,
    Report32,
    Launch32,
    Catalog32,
    Money32,
    FlowStream16,
    User32,
    RequestQuote16,
    ShoppingCartArrowUp32,
    Analytics32,
    Purchase16,
    Cafe16,
    Star16,
    Time16,
    Hourglass16,
    ErrorFilled16,
    CheckmarkFilled16,
    Notebook32,
    ArrowLeft16
} from '@carbon/icons-react'
import Button from '../../components/Button';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_POS_SESSION } from '../../constants/ObjectTypes';
import ProfilePic from '../../components/ProfilePic';
import { Link } from 'react-router-dom';
import Util from '../../util/Util';
import Rate from '../../components/Rate';
import { Tag, Link as DLink } from 'carbon-components-react';
import StockFlowListView from '../stock-flow/StockFlowListView';
import NoteView from '../../views/notes/NoteView';

const Section = ({id, children, icon, title, subtitle, extraTopMargin}) => (
    <div id={id} style={{marginTop: extraTopMargin ? '6rem' : '3rem'}}>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
            <div style={{width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25}} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25}} />
            {subtitle !== undefined && <>
                <p style={{fontSize: 12, opacity: 0.65, marginLeft: '0.5rem'}}>{subtitle}</p>
                <div style={{width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25}} />
            </>}
        </div>
        {children}
    </div>
)

class PosSessionItemPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            posSession: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getPosSessionItem(this.getPathParams().posSessionId, listener), payload => ({
            posSession: payload
        }))
    }

    renderStatus() {
        const row = this.state.posSession;
        const cell = { value: row.ended }
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Tag renderIcon={cell.value ? ErrorFilled16 : CheckmarkFilled16} style={{color: cell.value ? undefined : 'green', margin: 0}}>
                    {cell.value ? "Ended" : "Active"}
                </Tag>
                
            </div>
        )
    }

    renderTimeStatus() {
        const row = this.state.posSession;
        const cell = { value: row.ended }
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{marginLeft: '0rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <label className="bx--label" style={{marginBottom: 0, fontWeight: 'bold'}}>Total active</label>
                    <p style={{fontSize: 14,}}>{Util.formatSecondsPretty(((row.endDate - row.startDate) - row.totalBreakTime) / 1000)}</p>
                </div>

                <div style={{marginLeft: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <label className="bx--label" style={{ marginBottom: 0, fontWeight: 'bold'}}>Total break</label>
                    <p style={{fontSize: 14,}}>{Util.formatSecondsPretty(row.totalBreakTime / 1000)}</p>
                </div>
                {/* <div style={{minHeight: '0.5rem'}} /> */}
            </div>
        )
    }

    renderStats() {
        const posSession = this.state.posSession;
        return (
            <div>
                <div style={{display: 'flex'}}>
                    {posSession.totalSales !== null && <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, marginTop: '1rem', color: 'green'}}>
                            <Purchase16 /> 
                            <p style={{ marginLeft: 5 }}>
                                Total sales
                            </p>
                        </div>
                        <h3 style={{margin: 0, color: 'green'}}>AED {posSession.totalSales.toFixed(2)}</h3>
                    </div>}

                    <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, marginTop: '1rem', color: 'black'}}>
                            <DeliveryParcel16 /> 
                            <p style={{ marginLeft: 5 }}>
                                Checkouts
                            </p>
                        </div>
                        <h3 style={{margin: 0}}>{posSession.checkoutCounts}</h3>
                    </div>
                    <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 7, marginTop: '1rem', color: '#f26622'}}>
                            <Star16 /> 
                            <p style={{ marginLeft: 5 }}>
                                Average Reviews
                            </p>
                        </div>
                        <Rate disabled value={posSession.averageReviews} />
                    </div>
                </div>
            </div>
        )
    }

    getLayout() {
        const posSession = this.state.posSession;

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem'}}>
                <div style={{width: '75vw'}}>
                    <DLink onClick={() => this.props.history.goBack()} style={{marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center'}}><ArrowLeft16 style={{marginRight: '0.25rem'}} /> Back to list</DLink>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Calculator32 style={{marginRight: '0.25rem', width: 40, height: 40}} />
                        <h1 style={{flex: 1}}>POS Session</h1>

                        <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                    </div>

                    <Section icon={Report32} title="Session Info">
                        <label style={{}} className="bx--label">Operator</label>
                        <Link style={{textDecoration: 'none', color: 'black'}} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + posSession.userId}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ProfilePic size={30} style={{marginRight: '0.5rem'}} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, posSession.userId)} />
                                <p>{posSession.userFullName}</p>
                            </div>
                        </Link>

                        <label style={{ marginTop: '1rem'}} className="bx--label">Status</label>
                        {this.renderStatus()}
                        <label style={{ marginTop: '0.25rem'}} className="bx--label"></label>
                        {this.renderTimeStatus()}


                        <label style={{marginTop: '3rem'}} className="bx--label">Opening Date</label>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Calendar32 style={{marginRight: '0.5rem'}} />
                            <p>{Util.getDate(posSession.startDate)}</p>
                        </div>
                        {posSession.startAmount !== undefined && posSession.startAmount !== null && <>
                        <label style={{marginTop: '1rem'}} className="bx--label">Starting Amount</label>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p>AED {posSession.startAmount.toFixed(2)}</p>
                        </div></>}


                        <label style={{marginTop: '3rem'}} className="bx--label">Closing Date</label>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Calendar32 style={{marginRight: '0.5rem'}} />
                            <p>{Util.getDate(posSession.endDate)}</p>
                        </div>
                        {posSession.endAmount !== undefined && posSession.endAmount !== null && <>
                        <label style={{marginTop: '1rem'}} className="bx--label">Closing Amount</label>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p>AED {posSession.endAmount.toFixed(2)}</p>
                        </div> </>}
                    </Section>

                    <Section icon={Analytics32} title="Statistics"> 
                        {this.renderStats()}
                    </Section>


                    <Section icon={ShoppingCartArrowUp32} title="Sales">
                        <StockFlowListView report={posSession.salesReport} hideToolbar salesReport />
                    </Section>

                    <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                        <NoteView objectId={posSession.id} objectType={OBJECT_TYPE_POS_SESSION} />
                    </Section>
                </div>
            </div>
        )
    }
}

export default PosSessionItemPage;