import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Page from '../../base/Page';

import {
    Settings32,
    ArrowRight16,
    ArrowLeft16
} from '@carbon/icons-react'
import { SETTINGS_VIEWS } from './SettingsViews';

import './SystemSettingsPage.scss'
import { Link } from 'carbon-components-react';
import { Link as LK } from 'react-router-dom'
import Api from '../../session/Api';
import { getReportLink } from '../../reporting-engine/base/report-page';


const ViewBtn = ({ view, onClick, disable }) => {
    return (
        <div onClick={disable ? undefined : onClick} className="system-settings-group-btn" style={{ marginBottom: '0.5rem', cursor: disable ? 'default' : 'pointer' }}>
            {React.createElement(view.icon)}
            <h6 style={{ flex: 1, }}>{view.title}</h6>
            <ArrowRight16 />
        </div>
    )
}

const SettingsView = ({ view }) => {

    if (!view) {
        return null;
    }

    return (
        <div style={{ width: '100%', animation: 'settings-view-fade-in 250ms' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3rem' }}>
                {React.createElement(view.largeIcon, {
                    style: { width: 64, height: 64, marginRight: '0.5rem' }
                })}
                <div>
                    <h1>{view.title}</h1>
                    <p style={{ fontSize: 18 }}>{view.subtitle}</p>
                </div>
            </div>
            {React.createElement(view.component)}
        </div>
    )
}

export default () => {
    const [currentView, setCurrentView] = useState(null);
    // const [hideView, setHideView] = useState(false);

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', paddingTop: '4rem', paddingBottom: '6rem', overflowX: 'hidden' }}>
            <div style={{ width: '75vw' }} className="menu-tile">
                <div style={{ height: '2rem', display: 'flex', alignItems: 'flex-start', }}>
                    {currentView &&
                        <Link onClick={() => setCurrentView(null)} style={{ cursor: 'pointer', animation: 'settings-back-fade-in 250ms' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back</Link>}
                </div>

                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 0, width: '100%', transition: 'opacity 250ms', opacity: currentView ? 0 : 1, pointerEvents: currentView ? 'none' : undefined }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3rem' }}>
                            <Settings32 style={{ width: 64, height: 64, marginRight: '0.5rem' }} />
                            <div>
                                <h1>System Settings</h1>
                                <p style={{ fontSize: 18 }}>Update and set system settings</p>
                            </div>
                        </div>
                        {SETTINGS_VIEWS.map(view => <ViewBtn view={view} onClick={() => setCurrentView(view)} disable={currentView !== null && currentView !== undefined} />)}
                    </div>

                    <SettingsView view={currentView} />
                </div>
            </div>
        </div>
    )
}