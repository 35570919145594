import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { User32, User24 } from '@carbon/icons-react'
import { TextBox } from "../../components/basic-filter/text-box"
import { FormSection } from "../../../templates/form/form"

export const EmployeeList = newListDetail({
    title: "Employee",
    icon: User32,
    mdIcon: User24,

    filter: Filter, newForm: Form, updateForm: Form,

    defaultValues: {

    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Form({ endpoint, form }) {
    return (
        <div>
            <form.TextField fieldKey="name" title="Name" />
            <form.TextField fieldKey="idNo" title="ID No" />
            <FormSection title={"Detail"}>
                <form.TextField fieldKey="workPlace" title="Work Place" />
                <form.ComboBoxField fieldKey="costCenter" title="Cost Center" options={endpoint.predefinedClasses.map($ => ({ id: $.value, value: $.value }))} />
                <form.TextField fieldKey="designation" title="Designation" />
            </FormSection>
            <FormSection title={"System Access"}>
                <form.ComboBoxField fieldKey="userId" title="User" options={endpoint.staffUsers} />
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <TextBox state={state} label="Name" property="name" fullWidth />
    </>)
}
