import { useEffect, useMemo, useRef, useState } from "react";
import Portal from "../../../../util/Portal";
import "./styles.scss"
import { Close16, AirlinePassengerCare20, ArrowRight16, Wheat20, Receipt20, Information16, Cube20, InformationSquareFilled16 } from '@carbon/icons-react'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { InlineLoading, Tag } from "carbon-components-react";
import Api from "../../../../session/Api";
import { useDebounce } from "../../../../hooks/useDebounce";
import Util from "../../../../util/Util";
import { SidePanel } from "../../../../templates/draft/components/side-panel";
import ProfilePic from "../../../../components/ProfilePic";
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../../../constants/ObjectTypes";


export function createAnySearchDialog({
    ItemComponent, searchLoader, title = "Search", searchPlaceholder = "Search...",
    Root = Portal, hasBackdrop = true
}) {
    const useLoadItems = ({ key, searchValue }) => {
        const [page, setPage] = useState(0);
        const [loading, setLoading] = useState(false);
        const [items, setItems] = useState([]);
        const [hasNextPage, setHasNextPage] = useState(true);
        const [error, setError] = useState(undefined);

        useEffect(() => {
            setPage(0);
            setLoading(false);
            setItems([]);
            setHasNextPage(true);
            setError(undefined)
        }, [searchValue, key])

        const loadMore = () => {
            setLoading(true);
            searchLoader(searchValue, page, response => {
                if (response.status === true) {
                    setPage(page + 1);
                    setLoading(false);
                    setItems(items => [...items, ...response.payload.items])
                    setHasNextPage(response.payload.hasMore);
                    setError(undefined);
                } else {
                    setLoading(false);
                    setError(response.message);
                }
            })
        }

        return {
            loading, items, hasNextPage, loadMore, error
        }
    }

    const SearchBar = ({ visible, value, setValue }) => {
        const searchRef = useRef();
        useEffect(() => {
            if (visible && searchRef.current) {
                searchRef.current.focus();
            }
        }, [visible])
        return <input ref={searchRef} value={value} onChange={e => setValue(e.target.value)} placeholder={searchPlaceholder} style={{ width: '100%', height: 45, border: 'none', background: '#f4f4f4', padding: '1rem', outline: 'none' }} />
    }

    const Item = ({ item, onClick }) => {
        return (<>
            <div onClick={onClick} className="item" style={{ minHeight: '4rem', paddingInline: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <ItemComponent item={item} />

            </div>
            <div style={{ width: 'calc(100% - (1.5rem * 2))', marginLeft: '1.5rem', marginTop: -1, height: 1, background: '#00000020' }} />
        </>)
    }

    function AnySearchDialog({ visible, onClose, onItemSelected, bottomChildren }) {
        const [searchValue, setSearchValue] = useState("");
        const [key, setKey] = useState(() => Util.newTempId())
        const debouncedSearch = useDebounce(searchValue, 500)
        const { loading, items, hasNextPage, error, loadMore } = useLoadItems({
            key, searchValue: Util.isStringExists(searchValue) ? debouncedSearch : ""
        });

        useEffect(() => {
            if (visible) {
                setKey(Util.newTempId());
                setSearchValue("");
            }
        }, [visible])

        const [sentryRef, { rootRef }] = useInfiniteScroll({
            loading,
            hasNextPage,
            onLoadMore: loadMore,
            disabled: !!error,
            rootMargin: '0px 0px 400px 0px',
        });

        return (
            <Root>
                <div className="any-search-dialog">
                    {hasBackdrop && <div className={"backdrop " + (visible ? 'backdrop-visible' : "backdrop-hidden")} onClick={onClose} />}

                    <div className={"card " + (visible ? "card-visible" : "card-hidden")}>
                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', padding: '1rem' }}>
                            <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                            <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                                <Close16 />
                            </button>
                        </div>
                        <SearchBar value={searchValue} setValue={setSearchValue} />
                        <div ref={rootRef} style={{ flex: 1, overflow: 'auto', width: '100%' }}>
                            {items.map(item => (
                                <Item key={item.id} item={item} onClick={() => {
                                    onClose();
                                    onItemSelected(item);
                                }} />
                            ))}
                            <div ref={sentryRef} />

                            {(loading || hasNextPage) ? (
                                <div className="really_centered-progress-bar" style={{ height: '3rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                                    <InlineLoading style={{ width: 'unset' }} />
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>Loading...</p>
                                </div>
                            ) : (
                                <div className="really_centered-progress-bar" style={{ height: '3rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>{items.length ? 'No more items' : 'No items'}</p>
                                </div>
                            )}
                        </div>
                        {bottomChildren}
                    </div>
                </div>
            </Root>
        )
    }

    return AnySearchDialog
}

export const InvoiceSearchDialog = createAnySearchDialog({
    title: "Invoices",
    searchPlaceholder: "Customer name or invoice no",
    searchLoader: Api.searchInvoice.bind(Api),
    ItemComponent: ({ item }) => {
        const values = useMemo(() => {
            let data = [];
            const addToData = (key, mapper) => {
                if (item?.[key]) {
                    if (mapper) {
                        data.push(mapper(item[key]));
                    } else {
                        data.push(item[key]);
                    }
                }
            }

            addToData('destinationName')
            addToData('initiationDate', date => Util.getDate(date))
            addToData('amount', amount => 'AED ' + amount)

            return data.join('  •  ');
        }, [item])

        return (<>
            <Receipt20 />
            <div style={{ flex: 1 }}>
                <p>Invoice #{Util.getVoucherNumber(item.id)}</p>
                <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p>
            </div>
            <ArrowRight16 />
        </>)
    }
})


export const IngredientSearchDialog = createAnySearchDialog({
    title: "Ingredients",
    searchPlaceholder: "Search...",
    searchLoader: Api.searchIngredients.bind(Api),
    hasBackdrop: false,
    Root: ({ children }) => {
        return (
            <div>
                {children}
            </div>
        )
    },
    ItemComponent: ({ item }) => {
        // const values = useMemo(() => {
        //     let data = [];
        //     const addToData = (key, mapper) => {
        //         if (item?.[key]) {
        //             if (mapper) {
        //                 data.push(mapper(item[key]));
        //             } else {
        //                 data.push(item[key]);
        //             }
        //         }
        //     }

        //     addToData('destinationName')
        //     addToData('initiationDate', date => Util.getDate(date))
        //     addToData('amount', amount => 'AED ' + amount)

        //     return data.join('  •  ');
        // }, [item])

        return (<>
            <Wheat20 />
            <div style={{ flex: 1 }}>
                <p>{item.name}</p>
                {/* <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p> */}
            </div>
            <ArrowRight16 />
        </>)
    }
})


export const ProducibleBundlesSearchDialog = createAnySearchDialog({
    title: "Producible Bundles",
    searchPlaceholder: "Search...",
    searchLoader: Api.searchProducibleBundles.bind(Api),
    hasBackdrop: true,
    // Root: ({ children }) => {
    //     return (
    //         <div>
    //             {children}
    //         </div>
    //     )
    // },
    ItemComponent: ({ item }) => {
        // const values = useMemo(() => {
        //     let data = [];
        //     const addToData = (key, mapper) => {
        //         if (item?.[key]) {
        //             if (mapper) {
        //                 data.push(mapper(item[key]));
        //             } else {
        //                 data.push(item[key]);
        //             }
        //         }
        //     }

        //     addToData('destinationName')
        //     addToData('initiationDate', date => Util.getDate(date))
        //     addToData('amount', amount => 'AED ' + amount)

        //     return data.join('  •  ');
        // }, [item])

        return (<>
            <div style={{ minWidth: 30 }}>
                <ProfilePic size={30} notProfile src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, item.id)} />
            </div>
            <div style={{ flex: 1 }}>
                <p>{item.name}</p>
                {/* <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p> */}
            </div>
            <ArrowRight16 />
        </>)
    }
})

export const FoodItemRecipeItemsDialog = createAnySearchDialog({
    title: "Recipe Items",
    searchPlaceholder: "Search...",
    searchLoader: Api.searchFoodItemRecipeItems.bind(Api),
    hasBackdrop: true,
    // Root: ({ children }) => {
    //     return (
    //         <div>
    //             {children}
    //         </div>
    //     )
    // },
    ItemComponent: ({ item }) => {
        // const values = useMemo(() => {
        //     let data = [];
        //     const addToData = (key, mapper) => {
        //         if (item?.[key]) {
        //             if (mapper) {
        //                 data.push(mapper(item[key]));
        //             } else {
        //                 data.push(item[key]);
        //             }
        //         }
        //     }

        //     addToData('destinationName')
        //     addToData('initiationDate', date => Util.getDate(date))
        //     addToData('amount', amount => 'AED ' + amount)

        //     return data.join('  •  ');
        // }, [item])

        return (<>
            <div style={{ minWidth: 30 }}>
                <ProfilePic size={30} notProfile src={Api.getThumbnail(item.type, item.id)} />
            </div>
            <div style={{ flex: 1 }}>
                <p>{item.name}</p>
                {/* <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p> */}
                {item.type === OBJECT_TYPE_PRODUCT_BUNDLE && !item.food && <Tag size="sm" type="blue">Bundle</Tag>}
                {item.type === OBJECT_TYPE_PRODUCT_BUNDLE && item.food && <Tag size="sm" type="magenta">Food</Tag>}
                {item.type === OBJECT_TYPE_PRODUCT && <Tag size="sm" type="green">Product</Tag>}
            </div>
            <ArrowRight16 />
        </>)
    }
})

export const InvRequestItemsDialog = createAnySearchDialog({
    title: "Items",
    searchPlaceholder: "Search...",
    searchLoader: Api.searchStockReqItems.bind(Api),
    hasBackdrop: true,
    ItemComponent: ({ item }) => {
        return (<>
            <div style={{ minWidth: 30 }}>
                <ProfilePic size={30} notProfile src={Api.getThumbnail(item.itemType, item.itemId)} />
            </div>
            <div style={{ flex: 1 }}>
                <p>{item.name}</p>
                {/* <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p> */}
                {item.itemType === OBJECT_TYPE_PRODUCT_BUNDLE && !item.food && <Tag size="sm" type="blue">Bundle</Tag>}
                {item.itemType === OBJECT_TYPE_PRODUCT && <Tag size="sm" type="green">Product</Tag>}
            </div>
            <ArrowRight16 />
        </>)
    }
})


export const InvReqAssignSf = createAnySearchDialog({
    title: "Assign Transfer / Purchase",
    searchPlaceholder: "Source/destination name or voucher no",
    searchLoader: Api.searchInvReqAssignSf.bind(Api),
    ItemComponent: ({ item }) => {
        const values = useMemo(() => {
            let data = [];
            const addToData = (key, mapper) => {
                if (item?.[key]) {
                    if (mapper) {
                        data.push(mapper(item[key]));
                    } else {
                        data.push(item[key]);
                    }
                }
            }

            addToData('sourceName')
            addToData('destinationName')
            addToData('initiationDate', date => Util.getDate(date))
            addToData('actFlowTypeStr')

            return data.join('  •  ');
        }, [item])

        return (<>
            <Receipt20 />
            <div style={{ flex: 1 }}>
                <p>Voucher #{Util.getVoucherNumber(item.id)}</p>
                <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p>
            </div>
            <ArrowRight16 />
        </>)
    }
})


export const InvReqList = createAnySearchDialog({
    title: "Assign Stock Request",
    searchPlaceholder: "Request ID",
    searchLoader: Api.searchInvReqList.bind(Api),
    ItemComponent: ({ item }) => {
        const values = useMemo(() => {
            let data = [];
            const addToData = (key, mapper) => {
                if (item?.[key]) {
                    if (mapper) {
                        data.push(mapper(item[key]));
                    } else {
                        data.push(item[key]);
                    }
                }
            }

            addToData('friendlyStatus')
            addToData('requestingLocationName')
            addToData('requestedOn', date => Util.getDate(date))

            return data.join('  •  ');
        }, [item])

        return (<>
            <Cube20 />
            <div style={{ flex: 1 }}>
                <p>Request #{item.id}</p>
                <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p>
            </div>
            <ArrowRight16 />
        </>)
    }
})

