import React from 'react'
import Page from '../../base/Page';
import { API_RESPONSE_FAILED_EVENT_404 } from '../../constants/Constants';
import Api from '../../session/Api';
import { getTerminal } from '../../session/SessionManager';
import SocketSession, { SOCKET_EVENT_POS_MONITORING } from '../../session/SocketSession';
import Util from '../../util/Util';
import TerminalMonitoringView from './TerminalMonitoringView';

const isLoggedIn = () => false;

class TerminalMonitoringPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            terminal: undefined
        }
    }

    componentDidMount() {
        super.componentDidMount();
        SocketSession.open();

        this.onMonitoringEventListener = (event => this.setState({
            terminal: {
                ...event.terminalMonitoring,

                checkoutFlag: event.checkoutFlag ? Util.newTempId() : undefined,
                breakStartFlag: event.breakStartFlag ? Util.newTempId() : undefined,
                breakEndFlag: event.breakEndFlag ? Util.newTempId() : undefined,
                cashOpenedFlag: event.cashOpenedFlag ? Util.newTempId() : undefined,
                cashClosedFlag: event.cashClosedFlag ? Util.newTempId() : undefined,
            }
        })).bind(this);

        if (!isLoggedIn() && getTerminal() !== undefined && getTerminal() !== null) {
            SocketSession.registerListener(SOCKET_EVENT_POS_MONITORING + "-" + getTerminal().id, this.onMonitoringEventListener);
        }
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (!isLoggedIn() && getTerminal() !== undefined && getTerminal() !== null) {
                SocketSession.subscribe(SOCKET_EVENT_POS_MONITORING + "-" + getTerminal().id, subscription => {
                    this.socketSubscription = subscription;
                    Api.getActiveMonitoring(listener);
                })
            } else {
                listener({
                    status: false,
                    payload: API_RESPONSE_FAILED_EVENT_404
                })
            }
        }, payload => ({
            terminal: payload
        }));
    }

    componentWillUnmount() {
        if (!isLoggedIn() && getTerminal() !== undefined && getTerminal() !== null) {
            SocketSession.unregisterListener(SOCKET_EVENT_POS_MONITORING + "-" + getTerminal().id, this.onMonitoringEventListener);
        }

        if (this.socketSubscription !== undefined) {
            this.socketSubscription.unsubscribe();
            this.socketSubscription = undefined;
        }

        SocketSession.close();
    }

    getLayout() {
        return (
            <div //style={{ width: '100vw', height: '100vh'}}
                style={{ height: '100%', width: "100%", display: 'flex', flexDirection: 'column', flex: 1, }}>
                <TerminalMonitoringView terminal={this.state.terminal} clientPhoneViewer={false} />
            </div>
        )
    }

}

export default TerminalMonitoringPage;