

import {
    DocumentBlank16,
    Folder16,
    DocumentPdf16,
    Image16,
    VideoChat16,
    DocumentUnknown16,
    Notebook16
} from '@carbon/icons-react'
import { ADVANCED_NOTES_TYPE } from '..';

export function typeGetAttachmentType(type) {
    switch (type) {
        case ADVANCED_NOTES_TYPE.FOLDER:
            return [];
        case ADVANCED_NOTES_TYPE.PDF:
            return ['application/pdf'];
        case ADVANCED_NOTES_TYPE.IMAGE:
            return ['image/*'];
        case ADVANCED_NOTES_TYPE.VIDEO:
            return ['video/*'];
        case ADVANCED_NOTES_TYPE.FILE:
            return undefined;
        case ADVANCED_NOTES_TYPE.NOTE:
            return [];
        default:
            return [];
    }
}

export function typeHasAttachment(type) {
    switch (type) {
        case ADVANCED_NOTES_TYPE.FOLDER:
            return false
        case ADVANCED_NOTES_TYPE.PDF:
            return true;
        case ADVANCED_NOTES_TYPE.IMAGE:
            return true;
        case ADVANCED_NOTES_TYPE.VIDEO:
            return true;
        case ADVANCED_NOTES_TYPE.FILE:
            return true;
        case ADVANCED_NOTES_TYPE.NOTE:
            return false;
        default:
            return false;
    }
}

export function typeName(type) {
    switch (type) {
        case ADVANCED_NOTES_TYPE.FOLDER:
            return "Section"
        case ADVANCED_NOTES_TYPE.PDF:
            return "PDF"
        case ADVANCED_NOTES_TYPE.IMAGE:
            return "Image"
        case ADVANCED_NOTES_TYPE.VIDEO:
            return "Video"
        case ADVANCED_NOTES_TYPE.FILE:
            return "File"
        case ADVANCED_NOTES_TYPE.NOTE:
            return "Note";
        default:
            return "Unknown";
    }
}