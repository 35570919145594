import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TAX, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import AllSubsidiaryReportView from '../accounting-reports/AllSubsidiaryReportView';
import GeneralLedgerResultPage from '../accounting-reports/general-ledger-report/GeneralLedgerResultPage';
import StockFlowListView from '../stock-flow/StockFlowListView';
import TransactionListView from '../transaction/TransactionListView';
import { ReportPage } from '../../reporting-engine/base/report-page';
import { useMemo } from 'react';
import { toBase64 } from '../../util/Util';

const VatPayable = ({ item }) => {
    const query = useMemo(() => toBase64(JSON.stringify({
        selectedAccountLedger: item.ledger,
        filters: [
            { id: "ledgerId", property: "ledgerId", operator: "EQ", value: item.accountLedgerId },
        ]
    })), [item])
    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ height: '100vh', width: '100%' }}>
                <ReportPage reportName="LedgerReport" overrideQuery={query} componentMode />
            </div>
        </div>
    )
    // return (
    //     <div style={{ marginTop: '1rem' }}>
    //         <GeneralLedgerResultPage
    //             embedded hideSubsidiary
    //             hideSubsidiaryReportButton
    //             accountIdOverride={item.accountLedgerId} />
    //     </div>
    // )
}

const VatInput = ({ item }) => {
    const query = useMemo(() => toBase64(JSON.stringify({
        selectedAccountLedger: item.ledger,
        filters: [
            { id: "ledgerId", property: "ledgerId", operator: "EQ", value: item.accountLedgerId },
            { id: "debitOrCredit", property: "debitOrCredit", operator: "EQ", value: 1 }
        ]
    })), [item])
    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ height: '100vh', width: '100%' }}>
                <ReportPage reportName="LedgerReport" overrideQuery={query} componentMode />
            </div>
        </div>
    )
    // return (
    //     <div style={{ marginTop: '1rem' }}>
    //         <GeneralLedgerResultPage
    //             embedded hideSubsidiary
    //             showDebitOnly
    //             hideSubsidiaryReportButton
    //             accountIdOverride={item.accountLedgerId} />
    //     </div>
    // )
}

const VatOutput = ({ item }) => {
    const query = useMemo(() => toBase64(JSON.stringify({
        selectedAccountLedger: item.ledger,
        filters: [
            { id: "ledgerId", property: "ledgerId", operator: "EQ", value: item.accountLedgerId },
            { id: "debitOrCredit", property: "debitOrCredit", operator: "EQ", value: 0 }
        ]
    })), [item])
    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ height: '100vh', width: '100%' }}>
                <ReportPage reportName="LedgerReport" overrideQuery={query} componentMode />
            </div>
        </div>
    )
    // return (
    //     <div style={{ marginTop: '1rem' }}>
    //         <GeneralLedgerResultPage
    //             embedded hideSubsidiary
    //             showCreditOnly
    //             hideSubsidiaryReportButton
    //             accountIdOverride={item.accountLedgerId} />
    //     </div>
    // )
}

class TaxDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_TAX, listener)
            } else {
                Api.getItem(OBJECT_TYPE_TAX, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_TAX,
                    itemResult: this.state.itemResult,
                    pagePath: "/taxes/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "VAT Payable",
                            component: VatPayable
                        },
                        {
                            title: "VAT Input",
                            component: VatInput
                        },
                        {
                            title: "VAT Output",
                            component: VatOutput
                        },
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(TaxDetailPage);