import { InlineLoading } from "carbon-components-react";
import { useEffect, useState } from "react";
import Table from "../../../../components/data-table/Table";
import ProfilePic from "../../../../components/ProfilePic";
import { OBJECT_TYPE_STUDENT } from "../../../../constants/ObjectTypes";
import Api from "../../../../session/Api";
import UIUtil from "../../../../util/UIUtil";
import Button from "../../../../components/Button";
import { Add16 } from '@carbon/icons-react'
import { BecomeStudentDialog } from "../../../../views/auth/lms-auth-view";

const COLUMNS = [
    {
        id: "id",
        name: "Student",
        render: item => (
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_STUDENT, item.id)} />
                <p>{item.value}</p>
            </div>
        )
    }
]

export default function MyStudentsPage() {
    const [showNewStudent, setShowNewStudent] = useState(false);

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        Api.guardianGetMyStudents(response => {
            if (response.status === true) {
                setRows(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [])

    return (
        <div className="main-content">
            <Table
                hasFooter
                title="My students" description="List of students"
                rows={rows} columns={COLUMNS} onClick={id => { }}
                rightContent={
                    <Button onClick={() => setShowNewStudent(true)} renderIcon={Add16}>New Student</Button>
                }
            >
                {loading && <InlineLoading style={{ marginLeft: '1rem' }} />}
            </Table>

            <BecomeStudentDialog title="Creating Student" open={showNewStudent} onClose={() => setShowNewStudent(false)} />
        </div>
    )
}