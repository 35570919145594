import { Tag } from "carbon-components-react";
import { useEffect, useMemo, useState } from "react";
import Api from "../../../session/Api";
import { CloseFilled16, Close16 } from '@carbon/icons-react'


export function TargetEmails({ type, ids, emails, skippedEmails, setSkippedEmails }) {
    const [emailAddresses, setEmailAddresses] = useState([]);

    const allAddresses = useMemo(() => [...emailAddresses.map($ => $.emailAddress), ...emails], [emails, emailAddresses])

    useEffect(() => {
        setEmailAddresses([]);
        let cancelled = false;
        Api.resolveLmsNotificationEmails(type, ids, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setEmailAddresses(response.payload);
                setSkippedEmails(prev => prev.filter(item => response.payload.find($ => $.emailAddress === item)))
            }
        })
        return () => cancelled = true;
    }, [type, ids])

    if (ids?.length && !allAddresses.length) {
        return <p style={{ fontSize: 14, opacity: 0.65 }}>No email addresses available</p>
    }

    if (!ids?.length && !allAddresses.length) {
        return <p style={{ fontSize: 14, opacity: 0.65 }}>Please select 'To' to load addresses</p>
    }

    const isEmailSkipped = email => skippedEmails.includes(email);
    const setEmailSkipped = (email, skip) => setSkippedEmails(prev => skip ? [...prev, email] : prev.filter(other => other !== email));

    return (<>
        {allAddresses.map(email => (
            isEmailSkipped(email) ? (
                <Tag key={email} onClick={() => setEmailSkipped(email, false)} style={{ opacity: 0.65 }} renderIcon={Close16} type="gray">{email}</Tag>
            ) : (
                <Tag key={email} onClick={() => setEmailSkipped(email, true)} renderIcon={CloseFilled16} type="blue">{email}</Tag>
            )
        ))}
    </>)
}