import { useEffect, useMemo, useState } from "react";
import { MinimalTable } from "../../../components/minimal-table"
import useStore from "../../../hooks/useStore";
import Util, { big, debounce } from "../../../util/Util";
import { InlineLoading } from "carbon-components-react";
import Api from "../../../session/Api";
import { selectionsToGroupToProductMap } from "./func";

const EMPTY = []

const COLUMNS = [
    {
        name: "item",
        title: "Item",
        flex: 2,
        renderCell: item => <p>{item.itemName}</p>
    },
    {
        name: "qty",
        title: 'Quantity',
        flex: 1,
        renderCell: item => <p>{Util.formatAmount(item.quantityValue)} <strong>{item.uom}</strong></p>
    },
    // avg cost
    {
        name: "avgCost",
        title: 'Avg Cost',
        flex: 1,
        renderCell: item => <p>{Util.formatMoney(item.amount)}</p>
    },
    // total
    {
        name: "total",
        title: 'Total',
        flex: 1,
        renderCell: item => <p>{Util.formatMoney(big(item.quantityValue).times(big(item.amount)).toNumber())}</p>
    },
]

const getProductionStockUsage = debounce(Api.getProductionStockUsage.bind(Api), 500);


const UsageTable = ({ item, quantity, selections }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let canceled = false;
        setLoading(true);
        setData([]);
        setError(null);


        getProductionStockUsage({
            bundleId: item.id,
            quantity,
            groupToProductMap: selectionsToGroupToProductMap(item, selections),
        }, response => {
            if (canceled) {
                return;
            }

            if (response.status === true) {
                setData(response.payload)
            } else {
                setError(response.message || "An error occurred. Please try again later.");
            }

            setLoading(false)
        })


        return () => {
            canceled = true;
        }
    }, [item, quantity, selections]);

    if (loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 45 }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    if (error) {
        return (
            <p style={{ fontSize: 12, opacity: 0.65 }}>
                {error}
            </p>
        )
    }

    return (
        <MinimalTable
            large
            items={data}
            columns={COLUMNS}
        />
    )
}

export function StockUsage({ store }) {
    // const [bundleId] = useStore(store, 'bundleId');
    const [item] = useStore(store, 'bundle');
    const [selections] = useStore(store, 'groupSelections') ?? EMPTY;
    const [quantity, setQuantity] = useStore(store, 'quantity');

    const hasBundleSelected = !!item;

    const hasOptionsSelected = useMemo(() => {
        if (!item) {
            return false;
        }

        const selection = (groupId) => selections.find(selection => selection.groupId === groupId) ?? { groupId }
        const values = item.groups.map(group => selection(group.id))
        return values.filter(item => {
            //check if group has NOT been selected

            if (item.optionRecords) {
                const totalPercentage = item.optionRecords.filter(selection => Util.isNumberExist(selection.optionId))
                    .map(selection => selection.percentage ?? 100)
                    .reduce((t, c) => t + c, 0)
                const isHunderedPercent = totalPercentage === 100;
                return !isHunderedPercent;
            } else {
                return !Util.isNumberExist(item.optionId);
            }
        }).length === 0
    }, [item, selections])

    if (!hasBundleSelected) {
        return (
            <p style={{ fontSize: 12, opacity: 0.65 }}>
                Please select a bundle to calculate production stock/inventory usage
            </p>
        )
    }

    if (!hasOptionsSelected) {
        return (
            <p style={{ fontSize: 12, opacity: 0.65 }}>
                Please select all options for the selected bundle to calculate production stock/inventory usage
            </p>
        )
    }

    if (big(quantity).lte(0)) {
        return (
            <p style={{ fontSize: 12, opacity: 0.65 }}>
                Please enter the quantity of the selected bundle to calculate production stock/inventory usage
            </p>
        )
    }

    return <UsageTable item={item} quantity={quantity} selections={selections} />
}