import Button from "../../../../components/Button";
import { CheckmarkFilled16, Password16 } from '@carbon/icons-react'
import { useState } from "react";
import { FormDialog } from "../../../../templates/form/form-dialog";
import Api from "../../../../session/Api";
import useStore from "../../../../hooks/useStore";
import { TextInput } from "carbon-components-react";
import { getAccountRole } from "../../../../session/SessionManager";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../../constants/Constants";
import { DealFormDialog } from "../../../property-finances/dialogs/deal-form-dialog";

export function RealEstateFinalizeButton({ leadId, dealId, onUpdate }) {
    const [open, setOpen] = useState(false);


    const role = getAccountRole();
    if (role != ACCOUNT_TYPE_ADMINISTRATION && role != ACCOUNT_TYPE_SUPERVISOR) {
        return null;
    }
    return (<>
        <Button onClick={() => setOpen(true)} kind="primary" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={CheckmarkFilled16}>Finalize</Button>
        {open && <DealFormDialog leadId={leadId} dealId={dealId ?? 0} onUpdate={onUpdate} onClose={() => setOpen(false)} />}
    </>)
}