import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { Product32, ArrowsVertical16, Download16, Upload16, ChartRelationship32, ListBoxes } from '@carbon/icons-react'
import { ComposedModal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { useStringValueFilter } from "../../engine/useValueFilter";

export const ProductList = {
    filter: Filter,
    icon: Product32,

    // customPath: "/products",
    newLink: "/products/new",

    itemToOpenLink: item => `/products/${item.id}`,
    isListMode: true,
    // rowHeight: 75,

    isAvailable: () => {
        const role = getAccountRole();
        return (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_PRODUCT_MANAGER);
    }
}


const DirTypeFilter = ({ state }) => {
    const [value, setValue] = useStringValueFilter(state, "type", { property: "type", operator: "EQ" })
    return (
        <div style={{ paddingInline: '0.5rem' }}>
            <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                <button data-active={!value} onClick={() => setValue(null)} className='report-engine-menu-button-light'><ArrowsVertical16 /></button>
                <button data-active={value === "Import"} onClick={() => setValue("Import")} className='report-engine-menu-button-light'><Download16 /></button>
                <button data-active={value === "Export"} onClick={() => setValue("Export")} className='report-engine-menu-button-light'><Upload16 /></button>
            </div>
        </div>
    )
}


function Filter({ endpoint, state }) {
    return (<>


        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Ref No" property="sku" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Description" property="name" fullWidth />
        </div>


        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Supplier" fieldKey="productListSupplierField" options={endpoint.suppliers.map(item => item.value)} state={state} property="supplier" />
        </div>
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Brand" fieldKey="productListBrandField" options={endpoint.brands.map(item => item.value)} state={state} property="brand" />
        </div>
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Department" fieldKey="productListDepartmentField" options={endpoint.departments.map(item => item.value)} state={state} property="department" />
        </div>
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Category" fieldKey="productListCategoryField" options={endpoint.categories.map(item => item.value)} state={state} property="category" />
        </div>
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Subcategory" fieldKey="productListSubcategoryField" options={endpoint.subcategories.map(item => item.value)} state={state} property="subcategory" />
        </div>

    </>)
}
