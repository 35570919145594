import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

export function AmountTagSelector({ value, setValue, readonly, options, minHeight = 48 }) {
    return (
        <CreatableSelect
            value={value}
            onChange={setValue}
            isClearable={!readonly}
            isDisabled={readonly}
            placeholder={readonly ? "No class" : "Select..."}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 0,
                    background: 'transparent',
                    border: 'none',
                    borderBottom: '1px solid #e0e0e0',
                    minHeight,
                }),
                multiValueRemove: (baseStyles, state) => ({
                    ...baseStyles,
                    display: readonly ? 'none' : undefined
                }),
                dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    display: readonly ? 'none' : undefined
                }),
                indicatorSeparator: (baseStyles, state) => ({
                    ...baseStyles,
                    display: readonly ? 'none' : undefined
                }),
            }}
            isMulti options={options.map(opt => ({ value: opt.id, label: opt.value }))} />
    )
}

export function AmountTagSelectorNormalField({ minimalInput, value, setValue, readonly, options, skipBottomBar }) {
    return (
        <CreatableSelect
            value={value}
            onChange={setValue}
            isClearable={!readonly}
            isDisabled={readonly}
            placeholder={readonly ? "No class" : "Select..."}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: '100%',
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: '2.5rem',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 0,
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: 1.29,
                    letterSpacing: '0.16px',
                    outline: '2px solid transparent',
                    outlineOffset: '-2px',
                    width: '100%',
                    minHeight: '2.5rem',
                    padding: minimalInput ? '0 5px' : '0 1rem',
                    border: 'none',
                    borderBottom: skipBottomBar ? 'none' : '1px solid #8d8d8d',
                    backgroundColor: '#f4f4f4',
                    color: '#161616',
                    transition: 'background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9)',

                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'black',
                    height: '100%',
                    margin: 0
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: '2.5rem',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),

            }}
            isMulti options={options.map(opt => ({ value: opt.id, label: opt.value }))} />
    )
}