

import {
    ErrorFilled16, CheckmarkFilled16,
} from '@carbon/icons-react'

import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react';
import { useState } from 'react';
import Button from '../../../../components/Button';
import useThirdPartyPos from '../../pos/useThirdPartyPos';
import CartView from '../views/CartView';

export default ({ sessionStore, visible, onClose }) => {
    const { loadingCheckout, checkout } = useThirdPartyPos(sessionStore)

    return (
        <ComposedModal
            key="confirm-third-party-order"
            open={visible}

            onClose={onClose}
            size="sm">
            <ModalHeader label="POS" title={"Confirm Order"} />
            <ModalBody style={{ paddingRight: '1rem' }} className="white-out-third-party-pos-card-bg" hasScrollingContent>
                <CartView sessionStore={sessionStore} readonly />
            </ModalBody>
            <ModalFooter>
                <ButtonSet style={{ width: '100%' }}>
                    <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                        Cancel
                    </Button>
                    <Button onClick={checkout} renderIcon={CheckmarkFilled16} loading={loadingCheckout}
                    >
                        Confirm Order
                    </Button>
                </ButtonSet>
            </ModalFooter>
        </ComposedModal>
    )
}