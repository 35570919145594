import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { newListDetail } from "../../base/list-detail"
import { FlowStream32, FlowStream24, ArrowRight24 } from '@carbon/icons-react'
import { StockTransferItemsList } from "./item-list"
import Util, { isV2 } from "../../../util/Util"
import { DisplayFormGroup, DisplayFormValueField, FormSection } from "../../../templates/form/form"
import { useMemo } from "react"
import { MinimalTable } from "../../../components/minimal-table"

export const StockTransferList = newListDetail({
    title: "Stock Transfer",
    icon: FlowStream32,
    mdIcon: FlowStream24,

    filter: Filter, newForm: Form, updateForm: ViewForm,
    // canDelete: false,

    defaultValues: {
        date: new Date().getTime(),
        items: [{ tempId: Util.newTempId() }]
    },
    finalFormMapper: data => ({
        ...data,
        items: data.items.filter($ => $?.item?.selectedItem?.id).map($ => ({
            itemId: $.item.selectedItem.id,
            qty: $.qty,
            memo: $.memo,
            tags: $.tags
        }))
    }),

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})


const Items = ({ products, predefinedClasses, editable, value, setValue }) => {
    // console.log(value)
    return <StockTransferItemsList products={products} predefinedClasses={predefinedClasses} editable={editable} items={value} setItems={setValue} />
}

function ViewForm({ endpoint, form }) {
    const data = useMemo(() => form.getFormData(), [form]);
    return (
        <>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '3rem' }}>
                <DisplayFormValueField title={"Date"}>{Util.getDateOnly(data.date)}</DisplayFormValueField>
                <div style={{ width: '3rem' }} />
                <DisplayFormValueField title={"From"}>{data.fromLocationName}</DisplayFormValueField>
                <ArrowRight24 />
                <DisplayFormValueField title={"To"}>{data.toLocationName}</DisplayFormValueField>
            </div>

            <MinimalTable
                large items={data.items}
                columns={[
                    {
                        name: "item",
                        title: "Item",
                        flex: 5,
                        renderCell: item => item.itemName
                    },
                    {
                        name: "class",
                        title: "Class",
                        flex: 3,
                        renderCell: item => item?.tags?.map($ => $.name).join(", ")
                    },
                    {
                        name: "memo",
                        title: "Memo",
                        flex: 3,
                        renderCell: item => item.memo
                    },
                    {
                        name: "qty",
                        title: "Qty",
                        flex: 1,
                        renderCell: item => item.qty
                    },
                ]}
            />
        </>
    )
}

function Form({ endpoint, form }) {
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '3rem', }}>
                <div style={{ flex: 1 }}>
                    <form.DateField fieldKey="date" title="Date" />
                </div>
                <div style={{ height: 40, display: 'flex', alignItems: 'center', marginBottom: '1rem', opacity: 0 }}>
                    <ArrowRight24 />
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end', gap: '3rem', marginBottom: '3rem' }}>
                <div style={{ flex: 1 }}>
                    <form.ComboBoxField fieldKey="fromLocationId" title="From" options={endpoint.locations} />
                </div>
                <div style={{ height: 40, display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <ArrowRight24 />
                </div>
                <div style={{ flex: 1 }}>
                    <form.ComboBoxField fieldKey="toLocationId" title="To" options={endpoint.locations} />
                </div>
            </div>
            <FormSection title={"Items"}>
                <form.CustomField fieldKey="items" childrenProps={{ products: endpoint.products, predefinedClasses: endpoint.predefinedClasses, editable: true, }}>
                    {Items}
                </form.CustomField>
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
