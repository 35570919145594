import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

import { 
    ReportData16,
    WatsonHealthSpineLabel32,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    Information16
} from '@carbon/icons-react'
import Button from '../../components/Button';
import { Checkbox, ComboBox, ContentSwitcher, Switch } from 'carbon-components-react';
import { printProductLabels } from '../../pdfs/product-labels/ProductLabelsPdf';
import UIUtil from '../../util/UIUtil';
import Api from '../../session/Api';
import { TEMPLATE_TYPE_BUNDLE_LABEL, TEMPLATE_TYPE_LABEL_GENERIC } from '../label-designer/templates/Template';
import { getAndExportTemplate } from '../label-designer/export/TemplateExporter';
import { getDefaultTemplate } from '../label-designer/util/TemplateDefaultFinder';

const Section = ({children, icon, title, extraTopMargin, skipTitleMargin}) => (
    <div style={{marginTop: extraTopMargin ? '6rem' : '3rem'}}>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: !skipTitleMargin ? '1rem' : 0}}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

class ProductBundleLabelsPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            labelType: "Barcode only",
            displayPrice: false,

            generatingLabel: false,
            selectedProducts: [],

            templates: [],
            advanced: true,
            selectedTemplateId: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getTemplates(listener), payload => {
            const templates = payload.filter(item => item.type == TEMPLATE_TYPE_BUNDLE_LABEL || item.type == TEMPLATE_TYPE_LABEL_GENERIC); 

            return {
                templates,
                selectedTemplateId: getDefaultTemplate(templates, TEMPLATE_TYPE_BUNDLE_LABEL, TEMPLATE_TYPE_LABEL_GENERIC).id
            }
        })
    }

    canGenerateLabel() {
        if (this.state.advanced) {
            if (!this.state.selectedTemplateId) {
                return false;
            }
        }
        return this.state.selectedProducts.length > 0;
    }

    onGenerateLabelsBtn() {
        if (this.state.advanced) {
            this.setState({ generatingLabel: true })
            getAndExportTemplate(this.state.selectedTemplateId, this.state.selectedProducts.map(id => ({ itemId: id })), { loadBundles: true })
            .then(() => {
                UIUtil.showSuccess()
                this.setState({ generatingLabel: false })
            })
            .catch(() => {
                UIUtil.showError();
                this.setState({ generatingLabel: false })
            })
        } else {
            this.setState({ generatingLabel: true })
            Api.getBundleBarcodes(this.state.selectedProducts, response => {
                if (response.status === true) {
                    printProductLabels(this.state.labelType, {
                        displayPrice: this.state.displayPrice,
                    }, response.payload)
                    .then(() => {
                        this.setState({ generatingLabel: false })
                    })
                    .catch(() => {
                        UIUtil.showError();
                        this.setState({ generatingLabel: false })
                    })
                } else {
                    UIUtil.showError();
                    this.setState({ generatingLabel: false })
                }
            })
        }
    }

    getLayout() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem'}}>
                <div style={{width: '75vw'}}>
                    <h1>Bundle Labels</h1>
                    <p style={{fontSize: 18}}>Generate barcode labels</p>

                    <Section icon={Number_132} title={"Label Type"}>
                        <ContentSwitcher style={{marginBottom: '1rem'}} 
                        selectedIndex={this.state.advanced ? 1 : 0} onChange={({index}) => {
                            this.setState({ advanced: index == 1 })
                        }}>
                            <Switch text="Basic" />
                            <Switch text="Advanced" />
                        </ContentSwitcher>


                        {this.state.advanced && this.state.templates.filter(item => this.state.selectedTemplateId == item.id)[0] ? (
                            <ComboBox 
                            titleText="Template"
                            items={this.state.templates}
                            value={this.state.templates.filter(item => this.state.selectedTemplateId == item.id)[0].title}
                            itemToString={item => item ? item.title : ''}
                            onChange={e => this.setState({selectedTemplateId: e.selectedItem ? e.selectedItem.id : getDefaultTemplate(this.state.templates, TEMPLATE_TYPE_BUNDLE_LABEL, TEMPLATE_TYPE_LABEL_GENERIC).id})}
                            />
                        ) : (<>
                            <div style={{marginTop: '0rem' /*1rem*/, display: 'flex', gap: '1rem', alignItems: 'center', flexWrap: 'wrap'}}>
                                <div onClick={() => this.setState(prevState => ({displayPrice: !prevState.displayPrice}))}>
                                    <Checkbox labelText="Display price" checked={this.state.displayPrice}  />
                                </div>
                            </div>
                        </>)}
                    </Section>

                    <Section icon={Number_232} title="Select Bundles" skipTitleMargin>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem', marginTop: '0.25rem'}}>
                            <Information16 />
                            <p style={{fontSize: 14, marginLeft: '0.25rem', opacity: 0.65}}>The first barcode of each product is displayed. You can rearrange the barcode order in the product page.</p>
                        </div>
                        <div style={{maxHeight: 450, overflow: 'auto'}}>
                            {TableTemplate.renderTemplate({
                                selectableOnly2: {
                                    //onSelectUpdate: items => this.setState({selectedProducts: items.map(item => item.id)})
                                    selectedIds: this.state.selectedProducts,
                                    onSelectAdded: id => this.setState(prevState => ({ selectedProducts: [...prevState.selectedProducts, id] })),
                                    onMultiSelectAdded: ids => this.setState(prevState => ({ selectedProducts: [...prevState.selectedProducts, ...ids] })),
                                    onSelectRemoved: removeId => this.setState(prevState => ({ selectedProducts: prevState.selectedProducts.filter(id => id != removeId) })),
                                    onMultiSelectRemoved: removeIds => this.setState(prevState => ({ selectedProducts: prevState.selectedProducts.filter(id => !removeIds.includes(id)) }))
                                },
                                embedded: true,
                                title: "Bundle Labels", 
                                subTitle: "Generate barcode labels", 
                                objectType: OBJECT_TYPE_PRODUCT_BUNDLE, 
                                pagePath: "/bundle-labels/", 
                                history: this.props.history
                            })}
                        </div>
                    </Section>

                    <Section icon={Number_332} title="Generate" extraTopMargin>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={this.onGenerateLabelsBtn.bind(this)} disabled={!this.canGenerateLabel()} loading={this.state.generatingLabel} renderIcon={WatsonHealthSpineLabel32}>Generate Labels</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }
    
    isPageLoadable() {
        return true;
    }

}

export default withRouter(ProductBundleLabelsPage);