import React, { useEffect, useMemo, useState } from 'react';
import './shipment-item-page.scss'
import { StatusBar } from './views/status-bar';
import { Toolbar } from "./views/toolbar";
import { Close16, CheckmarkFilled16, ErrorFilled16, Reset16, Number_432, ArrowRight16 } from '@carbon/icons-react'
import { getSideViewTitle, renderSideView, SideView } from './side-views';
import Util from '../../../../util/Util';
import { useForceLockScroll } from '../../../../hooks/useLockScroll';
import Button from '../../../../components/Button';
import { TextArea, TextInput } from 'carbon-components-react';
import { Content } from './views/content';
import { Footer } from './views/footer';
import { importFlow, exportFlow } from './flow';
import { getNextStatusById, getPreviousStatusById, getShipmentStatus, getStatusIndexById, SHIPMENT_DIR_TYPE } from '..';
import { useForm } from '../../../../templates/form/form';
import isEqual from 'lodash.isequal';
import UIUtil from '../../../../util/UIUtil';
import Api from '../../../../session/Api';
import { withLoadablePageWithParams } from '../../../../base/Page';
import { fixIdForApi } from '../../../../templates/form/form-api';
import { OBJECT_TYPE_SHIPMENT } from '../../../../constants/ObjectTypes';
import { AdvancedNotesView } from '../../../../views/advanced-notes/advanced-notes-view';
import { BoardViewPage } from '../../../board-view/board-view-page';
import { CalendarPage } from '../../../calendar/calendar-page';

const useGlobalState = () => {
    const [state, setState] = useState({});
    return ({
        get: key => state[key],
        use: (key, defValue) => {
            return [state[key] ?? defValue, (arg) => {
                if (typeof arg === "function") {
                    setState(prevState => ({ ...prevState, [key]: arg(prevState[key] ?? defValue) }))
                } else {
                    setState(prevState => ({ ...prevState, [key]: arg }))
                }
            }]
        }
    })
}

export const useShipmentUpdator = (form, shipment, setShipment, flow) => {
    const [savingChanges, setSavingChanges] = useState(false)
    const [revertingStatus, setRevertingStatus] = useState(false);
    const [nextingStatus, setNextingStatus] = useState(false);

    const canRevert = flow && getStatusIndexById(flow, shipment?.status) > 0;
    const canNext = flow && getStatusIndexById(flow, shipment?.status) < (flow.length - 1)

    return {
        canRevert, canNext,


        onResetToDefaultBtn: () => form.setData(),

        onSaveChangesBtn: () => {
            const newShipment = form.getFormData();

            setSavingChanges(true)
            Api.updateShipment(fixIdForApi(newShipment), response => {
                setSavingChanges(false);

                if (response.status === true) {
                    setShipment(response.payload);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        },

        onRevertStatusBtn: () => {
            const shipment = form.getFormData();
            const newShipment = {
                ...shipment,
                status: getPreviousStatusById(flow, shipment.status).id,
                duringPortInspection: false
            };

            setRevertingStatus(true);
            Api.updateShipment(fixIdForApi(newShipment), response => {
                setRevertingStatus(false);

                if (response.status === true) {
                    setShipment(response.payload);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        },

        onNextStatusBtn: () => {
            const shipment = form.getFormData();
            const newShipment = {
                ...shipment,
                status: getNextStatusById(flow, shipment.status).id,
                duringPortInspection: false
            };

            setNextingStatus(true);
            Api.updateShipment(fixIdForApi(newShipment), response => {
                setNextingStatus(false);

                if (response.status === true) {
                    setShipment(response.payload);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        },


        onPortStaffInspectBtn: () => {
            const shipment = form.getFormData();
            const newShipment = {
                ...shipment,
                duringPortInspection: true
            };

            setNextingStatus(true);
            Api.updateShipment(fixIdForApi(newShipment), response => {
                setNextingStatus(false);

                if (response.status === true) {
                    setShipment(response.payload);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        },

        savingChanges, revertingStatus, nextingStatus
    }
}

function View({ payload: { item, endpoint } }) {
    const [shipment, setShipment] = useState(item)
    const flow = useMemo(() => shipment?.dirType === SHIPMENT_DIR_TYPE.IMPORT ? importFlow(endpoint) : exportFlow(endpoint), [shipment?.dirType])

    const [sideView, setSideView] = useState(undefined)
    const [tab, setTab] = useState(0)
    const [selectedStatus, setSelectedStatus] = useState(() => getShipmentStatus(flow, shipment));



    const [changesMade, setChangesMade] = useState(false);
    const form = useForm(store => store.setObject(shipment), {
        replaceComboBoxWithDataList: true,
        skipFieldEffect: true
    });
    useEffect(() => form.store.subscribeGlobal(() => {
        setChangesMade(!isEqual(shipment, form.getFormData()))
    }), [shipment])


    const shipmentUpdator = useShipmentUpdator(form, shipment, setShipment, flow)
    useEffect(() => {
        setSelectedStatus(getShipmentStatus(flow, shipment))
        form.store.clear()
        form.store.setObject(shipment)
    }, [flow, shipment])

    useForceLockScroll(true)

    const globalState = useGlobalState();


    let view = null;
    switch (tab) {
        case 0: //Overview
            view = (<>
                <StatusBar shipment={shipment} flow={flow} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
                <div style={{ height: 'calc(100% - 2rem - 3rem)', display: 'flex' }}>
                    <div style={{ height: '100%', flex: 1, minWidth: 0 }}>
                        <div style={{ height: 'calc(100% - 3rem)', background: '#f4f4f4', overflow: 'auto' }}>
                            <Content shipment={shipment} form={form} flow={flow} selectedStatus={selectedStatus} />
                        </div>
                        <div style={{ height: '3rem', width: '100%', paddingInline: '1rem', gap: '0.25rem', background: 'white', borderTop: '1px solid #00000020', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Footer {...{ changesMade, ...shipmentUpdator }} />
                        </div>
                    </div>

                    <SideView globalState={globalState} changesMade={changesMade} endpoint={endpoint} shipment={shipment} form={form} sideView={sideView} setSideView={setSideView} />
                </div>
            </>)
            break;

        case 1:
            view = (
                <div style={{ width: '100%', height: 'calc(100% - 3rem)' }}>
                    <CalendarPage />
                </div>
            )
            break

        case 2:
            view = (
                <div style={{ width: '100%', height: 'calc(100% - 3rem)' }}>
                    <BoardViewPage shipmentId={shipment.id} />
                </div>
            )
            break

        case 3:
            view = (
                <div style={{ width: '100%', height: 'calc(100% - 3rem)' }}>
                    <AdvancedNotesView title="Notes/Attachments" objectId={shipment.id} objectType={OBJECT_TYPE_SHIPMENT} />
                </div>
            )
            break;
    }


    return (
        <div style={{ height: '100%' }}>
            <Toolbar shipment={shipment} tabIndex={tab} setTabIndex={setTab} sideView={sideView} setSideView={setSideView} />
            {view}
        </div>
    )
}


export const ShipmentItemPage = withLoadablePageWithParams(params => listener => Api.getShipmentAndLock(params.shipmentId, listener), View)