import './third-party-pos.scss'
import Page from "../../../base/Page";
import Api from "../../../session/Api";
import { makeObservable } from "../../../util/makeObservable";

import { Screen32, ErrorFilled16, ArrowRight16 } from '@carbon/icons-react'
import Button from "../../../components/Button";
import { HasSession, NoSession } from "./views/SessionView";
import SocketSession, { SOCKET_EVENT_POS_SESSION } from "../../../session/SocketSession";
import { SESSION_EVENT_TYPE_SESSION_END } from "../../pos/state/PosState";
import UIUtil from "../../../util/UIUtil";


class ThirdPartyPos extends Page {

    sessionStore = makeObservable()

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            hasSession: false,
            allowNegativeStock: false
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getThirdPartyPosSession(listener), ({ session, allowNegativeStock }) => {
            if (session) {
                this.onLoadSession(session)
                return { allowNegativeStock };
            } else {
                return { hasSession: session !== null, allowNegativeStock }
            }
        })
    }

    onLoadSession(session) {
        this.sessionStore.setObject(session)
        this.setState({ hasSession: true })

        this.socketSessionEvent = SOCKET_EVENT_POS_SESSION + "-" + session.posSessionId;
        this.sessionEventListener = event => {
            if (event.type === SESSION_EVENT_TYPE_SESSION_END) {
                UIUtil.showInfo("Your session has ended!");
                this.onSessionStopped();
            }
        }
        SocketSession.registerListener(this.socketSessionEvent, this.sessionEventListener)
        SocketSession.subscribe(this.socketSessionEvent, subscription => {
            this.sessionSocketSubscription = subscription;
        })
    }

    componentWillUnmount() {
        if (this.sessionEventListener) {
            SocketSession.unregisterListener(this.socketSessionEvent, this.sessionEventListener)
        }
        if (this.sessionSocketSubscription !== undefined) {
            this.sessionSocketSubscription.unsubscribe();
            this.sessionSocketSubscription = undefined;
        }
    }

    onSessionStopped() {
        this.sessionStore = makeObservable();
        this.setState({ hasSession: false })
    }

    renderNoSession() {
        return <NoSession onStarted={this.onLoadSession.bind(this)} />
    }

    renderSession() {
        return <HasSession sessionStore={this.sessionStore} allowNegativeStock={this.state.allowNegativeStock} onStopped={this.onSessionStopped.bind(this)} />
    }

    getLayout() {
        return this.state.hasSession ? this.renderSession() : this.renderNoSession()
    }

    isPageLoadable() {
        return false;
    }

}

export default ThirdPartyPos;