
import {
    Pin16, PinFilled16,

    Dashboard32, User32, Product32, Screen32, Category32, Barcode32, Box32, Compare32, Rocket32,
    Delivery32, AirlinePassengerCare32, CutOut32, Headset32, Percentage32, Store32, DataVis_232,
    Calculator32, WatsonHealthTextAnnotationToggle32, Archive32, StringText32, Chip32, InventoryManagement32,
    FlowStream32, Cube32, DocumentImport32, Purchase32, Money32, Finance32, Account32, Receipt32, Events32,
    Gift32, TagGroup32, QrCode32, Close16, SubtractAlt32, Currency32, IncreaseLevel32, ShoppingCartArrowUp32,
    Api32, License32, RequestQuote32, Notebook32, Table32, ShoppingCartArrowDown32, InProgress32,
    DataFormat32, TreeView32, Catalog32, ListDropdown32, Course32, Grid32, DataShare32, Data_132,
    CaretRight16, ArrowDown32, ArrowUp32, PenFountain32, DeliveryParcel32, LicenseGlobal32,
    Network_132, CategoryNewEach32, PhraseSentiment32, Gradient32, App32, Book32, Document32,
    Wallet32, ReportData32, Van32, RulerAlt32, Types32, ShoppingCart32, Classification32, Settings32,
    Alarm32, Categories32, Boolean32, LocationCompany32, InsertPage32, Bullhorn32,
    Locked32, ViewFilled32, Blog32, Home32, TextLink32, LicenseDraft32, MediaLibrary32,
    UserData32, Enterprise32, Code32, RecentlyViewed32, Building32, ChartBullet32, BuildingInsights_132,
    AirlineManageGates32, Migrate32, ServiceDesk32, Application32, Certificate32, GraphicalDataFlow32,
    Wikis32, Map32, TwoPersonLift32, Industry32, Milestone32, UserCertification32, UserMultiple32,
    Task32, CalendarHeatMap32, SplitScreen32, ChoroplethMap32, Legend32, Roadmap32, EventsAlt32, UserProfile32,
    StarFilled32, Education32, UserSpeaker32, GroupPresentation32, Collaborate32, PassengerPlus32, ManageProtection32,
    DocumentSentiment32, DataClass32, ShoppingCartPlus32, CenterSquare32, MapBoundary32, FitToScreen32, Theater32, Video32,
    BuildingInsights_332, Translate32, CalendarTools32, Ticket32, TagEdit32, Hashtag32
} from '@carbon/icons-react'
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { MENU_PACKAGE } from '../../../app/Config';
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_REAL_ESTATE_AGENT, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_STORE_MASTER, ACCOUNT_TYPE_SUPERVISOR } from '../../../constants/Constants';
import { getObjectTypeUrl, OBJECT_TYPE_CINEMA, OBJECT_TYPE_MOVIE, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_MOVIE_GENRE, OBJECT_TYPE_MOVIE_LANGUAGE, OBJECT_TYPE_SEATING_PLAN, OBJECT_TYPE_THEATER_TYPE } from '../../../constants/ObjectTypes';
import { getReportLink } from '../../../reporting-engine/base/report-page';
import { getAccountRole } from '../../../session/SessionManager';
import { isSqlAvgEngine, isStockEntityFifoEngine } from '../../../stock-system/stock-engine';


export function productManagerMenu() {
    const accountRole = getAccountRole();
    const hasSalesSystem = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_SALES) && hasCapabilitySupport("salesSystem")

    const hasOrderManagement = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("orderManagementSystem")
    const hasOnlineStore = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("onlineStore");

    let menu;
    if (accountRole == ACCOUNT_TYPE_STORE_MASTER) {
        menu = []
    } else {
        menu = [
            {
                title: "Products",
                color: "green",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: Product32,
                                title: "Products",
                                desc: "View, add and edit products",
                                link: "/products"
                            },
                            {
                                icon: Box32,
                                title: "Bundles",
                                desc: "View, add and edit product bundles",
                                link: "/product-bundles"
                            },
                            {
                                icon: Categories32,
                                title: "Product Groups",
                                link: "/product-groups"
                            },
                            {
                                icon: Types32,
                                title: "Product Targets",
                                link: "/product-targets"
                            },
                            ...(hasCapabilitySupport("continuousStockType") ? [{
                                icon: RulerAlt32,
                                title: "Measurement Types",
                                link: "/measurement-types"
                            }] : []),
                            {
                                icon: StringText32,
                                title: "Attribute Packs",
                                link: "/product-attribute-packs"
                            },
                        ]
                    },
                    {
                        title: "Classification",
                        menus: [
                            {
                                icon: PhraseSentiment32,
                                title: "Brands",
                                desc: "View, add and edit product brands",
                                link: "/product-brands"
                            },
                            {
                                icon: Network_132,
                                title: "Departments",
                                desc: "View, add and edit departments",
                                link: "/product-departments"
                            },
                            {
                                icon: Category32,
                                title: "Categories",
                                desc: "View, add and edit categories",
                                link: "/product-categories"
                            },
                            {
                                icon: CategoryNewEach32,
                                title: "Subcategories",
                                desc: "View, add and edit subcategories",
                                link: "/product-subcategories"
                            },
                            {
                                icon: Classification32,
                                title: "Classifications",
                                link: "/product-classifications"
                            },
                        ]
                    },
                    {
                        title: "Labeling",
                        menus: [
                            {
                                icon: Barcode32,
                                title: "Product Labels",
                                desc: "Generate barcode and QR code labels",
                                link: "/product-labels"
                            },
                            {
                                icon: Barcode32,
                                title: "Bundle Labels",
                                desc: "Generate barcode and QR code labels",
                                link: "/bundle-labels"
                            },
                            {
                                icon: Gradient32,
                                title: "Templates",
                                link: "/templates"
                            },
                        ]
                    },
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: DeliveryParcel32,
                                title: "Low Stock Products",
                                link: "/low-stock-product-report"
                            }
                        ]
                    }
                ]
            },
            {
                title: "Inventory",
                color: "yellow",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: FlowStream32,
                                title: "Stock Flow",
                                desc: "View and initiate stock flow",
                                link: "/stock-flows"
                            },
                            {
                                icon: Delivery32,
                                title: "Suppliers",
                                desc: "View, add and edit suppliers",
                                link: "/suppliers"
                            },
                            ...(hasCapabilitySupport("thirdPartyPos") ? [{
                                icon: DeliveryParcel32,
                                title: "Stock Requests",
                                link: "/stock-requests"
                            }] : []),
                        ]
                    },
                    {
                        title: "Quick Filters",
                        menus: [
                            {
                                icon: Delivery32,
                                title: "Stock Flow Purchases",
                                link: "/stock-flows-purchases"
                            },
                            {
                                icon: ShoppingCartPlus32,
                                title: "Stock Flow Sales",
                                link: "/stock-flows-sales"
                            },
                        ]
                    },
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: Product32,
                                title: "Product Report",
                                link: getReportLink("ProductList")
                            },
                            {
                                icon: InventoryManagement32,
                                title: "Stock Report",
                                desc: "Analyze stock",
                                //link: "/stock-report"
                                link: getReportLink("StockReport")
                            },
                            {
                                icon: Grid32,
                                title: "Product Stocks",
                                //link: "/product-stock-report"
                                link: getReportLink("ProductStockReport")
                            },
                            {
                                icon: Hashtag32,
                                title: "Serial No Report",
                                link: getReportLink("SerialNoReport")
                            },
                            // {
                            //     icon: ShoppingCartArrowUp32,
                            //     title: "Sales Report",
                            //     desc: "Analyze sales",
                            //     link: "/sales-report"
                            // },
                            {
                                icon: ShoppingCartArrowDown32,
                                title: "Purchases Report",
                                link: "/purchases-report"
                            },
                            // {
                            //     icon: Data_132,
                            //     title: "Sales Summary",
                            //     link: "/sales-summary-report"
                            // },
                            // {
                            //     icon: DataShare32,
                            //     title: "Product Performance",
                            //     link: "/product-performance-report"
                            // },
                        ]
                    }
                ]
            },
            {
                title: "Promotion Management",
                color: "orange",
                subMenus: [
                    {
                        title: "Pricing",
                        menus: [

                            {
                                icon: Gift32,
                                title: "Loyalty",
                                desc: "View, add and edit loyalty cards",
                                link: "/loyalty-cards"
                            },
                            {
                                icon: TagEdit32,
                                title: "Coupon Promotion",
                                link: "/coupon-promotions"
                            },
                            {
                                icon: CutOut32,
                                title: "Coupons",
                                desc: "View, add and edit coupons",
                                link: "/coupons"
                            },
                            {
                                icon: TagGroup32,
                                title: "Discounts",
                                desc: "View, add and edit store discount periods",
                                link: "/discounts"
                            },
                        ]
                    }
                ]
            },
            {
                title: "System",
                color: "white",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: DocumentImport32,
                                title: "Import",
                                desc: "Import spreadsheet data into system",
                                link: "/import-data"
                            },
                        ]
                    },
                ]
            },
        ];



        if (accountRole == ACCOUNT_TYPE_SUPERVISOR) {
            for (const menuGroup of menu) {
                for (const subMenu of menuGroup.subMenus) {
                    subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/system-logs" && menuItem.link != "/system-settings" && menuItem.link != "/staff-users" && menuItem.link != "/supported-currencies" && menuItem.link != "/sales-app" && menuItem.link != "/delivery-app" && menuItem.link != "/pocket-pos-app" && menuItem.link != "/system-values");
                }
            }
        } else if (accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
            //menu = menu.filter(menu => (menu.title == "Accounting" || menu.title == "Finances" || menu.title == "Point of Sale"));
            menu = menu.filter(menu => (menu.title == "Accounting" || menu.title == "Finance Management" || menu.title == "Point of Sale" || menu.title == "Activities"));
        } else if (accountRole == ACCOUNT_TYPE_SALES) {
            if (hasCapabilitySupport("salesSystem")) {
                menu = menu.filter(menu => (menu.title == "Portal" || menu.title == "Products" || menu.title == "Relations" || menu.title == "Sales" || menu.title == "Activities"));

                for (const menuGroup of menu) {
                    if (menuGroup.title == "Sales") {
                        menuGroup.subMenus = menuGroup.subMenus.filter(subMenu => subMenu.title == "Management")
                    }
                }
            } else {
                menu = menu.filter(menu => (menu.title == "Portal" || menu.title == "Products" || menu.title == "Relations" || menu.title == "Activities"));
            }

            for (const menuGroup of menu) {
                for (const subMenu of menuGroup.subMenus) {
                    subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/monitoring");
                }

                if (menuGroup.title == "Relations") {
                    for (const subMenu of menuGroup.subMenus) {
                        subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link == "/customers" || menuItem.link == "/customer-groups" || menuItem.link == "/contacts");
                    }
                }
            }
        } else if (accountRole == ACCOUNT_TYPE_REAL_ESTATE_AGENT) {
            menu = menu.filter(menu => (menu.title == "Real Estate" || menu.title == "CRM" || menu.title == "Activities"));

            for (const menuGroup of menu) {
                if (menuGroup.title == "Activities") {
                    continue;
                }

                for (const subMenu of menuGroup.subMenus) {
                    subMenu.menus = subMenu.menus.filter(menuItem =>
                        menuItem.link == "/real-estate-project" ||
                        menuItem.link == "/real-estate-property" ||
                        menuItem.link == "/customers" ||
                        menuItem.link == "/contacts");
                }
            }
        }
    }

    return menu;
}