
//import Logo from '../../images/Logo.png'
import Logo from '../../images/LogoTransparent.png'
import Bg from '../../images/edu-bg.jpg'

import { ModalBody, ModalFooter, ModalHeader, TextInput, Tile } from 'carbon-components-react'
import React, { useState } from 'react'
import { Screen32, Education16, ArrowRight16, Login32 } from '@carbon/icons-react'
import Button from '../../components/Button'
import Api from '../../session/Api'
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util'
import { saveAccount } from '../../session/SessionManager'
import { FormDialog } from '../../templates/form/form-dialog'
import { isMobileScreen } from '../../App'

export const BecomeStudentDialog = ({ title = "Become a Student", open, onClose }) => {
    return (
        <FormDialog successMsg="Registration completed! We will get back to you shortly." open={open} onClose={onClose} label="Registration Form" title={title} item={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            schoolName: "",
            level: "",
            subject: "",
            message: "",
        }} setItem={() => onClose()} saveApi={(data, listener) => Api.studentRegister(data, listener)}>
            {({ TextField, TextAreaField }) => (<>
                <div style={{ display: 'flex', width: '100%', gap: '0.5rem' }}>
                    <div style={{ flex: 1 }}>
                        <TextField title="First Name" fieldKey="firstName" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField title="Last Name" fieldKey="lastName" />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', gap: '0.5rem' }}>
                    <div style={{ flex: 1 }}>
                        <TextField title="Email" fieldKey="email" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField title="Phone Number" fieldKey="phoneNumber" />
                    </div>
                </div>
                <TextField title="School Name/Curriculum" fieldKey="schoolName" />
                <TextField title="Level" fieldKey="level" />
                <TextField title="Subject" fieldKey="subject" />
                <TextAreaField title="Message (optional)" fieldKey="message" />
            </>)}
        </FormDialog>
    )
}

const Login = ({ onAuth }) => {
    const [showRegister, setShowRegister] = useState(false)

    const [emailAddressValue, setEmailAddressValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const [loggingIn, setLoggingIn] = useState(false);

    const onLoginBtn = () => {
        setLoggingIn(true);
        Api.auth(emailAddressValue, passwordValue, response => {
            if (response.status === true) {
                saveAccount(response.payload)
                onAuth();
            } else {
                UIUtil.showError(response.message);
            }
            setLoggingIn(false);
        })
    }

    return (<>
        <div style={{ width: '100%', padding: '3rem' }}>
            <h1><Login32 /> Login</h1>
            <br />
            <br />

            <TextInput disabled={loggingIn} light labelText="Email Address" value={emailAddressValue} onChange={e => setEmailAddressValue(e.target.value)} />
            <br />
            <TextInput.PasswordInput
                onKeyPress={e => {
                    if (e.key == "Enter") {
                        onLoginBtn();
                    }
                }}
                disabled={loggingIn} light labelText="Password" value={passwordValue} onChange={e => setPasswordValue(e.target.value)} />
        </div>
        <br />
        <br />

        <div style={{ display: 'flex' }}>
            <Button onClick={() => setShowRegister(true)} kind="secondary" renderIcon={Education16} size="lg" style={{ flex: 1 }}>Become a Student</Button>
            <div style={{ width: 1 }} />
            <Button loading={loggingIn} onClick={onLoginBtn} kind="primary" size="lg" style={{ flex: 1 }}
                disabled={!Util.isStringExists(emailAddressValue) || !Util.isStringExists(passwordValue)}
                renderIcon={ArrowRight16}>Login</Button>
        </div>

        <BecomeStudentDialog open={showRegister} onClose={() => setShowRegister(false)} />
    </>)
}

export function LmsAuthView({ onAuth }) {
    return (
        <div style={{ width: '100%', height: isMobileScreen ? undefined : '100%', paddingLeft: isMobileScreen ? '1rem' : '20%', paddingRight: isMobileScreen ? '1rem' : '20%', paddingTop: isMobileScreen ? '6rem' : '3rem', paddingBottom: isMobileScreen ? '6rem' : '3rem', gap: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ background: '#f4f4f4', border: '1px solid #00000020', borderRadius: 15, overflow: 'hidden', }}>
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    {!isMobileScreen && <div style={{ flex: 1, position: 'relative' }}>
                        <img src={Bg} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', zIndex: 1 }} />
                        <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', background: '#000000BB', backdropFilter: 'blur(8px)', zIndex: 2 }} />
                        <div src={Bg} style={{
                            position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', zIndex: 3,
                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'white'
                        }}>
                            <img src={Logo} style={{ height: 50, objectFit: 'contain', objectPosition: 'center' }} />
                            <h5 style={{ marginTop: '6rem' }}>Welcome</h5>
                            {/* <h4 style={{ textAlign: 'center' }}>NUM8ERS INSTITUTE & EDUCATION</h4> */}
                        </div>
                    </div>}
                    <div style={{ flex: 1, }}>
                        <Login onAuth={onAuth} />
                    </div>
                </div>
            </div>
        </div >
    )
}