import Button from "../../../../../components/Button";
import { Close16, CheckmarkFilled16, ErrorFilled16, Reset16, Number_432, ArrowRight16 } from '@carbon/icons-react'


export function Footer({ canRevert, canNext, nextingStatus, changesMade, savingChanges, revertingStatus, onRevertStatusBtn, onNextStatusBtn, onSaveChangesBtn, onResetToDefaultBtn }) {
    return (<>
        {changesMade ? (<>
            {!revertingStatus && !nextingStatus && <>
                <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>Changes made</p>
                <Button onClick={onResetToDefaultBtn} disabled={savingChanges} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Reset16}>Reset to Defaults</Button>
                <Button onClick={onSaveChangesBtn} loading={savingChanges} size="sm" style={{ borderRadius: 50 }} renderIcon={CheckmarkFilled16}>Save changes</Button>
            </>}
        </>) : (<>
            {!savingChanges && <>
                {canNext ? (<>
                    <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>Proceed to the next status</p>
                </>) : (<>
                    <CheckmarkFilled16 style={{ marginRight: '0.25rem', color: '#00520f' }} />
                    <p style={{ fontSize: 12, opacity: 0.65, color: '#00520f', flex: 1 }}>Event finalized</p>
                </>)}

                <Button onClick={onRevertStatusBtn} disabled={!canRevert || nextingStatus} loading={revertingStatus} kind="secondary" size="sm" style={{ borderRadius: 50 }} renderIcon={ErrorFilled16}>Revert Back</Button>
                {canNext &&
                    <Button onClick={onNextStatusBtn} loading={nextingStatus} disabled={revertingStatus || !canNext} className="green-button" size="sm" style={{ borderRadius: 50 }} renderIcon={ArrowRight16}>Next Step</Button>}
            </>}
        </>)}

    </>)
}