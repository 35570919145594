import { FNB_EVENT_TYPE } from "../..";
import { Info16 } from "../../../../../components/info";
import { TableInput } from "../../../../../components/table-input";
import { FormSection, FormSectionWithoutMargin } from "../../../../../templates/form/form";
import Util from "../../../../../util/Util";
import { ModifyOrderPos, ModifyOrderViewWithOrderId } from "../../../../pos/restaurant/components/modify-order-view";
import { CustomerPicker } from "../../../components/customer-picker";
import { MenuSelectorFormField } from "../../../components/menu-selector";
import { KotPos } from "./kot-pos";
import { SaleItemsTable } from "./sale-items-table";
import { CheckmarkFilled32, Hourglass16, CheckmarkFilled16 } from '@carbon/icons-react'


export const createFlow = endpoint => [
    {
        id: "Created",
        title: "Created",
        card: ({ form, fnbEvent }) => (<>
            <FormSectionWithoutMargin title="Job Info">
                <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
                    <form.SwitchField title="Type" fieldKey="type" options={[{ id: 'CATERING', title: "Catering" }, { id: 'BANQUET', title: "Banquet" }]} />
                    <form.CustomField fieldKey="customerId" childrenProps={{ fnbEvent }}>
                        {CustomerPicker}
                    </form.CustomField>
                </div>
            </FormSectionWithoutMargin>
        </>)
    },

    {
        id: "Event Planning",
        title: "Event Planning",
        card: ({ form, fnbEvent }) => (<>
            <FormSectionWithoutMargin title="Event Info">
                <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
                    <form.IfTargetGroup targetKey="type" checkTargetIf={value => value === FNB_EVENT_TYPE.CATERING}>
                        <form.TextAreaField fieldKey="venueAddress" title="Venue Address" />
                    </form.IfTargetGroup>
                    <form.IfTargetGroup targetKey="type" checkTargetIf={value => value === FNB_EVENT_TYPE.BANQUET}>
                        <form.ComboBoxField fieldKey="salonId" title="Salon" options={endpoint.salons} />
                    </form.IfTargetGroup>

                    <form.TextField fieldKey="noOfGuests" title="Number of guests" />
                </div>
            </FormSectionWithoutMargin>

            <FormSection title="Scheduling">
                <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
                    <form.DateTimeField fieldKey="eventDate" title="Event Date" />
                    <form.TextField fieldKey="eventDuration" title="Event Duration (in hours)" />
                </div>
            </FormSection>

            <FormSection title="Kitchen">
                <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
                    <form.ComboBoxField fieldKey="storeId" title="Preparing Kitchen" options={endpoint.stores} />
                </div>
                <form.TimeField fieldKey="orderStartTimeStr" title="Order Start Time" help="How long before the event should the order be sent to the kitchen?" />
            </FormSection>
        </>)
    },

    {
        id: "Menu Selection",
        title: "Menu Selection",
        card: ({ form, fnbEvent }) => (<>
            <form.ComboBoxField fieldKey="menuId" title="Menu" options={endpoint.menus} />

            <FormSection>
                <div style={{ height: '1rem' }} />
                <form.IfTargetGroup targetKey="menuId" checkTargetIf={value => !Util.isNumberExist(value)}>
                    <p style={{ opacity: 0.65, fontSize: 12, marginTop: '0.5rem' }}>Please select a menu to continue</p>
                </form.IfTargetGroup>
                <form.IfTargetGroup targetKey="menuId" checkTargetIf={value => Util.isNumberExist(value)}>
                    <form.ExtField>
                        {MenuSelectorFormField}
                    </form.ExtField>
                </form.IfTargetGroup>
            </FormSection>
        </>)
    },

    {
        id: "Products/Services",
        title: "Products/Services",
        card: ({ form, fnbEvent }) => (<>

            <form.CustomField fieldKey="saleItems">
                {SaleItemsTable}
            </form.CustomField>

        </>)
    },

    {
        id: "KOT",
        title: "KOT",
        parentLayoutProps: {
            noPadding: true,
            hiddenOverflow: true,
            showOnlyIfSelected: true,
        },
        header: ({ }) => (<>
            <div style={{ background: '#edffed', borderRadius: 5, border: '1px solid #00000020', paddingInline: '1rem', marginLeft: '3rem' }}>
                <p style={{ fontSize: 14, fontWeight: 'bold', color: '#009900', }}>
                    Auto Save Enabled
                </p>
            </div>
        </>),
        card: ({ form, fnbEvent }) => (<>
            {fnbEvent.orderId > 0 && <>
                {/* 8rem */}
                <KotPos fnbEvent={fnbEvent} />
            </>}
        </>)
    },

    {
        id: "Done",
        title: "Done",
        card: ({ form, fnbEvent }) => (<>
            <div style={{ width: '100%', height: 400, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                <CheckmarkFilled32 />
                <h4 style={{ marginBottom: '1rem' }}>You are all done!</h4>

                <form.TextAreaField fieldKey="finalNotes" title="Final Event Notes" />
            </div>
        </>)
    }
]