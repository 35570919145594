import Button from "../../../components/Button";
import { Calendar16 } from '@carbon/icons-react'
import { Modal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { useState } from "react";


export function UpdateOrderDateButton({ salesOrderId, salesOrder, onUpdate }) {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button onClick={() => setVisible(true)} size="sm" style={{ borderRadius: 50 }} renderIcon={Calendar16}>Update Date</Button>

        <FormDialog customPositiveLabel="Update" open={visible} onClose={() => setVisible(false)} label="Date" title="Update" item={{
            dateCreated: salesOrder.dateCreated,
            preferredDeliveryDate: salesOrder.preferredDeliveryDate,
            targetDeliveryDate: salesOrder.targetDeliveryDate,
        }} setItem={onUpdate}
            saveApi={(data, listener) => Api.updateOrderDate(salesOrderId, {
                dateCreated: data.dateCreated,
                preferredDeliveryDate: data.preferredDeliveryDate,
                targetDeliveryDate: data.targetDeliveryDate
            }, listener)}>
            {({ DateTimeField, }) => (<>
                <DateTimeField title="Order date/time" fieldKey="dateCreated" />
                <DateTimeField title="Preferred Delivery Date" fieldKey="preferredDeliveryDate" />
            </>)}
        </FormDialog>
    </>)
}

export function RestUpdateOrderDateButton({ salesOrder, onUpdate }) {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button style={{ maxWidth: 'unset' }} onClick={() => setVisible(true)} renderIcon={Calendar16} kind="secondary" size="md">Update Schedule</Button>

        <FormDialog customPositiveLabel="Update" open={visible} onClose={() => setVisible(false)} label="Date" title="Update" item={{
            dateCreated: salesOrder.dateCreated,
            preferredDeliveryDate: salesOrder.preferredDeliveryDate,
            targetDeliveryDate: salesOrder.targetDeliveryDate,
        }} setItem={onUpdate}
            saveApi={(data, listener) => Api.updateOrderDate(salesOrder.id, {
                dateCreated: data.dateCreated,
                preferredDeliveryDate: data.preferredDeliveryDate,
                targetDeliveryDate: data.targetDeliveryDate
            }, listener)}>
            {({ DateTimeField, }) => (<>
                <DateTimeField title="Target Delivery Date" fieldKey="targetDeliveryDate" />
            </>)}
        </FormDialog>
    </>)
}