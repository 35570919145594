import React from 'react';
import { Page, Document, Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
// import logo from '../../../images/Logo.png'
import { paymentMethodText } from '../../../pages/transaction/TransactionEditor';
import Util from '../../../util/Util';
// import ReceiptLogo from '../../../images/ReceiptLogo.png'
import { THERMAL_RECEIPT_LOGO } from '../../../app/Config';

import Logo from '../../../images/Logo.png'
import SquareLogo from '../../../images/SquareLogo.png'
const imageProps = THERMAL_RECEIPT_LOGO.squareLogo ? { style: { width: THERMAL_RECEIPT_LOGO.width, height: THERMAL_RECEIPT_LOGO.height, objectFit: 'contain', objectPosition: 'left', maxHeight: 100 }, src: SquareLogo } : { style: { width: 178, height: 37, objectFit: 'contain', objectPosition: 'center' }, src: Logo }

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 178,
        height: 37,
        //marginLeft: 'auto',
        //marginRight: 'auto'
    }
});

const Invoice = ({ invoice }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* <Image style={styles.logo} src={logo} /> */}
            {/* <Image style={styles.logo} src={THERMAL_RECEIPT_LOGO.squareLogo ? logo : ReceiptLogo} /> */}
            <Image {...imageProps} />
            <InvoiceTitle title='Purchase Voucher' />
            <InvoiceNo invoice={invoice} />
            <BillTo invoice={invoice} />
            <InvoiceItemsTable invoice={invoice} />
            {/* <InvoiceThankYouMsg /> */}

            <Text style={{ marginTop: 25 }}>Payment Methods</Text>
            {invoice.stockFlow.transactions !== undefined && invoice.stockFlow.transactions !== null && invoice.stockFlow.transactions.map(transaction => (
                transaction.paymentMethods.map(method => (
                    <View style={{ marginTop: 5, flexDirection: 'row' }}>
                        <Text style={{ flex: 1, fontSize: 14, color: 'gray' }}>{paymentMethodText(method.methodType)} {Util.isStringExists(method.paymentId) && `(ID: ${method.paymentId})`}</Text>
                        <Text style={{}}>AED {method.amount.toFixed(2)}</Text>
                    </View >
                ))
            ))}
        </Page>
    </Document>
);

export default Invoice