import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react';


import { ChevronDown16, CaretRight16, Close16, InventoryManagement20 } from '@carbon/icons-react'
import { Button, TextInput } from 'carbon-components-react';
import Util from '../util/Util';


const ItemList = ({ ItemView, itemProps, itemsList, onSelect }) => {
    return (
        <div className="bx--list-box__menu" style={{ width: '100%', height: 250, animation: 'custom-combo-box-drop-down 250ms' }}>
            {itemsList.map(item => (
                <div key={item.id}
                    className="bx--list-box__menu-item"
                    style={{ display: 'flex', alignItems: 'center', padding: ItemView.noPadding ? 0 : '1rem', height: 'unset', minHeight: 45, paddingLeft: '1rem' }}
                    onClick={onSelect !== undefined ? (() => onSelect(item)) : undefined}
                >
                    <ItemView item={item} {...(itemProps ?? {})} />
                </div>
            ))}

        </div>
    )
}

export default ({ light, items, readOnly, textValue, setTextValue, selectedItem, setSelectedItem, itemToString, ItemView, itemProps, onClear }) => {
    const [expanded, setExpanded] = useState(false);

    const wrapperRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        const onClickHandle = e => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                setExpanded(false)
            }
        }

        window.addEventListener('mousedown', onClickHandle);
        return () => window.removeEventListener('mousedown', onClickHandle);
    }, [])

    useEffect(() => {
        if (selectedItem) {
            setTextValue(selectedItem ? itemToString(selectedItem) : "")
        }
    }, [selectedItem])

    useEffect(() => {
        if (Util.isStringExists(textValue) && selectedItem) {
            if (items.map(itemToString).find(s => s == textValue) === undefined) {
                setSelectedItem(undefined)
            }
        }
    }, [textValue, selectedItem])

    useEffect(() => {
        if (Util.isStringExists(textValue) && !selectedItem) {
            const itemToSelect = items.find(item => itemToString(item) === textValue);
            if (itemToSelect) {
                setSelectedItem(itemToSelect)
            }
        }
    }, [textValue, items, selectedItem])

    useEffect(() => {
        setExpanded(false)
    }, [selectedItem])

    useEffect(() => {
        if (Util.isStringExists(textValue) && !selectedItem) {
            setExpanded(true)
        }
    }, [textValue, selectedItem])

    const clear = () => {
        // setSelectedItem(undefined)
        // setTextValue("")
        onClear()
    }

    const onSearchValueChange = (value) => {
        if (!selectedItem) {
            setTextValue(value);
        }
    }

    const itemsList = useMemo(() => {
        if (Util.isStringExists(textValue) && !selectedItem) {
            return items.filter(item => itemToString(item).toLowerCase().includes(textValue.toLowerCase()))
        } else {
            return items;
        }
    }, [textValue])

    return (
        <div ref={wrapperRef} style={{ width: '100%', height: '100%', position: 'relative' }} onKeyDown={e => {
            if (e.key === "Tab" && expanded) {
                setExpanded(false)
            }
        }}>
            <div onClick={() => {
                if (!readOnly) {
                    setExpanded(true)
                    inputRef?.current?.focus()
                }
            }} className="bx--text-input" style={{ position: 'relative', height: '100%', height: '100%', background: light ? 'white' : '#f4f4f4', display: 'flex', alignItems: 'center', cursor: !readOnly ? 'pointer' : undefined }}>
                <input ref={inputRef}
                    style={{ all: 'unset', fontSize: 14, flex: 1, }} value={textValue} placeholder="Input..." onChange={e => setTextValue(e.target.value)}></input>

                {selectedItem !== undefined && !readOnly && (
                    <div onClick={clear} style={{ height: '100%', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '250ms', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                        <Close16 />
                    </div>
                )}

                {!readOnly && <div
                    style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '250ms', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                    <ChevronDown16 />
                </div>}
            </div>

            {expanded && <ItemList
                ItemView={ItemView}
                onSelect={setSelectedItem}
                itemsList={itemsList}
                itemProps={itemProps} />}
        </div>
    )
}