import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_LECTURE_QUIZ } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import Api, { createApiWithLang } from '../../../session/Api';
import { TranslateBanner } from '../../../templates/translate/translate-banner';
import Util from '../../../util/Util';
import { QuizEditor } from '../../../views/quiz-editor';

const OBJECT_TYPE = OBJECT_TYPE_LECTURE_QUIZ;




// const Quiz = ({ item }) => {
//     return (
//         <div>
//             <QuizEditor quizId={item.id} />
//         </div>
//     )
// }





const useLang = () => {
    const [lang, setLang] = useState("EN");
    const api = useMemo(() => (!Util.isStringExists(lang) || lang === "EN") ? Api : createApiWithLang(lang), [lang]);

    return { lang, setLang, api }
}

const createContent = (lang, setLang) => ({ payload: itemResult }) => {
    const history = useHistory();

    const Quiz = useCallback(({ item }) => (
        <QuizEditor quizId={item.id} lang={lang} />
    ), [lang])

    return (<>
        <TranslateBanner lang={lang} setLang={setLang} />
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                lang: lang,
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                    {
                        title: "Questions",
                        component: Quiz
                    }
                ]
            })}
        </div>
    </>)
}

const createPage = (lang, setLang, api) => {
    return withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE, api), createContent(lang, setLang))
}

export const LectureQuizDetailPage = (props) => {
    const { lang, setLang, api } = useLang();
    const Page = useMemo(() => createPage(lang, setLang, api), [lang, setLang, api]);
    return <Page {...props} />
};

