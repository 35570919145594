import { hasCapabilitySupport } from "../../../app/Capabilities";
import Util from "../../../util/Util";

export const STATUS_REVOKED = -1;
export const STATUS_PENDING = 0;
export const STATUS_PROCESSING = 1;
export const STATUS_AWAITING_DELIVERY = 2;
export const STATUS_OUT_FOR_DELIVERY = 3;
export const STATUS_DELIVERED = 4;
export const STATUS_PROCESSING_RENTAL = 5;
export const STATUS_AWAITING_RENTAL_PICKUP = 6;
export const STATUS_RENTAL_OUT_FOR_PICKUP = 7;
export const STATUS_PICKED_UP = 8;
export const STATUS_AWAITING_RENTAL_INSPECTION = 12;
export const STATUS_FINALIZED = 9;
export const STATUS_AWAITING_PAYMENT_COLLECTION = 10;
export const STATUS_OUT_FOR_PAYMENT_COLLECTION = 11;

export const STATUS_REST_PREPARING = 13;
export const STATUS_REST_SERVING = 14;
export const STATUS_REST_AWAITING_FINALIZE = 15;
export const STATUS_REST_PACKING = 16;

export function getOrderStateBarTextColor(color) {
    const isLightColor = (color) =>
        1 -
        (0.299 * parseInt(color.substring(1, 3), 16) +
            0.587 * parseInt(color.substring(3, 5), 16) +
            0.114 * parseInt(color.substring(5, 7), 16)) /
        255 <
        0.5;
    return isLightColor(color ?? "#000000") ? "black" : "white";
}

export function getOrderAddress(order) {
    return addressToString(order?.shippingAddress)
}

export function getAddressCustomerName(address) {
    if (Util.isStringExists(address?.firstName) || Util.isStringExists(address?.lastName)) {
        if (Util.isStringExists(address?.firstName) && Util.isStringExists(address?.lastName)) {
            return `${address.firstName.trim()} ${address.lastName.trim()}`
        } else if (Util.isStringExists(address?.firstName)) {
            return address.firstName.trim();
        } else {
            return address.lastName.trim();
        }
    } else {
        return "Guest";
    }
}

export function addressToString(address, extras) {
    let data = [];
    const addToData = (key) => {
        if (address?.[key]) {
            data.push(address[key]);
        }
    }

    if (extras !== null && extras !== undefined) {
        console.log(extras);

        extras?.filter?.($ => Util.isStringExists($)).forEach($ => data.push($))
    }
    addToData('buildingName')
    addToData('area')
    addToData('addressLineOne')
    addToData('addressLineTwo')
    addToData('unitNo')
    addToData('zipCode')
    addToData('city')
    addToData('state')
    addToData('country')

    return data.join(', ');
}


export function getOrderStatusName(status) {
    switch (status) {
        case STATUS_REVOKED:
            return "Revoked";
        case STATUS_PENDING:
            return "Pending";
        case STATUS_PROCESSING:
            return hasCapabilitySupport("restaurant") ? "Awaiting Preparation" : "Processing";
        case STATUS_AWAITING_DELIVERY:
            return "Awaiting Delivery";
        case STATUS_OUT_FOR_DELIVERY:
            return "Out For Delivery";
        case STATUS_DELIVERED:
            return "Delivered";
        case STATUS_PROCESSING_RENTAL:
            return "Processing Rental";
        case STATUS_AWAITING_RENTAL_PICKUP:
            return "Awaiting Pickup";
        case STATUS_RENTAL_OUT_FOR_PICKUP:
            return "Out For Pickup";
        case STATUS_PICKED_UP:
            return "Picked up";
        case STATUS_AWAITING_RENTAL_INSPECTION:
            return "Awaiting Rental Inspection";
        case STATUS_FINALIZED:
            return "Finalized";
        case STATUS_AWAITING_PAYMENT_COLLECTION:
            return "Awaiting Payment Collection";
        case STATUS_OUT_FOR_PAYMENT_COLLECTION:
            return "Out For Payment Collection";
    }
}
