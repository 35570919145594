import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { OBJECT_TYPE_REAL_ESTATE_PROJECT, OBJECT_TYPE_REAL_ESTATE_PROJECT_TYPE } from '../../../constants/ObjectTypes';
import Page from '../../../base/Page';
import Api, { createApiWithLang } from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import TableTemplate from '../../../templates/TableTemplate';
import { REAL_ESTATE_PROJECT_CUSTOM_LIST } from '../../../constants/Constants';
import Util from '../../../util/Util';
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { TranslateBanner } from '../../../templates/translate/translate-banner';

const Projects = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                embedded: true,
                hideCreateButton: true,
                objectType: OBJECT_TYPE_REAL_ESTATE_PROJECT,
                pagePath: "/real-estate-project/",
                history: history,
                paginated: false,

                getCustomListRequest: {
                    requestType: REAL_ESTATE_PROJECT_CUSTOM_LIST.BY_TYPE_ID,
                    params: {
                        typeId: item.id,
                    }
                },
            })}
        </div>
    )
}

class ProjectTypeDetailPage extends Page {

    api = Api;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined,
            lang: "EN"
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                this.api.getItemCreator(OBJECT_TYPE_REAL_ESTATE_PROJECT_TYPE, listener)
            } else {
                this.api.getItem(OBJECT_TYPE_REAL_ESTATE_PROJECT_TYPE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    setLang(lang) {
        this.setState({ lang }, () => {
            this.api = (!Util.isStringExists(lang) || lang === "EN") ? Api : createApiWithLang(lang);
            this.onPageStart();
        })
    }

    hasTranslation() {
        return hasCapabilitySupport("translationSupport") && this.state.itemResult.translationSupport;
    }

    getLayout() {
        return (
            <>
                {this.hasTranslation() && <TranslateBanner lang={this.state.lang} setLang={this.setLang.bind(this)} />}
                <div className="main-content">
                    {ItemTemplate.renderTemplate({
                        lang: this.state.lang,
                        objectType: OBJECT_TYPE_REAL_ESTATE_PROJECT_TYPE,
                        itemResult: this.state.itemResult,
                        pagePath: "/real-estate-project-type/",
                        history: this.props.history,
                        customTabs: [
                            {
                                title: "Projects",
                                component: Projects
                            }
                        ]
                    })}
                </div>
            </>
        )
    }

}

export default withRouter(ProjectTypeDetailPage);