import React, { useEffect, useState } from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Industry32,
    Van16,
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Save16,
    Reset16,
    FlowStream32,
    DirectionStraightRight32,
    TextLinkAnalysis32,
    Calendar32,
    Calculator32,
    Run32,
    DeliveryParcel32,
    Currency32,
    Notebook16,
    DeliveryParcel16,
    Money16,
    Archive32,
    TagEdit16,
    Box16,
    Wheat16,
    Delivery16,
    FlowStream16,
    Percentage32,
    CheckmarkFilled16,
    SettingsCheck32,
    Redo16,
    RequestQuote16,
    Currency16,
    Notebook32,
    ArrowLeft16,
    TreeView32,
    Report32,
    Catalog32,
    Money32,
    TableSplit20,
    DataVis_420,
    DocumentExport16,
    ArrowRight16,
    Van32,
    Collaborate16,
    User16,
    AirlinePassengerCare16,
    Catalog16,
    Calendar16,
    Account16,
    Cube16,
    FlowStreamReference16,
    DataVis_216,
    Store16,
    CheckboxIndeterminate16,
    Rule32,
    ShoppingCartPlus32,
    LocationCompany32,
    LocationCompany16,
    Building16,
    Building32,
    Document16, BuildingInsights_132, Migrate16, Certificate16, Ticket16,
    Delete16,
    Industry16,
} from '@carbon/icons-react'
import { TileGroup, RadioTile, Link as DLink, Tag, Tile, ClickableTile, ComboBox, ButtonSet, ComposedModal, ModalHeader, ModalBody, ModalFooter, Tabs, Tab } from 'carbon-components-react';
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_WAREHOUSE, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE, SOURCE_TYPE_MOBILE_UNIT, STOCK_FLOW_INITIATION_TYPE_POS, DESTINATION_TYPE_MOBILE_UNIT, POS_SESSION_DISCOUNT_TYPE_FREE_FORM, STOCK_FLOW_INITIATION_TYPE_SALES_CART, DESTINATION_TYPE_VENUE, SOURCE_TYPE_VENUE, TRANSACTION_DIRECTION_TYPE_OUTWARD, TRANSACTION_DIRECTION_TYPE_INWARD } from '../../constants/Constants';
import DirectionIndicator from '../../components/DirectionIndicator';
import ProfilePic from '../../components/ProfilePic';
import Util, { big } from '../../util/Util';
import TransactionEditor from '../transaction/TransactionEditor';
import Button from '../../components/Button';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_STOCK_FLOW, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_VENUE, OBJECT_TYPE_WAREHOUSE } from '../../constants/ObjectTypes';
import { Link, withRouter } from 'react-router-dom';
import UIUtil from '../../util/UIUtil';
import NoteViewDialog from '../../views/notes/NoteViewDialog';
import NoteView from '../../views/notes/NoteView';
import { SalesReceiptDownload } from '../../pdfs/sales-receipt/SalesReceiptPdf';
import { PurchaseVoucherDownload } from '../../pdfs/purchase-voucher/PurchaseVoucherPdf';
import { QuotationDownload } from '../../pdfs/quotation/QuotationPdf';
import { StockTransferDownload } from '../../pdfs/stock-transfer/StockTransferPdf';
import { StockFlowInvoiceBtn } from '../../pdfs/invoice-document/InvoiceDocumentPdf';
import { TransactionPayButton } from '../../views/transactions/transaction-pay-button';
import { DISCOUNT_TYPE } from '../../app/Config';
import { StockFlowDeliveryNoteBtn } from '../../pdfs/generic-pos-print/GenericPosPdf';
import { openPdf } from '../../markup-template-engine';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { listDetailItemId } from '../../reporting-engine/base/report-page';
import { AssignedStockRequests } from './assigned-stock-requests';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const Section = ({ id, children, icon, title, subtitle, subtitleColor, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.5rem', color: subtitleColor }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

const ProductItem = ({ stockFlowItem, isSalesReturn, isPurchase, isCreation, isSales, isPurchaseReturn, stockFlow }) => {
    const isProductionSf = stockFlow.producedInProduction || stockFlow.usedInProduction;

    let amountTitle;
    let amount;
    let totalCost;

    let originalAmountTitle;
    let originalAmount;
    if (isSales) {
        amount = stockFlowItem.amount;
        originalAmount = stockFlowItem.originalPrice;
        originalAmountTitle = "Original Price";
        amountTitle = "Price";
    } else if (isPurchase || isCreation) {
        amount = stockFlowItem.amount;
        originalAmount = stockFlowItem.originalCost;
        originalAmountTitle = "Original Cost";
        amountTitle = "Cost";
    } else if ((isSalesReturn || isPurchaseReturn) && !stockFlow.rentalReturn) {
        amount = stockFlowItem.amount;
        originalAmount = isSalesReturn ? stockFlowItem.originalPrice : stockFlowItem.originalCost;
        originalAmountTitle = isSalesReturn ? "Actual Price" : "Actual Cost";
        amountTitle = "Refund";
    }

    if (isProductionSf) {
        amount = stockFlowItem.amount;
        amountTitle = "Unit Cost";

        try {
            totalCost = big(stockFlowItem.amount).times(stockFlowItem.quantityValue).toNumber()
        } catch (e) { }
    }


    // if (Util.isNumberExist(stockFlowItem.price)) {

    // } else if (Util.isNumberExist(stockFlowItem.cost)) {

    // } else if (Util.isNumberExist(stockFlowItem.refund)) {

    // }

    if (amount !== undefined) {
        amount = amount.toFixed(2);
    }
    if (originalAmount !== undefined) {
        originalAmount = originalAmount?.toFixed(2) ?? 0;
    }

    const isTicket = Util.isNumberExist(stockFlowItem.movieTicketServiceSaleId)
    const isDeliveryFee = stockFlowItem.flagDeliveryFee;


    const skipAmountModified = isProductionSf;

    let href;
    if (isTicket) {
        href = '/service-sale/' + stockFlowItem.movieTicketServiceSaleId;
    } else if (stockFlowItem.food) {
        href = '/food/' + stockFlowItem.itemId
    } else if (stockFlowItem.ingredient) {
        href = '/ingredient/' + stockFlowItem.itemId
    } else {
        href = getObjectTypeUrl(stockFlowItem.itemType) + "/" + stockFlowItem.itemId
    }

    if (isDeliveryFee) {
        href = null;
    }
    return (
        <div>
            {Util.isStringExists(stockFlowItem.serialNumber) && <div style={{ paddingInline: '0.5rem', paddingBlock: '0.25rem', color: 'white', background: '#1c1c1c' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Serial No.: {stockFlowItem.serialNumber}</p>
            </div>}
            {Util.isStringExists(stockFlowItem.batchNo) && <div style={{ paddingInline: '0.5rem', paddingBlock: '0.25rem', color: 'white', background: '#1c1c1c' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Batch No.: {stockFlowItem.batchNo}</p>
            </div>}
            {Util.isStringExists(stockFlowItem.bundleProductSerialNoSummary) && <div style={{ paddingInline: '0.5rem', paddingBlock: '0.25rem', color: 'white', background: '#1c1c1c' }}>
                <p style={{ fontSize: 12, opacity: 0.65, maxWidth: 300, whiteSpace: 'pre-wrap' }}>{stockFlowItem.bundleProductSerialNoSummary}</p>
            </div>}
            <Link target="_blank" to={href} style={{ textDecoration: 'none', pointerEvents: isDeliveryFee ? 'none' : undefined }}>
                <ClickableTile
                    style={{ display: 'flex', padding: '1rem', alignItems: 'flex-start', minWidth: 400 }}>
                    <ProfilePic notProfile size={60} src={Api.getThumbnail(stockFlowItem.itemType, stockFlowItem.itemId)} />
                    <div style={{ marginLeft: '1rem' }}>
                        {!isDeliveryFee && <h5 style={{ fontSize: 14 }}>{stockFlowItem.itemName}</h5>}
                        {isDeliveryFee && <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Delivery16 />
                            <h6 style={{ fontSize: 14, opacity: 1, marginLeft: '0.25rem' }}>Delivery Fee</h6>
                        </div>}


                        <div style={{ height: '0.25rem' }} />
                        <div style={{}}>
                            {!isTicket && !isDeliveryFee && <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DeliveryParcel16 />
                                <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>{stockFlowItem.quantityValue} {stockFlowItem.uom}</p>
                            </div>}
                            {amount !== undefined && !isDeliveryFee && <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Money16 />
                                <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>{amountTitle}: AED {amount}</p>
                            </div>}
                            {amount !== undefined && isDeliveryFee && <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>AED {amount}</p>
                            </div>}

                            {!skipAmountModified && amount != originalAmount && <>
                                <div style={{ marginTop: '1rem', color: 'red' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <TagEdit16 />
                                        <h6 style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>Amount modified</h6>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 16 }}>
                                    <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>{originalAmountTitle}: AED {originalAmount}</p>
                                </div> </>}

                            {stockFlowItem.itemType == OBJECT_TYPE_PRODUCT_BUNDLE && !isTicket && !isDeliveryFee && !stockFlowItem.food && stockFlowItem.bundleProductSummary && <>
                                <div style={{ marginTop: '1rem' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Box16 />
                                        <h6 style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>Bundle</h6>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 16 }}>
                                    <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem', maxWidth: 300, whiteSpace: 'pre-wrap' }}>{stockFlowItem.bundleProductSummary}</p>
                                </div> </>}

                            {stockFlowItem.itemType == OBJECT_TYPE_PRODUCT_BUNDLE && !isTicket && !isDeliveryFee && stockFlowItem.food && <>
                                <div style={{ marginTop: '1rem' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Wheat16 />
                                        <h6 style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>Ingredients</h6>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 16 }}>
                                    <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem', maxWidth: 300, whiteSpace: 'pre-wrap' }}>{stockFlowItem.bundleProductSummary}</p>
                                </div> </>}

                            {isTicket && <>
                                <div style={{ marginTop: '1rem' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Ticket16 />
                                        <h6 style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem' }}>Ticket</h6>
                                    </div>
                                </div></>}

                            {totalCost !== null && totalCost !== undefined && typeof totalCost === 'number' && <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Money16 />
                                <p style={{ fontSize: 14, opacity: 1, marginLeft: '0.25rem' }}>Total Cost: <span style={{ fontWeight: '600' }}>AED {Util.formatMoney(totalCost)}</span></p>
                            </div>}

                        </div>
                    </div>
                </ClickableTile>
            </Link>
        </div>)
}


const CompleteQuotationSaleDialog = ({ loading, amount, open, onClose, onSubmit }) => {
    const [editorKey, setEditorKey] = useState(Util.newTempId());
    const [paymentMethods, setPaymentMethods] = useState([]);

    const amountPaid = () => {
        let totalPaid = 0;
        for (const paymentMethod of paymentMethods) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                totalPaid += parseFloat(paymentMethod.amount);
            }
        }
        return totalPaid == amount
    }

    useEffect(() => {
        setEditorKey(Util.newTempId())
        setPaymentMethods([])
    }, [open])

    return (
        <ComposedModal open={open} onClose={onClose} size="lg">
            <ModalHeader label="Quotation" title="Finalizing sales" />
            <ModalBody style={{ paddingRight: '1rem' }}>

                <TransactionEditor key={editorKey}
                    onUpdate={paymentMethods => setPaymentMethods(paymentMethods)}
                    currency={"AED"}
                    incoming
                    hideDirection
                    totalAmount={amount} />

            </ModalBody>
            <ModalFooter
            // primaryButtonDisabled={loading || !amountPaid()}
            // onRequestSubmit={() => onSubmit(paymentMethods)}
            // primaryButtonText={loading ? "Loading..." : "Confirm"} secondaryButtonText="Cancel"
            >

                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={loading || !amountPaid()}
                    onClick={() => onSubmit(paymentMethods)}>
                    {loading ? "Loading..." : "Confirm"}
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}

const TransactionItem = ({ stockFlow, transaction, isSourceExternal }) => {
    const [expanded, setExpanded] = useState(false);

    const paymentMethodHeight = 80 + convertRemToPixels(0.5);

    let dividedAmount = 0;
    if (transaction.againstItems && transaction.againstItems.length !== undefined) {
        for (const item of transaction.againstItems) {
            if (item.stockFlowId == stockFlow.id) {
                dividedAmount = item.dividedAmount;
                break;
            }
        }
    }

    return (
        <div style={{
            transition: '250ms', height: expanded ? 75 + (115 + (paymentMethodHeight * transaction.paymentMethods.length - 1)) : 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>Transaction (voucher no: {Util.getVoucherNumber(transaction.id)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>AED {dividedAmount.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / AED {transaction.amount.toFixed(2)} (Total Transaction Amount)</p>
                    </div>



                    {/* <h2>AED {transaction.amount.toFixed(2)}</h2> */}
                </div>
                <p style={{ fontSize: 12, opacity: 0.65 }}>View details</p>
                <ArrowRight16 style={{ opacity: 0.65, transition: '250ms', marginLeft: '0.25rem', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
            </div>
            <div style={{ height: 115 + (paymentMethodHeight * transaction.paymentMethods.length - 1), paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, overflow: 'auto' }} >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link target="_blank" to={"/transaction/" + transaction.id} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Open Transaction</Button>
                    </Link>
                </div>

                <TransactionEditor
                    hideTotal
                    readOnly showColorForIncome
                    defaultPaymentMethods={transaction.paymentMethods}
                    currency={"AED"}
                    incoming={!isSourceExternal}
                    totalAmount={transaction.amount} />
            </div>
        </div>
    )
}

const GridViewField = ({ icon, title, value }) => (
    <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon, { style: { marginRight: '0.15rem' } })}
            <label className="bx--label" style={{ margin: 0, padding: 0, fontSize: 12, color: 'black', fontWeight: 'bold' }}>{title}</label>
        </div>
        <p style={{ fontSize: 16 }}>{value}</p>
    </div>
)

const GridProductItem = ({ stockFlow, stockFlowItem, isSalesReturn, isPurchase, isCreation, isSales, isPurchaseReturn }) => {
    let amountTitle;
    let amount;

    let originalAmountTitle;
    let originalAmount;
    if (isSales) {
        amount = stockFlowItem.amount;
        originalAmount = stockFlowItem.originalPrice;
        originalAmountTitle = "Original Price";
        amountTitle = "Price";
    } else if (isPurchase || isCreation) {
        amount = stockFlowItem.amount;
        originalAmount = stockFlowItem.originalCost;
        originalAmountTitle = "Original Cost";
        amountTitle = "Cost";
    } else if (isSalesReturn || isPurchaseReturn) {
        amount = stockFlowItem.amount;
        originalAmount = isSalesReturn ? stockFlowItem.originalPrice : stockFlowItem.originalCost;
        originalAmountTitle = isSalesReturn ? "Actual Price" : "Actual Cost";
        amountTitle = "Refund";
    }

    // if (Util.isNumberExist(stockFlowItem.price)) {
    //     amount = stockFlowItem.price;
    //     originalAmount = stockFlowItem.originalPrice;
    //     originalAmountTitle = "Original Price";
    //     amountTitle = "Price";
    // } else if (Util.isNumberExist(stockFlowItem.cost)) {
    //     amount = stockFlowItem.cost;
    //     originalAmount = stockFlowItem.originalCost;
    //     originalAmountTitle = "Original Cost";
    //     amountTitle = "Cost";
    // } else if (Util.isNumberExist(stockFlowItem.refund)) {
    //     amount = stockFlowItem.refund;
    //     originalAmount = isSalesReturn ? stockFlowItem.originalPrice : stockFlowItem.originalCost;
    //     originalAmountTitle = isSalesReturn ? "Actual Price" : "Actual Cost";
    //     amountTitle = "Refund";
    // }

    if (amount !== undefined) {
        amount = amount.toFixed(2);
    }
    if (originalAmount !== undefined) {
        originalAmount = originalAmount?.toFixed(2) ?? 0;
    }

    return (
        <div style={{
            display: 'flex', backgroundColor: '#f4f4f4',
            marginLeft: '-1rem', marginRight: '-1rem',
            minHeight: 65, fontSize: '.875rem', color: '#525252', borderBottom: '1px solid #e0e0e0', padding: '1rem .5rem 1.5rem', paddingLeft: '1rem'
        }}>

            {stockFlowItem.itemType == OBJECT_TYPE_PRODUCT_BUNDLE ? (
                <div style={{ flex: 2 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: -5 }}>
                        <ProfilePic size={30} notProfile src={Api.getThumbnail(stockFlowItem.itemType, stockFlowItem.itemId)} />
                        {stockFlowItem.itemName}
                    </div>
                    <p style={{ fontSize: 12, opacity: 1, marginLeft: '0.25rem', marginTop: '1rem', maxWidth: 300, whiteSpace: 'pre-wrap' }}>{stockFlowItem.bundleProductSummary}</p>
                </div>
            ) : (
                <div style={{ flex: 2 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: -5 }}>
                        <ProfilePic size={30} notProfile src={Api.getThumbnail(stockFlowItem.itemType, stockFlowItem.itemId)} />
                        {stockFlowItem.itemName}
                    </div>
                    {Util.isStringExists(stockFlowItem.serialNumber) &&
                        <p style={{ fontSize: 12, marginLeft: '0.25rem', marginTop: '1rem', }}>Serial No.: {stockFlowItem.serialNumber}</p>}
                    {Util.isStringExists(stockFlowItem.batchNo) &&
                        <p style={{ fontSize: 12, marginLeft: '0.25rem', marginTop: '1rem', }}>Batch No.: {stockFlowItem.batchNo}</p>}
                </div>
            )}

            {Util.isNumberExist(stockFlow.amount) && <div style={{ flex: 2 }}>
                {amount != originalAmount ? (<>
                    <span style={{ textDecoration: 'line-through', color: 'red' }}>
                        <span style={{ color: 'black' }}>AED {originalAmount !== null ? originalAmount : (0).toFixed(2)}</span>
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
                        <TagEdit16 style={{ marginRight: '0.25rem', fill: 'red' }} />
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                            AED {amount !== null ? amount : (0).toFixed(2)}
                        </span>
                    </div>
                </>) : (<>
                    <span style={{}}>AED {amount !== null ? amount : (0).toFixed(2)}</span>
                </>)}

                {/* AED {amount} */}
            </div>}
            <div style={{ flex: 1 }}>
                {stockFlowItem.quantityValue} {stockFlowItem.uom}
            </div>
            {Util.isNumberExist(stockFlow.amount) && <div style={{ flex: 1 }}>
                AED {(stockFlowItem.quantityValue * amount).toFixed(2)}
            </div>}



        </div>
    )
}

class StockFlowItemPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            stockFlow: undefined,

            showCompleteQuotationSaleDialog: false,
            loadingFinalizeSales: false,

            showNotesDocsDialog: false,

            viewMode: "graphical-view"
        }
    }

    finalizeSales(paymentMethods) {
        this.setState({ loadingFinalizeSales: true })
        Api.completeQuotationSales(this.state.stockFlow.id, paymentMethods, response => {
            if (response.status === true) {
                this.setState({
                    stockFlow: response.payload,
                    showCompleteQuotationSaleDialog: false,
                    loadingFinalizeSales: false,
                })
                UIUtil.showSuccess("Sales made!");
            } else {
                UIUtil.showError(response.message);
                this.setState({
                    loadingFinalizeSales: false
                })
            }
        })
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.props.forceStockFlow) {
                listener({ status: true, payload: this.props.forceStockFlow })
            } else {
                Api.getStockFlowItem(this.getPathParams().stockFlowId, listener)
            }
        }, payload => ({
            stockFlow: payload
        }))
    }

    isQuotation() {
        //return this.state.stockFlow.quotation && !Util.isNumberExist(this.state.stockFlow.transactionId);
        return this.state.stockFlow.quotation && (this.state.stockFlow.transactionIds == null || this.state.stockFlow.transactionIds.length == 0);
    }

    isSalesReturn() {
        return this.state.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal();
    }

    isPurchaseReturn() {
        return !this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER
    }

    renderFlowReturnBtn() {
        let text;
        let flowId;
        if (this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal()) {
            text = "View Purchase Return";
            flowId = this.state.stockFlow.returnFlowId;
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
            text = "View Sales Return";
            flowId = this.state.stockFlow.returnFlowId;
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
            text = "View Original Purchase";
            flowId = this.state.stockFlow.returnFlowParentId;
        } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal()) {
            text = "View Original Sales";
            flowId = this.state.stockFlow.returnFlowParentId;
        }

        if (Array.isArray(flowId)) {
            return flowId.length > 0 && flowId.map((id, index) => (
                <Link key={id + 'flowIdReturn'} target="_blank" to={"/stock-flow/" + id} style={{ textDecoration: 'none' }}>
                    <Button kind="secondary" style={{ marginTop: '1rem', marginLeft: index > 0 ? '0.25rem' : 0 }} renderIcon={FlowStream16}>{text} ({Util.getVoucherNumber(id)})</Button>
                </Link>
            ))
        } else {
            return Util.isNumberExist(flowId) && (
                <Link target="_blank" to={"/stock-flow/" + flowId} style={{ textDecoration: 'none' }}>
                    <Button kind="secondary" style={{ marginTop: '1rem' }} renderIcon={FlowStream16}>{text}</Button>
                </Link>
            )
        }
    }

    isPurchase() {
        return this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal();
    }

    isCreation() {
        return this.state.stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION && !this.isDestinationExternal();
    }

    isSales() {
        return !this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER && !this.isQuotation();
    }

    onDeleteBtn() {
        UIUtil.confirmWithMsg(`Deleting the stock flow is irreversible and will be permanent!`, () => {
            Api.deleteStockFlow(this.state.stockFlow.id, response => {
                if (response.status === true) {
                    this.props.history.goBack();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        })
    }

    canBeReturned() {
        const isPurchase = this.isPurchase();
        const isSales = this.isSales()

        if (this.state.stockFlow.pastPurchase) {
            return null;
        }

        return !Util.isNumberExist(this.state.stockFlow.returnFlowId?.length) && (isPurchase || isSales);
    }

    getReturnUrl() {
        let reversedType;
        let reversedId;
        if (this.isPurchase()) {
            if (this.state.stockFlow.destinationType == DESTINATION_TYPE_WAREHOUSE) {
                reversedType = SOURCE_TYPE_WAREHOUSE;
            } else if (this.state.stockFlow.destinationType == DESTINATION_TYPE_STORE) {
                reversedType = SOURCE_TYPE_STORE;
            } else if (this.state.stockFlow.destinationType == DESTINATION_TYPE_MOBILE_UNIT) {
                reversedType = SOURCE_TYPE_MOBILE_UNIT;
            } else if (this.state.stockFlow.destinationType == DESTINATION_TYPE_VENUE) {
                reversedType = SOURCE_TYPE_VENUE;
            }
            reversedId = this.state.stockFlow.destinationId;
        } else {
            if (this.state.stockFlow.sourceType == SOURCE_TYPE_WAREHOUSE) {
                reversedType = DESTINATION_TYPE_WAREHOUSE;
            } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_STORE) {
                reversedType = DESTINATION_TYPE_STORE;
            } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_MOBILE_UNIT) {
                reversedType = DESTINATION_TYPE_MOBILE_UNIT;
            } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_VENUE) {
                reversedType = DESTINATION_TYPE_VENUE;
            }
            reversedId = this.state.stockFlow.sourceId;
        }

        return {
            pathname: "/stock-flow-creator",
            defaultFlow: {
                sourceTypeValue: this.isPurchase() ? reversedType : SOURCE_TYPE_CUSTOMER,
                destinationTypeValue: this.isPurchase() ? DESTINATION_TYPE_SUPPLIER + "" : reversedType,

                ...(this.isPurchase() ? { sourceIdValue: reversedId } : { destinationIdValue: reversedId }),

                returnFlowParentIdRequest: this.state.stockFlow.stockFlowRecordId,

                itemsValue: this.state.stockFlow.items.filter(item => !Util.isStringExists(item.itemProxyParentId)).map(item => ({
                    //...item, id: 0, recordId: null, itemProxyParentId: null, proxyParent: false, refund: this.isPurchase() ? item.cost : item.price
                    ...item, id: Util.newTempId(), recordId: null, itemProxyParentId: null, proxyParent: false, amount: item.refundWithoutTax
                }))
            }
        }
    }

    getSubtitle() {
        let subtitle;
        if (this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal()) {
            subtitle = this.state.stockFlow.pastPurchase ? "Past Purchase" : "Purchase"
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
            subtitle = this.isQuotation() ? "Quotation" : "Sales";
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
            subtitle = "Purchase Return"
        } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal()) {
            if (this.state.stockFlow.rentalReturn) {
                subtitle = "Rental Return"
            } else {
                subtitle = "Sales Return";
            }
        } else if (!this.isSourceExternal() && !this.isDestinationExternal()) {
            subtitle = "Stock Transfer";
        }

        return subtitle;
    }

    getStockFlowDirectionSuffix() {
        //remember against items in transaction item page
        if (this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal()) {
            //return "Purchase"
            return this.state.stockFlow.pastPurchase ? "Past Purchase" : "Purchase";
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
            return this.isQuotation() ? "Quotation" : "Sales";
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
            return "Purchase Return"
        } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal()) {
            if (this.state.stockFlow.rentalReturn) {
                return "Rental Return"
            } else {
                return "Sales Return";
            }
        } else if (!this.isSourceExternal() && !this.isDestinationExternal()) {
            return "Stock Transfer";
        }

        return undefined;
    }

    renderPrintBtn() {
        let btn;
        if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER && !this.isQuotation()) {
            //btn = <SalesReceiptDownload stockFlow={this.state.stockFlow} />
            if (Util.isNumberExist(this.state.stockFlow.invoiceDocumentId)) {
                btn = (
                    <Link to={"/invoice-documents/" + this.state.stockFlow.invoiceDocumentId} style={{ textDecoration: 'none' }}>
                        <Button style={{ width: '100%' }} renderIcon={Document16}>Invoice Document</Button>
                    </Link>
                )
            } else if (Util.isNumberExist(this.state.stockFlow.saleId)) {
                btn = (
                    <Link to={"/sale/" + this.state.stockFlow.saleId} style={{ textDecoration: 'none' }}>
                        <Button style={{ width: '100%' }} renderIcon={Certificate16}>Sale Voucher</Button>
                    </Link>
                )
            } else {
                btn = <StockFlowInvoiceBtn stockFlowId={this.state.stockFlow.id} />
            }
        } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal()) {
            if (Util.isNumberExist(this.state.stockFlow.purchaseId)) {
                btn = (
                    <Link to={"/purchase/" + this.state.stockFlow.purchaseId} style={{ textDecoration: 'none' }}>
                        <Button kind="secondary" style={{ width: '100%' }} renderIcon={Migrate16}>Purchase Voucher</Button>
                    </Link>
                )
            } else {
                btn = <PurchaseVoucherDownload stockFlow={this.state.stockFlow} />
            }
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER && this.isQuotation()) {
            btn = <QuotationDownload stockFlow={this.state.stockFlow} />
        } else if (!this.isSourceExternal() && !this.isDestinationExternal()) {
            btn = <StockTransferDownload stockFlow={this.state.stockFlow} />
        }

        let secondBtn;
        if (this.isSales()) {
            secondBtn = <StockFlowDeliveryNoteBtn stockFlow={this.state.stockFlow} />
        }


        if (hasCapabilitySupport('templateEngine')) {
            if (this.isSalesReturn()) {
                btn = (
                    <Button onClick={() => openPdf('CreditNote', this.state.stockFlow.id)} style={{ width: '100%' }} renderIcon={Document16}>Credit Note</Button>
                )
            } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal()) {
                // purchase
                if (!Util.isNumberExist(this.state.stockFlow.purchaseId)) {
                    btn = <>
                        <div>
                            <PurchaseVoucherDownload stockFlow={this.state.stockFlow} />
                        </div>
                        <Button onClick={() => openPdf('GoodsReceivedNote', this.state.stockFlow.id)} renderIcon={Document16}>Goods Received Note</Button>
                    </>
                }
            }
        }


        if (btn || secondBtn) {
            return (
                <div style={{ marginBottom: '2rem', display: 'flex', gap: '0.5rem' }}>
                    {btn} {secondBtn}
                </div>
            )
        } else {
            return null;
        }
    }

    getAmountStatus() {
        const amount = this.state.stockFlow.amount;
        const amountPaid = this.state.stockFlow.amountPaid;

        const amountLeft = amount - amountPaid;

        let status;
        let statusColor;
        if (amountLeft <= 0) {
            status = "Fully Paid";
            statusColor = "green";
        } else {
            status = amountPaid == 0 ? "Not paid" : "Partially paid";
            statusColor = amountPaid == 0 ? "red" : undefined;

            status += " (AED " + amountLeft.toFixed(2) + " left)";
        }

        return { status, statusColor }
    }

    renderAmount() {
        if (Util.isNumberExist(this.state.stockFlow.amount)) {
            const stockFlow = this.state.stockFlow;

            const amount = this.state.stockFlow.amount;
            const amountPaid = this.state.stockFlow.amountPaid;

            const amountLeft = amount - amountPaid;

            let status;
            let statusColor;
            if (amountLeft <= 0) {
                status = "Paid";
                statusColor = "green";
            } else {
                status = amountPaid == 0 ? "Not paid" : "Partially paid";
                statusColor = amountPaid == 0 ? "red" : undefined;
            }

            return (<>
                <div style={{ marginBottom: '1rem', }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
                    <p>
                        AED {amount.toFixed(2)}
                    </p>
                </div>

                <div style={{ marginBottom: '1rem', }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
                    <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                        AED {amountPaid.toFixed(2)}
                    </p>
                </div>

                {this.isSales() && <>
                    <TransactionPayButton
                        amount={parseFloat(parseFloat(stockFlow.amount - stockFlow.amountPaid).toFixed(2))}
                        direction={TRANSACTION_DIRECTION_TYPE_INWARD} itemId={stockFlow.id} itemType={OBJECT_TYPE_STOCK_FLOW}
                        partyItemType={OBJECT_TYPE_CUSTOMER} partyId={stockFlow.destinationId} />
                    <br />
                    <div style={{ marginBottom: '1rem' }} />
                </>}
            </>)
        } else {
            return null;
        }
    }

    renderStockId() {
        let idTitle;
        if (this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal()) {
            idTitle = "Purchase Stock Flow ID";
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
            idTitle = this.isQuotation() ? "Quotation ID" : "Sales ID";
        } else if (!this.isSourceExternal() && this.state.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
            idTitle = "Purchase Return ID";
        } else if (this.state.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal()) {
            if (this.state.stockFlow.rentalReturn) {
                idTitle = "Rental Return ID";
            } else {
                idTitle = "Sale Return ID";
            }
        } else if (!this.isSourceExternal() && !this.isDestinationExternal()) {
            idTitle = "Stock Transfer ID"
        } else {
            idTitle = "Stock Flow ID"
        }

        return (
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{idTitle}</label>
                <p style={{}}>{this.state.stockFlow.stockFlowRecordId}</p>
            </div>
        )
    }

    renderFlowCard() {
        if (this.state.stockFlow.sourceType === SOURCE_TYPE_VENUE && this.state.stockFlow.destinationType === DESTINATION_TYPE_CUSTOMER) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    {this.renderSourceCard()}
                    <DirectionStraightRight32 style={{ marginLeft: '3rem', marginRight: '3rem' }} />
                    {(!this.isSourceExternal() && !this.isDestinationExternal()) ? (
                        <Tag renderIcon={Delivery16}>Stock Transfer</Tag>
                    ) : (<>
                        <DirectionIndicator inward={this.isSourceExternal()} />
                        <h6 style={{ marginLeft: '0.25rem', fontSize: 14 }}>{this.getStockFlowDirectionSuffix()}</h6>
                    </>)}
                    <DirectionStraightRight32 style={{ marginLeft: '3rem', marginRight: '3rem' }} />
                    {/* {this.renderDestinationCard()} */}
                    <RadioItem icon={BuildingInsights_132} title="Venue Sales" />
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    {this.renderSourceCard()}
                    <DirectionStraightRight32 style={{ marginLeft: '3rem', marginRight: '3rem' }} />
                    {(!this.isSourceExternal() && !this.isDestinationExternal()) ? (
                        <Tag renderIcon={Delivery16}>Stock Transfer</Tag>
                    ) : (<>
                        <DirectionIndicator inward={this.isSourceExternal()} />
                        <h6 style={{ marginLeft: '0.25rem', fontSize: 14 }}>{this.getStockFlowDirectionSuffix()}</h6>
                    </>)}
                    <DirectionStraightRight32 style={{ marginLeft: '3rem', marginRight: '3rem' }} />
                    {this.renderDestinationCard()}
                </div>
            )
        }
    }

    renderGraphicalView() {
        const stockFlow = this.state.stockFlow;
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    {/* <FlowStream32 style={{marginRight: '0.25rem', marginTop: '0.25rem', width: 40, height: 40}} /> */}
                    <div style={{ flex: 1 }}>
                        <h1 style={{ flex: 1 }}>Stock Flow</h1>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ fontSize: 18 }}>{this.getSubtitle()}</p>
                            {Util.isNumberExist(this.state.stockFlow.returnFlowId?.length) &&
                                <p style={{ color: 'red', marginLeft: '0.25rem', fontSize: 14 }}>(Returned)</p>}
                        </div>
                    </div>

                    {stockFlow.canDelete &&
                        <Button kind="danger" onClick={() => this.onDeleteBtn()} renderIcon={Delete16} style={{ marginRight: 2, width: 195 }}>Delete</Button>}

                    {this.isPurchase() && (
                        <div style={{ marginRight: 2 }}>
                            <TransactionPayButton
                                amount={parseFloat(parseFloat(stockFlow.amount - stockFlow.amountPaid).toFixed(2))}
                                direction={TRANSACTION_DIRECTION_TYPE_OUTWARD} itemId={stockFlow.id} itemType={OBJECT_TYPE_STOCK_FLOW}
                                partyItemType={OBJECT_TYPE_SUPPLIER} partyId={stockFlow.sourceId} />
                        </div>
                    )}

                    {this.canBeReturned() ? (
                        <ButtonSet style={{ width: '392px', gap: 2 }}>
                            <Link to={this.getReturnUrl()} style={{ textDecoration: 'none' }}>
                                <Button kind="secondary" renderIcon={Redo16} style={{ width: 195 }}>
                                    {this.getSubtitle()} Return
                                </Button>
                            </Link>
                            <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                        </ButtonSet>
                    ) : (
                        <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                    )}
                </div>

                {(Util.isStringExists(stockFlow.purchaseSupplierInvoiceNo) || Util.isNumberExist(stockFlow.purchaseSupplierInvoiceDate)) &&
                    <Section icon={Report32} title={this.isPurchase() ? "Supplier Purchase Details" : "Details"}>
                        {Util.isStringExists(stockFlow.purchaseSupplierInvoiceNo) && <>
                            <label style={{ marginTop: '1rem' }} className="bx--label">Supplier Invoice No.</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Catalog32 style={{ marginRight: '0.5rem' }} />
                                <p>{stockFlow.purchaseSupplierInvoiceNo}</p>
                            </div>
                        </>}

                        {Util.isNumberExist(stockFlow.purchaseSupplierInvoiceDate) && <>
                            <label style={{ marginTop: '1rem' }} className="bx--label">
                                {this.isPurchase() ? 'Supplier Invoice Date' : 'Date'}
                            </label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Calendar32 style={{ marginRight: '0.5rem' }} />
                                <p>{Util.getDateOnly(stockFlow.purchaseSupplierInvoiceDate)}</p>
                            </div>
                        </>}
                    </Section>}

                {(this.state.stockFlow.usedInProduction || this.state.stockFlow.producedInProduction) ? (
                    <Link to={listDetailItemId('Productions', this.state.stockFlow.usedInProduction || this.state.stockFlow.producedInProduction)}>
                        <Button size="sm" style={{ borderRadius: 50, }} renderIcon={Industry16} kind="secondary">
                            Open Production
                        </Button>
                    </Link>
                ) : null}

                {(this.state.stockFlow.purchaseOrderId > 0) ? (
                    <Link to={listDetailItemId('V1PoList', this.state.stockFlow.purchaseOrderId)}>
                        <Button size="sm" style={{ borderRadius: 50, marginTop: '0.5rem' }} renderIcon={Cube16} kind="secondary">
                            Purchase Order #{this.state.stockFlow.purchaseOrderNo}
                        </Button>
                    </Link>
                ) : null}

                <Section icon={TextLinkAnalysis32} title="Flow info">
                    <div style={{ marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
                        <p style={{}}>{Util.getVoucherNumber(this.state.stockFlow.id)}</p>
                    </div>

                    {this.renderStockId()}
                    {this.renderPrintBtn()}

                    {this.renderAmount()}

                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Stock Quantity</label>
                    <p style={{}}>{stockFlow.totalStockCount}</p>

                    {Util.isNumberExist(stockFlow.salesPersonId) &&
                        <div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                            <label style={{}} className="bx--label">Salesperson</label>
                            <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + stockFlow.salesPersonId}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, stockFlow.salesPersonId)} />
                                    <p>{stockFlow.salesPersonNameFullName}</p>
                                </div>
                            </Link>
                        </div>}

                    {this.renderFlowCard()}

                    {this.renderFlowReturnBtn()}
                </Section>

                {stockFlow?.stockRequests?.length > 0 && <>
                    <Section icon={Cube32} title="Stock Requests">
                        <AssignedStockRequests value={stockFlow.stockRequests} noLabel />
                    </Section>
                </>}

                {Util.isNumberExist(stockFlow.salesOrderId) &&
                    <Section icon={LocationCompany32} title="Sales Order">
                        <div style={{ marginBottom: '1rem' }}>
                            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Sales Order ID'}</label>
                            <p style={{}}>{Util.getVoucherNumber(stockFlow.salesOrderId)}</p>
                        </div>

                        <div style={{ marginBottom: '1rem' }}>
                            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Sales Order No'}</label>
                            <p style={{}}>{stockFlow.salesOrderNo}</p>
                        </div>

                        <Link target="_blank" to={"/deliverables/" + stockFlow.salesOrderId} style={{ textDecoration: 'none', }}>
                            <Button kind="secondary" renderIcon={LocationCompany32} style={{ width: 195 }}>View Sales Order</Button>
                        </Link>
                    </Section>}

                {Util.isNumberExist(stockFlow.journalEntryId) &&
                    <Section icon={TreeView32} title="Accounting">
                        <Link target="_blank" to={"/journal-entry/" + stockFlow.journalEntryId} style={{ textDecoration: 'none', }}>
                            <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: 195 }}>Associated Journal Entry</Button>
                        </Link>
                    </Section>}

                <Section icon={Run32} title="Initiation">
                    <label style={{}} className="bx--label">Initiator</label>
                    <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + stockFlow.initiatorId}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, stockFlow.initiatorId)} />
                            <p>{stockFlow.initiatorFullName}</p>
                        </div>
                    </Link>

                    <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Calendar32 style={{ marginRight: '0.5rem' }} />
                        <p>{Util.getDate(stockFlow.initiationDate)}</p>
                    </div>

                    <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Type</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {stockFlow.initiationType == STOCK_FLOW_INITIATION_TYPE_POS ? (<>
                            <Calculator32 style={{ marginRight: '0.5rem' }} />
                            <p>POS</p>
                        </>) : stockFlow.initiationType == STOCK_FLOW_INITIATION_TYPE_SALES_CART ? (<>
                            <ShoppingCartPlus32 style={{ marginRight: '0.5rem' }} />
                            <p>Sales Cart</p>
                        </>) : (<>
                            <SettingsCheck32 style={{ marginRight: '0.5rem' }} />
                            <p>Backend</p>
                        </>)}
                    </div>
                </Section>

                {stockFlow.recordedDiscounts && stockFlow.recordedDiscounts.filter(discount => discount.posSessionDiscountType == POS_SESSION_DISCOUNT_TYPE_FREE_FORM).length > 0 &&
                    <Section icon={Rule32} title="Authorized Free-form Discounts">
                        {stockFlow.recordedDiscounts.filter(discount => discount.posSessionDiscountType == POS_SESSION_DISCOUNT_TYPE_FREE_FORM).map(item => (
                            <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} key={item.id} to={`${getObjectTypeUrl(OBJECT_TYPE_ACCOUNT)}/${item.authorizedByUserId}`}>
                                <div style={{
                                    background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
                                    paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem', display: 'flex', alignItems: 'center'
                                }}>
                                    <ProfilePic size={45} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.authorizedByUserId)} />
                                    <div style={{ marginLeft: '0.5rem' }}>
                                        <h4>{item.authorizedByName}</h4>
                                        {item.subtractingAmount ? (
                                            <h5>Amount authorized: AED {item.subtractingAmount.toFixed(2)}</h5>
                                        ) : (
                                            <h5>Percentage authorized: {item.subtractingPercentage}%</h5>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </Section>}

                {Util.isStringExists(stockFlow.info) &&
                    <Section icon={Catalog32} title="Additional Information">
                        <p style={{ whiteSpace: 'pre-line' }}>{stockFlow.info}</p>
                    </Section>}

                {((Util.isNumberExist(stockFlow.amount) && stockFlow.salesAmountSubtotal != (stockFlow.amount)) ||
                    (
                        stockFlow.amount != null && stockFlow.amount != undefined && Util.isNumberExist(stockFlow.salesAmountSubtotal) &&
                        Util.isNumberExist(stockFlow.salesAmountDiscount) &&
                        stockFlow.salesAmountDiscount >= stockFlow.salesAmountSubtotal
                    )) &&
                    <Section icon={Money32} title="Amount Breakdown">
                        {Util.isNumberExist(stockFlow.salesAmountSubtotal) && <>
                            <p style={{ fontSize: 14 }}>Subtotal (+)</p>
                            <h2>AED {stockFlow.salesAmountSubtotal.toFixed(2)}</h2>
                        </>}

                        {Util.isNumberExist(stockFlow.amountAdditionalCosts) && <>
                            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Additional Costs (+)</p>
                            <h2>AED {stockFlow.amountAdditionalCosts.toFixed(2)}</h2>
                        </>}

                        {(DISCOUNT_TYPE === "NORMAL" || DISCOUNT_TYPE === "TAX_INCLUDED") && Util.isNumberExist(stockFlow.salesAmountDiscount) && <>
                            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Deduction (-)</p>
                            <h2>AED {stockFlow.salesAmountDiscount.toFixed(2)}</h2>
                        </>}

                        {Util.isNumberExist(stockFlow.salesAmountTax) && <>
                            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Tax (+)</p>
                            <h2>AED {stockFlow.salesAmountTax.toFixed(2)}</h2>
                        </>}

                        {DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT" && Util.isNumberExist(stockFlow.salesAmountDiscount) && <>
                            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Deduction (-)</p>
                            <h2>AED {stockFlow.salesAmountDiscount.toFixed(2)}</h2>
                        </>}

                        <p style={{ marginTop: '1rem' }}>Total Amount</p>
                        <h1 style={{ color: 'green' }}>AED {stockFlow.amount.toFixed(2)}</h1>
                    </Section>}

                <Section icon={DeliveryParcel32} title="Items">
                    {/* repeated below */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', }}>
                        {stockFlow.items.filter(item => !Util.isStringExists(item.itemProxyParentId)).map(item =>
                            <ProductItem key={item.id + '-item'}
                                stockFlowItem={item}
                                isSalesReturn={this.isSalesReturn()}
                                isSales={this.isSales()}
                                isPurchase={this.isPurchase()}
                                isCreation={this.isCreation()}
                                isPurchaseReturn={this.isPurchaseReturn()}
                                stockFlow={this.state.stockFlow}
                            />)}
                    </div>

                </Section>

                {stockFlow.transactions !== null && stockFlow.transactions.length > 0 &&
                    <Section icon={Currency32} title="Transactions" //subtitle={'Total Amount Paid: AED ' + parseFloat(stockFlow.amountPaid).toFixed(2)}
                        subtitle={this.getAmountStatus().status} subtitleColor={this.getAmountStatus().statusColor}
                    >
                        {stockFlow.transactions.map(transaction => <TransactionItem key={transaction.id} stockFlow={stockFlow} transaction={transaction} isSourceExternal={this.isSourceExternal()} />)}
                        {/* {stockFlow.transactions.map(transaction => <p>{JSON.stringify(transaction)}</p>)} */}
                    </Section>}

                {/* {Util.isNumberExist(stockFlow.transactionId) && <Section icon={Currency32} title="Transaction" subtitle={'ID: ' + stockFlow.transaction.transactionId}>
                        {<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <ButtonSet style={{width: Util.isNumberExist(stockFlow.journalEntryId) ? '392px' : '195px', gap: 2}}>
                                {Util.isNumberExist(stockFlow.journalEntryId) &&  
                                <Link target="_blank" to={"/journal-entry/" + stockFlow.journalEntryId} style={{textDecoration: 'none',}}>
                                    <Button kind="secondary" renderIcon={RequestQuote16} style={{width: 195}}>Associated Journal Entry</Button>
                                </Link>}
                                <Link target="_blank" to={"/transaction/" + stockFlow.transactionId} style={{textDecoration: 'none',}}>
                                    <Button kind="secondary" renderIcon={Currency16} style={{width: 195}}>Associated Transaction</Button>
                                </Link>
                            </ButtonSet>
                        </div>}

                        <TransactionEditor
                        readOnly showColorForIncome
                        defaultPaymentMethods={stockFlow.transaction.paymentMethods}
                        currency={"AED"}
                        incoming={!this.isSourceExternal()}
                        totalAmount={stockFlow.transaction.amount} />
                    </Section>} */}

                {this.isQuotation() && <Section icon={Percentage32} title="Quotation" subtitle="Sales not completed">
                    <h2>{'AED'} {stockFlow.quotationAmount.toFixed(2)}</h2>
                    <Button onClick={() => this.setState({ showCompleteQuotationSaleDialog: true })}
                        style={{ marginTop: '1rem' }} renderIcon={CheckmarkFilled16}>Finalize Sales</Button>
                </Section>}

                <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                    <NoteView objectId={stockFlow.id} objectType={OBJECT_TYPE_STOCK_FLOW} />
                </Section>
            </>
        )
    }

    renderGridView() {
        const stockFlow = this.state.stockFlow;

        const amount = this.state.stockFlow.amount ? this.state.stockFlow.amount : 0;
        const amountPaid = this.state.stockFlow.amountPaid;

        let amountLeft = amount - amountPaid;

        let status;
        let statusColor;
        if (amountLeft <= 0) {
            status = "Fully Paid";
            statusColor = "green";
        } else {
            status = amountPaid == 0 ? "Not paid" : "Partially paid";
            if (amountPaid > 0) {
                status += ` (AED ${amountLeft.toFixed(2)} left)`
            }
            statusColor = amountPaid == 0 ? "red" : undefined;
        }

        return (
            <>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    {/* <FlowStream32 style={{marginRight: '0.25rem', marginTop: '0.25rem', width: 40, height: 40}} /> */}
                    <div style={{ flex: 1 }}>
                        <h1 style={{ flex: 1 }}>Stock Flow</h1>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ fontSize: 18 }}>Grid View</p>
                        </div>
                    </div>


                    {/* <a href={Api.downloadExportStockFlow(stockFlow.id)} download style={{ textDecoration: 'none' }}><Button renderIcon={DocumentExport16}>Export</Button></a> */}
                </div>

                <div style={{ padding: '1rem', backgroundColor: '#e0e0e0', width: '100%', marginTop: '1rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ flex: 1 }}>
                            {this.getSubtitle()}
                            {Util.isNumberExist(this.state.stockFlow.returnFlowId?.length) &&
                                <span style={{ color: 'red', fontSize: 14, marginLeft: '0.25rem' }}>(Returned)</span>}
                        </h3>

                        <p style={{ fontSize: 14 }}>{Util.getFullDate(stockFlow.initiationDate)}</p>
                    </div>
                    <label className="bx--label">{stockFlow.stockFlowRecordId}</label>

                    <div style={{ display: 'flex', marginTop: '2rem' }}>
                        <GridViewField icon={Account16} title="Voucher Number" value={Util.getVoucherNumber(stockFlow.id)} />
                        <GridViewField icon={User16} title="Initiator" value={stockFlow.initiatorFullName} />
                        <GridViewField icon={FlowStreamReference16} title="Initiation Type" value={stockFlow.initiationType == STOCK_FLOW_INITIATION_TYPE_POS ? "POS" : stockFlow.initiationType == STOCK_FLOW_INITIATION_TYPE_SALES_CART ? "Sales Cart" : "Backend"} />
                    </div>
                    <div style={{ display: 'flex', marginTop: '1rem' }}>
                        <GridViewField icon={Cube16} title="Stock Quantity" value={stockFlow.totalStockCount} />
                        <GridViewField icon={this.getSourceIcon()} title={this.getSourceTitle()} value={this.getSourceName()} />
                        <GridViewField icon={this.getDestinationIcon()} title={this.getDestinationTitle()} value={this.getDestinationName()} />
                    </div>
                    {(Util.isNumberExist(stockFlow.salesPersonId) || Util.isStringExists(stockFlow.purchaseSupplierInvoiceNo) || Util.isNumberExist(stockFlow.purchaseSupplierInvoiceDate)) &&
                        <div style={{ display: 'flex', marginTop: '1rem' }}>
                            {Util.isNumberExist(stockFlow.salesPersonId) ?
                                <GridViewField icon={Collaborate16} title="Salesperson" value={stockFlow.salesPersonNameFullName} /> : null}

                            {Util.isStringExists(stockFlow.purchaseSupplierInvoiceNo) ?
                                <GridViewField icon={Catalog16} title="Supplier Invoice No." value={stockFlow.purchaseSupplierInvoiceNo} /> : <div style={{ flex: 1 }} />}
                            {Util.isNumberExist(stockFlow.purchaseSupplierInvoiceDate) ?
                                <GridViewField icon={Calendar16} title="Supplier Invoice Date" value={Util.getDateOnly(stockFlow.purchaseSupplierInvoiceDate)} /> : <div style={{ flex: 1 }} />}

                            {!Util.isNumberExist(stockFlow.salesPersonId) && <div style={{ flex: 1 }} />}
                        </div>}

                    <div style={{ marginTop: '1rem' }}>
                        <div className="bx--structured-list-th"
                            style={{ display: 'flex', marginLeft: '-1rem', marginRight: '-1rem', color: 'white', paddingLeft: '1rem', paddingRight: '1rem', borderBottom: '0px solid #e0e0e0', background: '#1c1c1c' }}>
                            <div style={{ flex: 2 }}>
                                Item
                            </div>
                            {Util.isNumberExist(this.state.stockFlow.amount) && <div style={{ flex: 2 }}>
                                {this.getStockFlowItemAmountTitle((stockFlow.items !== null && stockFlow.items !== undefined) ? stockFlow.items[0] : undefined, this.isSalesReturn())}
                            </div>}
                            <div style={{ flex: 1 }}>
                                Quantity
                            </div>
                            {Util.isNumberExist(this.state.stockFlow.amount) && <div style={{ flex: 1 }}>
                                Total
                            </div>}
                        </div>
                        {stockFlow.items.filter(item => !Util.isStringExists(item.itemProxyParentId)).map(item =>
                            <GridProductItem key={item.id + '-item'} stockFlow={stockFlow} stockFlowItem={item}
                                isSalesReturn={this.isSalesReturn()}
                                isSales={this.isSales()}
                                isPurchase={this.isPurchase()}
                                isCreation={this.isCreation()}
                                isPurchaseReturn={this.isPurchaseReturn()}
                            />)}



                        {/* <div className="bx--structured-list-th" 
                        style={{display: 'flex', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5, paddingLeft: '1rem', borderBottom: '0px solid #e0e0e0', background: '#1c1c1c'}}>
                            <div style={{flex: 2}}>
                                Tets
                            </div>
                            <div style={{flex: 1}}>
                                Best
                            </div>
                        </div> */}
                    </div>

                    {Util.isNumberExist(stockFlow.amount) ? <div style={{ marginTop: '1rem' }}>
                        {//this.isSales() && 
                            stockFlow.salesAmountSubtotal != (stockFlow.amount) && <>
                                {Util.isNumberExist(stockFlow.salesAmountSubtotal) && <>
                                    <p style={{ fontSize: 14 }}>Subtotal (+)</p>
                                    <h2>AED {stockFlow.salesAmountSubtotal.toFixed(2)}</h2>
                                </>}

                                {Util.isNumberExist(stockFlow.amountAdditionalCosts) && <>
                                    <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Additional Costs (+)</p>
                                    <h2>AED {stockFlow.amountAdditionalCosts.toFixed(2)}</h2>
                                </>}

                                {(DISCOUNT_TYPE === "NORMAL" || DISCOUNT_TYPE === "TAX_INCLUDED") && Util.isNumberExist(stockFlow.salesAmountDiscount) && <>
                                    <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Deduction (-)</p>
                                    <h2>AED {stockFlow.salesAmountDiscount.toFixed(2)}</h2>
                                </>}

                                {Util.isNumberExist(stockFlow.salesAmountTax) && <>
                                    <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Tax (+)</p>
                                    <h2>AED {stockFlow.salesAmountTax.toFixed(2)}</h2>
                                </>}

                                {DISCOUNT_TYPE === "TAX_PLUS_DISCOUNT" && Util.isNumberExist(stockFlow.salesAmountDiscount) && <>
                                    <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Deduction (-)</p>
                                    <h2>AED {stockFlow.salesAmountDiscount.toFixed(2)}</h2>
                                </>}
                            </>}

                        <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>Total Amount</p>
                        <h1 style={{ color: 'green' }}>AED {stockFlow.amount.toFixed(2)}</h1>
                        <p style={{ fontSize: 14, color: statusColor }}>{status}</p>
                    </div> : <div style={{ height: 45 }} />}
                </div>
            </>
        )
    }

    getLayout() {
        if (this.props.renderSourceCardOnly) {
            return this.renderSourceCard();
        } else if (this.props.renderDestinationCardOnly) {
            return this.renderDestinationCard();
        } else if (this.props.renderItemsOnly) {
            return <>
                {/* repeated above */}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', }}>
                    {this.state.stockFlow.items.filter(item => !Util.isStringExists(item.itemProxyParentId)).map(item =>
                        <ProductItem key={item.id + '-item'}
                            stockFlowItem={item}
                            isSalesReturn={this.isSalesReturn()}
                            isSales={this.isSales()}
                            isPurchase={this.isPurchase()}
                            isCreation={this.isCreation()}
                            isPurchaseReturn={this.isPurchaseReturn()}
                            stockFlow={this.state.stockFlow}
                        />)}
                </div>
            </>
        }


        const stockFlow = this.state.stockFlow;
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '3rem', paddingBottom: '6rem' }}>
                <div style={{ width: this.props.fullWidth ? '90%' : '75vw' }}>
                    <div className="hidden-tab-content tab-no-padding" style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem' }}>
                        <DLink onClick={() => {
                            if (this.props.overrideBacktToListBtn) {
                                this.props.overrideBacktToListBtn();
                            } else {
                                this.props.history.goBack()
                            }
                        }} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>

                        <Tabs selected={this.state.viewMode == "graphical-view" ? 0 : 1}
                            onSelectionChange={index => this.setState({ viewMode: index == 0 ? "graphical-view" : "grid-view" })}
                            type="container" style={{ justifyContent: 'flex-end', flex: 1 }}>
                            <Tab id="graphical-view" label={<DataVis_420 />} />
                            <Tab id="grid-view" label={<TableSplit20 />} />
                        </Tabs>
                    </div>


                    {this.state.viewMode == "grid-view" ? this.renderGridView() : this.renderGraphicalView()}

                </div>

                {this.isQuotation() && <CompleteQuotationSaleDialog
                    loading={this.state.loadingFinalizeSales}
                    amount={stockFlow.quotationAmount} onSubmit={this.finalizeSales.bind(this)}
                    open={this.state.showCompleteQuotationSaleDialog} onClose={() => this.setState({ showCompleteQuotationSaleDialog: false })} />}
            </div>
        )
    }

    renderSourceCard() {
        let objectType;
        switch (this.state.stockFlow.sourceType) {
            case SOURCE_TYPE_SUPPLIER:
                objectType = OBJECT_TYPE_SUPPLIER;
                break;
            case SOURCE_TYPE_WAREHOUSE:
                objectType = OBJECT_TYPE_WAREHOUSE;
                break;
            case SOURCE_TYPE_STORE:
                objectType = OBJECT_TYPE_STORE;
                break;
            case SOURCE_TYPE_VENUE:
                objectType = OBJECT_TYPE_VENUE;
                break;
            case SOURCE_TYPE_CUSTOMER:
                objectType = OBJECT_TYPE_CUSTOMER;
                break;
            case SOURCE_TYPE_MOBILE_UNIT:
                objectType = OBJECT_TYPE_MOBILE_UNIT;
                break;
        }

        const card = this.getSourceCard();
        if (Util.isNumberExist(this.state.stockFlow.sourceId) && objectType !== undefined) {
            return (
                <Link target="_blank" style={{ textDecoration: 'none', color: 'inherit' }} to={getObjectTypeUrl(objectType) + '/' + this.state.stockFlow.sourceId}>
                    {card}
                </Link>
            )
        } else {
            return card;
        }
    }

    renderDestinationCard() {
        let objectType;
        switch (this.state.stockFlow.destinationType) {
            case DESTINATION_TYPE_SUPPLIER:
                objectType = OBJECT_TYPE_SUPPLIER;
                break;
            case DESTINATION_TYPE_CUSTOMER:
                objectType = OBJECT_TYPE_CUSTOMER;
                break;
            case DESTINATION_TYPE_WAREHOUSE:
                objectType = OBJECT_TYPE_WAREHOUSE;
                break;
            case DESTINATION_TYPE_STORE:
                objectType = OBJECT_TYPE_STORE;
                break;
            case DESTINATION_TYPE_VENUE:
                objectType = OBJECT_TYPE_VENUE;
                break;
            case DESTINATION_TYPE_MOBILE_UNIT:
                objectType = OBJECT_TYPE_MOBILE_UNIT;
                break;
        }

        const card = this.getDestinationCard();
        if (Util.isNumberExist(this.state.stockFlow.destinationId) && objectType !== undefined) {
            return (
                <Link target="_blank" style={{ textDecoration: 'none', color: 'inherit' }} to={getObjectTypeUrl(objectType) + '/' + this.state.stockFlow.destinationId}>
                    {card}
                </Link>
            )
        } else {
            return card;
        }
    }

    getSourceName() {
        if (this.state.stockFlow.producedInProduction) {
            return "Production Output";
        }

        if (this.state.stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION) {
            return "Stock Created";
        } else {
            return this.state.stockFlow.sourceName;
        }
    }

    getDestinationName() {
        if (this.state.stockFlow.usedInProduction) {
            return "Used in Production";
        }

        if (this.state.stockFlow.destinationType == DESTINATION_TYPE_STOCK_TERMINATION) {
            return "Stock Terminated";
        } else {
            return this.state.stockFlow.destinationName;
        }
    }

    getSourceTitle() {
        if (this.state.stockFlow.producedInProduction) {
            return "Production Output";
        }

        switch (this.state.stockFlow.sourceType) {
            case SOURCE_TYPE_SUPPLIER:
                return "Supplier";
            case SOURCE_TYPE_WAREHOUSE:
                return "Warehouse";
            case SOURCE_TYPE_STORE:
                return "Store";
            case SOURCE_TYPE_VENUE:
                return "Venue";
            case SOURCE_TYPE_STOCK_CREATION:
                return "Source";
            case SOURCE_TYPE_CUSTOMER:
                return "Customer";
            case SOURCE_TYPE_MOBILE_UNIT:
                return "Mobile Unit";
        }

        return undefined;
    }

    getSourceIcon() {
        switch (this.state.stockFlow.sourceType) {
            case SOURCE_TYPE_SUPPLIER:
                return Delivery16;
            case SOURCE_TYPE_WAREHOUSE:
                return DataVis_216;
            case SOURCE_TYPE_STORE:
                return Store16;
            case SOURCE_TYPE_VENUE:
                return Building16;
            case SOURCE_TYPE_STOCK_CREATION:
                return Cube16;
            case SOURCE_TYPE_CUSTOMER:
                return AirlinePassengerCare16;
            case SOURCE_TYPE_MOBILE_UNIT:
                return Van16;
        }

        return undefined;
    }

    getDestinationIcon() {
        switch (this.state.stockFlow.destinationType) {
            case DESTINATION_TYPE_SUPPLIER:
                return Delivery16;
            case DESTINATION_TYPE_CUSTOMER:
                return AirlinePassengerCare16;
            case DESTINATION_TYPE_WAREHOUSE:
                return DataVis_216;
            case DESTINATION_TYPE_STORE:
                return Store16;
            case DESTINATION_TYPE_VENUE:
                return Building16;
            case DESTINATION_TYPE_STOCK_TERMINATION:
                return CheckboxIndeterminate16;
            case DESTINATION_TYPE_MOBILE_UNIT:
                return Van16;
        }

        return undefined;
    }

    getDestinationTitle() {
        if (this.state.stockFlow.usedInProduction) {
            return "Used in Production";
        }

        switch (this.state.stockFlow.destinationType) {
            case DESTINATION_TYPE_SUPPLIER:
                return "Supplier";
            case DESTINATION_TYPE_CUSTOMER:
                return "Customer";
            case DESTINATION_TYPE_WAREHOUSE:
                return "Warehouse";
            case DESTINATION_TYPE_STORE:
                return "Store";
            case DESTINATION_TYPE_VENUE:
                return "Venue"
            case DESTINATION_TYPE_STOCK_TERMINATION:
                return "Destination";
            case DESTINATION_TYPE_MOBILE_UNIT:
                return "Mobile Unit"
        }

        return undefined;
    }

    //BELOW ITEMS IN LIST VIEW AS WELL
    getSourceCard() {
        if (this.state.stockFlow.producedInProduction) {
            return <RadioItem icon={Industry32} title="Production" desc="Production output" />
        }

        switch (this.state.stockFlow.sourceType) {
            case SOURCE_TYPE_SUPPLIER:
                return <RadioItem icon={Delivery32} title="Supplier" desc={this.state.stockFlow.sourceName} />
            case SOURCE_TYPE_WAREHOUSE:
                return <RadioItem icon={DataVis_232} title="Warehouse" desc={this.state.stockFlow.sourceName} />
            case SOURCE_TYPE_STORE:
                return <RadioItem icon={Store32} title="Store" desc={this.state.stockFlow.sourceName} />
            case SOURCE_TYPE_VENUE:
                return <RadioItem icon={Building32} title="Venue" desc={this.state.stockFlow.sourceName} />
            case SOURCE_TYPE_STOCK_CREATION:
                return <RadioItem icon={Cube32} title="Create" desc="Created stock" />
            case SOURCE_TYPE_CUSTOMER:
                return <RadioItem icon={AirlinePassengerCare32} title="Customer" desc={this.state.stockFlow.sourceName} />
            case SOURCE_TYPE_MOBILE_UNIT:
                return <RadioItem icon={Van32} title="Mobile Unit" desc={this.state.stockFlow.sourceName} />
        }

        return undefined;
    }

    getDestinationCard() {
        if (this.state.stockFlow.usedInProduction) {
            return <RadioItem icon={Industry32} title="Production" desc="Used in production" />
        }

        switch (this.state.stockFlow.destinationType) {
            case DESTINATION_TYPE_SUPPLIER:
                return <RadioItem icon={Delivery32} title="Supplier" desc={this.state.stockFlow.destinationName} />
            case DESTINATION_TYPE_CUSTOMER:
                return <RadioItem icon={AirlinePassengerCare32} title="Customer" desc={this.state.stockFlow.destinationName} />
            case DESTINATION_TYPE_WAREHOUSE:
                return <RadioItem icon={DataVis_232} title="Warehouse" desc={this.state.stockFlow.destinationName} />
            case DESTINATION_TYPE_STORE:
                return <RadioItem icon={Store32} title="Store" desc={this.state.stockFlow.destinationName} />
            case DESTINATION_TYPE_VENUE:
                return <RadioItem icon={Building32} title="Venue" desc={this.state.stockFlow.destinationName} />
            case DESTINATION_TYPE_STOCK_TERMINATION:
                return <RadioItem icon={CheckboxIndeterminate32} title="Terminate" desc="Terminated stock" />
            case DESTINATION_TYPE_MOBILE_UNIT:
                return <RadioItem icon={Van32} title="Mobile Unit" desc={this.state.stockFlow.destinationName} />
        }

        return undefined;
    }

    isSourceExternal() {
        return this.state.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER || this.state.stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION || this.state.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER;
    }

    isDestinationExternal() {
        return this.state.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER || this.state.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER || this.state.stockFlow.destinationType == DESTINATION_TYPE_STOCK_TERMINATION;
    }

    getStockFlowItemAmountTitle(stockFlowItem, isSalesReturn) {
        if (!stockFlowItem) {
            return "Amount";
        }

        let amountTitle;
        let amount;

        let originalAmountTitle;
        let originalAmount;
        if (this.isSales()) {
            amount = stockFlowItem.amount;
            originalAmount = stockFlowItem.originalPrice;
            originalAmountTitle = "Original Price";
            amountTitle = "Price";
        } else if (this.isPurchase()) {
            amount = stockFlowItem.amount;
            originalAmount = stockFlowItem.originalCost;
            originalAmountTitle = "Original Cost";
            amountTitle = "Cost";
        } else if (this.isCreation()) {
            amount = stockFlowItem.amount;
            originalAmount = stockFlowItem.originalCost;
            originalAmountTitle = "Original Cost";
            amountTitle = "Cost";
        } else if (this.isSalesReturn() || this.isPurchaseReturn()) {
            amount = stockFlowItem.amount;
            originalAmount = isSalesReturn ? stockFlowItem.originalPrice : stockFlowItem.originalCost;
            originalAmountTitle = isSalesReturn ? "Actual Price" : "Actual Cost";
            amountTitle = "Refund";
        }

        if (amount !== undefined) {
            amount = amount.toFixed(2);
        }
        if (originalAmount !== undefined) {
            originalAmount = originalAmount.toFixed(2);
        }

        return amountTitle;
    }

}

export default withRouter(StockFlowItemPage);
