import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import TransactionEditor from "../../transaction/TransactionEditor";
import { useState } from "react";
import Util from "../../../util/Util";
import { useEffect } from "react";
import Button from "../../../components/Button";


export function PaymentMethodDialog({ customerId, amount, open, onClose, onConfirmBtn, loading, hasCourierPM }) {
    const [editorKey, setEditorKey] = useState(() => Util.newTempId());
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        if (open) {
            setEditorKey(Util.newTempId())
            setPaymentMethods([])
        }
    }, [open])

    return (
        <ComposedModal open={open} onClose={onClose} size="lg">
            <ModalHeader label="Checkout" title="Add payment methods" />
            <ModalBody style={{ paddingRight: '1rem', marginBottom: '0rem' }}>
                <TransactionEditor key={editorKey}
                    onUpdate={paymentMethods => setPaymentMethods(paymentMethods)}
                    currency={"AED"}
                    posMode
                    incoming
                    hideDirection
                    showPosDiscountAmount
                    hasCourierPM={hasCourierPM}
                    customerId={customerId}
                    totalAmount={amount} />

            </ModalBody>
            <ModalFooter
            >

                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    loading={loading}
                    onClick={() => onConfirmBtn(paymentMethods)}>
                    Confirm
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}