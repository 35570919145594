import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import { newListDetail } from "../../base/list-detail"
import { AirlinePassengerCare32, AirlinePassengerCare24 } from '@carbon/icons-react'
import { TextBox } from "../../components/basic-filter/text-box"
import { isV2 } from "../../../util/Util"

export const V2CustomersList = newListDetail({
    title: "Customer",
    icon: AirlinePassengerCare32,
    mdIcon: AirlinePassengerCare24,

    filter: Filter, newForm: Form, updateForm: Form,

    defaultValues: {

    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Form({ endpoint, form }) {
    return (
        <div>
            {/* <form.CheckBoxField fieldKey="hasCustomerPos" title="Has ordering POS?" />
            <form.IfTargetGroup fieldKey="hasCustomerPos">
                <FormSection title={"Ordering POS"}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', }}>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="customerPosUsername" title="Username" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="customerPosUsername" title="Password" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="customerPosUsername" title="Clearance Code" />
                        </div>
                    </div>
                </FormSection>
            </form.IfTargetGroup> */}
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        {/* <TextBox state={state} label="Name" property="name" fullWidth /> */}
    </>)
}
