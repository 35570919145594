import { Activity32, DocumentImport32, TreeView32, Calendar32, Locked32, Number_132, Number_232, ArrowRight16, Warning16, } from "@carbon/icons-react";
import { RadioTile, Tag, TileGroup } from "carbon-components-react";
import { addDays } from "date-fns";
import React, { useState } from "react";
import { StatelessDateRange } from "../../reporting-engine/components/basic-filter/date-range";
import Button from "../../components/Button";
import UIUtil from "../../util/UIUtil";
import Util, { isV2 } from "../../util/Util";
import Api from "../../session/Api";
import { withLoadablePage } from "../../base/Page";

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)


const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginTop: '0.5rem', textAlign: 'center' }}>{title}</h4>
        <p style={{ marginTop: '0rem', textAlign: 'center', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

const RANGE_TYPE = {
    ALL_TIME: "ALL_TIME",
    SINCE_CLOSING: "SINCE_CLOSING",
    CUSTOM: "CUSTOM"
}

const RANGE_TYPE_LABEL = {
    ALL_TIME: "All Time",
    SINCE_CLOSING: "After Closed Books",
    CUSTOM: "Custom Range"
}

const STATUS = {
    NEVER_RAN: "NEVER_RAN",
    PROCESSING: "PROCESSING",
    FAILED: "FAILED",
    SUCCESS: "SUCCESS",
}

const UpdateMessage = ({ rangeType, startDate, endDate, payload }) => <>
    <p>Are you okay with your COGS ledgers and average costs to be updated permanently?</p>

    <p style={{ marginTop: '0.5rem' }}>It will update average costs and COGS for entries in range: <strong>{RANGE_TYPE_LABEL[rangeType]}</strong></p>

    {rangeType === RANGE_TYPE.SINCE_CLOSING && payload.closedBookDate > 0 && <>
        <p style={{ marginTop: '0.5rem', fontWeight: 'bold', color: 'orangered', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {Util.getDateOnly(payload.closedBookDate)}
            <ArrowRight16 />
            now
        </p>
    </>}

    {rangeType === RANGE_TYPE.CUSTOM && <>
        <p style={{ marginTop: '0.5rem', fontWeight: 'bold', color: 'orangered', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {Util.getDateOnly(startDate)}
            <ArrowRight16 />
            {Util.getDateOnly(endDate)}
        </p>
    </>}
</>

function View({ payload: initialPayload }) {
    const [payload, setPayload] = useState(initialPayload);

    const [rangeType, setRangeType] = useState(RANGE_TYPE.ALL_TIME);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();


    const [loading, setLoading] = useState(false);
    const onSubmit = async () => {
        if (rangeType === RANGE_TYPE.CUSTOM && (!startDate || !endDate || startDate > endDate)) {
            UIUtil.showError("Please select a valid date range");
            return;
        }

        if (!window.confirm("Please confirm this action. This is irreversible and will change your COGS ledgers and average costs!")) {
            return;
        }

        if (!(await UIUtil.confirmPrompt({ title: "Are you certain?", messageNode: <UpdateMessage {...{ rangeType, startDate, endDate, payload }} /> }))) {
            return;
        }

        const note = await UIUtil.inputPrompt({
            title: "Sign your confirmation",
            label: "Confirmation Note/Signature",
            message: "Please write a note recording yourself as the authorizer of this permanent and irreversible action.",
            textArea: true,
        })
        if (!Util.isStringExists(note)) {
            UIUtil.showInfo("Confirmation note was not supplied");
            return;
        }

        setLoading(true);
        try {
            const request = {
                rangeType,
                startDate,
                endDate,
                note,

                onlyRecreateJournalEntry: window._robo_onlyRecreateJournalEntry,
            }
            const [status, response] = await Api.try((api, listener) => api.submitCogsCalcTaskJob(request, listener), false, true);
            if (!status) {
                return;
            }

            UIUtil.showSuccess("Recalculation has been started and is being processed");
            setPayload(response);
            window.scrollTo({ top: 0, behavior: "smooth" });
        } finally {
            setLoading(false);
        }
    }


    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
            <div style={{ width: '75vw' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <Activity32 style={{ width: 64, height: 64, marginRight: '0.5rem' }} />
                    <div style={{ flex: 1 }}>
                        <h1>COGS Calculator</h1>
                        <p style={{ fontSize: 18 }}>Re-calculate and refresh previously recorded COGS</p>
                    </div>
                </div>
                <div style={{
                    borderRadius: 5, border: '1px solid #00000010', background: '#f4f4f4', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
                    padding: '1rem',
                    marginBottom: '1rem'
                }}>
                    <div style={{ marginBottom: '0.25rem', display: 'flex', alignItems: 'center' }}>
                        <h5 style={{}}>Last status update</h5>
                        {payload.lastUpdatedDate > 0 && <p style={{ fontSize: 14, opacity: 0.65, marginLeft: '0.25rem' }}>Last update: {Util.getFullDate(payload.lastUpdatedDate)}</p>}
                    </div>
                    {payload.status === STATUS.PROCESSING && <>
                        <Tag type="blue">Processing...</Tag>
                    </>}
                    {payload.status === STATUS.FAILED && <>
                        <Tag type="red">Failed</Tag>
                    </>}
                    {payload.status === STATUS.SUCCESS && <>
                        <Tag type="green">Success</Tag>
                    </>}
                    <p>{payload.lastStatusMessage}</p>
                </div>


                <Section icon={Number_132} title={"Select entries to update"}>


                    <TileGroup className="horizontal-tile-radio centered-content-tile"
                        valueSelected={rangeType} onChange={setRangeType}>
                        <RadioTile value={RANGE_TYPE.ALL_TIME}>
                            <RadioItem icon={TreeView32} title="All Time" desc={"Select ALL records that exist"} />
                        </RadioTile>

                        <RadioTile value={RANGE_TYPE.SINCE_CLOSING}>
                            <RadioItem icon={Locked32} title="After Closed Books" desc={"Select records from after closed books"} />
                        </RadioTile>
                        <RadioTile value={RANGE_TYPE.CUSTOM}>
                            <RadioItem icon={Calendar32} title="Custom Range" desc={"Select from a custom date range"} />
                        </RadioTile>
                    </TileGroup>

                </Section>

                {rangeType === RANGE_TYPE.CUSTOM && <Section icon={Number_232} title="Select custom date range">

                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <StatelessDateRange label="Entry"
                            start={startDate} end={endDate}
                            setStart={setStartDate} setEnd={setEndDate}
                        />
                    </div>

                </Section>}

                <div style={{ marginTop: '3rem', color: 'red' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Warning16 style={{ marginRight: '0.5rem' }} />
                        <h6>Disclaimer</h6>
                    </div>
                    <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                        * This tool will erase manual classes added to system generated Journal Entries.
                    </p>
                    <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                        * It is recommended to run this tool before or after work. Do not do it during work hours as it may result in inconsistent results or errors during processing.
                    </p>
                </div>

                <div style={{ marginTop: '6rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button disabled={!payload.canSubmit} onClick={onSubmit} loading={loading} renderIcon={DocumentImport32}>Confirm/Submit Task</Button>
                </div>
            </div>
        </div>
    )
}


export const CogsCalculator = withLoadablePage(Api.getCogsCalcPagePayload.bind(Api), View);