
import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, pdf, usePDF, PDFViewer } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';

import { Receipt16, DocumentPdf16 } from '@carbon/icons-react'
import Invoice from './pdf/Invoice';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';

const PDF = ({ title, details, showReceiverSign, posSessionSnapshot }) => (
    <Invoice invoice={{
        title, details, showReceiverSign,
        //id: "5df3180a09ea16dc4b95f910",
        invoice_no: posSessionSnapshot.lastStockFlowRecordId,
        balance: "$2,283.74",
        company: "MANTRIX",
        email: "susanafuentes@mantrix.com",
        phone: "+1 (872) 588-3809",
        address: "Address",
        trans_date: Util.getDateOnly(new Date().getTime()),
        due_date: Util.getDateOnly(new Date().getTime()),
        posSession: posSessionSnapshot,
        items: posSessionSnapshot.currentCartItems.map(item => ({
            sno: item.id,
            desc: item.name,
            qty: item.quantityValue,
            rate: item.amount.price,
        })),
    }} />
);


export const printGenericPosPdf = async (title, details, posSessionSnapshot, showReceiverSign) => {
    const blob = await pdf(<PDF title={title} details={details} posSessionSnapshot={posSessionSnapshot} showReceiverSign={showReceiverSign} />).toBlob();
    print(URL.createObjectURL(blob));
}

export const GenericPosPdfBtn = ({ title, icon, posStore, details, showReceiverSign }) => {
    const [loading, setLoading] = useState(false);
    const onPrintBtn = () => {
        setLoading(true)
        printGenericPosPdf(title, details, posStore.toObject(), showReceiverSign)
            .catch(() => UIUtil.showError())
            .finally(() => setLoading(false))
    }
    return <Button loading={loading} onClick={onPrintBtn} style={{ width: '100%' }} renderIcon={icon}>{title}</Button>
}

export const StockFlowDeliveryNoteBtn = ({ stockFlow }) => (
    <GenericPosPdfBtn title="Delivery Note" icon={Receipt16} posStore={{
        toObject: () => ({
            lastStockFlowRecordId: stockFlow.stockFlowRecordId,
            posTotals: {
                discount: stockFlow.salesAmountDiscount,
                subtotal: stockFlow.salesAmountSubtotal,
                tax: stockFlow.salesAmountTax,
                total: stockFlow.amount
            },
            currentCartItems: stockFlow.items.map($ => ({
                id: $.id,
                name: $.itemName,
                quantityValue: $.quantityValue,
                amount: {
                    price: $.amount
                }
            }))
        })
    }}
        showReceiverSign
        details={[
            { title: "Customer", value: Util.isStringExists(stockFlow.destinationName) ? stockFlow.destinationName : 'Guest' },
            { title: "Invoice No", value: Util.getVoucherNumber(stockFlow.id) },
            { title: "Date", value: Util.getDateOnly(stockFlow.initiationDate) },
        ]} />
)