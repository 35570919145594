import './reporting-dashboard.scss'
import { withLoadablePage } from "../base/Page"
import Api from "../session/Api";

import {
    ReportData32, Report32, ShoppingCartArrowUp32, ArrowRight16
} from '@carbon/icons-react'
import React, { useEffect, useRef } from 'react';
import useHover from '../hooks/useHover';
import { Link } from 'react-router-dom';
import { getReportDesignLink, getReportLink, routeReport } from './base/report-page';
import { getReport } from './reports/reports';

const Item = ({ primary, children }) => (
    <div className='reporting-dashboard-item' style={{ color: primary ? '#0f62fe' : undefined }}>
        <p style={{ flex: 1, opacity: 0.65 }}>{children}</p>
        <ArrowRight16 />
    </div>
)

const Title = ({ background, children }) => (
    <div style={{ background: background ?? '#333333', color: 'white', paddingBlock: '0.15rem', paddingInline: '1rem', borderBottom: '1px solid #00000020' }}>
        <p style={{ fontSize: 12, opacity: 0.65 }}>{children}</p>
    </div>
)

const Card = ({ report, background, titleColor, icon }) => {
    const ref = useRef()
    const hover = useHover()
    useEffect(() => {
        if (hover.isHover) {
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [hover.isHover])
    return (
        <div {...hover.init()} ref={ref} className='reporting-dashboard-card' style={{
            padding: '1rem', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ',
            display: 'flex', flexDirection: 'column', scrollMargin: 75
        }}>
            <div style={{ color: titleColor, height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {React.createElement(icon ?? Report32)}
                <h4>{report.title}</h4>
            </div>
            <div className='reporting-dashboard-card-inner' style={{ width: '100%', flex: 1, background: 'white', borderRadius: 10, overflowY: 'auto' }}>
                <Link to={getReportLink(report.name, {})}>
                    <Item primary>Go to report</Item>
                </Link>

                <Title background="#002c73">Public reports</Title>
                {report.publicDesigns.map(design => (
                    <Link to={getReportDesignLink(report.name, design.id)}>
                        <Item key={design.id}>{design.value}</Item>
                    </Link>
                ))}
                {report.publicDesigns.length === 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0.5rem', marginBottom: '2rem' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No reports</p>
                </div>}

                <Title>My reports</Title>
                {report.myDesigns.map(design => (
                    <Link to={getReportDesignLink(report.name, design.id)}>
                        <Item key={design.id}>{design.value}</Item>
                    </Link>
                ))}
                {report.myDesigns.length === 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0.5rem', marginBottom: '2rem' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No reports</p>
                </div>}
            </div>
        </div>
    )
}

function View({ payload: dashboard }) {
    return (
        <div style={{ height: '100%' }}>
            <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f4f4f4', borderBottom: '1px solid #00000010' }}>
                <ReportData32 />
                <h3>Reporting Dashboard</h3>
            </div>
            {dashboard.reports.length > 0 ? (
                <div style={{ height: 'calc(100% - 6rem)', padding: '1rem', display: 'flex', gap: '1rem', overflowY: 'none', overflowX: 'scroll' }}>
                    {dashboard.reports.map(report => <Card key={report.name} report={report} />)}
                </div>
            ) : (
                <div style={{ height: 'calc(100% - 6rem)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No reports available</p>
                </div>
            )}
        </div>
    )
}

export const ReportingDashboard = withLoadablePage(listener => Api.getReportingDashboard(response => {
    if (response.status === true) {
        listener({
            ...response, payload: {
                ...response.payload,
                reports: response.payload.reports.filter(report => getReport(report.name)?.isAvailable())
            }
        })
    } else {
        listener(response)
    }
}), View);