import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import { OBJECT_TYPE_BLOG_POST } from '../../../constants/ObjectTypes';
// import Page from '../../base/Page';

import TableTemplate from '../../../templates/TableTemplate';

class BlogPostsListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Blog Posts",
                    subTitle: "All blog posts",
                    objectType: OBJECT_TYPE_BLOG_POST,
                    pagePath: "/blog-posts/",
                    history: this.props.history
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(BlogPostsListPage);