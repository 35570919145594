import html2canvas from "html2canvas"
import { forwardRef } from "react";
import Portal from "./Portal";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { makeObservable } from "./makeObservable";
import useStore from "../hooks/useStore";
import Util from "./Util";
import QRCode from "react-qr-code";
import { ArabicPosReceipt } from "../integrations/thermal-printer/resource/arabic-pos-receipt";
import { renderToString } from 'react-dom/server'


function canvasToBitmap(canvas, width) {
    return new Promise((resolve, reject) => {
        if (!(canvas instanceof HTMLCanvasElement)) {
            reject(new Error('Invalid canvas element'));
        }

        const context = canvas.getContext('2d');
        if (!context) {
            reject(new Error('Canvas 2D context not supported'));
        }

        canvas.toBlob((blob) => {
            if (!blob) {
                reject(new Error('Unable to convert canvas to blob'));
            }

            createImageBitmap(blob, { resizeWidth: width })
                .then((imageBitmap) => {
                    resolve(imageBitmap);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    });
}

function bitmapToBase64(imageBitmap) {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (!context) {
            reject(new Error('Canvas 2D context not supported'));
        }

        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        context.drawImage(imageBitmap, 0, 0);

        try {
            const dataURL = canvas.toDataURL();
            resolve(dataURL);
        } catch (error) {
            reject(error);
        }
    });
}



const BITMAP_STORE = makeObservable();
export const BitmapManager = () => {
    const [bitmaps] = useStore(BITMAP_STORE, "bitmaps");

    return (
        <Portal>
            {bitmaps?.map(bitmap => <div style={{ position: 'fixed', top: '-100%', left: '-100%' }}>
                {bitmap.node}
            </div>)}
        </Portal>
    )
}

function addToManager(content, width) {
    const id = Util.newTempId();
    return new Promise((resolve, reject) => {
        const prev = BITMAP_STORE.get("bitmaps") ?? [];
        BITMAP_STORE.set("bitmaps", [...prev, {
            id, node: (
                <div ref={ref => resolve({ id, ref })} style={{ width, minWidth: width, maxWidth: width }}>
                    {content}
                </div>
            )
        }])
    })
}
function removeFromManager(id) {
    const prev = BITMAP_STORE.get("bitmaps") ?? [];
    BITMAP_STORE.set("bitmaps", prev.filter($ => $.id !== id));
}

export async function generateCanvas(content, width) {
    const { id, ref } = await addToManager(content, width);
    const canvas = await html2canvas(ref, { width, windowWidth: width });
    removeFromManager(id);
    return canvas;
}

export async function generateBitmap(content, width = 512) {
    const { id, ref } = await addToManager(content, width);

    const canvas = await html2canvas(ref, { width, windowWidth: width });
    const bitmap = await canvasToBitmap(canvas, width);
    const base64 = await bitmapToBase64(bitmap);

    removeFromManager(id);
    return base64;
}

export async function generateHtml(content) {
    const base64 = await generateBitmap(content);

    const html = `
      <html>
        <head>
          <title>Receipt</title>
        </head>
        <body style="margin: 0; background: white;">
          <img src="${base64}" style="width: 100vw; object-fit: cover; object-position: top; padding: 3rem;">
        </body>
      </html>
    `;

    return html;
}


export function openBase64ImageInNewTab(base64Data) {
    const newWindow = window.open();
    const html = `
      <html>
        <head>
          <title>Base64 Image</title>
        </head>
        <body style="margin: 0; display: flex; justify-content: center; align-items: center; background: black;">
          <img src="${base64Data}" style="max-width: 100%; max-height: 100%;">
        </body>
      </html>
    `;
    newWindow.document.open();
    newWindow.document.write(html);
    newWindow.document.close();
}