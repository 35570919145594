import { ComposedModal, FileUploaderDropContainer, FileUploaderItem, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from "carbon-components-react"
import Button from "../../../components/Button"
import { CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { useEffect, useState } from "react"
import { typeGetAttachmentType, typeHasAttachment, typeName } from "../components/type-name";
import Util from "../../../util/Util";
import { ADVANCED_NOTES_TYPE } from "..";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";

export function ItemCreateDialog({ parentType, parentId, type, visible, onClose, onNewItem }) {
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [attachment, setAttachment] = useState(undefined)

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {
            setTitle("");
            setDesc("")
            setAttachment(undefined);
        }
    }, [visible])

    const onSaveBtn = () => {
        setLoading(true);
        Api.saveAdvancedNote({
            parentType, parentId, type, title,
            description: desc
        }, attachment, response => {
            if (response.status === true) {
                onNewItem(response.payload);
                onClose();
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    return (
        <ComposedModal key="create-advanced-note-dialog" size="sm" open={visible} onClose={onClose}>
            <ModalHeader label="Creating" title={typeName(type)} />
            <ModalBody>
                <TextInput
                    data-modal-primary-focus
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    labelText="Title"
                    placeholder="Input title"
                />

                {type != ADVANCED_NOTES_TYPE.FOLDER && <>
                    <div style={{ height: '1rem' }} />
                    <TextArea
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
                        labelText={type == ADVANCED_NOTES_TYPE.NOTE ? "Note" : "Description"}
                        placeholder={type == ADVANCED_NOTES_TYPE.NOTE ? "Input note" : "Optional description"}
                    />

                </>}

                {typeHasAttachment(type) && <>
                    <div style={{ height: '1rem' }} />
                    <label className="bx--label">{typeName(type)}</label>
                    {attachment !== undefined ? (
                        <FileUploaderItem name={attachment.name} status="edit" onDelete={() => setAttachment(undefined)} />
                    ) : (
                        <FileUploaderDropContainer
                            accept={typeGetAttachmentType(type)}
                            labelText={"Drag and drop files here or click to upload"}
                            onAddFiles={(e, { addedFiles }) => {
                                if (addedFiles.length > 0) {
                                    setAttachment(addedFiles[0])
                                }
                            }} />
                    )}
                </>}

            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                    Close
                </Button>
                <Button loading={loading} disabled={!Util.isStringExists(title) || (typeHasAttachment(type) && attachment === undefined)} onClick={onSaveBtn} renderIcon={CheckmarkFilled16}>
                    Create
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}