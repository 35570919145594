import { DataTable, DataTableSkeleton, ExpandableSearch, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tag } from 'carbon-components-react';
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import useLocalStorage from '../../hooks/useLocalStorage';

import { 
    Add16,
    List16,
    Grid16,
    ShoppingCart32,
    ShoppingCartPlus16,
    WatsonHealthStackedScrolling_116,
    ViewOff16,
    View16,
    ColorSwitch16
} from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import useHover from '../../hooks/useHover';
import Util from '../../util/Util';

const VIEW_TYPE_KEY = "sc-l-vt"
const VIEW_TYPE_LIST = "vt-list"
const VIEW_TYPE_GRID = "vt-grid"

const SHOW_HIDDEN_KEY = "sc-l-sh"
const SHOW_FULL_COLOR = "sc-l-fc"

const MiniDashboard = () => {
    const hover = useHover()

    return (
        <div {...hover.init()} className="theme-bg" style={{ position: 'relative', width: 250, }}>


        </div>
    )
}

const Header = () => (
    <div style={{width: '100%', background: '#f4f4f4', marginBottom: -2, display: 'flex'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '0.25rem', padding: '1rem', flex: 1}}>
            <ShoppingCart32 />
            <h3>Sales Carts</h3>
        </div>
        {/* <MiniDashboard /> */}
    </div>
)

const TypeFilter = ({types, selectedType, onSelectedTypeChange}) => {
    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '0.25rem', marginRight: '1rem', padding: '0.5rem', flex: 1, flexWrap: 'wrap'}}>
            {[{id: 0}].concat(types).map(type => <Tag key={type.id + '-sales-cart-type-filter-id'} 
            className={selectedType != type.id ? "sales-cart-type-filter-tag" : ""}
            style={{opacity: selectedType == type.id ? 1 : 0.25, color: isLightColor(type.color ?? "#000000") ? "black" : "white", 
            background: type.color ?? "#000000", transition: '250ms', marginRight: type.id == 0 ? '1rem' : undefined}}
            onClick={selectedType != type.id ? (() => onSelectedTypeChange(type)) : undefined}
            renderIcon={type.id == 0 && WatsonHealthStackedScrolling_116}>{type.id > 0 ? type.name : "All"}</Tag>)}
        </div>
    )
}

const Toolbar = ({onSearchChange, viewType, onViewTypeChange, showFullColor, setShowFullColor, showHidden, setShowHidden, types, selectedType, onSelectedTypeChange}) => {
    const [creating, setCreating] = useState(false);
    const [searchFocused, setSearchFocused] = useState(false)

    const history = useHistory()

    const onNewCartBtn = () => {
        setCreating(true)
        Api.createSalesCart(response => {
            if (response.status === true) {
                history.push("/sales-carts/" + response.payload)
                UIUtil.showSuccess()
            } else {
                UIUtil.showError(response.message)
            }
            setCreating(false)
        })
    }

    return (
        <TableToolbar>
            <TableToolbarContent style={{height: 'unset', alignItems: 'flex-start'}}>
                {!searchFocused && <TypeFilter {...{ types, selectedType, onSelectedTypeChange }} />}
                <TableToolbarSearch //onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)} 
                onExpand={(_, expand) => setSearchFocused(expand)}
                // onFocus={() => {}} onBlur={() => {}} 
                placeholder={"Search Name"} onChange={e => onSearchChange(e.target.value)} />
                <TableToolbarMenu>
                    {/* <TableToolbarAction style={{display: 'flex', alignItems: 'center'}} onClick={() => onViewTypeChange(viewType == VIEW_TYPE_GRID ? VIEW_TYPE_LIST : VIEW_TYPE_GRID)}>
                        <span style={{flex: 1}}>{viewType == VIEW_TYPE_GRID ? "Show list" : "Show grid"}</span>
                        {React.createElement(viewType == VIEW_TYPE_GRID ? List16 : Grid16)}
                    </TableToolbarAction> */}

                    <TableToolbarAction style={{display: 'flex', alignItems: 'center'}} onClick={() => setShowFullColor(showFullColor == "true" ? 'false' : 'true')}>
                        <span style={{flex: 1}}>{showFullColor == "true" ? "Less highlight" : "More highlight"}</span>
                        {React.createElement(showFullColor == "true" ? ColorSwitch16 : ColorSwitch16)}
                    </TableToolbarAction>

                    <TableToolbarAction style={{display: 'flex', alignItems: 'center'}} onClick={() => setShowHidden(showHidden == "true" ? 'false' : 'true')}>
                        <span style={{flex: 1}}>{showHidden == "true" ? "Hide hidden" : "Show hidden"}</span>
                        {React.createElement(showHidden == "true" ? ViewOff16 : View16)}
                    </TableToolbarAction>
                </TableToolbarMenu>
                <Button onClick={onNewCartBtn} loading={creating} renderIcon={ShoppingCartPlus16}>New Cart</Button>
            </TableToolbarContent>
        </TableToolbar>
    )    
}
const List = ({carts, showFullColor}) => {
    const history = useHistory()
    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeader>
                        Name
                    </TableHeader>
                    <TableHeader>
                        Type
                    </TableHeader>
                </TableRow>
            </TableHead>
            <TableBody>
                {carts.map(cart => (
                    <TableRow key={cart.id} style={{ cursor: 'pointer' }} onClick={() => history.push('/sales-carts/' + cart.id)}>
                        <TableCell style={{}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {cart.name}
                                {cart.hidden && <ViewOff16 style={{ marginLeft: '0.25rem', opacity: 0.65 }} />}
                            </div>
                        </TableCell>
                        <TableCell key={showFullColor} style={{background: (cart.type && showFullColor) ? cart.type.color : undefined}}
                        >
                            {cart.type ? <>
                                <Tag key={cart.type.id + '-sales-cart-type-filter-id'} 
                                style={{color: isLightColor(cart.type.color ?? "#000000") ? "black" : "white", 
                                background: cart.type.color ?? "#000000", transition: '250ms',}}>{cart.type.name}</Tag>
                            </> : <>-</>}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table> 
    )
}

const Grid = ({carts}) => (
    <div style={{display: 'flex', }}>
        <GridItem />
    </div>
)
const GridItem = ({cart}) => {
    
    return (
        <div className="sales-cart-grid-item">
            
        </div>
    )
}

export default () => {
    const [loading, setLoading] = useState(true);

    const [carts, setCarts] = useState([])
    const [types, setTypes] = useState([])

    const [searchValue, setSearchValue] = useState("")

    const [selectedType, setSelectedType] = useState(0)
    const [viewType, setViewType] = useLocalStorage(VIEW_TYPE_KEY)
    const [showHidden, setShowHidden] = useLocalStorage(SHOW_HIDDEN_KEY)
    const [showFullColor, setShowFullColor] = useLocalStorage(SHOW_FULL_COLOR)
    
    const list = useMemo(() => {
        let list = carts;
        if (showHidden != "true") {
            list = list.filter(cart => !cart.hidden)
        }
        if (Util.isStringExists(searchValue)) {
            list = list.filter(cart => cart.name.toLowerCase().includes(searchValue.toLowerCase()))
        } else if (selectedType) {
            list = list.filter(cart => cart.typeId == selectedType)
        }
        return list;
    }, [selectedType, carts, showHidden, searchValue])

    useEffect(() => {
        setLoading(true)
        Api.getSalesCarts(response => {
            if (response.status === true) {
                setCarts(response.payload.salesCarts)
                setTypes(response.payload.types)
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [])

    if (loading) {
        return (
            <div style={{ width: '100%' }}>
                <DataTableSkeleton />
            </div>
        )
    }

    return (
        <div className="main-content">
            <div style={{ width: '100%' }}>
                <Header />
                <Toolbar 
                onSearchChange={setSearchValue}
                showFullColor={showFullColor} setShowFullColor={setShowFullColor}
                showHidden={showHidden} setShowHidden={setShowHidden}
                viewType={viewType} onViewTypeChange={viewType => setViewType(viewType)} types={types}
                selectedType={selectedType} onSelectedTypeChange={selectedType => setSelectedType(selectedType.id)} />

                {React.createElement(viewType == VIEW_TYPE_GRID ? Grid : List, { carts: list, showFullColor: showFullColor == "true" })}
                {viewType == VIEW_TYPE_LIST && <TableToolbar style={{width: '100%'}} />}
            </div>
        </div>
    )
}