import { Checkbox, ComboBox, TextInput, Toggle } from 'carbon-components-react';
import React, { useEffect } from 'react'



import { RowDelete16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';
import Button from './Button';

const PAYOUT_TYPE_FLAT = 0;
const PAYOUT_TYPE_PER_STUDENT = 1;

function typeToString(type) {
    switch (type) {
        case PAYOUT_TYPE_FLAT:
            return "Flat";
        case PAYOUT_TYPE_PER_STUDENT:
            return "Per Student";
        default:
            return "";
    }
}

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [name, setName] = useMultiInputState(input, 'name', onInputUpdate);
    const [price, setPrice] = useMultiInputState(input, 'price', onInputUpdate);
    const [payoutPercentage, setPayoutPercentage] = useMultiInputState(input, 'payoutPercentage', onInputUpdate);
    const [payoutType, setPayoutType] = useMultiInputState(input, "payoutType", onInputUpdate);
    const [hourlyPrice, setHourlyPrice] = useMultiInputState(input, "hourlyPrice", onInputUpdate);
    const [hourlyPayout, setHourlyPayout] = useMultiInputState(input, "hourlyPayout", onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <TextInput size="lg" labelText="Name" value={name} onChange={e => setName(e.target.value)} />
            <TextInput size="lg" labelText="Price" value={price} onChange={e => setPrice(e.target.value)} />
            <div style={{ paddingLeft: '0.5rem', paddingRight: '1rem', width: 125 }}>
                <label className="bx--label">Hourly Price</label>
                <Toggle
                    checked={hourlyPrice}
                    size="md"
                    labelA="No"
                    labelB="Yes"
                    onToggle={setHourlyPrice}
                />
            </div>


            <TextInput size="lg" labelText="Payout" value={payoutPercentage} onChange={e => setPayoutPercentage(e.target.value)} />

            <ComboBox
                titleText="Payout Type"
                style={{ width: 230 }}
                size="lg"
                items={[0, 1]}
                itemToString={typeToString}
                value={typeToString(payoutType)}
                selectedItem={payoutType}
                onChange={item => setPayoutType(item.selectedItem ?? 0)}
            />


            <div style={{ paddingLeft: '0.5rem', paddingRight: '1rem', width: 130 }}>
                <label className="bx--label">Hourly Payout</label>
                <Toggle
                    checked={hourlyPayout}
                    size="md"
                    labelA="No"
                    labelB="Yes"
                    onToggle={setHourlyPayout}
                />
            </div>


            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export default ({ defaultItems, onUpdate }) => (
    <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
)