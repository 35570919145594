

import {
    DocumentBlank16,
    Folder16,
    DocumentPdf16,
    Image16,
    VideoChat16,
    DocumentUnknown16,
    Notebook16
} from '@carbon/icons-react'
import { ADVANCED_FILTER_TYPE } from '..';

export function typeName(type) {
    switch (type) {
        case ADVANCED_FILTER_TYPE.LOGIC:
            return "Logical Operator"
        case ADVANCED_FILTER_TYPE.FILTER:
            return "Filter"
        default:
            return "Unknown";
    }
}