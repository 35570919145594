import { Checkbox, ContentSwitcher, Switch } from "carbon-components-react";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange, EndDateOnly } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"

export const StockValuationReport = {
    filter: Filter,
    viewBased: true,

    //itemToOpenLink: item => `/products/${item.id}`,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const HIDE_ZERO_STOCK_FILTER = { property: "qty", operator: "GT", value: 0 }

function Filter({ endpoint, state }) {
    return (<>
        <EndDateOnly state={state} property="date" skipAutoFilter />
        <Divider />
        <CheckboxFilter state={state} filterId="stockReportHideZeroStock" label="Hide with 0 stock" filter={HIDE_ZERO_STOCK_FILTER} />

        {/* <div style={{ flex: 1 }} />

        <div style={{ width: 250 }}>
            <ContentSwitcher selectedIndex={0} size="sm" onChange={e => { }}>
                <Switch name="BASIC" text="All Locations" />
                <Switch name="ADVANCED" text="Filter Locations" />
            </ContentSwitcher>
        </div> */}
    </>)
}
