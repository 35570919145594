import PaginatedDataTable from "../../../components/data-table/PaginatedDataTable"
import Util from "../../../util/Util"

import {
    Calendar16, Filter16, ArrowRight16, Search16, Chat16, Close16, Copy16, Alarm16, WatsonHealthStackedScrolling_116, ErrorFilled16, ArrowsVertical16, SortAscending16, SortDescending16, Save16, Delete16
} from '@carbon/icons-react'
import StatusIndicator from "../views/StatusIndicator";
import { useHistory, Link as LK } from "react-router-dom";
import Api from "../../../session/Api";
import { STATUS_FINALIZED, STATUS_PENDING, STATUS_REVOKED, addressToString } from "../base/sales-order";
import Button from "../../../components/Button";
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { useEffect, useMemo, useRef, useState } from "react";
import { ContentSwitcher, MultiSelect, Link, Switch, TextInput, ComboBox, Tag, ComposedModal, ModalHeader, ModalBody, ModalFooter, DatePicker, DatePickerInput, Checkbox, InlineLoading } from "carbon-components-react";
import UIUtil from "../../../util/UIUtil";
import useLocalStorage, { useObjectLocalStorage } from "../../../hooks/useLocalStorage";
import Portal from "../../../util/Portal";
import { CirclePicker } from "react-color";
import { classExistsInSomeParent } from "../../label-designer/util/ParentFinder";
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { TypeTag } from "../../pos/restaurant/components/type-tag";
//import { fromEpoch, fromEpochUsingDate, toEpoch, toEpochUsingDate } from "../../../reporting-engine/components/basic-filter/date-range";
import { MultiSelector } from "../../../components/multi-selector";
import { REST_ORDER_TYPE, getOrderCustomerName } from "../../../domain/rest-sales-order";
import DateTimePicker, { StatelessDatePicker, StatelessDateTimePicker } from "../../../components/DateTimePicker";
import { COMPANY_NAME } from "../../../app/Config";
import { PrintBillButton, PrintBillButtonForOrderId } from "../views/print-bill-button";
import { NewOrderButton } from "../views/rest/new-order-button";
import { StatelessDateRange } from "../../../reporting-engine/components/basic-filter/date-range";
import { SidePanel } from "../../../templates/draft/components/side-panel";
import DeliverableItemPage from "../DeliverableItemPage";
import { copyToClipboard } from "../../monitoring/TerminalMonitoringView";
import { LockScroll } from "../../product/ProductListPage";
import { getAccountRole } from "../../../session/SessionManager";
import { ACCOUNT_TYPE_REST_DELIVERY_MANAGER } from "../../../constants/Constants";
import { isAbshar, isRivas, isFoodBazaar } from "../../../app/app-util";
import { OrderPreInvoicePaymentIndicator } from "../../pos/restaurant/components/order-card";
import { CarbonDateTimeRange } from "../../../reporting-engine/components/basic-filter/date-time-range";
import { addDays, startOfDay } from "date-fns";
import { createScheduleFilter } from "../../pos/restaurant/engine/OrderState";

const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

const isReqExist = req => {
    for (const key in req) {
        const val = req[key];
        if (typeof val === "string") {
            if (val.trim().length > 0) {
                return true;
            }
        } else if (typeof val === "number") {
            if (val > 0) {
                return true;
            }
        } else if (Array.isArray(val)) {
            if (val.length > 0) {
                return true;
            }
        }
    }
    return false;
}

const useIsInFilter = (req) => {
    return useMemo(() => isReqExist(req), [req]);
}

const renderAmount = (salesOrder) => {
    if (Util.isNumberExist(salesOrder.amount)) {
        const amount = salesOrder.amount;
        const amountPaid = salesOrder.paidAmount;

        const amountLeft = amount - amountPaid;

        let status;
        let statusColor;
        if (amountLeft <= 0) {
            status = "Paid";
            statusColor = "green";
        } else {
            status = amountPaid == 0 ? "Not paid" : "Partially paid";
            statusColor = amountPaid == 0 ? "red" : undefined;
        }

        return (<>

            <div style={{ height: 5 }} />

            {Util.isStringExists(salesOrder.actualPaymentMethod) ? (
                <div style={{ marginBottom: '0.5rem', }}>
                    <label style={{ marginBottom: '-0.25rem', color: '#009900' }} className="bx--label">Payment Method</label>
                    <p style={{ color: '#009900' }}>
                        {salesOrder.actualPaymentMethod}
                    </p>
                </div>
            ) : (
                <div />
                // <div style={{ marginBottom: '0.5rem', }}>
                //     <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Preferred Payment Method</label>
                //     <p>
                //         {salesOrder.preferredPaymentMethodName}
                //     </p>
                // </div>
            )}

            <div style={{ marginBottom: '0.5rem', }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
                <p>
                    AED {amount.toFixed(2)}
                </p>
            </div>

            {(Util.isNumberExist(salesOrder.salesStockFlowId) || Util.isNumberExist(salesOrder.v2SaleId)) ? (<>
                <div style={{ marginBottom: 0, }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
                    <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                        AED {amountPaid.toFixed(2)}
                    </p>
                </div>
            </>) : (
                salesOrder.status !== STATUS_FINALIZED && salesOrder.status !== STATUS_REVOKED ? (<>
                    {/* <OrderPreInvoicePaymentIndicator order={salesOrder} updateOrder={} /> */}
                    <p style={{ opacity: 0.65, fontSize: 12 }}>No sales occurred yet</p>
                </>) : (<>
                    <p style={{ opacity: 0.65, fontSize: 12 }}>No sales occurred yet</p>
                </>)
            )}


            {(salesOrder?.discounts?.length ?? 0) > 0 && (
                <div style={{ borderRadius: 5, marginTop: '0.25rem', marginBottom: 15, width: '100%', paddingBlock: '0.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#bae6ff' }}>
                    <p style={{ textAlign: 'center', fontSize: 12, opacity: 1 }}>
                        Coupon / loyalty points applied
                    </p>
                </div>
            )}


            {isRivas() && salesOrder.status != STATUS_REVOKED && amountLeft > 0 && <>
                <div style={{ height: 5 }} />
                <a href={"https://rivas.ae/pay-order/" + salesOrder.orderNo} target="_blank">
                    <Button className="green-button" size="sm" renderIcon={ArrowRight16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>Payment Link</Button>
                </a>
                <div style={{ height: 5 }} />
            </>}
            {isAbshar() && salesOrder.status != STATUS_REVOKED && amountLeft > 0 && <>
                <div style={{ height: 5 }} />
                <a href={"https://grandabshar.ae/pay-order/" + salesOrder.orderNo} target="_blank">
                    <Button className="green-button" size="sm" renderIcon={ArrowRight16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>Payment Link</Button>
                </a>
                <div style={{ height: 5 }} />
            </>}
            {isFoodBazaar() && salesOrder.status != STATUS_REVOKED && amountLeft > 0 && <>
                <div style={{ height: 5 }} />
                <a href={"https://foodbazaardubai.com/pay-order/" + salesOrder.orderNo} target="_blank">
                    <Button className="green-button" size="sm" renderIcon={ArrowRight16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>Payment Link</Button>
                </a>
                <div style={{ height: 5 }} />
            </>}


        </>)
    } else {
        if ((salesOrder?.discounts?.length ?? 0) > 0) {
            return (
                <div style={{ borderRadius: 5, marginBottom: 15, width: '100%', paddingBlock: '0.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#bae6ff' }}>
                    <p style={{ textAlign: 'center', fontSize: 12, opacity: 1 }}>
                        Coupon / loyalty points applied
                    </p>
                </div>
            )
        } else {
            return <div />;
        }
    }
}

const isScheduledOrder = createScheduleFilter('future').predicate;

const renderDate = row => {
    return (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <h6>Order date/time</h6>
            <p><Calendar16 /> {Util.getFullDate(row.dateCreated)}</p>

            {row.status == STATUS_PENDING ? (
                <>
                    <div style={{ height: 5 }} />
                    <h6>Preferred Date</h6>
                    <p><Calendar16 /> {Util.getFullDate(row.preferredDeliveryDate)}</p>

                    {row.requiresRentalPickup && row.preferredRentalPickupDate > 0 && <>
                        <div style={{ height: 5 }} />
                        <h6>Preferred Pickup Date</h6>
                        <p><Calendar16 /> {Util.getFullDate(row.preferredRentalPickupDate)}</p>
                    </>}
                </>
            ) : <>

                {row.invoicedDate > 0 ? (<>
                    <div style={{ height: 5 }} />
                    <h6 style={{ color: 'green' }}>Invoiced Date</h6>
                    <p style={{ color: 'green' }}><Calendar16 /> {Util.getFullDate(row.invoicedDate)}</p>
                </>) : (<>
                    <div style={{ height: 5 }} />
                    <h6>Target Date</h6>
                    <p><Calendar16 /> {Util.getFullDate(row.targetDeliveryDate)}</p>
                </>)}

                {row.requiresRentalPickup && (row.targetRentalPickupDate > 0 || row.dateRentalStockReturned > 0) && <>

                    {row.dateRentalStockReturned > 0 ? (<>
                        <div style={{ height: 5 }} />
                        <h6 style={{ color: 'green' }}>Picked up Date</h6>
                        <p style={{ color: 'green' }}><Calendar16 /> {Util.getFullDate(row.dateRentalStockReturned)}</p>
                    </>) : (<>
                        <div style={{ height: 5 }} />
                        <h6 style={{ color: 'red' }}>Target Pickup Date</h6>
                        <p style={{ color: 'red' }}><Calendar16 /> {Util.getFullDate(row.targetRentalPickupDate)}</p>
                    </>)}
                </>}
            </>}

            {isScheduledOrder(row) && <>
                <div style={{ height: 5 }} />
                <Tag type="magenta" renderIcon={Calendar16}>Scheduled</Tag>
            </>}


        </div>
    )
}

const renderCustomer = (row, role) => {
    if (hasCapabilitySupport("restaurant")) {
        return (
            <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <h6>Customer</h6>
                {<>
                    <p>
                        {getOrderCustomerName(row)} - {Util.isNumberExist(row.customerId) ? (
                            <LK to={"/customers/" + row.customerId}>
                                <Link onClick={e => e.stopPropagation()}>View Customer</Link>
                            </LK>
                        ) : (
                            <strong>Guest</strong>
                        )}
                    </p>
                </>}
                {Util.isStringExists(row.shippingAddress?.readablePhoneNumber) && <>
                    <div style={{ height: 5 }} />
                    <h6>Phone number</h6>
                    {row.shippingAddress.readablePhoneNumber.includes('WhatsApp') ? (
                        <p style={{ fontWeight: 'bold', color: '#009900' }}>{row.shippingAddress.readablePhoneNumber}</p>
                    ) : (
                        <p>{row.shippingAddress.readablePhoneNumber}</p>
                    )}
                    <Button renderIcon={Copy16} onClick={e => {
                        e.stopPropagation()
                        copyToClipboard(row.shippingAddress.readablePhoneNumber)
                    }} size="sm" kind="tertiary" style={{ borderRadius: 25, marginBottom: 5 }}>Copy Number</Button>
                </>}

                {Util.isStringExists(row.deliveryLocationName) && <>
                    <div style={{ height: 5 }} />
                    <h6>Delivery Area</h6>
                    <p>{row.deliveryLocationName}</p>
                </>}

                {row.shippingAddress && <>
                    <div style={{ height: 5 }} />
                    <h6>Address</h6>
                    <p>{addressToString(row.shippingAddress)}</p>
                </>}
            </div>
        )
    }

    return (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            {/* <h6>Customer</h6>
            <p>{row.customerName ?? "Guest"}</p>
            <div style={{ height: 5 }} /> */}
            <h6>Full Name</h6>
            <p>{row.shippingAddress?.firstName} {row.shippingAddress?.lastName}</p>
            {//Util.isStringExists(row.shippingAddress) && 
                <>
                    <h6 style={{ color: 'magenta' }}>Area</h6>
                    <p style={{ color: Util.isStringExists(row.shippingAddress?.area) ? 'magenta' : '#000000AA' }}>{row.shippingAddress?.area ?? 'No area set'}</p>
                </>}
        </div>
    )
}

const get = (req, page, listener) => Api.getSalesOrders({ ...req, page }, listener)

function rivasWhatsappTracking(order) {
    const msg = `Thank you for choosing Rivas!

We’ve received your online order and it’s being prepared with care. You can track the status of your order here: 

https://rivas.ae/track-order/{orderNo}

We’ll keep you updated! Enjoy your meal.`.replace('{orderNo}', order.orderNo)

    return 'https://wa.me/' + order?.shippingAddress?.phoneNumber + "?text=" + encodeURIComponent(msg)
}

function absharWhatsappTracking(order) {
    const msg = `Thank you for choosing Grand Abshar!

We’ve received your online order and it’s being prepared with care. You can track the status of your order here: 

https://grandabshar.ae/track-order/{orderNo}

We’ll keep you updated! Enjoy your meal.`.replace('{orderNo}', order.orderNo)

    return 'https://wa.me/' + order?.shippingAddress?.phoneNumber + "?text=" + encodeURIComponent(msg)
}

function foodbazaarWhatsappTracking(order) {
    const msg = `Thank you for choosing FoodBazaar!

We’ve received your online order and it’s being prepared with care. You can track the status of your order here: 

https://foodbazaardubai.com/track-order/{orderNo}

We’ll keep you updated! Enjoy your meal.`.replace('{orderNo}', order.orderNo)

    return 'https://wa.me/' + order?.shippingAddress?.phoneNumber + "?text=" + encodeURIComponent(msg)
}



const headers = (mainApp, role) => [
    // {
    //     id: "id",
    //     name: "Order ID",
    //     render: item => Util.getVoucherNumber(item.id)
    // },
    {
        id: "orderNo",
        name: "Order",
        render: item => <>
            <div style={{ height: 5 }} />
            <h6 style={{ padding: '0.25rem', fontSize: 14, background: "#1c1c1c", borderRadius: 5, border: '1px solid #ffffff10', color: 'white', display: 'inline' }}>{item.storeName}</h6>
            <div style={{ height: 10 }} />
            <TypeTag type={item.type} noPadding />
            <div style={{ height: 5 }} />
            {item.fromOnlineStore && <>
                <Tag type="blue" size='sm'>online store</Tag>
                <div style={{ height: 5 }} />
            </>}

            <h6>Order No</h6>
            <span>{item.orderNo}</span>
            <div style={{ height: 5 }} />

            {Util.isStringExists(item.kotNo) && <>
                <h6>KOT No</h6>
                <span>{item.kotNo}</span>
                <div style={{ height: 5 }} />
            </>}

            {Util.isStringExists(item.tableName) && <>
                <h6>Table</h6>
                <span>{item.tableName}</span>
                <div style={{ height: 5 }} />
            </>}
        </>
    },
    {
        id: "customerId",
        name: "Customer",
        render: row => renderCustomer(row, role)
    },
    {
        id: "dateCreated",
        name: "Date",
        sortable: true,
        render: renderDate
    },
    {
        id: "status",
        name: "Status",
        render: item => (
            <>
                <StatusIndicator status={item.status} state={item.orderState} assignedDriverName={item.currentAssignedDriverId ? item.assignedDriverName : ""} />

                {Util.isNumberExist(item.creationToDeliveryTime) && <>
                    <div style={{ height: 5 }} />
                    <h6>Creation-To-Delivery Time</h6>
                    <p><Alarm16 /> {Util.formatSecondsPrettySmall(item.creationToDeliveryTime / 1000)} ({Util.formatSeconds(item.creationToDeliveryTime / 1000)})</p>
                </>}

                {Util.isStringExists(item.deliveredByName) && <>
                    <div style={{ height: 5 }} />
                    <h6>Delivered by</h6>
                    <p style={{ color: '#0f62fe' }}>{item.deliveredByName}</p>
                </>}

                {<>
                    {isRivas() && item.type === REST_ORDER_TYPE.DELIVERY && <>
                        <div style={{ height: 5 }} />
                        <a href={"https://rivas.ae/track-order/" + item.orderNo} target="_blank">
                            <Button size="sm" renderIcon={ArrowRight16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>Track Order</Button>
                        </a>
                    </>}
                    {isRivas() && item.type === REST_ORDER_TYPE.DELIVERY && <>
                        <div style={{ height: 5 }} />
                        <a href={rivasWhatsappTracking(item)} target="_blank">
                            <Button className="green-button" size="sm" renderIcon={Chat16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>WhatsApp</Button>
                        </a>
                    </>}


                    {isAbshar() && item.type === REST_ORDER_TYPE.DELIVERY && <>
                        <div style={{ height: 5 }} />
                        <a href={"https://grandabshar.ae/track-order/" + item.orderNo} target="_blank">
                            <Button size="sm" renderIcon={ArrowRight16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>Track Order</Button>
                        </a>
                    </>}
                    {isAbshar() && item.type === REST_ORDER_TYPE.DELIVERY && <>
                        <div style={{ height: 5 }} />
                        <a href={absharWhatsappTracking(item)} target="_blank">
                            <Button className="green-button" size="sm" renderIcon={Chat16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>WhatsApp</Button>
                        </a>
                    </>}


                    {isFoodBazaar() && item.type === REST_ORDER_TYPE.DELIVERY && <>
                        <div style={{ height: 5 }} />
                        <a href={"https://foodbazaardubai.com/track-order/" + item.orderNo} target="_blank">
                            <Button size="sm" renderIcon={ArrowRight16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>Track Order</Button>
                        </a>
                    </>}
                    {isFoodBazaar() && item.type === REST_ORDER_TYPE.DELIVERY && <>
                        <div style={{ height: 5 }} />
                        <a href={foodbazaarWhatsappTracking(item)} target="_blank">
                            <Button className="green-button" size="sm" renderIcon={Chat16} onClick={e => e.stopPropagation()} style={{ borderRadius: 7 }}>WhatsApp</Button>
                        </a>
                    </>}
                </>}
            </>
        )
    },
    {
        id: "amount",
        name: "Amount",
        render: item => (
            <>
                {renderAmount(item)}
                <div style={{ height: 5 }} />
                {item.type !== REST_ORDER_TYPE.CATERING && item.type !== REST_ORDER_TYPE.BANQUET && <>
                    <PrintBillButtonForOrderId mainApp={mainApp} orderId={item.id} />
                    <div style={{ height: 5 }} />
                </>}
            </>
        )
    }
]

const isReqSearch = req => Util.isStringExists(req.orderId) || Util.isStringExists(req.orderNo)

const useOrderSearch = (req) => {
    const [searchValueObj, setSearchValueObj] = useState(() => {
        if (Util.isStringExists(req.orderId)) {
            return { key: 'orderId', value: req.orderId };
        } else if (Util.isStringExists(req.orderNo)) {
            return { key: 'orderNo', value: req.orderNo };
        } else {
            return { key: 'orderId', value: "" };
        }
    })
    const searchValue = key => searchValueObj.key === key ? searchValueObj.value : ""
    const setSearchValue = (key, value) => setSearchValueObj({ key, value })
    const getSearchKV = () => searchValueObj;
    return [searchValue, setSearchValue, getSearchKV]
}

const useFilterFields = (lists, req) => {
    const [orderDateFilterKey, setOrderDateFilterKey] = useState(() => Util.newTempId())

    const importList = (listKey, reqKey) => {
        const listArr = lists[listKey] ?? [];
        const reqArr = req[reqKey] ?? [];

        if (listArr.length === 0 || reqArr.length === 0) {
            return [];
        }

        return reqArr
            .map(id => listArr.find(i => i.id == id))
            .filter(item => item !== undefined)
    }

    const history = useHistory()
    const [state, setState] = useState(() => ({
        statuses: importList("statuses", "statuses"),
        stateIds: importList("stateIds", "stateIds") ?? [],
        customerIds: importList("customerIds", "customerIds") ?? [],
        storeIds: importList("storeIds", "storeIds") ?? [],
        couponIds: importList("couponIds", "couponIds") ?? [],
        assignedDriverIds: importList("driverIds", "assignedDriverIds") ?? [],
        deliveredByDriverIds: importList("driverIds", "deliveredByDriverIds") ?? [],
        assignedOrDeliveredByDriverIds: importList("driverIds", "assignedOrDeliveredByDriverIds") ?? [],
        amountStatuses: importList("amountStatuses", "amountStatuses") ?? [],
        actualPaymentMethods: importList("actualPaymentMethods", "actualPaymentMethods") ?? [],
        shippingFullName: req.shippingFullName ?? "",
        phoneNumber: req.phoneNumber ?? "",
        startDate: req.startDate ?? (Util.isStringExists(history?.location?.state?.defaultPhoneNumber) ? undefined : startOfDay(new Date()).getTime()),
        endDate: req.endDate ?? (Util.isStringExists(history?.location?.state?.defaultPhoneNumber) ? undefined : startOfDay(addDays(new Date(), 1)).getTime()),

        invoiceStartDate: req.invoiceStartDate ?? 0,
        invoiceEndDate: req.invoiceEndDate ?? 0,

        orderDate: 0,
        customerName: "",
        orderNoFilter: "",
        kotNoFilter: "",
        receiptsInRangeOnly: false,
        fromOnlineStoreOnly: false,
        hideRevoked: false,
        tableIds: importList("tableIds", "tableIds") ?? [],
        paymentFilters: importList("paymentFilters", "paymentFilters") ?? [],
    }))

    const getField = key => state[key] ?? []
    const setField = (key, value) => setState(prev => ({ ...prev, [key]: value }))

    const exportFields = () => {
        let o = {}
        for (const field in state) {
            const val = state[field];
            if (Array.isArray(val)) {
                o[field] = val.map(v => v.id);
            } else {
                o[field] = val;
            }
        }
        return o;
    }

    const clear = () => {
        setState({
            statuses: [],
            stateIds: [],
            customerIds: [],
            storeIds: [],
            couponIds: [],
            assignedDriverIds: [],
            deliveredByDriverIds: [],
            assignedOrDeliveredByDriverIds: [],
            amountStatuses: [],
            actualPaymentMethods: [],
            shippingFullName: "",
            phoneNumber: "",
            startDate: 0,
            endDate: 0,
            invoiceStartDate: 0,
            invoiceEndDate: 0,

            orderDate: 0,
            customerName: "",
            orderNoFilter: "",
            kotNoFilter: "",
            receiptsInRangeOnly: false,
            fromOnlineStoreOnly: false,
            hideRevoked: false,
            tableIds: [],
            paymentFilters: [],
        })
        setOrderDateFilterKey(Util.newTempId())
    }

    return [getField, setField, exportFields, clear, state, orderDateFilterKey]
}

const ORDER_TYPES = [
    { id: REST_ORDER_TYPE.DINE_IN, value: "Dine in" },
    { id: REST_ORDER_TYPE.TAKE_OUT, value: "Take out" },
    { id: REST_ORDER_TYPE.DELIVERY, value: "Delivery" },
    { id: REST_ORDER_TYPE.PICK_UP, value: "Pick Up" },
    ...(hasCapabilitySupport("fnbEvent") ? [
        { id: REST_ORDER_TYPE.CATERING, value: "Catering" },
        { id: REST_ORDER_TYPE.BANQUET, value: "Banquet" },
    ] : [])
]

const FilterView = ({ lists, req, onSubmit, clearFilter, onForceSubmit }) => {
    const role = getAccountRole();

    const [isSearch, setIsSearch] = useState(isReqSearch(req))
    const [searchValue, setSearchValue, getSearchKV] = useOrderSearch(req);
    const [getField, setField, exportFields, clearFields, state, orderDateFilterKey] = useFilterFields(lists, req);

    const [selectKey, setSelectKey] = useState(() => Util.newTempId())

    const createReq = () => {
        const search = getSearchKV();
        if (Util.isStringExists(search.value)) {
            return {
                [search.key]: search.value
            }
        } else {
            return {
                ...exportFields()
            }
        }
    }

    const onSubmitBtn = () => {
        onSubmit(createReq())

        window?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    const onClear = () => {
        setIsSearch(false);
        setSearchValue('orderId', '')
        clearFields();

        setSelectKey(Util.newTempId())

        clearFilter()
    }

    const getList = key => lists[key] ?? []


    useEffect(() => {
        if (state?.paymentFilters) {
            const hasPaymentFilter = state.paymentFilters.find($ => $.id === "CARD" || $.id === "CASH");
            const hasUnpaidFilter = state.paymentFilters.find($ => $.id === "UNPAID");

            if (hasPaymentFilter && hasUnpaidFilter) {
                setField("paymentFilters", state.paymentFilters.slice(0, state.paymentFilters.length - 1))
                UIUtil.showInfo("Cannot filter for paid and unpaid orders at the same time.");
            }
        }
    }, [state])

    const history = useHistory();
    useEffect(() => {
        const defaultPhoneNumber = history?.location?.state?.defaultPhoneNumber;
        if (Util.isStringExists(defaultPhoneNumber)) {
            setField("phoneNumber", defaultPhoneNumber)
            onSubmit({ "phoneNumber": defaultPhoneNumber })
            window.history.replaceState({}, document.title)
        }
    }, [])

    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '1rem', gap: '0.5rem', width: '100%', flexWrap: 'wrap' }}>
            {lists.canFilterStores &&
                <MultiSelector style={{ minWidth: 175 }} light mdInput labelText="Store" value={getField('storeIds')} setValue={value => setField('storeIds', value)} options={getList("storeIds")} />}

            <TextInput light labelText="Order No" value={getField('orderNoFilter')} onChange={e => setField('orderNoFilter', e.target.value)} />
            <MultiSelector style={{ minWidth: 150 }} light mdInput labelText="Order Type" value={getField('orderTypes')} setValue={value => setField("orderTypes", value)} options={ORDER_TYPES} />

            {/* {isRivas() ? (<>
                <StatelessDateRange key={orderDateFilterKey} light label="Order" start={getField("startDate")} setStart={value => setField('startDate', value)} end={getField("endDate")} setEnd={value => setField('endDate', value)} />
                <StatelessDateRange light label="Sales" start={getField("invoiceStartDate")} setStart={value => setField('invoiceStartDate', value)} end={getField("invoiceEndDate")} setEnd={value => setField('invoiceEndDate', value)} />
            </>) : (<>
                <CarbonDateTimeRange key={orderDateFilterKey} light label="Order" start={getField("startDate")} setStart={value => setField('startDate', value)} end={getField("endDate")} setEnd={value => setField('endDate', value)} />
                <CarbonDateTimeRange light label="Sales" start={getField("invoiceStartDate")} setStart={value => setField('invoiceStartDate', value)} end={getField("invoiceEndDate")} setEnd={value => setField('invoiceEndDate', value)} />
            </>)} */}
            <CarbonDateTimeRange key={orderDateFilterKey} light start={getField("startDate")} setStart={value => setField('startDate', value)} end={getField("endDate")} setEnd={value => setField('endDate', value)} />
            {/* {isRivas() ? (
                <StatelessDateRange light label="Sales" start={getField("invoiceStartDate")} setStart={value => setField('invoiceStartDate', value)} end={getField("invoiceEndDate")} setEnd={value => setField('invoiceEndDate', value)} />
            ) : (
                <CarbonDateTimeRange light label="Sales" start={getField("invoiceStartDate")} setStart={value => setField('invoiceStartDate', value)} end={getField("invoiceEndDate")} setEnd={value => setField('invoiceEndDate', value)} />
            )} */}


            {/* <StatelessDatePicker light labelText="Order From Date" date={getField("startDate")} setDate={value => setField('startDate', value)} />
            <StatelessDatePicker light labelText="Order To Date" date={getField("endDate")} setDate={value => setField('endDate', value)} />
            <StatelessDatePicker light labelText="Sales From Date" date={getField("invoiceStartDate")} setDate={value => setField('invoiceStartDate', value)} />
            <StatelessDatePicker light labelText="Sales To Date" date={getField("invoiceEndDate")} setDate={value => setField('invoiceEndDate', value)} /> */}

            {/* <StatelessDatePicker light labelText="Order Date" date={getField("orderDate")} setDate={value => setField('orderDate', value)} />
            <StatelessDatePicker light labelText="Sales Date" date={getField("invoiceDate")} setDate={value => setField('invoiceDate', value)} /> */}

            <TextInput light labelText="KOT No" value={getField('kotNoFilter')} onChange={e => setField('kotNoFilter', e.target.value)} />
            <MultiSelector style={{ minWidth: 150 }} light mdInput labelText="Status" value={getField('statuses')} setValue={value => setField("statuses", value)} options={getList("statuses")} />
            <MultiSelector style={{ minWidth: 150 }} light mdInput labelText="Payment" value={getField('paymentFilters')} setValue={value => setField("paymentFilters", value)} options={getList("paymentFilters")} />


            <TextInput light labelText="Customer" value={getField('customerName')} onChange={e => setField('customerName', e.target.value)} />
            <TextInput light labelText="Phone Number" value={getField('phoneNumber')} onChange={e => setField('phoneNumber', e.target.value)} />

            <MultiSelector style={{ minWidth: 200 }} light mdInput labelText="Delivery Zone" value={getField('deliveryZoneIds')} setValue={value => setField("deliveryZoneIds", value)} options={getList("deliveryZoneIds")} />
            <MultiSelector style={{ minWidth: 200 }} light mdInput labelText="Delivery Area" value={getField('deliveryLocationIds')} setValue={value => setField("deliveryLocationIds", value)} options={getList("deliveryLocationIds")} />
            <MultiSelector style={{ minWidth: 150 }} light mdInput labelText="Driver" value={getField('assignedOrDeliveredByDriverIds')} setValue={value => setField('assignedOrDeliveredByDriverIds', value)} options={getList("driverIds")} />

            {role !== ACCOUNT_TYPE_REST_DELIVERY_MANAGER &&
                <MultiSelector style={{ minWidth: 175 }} light mdInput labelText="Table" value={getField('tableIds')} setValue={value => setField('tableIds', value)} options={getList("tableIds")} />}

            {<div onClick={() => setField("receiptsInRangeOnly", !getField("receiptsInRangeOnly"))}><Checkbox labelText="Receipts in range only?" checked={getField("receiptsInRangeOnly")} /></div>}
            {<div onClick={() => setField("fromOnlineStoreOnly", !getField("fromOnlineStoreOnly"))}><Checkbox labelText="From online store only?" checked={getField("fromOnlineStoreOnly")} /></div>}
            {<div onClick={() => setField("hideRevoked", !getField("hideRevoked"))}><Checkbox labelText="Hide revoked?" checked={getField("hideRevoked")} /></div>}


            <div style={{ flex: 1, }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', gap: '0.25rem' }}>
                    <Button kind="danger" renderIcon={ErrorFilled16} onClick={onClear}>
                        Clear
                    </Button>
                    <Button renderIcon={Search16} onClick={onSubmitBtn}>Search / Refresh</Button>
                </div>
            </div>
        </div>
    )
}

const FilterBar = ({ req, onSubmit, setSavedReqs, onSaved, clearFilter, onForceSubmit }) => {

    const [lists, setLists] = useState({});

    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        setInError(false)
        Api.getSalesOrdersFilterLists(response => {
            if (response.status === true) {
                setLists(response.payload)
            } else {
                UIUtil.showError("Can't filter")
                setInError(true);
            }
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <div style={{ padding: '1rem' }}>
            <InlineLoading />
        </div>
    }

    return (<>
        <FilterView
            onForceSubmit={onForceSubmit}
            setSavedReqs={setSavedReqs}
            clearFilter={clearFilter}
            onSaved={onSaved}
            lists={lists}
            req={req} onSubmit={onSubmit}
        />
    </>)
}

export default ({ customerId, mainApp }) => {
    const [selectedOrderId, setSelectedOrderId] = useState(undefined);

    const HEADERS = useMemo(() => headers(mainApp, getAccountRole()), [mainApp])

    const [savedReqs, setSavedReqs] = useObjectLocalStorage("sales-orders-saved-queries")

    const history = useHistory();
    const [tableKey, setTableKey] = useState(() => Util.newTempId());


    const [req, setReq] = useState(() => ({
        startDate: Util.isStringExists(history?.location?.state?.defaultPhoneNumber) ? undefined : startOfDay(new Date()).getTime(),
        endDate: Util.isStringExists(history?.location?.state?.defaultPhoneNumber) ? undefined : startOfDay(addDays(new Date(), 1)).getTime(),
    }));
    const [sort, setSort] = useState({ sort: "DATE_CREATED", sortAsc: false })
    const [savedReq, setSavedReq] = useState(undefined);


    const onForceSubmit = (submitReq) => {
        setReq(submitReq);
        setTableKey(Util.newTempId())
    }
    useEffect(() => {
        setTableKey(Util.newTempId())
    }, [req, sort, savedReq])

    const isCustomerList = Util.isNumberExist(customerId)
    const getReq = () => {
        let mapped;
        if (isCustomerList) {
            mapped = ({ customerIds: [customerId], ...sort })
        } else if (savedReq) {
            mapped = ({ ...savedReq.req, ...sort })
        } else {
            mapped = ({ ...req, ...sort });
        }

        const defaultPhoneNumber = history?.location?.state?.defaultPhoneNumber;
        if (Util.isStringExists(defaultPhoneNumber)) {
            mapped.phoneNumber = defaultPhoneNumber;
        }
        return mapped;
    }

    return (
        <div className="tooltip-supported-toolbar height-change-supported-toolbar">
            <PaginatedDataTable
                reloadTwoPages
                reloadRequest={tableKey}
                // title={"Order Management"}
                // description={"All sale orders"}
                columns={HEADERS}
                hasAggregates
                loader={(page, _, listener) => get(getReq(), page, listener)}
                loadResponseHandler={response => ({ ...response.payload, loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore, aggregate: response.payload.aggregate, })}
                //onClick={({ id }) => history.push('/deliverables/' + id)}
                //onClick={({ id }) => window.open('/deliverables/' + id, "_blank")}
                onClick={({ id }) => setSelectedOrderId(id)}

            >
                {(firstRes) => (
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '0.5rem', paddingInline: '1rem', marginTop: '1rem' }}>
                            <div>
                                <h4 style={{ fontWeight: 'bold' }}>Order Management</h4>
                                <p style={{ fontSize: 14, opacity: 0.65 }}>All orders</p>
                            </div>
                            <div style={{ flex: 1, minWidth: '0.15rem' }} />
                            <NewOrderButton fullPhoneNumber={firstRes?.fullPhoneNumber} customerId={firstRes?.uniqueCustomerId} customerName={firstRes?.uniqueCustomerName} />
                            {/* <div style={{ background: 'white', borderRadius: 7, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                             <p>No customer found</p>
                         </div> */}
                        </div>

                        <div style={{ display: 'flex', width: '100%' }}>
                            {!isCustomerList && !savedReq &&
                                <FilterBar req={req} onSubmit={setReq} onForceSubmit={onForceSubmit} setSavedReqs={setSavedReqs} onSaved={setSavedReq} clearFilter={() => setReq({})} />}
                            {/* {inFilter && <Button kind="danger" renderIcon={ErrorFilled16} onClick={() => setReq({})}>
                             Clear
                         </Button>} */}
                        </div>
                    </div>
                )}
            </PaginatedDataTable>


            {selectedOrderId ? (
                <SidePanel md2 backBtn onClose={() => setSelectedOrderId(undefined)}>
                    <LockScroll />
                    <DeliverableItemPage orderIdOverride={selectedOrderId} />
                </SidePanel>
            ) : null}

            {/* <div style={{ height: 1000 }} /> */}
        </div>
    )
}