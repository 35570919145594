import { hasCapabilitySupport } from "../../../app/Capabilities";
import { isRivas } from "../../../app/app-util";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange } from "../../components/basic-filter/date-range"
import { DateTimeRange } from "../../components/basic-filter/date-time-range";
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box"

export const SalesReport = {
    filter: Filter,

    // newLink: '/stock-flow-creator',
    itemToOpenLink: item => `/stock-flow/${item.salesId}`,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT || role == ACCOUNT_TYPE_SALES;
    },


    slowMode: true
}

const OrderTypes = [
    'Dine-in',
    'Take-out',
    'Pickup',
    'Delivery'
]



function Filter({ endpoint, state }) {
    return (<>
        {/* <DateRange state={state} property="salesDate" /> */}
        {(hasCapabilitySupport("restaurant") && !isRivas()) ? (
            <DateTimeRange state={state} property="salesDate" />
        ) : (
            <DateRange state={state} property="salesDate" />
        )}

        <Divider />
        {hasCapabilitySupport("restaurant") &&
            <div style={{ height: 30, marginTop: '0rem', width: 150 }}>
                <MultiSelect isMulti={false} placeholder="Order type" fieldKey="orderTypes" options={OrderTypes} state={state} property="orderType" />
            </div>}
        <MultiSelect isMulti={false} placeholder="Source" fieldKey="sourceField" options={endpoint.stores.map(item => item.value)} state={state} property="sourceName" />
        {/* <TextBox state={state} label="Source" property="sourceName" /> */}
        <TextBox state={state} label="Customer" property="customerName" />
        <TextBox state={state} label="Product" property="products" />

        {/* <TextBox state={state} label="Amount Status" property="amountStatus" /> */}
    </>)
}
