import UIUtil from "../../util/UIUtil";


class ThermalPrinterDriver {

    async connect(params) {
        throw new Error("Not implemented");
    }

    async disconnect() {
        throw new Error("Not implemented");
    }

    async printReceipt(posSessionSnapshot) {
        throw new Error("Not implemented");
    }

    async printPosCart(posSessionSnapshot) {
        UIUtil.showInfo("Printer driver does not support cart print")
    }

    async printSalesSummary(posSession) {
        throw new Error("Not implemented");
    }

    async printKot(order, kot, filterClasses, bottomText) {
        throw new Error("Not implemented");
    }

    async printAllKots(order, filterClasses, bottomText) {
        throw new Error("Not implemented");
    }

    async printBill(order) {
        throw new Error("Not implemented");
    }

    async printCoupons(posSessionSnapshot, coupons) {
        // throw new Error("Not implemented")
        UIUtil.showInfo("Printer driver does not support coupon print")
    }

    async printRestCartQr(cart) {
        UIUtil.showInfo("Printer driver does not support print")
    }

    async printRestBusinessSummary(report) {
        UIUtil.showInfo("Printer driver does not support print")
    }

    async openCashDrawer() {
        throw new Error("Not implemented");
    }

    hasCashDrawerAccess() {
        throw new Error("Not implemented");
    }

    isConnectionBased() {
        throw new Error("Not implemented");
    }

    canPrintSalesSummary() {
        throw new Error("Not implemented");
    }

    canPrintRestaurantDocs() {
        throw new Error("Not implemented");
    }

    /**
     * @returns (Array<{property: string, name: string}>)
     */
    getParams() {
        throw new Error("Not implemented");
    }

}

export default ThermalPrinterDriver;