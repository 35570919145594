import { FunctionMath16, UpToTop16, DownToBottom16, DataVis_216, VirtualColumn16, Filter16, Tools16, Moon16 } from '@carbon/icons-react'
import { useCallback, useMemo, useState } from 'react';
import useStore from '../../../hooks/useStore';
import Util from '../../../util/Util';
import { getReport } from '../../reports/reports';

function useHint() {
    const [message, setMessage] = useState("");
    return {
        message: Util.isStringExists(message) ? message : null,
        init: (message) => ({
            onMouseEnter: () => setMessage(message),
            onMouseLeave: () => setMessage("")
        })
    }
}

function Button({ hint, label, active, onClick, children }) {
    return <button {...hint.init(label)} onClick={onClick} data-active={active} className='report-engine-menu-button'>{children}</button>
}

export function Menu({ reportName, store }) {
    const report = useMemo(() => getReport(reportName), [reportName])

    const hint = useHint();
    const MenuButton = useCallback(props => <Button {...props} hint={hint} />, [])

    const [showGroupNames, setShowGroupNames] = useStore(store, 'showGroupNames');
    const [showFooter, setShowFooter] = useStore(store, 'showFooter');
    const [showDarkMode, setShowDarkMode] = useStore(store, 'showDarkMode');

    const [showQuickFilter, setShowQuickFilter] = useStore(store, 'showQuickFilter');
    const [showFormulaValues, setShowFormulaValues] = useStore(store, 'showFormulaValues');
    const [showPayloadBar, setShowPayloadBar] = useStore(store, 'showPayloadBar');

    const [showColumns, setShowColumns] = useStore(store, 'showColumns');
    const [showAdvancedFilter, setShowAdvancedFilter] = useStore(store, 'showAdvancedFilter');

    if (report.viewBased) {
        return (<>
            {hint.message && <p key={hint.message + 'report-toolbar-menu'} style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', animation: '250ms report-engine-menu-hint-fade-in' }}>
                {hint.message}
            </p>}
            <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                <MenuButton label="Bottom Toolbar" active={showFooter} onClick={() => setShowFooter(v => !v)}><DownToBottom16 /></MenuButton>
                <MenuButton label="Quick Filter" active={showQuickFilter} onClick={() => setShowQuickFilter(v => !v)}><Filter16 /></MenuButton>
                {report.hasPayloadBar && <MenuButton label="Additional Data" active={showPayloadBar} onClick={() => setShowPayloadBar(v => !v)}><DataVis_216 /></MenuButton>}
            </div>
        </>)
    }

    if (report.isTreeSructure) {
        return (<>
            {hint.message && <p key={hint.message + 'report-toolbar-menu'} style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', animation: '250ms report-engine-menu-hint-fade-in' }}>
                {hint.message}
            </p>}
            <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                <MenuButton label="Group Names" active={showGroupNames} onClick={() => setShowGroupNames(v => !v)}><UpToTop16 /></MenuButton>
                <MenuButton label="Dark Mode" active={showDarkMode} onClick={() => setShowDarkMode(v => !v)}><Moon16 /></MenuButton>
                <MenuButton label="Bottom Toolbar" active={showFooter} onClick={() => setShowFooter(v => !v)}><DownToBottom16 /></MenuButton>

                <div style={{ width: 5 }} />

                <MenuButton label="Quick Filter" active={showQuickFilter} onClick={() => setShowQuickFilter(v => !v)}><Filter16 /></MenuButton>
                {report.hasPayloadBar && <MenuButton label="Additional Data" active={showPayloadBar} onClick={() => setShowPayloadBar(v => !v)}><DataVis_216 /></MenuButton>}
                <MenuButton label="Columns" active={showColumns} onClick={() => setShowColumns(v => !v)}><VirtualColumn16 /></MenuButton>
            </div>
        </>)
    }

    return (<>
        {hint.message && <p key={hint.message + 'report-toolbar-menu'} style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', animation: '250ms report-engine-menu-hint-fade-in' }}>
            {hint.message}
        </p>}
        <div style={{ borderRadius: 7, background: '#0f0f0f', border: '1px solid #00000090', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
            <MenuButton label="Group Names" active={showGroupNames} onClick={() => setShowGroupNames(v => !v)}><UpToTop16 /></MenuButton>
            <MenuButton label="Bottom Toolbar" active={showFooter} onClick={() => setShowFooter(v => !v)}><DownToBottom16 /></MenuButton>
            {!report.viewBased && <MenuButton label="Dark Mode" active={showDarkMode} onClick={() => setShowDarkMode(v => !v)}><Moon16 /></MenuButton>}

            <div style={{ width: 5 }} />

            {report.filter && <MenuButton label="Quick Filter" active={showQuickFilter} onClick={() => setShowQuickFilter(v => !v)}><Filter16 /></MenuButton>}
            <MenuButton label="Formula Values" active={showFormulaValues} onClick={() => setShowFormulaValues(v => !v)}><FunctionMath16 /></MenuButton>
            {report.hasPayloadBar && <MenuButton label="Additional Data" active={showPayloadBar} onClick={() => setShowPayloadBar(v => !v)}><DataVis_216 /></MenuButton>}

            <div style={{ width: 5 }} />

            <MenuButton label="Columns" active={showColumns} onClick={() => setShowColumns(v => !v)}><VirtualColumn16 /></MenuButton>
            <MenuButton label="Advanced Filter" active={showAdvancedFilter} onClick={() => setShowAdvancedFilter(v => !v)}><Tools16 /></MenuButton>
        </div>
    </>)
}