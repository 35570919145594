import Util from "../../util/Util";
import RoboticLogo from '../../images/RL.png'

export function BoardPrintContent({ title, groups, items }) {
    return (
        <div style={{ paddingInline: '3rem', paddingBlock: '6rem', }}>
            <div style={{ background: 'black', padding: '0.5rem', marginBottom: '0.5rem', display: 'inline-block' }}>
                <img src={RoboticLogo} style={{ objectFit: 'contain', objectPosition: 'center', height: '100%', cursor: 'pointer', width: 200, }} />
            </div>
            <h2>{title}</h2>
            <p style={{ fontSize: 14, opacity: 0.65 }}>Snapshot generated on {Util.getFullDate(new Date().getTime())}</p>

            <div style={{ marginTop: '3rem', display: 'flex', gap: '1rem' }}>
                {groups.map(group => (
                    <div>
                        <h4 style={{ whiteSpace: 'nowrap' }}>{group}</h4>
                        <div style={{ borderRadius: 5, minWidth: 300, border: '2px solid black', padding: '0.5rem', marginTop: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                            {items.filter(item => item.group == group).map(item => (
                                <div style={{ borderRadius: 5, border: '1px solid black', padding: '0.5rem' }}>
                                    <p style={{ fontSize: 14, whiteSpace: 'pre-line' }}>{item.content}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}