import { useState } from "react";
import Util from "../util/Util";
import Button from "./Button";
import { SelectWindow16, Close16 } from '@carbon/icons-react'
import { InvoiceSearchDialog } from "../pages/pos/dialogs/any-search-dialog";


export function InvoiceSelectField({ value, setValue }) {
    const [visible, setVisible] = useState(false);

    const selected = Util.isNumberExist(value)

    return (
        <div style={{
            transition: '250ms',
            display: 'flex', alignItems: 'center', gap: '0.5rem',
            borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
            ...selected ? {
                background: "#1c1c1c",
                color: 'white'
            } : {}
        }}>
            <div style={{ flex: 1 }}>
                {selected ? (<>
                    <h6>Selected Invoice</h6>
                    <p style={{ color: 'green' }}>Invoice no: {Util.getVoucherNumber(value)}</p>
                </>) : (<>
                    <h5>Invoice</h5>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>no invoice selected</p>
                </>)}
            </div>
            <Button onClick={() => setVisible(true)} renderIcon={SelectWindow16} size="md" style={{ borderRadius: 5, }}>
                {selected ? "Select Another" : "Select Invoice"}
            </Button>
            {selected && <Button onClick={() => setValue(0)} renderIcon={Close16} kind="danger" iconDescription="Clear" size="md" hasIconOnly style={{ borderRadius: 5, }} />}

            <InvoiceSearchDialog visible={visible} onClose={() => setVisible(false)} onItemSelected={invoice => setValue(invoice.id)} />
        </div>
    )
}