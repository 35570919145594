import React, { useEffect, useState } from 'react'
import Page from '../../base/Page';

import {
    TreeView32,
    CaretRight16,
    Download16
} from '@carbon/icons-react'
import Api from '../../session/Api';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { BalanceView } from '../../components/BalanceView';
import { getReportLink } from '../../reporting-engine/base/report-page';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const ItemList = ({ items, paddingLeft, onExpandedUpdate, onSelect }) => items.map(item => (
    React.createElement(item.group ? GroupMenuItem : MenuItem, {
        key: item.id + "-" + item.name,
        item, paddingLeft, onExpandedUpdate, onSelect
    })
));

const GroupMenuItem = ({ item, paddingLeft, onExpandedUpdate, onSelect }) => {
    const [expanded, setExpanded] = useState(true);
    const [childExpand, setChildExpand] = useState(0);

    useEffect(() => {
        if (expanded) {
            onExpandedUpdate(item.items.length);
        } else {
            onExpandedUpdate(0);
        }
    }, [expanded])

    return (
        <div style={{ width: '100%' }}>
            <div onClick={() => setExpanded(prev => !prev)} className="bx--list-box__menu-item" style={{
                display: 'flex', alignItems: 'center', padding: '1rem', height: undefined, paddingLeft: paddingLeft, //pointerEvents: 'none'
            }}>
                <CaretRight16 style={{ marginRight: '0.25rem', transition: '250ms', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                <span style={{ fontWeight: 'bold' }}>{item.name}</span>
            </div>
            <div style={{
                //transition: '250ms', height: expanded ? 45 * (item.items.length + childExpand) : 0,
                height: expanded ? undefined : 0,
                //overflow: 'hidden'
                overflow: 'auto'
            }}>
                <ItemList items={item.items} paddingLeft={paddingLeft + convertRemToPixels(3)} onExpandedUpdate={count => setChildExpand(count)} onSelect={onSelect} />
            </div>
        </div>
    )
}

const MenuItem = ({ item, paddingLeft, onSelect }) => {
    const [expanded, setExpanded] = useState(true);

    const link = getReportLink('LedgerReport', {
        selectedAccountLedger: item,
        filters: [
            { id: "ledgerId", property: "ledgerId", operator: "EQ", value: item.id },
        ]
    })

    if ((item?.items?.length ?? 0) > 0) {
        return (
            <div style={{ width: '100%' }}>
                <div onClick={() => setExpanded(prev => !prev)} className="bx--list-box__menu-item" style={{
                    display: 'flex', alignItems: 'center', padding: '1rem', height: undefined, paddingLeft: paddingLeft, //pointerEvents: 'none'
                }}>
                    <CaretRight16 style={{ marginRight: '0.25rem', transition: '250ms', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                    <span style={{ fontWeight: 'bold' }}>{item.name}</span>
                    <span style={{ paddingInline: '0.5rem' }}>-</span>

                    <Link to={link}><div className="bx--list-box__menu-item" style={{ display: 'flex', alignItems: 'center', padding: 0 }} onClick={onSelect !== undefined ? (() => onSelect(item)) : undefined}>
                        View Ledger
                    </div></Link>

                    <div style={{ flex: 1 }} />
                    <BalanceView balance={item.balance} />
                </div>
                <div style={{ height: expanded ? undefined : 0, overflow: 'auto' }}>
                    <ItemList items={item.items} paddingLeft={paddingLeft + convertRemToPixels(3)} onExpandedUpdate={count => { }} onSelect={onSelect} />
                </div>
            </div>
        )
    } else {
        return (

            <Link to={link} style={{ textDecoration: 'none' }}>
                <div className="bx--list-box__menu-item" style={{ display: 'flex', alignItems: 'center', padding: '1rem', height: undefined, paddingLeft: paddingLeft }} onClick={onSelect !== undefined ? (() => onSelect(item)) : undefined}>
                    {item.name}

                    <div style={{ flex: 1 }} />
                    <BalanceView balance={item.balance} />
                </div>
            </Link>

        )
    }
}

class AccountTreePage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            accountTree: []
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getAccountTree(true, listener), payload => ({
            accountTree: payload
        }))
    }

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                            <TreeView32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
                            <h1 style={{ flex: 1 }}>Account Tree</h1>
                        </div>
                        <a download href={Api.downloadExportAccountTree()} style={{ textDecoration: 'none' }}>
                            <Button kind="secondary" renderIcon={Download16} style={{ width: undefined }}>Export to Excel</Button>
                        </a>
                    </div>

                    <div style={{
                        marginTop: '3rem', //pointerEvents: 'none'
                    }}>
                        <ItemList items={this.state.accountTree} paddingLeft={convertRemToPixels(1)} onExpandedUpdate={() => { }} onSelect={item => {

                        }} />
                    </div>
                </div>
            </div>
        )
    }
}

export default AccountTreePage;