
import React from 'react';
import { Page, Document, Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../../images/Logo.png'
import Util from '../../../util/Util';
import { TableHeader } from './TableHeader';
import Table from './Table';
import ReceiptLogo from '../../../images/ReceiptLogo.png'
import { THERMAL_RECEIPT_LOGO } from '../../../app/Config';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 178,
        height: 37,
    },
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle: {
        color: '#ff2800',
        letterSpacing: 4,
        fontSize: 16,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
});

const Divider = () => <View style={{ width: '100%', backgroundColor: 'black', height: 2, marginBottom: 5 }} />

const Field = ({ label, value }) => (
    <View style={{ marginBottom: 5 }}>
        <Text style={{ fontSize: 10, opacity: 0.65 }}>{label}</Text>
        <Text>{value}</Text>
    </View>
)

const Division = ({ value, style }) => (
    <View style={{ width: '20%' }}>
        <Text style={{ width: '100%', textAlign: 'center', ...(style ?? {}) }}>{value}</Text>
    </View>
)

const DayDivisons = ({ report }) => {

    return (
        <View style={{ marginTop: 50, }}>
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                {report.amountDivisions.map(division => <Division value={division.title} style={{ opacity: 0.65, fontSize: 10 }} />)}
                {/* <Division value="0 - 30 days" style={{ opacity: 0.65, fontSize: 10 }} />
                <Division value="31 - 60 days" style={{ opacity: 0.65, fontSize: 10 }} />
                <Division value="61 - 90 days" style={{ opacity: 0.65, fontSize: 10 }} />
                <Division value="91 - 120 days" style={{ opacity: 0.65, fontSize: 10 }} />
                <Division value="Over 120 days" style={{ opacity: 0.65, fontSize: 10 }} /> */}
            </View>
            <View style={{ width: '100%', backgroundColor: 'black', opacity: 0.65, height: 1, }} />
            <View style={{ alignItems: 'center', flexDirection: 'row', marginTop: 5 }}>
                {report.amountDivisions.map(division => <Division value={division.amount.toFixed(2)} />)}
                {/* <Division value={(233.00).toFixed(2)} />
                <Division value={(233.00).toFixed(2)} />
                <Division value={(233.00).toFixed(2)} />
                <Division value={(233.00).toFixed(2)} />
                <Division value={(233.00).toFixed(2)} /> */}
            </View>
        </View>
    )
}

export const ReportPdf = ({ report }) => {

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* <Image style={styles.logo} src={logo} /> */}
                <Image style={styles.logo} src={THERMAL_RECEIPT_LOGO.squareLogo ? logo : ReceiptLogo} />
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>Outstanding Report</Text>
                </View>
                <Text>Outstanding as of {Util.getDateOnly(new Date().getTime())}</Text>
                <Divider />

                {/* <Field label="Customer" value="Alex Wales" /> */}
                <Field label="Customer" value={report.name} />
                <Field label="Account No" value={Util.getVoucherNumber(report.id)} />

                <Text style={{ marginTop: 5, fontWeight: 'bold' }}>
                    Dear Sir/Madam
                </Text>
                <Text style={{ marginLeft: 25, marginBottom: 15 }}>
                    Given below are the detail of amounts outstanding against your name in our books as of {Util.getDateOnly(new Date().getTime())}. We
                    request you to take immediate steps for settling the overdue bills and oblige.
                </Text>

                <Table report={report} />

                <DayDivisons report={report} />

                <Text style={{ marginTop: 50, opacity: 0.65, fontSize: 8 }}>
                    This is a computer generated document and does not require any signature
                </Text>
                <Text style={{ opacity: 0.65, fontSize: 8 }}>
                    The details of amount outstanding are displayed in AED
                </Text>
            </Page>
        </Document>
    )
}