import { ComboBox, ContentSwitcher, InlineLoading, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from './MultipleInputEditor';
import Button from './Button';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';
import { OBJECT_TYPE_LECTURE_QUIZ, OBJECT_TYPE_LECTURE_VIDEO } from '../constants/ObjectTypes';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved, options }) => {
    const [componentType, setComponentType] = useMultiInputState(input, 'componentType', onInputUpdate);
    const [componentId, setComponentId] = useMultiInputState(input, 'componentId', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div>
                <label className="bx--label">Type</label>
                <ContentSwitcher selectedIndex={componentType == OBJECT_TYPE_LECTURE_QUIZ ? 1 : 0} size="lg" onChange={e => {
                    setComponentType(e.name)
                    setComponentId(0)
                }} style={{ alignSelf: 'flex-end', width: 200 }}>
                    <Switch name={OBJECT_TYPE_LECTURE_VIDEO} text="Video" />
                    <Switch name={OBJECT_TYPE_LECTURE_QUIZ} text="Quiz" />
                </ContentSwitcher>
            </div>

            <div style={{ flex: 1 }}>
                {(componentType == OBJECT_TYPE_LECTURE_VIDEO || !componentType) && (
                    <ComboBox style={{ width: '100%' }}
                        size='lg' titleText="Video"
                        items={options.videos} itemToString={item => item?.value ?? ""}
                        selectedItem={options.videos.find(item => item.id === componentId)}
                        onChange={e => setComponentId(e.selectedItem?.id ?? 0)} />
                )}
                {componentType == OBJECT_TYPE_LECTURE_QUIZ && (
                    <ComboBox style={{ width: '100%' }}
                        size='lg' titleText="Quiz"
                        items={options.quizzes} itemToString={item => item?.value ?? ""}
                        selectedItem={options.quizzes.find(item => item.id === componentId)}
                        onChange={e => setComponentId(e.selectedItem?.id ?? 0)} />
                )}
            </div>

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const LectureComponentListEditor = ({ defaultItems, onUpdate }) => {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState({
        videos: [],
        quizzes: [],
    })

    useEffect(() => {
        setLoading(true)
        Api.getAllLearningComponents(response => {
            if (response.status === true) {
                setOptions(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError("Failed to lecture components")
            }
        })
    }, [])

    if (loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    return (
        <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} options={options} />
    )
}