import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util, { isV2 } from "../../../util/Util"
import { getReportLink } from "../../base/report-page"
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { DateComparison } from "../../components/basic-filter/date-comparison"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { Archive32 } from '@carbon/icons-react'

const vendorDetail = (vendor, start, end) => {
    const filters = [
        { "id": "vendorName", "operator": "OR", "filters": [{ "property": "vendorName", "operator": "EQ", "value": vendor }] }
    ];
    if (Util.isNumberExist(start)) {
        filters.push(
            { "property": "date", "operator": "GTE", "id": "date-start", "skipAutoFilter": true, "value": start },
        )
    }
    if (Util.isNumberExist(end)) {
        filters.push(
            { "property": "date", "operator": "LT", "id": "date-end", "skipAutoFilter": true, "value": end }
        )
    }

    return { filters, "vendorField": { "label": vendor } }
}


export const SupplierBalanceSummary = {
    filter: Filter,
    freezeCol: 1,

    onAction: (action, data, history) => {
        switch (action) {
            case "vendorName":
                history.push(getReportLink("SupplierBalanceDetail", vendorDetail(data.vendorName, data._startDate, data._endDate)))
                return;
        }
    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter />
        <DateComparison state={state} />

        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Vendor" key="vendorField" fieldKey="vendorField" options={endpoint.vendors.map(item => item.value)} state={state} property="vendorName" />
        </div>
        <div style={{ height: 30, marginTop: '0rem', flex: 2 }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}