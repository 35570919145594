import Button from "../../../components/Button"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util from "../../../util/Util"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24, ArrowRight16 } from '@carbon/icons-react'
import { Link } from "react-router-dom";

export const BookClosings = newListDetail({
    title: "Book Closing",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: ReadonlyForm,

    defaultValues: {

    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ADMINISTRATION;
    }
})

function Form({ endpoint, form }) {
    return (
        <div>
            <form.DateField fieldKey="date" title="Closing Date" help="Clears balances as of 11:59 P.M. on the date immediately prior to this closing date" />
        </div>
    )
}

function ReadonlyForm({ endpoint, form }) {
    return (
        <div>
            <label className="bx--label" style={{ opacity: 0.65, marginBottom: 0 }}>Closing Date</label>
            <p style={{ marginBottom: '1rem' }}>{Util.getDateOnly(form.store.get('date'))}</p>
            <Link to={"/journal-entry/" + form.store.get('journalEntryId')}>
                <Button size="sm" renderIcon={ArrowRight16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Journal Entry</Button>
            </Link>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
