import { RootEngine, FlumeConfig, Colors, Controls } from 'flume'


// const engine = new RootEngine(
//     config,
//     (type, data) => {
//         switch (type) {
//             case "text":
//                 return data.text;
//             case "boolean":
//                 return data.boolean;
//             case "number":
//                 return data.number;
//             case "status":
//                 return data.status;
//             case "date":
//                 return data.date;
//             default:
//                 return {};
//         }
//     },
//     (node, inputValues, nodeType, context) => {
//         switch (node.type) {
//             case "and":
//                 return { result: inputValues.bool1 && inputValues.bool2 }
//             case "or":
//                 return { result: inputValues.bool1 || inputValues.bool2 }
//             case "not":
//                 return { boolean: !inputValues.boolean };

//             case "text":
//                 return { text: inputValues.text };
//             case "boolean":
//                 return { boolean: inputValues.boolean };
//             case "number":
//                 return { number: inputValues.number };

//             case "date":
//                 return { date: inputValues.date };
//             case "number":
//                 return { number: inputValues.number };
//             default:
//                 return {};
//         }
//     }
// );



export function createConfig(statuses = [], fields = []) {
    const config = new FlumeConfig()

    function portTypes() {
        config
            .addPortType({
                type: "string",
                name: "string",
                label: "Text",
                color: Colors.green,
                controls: [
                    Controls.text({
                        name: "string",
                        label: "Text"
                    })
                ]
            })
            .addPortType({
                type: "number",
                name: "number",
                label: "Number",
                color: Colors.red,
                controls: [
                    Controls.number({
                        name: "number",
                        label: "Number"
                    })
                ]
            })
            .addPortType({
                type: "boolean",
                name: "boolean",
                label: "Yes/No",
                color: Colors.blue,
                controls: [
                    Controls.checkbox({
                        name: "boolean",
                        label: "Yes/No"
                    })
                ]
            })
            .addPortType({
                type: "status",
                name: "status",
                label: "Status",
                color: Colors.orange,
                controls: [
                    Controls.text({
                        name: "status",
                        label: "Status"
                    })
                ]
            })
            .addPortType({
                type: "date",
                name: "date",
                label: "Date/Time",
                color: Colors.purple,
                controls: [
                    Controls.text({
                        name: "date",
                        label: "Date/Time"
                    })
                ]
            })
            .addPortType({
                type: "scheduleType",
                name: "scheduleType",
                label: "Schedule Type",
                color: Colors.grey,
                controls: [
                    Controls.select({
                        name: "scheduleType",
                        label: "Schedule Type",
                        options: [
                            { value: "starting", label: "Starting" },
                            { value: "ending", label: "Ending" },
                        ]
                    })
                ]
            })
            .addPortType({
                type: "field",
                name: "field",
                label: "Field",
                color: Colors.grey,
                controls: [
                    Controls.select({
                        name: "field",
                        label: "Field",
                        options: fields.map(field => ({
                            value: field, label: (field.params?.fieldLabel ?? "Unknown Field")
                        })),
                        // options: [
                        //     { value: "customer", label: "Customer" },
                        //     { value: "name", label: "Name" },
                        // ]
                    })
                ]
            })


    }

    function logicNodeTypes() {
        config
            .addNodeType({
                type: "and",
                label: "And",
                description: "Outputs 'Yes' if two 'Yes/No' are both 'Yes'",
                inputs: ports => [
                    ports.boolean({ name: "bool1" }),
                    ports.boolean({ name: "bool2" })
                ],
                outputs: ports => [ports.boolean({ name: "result" })]
            })
            .addNodeType({
                type: "or",
                label: "Or",
                description: "Outputs 'Yes' if two 'Yes/No' have at least one 'Yes'",
                inputs: ports => [
                    ports.boolean({ name: "bool1" }),
                    ports.boolean({ name: "bool2" })
                ],
                outputs: ports => [ports.boolean({ name: "result" })]
            })
            .addNodeType({
                type: "not",
                label: "Not",
                description: "Outputs the opposite of a 'Yes/No'",
                inputs: ports => [
                    ports.boolean(),
                ],
                outputs: ports => [
                    ports.boolean()
                ]
            })
    }

    function inputNodeTypes() {
        config
            .addNodeType({
                type: "status",
                label: "Status",
                description: "The activity's status",
                outputs: ports => [
                    ports.status({ name: "statusName", label: "Activity's Status" })
                ]
            })
            .addNodeType({
                type: "date",
                label: "Date/Time",
                description: "The activity's scheduled date",
                inputs: ports => [
                    ports.scheduleType()
                ],
                outputs: ports => [
                    ports.date({ name: "dateName", label: "Activity's Scheduled Date" })
                ]
            })
            .addNodeType({
                type: "field",
                label: "Form Field",
                description: "The activity's form field value",
                inputs: ports => [
                    ports.field()
                ],
                outputs: ports => (inputData, connections, context) => {
                    // if (inputData.field.field.)
                    if (inputData.field.field.dataType) {
                        return [
                            ports[inputData.field.field.dataType]()
                        ]
                    } else {
                        return []
                    }
                }
                // outputs: ports => [
                //     ports.string({ name: "dateName", label: "Activity's Scheduled Date" })
                // ]
            })
    }

    function dataNodeTypes() {
        config
            .addNodeType({
                type: "string",
                label: "Text",
                description: "Outputs text",
                inputs: ports => [
                    ports.string()
                ],
                outputs: ports => [
                    ports.string()
                ]
            })
            .addNodeType({
                type: "number",
                label: "Number",
                description: "Outputs number",
                inputs: ports => [
                    ports.number()
                ],
                outputs: ports => [
                    ports.number()
                ]
            })
            .addNodeType({
                type: "boolean",
                label: "Yes/No",
                description: "Outputs yes or no",
                inputs: ports => [
                    ports.boolean()
                ],
                outputs: ports => [
                    ports.boolean()
                ]
            })
    }

    function comparatorNodeTypes() {

    }

    function actionNodeTypes() {
        config
            .addNodeType({
                type: "homepage",
                label: "Homepage",
                initialWidth: 170,
                root: true,
                inputs: ports => [
                    ports.string({
                        name: "title",
                        label: "Title"
                    }),
                    ports.string({
                        name: "description",
                        label: "Description"
                    }),
                ]
            })
    }


    portTypes()
    logicNodeTypes()
    inputNodeTypes()
    dataNodeTypes()
    comparatorNodeTypes()
    actionNodeTypes()


    return config;
}