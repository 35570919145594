import {
    UserCertification32, ArrowLeft16, ArrowRight16, UserCertification24,
    Report16, Calendar16, SplitScreen16
} from '@carbon/icons-react'
import { Tab, Tabs, Tag } from 'carbon-components-react';
import { useHistory } from 'react-router-dom'
import useStore from '../../../../hooks/useStore';
import Util from '../../../../util/Util';
import { isMobileScreen } from '../../../../App';

const LeadName = ({ store }) => {
    const [profile] = useStore(store, 'profile')
    return <h4 style={{}}>{profile.fullName}</h4>
}

const CustomerButton = ({ store }) => {
    const history = useHistory();
    const [customerId] = useStore(store, 'customerId')
    return Util.isNumberExist(customerId) &&
        <Tag style={{ marginLeft: '3rem', }} onClick={() => history.push('/customers/' + customerId)} type="blue">Open Customer <ArrowRight16 style={{ marginLeft: '0.15rem' }} /></Tag>
}

export function Toolbar({ lead, store, tabIndex, setTabIndex }) {
    const history = useHistory();

    if (isMobileScreen) {
        return (<>
            <div className='lead-flow-tabs hidden-tab-content' style={{ paddingInline: '1rem', height: '3rem', width: '100%', display: 'flex', alignItems: 'center', background: '#212121', color: 'white', borderBottom: '1px solid #ffffff20' }}>
                <button onClick={() => history.goBack()} className='lead-flow-icon-button lead-flow-icon-button-light'>
                    <ArrowLeft16 />
                </button>

                <UserCertification24 style={{ marginLeft: '1rem', marginRight: '0.5rem' }} />
                <div style={{ flex: 1 }}>
                    <LeadName store={store} />
                </div>

            </div>
            <div className='mobile-icon-white-tab hidden-tab-content' style={{
                background: '#1c1c1c', color: 'white', borderBottom: '1px solid black',
            }}>
                <Tabs selected={tabIndex} onSelectionChange={index => setTabIndex(index)}>
                    <Tab label={<Report16 />}></Tab>
                    <Tab label={<Calendar16 />}></Tab>
                    <Tab label={<SplitScreen16 />}></Tab>
                </Tabs>
            </div>
        </>)
    }

    return (
        <div className='lead-flow-tabs hidden-tab-content' style={{ paddingInline: '1rem', height: '4rem', width: '100%', display: 'flex', alignItems: 'center', background: '#212121', color: 'white', borderBottom: '1px solid black' }}>
            <button onClick={() => history.goBack()} className='lead-flow-icon-button lead-flow-icon-button-light'>
                <ArrowLeft16 />
            </button>

            <UserCertification32 style={{ marginLeft: '1rem', marginRight: '0.5rem' }} />
            <div>
                <LeadName store={store} />
                <p style={{ fontSize: 12, opacity: 0.65 }}>Lead Management</p>
            </div>
            <CustomerButton store={store} />

            <div style={{ flex: 1 }} />
            <Tabs selected={tabIndex} onSelectionChange={index => setTabIndex(index)}>
                <Tab label="Overview"></Tab>
                <Tab label="Calendar"></Tab>
                <Tab label="To-Do Board"></Tab>
            </Tabs>
        </div>
    )
}