import { withLoadablePagePropsAndProps } from "../../../base/Page";
import ProfilePic from "../../../components/ProfilePic";
import { MinimalTable } from "../../../components/minimal-table";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import Api from "../../../session/Api";
import { Link } from 'react-router-dom'
import Util from "../../../util/Util";
import SystemLogsListView from "../../system-logs/SystemLogsListView";

const COLUMNS = [
    {
        name: "user",
        title: "User",
        flex: 1,
        renderCell: item => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }}>
                <ProfilePic src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.userId)} size={32} />
                <Link target="_blank" to={"/staff-users/" + item.userId}>
                    {item.user}
                </Link>
            </div>
        ),
    },
    {
        name: "date",
        title: "Date",
        flex: 1,
        renderCell: item => Util.getDateOnly(item.date),
    },
    {
        name: "time",
        title: "Time",
        flex: 1,
        renderCell: item => item.time,
    },
    {
        name: "staus",
        title: "Status",
        flex: 1,
        renderCell: item => item.statusSet,
    },
    {
        name: "note",
        title: "Action Note",
        flex: 2,
        renderCell: item => item.note,
    },
]


function View({ payload, salesOrder }) {

    return (
        <div style={{ paddingBlock: '1rem', }}>
            <h4 style={{
                border: '1px solid #00000010',
                borderRadius: 5,
                paddingInline: '0.5rem',
                paddingBlock: '0.25rem',
                background: '#f4f4f4',
                width: '100%',
                marginBottom: '1rem'
            }}>Order/KOT Logs</h4>
            <SystemLogsListView relatedObjectId={salesOrder?.id} />

            <h4 style={{
                border: '1px solid #00000010',
                borderRadius: 5,
                paddingInline: '0.5rem',
                paddingBlock: '0.25rem',
                background: '#f4f4f4',
                width: '100%',
                marginBottom: '1rem',
                marginTop: '3rem',
            }}>Status Timeline</h4>
            <MinimalTable
                columns={COLUMNS}
                items={payload}
                large
            />
        </div>
    )

}


export const OrderHistory = withLoadablePagePropsAndProps(props => listener => Api.getRestSalesOrderHistoryOverview(props.salesOrder?.id, listener), View);