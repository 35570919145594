import { TextArea, TextInput } from 'carbon-components-react'
import React from 'react'
import DimensionsField from './base/DimensionsField'
import InspectorContentHeader from './base/InspectorContentHeader'

export default ({engine}) => {
    return (
        <div>
            <InspectorContentHeader text="No properties" />
        </div>
    )
}