import { ComboBox } from "carbon-components-react";
import { useEffect, useState } from "react"
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";


export function SetWaiter({ state }) {
    const [loading, setLoading] = useState(true);
    const [waiters, setWaiters] = useState([]);

    useEffect(() => {
        setLoading(true)
        state.api.getAllWaiters(response => {
            if (response.status === true) {
                setWaiters([
                    { id: 0, value: "No Waiter" },
                    ...response.payload
                ])
                setLoading(false);
            } else {
                UIUtil.showError("Failed to load waiters")
            }
        })
    }, [])

    let item = waiters.find(waiter => waiter.id === state.getWaiterId());
    if (item?.id === 0) {
        item = null;
    }
    return (
        <ComboBox
            key={state.getWaiterId() + '-combobox-waiters' + state.isSetWaiterLoading()}
            disabled={loading || state.isSetWaiterLoading()}

            style={{ background: 'black', color: 'white', width: '100%' }}
            placeholder={"Waiter"}

            direction='bottom'
            item={null}
            onChange={e => state.setWaiterId(e.selectedItem !== null ? e.selectedItem.id : 0)}

            itemToString={item => item ? item.value : ""}
            itemToElement={item => (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%', pointerEvents: 'none' }}>
                    <ProfilePic size={24} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.id)} />
                    <span style={{ marginLeft: '0.5rem' }}>
                        {item.value}
                    </span>
                </div>
            )}

            value={item?.value ?? ""}
            items={waiters}
        />
    )
}