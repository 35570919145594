import { useState } from "react";
import Button from "../../../components/Button";
import { POS_SESSION_DISCOUNT_TYPE_COUPON, POS_SESSION_DISCOUNT_TYPE_FREE_FORM, POS_SESSION_DISCOUNT_TYPE_LOYALTY } from "../../../constants/Constants";
import Util from "../../../util/Util";
import { RowDelete16, CheckmarkFilled16 } from '@carbon/icons-react'
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import { Tag } from "carbon-components-react";

export const DiscountItem = ({ salesOrderId, discount, onUpdate }) => {
    let title;
    if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
        title = "Loyalty Points";
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_COUPON) {
        title = "Coupon" + (Util.isStringExists(discount.couponCode) ? " (" + discount.couponCode + ")" : "");
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_FREE_FORM) {
        title = "Free-form Discount"
    }

    let desc;
    if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
        desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_COUPON) {
        if (discount.subtractingAmount !== undefined && discount.subtractingAmount !== null) {
            desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
        } else if (discount.maxSubtractingPercentageAmount && parseFloat(parseFloat(discount.maxSubtractingPercentageAmount).toFixed(2)) > 0) {
            desc = "Subtracting " + discount.subtractingPercentage + "% (max: " + discount.maxSubtractingPercentageAmount + ")";
        } else {
            desc = "Subtracting " + discount.subtractingPercentage + "%";
        }

        // else {
        //     desc = "Subtracting " + discount.subtractingPercentage + "% (max: " + discount.maxSubtractingPercentageAmount + ")";
        // }
    } else if (discount.type == POS_SESSION_DISCOUNT_TYPE_FREE_FORM) {
        if (discount.subtractingAmount !== undefined && discount.subtractingAmount !== null) {
            desc = "Subtracting " + discount.subtractingAmount.toFixed(2);
        } else {
            desc = "Subtracting " + discount.subtractingPercentage + "%";
        }
    }


    const [deleting, setDeleting] = useState(false);
    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            setDeleting(true);
            Api.removeSalesOrderDiscount(salesOrderId, discount.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess();
                    onUpdate(response.payload)
                } else {
                    UIUtil.showError(response.message);
                    setDeleting(false)
                }
            })
        })
    }


    return (
        <div style={{ width: '100%', padding: '0.5rem', display: 'flex', background: '#f4f4f4', color: true ? 'green' : 'rgba(0,0,0,0.50)', marginTop: '0.25rem' }}>
            <div style={{ flex: 1 }}>
                <h5 style={{ fontSize: 14 }}>{title}</h5>
                <p style={{ fontSize: 12 }}>{desc}</p>
                {discount.appliedByAdmin && <Tag size="sm" type="cyan" renderIcon={CheckmarkFilled16}>Applied through backend</Tag>}
            </div>
            <Button style={{ height: 48 }} onClick={onDeleteBtn} loading={deleting} hasIconOnly data-tip="Remove" renderIcon={RowDelete16} className="bx--tooltip--hidden" kind="danger--tertiary" />
        </div>
    )
}