import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { PRODUCT_GROUP_DIVISION_TYPE_DIVISION_BY_ATTRIBUTES, PRODUCT_GROUP_DIVISION_TYPE_NO_DIVISION, TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_ATTRIBUTES, OBJECT_TYPE_PRODUCT_BRAND, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_CATEGORY, OBJECT_TYPE_PRODUCT_DEPARTMENT, OBJECT_TYPE_PRODUCT_GROUP } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import { ProductAttributeValuesView } from '../product/ProductAttributesView';
import {
    Box16, Information16, RowDelete16, Warning16, ErrorFilled16
} from '@carbon/icons-react'
import { ComboBox, ComposedModal, ContentSwitcher, InlineLoading, ModalBody, ModalFooter, ModalHeader, Switch, Tag, TextInput, Tile, TooltipIcon } from 'carbon-components-react';
import UIUtil from '../../util/UIUtil';
import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor';
import { isEqual } from 'lodash';
import Util from '../../util/Util';

const ProductsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_PRODUCT_GROUP,

                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Products",
                subTitle: "Products that exist in this group",
                objectType: OBJECT_TYPE_PRODUCT,
                paginated: true,
                pagePath: "/products/",
                history: history
            })}
        </div>
    )
}

const BundlesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_PRODUCT_GROUP,

                    childType: OBJECT_TYPE_PRODUCT_BUNDLE,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Bundles",
                subTitle: "Bundles that exist in this group",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                pagePath: "/product-bundles/",
                history: history
            })}
        </div>
    )
}


const ChipView = ({ title, items, itemToString, style, selectedItem, onChangeSelectedItem }) => {
    return (
        <div style={style}>
            <p style={{ fontWeight: 'bold', color: 'black', marginBottom: '0.5rem' }}>{title}</p>
            <div style={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap' }}>
                {items.map(item => {
                    const label = itemToString(item);
                    const selected = selectedItem == item;
                    return (
                        <Tag onClick={!selected ? (() => {
                            onChangeSelectedItem(item);
                        }) : undefined} key={item.id} type='blue' style={{ border: 'solid', borderColor: 'blue', borderWidth: selected ? 1 : 0, transition: '250ms' }}>
                            {Util.isStringExists(label) ? label : '-'}
                        </Tag>
                    )
                })}
            </div>
        </div>
    )
}
const AttributeRecord = ({ attributes, input, nonExistent, onInputUpdate, onInputRemoved }) => {
    const [attribute, setAttribute] = useMultiInputState(input, 'attribute', onInputUpdate);

    if (nonExistent) {
        if (attributes.length == 0) {
            return null;
        }
        return (
            <div style={{ display: 'flex', width: '100%', gap: 5 }}>
                <div style={{ flex: 1 }}>
                    <ComboBox titleText="" items={attributes}
                        selectedItem={attribute}
                        props
                        placeholder={attributes.length > 0 ? "Select next attribute" : "No attribute left"}
                        readOnly={attributes.length == 0}
                        light size='lg'
                        itemToString={item => item ? item.title : null}
                        onChange={e => setAttribute(e.selectedItem)} />
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ display: 'flex', width: '100%', gap: 5, }}>
                <TextInput size='xl' value={attribute.title} style={{ pointerEvents: 'none' }} />


                <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                    style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
            </div>
        )
    }
}

function getProductAttrValue(product, attrId) {
    for (const productAttribute of product.attributes) {
        if (attrId == productAttribute.id) {
            return productAttribute.value;
        }
    }
    return ""
}

function getAttrValues(products, selectedAttributes) {
    let attrValues = {};
    const addToAttrValues = (attrId, value) => {
        if (!attrValues[attrId]) {
            attrValues[attrId] = []
        }
        attrValues[attrId].push(value)
    }
    for (const { attribute } of selectedAttributes) {
        for (const product of products) {
            addToAttrValues(attribute.id, getProductAttrValue(product, attribute.id));
        }
    }
    // for (const product of products) {
    //     for (const attribute of product.attributes) {
    //         addToAttrValues(attribute.id, attribute.value);
    //     }
    // }
    return attrValues;
}

function getDistinctAttrValues(products, selectedAttributes) {
    let attrValues = getAttrValues(products, selectedAttributes);
    for (const attr in attrValues) {
        attrValues[attr] = [...new Set(attrValues[attr])].sort()
    }
    return attrValues;
}

function createDefaultAttrConfig(products, selectedAttributes) {
    const attrValues = getDistinctAttrValues(products, selectedAttributes);
    const getAttrValue = (attrId) => {
        const values = attrValues[attrId];
        return values ? (values[0] ?? "") : ""
    };

    let config = {};
    selectedAttributes.forEach(({ attribute }) => config[attribute.id] = getAttrValue(attribute.id));
    return config;
}


function createAttrConfig(product, selectedAttributes) {
    let config = {};
    selectedAttributes.forEach(({ attribute }) => config[attribute.id] = getProductAttrValue(product, attribute.id));
    return config;
}

function getAttrConfigs(products, selectedAttributes) {
    let configs = []
    // products = products.filter(product => {
    //     for (const { attribute } of selectedAttributes) {
    //         for (const productAttribute of product.attributes) {
    //             if (attribute.id == productAttribute.id) {
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // })
    for (const product of products) {
        let config = createAttrConfig(product, selectedAttributes);
        configs.push({ product, config });
    }
    return configs;
}

function getDuplicatedAttrConfigs(products, selectedAttributes) {
    const configs = getAttrConfigs(products, selectedAttributes);
    return configs.filter(({ config }) => {
        let count = 0;
        for (const { config: otherConfig } of configs) {
            if (isEqual(config, otherConfig)) {
                count++;
            }
        }
        return count > 1;
    })
}

//can't happen now, since the other options that don't have the attribute just become -
function getUnreachableProducts(products, selectedAttributes) {
    const configs = getAttrConfigs(products, selectedAttributes);
    const reachableProducts = configs.map(config => config.product);
    let unreachableProducts = [];

    mainLoop: for (const product of products) {
        for (const reachableProduct of reachableProducts) {
            if (product.id == reachableProduct.id) {
                continue mainLoop;
            }
        }
        unreachableProducts.push(product);
    }

    return unreachableProducts;
}

function getProductFromConfig(products, selectedAttributes, config) {
    const configs = getAttrConfigs(products, selectedAttributes);
    for (const { config: productConfig, product } of configs) {
        if (isEqual(productConfig, config)) {
            return product;
        }
    }
    return null;
}

function isProductSelectionAmbiguous(products, selectedAttributes) {
    return getDuplicatedAttrConfigs(products, selectedAttributes).length > 0;
}

const NoDivisionSelector = ({ products }) => {
    const [selected, setSelected] = useState(products[0]);

    return (<>
        <ChipView //labels={products.map(product => product.name)} 
            style={{ marginBottom: '0.5rem' }}
            itemToString={product => product.name}
            items={products}
            selectedItem={selected}
            onChangeSelectedItem={setSelected}
        />

        <label style={{ margin: 0 }} className="bx--label">Selected Product</label>
        <br />
        <Link target="_blank" to={`/products/${selected.id}`}>{selected.name}</Link>
    </>)
}

const AttrDivisionSelector = ({ products, selectedAttributes }) => {
    const [selectedConfig, setSelectedConfig] = useState({});
    const attrValues = useMemo(() => getDistinctAttrValues(products, selectedAttributes), [products, selectedAttributes]);
    const selectedProduct = useMemo(() => getProductFromConfig(products, selectedAttributes, selectedConfig),
        [products, selectedAttributes, selectedConfig])

    const [isSelectionAmbiguous, duplicatedAttrConfigs] = useMemo(() => {
        const dac = getDuplicatedAttrConfigs(products, selectedAttributes)
        return [dac.length > 0, dac];
    }, [products, selectedAttributes]);

    const [hasUnreachableProducts, unreachableProducts] = useMemo(() => {
        const ups = getUnreachableProducts(products, selectedAttributes)
        return [ups.length > 0, ups];
    }, [products, selectedAttributes]);

    useEffect(() => {
        setSelectedConfig(createDefaultAttrConfig(products, selectedAttributes))
    }, [products, selectedAttributes])

    const getAttrTitle = attrId => selectedAttributes.map(i => i.attribute).filter(i => i.id == attrId)[0].title;

    return (<>
        {selectedAttributes.map(({ attribute }) => (
            <ChipView style={{ marginBottom: '0.5rem' }} title={attribute.title}
                selectedItem={selectedConfig[attribute.id]}
                onChangeSelectedItem={value => setSelectedConfig(config => ({ ...config, [attribute.id]: value }))}
                itemToString={i => i}
                items={attrValues[attribute.id] ?? []} />
        ))}

        {isSelectionAmbiguous ? (<>
            <Tile style={{ marginTop: '1rem', background: '#ffd4d4', border: 'solid', borderWidth: 1, borderColor: 'red' }}>
                <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                    <ErrorFilled16 style={{ marginRight: '0.5rem' }} />
                    <h6>Ambiguous Selection Criteria</h6>
                </div>
                <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                    The following products have duplicate configurations in regards to the specified attributes:
                    <ul>
                        {duplicatedAttrConfigs.map(({ product, config }) => (
                            <li style={{ marginBottom: '0.5rem' }}>
                                <Link target="_blank" to={`/products/${product.id}`}>{product.name}</Link>
                                <ul style={{ marginLeft: '1rem', marginTop: '0rem' }}>
                                    {Object.entries(config).map(item => (
                                        <li>
                                            {getAttrTitle(item[0])}:&nbsp;
                                            <strong>{Util.isStringExists(item[1]) ? item[1] : "-"}</strong>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </p>
            </Tile>
        </>) : (<>

            <label style={{ margin: 0 }} className="bx--label">Selected Product</label>
            <br />
            {selectedProduct ? (
                <Link target="_blank" to={`/products/${selectedProduct.id}`}>{selectedProduct.name}</Link>
            ) : (
                <p>No product exists with selected configuration</p>
            )}

            {hasUnreachableProducts && <Tile style={{ marginTop: '1rem', background: '#fdffd4', border: 'solid', borderWidth: 1, borderColor: 'yellow' }}>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <Warning16 style={{ marginRight: '0.5rem' }} />
                    <h6>Selection criteria not exhaustive</h6>
                </div>
                <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                    The following product(s) can not be selected based on any configuration of the specified attributes
                    <ul style={{ marginTop: '0.5rem' }}>
                        {unreachableProducts.map((product) => (
                            <li style={{ marginBottom: '0.25rem' }}>
                                <Link target="_blank" to={`/products/${product.id}`}>{product.name}</Link>
                            </li>
                        ))}
                    </ul>
                </p>
            </Tile>}
        </>)}


    </>)
}


export const ProductDivisionDialog = ({ open, onClose, productGroupId }) => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [options, setOptions] = useState();
    const [currentType, setCurrentType] = useState(PRODUCT_GROUP_DIVISION_TYPE_NO_DIVISION)

    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const unselectedAttributes = useMemo(() => options ? options.attributes.filter(attribute => {
        for (const selectedAttribute of selectedAttributes) {
            if (selectedAttribute.attribute.id == attribute.id) {
                return false;
            }
        }
        return true;
    }) : [], [selectedAttributes, options])

    const changesMade = false;

    useEffect(() => {
        if (open) {
            setLoading(true)
            Api.getProductDivisionOptions(productGroupId, response => {
                if (response.status === true) {
                    setOptions(response.payload)
                    setSelectedAttributes([])
                    setLoading(false);
                } else {
                    UIUtil.showError(response.message);
                    onClose();
                }
            })
        }
    }, [open])

    const onSaveBtn = () => {
        setSaving(true);
    }


    const noDivisionContent = !loading && currentType == PRODUCT_GROUP_DIVISION_TYPE_NO_DIVISION && (
        <>
            <label style={{ margin: 0 }} className="bx--label">Selection Preview</label>
            <NoDivisionSelector products={options.products} />
        </>
    )

    const divideByAttributesContent = !loading && currentType == PRODUCT_GROUP_DIVISION_TYPE_DIVISION_BY_ATTRIBUTES && (
        <>
            <label className="bx--label">Specify Attributes</label>
            <div style={{ width: '100%', paddingInline: '1rem' }}>
                <MultipleInputEditor defaultInputs={selectedAttributes} onInputsUpdated={setSelectedAttributes}
                    attributes={unselectedAttributes}
                    inModal ItemComponent={AttributeRecord} />
            </div>

            {/* {'is: ' + isProductSelectionAmbiguous(options.products, selectedAttributes)} */}

            <label style={{ margin: 0 }} className="bx--label">Selection Preview</label>
            {selectedAttributes.length > 0 ? (
                <AttrDivisionSelector products={options.products} selectedAttributes={selectedAttributes} />
            ) : (
                <NoDivisionSelector products={options.products} />
            )}
        </>
    )

    const content = !loading && (
        <>
            <ContentSwitcher style={{ marginBottom: '1rem' }}
                selectedIndex={currentType == PRODUCT_GROUP_DIVISION_TYPE_NO_DIVISION ? 0 : 1} onChange={({ index }) => {
                    setCurrentType(index)
                }}
            >
                <Switch text="No division" />
                <Switch text="Divide by attributes" />
            </ContentSwitcher>

            {noDivisionContent}
            {divideByAttributesContent}

            {/* <ChipView labels={['Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13']} />
            <ChipView title="Selection" labels={['Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13', 'Nike', 'Iphon13']} /> */}
        </>
    )

    return (
        <ComposedModal size='sm' open={open} onClose={onClose} className="modal-that-supports-combo-box modal-that-supports-scrolling" style={{ overflow: 'auto' }}>
            <ModalHeader label="Product Group" title={<>
                Product Divisions <TooltipIcon direction='bottom' tooltipText={<div>
                    <strong>No division</strong>
                    <br />
                    - All products in the group are dislpayed to be selected
                    <br />
                    <br />
                    <strong>Divide by attributes</strong>
                    <br />
                    - Attributes are displayed to be selected and the system will match a product based on the attributes selected
                    <br />
                    - A pattern-match will be designed by the system so the target product configuration can be selected
                    <br />
                    - NOTE: If there is any ambiguous criteria or no attributes exist, the system will default to no division
                    {/* - NOTE: Only the attributes that exist on all products can be used in product divisions. The products must also have a unique value for each and can not be blank. */}
                </div>} renderIcon={Information16} />
            </>} />
            <ModalBody style={{ paddingRight: '1rem', overflow: 'auto' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <InlineLoading style={{ width: 'auto' }} />
                    </div>
                ) : <>
                    {content}
                </>}
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    disabled={!changesMade}
                    loading={saving}
                    onClick={onSaveBtn}
                >
                    Update
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}


class ProductGroupDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined,

            showProductDivisionDialog: false
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_PRODUCT_GROUP, listener)
            } else {
                Api.getItem(OBJECT_TYPE_PRODUCT_GROUP, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_PRODUCT_GROUP,
                    itemResult: this.state.itemResult,
                    pagePath: "/product-groups/",
                    history: this.props.history,
                    hasCustomBtn: (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1rem' }}>
                        <Button onClick={() => this.setState({ showProductDivisionDialog: true })} renderIcon={Box16}>Product Divisions</Button>
                    </div>),
                    customTabs: [
                        {
                            title: "Products",
                            component: ProductsList
                        },
                        {
                            title: "Bundles",
                            component: BundlesList
                        }
                    ]
                })}

                <ProductDivisionDialog
                    productGroupId={this.getPathParams().itemId}
                    open={this.state.showProductDivisionDialog} onClose={() => this.setState({ showProductDivisionDialog: false })} />
            </div>
        )
    }

}

export default withRouter(ProductGroupDetailPage);