import { useEffect, useRef } from "react";
import Util from "../util/Util";


export function useBarcodeScanner(enabled, onScan) {
    const keyInputBuffer = useRef()
    const keyInputTimeout = useRef();

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const pasteListener = e => {
            if (!(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLTextAreaElement)) {
                e.preventDefault();
                const pasted = (e.clipboardData || window.clipboardData).getData('text');
                if (Util.isStringExists(pasted)) {
                    onScan(pasted);
                }
            }
        }

        const keyListener = e => {
            if (!(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLTextAreaElement)) {
                // e.preventDefault();
                const keyInput = e.key;
                if (keyInput.length != 1 && !e.metaKey) {
                    return;
                }

                if (!keyInputBuffer.current) {
                    keyInputBuffer.current = "";
                }
                keyInputBuffer.current += keyInput;

                clearTimeout(keyInputTimeout.current);
                keyInputTimeout.current = setTimeout(() => {
                    const pasted = keyInputBuffer.current;
                    keyInputBuffer.current = undefined;

                    if (Util.isStringExists(pasted)) {
                        onScan(pasted);
                    }
                }, 100);
            }
        }

        window.addEventListener("paste", pasteListener);
        document.body.addEventListener("keydown", keyListener);

        return () => {
            keyInputBuffer.current = undefined;
            window.removeEventListener("paste", pasteListener);
            document.body.removeEventListener("keydown", keyListener);
        }
    }, [enabled])
}