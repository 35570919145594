import { ClickableTile, ComboBox, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import { ErrorFilled16, ArrowRight16, CheckmarkFilled16, AppConnectivity16 } from '@carbon/icons-react'
import ImageView from "../../components/ImageView";
import { OBJECT_TYPE_PRODUCT } from "../../constants/ObjectTypes";
import Util from "../../util/Util";

const AttributeOption = ({ title, options, value, onChange }) => {
    const [pickerKey, setPickerKey] = useState(() => Util.newTempId());

    useEffect(() => {
        if (!value) {
            setPickerKey(Util.newTempId())
        }
    }, [value])

    return (<>
        <div style={{ height: '1rem' }} />
        <ComboBox
            light
            key={pickerKey}
            titleText={title}
            items={options}
            // itemToString={item => item !== null ? item.value : ""}
            selectedItem={value}
            onChange={e => {
                onChange(e.selectedItem)
                // setSelectedId({ id: e.selectedItem !== null ? e.selectedItem.id : 0, type })
                // setSelectedPickerKey(Util.newTempId())
            }}
        />
    </>)
}

const ProductItem = ({ item, onClick }) => {
    return (<>
        <div style={{ height: '0.25rem' }} />
        <ClickableTile light handleClick={onClick} style={{ borderRadius: 15, border: '1px solid #00000020', display: 'flex', alignItems: 'center', minHeight: 'unset' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
                <ImageView imageViewStyle={{ borderRadius: '50%', }} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, item.id)} style={{ borderRadius: '50%', height: 32, width: 32, marginRight: 10, objectFit: 'contain', objectPosition: 'center', backgroundColor: 'white' }} />
                <div style={{ flex: 1 }}>
                    <h4 style={{ fontSize: 14 }}>{item.name}</h4>
                </div>

                <div style={{ width: 10 }} />
                <ArrowRight16 />
            </div>
        </ClickableTile>
    </>)
}


export function ProductGroupSetAttribute({ defaultProductId, groupId, open, onClose, onSelect }) {
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState({
        attributes: [],
        products: []
    });
    const [values, setValues] = useState({});
    const [searchedProducts, setSearchedProducts] = useState(undefined);

    const onActionBtn = () => {
        if (searchedProducts === undefined) {
            setSearchedProducts(option.products.filter(product => {
                for (const key in values) {
                    const value = values[key];
                    if (value) {
                        if (product.attributes.find(attr => attr.id == key)?.value != value) {
                            return false;
                        }
                    }
                }
                return true;
            }))
        } else {
            setSearchedProducts(undefined)
        }
    }

    const onProductClick = id => {
        onSelect(id);
        onClose();
    }

    useEffect(() => {
        if (open) {
            let canceled = false;
            setLoading(true);
            Api.getProductAttributeOptions(groupId, response => {
                if (canceled) {
                    return;
                }

                setSearchedProducts(undefined)
                if (response.status === true) {
                    let values = {}
                    if (Util.isNumberExist(defaultProductId)) {
                        const product = response.payload.products.find(item => item.id == defaultProductId);
                        if (product) {
                            for (const attr of product.attributes) {
                                values[attr.id] = attr.value;
                            }
                        }
                    }

                    setValues(values)
                    setOption(response.payload);
                } else {
                    setValues({})
                    UIUtil.showError(response.message);
                    setOption({})
                    onClose();
                }
                setLoading(false);
            })

            return () => canceled = true;
        }
    }, [groupId, defaultProductId, open])

    return (
        <ComposedModal className="modal-that-supports-combo-box" key="product-group-set-attribute" size="sm" open={open} onClose={onClose}>
            <ModalHeader label="Product Group" title="Set Attribute" />
            <ModalBody>
                <p style={{ fontSize: 12, marginBottom: '1rem', marginTop: '-1rem', opacity: 0.65 }}>Select attribute values</p>
                <h5 style={{ marginBottom: searchedProducts ? '1rem' : undefined }}>{option.name}</h5>

                {loading ?
                    (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <InlineLoading style={{ width: 'auto' }} />
                        </div>
                    ) : (<>
                        {searchedProducts ? (<>

                            <div style={{ maxHeight: 300, overflow: 'auto' }}>
                                {searchedProducts.map(product => <ProductItem key={product.id} item={product} onClick={() => onProductClick(product.id)} />)}
                            </div>
                            {searchedProducts.length === 0 && <p style={{ fontSize: 14, marginBottom: '1rem', opacity: 0.65 }}>No product found</p>}

                        </>) : (<>


                            {option.attributes.map(option => (
                                <AttributeOption key={option.id} title={option.name} options={option.options} value={values[option.id]} onChange={value => setValues(prev => ({ ...prev, [option.id]: value }))} />
                            ))}
                            <Button disabled={Object.entries(values).length === 0} onClick={() => setValues({})} renderIcon={ErrorFilled16} kind="danger" size="sm" style={{ borderRadius: 15, marginTop: '1rem' }}>Clear selections</Button>

                        </>)}

                    </>)}
                {/* <TextInput
                    data-modal-primary-focus
                    labelText="Label (optional)"
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                /> */}



            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose} disabled={searchedProducts !== undefined}>
                    Cancel
                </Button>
                <Button
                    onClick={onActionBtn}
                    kind={searchedProducts ? 'danger' : 'primary'}
                //disabled={loading || selectedId.id == 0}
                //onClick={onCreate}
                >
                    {searchedProducts ? 'Clear Search' : 'Search'}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}