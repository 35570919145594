import { Link } from "react-router-dom";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../../constants/Constants";
import { getAccountRole } from "../../../../session/SessionManager";
import { ArrowRight16, Policy16, CheckmarkFilledWarning16, CheckmarkOutline16, Pending16 } from '@carbon/icons-react'
import Util from "../../../../util/Util";
import { useState } from "react";
import { DealFormDialog } from "../../../property-finances/dialogs/deal-form-dialog";
import { Tag } from "carbon-components-react";
import { PROPERTY_DEAL_STATUS } from "../../../../domain/property-deal";


const UnconfirmedDeal = ({ setOpen }) => (<>
    <Tag renderIcon={Pending16} style={{ marginRight: '0.5rem' }} type="magenta">Unposted</Tag>
    <button className='lead-flow-icon-button' onClick={() => setOpen(true)}>
        <CheckmarkFilledWarning16 />
    </button>
</>)

const ConfirmedDeal = ({ dealId }) => (<>
    <Tag renderIcon={CheckmarkOutline16} style={{ marginRight: '0.5rem' }} type="blue">Posted</Tag>
    <Link target={"_blank"} to={'/property-deal/' + dealId}>
        <button className='lead-flow-icon-button'>
            <ArrowRight16 />
        </button>
    </Link>
</>)


export function FinalizedStatusDealView({ leadId, report, onUpdate }) {
    const [open, setOpen] = useState(false);

    const role = getAccountRole();
    if (role != ACCOUNT_TYPE_ADMINISTRATION && role != ACCOUNT_TYPE_SUPERVISOR) {
        return null;
    }
    return (
        <div style={{ display: 'flex', gap: '0.15rem', width: '100%', marginTop: '1rem', alignItems: 'center' }}>
            <div style={{ width: 48, height: 48, borderRadius: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#00000020' }}>
                <Policy16 />
            </div>
            <div style={{ flex: 1, marginLeft: '0.5rem' }}>
                <h4 style={{ fontSize: 16 }}>Property Deal</h4>
                <h6 style={{ fontSize: 14 }}>Deal no: {Util.getVoucherNumber(report.createdPropertyDealId)}</h6>
            </div>


            {/* <Link target={"_blank"} to={'/students/' + report.createdStudentId}>
                <button className='lead-flow-icon-button'>
                    <ArrowRight16 />
                </button>
            </Link> */}

            {report.createdPropertyDealStatus == PROPERTY_DEAL_STATUS.UN_POSTED && <UnconfirmedDeal setOpen={setOpen} />}
            {report.createdPropertyDealStatus == PROPERTY_DEAL_STATUS.POSTED && <ConfirmedDeal dealId={report.createdPropertyDealId} />}
            {open && <DealFormDialog leadId={leadId} dealId={report.createdPropertyDealId ?? 0} onUpdate={onUpdate} onClose={() => setOpen(false)} />}
        </div>
    )
}