import { withLoadablePage, withLoadablePageWithParams } from "../../base/Page";
import Api from "../../session/Api";

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Delivery32,
    Building32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    User32,
    CheckmarkOutline16,
    CheckmarkFilled16,
    ErrorFilled16,
    Enterprise32,
    Document32,
    RowDelete16,
    Migrate32,
    Money16,
    Product16,
    ListChecked16,
    Van32,
    Certificate32,
    AirlinePassengerCare16
} from '@carbon/icons-react'
import { ButtonSet, ComboBox, ContentSwitcher, DatePicker, DatePickerInput, NumberInput, RadioTile, Switch, Tag, TextArea, TextInput, TileGroup } from "carbon-components-react";
import { getObjectTypeName, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_PACKAGE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from "../../constants/ObjectTypes";
import { hasCapabilitySupport } from "../../app/Capabilities";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Util, { absAmt, big } from "../../util/Util";
import useStore from "../../hooks/useStore";
import { makeObservable } from "../../util/makeObservable";
import { SALE_AMOUNT_MODE_FREE_FORM, SALE_AMOUNT_MODE_ITEM_BASED } from "../../domain/sale";
import { TaxInput } from "../stock-flow/TaxInput";

import Big from 'big.js';
import Button from "../../components/Button";
import UIUtil from "../../util/UIUtil";
import { useHistory } from "react-router-dom";
import CustomComboBox from "../../components/CustomComboBox";
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_MOBILE_UNIT, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_VENUE, DESTINATION_TYPE_WAREHOUSE, LEDGER_TYPE_EXPENSE, LEDGER_TYPE_INCOME, SOURCE_TYPE_MOBILE_UNIT, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_VENUE, SOURCE_TYPE_WAREHOUSE } from "../../constants/Constants";
import { NumberedSections } from "../../components/numbered-sections";
import { DynamicTable } from "../../components/dynamic-table";
import StockItemEditor from "../stock-flow/StockItemEditor";
import ProductFinder from "../../views/product/ProductFinder";
import ProductServiceTable from "../../views/editable-tables/ProductServiceTable";
import ItemCreatorDialog from "../../templates/ItemCreatorDialog";


const Root = ({ children }) => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
        <div style={{ width: '75vw' }}>
            <NumberedSections>
                {children}
            </NumberedSections>
        </div>
    </div>
)

const Section = ({ children, icon, title, options, extraTopMargin }) => (
    // <div style={{ marginTop: extraTopMargin ? '6rem' : '5rem', borderTop: 'solid', paddingTop: '1rem', borderWidth: 1, borderColor: '#00000060' }}>
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem', }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: 1 }}>{title}</p>
            {options}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (<div style={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
    </div>
    <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
        {desc}
    </p>
</div>)

const Title = () => (
    <div>
        <h1>Sale</h1>
        <p style={{ fontSize: 18 }}>Creating new</p>
    </div>
)

const SelectSource = ({ store, endpoints }) => {
    const [id, setId] = useStore(store, 'sourceId')
    const [type, setType] = useStore(store, 'sourceType')
    const [pickerKey, setPickerKey] = useState(() => Util.newTempId());

    useEffect(() => {
        setId(0)
        setPickerKey(Util.newTempId())
    }, [type])

    const getSourceTypeName = () => {
        switch (type) {
            case SOURCE_TYPE_WAREHOUSE:
                return "Warehouse";
            case SOURCE_TYPE_STORE:
                return "Store";
            case SOURCE_TYPE_VENUE:
                return "Venue"
            case SOURCE_TYPE_MOBILE_UNIT:
                return "Mobile Unit";
        }
        return undefined;
    }

    const getSourceTypeList = () => {
        switch (type) {
            case SOURCE_TYPE_WAREHOUSE:
                return endpoints.warehouses;
            case SOURCE_TYPE_STORE:
                return endpoints.stores;
            case SOURCE_TYPE_VENUE:
                return endpoints.venues;
            case SOURCE_TYPE_MOBILE_UNIT:
                return endpoints.mobileUnits;
        }

        return undefined;
    }


    return (<>
        <TileGroup className={"horizontal-tile-radio "} valueSelected={type} onChange={setType}>
            <RadioTile value={SOURCE_TYPE_WAREHOUSE}>
                <RadioItem icon={DataVis_232} title="Warehouse" desc="Transfer stock from warehouse" />
            </RadioTile>
            <RadioTile value={SOURCE_TYPE_STORE}>
                <RadioItem icon={Store32} title="Store" desc="Transfer stock from store" />
            </RadioTile>
            {hasCapabilitySupport("mobileUnit") &&
                <RadioTile value={SOURCE_TYPE_MOBILE_UNIT}>
                    <RadioItem icon={Van32} title="Mobile unit" desc="Transfer stock from mobile unit" />
                </RadioTile>}
        </TileGroup>

        {getSourceTypeList() !== undefined && <>
            <div style={{ height: '1rem' }} />
            <ComboBox
                key={pickerKey}
                titleText={getSourceTypeName()}
                items={getSourceTypeList()}
                itemToString={item => item !== null ? item.value : ""}
                selectedItem={getSourceTypeList().filter(item => item.id == id)[0]}
                onChange={e => {
                    setId(e.selectedItem?.id ?? 0);
                    if (!e.selectedItem) {
                        setPickerKey(Util.newTempId())
                    }
                }} />
        </>}
    </>
    )
}

const Amount = ({ store }) => {
    const [subtotal] = useStore(store, "subtotal");
    const [tax, setTax] = useStore(store, "tax");

    const total = big(tax).add(big(subtotal))
    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ marginBottom: '1rem' }}>
                <label className="bx--label">{'Subtotal (without tax)'}</label>
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                    <p>AED</p>
                    <TextInput
                        disabled
                        size="lg" hideSteppers min={0}
                        value={subtotal} />
                </div>
            </div>

            <TaxInput
                amount={subtotal}
                taxValue={tax} onTaxValueChange={setTax} />

            <div style={{ height: '1rem' }} />
            <h5>Total Amount</h5>
            <h2>AED {total.toFixed(2)}</h2>
        </div>
    )
}

const RevenueAccountRecord = ({ onRemove, item, setItem, last, accountTreeItems }) => {
    const [selectedAccount, setSelectedAccount] = useState(undefined)
    useEffect(() => {
        const newItem = { ...item, }
        if (selectedAccount) {
            newItem.accountLedgerId = selectedAccount.id
        } else {
            delete newItem.accountLedgerId
        }
        setItem(newItem)
        // setItem({ ...item, accountLedgerId: selectedAccount ? selectedAccount.id : undefined })
    }, [selectedAccount])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', }}>
            <div style={{ flex: 3, }}>
                {/* <TextInput value={item.description} onChange={e => setItem({ ...item, description: e.target.value })} placeholder="Input..." /> */}
                <div style={{ height: 40 }}>
                    <CustomComboBox
                        items={accountTreeItems}
                        selectedItem={selectedAccount}
                        onSelectedItemUpdate={item => setSelectedAccount(item)}
                    // selectedItem={this.state.otherPartyIdObject}
                    // onSelectedItemUpdate={item => this.setState({ otherPartyIdValue: item !== undefined ? item.id : 0, otherPartyIdObject: item })}
                    />
                </div>
            </div>
            <div style={{ flex: 2, }}>
                <TextInput value={item.amount} onChange={e => setItem({ ...item, amount: absAmt(e.target.value) })} placeholder="Input..." />
            </div>
            <div style={{ flex: 4, }}>
                <TextInput value={item.narration} onChange={e => setItem({ ...item, narration: e.target.value })} placeholder="Input..." />
            </div>
            <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                size="sm" disabled={last}
                style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />
        </div>
    )
}

const RevenueAccountsTable = ({ store, endpoints }) => {
    const [total, setTotal] = useStore(store, "revenueAccountsTotal");
    const [items, setItems] = useStore(store, "revenueAccounts")

    const updateItem = item => {
        const newItems = [...items]
        const index = newItems.findIndex(i => i.tempId === item.tempId);
        if (index >= 0) {
            newItems[index] = item;
            setItems(newItems)
        }
    }

    const removeItem = item => setItems(items.filter(i => i.tempId !== item.tempId))

    useEffect(() => {
        const lastItem = items[items.length - 1];
        if (Object.keys(lastItem).length > 1) {
            setItems([...items, { tempId: Util.newTempId() }])
        }

        setTotal(items
            .slice(0, items.length - 1)
            .map(item => big(item.amount))
            .reduce((t, c) => t.add(c), big(0)))
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    const accountTreeItems = endpoints.accountTree.filter(item => item.id == LEDGER_TYPE_INCOME)[0].items

    return (
        <div>
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                    <div style={{ width: 40, height: 0, }} />
                </div>
                {items.map((item, i) =>
                    <RevenueAccountRecord key={item.tempId} accountTreeItems={accountTreeItems} item={item} setItem={updateItem} last={i === items.length - 1} onRemove={() => removeItem(item)} />)}
            </div>

        </div>
    )
}

const RevenueAccounts = ({ store, endpoints }) => {
    const [subtotal] = useStore(store, "serviceSubtotal");
    const [total, setTotal] = useStore(store, "revenueAccountsTotal");
    const totalAboveSubtotal = useMemo(() => big(total).gt(big(subtotal)), [total, subtotal])

    return (<>
        <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
            {totalAboveSubtotal ? (
                <label style={{ color: 'red', flex: 1 }} className="bx--label">Account amounts total is greater than revenue amount!</label>
            ) : (
                <label style={{ flex: 1 }} className="bx--label">Any amount not accounted for will be recorded under sales revenue</label>
            )}
            <Tag renderIcon={Money16} type="purple">Applicable Amount: AED {big(subtotal).toFixed(2)}</Tag>
        </div>
        <RevenueAccountsTable store={store} endpoints={endpoints} />
    </>)
}

const SelectCustomer = ({ store, endpoints }) => {
    const [id, setId] = useStore(store, 'customerId')
    const [pickerKey, setPickerKey] = useState(() => Util.newTempId());

    const [showCreateCustomer, setShowCreateCustomer] = useState(false);
    const [createdCustomers, setCreatedCustomers] = useState([]);

    const customers = useMemo(() => [...createdCustomers, ...endpoints.customers], [createdCustomers, endpoints])

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0rem' }}>
                <Button onClick={() => setShowCreateCustomer(true)} renderIcon={AirlinePassengerCare16} size="sm" style={{ borderRadius: 25 }}>Create Customer</Button>
            </div>

            <ComboBox
                key={pickerKey}
                titleText={"Customer"}
                items={customers}
                itemToString={item => item !== null ? item.value : ""}
                selectedItem={customers.filter(item => item.id == id)[0]}
                onChange={e => {
                    setId(e.selectedItem?.id ?? 0);
                    if (!e.selectedItem) {
                        setPickerKey(Util.newTempId())
                    }
                }} />



            <ItemCreatorDialog
                hideThumbnail
                filterProperties={["fullName", "email", "phoneNumber"]}
                objectType={OBJECT_TYPE_CUSTOMER}
                open={showCreateCustomer} onClose={() => setShowCreateCustomer(false)}
                onCreatedItem={createdItem => {
                    setCreatedCustomers(prev => [...prev, { id: createdItem.id, value: createdItem.fullName }])
                    setId(createdItem.id);
                    UIUtil.showSuccess();
                    setShowCreateCustomer(false);
                }} />
        </>
    )
}

const Items = ({ store }) => {
    const [productSubtotal, setProductSubtotal] = useStore(store, "productSubtotal");
    const [serviceSubtotal, setServiceSubtotal] = useStore(store, "serviceSubtotal");
    const [subtotal, setSubtotal] = useStore(store, "subtotal");
    const [items, setItems] = useStore(store, "items")


    useEffect(() => {
        const getItems = (...types) => items.slice(0, items.length - 1).filter(item => types.includes(item.item?.selectedItem?.type ?? -1));
        const calcItems = items => items.map(item => big(item.qty).times(big(item.amount))).reduce((t, c) => t.add(c), big(0))

        const ps = calcItems(getItems(OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE))
        const ss = calcItems(getItems(OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_PACKAGE, -1))
        const ts = ps.add(ss);

        setProductSubtotal(ps);
        setServiceSubtotal(ss);
        setSubtotal(ts);
    }, [items])

    useEffect(() => () => setItems([{ tempId: Util.newTempId() }]), [])

    return (
        <>
            <ProductServiceTable editable items={items} setItems={setItems} />
            <div style={{ marginBottom: '1rem' }} />
        </>
    )
}

const AdditionalInformation = ({ store }) => {
    const [info, setInfo] = useStore(store, 'info')
    return (
        <TextArea placeholder="Note here..." value={info} onChange={e => setInfo(e.target.value)} />
    )
}


const SaleRecordInfo = ({ store }) => {
    const [refNo, setRefNo] = useStore(store, 'refNo')
    const [saleDate, setSaleDate] = useStore(store, 'saleDate')
    return (
        <>
            <div style={{ marginBottom: '1rem' }}>
                <TextInput labelText="Ref no (optional)" value={refNo} onChange={e => setRefNo(e.target.value)} placeholder="Input..." />
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <DatePicker datePickerType="single"
                    dateFormat="d/m/Y"
                    value={Util.isNumberExist(saleDate) ? saleDate : undefined}
                    onChange={e => {
                        if (e.length > 0) {
                            setSaleDate(e[0].getTime())
                        } else {
                            setSaleDate(0)
                        }
                    }}
                >
                    <DatePickerInput
                        placeholder="dd/mm/yyyy"
                        // placeholder="mm/dd/yyyy"
                        labelText={"Date (optional)"}
                    />
                </DatePicker>
            </div>
        </>
    )
}


function initState(observable) {
    observable.set("items", [{ tempId: Util.newTempId() }])
    observable.set("productSubtotal", 0)
    observable.set("serviceSubtotal", 0)
    observable.set("subtotal", 0)

    //stock flow
    observable.set("sourceType", SOURCE_TYPE_WAREHOUSE)
    observable.set("sourceId", 0)
    observable.set("customerId", 0)

    //service sale
    observable.set("revenueAccounts", [{ tempId: Util.newTempId() }])
    observable.set("revenueAccountsTotal", 0)


    //sale
    observable.set("tax", 0)
    observable.set("info", "")




    observable.set("saleDate", 0)
    observable.set("refNo", "")
}

function validate(store) {
    const {
        subtotal, tax, customerId, revenueAccountsTotal, items,
        serviceSubtotal
    } = store.toObject();


    const amount = big(subtotal).add(big(tax));
    if (big(amount).lte(big(0))) {
        return "Total amount has to be a positive number";
    }

    if (big(revenueAccountsTotal).gt(big(serviceSubtotal))) {
        return "Account amounts total is greater than revenue amount (amount - tax)!"
    }

    if (customerId <= 0) {
        return "Customer not selected";
    }

    const getItems = (...types) => items.slice(0, items.length - 1).filter(item => types.includes(item.item?.selectedItem?.type ?? -1));
    const products = getItems(OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE);
    if (products.find(item => isNaN(item.amount))) {
        return "Invalid numeric value specified in product list"
    }

    return undefined;
}

function toSale(store) {
    const serviceMapper = item => ({
        description: item.item.name,
        itemId: item.item.selectedItem?.id,
        itemType: item.item.selectedItem?.type,
        qty: big(item.qty).toFixed(2),
        unitAmount: big(item.amount).toFixed(2),
        saveCustomService: item.saveCustomService,
    });
    const productMapper = item => ({
        itemId: item.item.selectedItem?.id,
        itemType: item.item.selectedItem?.type,
        quantityValue: big(item.qty).toFixed(2),
        amount: big(item.amount).toFixed(2),
    });



    const state = store.toObject();

    const getItems = (...types) => state.items.slice(0, state.items.length - 1).filter(item => types.includes(item.item?.selectedItem?.type ?? -1));
    const services = getItems(OBJECT_TYPE_SERVICE, OBJECT_TYPE_SERVICE_PACKAGE, -1);
    const products = getItems(OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE);

    const createServiceSale = big(state.serviceSubtotal).gt(big(0)) || services.length > 0;
    const createStockFlow = big(state.productSubtotal).gt(big(0)) || products.length > 0;
    return {
        serviceSaleReq: createServiceSale ? {

            items: services.map(serviceMapper),


            payerType: OBJECT_TYPE_CUSTOMER,
            payerId: state.customerId,
            subtotal: big(state.serviceSubtotal).toFixed(2),
            tax: 0,
            accountDivisions: state.revenueAccounts.slice(0, state.revenueAccounts.length - 1),

            saleDate: state.saleDate,
            refNo: state.refNo,
        } : null,
        stockFlowReq: createStockFlow ? {
            sourceType: state.sourceType,
            sourceId: state.sourceId,

            destinationType: DESTINATION_TYPE_CUSTOMER,
            destinationId: state.customerId,

            items: products.map(productMapper),

            salesAmountSubtotal: big(state.productSubtotal).toFixed(2),
            amount: big(state.productSubtotal).toFixed(2),

            amountAdditionalCosts: big(0),
            salesAmountDiscount: big(0),
            salesAmountTax: big(0),
            transactionPaymentMethods: [],
        } : null,

        customerId: state.customerId,
        subtotal: big(state.subtotal).toFixed(2),
        tax: big(state.tax).toFixed(2),
        info: state.info,

        saleDate: state.saleDate,
        refNo: state.refNo,
    }
}

const Confirm = ({ store, onReset }) => {
    const [creating, setCreating] = useState(false);
    const history = useHistory();

    const createSale = () => {
        const err = validate(store);
        if (err) {
            UIUtil.showError(err);
            return;
        }

        setCreating(true);
        Api.createSale(toSale(store), response => {
            setCreating(false)

            if (response.status === true) {
                history.replace("/sale/" + response.payload)
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonSet style={{ width: 392 }}>
                <Button onClick={onReset} disabled={creating} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                <Button onClick={() => createSale()} loading={creating} renderIcon={Certificate32}>Create Sale</Button>
            </ButtonSet>
        </div>
    )
}




const View = ({ payload: endpoints }) => {
    const [rootKey, setRootKey] = useState(() => Util.newTempId());

    const store = useMemo(() => {
        const observable = makeObservable();
        initState(observable);
        return observable;
    }, [])


    const onReset = () => {
        initState(store);
        setRootKey(Util.newTempId())
    }

    return (
        <Root key={rootKey}>
            <Title />
            {['Select Source',
                <SelectSource store={store} endpoints={endpoints} />]}
            {['Select Customer',
                <SelectCustomer store={store} endpoints={endpoints} />]}
            {['Sale Record Info (optional)',
                <SaleRecordInfo store={store} />]}

            {['Select Items',
                <Items store={store} />]}

            {['Amount',
                <Amount store={store} />]}

            {['Specify Revenue Accounts (optional)',
                <RevenueAccounts store={store} endpoints={endpoints} />]}

            {['Additional Information (optional)',
                <AdditionalInformation store={store} />]}
            {['Confirm', <Confirm store={store} onReset={onReset} />, { extraTopMargin: true }]}

        </Root>
    )
}

export default withLoadablePage(Api.getStockFlowEndpointsList.bind(Api), View);