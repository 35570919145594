import { TAX_TYPES } from "../../../2v/modules/sales";
import { OBJECT_TYPE_ACCOUNT_LEDGER, OBJECT_TYPE_PRODUCT } from "../../../constants/ObjectTypes";
import UIUtil from "../../../util/UIUtil";
import Util, { big } from "../../../util/Util";
import { calcAmounts } from "./financials"


export function FormMapper(data, endpoint) {
    const amounts = calcAmounts({ items: data.items, taxId: data.taxId })

    const {
        voucherNo,
        items: itemsValue,
        tags: tagsValue,
        destinationId: defDestinationId,

        vendorId, billDate, dueDate,

        taxId, memo
    } = data;


    const vendor = endpoint.vendors.find($ => $.id === vendorId);
    const items = itemsValue.filter($ => $.item?.selectedItem?.type)
    const tags = tagsValue.map(val => ({ ...val, name: val.label }))
    const tax = TAX_TYPES.find(tax => tax.id === taxId);


    if (!tax) {
        UIUtil.showInfo("No tax code selected");
        return;
    }

    if (!billDate || billDate <= 0) {
        UIUtil.showInfo("No date set")
        return;
    }

    if (!dueDate || dueDate <= 0) {
        UIUtil.showInfo("No due date set")
        return;
    }

    if (!vendor) {
        UIUtil.showInfo("No vendor selected")
        return;
    }

    if (items.length === 0) {
        UIUtil.showInfo("No products/expenses in bill")
        return;
    }

    const customItem = items.find($ => !$.item.selectedItem);
    if (customItem) {
        if (Util.isStringExists(customItem.item.name)) {
            UIUtil.showInfo(`Item '${customItem.item.name}' does not exist`)
        } else {
            UIUtil.showInfo("Please ensure every row has an item selected")
        }
        return;
    }


    return {
        voucherNo: voucherNo,
        billDate: billDate,
        dueDate: dueDate,
        changeGmtTime: true,

        billFromName: vendor.name,
        billFromTrn: vendor.trn,
        billFromAddress: vendor.address,

        items: items.map(item => ({
            vendorId: vendorId,
            destinationId: Util.isNumberExist(item.destinationId) ? item.destinationId : defDestinationId,

            itemId: item.item.selectedItem?.id,
            itemType: item.item.selectedItem?.type === "PRODUCT" ? OBJECT_TYPE_PRODUCT : OBJECT_TYPE_ACCOUNT_LEDGER,

            rate: big(item.amount).toFixed(4),
            qty: big(item.qty).toFixed(4),
            tax: amounts.itemTaxPercFn(item).toFixed(4),
            // dividedDiscount: amounts.itemDiscount(item).toFixed(2),

            taxName: Util.isStringExists(item?.taxName) ? item?.taxName : tax.value,
            tags: item.tags?.map(val => ({ ...val, name: val.label })) ?? [],

            memo: item.memo,
        })),

        memo: memo,
        tags: tags,
    }

}