import { ArrayInput } from "../../../../components/array-input";
import { AwareKVStoreEditor, KVStoreEditor } from "../../../../components/KVStoreEditor";
import Api from "../../../../session/Api";
import { FormSection } from "../../../../templates/form/form";
import { FormDialog } from "../../../../templates/form/form-dialog";
import { COUNTRY_LIST } from "../../../../util/country-list";

const AdditionalInformation = ({ value, setValue }) => (
    <div style={{ paddingInline: '0.5rem' }}>
        <AwareKVStoreEditor highZ defaultItems={value ?? []} onUpdate={setValue} />
    </div>
)

function FormView(form) {
    const { TextField, TextAreaField, SwitchField, ComboBoxField, CustomField, ExtField } = form;
    return (
        <div>
            <FormSection title="Info">
                <SwitchField title="Title" fieldKey="title" options={[{ id: 'Mr', title: "Mr" }, { id: 'Ms', title: "Ms" }]} />
                <TextField title="Full Name" fieldKey="fullName" />
                <TextField title="Age" fieldKey="age" />
                <TextAreaField title="Address" fieldKey="addressText" />
                <ComboBoxField title="Nationality" fieldKey="nationality" options={COUNTRY_LIST.map(item => ({ id: item, title: item }))} />
            </FormSection>

            <FormSection title="Contacts">
                <CustomField fieldKey="phoneNumbers" childrenProps={{ label: "Phone Number", placeholder: "number" }}>
                    {ArrayInput}
                </CustomField>
                <CustomField fieldKey="emailAddresses" childrenProps={{ label: "Email Address", placeholder: "address" }}>
                    {ArrayInput}
                </CustomField>
            </FormSection>

            <FormSection title="Additional Information">
                <CustomField fieldKey="additionalInformation" childrenProps={{}}>
                    {AdditionalInformation}
                </CustomField>
            </FormSection>

            {/* <pre><code>{JSON.stringify(form.getFormData(), null, 2)}</code></pre> */}
        </div>
    )
}

export function LeadProfileDialog({ leadId, profile, setProfile, open, onClose }) {

    return (
        <FormDialog open={open} onClose={onClose} label="Updating" title="Lead Profile"
            item={profile} setItem={setProfile}
            saveApi={(data, listener) => Api.updateLeadProfile(leadId, data, listener)} >
            {FormView}
        </FormDialog>
    )
}