
import Editor from "@monaco-editor/react";
import { Code16, CheckmarkFilled16, WarningAltFilled16, Locked16 } from '@carbon/icons-react'
import { InlineLoading, Link, Tag } from "carbon-components-react";
import { useSaveShortcut } from "../hooks/use-save-shortcut";
import UIUtil from "../../util/UIUtil";
import { useEffect, useMemo, useState } from "react";
import Api from "../../session/Api";
import { useWarningOnClose } from "../../hooks/useWarningOnClose";

export function CodeEditor({ selectedDefinition, title = '', lang, selected, onSaved, srcLoader, srcSaver, toolbarTopRight }) {
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState("");
    const [originalValue, setOriginalValue] = useState(false);

    //const readonly = !selected?.id;
    const readonly = selected?.readonly;

    const changesMade = useMemo(() => value !== originalValue, [value, originalValue])

    useWarningOnClose(changesMade);
    useEffect(() => {
        if (!changesMade) {
            return;
        }

        window._roboCodeEditorUnsavedChanges = true;

        return () => {
            delete window._roboCodeEditorUnsavedChanges;
        }
    }, [changesMade])

    const onSaveActionFn = async () => {
        if (!changesMade) {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => {
                if (srcSaver) {
                    return srcSaver(selected, value, listener);
                }

                return api.saveMarkupTemplateSrc(selected.path, value, listener);
            });
            if (!success) {
                return;
            }

            setOriginalValue(value);
            onSaved?.(response);
        } finally {
            stopLoading();
        }
    }

    useSaveShortcut(onSaveActionFn, [value])


    useEffect(() => {
        setValue("")
        setOriginalValue("");

        if (!selected?.path) {
            setLoading(false);
            return;
        }


        let cancelled = false;
        setLoading(true);

        const listener = response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setLoading(false);
                setValue(response.payload);
                setOriginalValue(response.payload);
            } else {
                UIUtil.showError(response.message);
            }
        }

        if (srcLoader) {
            srcLoader(selected, listener);
        } else {
            Api.loadMarkupTemplateSrc(selectedDefinition?.name, selected.path, listener);
        }

        return () => cancelled = true;
    }, [selected?.path])


    const loader = (
        <div className="robo-ide-editor centered-progress-bar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
            <InlineLoading style={{ width: 'unset' }} />
            <p style={{ fontSize: 12, opacity: 0.65, color: 'white', textAlign: 'center' }}>Loading...</p>
        </div>
    );


    if (loading) {
        return loader;
    }


    if (!selected) {
        return (
            <div className="robo-ide-editor" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
                <p style={{ fontSize: 12, opacity: 0.65, color: 'white', textAlign: 'center' }}>Nothing selected</p>
            </div>
        )
    }

    return (
        <div className="robo-ide-editor">
            <div className="robo-ide-editor-status-bar">
                <Code16 />
                {title + selected.name}


                {readonly ? (
                    <div className="readonly-message"><Locked16 /> Read Only</div>
                ) : changesMade ? (
                    <div className="unsaved-changes-warning"><WarningAltFilled16 /> Unsaved Changes!</div>
                ) : (
                    <div className="changes-saved-message"><CheckmarkFilled16 /> Changes Saved</div>
                )}

                {changesMade && <Link style={{ marginLeft: '0.25rem', cursor: 'pointer', color: 'green' }} onClick={onSaveActionFn}>Save</Link>}

                <div style={{ flex: 1 }} />

                {!changesMade &&
                    toolbarTopRight}
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 2rem)' }}>
                <Editor
                    value={value}
                    onChange={readonly ? undefined : setValue}
                    theme="vs-dark"
                    language={lang}
                    loading={loader}
                    options={{
                        readOnly: readonly,
                        "bracketPairColorization.enabled": true
                    }}
                />
            </div>
        </div>
    )
}