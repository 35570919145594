import { Checkbox, ComposedModal, Link, Loading, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Button from '../../../../components/Button'
import Api from '../../../../session/Api'
import UIUtil from '../../../../util/UIUtil'
import DimensionsField from './base/DimensionsField'
import InspectorContentHeader from './base/InspectorContentHeader'

export default ({ engine }) => {
    const [loading, setLoading] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [deleteConfirmPhraseValue, setDeleteConfirmPhraseValue] = useState("")
    const history = useHistory();

    const onDuplicateBtn = () => {
        UIUtil.confirm(() => {
            setLoading(true);
            Api.duplicateTemplate(engine.getState().templateId, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    window.open("/template-designer/" + response.payload, "_blank")
                } else {
                    UIUtil.showError(response.message);
                }
                setLoading(false);
            })
        })
    }

    const onDeleteBtn = () => {
        setLoading(true);
        Api.deleteTemplate(engine.getState().templateId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                history.replace("/templates")
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        });
    }

    return (
        <div>
            {loading && <Loading />}

            <InspectorContentHeader text="Template Info" />
            <TextInput labelText="Name" style={{ marginBottom: '1rem' }} value={engine.get("titleValue")} onChange={e => engine.set("titleValue", e.target.value)} />
            <TextArea labelText="Note (optional)" value={engine.get("noteValue")} onChange={e => engine.set("noteValue", e.target.value)} />
            <div style={{ marginTop: '1rem' }} onClick={() => engine.set('templateTypeDefaultValue', !engine.get('templateTypeDefaultValue'))}>
                <Checkbox labelText="Default in type" checked={engine.get('templateTypeDefaultValue')} />
            </div>
            <p style={{ fontSize: 12, opacity: 0.65 }}>Each type can only have one default that will be selected at first. Saving this template as default will remove the default status from any other template in this type.</p>

            <InspectorContentHeader text="Dimensions" hasTopMargin />
            <DimensionsField
                size={{ width: engine.get('canvasWidth'), height: engine.get('canvasHeight') }}
                onSizeChange={endSize => {
                    const startSize = { width: engine.get('canvasWidth'), height: engine.get('canvasHeight') };
                    const setSizeState = size => engine.setState({ canvasWidth: size.width, canvasHeight: size.height }, () => engine.onResize());

                    setSizeState(endSize);
                    engine.recordHistoryAction({
                        run: () => setSizeState(endSize),
                        reverse: () => setSizeState(startSize)
                    })
                }}

            />

            <InspectorContentHeader text="Action" hasTopMargin />
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.5rem' }}>
                <Link onClick={onDuplicateBtn} style={{ cursor: 'pointer', marginBottom: '0.25rem' }}>Duplicate Template</Link>
                <Link onClick={() => setShowConfirmDialog(true)} style={{ color: '#990000', cursor: 'pointer' }}>Delete Template</Link>
            </div>

            <ComposedModal open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
                <ModalHeader label="Confirm" title="Are you sure?" />
                <ModalBody>
                    <p style={{ marginBottom: 5 }}>
                        Deleting this template is irreversible and will be permanent!
                    </p>
                    <TextInput
                        data-modal-primary-focus
                        value={deleteConfirmPhraseValue}
                        onChange={e => setDeleteConfirmPhraseValue(e.target.value)}
                        onPaste={e => e.preventDefault()}
                        id="text-input-1"
                        labelText="To confirm please type out 'delete permanently'"
                        placeholder="Type out confirm phrase"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button kind="secondary" onClick={() => setShowConfirmDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        kind="danger" disabled={deleteConfirmPhraseValue != "delete permanently"}
                        onClick={onDeleteBtn}>
                        Delete
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </div>
    )
}