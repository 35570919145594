import React, { useEffect, useRef } from 'react'
import ProfilePic from '../../../components/ProfilePic'
import { getRandomMarkerColor } from './Markers'

import { Map16, ShoppingCart16, ArrowLeft16 } from '@carbon/icons-react'
import Button from '../../../components/Button'
import { Link } from 'react-router-dom'
import Api from '../../../session/Api'
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT } from '../../../constants/ObjectTypes'
import ReactTooltip from 'react-tooltip'




export default ({ item, onMonitorBtn, onMapEventsBtn, headerMode, onBackBtn, highlight, subtitle, onRemove }) => {
    const calledOnRemove = useRef(false);

    useEffect(() => {
        if (calledOnRemove.current) {
            return;
        }

        if (item.cashClosedFlag) {
            calledOnRemove.current = true;
            onRemove(item);
        }
    }, [item.cashClosedFlag])

    return (
        <div //className="theme-bg" 
            style={{
                ...(headerMode ? {
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    zIndex: 10,
                } : {}),


                width: '100%', //height: 48,
                paddingTop: '0.25rem', paddingBottom: '0.25rem',
                borderRadius: 5,
                transition: 'background 250ms',
                background: highlight ? getRandomMarkerColor(item.id) : 'white', display: 'flex', alignItems: 'center',
                paddingLeft: headerMode ? '0.25rem' : '1rem', paddingRight: '1rem', overflow: 'hidden',
                border: 'solid', borderWidth: 1, borderColor: getRandomMarkerColor(item.id),
                marginBottom: '0.5rem',
                boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) '
            }}>
            {headerMode &&
                <Button className="bx--tooltip--hidden" style={{ borderRadius: 5, marginRight: '0.5rem' }} kind="ghost" hasIconOnly renderIcon={ArrowLeft16} size="lg" onClick={onBackBtn} />}

            <Link target="_blank" to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + '/' + item.operatorId}>
                <ProfilePic size={30} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, item.operatorId)} />
            </Link>
            <div style={{ flex: 1, paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>{item.operatorFullName}</p>
                    <div style={{ minWidth: 4, minHeight: 4, borderRadius: '50%', marginLeft: '0.5rem', marginRight: '0.5rem', background: 'black', opacity: 0.65 }} />
                    <p>{item.terminalName}</p>
                </div>
                <p style={{ fontSize: 12, opacity: 0.65, marginTop: '-0.25rem' }}>{item.lastAddress}</p>

                {(item && item.totals && item.totals.total) ? <p style={{ color: '#00551a', fontSize: 14 }}>AED {item.totals.total.toFixed(2)}</p> : null}
            </div>

            {headerMode && (
                <div>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Location events</p>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{subtitle}</p>
                </div>
            )}

            {!headerMode && <>
                <Button className="bx--tooltip--hidden" style={{ borderRadius: 5 }} kind="ghost" hasIconOnly renderIcon={Map16} data-tip={'Location Events'} data-effect="solid" size="lg" onClick={onMapEventsBtn} />
                <Button className="bx--tooltip--hidden" style={{ borderRadius: 5 }} kind="ghost" hasIconOnly renderIcon={ShoppingCart16} data-tip="Monitor POS Cart" data-effect="solid" size="lg" onClick={onMonitorBtn} /> </>}

            <ReactTooltip />
        </div>
    )
}