
import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Number_832,
    Number_932,
} from '@carbon/icons-react'
import React from 'react';

const icon = (i) => {
    switch (i) {
        case 1: return Number_132;
        case 2: return Number_232;
        case 3: return Number_332;
        case 4: return Number_432;
        case 5: return Number_532;
        case 6: return Number_632;
        case 7: return Number_732;
        case 8: return Number_832;
        case 9: return Number_932;
    }
}

const Section = ({ children, icon, title, options, extraTopMargin }) => (
    // <div style={{ marginTop: extraTopMargin ? '6rem' : '5rem', borderTop: 'solid', paddingTop: '1rem', borderWidth: 1, borderColor: '#00000060' }}>
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem', }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: 1 }}>{title}</p>
            {options}
        </div>
        {children}
    </div>
)



const children = props => Array.isArray(props.children) ? props.children : [props.children]

export function NumberedSections(props) {
    let i = 0;
    // console.log(children(props).filter(item => Array.isArray(item)))
    return children(props)
        // .filter(item => !Array.isArray(item) || item[1] !== null)
        .map(item => {
            if (Array.isArray(item)) {
                i++;
                return (
                    <Section key={item[0] + '-key'} title={item[0]} icon={icon(i)} {...item[2]}>
                        {item[1]}
                    </Section>
                )
            } else {
                return item;
            }
        });
}