
import {
    Document32, ChartBullet16, Notebook16, ArrowLeft16, Report32, Run32, Calendar32, DocumentPdf32, ErrorFilled16, Currency32,
    Currency16, ArrowRight16, ChartBullet32, Catalog32, Money32, List32, TreeView32, RequestQuote16, Notebook32,
    Migrate16, Account32, PlaneSea32, Edit16
} from '@carbon/icons-react'
import { ButtonSet, Link as DLink } from "carbon-components-react"
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { withLoadablePageWithParams } from '../../base/Page';
import Button from '../../components/Button';
import ProfilePic from '../../components/ProfilePic';
import { TRANSACTION_DIRECTION_TYPE_OUTWARD } from '../../constants/Constants';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_BILL } from '../../constants/ObjectTypes';
import { BillStatusIndicator, BILL_STATUS_NOT_PAID, BILL_STATUS_PARTIALLY_PAID, BILL_STATUS_REVOKED } from '../../domain/bill';

import useApi from '../../hooks/useApi';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Util, { big } from '../../util/Util';
import NoteView from '../../views/notes/NoteView';
import { TransactionPayButton } from '../../views/transactions/transaction-pay-button';
import TransactionEditor from '../transaction/TransactionEditor';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { SelectShipments, ShipmentListDialog } from './bill-shipments';
import { EditBill } from './edit-bill';
import { SidePanel } from '../../templates/draft/components/side-panel';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const getAmountStatus = bill => {
    const amount = bill.amount;
    const amountPaid = bill.amountPaid;

    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Fully Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;

        status += " (AED " + amountLeft.toFixed(2) + " left)";
    }

    return { status, statusColor }
}

const Section = ({ id, children, icon, title, subtitle, extraTopMargin, extra }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
            {extra && <>
                <div style={{ marginLeft: '0.75rem' }} />
                {extra}
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const TransactionItem = ({ bill, transaction, mini }) => {
    const [expanded, setExpanded] = useState(false);

    const paymentMethodHeight = 80 + convertRemToPixels(0.5);

    let dividedAmount = 0;
    if (transaction.againstItems && transaction.againstItems.length !== undefined) {
        for (const item of transaction.againstItems) {
            if (item.billId == bill.id) {
                dividedAmount = item.dividedAmount;
                break;
            }
        }
    }

    return (
        <div style={{
            transition: '250ms', height: expanded ? 75 + (115 + (paymentMethodHeight * transaction.paymentMethods.length - 1)) : 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: mini ? 12 : 14 }}>Transaction (voucher no: {Util.getTransactionVoucherNumber(transaction.id, transaction.directionType, transaction.sequenceNo)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        {mini ? (<>
                            <p>AED {dividedAmount.toFixed(2)}</p>
                            <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem', fontSize: 14 }}> / AED {transaction.amount.toFixed(2)} (Total)</p>
                        </>) : (<>
                            <h2>AED {dividedAmount.toFixed(2)}</h2>
                            <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / AED {transaction.amount.toFixed(2)} (Total Transaction Amount)</p>
                        </>)}
                    </div>



                    {/* <h2>AED {transaction.amount.toFixed(2)}</h2> */}
                </div>
                {!mini && <p style={{ fontSize: 12, opacity: 0.65 }}>View details</p>}
                <ArrowRight16 style={{ opacity: 0.65, transition: '250ms', marginLeft: '0.25rem', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
            </div>
            <div style={{ height: 115 + (paymentMethodHeight * transaction.paymentMethods.length - 1), paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, overflow: 'auto' }} >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Link target="_blank" to={"/transaction/" + transaction.id} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Open Transaction</Button>
                    </Link>
                </div>

                <TransactionEditor
                    hideTotal
                    readOnly showColorForIncome
                    defaultPaymentMethods={transaction.paymentMethods}
                    currency={"AED"}
                    //incoming={!isSourceExternal}
                    incoming={false}
                    totalAmount={transaction.amount} />
            </div>
        </div>
    )
}



const Title = ({ bill, mini }) => {
    const history = useHistory();
    const [loading, revoke] = useApi(listener => Api.revokeBill(bill.id, listener), () => window.location.reload());

    if (mini) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                <ChartBullet16 />
                <h4 style={{ flex: 1, marginLeft: '0.15rem' }}>Bill</h4>
                <BillStatusIndicator status={bill.status} />
            </div>
        )
    }

    return (<>
        {!mini && <DLink onClick={() => history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>}
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChartBullet32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <h1 style={{ flex: 1 }}>Bill</h1>

            {!mini && <>
                {(bill.status == BILL_STATUS_PARTIALLY_PAID || bill.status == BILL_STATUS_NOT_PAID) && (
                    <div style={{ marginRight: 2 }}>
                        <TransactionPayButton
                            amount={parseFloat(parseFloat(bill.amount - bill.amountPaid).toFixed(2))}
                            direction={TRANSACTION_DIRECTION_TYPE_OUTWARD} itemId={bill.id} itemType={OBJECT_TYPE_BILL}
                            partyItemType={bill.payeeType} partyId={bill.payeeId} />
                    </div>
                )}
                {bill.status == BILL_STATUS_NOT_PAID ? (
                    <ButtonSet style={{ width: '392px', gap: 2 }}>
                        <Button loading={loading} onClick={() => UIUtil.confirm(revoke)} kind="danger" renderIcon={ErrorFilled16} style={{ width: 195 }}>
                            Revoke
                        </Button>
                        <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                    </ButtonSet>
                ) : (
                    <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                )}
            </>}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
            <BillStatusIndicator status={bill.status} />
        </div>
    </>)
}

const Amount = ({ bill }) => {
    const amount = bill.amount;
    const amountPaid = bill.amountPaid;
    const amountLeft = amount - amountPaid;

    let status;
    let statusColor;
    if (amountLeft <= 0) {
        status = "Paid";
        statusColor = "green";
    } else {
        status = amountPaid == 0 ? "Not paid" : "Partially paid";
        statusColor = amountPaid == 0 ? "red" : undefined;
    }

    return (<>
        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
            <p>
                AED {amount.toFixed(2)}
            </p>
        </div>

        <div style={{ marginBottom: '1rem', }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
            <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                AED {amountPaid.toFixed(2)}
            </p>
        </div>
    </>)
}

const PrintBtn = ({ bill }) => {
    let btn;
    if (Util.isNumberExist(bill.purchaseId)) {
        btn = (
            <Link to={"/purchase/" + bill.purchaseId} style={{ textDecoration: 'none' }}>
                <Button kind="secondary" style={{ width: '100%' }} renderIcon={Migrate16}>Purchase Voucher</Button>
            </Link>
        )
    } else {
        //btn = <PurchaseVoucherDownload stockFlow={this.state.stockFlow} />
        btn = null;
    }

    if (btn) {
        return (
            <div style={{ marginBottom: '2rem' }}>
                {btn}
            </div>
        )
    } else {
        return null;
    }
}

const RecordInfo = ({ bill }) => (
    <Section icon={Account32} title="Bill Record Info">
        {Util.isStringExists(bill.refNo) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Bill No'}</label>
                <p style={{}}>{bill.refNo}</p>
            </div>}
        {Util.isNumberExist(bill.billDate) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Bill Date'}</label>
                <p style={{}}>{Util.getDateOnly(bill.billDate)}</p>
            </div>}
    </Section>
)

const Info = ({ bill }) => (
    <Section icon={Report32} title="Document Info">
        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
            <p style={{}}>{Util.getVoucherNumber(bill.id)}</p>
        </div>

        <div style={{ marginBottom: '1rem' }}>
            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Payee'}</label>
            <Link target="_blank" to={getObjectTypeUrl(bill.payeeType) + '/' + bill.payeeId}><p style={{}}>{bill.payeeName}</p></Link>
        </div>

        <PrintBtn bill={bill} />

        <Amount bill={bill} />

        {/* <Link target="_blank" to={"/journal-entry/" + bill.journalEntryId} style={{ textDecoration: 'none', }}>
            <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: 195 }}>Associated Journal Entry</Button>
        </Link> */}
    </Section>

)

const Initiation = ({ bill }) => (
    <Section icon={Run32} title="Initiation">
        <label style={{}} className="bx--label">Initiator</label>
        <Link target="_blank" style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + bill.initiatorId}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, bill.initiatorId)} />
                <p>{bill.initiatorFullName}</p>
            </div>
        </Link>

        <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar32 style={{ marginRight: '0.5rem' }} />
            <p>{Util.getDate(bill.initiationDate)}</p>
        </div>
    </Section>
)

const Transactions = ({ bill, mini }) => (
    <Section icon={Currency32} title="Transactions" //subtitle={'Total Amount Paid: AED ' + parseFloat(stockFlow.amountPaid).toFixed(2)}
        subtitle={getAmountStatus(bill).status} subtitleColor={getAmountStatus(bill).statusColor}
    >
        {bill.transactions.map(transaction => <TransactionItem key={transaction.id} bill={bill} transaction={transaction} mini={mini} />)}
    </Section>
)

const AmountBreakdown = ({ bill }) => (
    <Section icon={Money32} title="Amount Breakdown">
        {Util.isNumberExist(bill.subtotal) && <>
            <p style={{ fontSize: 14 }}>Subtotal (+)</p>
            <h2>AED {bill.subtotal.toFixed(2)}</h2>
        </>}

        {Util.isNumberExist(bill.tax) && <>
            <p style={{ fontSize: 14, marginTop: '0.25rem' }}>Tax (+)</p>
            <h2>AED {bill.tax.toFixed(2)}</h2>
        </>}

        <p style={{ marginTop: '1rem' }}>Total Amount</p>
        <h1 style={{ color: 'green' }}>AED {bill.amount.toFixed(2)}</h1>
    </Section>
)


const ItemRecord = ({ item, }) => {
    const total = useMemo(() => big(item.qty).times(big(item.unitAmount)).toFixed(2), [item])
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', height: 40 }}>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.description}</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>AED {item.unitAmount}</p>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.qty}x</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>AED {total}</p>
            </div>
        </div>
    )
}

const ItemsTable = ({ bill }) => {
    const items = bill.items;
    return (
        <Section icon={List32} title="List">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Description</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Unit Amount</h6>
                    </div>
                    <div style={{ flex: 1, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Qty</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Total</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <ItemRecord key={item.id} item={item} />)}
            </div>
        </Section>
    )
}


const AccountRecord = ({ item, mini }) => {
    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: 'black', minHeight: mini ? 40 : undefined, height: mini ? undefined : 40 }}>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.accountLedgerName}</p>
            </div>
            <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{(item.tags ?? []).map(tag => tag.name).join(", ")}</p>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>AED {item.amount}</p>
            </div>
            <div style={{ flex: 4, display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '1rem' }} />
                <p style={{ fontSize: 14, opacity: 1, fontWeight: 'bold' }}>{item.narration}</p>
            </div>
        </div>
    )
}

const AccountsTable = ({ bill, mini }) => {
    const items = bill.accountDivisions;
    return (
        // <Section icon={List32} title="Expense Accounts">
        <Section icon={List32} title="Accounts">
            <div className="no-input-border-2" style={{ background: '#f4f4f4', width: '100%', border: 'solid', borderColor: 'black', borderRadius: 5, borderWidth: 1, borderBottomWidth: 0, overflow: 'hidden' }}>
                <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Account</h6>
                    </div>
                    <div style={{ flex: 3, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Class</h6>
                    </div>
                    <div style={{ flex: 2, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Amount</h6>
                    </div>
                    <div style={{ flex: 4, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: '1rem' }} />
                        <h6>Narration</h6>
                    </div>
                </div>
                {items.map((item) =>
                    <AccountRecord key={item.id} item={item} mini={mini} />)}
            </div>
        </Section>
    )
}

const ShipmentsTable = ({ bill, setBill }) => {
    const [editVisible, setEditVisible] = useState(false);

    return (
        <Section icon={PlaneSea32} title={"Shipments"} extra={
            undefined
            //<Button onClick={() => setEditVisible(true)} size="sm" style={{ borderRadius: 25 }} renderIcon={Edit16}>Edit List</Button>
        }>
            <SelectShipments value={bill.againstShipments} setValue={() => { }} readonly />
            {(bill.againstShipments?.length ?? 0) === 0 && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginTop: '1rem' }}>No shipments specified</p>
            </>}

            <ShipmentListDialog bill={bill} open={editVisible} onClose={() => setEditVisible(false)} onUpdateBill={setBill} />
        </Section>
    )
}

const View = ({ payload, mini }) => {
    const [bill, setBill] = useState(payload);
    const [showEditDialog, setShowEditDialog] = useState(false);
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: mini ? '1rem' : undefined, paddingTop: mini ? undefined : '6rem', paddingBottom: mini ? undefined : '6rem' }}>
            <div style={{ width: '75vw' }}>
                <Title bill={bill} mini={mini} />
                {bill.status !== BILL_STATUS_REVOKED && bill.canEdit &&
                    <Button size="sm" style={{ borderRadius: 25, marginTop: '0.25rem' }} kind="secondary" renderIcon={Edit16} onClick={() => setShowEditDialog(true)}>Edit</Button>}
                {(Util.isNumberExist(bill.billDate) || Util.isStringExists(bill.refNo)) && <RecordInfo bill={bill} />}
                <Info bill={bill} />

                {hasCapabilitySupport("shipping") && <ShipmentsTable bill={bill} setBill={setBill} />}

                {bill.status !== BILL_STATUS_REVOKED && Util.isNumberExist(bill.journalEntryId) && <Section icon={TreeView32} title="Accounting">
                    <Link target="_blank" to={"/journal-entry/" + bill.journalEntryId} style={{ textDecoration: 'none', }}>
                        <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: undefined }}>Associated Journal Entry</Button>
                    </Link>
                </Section>}

                {bill.items !== null && bill.items.length > 0 &&
                    <ItemsTable bill={bill} />}


                {/* amount list */}
                {bill.accountDivisions !== null && bill.accountDivisions.length > 0 &&
                    <AccountsTable bill={bill} mini={mini} />}


                {bill.transactions !== null && bill.transactions.length > 0 &&
                    <Transactions bill={bill} mini={mini} />}

                <AmountBreakdown bill={bill} />
                <Initiation bill={bill} />
                {Util.isStringExists(bill.info) && <Section icon={Catalog32} title="Additional Information">
                    <p style={{ whiteSpace: 'pre-line' }}>{bill.info}</p>
                </Section>}

                {!mini && <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                    <NoteView objectId={bill.id} objectType={OBJECT_TYPE_BILL} readonly={bill.status === BILL_STATUS_REVOKED} />
                </Section>}
            </div>

            {showEditDialog && <SidePanel md onClose={() => setShowEditDialog(false)}>
                <EditBill itemId={bill.id} onClose={() => setShowEditDialog(false)} />
            </SidePanel>}
        </div>
    )
}

export default withLoadablePageWithParams(params => listener => Api.getBill(params.billId, listener), View)
export const MiniBillPage = withLoadablePageWithParams(params => listener => Api.getBill(params.billId, listener), props => <View {...props} mini />)

