import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_COURSE_ENROLMENT, OBJECT_TYPE_GUARDIAN, OBJECT_TYPE_STUDENT } from '../../../constants/ObjectTypes';
import { withLoadablePageWithParams } from '../../../base/Page';
import ItemTemplate from '../../../templates/ItemTemplate';
import { CalendarPage } from '../../calendar/calendar-page';
import TableTemplate from '../../../templates/TableTemplate';
import ItemCreatorDialog from '../../../templates/ItemCreatorDialog';
import { COURSE_ENROLMENT_CUSTOM_LIST, TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants';
import UIUtil from '../../../util/UIUtil';
import AllSubsidiaryReportView from '../../accounting-reports/AllSubsidiaryReportView';

const OBJECT_TYPE = OBJECT_TYPE_STUDENT;


const Enrolments = ({ item }) => {
    const history = useHistory();

    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                title: "Course Enrolments",
                subTitle: "All of student's enrolments",
                objectType: OBJECT_TYPE_COURSE_ENROLMENT,
                pagePath: "/course-enrolments/",
                history: history,
                paginated: false,

                embedded: true,

                getCustomListRequest: {
                    requestType: COURSE_ENROLMENT_CUSTOM_LIST.BY_STUDENT_ID,
                    params: {
                        studentId: item.id
                    }
                }
            })}
        </div>
    )
}

const Guardians = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentType: OBJECT_TYPE_GUARDIAN,

                    childId: item.id,
                    childType: OBJECT_TYPE_STUDENT,
                    manyToManyChildView: false,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Guardians",
                subTitle: "Student's guardians",
                objectType: OBJECT_TYPE_GUARDIAN,
                pagePath: "/guardians/",
                history: history
            })}
        </div>
    )
}

const SubsidiaryReport = ({ item }) => {
    return <AllSubsidiaryReportView itemId={item.id} itemType={OBJECT_TYPE} />
}


const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                    {
                        title: "Guardians",
                        component: Guardians
                    },
                    {
                        title: "Enrolments",
                        component: Enrolments
                    },
                    {
                        title: "Ledger Report",
                        component: SubsidiaryReport
                    }
                ]
            })}
        </div>
    )
})

export const StudentsDetailPage = Page;
