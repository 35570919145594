import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { OBJECT_TYPE_SUPPLIER } from "../../../constants/ObjectTypes";
import { getAccountRole } from "../../../session/SessionManager";
import { getReportLink } from "../../base/report-page";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"


export const SupplierBalanceReport = {
    // filter: Filter,

    // newLink: '/stock-flow-creator',
    itemToOpenLink: item => getReportLink("LedgerReport", {
        "filters": [
            {
                "property": "ledgerId",
                "operator": "EQ",
                "id": "ledgerId",
                "value": item.accountId
            },
            {
                "property": "subsidiaryId",
                "operator": "EQ",
                "id": "subsidiaryId",
                "value": item.supplierId
            }
        ],
        "selectedAccountLedger": {
            "id": item.accountId,
            "name": "Accounts Payable",
            "group": false,
            "items": [],
            "parentId": 0,
            "parentName": null,
            "type": 0,
            "balance": null,
            "groupTotal": null,
            "accountMode": 0,
            "aliasId": false,
            "accountIsParent": false,
            "balanceTags": null
        },
        "selectedSubsidiaryAccountLedger": {
            "id": item.supplierId,
            "name": item.name,
            "group": false,
            "items": null,
            "parentId": OBJECT_TYPE_SUPPLIER,
            "parentName": null,
            "type": 0,
            "balance": null,
            "groupTotal": null,
            "accountMode": 0,
            "aliasId": false,
            "accountIsParent": false,
            "balanceTags": null
        }
    }),

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
    },

    onAction: (action, data, history) => {
        switch (action) {
            case "name":
                window.open("/suppliers/" + data.supplierId, "_blank")
                return;
            case "ledgerReport":
                history.push(SupplierBalanceReport.itemToOpenLink(data))
                return;
        }
    },
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
