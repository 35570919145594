import { useState } from "react";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";


function exportSeatTypesForApi(seatTypes) {
    return Object.entries(seatTypes).filter(entry => entry[1]?.enabled === true).map(entry => {
        const [row, col] = entry[0].split(",");
        return {
            seatRow: row,
            seatCol: col,
            seatTypeId: entry[1].type?.id
        }
    })
}


export function useSeatDesigner(seatPlanId, grid) {
    const [saving, setSaving] = useState(false);
    const onSaveBtn = () => {
        if (saving) {
            return;
        }

        setSaving(true);
        const { rowCount, colCount, seatTypes } = grid.raw
        Api.saveSeatPlanDesign(seatPlanId, {
            rowCount, colCount,
            seats: exportSeatTypesForApi(seatTypes)
        }, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
            } else {
                UIUtil.showError(response.message)
            }
            setSaving(false);
        })
    }

    return {
        saving, onSaveBtn
    }
}