import { useMemo } from "react"
import { MinimalTable } from "../../../components/minimal-table"
import Util from "../../../util/Util"
import Button from "../../../components/Button"
import { CheckmarkFilled16, ErrorFilled16, Reset16, ArrowRight16 } from '@carbon/icons-react'
import useApi from "../../../hooks/useApi"
import Api from "../../../session/Api"
import UIUtil from "../../../util/UIUtil"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { listDetailItemId } from "../../base/report-page"


const ValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{Util.isStringExists(children) ? children : '-'}</p>
    </div>
)

const Group = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

const COLUMNS = [
    {
        name: "item",
        title: "Item",
        flex: 2,
        renderCell: item => !item.aggregate ? item.itemName : undefined
    },
    {
        name: "class",
        title: "Class",
        flex: 2,
        renderCell: item => !item.aggregate ? item?.tags?.map($ => $.name).join(", ") : undefined
    },
    {
        name: "rate",
        title: "Cost",
        flex: 1,
        renderCell: item => !item.aggregate ? item.rate : undefined
    },
    {
        name: "qty",
        title: "Qty",
        flex: 1,
        renderCell: item => !item.aggregate ? item.qty : undefined
    },
    {
        name: "amount",
        title: "Amount",
        flex: 2,
        renderCell: item => !item.aggregate ? item.gross : undefined
    },
    {
        name: "tax",
        title: "Tax",
        flex: 2,
        renderCell: item => !item.aggregate ? item.tax : undefined
    },
    {
        name: "total",
        title: "Total",
        flex: 2,
        renderCell: item => !item.aggregate ? item.total : item.aggregate.total
    },
]

export function DetailView({ data, onUpdate }) {
    const items = useMemo(() => {
        const list = data.items.filter($ => !$.hidden);
        return [
            ...list,
            {
                id: "total", aggregate: {
                    total: data.amount
                }
            }
        ];
    }, [data])

    const [revoking, revoke] = useApi(listener => Api.listDetailSaveData("PurchaseOrderList", { ...data, status: "Revoked" }, listener), onUpdate);
    const [restoring, restore] = useApi(listener => Api.listDetailSaveData("PurchaseOrderList", { ...data, status: "Awaiting" }, listener), onUpdate);
    const [confirming, confirm] = useApi(listener => Api.listDetailSaveData("PurchaseOrderList", { ...data, status: "Confirmed" }, listener), onUpdate);

    return (
        <div style={{ paddingInline: '3rem' }}>
            {data.status === "Confirmed" ? (<>
                <div style={{ borderRadius: 10, border: '1px solid #00000020', background: '#00990010', display: 'flex', alignItems: 'center', padding: '1rem', gap: '0.5rem', marginBottom: '3rem', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <div style={{ flex: 1 }}>
                        <h4>Confirmed PO</h4>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Bill Created</p>
                    </div>
                    <Link to={listDetailItemId("V2BillList", data.billId)}>
                        <Button loading={restoring} renderIcon={ArrowRight16} style={{ borderRadius: 5, }}>View Bill</Button>
                    </Link>
                </div>
            </>) :
                data.status === "Revoked" ? (<>
                    <div style={{ borderRadius: 10, border: '1px solid #00000020', background: '#99000010', display: 'flex', alignItems: 'center', padding: '1rem', gap: '0.5rem', marginBottom: '3rem', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                        <div style={{ flex: 1 }}>
                            <h4>Revoked/Cancelled</h4>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Restore PO?</p>
                        </div>
                        <Button loading={restoring} onClick={restore} renderIcon={Reset16} style={{ borderRadius: 5, }}>Restore</Button>
                    </div>
                </>) : (<>
                    <div style={{ borderRadius: 10, border: '1px solid #00000020', display: 'flex', alignItems: 'center', padding: '1rem', gap: '0.5rem', marginBottom: '3rem', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                        <div style={{ flex: 1 }}>
                            <h4>Awaiting Confirmation</h4>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Confirm to create bill</p>
                        </div>
                        <Button disabled={confirming} loading={revoking} onClick={revoke} renderIcon={ErrorFilled16} kind="danger--ghost" style={{ borderRadius: 5, }}>Revoke</Button>
                        <Button disabled={revoking} loading={confirming} onClick={() => UIUtil.confirm(confirm)} renderIcon={CheckmarkFilled16} style={{ borderRadius: 5, }} className="green-button">Confirm PO</Button>
                    </div>
                </>)}

            <Group title="Bill Document">
                <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                    <ValueField title="Voucher No" style={{ flex: 1 }}>
                        {data.voucherNo}
                    </ValueField>
                    <ValueField title="Bill Date" style={{ flex: 1 }}>
                        {Util.getDateOnly(data.billDate)}
                    </ValueField>
                    <ValueField title="Due Date" style={{ flex: 1 }}>
                        {Util.getDateOnly(data.dueDate)}
                    </ValueField>
                </div>
                <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                    <ValueField title="Name" style={{ flex: 1 }}>
                        {data.billFromName}
                    </ValueField>
                    <ValueField title="TRN" style={{ flex: 1 }}>
                        {data.billFromTrn}
                    </ValueField>
                    <ValueField title="Address" style={{ flex: 1 }}>
                        {data.billFromAddress}
                    </ValueField>
                </div>
                <ValueField title="Memo">
                    {data.memo}
                </ValueField>
            </Group>

            <MinimalTable
                large items={items}
                columns={COLUMNS}
            />
        </div>
    )
}