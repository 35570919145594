import { useHistory } from "react-router-dom"
import ErrorPage from "../../components/page-messages/ErrorPage"
import { useLocationQuery } from "../../hooks/useLocationQuery"
import Util from "../../util/Util"


export function SearchTelPage() {
    const { tel } = useLocationQuery()
    const history = useHistory()

    if (Util.isStringExists(tel)) {
        history.replace("/deliverables", { defaultPhoneNumber: tel })
        return <div />
    } else {
        return <ErrorPage error404 />
    }
}