

import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { getObjectTypeUrl, OBJECT_TYPE_COUPON_PROMOTION, OBJECT_TYPE_COUPON, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_CUSTOMER_GROUP, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';

import { withLoadablePageWithParams } from '../../base/Page';


const OBJECT_TYPE = OBJECT_TYPE_COUPON_PROMOTION;


const CustomerRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_CUSTOMER,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Customers",
                subTitle: "Restricted customers",
                objectType: OBJECT_TYPE_CUSTOMER,
                pagePath: "/customers/",
                history: history
            })}
        </div>
    )
}

const CustomerGroupRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_CUSTOMER_GROUP,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Customer Groups",
                subTitle: "Restricted customer groups",
                objectType: OBJECT_TYPE_CUSTOMER_GROUP,
                pagePath: "/customer-groups/",
                history: history
            })}
        </div>
    )
}

const ProductRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Products",
                subTitle: "Restricted products",
                objectType: OBJECT_TYPE_PRODUCT,
                paginated: true,
                pagePath: "/products/",
                history: history
            })}
        </div>
    )
}

const BundleRestrictedList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_COUPON,

                    childType: OBJECT_TYPE_PRODUCT_BUNDLE,
                    manyToManyChildView: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Bundles",
                subTitle: "Restricted bundles",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                pagePath: "/bundles/",
                history: history
            })}
        </div>
    )
}




const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                // customTabs: [
                //     ...(itemResult.item.restricted ? [
                //         {
                //             title: "Customers",
                //             component: CustomerRestrictedList
                //         },
                //         {
                //             title: "Groups",
                //             component: CustomerGroupRestrictedList
                //         }
                //     ] : []),
                //     ...(itemResult.item.restrictedProducts ? [
                //         {
                //             title: "Products",
                //             component: ProductRestrictedList
                //         },
                //         {
                //             title: "Bundles",
                //             component: BundleRestrictedList
                //         }
                //     ] : []),
                // ],
            })}
        </div>
    )
})

export const CouponPromotionDetailPage = Page;
