import { useEffect, useMemo, useState } from "react";
import Button from "../../../../components/Button";
import ItemCreatorDialog from "../../../../templates/ItemCreatorDialog";
import Portal from "../../../../util/Portal";
import { Add16, ChevronDown16, Close16 } from '@carbon/icons-react'
import { OBJECT_TYPE_CUSTOMER } from "../../../../constants/ObjectTypes";
import UIUtil from "../../../../util/UIUtil";
import { TextInput } from "carbon-components-react";
import { CustomerSearchDialog } from "../../../pos/dialogs/customer-search-dialog";

const CUSTOMER_NAME_CACHE = {}

export function CustomerPicker({ value, setValue, fnbEvent }) {
    const [createDialogVisible, setCreateDialogVisible] = useState(false);
    const [showPicker, setShowPicker] = useState(false)

    const customerName = useMemo(() => {
        if (value === fnbEvent.customerId) {
            return fnbEvent.customerName
        } else {
            return CUSTOMER_NAME_CACHE[value] ?? 'Customer (ID: ' + value + ')';
        }
    }, [value, fnbEvent])

    return (<>
        <label className="bx--label">Customer</label>
        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
            <div style={{ flex: 1, height: 40 }}>
                <div onClick={() => setShowPicker(true)} className={"bx--text-input"} style={{ position: 'relative', height: '100%', height: '100%', background: '#f4f4f4', display: 'flex', alignItems: 'center', }}>
                    <input style={{ all: 'unset', fontSize: 14, flex: 1, }} value={value ? customerName : ''} placeholder="Select customer..."></input>

                    {value > 0 && (
                        <div onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            setValue(0)
                        }} style={{ height: '100%', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <Close16 />
                        </div>
                    )}

                    <div
                        style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <ChevronDown16 />
                    </div>
                </div>
            </div>
            <div style={{}}>
                <Button renderIcon={Add16} size="md" hasIconOnly iconDescription="New" onClick={() => setCreateDialogVisible(true)} />
            </div>

            <Portal>
                <ItemCreatorDialog
                    hideThumbnail
                    objectType={OBJECT_TYPE_CUSTOMER}
                    open={createDialogVisible} onClose={() => setCreateDialogVisible(false)}
                    onCreatedItem={customer => {
                        CUSTOMER_NAME_CACHE[customer.id] = customer.fullName;
                        setValue(customer.id)

                        UIUtil.showSuccess();
                        setCreateDialogVisible(false);
                    }} />
            </Portal>

            <Portal>
                <CustomerSearchDialog focus onClose={() => setShowPicker(false)} visible={showPicker} onCustomerSelected={customer => {
                    CUSTOMER_NAME_CACHE[customer.id] = customer.fullName;
                    setValue(customer.id)
                }} />
            </Portal>
        </div>
    </>)
}