import React from 'react'
import Page from '../../../base/Page';

import Button from '../../../components/Button';
import Api from '../../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16,
    FlowStream16,
    ChartBullet16,
    Currency16,
    GraphicalDataFlow16,
    ArrowRight16
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, Link, RadioTile, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar,
    Tag
} from 'carbon-components-react';

import Util from '../../../util/Util';
import UIUtil from '../../../util/UIUtil';
import StockFlowField from '../../stock-flow/StockFlowField';
import { DESTINATION_TYPE_STORE, DESTINATION_TYPE_WAREHOUSE } from '../../../constants/Constants';
import ProfilePic from '../../../components/ProfilePic';
import { getObjectTypeName, getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_VENUE, OBJECT_TYPE_WAREHOUSE } from '../../../constants/ObjectTypes';
import ProductField from '../../../views/product/ProductField';
import { withRouter, Link as LK } from 'react-router-dom';
import { hasCapabilitySupport } from '../../../app/Capabilities';

// const HEADERS = [
//     {
//         header: "Journal Entry Voucher No",
//         key: "journalEntryId"
//     },
//     {
//         header: "Subsidiary Account",
//         key: "subsidiaryType"
//     },
//     {
//         header: "Subsidiary Name",
//         key: "subsidiaryAccountName"
//     },
//     {
//         header: "Date",
//         key: "date"
//     },
//     {
//         header: "Debit",
//         key: "debitAmount"
//     },
//     {
//         header: "Credit",
//         key: "creditAmount",
//     }
// ]

const headers = (hasMoreThanOneAccount) => {
    if (hasMoreThanOneAccount) {
        return [
            {
                header: "Voucher No",
                key: "journalEntryId"
            },
            {
                header: "Voucher Memo",
                key: "memo"
            },
            {
                header: "Account Name",
                key: "accountName"
            },
            {
                header: "Subsidiary Account",
                key: "subsidiaryType"
            },
            {
                header: "Subsidiary Name",
                key: "subsidiaryAccountName"
            },
            {
                header: "Date",
                key: "date"
            },
            {
                header: "Debit",
                key: "debitAmount"
            },
            {
                header: "Credit",
                key: "creditAmount",
            },
            {
                header: "Balance",
                key: "balanceAmount",
            },
            {
                header: "Action",
                key: "action"
            }
        ]
    } else {
        return [
            {
                header: "Voucher No",
                key: "journalEntryId"
            },
            {
                header: "Voucher Memo",
                key: "memo"
            },
            {
                header: "Subsidiary Account",
                key: "subsidiaryType"
            },
            {
                header: "Subsidiary Name",
                key: "subsidiaryAccountName"
            },
            {
                header: "Date",
                key: "date"
            },
            {
                header: "Debit",
                key: "debitAmount"
            },
            {
                header: "Credit",
                key: "creditAmount",
            },
            {
                header: "Balance",
                key: "balanceAmount",
            },
            {
                header: "Action",
                key: "action"
            }
        ]
    }
}

class SubsidiaryLedgerResultPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            report: undefined
        }
    }

    get headers() {
        const arr = headers(this.state.report.hasMoreThanOneAccount);
        if (this.props.hideSubsidiary) {
            return arr.filter($ => $.key !== "subsidiaryType" && $.key !== "subsidiaryAccountName");
        } else {
            return arr;
        }
    }

    onPageStart() {
        if (this.props.report) {
            this.callPageApi(listener => listener({ status: true, payload: this.props.report }), report => ({ report }))
        } else {
            this.callPageApi(listener => Api.getSubsidiaryLedgerReport(
                this.getPathParams().accountId,
                this.getPathParams().subsidiaryId,
                this.getPathParams().subsidiaryType,
                listener), report => ({ report }))
        }
    }

    getAccountId() {
        return;
    }

    getCellHeader(cell) {
        for (const header of this.headers) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);
        const item = row.entry;

        switch (header.key) {
            // case "name":
            //     return (
            //         <div style={{display: 'flex', alignItems: 'center'}}>
            //             <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, row.id)} />
            //             <p style={{marginLeft: '0.5rem'}}>{cell.value}</p>
            //         </div>
            //     )

            case "journalEntryId":
                if (hasCapabilitySupport("shipping") && Util.isStringExists(row.voucherNo)) {
                    return row.voucherNo;
                }
                return Util.getVoucherNumber(cell.value)

            case "subsidiaryType":
                return cell.value == -1 ? "-" : getObjectTypeName(cell.value)

            case "objectType":
                if (cell.value == OBJECT_TYPE_WAREHOUSE) {
                    return "Warehouse"
                } else if (cell.value == OBJECT_TYPE_STORE) {
                    return "Store"
                } else if (cell.value == OBJECT_TYPE_VENUE) {
                    return "Venue"
                } else if (cell.value == OBJECT_TYPE_MOBILE_UNIT) {
                    return "Mobile Unit"
                } else {
                    return "-";
                }

            case "debitAmount": case "creditAmount":
                if ((row.debit && header.key == "debitAmount") || (!row.debit && header.key == "creditAmount")) {
                    //return <> AED {parseFloat(row.amount).toFixed(2)} </>
                    return <> AED {Util.formatMoney(row.amount)} </>
                } else {
                    return null;
                }

            case "balanceAmount":
                return <> AED {Util.formatMoney(parseFloat(row.balance))} </>

            case "date":
                return <> <Calendar16 /> {Util.getDateOnly(cell.value)} </>
            // return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            case "action":
                return (<>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.5rem', paddingRight: '0.5rem' }}>


                        {Util.isNumberExist(item.stockFlowId) &&
                            <LK target={"_blank"} to={'/stock-flow/' + item.stockFlowId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <FlowStream16 />
                                </button>
                            </LK>}
                        {Util.isNumberExist(item.billId) &&
                            <LK target={"_blank"} to={'/bill/' + item.billId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <ChartBullet16 />
                                </button>
                            </LK>}
                        {Util.isNumberExist(item.transactionId) &&
                            <LK target={"_blank"} to={'/transaction/' + item.transactionId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <Currency16 />
                                </button>
                            </LK>}
                        {Util.isNumberExist(item.serviceSaleId) &&
                            <LK target={"_blank"} to={'/service-sale/' + item.serviceSaleId}>
                                <button className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                                    <GraphicalDataFlow16 />
                                </button>
                            </LK>}

                        {!item.systemGenerated && <Tag size="sm">User defined</Tag>}

                        <LK target={"_blank"} to={'/journal-entry/' + row.journalEntryId}>
                            <button className='lead-flow-icon-button lead-flow-icon-button-primary'>
                                <ArrowRight16 />
                            </button>
                        </LK>
                    </div>
                </>)

            default:
                return cell.value;
        }
    }

    getLayout() {
        // return JSON.stringify(this.state.report)

        return (
            <div className="main-content">
                {!this.props.report && <div onClick={() => this.props.history.goBack()} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                    <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another report</Link>
                </div>}
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2 }}>
                    {!this.props.report && <h2>{this.state.report.subsidiaryAccountName}</h2>}

                    {!this.props.report && <h3>{this.state.report.accountName}</h3>}

                    <h4>Balance: AED {Util.formatMoney(this.state.report.balance)}</h4>
                    {/* <h6></h6> */}

                    {/* {this.state.report !== undefined && this.state.report.values !== undefined &&
                        <h4 style={{color: 'green'}}>Total Stock: <DeliveryParcel24 /> {this.state.report.values.totalStock}</h4>}
                    {this.state.report !== undefined && this.state.report.values !== undefined &&
                        <h6 style={{color: 'green'}}>Total Value: AED {this.state.report.values.totalValue.toFixed(2)}</h6>}
                    
                    <div style={{display: 'flex', alignItems: 'center', opacity: 0.65, marginTop: '0.25rem'}}>
                        <p>{this.getLocationTypeName()}</p>
                    </div> */}
                </div>
                {/* <TransactionListView report={this.state.report} hideToolbar /> */}

                <DataTable rows={this.state.report.items} headers={this.headers} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => {
                                            const item = this.getRow(row.id);
                                            // this.props.history.push("/journal-entry/" + item.journalEntryId);
                                        })} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>
            </div>
        )
    }

}

export default withRouter(SubsidiaryLedgerResultPage);