import { addDays, startOfDay } from "date-fns";
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { DateTimeRange } from "../../components/basic-filter/date-time-range";
import { Divider } from "../../components/basic-filter/divider";
import { useEffect } from "react";
import { MultiSelect } from "../../components/basic-filter/multi-select";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";

export const SalesOrderReport = {
    filter: Filter,

    isAvailable: () => {
        if (hasCapabilitySupport("restaurant")) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const DATE_BY_OPTS = [
    "Sales/Order Date",
    "Order Date Only",
    "Sales Date Only",
]

const RECEIPTS_IN_RANGE_ONLY = {
    skipAutoFilter: true,
    value: true
}

const STATUSES = [
    "Revoked",
    "Awaiting Confirmation",
    "Awaiting Preparation",
    "Awaiting Delivery",
    "Out for Delivery",
    "Delivered",
    "Processing Rental",
    "Awaiting pickup",
    "Out for pickup",
    "Picked up",
    "Awaiting Rental Inspection",
    "Finalized",
    "Awaiting Payment Collection",
    "Out for Payment Collection",
    "Preparing",
    "Serving",
    "Awaiting Finalization",
    "Packing",
]

function Filter({ endpoint, state }) {
    const [filters, setFilters] = state.use("filters", [])
    useEffect(() => {
        if (!filters.length) {
            setFilters([
                { id: "date-start", skipAutoFilter: true, property: "date", operator: "GTE", value: startOfDay(new Date()).getTime() },
                { id: "date-end", skipAutoFilter: true, property: "date", operator: "LT", value: startOfDay(addDays(new Date(), 1)).getTime() },
            ])
        }
    }, [])
    return (<>
        <DateTimeRange state={state} property="date" skipAutoFilter />
        <MultiSelect skipAutoFilter isMulti={false} placeholder="Sales/Order Date" fieldKey={"dateBy"} property="dateBy" options={DATE_BY_OPTS} state={state} />
        <CheckboxFilter state={state} filterId="receiptsInRange" label="Receipts in range only?" filter={RECEIPTS_IN_RANGE_ONLY} />

        <Divider />

        <div style={{ height: 30, width: 250 }}>
            <MultiSelect isMulti={false} placeholder="Store" fieldKey="store_name" options={endpoint.stores.map(item => item.value)} state={state} property="store_name" />
        </div>
        <div style={{ height: 30, width: 250 }}>
            <MultiSelect isMulti={false} placeholder="Status" fieldKey="status_name" options={STATUSES} state={state} property="status_name" />
        </div>

    </>)
}
