import { User24, Add16, Search16, ErrorFilled16, VoiceActivate16, UserRole16, Identification16, InformationSquareFilled16, Launch16 } from '@carbon/icons-react'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import ProfilePic from '../../../components/ProfilePic'
import Button from '../../../components/Button'
import Util from '../../../util/Util'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import { TextInput } from 'carbon-components-react'
import useSize from '../../../util/useSize'
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { getTerminal } from '../../../session/SessionManager'
import { CustomerSearchDialog } from '../dialogs/customer-search-dialog'
import Portal from '../../../util/Portal'
import { SidePanel } from '../../../templates/draft/components/side-panel'
import CustomerDetailPage from '../../customer/CustomerDetailPage'


const HEIGHT_MIN = 80;
const HEIGHT_MID = 136;

const OPTIMAL_WIDTH = 452;
const OPTIMAL_HEIGHT = 180;

const Content = ({ size, state, light, hideTitle, hasCustomer, searchValue, setSearchValue, searchingCustomer, hidePosBtn, forOrder, setSearchingCustomer, removingCustomer, setRemovingCustomer, onRemoveCustomerBtn, mini }) => {
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);

    const [openingCustomerQrCode, setOpeningCustomerQrCode] = useState(false);
    const [closingCustomerQrCode, setClosingCustomerQrCode] = useState(false);
    const [openingCustomerSearch, setOpeningCustomerSearch] = useState(false);

    const [searchVisible, setSearchVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const setCustomer = customer => {
        if (forOrder) {
            setLoading(true);
            state.api.setPosCustomerForSalesOrder(state.salesOrderId, customer.id, response => {
                setLoading(false);
                if (response.status === true) {
                    const { a: session, b: order } = response.payload;

                    state.onUpdateSession(session)
                    state.updateOrder?.(order);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        } else {
            setLoading(true)
            state.api.setPosCustomer(customer.id, response => {
                setLoading(false);
                if (response.status === true) {
                    state.onUpdateSession(response.payload)
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        }
    }

    const values = useMemo(() => {
        let data = [];
        const addToData = (key) => {
            if (state.getSession()?.[key]) {
                data.push(state.getSession()[key]);
            }
        }

        addToData('customerPhoneNumber')
        addToData('customerAltPhoneNumber')

        return data.join('  •  ');
    }, [state.getSession()])

    return (<>
        <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem' }}>
            {/* <ProfilePic size={45} style={{marginRight: '1rem'}} /> */}
            <User24 />
            <div style={{ marginLeft: '0.25rem', flex: 1 }}>
                <div style={(mini && size.height < HEIGHT_MIN) ? { flex: 1 } : {}}>
                    <p style={{ fontStyle: 'bold' }}>{hasCustomer ? state.getSession().customerName : 'Customer'}</p>
                    {hasCustomer && state.hasLoyaltyCard() && <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, color: 'green' }}>
                            {state.getSession().customerLoyaltyPoints.toFixed(2)} points
                        </p>
                        <div style={{ width: 4, height: 4, borderRadius: '50%', marginLeft: 5, marginRight: 5, background: 'green' }} />
                        <p style={{ fontSize: 12, color: 'green' }}>
                            AED {state.getSession().customerLoyaltyMoney.toFixed(2)}
                        </p>
                    </div>}
                </div>
                {hasCustomer && !state.hasLoyaltyCard() && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>
                        No loyalty card
                    </p>
                </div>}
                {!hasCustomer && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>
                        Find or create
                    </p>
                </div>}
                {hasCustomer && Util.isStringExists(values) && <p style={{ fontSize: 14, fontWeight: 'bold', marginTop: 5, color: '#4082ff' }}>{values}</p>}
                {hasCustomer && <p style={{ fontSize: 15, fontWeight: 'bold', color: '#50ff40' }}>Credit: AED {Util.formatMoney(state.getSession().customerCurrentCredit)}</p>}
            </div>
            {/* 
            {hasCustomer && <button onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setShowCustomerInfo(true)
            }} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                <InformationSquareFilled16 />
            </button>} */}

            {hasCustomer ? (
                <>
                    <Button loading={removingCustomer} onClick={onRemoveCustomerBtn} kind="danger" className="bx--tooltip--hidden" renderIcon={ErrorFilled16} hasIconOnly data-tip="Remove" />

                    {!hidePosBtn && <><Button
                        loading={openingCustomerQrCode}
                        onClick={() => {
                            setOpeningCustomerQrCode(true)
                            state.api.openCustomerQrCodeDialog(getTerminal().id, response => {
                                setOpeningCustomerQrCode(false);
                                if (response.status === true) {
                                    UIUtil.showSuccess();
                                } else {
                                    UIUtil.showError(response.payload)
                                }
                            })
                        }}
                        style={{ height: 40 }}
                        className="bx--tooltip--hidden"
                        kind="secondary"
                        renderIcon={Identification16} hasIconOnly data-tip="Show Card" />
                        <Button
                            loading={closingCustomerQrCode}
                            onClick={() => {
                                setClosingCustomerQrCode(true)
                                state.api.closeCustomerQrCodeDialog(getTerminal().id, response => {
                                    setClosingCustomerQrCode(false);
                                    if (response.status === true) {
                                        UIUtil.showSuccess();
                                    } else {
                                        UIUtil.showError(response.payload)
                                    }
                                })
                            }}
                            style={{ height: 40 }}
                            className="bx--tooltip--hidden"
                            kind="secondary"
                            renderIcon={UserRole16} hasIconOnly data-tip="Close Card" /></>}


                    <Button
                        onClick={() => setShowCustomerInfo(true)}
                        style={{ height: 40 }}
                        className="bx--tooltip--hidden"
                        renderIcon={InformationSquareFilled16} hasIconOnly data-tip="Information" />
                </>
            ) : (<>
                <Button onClick={() => setSearchVisible(true)}
                    loading={loading}
                    style={{ height: 40 }} kind="secondary"
                    renderIcon={Search16}>Search</Button>

                {!hidePosBtn && <Button onClick={() => state.setShowCustomerCreatorDialog(true)}
                    disabled={searchingCustomer}
                    loading={state.isCreatingCustomerLoading()}
                    style={{ height: 40 }}
                    className="bx--tooltip--hidden"
                    renderIcon={Add16} hasIconOnly data-tip="Create Customer" />}
            </>)}


        </div>

        <CustomerSearchDialog visible={searchVisible} onClose={() => setSearchVisible(false)} onCustomerSelected={setCustomer} />

        {showCustomerInfo && <Portal>
            <SidePanel fullscreen md2 onClose={() => setShowCustomerInfo(false)}>
                <CustomerDetailPage pagePathParamsOverride={{ itemId: state.getSession().customerId }} overrideBacktToListBtn={() => setShowCustomerInfo(false)} />
            </SidePanel>
        </Portal>}


    </>)
}


export default ({ state, light, hideTitle, responsive, hidePosBtn, forOrder }) => {
    const hasCustomer = Util.isNumberExist(state.getSession().customerId);
    const [searchValue, setSearchValue] = useState("");

    const [searchingCustomer, setSearchingCustomer] = useState(false);
    const [removingCustomer, setRemovingCustomer] = useState(false);

    useEffect(() => {
        if (hasCustomer && Util.isStringExists(searchValue)) {
            setSearchValue("");
        }
    }, [hasCustomer])

    const onRemoveCustomerBtn = () => {
        UIUtil.confirm(() => {
            if (forOrder) {
                setRemovingCustomer(true)
                state.api.clearPosCustomerForSalesOrder(state.salesOrderId, response => {
                    setRemovingCustomer(false);
                    if (response.status === true) {
                        const { a: session, b: order } = response.payload;

                        state.onUpdateSession(session)
                        state.updateOrder?.(order);
                        UIUtil.showSuccess();
                    } else {
                        UIUtil.showError(response.message);
                    }
                })
            } else {
                setRemovingCustomer(true)
                state.api.clearPosCustomer(response => {
                    setRemovingCustomer(false);
                    if (response.status === true) {
                        //state.onCustomerUpdate()
                        state.onUpdateSession(response.payload)
                        UIUtil.showSuccess();
                    } else {
                        UIUtil.showError(response.message);
                    }
                })
            }
        })
    }


    const targetRef = useRef();
    const size = useSize(targetRef)
    const content = <Content {...({ size: responsive ? size : { height: 10000, width: 10000 }, state, light, hideTitle, hidePosBtn, forOrder, hasCustomer, searchValue, setSearchValue, searchingCustomer, setSearchingCustomer, removingCustomer, setRemovingCustomer, onRemoveCustomerBtn })} />

    const [contentOpen, setContentOpen] = useState(false)

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [size])

    return (
        <div ref={targetRef} style={{ width: '100%', height: '100%', padding: '0rem', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            {responsive ? (
                size.height > HEIGHT_MIN ? (
                    content
                ) : (<>
                    {React.cloneElement(content, { mini: () => setContentOpen(true) })}

                    <Modal
                        isOpen={contentOpen}
                        onRequestClose={() => setContentOpen(false)}
                        style={{
                            overlay: { zIndex: 8999, background: 'rgba(0,0,0,0.75)' },
                            content: {
                                height: OPTIMAL_HEIGHT, width: OPTIMAL_WIDTH, background: 'rgb(244, 244, 244)', border: 'none',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex', flexDirection: 'column', borderRadius: 0
                            }
                        }}
                    >
                        {React.cloneElement(content, { size: { height: 10000, width: 10000 } })}
                    </Modal>
                </>)
            ) : (
                content
            )}

        </div>
    )
}