import React from "react";


export default ({ icon, title, subtitle, children }) => (
    <div className="" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginLeft: '0.5rem', marginRight: '0.5rem', width: 45, display: 'flex', justifyContent: 'center' }}>
            {React.createElement(icon)}
        </div>
        <div>
            <h5 style={{ fontSize: 16, }}>{title}</h5>
            {subtitle && <p style={{ fontSize: 12, opacity: 0.65 }}>{subtitle}</p>}
        </div>


        <div style={{ display: 'flex', flex: 1, marginLeft: 5, gap: 5, justifyContent: 'flex-end', alignItems: 'center' }}>
            {children}
        </div>
    </div>
)