import { FormSection } from "../../../templates/form/form";
import { Link } from 'react-router-dom'
import Util from "../../../util/Util";
import StockFlowItemPage from "../../../pages/stock-flow/StockFlowItemPage";
import { ArrowRight16 } from '@carbon/icons-react'
import { OBJECT_TYPE_PRODUCTION } from "../../../constants/ObjectTypes";
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view";


export function ProductionViewPage({ production }) {

    return (
        <div>
            <FormSection title="Production Details">
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '0.5rem' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '0.5rem' }}>
                        <div>
                            <h6>Voucher No</h6>
                            <p>{Util.getVoucherNumber(production.id)}</p>
                        </div>
                        <div>
                            <h6>Date</h6>
                            <p>{Util.getDateOnly(production.date)}</p>
                        </div>
                        <div />

                        <div>
                            <h6>Bundle</h6>
                            <Link to={`/product-bundles/${production.bundleId}`} target="_blank">
                                <p>{production.bundleName}</p>
                            </Link>
                        </div>
                        <div>
                            <h6>Quantity</h6>
                            <p>{Util.formatAmount(production.quantity)} {production.bundleUom}</p>
                        </div>
                        <div />

                        <div>
                            <h6>Inventory Flow</h6>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '3rem' }}>
                                <div>
                                    <StockFlowItemPage
                                        forceStockFlow={production.usedStockFlow}
                                        renderSourceCardOnly
                                    />
                                </div>
                                <ArrowRight16 />
                                <div>
                                    <StockFlowItemPage
                                        forceStockFlow={production.producedStockFlow}
                                        renderDestinationCardOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6>Memo</h6>
                        <p>{production.memo || '-'}</p>
                    </div>
                </div>
            </FormSection>
            <FormSection title="Material Usage">
                <div>
                    <StockFlowItemPage
                        forceStockFlow={production.usedStockFlow}
                        renderItemsOnly
                    />
                </div>
            </FormSection>
            <FormSection title="Production Output">
                <div>
                    <StockFlowItemPage
                        forceStockFlow={production.producedStockFlow}
                        renderItemsOnly
                    />
                </div>
            </FormSection>
            <FormSection title="Record Info">
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '1rem' }}>
                    <div>
                        <h6>Created by</h6>
                        <Link to={`/staff-users/${production.usedStockFlow.initiatorId}`} target="_blank">
                            <p>{production.usedStockFlow.initiatorFullName}</p>
                        </Link>
                    </div>
                    <div>
                        <h6>Posted/Recorded Date</h6>
                        <p>{Util.getFullDate(production.creationDate)}</p>
                    </div>
                </div>
            </FormSection>
            <div style={{ marginTop: '3rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa', border: '1px solid #00000020' }}>
                <AdvancedNotesView title="Notes/Attachments" objectId={production.id} objectType={OBJECT_TYPE_PRODUCTION} />
            </div>
        </div>
    )
}