import React, { useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { ComponentTypes } from '../engine/ComponentTypes'
import setStyleSize from '../util/setStyleSize'

import { Resizable } from "re-resizable";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useRefSize } from '../../../util/useSize';

const ContentView = ({ engine, onResizeStart, onResizeStop, parentSize }) => {
    const canvasRef = useRef();

    const sizeState = { width: engine.getState().canvasWidth, height: engine.getState().canvasHeight };
    const setSizeState = size => engine.setState({ canvasWidth: size.width, canvasHeight: size.height }, () => engine.onResize());

    const [{ isOver }, drop] = useDrop(() => ({
        accept: [ComponentTypes.TEXT, ComponentTypes.QR_CODE, ComponentTypes.BARCODE, ComponentTypes.IMAGE, ComponentTypes.LINE, ComponentTypes.SQUARE, ComponentTypes.CIRCLE,],
        drop: (_, monitor) => {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const dropOffset = monitor.getSourceClientOffset();

            const left = dropOffset.x - canvasRect.x;
            const top = dropOffset.y - canvasRect.y;

            engine.addComponent(monitor.getItemType(), { left, top })
        },
        collect: monitor => ({ isOver: !!monitor.isOver() }),
    }))

    useEffect(() => {
        if (canvasRef.current) {
            engine.createCanvasManager(canvasRef.current);
        }
    }, [canvasRef.current])


    const x = parentSize.width / 2;
    const y = parentSize.height / 2;


    return (<>
        <Resizable
            style={{
                position: 'absolute',

                left: Math.max(x - ((sizeState.width * (engine.getZoom() / 100)) / 2), 15),
                top: Math.max(y - ((sizeState.height * (engine.getZoom() / 100)) / 2), 15),


                transformOrigin: 'top left',
                transform: `scale(${(engine.get("zoom") ?? 100) / 100})`,
            }}
            minWidth={50}
            minHeight={50}
            onResize={(_, __, ref) => engine.onResize({ width: ref.clientWidth, height: ref.clientHeight })}
            onResizeStart={onResizeStart}
            onResizeStop={(_, __, ___, sizeDelta) => {
                const newSize = { width: sizeState.width + sizeDelta.width, height: sizeState.height + sizeDelta.height };
                const startSize = Object.assign({}, sizeState);
                const endSize = Object.assign({}, newSize);

                setSizeState(newSize);
                engine.recordHistoryAction({
                    run: () => setSizeState(endSize),
                    reverse: () => setSizeState(startSize)
                })

                if (onResizeStop) {
                    onResizeStop();
                }
            }}
            size={sizeState}>
            <div key="label-designer-canvas" ref={drop} className={`label-designer-canvas-content ${isOver ? 'label-designer-canvas-content-highlighted' : undefined}`}>
                <div style={{ ...setStyleSize(sizeState.width, sizeState.height) }} className={engine.get('showCanvasGrid') && 'label-designer-canvas-content-grid'}>
                    <canvas id="label-designer-raw-canvas" ref={canvasRef} width={sizeState.width} height={sizeState.height} />
                </div>
            </div>
        </Resizable>
    </>)
}

export default ({ engine }) => {
    const [disableZoomPan, setDisableZoomPan] = useState(false);
    const [ref, size] = useRefSize()

    return (
        <div ref={ref} className={`label-designer-canvas-root ${engine.get('showBackgroundGrid') ? 'label-designer-canvas-root-grid-background' : undefined}`}>
            <ContentView engine={engine} parentSize={size} />

            {/* <div style={{ position: 'relative', left: 0, top: 0, width: '100%', height: '100%', overflow: 'visible', paddingLeft: engine.getState().canvasWidth * (engine.getZoom() / 100), paddingTop: engine.getState().canvasHeight * (engine.getZoom() / 100) }}>
                <ContentView engine={engine} />
            </div>

            <div style={{ position: 'absolute', left: 0, top: 0, width: engine.getState().canvasWidth * (engine.getZoom() / 100), height: engine.getState().canvasHeight * (engine.getZoom() / 100) }} /> */}
        </div>
    )
}