import { useState } from "react";


export default function usePromise(func) {
    const [loading, setLoading] = useState(false);
    return [
        loading,
        async () => {
            setLoading(true);
            try {
                await func();
            } finally {
                setLoading(false);
            }
        },
    ];
}