import React from 'react'
import Page from '../../../base/Page';
import { SectionView } from '../../../components/SectionView';

import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16,
    AirlinePassengerCare32,
    User32,
    DataConnected16,
    Warning16
} from '@carbon/icons-react'
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';
import RangeListEditor from '../../../components/RangeListEditor';
import ProductPriceResult from './ProductPriceResult';
import { ContentSwitcher, Link, Switch, Tile } from 'carbon-components-react';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import Util from '../../../util/Util';

class ProductPriceReport extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingReport: false,
            generatedReport: false,

            report: undefined,

            ranges: [],
            useProductPrice: false,
        }
    }

    clearReport() {
        this.setState({
            generatingReport: false,
            generatedReport: false,

            report: undefined
        })
    }

    canGenerateReport() {
        return this.state.ranges.length > 0;
    }

    onGenerateBtn() {
        for (const range of this.state.ranges) {
            if (!Util.isANumber(range.start) || !Util.isANumber(range.end)) {
                UIUtil.showInfo("All range values need to be actual numbers")
                return;
            }
        }

        this.setState({ generatingReport: true })
        Api.getPricePerformanceReport(this.state.ranges, this.state.useProductPrice, response => {
            if (response.status === true) {
                // console.log(response.payload)
                this.setState({ generatingReport: false, report: response.payload, generatedReport: true, })
                UIUtil.showSuccess();
            } else {
                this.setState({ generatingReport: false, generatedReport: false })
                UIUtil.showError(response.message);
            }
        })
    }

    getPriceModeNote() {
        return (
            <Tile style={{ marginTop: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Warning16 style={{ marginRight: '0.5rem' }} />
                    <h6>Note:</h6>
                </div>
                <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                    {this.state.useProductPrice ? (<>
                        Revenue and profit calculations will use the current product's price. <span style={{ color: 'red' }}>The calculated profit and gross profit margin will not be accurate.</span>
                    </>) : (<>
                        Revenue and profit calculations will use the actual product sales amount, hence the same product can exist in more than one range.
                    </>)}
                </p>
            </Tile>
        )
    }

    getLayout() {
        if (this.state.generatedReport) {
            return (
                <div className="main-content">
                    <div onClick={this.clearReport.bind(this)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                        <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another report</Link>
                    </div>
                    <ProductPriceResult report={this.state.report} />
                    {/* <code><pre>{JSON.stringify(this.state.report, null, 4)}</pre></code> */}
                </div>
            )
        }

        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>Price Performance</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <SectionView icon={Number_132} title="Price Range Comparisons">
                        <RangeListEditor defaultItems={this.state.ranges} onUpdate={ranges => this.setState({ ranges })} />
                    </SectionView>

                    <SectionView icon={Number_232} title="Revenue/Profit Calculations">
                        <ContentSwitcher selectedIndex={this.state.useProductPrice ? 1 : 0} onChange={e => this.setState({ useProductPrice: e.index == 1 })}>
                            <Switch text="Sold For Amount" />
                            <Switch text="Current Product Price" />
                        </ContentSwitcher>
                        {this.getPriceModeNote()}
                    </SectionView>

                    <SectionView icon={Number_332} title="Report" extraTopMargin={false}>
                        <p>Report Note:</p>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Product sales that have been returned will be removed</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.onGenerateBtn.bind(this)} disabled={!this.canGenerateReport()} loading={this.state.generatingReport} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </SectionView>
                </div>
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(ProductPriceReport);