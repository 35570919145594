import './user-assignation.scss'

import { Add16 } from '@carbon/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import Button from '../../../components/Button'
// import useStore from '../../../hooks/useStore'
import { makeObservable } from '../../../util/makeObservable'
import ProfilePic from '../../../components/ProfilePic'
import { Link, Select, SelectItem, Tag, TextInput } from 'carbon-components-react'
import Api from '../../../session/Api'
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR } from '../../../constants/ObjectTypes'
import Util from '../../../util/Util'

function useStore(store, name) {
    return store[name];
}

const ButtonPopover = ({ store, children, popover, closeOnChange }) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useStore(store, "items");

    useEffect(() => {
        if (closeOnChange) {
            setOpen(false);
        }
    }, [items, closeOnChange])

    return (
        <Popover
            isOpen={open}
            positions={['bottom', 'right']}
            // padding={10}
            containerStyle={{ zIndex: 1000000 }}
            align="end"
            onClickOutside={e => {
                // if (!classExistsInSomeParent(e.target, "table-list-filter-save-dialog")) {
                setOpen(false)
                // }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container user-assignation-button-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="user-assignation-button-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        {popover}
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                {children}
            </a>
        </Popover>
    )
}

const Message = ({ value }) => (
    <div>
        <div style={{ minWidth: 300, height: 45, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ fontSize: 12, opacity: 0.65 }}>
                {value}
            </p>
        </div>
    </div>
)


const Item = ({ isActivity, objectType, item, setItems, onRemoveBtn }) => {
    if (isActivity && item?.raw?.predefinedClassInvoices && (item.raw.predefinedClassInvoices?.length ?? 0) > 0) {
        const value = item.againstPredefinedClassServiceSale;
        const setValue = (id) => setItems(items => {
            const newItems = [...items];
            newItems[newItems.indexOf(item)] = {
                ...item,
                againstPredefinedClassServiceSale: id
            }
            return newItems;
        })
        return (
            <div style={{
                width: '100%', height: undefined, borderRadius: 15, border: 1, borderColor: '#00000020', borderStyle: 'solid', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
                marginBottom: '1rem', paddingInline: '1rem', paddingBlock: '0.5rem', gap: '0.5rem',
                animation: typeof item.id === "string" ? 'opacity-fade-in-md-anim 250ms' : undefined,
                background: 'white'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <ProfilePic src={Api.getThumbnail(objectType, item.userId)} size={32} style={{ minWidth: 32 }} />
                    <p style={{ fontSize: 14, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>{item.name}</p>
                    <Link onClick={onRemoveBtn} size="sm" style={{ color: '#990000', cursor: 'pointer' }}>Remove user</Link>
                </div>

                <div style={{ marginTop: '0.25rem' }}>
                    <Select labelText="Against Class Invoice (optional)" value={value} onChange={e => setValue(e.target.value)}>
                        <SelectItem value={0} text="No pre-existing invoice" />
                        {item.raw.predefinedClassInvoices.map(option => <SelectItem key={option.id} value={option.id} text={option.value} />)}
                    </Select>
                </div>
            </div>
        )
    }

    if (Util.isNumberExist(item.againstPredefinedClassServiceSale)) {
        return (
            <div style={{
                width: '100%', height: undefined, borderRadius: 15, border: 1, borderColor: '#00000020', borderStyle: 'solid', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
                marginBottom: '1rem', paddingInline: '1rem', paddingBlock: '0.5rem', gap: '0.5rem',
                animation: typeof item.id === "string" ? 'opacity-fade-in-md-anim 250ms' : undefined,
                background: 'white'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <ProfilePic src={Api.getThumbnail(objectType, item.userId)} size={32} style={{ minWidth: 32 }} />
                    <p style={{ fontSize: 14, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>{item.name}</p>
                    <Link onClick={onRemoveBtn} size="sm" style={{ color: '#990000', cursor: 'pointer' }}>Remove user</Link>
                </div>

                <div style={{ marginTop: '0.25rem' }}>
                    <TextInput labelText="Against Class Invoice" style={{ pointerEvents: 'none' }} value={Util.getVoucherNumber(item.againstPredefinedClassServiceSale)} />
                </div>
            </div>
        )
    }

    return (
        <div style={{
            width: '100%', height: 45, borderRadius: 15, border: 1, borderColor: '#00000020', borderStyle: 'solid', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
            marginBottom: '1rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.5rem',
            animation: typeof item.id === "string" ? 'opacity-fade-in-md-anim 250ms' : undefined,
            background: 'white'
        }}>
            <ProfilePic src={Api.getThumbnail(objectType, item.userId)} size={32} style={{ minWidth: 32 }} />
            <p style={{ fontSize: 14, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>{item.name}</p>
            <Link onClick={onRemoveBtn} size="sm" style={{ color: '#990000', cursor: 'pointer' }}>Remove user</Link>
        </div>
    )
}

const MemberTagName = ({ color, name }) => {
    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

    return (
        <Tag
            style={{
                color: isLightColor(color ?? "#000000") ? "black" : "white",
                background: color ?? "#000000",
            }}>{name}</Tag>
    )
}



const SelectionItem = ({ selected, profilePicSrc, name, onClick, nameHighlight }) => (
    <div onClick={onClick} data-selected={selected} className='user-assignation-selection-item'>
        {profilePicSrc && <ProfilePic src={profilePicSrc} size={32} style={{ minWidth: 32 }} />}
        {nameHighlight ?
            <MemberTagName color={nameHighlight} name={name} /> :
            <p style={{ fontSize: 14, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>{name}</p>}
        {selected && <Tag type="green" size="sm">Selected</Tag>}
    </div>
)

const TagList = ({ store, allUsers, tags }) => {
    const [items, setItems] = useStore(store, "items");
    const addTag = tag => {
        setItems(items => {
            const userIdsToAdd = tag.userIds.filter(userId => items.find(item => item.userId == userId) === undefined);
            return [...items, ...userIdsToAdd
                .filter(id => allUsers.find(user => user.id == id) !== undefined)
                .map(id => createUserAssignation(allUsers.find(user => user.id == id)))]
        })
    }

    return (
        <div style={{ padding: '0rem', borderRadius: 15, overflow: 'hidden' }}>
            <p style={{ fontSize: 12, opacity: 0.65, padding: '1rem' }}>Assign users with tag</p>
            <div style={{ maxHeight: 250, overflowX: 'hidden', overflowY: 'auto', borderTopWidth: 1, borderTopColor: '#00000020', borderTopStyle: 'solid' }}>
                {tags.map(tag => <SelectionItem name={tag.name}
                    nameHighlight={tag.color}
                    onClick={() => addTag(tag)} />)}
            </div>
            {tags.length === 0 && <Message value="No tag exists" />}
        </div>
    )
}

function createUserAssignation(user) {
    return { id: Util.newTempId(), userId: user.id, name: user.value, raw: user }
}

const AllUserList = ({ store, objectType, allUsers }) => {
    const [search, setSearch] = useState("");

    const [items, setItems] = useStore(store, "items");
    const isSelected = userId => items.find(item => item.userId == userId) !== undefined;
    const onUserClick = user => {
        const userId = user.id;
        if (isSelected(userId)) {
            setItems(items => items.filter(item => item.userId != userId))
        } else {
            setItems(items => [...items, createUserAssignation(user)])
        }
    }

    const filteredAllUsers = useMemo(() => {
        if (Util.isStringExists(search)) {
            return allUsers.filter(user => user?.value?.toLowerCase().includes(search.toLowerCase()))
        } else {
            return allUsers;
        }
    }, [allUsers, search])

    return (
        <div style={{ padding: '0rem', borderRadius: 15, overflow: 'hidden' }}>
            {/* <p style={{ fontSize: 12, opacity: 0.65, padding: '1rem' }}>Select users</p> */}
            <input value={search} onChange={e => setSearch(e.target.value)} placeholder="Search..." style={{ outline: 'none', padding: '1rem', fontSize: 12, border: 'none', background: 'none', width: '100%' }} />

            <div style={{ maxHeight: 250, overflowX: 'hidden', overflowY: 'auto', borderTopWidth: 1, borderTopColor: '#00000020', borderTopStyle: 'solid' }}>
                {filteredAllUsers.map(user => <SelectionItem profilePicSrc={Api.getThumbnail(objectType, user.id)} name={user.value}
                    selected={isSelected(user.id)} onClick={() => onUserClick(user)} />)}
            </div>
            {filteredAllUsers.length === 0 && <Message value="No users exists" />}
        </div>
    )
}

const NewAssignationBtns = ({ store, tags, objectType, allUsers, assignText }) => {
    return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end', gap: '0.25rem' }}>
            {tags && <ButtonPopover store={store} closeOnChange popover={<TagList store={store} allUsers={allUsers} tags={tags} />}>
                <Button kind="secondary" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add by Tag</Button>
            </ButtonPopover>}
            <ButtonPopover store={store} popover={<AllUserList store={store} objectType={objectType} allUsers={allUsers} />}>
                <Button size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>{assignText ?? 'Assign Users'}</Button>
            </ButtonPopover>
        </div>
    )
}

const List = ({ isActivity, store, objectType }) => {
    const [items, setItems] = useStore(store, "items");
    return (
        <div>
            {items.map(item => <Item isActivity={isActivity} key={item.id} objectType={objectType} item={item}
                onRemoveBtn={() => setItems(items => items.filter(other => other.userId != item.userId))}
                setItems={setItems}
            />)}
            {items.length === 0 && <Message value="No assignation exists" />}
        </div>
    )
}



export function UserAssignation({ title, tags, allUsers, value, onValueChanged }) {
    const store = { items: [value, onValueChanged] }
    return (
        <div style={{ marginTop: '0rem', position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <NewAssignationBtns store={store} tags={tags} allUsers={allUsers} objectType={OBJECT_TYPE_ACCOUNT} />
            </div>
            <List store={store} objectType={OBJECT_TYPE_ACCOUNT} />
        </div>
    )
}

export function TutorAssignation({ allUsers, value, onValueChanged }) {
    const parsedValue = value.map(val => ({ ...val, userId: val.tutorId }))
    const parsedOnValueChanged = value => {
        if (typeof value === "function") {
            onValueChanged(value(parsedValue).map(val => ({ ...val, tutorId: val.userId })))
        } else {
            onValueChanged(value.map(val => ({ ...val, tutorId: val.userId })))
        }
    }

    const store = { items: [parsedValue, parsedOnValueChanged] }
    return (
        <div style={{ marginTop: '0rem', position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <NewAssignationBtns store={store} allUsers={allUsers} objectType={OBJECT_TYPE_TUTOR} assignText="Assign Tutor" />
            </div>
            <List store={store} objectType={OBJECT_TYPE_TUTOR} />
        </div>
    )
}

export function StudentAssignation({ isActivity, allUsers, value, onValueChanged }) {
    const parsedValue = value.map(val => ({ ...val, userId: val.studentId }))
    const parsedOnValueChanged = value => {
        if (typeof value === "function") {
            onValueChanged(value(parsedValue).map(val => ({ ...val, studentId: val.userId })))
        } else {
            onValueChanged(value.map(val => ({ ...val, studentId: val.userId })))
        }
    }

    const store = { items: [parsedValue, parsedOnValueChanged] }
    return (
        <div style={{ marginTop: '0rem', position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <NewAssignationBtns store={store} allUsers={allUsers} objectType={OBJECT_TYPE_STUDENT} assignText="Assign Student" />
            </div>
            <List isActivity={isActivity} store={store} objectType={OBJECT_TYPE_STUDENT} />
        </div>
    )
}