
import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import print from 'print-js';
import Button from '../../components/Button';

import { Receipt16, ChartCustom16 } from '@carbon/icons-react'
import Util from '../../util/Util';
import { ReportPdf } from './pdf/ReportPdf';



export const OutstandingReportPdfBtn = ({report}) => {
    const [instance, updateInstance] = usePDF({ document: <ReportPdf report={report} /> });

    return (
        //width: '100%', 
        <a href={instance.url} target="_blank" style={{textDecoration: 'none'}}>
            <Button 
            kind="secondary"
            loading={instance.loading} disabled={instance.error}
            renderIcon={ChartCustom16}
            size="sm">Get Report</Button>
            {/* <Button style={{width: '100%'}} loading={instance.loading} disabled={instance.error} renderIcon={Receipt16}>Purchase Voucher</Button> */}
        </a>
    )
}