import { Button, ComboBox, ComposedModal, ContentSwitcher, ModalBody, ModalFooter, ModalHeader, Switch, TextInput } from 'carbon-components-react'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_VENUE } from '../../../constants/ObjectTypes';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import Util from '../../../util/Util';

const Selector = ({ customers, venues, selectedId, setSelectedId, open }) => {
    const [type, setType] = useState(selectedId.type)
    const [selectedPickerKey, setSelectedPickerKey] = useState(Util.newTempId());

    const getList = () => {
        switch (type) {
            case OBJECT_TYPE_CUSTOMER:
                return customers;
            case OBJECT_TYPE_VENUE:
                return venues;
            default:
                return []
        }
    }

    const getTitle = () => {
        switch (type) {
            case OBJECT_TYPE_CUSTOMER:
                return "Select customer"
            case OBJECT_TYPE_VENUE:
                return "Select venue"
            default:
                return []
        }
    }

    useEffect(() => {
        setSelectedId({ id: 0, type: OBJECT_TYPE_CUSTOMER })
        setType(OBJECT_TYPE_CUSTOMER)
        setSelectedPickerKey(Util.newTempId())
    }, [open])

    return (<>
        {hasCapabilitySupport("thirdPartyPos") && <>
            <ContentSwitcher selectedIndex={type == OBJECT_TYPE_VENUE ? 1 : 0} onChange={({ index }) => setType(index == 1 ? OBJECT_TYPE_VENUE : OBJECT_TYPE_CUSTOMER)}>
                <Switch text="Customer" />
                <Switch text="Venue" />
            </ContentSwitcher>
            <div style={{ height: '1rem' }} />
        </>}

        <ComboBox
            light
            key={selectedPickerKey}
            titleText={getTitle()}
            items={getList()}
            itemToString={item => item !== null ? item.value : ""}
            selectedItem={getList().filter(item => item.id == selectedId.id)[0]}
            onChange={e => {
                setSelectedId({ id: e.selectedItem !== null ? e.selectedItem.id : 0, type })
                setSelectedPickerKey(Util.newTempId())
            }} />
    </>)
}

export default ({ salesCartId, customers, venues, open, onClose, onCreated }) => {
    const [selectedId, setSelectedId] = useState({ id: 0, type: OBJECT_TYPE_CUSTOMER });

    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState("")
    // const [customerId, setCustomerId] = useState(0)
    // const [customerPickerKey, setCustomerPickerKey] = useState(Util.newTempId())

    const onCreate = () => {
        setLoading(true)

        const customerId = selectedId.type === OBJECT_TYPE_CUSTOMER ? selectedId.id : 0;
        const venueId = selectedId.type === OBJECT_TYPE_VENUE ? selectedId.id : 0;

        Api.createSalesCartWork(salesCartId, customerId, venueId, label, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onCreated(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        setLabel("")
        // setCustomerId(0)
        // setCustomerPickerKey(Util.newTempId())
    }, [open])

    return (
        <ComposedModal className="modal-that-supports-combo-box" key="apply-coupon-dialog" size="sm" open={open} onClose={onClose}>
            <ModalHeader label="Sales Cart" title="New Work" />
            <ModalBody>
                <p style={{ fontSize: 12, marginBottom: '1rem', marginTop: '-1rem', opacity: 0.65 }}>Creating new work from <strong>current</strong> cart content</p>

                <TextInput
                    data-modal-primary-focus
                    labelText="Label (optional)"
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                />

                <div style={{ height: '1rem' }} />
                <Selector customers={customers} venues={venues} open={open} selectedId={selectedId} setSelectedId={setSelectedId} />
                {/* <ComboBox
                light
                key={customerPickerKey}
                titleText={"Select customer"}
                items={customers}
                itemToString={item => item !== null ? item.value : ""} 
                selectedItem={customers.filter(item => item.id == customerId)[0]}
                onChange={e => {
                    setCustomerId(e.selectedItem !== null ? e.selectedItem.id : 0)
                    setCustomerPickerKey(Util.newTempId())
                }} /> */}
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={loading || selectedId.id == 0}
                    onClick={onCreate}>
                    {loading ? "Loading..." : "Create"}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}