import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { Archive32 } from '@carbon/icons-react'

export const SystemLogsReport = {
    filter: Filter,
    customPath: "/system-logs",

    // icon: Archive32,
    // isListMode: true,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }

    //newLink: '/new-leads',
    // itemToOpenLink: item => `/lead-flow/${item.id}`
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="timestamp" />
        <Divider />
        <TextBox state={state} label="IP" property="ipAddress" />
        <TextBox state={state} label="User" property="fullName" />
        {/* <DateRange state={state} property="created" />
        <Divider />
        <TextBox state={state} label="Customer" property="customer" /> */}
    </>)
}
