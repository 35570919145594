import React from "react";
import ReactDOM from "react-dom";

export default function Portal(props) {
    return ReactDOM.createPortal((
        <div onClick={e => e.stopPropagation()}>
            {props.children}
        </div >
        //), document.getElementById('root-portal'));
    ), document.getElementById('root'));
}

export function BodyPortal(props) {
    return ReactDOM.createPortal((
        <div onClick={e => e.stopPropagation()}>
            {props.children}
        </div >
        //), document.getElementById('root-portal'));
    ), document.body);
}