import { ButtonSet, Checkbox, ClickableTile, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { RowDelete32, ErrorFilled16, Save16, ArrowRight16, } from '@carbon/icons-react'
import Util from '../../util/Util';
import { getAccountRole } from '../../session/SessionManager';
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from '../../constants/Constants';


export default ({ open, onClose, stateType, currentDraft, getState, onSetState, showPublic }) => {
    const draft = currentDraft;

    const [title, setTitle] = useState("")
    const [visibilityPublic, setVisibilityPublic] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setTitle(draft ? draft.title : "")
            setVisibilityPublic(draft ? draft.visibilityPublic : "")
        }
    }, [open])

    const saveDraft = () => {
        const update = {
            id: draft ? draft.id : 0,
            stateType: stateType,
            title: title,
            visibilityPublic: visibilityPublic,
            state: JSON.stringify(getState())
        }

        setLoading(true)
        Api.saveStateDraft(update, response => {
            if (response.status === true) {
                UIUtil.showSuccess();

                onSetState(response.payload)
                onClose();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false)
        })
    }

    const accountRole = getAccountRole();

    return (
        <ComposedModal
            key="save-state-dialog"
            open={open}
            onClose={onClose}
            size="sm">
            <ModalHeader label={draft ? "Updating" : "Saving"} title="Draft" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                <TextInput
                    data-modal-primary-focus
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    labelText="Title"
                    placeholder="Optional title"
                />

                {showPublic && (accountRole === ACCOUNT_TYPE_ADMINISTRATION || accountRole === ACCOUNT_TYPE_SUPERVISOR) &&
                    <div onClick={() => setVisibilityPublic(v => !v)} style={{ marginTop: '1rem' }}>
                        <Checkbox labelText="Public" checked={visibilityPublic} />
                    </div>}
            </ModalBody>
            <ModalFooter>
                <ButtonSet style={{ width: '100%' }}>
                    <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                        Cancel
                    </Button>
                    <Button onClick={saveDraft} renderIcon={Save16} loading={loading}>
                        {draft ? 'Update' : 'Save'}
                    </Button>
                </ButtonSet>
            </ModalFooter>
        </ComposedModal>
    )
}