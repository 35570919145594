import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import Button from '../../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CONTACT, OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_SALES_ORDER_STATE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../../constants/ObjectTypes";
import Api from '../../../session/Api';
import ItemTemplate from '../../../templates/ItemTemplate';
import TableTemplate from '../../../templates/TableTemplate';
import AllSubsidiaryReportView from '../../accounting-reports/AllSubsidiaryReportView';
import StockFlowListView from '../../stock-flow/StockFlowListView';
import TransactionListView from '../../transaction/TransactionListView';



class OnlineStorePaymentMethodsDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD, listener)
            } else {
                Api.getItem(OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD,
                    itemResult: this.state.itemResult,
                    pagePath: "/online-store-payment-methods/",
                    history: this.props.history,
                })}
            </div>
        )
    }

}

export default withRouter(OnlineStorePaymentMethodsDetailPage);