import { CenterSquare16, Add16, Subtract16 } from '@carbon/icons-react'

export const SeatItem = ({ grid, row, col }) => {
    const enabled = grid.isEnabled(row, col);

    if (enabled) {
        const seatType = grid.getSeatType(row, col);
        const background = seatType?.color ? `${seatType?.color}` : 'rgba(184, 115, 11, 0.27)'

        if (grid.isSeatUnavailable(row, col)) {
            return (
                <div className="seat-node-enabled" style={{ background, pointerEvents: 'none', opacity: 0.25 }}>
                </div>
            )
        } else {
            return (
                <div className="seat-node-enabled" onClick={() => grid.toggleSelection(row, col)} style={{ background, border: grid.isSelected(row, col) ? '1px solid #ffffff' : undefined }}>
                </div>
            )
        }
    } else {
        return (
            <div className="seat-node">
                {/* <CenterSquare16 /> */}
            </div>
        )
    }
}
