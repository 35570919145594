
import { QueryQueue32, CheckmarkFilled16, Receipt24 } from '@carbon/icons-react'
import { FormSection, useForm } from '../../../templates/form/form'
import { TableInput } from '../../../components/table-input'
import { ProductListSelector } from '../../../components/list-selector'
import Button from '../../../components/Button'
import useApi from '../../../hooks/useApi'
import { useState } from 'react'
import { ComboBox, TextInput } from 'carbon-components-react'
import { InvoiceSearchDialog } from '../../../pages/pos/dialogs/any-search-dialog'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import { printPackingListPdf } from './pdf'
import Util from '../../../util/Util'
import { InvoiceSelectField } from '../../../components/invoice-select-field'

export function CreatePackingListForm({ endpoint, form }) {
    return (
        <div style={{ paddingInline: '3rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem', marginTop: '-6rem' }}>
                <div>
                    <QueryQueue32 style={{ width: 64, height: 64 }} />
                    <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>Packing List</h3>
                    {/* <h4>{notification.id}</h4> */}
                    <h4 style={{ color: '#0f62fe', fontSize: 16, marginTop: -3 }}>Create List</h4>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Please select invoice to continue</p>

                </div>
                <div style={{ flex: 1 }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', marginBottom: '1rem' }}>
                <Receipt24 />
                <h4>Select invoice</h4>
            </div>
            <form.CustomField fieldKey="stockFlowId">
                {InvoiceSelectField}
            </form.CustomField>
        </div>
    )
}