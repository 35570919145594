
import { TextInput } from 'carbon-components-react';
import React, { useEffect, useState } from 'react'
import Util from '../util/Util';


export default ({ defaultDate, onDateChanged, ...props }) => {
    const [value, setValue] = useState(() => Util.formatDate(defaultDate, "YYYY-MM-DD"));


    useEffect(() => {
        onDateChanged(new Date(value).getTime())
    }, [value])

    return (
        <>
            <TextInput
                {...props}
                type={"date"}
                onChange={e => setValue(e.target.value)}
                defaultValue={value} />
        </>
    )
}

