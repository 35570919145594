import { ComposedModal, FileUploaderDropContainer, FileUploaderItem, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from "carbon-components-react"
import Button from "../../../components/Button"
import { CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { useEffect, useState } from "react"
import { typeGetAttachmentType, typeHasAttachment, typeName } from "../components/type-name";
import Util from "../../../util/Util";
import { ADVANCED_NOTES_TYPE } from "..";
import UIUtil from "../../../util/UIUtil";
import Api from "../../../session/Api";

export function ItemEditDialog({ item, visible, onClose, onUpdateItem }) {
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {
            setTitle(item.title);
            setDesc(item.description)
        }
    }, [visible])

    const type = item.type;

    const onSaveBtn = () => {
        setLoading(true);
        Api.saveAdvancedNote({
            id: item.id,
            title, description: desc
        }, undefined, response => {
            if (response.status === true) {
                onUpdateItem(response.payload);
                onClose();
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    return (
        <ComposedModal key="edit-advanced-note-dialog" size="sm" open={visible} onClose={onClose}>
            <ModalHeader label="Editing" title={typeName(type)} />
            <ModalBody>
                <TextInput
                    data-modal-primary-focus
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    labelText="Title"
                    placeholder="Input title"
                />

                {type != ADVANCED_NOTES_TYPE.FOLDER && <>
                    <div style={{ height: '1rem' }} />
                    <TextArea
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
                        labelText={type == ADVANCED_NOTES_TYPE.NOTE ? "Note" : "Description"}
                        placeholder={type == ADVANCED_NOTES_TYPE.NOTE ? "Input note" : "Optional description"}
                    />
                </>}

            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                    Close
                </Button>
                <Button loading={loading} disabled={!Util.isStringExists(title)} onClick={onSaveBtn} renderIcon={CheckmarkFilled16}>
                    Update
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}