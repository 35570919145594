import { InlineLoading, Button } from 'carbon-components-react';
import React from 'react'
import ReactTooltip from 'react-tooltip';

export default ({style, loading, disabled, kind, size, ...props}) => {
    if (props['data-tip']) {
        return <Button style={style} disabled={disabled || loading} kind={kind} size={size} {...props} renderIcon={loading ? InlineLoading : props.renderIcon} onClick={() => {
            ReactTooltip.hide()
            if (props.onClick !== undefined) {
                props.onClick();
            }
        }} />;
    } else {
        return <Button style={style} disabled={disabled || loading} kind={kind} size={size} {...props} renderIcon={loading ? InlineLoading : props.renderIcon} />;
    }
}