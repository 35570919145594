import React from 'react'
import { Link } from 'react-router-dom';
import { COMPANY_NAME } from '../../app/Config';
import Util from '../../util/Util';
import { AccountTreeItem } from './acct-statements';

// const Item = ({ item }) => (
//     // <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2rem' }}>
//     <>
//         {/* <p style={{ flex: 2 }}>{item.name}</p> */}
//         <Link target="_blank" to={"/general-ledger-report/" + item.id} style={{ flex: 2 }}>{item.name}</Link>
//         <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
//             <p style={{}}>AED {item.balance.toFixed(2)}</p>
//         </div>
//         <div style={{ flex: 1 }} />
//         {/* </div> */}
//     </>
// )

class IncomeSheet extends React.Component {

    getItems(property) {
        return this.props.statement[property];
    }

    render() {
        const statement = this.props.statement;
        const isLoss = statement.netIncome < 0;
        //const netIncome = Math.abs(statement.netIncome).toFixed(2);
        const netIncome = Math.abs(statement.netIncome);
        return (
            <div style={{ width: '100%', }}>
                <div style={{ height: 15, background: '#f4f4f4' }} />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontSize: 16 }}>{COMPANY_NAME}</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontWeight: 'bold', fontSize: 16 }}>{'Profit & Loss Statement'}</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    {/* <h4 style={{ fontSize: 16 }}>For the period {Util.getStatementDate(statement.startDate)} to {Util.getStatementDate(statement.endDate)}</h4> */}
                    <h4 style={{ fontSize: 16 }}>For the period {Util.getStatementDate(statement.actualStartDate)} to {Util.getStatementDate(statement.actualEndDate - 1000)}</h4>
                    {/* <h4 style={{ fontSize: 16 }}>For the period {(statement.actualStartDate)} to {(statement.actualEndDate - 1000)}</h4> */}
                </div>
                <div style={{ height: 15, background: '#f4f4f4' }} />

                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ padding: '1rem', flex: 1 }}>
                        <h5 style={{ marginBottom: '0.5rem' }}>Revenue</h5>

                        {this.getItems('incomes').map(item => <AccountTreeItem startDate={statement.actualStartDate} endDate={statement.actualEndDate} hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} leftAligned //itemRender={Item} 
                        />)}


                        <div style={{ display: 'flex', flex: 1, borderBottom: '1px solid #00000020', paddingBottom: '0.5rem', paddingLeft: '1rem', marginTop: '1rem', }}>
                            <h6 style={{ flex: 3, fontSize: 14, opacity: 1.65, fontWeight: 'lighter', fontStyle: 'italic' }}>Total Revenue</h6>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                                <p style={{}}>AED {Util.formatMoney(statement.totalIncome)}</p>
                            </div>
                        </div>

                        <h5 style={{ marginBottom: '0.5rem', marginTop: '3rem' }}>Expenses</h5>

                        {this.getItems('expenses').map(item => <AccountTreeItem startDate={statement.actualStartDate} endDate={statement.actualEndDate} hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} leftAligned //itemRender={Item} 
                        />)}


                        <div style={{ display: 'flex', flex: 1, borderBottom: '1px solid #00000020', paddingBottom: '0.5rem', paddingLeft: '1rem', marginTop: '1rem', }}>
                            <h6 style={{ flex: 3, fontSize: 14, opacity: 1.65, fontWeight: 'lighter', fontStyle: 'italic' }}>Total Expense</h6>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                                <p style={{}}>AED ({Util.formatMoney(statement.totalExpense)})</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1rem', background: '#f4f4f4', marginTop: '1rem' }}>
                    <div style={{ padding: '1rem', flex: 1, display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ flex: 1 }}>Profit/(Loss)</h5>
                        <p style={{ color: isLoss ? 'red' : netIncome > 0 ? 'green' : 'black' }}>AED {isLoss ? `(${Util.formatMoney(netIncome)})` : Util.formatMoney(netIncome)}</p>
                    </div>
                </div>
            </div>
        )
    }

}

export default IncomeSheet;