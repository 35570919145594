import { ComboBox, ContentSwitcher, Switch } from "carbon-components-react";
import QRCode from "react-qr-code";
import Button from "../../../components/Button";
import { QrCode16 } from '@carbon/icons-react'
import UIUtil from "../../../util/UIUtil";

const QrCode = ({ seatPlanId, grid }) => {
    //const value = 'STU-ID-' + 'fe';
    const [row, col] = grid.inSelection.getSingularSeat();
    const value = window.location.origin.toString() + '/cinema-seat-qr/' + seatPlanId + "/" + row + "/" + col;
    return (<div>
        <div style={{ width: '100%', height: 253, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5, background: 'white' }}>
            <QRCode size={225} value={value} />
        </div>
        <Button onClick={() => UIUtil.showQrCode(value)} renderIcon={QrCode16} style={{ borderRadius: 7, width: '100%', maxWidth: 'unset', marginTop: '0.5rem' }}>Open QR Code</Button>

        <div style={{ height: '3rem' }} />
    </div>)
}

export function Inspector({ seatPlanId, endpoints, grid }) {
    const selectedTypeIds = [...new Set(grid.inSelection.getSeatTypes().map(item => item?.id))];
    return (
        <div className="" style={{ padding: '1rem', width: '100%' }}>
            <p style={{ fontWeight: 'bold', color: 'white', }}>Seat Editor</p>
            <p style={{ fontSize: 12, opacity: 0.65 }}>Multi-editor</p>
            <div style={{ height: '1rem' }} />


            <label className="bx--label">Seat Node</label>
            <ContentSwitcher selectedIndex={grid.inSelection.hasAnyEnabled() ? 1 : 0} onChange={e => grid.inSelection.set(seat => ({ ...seat, enabled: e.index === 1 }))}>
                <Switch name="Disabled" text="Disabled" />
                <Switch name="Enabled" text="Enabled" />
            </ContentSwitcher>
            <div style={{ height: '1rem' }} />



            <label className="bx--label">Seat Type</label>
            <div className="white-combo-box-arrow dark-text-input">
                <ComboBox
                    style={{ background: 'black', color: 'white', }}
                    placeholder="Select seat type"

                    value={selectedTypeIds.length > 1 ? '(multiple types)' : endpoints.seatTypes.find(type => type.id == selectedTypeIds[0])?.name}
                    item={endpoints.seatTypes.find(type => type.id == selectedTypeIds[0])}
                    onChange={e => grid.inSelection.set(seat => ({ ...seat, type: e.selectedItem }))}

                    itemToString={item => item ? item.name : ""}
                    items={endpoints.seatTypes}
                />
            </div>

            {grid.inSelection.canShowSingularQrCode() && <>
                <div style={{ height: '1rem' }} />
                <label className="bx--label">Seat QR Code</label>
                <QrCode seatPlanId={seatPlanId} grid={grid} />
            </>}
        </div>
    )
}