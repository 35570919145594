import { useEffect, useMemo, useRef, useState } from "react";
import Portal from "../../../../util/Portal";
import "./styles.scss"
import { Close16, AirlinePassengerCare20, Printer24, Chat24, CheckmarkFilled32, ArrowRight16, Receipt20, Information16, InformationSquareFilled16 } from '@carbon/icons-react'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { InlineLoading, TextInput } from "carbon-components-react";
import Api from "../../../../session/Api";
import { useDebounce } from "../../../../hooks/useDebounce";
import Util from "../../../../util/Util";
import { SidePanel } from "../../../../templates/draft/components/side-panel";
import Button from "../../../../components/Button";
import UIUtil from "../../../../util/UIUtil";
import { generateHtml } from "../../../../util/useBitmap";
import { ArabicPosReceipt } from "../../../../integrations/thermal-printer/resource/arabic-pos-receipt";
import { COMPANY_NAME } from "../../../../app/Config";



export function FinalReceiptDialog({ receipt, state, visible, onClose }) {
    const [shouldPrint, setShouldPrint] = useState(true);
    const [shouldSendSms, setShouldSendSms] = useState(Util.isStringExists(receipt._transient_param_lastSaleCustomerNumber));
    const [smsNumber, setSmsNumber] = useState(receipt._transient_param_lastSaleCustomerNumber)

    const [loading, setLoading] = useState(false);
    const onSubmitBtn = async () => {
        if (shouldSendSms && !Util.isStringExists(smsNumber)) {
            UIUtil.showInfo("Please enter customer number for SMS");
            return;
        }


        if (shouldSendSms) {
            setLoading(true);
            Api.sendPosReceiptSms(receipt.lastStockFlowId, smsNumber, await generateHtml(<ArabicPosReceipt request={{
                ...receipt,
                __company_name: COMPANY_NAME.toUpperCase(),
                __current_date: Util.getFullDate(new Date().getTime()),
                __voucher_no: Util.getVoucherNumber(receipt.lastStockFlowId)
            }} />), response => {
                if (response.status === true) {
                    if (shouldPrint) {
                        state?.mainApp?.thermalPrinter.printReceipt(receipt)
                    } else if (receipt._transient_param_coupons?.length) {
                        state?.mainApp?.thermalPrinter.driver.printCoupons(receipt, receipt._transient_param_coupons)
                    }

                    onClose();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError("Failed to send SMS: " + response.message)
                }
                setLoading(false)
            })
        } else if (shouldPrint) {
            // no sms
            setLoading(true)
            if (state.mainApp) {
                state.mainApp.thermalPrinter.printReceipt(receipt)
            }
            UIUtil.showSuccess();
            setLoading(false)
            onClose();
        } else {
            if (receipt._transient_param_coupons?.length) {
                state?.mainApp?.thermalPrinter.driver.printCoupons(receipt, receipt._transient_param_coupons)
            }
            onClose();
        }
    }

    return (
        <Portal>
            <div className="final-receipt-dialog">
                <div className={"backdrop " + (visible ? 'backdrop-visible' : "backdrop-hidden")} />

                <div className={"card " + (visible ? "card-visible" : "card-hidden")} style={{ padding: '1rem' }}>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <h4 style={{ fontWeight: 'bold', flex: 1 }}>Receipt
                        </h4>
                        {/* <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                            <Close16 />
                        </button> */}
                    </div>

                    <div onClick={() => setShouldPrint(prev => !prev)} style={{ borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', display: 'flex', alignItems: 'center', gap: '0.25rem', padding: '1rem', marginBottom: '0.5rem' }}>
                        <Printer24 />
                        <h5 style={{ flex: 1 }}>Should Print?</h5>

                        {shouldPrint ? (
                            <CheckmarkFilled32 style={{ color: 'green' }} />
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 32, height: 32 }}>
                                <div style={{ width: 28, height: 28, borderRadius: '50%', background: '#00000020' }} />
                            </div>
                        )}
                    </div>

                    <div onClick={() => setShouldSendSms(prev => !prev)} style={{ borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', display: 'flex', alignItems: 'center', gap: '0.25rem', padding: '1rem', marginBottom: '0.5rem' }}>
                        <Chat24 />
                        <h5 style={{}}>Send SMS? To:</h5>

                        <TextInput onClick={e => e.stopPropagation()} placeholder="Input customer number" style={{ flex: 1, marginInline: '1rem' }} value={smsNumber} onChange={e => {
                            setSmsNumber(e.target.value)
                            if (Util.isStringExists(e.target.value)) {
                                setShouldSendSms(true)
                            }
                        }} />

                        {shouldSendSms ? (
                            <CheckmarkFilled32 style={{ color: 'green' }} />
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 32, height: 32 }}>
                                <div style={{ width: 28, height: 28, borderRadius: '50%', background: '#00000020' }} />
                            </div>
                        )}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                        <Button loading={loading} onClick={onSubmitBtn} renderIcon={ArrowRight16} style={{ borderRadius: 15, }} size="lg">Submit</Button>
                    </div>
                </div>
            </div>
        </Portal >
    )
}