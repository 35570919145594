import React from 'react'
import { Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, DataTableSkeleton, Tag, ButtonSet, TableSelectAll, TableSelectRow, ExpandableSearch, InlineLoading } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import {
    Add16, Error16, Identification16, Receipt16, Save16, ArrowRight16, ArrowLeft16, Calendar16, QueryQueue16, SubtractAlt16, AddFilled16, Currency16,
    AirlinePassengerCare32, WirelessCheckout32, CheckmarkFilled32, Delivery32, Time32
} from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Button from '../../components/Button';
import { Link, useHistory, withRouter } from "react-router-dom";
import DirectionIndicator from '../../components/DirectionIndicator';
import { TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_SUPPLIER } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_SUPPLIER } from '../../constants/ObjectTypes';
import Util from '../../util/Util';
import CountUp from 'react-countup';
import './sales-order.scss'
import { getOrderStatusName, STATUS_PENDING, STATUS_PROCESSING } from './base/sales-order';
import StatusIndicator from './views/StatusIndicator';
import PaginatedDataTable from '../../components/data-table/PaginatedDataTable';
import SalesOrderTable from './lists/SalesOrderTable';
import { hasCapabilitySupport } from '../../app/Capabilities';

const HEADERS = [
    {
        header: "Order ID",
        key: "id"
    },
    {
        header: "Order No",
        key: "orderNo"
    },
    {
        header: "Customer",
        key: "customerId"
    },
    {
        header: "Date",
        key: "dateCreated"
    },
    {
        header: "Status",
        key: "status"
    },
    {
        header: "Amount",
        key: "amount"
    }
    // {
    //     header: "Progress",
    //     key: "id"
    // }
]


const renderAmount = (salesOrder) => {
    if (Util.isNumberExist(salesOrder.amount)) {
        const amount = salesOrder.amount;
        const amountPaid = salesOrder.paidAmount;

        const amountLeft = amount - amountPaid;

        let status;
        let statusColor;
        if (amountLeft <= 0) {
            status = "Paid";
            statusColor = "green";
        } else {
            status = amountPaid == 0 ? "Not paid" : "Partially paid";
            statusColor = amountPaid == 0 ? "red" : undefined;
        }

        return (<>
            <div style={{ marginBottom: '0.5rem', }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
                <p>
                    AED {amount.toFixed(2)}
                </p>
            </div>

            {Util.isNumberExist(salesOrder.salesStockFlowId) ? (<>
                <div style={{ marginBottom: 0, }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
                    <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                        AED {amountPaid.toFixed(2)}
                    </p>
                </div>
            </>) : (
                <p style={{ opacity: 0.65, fontSize: 12 }}>No sales occurred yet</p>
            )}



        </>)
    } else {
        return null;
    }
}


const CardView = ({ index, icon, title, value }) => {
    return (
        <div className={`sales-order-view-${index}`} style={{ flex: 1, height: 175, borderRadius: 5, padding: '1rem', color: 'white', display: 'flex', flexDirection: 'column' }}>
            {React.createElement(icon, {
                style: {
                    width: 64, height: 64, fill: 'white'
                }
            })}

            <div style={{ flex: 1 }}></div>
            <h3 style={{ alignSelf: 'flex-end' }}>{title}</h3>
            {/* <h1 ref={valueRef} style={{ fontWeight: 'bold', alignSelf: 'flex-end', }}>{value}</h1> */}
            <CountUp start={0} end={value} delay={0} duration={1.25} separator="," decimals={0} decimal="">
                {({ countUpRef }) => (
                    <h1 ref={countUpRef} style={{ fontWeight: 'bold', alignSelf: 'flex-end', }} />
                )}
            </CountUp>
        </div>

    )
}

class DeliverablesListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rows: [],

            activeOrders: 0,
            outForDelivery: 0,
            todayConfirmedOrders: 0,
            pendingOrders: 0,

            idFilter: "",
            voucherNoFilter: ""
        }
    }

    getTitle() {
        return "Deliverables/Rentals"
    }

    getSubtitle() {
        if (this.props.partyList && (this.props.partyList.type == OBJECT_TYPE_SUPPLIER ||
            this.props.partyList.type == OBJECT_TYPE_CUSTOMER)) {
            return undefined;
        }

        if (this.props.productList) {
            return undefined;
        }

        if (this.props.report) {
            return null;
        }

        return "All deliverable/rental sales orders";
    }

    getPath() {
        return "/deliverables/";
    }

    componentDidMount() {
        if (hasCapabilitySupport('restaurant')) {
            return;
        }

        this.setState({ loading: true })
        Api.getDeliverables(this.props.customerId ?? 0, response => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    rows: response.payload.list,
                    activeOrders: response.payload.activeOrders,
                    outForDelivery: response.payload.outForDelivery,
                    todayConfirmedOrders: response.payload.todayConfirmedOrders,
                    pendingOrders: response.payload.pendingOrders,
                })
            } else {
                this.setState({ loading: false, rows: [] })
                UIUtil.showError(response.message);
            }
        })
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.rows) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        switch (header.key) {
            case "otherPartyName":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, row.otherPartyId)} />
                        <p style={{ marginLeft: '0.5rem' }}>{cell.value}</p>
                    </div>
                )

            case "directionType":
                const isInward = row.directionType == TRANSACTION_DIRECTION_TYPE_INWARD;
                return <DirectionIndicator inward={isInward} suffix={isInward ? "(receipt)" : "(payment)"} />

            case "status":
                //return getOrderStatusName(cell.value)
                return <StatusIndicator status={cell.value} state={row.orderState} />

            case "initiationDate":
                return <>  {Util.getDate(cell.value)} </>

            case "dateCreated":
                return (
                    <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                        <h6>Order date/time</h6>
                        <p><Calendar16 /> {Util.getFullDate(row.dateCreated)}</p>

                        {row.status == STATUS_PENDING ? (
                            <>
                                <div style={{ height: 5 }} />
                                <h6>Preferred Delivery Date</h6>
                                <p><Calendar16 /> {Util.getFullDate(row.preferredDeliveryDate)}</p>

                                {row.preferredRentalPickupDate > 0 && <>
                                    <div style={{ height: 5 }} />
                                    <h6>Preferred Pickup Date</h6>
                                    <p><Calendar16 /> {Util.getFullDate(row.preferredRentalPickupDate)}</p>
                                </>}
                            </>
                        ) : <>
                            <div style={{ height: 5 }} />
                            <h6>Target Delivery Date</h6>
                            <p><Calendar16 /> {Util.getFullDate(row.targetDeliveryDate)}</p>

                            {row.preferredRentalPickupDate > 0 && <>
                                <div style={{ height: 5 }} />
                                <h6>Target Pickup Date</h6>
                                <p><Calendar16 /> {Util.getFullDate(row.targetRentalPickupDate)}</p>
                            </>}
                        </>}
                    </div>
                )

            case "customerId":
                return (
                    <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                        <h6>Customer</h6>
                        <p>{row.customerName ?? "Guest"}</p>
                        <div style={{ height: 5 }} />
                        <h6>Shipping Full Name</h6>
                        <p>{row.shippingAddress.firstName} {row.shippingAddress.lastName}</p>
                    </div>
                )

            case "id":
                return Util.getVoucherNumber(cell.value)

            case "amount":
                return renderAmount(row)

            default:
                return cell.value;
        }
    }

    render() {
        // if (this.state.loading) {
        //     return (
        //         <div style={{ width: '100%' }}>
        //             <DataTableSkeleton />
        //         </div>
        //     )
        // }


        return (
            <div style={{ width: '100%' }}>

                {!hasCapabilitySupport('restaurant') && <>
                    {this.state.loading ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 175 }}>
                            <InlineLoading style={{ width: 'unset' }} />
                        </div> :
                        <div style={{ display: 'flex', marginBottom: '1rem', gap: '0.5rem' }}>
                            <CardView index={0} icon={WirelessCheckout32} title="Active Orders" value={this.state.activeOrders} />
                            <CardView index={2} icon={Delivery32} title="Out For Delivery" value={this.state.outForDelivery} />
                            {!this.props.customerId && <CardView index={1} icon={CheckmarkFilled32} title="Today Confirmed Orders" value={this.state.todayConfirmedOrders} />}
                            <CardView index={3} icon={Time32} title="Pending Orders" value={this.state.pendingOrders} />
                        </div>}
                </>}

                <SalesOrderTable customerId={this.props.customerId} deliveryOnly />

                {/* <PaginatedDataTable
                hideToolbar
                loader={(page, sortRequest, listener) => Api.getLowStockProductReport(-1, page, listener)}
                loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.values.paginationHasMore })}
                columns={HEADERS.map(header => ({
                    id: header.key,
                    name: header.header,
                    render: item => this.renderCell(header.key, item[header.key], item.id, item)
                }))}
                onClick={() => {}}
                /> */}

                {/* <DataTable rows={Util.isStringExists(this.state.idFilter) ? this.state.rows.filter(row => row.id == Util.getIdFromVoucherNumber(this.state.idFilter)) : (Util.isStringExists(this.state.voucherNoFilter) ? this.state.rows.filter(row => row.orderNo == (this.state.voucherNoFilter)) : this.state.rows)} headers={HEADERS} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer title={this.getTitle()} description={this.getSubtitle()}>
                            {!this.props.hideToolbar && <TableToolbar>
                                <TableToolbarContent>
                                    

                                    <ExpandableSearch
                                        style={{ minWidth: 48 }}
                                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                            <Receipt16 />
                                        </div>}
                                        placeholder={"Find by Order No"} onChange={e => this.setState({ voucherNoFilter: e.target.value })} value={this.state.voucherNoFilter} />

                                    <ExpandableSearch
                                        style={{ minWidth: 48 }}
                                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                            <Identification16 />
                                        </div>}
                                        placeholder={"Find by ID"} onChange={e => this.setState({ idFilter: e.target.value })} value={this.state.idFilter} />

                                
                                </TableToolbarContent>
                            </TableToolbar>}
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => this.props.history.push(this.getPath() + row.id))} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell style={{ background: '#99000005' }} key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable> */}
            </div>
        )
    }

}

export default withRouter(DeliverablesListView);