import { TextInput, Search, ExpandableSearch, ClickableTile } from 'carbon-components-react'
import React, { useState } from 'react'

import { 
    Dashboard32, User32, Product32, Screen32, Category32, Barcode32, Box32, Compare32, Rocket32,
    Delivery32, AirlinePassengerCare32, CutOut32, Headset32, Percentage32, Store32, DataVis_232,
    Calculator32, WatsonHealthTextAnnotationToggle32, Archive32, StringText32, Chip32, InventoryManagement32,
    FlowStream32, Cube32, DocumentImport32, Purchase32, Money32, Finance32, Account32, Receipt32, Events32,
    Gift32, TagGroup32, QrCode32, Close16, SubtractAlt32, Currency32, IncreaseLevel32, ShoppingCartArrowUp32,
    App32
} from '@carbon/icons-react'
import Util from '../util/Util'
import { Link } from 'react-router-dom'
import Button from './Button'

const MenuItem = ({icon, title, desc}) => {
    const [hover, setHover] = useState(false)

    return (
        <ClickableTile onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{overflow: 'hidden'}}>
            {React.createElement(icon, {
                style: {
                    width: 64,
                    height: 64
                }
            })}
            <h3>{title}</h3>
            <p style={{fontSize: 14, transition: '250ms', opacity: hover ? 0.65 : 0, transform: hover ? 'translateY(0px)' : 'translateY(50px)'}}>{desc}</p>
        </ClickableTile>
    )
}

class FullMenu extends React.Component {

    /**
     * @returns {Array<{title: string, subMenus: Array<{title: string, menus: Array<{icon: React.Component, title: string, desc: string}>}>}>}
     */
    getMenu() {
        return [
            {
                title: "Portal",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: Dashboard32,
                                title: "Dashboard",
                                desc: "Get an overview of the system",
                            },
                            {
                                icon: Chip32,
                                title: "Monitoring",
                                desc: "Monitor active POS terminals",
                                link: "/monitoring"
                            },
                            {
                                icon: Calculator32,
                                title: "POS",
                                desc: "Open POS terminal",
                                link: "/"
                            }
                        ]
                    }
                ]
            },
            {
                title: "Finances",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: Currency32,
                                title: "Transaction",
                                desc: "View and record inward/outward transactions",
                                link: "/transactions"
                            },
                            {
                                icon: Finance32,
                                title: "Taxes",
                                desc: "Calculate tax and record tax payments"
                            },
                        ]
                    },
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: IncreaseLevel32,
                                title: "Income",
                                desc: "Analyze income",
                                link: "/income-report"
                            },
                            {
                                icon: SubtractAlt32,
                                title: "Expenses",
                                desc: "Analyze expenses",
                                link: "/expense-report"
                            },
                            {
                                icon: Money32,
                                title: "Profit",
                                desc: "Calculate profits",
                                link: "/profit-report"
                            },
                            {
                                icon: Account32,
                                title: "Cash Closed",
                                desc: "Cash closed report"
                            },
                        ]
                    }
                ]
            },
            {
                title: "Inventory",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: FlowStream32,
                                title: "Stock Flow",
                                desc: "View and initiate stock flow",
                                link: "/stock-flows"
                            },
                        ]
                    }, 
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: InventoryManagement32,
                                title: "Stock",
                                desc: "Analyze stock"
                            },
                            {
                                icon: ShoppingCartArrowUp32,
                                title: "Sales",
                                desc: "Analyze sales"
                            },
                        ]
                    }
                ]
            },
            {
                title: "Products",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: Product32,
                                title: "Products",
                                desc: "View, add and edit products",
                                link: "/products"
                            },
                            {
                                icon: Box32,
                                title: "Bundles",
                                desc: "View, add and edit product bundles",
                                link: "/product-bundles"
                            },
                            {
                                icon: Category32,
                                title: "Categories",
                                desc: "View, add and edit categories",
                                link: "/product-categories"
                            },
                            {
                                icon: Barcode32,
                                title: "Barcode",
                                desc: "Generate barcode and QR code labels"
                            },
                        ]
                    }, 
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: Rocket32,
                                title: "Performance",
                                desc: "Compare product performance"
                            },
                        ]
                    }
                ]
            },
            {
                title: "Relations",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: Delivery32,
                                title: "Suppliers",
                                desc: "View, add and edit suppliers",
                                link: "/suppliers"
                            },
                            {
                                icon: AirlinePassengerCare32,
                                title: "Customers",
                                desc: "View, add and edit customers",
                                link: "/customers"
                            },
                            {
                                icon: Events32,
                                title: "Groups",
                                desc: "View, add and edit customer groups",
                                link: "/customer-groups"
                            },
                            { 
                                icon: Gift32,
                                title: "Loyalty",
                                desc: "View, add and edit loyalty cards",
                                link: "/loyalty-cards"
                            },
                            {
                                icon: CutOut32,
                                title: "Coupons",
                                desc: "View, add and edit coupons",
                                link: "/coupons"
                            },
                            {
                                icon: TagGroup32,
                                title: "Discounts",
                                desc: "View, add and edit store discount periods",
                                link: "/discounts"
                            },
                            {
                                icon: Percentage32,
                                title: "Quotations",
                                desc: "View, add and edit custom quotations",
                                link: "/quotations"
                            },
                            {
                                icon: Headset32,
                                title: "Contacts",
                                desc: "View, add and edit contact informations",
                                link: "/contacts"
                            }
                        ]
                    }
                ]
            },
            {
                title: "Company",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: User32,
                                title: "Staff Users",
                                desc: "View, add and edit staff users",
                                link: "/staff-users"
                            },
                            {
                                icon: Store32,
                                title: "Stores",
                                desc: "View, add and edit stores",
                                link: "/stores"
                            },
                            {
                                icon: DataVis_232,
                                title: "Warehouses",
                                desc: "View, add and edit warehouses",
                                link: "/warehouses"
                            },
                            {
                                icon: Screen32,
                                title: "Terminals",
                                desc: "View, add and edit terminals",
                                link: "/terminals"
                            },
                        ]
                    }, 
                ]
            },
            {
                title: "System",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: Archive32,
                                title: "Logs",
                                desc: "View system logs"
                            },
                            {
                                icon: WatsonHealthTextAnnotationToggle32,
                                title: "Values",
                                desc: "Set system values"
                            },
                            {
                                icon: StringText32,
                                title: "Attributes",
                                desc: "Define custom system attributes"
                            },
                            {
                                icon: DocumentImport32,
                                title: "Import",
                                desc: "Import spreadsheet data into system"
                            },
                        ]
                    },
                ]
            },
        ]
    }

    render() {
        return (
            <div style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: '#000000DD', zIndex: 100000,
            display: 'flex', justifyContent: 'center', paddingTop: '6rem', color: 'white', overflow: 'auto'}}>
                <div style={{width: '75vw'}}>
                    <div style={{ marginBottom: '3rem', display: 'flex', alignItems: 'center' }}>
                        <h1 style={{flex: 1}}>Menu</h1>
                        <div style={{marginLeft: 25}} className="white-search">
                            {/* <ExpandableSearch style={{background: 'transparent', color: 'white', alignSelf: 'flex-end'}} placeholder="Search Menu"/> */}
                            <Button className="bx--tooltip--hidden" hasIconOnly renderIcon={Close16} kind="secondary" onClick={this.props.onClose} />
                        </div>
                    </div>

                    {this.getMenu().map(menuGroup => (<>
                        <h4>{menuGroup.title}</h4>
                        {menuGroup.subMenus.map((subMenu, index) => (<>
                            {Util.isStringExists(subMenu.title) ?
                            <p style={{fontSize: 12, opacity: 0.65, marginBottom: '1rem'}}>{subMenu.title}</p> : 
                            <div style={{marginBottom: '1rem'}} />}

                            <div style={{marginBottom: (index == menuGroup.subMenus.length - 1) ? '6rem' : '3rem', display: 'flex', gap: 15, flexWrap: 'wrap'}} 
                            className="menu-tile">
                                {subMenu.menus.map(menu => (
                                    <Link to={menu.link} onClick={menu.link !== undefined && (() => this.props.onClose())} style={{textDecoration: 'none', opacity: Util.isStringExists(menu.link) ? 1 : 0.4}}>
                                        <MenuItem key={menu.title} icon={menu.icon} title={menu.title} desc={menu.desc} />
                                    </Link>
                                ))}
                            </div>
                        </>))}
                    </>))}


                    <div style={{height: '6rem'}} />
                </div>
            </div>
        )
    }

}

export default FullMenu;