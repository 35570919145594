import { ComposedModal, ModalBody, ModalFooter, ModalHeader, Checkbox, TextArea, TextInput } from "carbon-components-react";
import { ComboBoxField } from "../../../../../components/ComboBoxField";
import { makeListInput } from "../../../../../components/list-input";
import { Close16, ListBoxes16 } from '@carbon/icons-react'
import Util from "../../../../../util/Util";
import { UploadField } from "../../../../../components/upload/upload-field";
import Button from "../../../../../components/Button";
import Portal from "../../../../../util/Portal";
import { useState } from "react";

const ImagesEditor = makeListInput(({ item, setItemValue, onRemove, SortHandler }) => {
    return (<>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%', padding: '1rem', paddingTop: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 7, }}>
            <UploadField dontClear imageMode uploadMsg="Select" selectMsg="Upload image" value={item.inspectionImage} setValue={value => setItemValue('inspectionImage', value)} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>
    </>)
}, () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
), false)


const ItemsBtn = ({ text, children }) => {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button style={{ borderRadius: 50, width: '100%' }} size="sm" renderIcon={ListBoxes16} onClick={() => setVisible(true)}>{text}</Button>

        <Portal>
            <ComposedModal size="lg" open={visible} onClose={() => setVisible(false)}>
                <ModalHeader label="Item" title="Inspection" />
                <ModalBody hasScrollingContent>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setVisible(false)} kind="secondary">Close</Button>
                </ModalFooter>
            </ComposedModal>
        </Portal>
    </>)
}

export const PortStaffPackageInspectionListEditor = makeListInput(({ item, setItemValue, onRemove, SortHandler, packageTypes }) => {
    if (!Util.isNumberExist(item.id)) {
        return <div />;
    }
    return (<>
        <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h6>Package: {item.id}</h6>
                <div style={{ flex: 1 }} />
                {/* <button onClick={onRemove} className='lead-flow-icon-button'>
                    <Close16 />
                </button> */}
            </div>

            {/* <Images /> */}
            <label className="bx--label">Inspection images</label>
            <div style={{ background: '#00000010', padding: '1rem', width: '100%', borderRadius: 10, }}>
                <ImagesEditor value={item.inspectionImages ?? []} setValue={value => {
                    if (typeof value === "function") {
                        setItemValue('inspectionImages', value(item.inspectionImages ?? []))
                    } else {
                        setItemValue('inspectionImages', value)
                    }
                }} />
            </div>

            <div style={{ width: '100%', marginBottom: '1rem', paddingLeft: '6rem' }}>
                {item.items?.map(packageItem => (<>
                    <div style={{ marginTop: '1rem' }}>
                        <h6>Item: {packageItem.id}</h6>
                        <label className="bx--label">Item specific inspection</label>
                        <ItemsBtn text={(packageItem.inspectionImages?.length ?? 0) + ' image' + (packageItem.inspectionImages?.length != 1 ? 's' : '')}>
                            <div style={{ background: '#00000010', padding: '1rem', width: '100%', borderRadius: 10, }}>
                                <ImagesEditor value={packageItem.inspectionImages ?? []} setValue={value => {
                                    if (typeof value === "function") {
                                        setItemValue('items', item.items.map(e => e.id === packageItem.id ? ({
                                            ...packageItem,
                                            inspectionImages: value(packageItem.inspectionImages ?? [])
                                        }) : e))
                                    } else {
                                        setItemValue('items', item.items.map(e => e.id === packageItem.id ? ({
                                            ...packageItem,
                                            inspectionImages: value
                                        }) : e))
                                    }
                                }} />
                            </div>
                        </ItemsBtn>
                    </div>
                </>))}
            </div>

            {item.confirmed && <TextArea value={item.confirmNote} placeholder="Confirm note..." onChange={e => setItemValue("confirmNote", e.target.value)} />}

        </div>


    </>)
}, () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
), true)