
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import ProfilePic from "./ProfilePic";

function trimSrcs(srcs) {
    const max = 4;
    if (srcs.length > max) {
        return [...srcs.slice(0, 4), { rem: srcs.length - max }]
    } else {
        return srcs;
    }
}

function RemItems({ size, rem }) {
    return (
        <div style={{ width: size, height: size, background: 'black', color: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h6>{rem}+</h6>
        </div>
    )
}

export function ProfilePicGroup({ srcs, reverse, ...props }) {

    return (
        <div style={{ display: 'flex', position: 'relative', width: props.size, height: props.size }}>
            {trimSrcs(srcs)
                .map((src, index) => (
                    <div style={{ position: 'absolute', top: 0, [reverse ? 'left' : 'right']: (props.size / 2) * index, }}>
                        {src?.rem ?
                            <RemItems size={props.size} rem={src.rem} /> :
                            <ProfilePic {...props} src={src} />
                        }
                        {/* marginRight: -16 * index  */}
                    </div>
                ))}
        </div>
    )
}

export function ProfilePicGroupWithName({ srcs, reverse, ...props }) {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [srcs])
    return (
        <div style={{ display: 'flex', position: 'relative', width: props.size, height: props.size }}>
            {trimSrcs(srcs)
                .map((src, index) => (
                    <div style={{ position: 'absolute', top: 0, [reverse ? 'left' : 'right']: (props.size / 2) * index, }}>
                        {src?.rem ?
                            <RemItems size={props.size} rem={src.rem} /> :
                            <ProfilePic {...props} src={src.url} tip={src.name} tipEffect="solid" />
                        }
                        {/* marginRight: -16 * index  */}
                    </div>
                ))}

            <ReactTooltip />
        </div>
    )
}