import { GroupPresentation16, CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { ComposedModal, ModalBody, ModalFooter, ModalHeader, Tag, TextArea } from 'carbon-components-react';
import { useState } from 'react';
import Button from '../../../../components/Button';
import { STUDENT_PRESENCE } from '../../../../constants/Constants';
import Api from '../../../../session/Api';
import UIUtil from '../../../../util/UIUtil';
import Util from '../../../../util/Util';

const AttendanceNoteDialog = ({ onClose, onConfirm }) => {
    const [note, setNote] = useState("");

    return (
        <ComposedModal key="student-attandance-note" size="sm" open onClose={onClose}>
            <ModalHeader label="Student Attendance" title="Note Required" />
            <ModalBody>
                <p style={{ fontSize: 14 }}>Please specify reason for contradictory attendance decision</p>

                <div style={{ height: '1rem' }} />
                <TextArea
                    value={note}
                    onChange={e => setNote(e.target.value)}
                    labelText={"Note"}
                    placeholder={"Input note"}
                />

            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                    Close
                </Button>
                <Button disabled={!Util.isStringExists(note)} onClick={() => onConfirm(note)} renderIcon={CheckmarkFilled16}>
                    Confirm
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}

const ConfirmButton = ({ activityId, studentId, setPresences, studentPresence, attendanceRecord }) => {
    const [loading, setLoading] = useState(false);
    const onConfirm = () => {
        const performAttendance = note => {
            setLoading(true);
            Api.createStudentPresenceConfirmation(activityId, STUDENT_PRESENCE.PRESENT, studentId, note, response => {
                if (response.status === true) {
                    setPresences(response.payload)
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false)
            })
        }

        const shouldRequireNote =
            studentPresence?.state !== STUDENT_PRESENCE.PRESENT ||
            attendanceRecord?.allowedToProceed !== true;

        if (shouldRequireNote) {
            UIUtil.showOverlay2(onClose => <AttendanceNoteDialog onClose={onClose} onConfirm={note => {
                onClose();
                performAttendance(note);
            }} />)
        } else {
            performAttendance(null);
        }
    }
    return (
        <Button onClick={onConfirm} loading={loading} size="sm" style={{ borderRadius: 50 }} renderIcon={CheckmarkFilled16}>
            Present
        </Button>
    )
}

const CancelButton = ({ presenceConfirmation, setPresences, children }) => {
    const [loading, setLoading] = useState(false);
    const onBtn = () => {
        setLoading(true);
        Api.cancelStudentPresenceConfirmation(presenceConfirmation.id, response => {
            if (response.status === true) {
                setPresences(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }
    return (
        <Button loading={loading} onClick={onBtn} size="sm" style={{ borderRadius: 50 }} kind="secondary" renderIcon={ErrorFilled16}>
            {children}
        </Button>
    )
}

const AbsentButton = ({ activityId, studentId, setPresences, studentPresence, attendanceRecord }) => {
    const [loading, setLoading] = useState(false);
    const onConfirm = () => {
        const performAttendance = note => {
            setLoading(true);
            Api.createStudentPresenceConfirmation(activityId, STUDENT_PRESENCE.ABSENT, studentId, note, response => {
                if (response.status === true) {
                    setPresences(response.payload)
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false)
            })
        }

        const shouldRequireNote =
            studentPresence?.state !== STUDENT_PRESENCE.ABSENT ||
            attendanceRecord?.allowedToProceed !== false;

        if (shouldRequireNote) {
            UIUtil.showOverlay2(onClose => <AttendanceNoteDialog onClose={onClose} onConfirm={note => {
                onClose();
                performAttendance(note);
            }} />)
        } else {
            performAttendance(null);
        }
    }
    return (
        <Button onClick={onConfirm} loading={loading} size="sm" style={{ borderRadius: 50 }} kind="danger" renderIcon={ErrorFilled16}>
            Absent
        </Button>
    )
}

export function ActualStudentAttendance({ activityId, studentId, classDate, presenceConfirmation, setPresences, studentPresence, attendanceRecord }) {
    if (classDate > new Date().getTime()) {
        return (
            <div style={{ marginTop: '1rem', borderRadius: 15, border: '1px solid #ffffff40', background: 'black', color: 'white', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center' }}>
                <GroupPresentation16 />
                <p style={{ fontSize: 12, marginLeft: '0.25rem', opacity: 0.65, fontStyle: 'italic', fontWeight: 'bold', flex: 1 }}>Actual Attendance</p>
                <Tag size='sm'>class not started</Tag>
            </div>
        )
    } else {
        if (presenceConfirmation) {
            return (
                <div style={{ marginTop: '1rem', borderRadius: 15, border: '1px solid #ffffff40', background: 'black', color: 'white', paddingInline: '1rem', paddingBlock: '1rem', }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <GroupPresentation16 />
                        <div style={{ marginLeft: '0.5rem', flex: 1 }}>
                            <p style={{ fontSize: 14, opacity: 0.65, fontStyle: 'italic', fontWeight: 'bold', }}>Actual Attendance</p>
                            <p style={{ fontSize: 12, marginTop: -2, opacity: 0.65 }}>Confirmed by tutor</p>
                        </div>
                        {presenceConfirmation.state === STUDENT_PRESENCE.PRESENT ? (
                            <Tag size='sm' type='green'>present confirmed</Tag>
                        ) : presenceConfirmation.state === STUDENT_PRESENCE.ABSENT ? (
                            <Tag size='sm' type='red'>absent confirmed</Tag>
                        ) : null}
                    </div>
                    {Util.isStringExists(presenceConfirmation.note) && <div style={{ marginTop: '1rem' }}>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Note</p>
                        <p style={{ fontSize: 14, whiteSpace: 'pre-line' }}>{presenceConfirmation.note}</p>
                    </div>}
                    <div style={{ width: '100%', marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.5rem' }}>
                        <CancelButton {...({ presenceConfirmation, setPresences })}>
                            Cancel Confirmation
                        </CancelButton>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ marginTop: '1rem', borderRadius: 15, border: '1px solid #ffffff40', background: 'black', color: 'white', paddingInline: '1rem', paddingBlock: '0.5rem', }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <GroupPresentation16 />
                        <p style={{ fontSize: 12, marginLeft: '0.25rem', opacity: 0.65, fontStyle: 'italic', fontWeight: 'bold', flex: 1 }}>Actual Attendance</p>
                        <Tag size='sm'>attendance not confirmed</Tag>
                    </div>
                    <div style={{ width: '100%', marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.5rem' }}>
                        <AbsentButton {...({ activityId, studentId, setPresences, studentPresence, attendanceRecord })} />
                        <ConfirmButton {...({ activityId, studentId, setPresences, studentPresence, attendanceRecord })} />
                    </div>
                </div>
            )
        }
    }
}