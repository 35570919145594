import ThermalPrinter from "../ThermalPrinter";
import ThermalPrinterDriver from "../ThermalPrinterDriver";
import Logo from '../../../images/SquareLogo.png';
import ReceiptLogo from '../../../images/ReceiptLogo.png'
import Util from "../../../util/Util";
import { paymentMethodText } from "../../../pages/transaction/TransactionEditor";
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'
import { COMPANY_NAME, THERMAL_RECEIPT_LOGO, VAT } from "../../../app/Config";

class EpsonThermalPrinterDriver extends ThermalPrinterDriver {

    device = new window.epson.ePOSDevice();
    printer;

    widthInCharacters = 42;

    printPromiseResolve;
    printPromiseReject;
    printPromiseTimeout;

    async connect(params) {
        return new Promise((resolve, reject) => {
            this.device.connect(params.ipAddress, params.port, connectResult => {
                if (connectResult == "OK" || connectResult == "SSL_CONNECT_OK") {
                    this.device.createDevice(params.deviceId, this.device.DEVICE_TYPE_PRINTER, {
                        crypto: false,
                        buffer: false,
                    }, (createdDevice, errorCode) => {
                        if (createdDevice) {
                            this.printer = createdDevice;
                            this.printer.onreceive = this.onPrinterEvent.bind(this);
                            resolve();
                        } else {
                            reject(errorCode);
                        }
                    });
                } else {
                    reject(connectResult);
                }
            });
        })
    }

    onPrinterEvent(event) {
        if (event.success) {
            if (this.printPromiseResolve) {
                this.printPromiseResolve();
            }
        } else {
            if (this.printPromiseReject) {
                this.printPromiseReject(event.code);
            }
        }

        if ((this.printPromiseResolve || this.printPromiseReject) && this.printPromiseTimeout) {
            clearTimeout(this.printPromiseTimeout);
        }

        this.printPromiseResolve = undefined;
        this.printPromiseReject = undefined;
        this.printPromiseTimeout = undefined;


        let msg = "Print failed: ";
        const asb = event.status;
        if (asb & this.printer.ASB_NO_RESPONSE) { msg += ' No printer response\n'; }
        if (asb & this.printer.ASB_PRINT_SUCCESS) { msg += ' Print complete\n'; }
        if (asb & this.printer.ASB_DRAWER_KICK) { msg += ' Status of the drawer kick number 3 connector pin = "H"\n'; }
        if (asb & this.printer.ASB_OFF_LINE) { msg += ' Offline status\n'; }
        if (asb & this.printer.ASB_COVER_OPEN) { msg += ' Cover is open\n'; }
        if (asb & this.printer.ASB_PAPER_FEED) { msg += ' Paper feed switch is feeding paper\n'; }
        if (asb & this.printer.ASB_WAIT_ON_LINE) { msg += '  Waiting for online recovery\n'; }
        if (asb & this.printer.ASB_PANEL_SWITCH) { msg += ' Panel switch is ON\n'; }
        if (asb & this.printer.ASB_MECHANICAL_ERR) { msg += ' Mechanical error generated\n'; }
        if (asb & this.printer.ASB_AUTOCUTTER_ERR) { msg += ' Auto cutter error generated\n'; }
        if (asb & this.printer.ASB_UNRECOVER_ERR) { msg += ' Unrecoverable error generated\n'; }
        if (asb & this.printer.ASB_AUTORECOVER_ERR) { msg += ' Auto recovery error generated\n'; }
        if (asb & this.printer.ASB_RECEIPT_NEAR_END) { msg += ' No paper in the roll paper near end detector\n'; }
        if (asb & this.printer.ASB_RECEIPT_END) { msg += ' No paper in the roll paper end detector\n'; }
        if (asb & this.printer.ASB_SPOOLER_IS_STOPPED) { msg += ' Stop the spooler\n'; }
        if (event.success == false) {
            alert(msg);
        }
    }

    async disconnect() {
        return new Promise((resolve, reject) => {
            this.device.deleteDevice(this.printer, () => {
                this.device.disconnect();
                resolve();
            });
        })
    }

    async printSalesSummary(report) {
        const printer = this.printer;

        //Init
        printer.addPulse(printer.DRAWER_1, printer.PULSE_100);
        printer.brightness = 1.0;
        printer.halftone = printer.HALFTONE_ERROR_DIFFUSION;

        //Content
        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addText('\n');


        printer.addTextStyle(false, false, true, printer.COLOR_1);
        printer.addText("Robotic ERP\n")

        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addText("POS Sales Summary\n")
        printer.addText(`Printed on ${Util.getFullDate(new Date().getTime())}\n`)

        printer.addTextAlign(printer.ALIGN_LEFT);



        const header1 = (label) => {
            printer.addText("\n\n");
            printer.addTextStyle(false, false, true, printer.COLOR_2);
            printer.addText(label + "\n")
            printer.addTextStyle(false, false, false, printer.COLOR_1);
            printer.addText(this.getLine());
        }
        const infoField1 = (label, value) => {
            printer.addTextStyle(false, false, true, printer.COLOR_1);
            printer.addText(label)
            printer.addTextStyle(false, false, false, printer.COLOR_1);
            printer.addText(this.weightText(value, this.widthInCharacters - label.length, false));
            printer.addText('\n');
        }


        header1("POS Info")
        infoField1("Store Name:", report.storeName)
        infoField1("Terminal Name:", report.terminalName)
        infoField1("Operator Name:", report.operatorName)

        header1("Session Info")
        infoField1("Session ID:", report.posSessionId)
        infoField1("Opening Date:", Util.getFullDate(report.openingDate))
        if (Util.isNumberExist(report.closingDate)) {
            infoField1("Closing Date:", Util.getFullDate(report.closingDate))
        }
        infoField1("Opening Amount:", report.openingAmount.toFixed(2))

        header1("Sales Report")
        infoField1("Total Sales:", report.totalSales.toFixed(2))
        infoField1("    By Cash:", report.totalSalesByCash.toFixed(2))
        infoField1("    By Card:", report.totalSalesByNonCash.toFixed(2))
        if (Util.isNumberExist(report.totalSalesByCustomerCredit)) {
            infoField1("    By Customer Credit:", report.totalSalesByCustomerCredit.toFixed(2))
        }

        header1("Return Report")
        infoField1("Total Returns:", report.totalReturn?.toFixed(2))
        infoField1("      By Cash:", report.cashReturnAmount?.toFixed(2))
        infoField1("      By Card:", report.cardReturnAmount?.toFixed(2))

        header1("Calculation")
        // infoField1("Cash return:", report.cashReturnAmount.toFixed(2))
        infoField1("Cash in drawer:", report.calculatedCashInDrawer.toFixed(2))


        //Finalize
        printer.addFeedLine(3);
        printer.addCut(printer.CUT_FEED);
        printer.send();

        return new Promise((resolve, reject) => {
            this.printPromiseResolve = resolve;
            this.printPromiseReject = reject;

            this.printPromiseTimeout = setTimeout(() => {
                if (this.printPromiseReject === reject) {
                    reject();

                    this.printPromiseResolve = undefined;
                    this.printPromiseReject = undefined;
                    this.printPromiseTimeout = undefined;
                }
            }, 5000)
        });
    }

    async printReceipt(posSessionSnapshot) {
        const printer = this.printer;
        const logo = await this.getLogoContext();
        const barcode = await this.getBarcodeContext(posSessionSnapshot.lastStockFlowRecordId)


        printer.addPulse(printer.DRAWER_1, printer.PULSE_100);

        printer.brightness = 1.0;
        printer.halftone = printer.HALFTONE_ERROR_DIFFUSION;

        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addImage(logo, 0, 0, THERMAL_RECEIPT_LOGO.width, THERMAL_RECEIPT_LOGO.height);


        printer.addText('\n\n');

        printer.addTextStyle(false, false, true, printer.COLOR_1);
        //printer.addText("DELUXXE GENERAL TRADING" + "\n")
        printer.addText(COMPANY_NAME.toUpperCase() + "\n")
        printer.addTextStyle(false, false, false, printer.COLOR_1);

        printer.addText(posSessionSnapshot.receiptInfo.usrsys_address + "\n")
        printer.addText("Tel: " + posSessionSnapshot.receiptInfo.usrsys_phone + "\n")
        printer.addText("TRN: " + posSessionSnapshot.receiptInfo.usrsys_trn + "\n")

        printer.addText("\n");
        printer.addText("Tax Invoice" + "\n")

        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addText("\n\n");

        printer.addText(this.getLine());

        printer.addText('#   ');
        printer.addText(this.weightText("Description", 24, true));
        printer.addText(' ');
        printer.addText(this.weightText("Qty", 4, false));
        printer.addText(' ');
        printer.addText(this.weightText("Amount", 8, false));
        printer.addText('\n');

        printer.addText(this.getLine());

        let i = 0;
        for (const item of posSessionSnapshot.currentCartItems) {
            i++;

            if (i > 1) {
                printer.addText('\n\n')
            }
            printer.addText(this.weightText(i, 3, true))
            printer.addText(' ');
            printer.addText(this.weightText(item.ref, 24, true));
            printer.addText(' ');
            printer.addText(this.weightText(item.quantityValue + 'x', 4, false));
            printer.addText(' ');
            printer.addText(this.weightText(item.totalAmount.price.toFixed(2), 8, false));

            printer.addText('\n');
            printer.addText('    ');
            if (item.name.length > 24) {
                printer.addText(item.name.substring(0, 24));
                printer.addText('\n');
                printer.addText('    ');
                printer.addText(this.weightText(item.name.substring(24), 24, true));
            } else {
                printer.addText(this.weightText(item.name, 24, true));
            }
        }

        printer.addText('\n\n');
        printer.addText(this.getLine())
        printer.addText("Total Items: " + posSessionSnapshot.currentCartItems.length)
        printer.addText('\n');
        printer.addText(this.getLine())
        printer.addText('\n');



        printer.addTextStyle(false, false, true, printer.COLOR_1);
        printer.addText("Subtotal")
        printer.addTextStyle(false, false, false, printer.COLOR_1);

        printer.addText(this.weightText(posSessionSnapshot.posTotals.subtotal.toFixed(2), 34, false));
        printer.addText('\n');

        if (posSessionSnapshot.posTotals.discount !== undefined && posSessionSnapshot.posTotals.discount !== null && posSessionSnapshot.posTotals.discount > 0) {
            printer.addTextStyle(false, false, true, printer.COLOR_1);
            printer.addText("Discount")
            printer.addTextStyle(false, false, false, printer.COLOR_1);
            printer.addText(this.weightText(posSessionSnapshot.posTotals.discount.toFixed(2), 34, false));
            printer.addText('\n');
        }

        printer.addTextStyle(false, false, true, printer.COLOR_1);
        printer.addText("VAT " + `${VAT.PERC}%`)
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addText(this.weightText(posSessionSnapshot.posTotals.tax.toFixed(2), 36, false));
        printer.addText('\n');

        printer.addTextDouble(true);
        printer.addText("Grand Total")
        //printer.addText(this.weightText(posSessionSnapshot.posTotals.total.toFixed(2), 31, false));
        printer.addText(this.weightText(posSessionSnapshot.posTotals.total.toFixed(2), 10, false));
        printer.addTextDouble(false);
        printer.addText('\n');


        if (posSessionSnapshot.posTotals.totalSavings > 0) {
            printer.addTextAlign(printer.ALIGN_CENTER);
            printer.addText('\n');
            printer.addText('You have saved ' + posSessionSnapshot.posTotals.currency + ' ' + posSessionSnapshot.posTotals.totalSavings + '!');
            printer.addText('\n');
            printer.addTextAlign(printer.ALIGN_LEFT);
        }

        printer.addText("\n\n\n")

        printer.addTextStyle(false, true, false, printer.COLOR_1);
        printer.addText("Payment" + "\n")
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        for (const item of posSessionSnapshot.paymentMethods) {
            printer.addText(this.weightText(paymentMethodText(item.methodType), 28, true));
            printer.addText(' ');
            printer.addText(this.weightText(item.amount.toFixed ? item.amount.toFixed(2) : parseFloat(item.amount).toFixed(2), 13, false));
            printer.addText('\n');
        }


        if (Util.isNumberExist(posSessionSnapshot.cashbackAmount)) {
            printer.addText("\n")
            printer.addText(this.weightText("Balance Returned", 28, true));
            printer.addText(' ');
            printer.addText(this.weightText(posSessionSnapshot.cashbackAmount.toFixed(2), 13, false));
            printer.addText('\n');
        }


        printer.addText('\n\n');
        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addText('Thank you for shopping with us!' + "\n");
        printer.addText(Util.getFullDate(new Date().getTime()) + "\n");



        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addText('\n\n');
        printer.addTextStyle(false, true, false, printer.COLOR_1);
        printer.addText("Terms & Conditions" + "\n")
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addText(posSessionSnapshot.receiptInfo.usrsys_terms_and_conditions)
        printer.addText('\n');


        printer.addText('\n\n');


        printer.addTextAlign(printer.ALIGN_CENTER);
        //printer.addText('Transaction #' + Util.getVoucherNumber(posSessionSnapshot.lastTransactionId));
        printer.addText('Sales #' + Util.getVoucherNumber(posSessionSnapshot.lastStockFlowId));
        printer.addText('\n');
        printer.addText(posSessionSnapshot.lastStockFlowRecordId);
        printer.addText('\n\n');

        //
        printer.addImage(barcode, 0, 0, 256, 256);
        // printer.addImage(barcode, 0, 0, 512, 75);

        printer.addFeedLine(3);
        printer.addCut(printer.CUT_FEED);
        printer.send();


        return new Promise((resolve, reject) => {
            this.printPromiseResolve = resolve;
            this.printPromiseReject = reject;

            this.printPromiseTimeout = setTimeout(() => {
                if (this.printPromiseReject === reject) {
                    reject();

                    this.printPromiseResolve = undefined;
                    this.printPromiseReject = undefined;
                    this.printPromiseTimeout = undefined;
                }
            }, 5000)
        });

        // printer.addText('Receipt\n');
        // printer.addTextVPosition(86);
        // printer.addText('Thank you for shopping with us!');
        // printer.addText('\n');

        // printer.addText('\n\n');
        // printer.addText(Util.getFullDate(new Date().getTime()));
        // printer.addText('\n');

        // printer.addText(this.getLine());

        // printer.addTextAlign(printer.ALIGN_LEFT);
        // for (const item of posSessionSnapshot.currentCartItems) {
        //     printer.addText('\n');
        //     printer.addText(this.weightText(item.name, 28, true));
        //     printer.addText(' ');
        //     printer.addText(this.weightText(item.quantityValue + 'x', 4, false));
        //     printer.addText(' ');
        //     printer.addText(this.weightText(item.totalAmount.price.toFixed(2), 8, false));
        // }

        // printer.addText('\n\n');




        // printer.addTextAlign(printer.ALIGN_RIGHT);

        // let strSubtotal = posSessionSnapshot.posTotals.currency + ' ' + posSessionSnapshot.posTotals.subtotal.toFixed(2);
        // let strDiscount = posSessionSnapshot.posTotals.currency + ' ' + posSessionSnapshot.posTotals.discount.toFixed(2);
        // let strTax = posSessionSnapshot.posTotals.currency + ' ' + posSessionSnapshot.posTotals.tax.toFixed(2);
        // let strTotal = posSessionSnapshot.posTotals.currency + ' ' + posSessionSnapshot.posTotals.total.toFixed(2);

        // // Net
        // printer.addTextStyle(false, false, true, printer.COLOR_1);
        // printer.addText('Subtotal (+)');
        // printer.addTextStyle(false, false, false, printer.COLOR_1);
        // printer.addText('\n');
        // printer.addText(this.weightText(strSubtotal, this.widthInCharacters, false));
        // printer.addText('\n');

        // printer.addTextStyle(false, false, true, printer.COLOR_1);
        // printer.addText('Discount (-)');
        // printer.addTextStyle(false, false, false, printer.COLOR_1);
        // printer.addText('\n');
        // printer.addText(this.weightText(strDiscount, this.widthInCharacters, false));
        // printer.addText('\n');

        // // Tax
        // printer.addTextStyle(false, false, true, printer.COLOR_1);
        // printer.addText('Tax (+)');
        // printer.addTextStyle(false, false, false, printer.COLOR_1);
        // printer.addText('\n');
        // printer.addText(this.weightText(strTax, this.widthInCharacters, false));
        // printer.addText('\n');


        // // Total
        // printer.addTextDouble(true);
        // printer.addText('Grand Total');
        // printer.addText('\n');
        // printer.addText(this.weightText(strTotal, this.widthInCharacters, false));
        // printer.addTextDouble(false);
        // printer.addText('\n\n');



        // printer.addTextAlign(printer.ALIGN_CENTER);

        // if (posSessionSnapshot.posTotals.totalSavings > 0) {
        //     printer.addText('You have saved ' + posSessionSnapshot.posTotals.currency + ' ' + posSessionSnapshot.posTotals.totalSavings + '!');
        //     printer.addText('\n\n');
        // }




        // printer.addText(this.getLine());



        // printer.addTextAlign(printer.ALIGN_LEFT);

        // printer.addTextStyle(false, true, false, printer.COLOR_1);
        // printer.addText('Payment');
        // printer.addTextStyle(false, false, false, printer.COLOR_1);
        // for (const item of posSessionSnapshot.paymentMethods) {
        //     printer.addText('\n');
        //     printer.addText(this.weightText(paymentMethodText(item.methodType), 28, true));
        //     printer.addText(' ');
        //     // printer.addText(this.weightText(item.amount.toFixed(2), 13, false));
        //     printer.addText(this.weightText(item.amount.toFixed ? item.amount.toFixed(2) : parseFloat(item.amount).toFixed(2), 13, false));
        // }

        // printer.addText('\n\n');






        // printer.addTextAlign(printer.ALIGN_CENTER);

        // printer.addText('Transaction #' + Util.getVoucherNumber(posSessionSnapshot.lastTransactionId));
        // printer.addText('\n');
        // printer.addText(posSessionSnapshot.lastStockFlowRecordId);
        // // printer.addText('\n');
        // // printer.addBarcode(posSessionSnapshot.lastStockFlowRecordId, printer.BARCODE_CODE128, printer.HRI_NONE, printer.FONT_A, 3, 162);
        // // printer.addText('\n');
        // // printer.addBarcode(234725394738, printer.BARCODE_EAN13, printer.HRI_NONE, printer.FONT_A, 3, 162);

        // printer.addFeedLine(3);
        // printer.addCut(printer.CUT_FEED);
        // printer.send();


        // return new Promise((resolve, reject) => {
        //     this.printPromiseResolve = resolve;
        //     this.printPromiseReject = reject;

        //     this.printPromiseTimeout = setTimeout(() => {
        //         if (this.printPromiseReject === reject) {
        //             reject();

        //             this.printPromiseResolve = undefined;
        //             this.printPromiseReject = undefined;
        //             this.printPromiseTimeout = undefined;
        //         }
        //     }, 5000)
        // });
    }

    async openCashDrawer() {
        const printer = this.printer;
        printer.addPulse(printer.DRAWER_1, printer.PULSE_100);
        printer.send();

        return new Promise((resolve, reject) => {
            this.printPromiseResolve = resolve;
            this.printPromiseReject = reject;

            this.printPromiseTimeout = setTimeout(() => {
                if (this.printPromiseReject === reject) {
                    reject();

                    this.printPromiseResolve = undefined;
                    this.printPromiseReject = undefined;
                    this.printPromiseTimeout = undefined;
                }
            }, 5000)
        });
    }

    hasCashDrawerAccess() {
        return true;
    }

    isConnectionBased() {
        return true;
    }

    canPrintSalesSummary() {
        return true;
    }

    canPrintRestaurantDocs() {
        return false;
    }

    getParams() {
        return [
            {
                property: "ipAddress",
                name: "IP Address"
            },
            {
                property: "port",
                name: "Port"
            },
            {
                property: "deviceId",
                name: "Device ID"
            },
        ]
    }

    getLine() {
        let text = "";
        for (let i = 0; i < this.widthInCharacters; i++) {
            text += "-";
        }
        return text + "\n";
    }

    weightText(text, weight, leftAligned) {
        if (!text || !text.substring) {
            text = text + "";
        }

        if (text.length > weight) {
            text = text.substring(0, weight - 3) + "...";
        } else {
            const textLength = text.length;
            for (let i = 0; i < (weight - textLength); i++) {
                if (leftAligned) {
                    text += ' ';
                } else {
                    text = " " + text;
                }
            }
        }
        return text;
    }

    to2ByteChar(str) {
        return str;
    }

    async getLogoContext() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = THERMAL_RECEIPT_LOGO.width;
        canvas.height = THERMAL_RECEIPT_LOGO.height;

        const image = await this.loadImage(THERMAL_RECEIPT_LOGO.squareLogo ? Logo : ReceiptLogo);

        ctx.drawImage(image, 0, 0);

        return ctx;
    }

    async getBarcodeContext(value) {
        const canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;

        // JsBarcode(canvas, value, {
        //     format: "CODE128"
        // });
        QRCode.toCanvas(canvas, value, {
            width: 256
        });

        // var link = document.createElement('a');
        // link.download = 'filename.png';
        // link.href = canvas.toDataURL()
        // link.click();
        return canvas.getContext('2d')
    }

    loadImage(url) {
        return new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });
    }

}

export default EpsonThermalPrinterDriver;