import { Accordion, AccordionItem } from "carbon-components-react"
import { makeSettingComponent } from ".."

const CanEditJournalEntry = makeSettingComponent('can_edit_journal_entry')
const CanEditServiceSale = makeSettingComponent('can_edit_service_sale')
const CanEditBill = makeSettingComponent('can_edit_bill')
const CanEditTransaction = makeSettingComponent('can_edit_transaction')

export default ({ settingsValues }) => {
    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="Allow editing Journal Entry">
                <CanEditJournalEntry />
            </AccordionItem>
            <AccordionItem title="Allow editing Service Sale">
                <CanEditServiceSale />
            </AccordionItem>
            <AccordionItem title="Allow editing Bill">
                <CanEditBill />
            </AccordionItem>
            <AccordionItem title="Allow editing Transaction">
                <CanEditTransaction />
            </AccordionItem>
        </Accordion>
    )
}