import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_MANY_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_LOYALTY_CARD, OBJECT_TYPE_LOYALTY_CARD_ISSUE, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';

const IssuesList = ({item}) => {
    const history = useHistory();
    return (
        <div style={{marginTop: '1rem'}}>
            {TableTemplate.renderTemplate({
                getChildrenRequest: {
                    parentId: item.id,
                },
                embedded: true,
                title: "Issues", 
                subTitle: "Card issues", 
                objectType: OBJECT_TYPE_LOYALTY_CARD_ISSUE, 
                pagePath: "/loyalty-cards-issue/", 
                history: history
            })}
        </div>
    )
}


class LoyaltyCardDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_LOYALTY_CARD, listener)
            } else {
                Api.getItem(OBJECT_TYPE_LOYALTY_CARD, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_LOYALTY_CARD,
                    itemResult: this.state.itemResult,
                    pagePath: "/loyalty-cards/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Issues",
                            component: IssuesList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(LoyaltyCardDetailPage);