import { getTheme } from "../session/SessionManager";
import BlueTheme from "./impl/BlueTheme";
import GreenTheme from "./impl/GreenTheme";
import OrangeTheme from "./impl/OrangeTheme";
import OriginalTheme from "./impl/OriginalTheme";
import PinkTheme from "./impl/PinkTheme";
import RedTheme from "./impl/RedTheme";

export const getDefTheme = () => {
    const savedTheme = getTheme();
    if (savedTheme) {
        for (const theme of THEME_LIST) {
            if (theme.getName() == savedTheme) {
                return theme;
            }
        }
    }

    return new OriginalTheme()
}

export const THEME_LIST = [
    new OriginalTheme(),
    new OrangeTheme(),
    new BlueTheme(),
    new GreenTheme(),
    new RedTheme(),
    new PinkTheme()
]