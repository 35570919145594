import { useRefSize } from "../util/useSize"


export const Box = ({ children }) => {
    const [ref, { width }] = useRefSize()

    return (
        <div ref={ref} style={{ width: '100%', height: width }}>
            {typeof children === "function" ? children(width) : children}
        </div>
    )
}
