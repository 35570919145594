import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import Util from '../../../util/Util';
import { VAT } from '../../../app/Config';

const tableRowsCount = 2;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#ffb6a8',
  },
});

const InvoiceItemsTable = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow items={invoice.items} />
    {/* <InvoiceTableBlankSpace //rowsCount={ tableRowsCount - invoice.items.length} 
        rowsCount={invoice.items.length}
        /> */}
    <InvoiceTableFooter title="Subtotal" value={invoice.stockFlow.salesAmountSubtotal.toFixed(2)} />
    {Util.isNumberExist(invoice.stockFlow.salesAmountTax) &&
      <InvoiceTableFooter title={"VAT " + `${VAT.PERC}%`} value={invoice.stockFlow.salesAmountTax?.toFixed?.(2)} />}
    <InvoiceTableFooter title="Grand Total" value={invoice.stockFlow.amount.toFixed(2)} />
  </View>
);

export default InvoiceItemsTable