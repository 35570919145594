
import { TextInput } from 'carbon-components-react';
import React, { useEffect, useState } from 'react'
import Util from '../util/Util';


export const CustomStatelessDateTimePicker = ({ date, setDate, ...props }) => {
    const value = Util.formatDate(date, "YYYY-MM-DDTHH:mm");
    const setValue = value => setDate(new Date(value).getTime())

    const [key, setKey] = useState(() => Util.newTempId());
    useEffect(() => {
        if (!date) {
            setKey(Util.newTempId())
        }
    }, [date])

    return (
        <>
            <input key={key}
                {...props}
                type={"datetime-local"}
                onChange={e => setValue(e.target.value)}
                value={date === 0 ? undefined : value} />
        </>
    )
}

export const StatelessDateTimePicker = ({ date, setDate, ...props }) => {
    const value = Util.formatDate(date, "YYYY-MM-DDTHH:mm");
    const setValue = value => setDate(new Date(value).getTime())

    const [key, setKey] = useState(() => Util.newTempId());
    useEffect(() => {
        if (!date) {
            setKey(Util.newTempId())
        }
    }, [date])

    return (
        <>
            <TextInput
                key={key}
                {...props}
                type={"datetime-local"}
                onChange={e => setValue(e.target.value)}
                value={date === 0 ? undefined : value} />
        </>
    )
}


export const StatelessDatePicker = ({ date, setDate, ...props }) => {
    const value = Util.formatDate(date, "YYYY-MM-DD");
    const setValue = value => setDate(new Date(value).getTime())

    const [key, setKey] = useState(() => Util.newTempId());
    useEffect(() => {
        if (!date) {
            setKey(Util.newTempId())
        }
    }, [date])

    return (
        <>
            <TextInput
                key={key}
                {...props}
                type={"date"}
                onChange={e => setValue(e.target.value)}
                value={date === 0 ? undefined : value} />
        </>
    )
}




export default ({ defaultDate, onDateChanged, ...props }) => {
    const [value, setValue] = useState(() => Util.formatDate(defaultDate === 0 ? new Date().getTime() : defaultDate, "YYYY-MM-DDTHH:mm"));


    useEffect(() => {
        onDateChanged(new Date(value).getTime())
    }, [value])

    return (
        <>
            <TextInput
                {...props}
                type={"datetime-local"}
                onChange={e => setValue(e.target.value)}
                defaultValue={value} />
        </>
    )
}

