

import { fabric } from "fabric";
import Util from "../../../util/Util";
import { classExistsInSomeParent } from "../util/ParentFinder";
import { ComponentTypes } from "./ComponentTypes";
import LabelDesignerState from "./LabelDesignerState";
import html2canvas from "html2canvas"

import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'

import NoImage from '../../../images/NoImageSmall.png';
import Api from "../../../session/Api";

fabric.Object.prototype.cornerColor = '#000000BB';
fabric.Object.prototype.cornerStrokeColor = '#00000040';
fabric.Object.prototype.borderColor = "#00000060"

fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerSize = 6;
fabric.Object.prototype.cornerStyle = 'rect';

export const createCanvasBarcode = (value, type, color, background) => {
    let canvas = document.createElement('canvas');
    // canvas.width = 1000;
    // canvas.height =
    try {
        JsBarcode(canvas, value, {
            format: type,
            lineColor: color,
            // width: 1000,
            background: background
        });
    } catch (e) {
        const ctx = canvas.getContext('2d');

        ctx.fillStyle = background;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = color;
        ctx.font = '14px sans-serif';

        const textString = "Invalid barcode value and type";
        let metrics = ctx.measureText(textString);
        let textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        let textWidth = metrics.width;

        ctx.fillText(textString, (canvas.width / 2) - (textWidth / 2), (canvas.height / 2) - (textHeight / 2));
    }
    return canvas;
}

export const createCanvasQrCode = (value, color, background) => {
    let canvas = document.createElement('canvas');
    QRCode.toCanvas(canvas, value, {
        color: {
            dark: color,
            light: background
        }
    });
    return canvas;
}

export const createCanvasBarcodeWithSize = (value, type, color, background, size) => {
    let canvas = document.createElement('canvas');
    canvas.width = size.width;
    canvas.height = size.height;
    try {
        // JsBarcode()
        JsBarcode(canvas, value, {
            format: type,
            lineColor: color,
            background: background,
        });
    } catch (e) {
        const ctx = canvas.getContext('2d');

        ctx.fillStyle = background;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = color;
        ctx.font = '14px sans-serif';

        const textString = "Invalid barcode value and type";
        let metrics = ctx.measureText(textString);
        let textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        let textWidth = metrics.width;

        ctx.fillText(textString, (canvas.width / 2) - (textWidth / 2), (canvas.height / 2) - (textHeight / 2));
    }
    return canvas;
}

export const createSvgBarcode = async (value, type, color, background, size) => {
    const root = document.createElement("div");
    const container = document.createElement("div")
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

    container.appendChild(svg);
    root.appendChild(container);
    document.body.appendChild(root);
    try {
        JsBarcode(svg, value, {
            xmlDocument: document,
            format: type,
            lineColor: color,
            background: background,
        });

        const scaleX = size.width / svg.width.baseVal.value;
        const scaleY = size.height / svg.height.baseVal.value;

        root.setAttribute("style", `width:${size.width};height:${size.height}`);
        container.setAttribute("style", `width:${size.width};height:${size.height};transform-origin: top left;transform: scaleX(${scaleX}) scaleY(${scaleY})`);
        const canvas = await html2canvas(root, { width: size.width, height: size.height, });

        // const ctx = canvas.getContext('2d');
        // ctx.drawImage(svg, 0, 0);

        return canvas;
    } finally {
        document.body.removeChild(root);
    }
}

export const createRawSvgBarcode = async (value, type, color, background, size, displayValue) => {
    const root = document.createElement("div");
    const container = document.createElement("div")
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

    container.appendChild(svg);
    root.appendChild(container);
    document.body.appendChild(root);
    try {
        JsBarcode(svg, value, {
            xmlDocument: document,
            format: type,
            lineColor: color,
            background: background,
            displayValue
        });

        const scaleX = size.width / svg.width.baseVal.value;
        const scaleY = size.height / svg.height.baseVal.value;

        root.setAttribute("style", `width:${size.width};height:${size.height}`);
        container.setAttribute("style", `width:${size.width};height:${size.height};transform-origin: top left;transform: scaleX(${scaleX}) scaleY(${scaleY})`);
        const canvas = await html2canvas(root, { width: size.width, height: size.height, });

        // const ctx = canvas.getContext('2d');
        // ctx.drawImage(svg, 0, 0);

        return canvas;
    } finally {
        document.body.removeChild(root);
    }
}


const createCanvasItem = (pos, id, type, manager) => {
    switch (type) {
        case ComponentTypes.LINE:
            return new fabric.Rect({
                id: id,
                componentType: type,
                top: pos.top,
                left: pos.left,
                width: 100,
                height: 2,
                fill: '#cecece',
                stroke: 'black',
                strokeWidth: 0,
                lockSkewingX: true,
                lockSkewingY: true,
                strokeDashArray: [],

                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,
            })
        case ComponentTypes.CIRCLE:
            return new fabric.Rect({
                id: id,
                componentType: type,
                top: pos.top,
                left: pos.left,
                width: 50,
                height: 50,
                fill: '#cecece',
                stroke: 'black',
                strokeWidth: 0,
                lockSkewingX: true,
                lockSkewingY: true,
                strokeDashArray: [],

                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,

                rx: 50,
                ry: 50
            })
        case ComponentTypes.IMAGE:
            const image = new fabric.Image(null, {
                id: id,
                componentType: type,
                valueDynamic: false,

                top: pos.top,
                left: pos.left,
                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,
                fill: 'transparent',
                stroke: 'black',
                strokeWidth: 0,
                strokeDashArray: [],
            });
            image.setSrc(Api.getNoImagePlaceholder(), (image, error) => {
                if (!error) {
                    manager.canvas.renderAll();
                    manager.takeSnapshot();
                    // canvas.renderAll();
                }
            });
            return image;
        case ComponentTypes.BARCODE:
            return new fabric.Image(createCanvasBarcode("Barcode", "CODE128", "#000000", "#FFFFFF"), {
                id: id,
                componentType: type,
                barcodeValue: "Barcode",
                barcodeType: "CODE128",
                valueDynamic: false,

                top: pos.top,
                left: pos.left,
                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,
                fill: '#000000',
                backgroundColor: '#FFFFFF'
            })
        case ComponentTypes.QR_CODE:
            return new fabric.Image(createCanvasQrCode("QRCode"), {
                id: id,
                componentType: type,
                top: pos.top,
                left: pos.left,
                lockScalingFlip: true,
                lockSkewingX: true,
                lockSkewingY: true,
                qrValue: "QRCode",
                valueDynamic: false,
                fill: '#000000',
                backgroundColor: '#FFFFFF'
            })
        case ComponentTypes.TEXT:
            return new fabric.Textbox('Text', {
                id: id,
                componentType: type,
                valueDynamic: false,
                editable: false,
                top: pos.top,
                left: pos.left,
                stroke: 'black',
                strokeWidth: 0,
                backgroundColor: 'transparent',
                fontWeight: 'normal',
                fontFamily: 'sans-serif',
                fontSize: 20,
                textAlign: 'left',
                fontStyle: "normal",
                underline: false,
                lockSkewingX: true,
                lockSkewingY: true,
            })
        case ComponentTypes.SQUARE:
            return new fabric.Rect({
                id: id,
                componentType: type,
                top: pos.top,
                left: pos.left,
                width: 50,
                height: 50,
                fill: '#cecece',
                stroke: 'black',
                strokeWidth: 0,
                lockSkewingX: true,
                lockSkewingY: true,
                strokeDashArray: []
            })
    }
}

class CanvasManager {

    /**
     * @type {LabelDesignerState}
     */
    engine;

    /**
     * @type {HTMLCanvasElement}
     */
    nativeCanvas;

    /**
     * @type {fabric.Canvas}
     */
    canvas;

    /**
     * @type {Array<{ id: string, type: any }>}
     */
    components = [];

    /**
     * @type {any}
     */
    canvasSnapshot;

    closed;

    constructor(engine, nativeCanvas, size) {
        this.engine = engine;
        this.nativeCanvas = nativeCanvas;
        this.setup(size);
    }

    setup(size) {
        this.canvas = new fabric.Canvas(this.nativeCanvas, { ...size });
        if (this.engine.getState().savedTemplateData) {
            this.canvas.loadFromJSON(JSON.parse(this.engine.getState().savedTemplateData), () => {
                mainLoop: for (const object of this.canvas.getObjects()) {
                    for (const component of this.components) {
                        if (object.id == component.id) {
                            continue mainLoop;
                        }
                    }
                    this.components.push({ id: object.id, type: object.componentType })
                }

                this.canvas.renderAll();
                this.takeSnapshot();
            })
        }

        this.canvas.preserveObjectStacking = true;
        this.canvas.uniformScaling = false;

        window.addEventListener('mousedown', this.onClickHandle);
        document.addEventListener('keydown', this.keyDownListener);

        this.canvas.on('object:modified', () => this.recordAction())
        this.canvas.on('selection:cleared', () => this.updateSelectionState())
        this.canvas.on('selection:created', () => this.updateSelectionState())
        this.canvas.on('selection:updated', () => this.updateSelectionState())

        this.canvas.on("object:scaling", e => {
            const shape = e.target;
            if (shape.componentType == ComponentTypes.QR_CODE || shape.componentType == ComponentTypes.BARCODE || shape.componentType == ComponentTypes.IMAGE || shape.componentType == ComponentTypes.CIRCLE || shape.componentType == ComponentTypes.LINE) {
                if (e.transform.action == "scaleX") {
                    shape.scaleY = shape.scaleX;
                } else if (e.transform.action == "scaleY") {
                    shape.scaleX = shape.scaleY;
                } else {
                    shape.scaleX = shape.scaleX > shape.scaleY ? shape.scaleX : shape.scaleY;
                    shape.scaleY = shape.scaleY > shape.scaleX ? shape.scaleY : shape.scaleX;
                }

                if (shape.componentType == ComponentTypes.LINE) {
                    shape.scaleY = 1;
                }
            } else {
                shape.width = shape.scaleX * shape.width;
                shape.height = shape.scaleY * shape.height;
                shape.scaleX = 1;
                shape.scaleY = 1;
            }

            shape.dirty = true;
            this.canvas.renderAll();
        });

        let grid = 25;
        // Grid display part
        // for (var i = 0; i < (600 / grid); i++) {
        //     this.canvas.add(new fabric.Line([i * grid, 0, i * grid, 600], { stroke: '#ccc', selectable: false }));
        //     this.canvas.add(new fabric.Line([0, i * grid, 600, i * grid], { stroke: '#ccc', selectable: false }))
        // }
        this.canvas.on('object:moving', e => {
            if (!this.engine.get('showCanvasGrid')) {
                return;
            }

            if (Math.round(e.target.left / grid * 2) % 2 == 0 &&
                Math.round(e.target.top / grid * 2) % 2 == 0) {
                e.target.set({
                    left: Math.round(e.target.left / grid) * grid,
                    top: Math.round(e.target.top / grid) * grid
                }).setCoords();
            }
        });


        this.takeSnapshot();
    }

    bringToFront() {
        this.canvas.bringToFront(this.canvas.getActiveObject());
        this.recordAction();
    }

    bringForward() {
        this.canvas.bringForward(this.canvas.getActiveObject());
        this.recordAction();
    }

    sendBackward() {
        this.canvas.sendBackwards(this.canvas.getActiveObject());
        this.recordAction();
    }

    sendToBack() {
        this.canvas.sendToBack(this.canvas.getActiveObject());
        this.recordAction();
    }

    deleteSelection() {
        this.canvas.getActiveObjects().map(object => this.canvas.remove(object));
        this.canvas.discardActiveObject();
        this.canvas.renderAll();

        this.components = this.components.filter(component => {
            for (const object of this.canvas.getActiveObjects()) {
                if (object.id == component.id) {
                    return false;
                }
            }
            return true;
        })

        this.recordAction();
    }

    keyDownListener = (event => {
        if ((event.key == "Delete" || event.key == "Backspace") && this.canvas.getActiveObjects().length > 0 && !classExistsInSomeParent(event.target, "level-designer-inspector-root")) {
            this.deleteSelection();
        }
    }).bind(this);

    onClickHandle = (e => {
        classExistsInSomeParent(e.target)
        if (this.canvas.getActiveObjects().length > 0 && !(e.target.classList.contains("upper-canvas") || classExistsInSomeParent(e.target, "level-designer-inspector-root"))) {
            this.canvas.discardActiveObject();
            this.canvas.renderAll();
        }
    }).bind(this);

    addComponent(id, type, pos) {
        id = id ? id : Util.newTempId();
        const canvasItem = createCanvasItem(pos ? pos : { top: 15, left: 15 }, id, type, this);

        const component = {
            type, id,
        }

        this.components.push(component);
        this.canvas.add(canvasItem);
        // if (pos) {
        this.canvas.setActiveObject(canvasItem);
        // }
        this.takeSnapshot();
        return id;
    }

    removeComponent(id) {
        const objects = this.canvas.getObjects();
        for (let i = 0; i < objects.length; i++) {
            const object = objects[i];
            if (object.id == id) {
                this.canvas.remove(object);
                this.takeSnapshot();
                break
            }
        }

        for (let i = 0; i < this.components.length; i++) {
            const component = this.components[i];
            if (component.id == id) {
                this.components.splice(i, 1);
                break;
            }
        }
    }

    setObjectParams(id, params) {
        for (const object of this.canvas.getObjects()) {
            if (object.id == id) {
                let skipRecord = false;

                if (object.componentType == ComponentTypes.QR_CODE) {
                    if (object.qrValue != params.qrValue || object.fill != params.fill || object.backgroundColor != params.backgroundColor || object.valueDynamic != params.valueDynamic) {
                        object.setElement(createCanvasQrCode(params.valueDynamic ? Util.makeid(45) : params.qrValue, params.fill, params.backgroundColor))
                        // object.setElement(createCanvasQrCode(params.valueDynamic ? Util.makeid(4) : params.qrValue, params.fill, params.backgroundColor))
                    }
                } else if (object.componentType == ComponentTypes.BARCODE) {
                    if (object.barcodeValue != params.barcodeValue || object.barcodeType != params.barcodeType || object.fill != params.fill || object.backgroundColor != params.backgroundColor || object.valueDynamic != params.valueDynamic) {
                        object.setElement(createCanvasBarcode(params.valueDynamic ? '0000000000000' : params.barcodeValue, params.valueDynamic ? 'EAN13' : params.barcodeType, params.fill, params.backgroundColor))
                    }
                } else if (object.componentType == ComponentTypes.IMAGE) {
                    if (object.imageId != params.imageId || object.valueDynamic != params.valueDynamic) {
                        skipRecord = true;
                        object.setSrc(params.valueDynamic ? Api.getDynamicContentImage() : (Util.isStringExists(params.imageId) ? Api.getImage(params.imageId) : NoImage), () => {
                            this.canvas.renderAll();
                            this.recordAction();
                        });
                    }
                } else if (object.componentType == ComponentTypes.TEXT) {
                    //params.editable = !params.valueDynamic;
                    if (params.valueDynamic) {
                        if (!params.dynamicValueContent) {
                            params.dynamicValueContent = this.engine.getTemplate().dynamicTextValues[0];
                        }

                        if (object.dynamicValueContent != params.dynamicValueContent) {
                            params.text = params.dynamicValueContent.label;
                        }
                    } else if (params.dynamicValueContent) {
                        params.text = "Text";
                        params.dynamicValueContent = undefined;
                    }
                }
                object.set(params);
                this.canvas.renderAll();

                if (!skipRecord) {
                    this.recordAction();
                }
                break;
            }
        }
    }

    getObject(id) {
        for (const object of this.canvas.getObjects()) {
            if (object.id == id) {
                return object;
            }
        }
    }

    onResize(size) {
        this.canvas.setDimensions(size);
        this.canvas.calcOffset();
        this.canvas.renderAll();
    }

    onZoom(zoom) {
        fabric.Object.prototype.cornerSize = 6 * (1 / (zoom / 100));
    }

    updateSelectionState() {
        const activeObjects = this.canvas.getActiveObjects();
        if (activeObjects.length > 1) {
            this.engine.setState({ selectedComponent: { type: ComponentTypes.MULTI_SELECTION } })
        } else if (activeObjects.length == 1) {
            const id = activeObjects[0].id;
            let activeComponent;

            for (const component of this.components) {
                if (component.id == id) {
                    activeComponent = component;
                    break;
                }
            }

            if (activeComponent) {
                this.engine.setState({ selectedComponent: { type: activeComponent.type, id } })
            } else {
                this.engine.setState({ selectedComponent: {} })
            }
        } else {
            this.engine.setState({ selectedComponent: {} })
        }
    }

    recordAction() {
        const lastSnapshot = this.canvasSnapshot;
        const snapshot = this.takeSnapshot();

        // console.log("snapshot", JSON.stringify(snapshot))

        this.engine.recordHistoryAction({
            run: () => {
                this.canvas.clear();
                this.canvas.loadFromJSON(snapshot, () => {
                    this.canvas.renderAll();
                    this.takeSnapshot();
                });
            },
            reverse: () => {
                this.canvas.clear();
                this.canvas.loadFromJSON(lastSnapshot, () => {
                    this.canvas.renderAll();
                    this.takeSnapshot();
                });
            }
        })
    }

    getJSON() {
        return this.canvas.toJSON(['id', 'componentType', 'qrValue', 'valueDynamic', 'barcodeValue', 'barcodeType', 'imageId', 'dynamicValueContent']);
    }

    takeSnapshot() {
        return this.canvasSnapshot = this.getJSON();
    }

    close() {
        if (this.canvas) {
            this.canvas.dispose();
        }
        window.removeEventListener('mousedown', this.onClickHandle)
        document.removeEventListener('keydown', this.keyDownListener);
    }

}

export default CanvasManager;