import React, { useEffect } from 'react'
import Page from '../../base/Page';
import { API_RESPONSE_FAILED_EVENT_404 } from '../../constants/Constants';
import Api from '../../session/Api';
import SocketSession, { SOCKET_EVENT_POS_MONITORING } from '../../session/SocketSession';
import Util from '../../util/Util';
import TerminalMonitoringView from './TerminalMonitoringView';

function Interval({ onTick }) {
    useEffect(() => {
        const timeout = setInterval(() => {
            onTick();
        }, 2500)
        return () => clearTimeout(timeout);
    }, [])
    return <div />
}

class ClientMonitoringPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            terminal: undefined
        }
    }

    getTerminalId() {
        return this.getPathParams().terminalId;
    }

    getCartId() {
        return this.getPathParams().cartId;
    }

    componentDidMount() {
        super.componentDidMount();
        // SocketSession.open();

        // this.onMonitoringEventListener = (event => this.setState({
        //     terminal: {
        //         ...event.terminalMonitoring,

        //         checkoutFlag: event.checkoutFlag ? Util.newTempId() : undefined,
        //         breakStartFlag: event.breakStartFlag ? Util.newTempId() : undefined,
        //         breakEndFlag: event.breakEndFlag ? Util.newTempId() : undefined,
        //         cashOpenedFlag: event.cashOpenedFlag ? Util.newTempId() : undefined,
        //         cashClosedFlag: event.cashClosedFlag ? Util.newTempId() : undefined,
        //     }
        // })).bind(this);

        // SocketSession.registerListener(SOCKET_EVENT_POS_MONITORING + "-" + this.getTerminalId(), this.onMonitoringEventListener);
    }

    onPageStart() {
        this.callPageApi(listener => {
            //SocketSession.subscribe(SOCKET_EVENT_POS_MONITORING + "-" + this.getTerminalId(), subscription => {
            //this.socketSubscription = subscription;
            Api.trackSecondDisplayTerminal(this.getTerminalId(), listener);
            //})
        }, payload => ({
            terminal: payload
        }));
    }

    componentWillUnmount() {
        // SocketSession.unregisterListener(SOCKET_EVENT_POS_MONITORING + "-" + this.getTerminalId(), this.onMonitoringEventListener);

        // if (this.socketSubscription !== undefined) {
        //     this.socketSubscription.unsubscribe();
        //     this.socketSubscription = undefined;
        // }

        // SocketSession.close();
    }

    loadData() {
        Api.trackSecondDisplayTerminal(this.getTerminalId(), response => {
            if (response.status === true) {
                this.setState({ terminal: response.payload })
            }
        })
    }

    getLayout() {
        return (
            <div style={{ height: '100%', width: "100%", display: 'flex', flexDirection: 'column', flex: 1, }} className="themed-buttons">
                <Interval onTick={this.loadData.bind(this)} />
                <TerminalMonitoringView skipSocketConnection terminal={this.state.terminal} clientPhoneViewer={true} restrictedCartId={this.getCartId()} />
            </div>
        )
    }

}

export default ClientMonitoringPage;