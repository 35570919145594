import React from 'react'
import DashboardCard from '../components/DashboardCard'
import DashboardTable from '../components/DashboardTable'
import DashboardTitle from '../components/DashboardTitle'

import { PenFountain20 } from '@carbon/icons-react'
import { Link as L } from 'carbon-components-react'
import { Link } from 'react-router-dom'
import DirectionIndicator from '../../../components/DirectionIndicator'
import { CHEQUE_TYPE_RECEIVABLE } from '../../../constants/Constants'


function days_between(date1, date2) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    const days = Math.round(differenceMs / ONE_DAY) + 1;

    return days + " day" + (days > 1 ? "s" : "");
}

function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

export default ({dashboard}) => (
    <div>
        <DashboardTitle icon={PenFountain20} title={"Cheques"} />
        <DashboardTable customBgColor="#631A86"
        items={dashboard.upcomingCheques}
        columns={[
            {
                name: "cheque-tb-cheque",
                title: "Cheque",
                flex: 3,
                renderCell: item => <Link style={{textDecoration: 'none'}} target="_blank" to={"/cheques/" + item.chequeId}><L>{item.name}</L></Link>
            },
            {
                name: "cheque-tb-type",
                title: "Type",
                flex: 2,
                renderCell: item => <DirectionIndicator inward={item.type == CHEQUE_TYPE_RECEIVABLE} customInward="Receivable" customOutward="Payable" />
            },
            {
                name: "cheque-tb-upcoming",
                title: "Upcoming",
                flex: 1,
                renderCell: item => (
                    sameDay(new Date(item.date), new Date()) ? (
                        <span style={{fontWeight: 'bold'}}>Today</span>
                    ) : item.date < new Date().getTime() ? (
                        <span style={{color: 'red'}}>Overdue</span>
                    ) : (
                        // <span style={{}}>In {days_between(new Date().getTime(), item.date)}</span>
                        <span style={{}}>{days_between(new Date().getTime(), item.date)}</span>
                    )
                )
            },
        ]} />
    </div>
)