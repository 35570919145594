
import {
    ArrowRight16, Boolean16, Filter16
} from '@carbon/icons-react'
import { ADVANCED_FILTER_TYPE } from '..';

export function Icon({ type, treeMode, ...props }) {
    switch (type) {
        case ADVANCED_FILTER_TYPE.LOGIC:
            return treeMode ? <ArrowRight16 {...props} /> : <Boolean16 {...props} />
        case ADVANCED_FILTER_TYPE.FILTER:
            return <Filter16 {...props} />
        default:
            return <Filter16 {...props} />
    }
}