import React from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CHEQUE, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import Util from '../../util/Util';
import StockFlowListView from '../stock-flow/StockFlowListView';
import TransactionListView from '../transaction/TransactionListView'
import { Currency16 } from '@carbon/icons-react'
import { ChequeDocumentBtn } from '../../pdfs/cheque-document';

class ChequeDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    renderChequeStatus() {
        if (this.isCreating() || !this.state.itemResult.item) {
            return null;
        }
        const cheque = this.state.itemResult.item;


        return (
            <div style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '0.25rem' }}>
                <ChequeDocumentBtn cheque={cheque} />
                {Util.isNumberExist(cheque.usingTransactionId) &&
                    <Link to={"/transaction/" + cheque.usingTransactionId}>
                        <Button size="sm" style={{ borderRadius: 25 }} kind={"secondary"} renderIcon={Currency16}>Open Transaction</Button>
                    </Link>}
            </div>
        )
        if (Util.isNumberExist(cheque?.usingTransactionId)) {

        } else {
            return null;
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_CHEQUE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_CHEQUE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_CHEQUE,
                    itemResult: this.state.itemResult,
                    pagePath: "/cheques/",
                    history: this.props.history,
                    titleBottom: this.renderChequeStatus()
                })}
            </div>
        )
    }

}

export default withRouter(ChequeDetailPage);