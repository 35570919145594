import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import { withLoadablePage } from '../../base/Page';
import ImageView from '../../components/ImageView';
import { OBJECT_TYPE_MOVIE } from '../../constants/ObjectTypes';
import Api from '../../session/Api';
import './cinema-demo.scss'

import { ArrowLeft32, Ticket24, CheckmarkFilled16, Theater24, Receipt20 } from '@carbon/icons-react'
import Logo from '../../images/star-cinema.png'
import { big } from "../../util/Util";


// function View({ payload: movies }) {
//     const history = useHistory();
//     return (
//         <div style={{ padding: '6rem', display: 'flex', gap: '1rem' }}>
//             {movies.map(movie => (
//                 <div onClick={() => history.push('/cinema-web/' + movie.id)} key={movie.id}>
//                     <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, movie.id)} style={{ width: 150, height: 250 }} />
//                     <h4>{movie.displayName}</h4>
//                 </div>
//             ))}
//         </div>
//     )
// }

function View({ payload: movies }) {
    const history = useHistory();
    return (
        <div style={{ minHeight: '100vh', background: '#0f0f0f' }}>
            <div style={{ width: '100%', height: '6rem', background: '#1b1c3c', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ffffff10' }}>
                <div style={{ flex: 1 }} />
                <Link to="/cinema-web">
                    <img src={Logo} style={{ height: 75 }} />
                </Link>
                <div style={{ flex: 1 }} />
            </div>
            <div style={{ width: '100%', height: '3rem', background: '#11122b', paddingInline: '6rem', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <Theater24 />
                <h4 style={{ flex: 1 }}>All Movies</h4>
            </div>

            <div style={{ marginTop: '3rem', display: 'flex', paddingInline: '6rem', gap: '1rem', flexWrap: 'wrap' }}>
                {movies.map(movie => (
                    <div onClick={() => history.push('/cinema-web/' + movie.id)} key={movie.id} style={{ background: '#1c1c1c', borderRadius: 10, border: '1px solid #00000090', color: 'white', padding: '1rem' }}>
                        <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, movie.id)} style={{ width: 150, height: 225, borderRadius: 10, border: '1px solid #00000090' }} />
                        <h4 style={{ marginTop: '1rem' }}>{movie.displayName}</h4>
                    </div>
                ))}
            </div>

            <div style={{ height: '3rem' }} />
        </div>
    )
}

export const CinemaDemo = withLoadablePage(listener => Api.demo_getMovies(listener), View);