import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_SALES_ORDER_STATE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

class SalesOrderStateListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Sales Order States",
                    subTitle: "All sales order states",
                    objectType: OBJECT_TYPE_SALES_ORDER_STATE,
                    pagePath: "/sales-order-states/",
                    history: this.props.history
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(SalesOrderStateListPage);