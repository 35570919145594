

export const COMPONENT_TYPE = {
    SECTION: 0,
    ZONE: 1,
    TABLE: 2
}

export const COMPONENT_STATUS = {
    AVAILABLE: 0,
    OCCUPIED: 1,
    RESERVED: 2,
}