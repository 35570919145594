import { useEffect } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import { useState } from "react"
import Util from "../../util/Util";


export function useListFilter(state, id, skipAutoFilter) {
    const valueExists = value => value && Array.isArray(value) && value.length > 0;

    const [filters, setFilters] = state.use("filters", [])
    const listFilters = useMemo(() => filters.find(filter => filter.id === id)?.filters, [filters])

    const setListFilters = newListFilters => {
        if (valueExists(newListFilters)) {
            setFilters(prev => [...prev.filter(filter => filter.id !== id), { id, skipAutoFilter, operator: "OR", filters: newListFilters }])
        } else {
            setFilters(prev => prev.filter(filter => filter.id !== id));
        }
    }

    return [listFilters ?? [], setListFilters]
}


export function useValueFilter(state, id, filter, valueExists) {
    const [filters, setFilters] = state.use("filters", [])
    const value = useMemo(() => filters.find(filter => filter.id === id)?.value, [filters])

    const setValue = newValue => {
        //if (newValue && (!valueExists || valueExists(newValue))) {
        if (valueExists(newValue)) {
            setFilters(prev => [...prev.filter(filter => filter.id !== id), { ...filter, id, value: newValue }])
        } else {
            setFilters(prev => prev.filter(filter => filter.id !== id));
        }
    }

    return [value, setValue]
}

export function useValueFilterWithoutValue(state, id, filter, valueExists) {
    const [filters, setFilters] = state.use("filters", [])
    const value = useMemo(() => filters.find(filter => filter.id === id)?.__value, [filters])

    const setValue = newValue => {
        //if (newValue && (!valueExists || valueExists(newValue))) {
        if (valueExists(newValue)) {
            setFilters(prev => [...prev.filter(filter => filter.id !== id), { ...filter, id, __value: newValue }])
        } else {
            setFilters(prev => prev.filter(filter => filter.id !== id));
        }
    }

    return [value, setValue]
}


export function useDateValueFilter(state, id, filter) {
    const [value, setValue] = useValueFilter(state, id, filter, value => Util.isNumberExist(value))
    return [value ?? "", setValue]
}

export function useStringValueFilter(state, id, filter) {
    const [value, setValue] = useValueFilter(state, id, filter, value => Util.isStringExists(value))
    return [value ?? "", setValue]
}

export function useNumberValueFilter(state, id, filter) {
    const [value, setValue] = useValueFilter(state, id, filter, value => Util.isNumberExist(value))
    return [value ?? 0, setValue]
}

export function useBooleanValueFilter(state, id, filter) {
    const [value, setValue] = useValueFilterWithoutValue(state, id, filter, value => value === true)
    return [value ?? false, setValue]
}


export function useTypeValueFilter(state, id, filter) {
    const [value, setValue] = useValueFilter(state, id, filter, value => typeof value === "number" ? value >= 0 : parseInt(value) >= 0)
    return [value ?? -1, setValue]
}