import './student-presence.scss'
import { ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import { useEffect, useState } from "react"
import Button from "../../../../components/Button";
import Util from "../../../../util/Util";
import Api from '../../../../session/Api';
import UIUtil from '../../../../util/UIUtil';
import { CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { STUDENT_PRESENCE } from '../../../../constants/Constants';

const Card = ({ item, selected, onClick }) => {
    const spaceLeft = item.capacity - item.students.length;
    const hasSpace = spaceLeft > 0;
    return (
        <div onClick={onClick} className="student-presence-card"
            style={{
                borderRadius: 10, border: '1px solid #00000040', background: selected ? 'black' : undefined, color: selected ? 'white' : undefined, padding: '1rem', marginBottom: '0.5rem',
                display: 'flex', alignItems: 'center', pointerEvents: !hasSpace ? 'none' : undefined
            }}
        >
            <div style={{ flex: 1 }}>
                <h6>{Util.getFullDate(item.startDate) + ' - ' + Util.getTime(item.endDate)}</h6>
                {hasSpace ? (
                    <p style={{ fontSize: 12, color: '#009900' }}>{spaceLeft} space{spaceLeft != 1 ? 's' : ''} left</p>
                ) : (
                    <p style={{ fontSize: 12, color: '#990000' }}>No space left</p>
                )}
            </div>
            {selected && <CheckmarkFilled16 style={{ color: '#009900' }} />}
        </div>
    )
}

export function SettingAbsentDialog({ activityId, setPresence, open, onClose }) {
    const [loading, setLoading] = useState(false);
    const [activities, setActivites] = useState([]);
    const [selected, setSelected] = useState(undefined)

    const [confirming, setConfirming] = useState(false)

    useEffect(() => {
        if (open) {
            setSelected(undefined)
            setLoading(true);
            Api.getMakeUpActivities(activityId, response => {
                if (response.status === true) {
                    setActivites(response.payload)
                    setLoading(false)
                } else {
                    UIUtil.showError(response.message)
                    onClose();
                }
            })
        }
    }, [open])

    const onConfirmBtn = () => {
        setConfirming(true)
        Api.createStudentPresence(activityId, STUDENT_PRESENCE.ABSENT, selected?.id ?? 0, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onClose();
                setPresence(response.payload)
            } else {
                UIUtil.showError(response.message);
            }
            setConfirming(false);
        })
    }

    return (
        <ComposedModal
            // className="modal-that-supports-combo-box"
            open={open} onClose={onClose}>
            <ModalHeader label='Presence' title="Absent Confirmation" />
            <ModalBody style={{ paddingRight: '1rem', outline: 'none' }} hasScrollingContent>
                <p style={{ marginBottom: '3rem' }}>
                    Please be reminded that the class will still count and be
                    charged, unless everyone in the group agrees to
                    postpone the class altogether,
                    <br />
                    <br />
                    If the class will still proceed and you cannot make it,
                    choose one the following options to make up the class
                </p>

                {!loading ? (
                    activities.length > 0 ? (
                        activities.map(item => <Card key={item.id} item={item} selected={item === selected} onClick={() => setSelected(selected => selected === item ? undefined : item)} />)
                    ) : (
                        <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>No options available</p>
                        </div>
                    )
                ) : (
                    <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                        <InlineLoading style={{ width: 'unset' }} />
                    </div>
                )}


                <div style={{ height: '3rem' }} />
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    loading={confirming}
                    onClick={onConfirmBtn}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}