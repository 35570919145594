import { useMemo, useState } from "react"
import Button from '../../components/Button'
import { Add16, Reset16, CheckmarkFilled16 } from '@carbon/icons-react'
import { QuestionEntry, QuestionEntryDrag } from "./question-entry";
import Util from "../../util/Util";
import { makeDraggable } from "../../hooks/useDraggable";
import { isEqual } from "lodash";
import Api, { createApiWithLang } from "../../session/Api";
import UIUtil from "../../util/UIUtil";
import { fixIdForApi } from "../../templates/form/form-api";
import { withLoadablePage, withLoadablePageAndProps, withLoadablePagePropsAndProps } from "../../base/Page";

const Header = ({ onResetValuesBtn, changesMade, saveChanges }) => {
    const [saving, setSaving] = useState(false)
    const onSaveBtn = () => {
        setSaving(true);
        saveChanges(() => setSaving(false))
    }
    return (
        <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, width: '100%', paddingInline: '3rem', paddingTop: '5rem', paddingBottom: '2rem', color: 'white', background: '#1c1c1c' }}>
            <h2>Quiz Designer</h2>
            <div style={{ flex: 1 }} />

            {changesMade && <>
                <Button onClick={onResetValuesBtn} renderIcon={Reset16} kind="secondary" size="sm" style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>Reset values</Button>
                <div style={{ width: 1 }} />
                <Button onClick={onSaveBtn} loading={saving} renderIcon={CheckmarkFilled16} size="sm" style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}>Save changes</Button>
            </>}
        </div>
    )
}

const Footer = ({ value, onNewQuestionBtn, readonly }) => (
    <div style={{ width: '100%', background: '#1c1c1c', color: 'white', borderRadius: 10, overflow: 'hidden', border: '1px solid #ffffff10', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0.5rem' }}>
        <p style={{ fontSize: 14, flex: 1, paddingInline: '1rem' }}>{value.length + ' question' + (value.length !== 1 ? 's' : '') + ' in total'}</p>
        {!readonly && <Button onClick={onNewQuestionBtn} renderIcon={Add16} size="sm" style={{ borderRadius: 7 }}>New Question</Button>}
    </div>
)


const draggable = makeDraggable(QuestionEntry, QuestionEntryDrag);

export function itemState(value, setValue, item, property, wrapOther = other => other) {
    return [
        value.find(other => item.id === other.id)[property],
        (arg) => {
            if (typeof arg === "function") {
                setValue(prev => prev.map(other => item.id === other.id ? ({
                    ...other,
                    [property]: arg(other[property])
                }) : wrapOther(other)))
            } else {
                setValue(prev => prev.map(other => item.id === other.id ? ({
                    ...other,
                    [property]: arg
                }) : wrapOther(other)))
            }
        }
    ]
}


function View({ quizId, lang, payload }) {
    const readonly = Util.isStringExists(lang) && lang !== "EN";

    const [originalValue, setOriginalValue] = useState(payload ?? []);
    const [value, setValue] = useState(payload ?? []);
    const changesMade = useMemo(() => !isEqual(value, originalValue), [originalValue, value])

    const onResetValuesBtn = () => setValue(originalValue);
    const onNewQuestionBtn = () => {
        setValue(prev => [...prev, {
            id: Util.newTempId(),
            question: "",
            options: []
        }])
    }

    const itemProps = (item) => ({
        onRemoveBtn: () => setValue(prev => prev.filter(other => item.id !== other.id)),

        questionState: itemState(value, setValue, item, "question"),
        optionsState: itemState(value, setValue, item, "options"),

        readonly,
    })


    const saveChanges = (onDone) => {
        const api = (!Util.isStringExists(lang) || lang === "EN") ? Api : createApiWithLang(lang);
        api.saveLectureQuizQuestions(quizId, fixIdForApi(value), response => {
            if (response.status === true) {
                setOriginalValue(response.payload)
                setValue(response.payload)

                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            onDone()
        })
    }

    return (
        <div>
            <Header saveChanges={saveChanges} onResetValuesBtn={onResetValuesBtn} changesMade={changesMade} />

            <draggable.SortContainer onSortEnd={draggable.createOnSortEnd(value, setValue)}>
                {value.map((item, index) => <draggable.SortItem key={item.id} index={index} {...itemProps(item)} />)}
            </draggable.SortContainer>

            <Footer value={value} onNewQuestionBtn={onNewQuestionBtn} readonly={readonly} />
        </div>
    )
}

export const QuizEditor = withLoadablePagePropsAndProps(props => listener => {
    const api = (!Util.isStringExists(props.lang) || props.lang === "EN") ? Api : createApiWithLang(props.lang);
    api.getLectureQuizQuestions(props.quizId, listener)
}, View);