import React from 'react';
import { useHistory } from 'react-router-dom';
import { getObjectTypeName, getObjectTypeUrl, OBJECT_TYPE_DELIVERY_LOCATION, } from '../../constants/ObjectTypes';
import TableTemplate from '../../templates/TableTemplate';

const OBJECT_TYPE = OBJECT_TYPE_DELIVERY_LOCATION;

function Page() {
    const history = useHistory();
    return (
        <div className="main-content">
            {TableTemplate.renderTemplate({
                title: getObjectTypeName(OBJECT_TYPE),
                subTitle: "All " + getObjectTypeName(OBJECT_TYPE).toLowerCase(),
                objectType: OBJECT_TYPE,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                paginated: false
            })}
        </div>
    )
}

export const DeliveryLocationListPage = Page;