import { useEffect, useState } from 'react'
import './cinema-pos.scss'
import Api from '../../session/Api';
import { Theater24, ArrowLeft16, CloseFilled16, Close16, Ticket16, CheckmarkFilled16 } from '@carbon/icons-react'
import { OBJECT_TYPE_MOVIE } from '../../constants/ObjectTypes';
import ImageView from '../../components/ImageView';
import { Tag } from 'carbon-components-react';
import { StatusBar } from './status-bar';
import { TICKET_SELECTOR_FLOW } from '.';
import { MovieList } from './components/movie-list';
import { ShowtimeList } from './components/showtime-list';
import Util from '../../util/Util';
import { SeatList } from './components/seat-list';
import UIUtil from '../../util/UIUtil';
import { getTerminal } from '../../session/SessionManager';

const Root = ({ children }) => (
    <div className='cinema-pos-scroll-bar' style={{ position: 'absolute', display: 'flex', minHeight: 0, flexDirection: 'column', left: 0, top: '0rem', width: '100%', height: '100%', background: 'black', zIndex: 9000, animation: '250ms cinema-pos-overlay-animation' }}>
        {children}
    </div>
)

const Content = ({ children }) => (
    <div style={{ flex: 1, minHeight: 0, width: '100%', overflow: 'auto' }}>
        {children}
    </div>
)

const Title = ({ overview, onClose }) => (
    <div style={{ width: '100%', height: '3rem', background: '#1c1c1c', paddingInline: '1rem', borderBottom: '1px solid #ffffff10', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light' style={{ marginRight: '1rem', fontSize: 10 }}>
            <CloseFilled16 style={{ fontSize: 10 }} />
        </button>
        <Theater24 />
        <h4 style={{ flex: 1 }}>Ticket Sale</h4>


        {overview ? (<>
            <p style={{ fontSize: 12, opacity: 0.65, marginRight: '0.5rem' }}>Summary:</p>
            {overview.filter(v => Util.isStringExists(v)).map(v => <Tag type="high-contrast">{v}</Tag>)}
        </>) : (
            // <Tag renderIcon={Ticket16} type='high-contrast'>No ticket selected</Tag>
            null
        )}

    </div>
)




function getStatus(movieId, showtimeId) {
    if (Util.isNumberExist(showtimeId)) {
        return TICKET_SELECTOR_FLOW[2];
    } else if (Util.isNumberExist(movieId)) {
        return TICKET_SELECTOR_FLOW[1];
    } else {
        return TICKET_SELECTOR_FLOW[0];
    }
}

const useStatus = (movieId, showtimeId) => {
    const status = getStatus(movieId, showtimeId);
    const [selectedStatus, setSelectedStatus] = useState(status);

    return { status, selectedStatus, setSelectedStatus }
}

const useOverview = (movieId, showtimeId, seats) => {
    const [overview, setOverview] = useState(() => ["", "", ""]);
    const hasOverview = Util.isStringExists(overview[0]) && Util.isStringExists(overview[1]);

    useEffect(() => {
        setOverview(p => ["", p[1], p[2]])

        let cancelled = false;
        if (Util.isNumberExist(movieId)) {
            Api.getCinemaPosSelectionOverview(movieId, 0, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    setOverview(p => [response.payload[0], p[1], p[2]])
                } else {
                    setOverview(p => ["Unknown", p[1], p[2]])
                }
            })
        }
        return () => cancelled = true;
    }, [movieId])

    useEffect(() => {
        setOverview(p => [p[0], "", ""])

        let cancelled = false;
        if (Util.isNumberExist(showtimeId)) {
            Api.getCinemaPosSelectionOverview(0, showtimeId, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    setOverview(p => [p[0], response.payload[1], response.payload[2]])
                } else {
                    setOverview(p => [p[0], "Unknown", "Unknown"])
                }
            })
        }
        return () => cancelled = true;
    }, [showtimeId])


    if (hasOverview) {
        return [
            ...overview,
            [...new Set(seats.map($ => $.seatTypeName + " Seats"))].join(", "),
            seats.map($ => $.posName).join(", ")
        ]
    } else if (Util.isStringExists(overview[0])) {
        return overview;
    } else {
        return null;
    }
}

const useActions = ({
    movieId,
    showtimeId,
    seats,
    overview,

    setMovieId,
    setShowtimeId,
    setSeats,
    setSelectedStatus,

    onTicketsConfirmed,
}) => ({
    onMovieClick: movie => {
        const fn = () => {
            setMovieId(movie.id);
            if (movieId != movie.id) {
                setShowtimeId(0);
                setSeats([])
            }

            setSelectedStatus(TICKET_SELECTOR_FLOW[1])
        }

        if (seats.length && movieId != movie.id) {
            UIUtil.confirmWithMsg("Selecting a different movie will clear the current seat selections", fn)
        } else {
            fn();
        }
    },
    onShowtimeClick: showtime => {
        const fn = () => {
            setShowtimeId(showtime.id)
            if (showtimeId != showtime.id) {
                setSeats([])
            }

            setSelectedStatus(TICKET_SELECTOR_FLOW[2])
        }

        if (seats.length && showtimeId != showtime.id) {
            UIUtil.confirmWithMsg("Selecting a different showtime will clear the current seat selections", fn)
        } else {
            fn();
        }
    },
    onConfirmTicketsBtn: (totalAmount, onDone) => {
        Api.addTicketToSession({
            terminalId: getTerminal().id,
            amount: totalAmount,
            ticketOverview: overview.join(" - "),

            seats, showtimeId
        }, response => {
            if (response.status === true) {
                onTicketsConfirmed(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            onDone();
        })
    }
})



export function TicketSelector({ onClose, onTicketsConfirmed, defaultValues }) {
    const [movieId, setMovieId] = useState(defaultValues?.movieId ?? 0);
    const [showtimeId, setShowtimeId] = useState(defaultValues?.showtimeId ?? 0);
    const [seats, setSeats] = useState(defaultValues?.seats ?? []);

    const { status, selectedStatus, setSelectedStatus } = useStatus(movieId, showtimeId);
    const overview = useOverview(movieId, showtimeId, seats);


    const actions = useActions({
        movieId, showtimeId, seats,
        setMovieId, setShowtimeId, setShowtimeId, setSeats, setSelectedStatus,
        overview, onTicketsConfirmed
    })


    return (
        <Root>
            <Title overview={overview}
                onClose={() => UIUtil.confirmWithMsgAndOps("Please press 'Confirm Tickets' instead to save changes", { confirmLabel: "Close Anyway" }, onClose)} />

            <StatusBar movie={{ status: status.id }} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />

            <Content>
                {selectedStatus.id === "movie" &&
                    <MovieList onMovieClick={actions.onMovieClick} />}
                {selectedStatus.id === "showtime" &&
                    <ShowtimeList overview={overview} movieId={movieId} onShowtimeClick={actions.onShowtimeClick} />}
                {selectedStatus.id === "seat" &&
                    <SeatList overview={overview} movieId={movieId} showtimeId={showtimeId} seats={seats} setSeats={setSeats}
                        onConfirmTicketsBtn={actions.onConfirmTicketsBtn} />}
            </Content>

        </Root>
    )
}