import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { Calendar32, Restaurant16, DeskAdjustable16, ArrowsVertical16 } from '@carbon/icons-react'
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { DateRange2 } from "../../components/basic-filter/date-range";
import { useStringValueFilter } from "../../engine/useValueFilter";
import { MultiSelectListMode } from "../../components/basic-filter/multi-select";

export const FnbEventList = {
    filter: Filter,
    icon: Calendar32,

    newDialog: NewDialog,

    itemToOpenLink: item => `/fnb-event/${item.id}`,
    isListMode: true,
    openInSameTab: true,

    isAvailable: () => {
        const role = getAccountRole();
        return hasCapabilitySupport("fnbEvent") && role == ACCOUNT_TYPE_ADMINISTRATION;
    }
}

function NewDialog({ visible, onClose, onSuccess, endpoint }) {
    return (
        <FormDialog
            setItem={item => onSuccess(item)} label="Event" title="Create New"
            saveApi={(data, listener) => Api.createFnbEvent(data, listener)}

            open={visible} onClose={onClose} item={{
                type: 'CATERING'
            }} payload={endpoint}>
            {({ SwitchField }) => (<>
                <SwitchField title="Type" fieldKey="type" options={[{ id: 'CATERING', title: "Catering" }, { id: 'BANQUET', title: "Banquet" }]} />

            </>)}
        </FormDialog>
    )
}

const TypeFilter = ({ state }) => {
    const [value, setValue] = useStringValueFilter(state, "type", { property: "type", operator: "EQ" })
    return (
        <div style={{ paddingInline: '0.5rem' }}>
            <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                <button data-active={!value} onClick={() => setValue(null)} className='report-engine-menu-button-light rbt-re-menu-wide'><ArrowsVertical16 /> All</button>
                <button data-active={value === "Catering"} onClick={() => setValue("Catering")} className='report-engine-menu-button-light rbt-re-menu-wide'><Restaurant16 /> Catering</button>
                <button data-active={value === "Banquet"} onClick={() => setValue("Banquet")} className='report-engine-menu-button-light rbt-re-menu-wide'><DeskAdjustable16 /> Banquet</button>
            </div>
        </div>
    )
}


function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="eventDate" />

        <div className="list-mode-divider-quick-filter-bar" />

        <TypeFilter state={state} />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ width: 200 }}>
            <MultiSelectListMode isMulti={false} placeholder="Step" fieldKey="fnbEventListStepField" options={["Created", "Event Planning", "Menu Selection", "Products/Services", "KOT", "Done"]} state={state} property="status" />
        </div>
    </>)
}
