import React, { useState } from 'react'

import { Camera16, MediaLibrary16 } from '@carbon/icons-react'
import UploadWrapper from './UploadWrapper';
import ImageView from './ImageView';

export default ({ isVideo, isDefaultVideo, style, src, onChange, skipChange, videoOnly, onVideoLength }) => {
    const [hover, setHover] = useState(false)
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [selectedImageURL, setSelectedImageURL] = useState(undefined);

    return (
        <UploadWrapper accept={videoOnly ? "video/*" : "image/gif,image/jpeg,image/png,video/*"} onChange={e => {
            const file = e.target.files[0];

            setHover(false);
            if (!skipChange) {
                setSelectedImage(file)
                setSelectedImageURL(URL.createObjectURL(file))
            }
            onChange({ file, video: file.type.startsWith('video/') })
        }} onClick={e => {
            if (selectedImage !== undefined) {
                e.preventDefault();
                URL.revokeObjectURL(selectedImage);

                setHover(false);
                setSelectedImage(undefined);
                setSelectedImageURL(undefined);

                //onChange(undefined)
                onChange({ file: undefined, video: isDefaultVideo })
            }
        }}>
            <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ ...style, position: 'relative', overflow: 'hidden', cursor: 'pointer' }}>


                {isVideo ? (
                    <video muted autoPlay loop playsInline style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                        src={selectedImageURL !== undefined ? selectedImageURL : src}
                        onLoadedMetadata={e => {
                            onVideoLength?.(e.target.duration)
                        }}
                    />
                ) : (<ImageView
                    style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                    imageViewStyle={{ objectFit: 'cover' }} wide
                    src={selectedImageURL !== undefined ? selectedImageURL : src}
                />)}

                <div style={{
                    position: 'absolute', transition: '250ms', width: '100%', height: hover ? '100%' : 30, left: 0, bottom: 0, background: '#000000DD',
                    color: 'white', display: 'flex', alignItems: 'center', padding: '1rem'
                }}>
                    <p style={{ transition: '250ms', fontSize: hover ? 16 : 12, }}>{hover ? (selectedImage == undefined ? 'Select Media' : 'Clear Media') : 'Media'}</p>
                    {/* <div>
                        {selectedImage === undefined && hover && 
                        <p style={{fontSize: 12}}>jpeg/png</p>}
                    </div> */}
                    <div style={{ flex: 1 }} />
                    <MediaLibrary16 style={{ transition: '250ms', width: hover ? 32 : 16, height: hover ? 32 : 16 }} />
                </div>
            </div>
        </UploadWrapper>
    )
}