
import { ComboBox, ContentSwitcher, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16, FlowConnection16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import { LogicNodeEditorDialog } from '../../../views/logic-node-editor/LogicNodeEditorDialog';
import Util from '../../../util/Util';
import TimePicker from '../../../components/TimePicker';

const TIME_UNITS = [
    {
        type: 0,
        name: "Minutes",
    },
    {
        type: 1,
        name: "Hours",
    },
    {
        type: 2,
        name: "Days",
    }
]

const EVENT_TYPES = [
    {
        type: 0,
        name: "Before",
    },
    {
        type: 1,
        name: "After",
    }
]

const SCHEDULE_TYPES = [
    {
        type: 0,
        name: "Starting",
    },
    {
        type: 1,
        name: "Ending",
    }
]

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved }) => {
    const [msg, setMsg] = useMultiInputState(input, "message", onInputUpdate)
    const [timeUnit, setTimeUnit] = useMultiInputState(input, "timeUnit", onInputUpdate)
    const [time, setTime] = useMultiInputState(input, "time", onInputUpdate)
    const [eventType, setEventType] = useMultiInputState(input, "eventType", onInputUpdate);
    const [scheduleType, setScheduleType] = useMultiInputState(input, "scheduleType", onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div style={{ flex: 2 }}>
                <TextInput size="lg" labelText="Message (optional)" value={msg} onChange={e => setMsg(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput size="lg" labelText="Time" value={time} onChange={e => setTime(e.target.value)} />
            </div>
            <ComboBox style={{ width: 162 }} size='lg' titleText="Time Unit" items={TIME_UNITS} itemToString={item => item?.name ?? ""}
                selectedItem={TIME_UNITS.find(item => item.type === timeUnit)}
                onChange={e => setTimeUnit(e.selectedItem ? e.selectedItem.type : undefined)} />
            <ComboBox style={{ width: 162 }} size='lg' titleText="Precedence" items={EVENT_TYPES} itemToString={item => item?.name ?? ""}
                selectedItem={EVENT_TYPES.find(item => item.type === eventType)}
                onChange={e => setEventType(e.selectedItem ? e.selectedItem.type : undefined)} />
            <ComboBox style={{ width: 162 }} size='lg' titleText="Schedule Type" items={SCHEDULE_TYPES} itemToString={item => item?.name ?? ""}
                selectedItem={SCHEDULE_TYPES.find(item => item.type === scheduleType)}
                onChange={e => setScheduleType(e.selectedItem ? e.selectedItem.type : undefined)} />


            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const NotificationListEditor = ({ defaultItems, onUpdate }) => {
    return (
        <MultipleInputEditor defaultInputs={[]} inputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} />
    )
}