import BrowserPDFThermalPrinterDriver from "./impl/BrowserPDFThermalPrinterDriver";
import ECSPOSServerThermalPrinterDriver from "./impl/ECSPOSServerThermalPrinterDriver";
import EpsonThermalPrinterDriver from "./impl/EpsonThermalPrinterDriver";
import MultiEscServerDriver from "./impl/MultiEscServerDriver";


export const THERMAL_PRINTER_DRIVERS = [
    {
        id: 0,
        name: "Browser PDF",
        driver: BrowserPDFThermalPrinterDriver
    },
    {
        id: 1,
        name: "EPSON Thermal Printer",
        driver: EpsonThermalPrinterDriver
    },
    {
        id: 2,
        name: "ECS-POS Server Printer",
        driver: ECSPOSServerThermalPrinterDriver
    },
    {
        id: 3,
        name: "Multi ESC/POS Server",
        driver: MultiEscServerDriver
    }
]

/**
 * @returns (Array<{property: string, name: string}>)
 */
export const getThermalPrinterDriverParams = id => {
    for (const item of THERMAL_PRINTER_DRIVERS) {
        if (item.id == id) {
            return new item.driver().getParams();
        }
    }

    return []
}