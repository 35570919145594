import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

class ProductBundleListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Product Bundles", 
                    subTitle: "All bundles", 
                    objectType: OBJECT_TYPE_PRODUCT_BUNDLE, 
                    pagePath: "/product-bundles/", 
                    history: this.props.history
                })}
            </div>
        )
    }
    
    isPageLoadable() {
        return false;
    }

}

export default withRouter(ProductBundleListPage);