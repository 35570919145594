import { useEffect, useMemo, useState } from "react";
import CustomComboBox from "../../../components/CustomComboBox";
import { useNumberValueFilter, useStringValueFilter } from "../../engine/useValueFilter";


export function AccountLedgerSelector({ accountTree, label, state, id, property, parentStyle = {}, fieldStyle = {}, skipAutoFilter }) {
    const [selectedAccount, setSelectedAccount] = state.use("selectedAccountLedger", undefined)
    const [value, setValue] = useNumberValueFilter(state, id ?? property, { property, skipAutoFilter, operator: "EQ" })

    useEffect(() => {
        setValue(selectedAccount?.id ?? 0)
    }, [selectedAccount])

    return (
        <div className="filter-field" style={{ ...parentStyle }}>
            <div style={{ height: 30, ...fieldStyle }}>
                <CustomComboBox rounded dark placeholder="Select Account" items={accountTree} selectedItem={selectedAccount} onSelectedItemUpdate={item => setSelectedAccount(item)} />
            </div>
        </div>
    )
}


export function SubsidiaryLedgerSelector({ accountTree, label, state, id, property, parentStyle = {}, fieldStyle = {}, skipAutoFilter }) {
    const [selectedAccount, setSelectedAccount] = state.use("selectedSubsidiaryAccountLedger", undefined)
    const [value, setValue] = useNumberValueFilter(state, id ?? property, { property, skipAutoFilter, operator: "EQ" })

    useEffect(() => {
        setValue(selectedAccount?.id ?? 0)
    }, [selectedAccount])

    return (
        <div className="filter-field" style={{ ...parentStyle }}>
            <div style={{ height: 30, ...fieldStyle }}>
                <CustomComboBox rounded dark placeholder="Select Subsidiary" items={accountTree} selectedItem={selectedAccount} onSelectedItemUpdate={item => setSelectedAccount(item)} />
            </div>
        </div>
    )
}