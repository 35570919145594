import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_CATEGORY, OBJECT_TYPE_PRODUCT_DEPARTMENT, OBJECT_TYPE_PRODUCT_SUBCATEGORY } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';

const ProductsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'subcategoryId',
                    parentId: item.id,
                    showNotIncluded: false,
                    nonEditable: true
                },
                embedded: true,
                title: "Products",
                subTitle: "Products in subcategory",
                objectType: OBJECT_TYPE_PRODUCT,
                paginated: true,
                pagePath: "/products/",
                history: history
            })}
        </div>
    )
}

const BundlesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'subcategoryId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Bundles",
                subTitle: "Bundles in subcategory",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                pagePath: "/bundles/",
                history: history
            })}
        </div>
    )
}

class ProductSubcategoryDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_PRODUCT_SUBCATEGORY, listener)
            } else {
                Api.getItem(OBJECT_TYPE_PRODUCT_SUBCATEGORY, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_PRODUCT_SUBCATEGORY,
                    itemResult: this.state.itemResult,
                    pagePath: "/product-subcategories/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Products",
                            component: ProductsList
                        },
                        {
                            title: "Bundles",
                            component: BundlesList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(ProductSubcategoryDetailPage);