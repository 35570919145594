import { useEffect, useMemo, useState } from "react";
import Api from "../../../session/Api";
import UIUtil from "../../../util/UIUtil";
import usePosSession from "../state/usePosSession";
import useStore from "../../../hooks/useStore";
import { ButtonSet, ComboBox, ComposedModal, Link, ModalBody, ModalFooter, ModalHeader, TextArea } from "carbon-components-react";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import Button from "../../../components/Button";
import { Save16, ErrorFilled16 } from '@carbon/icons-react'
import Util from "../../../util/Util";


const EditorModal = ({ visible, onClose, value, setValue, onSave, loading }) => {
    return (
        <ComposedModal
            key="note-editor-modal" open={visible} onClose={onClose} size="sm">
            <ModalHeader label="Editing" title="AWB No" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                <TextArea
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    labelText="AWB No"
                    placeholder="Optional AWB no" />
            </ModalBody>
            <ModalFooter>
                <ButtonSet style={{ width: '100%' }}>
                    <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                        Cancel
                    </Button>
                    <Button onClick={onSave} renderIcon={Save16} loading={loading}>
                        Save
                    </Button>
                </ButtonSet>
            </ModalFooter>
        </ComposedModal>
    )
}

const DetailField = ({ title, value }) => (<>
    <label className="bx--label">{title}</label>
    <p style={{ marginTop: '-0.75rem', whiteSpace: 'pre-wrap' }}>{value}</p>
    <div style={{ height: '0.25remrem' }} />
</>)



export function AWBNo({ sessionStore, editable }) {
    const session = usePosSession(sessionStore)
    const [awbNo] = useStore(sessionStore, "awbNo")

    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState("")



    return (
        <div>
            <DetailField title={"AWB No"} value={Util.isStringExists(awbNo) ? awbNo : '-'} />
            {editable && <>
                <Link style={{ cursor: 'pointer' }} onClick={() => {
                    setValue(awbNo)
                    setVisible(true)
                }}>Update</Link>
                <EditorModal visible={visible} loading={session.loading} onClose={() => setVisible(false)} value={value} setValue={setValue} onSave={() => session.setAwbNo(value, undefined, () => setVisible(false))} />
            </>}
        </div>
    )
}