import React from 'react'
import ErrorPage from '../components/page-messages/ErrorPage';
import LoadingPage from '../components/page-messages/LoadingPage';
import { API_RESPONSE_FAILED_EVENT_404 } from '../constants/Constants';
import queryString from 'query-string'

export function withLoadablePagePropsAndProps(loader, view) {
    class Content extends Page {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                payload: undefined
            }
        }
        onPageStart() {
            this.callPageApi(loader(this.props), payload => ({ payload }))
        }
        getLayout() {
            return React.createElement(view, { payload: this.state.payload, ...this.props })
        }
    }
    return Content;
}


export function withLoadablePageAndProps(loader, view) {
    class Content extends Page {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                payload: undefined
            }
        }
        onPageStart() {
            this.callPageApi(loader, payload => ({ payload }))
        }
        getLayout() {
            return React.createElement(view, { payload: this.state.payload, ...this.props })
        }
    }
    return Content;
}


export function withLoadablePage(loader, view) {
    class Content extends Page {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                payload: undefined
            }
        }
        onPageStart() {
            this.callPageApi(loader, payload => ({ payload }))
        }
        getLayout() {
            return React.createElement(view, { payload: this.state.payload })
        }
    }
    return Content;
}

export function withLoadablePageWithParams(loader, view) {
    class Content extends Page {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                payload: undefined
            }
        }
        onPageStart() {
            this.callPageApi(loader(this.getPathParams()), payload => ({ payload }))
        }
        getLayout() {
            return React.createElement(view, { payload: this.state.payload })
        }
    }
    return Content;
}


class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pathParams: this.props.location !== undefined ? queryString.parse(this.props.location.search) : {},

            pageLoading: this.isPageLoadable(),
            pageInError: false,
            pageInErrorTitle: undefined,
            pageInErrorMessage: undefined,
            pageInError404: false
        }
    }

    getPathParams() {
        return this.props.pagePathParamsOverride ? this.props.pagePathParamsOverride : this.props.match.params;
    }

    componentDidMount() {
        this.onPageStart();
    }

    componentDidUpdate(prevProps) {
        /*if (this.props.location !== prevProps.location) {
            console.log(this.props)
            window.scrollTo(0, 0)
        }*/

        if (this.props.location !== undefined && this.props.match !== undefined) {
            if (this.props.location.search !== prevProps.location.search) {
                const pathParams = this.props.location !== undefined ? queryString.parse(this.props.location.search) : {};
                this.setState({
                    pathParams: pathParams
                }, () => this.onPageStart());
            } else if (JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)) {
                this.setState({}, () => this.onPageStart())
            }
        }
    }

    callPageApi(method, stateChanger) {
        this.setState({ pageLoading: true })
        method(response => {
            let state = response.status === true ? stateChanger(response.payload) : {};
            if (state === null || state === undefined) {
                state = {}
            }

            this.setState({
                pageLoading: false,
                pageInError: response.status === false,
                pageInErrorTitle: undefined,
                pageInErrorMessage: undefined,
                pageInError404: response.status === false && response.payload == API_RESPONSE_FAILED_EVENT_404,

                ...(state)
            })
        })
    }

    render() {
        if (this.isPageLoading()) {
            return <LoadingPage />
        } else if (this.isPageInError()) {
            return <ErrorPage hideGoHomeBtn={this.hideGoHomeBtn()} title={this.state.pageInErrorTitle} message={this.state.pageInErrorMessage} error404={this.state.pageInError404} />
        } else {
            return this.getLayout();
        }
    }

    getLayout() {
        throw new Error("Method not implemented");
    }

    isPageLoading() {
        return this.state.pageLoading;
    }

    isPageInError() {
        return this.state.pageInError;
    }

    isPageLoadable() {
        return true;
    }

    hideGoHomeBtn() {
        return false;
    }

    onPageStart() { }

}

export default Page;