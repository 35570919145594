import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { DateRange } from "../../components/basic-filter/date-range";
import { Divider } from "../../components/basic-filter/divider";
import { MultiSelect } from "../../components/basic-filter/multi-select";

export const SalesBySupplier = {
    filter: Filter,

    isAvailable: () => {
        // if (hasCapabilitySupport("restaurant")) {
        //     return false;
        // }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" skipAutoFilter />
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Supplier" fieldKey="supplierField" options={endpoint.suppliers.map(item => item.value)} state={state} property="supplierName" />
        </div>
    </>)
}
