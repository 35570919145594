import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";
import { listDetailItemId } from "../../base/report-page";
import { DateRange } from "../../components/basic-filter/date-range";

export const VATDetail = {
    filter: Filter,

    onAction: (action, data, history) => {
        switch (action) {
            case "voucherNo":
                if (data.voucherType === "Invoice") {
                    window.open(listDetailItemId("V2SalesList", data.voucherId), "_blank")
                } else if (data.voucherType === "Bill") {
                    window.open(listDetailItemId("V2BillList", data.voucherId), "_blank")
                }
                break;
        }
    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" />
    </>)
}
