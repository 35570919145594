
import {
    ArrowRight16,
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
    Checkmark16,
    Error16
} from '@carbon/icons-react'
import React, { useCallback } from 'react'
import { SALES_WORK_STATUSES, statusEq, statusManager } from '../domain/statuses'
import Util from '../../../util/Util'

const ICONS = [
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
]


const Divider = ({ left, right }) => (
    <div style={{ position: 'relative' }}>
        <div style={{ background: left.current ? '#00520f' : left.done ? '#0f62fe' : '#0f0f0f', transition: 'background 250ms', position: 'absolute', left: -1, top: 0, height: '100%', width: 'calc(100% + 1px)', clipPath: 'polygon(0 0, 25% 0, 50% 50%, 25% 100%, 0 100%, 0 50%)' }} />
        <div style={{ position: 'relative', zIndex: 2, height: '100%', width: 15, background: 'white', clipPath: 'polygon(0 0, 25% 0, 75% 51%, 25% 100%, 0 100%, 50% 50%)' }} />
        <div style={{ background: right.current ? '#00520f' : right.done ? '#0f62fe' : '#0f0f0f', transition: 'background 250ms', position: 'absolute', right: -1, top: 0, height: '100%', width: 'calc(100% + 2px)', clipPath: 'polygon(25% 0, 100% 0, 100% 50%, 100% 100%, 25% 100%, 75% 50%)' }} />
    </div>
)


const DividerSubtitle = ({ left, right }) => (
    <div style={{ position: 'relative' }}>
        <div style={{ background: left.current ? '#00520f' : left.done ? '#0f62fe' : '#b5b5b5', transition: 'background 250ms', position: 'absolute', left: -1, top: 0, height: '100%', width: 'calc(100% + 1px)', clipPath: 'polygon(0 0, 25% 0, 50% 50%, 25% 100%, 0 100%, 0 50%)' }} />
        <div style={{ position: 'relative', zIndex: 2, height: '100%', width: 15, background: 'white', clipPath: 'polygon(0 0, 25% 0, 75% 51%, 25% 100%, 0 100%, 50% 50%)' }} />
        <div style={{ background: right.current ? '#00520f' : right.done ? '#0f62fe' : '#b5b5b5', transition: 'background 250ms', position: 'absolute', right: -1, top: 0, height: '100%', width: 'calc(100% + 2px)', clipPath: 'polygon(25% 0, 100% 0, 100% 50%, 100% 100%, 25% 100%, 75% 50%)' }} />
    </div>
)


const Item = ({ icon, title, done, current, enabled, onClick }) => (
    <div onClick={!current ? onClick : undefined} style={{ cursor: !current ? 'pointer' : 'default', height: '100%', flex: 1, background: current ? '#00520f' : done ? "#0f62fe" : "#0f0f0f", transition: 'background 250ms', }}>
        <div style={{ width: '100%', height: '100%', opacity: !enabled && !current ? 0.45 : 1, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
            <div style={{ marginRight: '0.5rem', height: 18, width: 18, borderRadius: 50, background: 'white', color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {React.createElement(icon)}
            </div>
            <div>
                <p style={{ fontSize: 12 }}>{title}</p>
            </div>
        </div>
    </div>
)

const ItemSubtitle = ({ first, done, current, enabled, onClick }) => (
    <div onClick={!current ? onClick : undefined} style={{ cursor: !current ? 'pointer' : 'default', height: '100%', flex: 1, background: current ? '#00520f' : done ? "#0f62fe" : "#b5b5b5", transition: 'background 250ms', }}>
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
            <div>
                {done && <p style={{ fontSize: 12, minWidth: 'calc(0.5rem + 18px)', paddingInline: '0.25rem' }}>
                    By <strong>{done.byUser}</strong><br />
                    {Util.formatDate(done.date, "DD.MMM hh:mm A")}
                </p>}
            </div>
        </div>
    </div>
)

const Timeline = ({ work, onStatusSelected }) => {
    const flow = SALES_WORK_STATUSES;
    const statusOp = statusManager(work)
    return (<>
        <div style={{ width: '100%', height: '2rem', background: '#1c1c1c', display: 'flex', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5, overflow: 'hidden' }}>
            {flow.map((status, index) => {
                return (<>
                    {index > 0 &&
                        <Divider key={status + 'divider'} left={statusOp(flow[index - 1])} right={statusOp(status)} />}

                    <Item key={status + 'status'}
                        {...statusOp(status)}
                        enabled
                        icon={ICONS[index]}
                        title={status}
                        onClick={() => onStatusSelected(status)}
                    />
                </>)
            })}
        </div>

        <div style={{ pointerEvents: 'none', width: '100%', height: '3rem', marginTop: '0.25rem', display: 'flex', color: 'white', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, overflow: 'hidden' }}>
            {flow.map((status, index) => {
                return (<>
                    {index > 0 &&
                        <DividerSubtitle key={status + 'divider'} left={statusOp(flow[index - 1])} right={statusOp(status)} />}

                    <ItemSubtitle key={status + 'status'}
                        {...statusOp(status)}
                        enabled
                        first={index === 0}
                        icon={ICONS[index]}
                        title={status}
                        onClick={() => onStatusSelected(status)}
                    />
                </>)
            })}
        </div>
    </>)
}

export const StatusTimeline = Timeline;
StatusTimeline.Item = Item;