import { ComposedModal, ModalBody, ModalFooter, ModalHeader, Checkbox, TextArea, TextInput } from "carbon-components-react";
import { ComboBoxField } from "../../../../../components/ComboBoxField";
import { makeListInput } from "../../../../../components/list-input";
import { Close16, ListBoxes16, Checkmark16, CheckmarkFilled16 } from '@carbon/icons-react'
import Util from "../../../../../util/Util";
import { UploadField } from "../../../../../components/upload/upload-field";
import Button from "../../../../../components/Button";
import Portal from "../../../../../util/Portal";
import { useState } from "react";


export const PortStaffPackageLoadingListEditor = makeListInput(({ item, setItemValue, onRemove, SortHandler, packageTypes }) => {
    if (!Util.isNumberExist(item.id)) {
        return <div />;
    }
    return (<>
        <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h6>Package: {item.id}</h6>
                <div style={{ flex: 1 }} />
            </div>

            {/* <Images /> */}
            {/* <label className="bx--label">Inspection images</label> */}

            <Button
                key={item.id + item.portLoadingConfirmed}
                onClick={() => setItemValue("portLoadingConfirmed", !item.portLoadingConfirmed)}
                renderIcon={item.portLoadingConfirmed ? CheckmarkFilled16 : Checkmark16}
                kind={item.portLoadingConfirmed ? undefined : "tertiary"}
                className={item.portLoadingConfirmed ? "green-button" : undefined}
                size="sm" style={{ width: '100%', borderRadius: 50, marginTop: '0.5rem' }}>
                {item.portLoadingConfirmed ? 'Confirmed!' : 'Confirm it is Loaded'}
            </Button>


            <div style={{ width: '100%', marginBottom: '1rem', paddingLeft: '6rem' }}>
                {item.items?.map(packageItem => (<>
                    <div style={{ marginTop: '1rem' }}>
                        <h6>Item: {packageItem.id}</h6>
                        <Button
                            key={packageItem.id + packageItem.portLoadingConfirmed}
                            onClick={() => {
                                setItemValue('items', item.items.map(e => e.id === packageItem.id ? ({
                                    ...packageItem,
                                    portLoadingConfirmed: !packageItem.portLoadingConfirmed
                                }) : e))
                            }}
                            renderIcon={packageItem.portLoadingConfirmed ? CheckmarkFilled16 : Checkmark16}
                            kind={packageItem.portLoadingConfirmed ? undefined : "tertiary"}
                            className={packageItem.portLoadingConfirmed ? "green-button" : undefined}
                            size="sm" style={{ width: '100%', borderRadius: 50, marginTop: '0.5rem' }}>
                            {packageItem.portLoadingConfirmed ? 'Confirmed!' : 'Confirm it is Loaded'}
                        </Button>
                    </div>
                </>))}
            </div>

            {item.confirmed && <TextArea value={item.confirmNote} placeholder="Confirm note..." onChange={e => setItemValue("confirmNote", e.target.value)} />}

        </div>


    </>)
}, () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
), true)