import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../../base/Page';
import { OBJECT_TYPE_BLOG_CATEGORY, OBJECT_TYPE_BLOG_POST } from '../../../constants/ObjectTypes';
// import Page from '../../base/Page';

import TableTemplate from '../../../templates/TableTemplate';

class BlogCategoriesListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Blog Categories",
                    subTitle: "All blog categories",
                    objectType: OBJECT_TYPE_BLOG_CATEGORY,
                    pagePath: "/blog-categories/",
                    history: this.props.history
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(BlogCategoriesListPage);