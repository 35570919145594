// import '../third-party-pos/third-party-pos.scss'
import Header from "../third-party-pos/components/Header"
import { DeliveryParcel24, AddFilled16, ArrowLeft16, CheckmarkFilled16, ErrorFilled16, WatsonHealth3DSoftware24 } from '@carbon/icons-react'
import Button from "../../../components/Button"
import { ContentSwitcher, DataTableSkeleton, SkeletonPlaceholder, Switch } from "carbon-components-react"
import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../../../constants/Constants"
import { useEffect, useMemo, useState } from "react"
import useApi from "../../../hooks/useApi"
import Api from "../../../session/Api"
import Table from "../../../components/data-table/Table"
import UIUtil from "../../../util/UIUtil"
import { Link, useHistory } from "react-router-dom"
import ProductList from "../components/ProductList"
import CartList from "../components/CartList"
import useStore from "../../../hooks/useStore"
import { makeObservable } from "../../../util/makeObservable"
import { withLoadablePageWithParams } from "../../../base/Page"
import { StockRequestStatusIndicator } from "../../../domain/stock-request"
import Util from "../../../util/Util"

const BackBtn = () => {
    const history = useHistory();
    return (
        <Button
            onClick={() => history.goBack()}
            style={{ marginRight: '0.5rem' }}
            className="t-pos-btn" renderIcon={ArrowLeft16} hasIconOnly
            tooltipPosition="bottom" iconDescription="Back" tooltipAlignment="start" />
    )
}

const Top = ({ stockRequest }) => {
    const [loading, revoke] = useApi(listener => Api.revokeMyStockRequest(stockRequest.id, listener), () => window.location.reload())
    return (
        <div className="dashboard-card t-pos-card" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Header icon={BackBtn} title="Stock Request" subtitle={"Date requested: " + Util.getDate(stockRequest.dateCreated)}>
                {/* <Link to="/new-stock-request" style={{ textDecoration: 'none' }}>
                    <Button
                        className="t-pos-btn" renderIcon={AddFilled16}>New Request</Button>
                </Link> */}
                <StockRequestStatusIndicator status={stockRequest.status} />
                {stockRequest.status == STOCK_REQUEST_STATUS_PENDING_APPROVAL &&
                    <Button
                        loading={loading}
                        kind="danger" onClick={() => UIUtil.confirm(revoke)}
                        className="t-pos-btn" renderIcon={ErrorFilled16}>Revoke Request</Button>
                }

                <div style={{ height: 48 }} />
            </Header>
        </div>
    )
}


const Content = ({ stockRequest }) => {
    const controller = useMemo(() => {
        const items = stockRequest.items.map(item => item.cartItemAlias);
        return {
            useClear: () => [false, () => { }],
            useItems: () => items,
            useUpdateCart: () => [() => { }, () => { }],
            useThirdPartyPos: () => { },
            useComplimentary: () => undefined
        }
    }, [stockRequest])
    return <CartList readonly customTitle="Selection" controller={controller} />
}

const View = ({ payload }) => {
    return (
        <div className="third-party-pos" style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%', padding: '0.5rem', gap: '0.5rem', }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <Top stockRequest={payload} />
                <div style={{ flex: 1, minHeight: 0 }}>
                    <Content stockRequest={payload} />
                </div>
            </div>
        </div>
    )
}

export default withLoadablePageWithParams(params => listener => Api.getMyStockRequest(params.itemId, listener), View)