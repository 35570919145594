import QRCode from "react-qr-code";
import { useRefSize } from "../../../../../util/useSize";
import { ArrowRight16 } from '@carbon/icons-react'
import { useState } from "react";
import Util from "../../../../../util/Util";
import { isMobileScreen } from "../../../../../App";
import { SHIPMENT_DIR_TYPE } from "../..";

const Item = ({ value }) => {
    const [ref, size] = useRefSize()
    return (
        <div ref={ref} style={{ width: '100%', height: size.width, background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)' }}>
            <QRCode size={size.width} value={window.location.origin.toString() + value} />
        </div>
    )
}

const List = ({ label, children }) => (<>
    <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{label}</h4>
    <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '1rem' }}>
        <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            {children}
        </div>
    </div>
</>)

const ValueEntry = ({ label, qrCodeValue, defVisible = false, hasValue = true, loading }) => {
    const [visible, setVisible] = useState(defVisible)
    const height = isMobileScreen ? 300 : 400;
    return (<>
        <div>
            <div onClick={hasValue ? (() => setVisible(v => !v)) : undefined} className={hasValue ? 'shipment-side-view-entry-record' : undefined} style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
                <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
                {hasValue ? (
                    <ArrowRight16 style={{ opacity: 0.65, transition: '250ms', transform: visible ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                ) : (
                    <p style={{ opacity: 0.65, fontSize: 12 }}>not available</p>
                )}
            </div>
            <div style={{ height: visible ? height : 0, width: '100%', overflow: 'hidden', transition: '250ms', padding: 0, background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ padding: '3rem', width: isMobileScreen ? '90%' : '100%', height: height, minHeight: height }}>
                    <Item value={qrCodeValue} />
                </div>
            </div>
        </div>
    </>)
}
export function QrCode({ shipment }) {

    return (
        <div style={{ width: '100%' }}>
            <List label="Main">
                <ValueEntry label="Shipment Job" qrCodeValue={"/shipment-job-qr/" + shipment.id} />
                {shipment.dirType === SHIPMENT_DIR_TYPE.EXPORT &&
                    <ValueEntry hasValue={Util.isNumberExist(shipment.shipmentMasterId)} label="Shipment Master" qrCodeValue={"/shipment-master-qr/" + shipment.shipmentMasterId} />}
            </List>

            {shipment.packages?.map(item => (
                <List key={item.id} label={"Package: " + (item.id)}>
                    <ValueEntry label="Package" qrCodeValue={"/shipment-package-qr/" + item.id} />
                    {item.items.map(packageItem => (
                        <ValueEntry key={packageItem.id} label={"Item: " + (packageItem.id)} qrCodeValue={"/shipment-package-item-qr/" + packageItem.id} />
                    ))}
                </List>
            ))}

            <div style={{ height: '16rem' }} />
        </div>
    )
}