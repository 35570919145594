
import { Client, Message } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { startNotificationSystem, stopNotificationSystem } from '../notification/notification-system';
import Util from '../util/Util';
import Api from './Api';
import { useEffect } from 'react';
import { clearAccount } from './SessionManager';

export const SOCKET_EVENT_MONITORING = "/topic/monitoring"
export const SOCKET_EVENT_POS_MONITORING = "/topic/pos-monitoring"
export const SOCKET_EVENT_POS_MONITORING_INPUT = "/topic/pos-monitoring-input";
export const SOCKET_EVENT_SALES_MONITORING = "/topic/sales-monitoring"
export const SOCKET_EVENT_POS_SESSION = "/topic/pos-session";
export const EVENT_SALES_ORDER_UPDATE = "/topic/sales-order-update";
export const EVENT_DASHBOARD_RELOAD = "/topic/dashboard-reload";

export const SOCKET_EVENT_LIVE_CART = cartId => "/topic/live-cart-" + cartId;

export const USER_EVENT_LOGGED_OUT = "/user/topic/usr-logged-out";

const events = [
    USER_EVENT_LOGGED_OUT
]


const predefinedSubscriptions = [
    {
        event: USER_EVENT_LOGGED_OUT, listener: () => {
            clearAccount();
            window.location.href = "/";
        }
    }
]


class SocketSession {

    constructor() {
        this.eventListeners = {
            ...Object.fromEntries(predefinedSubscriptions.map(sub => [sub.event, [sub.listener]]))
        };
        this.eventSubQueue = [
            ...predefinedSubscriptions.map(sub => ({ event: sub.event }))
        ];
    }

    registerListener(event, listener) {
        if (this.eventListeners[event] === undefined) {
            const listeners = [];
            listeners.push(listener);

            this.eventListeners[event] = listeners;
        } else {
            this.eventListeners[event].push(listener);
        }
    }

    unregisterListener(event, listener) {
        const listeners = this.eventListeners[event];
        const index = listeners !== undefined ? listeners.indexOf(listener) : -1;
        if (listeners !== undefined && index != -1) {
            listeners.splice(index, 1);

            if (listeners.length === 0) {
                delete this.eventListeners[event];
            }
        }
    }

    open() {
        if (this.socket !== undefined) {
            return;
        }

        this.socket = new Client({
            brokerURL: Api.getRawSocketUrl(),
            reconnectDelay: 2500,
        })


        this.socket.onConnect = () => {
            startNotificationSystem(this);

            // events.forEach(event => this.subscribe(event));

            let subscribedEvents = [];
            for (const item of this.eventSubQueue) {
                if (subscribedEvents.includes(item.event)) {
                    continue;
                }

                subscribedEvents.push(item.event);
                this.subscribe(item.event, item.onResponse)
            }
        }

        this.socket.activate();
    }

    subscribe(event, onResponse) {
        // onResponse({ unsubscribe: () => { } })

        this.eventSubQueue.push({
            event: event,
            onResponse: onResponse
        })

        if (this.socket !== undefined && this.socket.connected) {
            const subscription = this.socket.subscribe(event, message => this.onMessage(event, JSON.parse(message.body)));
            if (onResponse !== undefined) {
                onResponse(subscription);
            }
        }
    }

    onMessage(event, message) {
        // console.log("event " + event, message);

        const listeners = this.eventListeners[event];
        if (listeners !== undefined) {
            listeners.forEach(listener => listener(message));
        }
    }

    close() {
        if (this.socket === undefined) {
            return;
        }

        stopNotificationSystem(this);

        this.socket.deactivate();
        if (this.socket.connected) {
            this.socket.forceDisconnect();
        }
        this.socket = undefined;
    }

}

const session = new SocketSession();
export default session;



export function useSubscribe(event, listener) {
    useEffect(() => {
        session.registerListener(event, listener)

        let sub;
        session.subscribe(event, subscription => sub = subscription)
        return () => {
            session.unregisterListener(event, listener)
            sub?.unsubscribe()
        }
    }, [event])
}