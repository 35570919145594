import { Tag } from "carbon-components-react";
import { Widget } from ".";
import { Group16 } from "@carbon/icons-react"
import { Link } from "react-router-dom";
import { isMobileScreen } from "../../App";
import { useEffect, useState } from "react";


const Card = ({ customer }) => {
    const animate = true;
    const [playAnim, setPlayAnim] = useState(!customer.initial);
    useEffect(() => {
        if (animate) {
            setTimeout(() => {
                setPlayAnim(false)
            }, 1010)
        }
    }, [])

    return (
        <div style={{
            animation: animate && playAnim ? 'scale-bounce-anim 1000ms' : '',
            position: 'relative', background: 'white', padding: '1rem', borderRadius: 5, width: '100%'
        }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <Link to={"/customers/" + customer.id} target="_blank">
                        <p style={{ fontSize: 14, flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{customer.name}</p>
                    </Link>
                </div>
                {customer.fromOnlineStore
                    ? <Tag type="blue">Online</Tag>
                    : <Tag type="magenta">In Store</Tag>}
            </div>
            <p style={{ fontSize: 12, opacity: 0.65 }}>{customer.joinedMsg}</p>
        </div>
    )
}


export function NewCustomers({ data }) {
    const { customers, othersCount } = data.newCustomers

    if (isMobileScreen) {
        return (
            <Widget icon={Group16} title={"New Customers"} style={{ width: '100%' }}>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                    {customers.map(customer => <Card key={customer.id} customer={customer} />)}
                    {othersCount > 0 && <div style={{
                        borderRadius: 5, width: '100%', height: 40, background: '#A4243B20', border: '1px solid #A4243B', color: '#A4243B',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <span style={{ fontSize: 22, fontWeight: 'bold' }}>
                            +{othersCount}
                        </span>
                    </div>}
                </div>
            </Widget>
        )
    }

    return (
        <Widget icon={Group16} title={"New Customers"} style={{ width: '100%' }}>
            <div style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', columnGap: '1rem', flex: 1 }}>
                    {customers.map(customer => <Card key={customer.id} customer={customer} />)}
                </div>
                {othersCount > 0 && <div style={{
                    borderRadius: 5, minWidth: 82, background: '#A4243B20', border: '1px solid #A4243B', color: '#A4243B',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <span style={{ fontSize: 22, fontWeight: 'bold' }}>
                        +{othersCount}
                    </span>
                </div>}
            </div>
        </Widget>
    )
}