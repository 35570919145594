import { InlineLoading, Tag } from "carbon-components-react"
import { useForceLockScroll } from "../../../hooks/useLockScroll"
import useSize, { useRefSize } from "../../../util/useSize"
import './seat-designer.scss'
import { MapBoundary32, Add16, Subtract16, ErrorOutline16, CheckmarkFilled16, ErrorFilled16 } from '@carbon/icons-react'
import { useEffect, useState } from "react"
import { useGrid } from "./useGrid"
import { SeatItem } from "./seat-item"
import ReactTooltip from "react-tooltip"
import { Inspector } from "./inspector"
import Api from "../../../session/Api"
import UIUtil from "../../../util/UIUtil"
import { useSeatDesigner } from "./useSeatDesigner"


function Toolbar({ saving, onSaveBtn }) {
    return (
        <div className='' style={{ paddingInline: '1rem', height: '4rem', width: '100%', display: 'flex', alignItems: 'center', gap: '0.5rem', background: '#0f0f0f', color: 'white', borderBottom: '1px solid #ffffff20' }}>
            <MapBoundary32 style={{}} />
            <div style={{ flex: 1 }}>
                <h4>Designer</h4>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Theater seats</p>
            </div>
            {/* {hasAnySelections && <button onClick={onClearSelectionBtn} data-tip="Unselect All" data-effect="solid" className='lead-flow-icon-button lead-flow-icon-button-light'>
                <ErrorOutline16 />
            </button>} */}
            <button disabled={saving} onClick={onSaveBtn} data-tip="Save" data-effect="solid" className='lead-flow-icon-button lead-flow-icon-button-primary really_centered-progress-bar'>
                {saving ? <InlineLoading /> : <CheckmarkFilled16 />}
            </button>
        </div >
    )
}

const SeatTypeTag = ({ color, name }) => {
    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)
    return (
        <Tag
            size="sm"
            style={{
                color: isLightColor(color ?? "#000000") ? "black" : "white",
                background: color ?? "#000000",
            }}>{name}</Tag>
    )
}


const View = ({ itemId, endpoints }) => {
    const [canvasRef, canvasSize] = useRefSize()
    const grid = useGrid(endpoints.design);
    const seatDesigner = useSeatDesigner(itemId, grid);

    return (
        <div style={{ width: '100%', height: '100%', }}>
            <Toolbar saving={seatDesigner.saving} onSaveBtn={seatDesigner.onSaveBtn} />
            <div style={{ width: '100%', height: 'calc(100% - 4rem)', display: 'flex' }}>
                <div className="seat-designer-root seat-designer-root-grid" style={{ flex: 1, height: '100%' }}>
                    {grid.hasAnySelections() && <div className="seat-designer-clear-selection-button" onClick={grid.clearSelections}>
                        <ErrorOutline16 />
                        <p style={{ fontSize: 12 }}>Deselect All</p>
                    </div>}

                    <div className="seat-designer-legend-bar">
                        {endpoints.seatTypes.map(type => <SeatTypeTag key={type.id} color={type.color ?? '#b8730b'} name={type.name} />)}
                    </div>

                    <div style={{ zIndex: 2 }}>
                        <div style={{ color: 'white', width: 25, gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: canvasSize.height - 55, marginTop: 55 - 25, marginRight: '0.5rem', background: 'black', borderRadius: 50, border: '1px solid #ffffff40', zIndex: 2 }}>
                            <div style={{ width: 25, height: '0.75rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            </div>
                            {grid.rows().map(({ row, value }) => (
                                <div key={row + '-row'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 16, height: 16, width: 16 }}>
                                    <span style={{ fontSize: 12 }}>{value}</span>
                                </div>
                            ))}
                            <div style={{ width: 25, height: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div ref={canvasRef} style={{ position: 'relative' }} className="seat-designer-canvas">
                            <div className="cinema-screen" />
                            <div style={{ height: 55, width: '100%', display: 'flex', paddingTop: '0.75rem', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <Tag size="sm" style={{ zIndex: 1 }} type="high-contrast">screen</Tag>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '100%', height: 1, background: '#ffffff20' }} />
                            </div>
                            <div style={{ width: '100%', padding: '1rem', color: 'white', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {grid.grid().map((row, rowIndex) => (
                                    <div key={rowIndex + '-row-seats'} style={{ width: '100%', height: 16, display: 'flex', gap: 2 }}>
                                        {row.map((_, colIndex) => (
                                            <SeatItem key={rowIndex + '-' + colIndex + '-seat'} row={rowIndex} col={colIndex} grid={grid} />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ position: 'relative', color: 'white', height: 25, gap: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: canvasSize.width, marginTop: '0.5rem', background: 'black', borderRadius: 50, border: '1px solid #ffffff40', zIndex: 4 }}>
                            <div style={{ height: 25, width: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            </div>
                            {grid.cols().map(({ col, value }) => (
                                <div key={col + '-col'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 16, height: 16, width: 16 }}>
                                    <span style={{ fontSize: 10 }}>{value}</span>
                                </div>
                            ))}
                            <div style={{ height: 25, width: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            </div>
                        </div>
                    </div>
                    <div style={{ color: 'white', overflow: 'hidden', width: 50, gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: -80, marginLeft: '3rem', background: 'black', borderRadius: 50, border: '1px solid #ffffff40', zIndex: 2 }}>
                        <button onClick={grid.newRow} className="counter-button-seat-grid">
                            <Add16 />
                        </button>
                        <h4>{grid.rowCount}</h4>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Rows</p>
                        <button onClick={grid.removeRow} className="counter-button-seat-grid" disabled={grid.rowCount <= 1}>
                            <Subtract16 />
                        </button>
                    </div>
                    <div style={{ color: 'white', overflow: 'hidden', width: 50, gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: -80, marginLeft: '0.5rem', background: 'black', borderRadius: 50, border: '1px solid #ffffff40', zIndex: 2 }}>
                        <button onClick={grid.newCol} className="counter-button-seat-grid">
                            <Add16 />
                        </button>
                        <h4>{grid.colCount}</h4>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Cols</p>
                        <button onClick={grid.removeCol} className="counter-button-seat-grid" disabled={grid.colCount <= 1}>
                            <Subtract16 />
                        </button>
                    </div>
                </div>
                <div style={{ width: 300, height: '100%', background: '#090a0a', color: 'white', overflow: 'auto' }}>
                    {!grid.hasAnySelections() ? (
                        <div style={{ width: '100%', height: '100%', opacity: 0.65, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <ErrorOutline16 />
                            <p style={{ fontSize: 12, marginTop: '0.25rem' }}>No seats selected</p>
                        </div>
                    ) : (
                        <Inspector seatPlanId={itemId} endpoints={endpoints} grid={grid} />
                    )}
                </div>
            </div>
            <ReactTooltip />
        </div>
    )
}

export function SeatDesigner({ itemId }) {
    const [loading, setLoading] = useState(true);
    const [endpoints, setEndpoints] = useState(undefined)
    useForceLockScroll(true)

    useEffect(() => {
        setLoading(true);
        setEndpoints(undefined);

        Api.getSeatPlanEndpoints(itemId, response => {
            if (response.status === true) {
                setEndpoints(response.payload)
                setLoading(false);
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [itemId])

    if (loading) {
        return (
            <div className='really_centered-progress-bar' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading />
            </div>
        )
    } else {
        return <View itemId={itemId} endpoints={endpoints} />
    }
}