import {
    Industry16
} from '@carbon/icons-react'

import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom';
import { GOOGLE_MAPS_API_KEY } from '../../app/Config';
import { MAP_STYLE } from './map-style';

const DEF_LOC = { lat: 25.20112613289534, lng: 55.28151582031251 };
const DEF_ZOOM = 12;

export function Projects({ dashboard }) {
    const history = useHistory();
    return (<>
        <div style={{ padding: '1rem' }}>
            <h3 style={{ fontWeight: 'bold' }}>Projects</h3>
        </div>
        <div style={{ position: 'relative', width: 'calc(100% + 2rem)', marginLeft: '-1rem' }}>

            <GoogleMapReact
                // yesIWantToUseGoogleMapApiInternals
                style={{ width: '100%', height: 400 }}
                bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                defaultCenter={DEF_LOC}
                defaultZoom={DEF_ZOOM}
                options={{
                    styles: MAP_STYLE
                }}
            // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
                {dashboard.projects.map(project => (
                    <div key={project.id} className='map-property-marker' onClick={() => history.push('/real-estate-project/' + project.id)} lat={project.lat} lng={project.lng}>
                        <div style={{ width: 32, height: 32, minWidth: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Industry16 />
                        </div>
                        <p>{project.name}</p>
                    </div>
                ))}

                {/* <div key="few" className='map-property-marker'>
                    <div style={{ width: 32, height: 32, minWidth: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Industry16 />
                    </div>
                    <p>Property Name</p>
                </div> */}


                {/* {customerLocations.map(location => (
                <CustomerLocationMarker key={location.id + '-cm'} color={"#FFFFFF"} lat={location.lat} lng={location.lng}
                    name={location.name} onClick={() => window.open("/customers/" + location.id, "_blank")}
                />
            ))}

            {getSessionsForMap().map(unit => (
                unit.locationEvents.map((event, index) => (
                    <Marker key={unit.id + '-m:e-' + event.id} color={getRandomMarkerColor(unit.id)} {...event}
                        isStart={index == 0}
                        isCurrent={index == unit.locationEvents.length - 1}
                        onClick={() => setSelected(unit)}
                        onHoverChange={hover => {
                            if (hover) {
                                setFocus(unit.id)
                            } else if (focus == unit.id) {
                                setFocus(undefined)
                            }
                        }}
                    />
                ))
            ))}

            <ReactTooltip /> */}
            </GoogleMapReact>
        </div>
    </>)
}