import { DATE_TIME_TYPE } from "../../../domain/activity/activity-blueprint";
import Util from "../../../util/Util";

const ValueField = ({ title, value }) => (
    <div style={{ marginBottom: '0.75rem' }}>
        <label style={{ fontWeight: 'bold', marginBottom: 0, opacity: 1, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{value}</p>
    </div>
)

function getValue(field) {
    switch (field.type) {
        case "options":
            return field.optionValue;
        case "object-item":
            return field.objectValue;
        case "list":
            return field.optionValue;
        case "country":
            return field.value
        case "date-time":
            switch (field.dateTimeType) {
                case DATE_TIME_TYPE.dateAndTime:
                    return Util.getFullDate(parseInt(field.value))
                case DATE_TIME_TYPE.dateOnly:
                    return Util.getDateOnly(parseInt(field.value))
                default:
                    return field.value;
            }
        case "checkbox":
            return field.value === "true" ? "Yes" : "No";
        default:
            return field.value
    }
}

export function BlueprintInputFieldsReadView({ formFieldValues }) {
    return (
        <div>
            {formFieldValues.map(field => (
                <ValueField key={field.id} title={field.label} value={getValue(field)} />
            ))}

        </div>
    )
}

export function stringifyBlueprintInputFields(formFieldValues) {
    return formFieldValues.filter(field => field.type !== "file" && getValue(field)).map(field => (
        field.label + ": " + (getValue(field) ?? "-")
    )).join(", ");
}