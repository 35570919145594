import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_NEWSLETTER_SUBSCRIPTION, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';
import { EmailNew16 } from '@carbon/icons-react'
import Button from '../../components/Button';
import UIUtil from '../../util/UIUtil';

class NewsletterSubscriptionListPage extends Page {

    onSendEmailBtn() {
        UIUtil.showInfo("Please connect and setup email configuration")
    }

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Newsletter",
                    subTitle: "All subscriptions",
                    objectType: OBJECT_NEWSLETTER_SUBSCRIPTION,
                    pagePath: "/newsletter-subscriptions/",
                    history: this.props.history,
                    customRowButtonListener: () => { },
                    hideCreateButton: true,
                    customToolbarButton: <Button onClick={() => this.onSendEmailBtn()} renderIcon={EmailNew16}>Broadcast Email</Button>
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(NewsletterSubscriptionListPage);