import React, { useEffect } from 'react'

import { Van16, LocationStarFilled20, Currency16, AirlinePassengerCare16, ShoppingCartPlus16, LocationHazardFilled20, LocationHazard20, AirportLocation16 } from '@carbon/icons-react'
import { LOCATION_EVENT_FLAG_LOCATION_OFF, LOCATION_EVENT_FLAG_LOCATION_ON, LOCATION_EVENT_FLAG_STAGNANT, LOCATION_EVENT_FLAG_NONE, LOCATION_EVENT_FLAG_SALES, LOCATION_EVENT_FLAG_AMOUNT_COLLECTED } from '../../../constants/Constants'

import useHover from '../../../hooks/useHover'
import ReactTooltip from 'react-tooltip'

let colors = {}
//export const getRandomMarkerColor = id => colors[id] ?? (colors[id] = "#" + Math.floor(Math.random()*16777215).toString(16))
export const getRandomMarkerColor = id => colors[id] ?? (colors[id] = "hsl(" + Math.random() * 360 + ", 100%, 75%)")
export const dark_getRandomMarkerColor = id => {
    const color = getRandomMarkerColor(id);
    return color.substring(0, color.length - 4) + "25%)"
}

export const MainMarker = ({ color, ...props }) => (
    <div className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip="Current Location" data-effect="solid"
            className="map-monitoring-marker map-monitoring-main-marker" style={{ background: color }}>
            <Van16 />
        </div>
    </div>
)

export const SecondaryMarker = ({ color, ...props }) => (
    <div className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip="Was Stagnant Here" data-effect="solid"
            className="map-monitoring-marker map-monitoring-secondary-marker" style={{ background: color }}>

        </div>
    </div>
)

export const LocationOffMarker = ({ color, ...props }) => (
    <div className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip="Unreachable" data-effect="solid"
            className="map-monitoring-marker map-monitoring-location-marker" style={{ color }}>
            <LocationHazardFilled20 />
        </div>
    </div>
)

export const LocationOnMarker = ({ color, ...props }) => (
    <div className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip="Back Online" data-effect="solid"
            className="map-monitoring-marker map-monitoring-location-marker" style={{ color }}>
            <LocationHazard20 />
        </div>
    </div>
)

export const StartLocationMarker = ({ color, ...props }) => (
    <div
        className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip="Started Work" data-effect="solid"
            className="map-monitoring-marker map-monitoring-start-marker" style={{ color }}>
            <AirportLocation16 />
        </div>
    </div>
)

export const LocationSalesMarker = ({ color, ...props }) => (
    <div className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip="Made a sale!" data-effect="solid"
            className="map-monitoring-marker map-monitoring-location-marker" style={{ color }}>
            <ShoppingCartPlus16 />
        </div>
    </div>
)

export const AmountCollectionMarker = ({ color, ...props }) => (
    <div className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip="Amount collected" data-effect="solid"
            className="map-monitoring-marker map-monitoring-location-marker" style={{ color }}>
            <Currency16 />
        </div>
    </div>
)

export const CustomerLocationMarker = ({ color, name, ...props }) => (
    <div className="map-monitoring-marker-parent">
        <div
            {...props}
            data-tip={name} data-effect="solid"
            className="map-monitoring-marker map-monitoring-location-marker" style={{ color }}>
            <AirlinePassengerCare16 />
        </div>
    </div>
)



export const Marker = ({ flag, isCurrent, isStart, onHoverChange, ...props }) => {
    const hover = useHover()
    useEffect(() => {
        onHoverChange(hover.isHover)
    }, [hover.isHover])

    if (isCurrent && (flag == LOCATION_EVENT_FLAG_SALES || flag == LOCATION_EVENT_FLAG_STAGNANT || flag == LOCATION_EVENT_FLAG_AMOUNT_COLLECTED)) {
        flag = LOCATION_EVENT_FLAG_NONE
    }

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [])

    switch (flag) {
        case LOCATION_EVENT_FLAG_LOCATION_ON:
            return React.createElement(LocationOnMarker, { ...props, ...hover.init() })
        case LOCATION_EVENT_FLAG_LOCATION_OFF:
            return React.createElement(LocationOffMarker, { ...props, ...hover.init() })
        case LOCATION_EVENT_FLAG_STAGNANT:
            return React.createElement(SecondaryMarker, { ...props, ...hover.init() })
        case LOCATION_EVENT_FLAG_NONE:
            if (isCurrent) {
                return React.createElement(MainMarker, { ...props, ...hover.init() })
            } else if (isStart) {
                return React.createElement(StartLocationMarker, { ...props, ...hover.init() })
            } else {
                return null;
            }
        case LOCATION_EVENT_FLAG_SALES:
            return React.createElement(LocationSalesMarker, { ...props, ...hover.init() })
        case LOCATION_EVENT_FLAG_AMOUNT_COLLECTED:
            return React.createElement(AmountCollectionMarker, { ...props, ...hover.init() })
        default:
            return null;
    }
}
