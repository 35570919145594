import { Html5Qrcode } from "html5-qrcode"
import { useEffect, useRef, useState } from "react"
import Logo from '../../images/LogoTransparent.png'
import { CheckmarkFilled32, ErrorFilled32, Help32 } from '@carbon/icons-react'
import { Loading } from "carbon-components-react"
import Util from "../../util/Util"
import Api from "../../session/Api"

const positiveAudio = new Audio("positive.wav");
const negativeAudio = new Audio("negative.wav");


const useScanner = (onScanCode) => {
    useEffect(() => {
        const scanner = new Html5Qrcode("reader");
        //scanner.start({ facingMode: { exact: "user" } }, {
        scanner.start({ facingMode: "user" }, {
            fps: 10,
            qrbox: 300,
        }, (text, result) => onScanCode(text));
        return () => scanner.stop();
    }, [])
}

function parseCode(code) {
    if (code.startsWith("STU-ID-")) {
        try {
            const value = parseInt(atob(code.substring("STU-ID-".length)));
            return Util.isNumberExist(value) ? value : undefined;
        } catch (e) {
            return undefined;
        }
    } else {
        return undefined;
    }
}

function StatusValidator({ proof, code, onClear }) {
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (Util.isStringExists(code)) {
            setLoading(true);
            setResult(null);

            const parsedValue = parseCode(code);
            if (parsedValue === undefined) {
                setLoading(false);
                setTimeout(onClear, 2500)
                return;
            }

            Api.checkStudentForUnpaidInvoices(parsedValue, proof, response => {
                if (response.status === true) {
                    setResult(response.payload)
                    try {
                        if (response.payload.canProceed) {
                            positiveAudio.play();
                        } else {
                            negativeAudio.play();
                        }
                    } catch (e) { }
                }

                setLoading(false);
                setTimeout(onClear, 2500)
            })
        }
    }, [code])

    return (
        <div style={{ animation: "monitor-checkout-dialog-backdrop 500ms", position: 'absolute', left: 0, top: 0, width: '100vw', height: '100%', background: '#00000080', zIndex: 10000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{
                animation: "monitor-checkout-dialog 500ms",
                width: 400, paddingTop: '3rem', paddingBottom: '3rem', paddingInline: '0.5rem', borderRadius: 25, background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }}>
                {loading ? (<>
                    <Loading withOverlay={false} />
                </>) : result?.canProceed === false ? (<>
                    <ErrorFilled32 style={{ color: '#990000', width: 96, height: 96 }} />
                    <h4 style={{ textAlign: 'center', marginTop: '1rem' }}>Hello, {result.name}</h4>
                    <p style={{ textAlign: 'center', }}>You have unpaid invoices due. Please complete payments before proceeding.</p>
                </>) : result?.canProceed === true ? (<>
                    <CheckmarkFilled32 style={{ color: 'green', width: 128, height: 128 }} />
                    <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>Welcome <strong>{result.name}</strong>!</h3>
                    <h4>You can proceed</h4>
                </>) : (<>
                    <Help32 style={{ width: 96, height: 96 }} />
                    <h4 style={{ textAlign: 'center', marginTop: '1rem' }}>QR Code not recognized</h4>
                    {/* <p style={{ textAlign: 'center', }}>You have unpaid invoices due. Please complete payments before proceeding.</p> */}
                </>)}
            </div>
        </div>
    )
}

function captureVideo(readerRef) {
    const video = readerRef.current.querySelector('video')
    const canvas = document.createElement('canvas');
    canvas.width = video.clientWidth;
    canvas.height = video.clientHeight;

    let ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURI = canvas.toDataURL('image/jpeg');

    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
        byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

export function LmsQrCodeScanner() {
    const readerRef = useRef(null);
    const [scanCode, setScanCode] = useState(undefined);
    const [proof, setProof] = useState(undefined)

    const active = useRef(true);
    useScanner(code => {
        if (!active.current) {
            return;
        }
        active.current = false;

        setProof(captureVideo(readerRef))
        setScanCode(code);
    });
    useEffect(() => {
        if (scanCode === undefined) {
            active.current = true;
        }
    }, [scanCode])

    return (
        <div ref={readerRef} style={{ position: 'relative', height: '100%', width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, background: 'black' }}>
            {/* <div style={{ position: 'absolute', zIndex: 1000, left: '1rem', top: '1rem', width: 'calc(100% - 2rem)', display: 'flex', gap: '0.5rem' }}> */}
            {/* <div style={{ width: '100%', paddingBlock: '0.5rem', paddingInline: '0.75rem', background: '#0f0f0f90', backdropFilter: 'blur(4px)', borderBottom: '1px solid #ffffff10', display: 'flex', gap: '0.5rem' }}> */}
            <div style={{ position: 'absolute', zIndex: 100, left: '0rem', top: '0rem', width: '100%', paddingBlock: '0.5rem', paddingInline: '0.75rem', background: '#0f0f0f90', backdropFilter: 'blur(4px)', borderBottom: '1px solid #ffffff10', display: 'flex', gap: '0.5rem' }}>
                <div style={{ background: '#00000080', backdropFilter: 'blur(6px)', borderRadius: 10, border: '1px solid #ffffff40', padding: '0.5rem', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                    <img src={Logo} style={{ height: 35, objectFit: 'contain' }} />
                </div>
                {/* display: 'flex', alignItems: 'center', */}
                <div style={{ background: '#00000080', display: 'flex', alignItems: 'center', gap: '0.25rem', color: 'white', backdropFilter: 'blur(6px)', borderRadius: 10, border: '1px solid #ffffff40', padding: '0.5rem', flex: 1 }}>
                    <div style={{ flex: 1 }}>
                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>Welcome!</p>
                        <p style={{ fontSize: 12, marginTop: -2 }}>Please scan your QR code to proceed</p>
                    </div>
                    <p style={{ fontStyle: 'italic', fontSize: 12, opacity: 0.65 }}>Please note, a snapshot will be taken when you scan your QR code</p>
                </div>
            </div>
            <div id="reader" width="100%" style={{ width: '100%' }} />

            {Util.isStringExists(scanCode) && <StatusValidator proof={proof} code={scanCode} onClear={() => {
                setProof(undefined)
                setScanCode(undefined)
            }} />}
        </div>
    )
}