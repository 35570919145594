import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import RoboLogo from '../../../../../../images/RoboBlack.png'

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
});


function PDF({ fnbEvent }) {
    return (
        <Document>
            <Page size="A4" orientation='portrait' style={styles.page}>
                <Image src={RoboLogo} style={{ objectFit: 'contain', objectPosition: 'center', width: 200 }} />
                <Text>{JSON.stringify(fnbEvent, null, 2)}</Text>
            </Page>
        </Document>
    )
}

export function createInvoice(fnbEvent) {
    return {
        PDF,
        canGenerate: true,
    }
}