
import bg from '../../images/other/romario-runners/bg.png'
import bgTop from '../../images/other/romario-runners/bg-top.png'
import bgBottom from '../../images/other/romario-runners/bg-bottom.png'
import logo from '../../images/other/romario-runners/r-logo.png'
import { Document, Font, View, Text, Page, Image, StyleSheet } from "@react-pdf/renderer";
import Util, { big } from '../../util/Util';
import Api from '../../session/Api';
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from '../../constants/ObjectTypes';
import React from 'react';

import { ToWords } from 'to-words';
import QRCode from 'qrcode'

const qtyToWord = num =>
    new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        }
    }).convert(num)
        .toLowerCase()
        .replace("dollar", "units")
        .replace("cent", "units");


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        width: '100%',
        // paddingTop: 42,
        // paddingBottom: 42,
        // paddingLeft: 42,
        // paddingRight: 42,
        // paddingTop: 20,
        // paddingLeft: 20,
        // paddingRight: 20,
        // paddingBottom: 90,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    pageBackground: {
        position: 'absolute',
        bottom: 0, right: 0,
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        objectPosition: 'bottom right'
    },
});

Font.register({
    //family: "M ontserrat",
    family: "Cairo",
    fonts: [
        {
            //src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf",
            src: "//fonts.gstatic.com/s/cairo/v1/9BU6Hrio9syG9zwo_CNPXg.ttf",
            fontWeight: 200
        },
        {
            //src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
            src: "//fonts.gstatic.com/s/cairo/v1/-tPnHq7mmAjcjJRSjsuZGA.ttf"
        },
        {
            //src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
            src: "//fonts.gstatic.com/s/cairo/v1/Ct_3a0tcTEyNNSnuZKDd7g.ttf",
            fontWeight: 600
        }
    ]
})

Font.register({
    family: 'Roboto Mono',
    src: '//fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf'
});

function addNewlineAfter13Characters(inputString) {
    const maxLength = 20;
    let result = '';

    for (let i = 0; i < inputString.length; i += maxLength) {
        const chunk = inputString.slice(i, i + maxLength);
        result += chunk + '\n';
    }

    return result;
}

const size = (x) => ({ width: x, maxWidth: x, minWidth: x, height: x, maxHeight: x, minHeight: x })

const COLS = (showImage, showRRP, showHsCode, showCoo) => {
    return [
        ...(showImage ? [
            {
                id: "image",
                name: "",
                flex: 1,
                alignment: "center",
                skipWrap: true,
                render: row => <Image style={{ ...size(32), objectFit: "contain", objectPosition: "center" }} src={Api.getThumbnailForSure(OBJECT_TYPE_PRODUCT, row.id)} />
            },
        ] : []),
        {
            id: "description",
            name: "Item",
            flex: 3,
            alignment: "flex-start",
            render: row => (row.description === "Total Quantity" ? (
                <Text style={{ fontSize: 8 }}>{row.description}</Text>
            ) : (
                <Text style={{ fontSize: 8 }}>{addNewlineAfter13Characters(row.description)}</Text>
            ))
            // render: row => <Text style={{ fontSize: 8 }}>{addNewlineAfter13Characters("FM9936-WHITE/BLACK/.BLACK-NS")}</Text>
        },
        {
            id: "info",
            name: "Description",
            flex: 4,
            alignment: "flex-start",
            render: row => <Text style={{ fontSize: 8 }}>{row.info}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>{'desc whatever fhgreighreu'}</Text>
        },
        ...(showCoo ? [
            {
                id: "coo",
                name: "COO",
                flex: 1,
                alignment: "center",
                render: row => <Text style={{ fontSize: 8 }}>{row.coo}</Text>
            },
        ] : []),
        ...(showHsCode ? [
            {
                id: "hsCode",
                name: "HS Code",
                flex: 1,
                alignment: "center",
                render: row => <Text style={{ fontSize: 8 }}>{row.hsCode}</Text>
                // render: row => <Text style={{ fontSize: 8 }}>3000</Text>
            },
        ] : []),
        {
            id: "qty",
            name: "Qty",
            flex: 1,
            alignment: "center",
            render: row => <Text style={{ fontSize: 8 }}>{row.qty}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>3000</Text>
        },
        ...(showRRP ? [
            {
                id: "rrp",
                name: "RRP",
                flex: 1,
                alignment: "center",
                render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.rrp)}</Text>
            },
        ] : []),
        {
            id: "unitPrice",
            name: "Rate",
            flex: 1,
            alignment: "flex-end",
            render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.unitPrice)}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(633)}</Text>
        },
        {
            id: "total",
            name: "Amount",
            flex: 2,
            alignment: "flex-end",
            render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.total)}</Text>
        },
    ]
}




const TableValueField = ({ label, mulitext, sm, children }) => (
    <View>
        <View style={{ borderBottom: '0px solid black', backgroundColor: '#01AC63', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2 }}>
            <Text style={{ fontSize: 10, lineHeight: 0, textTransform: 'uppercase', color: 'white', fontFamily: 'Cairo', }}>{label}</Text>
        </View>
        {/* <View style={{ backgroundColor: '#EFF9F4', paddingLeft: 5, paddingRight: 5, paddingTop: mulitext ? 5 : 2, paddingBottom: mulitext ? 5 : 2 }}> */}
        <View style={{ backgroundColor: '#EFF9F4', paddingLeft: 5, paddingRight: 5, paddingTop: 0, paddingBottom: mulitext ? 5 : 2 }}>
            <Text style={{ fontSize: sm ? 8 : 10, fontFamily: "Cairo", fontWeight: 600 }}>{children ? children : ' '}</Text>
        </View>
    </View>
)

const TableHeader = ({ cols }) => (
    <View fixed style={{ flexDirection: 'row' }}>
        {cols.map((col, i) => (
            <View key={col.id} style={{ backgroundColor: '#01AC63', paddingLeft: 5, paddingRight: 0, paddingTop: 2, paddingBottom: 2, flex: col.flex, border: 1, borderLeft: i == 0 ? 1 : 0, borderColor: '#01AC63' }}>
                <Text style={{ fontFamily: 'Cairo', fontSize: 10, lineHeight: 0, color: 'white', textAlignment: 'center', textTransform: 'uppercase', margin: 0, padding: 0 }}>{col.name}</Text>
            </View>
        ))}
    </View>
)

const TableRow = ({ cols, rows, rowBorder, normalPaddingTop, showImage }) => (
    <View style={{ backgroundColor: '#EFF9F4', borderBottom: 1, borderColor: '#01AC63', overflow: 'hidden' }}>
        {rows.map((row, index) => (
            <View wrap={false} style={{
                flexDirection: 'row', minHeight: 32, border: 1, borderTop: 0,
                borderBottom: rowBorder ? '1px solid #01AC63' : 0, borderColor: '#01AC63',
                // backgroundColor: index % 2 === 0 ? "red" : "blue",
                // paddingVertical: normalPaddingTop ? 1 : 5,
            }}>
                {cols.map((col, i) => (
                    <View key={col.id} style={{ flex: col.flex, borderLeft: i == 0 ? 0 : 1, paddingLeft: 1, paddingRight: 1, borderColor: '#01AC63', justifyContent: 'center', alignItems: col.alignment }}>
                        {col.skipWrap ? (<>
                            {Object.keys(row).length > 0 ? (
                                col.render(row)
                            ) : <View />}
                        </>) : (<>
                            <Text style={{ fontSize: 11, paddingHorizontal: 4, fontFamily: row._totalQty ? 'Cairo' : 'Roboto Mono', fontWeight: row._totalQty ? 600 : undefined }}>
                                {Object.keys(row).length > 0 ? (
                                    col.render(row)
                                ) : ''}
                            </Text>
                        </>)}
                    </View>
                ))}
            </View>
        ))}
    </View>
)

const Table = ({ showImage, cols, rows, normalPaddingTop, rowBorder }) => (
    <View style={{ flex: 1, }}>
        <TableHeader cols={cols} />
        <TableRow cols={cols} rows={rows} normalPaddingTop={normalPaddingTop} rowBorder={rowBorder} showImage={showImage} />
    </View>
)

function getCustomerName(doc) {
    const builder = []
    const append = (text) => Util.isStringExists(text) && builder.push(text)
    append(doc.customerName)
    append(doc.customerFullAddress)
    append(doc.customerTrn)
    return builder.join("\n");
}



export function RunnersRomarioInvoicePdf({ invoice, showImage, showRRP, showMemo, showHsCode, showCoo, title = "Tax Invoice" }) {
    // let qr = undefined;
    // if (Util.isStringExists(invoice.trackingId)) {
    //     const canvas = document.createElement('canvas');
    //     //REPEATED
    //     QRCode.toCanvas(canvas, window.location.origin.toString() + "/sw-tracking/" + invoice.trackingId, {
    //         margin: 0
    //     });
    //     qr = canvas.toDataURL();
    // }
    // invoice = inv1()
    // invoice = inv2().payload;

    const totalItem = (() => {
        const totalQty = parseFloat(invoice.items.map($ => big($.qty ?? 0)).reduce((t, c) => t.add(c), big(0)).toFixed(2))
        const startUpper = text => text.substring(0, 1).toUpperCase() + text.substring(1)
        return {
            id: Math.random(),
            description: "Total Quantity",
            info: startUpper(qtyToWord(totalQty)) + " unit" + (totalQty !== 1 ? 's' : ''),
            qty: totalQty,
            _totalQty: true,
            rrp: { __override_format_money_fn: '' },
            unitPrice: { __override_format_money_fn: '' },
            total: { __override_format_money_fn: '' },
        }
    })()
    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <Image fixed src={bg} style={styles.pageBackground} />
                <Image src={bgTop} style={{ width: '100%' }} />

                <View fixed style={{
                    flexDirection: 'row', borderBottom: '0px solid black',
                    position: 'absolute', left: 20, bottom: 25, width: '50%',
                }}>
                    <View style={{ flex: 1 }}>
                        <TableValueField label={"Phone"}>{invoice.systemCompany.tel}</TableValueField>
                    </View>
                    <View style={{ flex: 1 }}>
                        <TableValueField label={"Email"}>{invoice.systemCompany.email}</TableValueField>
                    </View>
                </View>


                <View style={{ padding: 20, paddingVertical: 0, }}>
                    <Text style={{ fontFamily: 'Cairo', fontWeight: 600, fontSize: 24, color: '#01AC63', }}>{title}</Text>
                    <Text style={{ fontFamily: 'Cairo', fontSize: 14, marginTop: -6 }}>TRN: {invoice.systemCompany.trn}</Text>

                    <View fixed style={{ height: 20 }} />

                    <View style={{ border: '0px solid black', marginBottom: 0 }}>
                        <View style={{ flexDirection: 'row', borderBottom: '0px solid black', }}>
                            <View style={{ flex: 1 }}>
                                <TableValueField label={"Voucher no"}>{invoice.invoiceNo}</TableValueField>
                            </View>
                            <View style={{ flex: 1 }}>
                                <TableValueField label={"Date"}>{Util.formatDate(invoice.date, "DD/MM/YYYY")}</TableValueField>
                            </View>
                            <View style={{ flex: 1 }}>
                                <TableValueField label={"Rep"}>{Util.isStringExists(invoice.salesPersonNameFullName) ? invoice.salesPersonNameFullName : '-'}</TableValueField>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                                <TableValueField mulitext label={"Invoice to"}>{getCustomerName(invoice)}</TableValueField>
                            </View>
                        </View>
                    </View>


                    <Table showImage={showImage} cols={COLS(showImage, showRRP, showHsCode, showCoo)} rows={
                        invoice.items
                            // .concat(invoice.items)
                            .concat([totalItem])
                    } />
                    <View wrap={false} style={{ flexDirection: 'row', marginBottom: 0, paddingBottom: 90 }}>
                        <View style={{ flex: 3, paddingTop: 5, paddingRight: 4 }}>
                            {showMemo && <TableValueField sm label={"Memo"}>
                                {invoice.memo}
                            </TableValueField>}
                            <TableValueField sm label={"Bank Details"}>
                                {`Account Name: ${invoice.systemCompany.name}
                                Bank Name: ${invoice.systemCompany.bankName}
                                Branch Name: ${invoice.systemCompany.branch}
                                Swift Code: ${invoice.systemCompany.swiftCode}
                                Account Number: ${invoice.systemCompany.bankAccountNo}
                                IBAN Number: ${invoice.systemCompany.iban}`}
                            </TableValueField>

                        </View>
                        <View style={{ flex: 2, }}>
                            <View style={{ backgroundColor: '#EFF9F4', border: '1px solid #01AC63', borderTop: 0, alignItems: 'center', paddingVertical: 5, paddingHorizontal: 5, flexDirection: 'row' }}>
                                <Text style={{ fontSize: 12, fontFamily: 'Cairo', fontWeight: 600 }}>Subtotal</Text>
                                <Text style={{ fontSize: 10, fontFamily: 'Cairo', flex: 1, textAlign: 'right' }}>AED {Util.formatMoney(invoice.totals.subTotal)}</Text>
                            </View>

                            {invoice.totals.discount > 0 &&
                                <View style={{ backgroundColor: '#EFF9F4', border: '1px solid #01AC63', borderTop: 0, alignItems: 'center', paddingVertical: 5, paddingHorizontal: 5, flexDirection: 'row' }}>
                                    <Text style={{ fontSize: 12, fontFamily: 'Cairo', fontWeight: 600 }}>Discount</Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Cairo', flex: 1, textAlign: 'right' }}>AED {Util.formatMoney(invoice.totals.discount)}</Text>
                                </View>}

                            {invoice.totals.vatTotal > 0 &&
                                <View style={{ backgroundColor: '#EFF9F4', border: '1px solid #01AC63', borderTop: 0, alignItems: 'center', paddingVertical: 5, paddingHorizontal: 5, flexDirection: 'row' }}>
                                    <Text style={{ fontSize: 12, fontFamily: 'Cairo', fontWeight: 600 }}>VAT</Text>
                                    <Text style={{ fontSize: 10, fontFamily: 'Cairo', flex: 1, textAlign: 'right' }}>AED {Util.formatMoney(invoice.totals.vatTotal)}</Text>
                                </View>}

                            <View style={{ backgroundColor: '#EFF9F4', border: '1px solid #01AC63', borderTop: 0, alignItems: 'center', paddingVertical: 5, paddingHorizontal: 5, flexDirection: 'row' }}>
                                <Text style={{ color: '#01AC63', fontSize: 10, fontFamily: 'Cairo', fontWeight: 600 }}>Total</Text>
                                <Text style={{ fontSize: 12, fontFamily: 'Cairo', flex: 1, textAlign: 'right' }}>AED {Util.formatMoney(invoice.totals.grandTotal)}</Text>
                            </View>
                        </View>
                    </View>





                    {/* <View fixed style={{
                        // position: 'absolute', 
                        paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2,
                        // marginTop: 10, 
                        justifyContent: 'flex-end', alignItems: 'center'
                    }}>
                        <Text style={{ fontSize: 10, textAlign: 'center', fontFamily: "Cairo", fontWeight: 600 }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                    </View> */}



                </View>

                {/* <View style={{ flex: 1 }} /> */}
                {/* <Image src={bgBottom} style={{ width: '100%' }} /> */}


            </Page>
        </Document>
    )
}


