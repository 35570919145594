import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { TableHeader } from './TableHeader';
import TableFooter from './TableFooter';
import TableRows from './TableRows';

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#ffb6a8',
    },
});

const Table = ({ report }) => (
    <View style={styles.tableContainer}>
        <TableHeader />
        {/* <InvoiceTableRow items={invoice.items} /> */}
        <TableRows items={report.unpaidSales} />
        
        <TableFooter title="Misc Amount" value={report.miscOutstandingAmount.toFixed(2)} />
        <TableFooter title="Total Outstanding" value={report.totalOutstandingAmount.toFixed(2)} />
    </View>
);

export default Table