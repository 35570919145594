import React from 'react'
import Page from '../../../base/Page';
import CustomComboBox from '../../../components/CustomComboBox';
import Api from '../../../session/Api';

import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16
} from '@carbon/icons-react'
import Button from '../../../components/Button';
import { withRouter } from 'react-router-dom';

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)



class GeneralLedgerReportPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            accountTree: [],
            selectedAccount: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getAccountTree(false, listener), payload => ({ accountTree: payload }))
    }

    canGenerateReport() {
        return this.state.selectedAccount !== undefined;
    }

    onGenerateBtn() {
        this.props.history.push(`/general-ledger-report/${this.state.selectedAccount.id}`)
    }

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>General Ledger</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title="Account">
                        <div style={{ height: 40 }}>
                            <CustomComboBox items={this.state.accountTree} selectedItem={this.state.selectedAccount} onSelectedItemUpdate={item => this.setState({ selectedAccount: item })} />
                        </div>
                    </Section>

                    <Section icon={Number_232} title="Report" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.onGenerateBtn.bind(this)} disabled={!this.canGenerateReport()} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

}

export default withRouter(GeneralLedgerReportPage);