import { useCallback, useMemo, useState } from "react";
import ImageView from "../../../components/ImageView";
import { OBJECT_TYPE_MOVIE } from "../../../constants/ObjectTypes";
import Api from "../../../session/Api";
import { SeatSelector } from "../seat-selector";
import Button from "../../../components/Button";
import { CheckmarkFilled16, Ticket24 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react";
import { big } from "../../../util/Util";
import { VAT } from "../../../app/Config";

function seatsToState(seats) {
    return Object.fromEntries(seats.map(seat => [`${seat.row},${seat.col}`, true]))
}

const useOnGridRawUpdate = (setSeats) => {
    return useCallback(({ rowCount, colCount, selections, seatTypes }, funcs) => {
        console.log({ selections })
        setSeats(Object.entries(seatTypes).filter(entry => entry[1]?.enabled === true && selections[entry[0]] === true).map(entry => {
            const [row, col] = entry[0].split(",");

            return {
                row: row,
                col: col,
                posName:
                    (funcs.rows().find(item => item.row == row)?.value ?? row) +
                    (funcs.cols().find(item => item.col == col)?.value ?? col),
                seatTypeName: entry[1].type?.name ?? 'Unknown',
                ticketPrice: entry[1].type?.defaultTicketPrice ?? 0,
            }
        }))
    }, [])
}

const FinalizeOrder = ({ showtimeId, seats, onConfirmTicketsBtn }) => {
    const amts = useMemo(() => {
        const subtotal = seats.map(item => big(item.ticketPrice)).reduce((t, c) => t.add(c), big(0)).round(2, 1)
        const tax = big(VAT.FRAC).times(subtotal).round(2, 1)
        const total = subtotal.plus(tax).round(2, 1)
        return { subtotal: subtotal.toFixed(2), tax: tax.toFixed(2), total: total.toFixed(2) }
    }, [seats])

    const [loading, setLoading] = useState(false);
    const onConfirmBtn = () => {
        setLoading(true)
        onConfirmTicketsBtn(amts.total, () => setLoading(false))
    }

    return (<>
        <div style={{ display: 'flex', height: 22, alignItems: 'center' }}>
            <label style={{ margin: 0, flex: 1, color: 'white', fontWeight: 'bold' }} className="bx--label">Subtotal</label>
            <h4 style={{ fontSize: 14 }}>AED {amts.subtotal}</h4>
        </div>
        <div style={{ display: 'flex', height: 22, alignItems: 'center' }}>
            <label style={{ margin: 0, flex: 1, color: 'white', fontWeight: 'bold' }} className="bx--label">VAT {`${VAT.PERC}%`}</label>
            <h4 style={{ fontSize: 14 }}>AED {amts.tax}</h4>
        </div>
        <div style={{ display: 'flex', height: 22, alignItems: 'center', }}>
            <label style={{ margin: 0, flex: 1, color: 'white', fontWeight: 'bold' }} className="bx--label">Total</label>
            <h4 style={{ color: 'green', fontWeight: 'bold' }}>AED {amts.total}</h4>
        </div>

        <Button loading={loading} onClick={onConfirmBtn} renderIcon={CheckmarkFilled16} style={{ borderRadius: 7, width: '100%', maxWidth: 'unset', marginTop: '1rem' }}>Confirm Tickets</Button>
    </>)
}


const OrderSummary = ({ seats, overview, onConfirmTicketsBtn }) => {
    const movieName = overview?.[0];
    return (<>
        <div style={{ paddingInline: '1rem', paddingBlock: '0.5rem', background: '#00000080', borderBottom: '1px solid #ffffff10', display: 'flex', alignItems: 'center', gap: '0.25rem', flexWrap: 'wrap' }}>
            <h4 style={{ fontSize: 22, flex: 1, wordBreak: 'break-word' }}>{movieName}</h4>
        </div>
        {seats.length ? (
            <div style={{ flex: 1, minHeight: 0, overflow: 'auto' }}>
                {seats.map(seat => (
                    <div key={seat.row + ',' + seat.col + '-ticket'} style={{ width: '100%', paddingInline: '1rem', paddingBlock: '0.5rem', borderBottom: '1px solid #ffffff10', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <Ticket24 />
                        <div style={{ flex: 1, }}>
                            <p>{seat.seatTypeName + ' Seat'}</p>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>{seat.posName}</p>
                        </div>
                        <strong>AED</strong> {seat.ticketPrice}
                    </div>
                ))}
            </div>
        ) : (
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Please select seats</p>
            </div>
        )}
        <div style={{ padding: '1rem', background: '#00000080', borderTop: '1px solid #ffffff10', }}>
            <FinalizeOrder seats={seats} onConfirmTicketsBtn={onConfirmTicketsBtn} />
        </div>
    </>)
}

const Info = ({ overview }) => {
    const theaterType = overview?.[1].replace("3D", "");
    const time = overview?.[2];
    const is3D = overview?.[1]?.includes("3D")
    return (
        <div style={{ display: 'flex', }}>
            <div style={{ flex: 1 }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Theater Type</p>
                <h4>
                    {theaterType}
                    {is3D && <Tag size="sm" type="high-contrast"><strong>3D</strong></Tag>}
                </h4>
            </div>
            <div style={{ flex: 1 }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Showtime</p>
                <h4>{time}</h4>
            </div>
            <div style={{ flex: 1 }} />
        </div>
    )

}
export function SeatList({ overview, movieId, showtimeId, seats, setSeats, onConfirmTicketsBtn }) {
    const onGridRawUpdate = useOnGridRawUpdate(setSeats);
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div style={{ flex: 5, paddingBlock: '1rem', paddingLeft: '1rem', color: 'white', minWidth: 0, overflow: 'auto' }}>
                {/* <Info overview={overview} /> */}
                <div style={{ marginTop: '0rem', minWidth: '100%', display: "inline-block", paddingRight: '1rem' }}>
                    <SeatSelector defaultState={seatsToState(seats)} itemId={showtimeId} onGridRawUpdate={onGridRawUpdate} />
                </div>
            </div>
            <div style={{ position: 'relative', color: 'white', background: '#0f0f0f', flex: 2, height: '100%', borderLeft: '1px solid #1A1C2D' }}>
                <ImageView src={Api.getThumbnail(OBJECT_TYPE_MOVIE, movieId)} style={{ width: '100%', height: '100%', }} />
                <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', minHeight: 0, display: 'flex', flexDirection: 'column', background: '#000000AA', backdropFilter: 'blur(16px) saturate(200%)', }}>
                    <OrderSummary overview={overview} seats={seats} onConfirmTicketsBtn={onConfirmTicketsBtn} />
                </div>
            </div>
        </div>
    )
}