
export const BARCODE_TYPE_CODE128 = "CODE128";
export const BARCODE_TYPE_EAN_13 = "EAN13";
export const BARCODE_TYPE_EAN_8 = "EAN8";
export const BARCODE_TYPE_EAN_5 = "EAN5";
export const BARCODE_TYPE_EAN_2 = "EAN2";
export const BARCODE_TYPE_UPC = "UPC";
export const BARCODE_TYPE_CODE39 = "CODE39";
export const BARCODE_TYPE_ITF14 = "ITF14";
export const BARCODE_TYPE_MSI = "MSI";
export const BARCODE_TYPE_MSI10 = "MSI10";
export const BARCODE_TYPE_MSI11 = "MSI11";
export const BARCODE_TYPE_MSI1010 = "MSI1010";
export const BARCODE_TYPE_MSI1110 = "MSI1110";
export const BARCODE_TYPE_PHARMACODE = "pharmacode";
export const BARCODE_TYPE_CODABAR = "codabar";

export const ALL_BARCODE_TYPES = [
    BARCODE_TYPE_CODE128,
    BARCODE_TYPE_EAN_13,
    BARCODE_TYPE_EAN_8,
    BARCODE_TYPE_EAN_5,
    BARCODE_TYPE_EAN_2,
    BARCODE_TYPE_UPC,
    BARCODE_TYPE_CODE39,
    BARCODE_TYPE_ITF14,
    BARCODE_TYPE_MSI,
    BARCODE_TYPE_MSI10,
    BARCODE_TYPE_MSI11,
    BARCODE_TYPE_MSI1010,
    BARCODE_TYPE_MSI1110,
    BARCODE_TYPE_PHARMACODE,
    BARCODE_TYPE_CODABAR
]