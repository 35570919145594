
import { useEffect, useRef, useState } from 'react';
import Bg from '../../images/_bg-test.jpeg'
import LightBg from '../../images/bg-test.jpeg'
import { BoardView } from '../../views/board-view/BoardView';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { useNumberLocalStorage } from '../../hooks/useLocalStorage';

export function BoardViewContent({ parentId, parentType, initialBoardViews, allUsers }) {
    const [loading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState(() => initialBoardViews?.[0]?.id ?? 0);
    // const [selectedId, setSelectedId] = useNumberLocalStorage("board-view-selection-" + parentId + '-' + parentType, initialBoardViews?.[0]?.id ?? 0)
    const [boardViews, setBoardViews] = useState(initialBoardViews);

    const [dark, setDark] = useState(false)
    const [title, setTitle] = useState("")
    const [groups, setGroups] = useState([])
    const [items, setItems] = useState([])
    const [assignations, setAssignations] = useState([]);
    const [canAssign, setCanAssign] = useState(false);

    const skipSave = useRef(true);
    useEffect(() => {
        if (!loading) {
            skipSave.current = false;
        }
    }, [loading])

    const setBoardView = boardView => {
        const { dark, title, groups, items, assignations, canAssign } = boardView;
        setDark(dark)
        setTitle(title)
        setGroups(groups);
        setItems(items)
        setAssignations(assignations);
        setCanAssign(canAssign);
    }

    useEffect(() => {
        if (selectedId > 0 && initialBoardViews.find(view => view.id !== selectedId) === undefined) {
            setSelectedId(initialBoardViews?.[0]?.id ?? 0);
            return;
        }

        setLoading(true);
        let cancelled = false;
        Api.getToDoBoardView(selectedId, parentId, parentType, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setBoardView(response.payload);
                setLoading(false);
            } else {
                UIUtil.showError("Failed loading board view");
            }
        })
        return () => {
            cancelled = true
            skipSave.current = true;
        };
    }, [selectedId])



    useEffect(() => {
        if (skipSave.current) {
            return;
        }
        Api.saveToDoBoardView(parentId, parentType, {
            id: selectedId,
            dark, title, groups,
            items: items.map(item => ({ ...item, id: typeof item.id !== "number" ? 0 : item.id })),
            assignations: assignations.map(item => ({ ...item, id: typeof item.id !== "number" ? 0 : item.id })),
        }, response => {
            if (response.status === true) {
                setBoardViews(response.payload.boardViews);
                setSelectedId(response.payload.toDoBoard.id);
            } else {
                UIUtil.showError("Could not save change");
            }
        })
        // Api.saveBoardView(recordId, { dark, title, groups, items }, () => { })
    }, [dark, title, groups, items, assignations])

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <img src={dark ? Bg : LightBg} style={{ position: 'absolute', zIndex: 1, left: 0, top: 0, objectFit: 'cover', width: '100%', height: '100%', pointerEvents: 'none' }} />
            <div style={{ zIndex: 100, position: 'relative', height: '100%', width: '100%' }}>
                <BoardView {...({ loading, boardViews, selectedId, canAssign, dark, setDark, title, setTitle, groups, setGroups, items, setItems, allUsers, assignations, setAssignations })}
                    selectId={setSelectedId} />
            </div>
        </div>
    )
}

// export function BoardViewContent({ recordId, initalData }) {
//     const [dark, setDark] = useState(initalData?.dark ?? false)
//     const [title, setTitle] = useState(initalData?.title ?? "My Board")
//     const [groups, setGroups] = useState(initalData?.groups ?? [])
//     const [items, setItems] = useState(initalData?.items ?? [])

//     const firstTime = useRef(true);
//     useEffect(() => {
//         if (firstTime.current) {
//             firstTime.current = false;
//             return;
//         }
//         Api.saveBoardView(recordId, { dark, title, groups, items }, () => { })
//     }, [dark, title, groups, items])

//     return (
//         <div style={{ width: '100%', height: '100%', position: 'relative' }}>
//             <img src={dark ? Bg : LightBg} style={{ position: 'absolute', zIndex: 1, left: 0, top: 0, objectFit: 'cover', width: '100%', height: '100%', pointerEvents: 'none' }} />
//             <div style={{ zIndex: 100, position: 'relative', height: '100%', width: '100%' }}>
//                 <BoardView {...({ dark, setDark, title, setTitle, groups, setGroups, items, setItems })} />
//             </div>
//         </div>
//     )
// }