import React from 'react'
import { Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, DataTableSkeleton, Tag, ButtonSet, TableSelectAll, TableSelectRow, ExpandableSearch, ComposedModal, ModalHeader, ModalBody, ModalFooter } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import { 
    Add16, DirectionStraightRight32, Delivery16, DeliveryParcel16, Error16, Save16, ArrowRight16, ArrowLeft16, Calendar16, QueryQueue16,
    CheckboxIndeterminate32,
    Delivery32,
    DataVis_232,
    Store32,
    Cube32,
    AirlinePassengerCare32,
    DirectionStraightRight16,
    Calculator16,
    Archive16,
    SettingsCheck16, DeliveryParcel32,
    Identification16,
    Calendar32,
    Wifi32
} from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Button from '../../components/Button';
import { Link, useHistory, withRouter } from "react-router-dom";
import DirectionIndicator from '../../components/DirectionIndicator';
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_WAREHOUSE, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE, STOCK_FLOW_INITIATION_TYPE_POS, TRANSACTION_DIRECTION_TYPE_INWARD } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STORE, OBJECT_TYPE_WAREHOUSE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_PRODUCT, getObjectTypeUrl } from '../../constants/ObjectTypes';
import Util from '../../util/Util';

const HEADERS = [
    {
        header: "Staff User",
        key: "userFullName"
    },
    {
        header: "Title",
        key: "title"
    },
    {
        header: "Event",
        key: "message"
    },
    {
        header: "IP Address",
        key: "ipAddress"
    },
    {
        header: "Time",
        key: "timestamp"
    },
]

class SystemLogsListView extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            rows: [],

            userIdFilter: "",

            showLogDialog: false,
            selectedLog: undefined
        }
    }

    componentDidMount() {
        this.setState({loading: true})
        const listener = response => {
            this.setState({loading: false, rows: response.payload !== null ? response.payload : []})
            if (response.payload === null) {
                UIUtil.showError(response.message);
            }
        }
        
        if (this.props.relatedObjectId) {
            Api.getRelatedSystemLogs(this.props.relatedObjectId, listener);
        } else {
            Api.getAllSystemLogs(listener);
        }
    }

    getTitle() {
        if (this.props.relatedObjectId) {
            return null;
        }

        return "System Logs";
    }

    getSubtitle() {
        if (this.props.relatedObjectId) {
            return null;
        }

        return "All system logs";
    }

    getHeaders() {
        return HEADERS;
    }

    getCellHeader(cell) {
        const headers = this.getHeaders();
        for (const header of headers) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.rows) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.rows) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        switch (header.key) {
            case "userFullName":
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, row.userId)} />
                        <p style={{marginLeft: '0.5rem'}}>{cell.value}</p>
                    </div>
                )

            case "timestamp":
                return <> <Calendar16 /> {Util.getFullDate(cell.value)} </>

            case "ipAddress":
                //return <label className="bx--label">{Util.isStringExists(cell.value) ? cell.value : '-'}</label>
                return Util.isStringExists(cell.value) ? cell.value : '-';

            default:
                return cell.value;
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ width: '100%' }}>
                    <DataTableSkeleton />
                </div>
            )
        }

        return (
            <div style={{ width: '100%' }}>
                <DataTable rows={Util.isStringExists(this.state.userIdFilter) ? this.state.rows.filter(row => row.stockFlowRecordId == this.state.userIdFilter) : this.state.rows} headers={this.getHeaders()} isSortable> 
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer title={this.getTitle()} description={this.getSubtitle()}>
                            {!this.props.hideToolbar && <TableToolbar>
                                <TableToolbarContent>
                                    <TableToolbarSearch placeholder={"Search"} onChange={onInputChange} />
                                    {/* <ExpandableSearch placeholder={"Find by ID"} 
                                    renderIcon={<div style={{width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none'}}>
                                        <Identification16 />
                                    </div>}
                                    onChange={e => this.setState({userIdFilter: e.target.value})} value={this.state.userIdFilter}  /> */}
                                    {/* <Link to={this.getNewPath()} style={{textDecoration: 'none'}}>
                                        <Button style={{minWidth: 250}} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
                                    </Link> */}
                                </TableToolbarContent>
                            </TableToolbar>}
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => this.setState({ selectedLog: this.getRow(row.id), showLogDialog: true }))} key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>

                {this.state.selectedLog !== undefined && 
                <ComposedModal open={this.state.showLogDialog} onClose={() => this.setState({showLogDialog: false})} size="md">
                    <ModalHeader label="System Log" title={this.state.selectedLog.title} />
                    <ModalBody style={{paddingRight: '1rem'}}>

                        <h6 style={{marginBottom: '0.5rem'}}>User</h6>
                        <Link target="_blank" style={{}} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + this.state.selectedLog.userId}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ProfilePic size={32} style={{marginRight: '0.5rem'}} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, this.state.selectedLog.userId)} />
                                <p>{this.state.selectedLog.userFullName}</p>
                            </div>
                        </Link>

                        <h6 style={{marginBottom: '0.5rem', marginTop: '1rem'}}>Date</h6>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Calendar32 style={{marginRight: '0.5rem'}}  />
                            <p>{Util.getFullDate(this.state.selectedLog.timestamp)}</p>
                        </div>

                        <h6 style={{marginBottom: '0.5rem', marginTop: '1rem'}}>IP Address</h6>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Wifi32 style={{marginRight: '0.5rem'}}  />
                            <p>{Util.isStringExists(this.state.selectedLog.ipAddress) ? this.state.selectedLog.ipAddress : '-'}</p>
                        </div>

                        <h6 style={{marginBottom: '0.5rem', marginTop: '1.5rem'}}>Event</h6>
                        <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all', overflow: 'hidden'}}>
                            {this.state.selectedLog.message}
                        </p>
                        

                    </ModalBody>
                    <ModalFooter 
                    // onRequestClose={() => this.setState({showLogDialog: false})}
                    // secondaryButtonText="Close"
                    >
                        <Button kind="secondary" onClick={() => this.setState({showLogDialog: false})}>
                            Close
                        </Button>
                    </ModalFooter>
                </ComposedModal>}
            </div>
        )
    }

}

export default SystemLogsListView;