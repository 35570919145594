import { isMobileScreen } from "../../../../App";
import { hasCapabilitySupport } from "../../../../app/Capabilities";
import { Card } from "../components/card";
import { ContentGrid } from "../components/content-grid";
import { ActivityReport } from "../widgets/activity-report";
import { Comments } from "../widgets/comments";
import { FlowInfo } from "../widgets/flow-info";
import { LeadInfo } from "../widgets/lead-info";
import { NoteDocs } from "../widgets/note-docs";
import { ProspectCourses } from "../widgets/prospect-courses";
import { ProspectProperties } from "../widgets/prospect-properties";
import { Status } from "../widgets/status";


export function Overview({ leadId, lead, store, data }) {
    if (isMobileScreen) {
        return (
            <div style={{ background: '#E0E9F5', display: 'flex', flexDirection: 'column', gap: '0.5rem', marginTop: '1rem' }}>
                <Card rowSpan={2} colSpan={2}>
                    <LeadInfo leadId={leadId} store={store} />
                </Card>
                <Card>
                    <FlowInfo leadId={leadId} lead={lead} store={store} data={data} />
                </Card>
                <Card>
                    <Status leadId={leadId} store={store} lead={lead} />
                </Card>

                {hasCapabilitySupport("realEstate") &&
                    <Card noPadding colSpan={2}>
                        <ProspectProperties leadId={leadId} data={data} />
                    </Card>}
                {hasCapabilitySupport("lms") &&
                    <Card noPadding colSpan={2}>
                        <ProspectCourses leadId={leadId} data={data} />
                    </Card>}
                <Card noPadding>
                    <NoteDocs leadId={leadId} />
                </Card>


                <Card noPadding>
                    <Comments leadId={leadId} />
                </Card>


                <div style={{ height: 300 }} />
            </div>
        )
    }

    return (
        <ContentGrid
            left={<>
                <Card rowSpan={2} colSpan={2}>
                    <LeadInfo leadId={leadId} store={store} />
                </Card>
                {hasCapabilitySupport("realEstate") &&
                    <Card noPadding colSpan={2}>
                        <ProspectProperties leadId={leadId} data={data} />
                    </Card>}
                {hasCapabilitySupport("lms") &&
                    <Card noPadding colSpan={2}>
                        <ProspectCourses leadId={leadId} data={data} />
                    </Card>}
                <Card noPadding>
                    <NoteDocs leadId={leadId} />
                </Card>

                {/* remember to lower prospect properties height if uncommenting this */}
                {/* <Card colSpan={2}>
                    <ActivityReport />
                </Card> */}
            </>}
            right={<>
                <Card>
                    <FlowInfo leadId={leadId} lead={lead} store={store} data={data} />
                </Card>
                <Card>
                    <Status leadId={leadId} store={store} lead={lead} />
                </Card>
                <Card noPadding>
                    <Comments leadId={leadId} />
                </Card>
            </>}
        />
    )


    // return (
    //     <ContentGrid
    //         left={<>
    //             <Card rowSpan={2} colSpan={2}>
    //                 <LeadInfo leadId={leadId} store={store} />
    //             </Card>
    //             {hasCapabilitySupport("realEstate") &&
    //                 <Card noPadding colSpan={2}>
    //                     <ProspectProperties leadId={leadId} data={data} />
    //                 </Card>}
    //             {hasCapabilitySupport("lms") &&
    //                 <Card noPadding colSpan={2}>
    //                     <ProspectCourses leadId={leadId} data={data} />
    //                 </Card>}


    //             {/* remember to lower prospect properties height if uncommenting this */}
    //             {/* <Card colSpan={2}>
    //                 <ActivityReport />
    //             </Card> */}
    //         </>}
    //         right={<>
    //             <Card>
    //                 <FlowInfo leadId={leadId} lead={lead} store={store} data={data} />
    //             </Card>
    //             <Card>
    //                 <Status leadId={leadId} store={store} lead={lead} />
    //             </Card>
    //             <Card noPadding>
    //                 <NoteDocs leadId={leadId} />
    //             </Card>
    //             <Card noPadding>
    //                 <Comments leadId={leadId} />
    //             </Card>
    //         </>}
    //     />
    // )


    // return (
    //     <ContentGrid>
    //         <Card rowSpan={2} colSpan={2}>
    //             <LeadInfo leadId={leadId} store={store} />
    //         </Card>
    //         <Card>
    //             <FlowInfo leadId={leadId} lead={lead} store={store} data={data} />
    //         </Card>
    //         <Card>
    //             <Status />
    //         </Card>
    //         <Card noPadding colSpan={2}>
    //             <ProspectProperties leadId={leadId} data={data} />
    //         </Card>
    //         <Card noPadding>
    //             <NoteDocs leadId={leadId} />
    //         </Card>
    //         <Card colSpan={2}>
    //             <ActivityReport />
    //         </Card>
    //         <Card noPadding maxHeight={600}>
    //             <Comments leadId={leadId} />
    //         </Card>
    //     </ContentGrid>
    // )
}