import { useEffect, useRef, useState } from 'react';
import './advanced-filter.scss'
import { Header } from "./header";
import { TreeItem } from './tree-item';
import {
    Draggable16
} from '@carbon/icons-react'
import arrayMove from 'array-move';
import { NewItemBtn } from './new-item-btn';
import { typeName } from './components/type-name';
import { InlineLoading } from 'carbon-components-react';
import ReactTooltip from 'react-tooltip';
import { isEqual } from 'lodash'
import { ADVANCED_FILTER_TYPE } from '.';
import { OBJECT_TYPE_PRO_DESIGN_NODE } from '../../../constants/ObjectTypes';
import { makeDraggable } from '../../../hooks/useDraggable';
import Util from '../../../util/Util';
import UIUtil from '../../../util/UIUtil';
import Api from '../../../session/Api';

function useItemsManager(parentId, setItems) {
    const addNewItem = newItem => {
        if (newItem.parentId !== parentId) {
            const itemMapper = item => {
                if (item.id == newItem.parentId) {
                    return {
                        ...item,
                        items: [...item.items, newItem]
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(prevItems => [...prevItems, newItem])
        }
    }
    const updateItem = itemUpdate => {
        if (itemUpdate.parentId !== parentId) {
            const itemMapper = item => {
                if (item.id == itemUpdate.parentId) {
                    return {
                        ...item,
                        items: item.items.map(subItem => subItem.id === itemUpdate.id ? itemUpdate : subItem)
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(prevItems => prevItems.map(subItem => subItem.id === itemUpdate.id ? itemUpdate : subItem))
        }
    }
    const removeItem = itemToRemove => {
        if (itemToRemove.parentId !== parentId) {
            const itemMapper = item => {
                if (item.id == itemToRemove.parentId) {
                    return {
                        ...item,
                        items: item.items.filter(subItem => subItem.id === itemToRemove.id ? false : true)
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(prevItems => prevItems.filter(subItem => subItem.id === itemToRemove.id ? false : true))
        }
    }
    return { addNewItem, updateItem, removeItem }
}

const InputFieldItemHeader = () => (
    <div style={{ width: '1.5rem', height: '3rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'grab' }}>
        <Draggable16 />
    </div>
)

const Item = ({ item, ...props }) => {
    if (item.type == ADVANCED_FILTER_TYPE.LOGIC) {
        return <Section item={item} {...props} />
    } else {
        return <TreeItem item={item} title={item.title} type={item.type} {...props} />
    }
}
const draggable = makeDraggable(Item, InputFieldItemHeader);

const ItemList = ({ parentId, readonly, items, setItems, onNewItem, updateItem, removeItem, columns }) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (Util.isStringExists(parentId)) {
            const itemMapper = item => {
                if (item.id == parentId) {
                    return {
                        ...item,
                        items: arrayMove(item.items, oldIndex, newIndex)
                    }
                } else if (item.items) {
                    return {
                        ...item,
                        items: item.items.map(itemMapper)
                    }
                } else {
                    return item;
                }
            }

            setItems(prevItems => prevItems.map(itemMapper))
        } else {
            setItems(arrayMove(items, oldIndex, newIndex))
        }
    }

    return (<>
        <draggable.SortContainer onSortEnd={onSortEnd}>
            {items.map((item, index) => <draggable.SortItem key={item.id} index={index} readonly={readonly} item={item} setItems={setItems} {...{ columns, onNewItem, updateItem, removeItem }} />)}
        </draggable.SortContainer>

        {!readonly && <NewItemBtn onNewItem={type => onNewItem(parentId, type)} />}
    </>)
}

const Section = ({ readonly, item, setItems, SortHandler, onNewItem, updateItem, removeItem, columns }) => {
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [expanded])

    return (<>
        <TreeItem {...{ updateItem, removeItem, columns }}
            readonly={readonly} item={item} isSection itemCount={item.items.length} title={item.title} type={item.type} expanded={expanded} setExpanded={setExpanded} SortHandler={SortHandler} />
        {expanded && <div style={{ paddingLeft: '3rem', position: 'relative', animation: 'advanced-filter-section-fade-in 250ms' }}>
            <div style={{ position: 'absolute', left: '1.5rem', top: 0, height: 'calc(100% - 1rem)', width: 1, background: '#1c1c1c40', }} />

            <ItemList readonly={readonly} parentId={item.id} items={item.items} setItems={setItems} {...{ onNewItem, updateItem, removeItem, columns }} />
        </div>}
    </>)
}

const ContentView = ({ columns, parentId, readonly, items, setItems, title, onBackBtn }) => {
    const { addNewItem, updateItem, removeItem } = useItemsManager(parentId, setItems)

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [items])

    const onNewItem = (itemParentId, type) => {
        addNewItem({
            id: Util.newTempId(),
            parentId: Util.isStringExists(itemParentId) ? itemParentId : parentId, type,
            items: [],
            operator: type === ADVANCED_FILTER_TYPE.LOGIC ? "AND" : undefined
        })
    }

    return (
        <div style={{ width: '100%', height: '100%', }}>
            <Header onBackBtn={onBackBtn}>{title}</Header>
            <div style={{ animation: 'advanced-filter-content-fade-in 250ms', height: 'calc(100% - 3rem)', width: '100%', overflow: 'auto', padding: '1rem' }}>
                <ItemList readonly={readonly} items={items} setItems={setItems}
                    {...{ columns, onNewItem, updateItem, removeItem }} />
            </div>
            <ReactTooltip />
        </div>
    )
}

const DEF_VALUE = []

const MainContent = ({ state, title, readonly, onBackBtn, columns }) => {
    const [items, setItems] = state.use("advancedFilterItems", DEF_VALUE)

    // useEffect(() => {
    //     console.log(items)
    // }, [items])

    return <ContentView columns={columns} onBackBtn={onBackBtn} parentId="root" title={title} items={items} setItems={setItems} readonly={readonly} />
}

export function AdvancedFilterView({ state, title, readonly, columns, onBackBtn }) {
    return (
        <div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
            <MainContent state={state} title={title} readonly={readonly} onBackBtn={onBackBtn} columns={columns} />
            {/* <div style={{ height: '6rem' }} /> */}
        </div>
    )
}