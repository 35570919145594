import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import Util from "../../../util/Util";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box"
import { LocationFilter } from "./location-filter";
import { ProductFilter } from "./product-filter";

export const ProductMergeReport = {
    filter: Filter,
    payload: Payload,

    // newLink: '/stock-flow-creator',
    itemToOpenLink: item => `/stock-flow/${Util.getIdFromVoucherNumber(item.stockFlowId)}`,
    hasPayloadBar: true,

    isAvailable: () => {
        return false;
        // const role = getAccountRole();
        // return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
        // return false;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <div style={{ minHeight: 30, flex: 1 }}>
            <ProductFilter state={state} options={endpoint.products} />
        </div>
        <div style={{ height: 36, flex: 1 }}>
            <LocationFilter state={state} accountTree={endpoint.locations} />
        </div>


        {/* <DateRange state={state} property="salesDate" />
        <Divider />
        <TextBox state={state} label="Source" property="sourceName" />
        <TextBox state={state} label="Customer" property="customerName" />
        <TextBox state={state} label="Product" property="products" /> */}

        {/* <TextBox state={state} label="Amount Status" property="amountStatus" /> */}
    </>)
}

function Payload({ payload }) {
    return (<>
        <p style={{ fontSize: 12, opacity: 0.65, fontWeight: 'bold', marginRight: '0.25rem' }}>Stock:</p>
        <p>{Util.formatAmount(payload.stock)}</p>
    </>)
}