import { STOCK_TYPE_CONTINUOUS, STOCK_TYPE_DISCRETE } from "../../../constants/Constants"
import Api from "../../../session/Api"
import { FormSection } from "../../../templates/form/form"
import { FormDialog } from "../../../templates/form/form-dialog"
import Util from "../../../util/Util"

export function IngredientFormDialog({ visible, onClose, onSuccess, endpoint, title, item }) {
    return (
        <FormDialog
            setItem={item => onSuccess(item)} label="Ingredient" title={title}
            formConfig={{ skipFieldEffect: true }}
            saveApi={(data, listener) => {
                if (Util.isNumberExist(item?.id)) {
                    Api.saveIngredient({ ...data, id: item.id }, listener)
                } else {
                    Api.saveIngredient(data, listener)
                }
            }}
            open={visible} onClose={onClose} item={{
                name: item?.name ?? "",
                departmentId: item?.departmentId ?? 0,
                categoryId: item?.categoryId ?? 0,
                subcategoryId: item?.subcategoryId ?? 0,

                cost: item?.cost ?? 0,
                uom: item?.uom ?? "Piece(s)",
                stockType: item?.stockType ?? 0,
                continuousMeasurementType: item?.continuousMeasurementType ?? 0
            }} payload={endpoint}>
            {IngredientFormFields}
        </FormDialog>
    )
}

const STOCK_TYPES = [{ id: STOCK_TYPE_DISCRETE, title: "Countable (e.g. 1 unit)" }, { id: STOCK_TYPE_CONTINUOUS, title: "Measurable (e.g. 1.5 unit)" }]

export const IngredientFormFields = ({
    TextField, IfTargetGroup, SwitchField, ComboBoxField
}, endpoint) => (<>
    <TextField title="Name" fieldKey="name" />

    <FormSection title="Inventory">
        <TextField fieldKey="cost" title="Default Cost" help="Default cost value to autofill the cost field in stock flow creation" />
        <SwitchField title="Stock Type" fieldKey="stockType" options={STOCK_TYPES} />
        <IfTargetGroup targetKey="stockType" checkTargetIf={stockType => stockType === STOCK_TYPE_DISCRETE}>
            <TextField fieldKey="uom" title="Unit of Measurement" />
        </IfTargetGroup>
        <IfTargetGroup targetKey="stockType" checkTargetIf={stockType => stockType === STOCK_TYPE_CONTINUOUS}>
            <ComboBoxField title="Measurement Type" fieldKey="continuousMeasurementType" options={endpoint.measurementTypes} />
        </IfTargetGroup>
    </FormSection>

    <FormSection title="Classification">
        <ComboBoxField title="Department" fieldKey="departmentId" options={endpoint.departments} />
        <ComboBoxField title="Category" fieldKey="categoryId" options={endpoint.categories} />
        <ComboBoxField title="Subcategory" fieldKey="subcategoryId" options={endpoint.subcategories} />
    </FormSection>
</>)