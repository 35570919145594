import '../pages/activities/components/user-assignation.scss'

import { Add16 } from '@carbon/icons-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import { Link, Select, SelectItem, Tag, TextInput } from 'carbon-components-react'
import ProfilePic from './ProfilePic'
import Util from '../util/Util'
import Api from '../session/Api'

function useStore(store, name) {
    return store[name];
}

const ButtonPopover = ({ parentRef, selectedItems, children, popover, closeOnChange }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (closeOnChange) {
            setOpen(false);
        }
    }, [selectedItems, closeOnChange])

    return (
        <Popover
            isOpen={open}
            // positions={['bottom', 'right']}
            parentElement={parentRef?.current}
            // padding={10}
            containerStyle={{ zIndex: 1000000 }}
            align="end"
            onClickOutside={e => {
                // if (!classExistsInSomeParent(e.target, "table-list-filter-save-dialog")) {
                setOpen(false)
                // }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container user-assignation-button-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="user-assignation-button-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        {popover}
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                {children}
            </a>
        </Popover>
    )
}

const Message = ({ value }) => (
    <div>
        <div style={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ fontSize: 12, opacity: 0.65 }}>
                {value}
            </p>
        </div>
    </div>
)


const SelectionItem = ({ selected, thumbnailSrc, name, onClick, nameHighlight }) => (
    <div onClick={onClick} data-selected={selected} className='user-assignation-selection-item'>
        {thumbnailSrc && <ProfilePic src={thumbnailSrc} notProfile size={32} style={{ minWidth: 32, borderRadius: 5 }} />}
        <p style={{ fontSize: 14, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0 }}>{name}</p>
        {selected && <Tag type="green" size="sm">Selected</Tag>}
    </div>
)


function createSelection(item) {
    return { id: Util.newTempId(), itemId: item.id, name: item.value, raw: item }
}

const List = ({ items, selectedItems, setSelectedItems, objectType, title }) => {
    const isSelected = itemId => selectedItems.find(item => item.itemId == itemId) !== undefined;
    const onUserClick = user => {
        const itemId = user.id;
        if (isSelected(itemId)) {
            setSelectedItems(items => items.filter(item => item.itemId != itemId))
        } else {
            setSelectedItems(items => [...items, createSelection(user)])
        }
    }
    return (
        <div style={{ padding: '0rem', borderRadius: 15, overflow: 'hidden' }}>
            <p style={{ fontSize: 12, opacity: 0.65, padding: '1rem' }}>{title}</p>
            <div style={{ maxHeight: 250, overflowX: 'hidden', overflowY: 'auto', borderTopWidth: 1, borderTopColor: '#00000020', borderTopStyle: 'solid' }}>
                {items.map(item => <SelectionItem thumbnailSrc={objectType == -1 ? undefined : Api.getThumbnail(objectType, item.id)} name={item.value}
                    selected={isSelected(item.id)} onClick={() => onUserClick(item)} />)}
            </div>
            {items.length === 0 && <Message value="No items exists" />}
        </div>
    )
}

export const SelectionPopover = ({ items, children, selectedItems, setSelectedItems, objectType, title, closeOnChange }) => {
    const ref = useRef()
    return (
        <ButtonPopover parentRef={ref} closeOnChange={closeOnChange} selectedItems={selectedItems} popover={<List {...{ items, selectedItems, setSelectedItems, objectType, title }} />}>
            <div ref={ref}>
                {children}
            </div>
        </ButtonPopover>
    )
}
