import { useRef } from "react"
import { ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY, ONLINE_STORE_URL } from "../../../app/Config"
import { useForceLockScroll } from "../../../hooks/useLockScroll"


export function OnlineStoreAdminView() {
    const ref = useRef();
    useForceLockScroll(true)

    const onLoad = () => {
        if (ref.current) {
            ref.current.contentWindow.postMessage("RBAVAK: " + ONLINE_STORE_ADMIN_VIEW_ACCESS_KEY, ONLINE_STORE_URL)
        }
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <iframe onLoad={onLoad} ref={ref} style={{ width: '100%', height: '100%' }} src={ONLINE_STORE_URL} />
        </div>
    )
}