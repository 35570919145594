import React from 'react'

import NoImage from '../images/NoImage.png'
import NoImageWide from '../images/NoImageWide.png'
import NoProfilePic from '../images/NoProfilePic.png';

class ImageView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            inError: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.setState({
                loaded: false,
                inError: false,
            })
        }
    }

    placeholderImage() {
        if (this.props.profilePlaceholder) {
            return NoProfilePic;
        } else if (this.props.wide) {
            return NoImageWide;
        } else {
            return NoImage;
        }
    }

    onImageLoaded(e) {
        this.setState({ loaded: true });
    }

    render() {
        return (
            <div {...this.props} style={{ ...this.props.style, border: 'none' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    {!this.state.loaded &&
                        <img src={this.placeholderImage()} style={{ ...this.props.style, margin: 0, width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, objectFit: 'cover', objectPosition: 'center', ...this.props.imageViewStyle }} />}

                    {!this.state.inError && this.props.src &&
                        <img src={this.props.src} style={{ ...this.props.style, margin: 0, height: '100%', position: 'absolute', left: 0, top: 0, objectFit: 'cover', objectPosition: 'center', ...this.props.imageViewStyle }}
                            onLoad={this.onImageLoaded.bind(this)} onError={e => {
                                e.target.onerror = null;
                                this.setState({ inError: true });
                            }} />}
                </div>
            </div>
        )
    }

}

export default ImageView;