

export const TEMPLATE_TYPE_GENERIC = 0;
export const TEMPLATE_TYPE_PRODUCT_LABEL = 1;
export const TEMPLATE_TYPE_BUNDLE_LABEL = 2;
export const TEMPLATE_TYPE_LABEL_GENERIC = 3;

export const getTemplate = type => {
    switch (type) {
        case TEMPLATE_TYPE_GENERIC:
            return {
                dynamicTextValues: [],
                supportDynamicText: false,
                supportDynamicBarcode: false,
                supportDynamicQrCode: false,
                supportDynamicImage: false,
                type: TEMPLATE_TYPE_GENERIC,
                name: "Generic Template",
                itemName: "",//Product or Product Bundle
            }
        case TEMPLATE_TYPE_PRODUCT_LABEL:
            return {
                dynamicTextValues: [{ name: "Name", label: "{{Name}}", field: "name" }, { name: "Price", label: "{{Price}}", field: "price" }, { name: "Ref No", label: "{{Ref No}}", field: "sku" }],
                supportDynamicText: true,
                supportDynamicBarcode: true,
                supportDynamicQrCode: true,
                supportDynamicImage: true,
                type: TEMPLATE_TYPE_PRODUCT_LABEL,
                name: "Product Label",
                itemName: "Product",
            }
        case TEMPLATE_TYPE_BUNDLE_LABEL:
            return {
                dynamicTextValues: [{ name: "Name", label: "{{Name}}", field: "name" }, { name: "Price", label: "{{Price}}", field: "price" }, { name: "Ref No", label: "{{Ref No}}", field: "refNo" }],
                supportDynamicText: true,
                supportDynamicBarcode: true,
                supportDynamicQrCode: false,
                supportDynamicImage: true,
                type: TEMPLATE_TYPE_BUNDLE_LABEL,
                name: "Bundle Label",
                itemName: "Product bundle",
            }
        case TEMPLATE_TYPE_LABEL_GENERIC:
            return {
                dynamicTextValues: [{ name: "Name", label: "{{Name}}", field: "name" }, { name: "Price", label: "{{Price}}", field: "price" }],
                supportDynamicText: true,
                supportDynamicBarcode: true,
                supportDynamicQrCode: false,
                supportDynamicImage: true,
                type: TEMPLATE_TYPE_LABEL_GENERIC,
                name: "Label Generic",
                itemName: "Generic label",
            }
    }
}