import {
    UserAvatarFilled32, Edit16, DotMark16
} from '@carbon/icons-react'
import { useState } from 'react'
import useStore from '../../../../hooks/useStore'
import Util from '../../../../util/Util'
import { LeadProfileDialog } from '../dialogs/lead-profile-dialog'

const Field = ({ label, value }) => Util.isStringExists(value) ? (
    <div>
        <h6 style={{ fontSize: 12 }}>{label}</h6>
        <p style={{ fontSize: 14, whiteSpace: 'pre-line' }}>{value}</p>
    </div>
) : null

const FieldList = ({ label, values }) => (values?.length ?? 0) > 0 ? (
    <div>
        <h6 style={{ fontSize: 12 }}>{label}</h6>
        <ul>
            {values.map(value => (
                <li key={value} style={{ fontSize: 14, marginBottom: '0.25rem' }}>{value}</li>
            ))}
        </ul>
    </div>
) : null

const EditBtn = ({ leadId, profile, setProfile }) => {
    const [visible, setVisible] = useState(false);
    return (<>
        <button onClick={() => setVisible(true)} className='lead-flow-icon-button'>
            <Edit16 />
        </button>
        <LeadProfileDialog leadId={leadId} profile={profile} setProfile={setProfile} open={visible} onClose={() => setVisible(false)} />
    </>)
}

export function LeadInfo({ leadId, store }) {
    const [profile, setProfile] = useStore(store, "profile");
    return (
        <div style={{}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <UserAvatarFilled32 style={{ width: 32, height: 32 }} />
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>Lead Profile</h4>
                <EditBtn leadId={leadId} profile={profile} setProfile={setProfile} />
            </div>
            {/* <pre><code>{JSON.stringify(profile, null, 2)}</code></pre> */}
            <div style={{}}>
                <h6 style={{ marginTop: '1rem' }}>Info</h6>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', }}>
                    <Field label='Title' value={profile.title} />
                    <Field label='Full Name' value={profile.fullName} />
                    <Field label='Age' value={profile.age} />
                    <Field label='Address' value={profile.addressText} />
                    <Field label='Nationality' value={profile.nationality} />
                </div>

                <h6 style={{ marginTop: '1rem' }}>Contacts</h6>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', }}>
                    <FieldList label="Phone Number" values={profile.phoneNumbers} />
                    <FieldList label="Email Address" values={profile.emailAddresses} />
                    {/* <FieldList label="Social Media" values={['test@emf.com', 'foinhew@ionwf.com', 'me@google.com']} /> */}
                </div>

                <h6 style={{ marginTop: '1rem' }}>Additional Information</h6>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', }}>
                    {(profile.additionalInformation ?? []).map(item => <Field key={item.recordKey} label={item.recordKey} value={item.recordValue} />)}
                </div>

            </div>
        </div>
    )
}