import { Page, Text, View, Document, StyleSheet, pdf, usePDF, Image } from '@react-pdf/renderer';
import { ToWords } from 'to-words';
import { THERMAL_RECEIPT_LOGO } from '../../app/Config';
import Logo from '../../images/Logo.png'
import SquareLogo from '../../images/SquareLogo.png'
import { hasCapabilitySupport } from '../../app/Capabilities';
import Util from '../../util/Util';
import picardlogo from '../../images/other/picard/picardlogo.png'
import { isPicard } from '../../app/app-util';

const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
        currency: false,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    }
});

const numToWord = num =>
    toWords.convert(num)
        .toLowerCase()
        .replace("dollar", "dirham")
        .replace("cent", "fil");


const GRAY = "#F2F2F2";
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
});

const imageProps = THERMAL_RECEIPT_LOGO.squareLogo ? { style: { width: THERMAL_RECEIPT_LOGO.width, height: THERMAL_RECEIPT_LOGO.height, objectFit: 'contain', objectPosition: 'center', maxHeight: 100 }, src: SquareLogo } : { style: { width: 178, height: 37, objectFit: 'contain', objectPosition: 'center' }, src: Logo }

export function Pdf({ item }) {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    {hasCapabilitySupport("extendedPosCheckout") && <View style={{ flex: 1 }} />}
                    {isPicard() ? <>
                        <Image src={picardlogo} style={{ width: 150, height: 80, objectFit: 'contain', objectPosition: 'top left' }} />
                    </> : <>
                        <Image {...imageProps} />
                    </>}
                    <View style={{ flex: 1 }} />
                    {hasCapabilitySupport("shipping") && <>
                        {isPicard() ? <>
                            <View style={{ marginTop: 0, alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 12, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'right', color: '#00148C' }}>{"Picard Logistics"}</Text>
                                <Text style={{ fontSize: 10, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'right', color: '#00148C' }}>
                                    {"FZS5 BB02, 1209 Street\nJebel Ali Free Zone [Jafza]\nDubai, UAE, P.O. Box: 17728\nInfo@picardlogistics.com\n+971 4 3588698"}
                                </Text>
                            </View>
                        </> : <>
                            <View style={{ marginTop: 0, alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 12, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'right', color: '#00148C' }}>{"EXCELLS SHIPPING LLC"}</Text>
                                <Text style={{ fontSize: 10, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'right', color: '#00148C' }}>{"P.O.BOX: 63067, DUBAI - U.A.E\nTEL: +971 4 2209620, FAX: +971 4 2209610\nEmail: info@excellsshipping.com\nWebsite: www.excellsshipping.com"}</Text>
                            </View>
                        </>}
                    </>}
                </View>
                <Text style={{ width: '100%', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 14, borderBottom: '1px solid black', paddingBottom: 5 }}>Cheque</Text>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 25 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 14 }}>Amount: </Text>
                        <Text style={{ minWidth: 100, padding: 5, border: '2px solid black', textAlign: 'center', lineHeight: 0, fontSize: 14 }}>AED {Util.formatMoney(item.amount)}</Text>
                    </View>

                    <View style={{ flex: 1 }} />

                    <View style={{ flexDirection: 'row', width: 200 }}>
                        <View style={{ paddingRight: 5 }}>
                            <Text style={{ fontSize: 14 }}>Bank: </Text>
                            <Text style={{ fontSize: 14, }}>Cheque No: </Text>
                            <Text style={{ fontSize: 14 }}>Date: </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 14, textAlign: 'right' }}>{Util.isStringExists(item.bankName) ? item.bankName : '-'}</Text>
                            <Text style={{ fontSize: 14, textAlign: 'right' }}>{Util.isStringExists(item.chequeNo) ? item.chequeNo : '-'}</Text>
                            <Text style={{ fontSize: 14, textAlign: 'right' }}>{Util.getDateOnly(item.dueDate)}</Text>
                        </View>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                    <View style={{ paddingRight: 25 }}>
                        <Text style={{ fontSize: 14, marginBottom: 5 }}>{item.type === 0 ? 'Received from:' : 'Paid to:'} </Text>
                        <Text style={{ fontSize: 14 }}>The sum of: </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontSize: 14, marginBottom: 5, textAlign: 'right', borderBottom: '1px dashed black' }}>{item.otherPartyRawName}</Text>
                        {Util.isNumberExist(item.amount) &&
                            <Text style={{ fontSize: 14, textAlign: 'right', borderBottom: '1px dashed black' }}>{numToWord(item.amount)}</Text>}
                    </View>
                </View>

                {Util.isStringExists(item.memo) && <View style={{ marginTop: 15 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>Memo:</Text>
                    <Text style={{}}>{item.memo}</Text>
                </View>}

                <View style={{ flexDirection: 'row', marginTop: 75, marginBottom: 25 }}>
                    <Text style={{ marginRight: 5, fontSize: 12, }}>Prepared by:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{item.createdByName}</Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Signature:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Date:</Text>
                    <Text style={{ fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{Util.getDateOnly(item.dateCreated)}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 25 }}>
                    <Text style={{ marginRight: 5, fontSize: 12, }}>Approved by:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{Util.isStringExists(item.approvedBy) ? item.approvedBy : ""}</Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Signature:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Date:</Text>
                    <Text style={{ fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                </View>
                <View style={{ flexDirection: 'row', }}>
                    <Text style={{ marginRight: 5, fontSize: 12, }}>Received by:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}>{Util.isStringExists(item.receiveBy) ? item.receiveBy : ""}</Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Signature:</Text>
                    <Text style={{ marginRight: 5, fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                    <Text style={{ marginRight: 5, fontSize: 12 }}>Date:</Text>
                    <Text style={{ fontSize: 12, borderBottom: '1px solid black', flex: 1 }}></Text>
                </View>

                <Text style={{ fontFamily: 'Times-Italic', fontSize: 12, marginTop: 50 }}>This document is printed on {Util.getFullDate(new Date().getTime())}</Text>
            </Page>
        </Document>
    )
}