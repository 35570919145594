import Button from "../../../components/Button";
import { ArrowRight16 } from '@carbon/icons-react'
import { useMemo } from "react";
import { getReport } from "../../reports/reports";
import Util from "../../../util/Util";
import { Link } from "react-router-dom";

export function OpenBtn({ reportName, item }) {
    const report = useMemo(() => getReport(reportName), [reportName])
    const openLink = useMemo(() => report && report.itemToOpenLink && item && report.itemToOpenLink(item), [report, item])

    if (!Util.isStringExists(openLink)) {
        return null;
    }
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={openLink} target={report.openInSameTab ? undefined : "_blank"}>
                {/* <Link to={openLink} target={"_blank"}> */}
                <Button style={{ borderRadius: 25 }} renderIcon={ArrowRight16} size="sm">Open Item</Button>
            </Link>
        </div>
    )
}