import { ComboBox, Tag } from "carbon-components-react";
import { useEffect, useState } from "react";
import Portal from "../../../util/Portal";
import { SidePanel } from "../../../templates/draft/components/side-panel";
import { RestTablesView } from "./views/tables/rest-tables-view";
import { ArrowLeft16 } from '@carbon/icons-react'
import UIUtil from "../../../util/UIUtil";
import Api from "../../../session/Api";
import { getTerminal } from "../../../session/SessionManager";


export function SetTable({ state }) {
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [tables, setTables] = useState([]);

    useEffect(() => {
        setLoading(true)
        state.api.getRestaurantComponents(getTerminal().storeId, response => {
            if (response.status === true) {
                setTables(response.payload.components)
                setLoading(false);
            } else {
                UIUtil.showError("Failed to load tables")
            }
        })
    }, [])

    return (
        <div style={{ position: 'relative' }}>
            <ComboBox
                style={{ background: 'black', color: 'white', width: '100%' }}
                disabled={state.isSetTableLoading()}
                placeholder="Table"
                direction='top'
                value={loading ? "Loading..." : (state.getTableId() > 0 ? ("Table " + tables.find($ => $.id === state.getTableId())?.name) : "")}
                items={[]}
            />

            <div onClick={() => {
                if (!state.isSetTableLoading()) {
                    if (state.getTableId() > 0) {
                        state.setTableId(0)
                    } else {
                        setVisible(true)
                    }
                }
            }} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', }} />

            {/* <Portal>
                
            </Portal> */}
        </div>
    )
}