

export const getDefaultTemplate = (templates, primaryTarget, secondaryTarget) => {
    let defaultTemplate = templates[0];

    for (const template of templates) {
        if (template.templateTypeDefault && template.type == primaryTarget) {
            return template;
        } else if (template.templateTypeDefault && template.type == secondaryTarget) {
            defaultTemplate = template;
        }
    }

    return defaultTemplate ? defaultTemplate : {};
}