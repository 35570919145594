import { useMemo } from "react";
import Button from "../../../../../components/Button";
import { CheckmarkFilled16 } from '@carbon/icons-react'
import Util from "../../../../../util/Util";
import { useState } from "react";
import Api from "../../../../../session/Api";
import UIUtil from "../../../../../util/UIUtil";

export function ConfirmBtn({ state, orderId, updateOrder }) {
    const items = state.getCartItems();
    // const [removingCartItemIds, setRemovingCartItemIds] = [state.getState().removingCartItemIds, removingCartItemIds => state.setState({ removingCartItemIds })];
    const [changingCartItemQtyReqs, setChangingCartItemQtyReqs] = [state.getState().changingCartItemQtyReqs, changingCartItemQtyReqs => state.setState({ changingCartItemQtyReqs })];

    const canConfirm = useMemo(() => {
        const prevItemsModified = changingCartItemQtyReqs.length > 0
        const newItemsAdded = items.find($ => !Util.isStringExists($.restKotNo));
        return prevItemsModified || newItemsAdded;
        //return removingCartItemIds.length > 0 || 
    }, [items, changingCartItemQtyReqs])


    const [loading, setLoading] = useState(false);
    const onConfirmBtn = () => {
        setLoading(true);
        Api.confirmSalesOrderModification(orderId, changingCartItemQtyReqs, response => {
            if (response.status === true) {
                const { a: session, b: order } = response.payload;

                setChangingCartItemQtyReqs([]);
                state.onUpdateSession(session);
                updateOrder(order);

                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    return (
        <div style={{ background: 'black', padding: '1rem' }}>
            <Button onClick={onConfirmBtn} loading={loading} disabled={!canConfirm} renderIcon={CheckmarkFilled16} style={{ borderRadius: 5, width: '100%', maxWidth: 'unset' }}>Confirm Changes</Button>
        </div>
    )
}