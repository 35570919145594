import { useParams } from "react-router-dom";
import { OBJECT_TYPE_ACTIVITY, OBJECT_TYPE_ACTIVITY_BLUEPRINT } from "../../../../constants/ObjectTypes";
import { FormControls, FormLayout } from "../../../../templates/form/FormLayout";
import { FormSection, useForm } from "../../../../templates/form/form";
import { ButtonSet, ContentSwitcher, InlineLoading, OverflowMenu, OverflowMenuItem, Switch, TextInput, Tile } from "carbon-components-react";
import { createParamsFields, createParamsFieldsNew, DATA_TYPES, getStatusesDataTypes, makeParamsField } from "../../../../domain/activity/activity-blueprint";
import Util from "../../../../util/Util";
import { useDraggable } from "../../../../hooks/useDraggable";
import { AddFilled32, CheckmarkFilled16, Warning16 } from '@carbon/icons-react'
import { FormEditor } from "../../../../views/custom-form-editor/FormEditor";
import { useEffect, useMemo, useState } from "react";
import MyStateDraftsDialog from "../../../../views/state-drafts/MyStateDraftsDialog";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_STUDENT, ACCOUNT_TYPE_SUPERVISOR, ACCOUNT_TYPE_TUTOR, STATE_TYPE_ACTIVITY, STATE_TYPE_ACTIVITY_BLUEPRINT, STATE_TYPE_STOCK_FLOW_CREATOR } from "../../../../constants/Constants";
import { useDraft } from "../../../../templates/draft/draft";
import { createFormLoader, fixIdForApi, useFormApi } from "../../../../templates/form/form-api";
import { LogicNodeEditorDialog } from "../../../../views/logic-node-editor/LogicNodeEditorDialog";
import { AutomationListEditor } from "../../components/automation-list";
import { NotificationListEditor } from "../../components/notification-list";
import { withLoadablePageWithParams } from "../../../../base/Page";
import Api from "../../../../session/Api";
import ErrorPage from "../../../../components/page-messages/ErrorPage";
import { StudentAssignation, TutorAssignation, UserAssignation } from "../../components/user-assignation";
import DateTimePicker, { StatelessDateTimePicker } from "../../../../components/DateTimePicker";
import useStore from "../../../../hooks/useStore";
import UIUtil from "../../../../util/UIUtil";
import { BlueprintInputFieldsForm } from "../../components/blueprint-input-fields-form";
import { getAccount, getAccountRole } from "../../../../session/SessionManager";
import { AssignedView } from "./assigned-view";
import { hasCapabilitySupport } from "../../../../app/Capabilities";
import { StatusView } from "./status-view";
import { StatusHistory } from "./status-history";
import { CalendarLayout } from "./calendar-layout";
import { CommentView } from "../../../../views/comment/comment-view";
import NoteView from "../../../../views/notes/NoteView";
import { parseApiCalendarEvent } from "../../../calendar/hooks";
import { ActivityPresenceReport } from "../../../lms/activity-ext/student-presence/activity-presence-report";
import { PaymentPackageSelector } from "../../../lms/activity-ext/payment-packages";
import { RecurringView } from "../../components/recurring";
import Button from "../../../../components/Button";
import { RRule, rrulestr } from 'rrule'
import { DeleteCommonEventDialog } from "../../components/delete-common-event-dialog";
import { RecurringStatusView } from "../../components/recurring-status-view";
import { DurationField } from "../../components/calendar-view-duration-field";

const Assignations = ({ value, setValue, tags, allUsers }) => (
    <UserAssignation
        title="User Assignations" value={value} onValueChanged={setValue}
        tags={tags} allUsers={allUsers}
    />
)

const TutorAssignations = ({ value, setValue, allUsers }) => (
    <TutorAssignation
        value={value} onValueChanged={setValue} allUsers={allUsers}
    />
)

const StudentAssignations = ({ value, setValue, allUsers, courseId }) => {
    const [loading, setLoading] = useState(true);
    const [allUsersOverride, setAllUsersOverride] = useState(undefined);

    useEffect(() => {
        let cancelled = false;

        setLoading(true);
        setAllUsersOverride(undefined);
        Api.getActivity(0, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setAllUsersOverride(response.payload.myStudents);
            }
            setLoading(false);
        })

        return () => cancelled = true;
    }, [])

    const myCourseStudents = useMemo(() => ((allUsersOverride ?? allUsers) ?? []).filter(student => (student.enrolledCourseIds ?? []).includes(courseId)),
        [allUsersOverride, allUsers, courseId])

    if (loading) {
        return <InlineLoading />
    }
    return (
        <StudentAssignation
            value={value} onValueChanged={setValue}
            allUsers={myCourseStudents}
            //allUsers={allUsersOverride ?? allUsers}
            isActivity
        />
    );
}

const Scheduling = ({ store }) => {
    const [startDate, setStartDate] = useStore(store, 'startDate');
    const [endDate, setEndDate] = useStore(store, 'endDate');

    const hasScheduling = startDate != -1;

    const noSchedule = () => {
        setStartDate(-1)
        setEndDate(undefined);
    }
    const hasSchedule = () => {
        setStartDate(undefined)
        setEndDate(undefined);
    }

    return (
        <div>
            <ContentSwitcher selectedIndex={hasScheduling ? 0 : 1} onChange={e => e.index === 0 ? hasSchedule() : noSchedule()}>
                <Switch text="Has scheduling" />
                <Switch text="No scheduling" />
            </ContentSwitcher>
            {hasScheduling ? (
                <div style={{ display: 'flex', gap: '0.5rem', marginTop: '1rem' }}>
                    <StatelessDateTimePicker labelText="Start" date={startDate == 0 ? undefined : startDate} setDate={setStartDate} />
                    <StatelessDateTimePicker labelText="End" date={endDate == 0 ? undefined : endDate} setDate={setEndDate} />
                </div>
            ) : (
                <Tile style={{ marginTop: '1rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Warning16 style={{ marginRight: '0.5rem' }} />
                        <h6>Important Note</h6>
                    </div>
                    <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                        No automation rule based on date/time events or notification will be applicable to unscheduled activities
                    </p>
                </Tile>
            )}
        </div>
    )
}


const ActivityDetails = ({ store, config }) => {
    const [formFieldValues, setFormFieldValues] = useStore(store, "formFieldValues");

    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState([]);

    const [blueprintId] = useStore(store, 'blueprintId');
    const hasBlueprint = blueprintId !== undefined && blueprintId !== null && blueprintId > 0;

    useEffect(() => {
        if (blueprintId) {
            let cancelled = false;

            setLoading(true);
            Api.getActivityBlueprintInputFields(blueprintId, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    setInputFields(response.payload)
                    setLoading(false)
                } else {
                    UIUtil.showError("Could not initialize activity details")
                }
            })

            return () => cancelled = true;
        } else {
            setLoading(false);
            setInputFields([])
        }
    }, [blueprintId])

    if (hasBlueprint) {
        if (!loading) {
            return (
                <div>
                    <BlueprintInputFieldsForm config={config} inputFields={inputFields} value={formFieldValues} onValueChanged={setFormFieldValues} />
                </div>
            )
        } else {
            return (
                <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                    <InlineLoading style={{ width: 'unset' }} />
                </div>
            )
        }
    } else {
        return (
            <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>
                    No blueprint selected
                </p>
            </div>
        )
    }
}


const AssignView = ({ store, item, Section, CustomField, TextField, CheckBoxField, tags, allUsers, myStudents, courseTutors }) => {
    const isTutor = getAccountRole() == ACCOUNT_TYPE_TUTOR;
    const isStudent = getAccountRole() == ACCOUNT_TYPE_STUDENT;

    const [courseId, setCourseId] = useStore(store, "courseId");

    useEffect(() => {
        if (!store.get("assignations")) {
            store.set("assignations", [])
        }
        if (!store.get("tutors")) {
            store.set("tutors", [])
        }
        if (!store.get("students")) {
            store.set("students", [])
        }
    }, [courseId])

    if (!Section) {
        return null;
    }

    return Util.isNumberExist(courseId) ? (<>
        {!isStudent && <>
            {!isTutor && <Section title="Tutors">
                <CustomField fieldKey="tutors" childrenProps={{ allUsers: courseTutors.filter(tutor => (tutor.courseIds ?? []).includes(courseId)) }} defValue={item?.tutors ?? []}>
                    {TutorAssignations}
                </CustomField>
            </Section>}
            {/* <Section title="Presence Management"> */}
            <Section title="Class Management">
                {/* <p>select payment package</p> */}
                <CustomField fieldKey="coursePaymentPackageId" childrenProps={{ courseId }} defValue={item?.coursePaymentPackageId ?? -1}>
                    {PaymentPackageSelector}
                </CustomField>
                <CheckBoxField title="Make up class" fieldKey="canUseAsMakeUp" />
                <TextField title="Capacity" fieldKey="capacity" />
            </Section>
            <Section title="Students">
                <CustomField fieldKey="students" childrenProps={{ allUsers: myStudents, courseId: courseId }} defValue={item?.students ?? []}>
                    {StudentAssignations}
                </CustomField>
            </Section>
        </>}
    </>) : (
        <Section title="User Assignations">
            <CustomField fieldKey="assignations" childrenProps={{ tags, allUsers }} defValue={item?.assignations ?? []}>
                {Assignations}
            </CustomField>
        </Section>
    )
}

const Form = ({ isCreating, calendarMode, reloadCurrentCalendarView, selectedEvent, focusLeadId, courseId, autoSelectBlueprintId, myCourses, myStudents, courseTutors, updateActivity, onUpdatePresence, onCalendarEventRemoved, onCalendarEventCreated, onCalendarEventUpdated, calendarTitle, onCalendarCloseBtn, popoverMode, getCalendarDate, setCalendarEndDate, item, objectType, objectId, blueprints, tags, allUsers, myLeads, activitiesAdminAndSupervisorOnlyAssign, activitiesAllowUnassignSelf }) => {
    const { TextField, SwitchField, ComboBoxField, CheckBoxField, CustomField, ExtField, ...form } = useForm(observable => {
        if (autoSelectBlueprintId) {
            observable.set("blueprintId", blueprints.find(item => item.id == autoSelectBlueprintId)?.id)
        } else {
            observable.set("blueprintId", blueprints[0]?.id)
        }
        if (courseId) {
            observable.set("courseId", courseId)
        }
        observable.set("assignations", [])
        observable.set("tutors", [])
        observable.set("students", [])
    }, {
        replaceComboBoxWithSelect: popoverMode,
        light: calendarMode,
        skipFieldEffect: true
    });
    const draft = useDraft(STATE_TYPE_ACTIVITY, data => {
        form.setData(data ? data : undefined)
    }, form.getFormData);
    const formApi = useFormApi(objectType, objectId)

    useEffect(() => {
        if (item) {
            form.setData(prepareApiDataForUse(item))
        }
    }, [item])

    const account = useMemo(getAccount, []);


    //const isAssigned = !isCreating && account.id != item?.userId;
    const isAssigned = !isCreating && account.id != item?.userId && item?.assignations?.find(ass => ass.userId == account.id);

    const showAssign = !isCreating ? true : (() => {
        if (activitiesAdminAndSupervisorOnlyAssign) {
            const role = account.type;
            return role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR;
        } else {
            return true;
        }
    })()

    const [statusActivityOverride, setStatusActivityOverride] = useState(undefined)
    const [key, setKey] = useState(Util.newTempId())

    const role = getAccountRole();
    const isStudent = role == ACCOUNT_TYPE_STUDENT;
    const isTutor = role == ACCOUNT_TYPE_TUTOR;
    const isTutorOrStudent = role == ACCOUNT_TYPE_TUTOR || role == ACCOUNT_TYPE_STUDENT;
    const canEdit = (isCreating || account.id == item?.userId) || (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR);
    //const readonly = !isCreating && !isAssigned && account.id != item?.userId;
    const readonly = false;

    const options = isStudent ? null : (
        isCreating ? draft.draftManagement : <StatusView key={key} item={statusActivityOverride ?? item} readonly={readonly} overrideUpdateSuccess={calendarMode ? record => {
            setKey(Util.newTempId())
            setStatusActivityOverride({
                ...item,
                statusRecordId: record.id,
                statusId: record.statusId,
                statusName: record.statusName,
                statusColor: record.statusColor, latestStatusRecord: record
            })

            updateActivity(activity => ({
                ...activity,
                statusRecordId: record.id,
                statusId: record.statusId,
                statusName: record.statusName,
                statusColor: record.statusColor, latestStatusRecord: record
            }))
        } : undefined} />
    );

    const statusHistoryView = !isCreating ? <>
        <StatusHistory item={item} smallWidth={calendarMode} />
    </> : null;

    const Section = popoverMode ? 'div' : FormSection;

    const showActivityInfo = (
        isCreating ||
        (hasCapabilitySupport("lead") && !focusLeadId) ||
        (hasCapabilitySupport("lms") && !courseId) ||
        !calendarMode
    )





    const content = (
        readonly ? (
            <div style={{ padding: "1rem" }}>
                {/* <p style={{ fontSize: 12, opacity: 0.65 }}>Read Only</p> */}
            </div>
        ) : isAssigned ? (
            <div style={{ padding: (calendarMode && !popoverMode) ? '1rem' : 0 }}>
                <AssignedView isStudent={isStudent} item={item} activitiesAllowUnassignSelf={activitiesAllowUnassignSelf}
                    overrideUnassignSuccess={calendarMode ? onCalendarEventRemoved : undefined}
                    onUpdatePresence={onUpdatePresence} />
            </div>
        ) : (<>
            <div style={{ padding: (calendarMode && !popoverMode) ? '1rem' : 0 }}>

                {showActivityInfo && <Section title="Activity Info">
                    {/* !!!!!!!!!!!!!!!!!!!!
                    Changing any conditions here MUST BE CHANGED UP IN showActivityInfo AS WELL
                    !!!!!!!!!!!!!!!!!!!! */}

                    {isCreating && <ComboBoxField title="Blueprint" fieldKey="blueprintId" options={blueprints.map(blueprint => ({ ...blueprint, title: blueprint.value }))} />}
                    {calendarMode && !Util.isStringExists(item?.commonSharedId) && (
                        <DurationField getCalendarDate={getCalendarDate} setCalendarEndDate={setCalendarEndDate} />
                    )}

                    {hasCapabilitySupport("lead") && !isTutorOrStudent && !focusLeadId && !courseId &&
                        <ComboBoxField title="Lead" fieldKey="leadId" options={myLeads.map(lead => ({ ...lead, title: lead.value }))} />}
                    {/* {hasCapabilitySupport("lms") && !isTutorOrStudent && !focusLeadId && !courseId &&
                        <ComboBoxField title="Course" fieldKey="courseId" options={myCourses.map(course => ({ ...course, title: course.value }))} />} */}
                    {hasCapabilitySupport("lms") && !focusLeadId && !courseId && isCreating &&
                        <ComboBoxField title="Course" fieldKey="courseId" options={myCourses.map(course => ({ ...course, title: course.value }))} />}
                    {!calendarMode && <ExtField title="Scheduling">
                        {Scheduling}
                    </ExtField>}
                </Section>}

                {/* <CustomField fieldKey="rruleToRe"> */}
                {isCreating && calendarMode && <Section title="Recurrence">
                    <CustomField fieldKey="repeatRrule">
                        {RecurringView}
                    </CustomField>
                </Section>}

                {!popoverMode && showAssign &&
                    <ExtField childrenProps={{ item, Section, CustomField, TextField, CheckBoxField, tags, allUsers, myStudents, courseTutors }}>
                        {AssignView}
                    </ExtField>}
                <Section title="Details">
                    <ExtField>
                        {ActivityDetails}
                    </ExtField>
                </Section>
            </div>


            {/* {canEdit &&
                <div className="cash-button">
                    <Button cl>Generate Multiple Events</Button>
                </div>} */}
            {canEdit &&
                <FormControls
                    CustomDeleteDialog={Util.isStringExists(item?.commonSharedId) ? DeleteCommonEventDialog : null}
                    dialogUsePortal={popoverMode}
                    fullWidth={calendarMode}
                    objectType={objectType}
                    onSave={() => {
                        if (calendarMode) {
                            const { start, end, allDay } = getCalendarDate();
                            const req = {
                                ...form.getFormData(),
                                startDate: start,
                                endDate: end,
                                allDay
                            };

                            if (Util.isStringExists(req.rrule)) {
                                const rrule = rrulestr(req.rrule)
                                const newRule = new RRule({ ...rrule.origOptions, dtstart: new Date(start) })

                                req.rrule = newRule.toString();
                                req.duration = 3600000;
                            }

                            let shouldReloadCalendar = false;
                            if (Util.isStringExists(req.repeatRrule)) {
                                const rrule = rrulestr(req.repeatRrule)
                                const newRule = new RRule({ ...rrule.origOptions, dtstart: new Date(start) })
                                const dates = newRule.all((_, l) => l < 100);

                                req.repeatStartDates = dates.map(date => date.getTime());
                                req.repeatDuration = end - start;
                                shouldReloadCalendar = true;
                            }

                            if (focusLeadId) {
                                req.leadId = focusLeadId;
                            }
                            if (courseId) {
                                req.courseId = courseId;
                            }

                            saveCalendarItem(formApi, req, onCalendarEventCreated, onCalendarEventUpdated, { shouldReloadCalendar, reloadCurrentCalendarView });

                            // if (focusLeadId) {
                            //     saveCalendarItem(formApi, {
                            //         ...form.getFormData(),
                            //         startDate: start,
                            //         endDate: end,
                            //         allDay,
                            //         leadId: focusLeadId
                            //     }, onCalendarEventCreated, onCalendarEventUpdated);
                            // } else if (courseId) {
                            //     saveCalendarItem(formApi, {
                            //         ...form.getFormData(),
                            //         startDate: start,
                            //         endDate: end,
                            //         allDay,
                            //         courseId: courseId
                            //     }, onCalendarEventCreated, onCalendarEventUpdated);
                            // } else {
                            //     saveCalendarItem(formApi, {
                            //         ...form.getFormData(),
                            //         startDate: start,
                            //         endDate: end,
                            //         allDay
                            //     }, onCalendarEventCreated, onCalendarEventUpdated);
                            // }
                        } else {
                            saveItem(formApi, form.getFormData());
                        }
                    }}
                    onDeleteBtn={arg => {
                        const deleteAll = typeof arg === "boolean" && arg === true;
                        if (calendarMode) {
                            deleteCalendarItem(formApi, item, deleteAll ? (() => {
                                if (onCalendarEventRemoved) {
                                    onCalendarEventRemoved();
                                }
                                reloadCurrentCalendarView();
                            }) : onCalendarEventRemoved, deleteAll)
                        } else {
                            deleteItem(formApi, item, deleteAll)
                        }
                    }}
                    saving={formApi.saving}
                    deleting={formApi.deleting}
                    isCreating={formApi.isCreating}
                    onSaveDraft={() => draft.setShowSaveDraft(true)}
                    selectedDraft={draft.selectedDraft} />}

            {draft.draftDialogs}
        </>)
    )

    if (popoverMode) {
        return content;
    } else if (calendarMode) {
        return (
            <CalendarLayout
                title={calendarTitle}
                onCloseBtn={onCalendarCloseBtn}
                {...(!isCreating ? {

                    commentView: <CommentView itemType={objectType} itemId={objectId} />,
                    statusHistoryView: statusHistoryView,
                    noteView: (
                        <div style={{ padding: '0.5rem' }}>
                            <NoteView miniMode light objectType={objectType} objectId={objectId} />
                        </div>
                    )
                } : {})}
            >
                {/* <h4>{'ID: ' + item?.id}</h4> */}
                {!isAssigned && calendarMode && <RecurringStatusView key={key} item={item} onFree={() => {
                    reloadCurrentCalendarView();
                    onCalendarCloseBtn();
                    // updateActivity(activity => ({
                    //     ...activity,
                    //     commonSharedId: null
                    // }))
                    // setKey(Util.newTempId())
                }} />}
                {!isStudent && <div style={{ padding: '1rem' }}>
                    {options}
                </div>}
                {isTutor && !isCreating && <ActivityPresenceReport activityId={objectId} item={item} />}
                {content}
            </CalendarLayout>
        )
    } else {
        return (
            <FormLayout hasComments objectType={objectType} objectId={objectId} objectTitle={item?.blueprintName} options={options}
                customTabs={[
                    {
                        title: "Work History",
                        content: statusHistoryView
                    }
                ]}>
                <div style={{ padding: '1rem' }}>
                    {content}
                </div>
            </FormLayout>
        )
    }
}



function prepareApiDataForUse(item) {
    return item;
}





function saveCalendarItem(formApi, formData, onCalendarEventCreated, onCalendarEventUpdated, { shouldReloadCalendar, reloadCurrentCalendarView }) {
    const data = fixIdForApi(formData);

    formApi.save(listener => Api.saveActivity(true, data, listener), {
        overrideCreatingSuccess: event => {
            if (shouldReloadCalendar) {
                onCalendarEventCreated(parseApiCalendarEvent(event))
                reloadCurrentCalendarView();
            } else {
                onCalendarEventCreated(parseApiCalendarEvent(event))
            }
        },
        overrideUpdateSuccess: event => onCalendarEventUpdated(parseApiCalendarEvent(event))
    });
}

function deleteCalendarItem(formApi, item, onRemoved, deleteAll) {
    formApi.deleteItem(listener => Api.deleteActivity(item.id, deleteAll, listener), {
        overrideSuccess: onRemoved
    })
}

function saveItem(formApi, formData) {
    const data = fixIdForApi(formData);

    formApi.save(listener => Api.saveActivity(false, data, listener));
}

function deleteItem(formApi, item, deleteAll) {
    formApi.deleteItem(listener => Api.deleteActivity(item.id, deleteAll, listener))
}

export function ActivityDetailsView
    ({
        itemId, calendarMode, updateActivity, focusLeadId, autoSelectBlueprintId, courseId, onUpdatePresence, onCalendarEventCreated, onCalendarEventUpdated, onCalendarEventRemoved, calendarTitle, onCalendarCloseBtn, popoverMode, getCalendarDate, setCalendarEndDate,
        selectedEvent, reloadCurrentCalendarView,
        payload: {
            blueprints, tags, allUsers, myLeads, myCourses, myStudents, courseTutors, activitiesAdminAndSupervisorOnlyAssign, activitiesAllowUnassignSelf, activity: item
        }
    }) {
    const isCreating = itemId === "new";

    if (!isCreating && !item) {
        return <ErrorPage error404 />
    }

    return (
        <Form isCreating={isCreating} objectType={OBJECT_TYPE_ACTIVITY} objectId={isCreating ? 0 : parseInt(itemId)}
            blueprints={blueprints} item={item} tags={tags} allUsers={allUsers}
            myLeads={myLeads} calendarMode={calendarMode} popoverMode={popoverMode}
            getCalendarDate={getCalendarDate}
            setCalendarEndDate={setCalendarEndDate}
            {...({ myCourses, myStudents, courseTutors })}
            onCalendarCloseBtn={onCalendarCloseBtn}
            calendarTitle={calendarTitle}
            focusLeadId={focusLeadId}
            courseId={courseId}
            selectedEvent={selectedEvent}
            autoSelectBlueprintId={autoSelectBlueprintId}
            updateActivity={updateActivity}
            onUpdatePresence={onUpdatePresence}
            onCalendarEventCreated={onCalendarEventCreated}
            onCalendarEventUpdated={onCalendarEventUpdated}
            onCalendarEventRemoved={onCalendarEventRemoved}
            activitiesAllowUnassignSelf={activitiesAllowUnassignSelf}
            reloadCurrentCalendarView={reloadCurrentCalendarView}
            activitiesAdminAndSupervisorOnlyAssign={activitiesAdminAndSupervisorOnlyAssign} />
    )
}

function Page({ payload }) {
    const { itemId } = useParams();
    return <ActivityDetailsView itemId={itemId} payload={payload} />
}

export const ActivityDetailPage =
    withLoadablePageWithParams(createFormLoader(Api.getActivity), Page);