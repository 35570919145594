import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../constants/Constants";
import { CheckmarkFilled16, ErrorFilled16, Money16, Misuse16, Error16, Search16, Time16, Renew16, DeliveryParcel16, Delivery16, WatsonHealthStackedScrolling_116 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react"

export function getStockRequestStatusName(status) {
    switch (status) {
        case STOCK_REQUEST_STATUS_PENDING_APPROVAL:
            return "Pending Approval";
        case STOCK_REQUEST_STATUS_APPROVED:
            return "Approved";
        case STOCK_REQUEST_STATUS_REJECTED:
            return "Rejected";
        case STOCK_REQUEST_STATUS_REVOKED_BY_SENDER:
            return "Revoked by Sender";
    }
}

export function getStockRequestStatusDetails(status) {
    switch (status) {
        case STOCK_REQUEST_STATUS_PENDING_APPROVAL:
            return { type: "high-contrast", text: "Pending Approval", icon: Time16 };
        case STOCK_REQUEST_STATUS_APPROVED:
            return { type: "green", text: "Approved", icon: CheckmarkFilled16 };
        case STOCK_REQUEST_STATUS_REJECTED:
            return { type: "red", text: "Rejected", icon: Misuse16 };
        case STOCK_REQUEST_STATUS_REVOKED_BY_SENDER:
            return { type: "purple", text: "Revoked by Sender", icon: ErrorFilled16 };
    }
}

export const StockRequestStatusIndicator = ({ status }) => {
    let { icon, text, type } = getStockRequestStatusDetails(status);
    return (
        <div>
            <Tag size="md" renderIcon={icon} type={type}>
                {text}
            </Tag>
        </div>
    )
}