import { Link } from "react-router-dom";
import Button from "../../../../components/Button";
import Util from "../../../../util/Util";
import { ArrowRight16 } from "@carbon/icons-react"
import { useState } from "react";
import PosState from "../../../pos/state/PosState";
import { HeadlessPosStateError, newHeadlessPosState } from "../../../pos/state/headless-pos-state";
import UIUtil from "../../../../util/UIUtil";
import { useHistory } from "react-router-dom";
import { REST_ORDER_TYPE } from "../../../../domain/rest-sales-order";

async function newOrder(history, customerId, phoneNumber) {
    const state = newHeadlessPosState();
    await state.load();
    if (!Util.isNumberExist(customerId)) {
        customerId = await state.newCustomer(phoneNumber);
        if (!customerId) {
            UIUtil.showInfo("No customer created");
            return;
        }
    }
    if (state.hasItems()) {
        if (state.isDineInMode()) {
            await state.createRestCart();
        } else {
            const shouldContinue = await UIUtil.confirmPrompt({ title: "Current cart not empty", message: "Do you want to clear your previous cart?" })
            if (!shouldContinue) {
                return;
            }
            await state.clearCart()
        }

        // UIUtil.showInfo("Holding previous active cart");
        // await state.holdCart();
    }
    await state.setCustomerId(customerId);
    await state.setOrderType("delivery");

    history.push(state.posUrl);
    UIUtil.showSuccess(true)
}

export function NewOrderButton({ customerId, customerName, fullPhoneNumber }) {
    const history = useHistory()
    const hasCustomer = Util.isNumberExist(customerId);
    const hasSearched = Util.isStringExists(fullPhoneNumber);

    const [loading, setLoading] = useState(false);
    const onNewOrderBtn = async () => {
        setLoading(true);
        try {
            await newOrder(history, customerId, fullPhoneNumber);
        } catch (e) {
            if (e instanceof HeadlessPosStateError) {
                UIUtil.showError(e.message);
            } else {
                console.error(e);
                UIUtil.showError();
            }
        } finally {
            setLoading(false);
        }
    }

    if (hasCustomer) {
        return (
            <div style={{
                background: 'white', borderRadius: 7, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', paddingInline: '0.5rem', paddingBlock: '0.5rem',
                display: "flex", alignItems: 'center', gap: '3rem'
            }}>
                <div style={{ paddingLeft: '0.5rem' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Customer</p>
                    <Link to={"/customers/" + customerId}>
                        <p style={{ color: '#0f62fe' }}>{customerName ?? "Unknown"}</p>
                    </Link>
                </div>
                <Button loading={loading} onClick={onNewOrderBtn} className="green-button" style={{ borderRadius: 5 }} renderIcon={ArrowRight16}>New Order</Button>
            </div>
        )
    }

    if (hasSearched) {
        return (
            <div style={{
                background: 'white', borderRadius: 7, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', paddingInline: '0.5rem', paddingBlock: '0.5rem',
                display: "flex", alignItems: 'center', gap: '3rem'
            }}>
                <div style={{ paddingLeft: '0.5rem' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Customer</p>
                    <p>Guest</p>
                </div>
                <Button loading={loading} onClick={onNewOrderBtn} className="green-button" style={{ borderRadius: 5 }} renderIcon={ArrowRight16}>New Customer & Order</Button>
            </div>
        )
    }

    return (
        <div style={{ background: '#00000015', borderRadius: 7, border: '1px solid #00000020', paddingInline: '1rem', paddingBlock: '0.75rem' }}>
            <p style={{ fontSize: 12, opacity: 0.65 }}>No unique customer // number found</p>
        </div>
    )
};