import { Button, ComboBox, DatePicker, DatePickerInput, TextInput } from 'carbon-components-react';
import React, { useEffect } from 'react'
import { useState } from 'react';
import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor';
import { RowDelete32, Currency16, Education16, Enterprise16, TreeView16, Building16, BuildingInsights_316, Store16, CloseFilled16, UserSpeaker16, ArrowRight16, ListDropdown16, AirlinePassengerCare16, Delivery16, User16 } from '@carbon/icons-react';
import { JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT, JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT } from '../../constants/Constants';
import Util, { isV2 } from '../../util/Util';
import CustomComboBox from '../../components/CustomComboBox';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STORE, OBJECT_TYPE_STUDENT, OBJECT_TYPE_TUTOR, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_V2_CUSTOMER, OBJECT_TYPE_V2_SALE, OBJECT_TYPE_V2_VENDOR, OBJECT_TYPE_V2_EMPLOYEE } from '../../constants/ObjectTypes';
import { Link } from 'react-router-dom';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { AmountTagSelector } from './tag-selector';

const EntryRecord = ({ input, nonExistent, onInputUpdate, onInputRemoved, itemIndex, accountTree, endpointsList, readOnly, editOnlyPredefinedClasses, noDate }) => {
    if (editOnlyPredefinedClasses) {
        readOnly = true;
    }

    const [dateValue, setDateValue] = useState(Util.isNumberExist(parseInt(input.date)) ? input.date : new Date().getTime());

    const [accountValue, setAccountValue] = useState(input.account);

    const [amountValue, setAmountValue] = useState(input.amount);
    const [typeValue, setTypeValue] = useState(input.type);

    const [hasSubsidiaryAccount, setHasSubsidiaryAccount] = useState(input.subsidiaryAccount !== undefined && input.subsidiaryAccount !== null);
    const [subsidiaryAccount, setSubsidiaryAccount] = useState(input.subsidiaryAccount);
    const [subsidiaryType, setSubsidiaryType] = useState(input.subsidiaryLedgerType);

    const [narration, setNarration] = useMultiInputState(input, 'narration', onInputUpdate);
    const [amountTags, setAmountTags] = useMultiInputState(input, 'amountTags', onInputUpdate);

    const onSetSubsidiaryAccount = () => {
        setHasSubsidiaryAccount(true)
        setSubsidiaryAccount(null)
        setSubsidiaryType(isV2() ? OBJECT_TYPE_V2_CUSTOMER : OBJECT_TYPE_CUSTOMER)

        onInputUpdate('subsidiaryAccount', null)
        onInputUpdate('subsidiaryLedgerType', isV2() ? OBJECT_TYPE_V2_CUSTOMER : OBJECT_TYPE_CUSTOMER)
    }

    const clearSubsidiaryAccount = () => {
        setHasSubsidiaryAccount(false)
        setSubsidiaryAccount(null)
        setSubsidiaryType(-1)

        onInputUpdate('subsidiaryAccount', null)
        onInputUpdate('subsidiaryLedgerType', -1)
    }

    const onSetSubsidiaryAccountType = type => {
        if (type == subsidiaryType) {
            return;
        }

        setSubsidiaryAccount(null);
        setSubsidiaryType(type);

        onInputUpdate('subsidiaryAccount', null)
        onInputUpdate('subsidiaryLedgerType', type)
    }

    const getSubsidiaryAccountList = () => {
        if (readOnly) {
            return []
        }

        switch (subsidiaryType) {
            case OBJECT_TYPE_CUSTOMER:
                return endpointsList.customers;
            case OBJECT_TYPE_SUPPLIER:
                return endpointsList.suppliers;
            case OBJECT_TYPE_ACCOUNT:
                return endpointsList.staff;
            case OBJECT_TYPE_VENDOR:
                return endpointsList.vendors;
            case OBJECT_TYPE_STORE:
                return endpointsList.stores;
            case OBJECT_TYPE_VENUE:
                return endpointsList.venues;
            case OBJECT_TYPE_STUDENT:
                return endpointsList.students;
            case OBJECT_TYPE_TUTOR:
                return endpointsList.tutors;
            case OBJECT_TYPE_MOVIE_DISTRIBUTOR:
                return endpointsList.movieDistributors;

            case OBJECT_TYPE_V2_CUSTOMER:
                return endpointsList.v2Customers;
            case OBJECT_TYPE_V2_VENDOR:
                return endpointsList.v2Vendors;
            case OBJECT_TYPE_V2_EMPLOYEE:
                return endpointsList.v2Employees;

            default:
                return []
        }
    }

    const getSubsidiaryTypeInfo = () => {
        switch (subsidiaryType) {
            case OBJECT_TYPE_CUSTOMER: case OBJECT_TYPE_V2_CUSTOMER:
                return { icon: AirlinePassengerCare16, title: "Customer" };
            case OBJECT_TYPE_V2_VENDOR:
                return { icon: Delivery16, title: "Vendor" };
            case OBJECT_TYPE_V2_EMPLOYEE:
                return { icon: User16, title: "Employee" }
            case OBJECT_TYPE_SUPPLIER:
                return { icon: Delivery16, title: "Supplier" };
            case OBJECT_TYPE_ACCOUNT:
                return { icon: User16, title: "Staff User" };
            case OBJECT_TYPE_VENDOR:
                return { icon: Enterprise16, title: "Vendor" };
            case OBJECT_TYPE_STORE:
                return { icon: Store16, title: "Store" };
            case OBJECT_TYPE_VENUE:
                return { icon: Building16, title: "Venue" };
            case OBJECT_TYPE_STUDENT:
                return { icon: Education16, title: "Student" }
            case OBJECT_TYPE_TUTOR:
                return { icon: UserSpeaker16, title: "Tutor" }
            case OBJECT_TYPE_MOVIE_DISTRIBUTOR:
                return { icon: BuildingInsights_316, title: "Movie Distributor" }
        }
    }

    const onDateUpdate = (e) => {
        if (readOnly) {
            return;
        }

        let date;
        if (e.length > 0) {
            date = e[0].getTime();
        } else {
            date = new Date().getTime();
        }

        setDateValue(date);
        onInputUpdate('date', date);
    }

    const onAmountUpdate = (amount, type) => {
        if (!isNaN(amount)) {
            let value = amount;
            if (value.startsWith("0")) {
                value = value.substring(1);
            }
            value = value == '' ? "0" : value;

            setTypeValue(type);
            setAmountValue(value)

            onInputUpdate('type', type)
            onInputUpdate('amount', value);
        }
    }

    return (
        <div className="no-input-border" style={{
            display: 'flex', width: '100%', color: '#525252', //borderBottom: '1px solid #e0e0e0', //padding: '1rem .5rem 1.5rem', paddingLeft: '1rem'
            backgroundColor: 'rgb(244, 244, 244)', borderBottom: '1px solid #8d8d8d'
        }}>
            {!noDate && <div style={{ flex: 2, borderBottom: '1px solid #e0e0e0', }} className="small-date-picker">
                {itemIndex == 0 ? <DatePicker tabIndex="-1" style={{ pointerEvents: readOnly ? 'none' : undefined }} value={dateValue} onChange={onDateUpdate} datePickerType={"single"}>
                    <DatePickerInput size="lg" />
                </DatePicker> : <div className="bx--text-input" style={{ height: '100%', width: '100%', background: '#f4f4f4', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {/* <button className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                        <TreeView16 />
                    </button> */}
                </div>}
            </div>}

            <div style={{ flex: 7, }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 4 }}>
                        <div style={{ display: 'flex', height: 48, }}>
                            <CustomComboBox readOnly={readOnly} items={accountTree} selectedItem={accountValue} onSelectedItemUpdate={item => {
                                setAccountValue(item);
                                onInputUpdate('account', item)

                                if (!item) {
                                    clearSubsidiaryAccount();
                                }
                            }} />
                            {!readOnly && !hasSubsidiaryAccount && accountValue && <Button kind="secondary" hasIconOnly renderIcon={ListDropdown16} size="lg"
                                iconDescription="Set Subsidiary Account"
                                style={{ marginLeft: 0, width: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onSetSubsidiaryAccount} />}
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        {readOnly ? (
                            <div style={{
                                height: 48, width: '100%', background: '#f4f4f4', //borderBottom: '1px solid #8d8d8d',
                                display: 'flex', alignItems: 'center'
                            }}>
                                {narration ?? '-'}
                            </div>
                        ) : (
                            <TextInput style={{ pointerEvents: readOnly ? 'none' : undefined, }} size="lg" value={narration}
                                onChange={e => {
                                    if (!readOnly) {
                                        setNarration(e.target.value)
                                    }
                                }} />
                        )}
                    </div>
                    <div style={{ flex: 2 }}>
                        <AmountTagSelector
                            readonly={editOnlyPredefinedClasses ? false : readOnly}
                            options={endpointsList?.predefinedClasses ?? []}
                            //value={(amountTags ?? []).map(tag => ({ label: tag.name }))}
                            value={amountTags ?? []}
                            setValue={value => {
                                console.log(value);
                                const bool = editOnlyPredefinedClasses ? false : readOnly;
                                //if (!readOnly) {
                                if (!bool) {
                                    // setAmountTags(value)
                                    setAmountTags(value.map(val => ({ ...val, name: val.label })))
                                }
                            }} />
                    </div>
                </div>
                {hasSubsidiaryAccount &&
                    <div className="full-width-box-wrapper" style={{ height: 48, display: 'flex' }}>
                        {readOnly ? <>
                            <Button style={{ height: 48, pointerEvents: 'none' }} kind={"secondary"} renderIcon={getSubsidiaryTypeInfo().icon}>{getSubsidiaryTypeInfo().title}</Button>
                            <div className="bx--text-input" style={{ position: 'relative', height: '100%', height: '100%', background: '#f4f4f4', display: 'flex', alignItems: 'center', cursor: !readOnly ? 'pointer' : undefined }}>
                                <p style={{ fontSize: 14, flex: 1 }}>{subsidiaryAccount.value}</p>
                            </div>

                            <Link to={getObjectTypeUrl(subsidiaryType) + "/" + subsidiaryAccount.id}>
                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind="ghost" renderIcon={ArrowRight16} iconDescription="View" />
                            </Link>
                        </> : <>
                            {isV2() ? (<>
                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_V2_CUSTOMER ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_V2_CUSTOMER)} renderIcon={AirlinePassengerCare16} iconDescription="Customer" />
                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_V2_VENDOR ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_V2_VENDOR)} renderIcon={Delivery16} iconDescription="Vendor" />
                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_V2_EMPLOYEE ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_V2_EMPLOYEE)} renderIcon={User16} iconDescription="Employee" />

                            </>) : (<>
                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_CUSTOMER ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_CUSTOMER)} renderIcon={AirlinePassengerCare16} iconDescription="Customer" />
                                {hasCapabilitySupport("lms") && <>
                                    <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_STUDENT ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_STUDENT)} renderIcon={Education16} iconDescription="Student" />
                                    <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_TUTOR ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_TUTOR)} renderIcon={UserSpeaker16} iconDescription="Tutor" />
                                </>}
                                {hasCapabilitySupport("cinema") && <>
                                    <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_MOVIE_DISTRIBUTOR ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_MOVIE_DISTRIBUTOR)} renderIcon={BuildingInsights_316} iconDescription="Movie Distributor" />
                                </>}

                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_SUPPLIER ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_SUPPLIER)} renderIcon={Delivery16} iconDescription="Supplier" />
                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_VENDOR ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_VENDOR)} renderIcon={Enterprise16} iconDescription="Vendor" />
                                <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_ACCOUNT ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_ACCOUNT)} renderIcon={User16} iconDescription="Staff User" />
                                {hasCapabilitySupport("thirdPartyPos") &&
                                    <Button hasIconOnly style={{ width: 48, height: 48 }} kind={subsidiaryType == OBJECT_TYPE_VENUE ? "secondary" : "ghost"} onClick={() => onSetSubsidiaryAccountType(OBJECT_TYPE_VENUE)} renderIcon={Building16} iconDescription="Venue" />
                                }

                            </>)}


                            <ComboBox
                                key={subsidiaryType + "-combo-box"}
                                placeholder="Select account"
                                size="lg"
                                items={getSubsidiaryAccountList()}
                                itemToString={item => item !== null ? item.value : ""}
                                selectedItem={subsidiaryAccount}
                                onChange={e => {
                                    setSubsidiaryAccount(e.selectedItem)
                                    onInputUpdate('subsidiaryAccount', e.selectedItem)
                                }} />
                        </>}



                        {!readOnly && <Button hasIconOnly style={{ width: 48, height: 48 }} kind="danger--ghost" renderIcon={CloseFilled16} iconDescription="Unset Subsidiary Account"
                            onClick={clearSubsidiaryAccount} />}
                    </div>}
            </div>

            <div style={{ flex: 1, }}>


                {readOnly ? (
                    <div style={{
                        height: 48, width: '100%', background: '#f4f4f4', //borderBottom: '1px solid #8d8d8d',
                        display: 'flex', alignItems: 'center'
                    }}>
                        {typeValue == JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT ? (amountValue.toFixed !== undefined ? Util.formatMoney(amountValue) : Util.formatMoney(parseFloat(amountValue))) : ""}
                    </div>
                ) : (
                    <TextInput style={{ pointerEvents: readOnly ? 'none' : undefined, }} size="lg" value={typeValue == JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT ? (amountValue.toFixed !== undefined ? amountValue.toFixed(2) : amountValue) : ""}
                        onChange={e => {
                            if (!readOnly) {
                                onAmountUpdate(e.target.value?.replace?.(",", ""), JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT)
                            }
                        }} />
                )}
            </div>
            <div style={{ flex: 1 }}>
                {readOnly ? (
                    <div style={{
                        height: 48, width: '100%', background: '#f4f4f4', //borderBottom: '1px solid #8d8d8d', 
                        display: 'flex', alignItems: 'center'
                    }}>
                        {typeValue == JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT ? (amountValue.toFixed !== undefined ? Util.formatMoney(amountValue) : Util.formatMoney(parseFloat(amountValue))) : ""}
                    </div>
                ) : (
                    <TextInput style={{ pointerEvents: readOnly ? 'none' : undefined }} size="lg" value={typeValue == JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT ? (amountValue.toFixed !== undefined ? amountValue.toFixed(2) : amountValue) : ""}
                        onChange={e => {
                            if (!readOnly) {
                                onAmountUpdate(e.target.value?.replace?.(",", ""), JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT)
                            }
                        }} />
                )}
            </div>




            {!readOnly && <Button kind="danger" hasIconOnly renderIcon={RowDelete32} size="lg"
                iconDescription="Remove"
                style={{
                    height: 48, marginLeft: 0, width: 48, //alignSelf: 'flex-end'
                }} disabled={nonExistent} onClick={onInputRemoved} />}
        </div>
    )
}

const Topline = ({ account, amount, amountType }) => {
    const amountValue = amount;
    const typeValue = amountType;
    return (
        <div className="no-input-border" style={{
            display: 'flex', width: '100%', color: '#525252', //borderBottom: '1px solid #e0e0e0', //padding: '1rem .5rem 1.5rem', paddingLeft: '1rem'
            backgroundColor: 'rgb(244, 244, 244)', borderBottom: '1px solid #8d8d8d'
        }}>

            <div style={{ flex: 7, }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 4 }}>
                        <div style={{ display: 'flex', height: 48, paddingInline: '1rem', alignItems: 'center' }}>
                            {account?.name}
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div style={{
                            height: 48, width: '100%', background: '#f4f4f4', //borderBottom: '1px solid #8d8d8d',
                            display: 'flex', alignItems: 'center'
                        }}>

                        </div>
                    </div>
                    <div style={{ flex: 2 }}>

                    </div>
                </div>
            </div>

            <div style={{ flex: 1, }}>
                <div style={{
                    height: 48, width: '100%', background: '#f4f4f4', //borderBottom: '1px solid #8d8d8d',
                    display: 'flex', alignItems: 'center', padding: '1rem'
                }}>
                    {typeValue == JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT ? (amountValue.toFixed !== undefined ? Util.formatMoney(amountValue) : Util.formatMoney(parseFloat(amountValue))) : ""}
                </div>
            </div>
            <div style={{ flex: 1 }}>
                <div style={{
                    height: 48, width: '100%', background: '#f4f4f4', //borderBottom: '1px solid #8d8d8d', 
                    display: 'flex', alignItems: 'center', padding: '1rem'
                }}>
                    {typeValue == JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT ? (amountValue.toFixed !== undefined ? Util.formatMoney(amountValue) : Util.formatMoney(parseFloat(amountValue))) : ""}
                </div>
            </div>




            <div style={{ height: 48, width: 48 }} />
        </div>
    )
}

export default ({ defaultEntries, onEntriesUpdate, accountTree, endpointsList, editOnlyPredefinedClasses, totalDebit, totalCredit, readOnly, topline, noDate }) => (
    <div>
        <div className="bx--structured-list-th" style={{ display: 'flex', paddingLeft: '1rem', marginBottom: '0px', borderBottom: '1px solid #e0e0e0', background: 'black', color: 'white' }}>
            {!noDate && <div style={{ flex: 2 }}>
                Date
            </div>}
            <div style={{ flex: 4 }}>
                Account
            </div>
            <div style={{ flex: 1 }}>
                Narration
            </div>
            <div style={{ flex: 2 }}>
                Class
            </div>
            <div style={{ flex: 1 }}>
                Debit
            </div>
            <div style={{ flex: 1 }}>
                Credit
            </div>
            {!(editOnlyPredefinedClasses ? true : readOnly) && <div style={{ minWidth: 48 }} />}
        </div>
        {topline && <Topline noDate={noDate} readOnly={readOnly} editOnlyPredefinedClasses={editOnlyPredefinedClasses} freezeLock={editOnlyPredefinedClasses} accountTree={accountTree} endpointsList={endpointsList} skipMargin defaultInputs={defaultEntries} onInputsUpdated={onEntriesUpdate} hideDrag ItemComponent={EntryRecord} {...topline} />}
        <MultipleInputEditor noDate={noDate} readOnly={readOnly} editOnlyPredefinedClasses={editOnlyPredefinedClasses} freezeLock={editOnlyPredefinedClasses} accountTree={accountTree} endpointsList={endpointsList} skipMargin defaultInputs={defaultEntries} onInputsUpdated={onEntriesUpdate} hideDrag ItemComponent={EntryRecord} />
        <div style={{ display: 'flex', paddingLeft: '1rem', paddingTop: '1rem', paddingBottom: '1rem', marginBottom: '0px', borderBottom: '1px solid #e0e0e0', background: 'rgb(248, 248, 248)' }}>
            {!noDate && <div style={{ flex: 2 }}>

            </div>}
            <div style={{ flex: 4 }}>

            </div>
            <div style={{ flex: 1 }}>

            </div>
            <div style={{ flex: 2 }}>

            </div>
            <div style={{ flex: 1 }}>
                {Util.formatMoney(totalDebit)}
            </div>
            <div style={{ flex: 1 }}>
                {Util.formatMoney(totalCredit)}
            </div>
            {!(editOnlyPredefinedClasses ? true : readOnly) && <div style={{ minWidth: 48 }} />}
        </div>
    </div>
)