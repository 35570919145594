import { Button, ComboBox, TextInput, TextInputSkeleton } from 'carbon-components-react';
import React, { useEffect, useRef, useState } from 'react'
import Api from '../../session/Api';
import Util from '../../util/Util';

import { Close16 } from '@carbon/icons-react'

export default ({ labelText, helperText, productId, quantity, uomId, loadPackageUnits = false, displayQuantity, displayQuantityConversionFactor, onUpdate }) => {
    const valueCacheRef = useRef(null)
    const [loading, setLoading] = useState(false);

    const [measurementType, setMeasurementType] = useState()
    const [selectedUOM, setSelectedUOM] = useState()

    const getDefaultUOM = () => ({ symbol: measurementType.defaultUomSymbol, name: measurementType.defaultUomName })
    const hasDisplayQuantity = Util.isStringExists(displayQuantity)

    useEffect(() => {
        onUpdate({ quantity, uomId: selectedUOM ? selectedUOM.id : 0 })
    }, [selectedUOM])

    useEffect(() => {
        if (loading) {
            valueCacheRef.current = { quantity, uomId };
            onUpdate({ quantity: 0, uom: 0 })
        } else if (valueCacheRef.current !== null) {
            onUpdate(valueCacheRef.current)
        }
    }, [loading])

    useEffect(() => {
        setMeasurementType(undefined)
        setSelectedUOM(undefined)
        setLoading(true)
        Api.getProductMeasurementType(productId, loadPackageUnits, response => {
            if (response.status === true && response.payload) {
                setMeasurementType(response.payload)
            }
            setLoading(false)
        })
    }, [productId])

    if (loading) {
        return (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
                <TextInputSkeleton style={{ flex: 2 }} />

                <TextInputSkeleton style={{ width: 250 }} />
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <TextInput
                style={{ flex: 2 }}
                disabled={loading}
                light
                readOnly={hasDisplayQuantity}
                labelText={labelText}
                helperText={helperText}
                value={quantity}
                onChange={e => onUpdate({ quantity: e.target.value, uomId })}
            />

            {hasDisplayQuantity && <div style={{ flex: 1, display: 'flex', gap: '0.15rem' }}>

                <TextInput
                    style={{ flex: 2 }}
                    disabled={loading}
                    light
                    labelText={"UOM"}
                    helperText={"Previously set UOM and its conversion factor"}
                    value={displayQuantity + " (" + displayQuantityConversionFactor + ")"}
                    readOnly
                />

                <Button
                    onClick={() => onUpdate({ quantity, uomId: 0, clearDisplay: true })}
                    renderIcon={Close16} hasIconOnly iconDescription="Clear UOM" kind="danger" size="field" style={{ height: 40, marginTop: '1.5rem' }} />

            </div>}

            {measurementType && !hasDisplayQuantity &&
                <div style={{ flex: 1, marginTop: -3 }}>
                    <ComboBox
                        titleText="UOM"
                        light
                        value={!selectedUOM ? getDefaultUOM().symbol : selectedUOM.symbol}
                        helperText={<> Quantity will be converted once only with the <strong>current</strong> conversion factor </>}
                        onChange={item => setSelectedUOM(item.selectedItem)}
                        itemToString={item => item ? item.symbol : getDefaultUOM().symbol}
                        items={measurementType.unitOfMeasurements}
                    />
                </div>}
        </div>
    )
}