import { Link as RouterLink, Route } from "react-router-dom";
import WelcomePage from "../../pages/message-pages/WelcomePage";

import {
    AirlinePassengerCare16, List16, PassengerPlus16, Delivery16, ArrowRight16, Finance16, ServiceDesk16, Code16, Enterprise16, Archive16,
    Receipt16, AddAlt16, ShoppingBag16, Certificate16, ShoppingCartArrowUp16, Report16, Migrate16, ChartBullet16,
    FlowConnection16, GraphicalDataFlow16, Currency16, Notebook16, Money16, Table16, ArrowDown16, ArrowUp16, RequestQuote16,
    Api16, License16, DataShare16, PenFountain16, DataClass16, InventoryManagement16, Wallet16, FlowStream16, SubtractAlt16,
    MigrateAlt16, SettingsAdjust16, UserData16, Product16, Box16, Application16, Categories16, RulerAlt16, Types16,
    PhraseSentiment16, Network_116, Category16, CategoryNewEach16, Classification16, CutOut16, User16, Van16,
    WatsonHealthTextAnnotationToggle16, DocumentImport16, App16, Settings16, Gradient16, Calculation16, TrashCan16, Cube16,
    ManageProtection16,
    Home16,
    AirlineManageGates16,
    LicenseDraft16,
    MediaLibrary16,
    Blog16,
    ViewFilled16,
    Locked16,
    Bullhorn16,
    InsertPage16,
    Purchase16,
    GroupPresentation16,
    VideoFilled16,
    ListBoxes16,
    Education16,
} from '@carbon/icons-react'
import { getReportLink, listDetailNew, routeReport } from "../../reporting-engine/base/report-page";
import { OnlineStoreAdminView } from "../../pages/online-store/admin-view";
import DeliverablesPage from "../../pages/sales-order/DeliverablesPage";
import DeliverableItemPage from "../../pages/sales-order/DeliverableItemPage";
import CRVPage from "../../pages/crv/CRVPage";
import CRVCreatorPage from "../../pages/crv/CRVCreatorPage";
import CRVItemPage from "../../pages/crv/CRVItemPage";
import StockFlowCreatorPage from "../../pages/stock-flow/StockFlowCreatorPage";
import StockFlowItemPage from "../../pages/stock-flow/StockFlowItemPage";

const Link = ({ ...props }) => <RouterLink {...props} style={{ textDecoration: 'none' }} />

const DropdownBtn = ({ to, Icon, children }) => {
    if (to) {
        return (
            <Link to={to}>
                <button style={{}} className="menu-dropdown-button"><Icon /> <span style={{ flex: 1, textAlign: 'start' }}>{children}</span> <ArrowRight16 className="right-arrow" /></button>
            </Link>
        )
    } else {
        return (
            <button style={{ opacity: true ? 0.25 : 1 }} className="menu-dropdown-button"><Icon /> <span style={{ flex: 1, textAlign: 'start' }}>{children}</span> <ArrowRight16 className="right-arrow" /></button>
        )
    }
}

const crudRoute = (path, list, detail) => ([
    <Route exact path={`/${path}`} component={list} />,
    <Route exact path={`/${path}/:itemId`} component={detail} />
])

export const DELIVERY_MANAGER = {
    router: [
        <Route exact path="/" component={WelcomePage} />,

        <Route exact path="/website-admin-view" component={OnlineStoreAdminView} />,
        <Route exact path="/deliverables" component={DeliverablesPage} />,
        <Route exact path="/deliverables/:itemId" component={DeliverableItemPage} />,
        <Route exact path="/cash-receipt-vouchers" component={CRVPage} />,
        <Route exact path="/crv-creator" component={CRVCreatorPage} />,
        <Route exact path="/crv/:itemId" component={CRVItemPage} />,
        routeReport("CRVSummary"),
        routeReport("CRVDetail"),
        routeReport("CRVAccountsBalance"),

        routeReport("ConsumptionByService"),
        routeReport("SalesConsumptionDetail"),

        routeReport("StockQtyByLocReport"),
        routeReport("StockValuationReport"),
        routeReport("StockValuationByLocReport"),

        routeReport("StockByLocation"),
        routeReport("InvValuationSummary"),
        routeReport("InvValuationDetail"),
        routeReport("StockStatusByItem"),
        routeReport("ConsumptionReport"),
        routeReport("PurchaseOrderList"),
        routeReport("CostingAudit"),
        routeReport("V2StockReport"),
        routeReport("PhysicalInventoryWorksheet"),

        routeReport("StockAdjustmentList"),
        routeReport("StockTransferList"),
        routeReport("StockWasteList"),

        routeReport("StockReport"),
        routeReport("ProductStockReport"),
        <Route exact path="/stock-flow/:stockFlowId" component={StockFlowItemPage} />,
        <Route exact path="/new-stock-transfer" key="new-stock-transfer" render={(props) => <StockFlowCreatorPage {...props} transferOnly />} />,
        <Route exact path="/new-stock-creation" key="new-stock-creation" render={(props) => <StockFlowCreatorPage {...props} creationOnly />} />,
        <Route exact path="/new-stock-termination" key="new-stock-termination" render={(props) => <StockFlowCreatorPage {...props} terminationOnly />} />,
    ],
    menu: [
        {
            id: "website",
            button: <button className="menu-button"><Code16 /> Website</button>,
            to: '/website-admin-view'
        },
        {
            id: 'delivery-orders',
            button: <button className="menu-button"><ShoppingBag16 /> Delivery Orders</button>,
            to: '/deliverables'
        },
        {
            id: 'delivery-orders',
            button: <button className="menu-button"><FlowConnection16 /> POs</button>,
            to: getReportLink("PurchaseOrderList")
        },
        {
            id: "finances",
            button: <button className="menu-button"><Finance16 /> Finances</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <DropdownBtn to="/cash-receipt-vouchers" Icon={Wallet16}>Cash Receipt Vouchers</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <DropdownBtn to={getReportLink("CRVSummary")} Icon={Report16}>CRV Summary</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <DropdownBtn to={getReportLink("CRVDetail")} Icon={Report16}>CRV Detail</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <DropdownBtn to={getReportLink("CRVAccountsBalance")} Icon={Report16}>Accounts Balance</DropdownBtn>
                    </div>
                </div>
            )
        },
        {
            id: "inventory",
            button: <button className="menu-button"><InventoryManagement16 /> Inventory</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Quick Actions</h6>
                        <DropdownBtn to={listDetailNew("StockTransferList")} Icon={MigrateAlt16}>Transfer Stock</DropdownBtn>
                        <DropdownBtn to={listDetailNew("StockAdjustmentList")} Icon={Calculation16}>Adjust Stock</DropdownBtn>
                        <DropdownBtn //to={listDetailNew("StockWasteList")} 
                            Icon={SubtractAlt16}>Waste Stock</DropdownBtn>


                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Inventory Management</h6>
                        <DropdownBtn to={getReportLink("StockTransferList")} Icon={FlowStream16}>Stock Transfers</DropdownBtn>
                        <DropdownBtn to={getReportLink("StockAdjustmentList")} Icon={Calculation16}>Stock Adjustments</DropdownBtn>
                        <DropdownBtn //to={getReportLink("StockWasteList")} 
                            Icon={TrashCan16}>Stock Waste</DropdownBtn>
                        <br />
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Reporting</h6>
                        <DropdownBtn to={getReportLink("V2StockReport")} Icon={Report16}>Stock Report</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("StockByLocation")} Icon={Report16}>Stock by Location</DropdownBtn>
                        <DropdownBtn to={getReportLink("InvValuationSummary")} Icon={Report16}>Valuation Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("InvValuationDetail")} Icon={Report16}>Valuation Detail</DropdownBtn>

                        {/* <DropdownBtn to={getReportLink("StockQtyByLocReport")} Icon={Report16}>Stock by Location</DropdownBtn>
                        <DropdownBtn to={getReportLink("StockValuationReport")} Icon={Report16}>Valuation Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("StockValuationByLocReport")} Icon={Report16}>Valuation Detail</DropdownBtn> */}
                        <br />

                        <DropdownBtn to={getReportLink("CostingAudit")} Icon={SettingsAdjust16}>Costing Audit</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', opacity: 0 }}>Reporting</h6>
                        <DropdownBtn to={getReportLink("StockStatusByItem")} Icon={Report16}>Stock Status by Item</DropdownBtn>
                        <DropdownBtn to={getReportLink("PhysicalInventoryWorksheet")} Icon={Report16}>Physical Inventory Worksheet</DropdownBtn>

                        <br />
                        <DropdownBtn to={getReportLink("ConsumptionReport")} Icon={Report16}>Consumption</DropdownBtn>
                        <DropdownBtn to={getReportLink("ConsumptionByService")} Icon={Report16}>Consumption by Service</DropdownBtn>
                        {/* <DropdownBtn to={getReportLink("ConsumptionByLocation")} Icon={Report16}>Consumption by Location</DropdownBtn> */}


                        <br />
                        <DropdownBtn to={getReportLink("SalesConsumptionDetail")} Icon={Report16}>Sales Consumption Detail</DropdownBtn>


                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Custom Reports</h6>

                        <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>
                    </div>
                </div>
            )
        },
    ]
}