import { FacePendingFilled20, FaceNeutralFilled20, FaceDissatisfiedFilled20, FaceSatisfiedFilled20, GraphicalDataFlow24, Edit16 } from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'
import React, { useState } from 'react';
import ProfilePic from '../../../../components/ProfilePic';
import { ProfilePicGroup, ProfilePicGroupWithName } from '../../../../components/ProfilePicGroup'
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_TUTOR } from '../../../../constants/ObjectTypes';
import { LEAD_FEELING_HAPPY, LEAD_FEELING_NEUTRAL, LEAD_FEELING_PENDING, LEAD_FEELING_SAD } from '../../../../domain/lead';
import useStore from '../../../../hooks/useStore';
import Api from '../../../../session/Api';
import Util from '../../../../util/Util';
import { FlowInfoDialog } from '../dialogs/flow-info-dialog';
import { hasCapabilitySupport } from '../../../../app/Capabilities';


const Field = ({ label, value }) => (
    <div>
        <h6 style={{ fontSize: 12 }}>{label}</h6>
        <p style={{ fontSize: 14 }}>{value}</p>
    </div>
)

const EditBtn = ({ leadId, info, setInfo, data }) => {
    const [visible, setVisible] = useState(false);
    return (<>
        <button onClick={() => setVisible(true)} className='lead-flow-icon-button'>
            <Edit16 />
        </button>
        <FlowInfoDialog leadId={leadId} info={info} setInfo={setInfo} payload={data} open={visible} onClose={() => setVisible(false)} />
    </>)
}

const FeelingIndicator = ({ info }) => {
    const { icon, title, background, color } = (() => {
        switch (info.feeling) {
            case LEAD_FEELING_NEUTRAL:
                return { icon: FaceNeutralFilled20, title: "Feeling Neutral", background: 'rgba(0,0,0,.05)', color: 'black' }
            case LEAD_FEELING_SAD:
                return { icon: FaceDissatisfiedFilled20, title: "Feeling Sad", background: '#990000', color: 'white' }
            case LEAD_FEELING_HAPPY:
                return { icon: FaceSatisfiedFilled20, title: "Feeling Happy", background: 'green', color: 'white' }
            case LEAD_FEELING_PENDING: default:
                return { icon: FacePendingFilled20, title: "No Feeling", background: 'rgba(0,0,0,.05)', color: 'black' }

        }
    })()
    return (
        <div style={{ gridColumn: 'span 2 / span 2', background, color, height: '100%', paddingInline: '1rem', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
            {React.createElement(icon)}
            <p style={{ fontWeight: 'bold', fontSize: 12 }}>{title}</p>
        </div>
    )
}

export const LeadTag = ({ color, name, }) => {
    //REMEMBER its being used in the lead list for its source

    const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

    return (
        <Tag
            style={{
                color: isLightColor(color ?? "#000000") ? "black" : "white",
                background: color ?? "#000000",
            }}>{name}</Tag>
    )
}

export function LeadTagView({ lead }) {
    return (
        (lead.tagAssignations?.length ?? 0) > 0 ? (
            lead.tagAssignations
                .map(tag => (
                    <LeadTag key={tag.id} color={tag.color} name={tag.name} />
                ))
        ) : (
            <p style={{ fontSize: 12, opacity: 0.65 }}>No tags</p>
        )
    )
}

export function FlowInfo({ leadId, lead, store, data }) {
    const [info, setInfo] = useStore(store, "flowInfo");
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <GraphicalDataFlow24 />
                <h6 style={{ fontSize: 16, margin: 0, flex: 1 }}>Flow Info</h6>
                <EditBtn data={data} info={info} leadId={leadId} setInfo={setInfo} />
            </div>
            {hasCapabilitySupport("cargo") && <div style={{ marginTop: '0.5rem' }}>
                <Field label="CRN" value={info.crnId ? Util.getVoucherNumber(info.crnId) : '-'} />
            </div>}
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', marginTop: '0.5rem' }}>
                <Field label="Source" value={info.sourceName} />
                <FeelingIndicator info={info} />
            </div>
            {(info.assignations?.length ?? 0) > 0 &&
                <div style={{ marginTop: '1rem' }}>
                    <h6 style={{ fontSize: 12, marginBottom: 4 }}>Assignations</h6>

                    {lead.assignedToYou &&
                        <div style={{ display: 'inline-block' }}>
                            <div style={{ borderRadius: 10, background: '#0f62fe', marginBottom: '0.5rem', paddingTop: '0.15rem', paddingBottom: '0.15rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.5rem' }}>
                                <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, lead.createdByUserId)}
                                //style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }} 
                                />
                                <p style={{ flex: 1, fontSize: 12, fontWeight: 'bold', color: 'white' }}>Assigned to you by <span style={{ fontWeight: 'lighter' }}>{lead.creatorFullName}</span></p>
                                {/* {activitiesAllowUnassignSelf &&
                                    <Button onClick={onUnassignMe} loading={loading} size="sm" kind="secondary" renderIcon={Cut16} style={{ borderRadius: 10 }}>
                                        Unassign Me
                                    </Button>} */}
                            </div>
                        </div>}

                    {/* <ProfilePicGroupWithName reverse size={32} srcs={info.assignations.map(assignation => Api.getThumbnail(OBJECT_TYPE_ACCOUNT, assignation.userId))} /> */}
                    <ProfilePicGroupWithName reverse size={32} srcs={info.assignations.map(assignation => ({
                        url: Api.getThumbnail(Util.isNumberExist(assignation.tutorId) ? OBJECT_TYPE_TUTOR : OBJECT_TYPE_ACCOUNT, Util.isNumberExist(assignation.tutorId) ? assignation.tutorId : assignation.userId),
                        name: assignation.name
                    }))} />
                </div>}
            {(info.tagAssignations?.length ?? 0) > 0 &&
                <div style={{ marginTop: '1rem' }}>
                    <h6 style={{ fontSize: 12 }}>Tags</h6>
                    {info.tagAssignations.map(tag => (
                        <LeadTag key={tag.id} color={tag.color} name={tag.name} />
                    ))}
                </div>}
        </div>
    )
}