import { useState } from "react";
import { ArrowRight16 } from '@carbon/icons-react'
import Button from "../../../../components/Button";
import { CustomizeFoodDialog } from "../dialog/customize-food-dialog";
import UIUtil from "../../../../util/UIUtil";


export function CartItemEdit({ cartItem, onUpdateBtn }) {
    const [showRecipe, setShowRecipe] = useState(false);
    const [values, setValues] = useState(cartItem.recipeOptions);
    const [loading, setLoading] = useState(false);

    const onCustomizeBtn = () => {
        setValues(cartItem.recipeOptions);
        setShowRecipe(true);
    };

    // const onUpdateBtn = usePromise(async () => {
    //     await posSession.updateRecipeOptions(cartItem.id, values);
    //     setShowRecipe(false);
    // });

    if (!cartItem.hasCustomizationSupport) {
        return <div />;
    }

    return (
        <>
            <Button renderIcon={ArrowRight16} onClick={() => onCustomizeBtn()} style={{ borderRadius: 7 }}>Customize</Button>
            <div style={{ flex: 1 }} />

            {showRecipe &&
                <CustomizeFoodDialog
                    loadingDone={loading}
                    onDone={(_, recipeOptions) => {
                        setLoading(true);
                        onUpdateBtn(recipeOptions, (success) => {
                            if (success) {
                                UIUtil.showSuccess();
                                setShowRecipe(false);
                            }
                            setLoading(false);
                        })
                    }}
                    numberItemId={cartItem.itemId} defaultValues={values} open={showRecipe} onClose={() => setShowRecipe(false)} />}

            {/* <Dialog
                title={"Customize"}
                visible={showRecipe}
                onClose={() => setShowRecipe(false)}
                buttons={
                    <>
                        <Dialog.PrimaryButton
                            loading={onUpdateBtn.loading}
                            onClick={onUpdateBtn}
                        >
                            Update
                        </Dialog.PrimaryButton>
                        <Dialog.SecondaryButton
                            onClick={() => setShowRecipe(false)}
                        >
                            Cancel
                        </Dialog.SecondaryButton>
                    </>
                }
            >
                {showRecipe && (
                    <RecipeEditor
                        foodId={cartItem.itemId}
                        values={values}
                        setValues={setValues}
                    />
                )}
            </Dialog> */}
        </>
    );
}
