import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT_ATTRIBUTES, OBJECT_TYPE_PRODUCT_BRAND, OBJECT_TYPE_PRODUCT_CATEGORY, OBJECT_TYPE_PRODUCT_DEPARTMENT } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';

class ProductAttributesListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Attributes",
                    subTitle: "All product attributes",
                    objectType: OBJECT_TYPE_PRODUCT_ATTRIBUTES,
                    pagePath: "/product-attribute-packs/",
                    history: this.props.history
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(ProductAttributesListPage);