import { useRef, useState } from "react"
import {
    DocumentBlank16, ArrowRight16, Box16, Edit16, Close16, CloseFilled16, Reset16, Download16
} from '@carbon/icons-react'
import { ButtonSet, ComposedModal, ContentSwitcher, InlineLoading, ModalBody, ModalFooter, ModalHeader, Switch, Tag, Toggle } from "carbon-components-react";
import { Icon } from "./components/icon";
import { ADVANCED_FILTER_TYPE } from ".";
import { typeName } from "./components/type-name";
import Button from "../../../components/Button";
import Util from "../../../util/Util";
import Api from "../../../session/Api";
import { FilterEditor } from "./components/filter-editor";

const DEF_VALUE = {
    column: null,
    operator: null,
    value: null
}

export function TreeItem({ readonly, item, title, type, isSection, itemCount, expanded, setExpanded, SortHandler, updateItem, removeItem, columns }) {
    const filter = item.filterValue ?? DEF_VALUE
    const setFilter = filter => updateItem({ ...item, filterValue: filter })

    const onRemoveBtn = () => {
        removeItem(item)
    }

    return (
        <div className="no-padding-toggle-control advanced-filter-items-tree-root" style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', }}>
            {SortHandler && !readonly && !expanded && <SortHandler />}
            {/* {SortHandler && expanded && <div style={{ width: '1.5rem' }} />} */}
            <div onClick={() => type == ADVANCED_FILTER_TYPE.LOGIC && setExpanded?.(!expanded)} className="advanced-filter-items-tree" style={{ overflow: 'visible', width: '100%', height: '3rem', borderRadius: 5, background: '#F3F3F3', border: '1px solid #00000020', gap: '1rem', display: 'flex', alignItems: 'center', paddingRight: '1rem', }}>
                <div className="advanced-filter-items-thumb-icon-tree" style={{ width: '3rem', height: '3rem', minWidth: '3rem', padding: expanded ? 0 : '0.25rem', transition: '250ms', background: 'none', border: 'none', borderRadius: 5, overflow: 'hidden' }}>
                    <div style={{ height: '100%', width: '100%', background: expanded ? '#1c1c1c' : 'white', color: expanded ? 'white' : 'black', transition: 'background 250ms', borderRadius: expanded ? 0 : 5, borderRight: expanded ? '1px solid #00000020' : undefined, border: expanded ? undefined : '1px solid #00000020', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Icon treeMode type={type} style={{ transition: '250ms', transform: expanded ? 'rotate(90deg)' : undefined }} />
                    </div>
                </div>
                {item.type == ADVANCED_FILTER_TYPE.LOGIC && (<>
                    <div style={{ minWidth: 0 }}>
                        {/* {item.type == ADVANCED_FILTER_TYPE.LOGIC &&
                        <p style={{ userSelect: 'none', fontWeight: 'bold', fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {item.operator}
                        </p>} */}
                        {item.type == ADVANCED_FILTER_TYPE.LOGIC && (<>
                            <ContentSwitcher onClick={e => e.stopPropagation()} selectedIndex={item.operator == "OR" ? 1 : 0} size="sm" onChange={e => updateItem({ ...item, operator: e.name })} style={{ width: 150 }}>
                                <Switch name="AND" text="AND" />
                                <Switch name="OR" text="OR" />
                            </ContentSwitcher>
                        </>)}
                    </div>
                    {item.type == ADVANCED_FILTER_TYPE.LOGIC && (<>
                        <Toggle
                            checked={item.negate}
                            size="sm"
                            labelA="Negate Node?"
                            labelB="Negated Node"
                            onToggle={() => updateItem({ ...item, negate: !item.negate })}
                        />
                    </>)}
                </>)}
                {item.type == ADVANCED_FILTER_TYPE.FILTER ? (
                    <FilterEditor columns={columns} filter={filter} setFilter={setFilter} />
                ) : (
                    <div style={{ flex: 1 }} />
                )}

                {/* <div style={{ flex: 1 }} /> */}


                {isSection ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
                        <Tag style={{ userSelect: 'none', }} type={expanded ? undefined : "blue"} size="sm">{itemCount} item{itemCount != 1 ? 's' : ''}</Tag>
                    </div>
                ) : (
                    null
                )}

                {!readonly && <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <button onClick={e => {
                        e.stopPropagation();
                        onRemoveBtn();
                    }} className='lead-flow-icon-button lead-flow-icon-button-secondary'>
                        <CloseFilled16 />
                    </button>
                </div>}
            </div>
        </div>
    )
}