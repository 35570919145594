
import {
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
    Checkmark16,

    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Number_832,
    Number_932,
    Checkmark32,

} from '@carbon/icons-react'

const ICONS = [
    Number_116,
    Number_216,
    Number_316,
    Number_416,
    Number_516,
    Number_616,
    Number_716,
    Number_816,
    Number_916,
]

const ICONS_LX = [
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Number_832,
    Number_932,
]

const flow = [
    {
        id: "movie",
        title: "Movie",
    },
    {
        id: "showtime",
        title: "Showtime",
    },
    {
        id: "seat",
        title: "Seats",
    }
]

export const TICKET_SELECTOR_FLOW = flow;

export const getStatusIndexById = (statusId) => {
    return flow.findIndex(item => item.id === statusId);
}

export const getPreviousStatusById = (statusId) => {
    return flow[flow.findIndex(item => item.id === statusId) - 1];
}

export const getNextStatusById = (statusId) => {
    return flow[flow.findIndex(item => item.id === statusId) + 1];
}

export const getShipmentStatus = (shipment) => {
    return flow[Math.max(flow.findIndex(status => status.id === shipment?.status), 0)]
}

export const getStatusIcon = (status, lx) => {
    const index = flow.findIndex(item => item.id === status.id);
    if (index < flow.length - 1) {
        return lx ? ICONS_LX[index % ICONS.length] : ICONS[index % ICONS.length]
    } else {
        return lx ? Checkmark32 : Checkmark16;
    }
};

export const getStatusOp = (movie, selectedStatus, status) => {
    if (status) {
        const movieStatusIndex = flow.findIndex(item => item.id === movie.status);
        const statusIndex = flow.findIndex(item => item.id === status.id);

        return {
            done: statusIndex < movieStatusIndex,
            enabled: statusIndex <= movieStatusIndex,
            selected: status.id === selectedStatus?.id,
        }
    } else {
        return { done: false, active: false }
    }
};