import { ComboBox, TextInput } from "carbon-components-react"
import { useEffect, useState } from "react"
import { ADVANCED_FILTER_TYPE } from "..";
import Util from "../../../../util/Util";
import { DATE_RANGE_UTIL } from "../../basic-filter/date-range";

function getOperators(type) {
    switch (type) {
        case "TEXT": return ['Is', 'Contains'];
        case "DATE": return ['From', 'To'];
        case "DATE_TIME": return ['From', 'To'];
        case "MONEY": return ["=", ">", "<", "≥", "≤"];
        case "NUMBER": return ["=", ">", "<", "≥", "≤"];
        case "LIST": return ["Contains"];
        default: return ["="]
    }
}

function getOperatorFromLabel(label) {
    switch (label) {
        case "Is": return "EQ";
        case "Contains": return "CONTAINS";
        case "=": return "EQ";
        case ">": return "GT";
        case "<": return "LT";
        case "≥": return "GTE";
        case "≤": return "LTE";
        default: return "";
    }
}

function getOperatorAndValueFromFilterValue(filterValue) {
    const type = filterValue.column.type;
    const operator = filterValue.operator;
    const value = filterValue.value;

    if (type == "DATE") {
        switch (operator) {
            case "From":
                return {
                    operator: 'GTE',
                    value: DATE_RANGE_UTIL.toEpoch(value)
                };
            case "To":
                return {
                    operator: "LT",
                    value: DATE_RANGE_UTIL.toEpoch(value, true)
                }
        }

        return {
            operator: getOperatorFromLabel(operator),
            value: value
        };
    } else if (type == "DATE_TIME") {
        switch (operator) {
            case "From":
                return {
                    operator: 'GTE',
                    value: DATE_RANGE_UTIL.toEpoch(value)
                };
            case "To":
                return {
                    operator: "LT",
                    value: DATE_RANGE_UTIL.toEpoch(value)
                }
        }

        return {
            operator: getOperatorFromLabel(operator),
            value: value
        };
    } else {
        return {
            operator: getOperatorFromLabel(operator),
            value: value
        };
    }
}

export function advancedFilterNodesToFilters(nodes) {
    return (nodes ?? [])
        .filter(node => {
            switch (node.type) {
                case ADVANCED_FILTER_TYPE.LOGIC:
                    return (node.items?.length ?? 0) > 0
                case ADVANCED_FILTER_TYPE.FILTER:
                    return Util.isStringExists(node.filterValue?.column?.id) && Util.isStringExists(node.filterValue?.operator) && Util.isStringExists(node.filterValue?.value);
                default:
                    return false;
            }
        })
        .map(node => node.type === ADVANCED_FILTER_TYPE.LOGIC ? {
            operator: node.operator,
            negate: node.negate,
            filters: advancedFilterNodesToFilters(node.items)
        } : {
            property: node.filterValue.column.id,
            ...getOperatorAndValueFromFilterValue(node.filterValue),
        })
        .filter(node => Util.isStringExists(node.property) ? true : node.filters.length > 0)
}


function ValueInput({ type, value, setValue }) {
    if (type == "DATE") {
        return <TextInput type="date" light style={{ width: '100%' }} hideLabel size="sm" value={value} onChange={e => setValue(e.target.value)} />
    } else if (type == "DATE_TIME") {
        return <TextInput type="datetime-local" light style={{ width: '100%' }} hideLabel size="sm" value={value} onChange={e => setValue(e.target.value)} />
    } else {
        return <TextInput light style={{ width: '100%' }} hideLabel size="sm" value={value} onChange={e => setValue(e.target.value)} />
    }
}


function useFilterValue(key, filter, setFilter) {
    return [filter[key], (val) => setFilter({ ...filter, [key]: val })]
}

export function FilterEditor({ columns, filter, setFilter }) {
    const [column, setColumn] = useFilterValue('column', filter, setFilter)
    const [operator, setOperator] = useFilterValue('operator', filter, setFilter)
    const [value, setValue] = useFilterValue('value', filter, setFilter)

    useEffect(() => {
        if (column && operator) {
            if (!getOperators(column.type).includes(operator)) {
                setOperator(null)
            }
        }
    }, [column, operator])
    useEffect(() => {
        if (!operator) {
            setValue(null)
        }
    }, [operator])

    return (
        <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', flex: 1, height: '100%', }}>
            <div style={{ flex: 2 }}>
                <ComboBox light style={{ width: '100%' }} size="sm" items={columns}
                    placeholder="Property" itemToString={item => item?.title}
                    selectedItem={column} onChange={e => setColumn(e.selectedItem)} />
            </div>
            {column ? (<>
                <div style={{ flex: 1 }}>
                    <ComboBox light style={{ width: '100%' }} size="sm" items={getOperators(column.type)}
                        placeholder="Operator"
                        selectedItem={operator} onChange={e => setOperator(e.selectedItem)} />
                </div>

                {Util.isStringExists(operator) ? (
                    <div style={{ flex: 2 }}>
                        <ValueInput type={column.type} value={value} setValue={setValue} />
                    </div>
                ) : (
                    <div style={{ flex: 2 }} />
                )}
            </>) : (
                <div style={{ flex: 3 }} />
            )}
        </div>
    )
}