
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timegridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule' // a plugin!
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeObservable } from '../../util/makeObservable'
import { ActivityDetailPage, ActivityDetailsView } from '../activities/pages/activities/detail'
import Api from '../../session/Api'
import UIUtil from '../../util/UIUtil'
import useStore from '../../hooks/useStore'
import { Close16 } from '@carbon/icons-react'
import { classExistsInSomeParent } from '../label-designer/util/ParentFinder'
import { onSelection, eventsLoader, useManageEventSelection, useHandleEventModificationAndSelection, useEventActivity } from './hooks'
import { Manager } from './manager'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import './calendar.scss'
import Button from '../../components/Button'
import Util from '../../util/Util'
import { FilterView } from './filter-view'
import { ACCOUNT_TYPE_STUDENT, ACCOUNT_TYPE_TUTOR, STUDENT_PRESENCE } from '../../constants/Constants'
import { getAccount, getAccountRole } from '../../session/SessionManager'
import { InformationFilled16 } from '@carbon/icons-react'
import { Link } from 'carbon-components-react'
import ProfilePic from '../../components/ProfilePic'
import { OBJECT_TYPE_STUDENT } from '../../constants/ObjectTypes'
import { AdvancedNotesView } from '../../views/advanced-notes/advanced-notes-view'
import { isMobileScreen } from '../../App'


const EventPopover = ({ info, store, children, popover }) => {
    const event = info.event;
    const [inspectorVisible] = useStore(store, "inspectorVisible");
    const [selectedEvent, setSelectedEvent] = useStore(store, "selectedEvent");

    const isSelected = selectedEvent?.id === event.id;
    const open = isSelected && !inspectorVisible && info.isStart

    return (
        <Popover
            isOpen={open}
            positions={['right', 'left', 'top', 'bottom']}
            padding={10}
            //containerStyle={{ zIndex: 1000000 }}
            containerStyle={{ zIndex: 8999 }}
            align="center"
            onClickOutside={e => {
                // setOpen(false)
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ marginTop: 25 }}
                    className='popover-arrow-container calendar-button-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div className="calendar-button-pop-over">
                        {popover}
                    </div>
                </ArrowContainer >
            )}
        >
            <div className='fc-event-main-frame' //onClick={() => setOpen(p => !p)}
                style={{
                    // boxShadow: "0px 24px 38px 3px rgba(0,0,0,0.14) , 0px 9px 46px 8px rgba(0,0,0,0.12) , 0px 11px 15px -7px rgba(0,0,0,0.2) "
                    transition: 'box-shadow 250ms',
                    boxShadow: isSelected ? '0px 24px 38px 3px rgba(0,0,0,0.28) , 0px 9px 46px 8px rgba(0,0,0,0.24) , 0px 11px 15px -7px rgba(0,0,0,0.4) ' : undefined,
                }}
            >
                {children}
            </div>
        </Popover >
    )
}

const HoverPopover = ({ children, popover }) => {
    const [hover, setHover] = useState(false);

    return (
        <Popover
            isOpen={hover}
            positions={['right', 'left', 'top', 'bottom']}
            padding={10}
            //containerStyle={{ zIndex: 1000000 }}
            containerStyle={{ zIndex: 8999 }}
            align="center"
            content={({ position, childRect, popoverRect }) => (
                <div className='calendar-button-pop-over-root'>
                    <div className="calendar-button-pop-over-hover">
                        {popover}
                    </div>
                </div>
            )}
        >
            <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                {children}
            </div>
        </Popover >
    )
}


const EventPopoverContent = ({ store, activityDetailsPayload, calendarManager, focusLeadId, courseId, autoSelectBlueprintId }) => {
    const { payload, selectedEvent, removeEvent } = useEventActivity(store, activityDetailsPayload)




    return (
        <div style={{ minWidth: 250, maxWidth: 350, maxHeight: 500, overflow: 'auto', padding: '0.5rem', }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ flex: 1, marginBottom: '0rem' }}>
                    <p style={{ fontWeight: 'bold', color: 'black', }}>{selectedEvent?.extendedProps?.activity?.blueprintName ?? 'New Activity'}</p>
                    {/* <p style={{ opacity: 0.65, fontSize: 14 }}>{selectedEvent?.extendedProps?.activity?.blueprintName}</p>
                    {Util.isNumberExist(selectedEvent?.extendedProps?.activity?.leadId) &&
                        <p style={{ fontWeight: 'bold', color: 'black', }}>{selectedEvent?.extendedProps?.activity?.leadFullName}</p>} */}
                </div>
                <button className="calendar-icon-button" onClick={() => store.set('selectedEvent', undefined)}>
                    <Close16 />
                </button>
            </div>

            <ActivityDetailsView itemId={payload.activity?.id ?? "new"} payload={payload}
                key={selectedEvent?.id} calendarMode popoverMode
                // selectedEvent={calendarManager.getEditingEvent()}
                getCalendarDate={() => calendarManager.getEventCalendarDate(selectedEvent)}
                setCalendarEndDate={end => calendarManager.setEventCalendarEndDate(selectedEvent, end)}

                onUpdatePresence={calendarManager.onUpdatePresence.bind(calendarManager)}

                onCalendarEventCreated={event => calendarManager.replaceCalendarEvent(selectedEvent, event, newEvent => store.set('selectedEvent', undefined))}
                onCalendarEventUpdated={event => calendarManager.updateEvent(event)}
                updateActivity={activity => calendarManager.updateEventActivity(selectedEvent, activity)}
                onCalendarEventRemoved={removeEvent}
                focusLeadId={focusLeadId}
                autoSelectBlueprintId={autoSelectBlueprintId}
                courseId={courseId}
                reloadCurrentCalendarView={calendarManager.reloadCurrentView.bind(calendarManager)}
            />
        </div>
    )
}

const EventPresenceInfo = ({ presence, activity }) => {
    const isAssigned = getAccount().id != activity?.userId;
    if (!isAssigned) {
        return "";
    }

    if (presence) {
        if (presence.state === STUDENT_PRESENCE.PRESENT) {
            return "Attending";
        } else if (presence.state === STUDENT_PRESENCE.ABSENT) {
            return "Absent";
        } else {
            return "";
        }
    } else {
        return "Please confirm attendance";
    }
}

const CourseInfo = ({ info }) => {
    const activity = info.event.extendedProps.activity;
    return (
        <div style={{ opacity: 1, position: 'absolute', bottom: 0, right: 0 }}>
            <HoverPopover popover={
                <div style={{ padding: '0.25rem', background: '#1c1c1cCC', width: 150, borderRadius: 5 }}>
                    {activity.students.map((student, index) => (
                        <div key={student.id} style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', marginTop: index > 0 ? '0.15rem' : 0 }}>
                            <ProfilePic size={16} src={Api.getThumbnail(OBJECT_TYPE_STUDENT, student.id)} />
                            <p style={{ fontSize: 12, opacity: 1, color: 'white' }}>{student.name}</p>
                        </div>
                    ))}

                    {activity.students.length === 0 && <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', }}>
                        <p style={{ fontSize: 12, opacity: 0.65, color: 'white', fontStyle: 'italic' }}>No students assigned</p>
                    </div>}
                </div>
            }>
                <div style={{ padding: '0.15rem', }}>
                    <InformationFilled16 />
                </div>
            </HoverPopover>
        </div>
    )
}

const Event = (info, store, activityDetailsPayload, calendarManager, focusLeadId, courseId, autoSelectBlueprintId) => {
    const role = getAccountRole();
    return (
        <EventPopover info={info} store={store} popover={
            <EventPopoverContent store={store} activityDetailsPayload={activityDetailsPayload} calendarManager={calendarManager} focusLeadId={focusLeadId} courseId={courseId} autoSelectBlueprintId={autoSelectBlueprintId} />
        }>
            <div className='fc-event-time'>{info.timeText}</div>
            <div className='fc-event-title-container'>
                <div className='fc-event-title fc-sticky'>
                    <span>{info.event?.extendedProps?.activity?.blueprintName ?? 'New Activity'}</span>
                    {Util.isNumberExist(info.event?.extendedProps?.activity?.leadId) &&
                        <div>
                            <strong>{info.event?.extendedProps?.activity?.leadFullName}</strong>
                        </div>}
                    {Util.isNumberExist(info.event?.extendedProps?.activity?.courseId) && <>
                        <div>
                            <strong>{info.event?.extendedProps?.activity?.courseName}</strong>
                        </div>
                        {role == ACCOUNT_TYPE_STUDENT && <div>
                            <EventPresenceInfo presence={info.event?.extendedProps?.studentPresence} activity={info.event?.extendedProps?.activity} />
                        </div>}


                    </>}


                </div>
            </div>
            {Util.isNumberExist(info.event?.extendedProps?.activity?.courseId) && role != ACCOUNT_TYPE_STUDENT && !isMobileScreen &&
                <CourseInfo info={info} />}
        </EventPopover>
    )
}
const Calendar = React.forwardRef(({ inspectorVisible, setInspectorVisible, activityDetailsPayload, store, calendarManager, focusLeadId, courseId, autoSelectBlueprintId }, ref) => {
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState({});

    const eventClick = useCallback(info => store.set("selectedEvent", info.event), [store]);
    const select = useCallback(info => {
        const event = onSelection(info);
        eventClick({ event })
    }, [eventClick])
    const updateSchedule = useCallback(info => {
        const activity = info.event.extendedProps.activity;

        if (activity) {
            calendarManager.updateSchedule(info.event, info)
        }
    }, [calendarManager])

    const eventComponent = useCallback(info => Event(info, store, activityDetailsPayload, calendarManager, focusLeadId, courseId, autoSelectBlueprintId), [store, focusLeadId, courseId, autoSelectBlueprintId])
    const eventModificationAndSelectionHandler = useHandleEventModificationAndSelection(store)

    const loader = useCallback((info, success, failure) => eventsLoader(focusLeadId ?? 0, courseId ?? 0, info, success, failure), [focusLeadId, courseId])

    useManageEventSelection(store, ref);
    return (
        <div style={{ flex: 2, padding: isMobileScreen ? 7 : '1rem', position: 'relative' }}>
            <FullCalendar
                ref={ref}

                height="100%"
                plugins={[dayGridPlugin, interactionPlugin, listPlugin, timegridPlugin, rrulePlugin]}
                initialView={isMobileScreen ? "timeGridDay" : "timeGridWeek"}
                nowIndicator

                customButtons={{
                    toggleInspectorBtn: {
                        text: isMobileScreen ? (
                            inspectorVisible ? "Hide" : "Show"
                        ) : (
                            inspectorVisible ? "Hide Sidebar" : "Show Sidebar"
                        ),
                        click: () => setInspectorVisible(!inspectorVisible)
                    },
                    filterBtn: {
                        text: "Filter",
                        click: () => setShowFilter(true)
                    }
                }}


                headerToolbar={{
                    left: isMobileScreen ? 'prev,next' : 'prev,next today',
                    center: 'title',
                    //right: 'dayGridMonth,timeGridWeek,timeGridDay filterBtn toggleInspectorBtn'
                    right: isMobileScreen ? 'dayGridMonth,timeGridDay toggleInspectorBtn' : 'dayGridMonth,timeGridWeek,timeGridDay toggleInspectorBtn'
                }}

                selectable
                selectMirror

                //events={eventsLoader}
                events={loader}

                select={select}
                eventClick={eventClick}
                // eventClick={e => e.event.setP}


                eventResizableFromStart
                eventResize={updateSchedule}
                eventDrop={updateSchedule}

                {...eventModificationAndSelectionHandler}

                eventContent={eventComponent}
            />

            {showFilter && <FilterView {...({ filter, setFilter })} />}
        </div>
    )
})

const Inspector = ({ store, activityDetailsPayload, calendarManager, focusLeadId, courseId, autoSelectBlueprintId, loadAdvancedNotesAtFirstReq }) => {
    const { payload, selectedEvent, removeEvent } = useEventActivity(store, activityDetailsPayload)

    return (
        <div className='activity-inspector' style={{ width: isMobileScreen ? undefined : 400, overflowX: 'hidden', overflowY: 'hidden', background: (loadAdvancedNotesAtFirstReq && !selectedEvent) ? 'white' : '#f4f4f4', height: '100%', borderLeft: '1px solid #00000020' }}>
            {selectedEvent ? (<>
                <ActivityDetailsView itemId={payload.activity?.id ?? "new"} payload={payload}
                    key={selectedEvent?.id}
                    calendarMode onCalendarCloseBtn={() => store.set('selectedEvent', undefined)}
                    getCalendarDate={() => calendarManager.getEventCalendarDate(selectedEvent)}
                    setCalendarEndDate={end => calendarManager.setEventCalendarEndDate(selectedEvent, end)}

                    focusLeadId={focusLeadId}
                    courseId={courseId}
                    autoSelectBlueprintId={autoSelectBlueprintId}

                    onUpdatePresence={calendarManager.onUpdatePresence.bind(calendarManager)}

                    calendarTitle={selectedEvent?.extendedProps?.activity?.blueprintName ?? 'New Activity'}

                    onCalendarEventCreated={event => calendarManager.replaceCalendarEvent(selectedEvent, event, newEvent => store.set('selectedEvent', undefined))}
                    onCalendarEventUpdated={event => calendarManager.updateEvent(event)}
                    updateActivity={activity => calendarManager.updateEventActivity(selectedEvent, activity)}

                    onCalendarEventRemoved={removeEvent}

                    reloadCurrentCalendarView={calendarManager.reloadCurrentView.bind(calendarManager)}
                />
            </>) : loadAdvancedNotesAtFirstReq ? (<>

                <AdvancedNotesView {...loadAdvancedNotesAtFirstReq} />
            </>) : (<>
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Nothing is selected</p>
                </div>
            </>)}
        </div>
    )
}

export function CalendarView({ activityDetailsPayload, focusLeadId, courseId, autoSelectBlueprintId, loadAdvancedNotesAtFirstReq }) {
    const calendarRef = useRef();
    const calendarManager = useMemo(() => new Manager(calendarRef), [])

    const store = useMemo(() => {
        const observable = makeObservable();
        observable.set("inspectorVisible", true)
        observable.set("selectedEvent", null);
        return observable;
    }, [])

    const [inspectorVisible, setInspectorVisible] = useStore(store, "inspectorVisible");

    if (isMobileScreen) {
        return (
            <div //className='dark-minimal-scroll-bar' 
                style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Calendar ref={calendarRef} calendarManager={calendarManager} store={store} inspectorVisible={inspectorVisible} setInspectorVisible={setInspectorVisible} activityDetailsPayload={activityDetailsPayload} focusLeadId={focusLeadId} courseId={courseId} autoSelectBlueprintId={autoSelectBlueprintId} />
                {inspectorVisible &&
                    <div style={{ height: 300, overflow: 'auto', background: '#f4f4f4', borderTop: '1px solid #00000020' }}>
                        <Inspector loadAdvancedNotesAtFirstReq={loadAdvancedNotesAtFirstReq} calendarManager={calendarManager} store={store} activityDetailsPayload={activityDetailsPayload} focusLeadId={focusLeadId} courseId={courseId} autoSelectBlueprintId={autoSelectBlueprintId} />
                    </div>}
            </div>
        )
    }

    return (
        <div //className='dark-minimal-scroll-bar' 
            style={{ width: '100%', height: '100%', display: 'flex' }}>
            <Calendar ref={calendarRef} calendarManager={calendarManager} store={store} inspectorVisible={inspectorVisible} setInspectorVisible={setInspectorVisible} activityDetailsPayload={activityDetailsPayload} focusLeadId={focusLeadId} courseId={courseId} autoSelectBlueprintId={autoSelectBlueprintId} />
            {inspectorVisible && !isMobileScreen &&
                <Inspector loadAdvancedNotesAtFirstReq={loadAdvancedNotesAtFirstReq} calendarManager={calendarManager} store={store} activityDetailsPayload={activityDetailsPayload} focusLeadId={focusLeadId} courseId={courseId} autoSelectBlueprintId={autoSelectBlueprintId} />}
        </div>
    )
}