
import { ErrorFilled16, CheckmarkFilled16, ArrowRight16, Hourglass16, RecentlyViewed16, Activity24 } from '@carbon/icons-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { hasCapabilitySupport } from '../../../../app/Capabilities'
import Button from '../../../../components/Button'
import ProfilePic from '../../../../components/ProfilePic'
import { ACCOUNT_TYPE_TUTOR } from '../../../../constants/Constants'
import { OBJECT_TYPE_STUDENT } from '../../../../constants/ObjectTypes'
import { getStatusName, LEAD_STATUS_ACTIVE, LEAD_STATUS_CANCELLED, LEAD_STATUS_DEAL_DONE, LEAD_STATUS_FINALIZED, LEAD_STATUS_ON_HOLD, StatusIndicator } from '../../../../domain/lead'
import useStore from '../../../../hooks/useStore'
import Api from '../../../../session/Api'
import { getAccountRole } from '../../../../session/SessionManager'
import UIUtil from '../../../../util/UIUtil'
import Util from '../../../../util/Util'
import { LmsFinalizeButton } from '../buttons/lms-finalize-button'
import { RealEstateFinalizeButton } from '../buttons/real-estate-finalize-button'
import { FinalizedStatusDealView } from '../views/finalized-status-deal-view'

const HowLongIndicator = ({ status, days }) => {
    const rangePerc = Math.min(days / 60, 1) * 100;
    return (
        <div style={{ marginTop: '1rem', width: '100%' }}>
            <div style={{ background: 'rgba(0,0,0,.05)', padding: '1rem', borderRadius: 15 }}>
                {days > 0 ? (
                    <p style={{ fontSize: 14, margin: 0, marginTop: -5 }}>{getStatusName(status)} for {days} days</p>
                ) : (
                    <p style={{ fontSize: 14, margin: 0, marginTop: -5 }}>{getStatusName(status)} from today</p>
                )}
                <div style={{ width: '100%', position: 'relative', marginTop: '0.25rem' }}>
                    <div style={{
                        borderRadius: 50, height: 20, width: '100%', background: 'linear-gradient(90deg, rgba(22,80,0,1) 0%, rgba(219,224,0,1) 49%, rgba(255,0,0,1) 100%)',
                        border: '1px solid white'
                    }} />
                    <div style={{ background: '#ffffff80', position: 'absolute', height: 20, borderRadius: 50, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: (100 - rangePerc) + '%', left: rangePerc + '%', top: 0 }} />
                    <div style={{ background: '#0f62fe', height: 25, width: 4, position: 'absolute', left: rangePerc + '%', top: '50%', transform: 'translateY(-50%)' }} />
                </div>
            </div>
        </div>
    )
}

const StatusButton = ({ leadId, status, onUpdate, ...props }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true)
        Api.setLeadStatus(leadId, status, null, response => {
            if (response.status === true) {
                onUpdate(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }
    return (
        <Button {...props} loading={loading} onClick={onClick} />
    )
}

const StatusButtons = ({ leadId, store, status, onUpdate, report }) => {
    switch (status) {
        case LEAD_STATUS_ACTIVE:
            return (<>
                <StatusButton status={LEAD_STATUS_CANCELLED} leadId={leadId} onUpdate={onUpdate} kind="danger" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={ErrorFilled16}>Cancelled</StatusButton>
                <StatusButton status={LEAD_STATUS_ON_HOLD} leadId={leadId} onUpdate={onUpdate} kind="secondary" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={Hourglass16}>Hold</StatusButton>
                {hasCapabilitySupport("lms") ? (
                    <LmsFinalizeButton store={store} leadId={leadId} onUpdate={onUpdate} />
                ) : hasCapabilitySupport("realEstate") ? (
                    <RealEstateFinalizeButton store={store} leadId={leadId} dealId={report.createdPropertyDealId} onUpdate={onUpdate} />
                ) : (
                    <StatusButton status={LEAD_STATUS_FINALIZED} leadId={leadId} onUpdate={onUpdate} kind="primary" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={CheckmarkFilled16}>Finalize</StatusButton>
                )}
            </>)
        case LEAD_STATUS_ON_HOLD:
            return (<>
                <StatusButton status={LEAD_STATUS_CANCELLED} leadId={leadId} onUpdate={onUpdate} kind="danger" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={ErrorFilled16}>Cancelled</StatusButton>
                <StatusButton status={LEAD_STATUS_ACTIVE} leadId={leadId} onUpdate={onUpdate} kind="primary" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={RecentlyViewed16}>Re-open</StatusButton>
            </>)
        case LEAD_STATUS_CANCELLED:
            return (<>
                <StatusButton status={LEAD_STATUS_ACTIVE} leadId={leadId} onUpdate={onUpdate} kind="primary" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={RecentlyViewed16}>Re-open</StatusButton>
            </>)
        case LEAD_STATUS_FINALIZED:
            return (<>
                <StatusButton status={LEAD_STATUS_CANCELLED} leadId={leadId} onUpdate={onUpdate} kind="danger" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={ErrorFilled16}>Cancelled</StatusButton>
                <StatusButton status={LEAD_STATUS_ACTIVE} leadId={leadId} onUpdate={onUpdate} kind="secondary" size="sm" style={{ flex: 1, borderRadius: 50 }} renderIcon={RecentlyViewed16}>Re-open</StatusButton>
            </>)
        default:
            return null;
    }
}

export function Status({ leadId, store, lead }) {
    const [report, setReport] = useStore(store, 'statusReport');
    const onUpdate = lead => {
        if (store.get('customerId') !== lead.customerId) {
            store.set('customerId', lead.customerId)
        }

        setReport({
            status: lead.status,
            lastStatusUpdate: lead.lastStatusUpdate,
            lastStatusUpdateUserId: lead.lastStatusUpdateUserId,
            lastStatusUpdateUserFullName: lead.lastStatusUpdateUserFullName,
            daysSinceLastUpdate: lead.daysSinceLastUpdate,
            createdStudentFullName: lead.createdStudentFullName,
            createdStudentId: lead.createdStudentId,

            createdPropertyDealStatus: lead.createdPropertyDealStatus,
            createdPropertyDealId: lead.createdPropertyDealId
        })
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '0.5rem' }}>
                <Activity24 />
                <div style={{ flex: 1 }}>
                    <h6 style={{ fontSize: 14, margin: 0 }}>Status Report</h6>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>by {report.lastStatusUpdateUserFullName} on {Util.getDateOnly(report.lastStatusUpdate)}</p>
                </div>
                <StatusIndicator status={report.status} />
            </div>
            {(report.status == LEAD_STATUS_ACTIVE || report.status == LEAD_STATUS_ON_HOLD) &&
                <HowLongIndicator status={report.status} days={report.daysSinceLastUpdate} />
            }
            {(report.status == LEAD_STATUS_FINALIZED && hasCapabilitySupport("lms")) ? (
                <div style={{ display: 'flex', gap: '0.15rem', width: '100%', marginTop: '1rem', alignItems: 'center' }}>
                    <ProfilePic size={48} src={Api.getThumbnail(OBJECT_TYPE_STUDENT, report.createdStudentId)} />
                    <div style={{ flex: 1, marginLeft: '0.5rem' }}>
                        <h4 style={{ fontSize: 16 }}>{report.createdStudentFullName}</h4>
                        <h6 style={{ fontSize: 14 }}>Created student</h6>
                    </div>
                    {getAccountRole() != ACCOUNT_TYPE_TUTOR &&
                        <Link target={"_blank"} to={'/students/' + report.createdStudentId}>
                            <button className='lead-flow-icon-button'>
                                <ArrowRight16 />
                            </button>
                        </Link>}
                </div>
            ) : (report.status == LEAD_STATUS_FINALIZED && hasCapabilitySupport("realEstate")) ? (
                <FinalizedStatusDealView leadId={lead.id} report={report} onUpdate={onUpdate} />
            ) : (
                <div style={{ display: 'flex', gap: '0.15rem', width: '100%', marginTop: '1rem' }}>
                    <StatusButtons store={store} leadId={leadId} status={report.status} onUpdate={onUpdate} report={report} />
                </div>
            )}
        </div>
    )
}