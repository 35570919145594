import { Purchase24, WirelessCheckout16, TrashCan16, CloseOutline16, ArrowRight16, Tag16 } from '@carbon/icons-react'
import { Checkbox, ComboBox, ContentSwitcher, Switch, TextInput } from 'carbon-components-react'
import React, { useRef, useEffect } from 'react'
import Button from '../../../components/Button'
import useSize from '../../../util/useSize'
import ReactTooltip from 'react-tooltip';
import Util from '../../../util/Util'
import UIUtil from '../../../util/UIUtil'
import { useState } from 'react'
import { SetWaiter } from './set-waiter'
import { REST_ORDER_TYPE } from '../../../domain/rest-sales-order'
import { SetNoOfPeople } from './set-no-of-people'
import { SetTable } from './set-table'
import { SetDineInTakeOut } from './set-dine-in-take-out'
import { getAccountRole } from '../../../session/SessionManager'
import { ACCOUNT_TYPE_TYPE_WAITER } from '../../../constants/Constants'

function DeleteBtn({ state }) {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        if (loading) {
            return;
        }

        UIUtil.confirm(() => {
            setLoading(true);
            state.clearCart((success) => {
                setLoading(false)
                if (!success) {
                    UIUtil.showError();
                }
            })
        })
    }
    return (
        <Button loading={loading} onClick={onClick} renderIcon={TrashCan16} kind={"danger"} style={{ borderRadius: 7, flex: 1, minWidth: 'unset', maxWidth: 125 }}>Delete</Button>
    )
}


function CloseBtn({ state }) {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        state.createRestCart((success) => {
            setLoading(false)
            if (!success) {
                UIUtil.showError();
            }
        })
    }
    return (
        <Button loading={loading} onClick={onClick} renderIcon={CloseOutline16} kind="secondary" style={{ borderRadius: 7, flex: 1, minWidth: 'unset', maxWidth: 125 }}>Close</Button>
    )
}

export default ({ state, }) => {
    //const [mode, setMode] = useState("dine-in")
    const [mode, setMode] = state.useRestOrderType();

    const onDineInCreateBtn = () => {
        state.setShowDineInCheckoutDialog(true)
    }

    const onTakeOutCreateBtn = () => {
        state.setShowTakeOutCheckoutDialog(true)
    }

    const onDeliveryCreateBtn = () => {
        state.setShowDeliveryCheckoutDialog(true);
    }

    const onCreateOrderBtn = () => {
        if (!state.getAllCartItems().length) {
            UIUtil.showInfo("No item in cart");
            return;
        }

        switch (mode) {
            case "dine-in":
                onDineInCreateBtn()
                break;
            case "take-out":
                onTakeOutCreateBtn()
                break;
            case "delivery":
                onDeliveryCreateBtn()
                break;
            default:
                UIUtil.showInfo("Please select order type (Dine-in, Take-out or Delivery)")
                break;
        }
    }

    return (
        <div style={{ background: 'black', paddingInline: '0.5rem' }}>
            {/* {state.getSession()?.currentCartId} */}

            {/* {getAccountRole() !== ACCOUNT_TYPE_TYPE_WAITER && <>
                <SetDineInTakeOut mode={mode} setMode={setMode} />
            </>} */}


            {mode === "dine-in" && (<>
                <div style={{
                    width: '100%', height: 48, paddingLeft: '0.5rem', color: 'white',
                    display: 'flex', alignItems: 'center', flexDirection: 'row',
                }}>
                    {!state.isCartSession && <div className="white-combo-box-arrow dark-text-input" style={{ flex: 2 }}>
                        <SetTable state={state} />
                    </div>}
                    <div className="white-combo-box-arrow dark-text-input" style={{ flex: 2 }}>
                        <SetWaiter state={state} />
                    </div>
                    <div style={{ width: '0.5rem' }} />

                </div>
                <SetNoOfPeople state={state} />
                <div style={{
                    width: '100%', height: 48, paddingLeft: '0.5rem', color: 'white',
                    display: 'flex', alignItems: 'center', flexDirection: 'row',
                }}>
                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Subtotal (+)</p>
                        <h5>{state.getSubtotal()}</h5>
                    </div>

                    {state.getRawDiscount() > 0 && <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Deductions (-)</p>
                        <h5>{state.getDiscount()}</h5>
                    </div>}

                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Tax (+)</p>
                        <h5>{state.getTax()}</h5>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: 15, flex: 1, justifyContent: 'flex-end' }}>
                        <h2 style={{ color: 'white', fontWeight: 'bold', lineHeight: 1, textAlign: 'end' }}>{state.getTotal()}</h2>
                    </div>
                    <div style={{ width: '0.25rem', height: '1rem' }} />
                </div>
            </>)}

            {mode === "take-out" && (<>
                <div style={{
                    width: '100%', height: 48, paddingLeft: '0.5rem', overflow: 'hidden', color: 'white',
                    display: 'flex', alignItems: 'center', flexDirection: 'row',
                }}>
                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Subtotal (+)</p>
                        <h5>{state.getSubtotal()}</h5>
                    </div>

                    {state.getRawDiscount() > 0 && <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Deductions (-)</p>
                        <h5>{state.getDiscount()}</h5>
                    </div>}

                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Tax (+)</p>
                        <h5>{state.getTax()}</h5>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: 15, flex: 1, justifyContent: 'flex-end' }}>
                        <h2 style={{ color: 'white', fontWeight: 'bold', lineHeight: 1, textAlign: 'end' }}>{state.getTotal()}</h2>
                    </div>
                    <div style={{ width: '0.25rem', height: '1rem' }} />

                    {/* <div style={{ width: '0.25rem', height: '1rem' }} />
                    <Button
                        hasIconOnly
                        loading={state.isCheckoutLoading()}
                        onClick={() => {
                            if (state.getAllCartItems().length > 0) {
                                onTakeOutCreateBtn();
                            } else {
                                UIUtil.showInfo("No item in cart");
                            }
                        }}
                        renderIcon={WirelessCheckout16}>
                    </Button> */}
                </div>
            </>)}

            {mode === "delivery" && (<>
                <div style={{
                    width: '100%', height: 48, paddingLeft: '0.5rem', overflow: 'hidden', color: 'white',
                    display: 'flex', alignItems: 'center', flexDirection: 'row',
                }}>
                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Subtotal (+)</p>
                        <h5>{state.getSubtotal()}</h5>
                    </div>

                    {state.getRawDiscount() > 0 && <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Deductions (-)</p>
                        <h5>{state.getDiscount()}</h5>
                    </div>}

                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 12 }}>Tax (+)</p>
                        <h5>{state.getTax()}</h5>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: 15, flex: 1, justifyContent: 'flex-end' }}>
                        <h2 style={{ color: 'white', fontWeight: 'bold', lineHeight: 1, textAlign: 'end' }}>{state.getTotal()}</h2>
                    </div>
                    <div style={{ width: '0.25rem', height: '1rem' }} />

                    {/* <div style={{ width: '0.25rem', height: '1rem' }} />
                    <Button
                        hasIconOnly
                        loading={state.isCheckoutLoading()}
                        onClick={() => {
                            if (state.getAllCartItems().length > 0) {
                                onDeliveryCreateBtn();
                            } else {
                                UIUtil.showInfo("No item in cart");
                            }
                        }}
                        renderIcon={WirelessCheckout16}>
                    </Button> */}
                </div>
            </>)}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: '0.15rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                <DeleteBtn state={state} />
                <CloseBtn state={state} />
                <Button loading={state.isCheckoutLoading()} onClick={onCreateOrderBtn} renderIcon={ArrowRight16} className="green-button" style={{ borderRadius: 7, flex: 1, minWidth: 'unset', maxWidth: 125 }}>Create</Button>
            </div>
        </div>
    )
}