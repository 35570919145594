import { Printer32, Add16 } from '@carbon/icons-react'
import { PrintEvent } from './print-event'
import { useRef, useState } from 'react'
import { withLoadablePage } from '../../base/Page';
import Api from '../../session/Api';
import Button from '../../components/Button';
import Util from '../../util/Util';




function View({ payload }) {
    const [events, setEvents] = useState(payload.events)
    const bottomRef = useRef();

    const onNewBtn = () => {
        setEvents(prev => [...prev, {
            id: Util.newTempId(),
            whenHook: "",
            sendTo: "",
            template: ""
        }])
        setTimeout(() => bottomRef.current?.scrollIntoView?.({ behavior: "smooth" }))
    }

    const removeEvent = event => {
        setEvents(prev => prev.filter(item => item.id !== event.id))
    }

    const updateEvent = (oldEvent, newEvent) => {
        setEvents(prev => prev.map(item => item.id === oldEvent.id ? newEvent : item));
    }

    return (
        <div style={{ paddingInline: '6rem', paddingTop: '3rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem' }}>
                <div>
                    <Printer32 style={{ width: 64, height: 64 }} />
                    <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>Print Event Designer</h3>
                    {/* <h4>{notification.id}</h4> */}
                    <h4 style={{ color: '#0f62fe', fontSize: 16 }}>Dynamic Events</h4>

                </div>
                <div style={{ flex: 1 }} />
                <Button onClick={onNewBtn} renderIcon={Add16} size="sm" style={{ borderRadius: 25 }}>New Flow</Button>
            </div>

            {events.map(event => <PrintEvent key={event.id} stores={payload.stores} printers={payload.printers} classes={payload.classes} event={event} updateEvent={newEvent => updateEvent(event, newEvent)} removeEvent={() => removeEvent(event)} />)}
            {events.length === 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>No flows</p>
            </div>}

            <div style={{ height: '6rem' }} />
            <div ref={bottomRef} />
        </div>
    )
}

export const PrintEventDesignerPage = withLoadablePage(listener => Api.getPrintEvents(listener), View)