import Portal from "../../util/Portal"
import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react';
import {
    ErrorFilled16, CheckmarkFilled16,
} from '@carbon/icons-react'
import Button from "../Button";

import Editor, { useMonaco } from "@monaco-editor/react";
import { useEffect, useRef } from "react";
import Util from "../../util/Util";


export const CodeEditorInput = ({ availableProperties, value, onValueChange }) => {
    const editorRef = useRef();

    const monaco = useMonaco();

    useEffect(() => {
        if (monaco) {
            monaco.languages.register({ id: "mainLang" })
            monaco.languages.setLanguageConfiguration("mainLang", {
                surroundingPairs: [
                    { open: '(', close: ')' },
                ],
                autoClosingPairs: [
                    { open: '(', close: ')' },
                ],
            })
            monaco.languages.setMonarchTokensProvider('mainLang', {
                ignoreCase: true,
                keywords: ["tes ft", "case", "if"],
                tokenizer: {
                    root: [
                        ...(availableProperties?.map(item => [new RegExp('(' + item + ')', 'gmi'), 'type']) ?? []),
                        //[/(subsidiary account)/gmi, 'type'],
                        //[/(debit)/gmi, 'type'],
                        [/(math(?=\.))/gmi, 'keyword'],
                        [/(sum(?=\())/gmi, 'string'],
                        [/(avg(?=\())/gmi, 'string'],
                        [/(max(?=\())/gmi, 'string'],
                        [/(min(?=\())/gmi, 'string'],
                        [/(std(?=\())/gmi, 'string'],
                        [/(var(?=\())/gmi, 'string'],
                    ],
                },
            })
            const completionDispoable = monaco.languages.registerCompletionItemProvider('mainLang', {
                provideCompletionItems: (model, position) => {
                    const textUntilPosition = model.getValueInRange({
                        startLineNumber: 1,
                        startColumn: 1,
                        endLineNumber: position.lineNumber,
                        endColumn: position.column,
                    });
                    const word = model.getWordUntilPosition(position);
                    const range = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn,
                        endColumn: word.endColumn,
                    };

                    if ((textUntilPosition.match(/\(/g) || []).length > (textUntilPosition.match(/\)/g) || []).length) {
                        return {
                            suggestions: availableProperties?.map(item => ({
                                label: item,
                                kind: monaco.languages.CompletionItemKind.Value,
                                insertText: item,
                                range: range,
                            })) ?? []
                        }
                    }
                    if ((textUntilPosition.match(/\./g) || []).length > (textUntilPosition.match(/\)/g) || []).length &&
                        textUntilPosition.match(/(math(?=\.))/gmi)) {
                        return {
                            suggestions: [
                                {
                                    label: 'Summation',
                                    insertText: 'sum(',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    range: range,
                                },
                                {
                                    label: 'Average',
                                    insertText: 'avg(',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    range: range,
                                },
                                {
                                    label: 'Max Value',
                                    insertText: 'max(',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    range: range,
                                },
                                {
                                    label: 'Min Value',
                                    insertText: 'min(',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    range: range,
                                },
                                {
                                    label: 'Standard Deviation',
                                    insertText: 'std(',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    range: range,
                                },
                                {
                                    label: 'Variance',
                                    insertText: 'variance(',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    range: range,
                                }
                            ]
                        }
                    } else {
                        return {
                            suggestions: [
                                {
                                    label: 'Math',
                                    kind: monaco.languages.CompletionItemKind.Operator,
                                    insertText: 'math.',
                                    range: range,
                                }
                            ],
                        }
                    }
                }
            })
            if (!Util.isStringExists(value)) {
                editorRef.current?.trigger('', 'editor.action.triggerSuggest');
            }
            return () => completionDispoable.dispose();
        }
    }, [monaco, availableProperties])

    const initEditor = (editor, monaco) => {
        editorRef.current = editor;
        if (!Util.isStringExists(value)) {
            editorRef.current?.trigger('', 'editor.action.triggerSuggest');
        }
    }

    useEffect(() => {
        editorRef.current?.trigger('', 'editor.action.triggerSuggest');
    }, [value])

    return (
        <div style={{ borderRadius: 5, paddingBlock: '0.5rem', paddingInline: '0.5rem', background: '#1E1E1E', color: 'white' }}>
            <Editor
                onMount={initEditor}
                height="20px"
                language="mainLang"
                theme="vs-dark"


                // defaultValue={value}
                value={value}

                onChange={value => onValueChange(value.replace(/[\n\r]/g, '.'))}

                options={{
                    wordWrap: 'off',
                    lineNumbers: 'off',
                    lineNumbersMinChars: 0,
                    overviewRulerLanes: 0,
                    overviewRulerBorder: false,
                    lineDecorationsWidth: 0,
                    hideCursorInOverviewRuler: true,
                    glyphMargin: false,
                    folding: false,
                    scrollBeyondLastColumn: 0,
                    scrollbar: { horizontal: 'hidden', vertical: 'hidden' },
                    find: { addExtraSpaceOnTop: false, autoFindInSelection: 'never', seedSearchStringFromSelection: false },
                    minimap: { enabled: false },
                }}
            />
        </div>
    )
}