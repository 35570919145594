import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util, { isV2 } from "../../../util/Util"
import { listDetailItemId } from "../../base/report-page"
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { Archive32 } from '@carbon/icons-react'

export const SalesOrderQuantity = {
    filter: Filter,

    onAction: (action, data, history) => {
        switch (action) {
            case "invoiceNo":
                if (Util.isNumberExist(data.invoiceId)) {
                    window.open(listDetailItemId("V2SalesList", data.invoiceId), "_blank")
                }
                break;
        }
    },
    freezeCol: 3,
    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const ORDER_TYPES = [
    "POS",
    "Online Store"
]

const CUSTOMER_TYPES = [
    "Venue",
    "Delivery"
]

const SHOW_INVOICED_FILTER = { property: "isInvoiced", operator: "EQ", value: "Yes" }

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" padWorkingHours />
        <div style={{ paddingLeft: '1rem' }}>
            <CheckboxFilter state={state} filterId="showInvoicedOnly" label="Invoiced only?" filter={SHOW_INVOICED_FILTER} />
        </div>
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Order Type" key="orderTypeField" fieldKey="orderTypeField" options={ORDER_TYPES} state={state} property="type" />
        </div>
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Customer Type" key="customerTypeField" fieldKey="customerTypeField" options={CUSTOMER_TYPES} state={state} property="customerType" />
        </div>
        <div style={{ height: 30, flex: 2 }}>
            <MultiSelect isMulti={false} placeholder="Customer" key="customerField" fieldKey="customerField" options={endpoint.customers.map(item => item.value)} state={state} property="customerName" />
        </div>
    </>)
}
