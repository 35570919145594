
import { ComboBox, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import ProfilePic from "../../../components/ProfilePic";
import { OBJECT_TYPE_ACCOUNT } from "../../../constants/ObjectTypes";
import Api from "../../../session/Api";
import { BlueprintInputFieldsReadView } from "../components/blueprint-input-fields-read";
import { ActivityStatusTag, statusSummary, statusSummaryUserId } from "../pages/activities/status-view";

export const ActivityStatusDetailsContent = ({ item, skipBanner, hasNoDetailsMsg }) => (<>
    {!skipBanner && <div style={{ background: '#E0E0E0', borderRadius: 10, display: 'flex', alignItems: 'center', paddingInline: '0.5rem', minHeight: 45 }}>
        <ProfilePic size={32} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, statusSummaryUserId(item))} />
        <p style={{ fontSize: 14, marginLeft: '0.25rem' }}>{statusSummary(item)}</p>
        <div style={{ flex: 1, minWidth: '0.5rem' }} />
        <ActivityStatusTag item={item} />
    </div>}

    {item.latestStatusRecord && item.latestStatusRecord.formFieldValues && item.latestStatusRecord.formFieldValues.length > 0 ? <>
        {/* <p style={{ fontWeight: 'bold', color: 'black', marginTop: '1rem' }}>Details</p> */}
        <div style={{ marginTop: '1rem' }} />
        <div style={{ background: 'white', borderRadius: 10, padding: '1rem', borderWidth: 1, borderColor: '#00000020', borderStyle: 'solid' }}>
            <BlueprintInputFieldsReadView formFieldValues={item.latestStatusRecord.formFieldValues} />
        </div>
    </> : <>
        {hasNoDetailsMsg &&
            <div style={{ width: '100%', marginTop: '1rem' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>
                    No details exist
                </p>
            </div>}
    </>}
</>)

export function ActivityStatusDetailsDialog({ item, open, onClose, onReplicate }) {
    const canReplicate = true;

    const onReplicateBtn = () => {
        onReplicate();
    }

    return (
        <ComposedModal key="set-activity-status-dialog" open={open} onClose={onClose}>
            <ModalHeader label="Activity" title="Status Details" />
            <ModalBody style={{ paddingRight: '1rem', outline: 'none' }} hasScrollingContent>
                <ActivityStatusDetailsContent item={item} />

                <div style={{ height: 40 }} />
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Close
                </Button>
                {canReplicate && <Button onClick={onReplicateBtn}>
                    Replicate
                </Button>}
            </ModalFooter>
        </ComposedModal>
    )
}