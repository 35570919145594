
import { Close16, ArrowRight16 } from '@carbon/icons-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ArrayInput } from '../../../../../components/array-input'
import Button from '../../../../../components/Button'
import Util from '../../../../../util/Util'
import { Link } from 'react-router-dom'
import UIUtil from '../../../../../util/UIUtil'
import print from 'print-js';
import { pdf } from '@react-pdf/renderer'
import { createLoadingList } from '../pdf/loading-list'
import { InlineLoading } from 'carbon-components-react'
import { getAccount } from '../../../../../session/SessionManager'

function ChargeInput({ record, updateRecord, clearRecordIfNeeded, canDelete, services, serviceList, showMineOnly, clearRecord }) {
    const inputRef1 = useRef();
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();

    const checkBlur = () => setTimeout(() => {
        if (document.activeElement != inputRef1.current &&
            document.activeElement != inputRef2.current &&
            document.activeElement != inputRef3.current &&
            document.activeElement != inputRef4.current) {
            clearRecordIfNeeded(record)
        }
    }, 10)

    useEffect(() => {
        const hasAmount = (typeof record.amount === "number" && record.amount !== 0) || Util.isANumber(record.amount)
        if (services && serviceList && !hasAmount) {
            const foundService = serviceList.find(service => service.name === record.description);
            if (foundService) {
                updateRecord({ ...record, amount: foundService.amount, serviceId: foundService.id, __amount_from_system: true })
            } else if (record.__amount_from_system) {
                updateRecord({ ...record, amount: "", __amount_from_system: false })
            }
        }
    }, [record.description, services, serviceList])

    const userId = useMemo(() => getAccount().id, [record])

    if (showMineOnly && record.byUserId != userId && Util.isNumberExist(record.id)) {
        return null;
    }

    return (<>
        {Util.isNumberExist(record.byUserId) && record.byUserId != userId && <>
            <div style={{ height: '0.5rem' }} />
            <p style={{ fontSize: 12, opacity: 0.65, paddingInline: '1rem', fontWeight: 'bold', marginBottom: '-0.75rem' }}>Recorded by {record.byUserFullName}</p>
        </>}

        <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000020' }}>
            {services ? (<>
                <input list='shipment-service-charge-input' ref={inputRef1} value={record.description} onChange={e => updateRecord({ ...record, description: e.target.value })} placeholder='Description' style={{ flex: 3, minWidth: 0, height: '100%', border: 'none', background: 'none', padding: '1rem', outline: 'none' }} onBlur={checkBlur} />
                <datalist id='shipment-service-charge-input'>
                    {serviceList.map(service => <option key={service.id} label={`AED ${service.amount}`} value={service.name} />)}
                </datalist>
            </>) : (
                <input ref={inputRef1} value={record.description} onChange={e => updateRecord({ ...record, description: e.target.value })} placeholder='Description' style={{ flex: 3, height: '100%', minWidth: 0, border: 'none', background: 'none', padding: '1rem', outline: 'none' }} onBlur={checkBlur} />
            )}
            {/* <p style={{ fontSize: 12, opacity: 0.65, fontWeight: 'bold', }}>AED</p> */}
            <input ref={inputRef2} value={record.amount} onChange={e => updateRecord({ ...record, amount: e.target.value, __amount_from_system: false })} placeholder='Amount' style={{ flex: 1, height: '100%', minWidth: 0, border: 'none', background: 'none', paddingBlock: '1rem', paddingLeft: '0.25rem', paddingRight: '0.25rem', outline: 'none' }} onBlur={checkBlur} />
            <input ref={inputRef4} value={record.qty ?? 1} onChange={e => updateRecord({ ...record, qty: e.target.value })} placeholder='Qty' style={{ flex: 1, height: '100%', minWidth: 0, border: 'none', background: 'none', paddingBlock: '1rem', paddingLeft: '0.25rem', paddingRight: '0.25rem', outline: 'none' }} onBlur={checkBlur} />
            <div style={{ flex: 1 }}>
                <select value={record.taxRateOverride ?? 0.05} onChange={e => updateRecord({ ...record, taxRateOverride: e.target.value })} ref={inputRef3} style={{ height: '100%', border: 'none', background: 'none', outline: 'none' }} onBlur={checkBlur}>
                    <option value={0.05}>5%</option>
                    <option value={0}>0%</option>
                </select>
            </div>
            <Button
                style={!canDelete ? {
                    opacity: 0, pointerEvents: 'none'
                } : {}}
                tooltipPosition="left" tooltipAlignment="end"
                onClick={() => clearRecord(record)} renderIcon={Close16} kind="ghost" hasIconOnly iconDescription="Remove" />
        </div>
    </>)
}

function ChargeInputList({ readonly, label, value, setValue, services, serviceList, showMineOnly }) {
    const records = useMemo(() => {
        let result;

        const lastValue = value.at ? value.at(-1) : value[value.length - 1];
        if (!readonly && (!lastValue || (Util.isStringExists(lastValue.description) || Util.isStringExists(lastValue.amount)))) {
            result = [...value, { tempId: Util.newTempId(), description: "", amount: "", }]
        } else {
            result = value;
        }

        // if (showMineOnly) {
        //     const userId = getAccount().id;
        //     result = result.filter($ => $.byUserId == userId || !Util.isNumberExist($.id));
        // }

        return result;
    }, [value])

    const updateRecord = (update) => {
        if (readonly) {
            return;
        }

        setValue(records.map(record => (record.id ?? record.tempId) === (update.id ?? update.tempId) ? update : record))
    }

    const clearRecordIfNeeded = update => {
        if (readonly) {
            return;
        }

        if (!Util.isStringExists(update.description) && !Util.isStringExists(update.amount)) {
            setValue(records.filter(record => (record.id ?? record.tempId) === (update.id ?? update.tempId) ? false : true))
        }
    }

    const clearRecord = update => {
        if (readonly) {
            return;
        }

        setValue(records.filter(record => (record.id ?? record.tempId) === (update.id ?? update.tempId) ? false : true))
    }

    return (<>
        <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', }}>{label}</h4>
        <p style={{ paddingInline: '1rem', width: '100%', textAlign: 'end', paddingBottom: '0.5rem', fontSize: 12, opacity: 0.65 }}>Tax NOT included</p>
        <div style={{
            width: '100%', background: '#1c1c1c', paddingBlock: '0.15rem', color: 'white', paddingLeft: '1rem',
            display: 'flex', alignItems: 'center', paddingRight: 48,
        }}>
            <h6 style={{ flex: 3 }}>Service</h6>
            <h6 style={{ flex: 1 }}>Rate</h6>
            <h6 style={{ flex: 1 }}>Qty</h6>
            <h6 style={{ flex: 1 }}>Tax</h6>
        </div>
        <div style={{ width: '100%', background: '#f4f4f4', marginBottom: '3rem' }}>
            {records.map((record, index) => (
                <ChargeInput key={(record.id ?? record.tempId) + 'charge-input-item'}
                    canDelete={index < (records.length - 1)}
                    {...{ record, updateRecord, clearRecordIfNeeded, services, serviceList, showMineOnly, clearRecord }} />
            ))}
        </div>
    </>)
}

export function Services({ shipment, form, endpoint, showMineOnly }) {
    // const [items, setItems] = useState([])
    return (
        <div style={{ width: '100%', }}>
            <form.CustomField fieldKey="userDefinedCharges" childrenProps={{ label: "", services: true, serviceList: endpoint.services, showMineOnly }}>
                {ChargeInputList}
            </form.CustomField>
        </div>
    )
}