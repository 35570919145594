// grid-column: span 2 / span 2

import { isMobileScreen } from "../../../../App";

export function Card({ rowSpan, colSpan, noPadding, maxHeight, children }) {
    return (
        <div style={{
            // gridColumn: colSpan ? `span ${colSpan} / span ${colSpan}` : undefined,
            // gridRow: rowSpan ? `span ${rowSpan} / span ${rowSpan}` : undefined,
            // height: '100%', 

            width: '100%', borderRadius: isMobileScreen ? 0 : 15,
            background: 'white',
            boxShadow: '6px 2px 16px 0px rgba(136, 165, 191, 0.48) , -6px -2px 16px 0px rgba(255, 255, 255, 0.8) ',
            border: '1px solid #00000040',
            overflow: 'hidden',
            padding: noPadding ? 0 : '1rem',
            maxHeight: maxHeight
        }}>
            {children}
        </div>
    )
}