import { ButtonSet, Form, FormGroup, InlineLoading, Tab, Tabs, TextInput } from "carbon-components-react";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { TABLE_RELATION_TYPE_MANY_TO_MANY } from "../../constants/Constants";
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_ATTRIBUTES } from "../../constants/ObjectTypes";
import TableTemplate from "../../templates/TableTemplate";
import { Save16 } from '@carbon/icons-react'
import Button from "../../components/Button";
import Api from "../../session/Api";
import UIUtil from '../../util/UIUtil'
import Util from "../../util/Util";

const AttributesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,
                    parentType: OBJECT_TYPE_PRODUCT_ATTRIBUTES,
                    childId: item.id,
                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: false,
                    manyToManyShowChild: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Product Attribute Packs",
                subTitle: "Product attribute packs that this product has",
                objectType: OBJECT_TYPE_PRODUCT_ATTRIBUTES,
                pagePath: "/product-attribute-packs/",
                history: history
            })}
        </div>
    )
}


const useAttributesValues = () => {
    const [values, setValues] = useState({});

    const getValue = attributeId => values[attributeId];
    const setValue = (attributeId, value) => setValues(values => ({ ...values, [attributeId]: value }))

    return { values, setValues, getValue, setValue }
}


const Values = ({ productId }) => {
    const { values, setValues, getValue, setValue } = useAttributesValues();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [attributes, setAttributes] = useState([]);
    const attributeGroups = useMemo(() => {
        let groups = {};
        const addToGroup = (groupName, item) => {
            if (!groups[groupName]) {
                groups[groupName] = []
            }
            groups[groupName].push(item);
        }
        attributes.forEach(attribute => addToGroup(attribute.groupName, attribute));

        return Object.entries(groups);
    }, [attributes])

    const changesMade = useMemo(() => {
        for (const attribute of attributes) {
            const value = values[attribute.id];
            if (attribute.value !== value) {
                return true;
            }
        }
        return false;
    }, [attributes, values]);

    useEffect(() => {
        let values = {};
        for (const attribute of attributes) {
            values[attribute.id] = attribute.value
        }
        setValues(values);
    }, [attributes])

    const onSaveBtn = () => {
        setSaving(true);
        let update = [];
        for (const attributeId in values) {
            update.push({
                attributeId,
                value: values[attributeId]
            })
        }
        Api.updateProductAttributeValues(productId, update, response => {
            if (response.status === true) {
                setAttributes(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setSaving(false);
        })
    }

    useEffect(() => {
        setLoading(true)
        Api.getAllProductAttributes(productId, response => {
            if (response.status === true) {
                setAttributes(response.payload);
                setLoading(false)
            } else {
                UIUtil.showError(response.message)
            }
        })
    }, [])

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 45 }}>
                <InlineLoading style={{ width: 'auto' }} />
            </div>
        )
    }

    return (
        <div style={{ padding: '1rem' }}>
            <Form className="item-form">
                {attributeGroups.map(attributeGroup => (
                    <FormGroup key={'ag-' + attributeGroup[0]} legendText={<p style={{ fontWeight: 'bold', color: 'black' }}>{attributeGroup[0]}</p>} hasMargin>
                        {attributeGroup[1].map(attribute => (
                            <div key={attribute.id} style={{ marginBottom: '1rem' }}>
                                <TextInput
                                    disabled={saving}
                                    labelText={attribute.title}
                                    value={getValue(attribute.id)}
                                    onChange={e => setValue(attribute.id, e.target.value)}
                                />
                            </div>
                        ))}
                    </FormGroup>
                ))}

                <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                    <ButtonSet>
                        <Button onClick={onSaveBtn} loading={saving} disabled={loading || !changesMade} style={{ minWidth: 200 }} renderIcon={Save16} size="lg">Save</Button>
                    </ButtonSet>
                </div>
            </Form>
        </div>
    )
}

export default ({ item }) => {
    const [reloadRequest, setReloadRequest] = useState(() => Util.newTempId());
    return (
        <Tabs onSelectionChange={e => setReloadRequest(Util.newTempId())}>
            <Tab label="Values">
                <Values key={reloadRequest} productId={item.id} />
            </Tab>
            <Tab label="Attribute Packs">
                <AttributesList item={item} />
            </Tab>
        </Tabs>
    )
}

export { Values as ProductAttributeValuesView };