import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SHIPMENT_DIR_TYPE } from "../.."
import Api from "../../../../../session/Api"
import Util from "../../../../../util/Util"
import { UserAssignation } from "../../../../activities/components/user-assignation"
import { ShipmentMasterSelector } from "../components/shipment-master-selector"






export function Master({ shipment, form, endpoint }) {
    return (
        <div style={{ width: '100%', paddingInline: '1rem' }}>
            <form.ExtField childrenProps={{ options: endpoint.shipmentMasters }}>
                {ShipmentMasterSelector}
            </form.ExtField>

            <div style={{ height: 300 }} />
        </div>
    )
}