import { useMemo, useState } from "react";
import { Frame } from "../../../frame";
import { useFrameConnection } from "../../../frame/useFrameConnection";
import { useForceLockScroll } from "../../../hooks/useLockScroll";
import UIUtil from "../../../util/UIUtil";
import { CustomizeFoodDialog } from "./dialog/customize-food-dialog";
import { getTerminal } from "../../../session/SessionManager";
import { POS_SESSION_TYPE_ONLINE_STORE } from "../../../constants/Constants";
import { OBJECT_TYPE_FNB_EVENT } from "../../../constants/ObjectTypes";

export function RestProducts({ state, fnbEvent }) {
    const storeId = useMemo(() => getTerminal() ? getTerminal().storeId : -1, []);

    const [customizeItemId, setCustomizeItemId] = useState(undefined);

    const addItem = (itemId, recipeOptions) => state.updateCartItem(itemId, 1, false, undefined, undefined, undefined, { recipeOptions })

    const [frameRef, connection] = useFrameConnection({
        addProduct: ({ itemNo, id, hasCustomizationSupport, customizationSupportOnlyNote }) => {
            if (hasCustomizationSupport && !customizationSupportOnlyNote) {
                setCustomizeItemId(id);
            } else {
                addItem(itemNo);
            }
        }
    });

    useForceLockScroll(true);

    const onlineStore = state.getSession()?.posSessionType === POS_SESSION_TYPE_ONLINE_STORE || state.getSession().fromOnlineStore === true;
    //const path = "/backend/menu/" + ((showOnlineStore || state.getSession()?.restOrderType === "delivery" || state.getSession()?.restOrderType === "take-out") ? 0 : storeId);
    const path =
        onlineStore ? "/backend/menu/0/0"
            : fnbEvent ? `/backend/menu/${fnbEvent.id}/-${OBJECT_TYPE_FNB_EVENT}`
                : "/backend/menu/" + storeId + "/" + state.getSession()?.storeType;
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Frame ref={frameRef} background="#f4f4f4" path={path} />

            <CustomizeFoodDialog
                onDone={(itemId, recipeOptions) => {
                    addItem(itemId, recipeOptions);
                    setCustomizeItemId(undefined);
                }}
                open={customizeItemId !== undefined} onClose={() => setCustomizeItemId(undefined)} itemId={customizeItemId} />
        </div>
    )
}