import { useMemo, useState } from "react";
import { withLoadablePagePropsAndProps, withLoadablePageWithParams } from "../../../base/Page";
import { OBJECT_TYPE_FUND_TRANSFER, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../../constants/ObjectTypes";
import Api from "../../../session/Api";
import { Restaurant32, Wheat16, ArrowRight16, Edit16, Thumbnail_116, ArrowRight32, TrashCan16 } from '@carbon/icons-react'
import { Link, useHistory } from "react-router-dom";
import UIUtil from "../../../util/UIUtil";
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view";
import Button from "../../../components/Button";
import { PackageUnitsList } from "../../product/ProductDetailPage";
import { Tag } from "carbon-components-react";
import { FoodFormDialog, IngredientFormDialog } from "./fields";
import { getReportLink } from "../../../reporting-engine/base/report-page";
import Portal from "../../../util/Portal";
import { SidePanel } from "../../../templates/draft/components/side-panel";
import { RecipeManager } from "./recipe-manager";
import ImageView from "../../../components/ImageView";
import ItemCreatorDialog from "../../../templates/ItemCreatorDialog";
import Util from "../../../util/Util";
import { MenuManager } from "./menu-manager";

function DeleteBtn({ item }) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const onDeleteBtn = () => UIUtil.confirm(() => {
        setLoading(true)
        Api.deleteItem(OBJECT_TYPE_PRODUCT_BUNDLE, item.id, response => {
            if (response.status === true) {
                history.replace(getReportLink("FoodList"))
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    })

    return (
        <Button loading={loading} kind="danger" onClick={onDeleteBtn} size="sm" renderIcon={TrashCan16} style={{ borderRadius: 50 }}>Delete</Button>
    )
}


const ValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16, whiteSpace: "pre-line" }}>{children}</p>
    </div>
)

const Group = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

const View = ({ payload }) => {
    const [item, setItem] = useState(payload.b);
    const [editVisible, setEditVisible] = useState(false);
    const [thumbnailEditVisible, setThumbnailEditVisible] = useState(false);
    const [showRecipeManager, setShowRecipeManager] = useState(false);

    const [thumbnailKey, setThumbnailKey] = useState(() => Util.newTempId());

    const info = useMemo(() => {
        const find = (property, list) => payload.a[list].find($ => $.id === item[property])?.value ?? "-"
        return {
            department: find("departmentId", "departments"),
            category: find("categoryId", "categories"),
            subcategory: find("subcategoryId", "subcategories"),
            classification: find("classificationId", "classifications"),
        }
    }, [item, payload])

    return (
        <div>
            <div style={{ paddingInline: '6rem', paddingTop: '3rem' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem', }}>
                    <ImageView key={thumbnailKey} style={{ width: 150, height: 200, borderRadius: 7, marginRight: '1rem' }} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, item.id)} />
                    <div>
                        <Restaurant32 style={{ width: 64, height: 64 }} />
                        <h3 style={{ fontWeight: 'bold', marginTop: '1rem' }}>{item.name}</h3>
                        <h4 style={{ color: '#0f62fe' }}>Food</h4>
                        {Util.isStringExists(item.refNo) && <Tag type="magenta">{item.refNo}</Tag>}
                        <Tag type="blue">{item._barcode}</Tag>
                    </div>

                </div>

                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '3rem', }}>
                    <div style={{ flex: 1 }} />
                    <DeleteBtn item={item} />
                    <Button onClick={() => setThumbnailEditVisible(true)} size="sm" renderIcon={Thumbnail_116} kind="secondary" style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Thumbnail</Button>
                    <Button onClick={() => setEditVisible(true)} size="sm" renderIcon={Edit16} kind="secondary" style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Edit</Button>
                    <Button onClick={() => setShowRecipeManager(true)} size="sm" renderIcon={Wheat16} style={{ marginLeft: '0.5rem', borderRadius: 50 }}>Recipe Manager</Button>
                </div>

                {Util.isStringExists(item.description) &&
                    <ValueField style={{}} title="Description">{item.description}</ValueField>}


                <Group title="Details">
                    <div style={{ display: 'flex' }}>
                        <ValueField style={{ flex: 1 }} title="Estimated Daily Portions Stock Minimum">{item.minQuantity?.toFixed?.(2) ?? 0}x</ValueField>
                        <ValueField style={{ flex: 1 }} title="Department">{info.department}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Category">{info.category}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Subcategory">{info.subcategory}</ValueField>
                        <ValueField style={{ flex: 1 }} title="Classification">{info.classification}</ValueField>
                    </div>
                </Group>

                <Group title="Menu Manager">
                    <MenuManager item={item} />
                </Group>


                <div style={{ marginTop: '6rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                    <AdvancedNotesView title="Attachments" objectId={item.id} objectType={OBJECT_TYPE_PRODUCT_BUNDLE} />
                </div>

                <div style={{ height: '3rem' }} />

            </div>

            <FoodFormDialog title="Updating" endpoint={payload.a} item={item} onClose={() => setEditVisible(false)} visible={editVisible} onSuccess={setItem} />

            <Portal>
                {showRecipeManager && <SidePanel onClose={() => { }}>
                    <RecipeManager foodId={item.id} onClose={() => setShowRecipeManager(false)} />
                </SidePanel>}

                <ItemCreatorDialog
                    fullSized
                    dialogLabel="Food"
                    dialogTitle="Thumbnail"
                    // size="lg"
                    filterProperties={[]}
                    noDelete
                    itemId={item.id}
                    objectType={OBJECT_TYPE_PRODUCT_BUNDLE}
                    open={thumbnailEditVisible} onClose={() => setThumbnailEditVisible(false)}
                    onPreSave={update => update.parentId = item.id}
                    onCreatedItem={createdItem => {
                        UIUtil.showSuccess();
                        setThumbnailKey(Util.newTempId());
                        setThumbnailEditVisible(false);
                    }}
                    onSave={savedItem => {
                        UIUtil.showSuccess();
                        setThumbnailKey(Util.newTempId());
                        setThumbnailEditVisible(false);
                    }}
                />
            </Portal>
        </div>
    )
}

export const FoodDetailPage = withLoadablePageWithParams(params => listener => Api.getFood(params.itemId, listener), View)
export const FoodDetailComponent = withLoadablePagePropsAndProps(props => listener => Api.getFood(props.foodId, listener), View);