

import { STOCK_REQUEST_STATUS_APPROVED, STOCK_REQUEST_STATUS_PENDING_APPROVAL, STOCK_REQUEST_STATUS_REJECTED, STOCK_REQUEST_STATUS_REVOKED_BY_SENDER } from "../constants/Constants";
import { CheckmarkFilled16, ErrorFilled16, WarningFilled16, Money16, Misuse16, Error16, Search16, Time16, Renew16, DeliveryParcel16, Delivery16, WatsonHealthStackedScrolling_116 } from '@carbon/icons-react'
import { Tag } from "carbon-components-react"

export const INVOICE_DOCUMENT_STATUS_NOT_PAID = 0;
export const INVOICE_DOCUMENT_STATUS_PAID = 1;
export const INVOICE_DOCUMENT_STATUS_REVOKED = 2;

export function getInvoiceDocumentStatusName(status) {
    switch (status) {
        case INVOICE_DOCUMENT_STATUS_NOT_PAID:
            return "Not Paid";
        case INVOICE_DOCUMENT_STATUS_PAID:
            return "Paid";
        case INVOICE_DOCUMENT_STATUS_REVOKED:
            return "Revoked";
    }
}

export function getInvoiceDocumentStatusDetails(status) {
    switch (status) {
        case INVOICE_DOCUMENT_STATUS_NOT_PAID:
            return { type: "high-contrast", text: "Not Paid", icon: WarningFilled16 };
        case INVOICE_DOCUMENT_STATUS_PAID:
            return { type: "green", text: "Paid", icon: CheckmarkFilled16 };
        case INVOICE_DOCUMENT_STATUS_REVOKED:
            return { type: "red", text: "Revoked", icon: ErrorFilled16 };
        // case STOCK_REQUEST_STATUS_REVOKED_BY_SENDER:
        //     return { type: "purple", text: "Revoked by Sender", icon: ErrorFilled16 };
    }
}

export const InvoiceDocumentStatusIndicator = ({ status }) => {
    let { icon, text, type } = getInvoiceDocumentStatusDetails(status);
    return (
        <div>
            <Tag size="md" renderIcon={icon} type={type}>
                {text}
            </Tag>
        </div>
    )
}