import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

import { Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, DataTableSkeleton, Tag, ButtonSet, TableSelectAll, TableSelectRow, ExpandableSearch } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import { Add16, Error16, Identification16, Receipt16, Save16, ArrowRight16, ArrowLeft16, Calendar16, QueryQueue16, SubtractAlt16, AddFilled16, Currency16 } from '@carbon/icons-react'
import Util from '../../util/Util';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT } from '../../constants/ObjectTypes';
import Button from '../../components/Button';
import PaginatedDataTable from '../../components/data-table/PaginatedDataTable';

const HEADERS = [
    {
        header: "Staff",
        key: "cashBorrowerName"
    },
    {
        header: "Amount Returned",
        key: "amountReturned"
    },
    {
        header: "Date",
        key: "initiationDate"
    }
]

class CRVListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            rows: [],

            idFilter: "",
            voucherNoFilter: ""
        }
    }

    getTitle() {
        return "Cash Receipt Vouchers"
    }

    getSubtitle() {
        return "All cash receipt vouchers";
    }

    getNewBtnTitle() {
        return "Create CRV";
    }

    getNewPath() {
        return "/crv-creator";
    }

    getPath() {
        return "/crv/";
    }

    componentDidMount() {
        // this.setState({ loading: true })
        // const listener = response => {
        //     if (response.status === true && response.payload !== null) {
        //         this.setState({ loading: false, rows: response.payload })
        //     } else {
        //         this.setState({ loading: false, rows: [] })
        //     }

        //     if (response.payload === null) {
        //         UIUtil.showError(response.message);
        //     }
        // }

        // Api.getAllCrv(listener)
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.rows) {
            if (row.id == id) {
                return row;
            }
        }
    }

    actuallyRenderCell(key, value, row) {
        switch (key) {
            case "cashBorrowerName":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProfilePic size={34} src={Api.getThumbnail(row.cashBorrowerType, row.cashBorrowerId)} />
                        <p style={{ marginLeft: '0.5rem' }}>{value}</p>
                    </div>
                )


            case "amountReturned":
                return <> AED {parseFloat(value).toFixed(2)} </>

            case "initiationDate":
                return <> <Calendar16 /> {Util.getDateOnly(value)} </>

            default:
                return value;
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        return this.actuallyRenderCell(header.key, cell.value, row)
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ width: '100%' }}>
                    <DataTableSkeleton />
                </div>
            )
        }

        return (
            <div className='tooltip-supported-toolbar' style={{ width: '100%' }}>
                <PaginatedDataTable
                    reloadRequest={this.state.idFilter + ':fi-all-transactions-list-fr:' + this.state.voucherNoFilter + "--type-filter" + this.state.typeFilter}
                    title={this.getTitle()} description={this.getSubtitle()}
                    loader={(page, sortRequest, listener) => Api.getAllCrv(page, {
                        // filterId: (() => {
                        //     if (Util.isStringExists(this.state.voucherNoFilter)) {
                        //         const id = Util.getIdFromVoucherNumber(parseInt(this.state.voucherNoFilter));
                        //         return Math.max(id, 0);
                        //     } else {
                        //         return -1;
                        //     }
                        // })(),
                        // filterType: this.state.typeFilter
                    }, listener)}
                    loadResponseHandler={response => ({ loadedItems: response.payload.crvs, loadedHasMore: response.payload.paginationHasMore })}
                    columns={HEADERS.map(header => ({
                        id: header.key,
                        name: header.header,
                        render: item => (
                            <div style={{ minWidth: header.minWidth }}>
                                {this.actuallyRenderCell(header.key, item[header.key], item)}
                            </div>
                        )
                    }))}
                    onClick={row => {
                        // this.setState({ selectedStockFlowId: row.id })
                        // this.props.history.push()
                        window.open(this.getPath() + row.id)
                    }}
                >
                    {!this.props.hideToolbar && <TableToolbar>
                        <TableToolbarContent>



                            <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                <Button style={{ minWidth: 250 }} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
                            </Link>
                        </TableToolbarContent>
                    </TableToolbar>}
                </PaginatedDataTable>

                {/* {this.renderSideView()} */}
            </div>
        )

        // return (
        //     <div style={{ width: '100%' }}>
        //         <DataTable rows={Util.isStringExists(this.state.idFilter) ? this.state.rows.filter(row => row.crvId == this.state.idFilter) : (Util.isStringExists(this.state.voucherNoFilter) ? this.state.rows.filter(row => row.id == Util.getIdFromVoucherNumber(this.state.voucherNoFilter)) : this.state.rows)} headers={HEADERS} isSortable>
        //             {({
        //                 rows,
        //                 headers,
        //                 getHeaderProps,
        //                 getRowProps,
        //                 getTableProps,
        //                 onInputChange,
        //                 getSelectionProps,
        //                 selectedRows
        //             }) => (
        //                 <TableContainer title={this.getTitle()} description={this.getSubtitle()}>
        //                     {!this.props.hideToolbar && <TableToolbar>
        //                         <TableToolbarContent>
        //                             <TableToolbarSearch placeholder={"Search"} onChange={onInputChange} />

        //                             <ExpandableSearch
        //                                 style={{ minWidth: 48 }}
        //                                 renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
        //                                     <Receipt16 />
        //                                 </div>}
        //                                 placeholder={"Find by Voucher No"} onChange={e => this.setState({ voucherNoFilter: e.target.value })} value={this.state.voucherNoFilter} />

        //                             <ExpandableSearch
        //                                 style={{ minWidth: 48 }}
        //                                 renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
        //                                     <Identification16 />
        //                                 </div>}
        //                                 placeholder={"Find by ID"} onChange={e => this.setState({ idFilter: e.target.value })} value={this.state.idFilter} />

        //                             <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
        //                                 <Button style={{ minWidth: 250 }} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
        //                             </Link>
        //                         </TableToolbarContent>
        //                     </TableToolbar>}
        //                     <Table {...getTableProps()}>
        //                         <TableHead>
        //                             <TableRow>
        //                                 {headers.map((header) => (
        //                                     <TableHeader key={header.key} {...getHeaderProps({ header })}>
        //                                         {header.header}
        //                                     </TableHeader>
        //                                 ))}
        //                             </TableRow>
        //                         </TableHead>
        //                         <TableBody>
        //                             {rows.map((row) => (
        //                                 <TableRow onClick={(() => this.props.history.push(this.getPath() + row.id))} key={row.id} {...getRowProps({ row })}>
        //                                     {row.cells.map((cell) => (
        //                                         <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
        //                                     ))}
        //                                 </TableRow>
        //                             ))}
        //                         </TableBody>
        //                     </Table>
        //                     {<TableToolbar>
        //                         {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
        //                     </TableToolbar>}
        //                 </TableContainer>
        //             )}
        //         </DataTable>
        //     </div>
        // )
    }

}

export default withRouter(CRVListView);