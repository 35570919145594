import { useState, useMemo, useEffect } from "react";
import "./rest-tables.scss"
import { Resizable } from "re-resizable";

import {
    useDraggable,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    createSnapModifier,
} from '@dnd-kit/modifiers';
import { InlineLoading, Tag } from "carbon-components-react";
import { Events16 } from '@carbon/icons-react'
import { COMPONENT_STATUS, COMPONENT_TYPE } from ".";
import { useRefSize } from "../../../../../util/useSize";
import UIUtil from "../../../../../util/UIUtil";
import ProfilePic from "../../../../../components/ProfilePic";
import Api from "../../../../../session/Api";
import { getTerminal } from "../../../../../session/SessionManager";


function checkIntersection(objA, objB) {
    // Calculate the coordinates of the edges for each object
    const objALeft = objA.x;
    const objARight = objA.x + objA.width;
    const objATop = objA.y;
    const objABottom = objA.y + objA.height;

    const objBLeft = objB.x;
    const objBRight = objB.x + objB.width;
    const objBTop = objB.y;
    const objBBottom = objB.y + objB.height;

    // Check for intersection
    if (
        objALeft < objBRight &&
        objARight > objBLeft &&
        objATop < objBBottom &&
        objABottom > objBTop
    ) {
        return true; // Intersection detected
    }

    return false; // No intersection
}

function checkOverflow(obj, layoutWidth, layoutHeight) {
    const objRight = obj.x + obj.width;
    const objBottom = obj.y + obj.height;

    if (obj.x < 0 || obj.y < 0 || objRight > layoutWidth || objBottom > layoutHeight) {
        return true; // Overflow detected
    }

    return false; // No overflow
}

function areRectanglesTouching(rectangleA, rectangleB) {
    // Extract rectangle A properties
    const { x: x1, y: y1, width: w1, height: h1 } = rectangleA;

    // Extract rectangle B properties
    const { x: x2, y: y2, width: w2, height: h2 } = rectangleB;

    // Check if any of the conditions are true
    if (x1 + w1 >= x2 && x1 <= x2 + w2 && y1 + h1 >= y2 && y1 <= y2 + h2) {
        return true; // Rectangles are touching
    } else {
        return false; // Rectangles are not touching
    }
}
function findTouchingComponents(components) {
    const touchingArrays = [];

    for (let i = 0; i < components.length; i++) {
        let isTouchingExisting = false;
        const touchingComponents = [components[i]]; // Start with the current component

        for (let j = 0; j < touchingArrays.length; j++) {
            const touchingGroup = touchingArrays[j];
            for (let k = 0; k < touchingGroup.length; k++) {
                const touchingComponent = touchingGroup[k];
                if (areRectanglesTouching(components[i], touchingComponent)) {
                    isTouchingExisting = true;
                    touchingGroup.push(components[i]);
                    break;
                }
            }
            if (isTouchingExisting) {
                break;
            }
        }

        if (!isTouchingExisting) {
            touchingArrays.push(touchingComponents);
        }
    }

    return touchingArrays;
}
function constructBoundingRectangle(rectangles) {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < rectangles.length; i++) {
        const rectangle = rectangles[i];
        minX = Math.min(minX, rectangle.x);
        maxX = Math.max(maxX, rectangle.x + rectangle.width);
        minY = Math.min(minY, rectangle.y);
        maxY = Math.max(maxY, rectangle.y + rectangle.height);
    }

    const width = maxX - minX;
    const height = maxY - minY;

    const boundingRectangle = {
        x: minX,
        y: minY,
        width: width,
        height: height,
    };

    return boundingRectangle;
}



function handleInitialSize(initialSize, initialComponents) {
    const maxX = handleInitialComponents(initialSize, initialComponents).map($ => $.x).sort().reverse()[0]
    const maxY = handleInitialComponents(initialSize, initialComponents).map($ => $.y).sort().reverse()[0]

    return {
        width: Math.max(initialSize.width, maxX + 3),
        height: Math.max(initialSize.height, maxY + 2),
    }
}


function handleInitialComponents(initialSize, initialComponents) {
    let x = 1;
    return initialComponents.map(($, i) => ($.width === 0 || $.height === 0) ? ({
        ...$, width: 1, height: 1,
        x: x++, y: 1,
    }) : $);
}




const Content = ({ children, rootBg }) => {
    const [parentRef, parentSize] = useRefSize()

    return (
        <div ref={parentRef} className="table-designer-root table-designer-root-grid" style={{ width: '100%', height: '100%', backgroundColor: rootBg }}>
            {children(parentSize)}
        </div>
    )
}

const Canvas = ({ parentSize, size, onCanvasResized, children }) => {
    return (
        <div style={{ minWidth: ((parentSize.width / 2) + (size.width * 50)), minHeight: ((parentSize.height / 4) + (size.height * 50)) }}>
            <Resizable
                className="table-designer-canvas" minWidth={50} minHeight={50}
                size={{ width: size.width * 50, height: size.height * 50 }} grid={[50, 50]}
                onResizeStop={(_, __, ___, sizeDelta) => {
                    const newSize = { width: size.width + (sizeDelta.width / 50), height: size.height + (sizeDelta.height / 50) };
                    onCanvasResized(newSize);
                }}
                style={{
                    position: 'absolute',

                    // left: Math.max((parentSize.width / 2) - ((size.width * 50) / 2), 25),
                    // top: Math.max((parentSize.height / 2) - ((size.height * 50) / 2), 25),
                    // left: parentSize.width / 4,
                    // top: parentSize.height / 8,
                    left: 25,
                    top: 25,

                    transformOrigin: 'top left',

                    marginRight: '6rem'
                }}
            >

                {children}
            </Resizable>
        </div>
    )
}

const Context = ({ children, onComponentMoved }) => {
    const mouseSensor = useSensor(MouseSensor);
    const touchSensor = useSensor(TouchSensor);
    const keyboardSensor = useSensor(KeyboardSensor);

    const sensors = useSensors(
        mouseSensor,
        touchSensor,
        keyboardSensor,
    );

    const snapToGrid = useMemo(() => createSnapModifier(50), []);

    return (
        <DndContext sensors={sensors} modifiers={[snapToGrid]} onDragEnd={e => {
            onComponentMoved(e.active.id, e.delta)
        }}>
            {children}
        </DndContext>
    )
}

const Component = ({ component: { id, type, x, y, width, height, ...component }, onComponentResized, group, selectMode }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: id,
    });

    const isMain = group?.[0]?.id === id;
    const canResize = true;

    const background = () => {
        if (type === COMPONENT_TYPE.TABLE) {
            let status;
            if (isMain) {
                status = component.status
            } else {
                status = group?.[0]?.status;
            }
            switch (status) {
                case COMPONENT_STATUS.AVAILABLE: return "#008800";
                case COMPONENT_STATUS.RESERVED: return "#a200c9";
                case COMPONENT_STATUS.OCCUPIED: return "#000000";
            }
        } else if (type === COMPONENT_TYPE.ZONE) {
            return component.color + "50";
        } else if (type === COMPONENT_TYPE.SECTION) {
            return "#000000BB";
        }

        return "black"
    }

    return (
        <Resizable grid={[50, 50]}
            size={{ width: 50 * width, height: 50 * height }}
            style={{
                left: 50 * x, top: 50 * y, position: 'absolute',
                transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
                zIndex:
                    type === COMPONENT_TYPE.TABLE ? 4 :
                        type === COMPONENT_TYPE.ZONE ? 2 :
                            type === COMPONENT_TYPE.SECTION ? 1 :
                                undefined,

                pointerEvents: selectMode ? 'none' : undefined
            }}
            onResizeStop={(_, __, ___, sizeDelta) => {
                onComponentResized(id, sizeDelta);
            }}

            maxWidth={!canResize ? width * 50 : undefined}
            maxHeight={!canResize ? height * 50 : undefined}
            minWidth={!canResize ? width * 50 : 50}
            minHeight={!canResize ? height * 50 : 50}
        >
            <div
                ref={setNodeRef} {...listeners} {...attributes}
                style={{
                    background: background(),
                    position: 'absolute',
                    width: '100%', height: '100%',
                    border: transform ? '1px solid #ffffff' : undefined,

                    display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                }}
            >
                {type === COMPONENT_TYPE.TABLE && <>
                    <h4 style={{ color: 'white', opacity: 0.65, fontWeight: isMain ? "bold" : undefined }}>{component.name}</h4>
                    <p style={{ color: 'white', opacity: 0.65, fontSize: 12 }}>Table</p>
                </>}

                {type === COMPONENT_TYPE.ZONE && <>
                    <h4 style={{ color: 'white', opacity: 0.65, }}>{component.name}</h4>
                    <p style={{ color: 'white', opacity: 0.65, fontSize: 12 }}>Zone</p>
                </>}

                {type === COMPONENT_TYPE.SECTION && <div style={{ width: '100%', height: '100%', padding: '1rem' }}>
                    <p style={{ color: 'white', opacity: 0.65, fontSize: 12 }}><strong>Section</strong> {component.name}</p>
                </div>}
            </div>
        </Resizable>
    )
}

const Title = ({ title, subtitle }) => {
    return (
        <div style={{ position: 'fixed', left: '1rem', bottom: '3rem', opacity: 0.65, userSelect: 'none', WebkitUserSelect: 'none', }}>
            <p style={{ fontSize: 16, color: 'white', opacity: 0.65 }}>{title}</p>
            <h2 style={{ fontWeight: 'bold', color: 'white' }}>{subtitle}</h2>
        </div>
    )
}

const ZoneKey = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ProfilePic size={24} />
            <Tag style={{ background: "#990000", color: 'white', paddingLeft: '1rem', marginLeft: -10, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                <strong>Alex Wales</strong> Zone A
            </Tag>
        </div>
    )
}

const Toolbar = () => {
    return (
        <div style={{ paddingBlock: '0.5rem', background: '#f4f4f4', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem', flexWrap: 'wrap' }}>
            <Tag type="green">Available</Tag>
            <Tag type="high-contrast">Occupied</Tag>
            <Tag type="purple">Reserved</Tag>

            {/* <div style={{ height: '1rem', width: 1, background: "#00000020", marginInline: '1rem' }} />

            <ZoneKey />
            <ZoneKey />
            <ZoneKey />
            <ZoneKey />
            <ZoneKey />
            <ZoneKey />
            <ZoneKey />
            <ZoneKey />

            <div style={{ height: '1rem', width: 1, background: "#00000020", marginInline: '1rem' }} />

            <Tag type="high-contrast" renderIcon={Events16}>23/23</Tag>
            <Tag renderIcon={Events16}><strong>Section A</strong> 4/5</Tag> */}
        </div>
    )
}

function View({ initialSize, initialComponents, showKey, title, subtitle, selectMode, onSelect, rootBg }) {
    // const [size, setSize] = useState(() => ({ width: 10, height: 10 }))
    // const [components, setComponents] = useState(() => ([
    //     { id: 324, type: COMPONENT_TYPE.ZONE, x: 1, y: 1, width: 2, height: 2 },
    //     { id: 325, type: COMPONENT_TYPE.ZONE, x: 3, y: 2, width: 4, height: 2 },

    //     { id: 3325, type: COMPONENT_TYPE.TABLE, x: 3, y: 2, width: 1, height: 1, name: "1", status: COMPONENT_STATUS.AVAILABLE, capacity: 4, filled: 4 },
    //     { id: 355, type: COMPONENT_TYPE.TABLE, x: 4, y: 4, width: 2, height: 2, name: "2", status: COMPONENT_STATUS.AVAILABLE, capacity: 7, filled: 7 },
    //     { id: 3655, type: COMPONENT_TYPE.TABLE, x: 6, y: 6, width: 2, height: 2, name: "3", status: COMPONENT_STATUS.AVAILABLE, capacity: 10, filled: 0 }
    // ]))
    const [size, setSize] = useState(() => handleInitialSize(initialSize, initialComponents))
    const [components, setComponents] = useState(() => handleInitialComponents(initialSize, initialComponents))
    useEffect(() => {
        Api.saveRestaurantComponents(getTerminal().storeId, { size, components }, response => {
            if (response.status === false) {
                UIUtil.showError("Failed to save changes")
            }
        })
    }, [components, size])



    const tableGroups = useMemo(() => findTouchingComponents(components.filter($ => $.type === COMPONENT_TYPE.TABLE)), [components])
    const tableGroupRects = useMemo(() => tableGroups.map(group => ({
        ...constructBoundingRectangle(group),
        mainComponent: group[0],
        status: group[0]?.status,
        capacity: group.map($ => $.capacity).reduce((t, v) => t + v, 0),
        filled: group.map($ => $.filled).reduce((t, v) => t + v, 0)
    })), [tableGroups])
    const getComponentGroup = id => tableGroups.find(group => group.find($ => id === $.id))

    const validate = (size, components, prevSize, prevComponents) => {
        const getErr = () => {
            for (let i = 0; i < components.length; i++) {
                for (let j = i + 1; j < components.length; j++) {
                    const [a, b] = [components[i], components[j]]
                    if (a.type === b.type && checkIntersection(a, b)) {
                        return "Components can not intersect!";
                    }
                }
            }

            for (const component of components) {
                if (checkOverflow(component, size.width, size.height)) {
                    return "Components can not exceed the bounds!"
                }
            }


            const prevMainTables = findTouchingComponents(prevComponents.filter($ => $.type === COMPONENT_TYPE.TABLE)).map($ => $[0]);
            const newMainTables = findTouchingComponents(components.filter($ => $.type === COMPONENT_TYPE.TABLE)).map($ => $[0]);

            for (const table of prevMainTables) {
                const isInNew = newMainTables.find($ => $.id === table.id);
                if (!isInNew) {
                    //is being removed
                    if (table.status !== COMPONENT_STATUS.AVAILABLE) {
                        return "Table is not available!"
                    }
                }
            }

        }

        const err = getErr();
        if (err) {
            UIUtil.showError(err);
            return false;
        } else {
            return true;
        }
    }
    const updateComponent = (id, update) => setComponents(prev => {
        const newComponents = prev.map($ => $.id === id ? ({
            ...$, ...update($)
        }) : $);
        return validate(size, newComponents, size, prev) ? newComponents : prev;
    });

    const onCanvasResized = (newSize) => {
        if (validate(newSize, components, size, components)) {
            setSize(newSize)
        }
    }
    const onComponentMoved = (componentId, moveDelta) => {
        updateComponent(componentId, component => ({
            x: component.x + (moveDelta.x / 50),
            y: component.y + (moveDelta.y / 50)
        }))
    }
    const onComponentResized = (componentId, resizeDelta) => {
        updateComponent(componentId, component => ({
            width: component.width + (resizeDelta.width / 50),
            height: component.height + (resizeDelta.height / 50)
        }))
    }

    return (
        <div className="light-minimal-scroll-bar" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {showKey && <Toolbar />}
            <div style={{ width: '100%', minHeight: 0, flex: 1 }}>
                <Context onComponentMoved={onComponentMoved}>
                    <Content rootBg={rootBg}>
                        {parentSize => (<>
                            <Title title={title} subtitle={subtitle} />
                            <Canvas parentSize={parentSize} size={size} onCanvasResized={onCanvasResized}>
                                {components.map(component => (
                                    <Component key={component.id} component={component} onComponentResized={onComponentResized} group={getComponentGroup(component.id)} selectMode={selectMode} />
                                ))}
                                {tableGroupRects.map(rect => (<>
                                    <div onClick={() => {
                                        if (selectMode && rect.status === COMPONENT_STATUS.AVAILABLE) {
                                            onSelect(rect.mainComponent)
                                        }
                                    }} style={{ boxShadow: '0px 0px 48px 0px #0f62fe50', outline: '2px solid #0f62fe75', zIndex: 2, background: '#080808', position: 'absolute', pointerEvents: (selectMode && rect.status === COMPONENT_STATUS.AVAILABLE) ? undefined : 'none', cursor: (selectMode && rect.status === COMPONENT_STATUS.AVAILABLE) ? "pointer" : undefined, left: rect.x * 50, top: rect.y * 50, width: rect.width * 50, height: rect.height * 50 }} />
                                    <Tag size="sm" type={rect.filled < rect.capacity ? "green" : rect.filled == rect.capacity ? "red" : "cool-gray"}
                                        style={{ zIndex: 7, position: 'absolute', pointerEvents: 'none', left: (rect.x * 50) + (rect.width * 50), top: rect.y * 50, transform: 'translate(-50%, -50%)', }}>{rect.filled}/{rect.capacity}</Tag>
                                </>))}

                                {tableGroups.map(group => group.map((table, index) => index > 0 ? (<>
                                    <div style={{ zIndex: 6, background: '#000000BB', position: 'absolute', pointerEvents: 'none', left: table.x * 50, top: table.y * 50, width: table.width * 50, height: table.height * 50 }} />
                                </>) : null))}
                            </Canvas>
                        </>)}
                    </Content>
                </Context>
            </div>
        </div>
    )
}

export function RestTablesView({ showKey, title, subtitle, selectMode, onSelect, rootBg = "#1c1c1c" }) {
    const [restComponents, setRestComponents] = useState(undefined);
    useEffect(() => {
        Api.getRestaurantComponents(getTerminal().storeId, response => {
            if (response.status === true) {
                setRestComponents(response.payload);
            } else {
                UIUtil.showError("Failed to initialize")
            }
        })
    }, [])

    return restComponents ? <View {...{
        showKey, title, subtitle, selectMode, onSelect,
        initialSize: restComponents.size,
        initialComponents: restComponents.components,
        rootBg
    }} /> : (
        <Content rootBg={rootBg}>
            {_ => (<>
                <Title title={title} subtitle={subtitle} />
                <div className="really_centered-progress-bar" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <InlineLoading style={{ width: "unset" }} />
                </div>
            </>)}
        </Content>
    )
}