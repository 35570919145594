import React from 'react'
import NoProfilePic from '../images/NoProfilePic.png';
import ImageView from './ImageView';


const ProfilePic = ({ style, size, src, notProfile, tip, tipEffect }) => {
    return (
        <ImageView data-tip={tip} data-effect={tipEffect} src={src} profilePlaceholder={!notProfile} style={{ borderRadius: '50%', width: size, height: size, objectFit: 'cover', objectPosition: 'center', ...style }} />
    )
}

export default ProfilePic;