import React from 'react'
import { Link } from 'react-router-dom';
import { COMPANY_NAME } from '../../app/Config';
import Util from '../../util/Util';
import { AccountTreeItem } from './acct-statements';

// const Item = ({ item, debit }) => (
//     <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2rem' }}>
//         {/* <p style={{ flex: 2 }}>{item.name}</p> */}
//         <Link
//             // onClick={() => alert('co')}
//             target="_blank" to={"/general-ledger-report/" + item.id} style={{ flex: 2 }}>{item.name}</Link>
//         <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//             {debit && <p style={{}}>AED {item.balance.toFixed(2)}</p>}
//         </div>
//         <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//             {!debit && <p style={{}}>AED {item.balance.toFixed(2)}</p>}
//         </div>
//     </div>
// )

// const ItemDebit = ({ item }) => {
//     const debit = true;
//     return (
//         <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '2rem' }}>
//             {/* <p style={{ flex: 2 }}>{item.name}</p> */}
//             <Link
//                 // onClick={() => alert('co')}
//                 target="_blank" to={"/general-ledger-report/" + item.id} style={{ flex: 2 }}>{item.name}</Link>
//             <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//                 {debit && <p style={{}}>AED {item.balance.toFixed(2)}</p>}
//             </div>
//             <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//                 {!debit && <p style={{}}>AED {item.balance.toFixed(2)}</p>}
//             </div>
//         </div>
//     )

// }
class TrialBalance extends React.Component {

    getItems(property) {
        // let groupNames = new Set();
        // for (const item of this.props.statement[property]) {
        //     groupNames.add(item.groupName);
        // }

        // let groups = [];
        // for (const groupName of groupNames) {
        //     groups.push({
        //         groupName,
        //         items: this.props.statement[property].filter(item => item.groupName == groupName)
        //     })
        // }

        // return groups;
        return this.props.statement[property];
    }

    render() {
        const statement = this.props.statement;
        // const isLoss = statement.netIncome < 0;
        // const netIncome = Math.abs(statement.netIncome).toFixed(2);
        return (
            <div style={{ width: '100%', }}>
                <div style={{ height: 15, background: '#f4f4f4' }} />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontSize: 16 }}>{COMPANY_NAME}</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontWeight: 'bold', fontSize: 16 }}>Trial Balance</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30, background: '#f4f4f4' }}>
                    <h4 style={{ fontSize: 16 }}>As of {Util.getStatementDate(statement.date)}</h4>
                </div>
                <div style={{ height: 15, background: '#f4f4f4' }} />
                <div style={{ height: undefined, paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '0.25rem', paddingBottom: '0.25rem', background: '#161616', display: 'flex', justifyContent: 'center', color: 'white' }}>
                    <p style={{ flex: 2, fontWeight: 'bold' }}>Account</p>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                        <p style={{ fontWeight: 'bold' }}>Debit</p>
                    </div>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                        <p style={{ fontWeight: 'bold' }}>Credit</p>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ padding: '1rem', flex: 1 }}>

                        <h5 style={{ marginBottom: '0.5rem', marginTop: '0rem' }}>Assets</h5>
                        {/* {this.getItems('assets').map(item => <AccountTreeItem hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} itemRender={(props) => <Item {...props} key={item.id} debit />} />)} */}
                        {this.getItems('assets').map(item => <AccountTreeItem endDate={statement.endDate} hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} leftAligned />)}

                        {/* {this.getItems('assets').map(group => (<>
                            <h6 style={{paddingLeft: '1rem', marginTop: '0.5rem'}}>{group.groupName}</h6>
                            {group.items.map(item => <Item item={item} debit />)}
                        </>))} */}
                        <div style={{ display: 'flex', flex: 1, borderBottom: '1px solid #00000020', paddingBottom: '0.5rem', paddingLeft: '1rem', marginTop: '1rem', }} />

                        <h5 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>Liabilities</h5>
                        {this.getItems('liabilities').map(item => <AccountTreeItem endDate={statement.endDate} hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} rightAligned />)}
                        {/* {this.getItems('liabilities').map(group => (<>
                            <h6 style={{paddingLeft: '1rem', marginTop: '0.5rem'}}>{group.groupName}</h6>
                            {group.items.map(item => <Item item={item} />)}
                        </>))} */}
                        <div style={{ display: 'flex', flex: 1, borderBottom: '1px solid #00000020', paddingBottom: '0.5rem', paddingLeft: '1rem', marginTop: '1rem', }} />

                        <h5 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>Equity</h5>
                        {this.getItems('equities').map(item => <AccountTreeItem endDate={statement.endDate} hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} rightAligned />)}
                        {/* {this.getItems('equities').map(group => (<>
                            <h6 style={{paddingLeft: '1rem', marginTop: '0.5rem'}}>{group.groupName}</h6>
                            {group.items.map(item => <Item item={item} />)}
                        </>))} */}
                        <div style={{ display: 'flex', flex: 1, borderBottom: '1px solid #00000020', paddingBottom: '0.5rem', paddingLeft: '1rem', marginTop: '1rem', }} />


                        <h5 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>Revenue</h5>
                        {this.getItems('incomes').map(item => <AccountTreeItem endDate={statement.endDate} hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} rightAligned />)}
                        {/* {this.getItems('incomes').map(group => (<>
                            <h6 style={{paddingLeft: '1rem', marginTop: '0.5rem'}}>{group.groupName}</h6>
                            {group.items.map(item => <Item item={item} />)}
                        </>))} */}
                        <div style={{ display: 'flex', flex: 1, borderBottom: '1px solid #00000020', paddingBottom: '0.5rem', paddingLeft: '1rem', marginTop: '1rem', }} />

                        <h5 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>Expenses</h5>
                        {this.getItems('expenses').map(item => <AccountTreeItem endDate={statement.endDate} hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} leftAligned />)}
                        {/* {this.getItems('expenses').map(item => <AccountTreeItem hideZeroBalance={this.props.hideZeroBalance} key={item.id} item={item} itemRender={(props) => <Item {...props} debit />} />)} */}
                        {/* {this.getItems('expenses').map(group => (<>
                            <h6 style={{paddingLeft: '1rem', marginTop: '0.5rem'}}>{group.groupName}</h6>
                            {group.items.map(item => <Item item={item} debit />)}
                        </>))} */}
                        <div style={{ display: 'flex', flex: 1, borderBottom: '1px solid #00000020', paddingBottom: '0.5rem', paddingLeft: '1rem', marginTop: '1rem', }} />
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '1rem', background: '#f4f4f4', marginTop: '1rem' }}>
                    <div style={{ padding: '1rem', flex: 1, display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ flex: 2 }}>Total</h5>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <p style={{}}>AED {Util.formatMoney(statement.totalDebit)}</p>
                        </div>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <p style={{}}>AED {Util.formatMoney(statement.totalCredit)}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default TrialBalance;