import React, { useState } from 'react'


import { Renew16, ArrowLeft20, Gradient24, Save16, Printer16 } from '@carbon/icons-react'
import Button from '../../../components/Button';
import UIUtil from '../../../util/UIUtil';
import { useHistory } from 'react-router';
import { Loading } from 'carbon-components-react';
import Api from '../../../session/Api';
//import { Button } from 'carbon-components-react'


const IconButton = ({icon}) => (
    <div className="label-designer-header-button" style={{}}>
        {React.createElement(icon)}
    </div>
)

const GhostButton = (props) => {
    const [hover, setHover] = useState(false);
    return <Button onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
    kind="ghost" style={!hover ? {color: 'white', fill: 'white'} : {}} {...props}>{props.children}</Button>;
}

export default ({engine, loadingPrinting, onPrintBtn}) => {
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    const onBackBtn = () => {
        if (engine.changesMade()) {
            UIUtil.confirmWithMsg("You have unsaved changes that will be lost!", () => history.goBack())
        } else {
            history.goBack();
        }
    }

    const onSaveBtn = () => {
        setSaving(true);
        engine.save(() => setSaving(false));
    }

    return (
        <div className="label-designer-header theme-bg">
            {saving && <Loading />}

            <GhostButton iconDescription="Back" renderIcon={ArrowLeft20} onClick={onBackBtn} />
    
            <Gradient24 style={{marginLeft: '0.5rem', marginRight: '0.25rem'}} />
            <h4 style={{flex: 1}}>Template Designer</h4>
    
            <GhostButton loading={loadingPrinting} renderIcon={Printer16} onClick={onPrintBtn}>Print</GhostButton>
            <Button disabled={!engine.changesMade()} onClick={onSaveBtn} style={{height: '100%'}} kind="primary" renderIcon={Save16}>Save</Button>
        </div>
    )
}