import { useEffect, useState } from "react";
import Api from "../../../../../session/Api";
import UIUtil from "../../../../../util/UIUtil";
import Util from "../../../../../util/Util";
import { BlueprintInputFieldsForm } from "../../../../activities/components/blueprint-input-fields-form";
import { InlineLoading } from "carbon-components-react";


export function PackageForm({ item, setItemValue }) {
    const [formFieldValues, setFormFieldValues] = [item.formFieldValues, val => setItemValue('formFieldValues', val)];
    const itemType = item.packageTypeId;
    const hasItemType = Util.isNumberExist(itemType);

    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState([]);

    useEffect(() => {
        if (itemType) {
            let cancelled = false;

            setLoading(true);
            Api.getShipmentPackageTypeBlueprintInputFields(itemType, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    setInputFields(response.payload)
                    setLoading(false)
                } else {
                    UIUtil.showError("Could not initialize form")
                }
            })

            return () => cancelled = true;
        } else {
            setLoading(false);
            setInputFields([])
        }
    }, [itemType])

    if (hasItemType) {
        if (!loading) {
            return (
                <div>
                    <BlueprintInputFieldsForm config={{ forceGrayBg: true }} inputFields={inputFields} value={formFieldValues} onValueChanged={setFormFieldValues} />
                </div>
            )
        } else {
            return (
                <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                    <InlineLoading style={{ width: 'unset' }} />
                </div>
            )
        }
    } else {
        return (
            <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>
                    Please select type
                </p>
            </div>
        )
    }
}