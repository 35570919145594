import React from 'react'
import Page from '../../base/Page';

import { AppConnectivity32, Download20, Document16, Mobile16 } from '@carbon/icons-react'
import Api from '../../session/Api';
import Button from '../../components/Button';

const Section = ({ children, icon, title, extraTopMargin, hasLink, linkText, onLinkClick }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ marginLeft: '0.25rem' }}>{title}</p>


            {/* {hasLink && <Link style={{marginLeft: '1rem', fontSize: 12, cursor: 'pointer'}} onClick={onLinkClick}>{linkText}</Link>} */}
        </div>
        {children}
    </div>
)


class EscPosPrintServerPage extends Page {

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }} className="menu-tile">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3rem' }}>
                        <AppConnectivity32 style={{ width: 64, height: 64, marginRight: '0.5rem' }} />
                        <div>
                            <h1>ESC/POS Print Server</h1>
                            <p style={{ fontSize: 18 }}>v12</p>
                        </div>
                    </div>

                    <Section icon={Download20} title="Run Server">
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <div>
                                <a target="_blank" download={"Robotic ESC/POS Print Server.exe"} href="/EscPosPrintServer.exe" style={{ textDecoration: 'none' }}>
                                    <Button kind="secondary" renderIcon={Mobile16} style={{ width: 200 }}>Windows</Button>
                                </a>
                                <p className="bx--label" style={{ display: 'block', marginTop: '0.25rem' }}>.exe file format</p>
                            </div>
                            <div>
                                <a target="_blank" download={"Robotic ESC/POS Print Server"} href="/EscPosPrintServer.mac" style={{ textDecoration: 'none' }}>
                                    <Button kind="secondary" renderIcon={Mobile16} style={{ width: 200 }}>macOS</Button>
                                </a>
                                <p className="bx--label" style={{ display: 'block', marginTop: '0.25rem' }}>executable</p>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default EscPosPrintServerPage;