
import React from 'react';
import { Page, Document, Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../../images/Logo.png'
import Util from '../../../util/Util';
import { TableHeader } from './TableHeader';
import Table from './Table';
import ReceiptLogo from '../../../images/ReceiptLogo.png'
import { INVOICE_ALT_PDF, THERMAL_RECEIPT_LOGO } from '../../../app/Config';
import { PvgTradingStatementsOfAccount } from '../../../pdfs-alt/pvg-trading/PvgTradingStatementsOfAccount';
import { hasCapabilitySupport } from '../../../app/Capabilities';
import { ExcellsReportPdf } from '../excells-pdf/ReportPdf';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 178,
        height: 37,
    },
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    reportTitle: {
        color: '#ff2800',
        letterSpacing: 4,
        fontSize: 16,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    reportSub: {
        textTransform: 'uppercase',
        fontStyle: 'bold',
    }
});

const Divider = () => <View style={{ width: '100%', backgroundColor: 'black', height: 2, marginBottom: 5 }} />

const Field = ({ label, value }) => (
    <View style={{ marginBottom: 5 }}>
        <Text style={{ fontSize: 10, opacity: 0.65 }}>{label}</Text>
        <Text>{value}</Text>
    </View>
)

export const ReportPdf = ({ report, ...props }) => {
    if (INVOICE_ALT_PDF === "pvg-trading") {
        return <PvgTradingStatementsOfAccount {...{ report, props }} />
    } else if (hasCapabilitySupport("shipping")) {
        return <ExcellsReportPdf report={report} {...props} />
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* <Image style={styles.logo} src={logo} /> */}
                <Image style={styles.logo} src={THERMAL_RECEIPT_LOGO.squareLogo ? logo : ReceiptLogo} />
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>Statement of Accounts</Text>
                </View>
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Text style={styles.reportSub}>For the period of {Util.getDateOnly(report.startDate)} to {Util.getDateOnly(report.endDate)}</Text>
                </View>
                <Divider />

                <Field label="Account No" value={Util.getVoucherNumber(report.id)} />
                <Field label="Subsidiary Ledger Name" value={report.name} />

                {/* <Text style={{marginTop: 5, fontWeight: 'bold'}}>
                    Dear Sir/Madam
                </Text> */}
                <Text style={{ marginLeft: 25, marginBottom: 7 }}>
                    {/* Given below are the detail of amounts outstanding against your name in our books as of {Util.getDateOnly(new Date().getTime())}. We
                    request you to take immediate steps for settling the overdue bills and oblige. */}
                </Text>

                <Table report={report} />

                <Text style={{ marginTop: 50, opacity: 0.65, fontSize: 8 }}>
                    This is a computer generated document and does not require any signature
                </Text>
                <Text style={{ opacity: 0.65, fontSize: 8 }}>
                    The details of amount are displayed in AED
                </Text>
            </Page>
        </Document>
    )
}