
import { Printer16, Xls16 } from '@carbon/icons-react'
import { ExportToExcelBtn } from '../export-btn/export-to-excel-btn'

export function Footer({ count, isLazyAgg, isTreeStructure, showDarkMode, children, isListMode, viewBased }) {
    return (
        <div style={{
            paddingLeft: '6rem', paddingRight: '6rem', height: 25,
            background: viewBased ? '#F7F7F8' : showDarkMode ? '#141414' : isListMode ? '#fafafa' : '#ededed',
            borderTop: viewBased ? undefined : showDarkMode ? '1px solid #ffffff20' : '1px solid #00000020',
            color: showDarkMode ? 'white' : 'black',
            display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
        }}>
            {!viewBased && !isTreeStructure && !isLazyAgg && <p style={{ fontSize: 12, opacity: 0.65, flex: 1 }}>{count} Row{count !== 1 && 's'}</p>}
            {/* <button className='report-engine-bottom-bar-button report-engine-bottom-bar-button-blue'>
                Print Report <Printer16 />
            </button> */}
            {children}
        </div>
    )
}