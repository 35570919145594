import { useEffect, useState } from "react"

const Item = ({ item, columns, large, itemChildren, itemPadding, animate }) => {
    const [playAnim, setPlayAnim] = useState(!item.initial);
    useEffect(() => {
        if (animate) {
            setTimeout(() => {
                setPlayAnim(false)
            }, 1010)
        }
    }, [])

    return (<>
        <div style={{
            animation: animate && playAnim ? 'scale-bounce-anim 1000ms' : '',
            borderBottom: '1px solid #e0e0e0'
        }}>
            <div style={{
                display: 'flex',
                fontSize: '.875rem', color: '#525252',
                height: large ? 45 : undefined,
                ...(itemPadding ? { padding: itemPadding } : {
                    paddingInline: '0.5rem', paddingBlock: '0.25rem',
                })
            }}>
                {columns.map(column => (
                    <div key={column.name + "-row-" + item.id} style={{ flex: column.flex, display: 'flex', alignItems: 'center', gap: 5 }}>
                        {column.renderCell(item)}
                    </div>
                ))}
            </div>
            {itemChildren && itemChildren(item)}
        </div>
    </>)
}

export function MinimalTable({ columns, items, customBgColor, large, customExt, itemPadding, itemChildren, animate }) {
    return (
        <>
            <div className="bx--structured-list-th"
                style={{ display: 'flex', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5, paddingLeft: '1rem', borderBottom: '0px solid #e0e0e0', background: customBgColor ? customBgColor : '#1c1c1c', }}>
                {columns.map(column => (
                    <div key={column.name} style={{ flex: column.flex }}>
                        {column.title}
                    </div>
                ))}
            </div>
            <div style={{ background: 'white', border: '1px solid #00000020', borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                {items.length == 0 && <p style={{ fontSize: 12, opacity: 0.65, marginTop: '1rem', marginLeft: '1rem' }}>No items</p>}
                {items.map(item =>
                    item.isDivider ? (
                        <div key={item.isDivider + '-divider-item'} style={{ width: '100%', background: '#292929', display: 'flex', alignItems: 'center', paddingInline: '0.5rem', paddingBlock: '0.15rem' }}>
                            <p style={{ color: 'white', fontSize: 12, opacity: 0.65, fontStyle: 'italic' }}>{item.title}</p>
                        </div>
                    ) : (
                        <Item key={item.id} item={item} columns={columns} large={large} itemChildren={itemChildren} itemPadding={itemPadding} animate={animate} />
                    )
                )}
            </div>
        </>
    )
}