import { useMemo } from "react"
import { DataTable, InlineLoading, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, TableToolbar, TableToolbarContent, TableToolbarSearch } from 'carbon-components-react'
import React, { useEffect, useRef, useState } from 'react'

function getCellHeader(headers, cell) {
    for (const header of headers) {
        if (header.key == cell.info.header) {
            return header;
        }
    }
}

function getCellRow(rows, cell) {
    const id = cell.id.split(":")[0];
    for (const row of rows) {
        if (row.id == id) {
            return row;
        }
    }
}

export default function ({ rows, columns, hasFooter, title, description, hideToolbar, onClick, children, rightContent }) {
    const headers = useMemo(() => columns.map(column => ({
        key: column.id,
        header: column.name,
    })), columns)


    const renderCell = cell => {
        const cellHeader = getCellHeader(headers, cell);
        const cellRow = getCellRow(rows, cell);

        const column = columns.find(column => column.id == cellHeader.key);
        if (column) {
            return cellRow ? column.render(cellRow) : null;
        } else {
            return cell.value;
        }
    }

    return (
        <DataTable rows={rows} headers={headers} isSortable>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                onInputChange,
                getSelectionProps,
                selectedRows
            }) => (
                <TableContainer title={title} description={description}>
                    {!hideToolbar && <TableToolbar>
                        <TableToolbarContent>
                            {children}

                            <TableToolbarSearch placeholder={"Search"} onChange={onInputChange} />
                            {rightContent}
                        </TableToolbarContent>
                    </TableToolbar>}
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => (
                                    <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow onClick={() => onClick && onClick(row.id)} key={row.id} {...getRowProps({ row })}>
                                    {row.cells.map((cell) => (
                                        <TableCell key={cell.id}>{renderCell(cell)}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {hasFooter && <TableToolbar>

                    </TableToolbar>}
                </TableContainer>
            )}
        </DataTable>
    )
}