import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getObjectTypeUrl } from "../../constants/ObjectTypes";
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";

export function createFormLoader(getter) {
    return ({ itemId }) => listener => {
        const isCreating = itemId == "new";
        if (isCreating) {
            getter.bind(Api)(0, listener)
            // if (onIsCreating) {
            //     // onIsCreating();
            //     getter.bind(Api)(itemId, listener)
            // } else {
            //     listener({ status: true, message: null, payload: null })
            // }
        } else {
            // onIsUpdating();
            getter.bind(Api)(itemId, listener)
        }
    }
}


function clearId(x) {
    if (!x) {
        return;
    }

    if (Array.isArray(x)) {
        x.forEach(clearId);
    } else {
        Object.entries(x).forEach(([key, value]) => {
            if (key === "id") {
                x[key] = 0;
            } else if (typeof value === "object") {
                clearId(value);
            }
        });
    }
}

export function clearIdForApi(obj, preprocessor) {
    // const fixed = { ...obj };
    const fixed = JSON.parse(JSON.stringify(obj))
    if (preprocessor) {
        preprocessor(fixed);
    }
    clearId(fixed);
    return fixed;
}



function fixId(x) {
    if (!x) {
        return;
    }

    if (Array.isArray(x)) {
        x.forEach(fixId);
    } else {
        Object.entries(x).forEach(([key, value]) => {
            if (key === "id" && typeof value === "string") {
                x[key] = 0;
            } else if (typeof value === "object") {
                fixId(value);
            }
        });
    }
}

export function fixIdForApi(obj, preprocessor) {
    // const fixed = { ...obj };
    const fixed = JSON.parse(JSON.stringify(obj))
    if (preprocessor) {
        preprocessor(fixed);
    }
    fixId(fixed);
    return fixed;
}


export function useFormApi(type, id) {
    const history = useHistory();

    const isCreating = id == 0;
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const save = (api, { overrideCreatingSuccess, overrideUpdateSuccess } = {}) => {
        setSaving(true)
        api(response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                if (isCreating) {
                    if (overrideCreatingSuccess) {
                        overrideCreatingSuccess(response.payload)
                    } else {
                        history.replace(getObjectTypeUrl(type) + '/' + response.payload.id)
                    }
                } else {
                    if (overrideUpdateSuccess) {
                        overrideUpdateSuccess(response.payload)
                    }
                }
            } else {
                UIUtil.showError(response.message);
            }
            setSaving(false)
        })
    }

    const deleteItem = (api, { overrideSuccess } = {}) => {
        setDeleting(true)
        api(response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                if (overrideSuccess) {
                    overrideSuccess();
                } else {
                    history.goBack();
                }
            } else {
                UIUtil.showError(response.message);
            }
            setDeleting(false)
        })
    }

    return {
        isCreating,
        save,
        saving,
        deleteItem,
        deleting
    }
}