
import { CheckmarkFilled32, ErrorFilled32, InProgress32, Help32, Account16 } from '@carbon/icons-react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { Loading } from 'carbon-components-react'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Util from '../../util/Util'
import { stripePromise } from './stripe-payment'

const LoadingMsg = () => (<>
    <Loading withOverlay={false} />
</>)

const SuccessMsg = () => (<>
    <CheckmarkFilled32 style={{ width: 64, height: 64, color: 'green' }} />
    <h3 style={{ fontWeight: 'bold', color: 'green' }}>Thank you!</h3>
    <p style={{ fontSize: 12, }}>Payment was successful</p>
</>)

const ErrorMsg = ({ message }) => (<>
    <ErrorFilled32 style={{ width: 32, height: 32, }} />
    <h4 style={{}}>Payment failed</h4>
    <p style={{ fontSize: 14, }}>{'An error occurred. Please try again later.'}</p>
</>)

const UnknownMsg = ({ message }) => (<>
    <Help32 style={{ width: 32, height: 32, }} />
    <p style={{ marginTop: '0.5rem' }}>Unknown status</p>
</>)

const ProcessingMsg = ({ message }) => (<>
    <InProgress32 style={{ width: 32, height: 32, }} />
    <p style={{ marginTop: '0.5rem' }}>Payment is being processed</p>
</>)


const GoBackBtn = ({ loading }) => {
    const { serviceSaleId } = useParams();
    const history = useHistory();

    const onBackBtn = () => history.replace('/invoice/' + serviceSaleId)

    return <Button onClick={onBackBtn} disabled={loading} renderIcon={Account16} size="sm" style={{ borderRadius: 15, width: 350 }}>Go back to invoice</Button>
}

const Layout = ({ loading, children }) => {
    return (
        <div style={{ width: '100%', height: '100%', paddingTop: '3rem', paddingBottom: '3rem', gap: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ background: '#f4f4f4', border: '1px solid #00000020', borderRadius: 15, overflow: 'hidden', width: 350 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1rem', paddingBlock: '3rem' }}>
                    {children}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <GoBackBtn loading={loading} />
            </div>
        </div>
    )
}

function View({ clientSecret }) {
    const stripe = useStripe();
    const [status, setStatus] = useState({ loading: true, success: false, error: false, processing: false, unknown: false });

    const Msg = (() => {
        if (status.loading) {
            return LoadingMsg;
        } else if (status.success) {
            return SuccessMsg;
        } else if (status.error) {
            return ErrorMsg;
        } else if (status.processing) {
            return ProcessingMsg;
        } else {
            return UnknownMsg;
        }
    })();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!Util.isStringExists(clientSecret)) {
            setStatus({ loading: false, success: false, error: false, processing: false, unknown: true })
            return;
        }

        setStatus({ loading: true, success: false, error: false, processing: false, unknown: false })

        let cancelled = false;
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if (cancelled) {
                return;
            }

            switch (paymentIntent.status) {
                case "succeeded":
                    setStatus({ loading: false, success: true, error: false, processing: false, unknown: false })
                    break;
                case "processing":
                    setStatus({ loading: false, success: false, error: false, processing: true, unknown: false })
                    break;
                default:
                    setStatus({ loading: false, success: false, error: true, processing: false, unknown: false })
                    break;
            }
        });

        return () => {
            cancelled = true;
        }
    }, [stripe, clientSecret]);

    return <Layout loading={status.loading}><Msg message={""} /></Layout>
}

export function StripePaymentMessagePage() {
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (Util.isStringExists(clientSecret)) {
        return <Elements stripe={stripePromise}><View clientSecret={clientSecret} /></Elements>
    } else {
        return <Layout><UnknownMsg /></Layout>
    }
}