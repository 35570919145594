import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Save16,
    Reset16,
    FlowStream32,
    DirectionStraightRight32,
    TextLinkAnalysis32,
    Calendar32,
    Calculator32,
    Run32,
    DeliveryParcel32,
    Currency32,
    Notebook16,
    DeliveryParcel16,
    Money16,
    Archive32,
    TagEdit16,
    Box16,
    Report32,
    Launch32,
    Catalog32,
    Money32,
    FlowStream16,
    RequestQuote32,
    Currency16,
    Notebook32,
    ArrowLeft16,
    Wallet16,
    Delete16,
    ChartBullet16,
    Migrate16,
    Certificate16,
    GraphicalDataFlow16,
    CheckmarkFilled16,
    DataShare16,
    Edit16,
    Cube16
} from '@carbon/icons-react'
import Button from '../../components/Button';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_JOURNAL_ENTRY, OBJECT_TYPE_SUPPLIER } from '../../constants/ObjectTypes';
import Util, { isV2 } from '../../util/Util';
import { Link, withRouter } from 'react-router-dom';
import ProfilePic from '../../components/ProfilePic';
import DirectionIndicator from '../../components/DirectionIndicator';
import { JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT, JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT, TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_OTHER, TRANSACTION_PARTY_TYPE_SUPPLIER } from '../../constants/Constants';
import { ButtonSet, Link as DLink } from 'carbon-components-react';
import JournalEntryEditor from './JournalEntryEditor';
import NoteView from '../../views/notes/NoteView';
import { JournalVoucherDownload } from '../../pdfs/journal-voucher/JournalVoucherPdf';
import UIUtil from '../../util/UIUtil';
import { EditJournalEntry } from './edit-journal-entry';
import { SidePanel } from '../../templates/draft/components/side-panel';
import { listDetailItemId } from '../../reporting-engine/base/report-page';

const Section = ({ id, children, icon, title, subtitle, extraTopMargin, miniTopMargin }) => (
    <div id={id} style={{ marginTop: miniTopMargin ? '1rem' : extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

class JournalEntriesItemPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            journalEntry: undefined,
            updating: false,
            canUpdate: false,
            showEditDialog: false
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getJournalEntryItem(this.getPathParams().journalEntryId, listener), payload => ({
            journalEntry: payload,
            canUpdate: false
        }))
    }

    updateClasses() {
        this.setState({ updating: true })
        Api.updateJournalEntryTags(this.state.journalEntry, response => {
            this.setState({ updating: false, canUpdate: response.status !== true })
            if (response.status === true) {
                UIUtil.showSuccess()
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    getTotalAmount(type) {
        let total = 0;
        for (const amount of this.state.journalEntry.amounts) {
            if (amount.type == type) {
                total += parseFloat(amount.amount);
            }
        }
        return total;
    }

    renderPrintBtn() {
        let btn = <JournalVoucherDownload journalEntry={this.state.journalEntry} />;

        if (btn) {
            return (
                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                    {btn}
                </div>
            )
        } else {
            return null;
        }
    }

    onDeleteBtn() {
        if (Util.isNumberExist(this.state.journalEntry.v2SaleId)) {
            UIUtil.showError("Please delete invoice instead")
            return;
        } else if (Util.isNumberExist(this.state.journalEntry.v2BillId)) {
            UIUtil.showError("Please delete bill instead")
            return;
        }

        if (this.state.journalEntry.systemGenerated && isV2()) {
            UIUtil.showError("System generated entry cannot be deleted directly")
            return;
        }


        UIUtil.confirmWithMsg(`Deleting the journal entry is irreversible and will be permanent!`, () => {
            Api.deleteJournalEntry(this.state.journalEntry.id, response => {
                if (response.status === true) {
                    this.props.history.goBack();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        })
    }

    canEdit() {
        const journalEntry = this.state.journalEntry;
        return !isV2() &&
            journalEntry.canEdit &&
            !Util.isNumberExist(journalEntry.stockFlowId) &&
            !Util.isNumberExist(journalEntry.transactionId) &&
            !Util.isNumberExist(journalEntry.fundTransferId) &&
            !Util.isNumberExist(journalEntry.crvId) &&
            !Util.isNumberExist(journalEntry.billId) &&
            !Util.isNumberExist(journalEntry.purchaseId) &&
            !Util.isNumberExist(journalEntry.serviceSaleId) &&
            !Util.isNumberExist(journalEntry.saleId) &&
            !Util.isNumberExist(journalEntry.payoutId) &&
            !Util.isNumberExist(journalEntry.bookClosingId)
    }

    get mini() {
        return this.props.mini;
    }

    renderButtons() {
        const journalEntry = this.state.journalEntry;
        const size = this.mini ? 'sm' : 'md';
        return (<>
            {Util.isNumberExist(journalEntry.stockFlowId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/stock-flow/" + journalEntry.stockFlowId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={FlowStream16} style={{ width: 195 }}>Associated Stock Flow</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.transactionId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/transaction/" + journalEntry.transactionId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={Currency16} style={{ width: 195 }}>Associated Transaction</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.fundTransferId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/fund-transfer/" + journalEntry.fundTransferId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={DataShare16} style={{ width: 195 }}>Associated Fund Transfer</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.crvId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/crv/" + journalEntry.crvId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={Wallet16} style={{ width: 195 }}>Associated CRV</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.billId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/bill/" + journalEntry.billId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={ChartBullet16} style={{ width: 195 }}>Associated Bill</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.purchaseId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/purchase/" + journalEntry.purchaseId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={Migrate16} style={{ width: 195 }}>Associated Purchase</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.serviceSaleId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/service-sale/" + journalEntry.serviceSaleId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={GraphicalDataFlow16} style={{ width: 195 }}>Associated Service Sale</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.saleId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/sale/" + journalEntry.saleId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={Certificate16} style={{ width: 195 }}>Associated Sale</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.payoutId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={"/payout/" + journalEntry.payoutId} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={Certificate16} style={{ width: 195 }}>Associated Payout</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : Util.isNumberExist(journalEntry.bookClosingId) ? (
                <ButtonSet style={{ width: '392px', gap: 2 }}>
                    <Link to={listDetailItemId("BookClosings", journalEntry.bookClosingId)} style={{ textDecoration: 'none', }}>
                        <Button size={size} kind="secondary" renderIcon={Cube16} style={{ width: 195 }}>Associated Book Closing</Button>
                    </Link>
                    <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                </ButtonSet>
            ) : (<>

                {journalEntry.systemGenerated ? (
                    // <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                    <ButtonSet style={{ width: '392px', gap: 2 }}>
                        <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                        <Button size={size} kind="danger" onClick={() => this.onDeleteBtn()} renderIcon={Delete16} style={{ width: 195 }}>Delete</Button>
                    </ButtonSet>
                ) : (
                    <ButtonSet style={{ width: '392px', gap: 2 }}>
                        <Button size={size} onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{ width: 195 }}>Notes/Docs</Button>
                        <Button size={size} kind="danger" onClick={() => this.onDeleteBtn()} renderIcon={Delete16} style={{ width: 195 }}>Delete</Button>
                    </ButtonSet>
                )}
            </>)}
        </>)
    }

    getLayout() {
        const journalEntry = this.state.journalEntry;

        if (this.mini) {
            return (
                <div style={{ width: '100%', padding: '0rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '1rem' }}>
                        <div>
                            <label style={{ marginBottom: '0.25rem' }} className="bx--label">Voucher Number</label>
                            <p style={{ marginBottom: '0rem' }}>{Util.getJournalEntryVoucherNumber(journalEntry.id, journalEntry.sequenceNo)}</p>
                        </div>
                        <div style={{ flex: 1 }} />
                        {this.renderButtons()}
                    </div>

                    <JournalEntryEditor
                        readOnly
                        defaultEntries={journalEntry.amounts}
                        onEntriesUpdate={entries => {
                            this.setState({ canUpdate: true, journalEntry: { ...journalEntry, amounts: entries } })
                        }}
                        totalDebit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT)}
                        totalCredit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT)}
                        accountTree={[]}
                        endpointsList={{ predefinedClasses: this.state.journalEntry.predefinedClasses }}
                    />

                    <Section icon={Report32} title="Details" miniTopMargin>
                        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '3rem' }}>
                            <div>
                                <label style={{}} className="bx--label">Recorded by</label>
                                <Link style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + journalEntry.userId}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, journalEntry.userId)} />
                                        <p>{journalEntry.userFullName}</p>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <label style={{ marginTop: '0rem' }} className="bx--label">Recorded on</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Calendar32 style={{ marginRight: '0.5rem' }} />
                                    <p>{Util.getDateOnly(journalEntry.creationDate)}</p>
                                </div>
                            </div>
                        </div>
                        <label style={{ marginTop: '1rem' }} className="bx--label">Description</label>
                        <p style={{ whiteSpace: 'pre-line' }}>{Util.isStringExists(journalEntry.info) ? journalEntry.info : "-"}</p>
                    </Section>

                    <Section id="note-docs" icon={Notebook32} title="Notes/Docs" miniTopMargin>
                        <NoteView objectId={journalEntry.id} objectType={OBJECT_TYPE_JOURNAL_ENTRY} />
                    </Section>
                </div>
            )
        }

        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <DLink onClick={() => this.props.history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <RequestQuote32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
                        <h1 style={{ flex: 1 }}>Journal Entry</h1>

                        {this.renderButtons()}
                    </div>
                    {this.canEdit() &&
                        <Button size="sm" style={{ borderRadius: 25, marginTop: '0.25rem' }} kind="secondary" renderIcon={Edit16} onClick={() => this.setState({ showEditDialog: true })}>Edit</Button>}

                    <Section icon={Report32} title="Entry">
                        <label style={{ marginBottom: '0.25rem' }} className="bx--label">Voucher Number</label>
                        <p style={{ marginBottom: '0rem' }}>{Util.getJournalEntryVoucherNumber(journalEntry.id, journalEntry.sequenceNo)}</p>

                        {this.renderPrintBtn()}

                        <JournalEntryEditor
                            // readOnly
                            editOnlyPredefinedClasses
                            defaultEntries={journalEntry.amounts}
                            onEntriesUpdate={entries => {
                                this.setState({ canUpdate: true, journalEntry: { ...journalEntry, amounts: entries } })
                            }}
                            totalDebit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT)}
                            totalCredit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT)}
                            accountTree={[]}
                            endpointsList={{ predefinedClasses: this.state.journalEntry.predefinedClasses }}
                        />
                        {/* <label style={{marginTop: '1rem', marginBottom: '0.5rem'}} className="bx--label">{this.isInward() ? "Payer" : "Recipient"}</label> */}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem' }}>
                            <Button disabled={!this.state.canUpdate} onClick={() => this.updateClasses()} loading={this.state.updating} size="sm" style={{ borderRadius: 50 }} kind="secondary" renderIcon={CheckmarkFilled16}>
                                Update classes
                            </Button>
                        </div>
                    </Section>

                    {Util.isStringExists(journalEntry.info) && <Section icon={Catalog32} title="Description">
                        <p style={{ whiteSpace: 'pre-line' }}>{journalEntry.info}</p>
                    </Section>}

                    <Section icon={Run32} title="Record">
                        <label style={{}} className="bx--label">Recorded by</label>
                        <Link style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + journalEntry.userId}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, journalEntry.userId)} />
                                <p>{journalEntry.userFullName}</p>
                            </div>
                        </Link>

                        <label style={{ marginTop: '1rem' }} className="bx--label">Recorded on</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Calendar32 style={{ marginRight: '0.5rem' }} />
                            <p>{Util.getDateOnly(journalEntry.creationDate)}</p>
                        </div>

                    </Section>


                    <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                        <NoteView objectId={journalEntry.id} objectType={OBJECT_TYPE_JOURNAL_ENTRY} />
                    </Section>
                </div>

                {this.state.showEditDialog && <SidePanel md onClose={() => this.setState({ showEditDialog: false })}>
                    <EditJournalEntry itemId={this.state.journalEntry.id} onClose={() => this.setState({ showEditDialog: false })} />
                </SidePanel>}
            </div>
        )
    }

}

export default withRouter(JournalEntriesItemPage);