import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import Util from "../../../util/Util";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"

export const FreeSalesReport = {
    filter: Filter,

    // newLink: '/stock-flow-creator',
    itemToOpenLink: item => `/stock-flow/${Util.getIdFromVoucherNumber(item.salesVoucherNo)}`,

    isAvailable: () => {
        // const role = getAccountRole();
        // return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
        return false;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="salesDate" />
        <Divider />
        {/* <TextBox state={state} label="Source" property="sourceName" />
        <TextBox state={state} label="Customer" property="customerName" />
        <TextBox state={state} label="Product" property="products" /> */}

        {/* <TextBox state={state} label="Amount Status" property="amountStatus" /> */}
    </>)
}
