import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../../images/Logo.png'
import ReceiptLogo from '../../../images/ReceiptLogo.png'
import { THERMAL_RECEIPT_LOGO } from '../../../app/Config';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 178,
        height: 37,
        //marginLeft: 'auto',
        //marginRight: 'auto'
    }
});

const Invoice = ({ invoice }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* <Image style={styles.logo} src={logo} /> */}
            <Image style={styles.logo} src={THERMAL_RECEIPT_LOGO.squareLogo ? logo : ReceiptLogo} />
            <InvoiceTitle title='Quotation' />
            <InvoiceNo invoice={invoice} />
            <BillTo invoice={invoice} />
            <InvoiceItemsTable invoice={invoice} />
            {/* <InvoiceThankYouMsg /> */}
        </Page>
    </Document>
);

export default Invoice