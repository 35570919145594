import { withLoadablePageWithParams } from "../../../../base/Page"
import Api from "../../../../session/Api"
import Logo from "../../../../images/other/romario-adidas/white-logo.png"
import Bg from "../../../../images/other/romario-adidas/bg.jpg"
import { WorkStatusTag, getWorkStatusInfoLg } from "../../work-lists/component/status-tag"
import { useMemo, useState } from "react"
import { Tag, TextArea } from "carbon-components-react"
import { ArrowRight16, CheckmarkFilled20, Checkmark16, CheckmarkFilled32 } from "@carbon/icons-react"
import Util from "../../../../util/Util"
import { SALES_CART_WORK_STATUS_EXPIRED, SALES_CART_WORK_STATUS_ONGOING, SALES_CART_WORK_STATUS_QUOTATION, SALES_CART_WORK_STATUS_SALES, SALES_CART_WORK_STATUS_SALES_ORDER } from "../../../../constants/Constants"
import { Link } from "react-router-dom"
import { AdidasRomarioQuotationBtn, StockFlowInvoiceBtn } from "../../../../pdfs/invoice-document/InvoiceDocumentPdf"
import { AdidasRomarioProformaPdf } from "../../../../pdfs-alt/adidas-romario/AdidasRomarioProformaPdf"
import { FEEDBACK, ORDER_DELIVERED } from "../../domain/statuses"
import HappinessSlider from "../../../../components/happiness-slider/HappinessSlider"
import Button from "../../../../components/Button"
import UIUtil from "../../../../util/UIUtil"

function getCartHref(work) {
    if (work.status !== SALES_CART_WORK_STATUS_SALES && Util.isNumberExist(work.terminalId) && Util.isStringExists(work.currentCartId)) {
        return window.location.origin.toString() + "/client-monitor/" + work.terminalId + "/" + work.currentCartId
    } else {
        return undefined;
    }
}

function getVoucherNo(work) {
    switch (work.status) {
        case SALES_CART_WORK_STATUS_SALES:
            return Util.getVoucherNumber(work.salesStockFlowId)
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            return Util.getVoucherNumber(work.salesOrderId);
    }

    return Util.getVoucherNumber(work.id);
}

const hasPdf = (item) => {
    switch (item.status) {
        case SALES_CART_WORK_STATUS_SALES:
            return true;
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            return true;
        case SALES_CART_WORK_STATUS_QUOTATION:
            return true;
    }
    return false;
}

function PDFButton({ item }) {
    // <ButtonValueEntry label={"Document PDF"} />
    switch (item.status) {
        case SALES_CART_WORK_STATUS_SALES:
            return <StockFlowInvoiceBtn stockFlowId={item.salesStockFlowId} />
        case SALES_CART_WORK_STATUS_SALES_ORDER:
            return <AdidasRomarioQuotationBtn quotationId={item.id} salesOrder PDFComponent={AdidasRomarioProformaPdf} />
        case SALES_CART_WORK_STATUS_QUOTATION:
            return <AdidasRomarioQuotationBtn quotationId={item.id} salesOrder={false} />
    }

    return <div />
}

const Title = ({ status }) => {
    const info = useMemo(() => getWorkStatusInfoLg(status), [status])
    return (
        <div style={{
            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem', color: 'white',
            paddingLeft: '1rem', height: '100%', marginTop: -2
        }}>
            {status !== SALES_CART_WORK_STATUS_ONGOING &&
                <h4 style={{ margin: 0, fontWeight: 'bold', fontSize: 22 }}>{info.title}</h4>}
            <Tag type="magenta">Tracking</Tag>
        </div>
    )
}

const ReviewForm = ({ item }) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [rate, setRate] = useState()
    const [feedback, setFeedback] = useState("");

    const onSubmitBtn = () => {
        setLoading(true);
        Api.submitSalesWorkCustomerTrackingFeedback(item.hashId, rate, feedback, response => {
            if (response.status === true) {
                setSubmitted(true)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <div style={{
            borderRadius: 10, border: '1px solid #d3003c', paddingBlock: '0.15rem', paddingBlock: '0.5rem', gap: '1rem', background: "#d3003c10",
            display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
            color: 'black', marginTop: '0.5rem', flexDirection: 'column', padding: '1rem'
        }}>
            {submitted ? (<>
                <div style={{ animation: 'client-app-fade-in 250ms', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '0.25rem', color: 'green' }}>
                    <CheckmarkFilled32 style={{ width: 48, height: 48, }} />
                    <h4>Thank you for your feedback!</h4>
                </div>
            </>) : (<>
                <h4>How was your experience?</h4>
                <div style={{ width: '80%', maxWidth: 600, padding: '1rem', borderRadius: 25, }}>
                    <HappinessSlider onSubmitted={value => setRate(value)} />
                </div>
                {rate && <div style={{ animation: 'client-app-fade-in 250ms', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '0.25rem' }}>
                    <TextArea labelText="Talk to us!" placeholder="Optional..." value={feedback} onChange={e => setFeedback(e.target.value)} />

                    <Button onClick={onSubmitBtn} loading={loading} renderIcon={Checkmark16} style={{ borderRadius: 7, width: '100%', marginTop: '1rem' }}>Submit Feedback</Button>
                </div>}
            </>)}
        </div>
    )
}

const Status = ({ item }) => {
    const final = item.state === ORDER_DELIVERED || item.state === FEEDBACK;
    if (final) {
        return (<>
            <div style={{
                borderRadius: 10, border: '1px solid #009900', paddingBlock: '0.15rem', paddingBlock: '0.5rem', gap: '0.25rem', background: "#00990010",
                display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
                color: 'black', marginTop: '1rem', color: 'green'
            }}>
                <p style={{ fontSize: 16, }}><strong>{ORDER_DELIVERED}</strong></p>
                <CheckmarkFilled20 />
            </div>

            {!item.customerFeedbackSubmitted &&
                <ReviewForm item={item} />}
        </>)
    } else {
        return (<>
            <div style={{
                borderRadius: 10, border: '1px solid #d3003c', paddingBlock: '0.15rem', paddingBlock: '0.5rem', gap: '0.25rem', background: "#d3003c10",
                display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ',
                color: 'black', marginTop: '1rem'
            }}>
                Status <p style={{ fontSize: 16, color: '#d3003c' }}><strong>{item.state}</strong></p>
            </div>
        </>)
    }
}

const Entry = ({ label, value, skipTextAlign }) => (<>
    <div style={{ minHeight: 45, paddingBlock: skipTextAlign ? 10 : undefined, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: 1, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: 2, outline: 'none', textAlign: !skipTextAlign ? 'end' : undefined, wordBreak: 'break-all', }}>{value}</p>
    </div>
</>)


const ButtonValueEntry = ({ label, href }) => {
    return (<>
        <a href={href} target="_blank">
            <div style={{ color: '#d3003c', height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
                <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
                <ArrowRight16 style={{ opacity: 0.65 }} />
            </div>
        </a>
    </>)
}

const Group = ({ title, children }) => (
    <div style={{ width: '100%' }}>
        <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>{title}</h4>
        <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '0rem' }}>
            <div style={{ width: '100%', background: '#d3003c10', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                {children}
            </div>
        </div>
    </div>
)

function View({ payload: item }) {
    const cartHref = getCartHref(item);
    return (
        <div style={{ position: 'relative', overflowX: 'hidden', maxWidth: '100vw', }}>
            {/* <img src={Bg} style={{ width: '100vw', height: '100dvh', position: 'fixed', right: 0, bottom: 0, objectFit: 'contain', objectPosition: 'right bottom', zIndex: -1 }} /> */}

            <div style={{
                position: 'absolute', left: 0, top: 0, zIndex: 3,
                background: '#d3003c', borderBottomLeftRadius: 0, borderBottomRightRadius: 1000, width: '100%', height: 120, display: 'flex',
                justifyContent: 'flex-start', alignItems: 'center'
            }}>
                <img src={Logo} style={{ width: '45%', maxWidth: 300, objectFit: 'contain', objectPosition: 'center', marginLeft: '15%' }} />
            </div>

            <div style={{
                position: 'absolute', left: 0, top: 0, zIndex: 2,
                background: '#9a002c', borderBottomLeftRadius: 0, borderBottomRightRadius: 750, width: '100.5%', height: 140, display: 'flex',
                justifyContent: 'center', alignItems: 'center'
            }} />

            <div style={{
                left: 0, top: 0, zIndex: 1,
                background: '#62001c', borderBottomLeftRadius: 0, borderBottomRightRadius: 1500, width: '102%', height: 185, display: 'flex',
                justifyContent: 'center', alignItems: 'center', paddingTop: 140
            }}>
                <div style={{ height: '100%', width: '100%' }}>
                    <Title status={item.status} />
                </div>
            </div>


            <div style={{ padding: '1rem', paddingTop: 0, paddingBottom: 50, position: 'relative', zIndex: 4 }}>
                {Util.isStringExists(item.state) && <Status item={item} />}

                <div style={{ marginTop: '3rem' }} />
                <Group title={"Info"}>
                    <Entry label={"Status"} value={Util.isStringExists(item.state) ? item.state : "-"} />
                    <Entry label={"Voucher no"} value={getVoucherNo(item)} />
                    {Util.isStringExists(item.shipmentTrackingNo) &&
                        <Entry label={"Tracking no"} value={item.shipmentTrackingNo} />}
                    {cartHref && <ButtonValueEntry label={"View Live Cart"} href={cartHref} />}
                </Group>


                {/* {hasPdf(item) && <Group>
                    <PDFButton item={item} />
                </Group>} */}
            </div>
        </div>
    )
}

export const AdidasTrackingApp = withLoadablePageWithParams(params => listener => Api.getSalesWorkCustomerTracking(params.id, listener), View)