import Util from "../util/Util";


export function getBLNo(shipment) {
    const date = Util.formatDate(new Date(shipment.createDate), "YYMM")
    const seqNo = Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 4,
        useGrouping: false
    })
    if (shipment._blNoSuffix) {
        return `ES${date}-${seqNo.format(shipment.sequenceNo)}-${shipment._blNoSuffix}`
    } else {
        return `ES${date}-${seqNo.format(shipment.sequenceNo)}`
    }

    // const date = new Date(shipment.createDate);
    // const month = date.getMonth()
    // const year = new Date(shipment.createDate).getFullYear();
    // const seqNo = Intl.NumberFormat('en-US', {
    //     minimumIntegerDigits: 6,
    //     useGrouping: false
    // })
    // return `ES${year}-${seqNo.format(shipment.sequenceNo)}`
}