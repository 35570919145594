
import {
    Pin16, PinFilled16,

    Dashboard32, User32, Product32, Screen32, Category32, Barcode32, Box32, Compare32, Rocket32,
    Delivery32, AirlinePassengerCare32, CutOut32, Headset32, Percentage32, Store32, DataVis_232,
    Calculator32, WatsonHealthTextAnnotationToggle32, Archive32, StringText32, Chip32, InventoryManagement32,
    FlowStream32, Cube32, DocumentImport32, Purchase32, Money32, Finance32, Account32, Receipt32, Events32,
    Gift32, TagGroup32, QrCode32, Close16, SubtractAlt32, Currency32, IncreaseLevel32, ShoppingCartArrowUp32,
    Api32, License32, RequestQuote32, Notebook32, Table32, ShoppingCartArrowDown32, InProgress32,
    DataFormat32, TreeView32, Catalog32, ListDropdown32, Course32, Grid32, DataShare32, Data_132,
    CaretRight16, ArrowDown32, ArrowUp32, PenFountain32, DeliveryParcel32, LicenseGlobal32, Group32,
    Network_132, CategoryNewEach32, PhraseSentiment32, Gradient32, App32, Book32, Document32,
    Wallet32, ReportData32, Van32, RulerAlt32, Types32, ShoppingCart32, Classification32, Settings32,
    Alarm32, Categories32, Boolean32, LocationCompany32, InsertPage32, Bullhorn32,
    Locked32, ViewFilled32, Blog32, Home32, TextLink32, LicenseDraft32, MediaLibrary32,
    UserData32, Enterprise32, Code32, RecentlyViewed32, Building32, ChartBullet32, BuildingInsights_132,
    AirlineManageGates32, Migrate32, ServiceDesk32, Application32, Certificate32, GraphicalDataFlow32,
    Wikis32, Map32, TwoPersonLift32, Industry32, Milestone32,
    Task32, CalendarHeatMap32, SplitScreen32, ChoroplethMap32, Legend32, Roadmap32, EventsAlt32, UserProfile32,
    StarFilled32, UserCertification32, UserMultiple32, GroupPresentation32,
} from '@carbon/icons-react'


export function guardianMenu() {
    return [
        {
            title: "Portal",
            color: "black",
            subMenus: [
                {
                    menus: [{
                        icon: Dashboard32,
                        title: "Home",
                        link: "/"
                    },
                    // {
                    //     icon: GroupPresentation32,
                    //     title: "Courses",
                    //     link: "/courses"
                    // },
                    // {
                    //     icon: CalendarHeatMap32,
                    //     title: "Calendar/Appointments",
                    //     link: "/calendar"
                    // },
                    {
                        icon: Group32,
                        title: "My Students",
                        link: "/my-students"
                    },
                    {
                        icon: Account32,
                        title: "Invoices",
                        link: "/invoices"
                    },
                    {
                        icon: User32,
                        title: "Profile",
                        link: "/profile"
                    }]
                }
            ]
        },
    ];
}