import { Route } from "react-router-dom";
import WelcomePage from "../../pages/message-pages/WelcomePage";




export const NONE = {
    router: [
        <Route exact path="/" component={WelcomePage} />
    ],
    menu: [
    ]
}