import React, { useState } from 'react'
import { Purchase16, Star16, Screen32, Receipt16, ErrorFilled16, Printer16, ShoppingCart16, ArrowRight16, WarningFilled32, Cafe16, Cafe32, Time16, Hourglass16, DeliveryParcel16 } from '@carbon/icons-react'
import Rate from '../../../components/Rate'
import Button from '../../../components/Button'
import Util from '../../../util/Util'
import UIUtil from '../../../util/UIUtil'
import { PrintSearchDialog } from '../dialogs/print-search-dialog'
import { ActionBar } from './action-bar'
import { PosStats } from './stats'

export const PosInfoViewBtns = ({ posState }) => {
    return (
        <>
            <Button kind="primary" loading={posState.isLoadingPosSetOnBreak()}
                renderIcon={Cafe16} onClick={() => posState.confirmToGoOnBreak()}>
                Go on break
            </Button>
            <Button kind="danger" renderIcon={ErrorFilled16} onClick={() => {
                UIUtil.confirm(() => posState.showStopSessionDialog());
            }}>
                Stop Session
            </Button>
        </>
    )
}



export const PosInfoView = ({ posState, setHidden }) => {
    if (posState.getSession() == null) {
        return null;
    }

    return (
        <div>
            {/* <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: '1rem', gap: '0.5rem' }}>
                <PrintSalesSummaryBtn state={posState} />
                <SaleHistoryBtn state={posState} onClose={onClose} />
            </div> */}


            <ActionBar state={posState} setHidden={setHidden} />
            <div style={{ height: '1rem' }} />
            <PosStats state={posState} />


            {/* <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, marginTop: '1rem', color: 'green' }}>
                        <Purchase16 />
                        <p style={{ marginLeft: 5 }}>
                            Total sales
                        </p>
                    </div>
                    <h3 style={{ margin: 0, color: 'green' }}>{posState.getSession().posTotals.currency} {posState.getSession().totalSalesAmount.toFixed(2)}</h3>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 7, marginTop: '1rem', color: '#f26622' }}>
                        <Star16 />
                        <p style={{ marginLeft: 5 }}>
                            Average Reviews
                        </p>
                    </div>
                    <Rate disabled value={posState.getSession().averageReviews} />
                </div>
            </div> */}

            <div style={{ display: 'flex', marginTop: '1rem' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, color: 'black' }}>
                        <Time16 />
                        <p style={{ marginLeft: 5 }}>
                            Start Time
                        </p>
                    </div>
                    <h3 style={{ margin: 0 }}>{Util.getDate(posState.getSession().startDate)}</h3>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 7, marginTop: '1rem', color: '#f26622' }}>
                        <Star16 />
                        <p style={{ marginLeft: 5 }}>
                            Average Reviews
                        </p>
                    </div>
                    <Rate disabled value={posState.getSession().averageReviews} />
                </div>
                {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, color: 'black' }}>
                        <DeliveryParcel16 />
                        <p style={{ marginLeft: 5 }}>
                            Checkouts
                        </p>
                    </div>
                    <h3 style={{ margin: 0 }}>{posState.getSession().totalSalesCount}</h3>
                </div> */}
            </div>

            <div style={{ display: 'flex', marginTop: '2rem' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, color: 'black' }}>
                        <Cafe16 />
                        <p style={{ marginLeft: 5 }}>
                            Break time
                        </p>
                    </div>
                    <h3 style={{ margin: 0 }}>{Util.formatSecondsPretty(posState.getSession().totalBreakTime / 1000)}</h3>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, color: 'black' }}>
                        <Hourglass16 />
                        <p style={{ marginLeft: 5 }}>
                            Active time
                        </p>
                    </div>
                    <h3 style={{ margin: 0 }}>{Util.formatSecondsPretty(((new Date().getTime() - posState.getSession().startDate) - posState.getSession().totalBreakTime) / 1000)}</h3>
                </div>
            </div>

        </div>
    )
}