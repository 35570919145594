import { ContentSwitcher, Switch, TextInput } from "carbon-components-react";
import { useState } from "react";
import Util from "../../../util/Util";
import { useEffect } from "react";
import { useMemo } from "react";


export function DurationField({ getCalendarDate, setCalendarEndDate }) {
    const [{ start, end }, setDate] = useState(() => getCalendarDate());

    const durationInMs = (end - start);
    const durationInHour = durationInMs / 3600000;
    const durationInMinutes = durationInMs / 60000;

    const [unit, setUnit] = useState("h")
    const [value, setValue] = useState(() => durationInHour);

    useEffect(() => {
        const duration = parseFloat(value)
        if (Util.isNumberExist(duration)) {
            if (unit === "h") {
                setCalendarEndDate(start + (duration * 3600000))
            } else {
                setCalendarEndDate(start + (duration * 60000))
            }
            setDate(getCalendarDate())
        }
    }, [value])
    useEffect(() => {
        setValue(unit === "h" ? durationInHour : durationInMinutes)
    }, [unit])

    useEffect(() => {
        setTimeout(() => {
            const date = getCalendarDate();
            setDate(date)
            setValue(unit === "h" ? ((date.end - date.start) / 3600000) : ((date.end - date.start) / 60000))
        })
    }, [])

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
            {/* <button onClick={() => alert(getCalendarDate().start)}></button> */}
            <div style={{ flex: 2 }}>
                <TextInput light labelText="Duration" value={value} onChange={e => setValue(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label">unit</label>
                <ContentSwitcher light selectedIndex={unit === "h" ? 0 : 1} onChange={e => setUnit(e.name)}>
                    <Switch name="h" text="h" />
                    <Switch name="m" text="m" />
                </ContentSwitcher>
            </div>
        </div>
    )
}