import { Button, ButtonSet, TextInput, ComposedModal, ModalBody, ModalHeader, Loading } from 'carbon-components-react';
import React, { createRef } from 'react'
import PosState, { getDefaultPosState } from '../pos/state/PosState';
import { PosInfoView } from '../pos/view/PosInfoView';
import CartContent from '../pos/widgets/CartContent';
import CustomerInfo from '../pos/widgets/CustomerInfo';
import PaymentWidget from '../pos/widgets/PaymentWidget';
import PosInfoWidget from '../pos/widgets/PosInfoWidget';

import Logo from '../../images/Logo.png'
import LogoTransparent from '../../images/LogoTransparent.png'
import Api from '../../session/Api';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER } from '../../constants/ObjectTypes';
import ProfilePic from '../../components/ProfilePic';
import QRCode from 'react-qr-code';
import Util, { sendWhatsappMessage } from '../../util/Util';

import { Screen32, Cafe32, CloseFilled20, WatsonHealthDownloadStudy16, ArrowRight16, AirlinePassengerCare32, FaceAdd32, Identification32, User32, Share16, Email16, CheckmarkFilled32, ErrorFilled32 } from '@carbon/icons-react'
import HappinessSlider from '../../components/happiness-slider/HappinessSlider';
import UIUtil from '../../util/UIUtil';
import { PosReceiptDownload } from '../../pdfs/pos-receipt/PosReceiptPdf';
import EmailSender from '../../templates/EmailSender';
import ItemCreatorView from '../../templates/ItemCreatorView';
import SocketSession, { SOCKET_EVENT_POS_MONITORING_INPUT } from '../../session/SocketSession';
import { getTerminal } from '../../session/SessionManager';
import { saveComponentAsImage } from '../../util/ComponentSaver';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { Link } from 'react-router-dom';
import { WhatsAppIcon } from '../../components/icons/whatsapp-icon';
import { WHATSAPP_TRACKER } from '../../app/Config';

export const share = (url, lastResort) => {
    if (navigator.share) {
        navigator.share({ url: url })
            .then(() => { })
            .catch(e => {
                if (e.name != "AbortError") {
                    navigator.clipboard.writeText(url)
                        .then(() => UIUtil.showSuccess("Copied link!"))
                        .catch(() => UIUtil.showError())
                }
            });
    } else if (navigator.clipboard) {
        navigator.clipboard.writeText(url)
            .then(() => UIUtil.showSuccess("Copied link!"))
            .catch(() => UIUtil.showError());
    } else {
        lastResort();
    }
}

export function copyToClipboard(value) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(value)
            .then(() => UIUtil.showSuccess("Copied!"))
            .catch(() => UIUtil.showError());
    } else {
        UIUtil.showInfo("Browser does not support feature")
    }
}

class TerminalMonitoringView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...getDefaultPosState(),

            showCheckoutDialog: false,
            feedbackSubmitted: false,

            showNewCustomerDialog: false,
            newCustomer: {},

            showCustomerQrCode: false,

            showSearchCustomerDialog: false,
            searchCustomerValue: "",

            loading: false
        }

        this.posState = new PosState(() => this.state, this.setState.bind(this))
        this.posState.readOnly = true;

        //this.props.onStateListener(this.posState);
        this.customerCardRef = createRef();
    }

    isClientPhoneViewer() {
        return this.props.clientPhoneViewer;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showPosInfoDialog != this.state.showPosInfoDialog) {
            //this.props.onStateListener(this.posState);
        }

        if (prevProps.terminal !== this.props.terminal) {
            this.posState.onUpdateSession(this.props.terminal.session)
            if (this.checkoutDialogTimeout !== undefined) {
                clearTimeout(this.checkoutDialogTimeout)
            }

            let openedChecked = false;
            if (prevProps.terminal !== undefined && this.props.terminal !== undefined && prevProps.terminal !== null && this.props.terminal !== null) {
                if (prevProps.terminal.checkoutFlag !== this.props.terminal.checkoutFlag && this.props.terminal.checkoutFlag) {
                    this.posSessionSnapshot = JSON.parse(JSON.stringify(prevProps.terminal.session));
                    this.lastStockFlowRecordId = this.props.terminal.session.lastStockFlowRecordId;
                    //this.posSessionSnapshot = JSON.parse(JSON.stringify(this.props.terminal.session))

                    openedChecked = true;
                    if (!this.isClientPhoneViewer()) {
                        this.checkoutDialogTimeout = setTimeout(() => {
                            this.setState({ showCheckoutDialog: false, feedbackSubmitted: false })
                        }, 30 * 1000)
                    }
                }
            }

            this.setState({ showCheckoutDialog: openedChecked, feedbackSubmitted: false })

            if (this.props.terminal && this.state.showNewCustomerDialog && Util.isNumberExist(this.props.terminal.customerId)) {
                this.setState({ showNewCustomerDialog: false, showCustomerQrCode: true })
            }

            if (this.props.terminal && this.state.showCustomerQrCode && !Util.isNumberExist(this.props.terminal.customerId)) {
                this.setState({ showCustomerQrCode: false })
            }

            if (this.props.terminal && this.state.showSearchCustomerDialog && Util.isNumberExist(this.props.terminal.customerId)) {
                this.setState({ showSearchCustomerDialog: false })
            }
        }
    }

    componentDidMount() {
        if (this.props.terminal) {
            this.posState.loadExternalSession(this.props.terminal.session);

            this.updateId = Util.newTempId();
            this.onMonitoringInputEventListener = (event => {
                if (!(event.openCustomerSearchDialog || event.closeCustomerSearchDialog)) {
                    if (!event.openCustomerQrCodeDialog && !event.closeCustomerQrCodeDialog) {
                        if (event.openNewCustomerDialog || event.closeNewCustomerDialog) {
                            this.setState({ showNewCustomerDialog: event.openNewCustomerDialog ? true : false, newCustomer: {}, showSearchCustomerDialog: false })
                        }
                    } else {
                        if (event.closeCustomerQrCodeDialog) {
                            this.setState({ showNewCustomerDialog: false, showCustomerQrCode: false, showSearchCustomerDialog: false })
                        } else {
                            this.setState({ showNewCustomerDialog: false, showCustomerQrCode: true, showSearchCustomerDialog: false })
                        }
                    }
                } else {
                    this.setState({ showNewCustomerDialog: false, showCustomerQrCode: false, showSearchCustomerDialog: event.openCustomerSearchDialog ? true : false, searchCustomerValue: "" })
                }

                if (event.setUpdateValue && event.updateId != this.updateId) {
                    if (event.updateProperty == "searchCustomerValue") {
                        this.setState({ searchCustomerValue: event.updateValue })
                    } else {
                        this.setState(prevState => ({
                            newCustomer: {
                                ...prevState.newCustomer,
                                [event.updateProperty]: event.updateValue
                            }
                        }))
                    }
                }
            }).bind(this);
            if (!this.props.skipSocketConnection) {
                SocketSession.registerListener(SOCKET_EVENT_POS_MONITORING_INPUT + "-" + this.props.terminal.id, this.onMonitoringInputEventListener);
                this.setState({ loading: true })
                SocketSession.subscribe(SOCKET_EVENT_POS_MONITORING_INPUT + "-" + this.props.terminal.id, subscription => {
                    this.setState({ loading: false })
                    this.socketSubscription = subscription;
                })
            } else {
                this.setState({ loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    componentWillUnmount() {
        if (this.checkoutDialogTimeout !== undefined) {
            clearTimeout(this.checkoutDialogTimeout)
        }



        if (this.props.terminal && this.onMonitoringInputEventListener) {
            SocketSession.unregisterListener(SOCKET_EVENT_POS_MONITORING_INPUT + "-" + this.props.terminal.id, this.onMonitoringInputEventListener);
        }
        if (this.socketSubscription !== undefined) {
            this.socketSubscription.unsubscribe();
            this.socketSubscription = undefined;
        }
    }

    renderInputs() {
        const state = this.posState;
        const session = state.getSession();
        const hasCustomer = Util.isNumberExist(state.getSession().customerId);

        return (<>

            {this.state.showNewCustomerDialog && <div style={{ animation: "monitor-checkout-dialog-backdrop 500ms", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: '#00000080', zIndex: 1000000, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
                <div style={{
                    animation: "monitor-checkout-dialog 500ms",
                    width: 400, paddingTop: '3rem', paddingBottom: '3rem', borderRadius: 25, background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                }}>
                    <FaceAdd32 style={{ color: 'black', width: 128, height: 128 }} />

                    <h2 style={{ marginTop: '1rem' }}>Welcome!</h2>
                    <h4>We are excited you are joining us!</h4>

                    <div style={{ height: '3rem' }} />

                    <div style={{ marginBottom: '1rem', width: '100%', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <TextInput
                            labelText="Full Name"
                            style={{ borderRadius: 50, borderBottom: 'none', border: 'solid', borderWidth: 1, borderColor: '#1c1c1c50' }}
                            value={this.state.newCustomer.fullName}
                            onChange={e => {
                                this.setState(prevState => ({ newCustomer: { ...prevState.newCustomer, ['fullName']: e.target.value } }))
                                Api.setUpdateValue(this.props.terminal.id, 'fullName', e.target.value, this.updateId, () => { })
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '1rem', width: '100%', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <TextInput
                            labelText="Email Address"
                            style={{ borderRadius: 50, borderBottom: 'none', border: 'solid', borderWidth: 1, borderColor: '#1c1c1c50' }}
                            value={this.state.newCustomer.email}
                            onChange={e => {
                                this.setState(prevState => ({ newCustomer: { ...prevState.newCustomer, ['email']: e.target.value } }))
                                Api.setUpdateValue(this.props.terminal.id, 'email', e.target.value, this.updateId, () => { })
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '1rem', width: '100%', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <TextInput
                            labelText="Phone Number"
                            style={{ borderRadius: 50, borderBottom: 'none', border: 'solid', borderWidth: 1, borderColor: '#1c1c1c50' }}
                            value={this.state.newCustomer.phoneNumber}
                            onChange={e => {
                                this.setState(prevState => ({ newCustomer: { ...prevState.newCustomer, ['phoneNumber']: e.target.value } }))
                                Api.setUpdateValue(this.props.terminal.id, 'phoneNumber', e.target.value, this.updateId, () => { })
                            }}
                        />
                    </div>
                </div>
            </div>}

            {this.state.showCustomerQrCode && hasCustomer &&
                <div onClick={() => this.setState({ showCustomerQrCode: false })} style={{ animation: "monitor-checkout-dialog-backdrop 500ms", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: '#00000080', zIndex: 1000000, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
                    {/* <div  style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}} /> */}
                    <div onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }} style={{
                        animation: "monitor-checkout-dialog 500ms", position: 'relative',
                        width: 600, maxWidth: '100%', paddingTop: '3rem', paddingBottom: '3rem', borderRadius: 25, background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Button style={{ position: 'absolute', top: 0, right: 0 }} kind="ghost"
                            onClick={() => this.setState({ showCustomerQrCode: false })} renderIcon={CloseFilled20} hasIconOnly className="bx--tooltip--hidden" />

                        {/* <Identification32 style={{color: 'black', width: 128, height: 128}} />

                <h2 style={{marginTop: '1rem', textAlign: 'center', paddingLeft: '1rem', paddingRight: '1rem'}}>{state.getSession().customerName}</h2>
                <h4 style={{}}>Customer Card</h4> */}

                        <div style={{ height: '3rem' }} />

                        <div ref={this.customerCardRef} style={{ backgroundColor: '#1c1c1c', color: 'white', borderRadius: 15, width: 500, maxWidth: '90%', height: 250, padding: '1rem', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                                <img src={LogoTransparent} style={{ height: 40, alignSelf: 'flex-start' }} />
                                <div style={{ flex: 1 }} />
                                <label className="bx--label" style={{ color: 'white', opacity: 0.80 }}>Customer Card</label>
                            </div>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                                <h5 style={{ flex: 1 }}>{state.getSession().customerName}</h5>
                                {Util.isStringExists(this.props.terminal.customerQrCode) &&
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem', background: "white" }}>
                                        <QRCode value={this.props.terminal.customerQrCode} size={128} />
                                    </div>}
                            </div>
                        </div>

                        {/* {Util.isStringExists(this.props.terminal.customerQrCode) &&
                <QRCode value={this.props.terminal.customerQrCode} size={256} />} */}


                        {this.isClientPhoneViewer() ? (
                            <div style={{ marginTop: '3rem', display: 'flex', gap: '0.05rem' }}>
                                <Button onClick={() => new EmailSender().sendCustomerCard(this.props.terminal.customerId)}
                                    kind="secondary" renderIcon={Email16}>Email me card</Button>

                                {/* <Button onClick={() => share(window.location.origin.toString() + Api.getCustomerCardPdf(this.props.terminal.customerUrlId), () => {
                            window.open(window.location.origin.toString() + Api.getCustomerCardPdf(this.props.terminal.customerUrlId), "_blank")
                        })}
                        renderIcon={WatsonHealthDownloadStudy16}>Save card</Button> */}
                            </div>
                        ) : (
                            <Button style={{ marginTop: '3rem' }} onClick={() => new EmailSender().sendCustomerCard(this.props.terminal.customerId)}
                                kind="secondary" renderIcon={Email16}>Email me card</Button>
                        )}

                    </div>
                </div>}

            {this.state.showSearchCustomerDialog && <div style={{ animation: "monitor-checkout-dialog-backdrop 500ms", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: '#00000080', zIndex: 1000000, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
                <div style={{
                    animation: "monitor-checkout-dialog 500ms",
                    width: 400, paddingTop: '3rem', paddingBottom: '3rem', borderRadius: 25, background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                }}>
                    <AirlinePassengerCare32 style={{ color: 'black', width: 128, height: 128 }} />

                    <h2 style={{ marginTop: '1rem' }}>Welcome Back!</h2>
                    <h4>Please enter your email/phone number</h4>

                    <div style={{ height: '3rem' }} />

                    <div style={{ marginBottom: '1rem', width: '100%', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <TextInput
                            placeholder="Type here..."
                            style={{ borderRadius: 50, borderBottom: 'none', border: 'solid', borderWidth: 1, borderColor: '#1c1c1c50' }}
                            value={this.state.searchCustomerValue}
                            onChange={e => {
                                this.setState({ searchCustomerValue: e.target.value })
                                Api.setUpdateValue(this.props.terminal.id, 'searchCustomerValue', e.target.value, this.updateId, () => { })
                            }}
                        />
                    </div>
                </div>
            </div>}
        </>)
    }

    renderView() {
        if (this.posState.isLoadingPos()) {
            return null;
        }

        if (this.state.loading) {
            return (
                <div style={{}}>
                    <Loading />
                </div>
            )
        }

        if (!this.posState.hasPos() || this.props.terminal.cashClosedFlag) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={Logo} style={{ height: 75, marginBottom: '7%' }} />

                    <Screen32 style={{ width: 200, height: 200, marginBottom: '1rem' }} />

                    <h1>Register is closed</h1>
                    <h5 style={{ fontSize: 18, marginTop: '0.5rem' }}>Please use next register</h5>
                </div>
            )
        }

        if (this.posState.isOnBreak()) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={Logo} style={{ height: 75, marginBottom: '7%' }} />

                    <div style={{ marginBottom: '1rem', position: 'relative' }}>
                        <ProfilePic size={200} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, this.posState.getSession().operatorId)} />
                        <div style={{
                            background: 'white', borderRadius: '50%', width: 64, height: 64, position: 'absolute', right: 4, bottom: 4,
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <Cafe32 />
                        </div>
                    </div>

                    <h1>Away at the moment</h1>
                    <h5 style={{ fontSize: 18, marginTop: '0.5rem' }}>I will be with you shortly!</h5>
                </div>
            )
        }

        const state = this.posState;
        const session = state.getSession();
        const hasCustomer = Util.isNumberExist(state.getSession().customerId);

        return (
            //<div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', flex: 1 }}>
                <div style={{ background: 'rgb(244, 244, 244)', display: 'flex', alignItems: 'center', paddingRight: '0.25rem', }}>
                    {/* <p>{this.props.terminal.cashClosedFlag + 'f'}</p> */}
                    {/* <div style={{ height: '100%', display: 'flex', alignItems: 'center', background: 'rgb(68,68,68)' }}> */}
                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', background: 'black', paddingInline: '1rem' }}>
                        <img src={LogoTransparent} style={{ height: 75 }} />
                    </div>
                    <ProfilePic size={45} style={{ marginLeft: '1rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, state.getSession().operatorId)} />
                    <div style={{ marginLeft: '0.5rem', flex: 1 }}>
                        <h6>{session.operatorName}</h6>
                        <p style={{ fontStyle: 'bold' }}>How may I help you today?</p>
                    </div>

                    <div>
                        <h5 style={{ marginRight: '1rem' }}>Scan to view on your phone!</h5>
                        <p style={{ fontSize: 14, fontStyle: 'italic', opacity: 0.65 }}>Tap to enlarge</p>
                    </div>
                    <div style={{ paddingTop: '0.25rem' }} onClick={() => UIUtil.showOverlay(
                        <QRCode size={256} value={window.location.origin.toString() + "/client-monitor/" + state.getSession().terminalId + "/" + state.getSession().currentCartId} />
                    )}>
                        <QRCode size={96} value={window.location.origin.toString() + "/client-monitor/" + state.getSession().terminalId + "/" + state.getSession().currentCartId} />
                    </div>
                </div>
                <div style={{ background: 'white', padding: '1rem' }}>
                    {hasCustomer ? (<>
                        <h2>Welcome, {state.getSession().customerName}!</h2>
                        {state.getSession().customerLoyaltyMoney > 0 && state.getSession().customerLoyaltyMoney.toFixed !== undefined &&
                            <p style={{ color: 'green' }}>You have AED {state.getSession().customerLoyaltyMoney.toFixed(2)} in loyalty points!</p>}
                    </>) : (
                        <h2>Welcome!</h2>
                    )}
                </div>
                {/* <div style={{flex: 1}}>
                    <CartContent state={this.posState} />
                </div> */}
                <div style={{ flex: 1, position: 'relative' }}>
                    <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                        <CartContent state={this.posState} customerFacing />
                    </div>
                </div>
                <div>
                    <PaymentWidget state={this.posState} monitorMode />
                </div>



                {this.state.showCheckoutDialog && <div style={{ animation: "monitor-checkout-dialog-backdrop 500ms", position: 'absolute', left: 0, top: 0, width: '100vw', height: '100vh', background: '#00000080', zIndex: 9998, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{
                        animation: "monitor-checkout-dialog 500ms",
                        width: 400, paddingTop: '3rem', paddingBottom: '3rem', borderRadius: 25, background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                        {!Util.isNumberExist(this.posState.getSession().lastTicketSaleId) &&
                            <CheckmarkFilled32 style={{ color: 'green', width: 128, height: 128 }} />}

                        <h2 style={{ marginTop: !Util.isNumberExist(this.posState.getSession().lastTicketSaleId) ? '1rem' : 0 }}>Thank you!</h2>
                        <h4>Come again next time</h4>



                        {(hasCapabilitySupport("cinema") && Util.isNumberExist(this.posState.getSession().lastTicketSaleId)) ? (<>
                            <div style={{ height: '1rem' }} />
                            <p style={{ opacity: 0.65, fontSize: 14, marginBottom: '1rem' }}>Scan to receive your tickets</p>
                            <QRCode size={150} value={window.location.origin.toString() + '/ticket/' + this.posState.getSession().lastTicketSaleId} />
                            <div style={{ height: '1rem' }} />
                        </>) : (<>
                            <div style={{ height: '3rem' }} />
                            {!this.state.feedbackSubmitted && <p style={{ opacity: 0.65, fontSize: 14, marginBottom: '1rem' }}>How was your experience today?</p>}
                            {this.state.feedbackSubmitted && <p style={{ opacity: 0.65 }}>Thank you for your feedback!</p>}
                            <div style={{ width: '100%', paddingLeft: '3rem', paddingRight: '3rem' }}>
                                <div style={{ width: '100%', padding: '1rem', background: 'black', borderRadius: 25, display: this.state.feedbackSubmitted ? 'none' : undefined }}>
                                    <HappinessSlider onSubmitted={value => {
                                        this.setState({ feedbackSubmitted: true })
                                        Api.rateTransaction(this.posState.getSession().lastTransactionId, this.posState.getSession().posSessionId, value, () => { })
                                    }} />
                                </div>
                            </div>
                            <div style={{ height: '3rem' }} />
                        </>)}




                        <div style={{ flexDirection: 'column', display: 'flex', gap: '0.05rem' }}>
                            <Button onClick={() => {
                                //new EmailSender().sendSalesReceipt(this.posSessionSnapshot.lastStockFlowRecordId)
                                new EmailSender().sendSalesReceipt(this.lastStockFlowRecordId)
                            }} kind="secondary" renderIcon={Email16}>Email me receipt</Button>
                        </div>

                        {/* <p style={{opacity: 0.65, fontSize: 14, marginBottom: '1rem'}}>How was your experience today?</p>
                        <div style={{width: '100%', paddingLeft: '3rem', paddingRight: '3rem'}}>
                            <div style={{width: '100%', padding: '1rem', background: 'black', borderRadius: 25}}>
                                <HappinessSlider onSubmitted={value => {
                                    this.setState({showCheckoutDialog: false, feedbackSubmitted: true})
                                    UIUtil.showSuccess("Thank you for your feedback!")
                                    Api.rateTransaction(this.posState.getSession().lastTransactionId, this.posState.getSession().posSessionId, value, () => {})
                                }} />
                            </div>
                        </div> */}



                    </div>
                </div>}

                {this.renderInputs()}
            </div>
        )
    }

    renderPhoneViewer() {
        if (this.posState.isLoadingPos()) {
            return null;
        }

        if (this.state.loading) {
            return (
                <div style={{}}>
                    <Loading />
                </div>
            )
        }

        if (!this.posState.hasPos() || this.props.terminal.cashClosedFlag) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '6rem', paddingBottom: '6rem', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flex: 1 }}>
                    <img src={Logo} style={{ height: 45, marginBottom: '6rem' }} />

                    <ErrorFilled32 style={{ width: 32, height: 32, marginBottom: '0.25rem' }} />

                    <p style={{ fontSize: 14, opacity: 0.65 }}>Session not available anymore</p>
                </div>
            )
        }

        if (!this.state.showCheckoutDialog && this.posState.getSession().currentCartId != this.props.restrictedCartId && !this.posState.getSession().cartIds.includes(this.props.restrictedCartId)) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '6rem', paddingBottom: '6rem', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flex: 1 }}>
                    <img src={Logo} style={{ height: 45, marginBottom: '6rem' }} />

                    <ErrorFilled32 style={{ width: 32, height: 32, marginBottom: '0.25rem' }} />

                    <p style={{ fontSize: 14, opacity: 0.65 }}>Session not available anymore</p>
                </div>
            )
        }


        if (!this.state.showCheckoutDialog && (this.posState.isOnBreak() || this.posState.getSession().currentCartId != this.props.restrictedCartId)) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '6rem', paddingBottom: '6rem', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flex: 1 }}>
                    <img src={Logo} style={{ height: 45, marginBottom: '6rem' }} />

                    <div style={{ marginBottom: '3rem', position: 'relative' }}>
                        <ProfilePic size={100} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, this.posState.getSession().operatorId)} />
                        <div style={{
                            background: 'white', borderRadius: '50%', width: 32, height: 32, position: 'absolute', right: 4, bottom: 4,
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <Cafe32 style={{ width: 16, height: 16 }} />
                        </div>
                    </div>

                    <h1 style={{ fontSize: 20 }}>Away at the moment</h1>
                    <p style={{ fontSize: 14, opacity: 0.65 }}>I will be with you shortly!</p>
                </div>
            )
        }


        const state = this.posState;
        const session = state.getSession();
        const hasCustomer = Util.isNumberExist(state.getSession().customerId);

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', flex: 1 }}>
                <img src={Logo} style={{ height: 75, background: 'black', objectFit: 'contain', objectPosition: 'center' }} />

                {this.state.showCheckoutDialog ? (<>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', paddingTop: '3rem', alignItems: 'center' }}>
                        <CheckmarkFilled32 style={{ color: 'green', width: 64, height: 64 }} />

                        <h4 style={{ marginTop: '1rem' }}>Thank you!</h4>
                        <h4 style={{ fontSize: 14 }}>Come again next time</h4>

                        <div style={{ height: '3rem' }} />

                        {!this.state.feedbackSubmitted && <p style={{ opacity: 0.65, fontSize: 14, marginBottom: '1rem' }}>How was your experience today?</p>}
                        {this.state.feedbackSubmitted && <p style={{ opacity: 0.65 }}>Thank you for your feedback!</p>}
                        <div style={{ width: '100%', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div style={{ width: '100%', padding: '1rem', background: 'black', borderRadius: 25, display: this.state.feedbackSubmitted ? 'none' : undefined }}>
                                <HappinessSlider onSubmitted={value => {
                                    this.setState({ feedbackSubmitted: true })
                                    Api.rateTransaction(this.posState.getSession().lastTransactionId, this.posState.getSession().posSessionId, value, () => { })
                                }} />
                            </div>
                        </div>

                        <div style={{ height: '3rem' }} />
                        <div style={{ flexDirection: 'column', display: 'flex', gap: '0.05rem' }}>
                            <div style={{ width: '100%' }}>
                                <PosReceiptDownload posSessionSnapshot={this.posSessionSnapshot} />
                            </div>
                            <Button //onClick={() => new EmailSender().sendSalesReceipt(this.posSessionSnapshot.lastStockFlowRecordId)} 
                                onClick={() => new EmailSender().sendSalesReceipt(this.lastStockFlowRecordId)}
                                kind="secondary" renderIcon={Email16}>Email me receipt</Button>
                        </div>

                        {hasCapabilitySupport("cinema") && Util.isNumberExist(this.posState.getSession().lastTicketSaleId) && <>
                            <div style={{ height: '3rem' }} />
                            <Button renderIcon={ArrowRight16} onClick={() => window.open('/ticket/' + this.posState.getSession().lastTicketSaleId)} className='green-button' style={{ borderRadius: 7, maxWidth: 'unset', width: '75%' }}>Open Tickets</Button>
                            <div style={{ height: '3rem' }} />
                        </>}
                    </div>
                </>) : (<>
                    <div style={{ background: 'rgb(244, 244, 244)', display: 'flex', alignItems: 'center', paddingTop: '0.50rem', paddingBottom: '0.50rem' }}>
                        <ProfilePic size={45} style={{ marginLeft: '1rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, state.getSession().operatorId)} />
                        <div style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>
                            <h6>{session.operatorName}</h6>
                            <p style={{ fontStyle: 'bold' }}>How may I help you today?</p>
                        </div>
                        {WHATSAPP_TRACKER &&
                            <button
                                style={{ borderRadius: 7, paddingBottom: 7, width: 'unset', height: '50px' }}
                                className='lead-flow-icon-button lead-flow-icon-button-light-on-white' onClick={() => {
                                    const link = sendWhatsappMessage(WHATSAPP_TRACKER);
                                    window.open(link, "_blank")
                                }}>
                                <WhatsAppIcon size={48} />
                            </button>}
                        <div style={{ flex: 1 }} />

                        <Button className="bx--tooltip--hidden" style={{ marginRight: '0.5rem' }} renderIcon={Share16} hasIconOnly onClick={() => {
                            share(window.location.href, () => UIUtil.showInfo("Copy and send the link from the address bar"))
                        }} />
                    </div>

                    <div style={{ background: 'white', padding: '1rem' }}>
                        {hasCustomer ? (<>
                            <h2>Welcome, {state.getSession().customerName}!</h2>
                            {state.getSession().customerLoyaltyMoney > 0 &&
                                <p style={{ color: 'green' }}>You have AED {state.getSession().customerLoyaltyMoney.toFixed(2)} in loyalty points!</p>}
                        </>) : (
                            <h2>Welcome!</h2>
                        )}
                    </div>



                    <div style={{ flex: 1, position: 'relative' }}>
                        <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                            <CartContent state={this.posState} customerFacing />
                        </div>
                    </div>

                    <div>
                        <PaymentWidget hideTitle state={this.posState} mobileClientMode monitorMode />
                    </div>
                </>)}

                {this.renderInputs()}
            </div>
        )
    }

    render() {
        return this.isClientPhoneViewer() ? this.renderPhoneViewer() : this.renderView()
    }

}

export default TerminalMonitoringView;