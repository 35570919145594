import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import { OBJECT_TYPE_ACCOUNT } from "../../constants/ObjectTypes";
import TableTemplate from '../../templates/TableTemplate';
import { Enterprise16, Events16, OverflowMenuHorizontal16, Person16 } from '@carbon/icons-react';
import Button from '../../components/Button';

const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)

class UserListPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            selectedMode: -1,
        }
    }

    hasFilter = () => this.state.selectedMode != -1;

    filter = (item) => {
        const modeCondition = this.state.selectedMode == -1 || item.mode == this.state.selectedMode;

        return modeCondition;
    }

    renderFilterTab() {
        return (
            <>
                <div>
                    {/* <label className="bx--label" style={{ paddingLeft: '1rem' }}>User Type</label> */}
                    <div style={{ display: 'flex' }}>
                        <TabItem key={this.state.selectedMode + '-all-tab'} icon={Events16} title="All" selected={this.state.selectedMode == -1} onClick={() => this.setState({ selectedMode: -1 })} />
                        <TabItem key={this.state.selectedMode + '-employee-tab'} icon={Person16} title="Employees" selected={this.state.selectedMode == 0} onClick={() => this.setState({ selectedMode: 0 })} />
                        <TabItem key={this.state.selectedMode + '-other-tab'} icon={OverflowMenuHorizontal16} title="Others" selected={this.state.selectedMode == 1} onClick={() => this.setState({ selectedMode: 1 })} />

                    </div>
                </div>

                <div style={{ flex: 1 }} />
            </>
        )
    }

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Staff Users",
                    subTitle: "All staff users",
                    objectType: OBJECT_TYPE_ACCOUNT,
                    pagePath: "/staff-users/",
                    history: this.props.history,

                    customToolbarContent: this.renderFilterTab(),
                    filter: this.hasFilter() ? this.filter : undefined,
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(UserListPage);