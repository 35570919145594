import { hasCapabilitySupport } from "../../../../app/Capabilities";
import { withLoadablePageAndProps } from "../../../../base/Page";
import { REST_ORDER_TYPE } from "../../../../domain/rest-sales-order";
import Api from "../../../../session/Api";
import { FormSection, useFormField } from "../../../../templates/form/form";
import { FormDialog } from "../../../../templates/form/form-dialog";
import { CustomerAddress, NoDataCustomerAddress } from "../../../../views/customer/customer-address";

const CustomerAddressField = ({ store, payload, setHide }) => {
    const useField = (key, defValue) => useFormField({ skipFieldEffect: true }, store, key, defValue)

    const [value, setValue] = useField("selectedAddress", undefined);

    return (
        <NoDataCustomerAddress payload={{
            addresses: payload.addresses ?? [],
            deliveryLocations: payload.deliveryAreas
        }} customerId={payload.customerId} setShowingDialog={setHide} value={value} setValue={setValue} />
    )
}

const FormView = (form, payload, { setHide }) => {
    const { TextField, TextAreaField, SwitchField, ComboBoxField, CheckBoxField, CustomField, ExtField } = form;
    return (
        <div>
            <FormSection title={"Delivery Information"}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div style={{ flex: 1 }}>
                        <TextField fieldKey="name" title="Name" />
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField fieldKey="phone" title="Phone" />
                    </div>
                </div>
                {/* <TextField fieldKey="buildingName" title="Building Name" />
                <ComboBoxField fieldKey="deliveryArea" title="Delivery Area" options={payload.deliveryAreas} />
                <TextAreaField fieldKey="address" title="Address" /> */}
            </FormSection>

            <FormSection title={"Address"}>
                <ExtField childrenProps={{ payload, setHide }}>
                    {CustomerAddressField}
                </ExtField>
            </FormSection>

            <FormSection title={"Order Preferences"}>
                {hasCapabilitySupport("restaurant") && <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap' }}>
                    <CheckBoxField fieldKey="_pref_Avoid cutlery?" title="Avoid cutlery?" />
                    <CheckBoxField fieldKey="_pref_For parties?" title="For parties?" />
                    <CheckBoxField fieldKey="_pref_No bread?" title="No bread?" />
                    <CheckBoxField fieldKey="_pref_No yogurt?" title="No yogurt?" />
                    <CheckBoxField fieldKey="_pref_No services?" title="No services?" />
                    <CheckBoxField fieldKey="_pref_Add service?" title="Add service?" />
                    <CheckBoxField fieldKey="_pref_Add fresh vegetable" title="Add fresh vegetable" />
                </div>}

                <TextAreaField fieldKey="_pref_Additional Notes" title="Additional Notes" />
            </FormSection>
            <FormSection title={"Payment"}>
                <SwitchField fieldKey="paymentMethod" options={payload.paymentMethods} />
            </FormSection>
        </div>
    )
}


function View({ state, onClose, payload }) {
    const defaultValues = {
        name: payload.name,
        phone: payload.phone,
        paymentMethod: payload.paymentMethods[0]?.id,

        selectedAddress: payload.addresses?.[0],

        // buildingName: payload.buildingName,
        // deliveryArea: payload.deliveryArea,
        // address: payload.address,

    }

    const save = (data, listener) => {
        state.createSalesOrderWithoutPrint(REST_ORDER_TYPE.DELIVERY, {
            shippingAddress: {
                firstName: data.name,
                lastName: "",
                phoneNumber: data.phone,

                addressLineOne: data.selectedAddress?.addressLineOne,
                buildingName: data.selectedAddress?.buildingName,
                deliveryLocationId: data.selectedAddress?.deliveryLocationId,
                area: data.selectedAddress?.area,
            },
            deliveryLocationId: data.selectedAddress?.deliveryLocationId,
            preferredPaymentMethodId: data.paymentMethod,

            preferences: JSON.stringify(Object.entries(data).filter(([key]) => key.startsWith('_pref_')).map(([key, value]) => ({
                id: key.replace("_pref_", ''), label: key.replace("_pref_", ''), value: value
            })))

        }, () => {
            // success
            listener({ status: true })
        }, () => {
            listener({ status: false })
        })
    }

    return (
        <FormDialog size="lg" open skipToast saveApi={save} payload={payload} item={defaultValues} onClose={onClose} customPositiveLabel={"Confirm"} title={"Delivery Order"} formConfig={{ skipFieldEffect: true }}>
            {FormView}
        </FormDialog>
    )
}

export const CreateDeliveryOrderDialog = withLoadablePageAndProps(Api.getCreateDeliveryOrderInformation.bind(Api), View);