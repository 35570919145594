import { ComposedModal, ContentSwitcher, ModalBody, ModalFooter, ModalHeader, Switch, TextArea, TextInput } from "carbon-components-react";
import { ComboBoxField, DataListField } from "../../../../../components/ComboBoxField";
import { makeListInput } from "../../../../../components/list-input";
import { Close16, ListBoxes16, ArrowRight16 } from '@carbon/icons-react'
import Button from "../../../../../components/Button";
import UIUtil from "../../../../../util/UIUtil";
import { useState } from "react";
import Portal from "../../../../../util/Portal";
import { ItemForm } from "./item-form";
import { PackageForm } from "./package-form";
import { SHIPMENT_PACKAGE_CHARGE_TYPE } from "../..";
import { useMemo } from "react";
import useStore from "../../../../../hooks/useStore";
import { UploadField } from "../../../../../components/upload/upload-field";
import { StatelessDatePicker, StatelessDateTimePicker } from "../../../../../components/DateTimePicker";
import Util, { onEnterKeyPressed } from "../../../../../util/Util";
import { hasCapabilitySupport } from "../../../../../app/Capabilities";
import Api from "../../../../../session/Api";

const ImagesEditor = makeListInput(({ item, setItemValue, onRemove, SortHandler }) => {
    return (<>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%', padding: '1rem', paddingTop: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 7, }}>
            <UploadField dontClear imageMode uploadMsg="Select" selectMsg="Upload image" value={item.inspectionImage} setValue={value => setItemValue('inspectionImage', value)} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>
    </>)
}, () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
), false)

const CargoInventoryItemSelector = ({ item, setItemMultipleValues }) => {
    const [id, setId] = useState('');

    const onLoadItemBtn = async () => {
        const stopLoading = await UIUtil.pageLoader();
        try {
            // wait for 2 sceonds
            const [status, invItem] = await Api.try((api, listener) => api.getCargoInvItem(id, listener));
            if (!status) {
                return;
            }

            setItemMultipleValues({
                'fromCargoInvItemId': invItem.id,
                'quantity': 1,
                'weight': invItem.weight,
                'length': invItem.length,
                'width': invItem.width,
                'height': invItem.height,
                'remark': invItem.description
            })


        } finally {
            stopLoading();
        }
    }

    if (item.fromCargoInvItemId) {
        return <>
            <h6>Item ID: {item.fromCargoInvItemId}</h6>
            <p>Weight: {item.weight}</p>
            <p>Length: {item.length}</p>
            <p>Width: {item.width}</p>
            <p>Height: {item.height}</p>
        </>
    }

    return <>
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-end' }}>
            <TextInput style={{ background: '#f4f4f4' }} labelText="Cargo Inventory Item ID" value={id} onChange={e => setId(e.target.value)}
                {...onEnterKeyPressed(() => {
                    if (id) {
                        onLoadItemBtn();
                    }
                })}
            />
            <Button size="md" disabled={!id} onClick={onLoadItemBtn}>Load Item</Button>
        </div>
    </>
}

const ItemListEditor = makeListInput(({ parentPackage, item, setItemValue, setItemMultipleValues, onRemove, SortHandler, itemTypes, customers, consignees, exportFlow }) => exportFlow && hasCapabilitySupport("cargo") ? (
    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <SortHandler />
            <div style={{ flex: 1 }} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>

        <CargoInventoryItemSelector item={item} setItemMultipleValues={setItemMultipleValues} />
    </div>
) : (
    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <SortHandler />
            <div style={{ flex: 1 }} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
                <DataListField title="Consignor" options={Array.isArray(customers) ? customers : []} value={item.consignorId} setValue={value => setItemValue("consignorId", value ?? 0)} />
            </div>
            <div style={{ flex: 1 }}>
                <DataListField title="Consignee" options={Array.isArray(consignees) ? consignees : []} value={item.consigneeId} setValue={value => setItemValue("consigneeId", value ?? 0)} />
            </div>
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
                <ComboBoxField title="Type" options={Array.isArray(itemTypes) ? itemTypes : []} value={item.itemType} setValue={value => setItemValue("itemType", value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Quantity" value={item.quantity} onChange={e => setItemValue('quantity', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Unit Weight" value={item.weight} onChange={e => setItemValue('weight', e.target.value)} />
            </div>
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Display Quantity" helperText="Optional value that replaces the numerical quantity on the BL" value={item.displayQuantity} onChange={e => setItemValue('displayQuantity', e.target.value)} />
            </div>
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Length" value={item.length} onChange={e => setItemValue('length', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Width" value={item.width} onChange={e => setItemValue('width', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Height" value={item.height} onChange={e => setItemValue('height', e.target.value)} />
            </div>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Cargo Status" value={item.cargoStatus} onChange={e => setItemValue('cargoStatus', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Yard Location" value={item.yardLocation} onChange={e => setItemValue('yardLocation', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <StatelessDateTimePicker style={{ background: '#f4f4f4' }} labelText={"Cargo Arrival Date"} date={item.cargoArrivalDate} setDate={value => setItemValue('cargoArrivalDate', value)} />
            </div>
        </div>
        {parentPackage &&
            <div style={{ width: '100%', marginBottom: '1rem', padding: '1rem', borderRadius: 7, background: '#f4f4f4', border: '1px solid #00000020' }}>
                <h5>Scheduling</h5>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <div>
                        <h6>Cargo Arrival Date</h6>
                        <p style={{ fontSize: 14 }}>{Util.getDateOnlyWithoutYear(item.cargoArrivalDate)}</p>
                    </div>
                    <ArrowRight16 />
                    <div>
                        <h6>Cargo Loading Date</h6>
                        <p style={{ fontSize: 14 }}>{Util.getDateOnlyWithoutYear(parentPackage.loadOrUnloadDate)}</p>
                    </div>
                    <ArrowRight16 />
                    <div>
                        <h6>{'Days (arrival to loading)'}</h6>
                        <p style={{ fontSize: 14 }}>{Util.daysBetweenDates(item.cargoArrivalDate, parentPackage.loadOrUnloadDate)}</p>
                    </div>
                </div>
            </div>}

        <label style={{ marginTop: '1rem' }} className="bx--label">Item Details</label>
        <ItemForm item={item} setItemValue={setItemValue} />

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Additional Charge" value={item.additionalCharges} onChange={e => setItemValue('additionalCharges', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Charge Description" value={item.additionalChargesDescription} onChange={e => setItemValue('additionalChargesDescription', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <ComboBoxField title="Tax" options={[{ id: "0.05", value: "VAT 5%" }, { id: "0", value: "None 0%" }]} value={item.additionalChargesTaxRateOverride === null ? "0.05" : item.additionalChargesTaxRateOverride === 0.05 ? "0.05" : item.additionalChargesTaxRateOverride === 0 ? "0" : item.additionalChargesTaxRateOverride}
                    setValue={value => setItemValue("additionalChargesTaxRateOverride", value)} />
            </div>
        </div>

        <TextArea style={{ background: '#f4f4f4', marginBottom: '1rem' }} labelText="Remarks / Description of Goods" value={item.remark} onChange={e => setItemValue('remark', e.target.value)} />

        <label className="bx--label">Images</label>
        <div style={{ background: '#00000010', padding: '1rem', width: '100%', borderRadius: 10, }}>
            <ImagesEditor value={item.inspectionImages ?? []} setValue={value => {
                if (typeof value === "function") {
                    setItemValue('inspectionImages', value(item.inspectionImages ?? []))
                } else {
                    setItemValue('inspectionImages', value)
                }
            }} />
        </div>

        {/* <TextInput style={{ background: '#f4f4f4', marginBottom: '1rem' }} labelText="Ref no" value={item.refNo} onChange={e => setItemValue('refNo', e.target.value)} />

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Unit Type" value={item.typeOfUnit} onChange={e => setItemValue('typeOfUnit', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Unit Brand" value={item.unitBrand} onChange={e => setItemValue('unitBrand', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Unit Model" value={item.model} onChange={e => setItemValue('model', e.target.value)} />
            </div>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Color" value={item.color} onChange={e => setItemValue('color', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Year of Made" value={item.yearOfMade} onChange={e => setItemValue('yearOfMade', e.target.value)} />
            </div>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Chassis No" value={item.chassisNo} onChange={e => setItemValue('chassisNo', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Country of Origin" value={item.countryOfOrigin} onChange={e => setItemValue('countryOfOrigin', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Yard No" value={item.yardNumber} onChange={e => setItemValue('yardNumber', e.target.value)} />
            </div>
        </div> */}



        {/* <TextInput style={{ background: '#f4f4f4' }} labelText="Chargeable Amount" value={item.chargeAmount} onChange={e => setItemValue('chargeAmount', e.target.value)} /> */}
    </div>
), () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
))

const ItemsBtn = ({ item, setItemValue, itemTypes, customers, consignees, exportFlow }) => {
    const [visible, setVisible] = useState(false);
    const text = (item.items?.length ?? 0) + " Item" + (item.items?.length != 1 ? 's' : '')
    return (<>
        <Button renderIcon={ListBoxes16} style={{ height: 40, minHeight: "unset", maxWidth: "unset", width: '100%' }} onClick={() => setVisible(true)}>{text}</Button>

        <Portal>
            <ComposedModal size="lg" open={visible} onClose={() => setVisible(false)}>
                <ModalHeader label="Package" title="Items" />
                <ModalBody hasScrollingContent>
                    <ItemListEditor itemProps={{ itemTypes, customers, consignees, exportFlow, parentPackage: item }} value={item.items} setValue={value => {
                        if (typeof value === "function") {
                            setItemValue('items', value(item.items))
                        } else {
                            setItemValue('items', value)
                        }
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setVisible(false)} kind="secondary">Close</Button>
                </ModalFooter>
            </ComposedModal>
        </Portal>
    </>)
}

export const PackageListEditor = makeListInput(({ item, setItemValue, onRemove, SortHandler, packageTypes, itemTypes, customers, consignees, exportFlow }) => (
    <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <SortHandler />
            <div style={{ flex: 1 }} />
            <button onClick={onRemove} className='lead-flow-icon-button'>
                <Close16 />
            </button>
        </div>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
            <div style={{ flex: 1 }}>
                <ComboBoxField title="Package Type" options={Array.isArray(packageTypes) ? packageTypes : []} value={item.packageTypeId} setValue={value => setItemValue("packageTypeId", value)} />
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label">Charge Amount Calculation</label>
                <ContentSwitcher selectedIndex={item.chargeType} onChange={e => setItemValue('chargeType', e.index)}>
                    <Switch value={SHIPMENT_PACKAGE_CHARGE_TYPE.CALC} text="Automatic" />
                    <Switch value={SHIPMENT_PACKAGE_CHARGE_TYPE.MANUAL} text="Manual" />
                </ContentSwitcher>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', height: 64 }}>
                <ItemsBtn item={item} setItemValue={setItemValue} itemTypes={itemTypes} customers={customers} consignees={consignees} exportFlow={exportFlow} />
            </div>
        </div>
        {/* <label style={{ marginTop: '1rem' }} className="bx--label">Package Details</label> */}
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Container No" value={item.containerNo} onChange={e => setItemValue('containerNo', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Seal No" value={item.sealNo} onChange={e => setItemValue('sealNo', e.target.value)} />
            </div>
        </div>

        <PackageForm item={item} setItemValue={setItemValue} />

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem', gap: '1rem' }}>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Additional Charge" value={item.additionalCharges} onChange={e => setItemValue('additionalCharges', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <TextInput style={{ background: '#f4f4f4' }} labelText="Charge Description" value={item.additionalChargesDescription} onChange={e => setItemValue('additionalChargesDescription', e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <ComboBoxField title="Tax" options={[{ id: "0.05", value: "VAT 5%" }, { id: "0", value: "None 0%" }]} value={item.additionalChargesTaxRateOverride === null ? "0.05" : item.additionalChargesTaxRateOverride === 0.05 ? "0.05" : item.additionalChargesTaxRateOverride === 0 ? "0" : item.additionalChargesTaxRateOverride}
                    setValue={value => setItemValue("additionalChargesTaxRateOverride", value)} />
            </div>
        </div>

        <TextArea style={{ background: '#f4f4f4', marginBottom: '1rem' }} labelText="Remarks" value={item.remark} onChange={e => setItemValue('remark', e.target.value)} />

        <label className="bx--label">Images</label>
        <div style={{ background: '#00000010', padding: '1rem', width: '100%', borderRadius: 10, }}>
            <ImagesEditor value={item.inspectionImages ?? []} setValue={value => {
                if (typeof value === "function") {
                    setItemValue('inspectionImages', value(item.inspectionImages ?? []))
                } else {
                    setItemValue('inspectionImages', value)
                }
            }} />
        </div>
    </div>
), () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
), false, {
    chargeType: SHIPMENT_PACKAGE_CHARGE_TYPE.MANUAL
})


export const LoadingModeFilteredPackageListEditor = ({ store, allPackageTypes, itemTypes, customers, consignees, exportFlow }) => {
    const [loadingModeId] = useStore(store, "loadingModeId")
    const [value, setValue] = useStore(store, "packages");

    const packageTypes = useMemo(() => allPackageTypes?.filter(type => type.loadingModeId === loadingModeId), [loadingModeId, allPackageTypes])

    return <PackageListEditor {...{ value, setValue, itemProps: { packageTypes, itemTypes, customers, consignees, exportFlow } }} />
}