import React from 'react'
import './GridListTransitionBtn.scss'

export default ({onClick, grid}) => (
    <div class="list-grid-toggle" onClick={onClick}>
        <div class="grid-list-icon" className={grid ? "grid-list-icon-grid" : ""}>
            <div class="grid-list-icon-bar"></div>
            <div class="grid-list-icon-bar"></div>
            <div class="grid-list-icon-bar"></div>
        </div>
        {/* <span class="label">List</span> */}
    </div>
)