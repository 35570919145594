import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { PlaneSea32, ArrowsVertical16, Download16, Upload16, ChartRelationship32 } from '@carbon/icons-react'
import { ComposedModal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { ShippingMasterFormFields } from "../../../pages/shipping/master/form-fields";

export const ShipmentMasterList = {
    filter: Filter,
    icon: ChartRelationship32,

    customPath: "/shipment-master",
    newDialog: NewDialog,

    itemToOpenLink: item => `/shipment-master/${item.id}`,
    isListMode: true,
    openInSameTab: true,

    isAvailable: () => {
        const role = getAccountRole();
        return hasCapabilitySupport("shipping") && (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR);
    }
}

function NewDialog({ endpoint, visible, onClose, onSuccess }) {
    return (
        <FormDialog
            size="lg"
            setItem={item => onSuccess(item)} label="Shipment Master" title="Create New"
            saveApi={(data, listener) => Api.createShipmentMaster(data, listener)}

            open={visible} onClose={onClose} item={{}} payload={endpoint}>
            {ShippingMasterFormFields}
            {/* {({ TextField, SwitchField, }) => (<>
                <TextField title="Content" fieldKey="content" />
            </>)} */}
        </FormDialog>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="dateCreated" />

        <div className="list-mode-divider-quick-filter-bar" />
        <div style={{ flex: 2 }}>
            <TextBox state={state} label="Master No" property="masterNo" fullWidth />
        </div>

        {/* <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ paddingInline: '0.5rem' }}>
            <div style={{ borderRadius: 7, background: '#00000005', border: '1px solid #00000010', display: 'flex', alignItems: 'center', padding: '0.15rem', gap: '0.25rem' }}>
                <button data-active={true} className='report-engine-menu-button-light'><ArrowsVertical16 /></button>
                <button data-active={false} className='report-engine-menu-button-light'><Download16 /></button>
                <button data-active={false} className='report-engine-menu-button-light'><Upload16 /></button>
            </div>
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="shipmentMasterListStatusField" options={['Akbar Abdi', 'System Admin']} state={state} property="fullName" />
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <TextBox state={state} label="Shipment No" property="title" fullWidth />
        </div> */}
    </>)
}
