import { forwardRef, useEffect, useRef, useState } from "react"
import { useForceLockScroll } from "../hooks/useLockScroll";
import Button from "../components/Button";
import { useFrameConnection } from "./useFrameConnection";
import { ONLINE_STORE_URL } from "../app/Config";
import { InlineLoading } from "carbon-components-react";

export function FrameTest() {
    const [frameRef, connection] = useFrameConnection({
        getDate: ({ format }) => {
            return format + "26th June";
        }
    });

    useForceLockScroll(true)
    return (<>
        {/* <Button onClick={async () => {
            const time = await connection.getTime({ format: "this is format" })
            alert("Got time: " + time);
        }}>Click me</Button> */}

        <Frame ref={frameRef} path="/backend" />
    </>)
}

export const Frame = forwardRef(({
    path, background = "#ffffff"
}, ref) => {
    const [loaded, setLoaded] = useState(false);
    const onLoad = () => {
        setLoaded(true);
    }
    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', }}>
            <iframe onLoad={onLoad} ref={ref} style={{ width: '100%', height: '100%' }} src={ONLINE_STORE_URL + path} />

            {!loaded && <div className="centered-progress-bar" style={{ width: '100%', height: '100%', top: 0, left: 0, position: 'absolute', zIndex: 500, background: background, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>}
        </div>
    )
})