

export const TRANSPORTATION_LEG_STATUS = {
    STATUS_NOT_STARTED: 0,
    STATUS_STARTED: 1,
    STATUS_FINISHED: 2,
}

export const SHIPMENT_MASTER_STATUS = {
    STATUS_SCHEDULED: 0,
    STATUS_TRANSPORTING: 1,
    STATUS_AT_DESTINATION: 2,
}


const TRANSPORTATION_LEG_STATUS_LABEL = {
    [TRANSPORTATION_LEG_STATUS.STATUS_NOT_STARTED]: "Not started",
    [TRANSPORTATION_LEG_STATUS.STATUS_STARTED]: "Started",
    [TRANSPORTATION_LEG_STATUS.STATUS_FINISHED]: "Finished",
}

const SHIPMENT_MASTER_STATUS_LABEL = {
    [SHIPMENT_MASTER_STATUS.STATUS_SCHEDULED]: "Scheduled",
    [SHIPMENT_MASTER_STATUS.STATUS_TRANSPORTING]: "Transporting",
    [SHIPMENT_MASTER_STATUS.STATUS_AT_DESTINATION]: "At Destination",
}

const SHIPMENT_MASTER_STATUS_HEADER_STYLES = {
    [SHIPMENT_MASTER_STATUS.STATUS_SCHEDULED]: {
        background: "#f4f4f4",
        color: "black"
    },
    [SHIPMENT_MASTER_STATUS.STATUS_TRANSPORTING]: {
        background: "#0f62fe",
        color: "white"
    },
    [SHIPMENT_MASTER_STATUS.STATUS_AT_DESTINATION]: {
        background: "#006616",
        color: "white"
    },
}


export const transportationStatusLabel = status => TRANSPORTATION_LEG_STATUS_LABEL[status];
export const transportationStatusHeaderStyles = status => SHIPMENT_MASTER_STATUS_HEADER_STYLES[status] ?? {};
export const shipmentMasterStatusLabel = status => SHIPMENT_MASTER_STATUS_LABEL[status];