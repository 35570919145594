

import Button from "../../../components/Button";
import { Receipt16 } from '@carbon/icons-react'
import { Modal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { useState } from "react";
import UIUtil from "../../../util/UIUtil";
import { MainApp } from "../../../App";


export function PrintBillButton({ order, onUpdate, mainApp, label = "Print Bill" }) {
    const [printingBill, setPrintingBill] = useState(false)
    const onPrintBill = async () => {
        if (printingBill) {
            return;
        }

        const app = mainApp || MainApp.instance;

        if (app?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
            setPrintingBill(true);
            try {
                await app.thermalPrinter.driver.printBill(order)
                UIUtil.showSuccess();
            } catch (e) {
                // console.error(e);
                UIUtil.showError()
            } finally {
                setPrintingBill(false)
            }
        } else {
            UIUtil.showInfo("Printer not connected")
        }
    }

    return (<>
        <Button onClick={onPrintBill} loading={printingBill} kind={"secondary"} size="sm" style={{ borderRadius: 50 }} renderIcon={Receipt16}>{label}</Button>
    </>)
}


export function PrintBillButtonForOrderId({ orderId, mainApp }) {
    const [printingBill, setPrintingBill] = useState(false)
    const onPrintBill = async (e) => {
        e.stopPropagation()

        if (printingBill) {
            return;
        }

        const app = mainApp || MainApp.instance;

        if (app?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
            setPrintingBill(true);
            try {
                const order = await Api.asyncGetSalesOrder(orderId);
                await app.thermalPrinter.driver.printBill(order)
                UIUtil.showSuccess();
            } catch (e) {
                // console.error(e);
                UIUtil.showError()
            } finally {
                setPrintingBill(false)
            }
        } else {
            UIUtil.showInfo("Printer not connected")
        }
    }

    return (<>
        <Button onClick={onPrintBill} loading={printingBill} kind={"secondary"} size="sm" style={{ borderRadius: 50 }} renderIcon={Receipt16}>Print Bill</Button>
    </>)
}