import React from 'react'
import Page from '../../base/Page';

import {
    Calendar16,
    Classification32,
    ShoppingCart16,
    ChartCustom16
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, RadioTile, TableToolbarContent, TableToolbarSearch, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar
} from 'carbon-components-react';
import Api from '../../session/Api';

import { Link } from 'react-router-dom';
import Util from '../../util/Util';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STUDENT } from '../../constants/ObjectTypes';
import TransactionAgainstSelectionDialog from '../transaction/TransactionAgainstSelectionDialog';
import Button from '../../components/Button';
import { OutstandingReportPdfBtn } from '../../pdfs/outstanding-report/OutstandingReportPdf';

const HEADERS = [
    {
        header: "Name",
        key: "name"
    },
    {
        header: "Last Receipt Date",
        key: "lastReceiptVoucherDate"
    },
    {
        header: "Outstanding Sales Amount",
        key: "salesOutstandingAmount"
    },
    {
        header: "Misc Amount",
        key: "miscOutstandingAmount"
    },
    {
        header: "Total Outstanding Amount",
        key: "totalOutstandingAmount"
    },
    {
        header: "",
        key: "id"
    }
]


class OutstandingStudentReportPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            report: undefined,

            showUnpaidSalesList: undefined
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getStudentOutstandingReport(listener), report => ({ report }))
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.students) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.report.students) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);


        switch (header.key) {
            case "id":
                return (<>

                    {/* <Button
                        kind="danger"
                        renderIcon={ShoppingCart16}
                        onClick={() => this.setState({ showUnpaidSalesList: { id: row.id } })} size="sm">Unpaid Sales</Button> */}

                    <OutstandingReportPdfBtn report={row} />

                </>)

            case "name":
                return <Link target="_blank" onClick={(e) => e.stopPropagation()} to={getObjectTypeUrl(OBJECT_TYPE_STUDENT) + "/" + row.id}>{cell.value}</Link>

            case "salesOutstandingAmount": case "miscOutstandingAmount":
                return <> AED {parseFloat(cell.value).toFixed(2)} </>

            case "totalOutstandingAmount":
                return <strong> AED {parseFloat(cell.value).toFixed(2)} </strong>

            case "lastReceiptVoucherDate":
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            default:
                return cell.value;
        }
    }

    getLayout() {
        return (
            <div className="main-content">
                <div style={{ width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2, display: 'flex', alignItems: 'flex-start' }}>
                    <Classification32 style={{ width: 64, height: 64 }} />
                    <div style={{ marginLeft: '0.5rem', flex: 1 }}>
                        <h4>Total Amount Outstanding</h4>
                        <h3 style={{ color: 'red' }}>AED {this.state.report.totalAmountOutstanding.toFixed(2)}</h3>
                        <p>{this.state.report.totalSalesCount + ""} sale{this.state.report.totalSalesCount != 1 && 's'}</p>
                        <p>{this.state.report.students.length + ""} student{this.state.report.students.length != 1 && 's'}</p>
                    </div>
                </div>
                {/* <TransactionListView report={this.state.report} hideToolbar /> */}

                <DataTable rows={this.state.report.students} headers={HEADERS} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                        getSelectionProps,
                        selectedRows
                    }) => (
                        <TableContainer>
                            <TableToolbar>
                                <TableToolbarContent>
                                    <TableToolbarSearch placeholder={"Search Student"} onChange={onInputChange} />
                                </TableToolbarContent>
                            </TableToolbar>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            // onClick={(() => {
                                            //     // const item = this.getRow(row.id);
                                            //     this.props.history.push("/crv-creator", { staffId: row.id });
                                            // })} 
                                            key={row.id} {...getRowProps({ row })}
                                            style={{}}>
                                            {row.cells.map((cell, index) => (
                                                <TableCell style={{ textAlign: (index > 1) ? 'end' : undefined }} key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>

                {this.state.showUnpaidSalesList &&
                    <TransactionAgainstSelectionDialog
                        readOnly
                        isAgainstSale againstParentId={this.state.showUnpaidSalesList.id}
                        defaultSelection={[]}
                        onClose={() => this.setState({ showUnpaidSalesList: undefined })} />}
            </div>
        )
    }

}

export default OutstandingStudentReportPage;