import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import Button from "../../../components/Button";
import Api from "../../../session/Api";


export function ImagesDialog({ shipment, itemId, onClose }) {
    const images = shipment.packages.flatMap($ => $.items).filter($ => $.id === itemId).flatMap($ => $.inspectionImages)
    return (
        <ComposedModal size="md" open onClose={onClose}>
            <ModalHeader label="Item Inspection" title="Images" />
            <ModalBody hasScrollingContent style={{ paddingRight: '1rem' }}>
                {images.length === 0 && <p style={{ fontSize: 12, opacity: 0.65 }}>No images</p>}
                {images.map(image => (
                    <img style={{ objectFit: 'contain', objectPosition: 'center', width: '100%', marginBottom: '1rem' }} key={image.id} src={Api.downloadUploadFieldFileAsImage(image.inspectionImage)} />
                ))}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>
                    Done
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}