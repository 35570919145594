import { Loading, Tile, Button } from 'carbon-components-react'
import React from 'react'
import { ErrorFilled32, Home16 } from '@carbon/icons-react'

import { Link } from 'react-router-dom'

export default ({title, message, error404, hideGoHomeBtn}) => (
    <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Tile style={{paddingRight: 100}}>
            <ErrorFilled32 style={{width: 60, height: 60}} />
            <h1>{title !== undefined ? title : (error404 ? '404 Page not found' : 'An error occurred')}</h1>
            <p>{message !== undefined ? message : (error404 ? 'The requested URL was not found on this server' : 'Please try again later')}</p>

            {!hideGoHomeBtn && <>
            <br />
            <br />
            <br />
            <br />

            <Link to="/">
                <Button kind="primary" renderIcon={Home16}>Go Home</Button>
            </Link>
            </>}
        </Tile>
    </div>
)