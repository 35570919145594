
import { LicenseDraft24, Close16, Reset16, Checkmark16, ArrowLeft16 } from '@carbon/icons-react'
import { Tag } from 'carbon-components-react';
import { useRef } from 'react';
import Button from '../../components/Button';

function Tags({ tags, onTagClicked }) {
    if (!tags.length) {
        return <p style={{ fontSize: 14, opacity: 0.65 }}>No tags available</p>
    }

    return (<>
        <p style={{ fontSize: 12, paddingInline: '1rem' }}>click to add</p>
        {tags.map(tag => <Tag key={tag} type="high-contrast" onClick={() => onTagClicked(tag)}>{tag}</Tag>)}
    </>)
}

export function TemplateDesigner({ onClose, tags = [], value, setValue, changesMade, onResetBtn }) {
    const messageTextArea = useRef();
    return (
        <div style={{ paddingBlock: '6rem', paddingInline: '3rem' }}>
            <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem' }}>
                <LicenseDraft24 />
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>Template</h4>
                <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>

            <div style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05)', background: 'white', border: '1px solid #00000020', width: '100%', marginBottom: '6rem' }}>
                <div style={{ height: '3rem', display: 'flex', paddingInline: '1rem', alignItems: 'center', background: '#00000010', borderBottom: '1px solid #00000020', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Message:</p>
                    <div style={{ flex: 1 }} />
                    {/* <p style={{ fontSize: 10, opacity: 0.65 }}>tags</p> */}
                    <Tags tags={tags} onTagClicked={tag => {
                        setValue(prev => prev + "{" + tag + "}")
                        messageTextArea.current?.focus();
                    }} />
                </div>
                <div style={{ width: '100%', borderBottom: '1px solid #00000020', borderRadius: 10, overflow: 'hidden' }}>
                    <textarea ref={messageTextArea} value={value} onChange={e => setValue(e.target.value)} style={{ padding: '1rem', border: 'none', outline: 'none', width: '100%' }} rows={12} placeholder="Input template..." />
                </div>
            </div>

            {<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                {changesMade && <Button onClick={onResetBtn} renderIcon={Reset16} size="sm" kind="ghost" style={{ borderRadius: 25 }}>Reset to Default</Button>}
                <Button onClick={onClose} renderIcon={Checkmark16} size="sm" kind="primary" style={{ borderRadius: 25 }}>Done</Button>
            </div>}
        </div>
    )
}