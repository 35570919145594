import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import Api from "../../../session/Api";
import { getAccountRole } from "../../../session/SessionManager";
import UIUtil from "../../../util/UIUtil";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select";
import { TextBox } from "../../components/basic-filter/text-box"
import { ImagesDialog } from "./images-dialog";

export const LoadingListReport = {
    filter: Filter,
    itemToOpenLink: item => `/shipment-job/${item.shipmentId}`,
    isAvailable: () => {
        const role = getAccountRole();
        return hasCapabilitySupport("shipping") && (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR);
    },


    onAction: async (action, data, history) => {
        switch (action) {
            case "images":
                const { item: shipment } = await Api.asyncGetShipment(data.shipmentId);
                UIUtil.showOverlay2(onClose => (
                    <ImagesDialog shipment={shipment} itemId={data.itemId} onClose={onClose} />
                ))
                return;
        }
    },
}

const STATUS = ["Scheduled", "Transporting", "At Destination"]

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="scheduledArrivalDate" />
        <Divider />

        <TextBox state={state} label="Master" property="masterNo" />
        <TextBox state={state} label="Job" property="shipmentNo" />
        <TextBox state={state} label="Customer" property="customerName" />
        <Divider />

        <MultiSelect isMulti={false} placeholder="Status" fieldKey="loadingListReportStatus" options={STATUS} state={state} property="status" />
        <MultiSelect isMulti={false} placeholder="Origin" fieldKey="loadingListReportOrigin" options={endpoint.ports.map(item => item.value)} state={state} property="origin" />
        <MultiSelect isMulti={false} placeholder="Destination" fieldKey="loadingListReportDestination" options={endpoint.ports.map(item => item.value)} state={state} property="destination" />

        {/* <DateRange state={state} property="salesDate" />
        <TextBox state={state} label="Source" property="sourceName" />
        <TextBox state={state} label="Customer" property="customerName" />
        <TextBox state={state} label="Product" property="products" /> */}

    </>)
}
