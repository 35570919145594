import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util, { isV2 } from "../../../util/Util"
import { getReportLink } from "../../base/report-page"
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { DateRange, EndDateOnly } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect } from "../../components/basic-filter/multi-select"

// current
// 01_30
// 31_60
// 61_90
// 91_PS
// TOTAL

const customerDetail = (customer, start, end, dueStart, dueEnd) => {
    const filters = [
        { "id": "customerName", "operator": "OR", "filters": [{ "property": "customerName", "operator": "EQ", "value": customer }] }
    ];
    if (Util.isNumberExist(start)) {
        filters.push(
            { "property": "date", "operator": "GTE", "id": "date-start", "value": start },
        )
    }
    if (Util.isNumberExist(end)) {
        filters.push(
            { "property": "date", "operator": "LT", "id": "date-end", "value": end }
        )
    }

    if (Util.isNumberExist(dueStart)) {
        filters.push(
            { "property": "due_date", "operator": "GTE", "id": "due-date-start", "value": dueStart },
        )
    }
    if (Util.isNumberExist(dueEnd)) {
        filters.push(
            { "property": "due_date", "operator": "LT", "id": "due-date-end", "value": dueEnd }
        )
    }

    return { filters, "customerField": { "label": customer } }
}

export const CustomerAgingSummary = {
    filter: Filter,
    freezeCol: 1,
    onAction: (action, data, history) => {
        switch (action) {
            case "current":
                history.push(getReportLink("CustomerBalanceDetail", customerDetail(
                    data.name, data['START_FILTER'], data['END_FILTER'], data['R_CURNT_0'], data['R_CURNT_1']
                )))
                return;
            case "01_30":
                history.push(getReportLink("CustomerBalanceDetail", customerDetail(
                    data.name, data['START_FILTER'], data['END_FILTER'], data['R_01_30_0'], data['R_01_30_1']
                )))
                return;
            case "31_60":
                history.push(getReportLink("CustomerBalanceDetail", customerDetail(
                    data.name, data['START_FILTER'], data['END_FILTER'], data['R_31_60_0'], data['R_31_60_1']
                )))
                return;
            case "61_90":
                history.push(getReportLink("CustomerBalanceDetail", customerDetail(
                    data.name, data['START_FILTER'], data['END_FILTER'], data['R_61_90_0'], data['R_61_90_1']
                )))
                return;
            case "91_PS":
                history.push(getReportLink("CustomerBalanceDetail", customerDetail(
                    data.name, data['START_FILTER'], data['END_FILTER'], data['R_91_PS_0'], data['R_91_PS_1']
                )))
                return;
            case "TOTAL":
                history.push(getReportLink("CustomerBalanceDetail", customerDetail(
                    data.name, data['START_FILTER'], data['END_FILTER']
                )))
                return;
        }
    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        <EndDateOnly state={state} property="date" padWorkingHours />
        <Divider />
        <div style={{ height: 30, flex: 1 }}>
            <MultiSelect isMulti={false} placeholder="Customer" key="customerField" fieldKey="customerField" options={endpoint.customers.map(item => item.value)} state={state} property="customerName" />
        </div>
        <div style={{ height: 30, marginTop: '0rem', flex: 2 }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
