import React from 'react'
import Page from '../../base/Page';
import StockFlowListView from './StockFlowListView';

class StockFlowsPage extends Page {

    render() {
        return (
            <div className="main-content">
                <StockFlowListView forcePurchaseFilter={this.props.forcePurchaseFilter} forceSalesFilter={this.props.forceSalesFilter} />
            </div>
        )
    }

}

export default StockFlowsPage;