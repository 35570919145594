import { ComboBox, ContentSwitcher, InlineLoading, Switch, TextInput } from 'carbon-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react'
//import MultipleInputEditor, { useMultiInputState } from '../../components/MultipleInputEditor'

import { RowDelete16, Code16 } from '@carbon/icons-react'
import MultipleInputEditor, { useMultiInputState } from '../../../components/MultipleInputEditor';
import Button from '../../../components/Button';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';

const Record = ({ input, onInputUpdate, nonExistent, onInputRemoved, options }) => {
    const [name, setName] = useMultiInputState(input, 'name', onInputUpdate);
    const [seatingPlanId, setSeatingPlanId] = useMultiInputState(input, 'seatingPlanId', onInputUpdate);

    return (
        <div style={{ display: 'flex', width: '100%', gap: 5 }}>
            <div style={{ flex: 1 }}>
                <TextInput size="lg" labelText="Name" style={{ width: '100%' }} value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
                <ComboBox style={{ width: '100%' }}
                    size='lg' titleText="Theater Design"
                    items={options} itemToString={item => item?.value ?? ""}
                    selectedItem={options.find(item => item.id === seatingPlanId)}
                    onChange={e => setSeatingPlanId(e.selectedItem?.id ?? 0)} />
            </div>

            <Button kind="danger" hasIconOnly renderIcon={RowDelete16} size="lg" iconDescription="Remove"
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} />
        </div>
    )
}

export const CinemaHallsListEditor = ({ defaultItems, onUpdate }) => {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([])

    useEffect(() => {
        setLoading(true)
        Api.getCinemaSeatPlans(response => {
            if (response.status === true) {
                setOptions(response.payload)
                setLoading(false)
            } else {
                UIUtil.showError("Failed to load theater designs")
            }
        })
    }, [])

    if (loading) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    }

    return (
        <MultipleInputEditor defaultInputs={defaultItems} onInputsUpdated={onUpdate} ItemComponent={Record} options={options} />
    )
}