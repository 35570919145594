
import {
    DocumentBlank16,
    Folder16,
    DocumentPdf16,
    Image16,
    VideoChat16,
    DocumentUnknown16,
    ArrowRight16,
    Notebook16
} from '@carbon/icons-react'
import { ADVANCED_NOTES_TYPE } from '..';

export function Icon({ type, treeMode, ...props }) {
    switch (type) {
        case ADVANCED_NOTES_TYPE.FOLDER:
            return treeMode ? <ArrowRight16 {...props} /> : <Folder16 {...props} />
        case ADVANCED_NOTES_TYPE.PDF:
            return <DocumentPdf16 {...props} />
        case ADVANCED_NOTES_TYPE.IMAGE:
            return <Image16 {...props} />
        case ADVANCED_NOTES_TYPE.VIDEO:
            return <VideoChat16 {...props} />
        case ADVANCED_NOTES_TYPE.FILE:
            return <DocumentBlank16 {...props} />
        case ADVANCED_NOTES_TYPE.NOTE:
            return <Notebook16 {...props} />
        default:
            return <DocumentUnknown16 {...props} />
    }
}