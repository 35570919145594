
export const saveAccount = account => window.localStorage.setItem('as', JSON.stringify(account));

export const clearAccount = () => window.localStorage.removeItem('as');

export const getAccountRole = () => {
    const account = getAccount();
    if (account !== undefined && account !== null) {
        return account.type;
    } else {
        return -1;
    }
}

export const getAccount = () => JSON.parse(window.localStorage.getItem('as'));

export const isLoggedIn = () => getAccount() !== null;

export const hasPrivilege = (privilege) => {
    const account = getAccount();
    return account !== null && account.role == privilege;
}

export const saveTerminal = terminal => window.localStorage.setItem('tm', JSON.stringify(terminal));

export const clearTerminal = () => window.localStorage.removeItem('tm');

export const getTerminal = () => JSON.parse(window.localStorage.getItem('tm'));

export const hasTerminal = () => getTerminal() !== null;

export const isPosEditMode = () => window.localStorage.getItem('editPos') == "true" ? true : false;

export const setPosEditMode = locked => window.localStorage.setItem('editPos', locked);

export const getPosLayout = () => window.localStorage.getItem('posLayout');

export const setPosLayout = layout => window.localStorage.setItem('posLayout', layout)





export const saveThermalPrinterDriverId = id => window.localStorage.setItem('tpdi', id);

export const clearThermalPrinterDriverId = () => window.localStorage.removeItem('tpdi');

export const getThermalPrinterDriverId = () => window.localStorage.getItem('tpdi');

export const hasThermalPrinterDriverId = () => getThermalPrinterDriverId() !== null;



export const saveThermalPrinterParams = params => window.localStorage.setItem('tpdiparams', JSON.stringify(params));

export const clearThermalPrinterParams = () => window.localStorage.removeItem('tpdiparams');

export const getThermalPrinterParams = () => {
    const item = window.localStorage.getItem('tpdiparams')
    return JSON.parse(item !== undefined && item !== null ? item : "{}");
};

export const hasThermalPrinterParams = () => getTerminal() !== null;



export const saveTheme = theme => window.localStorage.setItem('theme', theme);

export const getTheme = () => window.localStorage.getItem('theme');