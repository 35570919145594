
import { ButtonSet, ClickableTile, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import { CheckmarkFilled16, ErrorFilled16, Save16, ArrowRight16, } from '@carbon/icons-react'



export function RenameGroupDialog({ open, onClose, currentTitle, onRename }) {
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (open) {
            setTitle(currentTitle)
        }
    }, [currentTitle, open])

    return (
        <ComposedModal
            data-no-dnd="true"
            key="save-state-dialog"
            open={open}
            onClose={onClose}
            size="sm">
            <ModalHeader label={"Group"} title="Rename" />
            <ModalBody style={{ paddingRight: '1rem' }}>
                <TextInput
                    data-modal-primary-focus
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    labelText="Title"
                // placeholder="Optional title"
                />
            </ModalBody>
            <ModalFooter>
                <ButtonSet style={{ width: '100%' }}>
                    <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                        Cancel
                    </Button>
                    <Button onClick={() => onRename(title)} renderIcon={CheckmarkFilled16}>
                        Confirm
                    </Button>
                </ButtonSet>
            </ModalFooter>
        </ComposedModal>
    )
}