
import React from 'react';
import { Page, Text, View, Document, StyleSheet, pdf, usePDF, Image } from '@react-pdf/renderer';
import print from 'print-js';
import { createRawSvgBarcode, createSvgBarcode } from '../../../pages/label-designer/engine/CanvasManger';
import logo from '../../../images/other/romario-adidas/logo.png'
import rahela from '../../../images/other/romario-rahela/logo.png'
import runners from '../../../images/other/romario-runners/r-logo.png'
import { INVOICE_ALT_PDF } from '../../../app/Config';
import Util from '../../../util/Util';
import Api from '../../../session/Api';
import { OBJECT_TYPE_PRODUCT_BUNDLE } from '../../../constants/ObjectTypes';
import { isRahela } from '../../../app/app-util';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingBottom: 115,
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        lineHeight: 1.5,
        flexDirection: 'column',
    },

});

const PDF = ({ packingList, cartonNoBarcode, itemBarcodes }) => {


    return (
        <Document>
            <Page size="A5" style={styles.page}>
                <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 16 }}>{packingList.customerName ?? ''}</Text>
                <View style={{ flexDirection: 'row', gap: '1rem' }}>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12 }}>Location: {packingList.location ?? ''}</Text>
                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12 }}>INV: {packingList.invoiceNo ?? ''}</Text>
                        {isRahela() && <>
                            <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12 }}>Size: {packingList.size ?? ''}</Text>
                            <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12 }}>Weight: {packingList.weight ?? ''} KG</Text>
                        </>}
                    </View>
                    <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, textAlign: 'right' }}>Carton No: {packingList.cartonNo ?? ""}</Text>
                        <Image src={cartonNoBarcode} style={{ objectFit: 'contain', objectPosition: 'end', }} />
                    </View>
                </View>

                <View style={{ flexDirection: 'row', marginTop: '3rem' }}>
                    <Text style={{ fontSize: 12, flex: 2 }}>Art No.</Text>
                    <Text style={{ fontSize: 12, flex: 1 }}>Size</Text>
                    <Text style={{ fontSize: 12, flex: 1 }}>Qty</Text>
                    <Text style={{ fontSize: 12, flex: 2 }}>Barcode</Text>
                    <Text style={{ fontSize: 12, flex: 1 }}>COO</Text>
                </View>
                {packingList.items.map((item, index) => (
                    <View wrap={false} style={{ flexDirection: 'row', marginTop: '1rem', alignItems: 'center', height: 35 }}>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 2, justifyContent: 'center' }}>{item.articleNo ?? '-'}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 1, justifyContent: 'center' }}>{item.size ?? '-'}</Text>
                        {/* <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 1, justifyContent: 'center' }}>{item.qty ?? '-'}</Text> */}
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 1, justifyContent: 'center' }}>{Util.formatAmountWithNoDigits(item.packedQty)}</Text>
                        <View style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 2, justifyContent: 'center' }}>
                            <Image src={itemBarcodes[index]} style={{ objectFit: 'contain', objectPosition: 'left center', height: 40, width: 100 }} />
                        </View>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', flex: 1, justifyContent: 'center' }}>{item.coo ?? '-'}</Text>
                    </View>
                ))}

                {INVOICE_ALT_PDF === "adidas-romario" && (
                    <View fixed style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, paddingBottom: 15 }}>
                        <View style={{ flex: 1, paddingRight: 15 }}>
                            <Image style={{ width: '100%', objectFit: 'contain', objectPosition: 'center' }} src={logo} />
                        </View>
                        <View style={{ flex: 1, paddingRight: 15 }}>
                            <Image src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, 123873)} style={{ width: '100%', height: 100, objectFit: 'contain', objectPosition: 'center', marginBottom: 5 }} />
                        </View>
                        <View style={{ flex: 1.5 }}>
                            <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 10, fontFamily: 'Montserrat', }}>{'Authorized B2B partner for'}</Text>
                            <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 10, fontFamily: 'Montserrat', paddingRight: 5 }}><Text style={{ fontWeight: 'bold' }}>adidas</Text> in GCC</Text>
                                <View style={{ flex: 1, height: 1, marginTop: -2, backgroundColor: 'rgb(14, 19, 24, 0.25)' }} />
                            </View>
                        </View>
                    </View>
                )}

                {INVOICE_ALT_PDF === "rahela-romario" && (
                    <View fixed style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', flexDirection: 'row', alignItems: 'center', paddingLeft: 30, paddingBottom: 30 }}>
                        <View style={{ flex: 1 }}>
                            <Image style={{ width: 145, objectFit: 'contain', objectPosition: 'center' }} src={rahela} />
                        </View>
                        {/* <View style={{ flex: 1 }}>
                            <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 12, fontFamily: 'Montserrat', }}>Authorized B2B partner for</Text>
                            <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 12, fontFamily: 'Montserrat', paddingRight: 5 }}><Text style={{ fontWeight: 'bold' }}>adidas</Text> in GCC</Text>
                                <View style={{ flex: 1, height: 1, marginTop: -2, backgroundColor: 'rgb(14, 19, 24, 0.25)' }} />
                            </View>
                        </View> */}
                    </View>
                )}

                {INVOICE_ALT_PDF === "runners-romario" && (
                    <View fixed style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', flexDirection: 'row', alignItems: 'center', paddingLeft: 30, paddingBottom: 30 }}>
                        <View style={{ flex: 1 }}>
                            <Image style={{ width: 145, objectFit: 'contain', objectPosition: 'center' }} src={runners} />
                        </View>
                        {/* <View style={{ flex: 1 }}>
                            <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 12, fontFamily: 'Montserrat', }}>Authorized B2B partner for</Text>
                            <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 12, fontFamily: 'Montserrat', paddingRight: 5 }}><Text style={{ fontWeight: 'bold' }}>adidas</Text> in GCC</Text>
                                <View style={{ flex: 1, height: 1, marginTop: -2, backgroundColor: 'rgb(14, 19, 24, 0.25)' }} />
                            </View>
                        </View> */}
                    </View>
                )}
            </Page>
        </Document>
    );
}


export const printPackingListPdf = async (packingList) => {
    const cartonNoBarcode = (await createRawSvgBarcode(Util.isStringExists(packingList.cartonNo) ? packingList.cartonNo : "00", "CODE128", "#000000", "#FFFFFF00", { width: 1000, height: 250 })).toDataURL();
    const itemBarcodes = []
    for (let i = 0; i < packingList.items.length; i++) {
        itemBarcodes[i] = (await createRawSvgBarcode(
            Util.isStringExists(packingList.items[i].barcode) ? packingList.items[i].barcode : "00",
            Util.isStringExists(packingList.items[i].barcodeType) ? packingList.items[i].barcodeType : "CODE128",

            "#000000", "#FFFFFF00",
            (Util.isStringExists(packingList.items[i].barcodeType) ? packingList.items[i].barcodeType : "CODE128") === "CODE128" ? { width: 1000, height: 250 } : { width: 1000, height: 600 },
            (Util.isStringExists(packingList.items[i].barcodeType) ? packingList.items[i].barcodeType : "CODE128") === "CODE128" ? false : true,
        )).toDataURL();
    }
    const blob = await pdf(<PDF packingList={packingList} cartonNoBarcode={cartonNoBarcode} itemBarcodes={itemBarcodes} />).toBlob();
    print(URL.createObjectURL(blob));
}