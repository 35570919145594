import { Button, ComboBox, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import Util from '../../../util/Util';

export default ({salesCart, open, onClose, onEdited}) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("")
    const [typeId, setTypeId] = useState(0)
    const [typePickerKey, setTypePickerKey] = useState(Util.newTempId())

    const onCreate = () => {
        setLoading(true)
        Api.updateSalesCart({
            id: salesCart.id, name, typeId
        }, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onEdited(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (open) {
            setName(salesCart.name)
            setTypeId(salesCart.typeId)
            setTypePickerKey(Util.newTempId())
        }
    }, [open])

    return (
        <ComposedModal className="modal-that-supports-combo-box" key="apply-coupon-dialog" size="sm" open={open} onClose={onClose}>
            <ModalHeader label="Editing Info" title="Sales Cart" />
            <ModalBody>
                <TextInput
                data-modal-primary-focus
                labelText="Name"
                value={name}
                onChange={e => setName(e.target.value)}
                />

                <div style={{height: '1rem'}} />
                <ComboBox
                light
                key={typePickerKey}
                titleText={"Select type"}
                items={salesCart.cartTypes}
                itemToString={item => item !== null ? item.name : ""} 
                selectedItem={salesCart.cartTypes.filter(item => item.id == typeId)[0]}
                onChange={e => {
                    setTypeId(e.selectedItem !== null ? e.selectedItem.id : 0)
                    setTypePickerKey(Util.newTempId())
                }} />
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button 
                disabled={loading || !Util.isStringExists(name)}
                onClick={onCreate}>
                    {loading ? "Loading..." : "Save"}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}