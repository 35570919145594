import React from 'react'
import Page from '../../base/Page';
import TransactionListView from './TransactionListView';

class TransactionsPage extends Page {

    render() {
        return (
            <div className="main-content">
                <TransactionListView />
            </div>
        )
    }

}

export default TransactionsPage;