import { FunctionMath16, UpToTop16, DownToBottom16, DataVis_216, VirtualColumn16, Filter16, Tools16, Moon16 } from '@carbon/icons-react'
import { useCallback, useMemo, useState } from 'react';
import useStore from '../../../hooks/useStore';
import Util from '../../../util/Util';
import { getReport } from '../../reports/reports';

function useHint() {
    const [message, setMessage] = useState("");
    return {
        message: Util.isStringExists(message) ? message : null,
        init: (message) => ({
            onMouseEnter: () => setMessage(message),
            onMouseLeave: () => setMessage("")
        })
    }
}

export function ListModeMenu({ reportName, store }) {
    const hint = useHint();

    const [showColumns, setShowColumns] = useStore(store, 'showColumns');
    const [showAdvancedFilter, setShowAdvancedFilter] = useStore(store, 'showAdvancedFilter');
    return (<>
        {hint.message && <p key={hint.message + 'report-toolbar-menu'} style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', animation: '250ms report-engine-menu-hint-fade-in' }}>
            {hint.message}
        </p>}
        <button {...hint.init("Columns")} onClick={() => setShowColumns(v => !v)} className='report-engine-list-mode-top-right-button'>
            <VirtualColumn16 />
        </button>
        <button {...hint.init("Advanced Filter")} onClick={() => setShowAdvancedFilter(v => !v)} className='report-engine-list-mode-top-right-button'>
            <Tools16 />
        </button>
    </>)
}