import { User32 } from '@carbon/icons-react';
import React from 'react'
import Page from "../../base/Page";
import { getAccount } from '../../session/SessionManager';

class NoPosPage extends Page {

    getLayout() {
        return (
            <div style={{ width: '100%', height: '100%', paddingLeft: '15rem', paddingRight: '15rem', paddingTop: '3rem', paddingBottom: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ background: '#f4f4f4', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '3rem' }}>
                    <User32 style={{ transform: 'scale(2)', marginLeft: 10 }} />
                    <h2 style={{ marginTop: '2rem' }}>Welcome, {getAccount().fullName}</h2>
                    <p>Venue master role required to start POS session</p>

                    {/* <Button onClick={() => this.props.mainApp.setState({showSetTerminalDialog: true})} style={{marginTop: '6rem'}} renderIcon={ArrowRight16}>Setup Terminal</Button> */}
                </div>
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default NoPosPage;