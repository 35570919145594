import {
    DndContext, closestCenter, pointerWithin, closestCorners, rectIntersection, DragOverlay, useDndMonitor, useDroppable, useDraggable,
    MouseSensor as LibMouseSensor,
    KeyboardSensor as LibKeyboardSensor,
    useSensor, useSensors, TouchSensor
} from '@dnd-kit/core';
import { SortableContext, useSortable, horizontalListSortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useEffect, useMemo, useState } from 'react';

const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

function shouldHandleEvent(element) {
    let cur = element

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false
        }
        cur = cur.parentElement
    }

    return true
}

class MouseSensor extends LibMouseSensor {
    static activators = [
        {
            eventName: 'onMouseDown',
            handler: ({ nativeEvent: event }) => {
                return shouldHandleEvent(event.target)
            }
        }
    ]
}

class KeyboardSensor extends LibKeyboardSensor {
    static activators = [
        {
            eventName: 'onKeyDown',
            handler: ({ nativeEvent: event }) => {
                return shouldHandleEvent(event.target)
            }
        }
    ]
}

function collisionDetection({ droppableContainers, active, ...args }) {
    const pointerCollisions = pointerWithin({
        ...args,
        active,
        droppableContainers: droppableContainers.filter(({ data }) => data?.current?.dragOption === true)
    });
    if (pointerCollisions.length > 0) {
        return pointerCollisions;
    }

    if (active.data?.current?.isColumn === true) {
        const rectCollisions = rectIntersection({
            ...args,
            active,
            droppableContainers: droppableContainers.filter(({ data }) => data?.current?.dragOption !== true && data?.current?.isColumn === true)
        })
        if (rectCollisions.length > 0) {
            return rectCollisions;
        }
    }

    //const cd = isSafari ? pointerWithin : rectIntersection; //crashes in Safari with closest center
    // const cd = isSafari ? pointerWithin : closestCenter; //crashes in Safari with closest center
    const cd = pointerWithin;

    // Compute other collisions
    return cd({
        ...args,
        active,
        droppableContainers: droppableContainers.filter(({ data }) => data?.current?.dragOption !== true)
    });
};


export function Column({ columnId, itemIds, children }) {
    // const { setNodeRef: ref, isOver } = useDroppable({
    //     id: columnId
    // })
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        active,
        isOver
    } = useSortable({
        id: columnId,
        data: {
            isColumn: true
        }
    });


    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: active?.id === columnId ? 0 : 1
    };

    return (
        <SortableContext items={itemIds} disabled={active?.data?.current?.isColumn === true} strategy={verticalListSortingStrategy}>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {children(false)}
            </div>
        </SortableContext>
    )
}

export function Item({ itemId, columnId, children }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        active
    } = useSortable({
        id: itemId,
        data: {
            columnId
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: active?.id === itemId ? 0 : 1
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}

export function Columns({ children, dragState, dragOverlay }) {
    const [active, setActive] = useState(null)

    useDndMonitor({
        onDragStart: event => {
            setActive(event.active)
        },
        onDragOver: event => {
            if (event.active?.data?.current?.isColumn === true) {
                return;
            }
            if (event.over?.data?.current?.dragOption === true) {
                return;
            }

            const { active, over } = event;

            const itemId = active.id;
            const itemColumnId = active.data.current.columnId;
            const overColumnId = over?.data?.current?.columnId ?? over?.id;

            if (!overColumnId) {
                return;
            }

            if (itemColumnId != overColumnId) {
                dragState.setItemGroup(itemId, overColumnId)
            }
        },
        onDragEnd: event => {
            setActive(null)

            if (event.active?.data?.current?.isColumn === true) {
                return;
            }
            if (event.over?.data?.current?.dragOption === true) {
                return;
            }

            const { active, over } = event;

            if (over?.data?.current?.columnId !== undefined) {
                if (active.id !== over.id) {
                    dragState.sortItem(active.id, over.id)
                }
            }
        }
    })

    return (<>
        {children}
        <DragOverlay>
            {active && dragOverlay(active)}
        </DragOverlay>
    </>)
}

export function DragOption({ dropId, onDropped, children }) {
    const { setNodeRef: ref, isOver } = useDroppable({
        id: dropId,
        data: {
            dragOption: true
        }
    });

    const [inDrag, setInDrag] = useState(false);
    useDndMonitor({
        onDragStart: () => setInDrag(true),
        onDragEnd: event => {
            setInDrag(false)

            if (event.over?.data?.current?.dragOption !== true) {
                return;
            }


            const isItem = event.active.data?.current?.columnId !== undefined;
            const id = event.active.id;

            onDropped(id, isItem)
        }
    })

    // console.log("id", id)
    return inDrag && (
        <div ref={ref}>
            {children(isOver)}
        </div>
    );
}

export function SortColumnView({ columnIds, dragState, children }) {
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
        // useSensor(KeyboardSensor)
    )
    return (
        <DndContext
            sensors={sensors}
            // collisionDetection={rectIntersection}
            collisionDetection={collisionDetection}
            //collisionDetection={isSafari ? pointerWithin : rectIntersection} //crashes in Safari with closest center
            onDragEnd={event => {
                if (event.active?.data?.current?.isColumn === false) {
                    return;
                }
                if (event.over?.data?.current?.dragOption === true) {
                    return;
                }

                const { active, over } = event;
                if (!over) {
                    return;
                }

                if (active.data?.current?.columnId === undefined && over?.data?.current?.columnId === undefined) {
                    if (active.id !== over.id) {
                        dragState.sortGroup(active.id, over.id)
                    }
                }
            }}
        >
            <SortableContext items={columnIds} strategy={horizontalListSortingStrategy}
            >
                {children}
            </SortableContext>
        </DndContext>
    )
}