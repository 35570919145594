import { useMemo, useState } from "react"
import { hasCapabilitySupport } from "../../../app/Capabilities"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import Api from "../../../session/Api"
import { getAccountRole } from "../../../session/SessionManager"
import UIUtil from "../../../util/UIUtil"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24, Checkmark16, ArrowRight24, ArrowRight16 } from '@carbon/icons-react'
import Button from "../../../components/Button"
import { Link, TextInput } from "carbon-components-react"
import { TableInput } from "../../../components/table-input"
import { FormSection } from "../../../templates/form/form"
import { DateRange2 } from "../../components/basic-filter/date-range"
import { MultiSelectListMode } from "../../components/basic-filter/multi-select"
import Util from "../../../util/Util"
import { openPdf } from "../../../markup-template-engine"
import { listDetailItemId } from "../../base/report-page"
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view"
import { OBJECT_TYPE_CARGO_ISSUE_FOR_TRANSFER, OBJECT_TYPE_CARGO_RECEIPT_FROM_TRANSFER } from "../../../constants/ObjectTypes"

export const CargoReceiptFromTransferList = newListDetail({
    title: "Cargo Receipt from Transfer",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    openDetailOnCreate: true,
    clearStateOnSave: true,
    skipInlinePadding: true,
    canDelete: false,
    skipCreateBtn: true,
    canSave: false,

    defaultValues: {
        date: Date.now(),
        items: [],
    },

    isAvailable: () => {
        if (!hasCapabilitySupport("cargo")) {
            return;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },
})

function ItemListField({ value, setValue, readonly }) {
    const cols = useMemo(() => [
        {
            key: "cargoItemId", title: "Item ID", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
        {
            key: "note", title: "Note (optional)", flex: 2, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ""} onChange={e => !readonly && setValue(e.target.value)} />
            }
        },
    ], [readonly])

    return <TableInput minimal columns={cols} value={value} setValue={setValue} readonly={readonly} hideDummy={readonly} />;
}

const StatusIndicator = ({ status }) => {
    const { text, color } = (() => {
        switch (status) {
            case "DRAFT": return {
                text: "Draft",
                color: "#ffcc00"
            }

            case "CONFIRMED": return {
                text: "Posted",
                color: "#0f62fe"
            }

            case "POSTED": return {
                text: "Receipt",
                color: "#00a300"
            }

            case "REVOKED": return {
                text: "Revoked",
                color: "#990000"
            }

            default: return {
                text: "Unknown",
                color: "#1c1c1c"
            }
        }
    })();

    return <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', paddingLeft: '0.25rem' }}>
            <div style={{ width: 6, height: 6, background: color, borderRadius: 50, boxShadow: `0 0 50px 15px ${color}CC` }} />
            <span>{text}</span>
        </div>
    </>
};

const InfoCard = ({ data }) => {
    const { } = data;

    return <>
        <div style={{
            borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
            display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            marginBottom: '2rem', marginTop: '-1rem'
        }}>
            <div>
                <h6>Status</h6>
                <StatusIndicator status={data.status} />
            </div>
            <div>
                <h6>Voucher No</h6>
                {Util.getVoucherNumber(data.id)}
            </div>
            <div>
                <h6>Created</h6>
                <em>By</em> {data.createdByName} <em>on</em> {Util.formatDate(data.createdOn, 'DD-MM')}
            </div>
            {(data.status === 'CONFIRMED' || data.status === 'POSTED') && <>
                <div>
                    <h6>Posted</h6>
                    <em>By</em> {data.confirmedByName} <em>on</em> {Util.formatDate(data.confirmedOn, 'DD-MM')}
                </div>
            </>}
            {data.status === 'POSTED' && <>
                <div>
                    <h6>Receipt</h6>
                    <em>By</em> {data.postedByName} <em>on</em> {Util.formatDate(data.postedOn, 'DD-MM')}
                </div>
            </>}
            <div>
                <h6>Document</h6>
                <Link style={{ cursor: 'pointer', lineHeight: 'unset' }} onClick={() => openPdf('CargoReceiptFromTransferDoc', data.id)}>Open PDF</Link>
            </div>
        </div>
    </>;
}


function Form({ endpoint, form, data }) {
    const readonly = data && data.status !== "DRAFT";
    const isPosted = data?.status === 'POSTED';

    if (!isPosted) {
        return <> </>;
    }

    return (
        <div>
            <div style={{ paddingInline: '3rem' }}>
                {data && <InfoCard data={data} />}
                {isPosted && <div style={{ marginBottom: '1rem', }}>
                    <Button size="md" style={{ borderRadius: 5 }} onClick={() => window.open(listDetailItemId("CargoIssueForTransferList", data.id))} renderIcon={ArrowRight16}>View CIT</Button>
                </div>}

                <div style={{ pointerEvents: readonly ? 'none' : undefined }} inert={readonly ? '' : undefined}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '3rem', }}>
                        <div style={{ flex: 1 }}>
                            <form.DateField fieldKey="date" title="Date" />
                        </div>
                        <div style={{ height: 40, display: 'flex', alignItems: 'center', marginBottom: '1rem', opacity: 0 }}>
                            <ArrowRight24 />
                        </div>
                        <div style={{ flex: 1 }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: '3rem', marginBottom: '3rem' }}>
                        <div style={{ flex: 1 }}>
                            <form.ComboBoxField fieldKey="fromCustomerId" title="From Customer" options={endpoint.customers} />
                        </div>
                        <div style={{ height: 40, display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                            <ArrowRight24 />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.ComboBoxField fieldKey="toCustomerId" title="To Customer" options={endpoint.customers} />
                        </div>
                    </div>

                </div>
            </div>

            <FormSection title={"Items"} titleStyle={{ paddingInline: '3rem' }}>
                <form.CustomField fieldKey="items" childrenProps={{ readonly }}>
                    {ItemListField}
                </form.CustomField>
            </FormSection>

            {data && <div style={{ paddingInline: '3rem' }}>
                <FormSection title={"Notes/Docs"}>
                    <div style={{ marginTop: '0rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                        <AdvancedNotesView title="" objectId={data.id} objectType={OBJECT_TYPE_CARGO_RECEIPT_FROM_TRANSFER} />
                    </div>
                </FormSection>
            </div>}
        </div>
    )
}


function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="date" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="From Customer" fieldKey="cargorecciutecustomersfrom" options={endpoint.customers.map(c => c.value)} state={state} property="fromCustomer" />
        </div>
        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="To Customer" fieldKey="cargorecciutecustomersto" options={endpoint.customers.map(c => c.value)} state={state} property="toCustomer" />
        </div>
    </>)
}

