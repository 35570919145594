import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../../base/Page';


import TableTemplate from '../../templates/TableTemplate';
import { OBJECT_TYPE_GALLERY_POST, OBJECT_TYPE_TESTIMONY_POST } from '../../constants/ObjectTypes';

class TestimonyPostsListPage extends Page {

    getLayout() {
        return (
            <div className="main-content">
                {TableTemplate.renderTemplate({
                    title: "Customer Testimonies",
                    subTitle: "All customer testimonies",
                    objectType: OBJECT_TYPE_TESTIMONY_POST,
                    pagePath: "/customer-testimonies/",
                    history: this.props.history
                })}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default withRouter(TestimonyPostsListPage);