import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import Logo from "../../../images/star-cinema.png"
import { ArrowRight16 } from '@carbon/icons-react'
import { useHistory } from "react-router-dom"
import Util from "../../../util/Util"

const DetailValueEntry = ({ label, value }) => (<>
    <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: 1, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: 2, outline: 'none', textAlign: 'end' }}>{value}</p>
    </div>
</>)
function Detail({ item }) {
    return (
        <div style={{ width: '100%' }}>
            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Details</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '0rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <DetailValueEntry label="Theater Type" value={item.theaterType} />
                    <DetailValueEntry label="Seat Type" value={item.seatType} />
                </div>
            </div>

        </div>
    )
}



function View({ payload: item }) {
    return (
        <div style={{ padding: '1rem' }}>
            <img src={Logo} style={{ width: '100%', height: 150, marginBlock: '1rem', objectFit: 'contain', objectPosition: 'center', }} />
            <h4 style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center', width: '100%' }}>Welcome</h4>
            <p style={{ fontSize: 16, opacity: 0.65, textAlign: 'center', width: '100%' }}>{item.seatName}</p>

            <div style={{ height: '1rem' }} />
            <Detail item={item} />

            {/* <div style={{ height: 300 }} /> */}
        </div>
    )
}

export const CinemaSeatQrCode = withLoadablePageWithParams(params => listener => Api.getCinemaSeatQrCode(params.seatPlanId, params.row, params.col, listener), View)

