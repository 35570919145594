import { ComposedModal, ContentSwitcher, ModalBody, ModalFooter, ModalHeader, Switch, TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import { getObjectTypeName } from "../../../constants/ObjectTypes";
import { WarningFilled32 } from '@carbon/icons-react'
import UIUtil from "../../../util/UIUtil";

export const DeleteCommonEventDialog = ({ objectType, visible, onClose, onDelete, confirmByDefault }) => {
    const [deleteAll, setDeleteAll] = useState(false);

    const [confirmPhrase, setConfirmPhrase] = useState("");
    useEffect(() => {
        if (visible) {
            setConfirmPhrase(confirmByDefault ? "delete permanently" : "");
            setDeleteAll(false)
        }
    }, [visible])

    useEffect(() => {
        if (deleteAll) {
            UIUtil.reverseConfirmWithMsg("Are you sure? You will be deleting every other occurrences of this current recurring event.", () => {
                setDeleteAll(false);
            })
        }
    }, [deleteAll])

    return (
        <ComposedModal open={visible} onClose={onClose}>
            <ModalHeader label="Confirm" title="Are you sure?" />
            <ModalBody >
                <div style={{ marginBottom: '1rem', borderRadius: 5, background: '#990000', paddingBlock: '1rem', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <WarningFilled32 />
                    <h5>Warning! This is a recurring event!</h5>
                </div>
                <ContentSwitcher selectedIndex={deleteAll ? 1 : 0} onChange={e => setDeleteAll(e.index === 1)} style={{ width: '100%' }}>
                    <Switch name="delete-one-only" text="Delete this event only" />
                    <Switch name="delete-all" text="Delete all events" />
                </ContentSwitcher>

                <p style={{ marginBottom: 5, marginTop: '3rem' }}>
                    Deleting this {getObjectTypeName(objectType).toLowerCase()} is irreversible and will be permanent!
                </p>
                {!confirmByDefault && <TextInput
                    data-modal-primary-focus
                    value={confirmPhrase}
                    onChange={e => setConfirmPhrase(e.target.value)}
                    onPaste={e => e.preventDefault()}
                    id="text-input-1"
                    labelText="To confirm please type out 'delete permanently'"
                    placeholder="Type out confirm phrase"
                />}
            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    kind="danger" disabled={confirmPhrase != "delete permanently"}
                    onClick={() => onDelete(deleteAll)}>
                    Delete
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}
