import "../restaurant.scss"

import { Wheat24, Close16, ChevronRight16, Reset16, Save16, Draggable16, CheckmarkFilled16, ArrowRight16, TrashCan16, Add16 } from '@carbon/icons-react'
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from '../../../constants/ObjectTypes'
import ProfilePic from '../../../components/ProfilePic'
import Api from '../../../session/Api'
import Button from '../../../components/Button'
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Util from "../../../util/Util"
import { RECIPE_COMPONENT_TYPE } from "../../../domain/recipe"
import { makeDraggable } from "../../../hooks/useDraggable"
import arrayMove from 'array-move';
import { isEqual } from "lodash"
import { withLoadablePageAndProps, withLoadablePagePropsAndProps } from "../../../base/Page"
import { clearIdForApi, fixIdForApi } from "../../../templates/form/form-api"
import UIUtil from "../../../util/UIUtil"
import { TextInput } from "carbon-components-react"
import { STATE_TYPE_RECIPE_MANAGER } from "../../../constants/Constants"
import { useDraft } from "../../../templates/draft/draft"
import { FoodItemRecipeItemsDialog } from "../../pos/dialogs/any-search-dialog"
import { renderNode } from "../../../components/imperative-render"

const DragHandle = () => (
    <div style={{ width: '1.5rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'grab' }}>
        <Draggable16 />
    </div>
)

const ReplacementItem = ({ SortHandler, replaceability, component, setComponentReplaceability, removeReplaceability }) => {
    const setNewQuantity = newQuantity => setComponentReplaceability(component, { ...replaceability, newQuantity })
    const setPriceModifier = priceModifier => setComponentReplaceability(component, { ...replaceability, priceModifier })

    return (
        <div style={{ zIndex: 10001, width: '100%', background: '#ffffffAA', borderRadius: 15, gap: '0.5rem', padding: '1rem', marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
            <SortHandler />
            <ProfilePic size={24} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, replaceability.ingredientId)} notProfile />
            <div style={{ flex: 1 }}>
                <h6 style={{ fontSize: 12, fontWeight: 'regular' }}>{replaceability.ingredientName}</h6>
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>New Quantity</label>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input value={replaceability.newQuantity} onChange={e => setNewQuantity(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
                    <p style={{ fontSize: 14, fontWeight: 'bold', marginRight: '3rem' }}>{replaceability.ingredientUOM}</p>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>Price Addition</label>
                <input value={replaceability.priceModifier} onChange={e => setPriceModifier(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
            </div>
            <Button kind="danger-ghost" renderIcon={TrashCan16} size="sm" onClick={() => removeReplaceability(replaceability.id)} />
        </div>
    )
}

const CustomizeItem = ({ SortHandler, customizability, component, setComponentCustomizability, removeCustomizability }) => {
    const setName = name => setComponentCustomizability(component, { ...customizability, name })
    const setQuantityAddition = quantityAddition => setComponentCustomizability(component, { ...customizability, quantityAddition })
    const setPriceModifier = priceModifier => setComponentCustomizability(component, { ...customizability, priceModifier })
    return (
        <div style={{ zIndex: 10001, width: '100%', background: '#ffffffAA', borderRadius: 15, gap: '0.5rem', padding: '1rem', marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
            <SortHandler />
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>Name</label>
                <input value={customizability.name} onChange={e => setName(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>Quantity Addition</label>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input value={customizability.quantityAddition} onChange={e => setQuantityAddition(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
                    <p style={{ fontSize: 14, fontWeight: 'bold', marginRight: '3rem' }}>{component.ingredientUOM}</p>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>Price Addition</label>
                <input value={customizability.priceModifier} onChange={e => setPriceModifier(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
            </div>
            <Button kind="danger-ghost" renderIcon={TrashCan16} size="sm" onClick={() => removeCustomizability(customizability.id)} />
        </div>
    )
}

const FoodReplacementItem = ({ SortHandler, replaceability, component, setComponentReplaceability, removeReplaceability }) => {
    const setNewQuantity = newQuantity => setComponentReplaceability(component, { ...replaceability, newQuantity })
    const setPriceModifier = priceModifier => setComponentReplaceability(component, { ...replaceability, priceModifier })

    return (
        <div style={{ zIndex: 10001, width: '100%', background: '#ffffffAA', borderRadius: 15, gap: '0.5rem', padding: '1rem', marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
            <SortHandler />
            <ProfilePic size={24} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, replaceability.foodId)} notProfile />
            <div style={{ flex: 1 }}>
                <h6 style={{ fontSize: 12, fontWeight: 'regular' }}>{replaceability.foodName}</h6>
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>New Quantity</label>
                <input value={replaceability.newQuantity} onChange={e => setNewQuantity(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>Price Addition</label>
                <input value={replaceability.priceModifier} onChange={e => setPriceModifier(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
            </div>
            <Button kind="danger-ghost" renderIcon={TrashCan16} size="sm" onClick={() => removeReplaceability(replaceability.id)} />
        </div>
    )
}

const QuestionOptionItem = ({ SortHandler, option, component, setComponentOption, removeOption }) => {
    const setOptionText = optionText => setComponentOption(component, { ...option, optionText })
    const setPriceModifier = priceModifier => setComponentOption(component, { ...option, priceModifier })
    return (
        <div style={{ zIndex: 10001, width: '100%', animation: undefined, background: '#ffffffAA', borderRadius: 15, gap: '0.5rem', padding: '1rem', marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
            <SortHandler />
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>Option</label>
                <input value={option.optionText} onChange={e => setOptionText(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 250, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
            </div>
            <div style={{ flex: 1 }}>
                <label className="bx--label" style={{ display: 'block' }}>Price Addition</label>
                <input value={option.priceModifier} onChange={e => setPriceModifier(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
            </div>
            <Button kind="danger-ghost" renderIcon={TrashCan16} size="sm" onClick={() => removeOption(option.id)} />
        </div>
    )
}


const replaceabilityDraggable = makeDraggable(ReplacementItem, DragHandle);
const customizabilityDraggable = makeDraggable(CustomizeItem, DragHandle);
const Ingredient = ({ SortHandler, component, setComponent, setComponentCustomizability, setComponentReplaceability, removeComponent, ingredients }) => {
    const [openedTab, setOpenedTab] = useState("")

    const setLabel = label => setComponent({ ...component, label })
    const setQuantity = quantity => setComponent({ ...component, quantity })
    const setReplaceability = replaceability => setComponent({ ...component, replaceability })
    const setCustomizability = customizability => setComponent({ ...component, customizability })
    const setAlwaysShowOption = alwaysShowOption => setComponent({ ...component, alwaysShowOption })
    const setAlwaysPrintOption = alwaysPrintOption => setComponent({ ...component, alwaysPrintOption })
    const setDontPrintTitle = dontPrintTitle => setComponent({ ...component, dontPrintTitle })
    const setPrintPrefix = printPrefix => setComponent({ ...component, printPrefix })

    const removeReplaceability = id => setComponent({ ...component, replaceability: component.replaceability.filter($ => $.id !== id) });
    const removeCustomizability = id => setComponent({ ...component, customizability: component.customizability.filter($ => $.id !== id) });

    const addCustomizability = () => setComponent({
        ...component, customizability: [...component.customizability, {
            id: Util.newTempId(),
            name: "",
            newQuantity: 0,
            priceModifier: 0
        }]
    })

    const addReplaceability = (ingredientId, ingredientName, ingredientUOM) => setComponent({
        ...component, replaceability: [...component.replaceability, {
            id: Util.newTempId(),
            ingredientId, ingredientName, ingredientUOM,

            newQuantity: 0,
            priceModifier: 0
        }]
    })

    const onNewReplaceabilityBtn = () => UIUtil.showOverlay2(onClose => <ListDialog title="Ingredients" onItemClick={item => addReplaceability(item.id, item.value, item.uom)} itemType={OBJECT_TYPE_PRODUCT} items={ingredients} onClose={onClose} />)

    return (
        <div style={{ marginBottom: '1rem', zIndex: 10000, animation: undefined }}>
            <div style={{ borderRadius: 5, overflow: 'hidden', background: 'white', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                <div style={{ padding: '1rem', border: '1px solid #00000010', borderBottom: '0px solid #0353e9', }}>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <SortHandler />
                        {component.ingredientId > 0 ? (<>
                            <ProfilePic size={24} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, component.ingredientId)} notProfile />
                            <div style={{ flex: 1 }}>
                                <h6 style={{ fontSize: 12 }}>{component.ingredientName}</h6>
                            </div>
                        </>) : (<>
                            <label style={{ fontSize: 12, opacity: 0.65 }}>Ingredient Option:</label>
                            <input value={component.label} onChange={e => setLabel(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 300, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
                            <div style={{ flex: 1 }} />
                        </>)}

                        <label style={{ fontSize: 12, opacity: 0.65 }}>Quantity:</label>
                        <input value={component.quantity} onChange={e => setQuantity(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
                        {component.ingredientId > 0 && <p style={{ fontSize: 14, fontWeight: 'bold', marginRight: '3rem' }}>{component.ingredientUOM}</p>}

                        <Button onClick={() => removeComponent(component.id)} kind="danger-ghost" renderIcon={TrashCan16} size="sm" />
                    </div>
                    {/* <p style={{ fontSize: 12, opacity: 0.65, textAlign: 'right' }}>{Util.getDate(item.lastModifiedDate)}</p> */}
                </div>
                {/* <div className='recipe-manager-item-accordion' onClick={() => setOpenedTab(p => p === "replace" ? "" : "replace")} style={{ opacity: openedTab === "customize" ? 0.65 : 1, width: '100%', display: 'flex', alignItems: 'center', paddingInline: '1rem', color: 'white' }}>
                    <p style={{ fontSize: 14 }}>Replaceability</p>
                    <div style={{ flex: 1 }} />
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{component.replaceability.length > 0 ? 'Replaceable' : 'Not replaceable'}</p>
                </div>
                {openedTab === "replace" && <div style={{ animation: '250ms actual-opacity-fade-in-anim', padding: '1rem', paddingBottom: '2rem', width: '100%', background: '#0f62fe20' }}>
                    <replaceabilityDraggable.SortContainer onSortEnd={({ oldIndex, newIndex }) => setReplaceability(arrayMove(component.replaceability, oldIndex, newIndex))}>
                        {component.replaceability.map((replaceability, index) => <replaceabilityDraggable.SortItem key={replaceability.id} index={index} replaceability={replaceability} component={component} setComponentReplaceability={setComponentReplaceability} removeReplaceability={removeReplaceability} />)}
                    </replaceabilityDraggable.SortContainer>
                    <div style={{ marginTop: '3rem', display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={onNewReplaceabilityBtn} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add replacement</Button>
                    </div>
                </div>} */}
                {!Util.isStringExists(openedTab) && <div style={{ background: '#0c46b3', height: 1, width: '100%' }} />}
                <div className='recipe-manager-item-accordion' onClick={() => setOpenedTab(p => p === "customize" ? "" : "customize")} style={{ opacity: openedTab === "replace" ? 0.65 : 1, width: '100%', display: 'flex', alignItems: 'center', paddingInline: '1rem', color: 'white' }}>
                    <p style={{ fontSize: 14 }}>Customizability</p>
                    <div style={{ flex: 1 }} />
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{component.customizability.length > 0 ? 'Customizable' : 'Not customizable'}</p>
                </div>
                {openedTab === "customize" && <div style={{ animation: '250ms actual-opacity-fade-in-anim', width: '100%', background: '#0f62fe20' }}>
                    <div style={{ width: '100%', paddingBlock: '0.5rem', background: '#f4f4f4', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', flexWrap: 'wrap', paddingInline: '1rem', gap: '0.25rem' }}>
                        <label style={{ fontSize: 12, opacity: 0.65 }}>Default Label:</label>
                        <input value={component.label} onChange={e => setLabel(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 250, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />
                        <div style={{ width: '1rem' }} />

                        <label style={{ fontSize: 12, opacity: 0.65 }}>Print Prefix:</label>
                        <input placeholder="optional prefix" value={component.printPrefix} onChange={e => setPrintPrefix(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 250, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />

                        <div style={{ flexBasis: '100%' }} />
                        <div style={{ flex: 1 }} />

                        <label style={{ fontSize: 12, opacity: 0.65 }}>Always Show:</label>
                        <input type="checkbox" checked={component.alwaysShowOption} onChange={e => setAlwaysShowOption(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />

                        <div style={{ width: '1rem' }} />
                        <label style={{ fontSize: 12, opacity: 0.65 }}>Always Print:</label>
                        <input type="checkbox" checked={component.alwaysPrintOption} onChange={e => setAlwaysPrintOption(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />

                        <div style={{ width: '1rem' }} />
                        <label style={{ fontSize: 12, opacity: 0.65 }}>DO NOT Print Title:</label>
                        <input type="checkbox" checked={component.dontPrintTitle} onChange={e => setDontPrintTitle(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />



                    </div>
                    <div style={{ padding: '1rem', paddingBottom: '2rem', }}>
                        <customizabilityDraggable.SortContainer onSortEnd={({ oldIndex, newIndex }) => setCustomizability(arrayMove(component.customizability, oldIndex, newIndex))}>
                            {component.customizability.map((customizability, index) => <customizabilityDraggable.SortItem key={customizability.id} index={index} customizability={customizability} component={component} setComponentCustomizability={setComponentCustomizability} removeCustomizability={removeCustomizability} />)}
                        </customizabilityDraggable.SortContainer>
                        <div style={{ marginTop: '3rem', display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={addCustomizability} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add option</Button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}



const foodReplaceabilityDraggable = makeDraggable(FoodReplacementItem, DragHandle);
const Food = ({ SortHandler, component, setComponent, setComponentFoodReplaceability, removeComponent, foods }) => {
    const [openedTab, setOpenedTab] = useState("")

    const setLabel = label => setComponent({ ...component, label })
    const setQuantity = quantity => setComponent({ ...component, quantity })
    const setReplaceability = foodReplaceability => setComponent({ ...component, foodReplaceability })
    const setAlwaysShowOption = alwaysShowOption => setComponent({ ...component, alwaysShowOption })
    const setAlwaysPrintOption = alwaysPrintOption => setComponent({ ...component, alwaysPrintOption })
    const setDontPrintTitle = dontPrintTitle => setComponent({ ...component, dontPrintTitle })
    const setPrintPrefix = printPrefix => setComponent({ ...component, printPrefix })

    const removeReplaceability = id => setComponent({ ...component, foodReplaceability: component.foodReplaceability.filter($ => $.id !== id) });
    const addReplaceability = (foodId, foodName) => setComponent({
        ...component, foodReplaceability: [...component.foodReplaceability, {
            id: Util.newTempId(),
            foodId, foodName,

            newQuantity: 0,
            priceModifier: 0
        }]
    })

    //const onNewReplaceabilityBtn = () => UIUtil.showOverlay2(onClose => <ListDialog title="Items" onItemClick={item => addReplaceability(item.id, item.value)} itemType={OBJECT_TYPE_PRODUCT_BUNDLE} items={foods} onClose={onClose} />)
    const onNewReplaceabilityBtn = () => {
        renderNode(onClose => (
            <FoodItemRecipeItemsDialog visible onClose={onClose} onItemSelected={bundle => {
                addReplaceability(bundle.id, bundle.name);
            }} />
        ))
    }

    return (
        <div style={{ marginBottom: '1rem', zIndex: 10000, animation: undefined }}>
            <div style={{ borderRadius: 5, overflow: 'hidden', background: 'white', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                <div style={{ padding: '1rem', border: '1px solid #00000010', borderBottom: '0px solid #0353e9', }}>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <SortHandler />
                        {component.foodId > 0 ? (<>
                            <ProfilePic size={24} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT_BUNDLE, component.foodId)} notProfile />
                            <div style={{ flex: 1 }}>
                                <h6 style={{ fontSize: 12 }}>{component.foodName}</h6>
                            </div>
                        </>) : (<>
                            <label style={{ fontSize: 12, opacity: 0.65 }}>Item Option:</label>
                            <input value={component.label} onChange={e => setLabel(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 300, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
                            <div style={{ flex: 1 }} />
                        </>)}

                        {component.foodId > 0 && <>
                            <label style={{ fontSize: 12, opacity: 0.65 }}>Quantity:</label>
                            <input value={component.quantity} onChange={e => setQuantity(e.target.value)} style={{ border: '1px solid #00000020', height: 30, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />
                        </>}


                        <Button onClick={() => removeComponent(component.id)} kind="danger-ghost" renderIcon={TrashCan16} size="sm" />
                    </div>
                    {/* <p style={{ fontSize: 12, opacity: 0.65, textAlign: 'right' }}>{Util.getDate(item.lastModifiedDate)}</p> */}
                </div>
                {component.foodId === 0 && <>
                    <div className='recipe-manager-item-accordion' onClick={() => setOpenedTab(p => p === "replace" ? "" : "replace")} style={{ opacity: openedTab === "customize" ? 0.65 : 1, width: '100%', display: 'flex', alignItems: 'center', paddingInline: '1rem', color: 'white' }}>
                        <p style={{ fontSize: 14 }}>Replaceability</p>
                        <div style={{ flex: 1 }} />
                        <p style={{ fontSize: 12, opacity: 0.65 }}>{component.foodReplaceability.length > 0 ? 'Replaceable' : 'Not replaceable'}</p>
                    </div>



                    {openedTab === "replace" && <div style={{ animation: '250ms actual-opacity-fade-in-anim', width: '100%', background: '#0f62fe20' }}>
                        <div style={{ width: '100%', height: '3rem', background: '#f4f4f4', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.5rem' }}>
                            <label style={{ fontSize: 12, opacity: 0.65 }}>Print Prefix:</label>
                            <input placeholder="optional prefix" value={component.printPrefix} onChange={e => setPrintPrefix(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 250, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />
                            <div style={{ width: '3rem' }} />

                            <label style={{ fontSize: 12, opacity: 0.65 }}>Always Show:</label>
                            <input type="checkbox" checked={component.alwaysShowOption} onChange={e => setAlwaysShowOption(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />

                            <div style={{ width: '1rem' }} />
                            <label style={{ fontSize: 12, opacity: 0.65 }}>Always Print:</label>
                            <input type="checkbox" checked={component.alwaysPrintOption} onChange={e => setAlwaysPrintOption(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />

                            <div style={{ width: '1rem' }} />
                            <label style={{ fontSize: 12, opacity: 0.65 }}>DO NOT Print Title:</label>
                            <input type="checkbox" checked={component.dontPrintTitle} onChange={e => setDontPrintTitle(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />
                        </div>


                        <div style={{ padding: '1rem', paddingBottom: '2rem', }}>
                            <foodReplaceabilityDraggable.SortContainer onSortEnd={({ oldIndex, newIndex }) => setReplaceability(arrayMove(component.foodReplaceability, oldIndex, newIndex))}>
                                {component.foodReplaceability.map((replaceability, index) => <foodReplaceabilityDraggable.SortItem key={replaceability.id} index={index} replaceability={replaceability} component={component} setComponentReplaceability={setComponentFoodReplaceability} removeReplaceability={removeReplaceability} />)}
                            </foodReplaceabilityDraggable.SortContainer>
                            <div style={{ marginTop: '3rem', display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={onNewReplaceabilityBtn} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add replacement</Button>
                            </div>
                        </div>
                    </div>}
                </>}
            </div>
        </div>
    )
}


const optionsDraggable = makeDraggable(QuestionOptionItem, DragHandle);
const Question = ({ SortHandler, component, setComponent, setComponentOption, removeComponent }) => {
    const [openedTab, setOpenedTab] = useState("")

    const setQuestionText = questionText => setComponent({ ...component, questionText })
    const setOptions = options => setComponent({ ...component, options })
    const removeOption = id => setComponent({ ...component, options: component.options.filter($ => $.id !== id) });
    const setAlwaysShowOption = alwaysShowOption => setComponent({ ...component, alwaysShowOption })
    const setAlwaysPrintOption = alwaysPrintOption => setComponent({ ...component, alwaysPrintOption })
    const setDontPrintTitle = dontPrintTitle => setComponent({ ...component, dontPrintTitle })
    const setPrintPrefix = printPrefix => setComponent({ ...component, printPrefix })

    const addOption = () => setComponent({
        ...component, options: [...component.options, {
            id: Util.newTempId(),
            optionText: "",
            priceModifier: 0
        }]
    })

    return (
        <div style={{ marginBottom: '1rem', zIndex: 10000, animation: undefined }}>
            <div style={{ borderRadius: 5, overflow: 'hidden', background: 'white', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                <div style={{ padding: '1rem', border: '1px solid #00000010', borderBottom: '0px solid #0353e9', }}>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <SortHandler />
                        <label style={{ fontSize: 12, opacity: 0.65 }}>Question:</label>
                        <input value={component.questionText} onChange={e => setQuestionText(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 350, background: '#00000005', borderRadius: 10, paddingInline: '1rem' }} />

                        <div style={{ flex: 1 }} />
                        <Button onClick={() => removeComponent(component.id)} kind="danger-ghost" renderIcon={TrashCan16} size="sm" />
                    </div>
                    {/* <p style={{ fontSize: 12, opacity: 0.65, textAlign: 'right' }}>{Util.getDate(item.lastModifiedDate)}</p> */}
                </div>
                <div className='recipe-manager-item-accordion' onClick={() => setOpenedTab(p => p === "replace" ? "" : "replace")} style={{ opacity: openedTab === "customize" ? 0.65 : 1, width: '100%', display: 'flex', alignItems: 'center', paddingInline: '1rem', color: 'white' }}>
                    <p style={{ fontSize: 14 }}>Options</p>
                    <div style={{ flex: 1 }} />
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{component.options.length} option{component.options.length !== 1 ? 's' : ''}</p>
                </div>
                {openedTab === "replace" && <div style={{ animation: '250ms actual-opacity-fade-in-anim', width: '100%', background: '#0f62fe20' }}>
                    <div style={{ width: '100%', height: '3rem', background: '#f4f4f4', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.5rem' }}>
                        <label style={{ fontSize: 12, opacity: 0.65 }}>Print Prefix:</label>
                        <input placeholder="optional prefix" value={component.printPrefix} onChange={e => setPrintPrefix(e.target.value)} style={{ border: '1px solid #00000020', height: 30, width: 250, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />
                        <div style={{ width: '3rem' }} />

                        <label style={{ fontSize: 12, opacity: 0.65 }}>Always Show:</label>
                        <input type="checkbox" checked={component.alwaysShowOption} onChange={e => setAlwaysShowOption(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />

                        <div style={{ width: '1rem' }} />
                        <label style={{ fontSize: 12, opacity: 0.65 }}>Always Print:</label>
                        <input type="checkbox" checked={component.alwaysPrintOption} onChange={e => setAlwaysPrintOption(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />

                        <div style={{ width: '1rem' }} />
                        <label style={{ fontSize: 12, opacity: 0.65 }}>DO NOT Print Title:</label>
                        <input type="checkbox" checked={component.dontPrintTitle} onChange={e => setDontPrintTitle(e.target.checked)} style={{ border: '1px solid #00000020', height: 30, width: 30, background: '#00000015', borderRadius: 10, paddingInline: '1rem' }} />
                    </div>

                    <div style={{ padding: '1rem', paddingBottom: '2rem', }}>
                        <optionsDraggable.SortContainer onSortEnd={({ oldIndex, newIndex }) => setOptions(arrayMove(component.options, oldIndex, newIndex))}>
                            {component.options.map((option, index) => <optionsDraggable.SortItem key={option.id} index={index} option={option} component={component} setComponentOption={setComponentOption} removeOption={removeOption} />)}
                        </optionsDraggable.SortContainer>
                        <div style={{ marginTop: '3rem', display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={addOption} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add option</Button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}




const RecipeComponent = ({ component, ...props }) => {
    switch (component.type) {
        case RECIPE_COMPONENT_TYPE.QUESTION:
            return <Question component={component} {...props} />
        case RECIPE_COMPONENT_TYPE.INGREDIENT:
            return <Ingredient component={component} {...props} />
        case RECIPE_COMPONENT_TYPE.FOOD:
            return <Food component={component} {...props} />
    }

    return <div />;
}





export const ListDialog = ({ title, itemType, items = [], onItemClick, onClose, hasDefault }) => {
    const [search, setSearch] = useState("");
    const resolvedItems = useMemo(() => {
        if (Util.isStringExists(search)) {
            return items.filter($ => $.value.toLowerCase().includes(search.toLowerCase()))
        } else {
            return items;
        }
    }, [items, search])
    return (
        <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                <h4 style={{ fontWeight: 'bold', flex: 1 }}>{title}</h4>
                <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <Close16 />
                </button>
            </div>

            <div style={{ marginTop: '0.5rem' }}>
                <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
            </div>

            <div style={{ maxHeight: 350, minHeight: 150, overflow: 'auto', paddingTop: '0.5rem' }}>
                {resolvedItems.map(item => (
                    <div key={item.id} onClick={() => {
                        onItemClick(item);
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <ProfilePic notProfile size={32} style={{ borderRadius: 7 }} src={Api.getThumbnail(itemType, item.id)} />
                        <p style={{ fontSize: 14, }}>{item.value}</p>
                    </div>
                ))}

                {resolvedItems.length === 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '1rem' }}>
                    <p style={{ fontSize: 12, opacity: 0.65, textAlign: 'center' }}>No items</p>
                </div>}
            </div>

            {hasDefault && <Button onClick={() => {
                onItemClick({ id: 0, value: '', uom: '' });
                onClose();
            }} renderIcon={ArrowRight16} kind="secondary" size="sm" style={{ width: '100%', marginTop: '1rem', borderRadius: 10, maxWidth: 'unset' }}>No default option</Button>}
        </div>
    )
}



const DraftBtn = forwardRef(({ setData, getData }, ref) => {
    const draft = useDraft(STATE_TYPE_RECIPE_MANAGER, setData, getData, true)

    useImperativeHandle(ref, () => draft, [draft])

    return (<>
        {draft.draftManagement}
        {draft.draftDialogs}
    </>)
})


function useMakeDraft({
    parentFoodId, defaultComponents,
    components, setComponents
}) {
    const ref = useRef();

    const setData = data => {
        setComponents(data?.components?.map($ => ({ ...$, parentFoodId })) ?? defaultComponents)
    }
    const getData = () => ({
        components: clearIdForApi(components)
    })
    return {
        draftBtn: <DraftBtn ref={ref} setData={setData} getData={getData} />,
        draft: ref.current
    }
}


const draggable = makeDraggable(RecipeComponent, DragHandle);
function View({ payload, foodId, showFoodHeader, title, onClose }) {
    const [defaultComponents, setDefaultComponents] = useState(payload.components);
    const [components, setComponents] = useState(defaultComponents);

    const changesMade = useMemo(() => !isEqual(defaultComponents, components), [defaultComponents, components])
    const resetToDefault = () => setComponents(defaultComponents);
    const { draftBtn, draft } = useMakeDraft({
        parentFoodId: foodId,
        defaultComponents,
        components, setComponents
    });



    const [saving, setSaving] = useState(false)
    const onSaveBtn = () => {
        setSaving(true);
        Api.saveFoodRecipe(foodId, fixIdForApi(components), response => {
            if (response.status === true) {
                setDefaultComponents(response.payload);
                setComponents(response.payload)

                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setSaving(false);
        })
    }


    const setComponent = update => setComponents(prev => prev.map($ => $.id === update.id ? update : $))
    const setComponentList = (componentUpdate, listUpdate, listProperty) => setComponent({
        ...componentUpdate,
        [listProperty]: componentUpdate[listProperty].map($ => $.id === listUpdate.id ? listUpdate : $)
    })

    const setComponentOption = (componentUpdate, optionUpdate) => setComponentList(componentUpdate, optionUpdate, "options");
    const setComponentCustomizability = (componentUpdate, customizabilityUpdate) => setComponentList(componentUpdate, customizabilityUpdate, "customizability")
    const setComponentReplaceability = (componentUpdate, replaceabilityUpdate) => setComponentList(componentUpdate, replaceabilityUpdate, "replaceability")
    const setComponentFoodReplaceability = (componentUpdate, replaceabilityUpdate) => setComponentList(componentUpdate, replaceabilityUpdate, "foodReplaceability")

    const removeComponent = id => setComponents(prev => prev.filter($ => $.id !== id))

    const addQuestion = () => setComponents(prev => [...prev, {
        id: Util.newTempId(),
        parentFoodId: foodId,
        type: RECIPE_COMPONENT_TYPE.QUESTION,
        questionText: "",
        options: []
    }])
    const addIngredient = (ingredientId, ingredientName, ingredientUOM) => setComponents(prev => [...prev, {
        id: Util.newTempId(),
        parentFoodId: foodId,
        type: RECIPE_COMPONENT_TYPE.INGREDIENT,
        ingredientId, ingredientName, ingredientUOM,
        quantity: 1,
        customizability: [],
        replaceability: []
    }])
    const addFood = (newFoodId, newFoodName) => setComponents(prev => [...prev, {
        id: Util.newTempId(),
        parentFoodId: foodId,
        type: RECIPE_COMPONENT_TYPE.FOOD,
        foodId: newFoodId,
        foodName: newFoodName,
        quantity: 1,
        foodReplaceability: []
    }])

    const onNewIngredientBtn = () => UIUtil.showOverlay2(onClose => <ListDialog title="Ingredients" onItemClick={item => addIngredient(item.id, item.value, item.uom)} itemType={OBJECT_TYPE_PRODUCT} items={payload.endpoint.ingredients} onClose={onClose} />)
    const onNewFoodBtn = async () => {
        // UIUtil.showOverlay2(onClose => <ListDialog hasDefault title="Items" onItemClick={item => addFood(item.id, item.value)} itemType={OBJECT_TYPE_PRODUCT_BUNDLE} items={payload.endpoint.foods} onClose={onClose} />)
        renderNode(onClose => (
            <FoodItemRecipeItemsDialog visible onClose={onClose} onItemSelected={bundle => {
                addFood(bundle.id, bundle.name);
            }} bottomChildren={<>
                <Button onClick={() => {
                    addFood(0, '');
                    onClose();
                }} renderIcon={ArrowRight16} kind="secondary" size="md" style={{ width: '100%', marginTop: '0rem', borderRadius: 10, maxWidth: 'unset' }}>No default option</Button>
            </>} />
        ))
    }

    return (
        <div style={{ paddingBottom: '6rem', }}>
            <div style={{ paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', background: '#f4f4f4', borderBottom: '1px solid #00000010', paddingInline: '1rem' }}>
                {draftBtn}
                <div style={{ flex: 1 }} />
                <Button renderIcon={Save16} onClick={() => draft.setShowSaveDraft(true)} size="sm" style={{ borderRadius: 50 }}>{draft?.selectedDraft ? 'Update Draft' : 'Save Draft'}</Button>
            </div>
            <div style={{ paddingInline: '3rem' }}>
                <div style={{ paddingTop: '6rem', display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem' }}>
                    {showFoodHeader ? (<>
                        <ProfilePic size={24} notProfile style={{ borderRadius: 7 }} />
                        <h4 style={{ fontWeight: 'bold', flex: 1, marginLeft: '0.25rem' }}>{title}</h4>
                    </>) : (<>
                        <Wheat24 />
                        <h4 style={{ fontWeight: 'bold', flex: 1 }}>Recipe Manager</h4>
                    </>)}

                    {changesMade ? (<>
                        <Button onClick={resetToDefault} size="sm" style={{ borderRadius: 25, marginRight: '0.25rem' }} renderIcon={Reset16} kind="ghost">Reset to Default</Button>
                        <Button onClick={onClose} size="sm" style={{ borderRadius: 25, marginRight: '0.25rem' }} renderIcon={Close16} kind="secondary">Cancel</Button>
                        <Button loading={saving} onClick={onSaveBtn} size="sm" style={{ borderRadius: 25 }} renderIcon={CheckmarkFilled16} className="green-button">Save</Button>
                    </>) : (

                        <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                            <Close16 />
                        </button>
                    )}
                </div>

                <draggable.SortContainer onSortEnd={({ oldIndex, newIndex }) => setComponents(arrayMove(components, oldIndex, newIndex))}>
                    {components.map((component, index) => <draggable.SortItem key={component.id} {...{
                        index, component, setComponent, setComponentOption, setComponentCustomizability,
                        setComponentFoodReplaceability, setComponentReplaceability, removeComponent,
                        ingredients: payload.endpoint.ingredients, foods: payload.endpoint.foods
                    }} />)}
                </draggable.SortContainer>


                <div style={{ marginTop: '3rem', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <Button onClick={onNewIngredientBtn} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add ingredient</Button>
                    <Button onClick={onNewFoodBtn} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add item</Button>
                    <Button onClick={addQuestion} kind="ghost" size="sm" style={{ borderRadius: 50 }} renderIcon={Add16}>Add question</Button>
                </div>
            </div>
        </div>
    )
}

export const RecipeManager = withLoadablePagePropsAndProps(props => listener => Api.getFoodRecipe(props.foodId, listener), View);