import UIUtil from "../../../../../util/UIUtil";
import { pdf } from '@react-pdf/renderer'
import print from 'print-js';

export async function printShipmentDoc(shipment, { getPayload, PDF, overrideShipment }, pdfProps = {}) {
    //repeated
    return new Promise((resolve, reject) => {
        const getShipmentForPdf = async () => {
            if (overrideShipment) {
                return await overrideShipment(shipment);
            } else {
                return shipment;
            }
        }

        if (getPayload) {
            getPayload(shipment, async (success, data) => {
                try {
                    if (success) {
                        const shipmentForPdf = await getShipmentForPdf(shipment);
                        if (!shipmentForPdf) {
                            return;
                        }

                        const blob = await pdf(<PDF shipment={shipmentForPdf} payload={data} {...pdfProps} />).toBlob();
                        print(URL.createObjectURL(blob));
                    } else {
                        UIUtil.showError(data);
                    }
                } finally {
                    resolve()
                }
            })
        } else {
            const fn = async () => {
                try {
                    const shipmentForPdf = await getShipmentForPdf(shipment);
                    if (!shipmentForPdf) {
                        return;
                    }

                    const blob = await pdf(<PDF shipment={shipmentForPdf} {...pdfProps} />).toBlob();
                    print(URL.createObjectURL(blob));
                } finally {
                    resolve()
                }
            }
            fn()
        }
    })
}