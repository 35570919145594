
import {
    Dashboard32, User32, Product32, Screen32, Category32, Barcode32, Box32, Compare32, Rocket32,
    Delivery32, AirlinePassengerCare32, CutOut32, Headset32, Percentage32, Store32, DataVis_232,
    Calculator32, WatsonHealthTextAnnotationToggle32, Archive32, StringText32, Chip32, InventoryManagement32,
    FlowStream32, Cube32, DocumentImport32, Purchase32, Money32, Finance32, Account32, Receipt32, Events32,
    Gift32, TagGroup32, QrCode32, Close16, SubtractAlt32, Currency32, IncreaseLevel32, ShoppingCartArrowUp32,
    Api32, License32, RequestQuote32, Notebook32, Table32, ShoppingCartArrowDown32, InProgress32,
    DataFormat32, TreeView32, Catalog32, ListDropdown32, Course32, Grid32, DataShare32, Data_132,
    CaretRight16, ArrowDown32, ArrowUp32, PenFountain32, DeliveryParcel32, DocumentBlank32,
    PhraseSentiment32, Network_132, CategoryNewEach32, Building32
} from '@carbon/icons-react'
import { ACCOUNT_TYPE_PRODUCT_MANAGER } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_ACCOUNT_LEDGER, OBJECT_TYPE_ACCOUNT_LEDGER_GROUP, OBJECT_TYPE_CHEQUE, OBJECT_TYPE_CONTACT, OBJECT_TYPE_COUPON, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_CUSTOMER_GROUP, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_LOYALTY_CARD, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BRAND, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_CATEGORY, OBJECT_TYPE_PRODUCT_DEPARTMENT, OBJECT_TYPE_PRODUCT_SUBCATEGORY, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TAX, OBJECT_TYPE_TERMINAL, OBJECT_TYPE_VENUE, OBJECT_TYPE_WAREHOUSE } from '../../constants/ObjectTypes'
import { getAccountRole } from '../../session/SessionManager';



export const isImportableObjectTypeExists = types => {
    const accountRole = getAccountRole();
    if (accountRole == ACCOUNT_TYPE_PRODUCT_MANAGER) {
        for (const item of IMPORTABLE_OBJECT_TYPES_PRODUCT_MANAGER) {
            if (item.objectType == types) {
                return true;
            }
        }

        return false;
    }

    for (const item of IMPORTABLE_OBJECT_TYPES) {
        if (item.objectType == types) {
            return true;
        }
    }

    return false;
}

export const getImportableObjectTypeLink = types => {
    for (const item of IMPORTABLE_OBJECT_TYPES) {
        if (item.objectType == types) {
            return item.link;
        }
    }

    return "/";
}


export const getImportableObjectTypeTitle = types => {
    for (const item of IMPORTABLE_OBJECT_TYPES) {
        if (item.objectType == types) {
            return item.title;
        }
    }

    return "Unknown";
}

export const getImportableObjectTypeIcon = types => {
    for (const item of IMPORTABLE_OBJECT_TYPES) {
        if (item.objectType == types) {
            return item.icon;
        }
    }

    return DocumentBlank32;
}

export const IMPORTABLE_OBJECT_TYPES_PRODUCT_MANAGER = [
    {
        icon: Product32,
        objectType: OBJECT_TYPE_PRODUCT,
        title: "Products",
        link: "/products"
    },
    {
        icon: Delivery32,
        objectType: OBJECT_TYPE_SUPPLIER,
        title: "Suppliers",
        link: "/suppliers"
    },
    {
        icon: Box32,
        objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
        title: "Product Bundles",
        link: "/product-bundles"
    },
    {
        icon: PhraseSentiment32,
        objectType: OBJECT_TYPE_PRODUCT_BRAND,
        title: "Product Brands",
        link: "/product-brands"
    },
    {
        icon: Network_132,
        objectType: OBJECT_TYPE_PRODUCT_DEPARTMENT,
        title: "Product Departments",
        link: "/product-departments"
    },
    {
        icon: Category32,
        objectType: OBJECT_TYPE_PRODUCT_CATEGORY,
        title: "Product Categories",
        link: "/product-categories"
    },
    {
        icon: CategoryNewEach32,
        objectType: OBJECT_TYPE_PRODUCT_SUBCATEGORY,
        title: "Product Subcategories",
        link: "/product-subcategories"
    },
]

export const IMPORTABLE_OBJECT_TYPES = [
    {
        icon: Product32,
        objectType: OBJECT_TYPE_PRODUCT,
        title: "Products",
        link: "/products"
    },
    {
        icon: Box32,
        objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
        title: "Product Bundles",
        link: "/product-bundles"
    },
    {
        icon: PhraseSentiment32,
        objectType: OBJECT_TYPE_PRODUCT_BRAND,
        title: "Product Brands",
        link: "/product-brands"
    },
    {
        icon: Network_132,
        objectType: OBJECT_TYPE_PRODUCT_DEPARTMENT,
        title: "Product Departments",
        link: "/product-departments"
    },
    {
        icon: Category32,
        objectType: OBJECT_TYPE_PRODUCT_CATEGORY,
        title: "Product Categories",
        link: "/product-categories"
    },
    {
        icon: CategoryNewEach32,
        objectType: OBJECT_TYPE_PRODUCT_SUBCATEGORY,
        title: "Product Subcategories",
        link: "/product-subcategories"
    },
    {
        icon: AirlinePassengerCare32,
        objectType: OBJECT_TYPE_CUSTOMER,
        title: "Customers",
        link: "/customers"
    },
    {
        icon: Events32,
        objectType: OBJECT_TYPE_CUSTOMER_GROUP,
        title: "Customer Groups",
        link: "/customer-groups"
    },
    {
        icon: Delivery32,
        objectType: OBJECT_TYPE_SUPPLIER,
        title: "Suppliers",
        link: "/suppliers"
    },
    // {
    //     icon: PenFountain32,
    //     objectType: OBJECT_TYPE_CHEQUE,
    //     title: "Cheques",
    //     link: "/cheques"
    // },
    {
        icon: Api32,
        objectType: OBJECT_TYPE_ACCOUNT_LEDGER_GROUP,
        title: "Account Ledger Groups",
        link: "/account-ledger-groups"
    },
    {
        icon: License32,
        objectType: OBJECT_TYPE_ACCOUNT_LEDGER,
        title: "Account Ledgers",
        link: "/account-ledgers"
    },
    {
        icon: Screen32,
        objectType: OBJECT_TYPE_TERMINAL,
        title: "Terminals",
        link: "/terminals"
    },
    {
        icon: User32,
        objectType: OBJECT_TYPE_ACCOUNT,
        title: "Staff Users",
        link: "/staff-users"
    },
    {
        icon: DataVis_232,
        objectType: OBJECT_TYPE_WAREHOUSE,
        title: "Warehouses",
        link: "/warehouses"
    },
    {
        icon: Store32,
        objectType: OBJECT_TYPE_STORE,
        title: "Stores",
        link: "/stores"
    },
    {
        icon: Building32,
        objectType: OBJECT_TYPE_VENUE,
        title: "Venues",
        link: "/venues"
    },
    {
        icon: Headset32,
        objectType: OBJECT_TYPE_CONTACT,
        title: "Contacts",
        link: "/contacts"
    },
    // {
    //     icon: CutOut32,
    //     objectType: OBJECT_TYPE_COUPON,
    //     title: "Coupons",
    //     link: "/coupons"
    // },
    // {
    //     icon: TagGroup32,
    //     objectType: OBJECT_TYPE_DISCOUNT,
    //     title: "Discounts",
    //     link: "/discounts"
    // },
    {
        icon: Gift32,
        objectType: OBJECT_TYPE_LOYALTY_CARD,
        title: "Loyalty Cards",
        link: "/loyalty-cards"
    },
    {
        icon: Finance32,
        objectType: OBJECT_TYPE_TAX,
        title: "Taxes",
        link: "/taxes"
    },
]