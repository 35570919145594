import React, { useEffect, useRef, useState } from 'react'

const createValues = (properties, object) => {
    let values = new Object();
    for (const property of properties) {
        values[property] = object[property];
    }
    return values;
}

export default (engine, id, properties) => {
    const object = engine.getCanvasComponent(id);
    const [values, setValues] = useState(() => createValues(properties, object))
    
    const update = (overrideValues) => {
        const params = overrideValues ? overrideValues : values;

        let changesMade = false;
        for (const param in params) {
            if (params[param] != object[param]) {
                changesMade = true;
                break;
            }
        }
        if (changesMade) {
            engine.setCanvasComponentParams(id, params)
        }
    };
    const changeListener = {
        onBlur: () => update(),
        onKeyPress: e => {
            if (e.key == "Enter") {
                update();
            }
        },
    }

    const getter = property => values[property];
    const setter = (property, value, shouldUpdate) => {
        const newValues = {
            ...values,
            [property]: value
        }
        setValues(newValues)
        if (shouldUpdate) {
            update(newValues)
        }
    }
    const multiSetter = (updatedValues, shouldUpdate) => {
        const newValues = {
            ...values,
            ...updatedValues
        }
        setValues(newValues)
        if (shouldUpdate) {
            update(newValues)
        }
    }

    const valuesRef = useRef();
    useEffect(() => valuesRef.current = values, [values])
    useEffect(() => {
        return () => {
            if (valuesRef.current) {
                update(valuesRef.current)
            }
        }
    }, [])

    return { changeListener, getter, setter, multiSetter };
}