import { useCallback } from "react"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import { newListDetail } from "../../base/list-detail"
import { AirlinePassengerCare32, AirlinePassengerCare24 } from '@carbon/icons-react'
import { PosItemsList } from "./pos-items-list"
import Util, { isV2 } from "../../../util/Util"
import { DeliveryCaptureList } from "./delivery-capture-list"
import { TextBox } from "../../components/basic-filter/text-box"

export const CustomerList = newListDetail({
    title: "Customer",
    icon: AirlinePassengerCare32,
    mdIcon: AirlinePassengerCare24,

    filter: Filter, newForm: Form, updateForm: Form,

    defaultValues: {
        items: [], itemCaptures: []
    },

    isAvailable: () => {
        if (!isV2()) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
})

function Form({ endpoint, form }) {
    return (
        <div>
            <form.TextField fieldKey="name" title="Name" />
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <form.CheckBoxField fieldKey="hasCustomerPos" title="Has ordering POS?" />
                <form.CheckBoxField fieldKey="hasInventoryLocation" title="Has inventory location?" />
            </div>

            <form.IfTargetGroup targetKey="hasCustomerPos">
                <FormSection title={"Ordering POS"}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', }}>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="customerPosUsername" title="Username" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="customerPosPassword" title="Password" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <form.TextField fieldKey="customerPosClearance" title="Clearance Code" />
                        </div>
                    </div>

                    <form.CustomField fieldKey="items" childrenProps={{ services: endpoint.services, }}>
                        {PosItemsList}
                    </form.CustomField>
                </FormSection>
            </form.IfTargetGroup>

            <FormSection title={"Online Delivery Item Capture"}>
                <form.CustomField fieldKey="itemCaptures" childrenProps={{ services: endpoint.services, }}>
                    {DeliveryCaptureList}
                </form.CustomField>
            </FormSection>

            <FormSection title={"Detail"}>
                <form.CheckBoxField fieldKey="active" title="Active" />
                <form.TextField fieldKey="currency" title="Currency" />
                <form.TextField fieldKey="fullName" title="Full Name" />
                <form.TextField fieldKey="address" title="Address" />
                <form.TextField fieldKey="trn" title="Tax Registration Number" />
                <form.TextField fieldKey="mainPhone" title="Main Phone" />
                <form.TextField fieldKey="fax" title="Fax" />
                <form.TextField fieldKey="contactPersonFinance" title="Contact Person (Finance)" />
                <form.TextField fieldKey="otherContactPerson" title="Other Contact Person" />
                <form.TextField fieldKey="mainEmail" title="Main Email" />
                <form.TextField fieldKey="taxCode" title="Tax Code" />
                <form.TextField fieldKey="taxCountry" title="Tax Country" />
                <form.TextField fieldKey="accountNo" title="Account Number" />
                <form.TextField fieldKey="creditLimit" title="Credit Limit" />
                <form.TextField fieldKey="jobStatus" title="Job Status" />
                <form.TextField fieldKey="jobDescription" title="Job Description" />
                <form.DateField fieldKey="startDate" title="Start Date" />
                <form.DateField fieldKey="projectedEnd" title="Projected End Date" />
                <form.DateField fieldKey="endDate" title="End Date" />
                <form.TextField fieldKey="jobTitle" title="Job Title" />
                <form.TextField fieldKey="typeOfOrg" title="Type of Organization" />
                <form.TextField fieldKey="paymentTerms" title="Payment Terms" />
            </FormSection>
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <TextBox state={state} label="Name" property="name" fullWidth />
    </>)
}
