import { TextInput, Search, ExpandableSearch, ClickableTile } from 'carbon-components-react'
import React, { createRef, useEffect, useState } from 'react'

import {
    Pin16, PinFilled16,

    Dashboard32, User32, Product32, Screen32, Category32, Barcode32, Box32, Compare32, Rocket32,
    Delivery32, AirlinePassengerCare32, CutOut32, Headset32, Percentage32, Store32, DataVis_232,
    Calculator32, WatsonHealthTextAnnotationToggle32, Archive32, StringText32, Chip32, InventoryManagement32,
    FlowStream32, Cube32, DocumentImport32, Purchase32, Money32, Finance32, Account32, Receipt32, Events32,
    Gift32, TagGroup32, QrCode32, Close16, SubtractAlt32, Currency32, IncreaseLevel32, ShoppingCartArrowUp32,
    Api32, License32, RequestQuote32, Notebook32, Table32, ShoppingCartArrowDown32, InProgress32,
    DataFormat32, TreeView32, Catalog32, ListDropdown32, Course32, Grid32, DataShare32, Data_132,
    CaretRight16, ArrowDown32, ArrowUp32, PenFountain32, DeliveryParcel32, LicenseGlobal32,
    Network_132, CategoryNewEach32, PhraseSentiment32, Gradient32, App32, Book32, Document32,
    Wallet32, ReportData32, Van32, RulerAlt32, Types32, ShoppingCart32, Classification32
} from '@carbon/icons-react'
import Util from '../util/Util'
import { Link } from 'react-router-dom'
import Button from './Button'
import { getAccount, getAccountRole } from '../session/SessionManager'
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR } from '../constants/Constants'
import { hasCapabilitySupport } from '../app/Capabilities'

import Logo from './../images/LogoTransparent.png'
import RoboticLogo from './../images/RL.png'
import { HAS_ROBOTIC_BRANDING, HAS_SMALL_ROBOTIC_BRANDING } from '../app/Config'
import useHover from '../hooks/useHover'
import useLocalStorage from '../hooks/useLocalStorage'
import MenuSection from './menu/MenuSection'
import { getReportLink } from '../reporting-engine/base/report-page'

// const MenuItem = ({icon, title, desc, opacity}) => {
//     const [hover, setHover] = useState(false)

//     return (
//         <ClickableTile onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} 
//         style={{overflow: 'hidden', width: '100%', height: 60, display: 'flex', alignItems: 'center', opacity: opacity}}>
//             {React.createElement(icon, {
//                 style: {
//                     width: 24,
//                     height: 24
//                 }
//             })}
//             <h4 style={{marginLeft: '1rem'}}>{title}</h4>
//         </ClickableTile>
//     )
// }

const PinButton = ({ pinned, onPinStatusUpdate }) => {
    // const [pinnedValue, setPinned] = useLocalStorage(pinKey + "-MENU-PINNED")
    // const isPinned = pinnedValue == "true";
    // const onClick = e => {
    //     e.stopPropagation();
    //     e.preventDefault();

    //     const newValue = (!isPinned) ? "true" : "false";
    //     setPinned(newValue);
    // }
    // useEffect(() => {
    //     onPinStatusUpdate(isPinned)
    // }, [pinnedValue])

    const isPinned = pinned;
    const onClick = e => {
        e.stopPropagation();
        e.preventDefault();
        onPinStatusUpdate(!isPinned)
    }
    return (
        <div onClick={onClick} className={`pin-button ${isPinned ? 'pin-button-pinned' : ''}`} style={{ marginRight: 5 }}>
            {/* {isPinned + '-'} */}
            {isPinned ? <PinFilled16 /> : <Pin16 />}
        </div>
    )
}

const MenuItem = ({ icon, title, link, desc, opacity, color, onPinStatusUpdate }) => {
    const hover = useHover();

    return (
        <ClickableTile className={`menu-btn ${color}`} {...hover.init()}
            style={{
                overflow: 'hidden', width: '100%', height: 45, display: 'flex', alignItems: 'center', //opacity: opacity
                // ...(color ? {
                //     color: color
                // } : {})
            }}>
            {React.createElement(icon, {
                style: {
                    width: 18,
                    height: 18
                }
            })}
            <h4 style={{ marginLeft: '1rem', fontSize: 16, flex: 1 }}>{title}</h4>

            {hover.isHover && <PinButton pinKey={link} onPinStatusUpdate={onPinStatusUpdate} />}
        </ClickableTile>
    )
}


function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}


class Menu extends React.Component {

    componentDidUpdate(prevProps, prevState) {
        let i = 0;
        for (const property in this.state) {
            if (property.endsWith("-expanded") && this.state[property]) {
                i++;
            }
        }

        if (i > 1) {
            for (const property in prevState) {
                if (property.endsWith("-expanded") && prevState[property]) {
                    this.setState({ [property]: false })
                }
            }
        }
    }

    /**
     * @returns {Array<{title: string, subMenus: Array<{title: string, menus: Array<{icon: React.Component, title: string, desc: string}>}>}>}
     */
    getMenu() {
        const accountRole = getAccountRole();
        const hasSalesSystem = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_SALES) && hasCapabilitySupport("salesSystem")

        let menu = [
            {
                title: "Portal",
                color: "black",
                subMenus: [
                    {
                        menus: (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) ? [
                            {
                                icon: Dashboard32,
                                title: "Dashboard",
                                desc: "Get an overview of the system",
                                link: "/"
                            },
                            {
                                icon: Chip32,
                                title: "Monitoring",
                                desc: "Monitor active POS terminals",
                                link: "/monitoring"
                            },
                            {
                                icon: Calculator32,
                                title: "POS",
                                desc: "Open POS terminal",
                                link: "/pos"
                            }
                        ] : [
                            // {
                            //     icon: Chip32,
                            //     title: "Monitoring",
                            //     desc: "Monitor active POS terminals",
                            //     link: "/monitoring"
                            // },
                            {
                                icon: Calculator32,
                                title: "POS",
                                desc: "Open POS terminal",
                                link: "/"
                            }
                        ]
                    }
                ]
            },
            ...((hasSalesSystem) ? [{
                title: "Sales",
                color: "orange",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: ShoppingCart32,
                                title: "Carts",
                                link: "/sales-carts"
                            },
                            {
                                icon: Types32,
                                title: "Cart Types",
                                link: "/sales-cart-types"
                            },
                        ]
                    }
                ]
            }] : []),
            {
                title: "Point of Sale",
                color: "blue",
                subMenus: [
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: DataFormat32,
                                title: "POS Summary",
                                link: "/pos-sales-summary-report"
                            },
                            {
                                icon: Receipt32,
                                title: "POS Sales",
                                link: "/pos-sales-report"
                            },
                            {
                                icon: InProgress32,
                                title: "Work Periods",
                                link: "/work-period-report"
                            },
                            {
                                icon: Account32,
                                title: "Cash Closed",
                                link: "/cash-closed-report"
                            },
                            {
                                icon: Rocket32,
                                title: "POS Product Performance",
                                link: "/pos-product-performance-report"
                            },
                        ]
                    }
                ]
            },
            {
                title: "Finances",
                color: "dark-pink",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: Currency32,
                                title: "Transaction",
                                desc: "View and record inward/outward transactions",
                                link: "/transactions"
                            },
                            {
                                icon: PenFountain32,
                                title: "Cheques",
                                link: "/cheques"
                            },
                            {
                                icon: ArrowDown32,
                                title: "Receipt Voucher",
                                link: "/receipt-voucher-creator"
                            },
                            {
                                icon: ArrowUp32,
                                title: "Payment Voucher",
                                link: "/payment-voucher-creator"
                            },
                            ...(hasCapabilitySupport("CRV") ? [{
                                icon: Wallet32,
                                title: "Cash Receipt Voucher",
                                link: "/cash-receipt-vouchers"
                            }] : []),
                            {
                                icon: Finance32,
                                title: "Taxes",
                                link: "/taxes"
                            },
                        ]
                    },
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: IncreaseLevel32,
                                title: "Receipts",
                                desc: "Analyze income",
                                link: "/receipts-report"
                            },
                            {
                                icon: SubtractAlt32,
                                title: "Payments",
                                desc: "Analyze expenses",
                                link: "/payments-report"
                            },
                            {
                                icon: Money32,
                                title: "Net Cash Flow",
                                desc: "Calculate profits",
                                link: "/net-cash-flow-report"
                            },
                            ...(hasCapabilitySupport("CRV") ? [{
                                icon: ReportData32,
                                title: "CRV Summary",
                                link: "/crv-report"
                            }, {
                                icon: ReportData32,
                                title: "CRV Detail",
                                link: "/crv-report-detail"
                            }] : []),
                        ]
                    }
                ]
            },
            {
                title: "Inventory",
                color: "yellow",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: FlowStream32,
                                title: "Stock Flow",
                                desc: "View and initiate stock flow",
                                link: "/stock-flows"
                            },
                        ]
                    },
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: InventoryManagement32,
                                title: "Stock Report",
                                desc: "Analyze stock",
                                // link: "/stock-report"
                                link: getReportLink("StockReport")
                            },
                            // {
                            //     icon: Grid32,
                            //     title: "Product Stocks",
                            //     // link: "/product-stock-report"
                            //     link: getReportLink("ProductStockReport")
                            // },
                            {
                                icon: ShoppingCartArrowUp32,
                                title: "Sales Report",
                                desc: "Analyze sales",
                                link: "/sales-report"
                            },
                            {
                                icon: ShoppingCartArrowDown32,
                                title: "Purchases Report",
                                link: "/purchases-report"
                            },
                            {
                                icon: Data_132,
                                title: "Sales Summary",
                                link: "/sales-summary-report"
                            },
                            {
                                icon: DataShare32,
                                title: "Product Performance",
                                link: "/product-performance-report"
                            },
                        ]
                    }
                ]
            },
            {
                title: "Accounting",
                color: "pink",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: RequestQuote32,
                                title: "Journal Entries",
                                link: "/journal-entries"
                            },
                            {
                                icon: TreeView32,
                                title: "Account Tree",
                                link: "/account-tree"
                            },
                            {
                                icon: Api32,
                                title: "Account Ledger Groups",
                                link: "/account-ledger-groups"
                            },
                            {
                                icon: License32,
                                title: "Account Ledgers",
                                link: "/account-ledgers"
                            },
                        ]
                    },
                    {
                        title: "Statements",
                        menus: [
                            {
                                icon: Notebook32,
                                title: "Balance Sheet",
                                link: "/balance-sheet"
                            },
                            {
                                icon: Money32,
                                title: "Profit & Loss Statement",
                                link: "/profit-and-loss-statement"
                            },
                            {
                                icon: Table32,
                                title: "Trial Balance",
                                link: "/trial-balance"
                            },
                            // {
                            //     icon: Course32,
                            //     title: "Cash Book",
                            // },
                        ]
                    },
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: Classification32,
                                title: "Outstanding Report",
                                link: "/outstanding-report"
                            },
                            {
                                icon: Catalog32,
                                title: "General Ledger Report",
                                link: "/general-ledger-report"
                            },
                            {
                                icon: ListDropdown32,
                                title: "Subsidiary Ledger Report",
                                link: "/subsidiary-ledger-report"
                            }
                        ]
                    }
                ]
            },
            {
                title: "Products",
                color: "light-blue",
                subMenus: [
                    {
                        title: "Management",
                        menus: [
                            {
                                icon: Product32,
                                title: "Products",
                                desc: "View, add and edit products",
                                link: "/products"
                            },
                            {
                                icon: Box32,
                                title: "Bundles",
                                desc: "View, add and edit product bundles",
                                link: "/product-bundles"
                            },
                            ...(hasCapabilitySupport("continuousStockType") ? [{
                                icon: RulerAlt32,
                                title: "Measurement Types",
                                link: "/measurement-types"
                            }] : []),
                        ]
                    },
                    {
                        title: "Classification",
                        menus: [
                            {
                                icon: PhraseSentiment32,
                                title: "Brands",
                                desc: "View, add and edit product brands",
                                link: "/product-brands"
                            },
                            {
                                icon: Network_132,
                                title: "Departments",
                                desc: "View, add and edit departments",
                                link: "/product-departments"
                            },
                            {
                                icon: Category32,
                                title: "Categories",
                                desc: "View, add and edit categories",
                                link: "/product-categories"
                            },
                            {
                                icon: CategoryNewEach32,
                                title: "Subcategories",
                                desc: "View, add and edit subcategories",
                                link: "/product-subcategories"
                            },
                        ]
                    },
                    {
                        title: "Labeling",
                        menus: [
                            {
                                icon: Barcode32,
                                title: "Product Labels",
                                desc: "Generate barcode and QR code labels",
                                link: "/product-labels"
                            },
                            {
                                icon: Barcode32,
                                title: "Bundle Labels",
                                desc: "Generate barcode and QR code labels",
                                link: "/bundle-labels"
                            },
                        ]
                    },
                    {
                        title: "Reporting",
                        menus: [
                            {
                                icon: DeliveryParcel32,
                                title: "Low Stock Products",
                                link: "/low-stock-product-report"
                            }
                        ]
                    }
                    // {
                    //     title: "Reporting",
                    //     menus: [
                    //         {
                    //             icon: Rocket32,
                    //             title: "Performance",
                    //             desc: "Compare product performance"
                    //         },
                    //     ]
                    // }
                ]
            },
            {
                title: "Relations",
                color: "green",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: Delivery32,
                                title: "Suppliers",
                                desc: "View, add and edit suppliers",
                                link: "/suppliers"
                            },
                            {
                                icon: AirlinePassengerCare32,
                                title: "Customers",
                                desc: "View, add and edit customers",
                                link: "/customers"
                            },
                            {
                                icon: Events32,
                                title: "Groups",
                                desc: "View, add and edit customer groups",
                                link: "/customer-groups"
                            },
                            {
                                icon: Gift32,
                                title: "Loyalty",
                                desc: "View, add and edit loyalty cards",
                                link: "/loyalty-cards"
                            },
                            {
                                icon: CutOut32,
                                title: "Coupons",
                                desc: "View, add and edit coupons",
                                link: "/coupons"
                            },
                            {
                                icon: TagGroup32,
                                title: "Discounts",
                                desc: "View, add and edit store discount periods",
                                link: "/discounts"
                            },
                            // {
                            //     icon: Percentage32,
                            //     title: "Quotations",
                            //     desc: "View, add and edit custom quotations",
                            //     link: "/quotations"
                            // },
                            {
                                icon: Headset32,
                                title: "Contacts",
                                desc: "View, add and edit contact informations",
                                link: "/contacts"
                            }
                        ]
                    }
                ]
            },
            {
                title: "Company",
                color: "red",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: User32,
                                title: "Staff Users",
                                desc: "View, add and edit staff users",
                                link: "/staff-users"
                            },
                            {
                                icon: Store32,
                                title: "Stores",
                                desc: "View, add and edit stores",
                                link: "/stores"
                            },
                            {
                                icon: DataVis_232,
                                title: "Warehouses",
                                desc: "View, add and edit warehouses",
                                link: "/warehouses"
                            },
                            ...(hasCapabilitySupport("mobileUnit") ? [{
                                icon: Van32,
                                title: "Mobile Units",
                                link: "/mobile-units"
                            }] : []),
                            {
                                icon: Screen32,
                                title: "Terminals",
                                desc: "View, add and edit terminals",
                                link: "/terminals"
                            },
                        ]
                    },
                ]
            },
            {
                title: "System",
                color: "white",
                subMenus: [
                    {
                        menus: [
                            {
                                icon: Gradient32,
                                title: "Templates",
                                link: "/templates"
                            },
                            {
                                icon: LicenseGlobal32,
                                title: "Supported Currencies",
                                link: "/supported-currencies"
                            },
                            {
                                icon: WatsonHealthTextAnnotationToggle32,
                                title: "System Values",
                                desc: "Set system values",
                                link: "/system-values"
                            },
                            // {
                            //     icon: StringText32,
                            //     title: "Attributes",
                            //     desc: "Define custom system attributes"
                            // },
                            {
                                icon: DocumentImport32,
                                title: "Import",
                                desc: "Import spreadsheet data into system",
                                link: "/import-data"
                            },
                            {
                                icon: Archive32,
                                title: "Logs",
                                desc: "View system logs",
                                link: "/system-logs",
                            },
                            ...(hasCapabilitySupport("salesApp") ? [{
                                icon: App32,
                                title: "Sales App",
                                link: "/sales-app"
                            }] : []),
                            ...(hasCapabilitySupport("pocketPosApp") ? [{
                                icon: App32,
                                title: "Pocket POS App",
                                link: "/pocket-pos-app"
                            }] : []),
                        ]
                    },
                ]
            },
        ];



        if (accountRole == ACCOUNT_TYPE_SUPERVISOR) {
            for (const menuGroup of menu) {
                for (const subMenu of menuGroup.subMenus) {
                    subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/system-logs" && menuItem.link != "/staff-users" && menuItem.link != "/supported-currencies" && menuItem.link != "/sales-app" && menuItem.link != "/pocket-pos-app" && menuItem.link != "/system-values");
                }
            }
        } else if (accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
            menu = menu.filter(menu => (menu.title == "Accounting" || menu.title == "Finances" || menu.title == "Point of Sale"));
        } else if (accountRole == ACCOUNT_TYPE_SALES) {
            menu = menu.filter(menu => (menu.title == "Portal" || menu.title == "Products" || menu.title == "Relations"));

            for (const menuGroup of menu) {
                for (const subMenu of menuGroup.subMenus) {
                    subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/monitoring");
                }

                if (menuGroup.title == "Relations") {
                    for (const subMenu of menuGroup.subMenus) {
                        subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link == "/customers" || menuItem.link == "/customer-groups" || menuItem.link == "/contacts");
                    }
                }
            }
        }

        return menu;
    }

    constructor(props) {
        super(props);

        this.state = {
            ["Portal" + "-expanded"]: true,

            pinnedMenus: []
        }

        this.wrapperRef = createRef();
    }

    componentDidMount() {
        this.onClickHandle = (e => {
            if (e.target.id == "menu-btn") {
                return;
            }

            // console.log(e)

            if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target) && this.props.onClose !== undefined) {
                this.props.onClose();
            }
        }).bind(this);

        window.addEventListener('mousedown', this.onClickHandle);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.onClickHandle)
    }

    getMenuHeight(menuGroup) {
        let total = 0;
        //total += 45 * menuGroup.subMenus.length


        for (const subMenu of menuGroup.subMenus) {
            // total += convertRemToPixels(1)

            if (Util.isStringExists(subMenu.title)) {
                total += 45;
            } else {
                total += convertRemToPixels(1)
            }

            total += 45 * subMenu.menus.length
        }

        return total;
        // return null
    }

    onPinStatusUpdate(pinned, menu) {
        if (pinned) {
            this.setState(prevState => ({
                pinnedMenus: [...prevState.pinnedMenus, menu]
            }))
        } else {
            this.setState(prevState => ({
                pinnedMenus: prevState.pinnedMenus.filter(item => item.link != menu.link)
            }))
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef} style={{}} className="menu-tile-2">
                {/* {this.state.pinnedMenus.map(menu => (
                    <Link to={menu.link} onClick={menu.link !== undefined && (() => this.props.onClose())} style={{textDecoration: 'none'}}>
                        <MenuItem 
                        link={menu.link}
                        onPinStatusUpdate={(pinned) => this.onPinStatusUpdate(pinned, menu)}
                        //color={menuGroup.color} 
                        key={menu.title} icon={menu.icon} title={menu.title} desc={menu.desc} opacity={Util.isStringExists(menu.link) ? 1 : 0.4} />
                    </Link>
                ))} */}

                {this.getMenu().map((menuGroup, index) => (<>
                    <div onClick={() => this.setState(prevState => ({ [menuGroup.title + "-expanded"]: prevState[menuGroup.title + "-expanded"] !== undefined ? !prevState[menuGroup.title + "-expanded"] : true }))}
                        style={{ width: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer', paddingTop: index == 0 ? '1rem' : '1rem', }}>
                        <CaretRight16 style={{ marginLeft: '1rem', opacity: 0.65, marginRight: '0.25rem', transition: '250ms', color: 'white', transform: this.state[menuGroup.title + "-expanded"] ? 'rotate(90deg)' : 'rotate(0deg)' }} />

                        <h4
                            style={{ paddingLeft: '0.5rem', flex: 1 }}>{menuGroup.title}</h4>
                    </div>

                    <div style={{
                        transition: '250ms', height: this.state[menuGroup.title + "-expanded"] ? this.getMenuHeight(menuGroup) : 0,
                        overflow: 'hidden'
                    }}>
                        {menuGroup.subMenus.map((subMenu, index) => (<>
                            {Util.isStringExists(subMenu.title) ?
                                <p style={{ fontSize: 12, opacity: 0.65, paddingLeft: '1rem', marginBottom: '1rem', marginTop: index > 0 ? '1rem' : undefined }}>{subMenu.title}</p> :
                                <div style={{ marginBottom: '1rem' }} />}

                            <MenuSection menus={subMenu.menus} color={menuGroup.color} onClose={this.props.onClose} />
                            {/* {subMenu.menus.map(menu => (
                                <Link to={menu.link} onClick={menu.link !== undefined && (() => this.props.onClose())} style={{textDecoration: 'none'}}>
                                    <MenuItem 
                                    link={menu.link}
                                    onPinStatusUpdate={(pinned) => this.onPinStatusUpdate(pinned, menu)}
                                    color={menuGroup.color} key={menu.title} icon={menu.icon} title={menu.title} desc={menu.desc} opacity={Util.isStringExists(menu.link) ? 1 : 0.4} />
                                </Link>
                            ))} */}
                        </>))}
                    </div>
                </>))}

                {HAS_ROBOTIC_BRANDING &&
                    <><div style={{ height: '1rem' }} />
                        <img src={Logo} style={{ objectFit: 'contain', objectPosition: 'center', height: '100%', cursor: 'pointer', width: 200, padding: '1rem' }} />
                        <div style={{ height: '1rem' }} /></>}

                {HAS_SMALL_ROBOTIC_BRANDING &&
                    <><div style={{ height: '1rem' }} />
                        <img src={RoboticLogo} style={{ objectFit: 'contain', objectPosition: 'center', height: '100%', cursor: 'pointer', width: 200, padding: '1rem' }} />
                        <div style={{ height: '1rem' }} /></>}
            </div>
        )
    }

}

export default Menu;