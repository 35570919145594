import { Checkbox, Loading, NumberInput, Slider, Tab, Tabs, TextArea, TextInput } from 'carbon-components-react'
import React, { useState } from 'react'
import DimensionsField from './base/DimensionsField'
import InspectorContentHeader from './base/InspectorContentHeader'

import useCanvasComponentInspectorField from '../../hooks/useCanvasComponentInspectorField'
import ColorField from './base/ColorField'
import useBooleanToggler from '../../hooks/useBooleanToggler'
import ThumbnailSelector from '../../../../components/ThumbnailSelector'
import Api from '../../../../session/Api'
import UIUtil from '../../../../util/UIUtil'
import Util from '../../../../util/Util'

export default ({engine, componentId}) => {
    const { changeListener, getter, setter, multiSetter } = useCanvasComponentInspectorField(engine, componentId, ['fill', 'stroke', 'strokeWidth', 'strokeDashArray', 'imageId', 'valueDynamic']);
    const [isValueDynamic, toggleValueDynamic] = useBooleanToggler('valueDynamic', true, false, getter, setter);

    const isDashedStroke = getter('strokeDashArray') != null && getter('strokeDashArray').length > 0;
    const toggleDashedStroke = () => setter('strokeDashArray', isDashedStroke ? [] : [10, 5], true);

    const [uploaderKey, setUploaderKey] = useState(Util.newTempId());
    const [uploading, setUploading] = useState(false);

    const uploadImage = image => {
        setUploading(true);
        Api.uploadImage(image, response => {
            setUploading(false);
            if (response.status) {
                setter('imageId', response.payload, true);
                setUploaderKey(Util.newTempId())
                UIUtil.showSuccess();
            } else {
                UIUtil.showError();
            }
        })
    }

    return (
        <div>
            {uploading && <Loading />}

            <InspectorContentHeader text="Content" />
            <div className="short-width-tabs">
                <Tabs selected={isValueDynamic ? 1 : 0} onSelectionChange={index => toggleValueDynamic()}>
                    <Tab id="static" label="Static">
                        <ThumbnailSelector key={uploaderKey} 
                        customTitle="Image" skipChange objectFit={getter('imageId') ? "contain" : "cover"}
                        src={getter('imageId') ? Api.getImage(getter('imageId')) : undefined} 
                        style={{width: '100%', height: 236,}} onChange={image => uploadImage(image)} />
                    </Tab>
                    <Tab id="dynamic" label="Dynamic">
                        {engine.getTemplate().supportDynamicImage ? (
                            <p style={{fontSize: 14, opacity: 0.65}}>{engine.getTemplate().itemName}'s thumbnail will be displayed</p>
                        ) : (
                            <p style={{fontSize: 14, opacity: 0.65, color: 'red'}}>Dynamic image content is not supported in template type '{engine.getTemplate().name}'</p>
                        )}
                    </Tab>
                </Tabs>
            </div>

            <InspectorContentHeader text="Border" hasTopMargin />
            <Slider onRelease={changeListener.onBlur} labelText="Outline Thickness" min={0} max={50} hideTextInput value={getter('strokeWidth')} onChange={({ value }) => setter('strokeWidth', value)} />
            <div style={{height: '1rem'}} />
            
            <ColorField title="Outline Color" color={getter('stroke')} onChange={color => setter('stroke', color, true)} />
            <div onClick={() => toggleDashedStroke()}>
                <Checkbox labelText="Dashed Outline" checked={isDashedStroke} />
            </div>
        </div>
    )
}