import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react"
import { useEffect, useState } from "react"
import Button from "../../components/Button"
import UIUtil from "../../util/UIUtil";
import { useForm } from "./form";
import { fixIdForApi } from "./form-api";


export function FormDialog({ size, customPositiveLabel, label, title, creating, customFooterButtons, open, onClose, observableSetter, successMsg, item, setItem, saveApi, children, payload, preventCloseOnClickOutside, skipToast, formConfig = {} }) {
    const [hide, setHide] = useState(false);

    const form = useForm(observableSetter, {
        // replaceComboBoxWithSelect: popoverMode,
        light: true,
        ...formConfig
    });

    useEffect(() => {
        if (open && item) {
            form.setData(JSON.parse(JSON.stringify(item)))
        }

        setHide(false);
    }, [open])

    const [loading, setLoading] = useState(false);

    const onSave = () => {
        setLoading(true);
        const data = fixIdForApi(form.getFormData());
        saveApi(data, response => {
            if (response.status === true) {
                if (!skipToast) {
                    UIUtil.showSuccess(successMsg);
                }
                onClose();
                if (setItem) {
                    setItem(response.payload)
                }
            } else {
                if (!skipToast) {
                    UIUtil.showError(response.message);
                }
            }
            setLoading(false)
        })
    }

    return (
        <ComposedModal
            // className="modal-that-supports-combo-box"
            size={size}
            open={open && !hide} onClose={onClose} preventCloseOnClickOutside={preventCloseOnClickOutside}>
            <ModalHeader label={label} title={title} />
            <ModalBody style={{ paddingRight: '1rem', outline: 'none' }} hasScrollingContent
            //className="dark-minimal-scroll-bar"
            >
                {/* <div style={{ overflow: 'scroll', paddingRight: 'calc(0.5rem-6px)' }}> */}
                {children(form, payload, { setHide })}
                {/* </div> */}
            </ModalBody>
            <ModalFooter
            >
                {(customFooterButtons !== undefined && customFooterButtons !== null) ? (
                    customFooterButtons({ form, loading, onClose, onSave })
                ) : (<>
                    <Button kind="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={onSave}
                    >
                        {loading ? "Loading..." : customPositiveLabel ?? (creating ? "Create" : "Save")}
                    </Button>
                </>)}
            </ModalFooter>
        </ComposedModal>
    )
}