import { useState } from "react";
import Api from "../session/Api";
import UIUtil from "../util/UIUtil";


export default function useApi(apiFunc, onSuccess, onResponse) {
    const [loading, setLoading] = useState(false);
    return [
        loading,
        () => {
            setLoading(true);
            apiFunc.bind(Api)(response => {
                setLoading(false);
                if (onSuccess) {
                    if (response.status === true) {
                        onSuccess(response.payload)
                        UIUtil.showSuccess();
                    } else {
                        UIUtil.showError(response.message)
                    }
                } else {
                    if (onResponse) {
                        onResponse(response)
                    }
                }
            })
        },
    ];
}
