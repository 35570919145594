import { ComboBox, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import Api from "../../../session/Api"
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { BlueprintInputFieldsForm } from "../components/blueprint-input-fields-form";

const Loading = () => (
    <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
        <InlineLoading style={{ width: 'unset' }} />
    </div>
)

const FormValues = ({ inputFields, value, onValueChanged }) => {
    if (inputFields) {
        return (
            <div>
                <BlueprintInputFieldsForm inputFields={inputFields} value={value} onValueChanged={onValueChanged} />
            </div>
        )
    } else {
        return (
            <div style={{ width: '100%', height: '6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>
                    No field exists
                </p>
            </div>
        )
    }
}


export function SetActivityStatusDialog({ open, onClose, item, replicateRequest, overrideSuccess }) {
    const [loading, setLoading] = useState(false);
    const [setting, setSetting] = useState(false);
    const [statuses, setStatuses] = useState([]);


    const [selectedStatus, setSelectedStatus] = useState(undefined);
    const [statusPickerKey, setStatusPickerKey] = useState(() => Util.newTempId());
    const [formFieldValues, setFormFieldValues] = useState([]);


    useEffect(() => {
        if (open) {
            let cancelled = false;

            setLoading(true);
            setSelectedStatus(undefined)
            setStatusPickerKey(Util.newTempId())
            setFormFieldValues([])

            Api.getActivitySupportedStatuses(item.id, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    let statusToSelect = undefined;
                    if (replicateRequest) {
                        statusToSelect = response.payload.find(status => status.type == item.statusId);
                        if (statusToSelect) {
                            setSelectedStatus(statusToSelect)
                        } else {
                            UIUtil.showInfo("Status not supported anymore")
                            onClose();
                            return;
                        }

                        if (item.latestStatusRecord && item.latestStatusRecord.formFieldValues) {
                            const formValuesToReplicate = item.latestStatusRecord.formFieldValues;
                            setFormFieldValues(JSON.parse(JSON.stringify(formValuesToReplicate)))
                        }
                    }

                    setLoading(false);
                    setStatuses(response.payload)
                } else {
                    UIUtil.showError(response.message);
                    onClose();
                }
            })

            return () => cancelled = true;
        }
    }, [open])

    const onSet = () => {
        setSetting(true)
        Api.setActivityStatus(item.id, selectedStatus?.type, formFieldValues, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                if (overrideSuccess) {
                    overrideSuccess(response.payload)
                    onClose();
                } else {
                    window.location.reload();
                }
            } else {
                UIUtil.showError(response.message)
            }
            setSetting(false)
        })
    }
    // Api.getActivitySupportedStatuses()

    return (
        <ComposedModal key="set-activity-status-dialog" open={open} onClose={onClose}>
            <ModalHeader label="Activity" title="Update Status" />
            <ModalBody style={{ paddingRight: '1rem', outline: 'none' }} hasScrollingContent>
                {loading ? <Loading /> : (<>
                    <ComboBox
                        light
                        key={statusPickerKey}
                        titleText={"Select status"}
                        items={statuses}
                        itemToString={item => item !== null ? item.title : ""}
                        selectedItem={selectedStatus}
                        onChange={e => {
                            setSelectedStatus(e.selectedItem)
                            if (!e.selectedItem) {
                                setStatusPickerKey(Util.newTempId())
                            }
                        }} />

                    <p style={{ fontWeight: 'bold', color: 'black', marginTop: '1rem' }}>Details</p>
                    <FormValues inputFields={selectedStatus?.params?.inputFields} value={formFieldValues} onValueChanged={setFormFieldValues} />
                </>)}
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={setting || loading || selectedStatus === undefined}
                    onClick={onSet}>
                    {setting ? "Loading..." : "Set Status"}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}