import { useState } from 'react';

const useColorGenerator = () => {
    const [colorMap, setColorMap] = useState({});

    const getIdColor = (id) => {
        if (colorMap[id]) {
            // If color is already generated for this id, return the same color.
            return colorMap[id];
        } else {
            // Generate a random bright color and store it in the colorMap for future reference.
            // const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            let color;
            do {
                color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
                const r = parseInt(color.substr(1, 2), 16);
                const g = parseInt(color.substr(3, 2), 16);
                const b = parseInt(color.substr(5, 2), 16);
                // Check if the color is bright.
                if ((r + g + b) / 3 < 150) {
                    color = null;
                }
            } while (!color);
            setColorMap((prevColorMap) => ({ ...prevColorMap, [id]: color }));
            return color;
        }
    };

    return { getIdColor };
};

export default useColorGenerator;