import React from 'react'
import { useHistory, Link as LK } from 'react-router-dom';
import { getObjectTypeUrl, OBJECT_TYPE_INVENTORY_BATCH_NO } from '../../constants/ObjectTypes';
import { withLoadablePagePropsAndProps, withLoadablePageWithParams } from '../../base/Page';
import ItemTemplate from '../../templates/ItemTemplate';
import Api from '../../session/Api';
import { FormGroup, Link } from 'carbon-components-react';
import BarcodesEditor from '../../components/BarcodesEditor';


const OBJECT_TYPE = OBJECT_TYPE_INVENTORY_BATCH_NO;

const Barcodes = withLoadablePagePropsAndProps(props => listener => Api.getInvBatchBarcodes(props.item.batchNo, listener), ({ payload }) => {

    return (
        <div style={{ paddingTop: '1rem' }}>
            {payload.products.length === 0 && <p style={{ fontSize: 12, opacity: 0.65 }}>No stock in batch</p>}
            {payload.products.map(product => (
                <FormGroup key={product.id} legendText={<p style={{ fontWeight: 'bold', color: 'black' }}>
                    {product.name} - <LK to={"/products/" + product.id} target="_blank">
                        <Link onClick={e => e.stopPropagation()}>View Product</Link>
                    </LK>
                </p>} hasMargin>
                    <BarcodesEditor
                        readonly
                        showTemplateLabelPrint={{ itemId: product.id, productBundle: false }}
                        defaultBarcodes={product.barcodes}
                        onBarcodesUpdate={items => { }} />
                </FormGroup>
            ))}
        </div>
    )
})

const Page = withLoadablePageWithParams(ItemTemplate.createLoader(OBJECT_TYPE), ({ payload: itemResult }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            {ItemTemplate.renderTemplate({
                objectType: OBJECT_TYPE,
                itemResult: itemResult,
                pagePath: getObjectTypeUrl(OBJECT_TYPE) + "/",
                history: history,
                customTabs: [
                    {
                        title: "Barcodes",
                        component: Barcodes
                    }
                ]
            })}
        </div>
    )
})

export const InventoryBatchNoDetailPage = Page;
