import React, { useEffect, useState } from "react";
import {
    Elements, PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import UIUtil from "../../util/UIUtil";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_API_KEY } from "../../app/Config";
import Util from "../../util/Util";
import Button from "../../components/Button";
import { Purchase16, ErrorFilled16 } from '@carbon/icons-react'

export const stripePromise = Util.isStringExists(STRIPE_API_KEY) ? loadStripe(STRIPE_API_KEY) : undefined;


const StripePaymentDialog = ({ serviceSaleId, amount, onClose }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onPaymentBtn = async (e) => {
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.origin.toString() + '/invoice-payment-result/' + serviceSaleId,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            //UIUtil.showError(error.message)
            alert(error.message);
        } else {
            //UIUtil.showError();
            alert('An error occurred. Please try again later.')
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }
    return (
        <ComposedModal open onClose={onClose}>
            <ModalHeader label="Online" title="Payment" />
            <ModalBody>
                <label className="bx--label" style={{ margin: 0 }}>Amount to Pay</label>
                <h4 style={{ color: 'green', marginBottom: '1rem' }}>AED {Util.formatMoney(amount)}</h4>
                <form id="payment-form">
                    <LinkAuthenticationElement
                        id="link-authentication-element"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <PaymentElement id="payment-element" options={paymentElementOptions} />
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                </form>
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16}>
                    Close
                </Button>
                <Button loading={isLoading} disabled={!stripe || !elements} onClick={onPaymentBtn} renderIcon={Purchase16}>
                    Complete Payment
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}

export function showStripePayment(serviceSaleId, clientSecret, amount) {
    UIUtil.showOverlay2(onClose => {
        const appearance = {
            theme: 'stripe',
            labels: 'floating'
        };
        const options = {
            clientSecret,
            appearance,
        };

        return (
            <Elements options={options} stripe={stripePromise}>
                <StripePaymentDialog {...({ serviceSaleId, amount, onClose })} />
            </Elements>
        )
    })
}