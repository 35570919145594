import Button from "../../../components/Button";
import { Edit16 } from '@carbon/icons-react'
import { Modal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { useState } from "react";


export function SetAwbNoBtn({ salesOrder, onUpdate }) {
    const [visible, setVisible] = useState(false);
    return (<>
        <Button onClick={() => setVisible(true)} size="sm" style={{ borderRadius: 50 }} renderIcon={Edit16}>Set AWB No</Button>

        <FormDialog open={visible} onClose={() => setVisible(false)} label="Sales Order" title="AWB No"
            item={{
                awbNo: salesOrder.awbNo || ""
            }}
            setItem={onUpdate}
            saveApi={(data, listener) => Api.setSalesOrderAwbNo(salesOrder.id, data.awbNo, listener)}>
            {({ TextField, }) => (<>
                <TextField title={"AWB No"} fieldKey={"awbNo"} placeholder="AWB No" />
            </>)}
        </FormDialog>
    </>)
}