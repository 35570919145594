import { ContentSwitcher, Switch } from "carbon-components-react";
import usePosSession from "../state/usePosSession";
import useStore from "../../../hooks/useStore";
import { VAT } from "../../../app/Config";


export function NoTax({ sessionStore, editable }) {
    const session = usePosSession(sessionStore)
    const [noTax] = useStore(sessionStore, "noTax")

    return (
        <div>
            <label className="bx--label" style={{ marginBottom: !editable ? 0 : undefined }}>Tax</label>
            {editable ? (
                <ContentSwitcher selectedIndex={noTax ? 1 : 0} onChange={({ index }) => session.setNoTax(index === 1)} style={{ width: 300 }}>
                    <Switch text={VAT.PERC + '%'} />
                    <Switch text="0%" />
                </ContentSwitcher>
            ) : (
                <p style={{ width: 300 }}>{noTax ? '0%' : (VAT.PERC + '%')}</p>
            )}
        </div>
    )
}