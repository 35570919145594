import React from "react"
import CountUp from 'react-countup';

export const CardView = ({ index, icon, title, value }) => {
    return (
        <div className={`sales-work-list-view-${index}`} style={{ flex: 1, height: 175, borderRadius: 5, padding: '1rem', color: 'white', display: 'flex', flexDirection: 'column' }}>
            {React.createElement(icon, {
                style: {
                    width: 64, height: 64, fill: 'white'
                }
            })}

            <div style={{ flex: 1 }}></div>
            <h3 style={{ alignSelf: 'flex-end' }}>{title}</h3>
            {/* <h1 ref={valueRef} style={{ fontWeight: 'bold', alignSelf: 'flex-end', }}>{value}</h1> */}
            <CountUp start={0} end={value} delay={0} duration={1.25} separator="," decimals={0} decimal="">
                {({ countUpRef }) => (
                    <h1 ref={countUpRef} style={{ fontWeight: 'bold', alignSelf: 'flex-end', }} />
                )}
            </CountUp>
        </div>

    )
}
