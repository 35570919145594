import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { DISCOUNT_CUSTOM_LIST_PRODUCT_BUNDLES, PRODUCT_BUNDLE_CUSTOM_LIST_PARENT, SERVICE_PACKAGE_CUSTOM_LIST_PARENT, TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOM_PRICE, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_BUNDLE_ITEM, OBJECT_TYPE_SERVICE_PACKAGE, OBJECT_TYPE_SERVICE_PACKAGE_ITEM } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import UIUtil from '../../util/UIUtil';





const ItemsList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentPackageId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Items",
                subTitle: "Service package items",
                objectType: OBJECT_TYPE_SERVICE_PACKAGE_ITEM,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                className="modal-that-supports-combo-box"
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_SERVICE_PACKAGE_ITEM}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                productFinderShowReplaceWithBundle={true}
                productFinderShowReplaceWithProductGroup
                open={showCreateDialog || selectedItem} onClose={e => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentPackageId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}

const ServicePackagesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                getCustomListRequest: {
                    requestType: SERVICE_PACKAGE_CUSTOM_LIST_PARENT,
                    params: {
                        parentId: item.id,
                    }
                },
                customToolbarContent: (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', flex: 1 }}>
                        <p>Packages containing this package</p>
                    </div>
                ),
                hideCreateButton: true,
                embedded: true,
                title: "Service Packages",
                subTitle: "Packages containing this package",
                objectType: OBJECT_TYPE_SERVICE_PACKAGE,
                pagePath: "/service-packages/",
                history: history
            })}
        </div>
    )
}


class ServicePackageDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_SERVICE_PACKAGE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_SERVICE_PACKAGE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_SERVICE_PACKAGE,
                    itemResult: this.state.itemResult,
                    pagePath: "/service-packages/",
                    history: this.props.history,
                    returnItemOnSave: true,
                    replaceItemOnSave: true,
                    customTabs: [
                        {
                            title: "Items",
                            component: ItemsList
                        },
                        {
                            title: "Packages",
                            component: ServicePackagesList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(ServicePackageDetailPage);