import { startOfMonth, addMonths } from "date-fns";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { OBJECT_TYPE_CUSTOMER } from "../../../constants/ObjectTypes";
import { printStatementOfAccount } from "../../../pdfs/statements-of-account/StatementsOfAccountPdf";
import { getAccountRole } from "../../../session/SessionManager"
import UIUtil from "../../../util/UIUtil";

export const CustomerOutstandingReport = {
    filter: Filter,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
    },

    onAction: (action, data, history) => {
        switch (action) {
            case "name":
                window.open("/customers/" + data.customerId, "_blank")
                return;
            case "statement":
                UIUtil.pageLoader()
                    .then(onClose => printStatementOfAccount(
                        data.customerId, OBJECT_TYPE_CUSTOMER,
                        startOfMonth(new Date()).getTime(),
                        startOfMonth(addMonths(new Date(), 1)).getTime(),
                        onClose
                    ))
                return;
        }
    },
}

function Filter({ endpoint, state }) {
    return (<>

    </>)
}
