import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { TABLE_RELATION_TYPE_ONE_TO_MANY } from "../../constants/Constants";
import { OBJECT_TYPE_ITEM_MEDIA } from "../../constants/ObjectTypes";
import ItemCreatorDialog from "../../templates/ItemCreatorDialog";
import TableTemplate from "../../templates/TableTemplate";
import UIUtil from "../../util/UIUtil";


export const ItemMediaTable = ({ item }) => {
    const itemId = item.id;

    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: itemId,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Media items",
                subTitle: "Media items",
                objectType: OBJECT_TYPE_ITEM_MEDIA,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_ITEM_MEDIA}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                tryMiniFieldSize
                parentId={itemId}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = itemId}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}