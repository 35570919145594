import { useState } from "react";
import { useHistory } from "react-router-dom";
import { withLoadablePage } from "../../../base/Page";
import Button from "../../../components/Button";
import PaginatedDataTable from "../../../components/data-table/PaginatedDataTable";
import Api from "../../../session/Api";
import { CreateFlowDialog } from "../views/create-flow-dialog";
import {
    Add16
} from '@carbon/icons-react'
import Util from "../../../util/Util";
import { StatusIndicator } from "../../../domain/lead";
import { LeadTag, LeadTagView } from "../lead-flow/widgets/flow-info";

const HEADERS = [
    {
        id: "id",
        name: "Lead ID",
        render: item => Util.getVoucherNumber(item.id)
    },
    {
        id: "source",
        name: "Source",
        render: item => <LeadTag name={item.sourceName} color={item.sourceColor} />
    },
    {
        id: "fullName",
        name: "Full Name",
        render: item => item.fullName
    },
    {
        id: "status",
        name: "Status",
        render: item => <StatusIndicator status={item.status} />
    },
    {
        id: "tags",
        name: "Tags",
        render: item => (
            <div style={{ maxWidth: 250 }}>
                <LeadTagView lead={item} />
            </div>
        )
    },
]


const CreateButton = ({ sources, users, userTags, customers }) => {
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    return (<>
        <Button renderIcon={Add16} onClick={() => setVisible(true)}>New Lead</Button>

        <CreateFlowDialog
            onCreated={id => history.push("/lead/" + id)}
            open={visible}
            sources={sources}
            users={users}
            userTags={userTags}
            customers={customers}
            onClose={() => setVisible(false)} />
    </>)
}

const View = ({ payload: { sources, users, customers } }) => {
    const history = useHistory();
    return (
        <div className="main-content">
            <div className="tooltip-supported-toolbar">
                {/* <h1>Leads</h1> */}

                <PaginatedDataTable
                    // reloadRequest={tableKey}
                    // loader={(page, _, listener) => get(getReq(), page, listener)}
                    // loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}

                    loader={(page, _, listener) => Api.getAllLeads(listener)}
                    loadResponseHandler={response => ({ loadedItems: response.payload, loadedHasMore: false })}

                    title="Leads"
                    description={"All leads"}
                    columns={HEADERS}
                    onClick={({ id }) => history.push('/lead/' + id)}

                >
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ flex: 1 }}>
                            {/* <SavedQueries selectedReq={savedReq} onSelectReq={setSavedReq}
                            savedReqs={savedReqs} inFilter={inFilter} clearFilter={() => setReq({})} /> */}
                        </div>
                        {/* <SortBtn sort={sort} setSort={setSort} />
                    {!isCustomerList && !savedReq &&
                        <FilterBtn req={req} onSubmit={setReq} setSavedReqs={setSavedReqs} onSaved={setSavedReq} />}
                    {savedReq &&
                        <DeleteQueryBtn savedReq={savedReq} setSavedReqs={setSavedReqs} onDelete={() => {
                            setSavedReq(undefined)
                        }} />} */}

                        <CreateButton sources={sources} users={users} userTags={[]} customers={customers} />
                    </div>
                </PaginatedDataTable>

                <div style={{ height: 1000 }} />
            </div>
        </div>
    )
}

export const AllLeadFlowList = withLoadablePage(Api.getLeadFlowCreationEndpoints.bind(Api), View);