
import { InlineLoading, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import { getObjectTypeUrl } from '../../constants/ObjectTypes'
import Api from '../../session/Api'
import UIUtil from '../../util/UIUtil'
import Util from '../../util/Util'

export default ({open, onClose}) => {
    const [barcodeValue, setBarcodeValue] = useState("")
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onSearchBtn = (forcedValue) => {
        setLoading(true)
        Api.barcodeSearch(forcedValue !== undefined ? forcedValue : barcodeValue, response => {
            setBarcodeValue("")
            if (response.status === true) {
                if (response.payload.foundResult) {
                    history.push(getObjectTypeUrl(response.payload.resultObjectType) + '/' + response.payload.resultObjectId)
                    onClose();

                    UIUtil.showSuccess("Barcode found!")
                } else {
                    UIUtil.showInfo("Barcode not found")
                }

                setLoading(false);
            } else {
                UIUtil.showError();
                setLoading(false);
            }
        })
    }

    return (
        <ComposedModal open={open} onClose={onClose} size="xs">
            <ModalHeader label="Scan Barcode" title="Search by Barcode" />
            <ModalBody>
                <TextInput
                style={{width: '100%'}}
                data-modal-primary-focus
                value={barcodeValue}
                disabled={loading}
                onPaste={e => onSearchBtn((e.clipboardData || window.clipboardData).getData('text'))}
                onChange={e => setBarcodeValue(e.target.value)}
                labelText="Barcode"
                placeholder="Scan barcode"
                />
                {loading && 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                    <InlineLoading style={{width: 'auto'}} />
                </div>}
            </ModalBody>

            <ModalFooter 
            // primaryButtonDisabled={!Util.isStringExists(barcodeValue)}
            // onRequestSubmit={() => onSearchBtn()}
            // primaryButtonText="Search" secondaryButtonText="Cancel"
            >
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button 
                disabled={!Util.isStringExists(barcodeValue)}
                onClick={() => onSearchBtn()}>
                    Search
                </Button>
            </ModalFooter>

        </ComposedModal>
    )
}