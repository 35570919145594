import { Checkmark20, Flow20, ArrowRight20, CheckmarkFilled16, LicenseDraft16, Reset16, Close16 } from '@carbon/icons-react'
import { useMemo, useState } from 'react'
import Select from 'react-select'
import Button from '../../components/Button'
import Util from '../../util/Util'
import UIUtil from '../../util/UIUtil'
import Api from '../../session/Api'


const styles = {
    container: (baseStyles) => ({
        ...baseStyles,
        paddingRight: 1,
        background: 'transparent',
        height: '100%',
        width: '100%'
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        maxHeight: '30px',
        padding: '0 6px',
        flexWrap: 'none',
        minWidth: 'fit-content'
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 5,
        background: '#ffffff10',
        border: '0px solid #00000099',
        outline: 'none',
        color: 'black',
        minHeight: 30,
        maxHeight: '30px',
        height: '100%',
        padding: 0,
        flexWrap: 'none'
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: 'black',
        height: '100%',
        maxHeight: '30px',
        margin: 0
    }),
    indicatorsContainer: (baseStyles, state) => ({
        ...baseStyles,
        height: '30px',
        maxHeight: '30px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
}

const parseOption = opt => opt ? { value: opt.id, label: opt.value, id: opt.id } : null;
function IdSelect({ placeholder, options, value: outValue, setValue: setOutValue }) {
    const value = useMemo(() => outValue?.map(id => options.find(opt => opt.id === id)).filter(opt => opt).map(parseOption) ?? [], [options, outValue])
    const setValue = values => setOutValue(values?.map($ => $.value) ?? [])

    return (
        <Select
            value={value}
            onChange={setValue}
            isClearable={false}
            placeholder={placeholder}
            styles={styles}
            isMulti options={options.map(parseOption)} />
    )
}

function SingleIdSelect({ placeholder, options, value: outValue, setValue: setOutValue }) {
    const value = useMemo(() => parseOption(options.find(opt => opt.id === outValue)), [options, outValue])
    const setValue = value => setOutValue(value?.id);

    return (
        <Select
            value={value}
            onChange={setValue}
            isClearable={false}
            placeholder={placeholder}
            styles={styles}
            options={options.map(parseOption)} />
    )
}



function TargetSelect({ value, setValue, placeholder, options }) {
    return (
        <Select
            value={value ? { value: value, label: value } : null}
            onChange={event => setValue(event.value)}
            placeholder={placeholder}
            styles={styles}
            options={options.map(opt => ({ value: opt, label: opt }))} />
    )
}



const Buttons = ({ event, changesMade, cancelBtn, createBtn, resetBtn, saveBtn, removeBtn }) => {
    if (Util.isNumberExist(event.id)) {
        if (changesMade) {
            return (<>
                {resetBtn}
                {saveBtn}
            </>)
        } else {
            return (<>
                {removeBtn}
            </>)
        }
        return null;
    } else {
        return (<>
            {cancelBtn}
            {createBtn}
        </>)
    }
}

const EVENTS = [
    "new kot"
]


export function PrintEvent({ stores, classes, printers, event, updateEvent, removeEvent }) {
    const [whenHook, setWhenHook] = useState(event.whenHook);
    const [printerId, setPrinterId] = useState(event.printerId);
    const [template, setTemplate] = useState(event.template)

    const [orderStores, setOrderStores] = useState(event.orderStores);
    const [orderClasses, setOrderClasses] = useState(event.orderClasses);

    const [editTemplate, setEditTemplate] = useState(false);

    const [saving, setSaving] = useState(false);
    const onSaveBtn = () => {
        setSaving(true)
        Api.updatePrintEvent({
            id: Util.isNumberExist(event.id) ? event.id : 0,
            whenHook, printerId, template, orderStores, orderClasses,
        }, response => {
            if (response.status === true) {
                updateEvent(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
            setSaving(false)
        })
    }

    const [deleting, setDeleting] = useState(false);
    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            setDeleting(true);
            Api.deletePrintEvent(event.id, response => {
                if (response.status === true) {
                    removeEvent();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message)
                }
                setDeleting(false)
            })
        })
    }

    const onResetBtn = () => {
        setWhenHook(event.whenHook);
        setPrinterId(event.printerId);
        setTemplate(event.template);
        setOrderStores(event.orderStores);
        setOrderClasses(event.orderClasses);
    }



    const canCreate = Util.isStringExists(whenHook) && Util.isNumberExist(printerId)
    const changesMade =
        event.whenHook !== whenHook
        || event.printerId !== printerId
        || event.template !== template
        || event.orderStores !== orderStores
        || event.orderClasses !== orderClasses;
    return (
        <div style={{ animation: !Util.isNumberExist(event.id) ? 'opacity-fade-in-md-anim 250ms' : undefined, width: '100%', marginBottom: '1rem', padding: '1rem', borderRadius: 10, border: '1px solid #00000010', background: 'white', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '1rem' }}>
                <div style={{ borderRadius: 10, background: '#00000000', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <h4 style={{ color: '#0f62fe', opacity: 0.85 }}>when</h4>
                    <Checkmark20 style={{ color: '#0f62fe', opacity: 0.85 }} />
                </div>
                <div style={{ borderRadius: 10, background: '#00000010', flex: 1, minWidth: 200, paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <TargetSelect placeholder={"Event"} setValue={setWhenHook} value={whenHook} options={EVENTS} />
                </div>

                <div style={{ borderRadius: 10, background: '#00000000', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <h4 style={{ color: '#0f62fe', opacity: 0.85 }}>if</h4>
                    <Flow20 style={{ color: '#0f62fe', opacity: 0.85 }} />
                </div>
                <div style={{ borderRadius: 10, background: '#00000010', flex: 2, paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <IdSelect placeholder={"Store"} value={orderStores} setValue={setOrderStores} options={stores} />
                    <IdSelect placeholder={"Class"} value={orderClasses} setValue={setOrderClasses} options={classes} />
                </div>

                <div style={{ borderRadius: 10, background: '#00000000', paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <h4 style={{ color: '#0f62fe', opacity: 0.85 }}>print on</h4>
                    <ArrowRight20 style={{ color: '#0f62fe', opacity: 0.85 }} />
                </div>
                <div style={{ borderRadius: 10, background: '#00000010', flex: 1, minWidth: 200, paddingInline: '1rem', paddingBlock: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    <SingleIdSelect placeholder={"Printer"} value={printerId} setValue={setPrinterId} options={printers} />
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <div style={{ flex: 1 }} />
                <Buttons event={event} changesMade={changesMade}

                    cancelBtn={
                        <Button onClick={removeEvent} disabled={saving} renderIcon={Close16} size="sm" kind="secondary" style={{ borderRadius: 25 }}>Cancel</Button>
                    }
                    createBtn={
                        <Button disabled={!canCreate} loading={saving} onClick={onSaveBtn} renderIcon={CheckmarkFilled16} size="sm" style={{ borderRadius: 25 }}>Create</Button>
                    }

                    resetBtn={
                        <Button onClick={onResetBtn} renderIcon={Reset16} size="sm" kind="ghost" style={{ borderRadius: 25 }}>Reset to Default</Button>
                    }
                    saveBtn={
                        <Button onClick={onSaveBtn} loading={saving} renderIcon={CheckmarkFilled16} size="sm" style={{ borderRadius: 25 }}>Save</Button>
                    }

                    removeBtn={
                        <Button onClick={onDeleteBtn} loading={deleting} renderIcon={Close16} size="sm" kind="danger-ghost" style={{ borderRadius: 25 }}>Delete</Button>
                    }
                />
            </div>

        </div>
    )
}